import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/scss/attachment.scss";
// import _ from "lodash";
import {
  AddAttachmentAction,
  AddExternalAttachmentAction
} from "../../actions/attachments/attachmentsaction";
// import moment from "moment";
import GooglePicker from "react-google-picker";
import DropboxChooser from "react-dropbox-chooser";
import {
  GOOGLE_KEY,
  GOOGLE_API_KEY,
  DROPBOX_APP_KEY,
  ONE_DRIVE_APP_KEY,
  ONE_DRIVE_REPLAY_URL
} from "../../constants/globalconst";
// import { UserAgentApplication } from 'msal';

import OneDriveAttachment from './oneDriveAttachment';

const mapStateToProps = (state) => {
  return {
    updateLoaderState: state.attachmentsReducer.updateLoaderState,
    uploadAttachmentLoaderState:
      state.attachmentsReducer.uploadAttachmentLoaderState
  };
};
function mapDispatchToProps(dispatch) {
  return {
    AddAttachmentAction: (
      body,
      file,
      is_priority,
      is_Activity_archived,
      callback
    ) => {
      dispatch(
        AddAttachmentAction(
          body,
          file,
          is_priority,
          is_Activity_archived,
          callback
        )
      );
    },
    AddExternalAttachmentAction: (
      body,
      is_priority,
      is_Activity_archived,
      callback
    ) => {
      dispatch(
        AddExternalAttachmentAction(
          body,
          is_priority,
          is_Activity_archived,
          callback
        )
      );
    }
  };
}
class AttachmentDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  chkMaxSize = (size) => {
    if (size > 10240000) {
      return true;
    } else {
      return false;
    }
  };

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  handleFileUpload(e) {
    const {
      // AddAttachmentAction,
      closeAttachmentDropdown,
      handleAttachFrom,
      attachedFrom,
      handleMaxSize,
      // is_priority,
      // is_archived
    } = this.props;
    e.stopPropagation();
    handleAttachFrom(attachedFrom);
    let selectedFiles = e.target.files;
    let attachments = []
    let attachmentsToAdd = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      if(selectedFiles[i].size < 30000000) {
        handleMaxSize(selectedFiles[i].size, attachedFrom);
        attachments.push(selectedFiles[i]);
        // console.log("attachment file", i, URL.createObjectURL(selectedFiles[i]));
        if(selectedFiles[i].type.indexOf("image") > -1) {
          // const src = await this.toBase64(selectedFiles[i]);
          attachmentsToAdd.push({
            title: selectedFiles[i].name,
            size: selectedFiles[i].size,
            src: URL.createObjectURL(selectedFiles[i])
          });
        } else {
            attachmentsToAdd.push(selectedFiles[i]);
        }
      }
      else {
          handleMaxSize(selectedFiles[i].size, attachedFrom);
          return closeAttachmentDropdown(null);
      }
  }
  closeAttachmentDropdown(attachmentsToAdd, attachments);
    // let file = e.target.files[0];
    //----Old Activity----------
    // const { activityDetails } = this.props;
    // const activity_id = activityDetails.id;
    // let body = {};
    // body.title = file.name;
    // body.size = file.size;
    // body.description = file.name;
    // body.activity_id = activity_id;
    // body.uploaded_by = parseInt(localStorage.getItem("id"));
    // body.is_archived = false;
    // body.file_name = file.name;
    // body.created_at = moment(new Date()).format();
    // const is_Activity_archived = is_archived;

    // call AddAttachmentAction
    // if (file.size < 30000000) {
      // handleMaxSize(file.size, attachedFrom);
      //----Old Activity----------
      // AddAttachmentAction(
      //   body,
      //   file,
      //   is_priority,
      //   is_Activity_archived,
      //   (response) => {
      //     if (response) {
      //       closeAttachmentDropdown(response);
      //       this.props.updateFetch();
      //     } else {
      //     }
      //   }
      // );
      //--------------New Activity design----------
      // const src = await this.toBase64(file);
      // const attachmentToAdd = {
      //   title: file.name,
      //   size: file.size,
      //   src
      // };
      // console.log(attachmentToAdd);
      // console.log("file", file);
      // closeAttachmentDropdown(attachmentToAdd);
      //--------------New Activity design above this----------
    // } else {
    //   handleMaxSize(file.size, attachedFrom);
    //   closeAttachmentDropdown(null);
    // }
  }

  handleGooglePickerUpload = (data) => {
    const {
      // AddExternalAttachmentAction,
      closeAttachmentDropdown,
      handleAttachFrom,
      attachedFrom,
      // is_priority,
      // is_archived
    } = this.props;
    // Google Drive
    if (data && data.docs && data.docs.length > 0) {
      handleAttachFrom(attachedFrom);
      let file = data.docs[0];
      //----Old Activity----------
      // const { activityDetails } = this.props;
      // const activity_id = activityDetails.id;
      // let body = {};
      // body.title = file.name;
      // body.size = file.sizeBytes;
      // body.description = file.name;
      // body.activity_id = activity_id;
      // body.uploaded_by = parseInt(localStorage.getItem("id"));
      // body.is_archived = false;
      // body.file_name = file.name;
      // body.created_at = moment(new Date()).format();
      // body.url = file.url;
      // const is_Activity_archived = is_archived;
      // AddExternalAttachmentAction(
      //   body,
      //   is_priority,
      //   is_Activity_archived,
      //   (response) => {
      //     if (response) {
      //       closeAttachmentDropdown(response);
      //     }
      //   }
      // );
      //--------------New Activity design----------
      const attachmentToAdd = {
        title: file.name,
        size: file.sizeBytes,
        url: file.url,
        // new_attachment: true
      };
      closeAttachmentDropdown(attachmentToAdd);
      //--------------New Activity design above this----------
    }
    // Dropbox
    if (data && data.length > 0) {
      handleAttachFrom(attachedFrom);
      let file = data[0];
      //----Old Activity----------
      // const { activityDetails } = this.props;
      // const activity_id = activityDetails.id;
      // let body = {};
      // body.title = file.name;
      // body.size = file.bytes;
      // body.description = file.name;
      // body.activity_id = activity_id;
      // body.uploaded_by = parseInt(localStorage.getItem("id"));
      // body.is_archived = false;
      // body.file_name = file.name;
      // body.created_at = moment(new Date()).format();
      // body.url = file.link;
      // const is_Activity_archived = is_archived;
      // AddExternalAttachmentAction(
      //   body,
      //   is_priority,
      //   is_Activity_archived,
      //   (response) => {
      //     if (response) {
      //       closeAttachmentDropdown(response);
      //     }
      //   }
      // );
      //--------------New Activity design----------
      const attachmentToAdd = {
        title: file.name,
        size: file.bytes,
        url: file.link,
        // new_attachment: true
      };
      closeAttachmentDropdown(attachmentToAdd);
      //--------------New Activity design above this----------
    }
  };

  oneDriveUpload =(data)=>{
    const {
      // AddExternalAttachmentAction,
      closeAttachmentDropdown,
      handleAttachFrom,
      attachedFrom,
      // is_priority,
      // is_archived,
      // activityDetails
    } = this.props;
    handleAttachFrom(attachedFrom);
    //----Old Activity----------
    // const activity_id = activityDetails.id;
    // let body = {};
    // body.title = data.name;
    // body.size = data.size;
    // body.description = data.name;
    // body.activity_id = activity_id;
    // body.uploaded_by = parseInt(localStorage.getItem("id"));
    // body.is_archived = false;
    // body.file_name = data.name;
    // body.created_at = moment(new Date()).format();
    // body.url = data.webUrl;
    // const is_Activity_archived = is_archived;
    // AddExternalAttachmentAction(
    //   body,
    //   is_priority,
    //   is_Activity_archived,
    //   (response) => {
    //     if (response) {
    //       closeAttachmentDropdown(response);
    //     }
    //   }
    // );
    //--------------New Activity design----------
    const attachmentToAdd = {
      title: data.name,
      size: data.size,
      url: data.webUrl,
      // new_attachment: true
    };
    closeAttachmentDropdown(attachmentToAdd);
    //--------------New Activity design above this----------
  }
  render() {
    const { attachedFrom } = this.props;
    return (
      <div
        className={`attach-files-dropdown-container ${
          attachedFrom === "attachment" ? "attachment" : ""
        }`}
      >
        <div className="attch-from-heading">Attach from</div>

        <div className="attch-from-link-holder">
          <label className="attch-from-link" style={{ cursor: "pointer" }}>
            Computer <span className="attach-file-size">file size 30 MB</span>
            <input
              type="file"
              name="myFile"
              onChange={this.handleFileUpload}
              className="attachmentBox"
              multiple
            />
          </label>
        </div>
        <div className="attch-from-link-holder">
          <GooglePicker
            clientId={GOOGLE_KEY}
            developerKey={GOOGLE_API_KEY}
            scope={["https://www.googleapis.com/auth/drive.readonly"]}
            onChange={(data) => this.handleGooglePickerUpload(data)}
            // onAuthFailed={data => console.log("on auth failed:", data)}
            multiselect={false}
            navHidden={true}
            authImmediate={false}
            viewId={"DOCS"}
          >
            <label className="attch-from-link from-drive"> Google Drive </label>
          </GooglePicker>
        </div>
        <div className="attch-from-link-holder">
          <DropboxChooser
            appKey={DROPBOX_APP_KEY}
            success={(files) => this.handleGooglePickerUpload(files)}
          >
            <label className="attch-from-link from-dropbox"> Dropbox </label>
          </DropboxChooser>
        </div>
        <div className="attch-from-link-holder">
                
          <OneDriveAttachment
            appKey={ONE_DRIVE_APP_KEY}
            oneDriveUpload={this.oneDriveUpload}
            updateAttchmetnOneDrive={this.props.updateAttchmetnOneDrive}
            replayurl={ONE_DRIVE_REPLAY_URL}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentDropdown);
