import React, { useState, useRef, useEffect } from "react";
import deleteChecklistSVG from "../../styles/img/svg/delete-checklist.svg";
import checkedOvalSVG from "../../styles/img/svg/checked_oval.svg";

import checkedmarkSVG from "../../styles/img/svg/checked-mark.svg";
import empty from "../../styles/img/svg/emptyimage.svg";
import calendarIcon from "../../styles/img/svg/blue-cal.svg";
import bookmarkIcon from "../../styles/img/svg/bookmark.svg";
import delete_icon from "../../styles/img/svg/user-name-delete.svg";
import bookmarkedIcon from "../../styles/img/svg/bookmarked.svg";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import DateRangePicker from "../common/dateRangePicker";
import ReactHtmlParser from "react-html-parser";
import { parseComment } from "../../helpers/generichelper";
import ChecklistItemInput from "./checklistItemInput";
import { DragTypes } from "../../constants/DragTypes";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";

function ChecklistItem({
  checklistItem,
  isSuperAdmin,
  activityDetails,
  isUserPartOfCard,
  checklistItemId,
  checklistItems,
  index,
  users,
  updateChecklistItemAction,
  toHideAddItemInput,
  clickedChecklistId,
  moveCard,
  onchecklistUpdated,
  onMoveOfItemToAnotherList,
  showCompleted,
  checklistId,
  setChecklist,
  // previewFlag,
  checklistsArray,
  draggingParent,
  setDraggingParent,
}) {
  const [isEdit, setEdit] = useState(false);
  const [isCompleted, setCompleted] = useState(checklistItem.is_completed);
  const [hovered, setHovered] = useState(false);
  const ref = useRef(null);
  
  const [showUndo, setShowUndo] = useState(false);
  

  

  useEffect(() => {
    if (document.getElementsByClassName("daterangepicker").length > 0) {
      let i;
      for (
        i = 0;
        i < document.getElementsByClassName("daterangepicker").length;
        i++
      ) {
        document
          .getElementsByClassName("daterangepicker")
          [i].classList.add("check-date-picker");
      }
    }
  }, []);

  

  const setEditfalse = () => {
    setEdit(false);
    setHovered(false);
  };

  const setHoveredFalse = () => {
    setHovered(false);
  };

  const onChecklistItemClick = (e) => {
    e.stopPropagation();
    toHideAddItemInput();
    (isSuperAdmin || isUserPartOfCard) &&
      !checklistItem.showUndo &&
      setEdit(!isEdit);
  };

  const onChecklistItemCompleted = (isCompletedValue) => {
    setCompleted(isCompletedValue);
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: checklistItem.is_archived,
      is_completed: isCompletedValue,
      title: checklistItem.title,
      completed_by: parseInt(localStorage.getItem("id")),
    };

    updateChecklistItemAction(updatedCheckListItem, (_res) => {
      onchecklistUpdated();
    });
  };

  

  const onUndoDelete = () => {
    
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: false,
      is_completed: checklistItem.is_completed,
      title: checklistItem.title,
    };

    updateChecklistItemAction(updatedCheckListItem, (res) => {
      if (res && res.id) onchecklistUpdated();
    });
  };

  const onChecklistItemAddDueDate = (_event, picker) => {
    const dueDate = moment(picker.startDate._d)
      .endOf("day")
      .format();
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: checklistItem.is_archived,
      is_completed: checklistItem.is_completed,
      title: checklistItem.title,
      data: {
        due_date: dueDate,
      },
    };
    updateChecklistItemAction(updatedCheckListItem, (res) => {
      if (res && res.id) onchecklistUpdated();
    });
  };

  const onChecklistItemRemoveDueDate = (event) => {
    event.stopPropagation();
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: checklistItem.is_archived,
      is_completed: checklistItem.is_completed,
      title: checklistItem.title,
      data: {
        due_date: "",
      },
    };
    updateChecklistItemAction(updatedCheckListItem, (res) => {
      if (res && res.id) onchecklistUpdated();
    });
  };

  const onChecklistItemDeleted = () => {
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: true,
      is_completed: checklistItem.is_completed,
      title: checklistItem.title,
    };

    updateChecklistItemAction(updatedCheckListItem, (res) => {
      if (res && res.id) setShowUndo(false);
      onchecklistUpdated();
    });
  };

  const onBookmarkUnBookmark = () => {
    const loggedinUserId = parseInt(localStorage.getItem("id"));
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: checklistItem.is_archived,
      is_completed: checklistItem.is_completed,
      title: checklistItem.title,
    };
    if (checklistItem?.social_data?.bookmarked_by_logged_in_user) {
      updatedCheckListItem.social_data = {
        likes: checklistItem.social_data.likes,
        bookmarked: checklistItem.social_data.bookmarked.filter(
          (userId) => userId !== loggedinUserId
        ),
      };
    } else if (
      checklistItem?.social_data?.bookmarked_by_logged_in_user === false
    ) {
      updatedCheckListItem.social_data = {
        likes: checklistItem.social_data.likes,
        bookmarked: [...checklistItem.social_data.bookmarked, loggedinUserId],
      };
    } else if (checklistItem?.social_data?.likes) {
      updatedCheckListItem.social_data = {
        likes: checklistItem.social_data.likes,
        bookmarked: [loggedinUserId],
      };
    } else {
      updatedCheckListItem.social_data = {
        bookmarked: [loggedinUserId],
      };
    }
    updateChecklistItemAction(updatedCheckListItem, (_res) => {
      onchecklistUpdated();
    });
  };

  const onChecklistItemRearranged = (newIndex, _oldIndex, item) => {
    const oldPosition = item.position;
    let position = item.position;
    

    // Conditions for same and different checklist
    if (item.sourceCheckListId === item.checklist_id) {
      // console.log("same position", checklistItems.findIndex(cItem => cItem.id === checklistItem.id ) === index)
      // if(checklistItems.indexOf(item) === newIndex) {
      //   // console.log("same position")
      //   return
      // } else
      if (checklistItems[newIndex - 1]?.position > item.position) {
        position = checklistItems[newIndex - 1].position;
      } else if (checklistItems[newIndex + 1]?.position < item.position) {
        position = checklistItems[newIndex + 1].position;
      } else {
        // TODO document why this block is empty
      }
    } else {
      if (checklistItems[newIndex + 1]) {
        position = checklistItems[newIndex + 1].position;
      } else {
        position = checklistItems[checklistItems.length - 2].position + 1;
      }
    }

    
    const updatedCheckListItem = {
      checklist_id: item.checklist_id,
      created_by: item.created_by,
      id: item.id,
      is_archived: false,
      is_completed: item.is_completed,
      position,
      title: item.title,
    };

    if (
      item.sourceCheckListId === item.checklist_id &&
      oldPosition !== position
    )
      updateChecklistItemAction(updatedCheckListItem, (_res) => {
        // TODO document why this arrow function is empty
      });

    if (item.sourceCheckListId !== item.checklist_id)
      updateChecklistItemAction(updatedCheckListItem, (_res) => {
        onMoveOfItemToAnotherList(item.index, item.index, item);
      });
  };

  const [{ isTargetDragging }, drop] = useDrop({
    accept: DragTypes.CHECKLISTITEM,
    collect: (monitor) => {
      return {
        isTargetDragging: monitor.isOver(),
      };
    },
    hover(item, _monitor) {
      if (!ref.current) {
        
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      const dragList = item.checklist_id;
      const hoverList = checklistItem.checklist_id;

      

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && item.currentChecklistId === checklistId) {
        // console.log("hoverindex", hoverIndex, "draggeditemindex", dragIndex)
        return;
      }
      isEdit && setEditfalse();

      
      setDraggingParent(true);
      let currentCheckListId = item.currentChecklistId;
      if (item.currentChecklistId !== checklistId) {
        item.currentChecklistId = checklistId;
      }

      moveCard(
        dragIndex,
        hoverIndex,
        dragList,
        hoverList,
        item,
        currentCheckListId,
        item.sourceCheckListId
      );
      item.index = hoverIndex;

      item.checklist_id = hoverList;
    },
    drop(item, _monitor) {
      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // if (!ref.current) {
      //   return;
      // }
      isEdit && setEditfalse();
      
      const hoverIndex = index;
      
      item.checkDIdDrop = true;
      setDraggingParent(false);
      setHovered(false);
      onChecklistItemRearranged(hoverIndex, item.index, item);

      // if (dragList !== hoverList)  // to trigger when lists are diff.
    },
  });

  const [{ opacity, isDragging }, drag, preview] = useDrag({
    item: {
      type: DragTypes.CHECKLISTITEM,
      ...checklistItem,
      index,
      isSuperAdmin,
      activityDetails,
      isUserPartOfCard,
      checklistItemId,
      checklistItems,
      users,
      updateChecklistItemAction,
      toHideAddItemInput,
      clickedChecklistId,
      moveCard,
      onchecklistUpdated,
      onMoveOfItemToAnotherList,
      checklistItem,
      showCompleted,
      currentChecklistId: checklistItem.checklist_id,
      sourceCheckListId: checklistItem.checklist_id,
      checkDIdDrop: false,
    },
    myVal: "vignesh",
    collect: (monitor, _props) => {
     
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      };
    },
    start(item, _monitor) {
      item.checkDIdDrop = false;
    },
    end(item, _monitor) {
      if (!item.checkDIdDrop) {
        let checklistsArrayCopy = _.cloneDeep(checklistsArray);
        checklistsArrayCopy.forEach((checklistData) => {
          if (checklistData.id !== checklistId) {
            let checkListItemData = checklistData.checklist_items.filter(
              (checklistItem) => checklistItem.id !== item.id
            );
            checklistData.checklist_items = checkListItemData;
          }
        });
        setChecklist(checklistsArrayCopy);
      }

      return;
    },
  });

  drag(drop(ref));

  

  

  const onStartDragging = (e) => {
    if (checklistItem.showUndo) {
      e.preventDefault();
      e.stopPropagation();
    } else if (isSuperAdmin) {
      // TODO document why this block is empty
    
    } else if (!isUserPartOfCard) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onMouseOver = () => {
    if (!draggingParent) {
      setHovered(true);
    } else {
      setHovered(false);
    }
  };

  const onCheckItem = () =>
    (isSuperAdmin ||
      isUserPartOfCard ||
      checklistItem.id === checklistItemId) &&
    !checklistItem.showUndo &&
    onChecklistItemCompleted(true);

  const onUncheckItem = () =>
    (isSuperAdmin ||
      isUserPartOfCard ||
      checklistItem.id === checklistItemId) &&
    !checklistItem.showUndo &&
    onChecklistItemCompleted(false);

  return (
    <>
      <DragPreviewImage
        connect={preview}
        style={{ width: "200px", height: "200px" }}
        src={empty}
      />
      <OutsideClickHandler onOutsideClick={setEditfalse}>
        <div onMouseOut={setHoveredFalse}>
          <div
            className={
              isDragging || isTargetDragging
                ? "dnd-lists-checklists getting-dragged"
                : `dnd-lists-checklists ${
                    isEdit || isDragging || isTargetDragging ? "" : "hovered"
                  }`
            }
            ref={ref}
            draggable={isSuperAdmin || isUserPartOfCard}
            onDragStart={onStartDragging}
          >
            <div className="additem-container" onMouseOver={onMouseOver}>
              {!isEdit && (
                <div className={isCompleted ? "checked" : "unchecked"}>
                  <div
                    className={`item-image-container-wrap ${
                      isCompleted ? "checked-item" : ""
                    } ${checklistItem.is_archived ? "blur" : ""}`}
                  >
                    {!isCompleted && (
                      <img
                        src={checkedOvalSVG}
                        alt="Smiley face"
                        className="check-round"
                        role="button"
                        tabIndex="0"
                        onClick={onCheckItem}
                      />
                    )}

                    {isCompleted && (
                      <img
                        src={checkedmarkSVG}
                        alt="Smiley face"
                        className="check-round"
                        role="button"
                        tabIndex="0"
                        onClick={onUncheckItem}
                      />
                    )}

                    <div
                      className="checklist-item-div"
                      onClick={onChecklistItemClick}
                    >
                      {index + 1 < 10 && (
                        <div className="checklist-no-holder">
                          {`${index + 1}.`} &nbsp;
                        </div>
                      )}
                      {index + 1 > 9 && (
                        <div className="checklist-no-holder more-than-9">
                          {`${index + 1}.`} &nbsp;
                        </div>
                      )}
                      <div className="checklist-title-render">
                        {" "}
                        {ReactHtmlParser(
                          parseComment(checklistItem.title)
                        )}{" "}
                        {checklistItem?.data?.due_date && (
                          <span
                            className={`chk-item-due-date ${
                              moment(
                                new Date(checklistItem.data.due_date)
                              ).isBefore(moment(new Date()))
                                ? "past-date"
                                : ""
                            }`}
                          >
                            &nbsp; Due on{" "}
                            {moment(
                              new Date(checklistItem.data.due_date)
                            ).format("ddd, DD MMM YYYY")}
                            {(isSuperAdmin || isUserPartOfCard) &&
                              !checklistItem.showUndo && (
                                <div className="user-img-delete">
                                  <img
                                    src={delete_icon}
                                    alt="Delete Icon"
                                    onClick={onChecklistItemRemoveDueDate}
                                  />
                                </div>
                              )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {(isSuperAdmin ||
                    isUserPartOfCard ||
                    checklistItem.id === checklistItemId) &&
                    !checklistItem.showUndo && (
                      <div
                        className={
                          hovered
                            ? "delete-img-container visible"
                            : "delete-img-container"
                        }
                      >
                        {checklistItem.id !== checklistItemId && (
                          <DateRangePicker
                            startDate={
                              checklistItem?.data?.due_date
                                ? moment(
                                    new Date(checklistItem.data.due_date)
                                  ).format("M/DD/YYYY")
                                : ""
                            }
                            endDate={
                              checklistItem?.data?.due_date
                                ? moment(
                                    new Date(checklistItem.data.due_date)
                                  ).format("M/DD/YYYY")
                                : ""
                            }
                            maxDate={moment(new Date()).format("M/DD/YYYY")}
                            onChangeDate={onChecklistItemAddDueDate}
                            source={"CHECKLIST_ITEM"}
                          >
                            <Tooltip title="Due on">
                              <div className="del-inner">
                                <img src={calendarIcon} alt="cal-icon" />
                              </div>
                            </Tooltip>
                          </DateRangePicker>
                        )}
                        {checklistItem.id !== checklistItemId && (
                          <div
                            className="del-inner"
                            onClick={onChecklistItemDeleted}
                          >
                            <img src={deleteChecklistSVG} alt="del-icon" />
                          </div>
                        )}
                        <Tooltip
                          title={
                            checklistItem?.social_data
                              ?.bookmarked_by_logged_in_user
                              ? "Remove from bookmarked"
                              : "Bookmark this checklist item"
                          }
                        >
                          {checklistItem?.social_data
                            ?.bookmarked_by_logged_in_user ? (
                            <div
                              className="del-inner bookmark"
                              onClick={onBookmarkUnBookmark}
                              style={{
                                visibility: isDragging ? "hidden" : "visible",
                              }}
                            >
                              <img
                                src={
                                  checklistItem?.social_data
                                    ?.bookmarked_by_logged_in_user
                                    ? bookmarkedIcon
                                    : bookmarkIcon
                                }
                                alt="bookmark"
                              />
                            </div>
                          ) : (
                            <div
                              className="del-inner bookmark"
                              onClick={onBookmarkUnBookmark}
                            >
                              <img
                                src={
                                  checklistItem?.social_data
                                    ?.bookmarked_by_logged_in_user
                                    ? bookmarkedIcon
                                    : bookmarkIcon
                                }
                                alt="bookmark"
                              />
                            </div>
                          )}
                        </Tooltip>
                      </div>
                    )}
                  {checklistItem.showUndo && (
                    <div
                      className={
                        hovered
                          ? "delete-img-container visible"
                          : "delete-img-container"
                      }
                    >
                      <button
                        className={hovered ? "undo-btn visible" : "undo-btn"}
                        onClick={onUndoDelete}
                      >
                        Undo
                      </button>
                    </div>
                  )}
                </div>
              )}

              {isEdit && !checklistItem.showUndo && (
                <ChecklistItemInput
                  users={users}
                  checklistItem={checklistItem}
                  className="checklist-textarea-input"
                  setEditfalse={setEditfalse}
                  value={checklistItem.title}
                  activityDetails={activityDetails}
                  updateChecklistItemAction={updateChecklistItemAction}
                  toHideAddItemInput={toHideAddItemInput}
                  onchecklistUpdated={onchecklistUpdated}
                ></ChecklistItemInput>
              )}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
}

export default React.memo(ChecklistItem);
