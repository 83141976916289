import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  Fragment,
  memo,
} from "react";
import collapseIcon from "../../styles/img/svg/collapse.svg";
import optionsDot from "../../styles/img/svg/3-dots.svg";
import KanbanActivityCard from "./kanbanActivityCard";
import DialogWrapper from "../common/dialog";
import ActivityForm from "../activity/addactivity";
import _ from "lodash";
import { DragTypes } from "../../constants/DragTypes";
import { useDrag, useDrop } from "react-dnd";
import KanbanContext from "../../contexts/kanban/kabanContext";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import MyDropTarget from "./kanbanActivityHelper";

momentDurationFormatSetup(moment);

const KanbanWorkflow = ({
  workflowList,
  setWorkflowList,
  workflow,
  index,
  collapsedWorkflows,
  setCollapsedWorkflow,
}) => {
  const ref = useRef(null);
  const {
    selectedProject,
    updateSelectedProjectState,
    updateProjectAction,
    getUserDetailsAction,
    fetchTimesheetAction,
    loggedinUser,
    getActivitiesWithProjectId,
  } = useContext(KanbanContext);
  const [workflowOption, setWorkflowOption] = useState(false);
  const [editWorkflow, setEditWorkflow] = useState(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState(false);
  const [workflowName, setWorkflowName] = useState("");

  const [workflowData, setWorkflowData] = useState({});

  useEffect(() => {
    setWorkflowData(workflow);
  }, [workflow]);

  const MoveCard = useCallback(
    (_dragIndex, hoverIndex, activity, workflowid, sourceWorkFlowid) => {
      let workflowCopy = _.cloneDeep(workflowData);
      let activityCopy = _.cloneDeep(activity);
      let workflowListCopy = _.cloneDeep(workflowList);
      if (workflowid === workflow.id) {
        let otherActivites = workflowCopy.activities.filter(
          activityData => activityData.id !== activityCopy.id
        );
        workflowCopy.activities = otherActivites;
        workflowCopy.activities.splice(hoverIndex, 0, activityCopy);
      } else {
        let otherActivites = workflowCopy.activities.filter(
          activityData => activityData.id !== activityCopy.id
        );
        workflowCopy.activities = otherActivites;
        workflowCopy.activities.splice(hoverIndex, 0, activityCopy);
        workflowListCopy.forEach(workFlowDataEach => {
          if (
            workFlowDataEach.id !== sourceWorkFlowid &&
            workflow.id !== workFlowDataEach.id
          ) {
            let actvities = workFlowDataEach.activities.filter(
              activitydata => activitydata.id !== activityCopy.id
            );
            workFlowDataEach.activities = actvities;
          }
          if (workflow.id === workFlowDataEach.id) {
            workFlowDataEach.activities = workflowCopy.activities;
          }
        });

        setWorkflowList(workflowListCopy);
      }
      setWorkflowData(workflowCopy);
    },
    [workflowData]
  );

  const createWorkflowOrder = () => {
    if (
      selectedProject.data &&
      (!selectedProject.data.workflowOrder ||
        selectedProject.data.workflowOrder.length < 1)
    ) {
      let projectWorkflowOrder = [];
      _.forIn(selectedProject.workflow, (value, key) => {
        if (!value.is_archived && !value.is_collapsed) {
          let workflow = {};
          workflow.id = key;
          workflow.name = value.name;
          workflow.is_archived = value.is_archived;
          workflow.is_collapsed = value.is_collapsed;
          projectWorkflowOrder.push(workflow);
        }
      });
      selectedProject.data.workflowOrder = projectWorkflowOrder;
    }
  };
  createWorkflowOrder();

  const moveWorkflow = (orderArray, dragIndex, hoverIndex) => {
    const draggedWorkflow = orderArray[dragIndex];
    orderArray.splice(dragIndex, 1);
    orderArray.splice(hoverIndex, 0, draggedWorkflow);
  };

  const closeWorkflowOption = () => {
    setWorkflowOption(false);
    setEditWorkflow(false);
    setDeleteWorkflow(false);
  };
  const [, drop] = useDrop({
    accept: DragTypes.WORKFLOW,
    drop(item, _monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      // const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      // const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return
      // }
      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return
      // }
      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      let arrayOrder;
      if (selectedProject.data.workflowOrder.length < 1) {
        createWorkflowOrder();
        arrayOrder = selectedProject.data.workflowOrder;
      } else {
        arrayOrder = selectedProject.data.workflowOrder;
      }

      let collapsedWf = [];
      let collapsedArrO = [];
      let archivedArrO = [];

      // Removing collapsed and archived to drag drop
      workflowList = workflowList.filter((wf, index) => {
        if (wf.is_collapsed) {
          collapsedWf.push({ ...wf, index });
          return false;
        } else return wf;
      });

      arrayOrder = arrayOrder.filter((ao, index) => {
        if (ao.is_collapsed) {
          collapsedArrO.push({ ...ao, index });
          return false;
        } else if (ao.is_archived) {
          archivedArrO.push(ao);
          return false;
        } else return ao;
      });

      

      // changing index positions
      moveWorkflow(arrayOrder, dragIndex, hoverIndex);
      moveWorkflow(workflowList, dragIndex, hoverIndex);

      // adding back the collapsed and archived to update
      if (collapsedWf.length > 0) {
        collapsedWf.forEach(cwf => {
          console.log("cwf", cwf.index);
          let cwfIndex = cwf.index;
          delete cwf.index;
          workflowList.splice(cwfIndex, 0, { ...cwf });
        });
      }

      if (collapsedArrO.length > 0) {
        collapsedArrO.forEach(cwf => {
          console.log("cwf", cwf.index);
          let cwfIndex = cwf.index;
          delete cwf.index;
          arrayOrder.splice(cwfIndex, 0, { ...cwf });
        });
      }

      if (archivedArrO.length > 0) {
        archivedArrO.forEach(cwf => {
          arrayOrder.push(cwf);
        });
      }

      

      setWorkflowList([...workflowList]);
      
      let projectToUpdate = {
        id: selectedProject.id,
        // data: selectedProject.data,
        updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        workflow_changes: {
          order_changed: true,
          new_order: arrayOrder.map(wfo => `${wfo.id}`),
        },
      };
      const auth_token = localStorage.getItem("auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: projectToUpdate },
        response => {
          if (response && response.id) {
            updateSelectedProjectState(response);
          }
        }
      );
      item.index = hoverIndex;
    },
  });
  const [{ opacity }, drag] = useDrag({
    item: { type: DragTypes.WORKFLOW, id: workflow.id, index },
    collect: monitor => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });
  drag(drop(ref));
  const updateWorkflowName = () => {
    
    const editWorkflowName = arr => {
      _.forEach(arr, (innerWorkflow, i, arr) => {
        if (parseInt(workflow.id) === parseInt(innerWorkflow.id)) {
          arr[i].name = workflowName;
        }
      });
    };
    
    let projectToUpdate = {
      id: selectedProject.id,
      // workflow: selectedProject.workflow,
      // data: selectedProject.data,
      updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      workflow_changes: {
        renamed: [{ id: workflow.id, old: workflow.name, new: workflowName }],
      },
    };
    const auth_token = localStorage.getItem("auth_token");
    updateProjectAction(
      { auth_token, queryParam: "", body: projectToUpdate },
      response => {
        if (response && response.id) {
          updateSelectedProjectState(response);
          editWorkflowName(workflowList);
          setWorkflowList([...workflowList]);
          setEditWorkflow(false);
          setWorkflowName("");
          getActivitiesWithProjectId(selectedProject.id);
        }
      }
    );
  };

  const handleDeleteWorkflow = () => {
    
    let projectToUpdate = {
      id: selectedProject.id,
      // workflow: selectedProject.workflow,
      // data: selectedProject.data,
      updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      workflow_changes: {
        archived: [{ id: workflow.id, name: workflow.name }],
      },
    };
    const auth_token = localStorage.getItem("auth_token");
    updateProjectAction(
      { auth_token, queryParam: "", body: projectToUpdate },
      response => {
        if (response && response.id) {
          
          updateSelectedProjectState(response);
          let workflowIndex;
          _.forEach(workflowList, (innerWorkflow, i) => {
            if (parseInt(workflow.id) === parseInt(innerWorkflow.id)) {
              workflowIndex = i;
            }
          });
          workflowList.splice(workflowIndex, 1);
          setWorkflowList([...workflowList]);
          setDeleteWorkflow(false);
          setWorkflowOption(false);
        }
      }
    );
  };

  const handleCollapseWorkflow = () => {
    
    let workflowIndex;
    _.forEach(workflowList, (innerWorkflow, i) => {
      if (parseInt(workflow.id) === parseInt(innerWorkflow.id)) {
        workflowIndex = i;
      }
    });
    
    workflowList = workflowList.map(workFlow => {
      if (parseInt(workFlow.id) === parseInt(workflow.id)) {
        workFlow.is_collapsed = true;
        return workFlow;
      }
      return workFlow;
    });
    let collapsedWfObj = {};
    collapsedWfObj.id = workflow.id;
    collapsedWfObj.name = workflow.name;
    collapsedWfObj.activities = workflow.activities;
    collapsedWfObj.is_archived = false;
    collapsedWfObj.is_collapsed = true;

    let projectToUpdate = {
      id: selectedProject.id,
      // workflow: selectedProject.workflow,
      // data: selectedProject.data,
      updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      workflow_changes: {
        collapsed: [{ id: workflow.id, name: workflow.name }],
      },
    };
    const auth_token = localStorage.getItem("auth_token");
    updateProjectAction(
      { auth_token, queryParam: "", body: projectToUpdate },
      response => {
        if (response && response.id) {
          setWorkflowOption(false);
          updateSelectedProjectState(response);
          setWorkflowList([...workflowList]);
          setCollapsedWorkflow([...collapsedWorkflows, collapsedWfObj]);
        }
      }
    );
  };

  const [isPopupEnable, setIsPopupEnable] = useState(false);
  const [dialogPropsWorkflow, setdialogPropsWorkflow] = useState({});

  const onPopupCloseCallBack = () => {
    getActivitiesWithProjectId(selectedProject.id);
    setIsPopupEnable(false);
  };
  const onConfirm = () => {};
  const callWorkflow = () => dialogPropsWorkflow;
  let dialogPropsOuter = {
    className: "create_activity",
    showPopup: isPopupEnable,
    dialogTitle: () => {
      return "New Activity";
    },
    onCloseCallBack: onPopupCloseCallBack,
    onConfirmCallBack: onConfirm,
    shouldButtonEnable: true,
    selectedProject: selectedProject,
    callWorkflow: callWorkflow,
  };
  const handleActivityPopup = workflow => {
    setdialogPropsWorkflow({
      name: workflow.name,
      id: workflow.id,
    });
    setIsPopupEnable(true);
  };
  let workFlowActivityCard = null;

  if (workflowData.activities && workflowData.activities.length > 0) {
    workFlowActivityCard = workflowData.activities.map((activity, index) => {
      if (!activity.workflow) {
        activity = { ...activity, workflow: { id: 1, name: "Backlog" } };
      }
      return (
        <KanbanActivityCard
          key={activity.id}
          activitiyList={workflow.activities}
          activity={activity}
          workflowList={workflowList}
          setWorkflowList={setWorkflowList}
          index={index}
          workflow={workflowData}
          setworkflowData={setWorkflowData}
          moveCard={MoveCard}
        ></KanbanActivityCard>
      );
    });
  } else {
    workFlowActivityCard = (
      <MyDropTarget
        workflow={workflow}
        index={index}
        setWorkflowList={setWorkflowList}
        workflowList={workflowList}
        moveCard={MoveCard}
      ></MyDropTarget>
    );
  }

  return (
    <Fragment>
      {isPopupEnable && (
        <DialogWrapper dialogProps={dialogPropsOuter}>
          <ActivityForm
            getUserDetailsAction={getUserDetailsAction}
            logedinUserDetails={loggedinUser}
            fetchTimesheetAction={fetchTimesheetAction}
            source="FROM_KANBAN"
            {...dialogPropsOuter}
          />
        </DialogWrapper>
      )}
      <li
        ref={ref}
        style={{ opacity }}
        className={
          "kanban-board " + (parseInt(workflow.id) === 1 ? "backlog" : "")
        }
      >
        <div className="kanban-board-header">
          <div className="header-left-prj-wf">
            <div className="worflow-name-title truncate-text">
              {workflow.name}
            </div>
            <div className="num-activties truncate-text">
              {" "}
              - {workflow.activities.length} Activities
            </div>
          </div>
          {parseInt(workflow.id) !== 1 && (
            <OutsideClickHandler onOutsideClick={closeWorkflowOption}>
              <div className="header-right">
                <div
                  className="flow-oval"
                  onClick={() => setWorkflowOption(!workflowOption)}
                >
                  <img
                    className="calendar-image"
                    src={optionsDot}
                    alt="more-option"
                  />
                </div>

                {workflowOption && (
                  <div className="flow-options">
                    <div className="options">
                      <p
                        className="kanban-link"
                        onClick={() => {
                          setEditWorkflow(true);
                          setWorkflowName(workflow.name);
                        }}
                      >
                        {" "}
                        Edit
                      </p>
                      {editWorkflow && (
                        <div className="flow-edit-box">
                          <input
                            type="text"
                            placeholder="Type workflow name "
                            id={"titleInput" + index}
                            autoFocus
                            value={workflowName}
                            onChange={e => setWorkflowName(e.target.value)}
                          />
                          <div className="button">
                            <span
                              className="cancel"
                              onClick={() => setEditWorkflow(false)}
                            >
                              Cancel
                            </span>
                            <button
                              className="save"
                              onClick={updateWorkflowName}
                              disabled={!workflowName}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                      <p
                        className="collapse-workflow-trigger kanban-link"
                        onClick={handleCollapseWorkflow}
                      >
                        Collapse
                      </p>

                      {workflow.activities.length === 0 && (
                        <p
                          className="kanban-link"
                          onClick={() => setDeleteWorkflow(true)}
                        >
                          Delete
                        </p>
                      )}
                      {deleteWorkflow && (
                        <div className="deleteflow-box">
                          <p>Delete this workflow ?</p>
                          <div className="button">
                            <span
                              className="cancel"
                              onClick={() => setDeleteWorkflow(false)}
                            >
                              Cancel
                            </span>
                            <button
                              className="save"
                              onClick={handleDeleteWorkflow}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          )}
        </div>

        <div className="kanban-content-area">
          <div className="kanban-content-area-holder">
            {workFlowActivityCard}
          </div>
        </div>
        <div className="add-activity">
          <p
            className="add-activity-trigger"
            onClick={() => handleActivityPopup(workflow)}
          >
            {" "}
            + Add activity
          </p>
          <div className="img-container" onClick={handleCollapseWorkflow}>
            <img src={collapseIcon} alt="collapse" />
          </div>
        </div>
      </li>
    </Fragment>
  );
};

export default memo(KanbanWorkflow);
