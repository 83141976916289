import React from "react";
import "../../styles/scss/user_profile.scss";

const UserCannotRemovePopup = ({
  userToRemove,
  loggedInUser,
  onClosePopup
}) => {
  return (
    <div className="pop-up-password google">
      <div className="message">
        <span>
          Please make sure{" "}
          {parseInt(userToRemove.id) === parseInt(loggedInUser.id)
            ? "your"
            : `${userToRemove.first_name}'s`}{" "}
          timer for this Activity is not running in order to perform this
          action.
        </span>
      </div>
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default UserCannotRemovePopup;
