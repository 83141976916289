import React from "react";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import "../../styles/scss/user_profile.scss";
import gotoWorkspaceBtn from "../../styles/img/svg/go-to-workspace.svg";

const TimerCannotStartPopup = ({
  errorObj,
  listOfCustomer,
  selectedCustomerDetails, 
  onClosePopup,
  onCloseActivityInner,
  onWorkspaceNavBarClicked
}) => {
  const workspaceToChange = listOfCustomer?.length > 0 &&
    listOfCustomer.filter(customer => customer.id === errorObj?.customer_id)[0];
  return (
    <div className="pop-up-password cannot-start-timer">
      <div className="message">
        {errorObj?.customer_id === selectedCustomerDetails.id ?
        <span>
        You cannot run multiple activities at the same time within a workspace. 
        Stop the timer for the activity <strong>{errorObj.activity_card_name}</strong> if you wish to start another activity. 
        </span> 
        :
        errorObj?.msg === "You are not a part of this activity card" ?
        <span>This Activity is not accessible currently. Please refresh the page to check if you're still a member of this activity.</span>
        :
        errorObj?.activity_card_name && <span>
        You cannot run multiple activities at the same time across workspaces. 
        Stop the timer for the activity <strong>{errorObj.activity_card_name}</strong> under 
        the workspace <strong>{errorObj.customer_name}</strong> if you wish to start another activity. 
        </span>}
        {errorObj.error === "Priority limit exceeded" &&
        <span>
          You cannot Prioritize more than 3 activities. Please refresh the page to get updated prioritized activities.
        </span>}

      </div>
      {errorObj?.msg === "You are not a part of this activity card" || 
      errorObj?.customer_id === selectedCustomerDetails.id ||
      errorObj?.error === "Priority limit exceeded" ?
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          Okay
        </button>
      </div>
      :
      workspaceToChange &&
      <div className="act-submit-btn-holder change-password-btn google cannot-start-timer">
        <div className="workspace-holder">
          {workspaceToChange.img ?
            <img src={workspaceToChange.img} alt="workspace-logo" />
          :
          <Avatar
            style={{ width: "100%", height: "100%" }}
            name={workspaceToChange.display_name}
            maxInitials={1} />
          }
          <div className="workspace-name-to-switch">
            {workspaceToChange.display_name}
          </div>
        </div>
        <img
            role="button"
            src={gotoWorkspaceBtn}
            alt="go to workspace"
            onClick={(e) => {
                workspaceToChange && onWorkspaceNavBarClicked(e, workspaceToChange);
                onClosePopup();
                onCloseActivityInner && onCloseActivityInner();
                }
            } />
      </div>
    }
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        selectedCustomerDetails:
            state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
        listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list
    };
  };

export default connect(mapStateToProps)(TimerCannotStartPopup);
