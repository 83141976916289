import React, { Component } from "react";
import "../../styles/scss/joinWorkspace.scss";
import crossIcon from "../../styles/img/svg/remove-user-icon.svg";
import PropTypes from "prop-types";
import { getDefaultClientLogo } from "../../helpers/generichelper";
import Avatar from "react-avatar";
import { Tooltip } from "@material-ui/core";

class JoinWorkspaceTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestToJoinId: null
    };
  }

  requestToJoin = () => {
    const { workspace, requestToJoin } = this.props;
    requestToJoin &&
      requestToJoin(workspace, response => {
        if (response) {
          this.setState({
            requestToJoinId: response.id
          });
        }
      });
  };

  render() {
    const { workspace, type, onAcceptRejectInvite } = this.props;
    const { requestToJoinId } = this.state;
    if (type === "requested" || this.state.requestToJoinId) {
      return (
        <div className="join-workspace-list">
          <div className="join-workspace-list-items">
            <div className="join-left-side">
              <div className="join-user-img">
                {workspace.customer_img && workspace.customer_img !== getDefaultClientLogo() ? <img
                  src={workspace.customer_img}
                  alt="Workspace"
                /> : <Avatar
                name={workspace.customer_display_name || workspace.customer_identifier}
                maxInitials={1}
              ></Avatar>}
              </div>
              <div className="join-user-details">
                <p className="user-name name-binding">
                  {workspace.customer_display_name || workspace.customer_identifier}
                </p>
                <p className="user-domain name-binding">
                  {workspace.customer_identifier}
                </p>
              </div>
            </div>
            <div className="join-right-side">
              {(workspace.is_requested ||
                requestToJoinId === workspace.customer_id) && (
                <button className="request-sent">Request sent</button>
              )}
            </div>
          </div>
        </div>
      );
    } else if(type === "invite") {
      return (
        <div className="join-workspace-list">
          <div className="join-workspace-list-items">
            <div className="join-left-side">
              <div className="join-user-img">
                {workspace.img && workspace.img !== getDefaultClientLogo() ? <img
                  src={workspace.img}
                  alt="Workspace"
                /> : <Avatar
                name={workspace.display_name || workspace.name}
                maxInitials={1}
              ></Avatar>}
              </div>
              <div className="join-user-details">
                <p className="user-name name-binding">
                  {workspace.display_name || workspace.name}
                </p>
                <p className="user-domain name-binding">
                  {workspace.name}
                </p>
              </div>
            </div>
            <div className="join-right-side accept-invite">
              <button onClick={() => onAcceptRejectInvite(workspace, "ACCEPT")}>Accept</button>
              <Tooltip title="Remove" placement="top">
              <div className="cross-button" onClick={() => onAcceptRejectInvite(workspace, "REJECT")}>
                <img src={crossIcon} alt="remove" />
              </div>
              </Tooltip>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="join-workspace-list">
          <div className="join-workspace-list-items">
            <div className="join-left-side">
              <div className="join-user-img">
              {workspace.customer_img && workspace.customer_img !== getDefaultClientLogo() ? <img
                  src={workspace.customer_img}
                  alt="Workspace"
                /> : <Avatar
                name={workspace.customer_display_name || workspace.customer_identifier}
                maxInitials={1}
              ></Avatar>}
              </div>
              <div className="join-user-details">
                <p className="user-name name-binding">
                  {workspace.customer_display_name || workspace.customer_identifier}
                </p>
                <p className="user-domain name-binding">
                  {workspace.customer_identifier}
                </p>
              </div>
            </div>
            <div className="join-right-side">
              {!workspace.is_requested &&
                requestToJoinId !== workspace.customer_id && (
                  <button onClick={this.requestToJoin}>Request to join</button>
                )}
              {workspace.is_requested &&
                requestToJoinId === workspace.customer_id && (
                  <button disabled>Request Sent</button>
                )}
            </div>
          </div>
        </div>
      );
    }
  }
}

JoinWorkspaceTile.propTypes = {
  img: PropTypes.string
  // customerName : PropTypes.string.isRequired,
  // customerId : PropTypes.string.isRequired
};

// JoinWorkspaceTile.defaultProps = {
//     img : ""
// }

export default JoinWorkspaceTile;
