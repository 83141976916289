import _ from "lodash";
import { notificationsConstants } from "../../constants/notificationconstants";
const initialState = {
  uploadNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  uploadYourNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  listOfFlaggedYouNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  listOfFlaggedAllNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  replyToNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  listOfGlobalNotification: {
    list: [],
    key: null,
    showMsgForAll: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  listOfSystemNotification: {
    list: [],
    key: null,
    showMsgForAll: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  listOfNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: null
  },
  listOfYourNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: null
  },
  listOfSearchYouNotification: {
    list: [],
    key: null,
    noMoreNotification: false
  },
  listOfSearchAllNotification: {
    list: [],
    key: null,
    noMoreNotification: false
  },
  searchYourNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  searchAllNotificationLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  listOfFlaggedYouNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  listOfFlaggedAllNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  listOfReadYouNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  listOfReadAllNotification: {
    list: [],
    key: null,
    noMoreNotification: false,
    customer_auth_token: localStorage.getItem("customer_auth_token")
  },
  noItem: false
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.getAllNotificationsLoaderState:
      return {
        ...state,
        uploadNotificationLoaderState: action.payload
      };
    case notificationsConstants.getYourNotificationsLoaderState:
      return {
        ...state,
        uploadYourNotificationLoaderState: action.payload
      };
    case notificationsConstants.listOfFlaggedYouNotificationLoaderState:
      return {
        ...state,
        listOfFlaggedYouNotificationLoaderState: action.payload
      };
    case notificationsConstants.listOfFlaggedAllNotificationLoaderState:
      return {
        ...state,
        listOfFlaggedAllNotificationLoaderState: action.payload
      };
    case notificationsConstants.searchYourNotificationsLoaderState:
      return {
        ...state,
        searchYourNotificationLoaderState: action.payload
      };
    case notificationsConstants.searchAllNotificationsLoaderState:
      return {
        ...state,
        searchAllNotificationLoaderState: action.payload
      };
      case notificationsConstants.replyToNotificationLoaderState:
      return {
        ...state,
        replyToNotificationLoaderState: action.payload
      };

    case notificationsConstants.getGlobalNotifications:
      return {
        ...state,
        listOfGlobalNotification: {
          list: [...action.payload.list],
          key: action.payload.key,
          showMsgForAll: checkStatus(action.payload.list),
          customer_auth_token: localStorage.getItem("customer_auth_token")
        }
      };

    case notificationsConstants.getSystemNotifications:
      return {
        ...state,
        listOfSystemNotification: {
          list: [...action.payload.list],
          key: action.payload.key,
          showMsgForAll: checkStatus(action.payload.list),
          customer_auth_token: localStorage.getItem("customer_auth_token")
        }
      };

    case notificationsConstants.archiveSystemNotification:
      return {
        ...state,
        listOfGlobalNotification: {
          list: state.listOfGlobalNotification.list.map(notification =>
            notification.id === action.payload.id
              ? { ...notification, archived_by_users: [...action.payload.list] }
              : notification
          ),
          key: action.payload.key,
          showMsgForAll: checkStatus(action.payload.list)
        }
      };

    case notificationsConstants.getAllNotifications:
      return {
        ...state,
        listOfNotification: {
          list:
            state.listOfNotification.customer_auth_token ===
            action.payload.customer_auth_token
              ? action.payload.list.length > 0 &&
                state.listOfNotification.list.length > 0 &&
                state.listOfNotification.list[0].id !==
                  action.payload.list[0].id
                ? _.uniqBy(
                    [...state.listOfNotification.list, ...action.payload.list],
                    "id"
                  )
                : [...state.listOfNotification.list]
              : [...action.payload.list],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };

    case notificationsConstants.getYourNotifications:
      return {
        ...state,
        listOfYourNotification: {
          list:
            state.listOfYourNotification.customer_auth_token ===
            action.payload.customer_auth_token
              ? action.payload.list.length > 0 &&
                state.listOfYourNotification.list.length > 0 &&
                state.listOfYourNotification.list[0].id !==
                  action.payload.list[0].id
                ? _.uniqBy(
                    [
                      ...state.listOfYourNotification.list,
                      ...action.payload.list
                    ],
                    "id"
                  )
                : [...state.listOfYourNotification.list]
              : [...action.payload.list],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };

    case notificationsConstants.removeNotification:
      return {
        ...state,
        listOfNotification: {
          noMoreNotification: false,
          list: []
        },
        listOfYourNotification: {
          noMoreNotification: false,
          list: []
        },
        listOfSearchYouNotification: {
          noMoreNotification: false,
          list: []
        },
        listOfSearchAllNotification: {
          noMoreNotification: false,
          list: []
        }
      };

    case notificationsConstants.updateNoItemStatus:
      return {
        ...state,
        noItem: action.payload.showStatus
      };

    case notificationsConstants.changeReadStatusOfYouNotification:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }

    case notificationsConstants.changeReadStatusOfAllNotification:
      // view all with no search case
      if (
        action.payload.ddownOption == "View flagged" &&
        action.payload.searchText == false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption == "View all" &&
        action.payload.searchText == false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        // console.log("met condition");
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          },
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_read: action.payload.is_read }
                : notification
            )
          }
        };
      }

    case notificationsConstants.changeFlagStatusOfYouNotification:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification => {
              if (notification.id === action.payload.id) {
                notification.is_flagged = false;
                return notification;
              }
              return notification;
            })
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification => {
              if (notification.id === action.payload.id) {
                notification.is_flagged = false;
                return notification;
              }
              return notification;
            })
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }

    case notificationsConstants.changeFlagStatusOfAllNotification:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification => {
              if (notification.id === action.payload.id) {
                notification.is_flagged = false;
                return notification;
              } else {
                return notification;
              }
            })
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        // console.log("met condition");
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.filter(
              notification => notification.id !== action.payload.id
            )
          },
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification => {
              if (notification.id === action.payload.id) {
                notification.is_flagged = false;
                return notification;
              }
              return notification;
            })
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? { ...notification, is_flagged: action.payload.is_flagged }
                : notification
            )
          }
        };
      }

    case notificationsConstants.markAllNotificationAsReadOfYouTab:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false
      ) {
        return {
          ...state,
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      break;

    case notificationsConstants.markAllNotificationAsReadOfAllTab:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false
      ) {
        return {
          ...state,
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view all with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true
      ) {
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, is_read: true }
                : notification
            )
          }
        };
      }
      break;

    case notificationsConstants.changeNewStatusOfSystemNotification:
      return {
        ...state,
        listOfSystemNotification: {
          ...state.listOfSystemNotification,
          list: state.listOfSystemNotification.list.map(notification => {
            notification = { ...notification, is_new: false };
            return notification;
          })
        }
      }

    case notificationsConstants.archiveRequestSystemNotification:
      return {
        ...state,
        listOfSystemNotification: {
          ...state.listOfSystemNotification,
          list: []
        }
      }
    case notificationsConstants.changeNewStatusOfNotification:
      return {
        ...state,
        listOfNotification: {
          ...state.listOfNotification,
          list: state.listOfNotification.list.map(notification => {
            notification = { ...notification, is_new: false };
            return notification;
          })
        },
        listOfYourNotification: {
          ...state.listOfYourNotification,
          list: state.listOfYourNotification.list.map(notification => {
            notification = { ...notification, is_new: false };
            return notification;
          })
        }
      };

    case notificationsConstants.replyInYouNotification:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view all case with search
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedYouNotification: {
            ...state.listOfFlaggedYouNotification,
            list: state.listOfFlaggedYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadYouNotification: {
            ...state.listOfReadYouNotification,
            list: state.listOfReadYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfSearchYouNotification: {
            ...state.listOfSearchYouNotification,
            list: state.listOfSearchYouNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfYourNotification: {
            ...state.listOfYourNotification,
            list: state.listOfYourNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }

    case notificationsConstants.replyInAllNotification:
      // view all with no search case
      if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view flagged with no search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view all case with search
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view flagged with search case
      else if (
        action.payload.ddownOption === "View all" &&
        action.payload.searchText === true &&
        action.payload.ddownOption2 === "View read"
      ) {
        return {
          ...state,
          listOfFlaggedAllNotification: {
            ...state.listOfFlaggedAllNotification,
            list: state.listOfFlaggedAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view read with no search case
      else if (
        action.payload.ddownOption === "View flagged" &&
        action.payload.searchText === false &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }
      // view read with search case
      else if (
        action.payload.ddownOption == "View flagged" &&
        action.payload.searchText == true &&
        action.payload.ddownOption2 === "View unread"
      ) {
        return {
          ...state,
          listOfReadAllNotification: {
            ...state.listOfReadAllNotification,
            list: state.listOfReadAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfSearchAllNotification: {
            ...state.listOfSearchAllNotification,
            list: state.listOfSearchAllNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          },
          listOfNotification: {
            ...state.listOfNotification,
            list: state.listOfNotification.list.map(notification =>
              notification.id === action.payload.id
                ? {
                    ...notification,
                    is_read: action.payload.is_read,
                    data: {
                      ...notification.data,
                      replies: [
                        ...notification.data.replies,
                        action.payload.reply
                      ]
                    }
                  }
                : notification
            )
          }
        };
      }

    case notificationsConstants.searchYouNotification:
      return {
        ...state,
        listOfSearchYouNotification: {
          list:
            action.payload.searchtext ===
            state.listOfSearchYouNotification.searchText
              ? _.uniqBy([
                  ...state.listOfSearchYouNotification.list,
                  ...action.payload.list
                ], "id")
              : [...action.payload.list],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          searchText: action.payload.searchtext
        }
      };
    case notificationsConstants.searchAllNotification:
      return {
        ...state,
        listOfSearchAllNotification: {
          list:
            action.payload.searchtext ===
            state.listOfSearchAllNotification.searchText
              ? _.uniqBy([
                  ...state.listOfSearchAllNotification.list,
                  ...action.payload.list
                ], "id")
              : [...action.payload.list],
          key: action.payload.key,
          searchText: action.payload.searchtext,
          noMoreNotification: action.payload.list.length < 10 ? true : false
        }
      };

    case notificationsConstants.pushNewAllNotification:
      const theRealNewOneAll = _.unionWith(
        state.listOfNotification.list,
        action.payload.notification,
        _.isEqual
      );
      return {
        ...state,
        listOfNotification: {
          ...state.listOfNotification,
          list: _.uniqBy(theRealNewOneAll, "id")
        }
      };

    case notificationsConstants.pushNewYouNotification:
      const theRealNewOneYou = _.unionWith(
        state.listOfYourNotification.list,
        action.payload.notification,
        _.isEqual
      );
      return {
        ...state,
        listOfYourNotification: {
          ...state.listOfYourNotification,
          list: _.uniqBy(theRealNewOneYou, "id")
        }
      };
    // *****************
    case notificationsConstants.flaggedNotificationOfYouTab:
      return {
        ...state,
        listOfFlaggedYouNotification: {
          list:
            action.payload.page === 1
              ? [...action.payload.list]
              : [
                  ...state.listOfFlaggedYouNotification.list,
                  ...action.payload.list
                ],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };

    case notificationsConstants.flaggedNotificationOfAllTab:
      // console.log("from REDUCER, pageno", action.payload.list);
      return {
        ...state,
        listOfFlaggedAllNotification: {
          list:
            action.payload.page === 1
              ? [...action.payload.list]
              : [
                  ...state.listOfFlaggedAllNotification.list,
                  ...action.payload.list
                ],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };

      case notificationsConstants.readNotificationOfYouTab:
      return {
        ...state,
        listOfReadYouNotification: {
          list:
            action.payload.page === 1
              ? [...action.payload.list]
              : [
                  ...state.listOfReadYouNotification.list,
                  ...action.payload.list
                ],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };

    case notificationsConstants.readNotificationOfAllTab:
      // console.log("from REDUCER, pageno", action.payload.list);
      return {
        ...state,
        listOfReadAllNotification: {
          list:
            action.payload.page == 1
              ? [...action.payload.list]
              : [
                  ...state.listOfReadAllNotification.list,
                  ...action.payload.list
                ],
          key: action.payload.key,
          noMoreNotification: action.payload.list.length < 10 ? true : false,
          customer_auth_token: action.payload.customer_auth_token
        }
      };
      
      case notificationsConstants.updateNotificationFlag:
        return{
          ...state,
          listOfFlaggedAllNotification:{
            ...state.listOfFlaggedAllNotification,
            noMoreNotification:false
          },
          listOfFlaggedYouNotification:{
            ...state.listOfFlaggedYouNotification,
            noMoreNotification:false
          }
        }

      case notificationsConstants.updateNotificationRead:
        return{
          ...state,
          listOfReadAllNotification:{
            ...state.listOfReadAllNotification,
            noMoreNotification:false
          },
          listOfReadYouNotification:{
            ...state.listOfReadYouNotification,
            noMoreNotification:false
          }
        }

        case notificationsConstants.updateNotificationSearchFlag:
          return{
            ...state,
            listOfSearchYouNotification:{
              ...state.listOfSearchYouNotification,
              noMoreNotification:false
            },
            listOfSearchAllNotification:{
              ...state.listOfSearchAllNotification,
              noMoreNotification:false
            }
          }
    // ***************
    default:
      return {
        ...state
      };
  }
}
const checkStatus = list => {
  if (list.length === 0) {
    return true;
  } else {
    return false;
  }
};
// listOfYourNotification: {list: (state.listOfYourNotification.customer_auth_token ===
//     action.payload.customer_auth_token)
//       ? (action.payload.list.length > 0 &&
//         state.listOfYourNotification.list.length > 0 &&
//         state.listOfYourNotification.list[0].id !==
//           action.payload.list[0].id
//         ? [...state.listOfYourNotification.list, ...action.payload.list]
//         : [...state.listOfYourNotification.list])
//       : [...action.payload.list],
//   key: action.payload.key,
//   noMoreNotification: action.payload.list.length < 10 ? true : false,
//   customer_auth_token: action.payload.customer_auth_token
// }
