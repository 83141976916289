import React, { Component } from "react";
import _ from "lodash";
import { convertTimeToString, secondsToHms } from "../../helpers/generichelper";

import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "../../firebase";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import closeIcon from "../../styles/img/svg/Close_White.svg";
import OutsideClickHandler from "react-outside-click-handler";
import AttachmentDropdown from "../attachments/attachmentDropdown";
import {
  getTimerStatusForWorkspaceAction
} from "../../actions/workspace/workspaceaction";
import DialogWrapper from "../common/dialog";
import AttachmentErrPop from "./attachmentErrPop";
import routesHelper from "../../router/routeshelper";

const styles = _theme => ({
  input: {
    height: 45
  },
  label: {
    transform: "translate(14px, 15px) scale(1)"
  },
  overviewInput: {
    minHeight: 80
  }
});

class TimesheetDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTypeDropDownUI: false,
      tags: [],
      activityTagFieldValue: "",
      description: "",
      openAttachmentDropdown: false,
      attachmentFrom: "",
      displayErrMsg:false,
      attachmentOneDrive: false,
      attachmentsToAdd: [],
      attachmentsFile: [],
      showAttachmentErrPop: false,
      isRecurring: false,
      descriptionInputTouched: false,
      hoursFromYesterday: 0,
      minsFromYesterday: 0,
      hoursOfToday: 0,
      minsOfToday: 0,
      clickedImg: null,
      fetching: false
    };
    this.onStopTimer = this.onStopTimer.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onStopTimer = (e) => {
    e.preventDefault();
    const {
      // timesheet,
      anyTimerStatus: { timesheet: myTimesheet },
      stopTimerAction,
      shouldEnablePopup,
      getTimerStatusForWorkspace,
      createTimerAction,
      // changeTimerStatus
    } = this.props;
    
    const currentTime = moment(new Date()).format();
    const stopTime = moment(new Date());
    const startTime = moment(myTimesheet.data.TIME[0].START);
    const customer_auth_token = localStorage.getItem("customer_auth_token");

    // Multipart/form-data attachments start
    const { attachmentsFile } = this.state;
    let formData;
    if(attachmentsFile.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else
          formData.append(`attachment${index + 1}`, JSON.stringify(file));
      })
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "timesheet");
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end

    this.setState({ fetching: true });

    if (!moment().isSame(myTimesheet.data.TIME[0].START, "day")) {
      const description = this.state.description;
      
      const totalSecondYesterday =
        this.state.hoursFromYesterday * 60 * 60 +
        this.state.minsFromYesterday * 60;
      const dataYesterday = {
        TIME: [
          {
            START: myTimesheet.data.TIME[0].START,
            STOP: moment(myTimesheet.data.TIME[0].START)
              .endOf("day")
              .format(),
            DESCRIPTION: description,
            INTERVAL: totalSecondYesterday
          }
        ]
      };

      const totalSecondsToday =
        this.state.hoursOfToday * 60 * 60 + this.state.minsOfToday * 60;

      const dataToday = {
        TIME: [
          {
            START: moment(new Date())
              .startOf("day")
              .format(),
            STOP: moment(new Date()).format(),
            DESCRIPTION: description,
            INTERVAL: totalSecondsToday
          }
        ]
      };

      stopTimerAction(
        {
          tags: [this.state.activityTagFieldValue],
          // attachments: this.state.attachmentsToAdd,

          activity_id: myTimesheet.activity_id,
          id: myTimesheet.id,
          data: dataYesterday,
          time_taken: totalSecondYesterday,
          description,
          user_id: myTimesheet.user_id,
          customer_auth_token,
          date: moment(myTimesheet.data.TIME[0].START).endOf("day").format()
        },
        formData,
        (response, attRes) => {
          if (response && !response.stdErr) {
            this.props.changeTimerStatus(false);
            getTimerStatusForWorkspace({ auth_token: routesHelper.getAuthToken() }, res => {
              if(res && !this.state.hoursOfToday && !this.state.minsOfToday) {
                if(!attRes?.stdErr) shouldEnablePopup(false);
                this.props.fetchTimesheetAction(
                  {
                    from_date: moment()
                      .startOf("day")
                      .format("YYYY-MM-DD"),
                    to_date: moment().format("YYYY-MM-DD"),
                    user_id: myTimesheet.user_id
                  });
              }
            });
            if(attRes?.stdErr) {
              this.setState({ showAttachmentErrPop: true });
            }
          }
        }
      );
      if(this.state.hoursOfToday || this.state.minsOfToday) {
        createTimerAction(
          {
            tags: [this.state.activityTagFieldValue],
            // attachments: this.state.attachmentsToAdd,
  
            activity_id: myTimesheet.activity_id,
            data: dataToday,
            time_taken: totalSecondsToday,
            description,
            date: currentTime,
            user_id: myTimesheet.user_id,
            customer_auth_token
          },
          formData,
          (response, attRes) => {
            if (response && !response.stdErr) {
              firebase.analytics().logEvent("GTD_Activity_description");
              if(!attRes?.stdErr) shouldEnablePopup(false);
              this.props.changeTimerStatus(false);
              this.props.fetchTimesheetAction(
                {
                  from_date: moment()
                    .startOf("day")
                    .format("YYYY-MM-DD"),
                  to_date: moment().format("YYYY-MM-DD"),
                  user_id: myTimesheet.user_id
                });
              if(attRes?.stdErr) {
                this.setState({ showAttachmentErrPop: true });
              }
            }
          }
        );
      }
    } else {
      const interval = parseInt(stopTime.diff(startTime) / 1000);
      const tags = [this.state.activityTagFieldValue];
      const description = this.state.description;
      
      const timesheetId = myTimesheet.id;
      const data = {
        TIME: [
          {
            START: myTimesheet.data.TIME[0].START,
            STOP: currentTime,
            DESCRIPTION: description,
            INTERVAL: interval
          }
        ]
      };

      stopTimerAction(
        {
          activity_id: myTimesheet.activity_id,
          data,
          time_taken: interval,
          tags,
          description,
          // attachments,
          id: timesheetId,
          date: currentTime,
          user_id: myTimesheet.user_id,
          customer_auth_token
        },
        formData,
        (response, attRes) => {
          if (response && !response.stdErr) {
            firebase.analytics().logEvent("GTD_Activity_description");
            this.props.changeTimerStatus(false);
            getTimerStatusForWorkspace({ auth_token: routesHelper.getAuthToken() }, res => {
              if(res)
                if(!attRes?.stdErr) shouldEnablePopup(false);
            });
            this.props.fetchTimesheetAction(
              {
                from_date: moment()
                  .startOf("day")
                  .format("YYYY-MM-DD"),
                to_date: moment().format("YYYY-MM-DD"),
                user_id: myTimesheet.user_id
              });
            if(attRes?.stdErr) {
              this.setState({ showAttachmentErrPop: true });
            }
          }
        }
      );
    }
  };

  UNSAFE_componentWillMount() {
    const {
      // timesheet,
      anyTimerStatus: { timesheet: myTimesheet }
    } = this.props;

    const diffFromYesterdayEnd = secondsToHms(
      moment(myTimesheet.data.TIME[0].START)
        .endOf("day")
        .diff(moment(myTimesheet.data.TIME[0].START), "seconds")
    );
    const diffFromTodayStart = secondsToHms(
      moment(new Date())
        .startOf("day")
        .diff(moment(new Date()), "seconds") * -1
    );

    this.setState({
      hoursFromYesterday: diffFromYesterdayEnd.hours,
      minsFromYesterday: diffFromYesterdayEnd.mins,
      hoursOfToday: diffFromTodayStart.hours,
      minsOfToday: diffFromTodayStart.mins
    });
  }

  componentDidUpdate() {
    const innerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].childNodes[0].clientHeight;
    const outerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].clientHeight
    // console.log(outerHeight, innerHeight)
    if(innerHeight > outerHeight) {
      document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "auto";
    }
    // else if(innerHeight < outerHeight) {
    //   document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "100%";
    // }
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.value.trim().length > 0) {
      this.setState({
        descriptionInputTouched: true
      });
    } else {
      this.setState({
        descriptionInputTouched: false
      });
    }
  }

  onFocusDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "#0090e9";
    document.getElementsByClassName("completed-description")[0].classList.add("textfield-focused");
  }

  onBlurDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "rgba(0, 0, 0, 0.23)";
    document.getElementsByClassName("completed-description")[0].classList.remove("textfield-focused");
  }

  changehoursOfToday = (event) => {
    this.setState({
      hoursOfToday: event.target.value
    });
  };

  changeminsOfToday = (event) => {
    this.setState({
      minsOfToday: event.target.value
    });
  };

  changehoursFromYesterday = (event) => {
    if (event.target.value > 23) {
      return this.setState({
        hoursFromYesterday: 23
      });
    }
    this.setState({
      hoursFromYesterday: event.target.value
    });
  };

  changeminsFromYesterday = (event) => {
    if (event.target.value > 59) {
      return this.setState({
        minsFromYesterday: 59
      });
    }
    this.setState({
      minsFromYesterday: event.target.value
    });
  };

  isValidTime = () => {
    const {
      anyTimerStatus: { timesheet: myTimesheet }
    } = this.props;
    if (myTimesheet && !moment().isSame(myTimesheet.data.TIME[0].START, "day")) {
      const validYesterdayTme = this.state.hoursFromYesterday || this.state.minsFromYesterday ? true : false;
      return validYesterdayTme;
    }
    return true;
  }

  renderRecurringDayForm = () => {
    const {
      // timesheet,
      anyTimerStatus: { timesheet: myTimesheet }
    } = this.props;

    if (myTimesheet && !moment(new Date()).isSame(myTimesheet.data.TIME[0].START, "day")) {
      return (
        <div className="split-time-container">
          <p className="timer-info-msg">
            Forgot to stop the timer? Add your time manually for the duration
            you worked.
          </p>
          <div className="time-spent-yest first-row-description">
            <span className="time-spent-on ng-binding">
              {" "}
              Time spent on{" "}
              {moment(myTimesheet.data.TIME[0].START).format("ddd, DD MMM YY")}
            </span>
            {/* <span className="time-delay-container">
              <span>
                <input
                  type="number"
                  min="0"
                  name="hoursFromYesterday"
                  max="999"
                  value={this.state.hoursFromYesterday}
                  onChange={this.changehoursFromYesterday}
                  aria-invalid="false"
                />
                h:
              </span>
              <span>
                <input
                  type="number"
                  min="0"
                  name="minsFromYesterday"
                  value={this.state.minsFromYesterday}
                  max="60"
                  aria-invalid="false"
                  onChange={this.changeminsFromYesterday}
                />
                m
              </span>
            </span> */}
            <div className="act-time-inner-holder">
              <div className="rd-hours-area">
                <input
                  type="number"
                  placeholder="hh"
                  min="0"
                  name="hoursFromYesterday"
                  max="999"
                  value={this.state.hoursFromYesterday}
                  onChange={this.changehoursFromYesterday}
                  aria-invalid="false"
                />
              </div>
              :
              <div className="rd-hours-area">
                <input
                  type="number"
                  placeholder="mm"
                  min="0"
                  name="minsFromYesterday"
                  value={this.state.minsFromYesterday}
                  max="60"
                  aria-invalid="false"
                  onChange={this.changeminsFromYesterday}
                />
              </div>
            </div>
          </div>
          <div className="time-spent-yest">
            <span className="time-spent-on ng-binding">
              {" "}
              Time spent on {moment(new Date()).format("ddd, DD MMM YY")}
            </span>
            {/* <span className="time-delay-container">
              <span>
                <input
                  type="number"
                  min="0"
                  name="hoursOfToday"
                  value={this.state.hoursOfToday}
                  max="999"
                  aria-invalid="false"
                  onChange={this.changehoursOfToday}
                />
                h:
              </span>
              <span>
                <input
                  type="number"
                  min="0"
                  name="minsOfToday"
                  max="60"
                  value={this.state.minsOfToday}
                  aria-invalid="false"
                  onChange={this.changeminsOfToday}
                />
                m
              </span>
            </span> */}
            <div className="act-time-inner-holder">
              <div className="rd-hours-area">
                <input
                  type="number"
                  placeholder="hh"
                  min="0"
                  name="hoursOfToday"
                  value={this.state.hoursOfToday}
                  max="999"
                  aria-invalid="false"
                  onChange={this.changehoursOfToday}
                />
              </div>
              :
              <div className="rd-hours-area">
                <input
                  type="number"
                  placeholder="mm"
                  min="0"
                  name="minsOfToday"
                  max="60"
                  value={this.state.minsOfToday}
                  aria-invalid="false"
                  onChange={this.changeminsOfToday}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="display-none"></div>;
    }
  };

  renderTimsheetHistory = () => {
    const { timesheet } = this.props;
    const currentUserId = localStorage.getItem("id");
    
    let todaysTimeSheetHistory = null;
    if (timesheet && timesheet.length) {
      todaysTimeSheetHistory = timesheet
        .filter(timesheet => !timesheet.is_archived && timesheet.description)
        .filter((ts) => {
          return (
            ts.user_id === parseInt(currentUserId) &&
            _.has(ts.data.TIME[0], "STOP") &&
            moment(ts.data.TIME[0].STOP).isSame(new Date(), "d")
          );
        })
        .map((tsd) => {
          const timeTakenString = convertTimeToString(tsd.time_taken);
          return (
            <div className="description-details-container" key={tsd.id}>
              <Tooltip title={tsd.description}>
                <li className="description-content ">
                  <span className="description-dots"></span>
                  {tsd.description}
                </li>
              </Tooltip>
              <span className="description-timer ">{timeTakenString}</span>
            </div>
          );
        });
    }
    if (todaysTimeSheetHistory.length > 0) {
      return (
        <React.Fragment>
          <span>
            {"Today’s entries"} {"-"}{" "}
            {moment(new Date()).format("ddd, D MMM YYYY")}
          </span>

          <div className="previous-details ng-scope layout-column">
            {todaysTimeSheetHistory.length > 0 && todaysTimeSheetHistory}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span>
            {"No entries found "} {"-"}{" "}
            {moment(new Date()).format("ddd, D MMM YYYY")}
          </span>

          {/* <div className="previous-details ng-scope layout-column">
            {todaysTimeSheetHistory.length > 0 && todaysTimeSheetHistory}
          </div> */}
        </React.Fragment>
      );
    }
  };

  showActivityTypeDropdown = () => {
    this.setState({
      showTypeDropDownUI: true
    });
  };

  onChangeTypeField = e => {
    this.setState({
      activityTagFieldValue: e.target.value
    });
    if (e.target.value.length > 0) {
      this.setState({
        showTypeDropDownUI: true
      });
    }
  };

  onTypeOptionSelected = tag => {
    this.setState({
      showTypeDropDownUI: false,
      activityTagFieldValue: tag
    });
  };

  onOutsideActType = () => {
    this.setState({
      showTypeDropDownUI: false
    });
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  openAttachmentDropdown = () => {
    this.setState({
      openAttachmentDropdown: !this.state.openAttachmentDropdown
    }, () => {
      const inputBox = document.querySelector(".attach-files-dropdown-container");
    if (inputBox && this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    });
  };

  chkMaxSize = (size) => {
    return size > 30000000;
  };

  handleMaxSize = (size, _type) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (sizeOfFile) {
      this.setState({
        displayErrMsg: true
      }, () => {
        setTimeout(() => {
          this.setState({ displayErrMsg: false })
        }, 5000)
      });
    } else {
      this.setState({
        displayErrMsg: false
      });
    }
  };

  handleAttachFrom = (from) => {
    this.setState({
      attachmentFrom: from
    });
  };

  closeAttachmentDropdown = (response, attachments) => {
    
    if (response && response.length > 0 && (response[0].src || response[0].url || response[0].type)) {
  
    this.setState(
        {
          attachmentFrom: "",
          openAttachmentDropdown: false,
          attachmentsToAdd: [ ...this.state.attachmentsToAdd, ...response ],
          attachmentsFile: [ ...this.state.attachmentsFile, ...attachments]
        });
    } else if (response && response.url) {
      this.setState(
        {
          attachmentFrom: "",
          openAttachmentDropdown: false,
          attachmentsToAdd: [ ...this.state.attachmentsToAdd, response ],
          attachmentsFile: [ ...this.state.attachmentsFile, response]
        });
    } else {
      if(!this.state.attachmentOneDrive){
        this.setState({
          attachmentFrom: "",
          openAttachmentDropdown: false
        });
      }
    }
  };

  isImage = (url) => {
    
    if(url.indexOf(";base64") > -1) {
      return url.indexOf("image") > -1;
    } else {
      return false;
    }
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  onRemoveAttachment = (attachment) => {
    this.setState({ attachmentsToAdd: this.state.attachmentsToAdd.filter(att => {
      if(att.src && attachment.src)
        return att.src !== attachment.src
      else if(att.url && attachment.url)
        return att.url !== attachment.url
      else if(att.size && att.name) 
        return (att.size !== attachment.size && att.name !== attachment.name)
      else return true
    }
      ),
      attachmentsFile: this.state.attachmentsFile.filter(file => {
        if(file.size && file.name) 
          return (file.size !== attachment.size && file.name !== attachment.name)
        else if(attachment.url && file.url)
          return file.url !== attachment.url
        else return true;
      })
    });
  }

  updateAttchmetnOneDrive=(flag)=>{
    this.setState({attachmentOneDrive:flag})
  }

  onClickImg = (url) => {
    this.setState({ clickedImg: url });
    document
      .querySelector('[role="dialog"]')
      .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    document
      .querySelector('[role="dialog"]')
      .removeAttribute("id", "noscrolldialog");
  };

  onCloseAttachmentErrPop = () => {
    this.props.shouldEnablePopup(false);
    this.setState({ showAttachmentErrPop: false });
  }

  render() {
    const { classes } = this.props;
    const { showTypeDropDownUI, activityTagFieldValue, clickedImg, showAttachmentErrPop } = this.state;

    const tags = this.props.projectList
    .filter(proj => proj.id === this.props.selectedProject)
    .map(proj => proj?.data?.settings?.activity_type &&
      _.sortBy(proj.data.settings.activity_type))[0];

    const renderActivityTypes =
      tags &&
      tags
        .filter(
          tag =>
            tag.toLowerCase().indexOf(activityTagFieldValue.toLowerCase()) > -1
        )
        .map(tag => {
          return (
            <li
              key={`${tag}`}
              className="type-li"
              onClick={() => this.onTypeOptionSelected(tag)}
            >
              <span class="type-li-span">{tag}</span>
            </li>
          );
        });

    let dialogPropsAttErr = {
      className: "dialog_wrapper",
      showPopup: showAttachmentErrPop,
      dialogTitle: () => `Attachment Error`,
        // onCloseCallBack: this.onCloseGooglePopup,
        shouldButtonEnable: true
    };
    
    return (
      <div>
        <div className="previous">{this.renderTimsheetHistory()}</div>

        <div className="timesheet-description-dialog">
          <div className="re-control-holder">
              <div className="cus-autocomp-holder">
                <OutsideClickHandler onOutsideClick={this.onOutsideActType}>
                <div className="cus-autocomp-inner-holder">
                  <TextField
                    type="text"
                    label="Activity Type"
                    placeholder="Search an Activity Type or add a custom one for this Activity"
                    variant="outlined"
                    className="description-box activity-act-type"
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    value={activityTagFieldValue}
                    onClick={this.showActivityTypeDropdown}
                    // className="cus-autocomp-control"
                    autoComplete="off"
                    fullWidth={true}
                    model=".project"
                    onChange={this.onChangeTypeField}
                    innerRef={node => (this.projectNameNode = node)}
                    onFocus={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.add("textfield-focused");
                    }}
                    onBlur={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.remove("textfield-focused");
                    }}
                  />
                  {showTypeDropDownUI && (
                    <div className="edit-board-ddown activity-type-down">
                      <div className="activityTagsInput">
                        <ul className="activity-tags-ui">
                          {renderActivityTypes}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                </OutsideClickHandler>
              </div>
            </div>
            <div className="re-control-holder first">
            <TextField
              id="outlined-name"
              label="Description"
              placeholder="What did you do?"
              variant="outlined"
              model=".title"
              InputProps={{
                className: classes.overviewInput
              }}
              className={`description-box completed-description ${this.state.attachmentsToAdd.length > 0 ? "with-attachment" : ""}`}
              name="description"
              onKeyPress={(e) => {
                if (this.state.description.length > 0 && activityTagFieldValue && e.key === "Enter") {
                  e.preventDefault();
                  this.onStopTimer(e);
                } else if (
                  this.state.description.length <= 0 &&
                  e.key === "Enter"
                ) {
                  e.preventDefault();
                  this.setState({
                    description: ""
                  });
                } else {
                  return;
                }
              }}
              onFocus={this.onFocusDescription}
              onBlur={this.onBlurDescription}
              value={this.state.description}
              onChange={this.onInputChange}
              fullWidth={true}
              color="#0090e9"
              multiline
              rows="2"
              size='medium'
              rowsMax="200"
            />
                  {this.state.attachmentsToAdd.length > 0 && 
            <div className="comm-att-holder add-comp-act">
                    <React.Fragment>
                      {this.state.attachmentsToAdd.map((attachment, index) => {
                        const attachmentURL = (attachment.src || attachment.url || attachment.type);
                        const isImage = attachment.src ? true : false;
                        const isCloud = attachment.url ? true : false;
                        const isDocument = attachment.type ? true : false;
                        return <div
                        className="nw-att-row-container"
                        key={index}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              isImage
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {isImage && (
                              <img
                                src={decodeURI(attachmentURL)}
                                alt="attachment"
                                onClick={() => this.onClickImg(decodeURI(attachmentURL))}
                              />
                            )}
                            {isCloud &&
                              attachmentURL.indexOf(".google.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {isCloud &&
                              attachmentURL.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {isCloud &&
                              attachmentURL.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {isDocument &&
                              // attachmentURL.indexOf(".google.com") < 0 &&
                              // attachmentURL.indexOf("www.dropbox.com") < 0 &&
                              // attachmentURL.indexOf("https://1drv.ms/") < 0 && 
                              (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={isImage ? { cursor: "pointer" } : {}}
                                onClick={() => isImage && this.onClickImg(decodeURI(attachmentURL))}
                              >
                                {attachment.title || attachment.name}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              // href={decodeURI(attachment.url)}
                              // download={attachment.title}
                              // target="_blank"
                              onClick={() => this.onRemoveAttachment(attachment)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                </div>
                  }
            </div>
            <div className="act-att-holder">
            <div className="comment-attachment-area">
                  <OutsideClickHandler
                    onOutsideClick={this.closeAttachmentDropdown}
                  >
                      <div
                        className="comp-act-att"
                        onClick={this.openAttachmentDropdown}
                      >Add Attachment</div>
                    {this.state.openAttachmentDropdown && (
                      <AttachmentDropdown
                        closeAttachmentDropdown={this.closeAttachmentDropdown}
                        // activityDetails={activityDetails}
                        handleMaxSize={this.handleMaxSize}
                        handleAttachFrom={this.handleAttachFrom}
                        attachedFrom={"completedActivity"}
                        // is_priority={is_priority}
                        // updateFetch={this.props.updateFetch}
                        // is_archived={is_archived}
                        updateAttchmetnOneDrive={this.updateAttchmetnOneDrive}
                      />
                    )}
                  </OutsideClickHandler>
                </div>
            </div>
            {
            this.state.displayErrMsg && 
            (
                      <div class="new-comm-attacherror add-act">
                        The file size exceeds 30 MB{" "}
                      </div>
                    )}
          
        </div>
        <div className="save-loader">
        {this.state.fetching && <CircularProgress
                        classes={{ root: "circular-progress" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                      ></CircularProgress>}
          <button
            className={
              this.state.descriptionInputTouched && activityTagFieldValue && this.isValidTime()
                ? this.state.fetching ? "description-save-btn disabled" : "description-save-btn"
                : "description-save-btn disabled"
            }
            disabled={(!this.state.descriptionInputTouched && !activityTagFieldValue && !this.isValidTime()) || this.state.fetching}
            onClick={this.onStopTimer}
          >
            Save
          </button>
        </div>
        {clickedImg && (
            <div className="attachmentpopviews">
              <img src={clickedImg} alt="attachment" className="attachmentimage" />
              <img
                className="closeattachmentpopview"
                src={closeIcon}
                alt="close"
                onClick={this.onClosePreview}
              />
            </div>
          )}
          {showAttachmentErrPop && (
            <DialogWrapper dialogProps={dialogPropsAttErr}>
              <AttachmentErrPop
                onClosePopup={this.onCloseAttachmentErrPop} />
            </DialogWrapper>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.projectReducer.listOfProjects.list,
    anyTimerStatus: state.commonReducer.timerStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTimerStatusForWorkspace({ auth_token }, callback) {
      dispatch(
        getTimerStatusForWorkspaceAction({ auth_token }, callback)
      );
    }
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TimesheetDescription));
