import React, { useState, memo } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import moment from "moment";
import _ from "lodash";
import DateRangePicker from "../common/dateRangePicker";
import sortArrow from "../../styles/img/svg/sort-arrow.svg";
import { time_convert } from "../../helpers/generichelper";

const EffortOverviewPersonal = ({ effortOverview, startDate, endDate, onChangeEffortOverviewDate }) => {
  const {
    actual_hrs_available,
    actual_hrs_worked,
    avg_monthly_worked,
    avg_weekly_worked,
    avg_daily_worked,
    activity_wise_efforts,
    project_wise_efforts
  } = effortOverview;

  // const [startDate, setStartDate] = useState(
  //   moment()
  //     .subtract(1, "month")
  //     .subtract(1, "day")
  //     .format("ddd, DD MMM YY")
  // );
  // const [endDate, setEndDate] = useState(
  //   moment()
  //     .subtract(1, "day")
  //     .format("ddd, DD MMM YY")
  // );
  const [sortIn, setSortIn] = useState(false);
  const [activeTab, setActiveTab] = useState("activity");

  const onChangeDate = (event, picker) => {
    const from = moment(picker.startDate._d).format("ddd, DD MMM YY");
    const to = moment(picker.endDate._d).format("ddd, DD MMM YY");
    // setStartDate(from);
    // setEndDate(to);
    onChangeEffortOverviewDate(from, to);
  };

  const renderEffort = wiseEfforts => {
    let sortedWiseEfforts;
    if (sortIn)
      sortedWiseEfforts = wiseEfforts.sort(
        (a, b) => a.time_taken - b.time_taken
      );
    else
      sortedWiseEfforts = wiseEfforts.sort(
        (a, b) => b.time_taken - a.time_taken
      );
    return sortedWiseEfforts.map(entry => {
      let key = entry.activity_type || entry.project;
      let value = entry.time_taken;
      return (
        <div className="effort-name personal">
          {key} - <span>{time_convert(value).hours}h {time_convert(value).minutes}m</span>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="over-upper personal">
        <div className="title-date-holder">
          <div className="over-title">Effort Overview</div>
          <div className="date-range-picker">
            <div className="date-range">
              <DateRangePicker
                startDate={moment(new Date(startDate)).format("M/DD/YYYY")}
                endDate={moment(new Date(endDate)).format("M/DD/YYYY")}
                maxDate={moment(new Date()).format("M/DD/YYYY")}
                onChangeDate={onChangeDate}
                source={"DASHBOARD"}
              >
                <div className="date-img date-picker">{`${startDate} - ${endDate}`}</div>
              </DateRangePicker>
            </div>
          </div>
        </div>
        <div className="bill-non-bill-holder">
          <div className="billable extra-margin">
            <div className="hrs">{time_convert(actual_hrs_available).hours}h {time_convert(actual_hrs_available).minutes}m</div>
            <div className="bill">Expected</div>
          </div>
          <div className="billable">
            <div className="hrs">{time_convert(actual_hrs_worked).hours}h {time_convert(actual_hrs_worked).minutes}m</div>
            <div className="bill">Spent</div>
          </div>
        </div>
        <div className="bill-non-bill-holder">
          <div className="billable">
            <div className="hrs">
              {typeof avg_monthly_worked === "number" ? 
                `${time_convert(avg_monthly_worked).hours}h ${time_convert(avg_monthly_worked).minutes}m`
                : avg_monthly_worked
              }
            </div>
            <div className="bill">Monthly average</div>
          </div>
          <div className="billable">
            <div className="hrs">
              {typeof avg_weekly_worked === "number" ? 
                `${time_convert(avg_weekly_worked).hours}h ${time_convert(avg_weekly_worked).minutes}m`
                : avg_weekly_worked
              }
            </div>
            <div className="bill">Weekly average</div>
          </div>
          <div className="billable no-margin">
            <div className="hrs">{time_convert(avg_daily_worked).hours}h {time_convert(avg_daily_worked).minutes}m</div>
            <div className="bill">Daily average</div>
          </div>
        </div>
      </div>
      <div className="hr-line"></div>
      <div className="over-upper over-lower">
        <div className="title-date-holder">
          <div
            className="over-title"
            role="button"
            onClick={() => setSortIn(!sortIn)}
          >
            Effort spent by
            <span className="arrow-holder">
              <img
                className={`sort-img ${sortIn ? "" : "reverse"}`}
                src={sortArrow}
                alt="sort-arrow"
              />
            </span>
          </div>
          <div className="team-proj-tab-holder">
            <span
              className={`team-tab ${activeTab === "activity" ? "active" : ""}`}
              onClick={() => setActiveTab("activity")}
            >
              Activity
            </span>
            <span
              className={`team-tab ${activeTab === "project" ? "active" : ""}`}
              onClick={() => setActiveTab("project")}
            >
              Project
            </span>
          </div>
        </div>
        <div className="team-proj-data-holer personal">
          <Scrollbar>
            {activity_wise_efforts && !(activity_wise_efforts.msg || _.isEmpty(activity_wise_efforts)) &&
              activeTab === "activity" &&
              renderEffort(activity_wise_efforts)}
            {activity_wise_efforts && (activity_wise_efforts.msg || _.isEmpty(activity_wise_efforts)) && activeTab === "activity" &&
            <div className="no-data-to-show">{activity_wise_efforts.msg || "No data to show"}</div>}
            {project_wise_efforts && !(project_wise_efforts.msg || _.isEmpty(project_wise_efforts)) &&
              activeTab === "project" &&
              renderEffort(project_wise_efforts)}
            {project_wise_efforts && (project_wise_efforts.msg || _.isEmpty(project_wise_efforts)) && activeTab === "project" &&
            <div className="no-data-to-show">{project_wise_efforts.msg || "No data to show"}</div>}
          </Scrollbar>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(EffortOverviewPersonal);
