import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ProjectTable from "./projectTable";
import AssignProjectSearchBox from "./assignProjectSearchBox";
import { getProjectsAction } from "../../actions/project/projectaction";
import { connect } from "react-redux";
import _ from "lodash";
import { getProjectWidgets } from "../../services/projectapi";
import { getIsCreator, getIsSuperAdmin } from "../../helpers/reportlanding";

class ProjectAccessSection extends Component {
  state = {
    showSearch: false,
    allProjectsDetails: [],
    projectWidgets: []
  };
  componentDidMount() {
    let auth_token = localStorage.getItem("customer_auth_token");
    
    getProjectWidgets({ auth_token }, response => {
      if(response && response.length > 0) this.setState({ projectWidgets: response })
    })
    this.setState({
      allProjectsDetails: this.props.listOfProjects
    });
  }

  onShowSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showSearch: !this.state.showSearch
    });
    
  };

  onOutsideClickShowSearch = e => {
    if (
      this.outerSearchDivNode &&
      !this.outerSearchDivNode.contains(e.target)
    ) {
      if (this.state.showSearch) this.setState({ showSearch: false });
    }
  };

  onHideSearch = () => {
    this.setState({
      showSearch: false
    });
  };
  render() {
    
    const selectedUserId = this.props.userIdToShow.id;
    
    const isCreator = getIsCreator(this.props.currentWorkSpaceId);
    const isOwner = getIsSuperAdmin(this.props.loggedInUserDetails, this.props.currentWorkSpaceId);
    const isSelectedUserCreator = this.props.currentWorkSpaceId &&
      this.props.currentWorkSpaceId.super_admin_id === parseInt(selectedUserId);
    const isSelectedUserAdmin =
      this.props.currentWorkSpaceId &&
      this.props.currentWorkSpaceId.users[selectedUserId] === "SUPERADMIN";

    let projects = [];
    let showCreatorDetails = false;
    let showOwnerDetails = false;
    let projectsForSearchBox = [];

    if(isSelectedUserCreator) {
      showCreatorDetails = true;
      projects = this.props.listOfProjects;
    } else if (isSelectedUserAdmin) {
      showCreatorDetails = false;
      showOwnerDetails = true;
      
      projects = this.props.listOfProjects.filter(project => {
        return (
          !_.isUndefined(project.data.settings.member_access) &&
          project.data.settings.member_access.hasOwnProperty(selectedUserId) &&
          project.data.settings.member_access[selectedUserId]
        );
      });
        // console.log("projects@-",projects)
    } else {
      showCreatorDetails = false;
      showOwnerDetails = false;
      // projects = this.state.allProjectsDetails.filter(project => {
      projects = this.props.listOfProjects.filter(project => {
        return (
          !_.isUndefined(project.data.settings.member_access) &&
          project.data.settings.member_access.hasOwnProperty(selectedUserId) &&
          project.data.settings.member_access[selectedUserId]
        );
      });
    }
    if(isSelectedUserCreator) {
      projectsForSearchBox = [];
    } else if (isSelectedUserAdmin) {
      projectsForSearchBox = this.props.listOfProjects.filter(project => {
        return (
          !_.isUndefined(project.data.settings.member_access) &&
          !project.data.settings.member_access.hasOwnProperty(
            this.props.userIdToShow.id
          )
        );
      });
    } else {
      // projectsForSearchBox = this.state.allProjectsDetails.filter(project => {
      projectsForSearchBox = this.props.listOfProjects.filter(project => {
        return (
          !_.isUndefined(project.data.settings.member_access) &&
          !project.data.settings.member_access.hasOwnProperty(
            this.props.userIdToShow.id
          )
        );
      });
    }

    return (
      <div className="prj-access-tt-container">
        <div className="prj-title-wrapper">
          <div className="prj-access-header">
            Project access{" "}
            <span>
              {projects.length > 0
                ? `Assigned to ${projects.length} project${projects.length > 1 ? "s" : ""}`
                : "No project is assigned to this person"}
            </span>
          </div>
          {(isCreator || (isOwner && !isSelectedUserAdmin)) && <div className="prj-addmember" ref={node => (this.outerSearchDivNode = node)}>
          <span onClick={this.onShowSearch}>Assign project</span>
            {this.state.showSearch && (
              <OutsideClickHandler onOutsideClick={this.onOutsideClickShowSearch}>
              <AssignProjectSearchBox
                onHideSearch={this.onHideSearch}
                //   workSpaceUsers={this.props.workSpaceUsers}
                //   currentWorkSpaceId={currentWorkSpace.id}
                userToShow={this.props.userIdToShow}
                isSelectedUserAdmin={isSelectedUserAdmin}
                projectsForSearchBox={projectsForSearchBox}
                currentWorkSpaceId={this.props.currentWorkSpaceId}
              />
              </OutsideClickHandler>
            )}
          </div>}
        </div>
        {/* console.log("imp props",this.props.listOfCustomer, this.props.currentWorkSpaceId) */}
        <ProjectTable
          showCreatorDetails={showCreatorDetails}
          showOwnerDetails={showOwnerDetails}
          projects={projects}
          selectedUserId={this.props.userIdToShow.id}
          userToShow={this.props.userIdToShow}
          totalUsers={this.props.totalUsers}
          currentWorkSpaceId={this.props.currentWorkSpaceId}
          widgets={this.state.projectWidgets}
          currentUser={this.props.loggedInUserDetails}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    listOfProjects: state.projectReducer.listOfProjects.list,
    loggedInUserDetails: state.loginReducer.loginAuth.user_details
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getProjectsAction: ({ auth_token, queryParam }, callback) => {
      dispatch(getProjectsAction({ auth_token, queryParam }, callback));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAccessSection);