import { checklistConstant } from "../../constants/checklistconstant";

const initialState = {
  getChecklistLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  addChecklistLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateChecklistLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateChecklistItemLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  checklists: {
    list: [],
    key: null
  },
  searchedChecklists: {
    list: [],
    key: null
  },
  archivedChecklists: {
    list: [],
    key: null
  }
};

// const sortAndMoveCurrentUserItemToFirst = list => {
//   if (list) {
//     let sortedList = sort(ascend(prop("name")), list.slice());
//     const myUserId =
//       sessionStorage.getItem("id") && parseInt(sessionStorage.getItem("id"));
//     let foundMyWorspaceIndex = findIndex(propEq("super_admin_id", myUserId))(
//       sortedList
//     );
//     if (foundMyWorspaceIndex > -1) {
//       sessionStorage.setItem("haveMyWorkspace", true);
//       var toBeMovedElement = sortedList[foundMyWorspaceIndex];
//       sortedList.splice(foundMyWorspaceIndex, 1);
//       sortedList.splice(0, 0, toBeMovedElement);
//     }

//     return sortedList;
//   }
//   return list;
// };

export default function checklistReducer(state = initialState, action) {
  switch (action.type) {
    case checklistConstant.getChecklistLoaderState:
      return {
        ...state,
        getChecklistLoaderState: action.payload.state
      };

    case checklistConstant.addChecklistLoaderState:
      return {
        ...state,
        addChecklistLoaderState: action.payload.state
      };
    case checklistConstant.updateChecklistLoaderState:
      return {
        ...state,
        updateChecklistLoaderState: action.payload.state
      };
    case checklistConstant.updateChecklistItemLoaderState: {
      return {
        ...state,
        updateChecklistItemLoaderState: action.payload.state
      };
    }
    case checklistConstant.addChecklist:
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: [...state.checklists.list, action.payload.checklist]
        }
      };

    case checklistConstant.getChecklist:
      if(action.payload.page && action.payload.page!==1)
        return {
          ...state,
          checklists: { list: state.checklists.list.concat(action.payload.checklists), key: action.payload.key }
        };
      else
        return {
          ...state,
          checklists: { list: action.payload.checklists, key: action.payload.key }
        };
    case checklistConstant.searchChecklist:
      return {
        ...state,
        searchedChecklists: { list: action.payload.checklists, key: action.payload.key }
      };
    case checklistConstant.addChecklistItem: {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: state.checklists.list.map((list) =>
            list.id === action.payload.checklist_id
              ? {
                  ...list,
                  checklist_items: [
                    ...list.checklist_items,
                    { ...action.payload }
                  ],
                  recent: false
                }
              : list
          )
        }
      };
    }

    case checklistConstant.updateChecklistItem: {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: state.checklists.list.map(
            (list) =>
              (list.id = action.payload.checklist_id
                ? {
                    ...list,
                    checklist_items: list.checklist_items.map(
                      (checklist_item) =>
                        checklist_item.id === action.payload.id
                          ? { ...checklist_item, ...action.payload, showUndo: false }
                          : checklist_item
                    )
                  }
                : list)
          )
        },
        searchedChecklists: {
          ...state.searchedChecklists,
          list: state.searchedChecklists.list.map(
            (list) =>
              (list.id = action.payload.checklist_id
                ? {
                    ...list,
                    checklist_items: list.checklist_items.map(
                      (checklist_item) =>
                        checklist_item.id === action.payload.id
                          ? { ...checklist_item, ...action.payload, showUndo: false }
                          : checklist_item
                    )
                  }
                : list)
          )
        }
      };
    }
    // case checklistConstant.deleteChecklistItem: {
    //   return {
    //     ...state,
    //     checklists: {
    //       ...state.checklists,
    //       list: state.checklists.list.map(
    //         (list) =>
    //           (list.id = action.payload.checklist_id
    //             ? {
    //                 ...list,
    //                 checklist_items: list.checklist_items.filter(
    //                   (checklist_item) =>
    //                     checklist_item.id !== action.payload.id
    //                 )
    //               }
    //             : list)
    //       )
    //     }
    //   };
    // }

    case checklistConstant.deleteChecklistItem: {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: state.checklists.list.map(
            (list) =>
              (list.id === action.payload.checklist_id
                ? {
                    ...list,
                    checklist_items: list.checklist_items.map(
                      (checklist_item) =>
                        checklist_item.id === action.payload.id
                        ? { ...action.payload, showUndo: true }
                        : checklist_item
                    )
                  }
                : list)
          )
        },
        searchedChecklists: {
          ...state.searchedChecklists,
          list: state.searchedChecklists.list.map(
            (list) =>
              (list.id === action.payload.checklist_id
                ? {
                    ...list,
                    checklist_items: list.checklist_items.map(
                      (checklist_item) =>
                        checklist_item.id === action.payload.id
                        ? { ...action.payload, showUndo: true }
                        : checklist_item
                    )
                  }
                : list)
          )
        }
      };
    }

    case checklistConstant.updateChecklist: {
      if (action.payload.is_archived === false) {
        return {
          ...state,
          checklists: {
            ...state.checklists,
            list: state.checklists.list.map((list) =>
              list.id === action.payload.id
                ? { ...list, ...action.payload }
                : list
            )
          }
        };
      }
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: state.checklists.list.filter(
            (list) => list.id !== action.payload.id
          )
        },
        archivedChecklists: {
          ...state.archivedChecklists,
          list: [
            ...state.archivedChecklists.list,
            {
              ...action.payload,
              ...state.checklists.list.filter(
                (list) => list.id === action.payload.id
              )[0]
            }
          ]
        }
      };
    }
    case checklistConstant.getChecklistArchived: {
      return {
        ...state,
        archivedChecklists: {
          key: action.payload.key,
          list: action.payload.checklists
        }
      };
    }
    case checklistConstant.unarchiveChecklist: {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          list: [
            ...state.checklists.list,
            {
              ...state.archivedChecklists.list.filter(
                (checklist) => checklist.id === action.payload.id
              )[0],
              ...action.payload
            }
          ]
        },
        archivedChecklists: {
          ...state.checklists,
          list: state.archivedChecklists.list.filter(
            (checklist) => checklist.id !== action.payload.id
          )
        }
      };
    }

    default:
      return {
        ...state
      };
  }
}
