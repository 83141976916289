import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import GoogleSignOn from "../googlesignon";
import { GOOGLE_KEY } from "../../../constants/globalconst";
import routesHelper from "../../../router/routeshelper";
import { isGoogleSignIn } from "../../../helpers/loginconstants";
import * as actions from "../../../actions/index";

import { getAllCustomerForUserAction } from "../../../actions/workspace/workspaceaction";

class ForgotPasswordGoogleSignIn extends Component {
  onGoogleSignIn = ({ payload = {} }) => {
    const { googleAuthenticationAction, router, getAllCustomersAction } = this.props;
    if (payload.accessToken) {
      const body = {
        code: payload.accessToken,
        clientId: GOOGLE_KEY,
        redirectUri: routesHelper.getBaseUrl()
      };
      if (payload.access_type) body.access_type = payload.access_type;
      googleAuthenticationAction(
        { ...body },
        (resposne) => {
          if (resposne && resposne.id) {
            for (const key in resposne) {
              if (resposne.hasOwnProperty(key)) {
                const element = resposne[key];
                if (key === "data")
                  localStorage.setItem(key, JSON.stringify(element));
                else if(key === "auth_token") {
                  for (const k in resposne.auth_token) {
                    const ele = resposne.auth_token[k];
                    localStorage.setItem(k, ele);
                  }
                } else localStorage.setItem(key, element);
              }
            }
            localStorage.setItem(isGoogleSignIn, true);
            const auth_token = resposne.auth_token;
            getAllCustomersAction({ auth_token }, (workspaces) => {
              if (workspaces && !workspaces.stdErr) {
                if (workspaces.length > 0) {
                  return router.push(routesHelper.getRootPath());
                }
                return router.push("/finish-profile");
              }
            });
          }
        }
      );
    }
  };

  onGoogleSignInSuccess = (resposne) => {
    if (resposne && resposne.accessToken) {
      // localStorage.setItem("googlePayload", resposne)
      this.onGoogleSignIn({ payload: resposne });
    }
  };

  onGoogleSignInFailure = (_resposne) => { /* TODO document why this method 'onGoogleSignInFailure' is empty */ };

  render() {
    return (
      <div className="signIn">
        <div className="fp-header-area fp-google-header">
          <div className="fp-main-heading">Sign in with Google</div>
          <div className="fp-sub-heading fp-google-sub-heading">
            This account is created using Google sign up. Try signing in with
            the same credentials.
          </div>

          <div>
            <GoogleSignOn
              onGoogleSignInSuccess={this.onGoogleSignInSuccess}
              onGoogleSignInFailure={this.onGoogleSignInFailure}
              fromFp={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    googleAuthenticationAction({ code, clientId, redirectUri, access_type }, callback) {
      dispatch(
        actions.googleAuthentication(
          {
            code,
            clientId,
            redirectUri,
            access_type
          },
          callback
        )
      );
    },
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey
          },
          callback
        )
      );
    }
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(ForgotPasswordGoogleSignIn)
);
