import React from "react";
import { ToastContainer, Slide } from "react-toastify";
const ForgotPasswordEmailSent = props => {
  return (
    <div className="signIn">
        <ToastContainer autoClose={2000} transition={Slide} />
      <div className="fp-header-area reset-new-link">
        <div className="fp-main-heading">Reset password link sent! </div>
        <div className="fp-sub-heading new-link-sent">
          A link to reset your password has been <br />
          sent to your email address
        </div>
      </div>
      <div>
        <p className="din-receive">Didn’t receive any mail from us?</p>
        <div className="rp-submit-holder fp">
          <button
            className="md-raised common-submit-btn rp-resend"
            type="submit"
            onClick={props.onForgotEmailRequest}
          >
            <span>Resend link</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordEmailSent;
