import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/scss/notifications.scss";
import CommentNotify from "./commentnotify";
import AttachmentNotify from "./attachmentNotify";
import NotifyWorkHours from "./notifyWorkHours";
import NotifyAddedMember from "./notifyAddedMember";
import NotifyRemovedMember from "./notifyRemovedMember";
import NotifyTimeSheet from "./notifyTimesheet";
import GlobalNotification from "./globalNotification";
import SystemNotification from "./systemNotification";
import NotifyChecklist from "./notifyChecklist";
import LikeNotify from "./likenotify";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../common/loader";
import _ from "lodash";
import {
  notificationType,
  isUserAdded
} from "../../helpers/notificationHelper";
import { getDeprioritizedActivitiesForWorkspaceAction } from "../../actions/workspace/workspaceaction";
import {
  // getActivitiesForWorkspace,
  getActivity,
} from "../../services/workspaceapi";

import {
  getAllNotificationsAction,
  getYourNotificationsAction,
  archiveSystemNotificationAction,
  changeIsReadStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  archiveRequestSystemNotificationAction
} from "../../actions/notifications/notificationaction";
import routesHelper from "../../router/routeshelper";
import firebase from "../../firebase";

const mapStateToProps = (state) => {
  return {
    uploadNotificationLoaderState:
      state.notificationsReducer.uploadNotificationLoaderState,
    uploadYourNotificationLoaderState:
      state.notificationsReducer.uploadYourNotificationLoaderState,
    updateCustomerLoaderState:
      state.wrorkSpaceReducer.updateCustomerLoaderState,
    listOfFlaggedYouNotificationLoaderState:
      state.notificationsReducer.listOfFlaggedYouNotificationLoaderState,
    listOfFlaggedAllNotificationLoaderState:
      state.notificationsReducer.listOfFlaggedAllNotificationLoaderState,
    searchYourNotificationLoaderState:
      state.notificationsReducer.searchYourNotificationLoaderState,
    searchAllNotificationLoaderState:
      state.notificationsReducer.searchAllNotificationLoaderState,
    listOfNotification: state.notificationsReducer.listOfNotification,
    listOfYourNotification: state.notificationsReducer.listOfYourNotification,
    listOfSearchAllNotification:
      state.notificationsReducer.listOfSearchAllNotification,
    listOfSearchYouNotification:
      state.notificationsReducer.listOfSearchYouNotification,
    noMoreAllNotification:
      state.notificationsReducer.listOfNotification.noMoreNotification,
    noMoreYouNotification:
      state.notificationsReducer.listOfYourNotification.noMoreNotification,
    noMoreSearchYouNotification:
      state.notificationsReducer.listOfSearchYouNotification.noMoreNotification,
    noMoreSearchAllNotification:
      state.notificationsReducer.listOfSearchAllNotification.noMoreNotification,
    noMoreFlaggedYouNotification:
      state.notificationsReducer.listOfFlaggedYouNotification
        .noMoreNotification,
    noMoreFlaggedAllNotification:
      state.notificationsReducer.listOfFlaggedAllNotification
        .noMoreNotification,
    noMoreReadYouNotification:
      state.notificationsReducer.listOfReadYouNotification
        .noMoreNotification,
    noMoreReadAllNotification:
      state.notificationsReducer.listOfReadAllNotification
        .noMoreNotification,
    prioritizedActivity: state.activityReducer.prioritiZedActivity.list,
    unPrioritizedActivity: state.activityReducer.listOfActivity.list,
    archivedActivity: state.activityReducer.listOfArchivedActivity.list,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getAllNotificationsAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getAllNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getYourNotificationsAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getYourNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    archiveSystemNotificationAction: ({ notificationData }, callback) => {
      dispatch(archiveSystemNotificationAction({ notificationData }, callback));
    },
    archiveRequestSystemNotificationAction: ({ notificationArray, recipient_id }, callback) => {
      dispatch(archiveRequestSystemNotificationAction({ notificationArray, recipient_id }, callback));
    },
    changeIsReadStatusOfAllTabAction: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfYouTabAction: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    getDeprioritizedActivities: (
      { auth_token, activity_type, user_id, is_active, is_archived },
      cb
    ) => {
      dispatch(
        getDeprioritizedActivitiesForWorkspaceAction(
          { auth_token, activity_type, user_id, is_active, is_archived },
          cb
        )
      );
    },
  };
}
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayNotification: [],
      yesterdayNotification: [],
      olderNotification: [],
      clickedNotificationDetails: {},
      isPopupEnable: false,
      is_priority: false,
      showAllGlobalSysNotification: false,
      showDown:false
    };
  }

  showMore = (_tabvalue) => {
    this.props.updatePageNo();
  };

  archiveSystemNotification = (notification) => {
    let userId;
    userId = parseInt(localStorage.getItem("id"));
    const { archiveSystemNotificationAction } = this.props;
    let userArchivedSystemNotification = notification.archived_by_users;
    if (userArchivedSystemNotification.indexOf(userId) === -1) {
      userArchivedSystemNotification.push(userId);
    }
    // archived notification data
    let notificationData = {
      id: notification.id,
      archived_by_users: userArchivedSystemNotification,
    };
    archiveSystemNotificationAction({ notificationData });
  };

  archiveRequestSystemNotification = (notification) => {
    const { archiveRequestSystemNotificationAction } = this.props;
    const notificationArray = [{
      id: notification.id,
      is_archived: true
    }]
    archiveRequestSystemNotificationAction({ notificationArray, recipient_id: notification.recipient_id });
  }

  archiveAllGlobalNotification = () => {
    const { globalNotification, archiveSystemNotificationAction } = this.props;
    let userId = parseInt(localStorage.getItem("id"));
    const globalNotificationListFiltered = globalNotification.filter(
      (el) =>
        (el.archived_by_users && !el.archived_by_users.includes(userId))
    );
    globalNotificationListFiltered.forEach(notification => {
      let userArchivedSystemNotification = notification.archived_by_users;
      if (userArchivedSystemNotification.indexOf(userId) === -1) {
        userArchivedSystemNotification.push(userId);
      }
      // archived notification data
      let notificationData = {
        id: notification.id,
        archived_by_users: userArchivedSystemNotification,
      };
      archiveSystemNotificationAction({ notificationData });
    })

  }

  // @vignesh changes
  isNewActivity = (id) => {
    const {
      prioritizedActivity,
      unPrioritizedActivity,
      archivedActivity,
    } = this.props;

    return prioritizedActivity
      .concat(unPrioritizedActivity)
      .concat(archivedActivity)
      .findIndex(({ id: activityId }) => activityId === id) >= 0
      ? false
      : true;
  };

  // @vignesh changes
  isActivityOwner = (id, userId) => {
    const {
      prioritizedActivity,
      unPrioritizedActivity,
      archivedActivity,
    } = this.props;
    const currActivity = prioritizedActivity
      .concat(unPrioritizedActivity)
      .concat(archivedActivity)
      .find(({ id: activityId }) => activityId === id);
    return currActivity
      ? currActivity.user_id.filter((user) => user === userId).length
      : false;
  };

  goToActivity = (notification) => {
    let id = notification.data.parent_activity.id;
    let { recipient_id } = notification;
    let commentId = notification.data.comment.id;
    let parentCommentId=notification.data.comment.data.commentDetails?.parentCommentId || notification.data.comment.id;

    if (_.isUndefined(id) !== true) {
      if (this.isActivityOwner(id, recipient_id)) {
        if (this.isNewActivity(id)) {
          // get from server
          const user_id = localStorage.getItem("id");
          const { auth_token } = this.props.currentWorkspace;
          this.props.getDeprioritizedActivities(
            {
              auth_token,
              user_id,
              activity_type: "ACTIVITY",
              is_active: true,
              is_archived: false,
            },
            () =>
              this.props.controlDisplayDialogWrapper(id, {
                Id: commentId,
                type: "comments",
                checklistItemId: null,
                parentCommentId:parentCommentId
              })
          );
        } else {
          this.props.controlDisplayDialogWrapper(id, {
            Id: commentId,
            type: "comments",
            checklistItemId: null,
            parentCommentId:parentCommentId
          });
        }
      } else {
        getActivity({ id }, (response) => {
          if (response && !response.stdError) {
            this.props.controlDisplayDialogWrapper(response[0], {
              Id: commentId,
              type: "comments",
              checklistItemId: null,
              parentCommentId:parentCommentId
            });
          }
        });
      }
      // change read status
      this.notificationReadStatus(notification);
    }
  };
  goToAttachment = (notification) => {
    let id = notification.data.activity.id;
    let { recipient_id } = notification;
    let attachment_id = notification.data.attachment.id;

    if (_.isUndefined(id) !== true) {
      if (this.isActivityOwner(id, recipient_id)) {
        if (this.isNewActivity(id)) {
          // get from server
          const user_id = localStorage.getItem("id");
          const { auth_token } = this.props.currentWorkspace;
          this.props.getDeprioritizedActivities(
            {
              auth_token,
              user_id,
              activity_type: "ACTIVITY",
              is_active: true,
              is_archived: false,
            },
            () =>
              this.props.controlDisplayDialogWrapper(id, {
                Id: attachment_id,
                type: "attachments",
                checklistItemId: null,
              })
          );
        } else {
          this.props.controlDisplayDialogWrapper(id, {
            Id: attachment_id,
            type: "attachments",
            checklistItemId: null,
          });
        }
      } else {
        getActivity({ id }, (response) => {
          if (response && !response.stdError) {
            this.props.controlDisplayDialogWrapper(response[0], {
              Id: attachment_id,
              type: "attachments",
              checklistItemId: null,
            });
          }
        });
      }
      this.notificationReadStatus(notification);
    }
  };
  goToChecklist = (notification) => {
    let id = notification.data.activity.id;
    let { recipient_id } = notification;
    let checklist_id = notification.data.checklist.id;
    let checklistItemId = notification.data.checklist_item.id;
    // if (_.isUndefined(id) != true) {
    //   getActivity({ id }, (response) => {
    //     if (response && !response.stdError) {
    //       // this.props.getChecklistId();

    //       this.props.controlDisplayDialogWrapper(response[0], {
    //         Id: checklist_id,
    //         type: "checklists",
    //         checklistItemId: checklistItemId,
    //       });
    //       // change read status
    //       this.notificationReadStatus(notification);
    //     } else {
    //     }
    //   });
    // } else {
    // }
    if (_.isUndefined(id) !== true) {
      if (this.isActivityOwner(id, recipient_id)) {
        if (this.isNewActivity(id)) {
          // get from server
          const user_id = localStorage.getItem("id");
          const { auth_token } = this.props.currentWorkspace;
          this.props.getDeprioritizedActivities(
            {
              auth_token,
              user_id,
              activity_type: "ACTIVITY",
              is_active: true,
              is_archived: false,
            },
            () =>
              this.props.controlDisplayDialogWrapper(id, {
                Id: checklist_id,
                type: "checklists",
                checklistItemId: checklistItemId,
              })
          );
        } else {
          this.props.controlDisplayDialogWrapper(id, {
            Id: checklist_id,
            type: "checklists",
            checklistItemId: checklistItemId,
          });
        }
        this.notificationReadStatus(notification);
      } else {
        getActivity({ id }, (response) => {
          if (response && !response.stdError) {
            this.props.controlDisplayDialogWrapper(response[0], {
              Id: checklist_id,
              type: "checklists",
              checklistItemId: checklistItemId,
            });
          }
        });

        this.notificationReadStatus(notification);
      }
    }
  };
  goToAddedMemberSection = (notification) => {
    let id = notification.data.activity.id;
    let { recipient_id } = notification;

    if (_.isUndefined(id) !== true) {
      if (this.isActivityOwner(id, recipient_id)) { // check if current_user is part of activity
        if (this.isNewActivity(id)) { // check if activity is new i.e not available in app state
          /*  @vignesh changes:
           *   get from server
           */
          const user_id = localStorage.getItem("id");
          const { auth_token } = this.props.currentWorkspace;
          this.props.getDeprioritizedActivities(
            {
              auth_token,
              user_id,
              activity_type: "ACTIVITY",
              is_active: true,
              is_archived: false,
            },
            () =>
              this.props.controlDisplayDialogWrapper(id, {
                id: null,
                type: null,
                checklist_item: null,
              })
          );
        } else {
          this.props.controlDisplayDialogWrapper(id, {
            id: null,
            type: null,
            checklist_item: null,
          });
        }
        this.notificationReadStatus(notification);
      } else {
        getActivity({ id }, (response) => { // if current_user is not part of activity, only get that from server
          if (response && !response.stdError) {
            this.props.controlDisplayDialogWrapper(response[0], {
              id: null,
              type: null,
              checklist_item: null,
            });
          }
        });

        this.notificationReadStatus(notification);
      }
    }
  };

  goToReport = (reportDate, reportId) => {
    this.props.router.push({
      pathname: routesHelper.getReportPath(),
      state: {
        reportDate,
        reportId
      }
    });
    this.props.controlDisplayNotification(false);
  };

  goToKanban = (notification) => {
    this.props.router.push(routesHelper.kanbanPath(notification.data.project.id));
    this.notificationReadStatus(notification);
    this.props.controlDisplayNotification(false);
  }

  goToPeopleSettings = (user) => {
    this.props.router.push({
      pathname: routesHelper.getSettingsPath(),
      state: { user: user.id },
    });
    this.props.controlDisplayNotification(false);
  };

  notificationReadStatus = (notification) => {
    notification.is_read = true;
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTabAction,
      changeIsReadStatusOfAllTabAction,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText,
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTabAction({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText,
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTabAction({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText,
      });
    }
  };

  onShowAllGlobalSysNotification = () => {
    this.setState({ showAllGlobalSysNotification: true });
  };

  updateShowDown=(flag) =>{
    this.setState({showDown:flag})
  }

  onInfiniteScroll = () => {
    // console.log("scrolled")
    const el = document.querySelector('.notify-comment-text-area__suggestions');
    if(el) {
      const rect = el.getBoundingClientRect();
      // console.log("position", rect.top, rect.bottom)
      if(
        rect.top < 370 || this.state.showDown
        ) {
        // el.setAttribute("class", "notify-comment-text-area__suggestions top")
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='20px';
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="auto";
      } else {
        el.setAttribute("class", "notify-comment-text-area__suggestions")
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='auto';
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="20px";
      }
    }
  }

  render() {
   
    const {
      optionValue,
      allNotifications,
      // listOfNotification,
      yourNotification,
      // listOfYourNotification,
      globalNotification,
      searchYourNotificationLoaderState,
      searchAllNotificationLoaderState,
      uploadNotificationLoaderState,
      uploadYourNotificationLoaderState,
      updateCustomerLoaderState,
      noMoreAllNotification,
      noMoreSearchAllNotification,
      noMoreFlaggedAllNotification,
      noMoreSearchYouNotification,
      noMoreYouNotification,
      noMoreFlaggedYouNotification,
      noMoreReadAllNotification,
      noMoreReadYouNotification,
      listOfFlaggedYouNotificationLoaderState,
      listOfFlaggedAllNotificationLoaderState,
      ddownOption,
      ddownOption2,
      searchText,
    } = this.props;

    // check globar notification date
    let notifications = allNotifications && notificationType(allNotifications);
    let yourNotifications =
      yourNotification && notificationType(yourNotification);

    let userId = parseInt(localStorage.getItem("id"));
    const globalNotificationListFiltered = globalNotification.filter(
      (el) =>
        (el.archived_by_users && !el.archived_by_users.includes(userId)) ||
        el.type
    );
    let globalNotificationList = [];
    if (globalNotificationListFiltered.length > 0) {
      if (!this.state.showAllGlobalSysNotification)
        globalNotificationList.push(globalNotificationListFiltered[0]);
      else globalNotificationList = globalNotificationListFiltered;
    }
    return (
      <div className="tab-body" id="tab-body-parentdiv">
        {/* display loader */}

        {(uploadNotificationLoaderState.fetching ||
          uploadYourNotificationLoaderState.fetching ||
          listOfFlaggedYouNotificationLoaderState.fetching ||
          listOfFlaggedAllNotificationLoaderState.fetching ||
          searchYourNotificationLoaderState.fetching ||
          updateCustomerLoaderState.fetching ||
          searchAllNotificationLoaderState.fetching) && (
          <Loader size={100} loading={true} />
        )}
        {/* display global notification */}
        {globalNotificationList.length > 0 && (
          <div className="tab-content">
            {/* display global notification */}

            <div className="notify-card-container">
              <div className="notify-card-time-categ">
                {" "}
                Team Get Things Done{" "}
              </div>

              <div className="notify-card-holder ">
                {globalNotificationList.map((notification) => {
                  if (notification.description)
                    return (
                      <GlobalNotification
                        key={notification.id}
                        notification={notification}
                        archiveSystemNotification={
                          this.archiveSystemNotification
                        }
                        archiveAllGlobalNotification={this.archiveAllGlobalNotification}
                      />
                    );
                  if (notification.users_data && !notification.is_archived) {
                    return notification.users_data.map((userData) => (
                      <SystemNotification
                        notificationObj={notification}
                        notification={userData}
                        goToPeopleSettings={this.goToPeopleSettings}
                        archiveRequestSystemNotification={this.archiveRequestSystemNotification}
                      />
                    ));
                  }
                  return null;
                })}
              </div>
              {globalNotificationListFiltered.length !==
                globalNotificationList.length &&
                (globalNotificationList.length > 1 ||
                  !this.state.showAllGlobalSysNotification) && (
                  <div className="global-show-all">
                    <div className="hr-line"></div>
                    <div
                      className="show-all-txt"
                      onClick={this.onShowAllGlobalSysNotification}
                    >
                      Show all
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}

        {/* display all notification */}
        {optionValue === "all" && (
          <InfiniteScroll
            dataLength={allNotifications.length}
            onScroll={this.onInfiniteScroll}
            next={() => {
              firebase.analytics().logEvent("GTD_Notifications_Scroll_Pagination");
              this.showMore("all");
            }}
            hasMore={true}
            scrollableTarget="tab-body-parentdiv"
          >
            {searchAllNotificationLoaderState.fetching && (
              <Loader size={100} loading={true} />
            )}
            <div className="tab-content" id="tab-content-outdiv-notification">
              {/* All tab Notifications of today  */}
              {allNotifications &&
                notifications &&
                notifications.todaysNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Today</div>
                    <div className="notify-card-holder ">
                      {notifications.todaysNotification.map(
                        (notification, index) => {
                          if (
                            notification.type === "COMMENT" ||
                            notification.type === "EDIT_COMMENT"
                          ) {
                            return (
                              <div className="notify-temp-holder pointer">
                                <CommentNotify
                                  key={`EDIT_COMMENT-${index}`}
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  optionValue={optionValue}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (notification.type === "DELETE_COMMENT") {
                            return (
                              <div className="notify-temp-holder deleted">
                                <CommentNotify
                                  key={`DELETE_COMMENT-${index}`}
                                  notification={notification}
                                  optionValue={optionValue}
                                  delete={true}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (notification.type === "WORK_HOURS") {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyWorkHours
                                  optionValue={optionValue}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (
                            notification.type === "ATTACHMENT" ||
                            notification.type === "DELETE_ATTACHMENT"
                          ) {
                            return (
                              <div className="notify-temp-holder pointer">
                                <AttachmentNotify
                                  notification={notification}
                                  optionValue={optionValue}
                                  goToAttachment={this.goToAttachment}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          // if notification type people
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div
                                className="notify-temp-holder deleted"
                                // onClick={() => this.goToActivity(notification)}
                              >
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToAddedMemberSection={
                                    this.goToAddedMemberSection
                                  }
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToKanban={this.goToKanban}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  </div>
                )}

              {/* $$ All tab Notifications of the previous day */}
              {allNotifications &&
                notifications &&
                notifications.yesterdaysNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Yesterday</div>
                    <div className="notify-card-holder">
                      {notifications.yesterdaysNotification.map(
                        (notification) => {
                          if (
                            notification.type === "COMMENT" ||
                            notification.type === "EDIT_COMMENT"
                          ) {
                            return (
                              <div className="notify-temp-holder pointer">
                                <CommentNotify
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  optionValue={optionValue}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (notification.type === "DELETE_COMMENT") {
                            return (
                              <div className="notify-temp-holder deleted">
                                <CommentNotify
                                  notification={notification}
                                  optionValue={optionValue}
                                  delete={true}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (notification.type === "WORK_HOURS") {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyWorkHours
                                  optionValue={optionValue}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (
                            notification.type === "ATTACHMENT" ||
                            notification.type === "DELETE_ATTACHMENT"
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <AttachmentNotify
                                  notification={notification}
                                  optionValue={optionValue}
                                  goToAttachment={this.goToAttachment}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          // if notification type People
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div
                                className="notify-temp-holder deleted"
                                // onClick={() => this.goToActivity(notification)}
                              >
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToAddedMemberSection={
                                    this.goToAddedMemberSection
                                  }
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToKanban={this.goToKanban}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "ACTIVITY" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  </div>
                )}
              {/* $$ All other older All tab Notifications */}
              {allNotifications &&
                notifications &&
                notifications.olderNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Older</div>
                    <div className="notify-card-holder">
                      {notifications.olderNotification.map((notification) => {
                        if (
                          notification.type === "COMMENT" ||
                          notification.type === "EDIT_COMMENT"
                        ) {
                          return (
                            <div className="notify-temp-holder pointer">
                              <CommentNotify
                                notification={notification}
                                goToActivity={this.goToActivity}
                                optionValue={optionValue}
                                delete={false}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                                updateShowDown={this.updateShowDown}
                              />
                            </div>
                          );
                        }
                        if (notification.type === "DELETE_COMMENT") {
                          return (
                            <div className="notify-temp-holder deleted">
                              <CommentNotify
                                notification={notification}
                                optionValue={optionValue}
                                delete={true}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                                updateShowDown={this.updateShowDown}
                              />
                            </div>
                          );
                        }
                        if (notification.type === "WORK_HOURS") {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyWorkHours
                                optionValue={optionValue}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                              />
                            </div>
                          );
                        }

                        if (
                          notification.type === "ATTACHMENT" ||
                          notification.type === "DELETE_ATTACHMENT"
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <AttachmentNotify
                                notification={notification}
                                goToAttachment={this.goToAttachment}
                                optionValue={optionValue}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }

                        // if notification type people
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          isUserAdded(notification.message) &&
                          notification.data.activity
                        ) {
                          return (
                            <div
                              className="notify-temp-holder deleted"
                              // onClick={() => this.goToActivity(notification)}
                            >
                              <NotifyAddedMember
                                optionValue={optionValue}
                                notification={notification}
                                goToAddedMemberSection={
                                  this.goToAddedMemberSection
                                }
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          !isUserAdded(notification.message) &&
                          notification.data.activity
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyRemovedMember
                                optionValue={optionValue}
                                notification={notification}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          isUserAdded(notification.message) &&
                          notification.data.project
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyAddedMember
                                optionValue={optionValue}
                                notification={notification}
                                goToKanban={this.goToKanban}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          !isUserAdded(notification.message) &&
                          notification.data.project
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyRemovedMember
                                optionValue={optionValue}
                                notification={notification}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          isUserAdded(notification.message) &&
                          notification.data.customer
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyRemovedMember
                                optionValue={optionValue}
                                notification={notification}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        if (
                          notification.type === "ACTIVITY" &&
                          notification.message &&
                          !isUserAdded(notification.message) &&
                          notification.data.customer
                        ) {
                          return (
                            <div className="notify-temp-holder">
                              <NotifyRemovedMember
                                optionValue={optionValue}
                                notification={notification}
                                ddownOption={ddownOption}
                                ddownOption2={ddownOption2}
                                searchText={searchText}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}

              {/* **********No notification section************* */}
              {allNotifications.length === 0 &&
                optionValue === "all" &&
                !uploadNotificationLoaderState.fetching &&
                !searchAllNotificationLoaderState.fetching &&
                !listOfFlaggedAllNotificationLoaderState.fetching && (
                  <div className="tab-content">
                    <p className="no-notify more-margin">No {ddownOption === "View all" ? "Flagged " : ddownOption2 === "View unread" ? "Read " : ""}Notifications</p>
                  </div>
                )}

              {optionValue === "all" &&
                (noMoreAllNotification === true ||
                  noMoreSearchAllNotification === true ||
                  noMoreFlaggedAllNotification === true ||
                  noMoreReadAllNotification === true) &&
                allNotifications.length !== 0 && (
                  <div className="notify-show-more">
                    <div className="no-notification-holder">
                      No more {ddownOption === "View all" ? "Flagged " : ddownOption2 === "View unread" ? "Read " : ""}Notifications
                    </div>
                  </div>
                )}
            </div>
          </InfiniteScroll>
        )}

        {/* display you notification */}
        {optionValue === "you" && (
          <InfiniteScroll
            dataLength={yourNotification.length}
            onScroll={this.onInfiniteScroll}
            next={() => {
              // check search status
              firebase.analytics().logEvent("GTD_Notifications_Scroll_Pagination");
              this.showMore("you");
            }}
            hasMore={true}
            scrollableTarget="tab-body-parentdiv"
          >
            <div className="tab-content">
              {searchYourNotificationLoaderState.fetching && (
                <Loader size={100} loading={true} />
              )}
              {/* You tab Notifications of the today  */}
              {yourNotification &&
                yourNotifications.todaysNotification &&
                yourNotifications.todaysNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Today</div>
                    <div className="notify-card-holder ">
                      {yourNotifications.todaysNotification.map(
                        (notification) => {
                          if (
                            notification.type === "MENTIONS" ||
                            notification.type === "ACT" ||
                            notification.type === "COMMENT"
                          ) {
                            return (
                              <div
                                key={notification.id}
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToActivity(notification)}
                              >
                                <CommentNotify
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  optionValue={optionValue}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "DELETE_MENTIONED_COMMENT"
                          ) {
                            return (
                              <div
                                key={notification.id}
                                className="notify-temp-holder deleted"
                              >
                                <CommentNotify
                                  notification={notification}
                                  optionValue={optionValue}
                                  delete={true}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if(notification.type === "LIKE_COMMENT") {
                            return (
                              <div
                                key={notification.id}
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToActivity(notification)}
                              >
                                <LikeNotify
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  optionValue={optionValue}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div
                                className="notify-temp-holder deleted"
                                // onClick={() => this.goToActivity(notification)}
                              >
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToAddedMemberSection={
                                    this.goToAddedMemberSection
                                  }
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToKanban={this.goToKanban}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "CHECKLIST_MENTIONS") {
                            return (
                              <div className="notify-temp-holder pointer">
                                <NotifyChecklist
                                  notification={notification}
                                  optionValue={optionValue}
                                  goToChecklist={this.goToChecklist}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "WORK_HOURS") {
                            return (
                              <div
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToReport(notification.data.date)}
                              >
                                <NotifyTimeSheet
                                  notification={notification}
                                  optionValue={optionValue}
                                  // goToReport={this.goToReport}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "ACTIVITY_TIMER") {
                            return (
                              <div
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToReport(notification.data?.timesheet_date, notification.data?.timesheet_id)}
                              >
                                <NotifyTimeSheet
                                  notification={notification}
                                  optionValue={optionValue}
                                  // goToReport={this.goToReport}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  </div>
                )}

              {/* $$ You tab Notifications of the previous day */}
              {yourNotification &&
                yourNotifications.yesterdaysNotification &&
                yourNotifications.yesterdaysNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Yesterday</div>
                    <div className="notify-card-holder ">
                      {yourNotifications.yesterdaysNotification.map(
                        (notification) => {
                          if (
                            notification.type === "MENTIONS" ||
                            notification.type === "ACT" ||
                            notification.type === "COMMENT"
                          ) {
                            return (
                              <div className="notify-temp-holder pointer">
                                <CommentNotify
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "DELETE_MENTIONED_COMMENT"
                          ) {
                            return (
                              <div
                                key={notification.id}
                                className="notify-temp-holder deleted"
                              >
                                <CommentNotify
                                  notification={notification}
                                  optionValue={optionValue}
                                  delete={true}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if(notification.type === "LIKE_COMMENT") {
                            return (
                              <div
                                key={notification.id}
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToActivity(notification)}
                              >
                                <LikeNotify
                                  notification={notification}
                                  goToActivity={this.goToActivity}
                                  optionValue={optionValue}
                                  delete={false}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                  updateShowDown={this.updateShowDown}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div className="notify-temp-holder deleted">
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToAddedMemberSection={
                                    this.goToAddedMemberSection
                                  }
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.activity
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyAddedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  goToKanban={this.goToKanban}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.project
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          if (
                            notification.type === "PEOPLE" &&
                            notification.message &&
                            !isUserAdded(notification.message) &&
                            notification.data.customer
                          ) {
                            return (
                              <div className="notify-temp-holder">
                                <NotifyRemovedMember
                                  optionValue={optionValue}
                                  notification={notification}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "CHECKLIST_MENTIONS") {
                            return (
                              <div className="notify-temp-holder pointer">
                                <NotifyChecklist
                                  notification={notification}
                                  optionValue={optionValue}
                                  goToChecklist={this.goToChecklist}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "WORK_HOURS") {
                            return (
                              <div
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToReport(notification.data.date)}
                              >
                                <NotifyTimeSheet
                                  notification={notification}
                                  optionValue={optionValue}
                                  // goToReport={this.goToReport}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }

                          if (notification.type === "ACTIVITY_TIMER") {
                            return (
                              <div
                                className="notify-temp-holder pointer"
                                onClick={() => this.goToReport(notification.data?.timesheet_date, notification.data?.timesheet_id)}
                              >
                                <NotifyTimeSheet
                                  notification={notification}
                                  optionValue={optionValue}
                                  // goToReport={this.goToReport}
                                  ddownOption={ddownOption}
                                  ddownOption2={ddownOption2}
                                  searchText={searchText}
                                />
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  </div>
                )}

              {/* $$ All other older you tab Notifications */}
              {yourNotification &&
                yourNotifications.olderNotification &&
                yourNotifications.olderNotification.length > 0 && (
                  <div className="notify-card-container">
                    <div className="notify-card-time-categ">Older</div>
                    <div className="notify-card-holder">
                      {yourNotifications.olderNotification &&
                        yourNotifications.olderNotification.map(
                          (notification) => {
                            if (
                              notification.type === "MENTIONS" ||
                              notification.type === "ACT" ||
                              notification.type === "COMMENT"
                            ) {
                              return (
                                <div
                                  className="notify-temp-holder pointer"
                                  key={`notification${notification.id}`}
                                >
                                  <CommentNotify
                                    optionValue={optionValue}
                                    notification={notification}
                                    goToActivity={this.goToActivity}
                                    delete={false}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                    updateShowDown={this.updateShowDown}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "DELETE_MENTIONED_COMMENT"
                            ) {
                              return (
                                <div
                                  key={notification.id}
                                  className="notify-temp-holder deleted"
                                >
                                  <CommentNotify
                                    notification={notification}
                                    optionValue={optionValue}
                                    delete={true}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                    updateShowDown={this.updateShowDown}
                                  />
                                </div>
                              );
                            }
                            if(notification.type === "LIKE_COMMENT") {
                              return (
                                <div
                                  key={notification.id}
                                  className="notify-temp-holder pointer"
                                  onClick={() => this.goToActivity(notification)}
                                >
                                  <LikeNotify
                                    notification={notification}
                                    goToActivity={this.goToActivity}
                                    optionValue={optionValue}
                                    delete={false}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                    updateShowDown={this.updateShowDown}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "PEOPLE" &&
                              notification.message &&
                              isUserAdded(notification.message) &&
                              notification.data.activity
                            ) {
                              return (
                                <div className="notify-temp-holder deleted">
                                  <NotifyAddedMember
                                    optionValue={optionValue}
                                    notification={notification}
                                    goToAddedMemberSection={
                                      this.goToAddedMemberSection
                                    }
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "PEOPLE" &&
                              notification.message &&
                              !isUserAdded(notification.message) &&
                              notification.data.activity
                            ) {
                              return (
                                <div className="notify-temp-holder">
                                  <NotifyRemovedMember
                                    optionValue={optionValue}
                                    notification={notification}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "PEOPLE" &&
                              notification.message &&
                              isUserAdded(notification.message) &&
                              notification.data.project
                            ) {
                              return (
                                <div className="notify-temp-holder">
                                  <NotifyAddedMember
                                    optionValue={optionValue}
                                    notification={notification}
                                    goToKanban={this.goToKanban}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "PEOPLE" &&
                              notification.message &&
                              !isUserAdded(notification.message) &&
                              notification.data.project
                            ) {
                              return (
                                <div className="notify-temp-holder">
                                  <NotifyRemovedMember
                                    optionValue={optionValue}
                                    notification={notification}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }
                            if (
                              notification.type === "PEOPLE" &&
                              notification.message &&
                              !isUserAdded(notification.message) &&
                              notification.data.customer
                            ) {
                              return (
                                <div className="notify-temp-holder">
                                  <NotifyRemovedMember
                                    optionValue={optionValue}
                                    notification={notification}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }

                            if (notification.type === "CHECKLIST_MENTIONS") {
                              return (
                                <div className="notify-temp-holder">
                                  <NotifyChecklist
                                    notification={notification}
                                    optionValue={optionValue}
                                    goToChecklist={this.goToChecklist}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }

                            if (notification.type === "WORK_HOURS") {
                              return (
                                <div
                                  className="notify-temp-holder pointer"
                                  onClick={() => this.goToReport(notification.data.date)}
                                >
                                  <NotifyTimeSheet
                                    notification={notification}
                                    optionValue={optionValue}
                                    // goToReport={this.goToReport}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }

                            if (notification.type === "ACTIVITY_TIMER") {
                              return (
                                <div
                                  className="notify-temp-holder pointer"
                                  onClick={() => this.goToReport(notification.data?.timesheet_date, notification.data?.timesheet_id)}
                                >
                                  <NotifyTimeSheet
                                    notification={notification}
                                    optionValue={optionValue}
                                    // goToReport={this.goToReport}
                                    ddownOption={ddownOption}
                                    ddownOption2={ddownOption2}
                                    searchText={searchText}
                                  />
                                </div>
                              );
                            }
                            return null;
                          }
                        )}
                    </div>
                  </div>
                )}

              {/* **********No notification section************* */}

              {optionValue === "you" &&
                yourNotification.length === 0 &&
                !uploadYourNotificationLoaderState.fetching &&
                !searchYourNotificationLoaderState.fetching &&
                !listOfFlaggedYouNotificationLoaderState.fetching && (
                  <div className="tab-content">
                    <p className="no-notify more-margin"> No {ddownOption === "View all" ? "Flagged " : ddownOption2 === "View unread" ? "Read " : ""}Notifications</p>
                  </div>
                )}

              {optionValue === "you" &&
                (noMoreSearchYouNotification === true ||
                  noMoreYouNotification === true ||
                  noMoreFlaggedYouNotification === true ||
                  noMoreReadYouNotification === true) &&
                yourNotification.length !== 0 && (
                  <div className="notify-show-more">
                    <div className="no-notification-holder">
                      No more {ddownOption === "View all" ? "Flagged " : ddownOption2 === "View unread" ? "Read " : ""}Notifications
                    </div>
                  </div>
                )}
            </div>
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
