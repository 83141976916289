import React, { useState, memo } from "react";
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, Tooltip, withHighcharts, XAxis, YAxis, Legend, ColumnSeries
} from 'react-jsx-highcharts';
// import { Scrollbar } from "react-scrollbars-custom";
import _ from "lodash";
// import moment from "moment";
// import { time_convert } from "../../helpers/generichelper";
import OutsideClickHandler from "react-outside-click-handler";

const ProjectStatus = ({
  sortBy,
  onChangeSortBy,
  currentWorkSpace,
  projectStatus
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const onShowDropdown = () => {
      setShowDropdown(!showDropdown);
  }
  const handleClickOutside = () => {
      setShowDropdown(false);
  }

  const options = [
    {id: 0, name: "All Projects"},
    {id: 1, name: "Most Critical"},
    {id: 2, name: "Moderately Critical"},
    {id: 3, name: "Least Critical"}
  ];

  const dropdownOptions = 
      options.map((workflow) => {
        return (
          <li
            className={
                sortBy.id === workflow.id
                ? "active"
                : ""
            }
            key={workflow.id}
            onClick={() => onChangeSortBy(workflow)}
            style={{ fontSize: "11px" }}
          >
            {workflow.name}
          </li>
        );
      });

      const projectNames = _.isArray(projectStatus) && projectStatus.map(proj => proj.project_name);
      const plannedHrs = _.isArray(projectStatus) && projectStatus.map(proj => Math.floor(proj.project_planned_hours));
      const spentHrs = _.isArray(projectStatus) && projectStatus.map(proj => Math.floor(proj.project_actual_hours));

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
          <div className="over-title">Project Status
          <span>{_.isArray(projectStatus) ? projectStatus.length : 0} Projects</span>
          </div>
          <OutsideClickHandler onOutsideClick={handleClickOutside}>
          <div
                className={
                  showDropdown
                    ? "workflow-change-ddown-holder clicked"
                    : "workflow-change-ddown-holder"
                }
                onClick={onShowDropdown}
              >
                <div className="workflow-change-ddown-text" style={{ fontSize: "11px" }}>
                  {sortBy.name}{" "}
                </div>
                {showDropdown && (
                    <div className="workflow-change-ddown" style={{ width: "160px" }}>
                      <ul className="workflow-change-ddown-inner">
                        {dropdownOptions}
                      </ul>
                    </div>
                )}
              </div>
                </OutsideClickHandler>
        </div>
    {_.isArray(projectStatus) && 
    // <Scrollbar>
        <div id="container">
    <HighchartsChart
    plotOptions={{
        series: {
            pointWidth: 20,
            events: {
              legendItemClick: function() {
                return false;
              }
          }
        }
    }}
    >
          <Chart
            height={200}
            styledMode={true}
            // width={projectStatus.length > 4 ? projectStatus.length*100 : ""}
            style={{ fontFamily: "roboto" }}
            scrollablePlotArea={{
                minWidth: projectStatus.length*100,
                scrollPositionX: 0,
                opacity: 1
            }}
            />
          <Tooltip
            className="proj-status-tooltip"
            padding={5}
            hideDelay={250} 
            shape="square"
            backgroundColor="#494949"
            borderWidth={2}
            borderColor="#494949"
            borderRadius={10}
            shared={true}
            outside={true}
            distance={5}
            shadow={false}
            useHTML={true}
            headerFormat={'<div style="min-width: 120px;"><span style=" font-size: 11px; font-family: roboto;">{point.key}</span><br/><br/>'}
            pointFormat={
                '<div style="display: flex; justify-content: space-between; width: 100%; font-size: 10px; margin-bottom: 5px;"><span>{series.name}</span><span>{point.y}hrs</span></div></div>'}
            style={{"color": "#f0f0f0", "fontSize": "10px", "whiteSpace": "nowrap", "borderRadius": "5px"}} />
          <Legend align="left" verticalAlign="top"
          y={-10}
          style={{ color: "#494949",
                  fontWeight: "normal",
                  fontSize: "11px" }} />

          <XAxis categories={projectNames} />

          <YAxis>
          <YAxis.Title>hours</YAxis.Title>
            <ColumnSeries name="Planned" data={plannedHrs} />
            <ColumnSeries name="Spent" data={spentHrs} />
          </YAxis>
        </HighchartsChart>
        </div>
        // </Scrollbar>
    }
        {typeof projectStatus === "object" &&
          projectStatus?.msg && (
            <div className="no-data">{projectStatus.msg}</div>
          )}
      </div>
    </React.Fragment>
  );
};

export default memo(withHighcharts(ProjectStatus, Highcharts));
