import React from "react";
import { activities } from "../../../constants/activitiescontants";

export default function FloatingSearchBox(props) {
  const { onInputChange, img, OnInputClicked, source } = props;
  return (
    <div className="edit-board-search-holder add-register-search-holder">
      <input
        type="text"
        onClick={OnInputClicked}
        className="edit-board-search-control add-register-search"
        placeholder={`${source === activities.fromActivity ? "Search" : "Search by name or email"}`}
        aria-label="Search"
        onChange={onInputChange}
        autoFocus={true}
      />
      <div className="edit-search-icon-holder">
        <img src={img} alt="search-icon" />
      </div>
    </div>
  );
}
