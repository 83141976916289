import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import React, { Component } from "react";
import Avatar from "react-avatar";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import { updateCustomerDetailsToAddUserAction } from "../../actions/workspace/workspaceaction";
import { getUserDetailsAction } from "../../actions/index";
import { isEmailValid } from "../../helpers/generichelper";
import { findUserLike } from "../../services/userapi";
// import { getProfileDetails } from "../../helpers/generichelper";
import { sentInviteToJoinWorkspace } from "../../services/workspaceapi";
import Plus_blue from "../../styles/img/svg/plus-blue.svg";
import search_field from "../../styles/img/svg/search-field.svg";
import delete_icon from "../../styles/img/svg/user-name-delete.svg";
import "../../styles/scss/joinWorkspace.scss";
import "../../styles/scss/organization.scss";
import MaterialButton from "../common/materialbutton";
import CommonNav from "./commonnav";
// import routesHelper from "../../router/routeshelper";
import FloatingSearchBox from "./partials/floatingsearchbox";
import SearchSuggestions from "./partials/searchsuggestions";
import firebase from "../../firebase";
import routesHelper from "../../router/routeshelper";

class AddUsersToWorkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredUsersOwners: [],
      filteredUsersMembers: [],
      selectedUsersOwners: [],
      selectedUsersMembers: [],
      classToShow: false,
      removedUserOwnerId: null,
      removedUserMemberId: null,
      workspaceName: null,
      removeUserFlag: false
    };
    this.onInputChangeOwner = this.onInputChangeOwner.bind(this);
    this.onInputChangeMember = this.onInputChangeMember.bind(this);
    this.onAddingUserOwner = this.onAddingUserOwner.bind(this);
    this.onAddingUserMember = this.onAddingUserMember.bind(this);
    this.clickOnOutsideDiv = this.clickOnOutsideDiv.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInviteUser = this.onInviteUser.bind(this);
  }

  // componentWillMount(){
  //   if(!_.isUndefined(localStorage.getItem('customer_auth_token')) && !_.isNull(localStorage.getItem('data'))){
  //     this.props.router.push('/activity')
  //   }
  // }

  /*componentDidMount() {
    const { workspace } = this.props;

    const existedOwnersId = [];
    const existedMembersId = [];
    if (workspace && workspace.users) {
      this.setState({
        workspaceName: workspace.display_name
      });
      Object.keys(workspace.users).forEach(function eachKey(key) {
        // console.log(workspace.users[key]);
        if (workspace.users[key] === "SUPERADMIN") {
          // //console.log(key);
          existedOwnersId.push(key);
        }
        if (workspace.users[key] === "USER") {
          // //console.log(key);
          existedMembersId.push(key);
        }
      });
      //console.log(existedOwnersId);
      const existedOwnersQueryParams = existedOwnersId.reduce((acc, curr, index) => {
        if(index === 0) return acc;
        return acc + `&id=${curr}`;
      }, `?id=${existedOwnersId[0]}`);
      const existedMembersQueryParams = existedMembersId.length > 0 ? existedMembersId.reduce((acc, curr, index) => {
        if(index === 0) return acc;
        return acc + `&id=${curr}`;
      }, `?id=${existedMembersId[0]}`) : null;

      //console.log(existedOwnersQueryParams);

      getUserDetails({ queryparams: existedOwnersQueryParams }, (response) => {
        // //console.log(response);
        this.setState({
          selectedUsersOwners: response
        });
      });
      if(existedMembersQueryParams)
      getUserDetails({ queryparams: existedMembersQueryParams }, (response) => {
        // console.log("getUserDetails",response);
        this.setState({
          selectedUsersMembers: response
        });
      });
    }
  }*/

  static getDerivedStateFromProps(props, state) {
    const { workspace, getUserDetailsAction, owners, members } = props;
    const existedOwnersId = [];
    const existedMembersId = [];
    if ((workspace && workspace.users && !state.workspaceName) ||
        (owners.length > 0 && state.selectedUsersOwners.length === 0 && !state.removeUserFlag) ||
        (members.length > 0 && state.selectedUsersMembers.length === 0 && !state.removeUserFlag) ) {
      Object.keys(workspace.users).forEach(function eachKey(key) {
        // console.log(workspace.users[key]);
        if (workspace.users[key] === "SUPERADMIN") {
          // //console.log(key);
          existedOwnersId.push(key);
        }
        if (workspace.users[key] === "USER") {
          // //console.log(key);
          existedMembersId.push(key);
        }
      });
      // console.log(existedMembersId);
      const existedOwnersQueryParams = existedOwnersId.reduce((acc, curr, index) => {
        if(index === 0) return acc;
        return acc + `&id=${curr}`;
      }, `?id=${existedOwnersId[0]}`);
      const existedMembersQueryParams = existedMembersId.length > 0 ? existedMembersId.reduce((acc, curr, index) => {
        if(index === 0) return acc;
        return acc + `&id=${curr}`;
      }, `?id=${existedMembersId[0]}`) : null;

      // console.log(existedMembersQueryParams);
      if(owners.length === 0)
      getUserDetailsAction({ queryparams: existedOwnersQueryParams, type: "OWNERS" }, (response) => {
        // console.log(response);
      });
      if(existedMembersQueryParams && members.length === 0)
      getUserDetailsAction({ queryparams: existedMembersQueryParams, type: "MEMBERS" }, (response) => {
        // console.log("getUserDetails",response);
      });
      return {
        ...state,
        workspaceName: workspace.display_name,
        selectedUsersOwners: owners,
        selectedUsersMembers: members,
      }
    }
    return null;
  }

  onAddingUserOwner(user) {
    if (user) {
      firebase.analytics().logEvent("GTD_Registration_Flow_Add_Owner");
      this.setState({
        selectedUsersOwners: [...this.state.selectedUsersOwners, user],
        filteredUsersOwners: [],
        classToShowOwner: false
      });
    }
  }
  onInviteUser(user) {
    const { workspace } = this.props;
    const customer_id = workspace.id;
    const email = user.email;
    const auth_token = localStorage.getItem("auth_token");
    sentInviteToJoinWorkspace(
      { auth_token, customer_id, email },
      (response) => {
        if (response) {
          firebase.analytics().logEvent("GTD_Registration_Flow_Add_Invite_Member_Success");
          // //console.log(response);
          this.setState({
            classToShowOwner: false,
            classToShowMember: false,
            filteredUsersOwners: [],
            filteredUsersMembers: []
          });
        }
      }
    );
  }
  onInputChangeOwner(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const key = e.target.value;
    findUserLike({ key }, (response) => {
      if (response.length > 0) {
        const completedAddedUsers = _.unionBy(
          this.state.selectedUsersOwners,
          this.state.selectedUsersMembers,
          "id"
        );
        const filteredArray = _.differenceBy(
          response,
          completedAddedUsers,
          "id"
        );
        //console.log("filtered array",filteredArray);
        this.setState({
          filteredUsersOwners: filteredArray
        });
      } else {
        if (key.length > 0 && isEmailValid(key)) {
          this.setState({
            filteredUsersOwners: [{ email: key }]
          });
        } else {
          this.setState({
            filteredUsersOwners: []
          });
        }
      }
    });
  }
  // difference function
  onInputChangeMember(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const key = e.target.value;
    findUserLike({ key }, (response) => {
      if (response.length) {
        const completedAddedUsers = _.unionBy(
          this.state.selectedUsersOwners,
          this.state.selectedUsersMembers,
          "id"
        );
        const filteredArray = _.differenceBy(
          response,
          completedAddedUsers,
          "id"
        );
        this.setState({
          filteredUsersMembers: filteredArray
        });
      } else {
        if (key.length > 0 && isEmailValid(key)) {
          this.setState({
            filteredUsersMembers: [{ email: key }]
          });
        } else {
          this.setState({
            filteredUsersMembers: []
          });
        }
      }
    });
  }

  onAddingUserMember(user) {
    if (user) {
      firebase.analytics().logEvent("GTD_Registration_Flow_Add_Member");
      this.setState({
        selectedUsersMembers: [...this.state.selectedUsersMembers, user],
        filteredUsersMembers: [],
        classToShowMember: false
      });
    }
  }

  clickOnOutsideDiv(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      classToShowMember: false,
      classToShowOwner: false,
      removedUserId: null,
      filteredUsersOwners: [],
      filteredUsersMembers: []
    });
    //console.log(this.state.classToShowMember);
  }

  OnInputClicked(e) {
    e.stopPropagation();
  }

  removeFromSelectedOwner(e, user) {
    e.stopPropagation();
    this.setState({
      removedUserOwnerId: user.id
    });
  }
  removeFromSelectedMember(e, user) {
    e.stopPropagation();
    this.setState({
      removedUserMemberId: user.id
    });
  }

  removeFromSelectedOwnerArray(e, user) {
    e.preventDefault();
    e.stopPropagation();
    const filteredArray = this.state.selectedUsersOwners.filter(
      (selectedUsersOwner) => selectedUsersOwner.id !== user.id
    );
    // //console.log("from the function", filteredArray);
    this.setState({
      selectedUsersOwners: filteredArray,
      removedUserOwnerId: null,
      removeUserFlag: true
    });
  }
  removeFromSelectedOwnerCancel(e, user) {
    e.preventDefault();
    //console.log("here");
    this.setState({
      removedUserOwnerId: null,
      removedUserMemberId: null
    });
  }

  removeFromSelectedMemberArray(e, user) {
    e.preventDefault();
    e.stopPropagation();
    const filteredArray = this.state.selectedUsersMembers.filter(
      (selectedUsersMember) => selectedUsersMember.id !== user.id
    );
    // //console.log("from the function", filteredArray);
    this.setState({
      selectedUsersMembers: filteredArray,
      removedUserMemberId: null,
      removeUserFlag: true
    });
  }

  onFormSubmit(e) {
    const { workspace, updateCustomerDetailsAction, router } = this.props;
    const auth_token = localStorage.getItem("auth_token");
    const usersOwners = this.state.selectedUsersOwners.reduce(
      (acc, selectedUserOwner) => {
        return Object.assign(acc, { [selectedUserOwner.id]: "SUPERADMIN" });
      },
      {}
    );
    // //console.log(usersOwners);
    const userMembers = this.state.selectedUsersMembers.reduce(
      (acc, selectedUserMember) => {
        return Object.assign(acc, { [selectedUserMember.id]: "USER" });
      },
      {}
    );
    // //console.log(userMembers);
    const userArray = { ...usersOwners, ...userMembers };
    // //console.log(userArray);
    updateCustomerDetailsAction(
      { auth_token, users: userArray, id: workspace.id },
      (response) => {
        //console.log("from on submit", response);
        if (response && response.msg && response.msg === "Success")
          router.push(routesHelper.getProjectCreatePath());
      }
    );
  }

  render() {
    const { router, workspace } = this.props;
    // **********
    const renderSuggestionOwnerList = this.state.filteredUsersOwners.map(
      (filteredUsersMember) => {
        return (
          <SearchSuggestions
            key={filteredUsersMember.id || filteredUsersMember.email}
            user={filteredUsersMember}
            onAddingUser={this.onAddingUserOwner}
            onInviteUser={this.onInviteUser}
            OnInputClicked={this.OnInputClicked}
          ></SearchSuggestions>
        );
      }
    );

    const renderSuggestionMemberList = this.state.filteredUsersMembers.map(
      (filteredUsersMember) => {
        return (
          <SearchSuggestions
            key={filteredUsersMember.id || filteredUsersMember.email}
            user={filteredUsersMember}
            onAddingUser={this.onAddingUserMember}
            onInviteUser={this.onInviteUser}
            OnInputClicked={this.OnInputClicked}
          ></SearchSuggestions>
        );
      }
    );

    const renderSelectedUsersOwnerAvatar = this.state.selectedUsersOwners.map(
      (selectedUserOwner) => {
        // console.log("selectedUserOwner", selectedUserOwner);
        if (selectedUserOwner) {
          return (
            <div
              className={
                selectedUserOwner.id === workspace.super_admin_id
                  ? "user-img-holder workspace-owner"
                  : "user-img-holder workspace-member"
              }
              key={selectedUserOwner.id}
            >
              <Tooltip title={selectedUserOwner.first_name}>
                {(_.isNull(selectedUserOwner.img) ||
                  _.isEmpty(selectedUserOwner.img)) ||
                  selectedUserOwner.img ===
                  "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
                  (selectedUserOwner.img &&
                    selectedUserOwner.img.trim() ===
                    "https://s3.amazonaws.com/gtd/users/default.png") ? (
                    <div>
                      <Avatar
                        style={{ width: "100%", height: "100%" }}
                        name={selectedUserOwner.first_name}
                        maxInitials={1}
                      ></Avatar>
                    </div>
                  ) : (
                    <img src={selectedUserOwner.img} alt="owner-img" />
                  )}
              </Tooltip>
              <div className="user-img-delete">
                <img
                  src={delete_icon}
                  alt="Delete Icon"
                  onClick={(e) =>
                    this.removeFromSelectedOwner(e, selectedUserOwner)
                  }
                />
              </div>
              {this.state.removedUserOwnerId === selectedUserOwner.id && (
                <OutsideClickHandler
                  onOutsideClick={(e) =>
                    this.removeFromSelectedOwnerCancel(e, selectedUserOwner)
                  }
                >
                  <div className="edit-board-ddown remove-popup">
                    <div className="confirmation-box-remove">
                      <p className="user-name">
                        {" "}
                        Remove {selectedUserOwner.first_name}
                      </p>
                      <hr className="divider-remove-box"></hr>
                      <p className="message-remove-box">
                        Are you sure you want to remove{" "}
                        {selectedUserOwner.first_name} from the workspace ?
                      </p>
                      <div className="button-box">
                        <button
                          className="cancel-button-remove-box"
                          onClick={(e) =>
                            this.removeFromSelectedOwnerCancel(
                              e,
                              selectedUserOwner
                            )
                          }
                        >
                          Cancel
                        </button>
                        <button
                          className="confirm-button-remove-box"
                          onClick={(e) =>
                            this.removeFromSelectedOwnerArray(
                              e,
                              selectedUserOwner
                            )
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          );
        } else return null;
      }
    );
    const renderSelectedUsersMemberAvatar = this.state.selectedUsersMembers.map(
      (selectedUserMember) => {
        return (
          <div
            className="user-img-holder workspace-member"
            key={selectedUserMember.id}
          >
            <Tooltip title={selectedUserMember.first_name}>
              {(_.isNull(selectedUserMember.img) ||
                _.isEmpty(selectedUserMember.img)) ||
                selectedUserMember.img ===
                "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
                (selectedUserMember.img &&
                  selectedUserMember.img.trim() ===
                  "https://s3.amazonaws.com/gtd/users/default.png") ? (
                  <div>
                    <Avatar
                      // color={Avatar.getRandomColor("sitebase", [
                      //   "#ff9292",
                      //   "#7c9af1"
                      // ])}
                      style={{ width: "100%", height: "100%" }}
                      name={selectedUserMember.first_name}
                      maxInitials={1}
                    ></Avatar>
                  </div>
                ) : (
                  <img src={selectedUserMember.img} alt="user-member" />
                )}
            </Tooltip>
            <div className="user-img-delete">
              <img
                src={delete_icon}
                alt="Delete Icon"
                onClick={(e) =>
                  this.removeFromSelectedMember(e, selectedUserMember)
                }
              />
            </div>
            {this.state.removedUserMemberId === selectedUserMember.id && (
              <OutsideClickHandler
                onOutsideClick={(e) =>
                  this.removeFromSelectedOwnerCancel(e, selectedUserMember)
                }
              >
                <div className="edit-board-ddown remove-popup">
                  <div className="confirmation-box-remove">
                    <p className="user-name">
                      {" "}
                      Remove {selectedUserMember.first_name}
                    </p>
                    <hr className="divider-remove-box"></hr>
                    <p className="message-remove-box">
                      Are you sure you want to remove{" "}
                      {selectedUserMember.first_name} from the workspace ?
                    </p>
                    <div className="button-box">
                      <button
                        className="cancel-button-remove-box"
                        onClick={(e) =>
                          this.removeFromSelectedOwnerCancel(
                            e,
                            selectedUserMember
                          )
                        }
                      >
                        Cancel
                      </button>
                      <button
                        className="confirm-button-remove-box"
                        onClick={(e) =>
                          this.removeFromSelectedMemberArray(
                            e,
                            selectedUserMember
                          )
                        }
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        );
      }
    );

    return (
      <Scrollbar className="add-users-scroll">
        <div className="joinWorkspace-container">
          <div>
            <CommonNav router={router} />
          </div>
          <div className="join-container">
            <div className="join-header">
              <p className="join-title">
                {/* $$ Here the name of the workspace should appear*/}
                Add or Invite people to {this.state.workspaceName} workspace
              </p>
            </div>
            <div className="join-line"></div>
            <div
              className="adduser-conatainer"
              onClick={this.clickOnOutsideDiv}
            >
              <div className="addusers-workspace">
                <div className="adduser-superadmins">
                  <span className="adduser-label">
                    Owners ({this.state.selectedUsersOwners.length})
                  </span>
                  <span className="adduser-access">
                    Owners have access to all data in the workspace
                  </span>
                  <div className="user-row-ddown">
                    {renderSelectedUsersOwnerAvatar}
                    <div className="user-add">
                      <img
                        src={Plus_blue}
                        alt="Add User"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({
                            classToShowOwner: true,
                            classToShowMember: false
                          });
                        }}
                      />
                      {/* ***************** */}
                      {this.state.classToShowOwner && (
                        <div className="edit-board-ddown">
                          <FloatingSearchBox
                            img={search_field}
                            onInputChange={this.onInputChangeOwner}
                            OnInputClicked={this.OnInputClicked}
                          ></FloatingSearchBox>
                          {this.state.filteredUsersOwners.length > 0 && (
                            <div className="search-data-container more-list">
                              {renderSuggestionOwnerList}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="adduser-members">
                  <span className="adduser-label">
                    Members ({this.state.selectedUsersMembers.length})
                  </span>
                  <span className="adduser-access">
                    Members have access only to their data in the workspace
                  </span>
                  <div className="user-row-ddown">
                    <div className="user-row-ddown">
                      {renderSelectedUsersMemberAvatar}
                      <div className="user-add">
                        <img
                          src={Plus_blue}
                          alt="Add User"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                              classToShowMember: true,
                              classToShowOwner: false
                            });
                          }}
                          role="button"
                        />
                        {this.state.classToShowMember && (
                          <div className="edit-board-ddown">
                            <FloatingSearchBox
                              img={search_field}
                              onInputChange={this.onInputChangeMember}
                              OnInputClicked={this.OnInputClicked}
                            ></FloatingSearchBox>
                            {this.state.filteredUsersMembers.length > 0 && (
                              <div className="search-data-container more-list">
                                {renderSuggestionMemberList}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="addUser-btn for-adding-project">
              <MaterialButton
                buttonText="Proceed"
                variant="contained"
                className="active-proceed"
                color="primary"
                onClick={this.onFormSubmit}
              ></MaterialButton>
            </div>
          </div>
          <div className="payment-subscription-alert">
            {this.state.selectedUsersOwners.length +
              this.state.selectedUsersMembers.length >
              5
              ? "For more than 5 people, it is just $9 per user/month."
              : "Free for 5 people for life. No credit card required."}
          </div>
        </div>
      </Scrollbar>
      // </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workspace: state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    owners: state.wrorkSpaceReducer.owners,
    members: state.wrorkSpaceReducer.members
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCustomerDetailsAction: (auth_token, users, id) =>
      dispatch(updateCustomerDetailsToAddUserAction(auth_token, users, id)),
    getUserDetailsAction: ({ queryparams, type }) =>
      dispatch(getUserDetailsAction({ queryparams, type }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsersToWorkspace);
