import { secureFetch } from "../helpers/fetchhelper";

export const getRandomImage = callback => {
  secureFetch("https://api.unsplash.com/photos/random?featured", {
    method: "GET",
    headers: {
      Authorization:
        "Client-ID 86f0e0cb861521013c995a33c66b40bcb804060a5bbffc8180f82bfcb1a95b08"
    }
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      callback(error);
    });
};

export const getListOfImages = (pageNumber, callback) => {
  secureFetch(
    `https://api.unsplash.com/photos?page=${pageNumber}&per_page=50`,
    {
      method: "GET",
      headers: {
        Authorization:
          "Client-ID 86f0e0cb861521013c995a33c66b40bcb804060a5bbffc8180f82bfcb1a95b08"
      }
    }
  )
    .then(respJson => callback(respJson))
    .catch(error => {
      callback(error);
    });
};

export const searchPhotos = (content, pageNumber, callback) => {
  secureFetch(
    `https://api.unsplash.com/photos?page=${pageNumber}&per_page=15&query=${content}`,
    {
      method: "GET",
      headers: {
        Authorization:
          "Client-ID 86f0e0cb861521013c995a33c66b40bcb804060a5bbffc8180f82bfcb1a95b08"
      }
    }
  )
    .then(respJson => callback(respJson))
    .catch(error => {
      callback(error);
    });
};
