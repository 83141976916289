import { commentsConstant } from "../../constants/commentsconstants";
import { sortComments } from "../../helpers/generichelper";
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

const initialState = {
  getCommentsLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  addCommentLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  editCommentLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  replyCommentLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  comments: [],
  searchedComments: [],
  archivedComments: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case commentsConstant.getCommentsLoaderState:
      return {
        ...state,
        getCommentsLoaderState: action.payload.state
      };
    case commentsConstant.addCommentLoaderState:
      return {
        ...state,
        addCommentLoaderState: action.payload.state
      };
    case commentsConstant.editCommentLoaderState:
      return {
        ...state,
        editCommentLoaderState: action.payload.state
      };
    case commentsConstant.replyCommentLoaderState:
      return {
        ...state,
        replyCommentLoaderState: action.payload.state
      };
    case commentsConstant.getComments:
      
      let activeComments = [...action.payload.comments];
      activeComments = _.filter(
        activeComments,
        comment => comment.is_active
      ).reverse();
      const sortedComments = sortComments(activeComments);
      if(action.payload.pageNumber && action.payload.pageNumber!==1){
        return {
          ...state,
          comments: [...state.comments ].concat(sortedComments)
        };
       
      }else{
        return {
          ...state,
          comments: sortedComments
        };
       
      }

    case commentsConstant.searchComments:
      let activeSearchedComments = [...action.payload.comments];
      activeSearchedComments = _.filter(
        activeSearchedComments,
        comment => comment.is_active
      ).reverse();
      const sortedSearchedComments = sortComments(activeSearchedComments);
      if(action.payload.pageNumber && action.payload.pageNumber!==1){
        return {
          ...state,
          searchedComments: [...state.searchedComments ].concat(sortedSearchedComments)
        };
      } else
          return {
            ...state,
            searchedComments: sortedSearchedComments
          }

    case commentsConstant.getArchivedComments:
      return {
        ...state,
        archivedComments: _.uniqBy([ ...action.payload.comments ], 'id')
      };
    
    case commentsConstant.getMoreArchivedComments:
      return {
        ...state,
        archivedComments: _.uniqBy([ ...state.archivedComments, ...action.payload.comments ], 'id')
      }
    case commentsConstant.unArchiveComment:
      return {
        ...state,
        archivedComments: state.archivedComments.filter(comm => comm.id !== action.payload.comment.id)
      }
      // let sortedComments = _.sortBy(activeComments,function(o) { return new moment(o.created_at); }).reverse();
      
    case commentsConstant.addComment:
      let newState = { ...state };
      newState.comments = [action.payload.comment].concat(newState.comments);
      let sortedCommentsAfterAdd = sortComments(newState.comments);
      let sortedSearchedCommentsAfterAdd = [];
      if(newState.searchedComments.length > 0) {
        newState.searchedComments = [action.payload.comment].concat(newState.searchedComments);
        sortedSearchedCommentsAfterAdd = sortComments(newState.searchedComments);
      }
      return {
        ...newState,
        comments: sortedCommentsAfterAdd,
        searchedComments: sortedSearchedCommentsAfterAdd
      };

    case commentsConstant.addAttachments:
      return {
        ...state,
        comments: state.comments.map(comment => {
          if(comment.id === action.payload.commentId)
            return { ...comment, attachments: action.payload.attachments };
          return comment;
        }),
        searchedComments: state.searchedComments.map(comment => {
          if(comment.id === action.payload.commentId)
            return { ...comment, attachments: action.payload.attachments };
          return comment;
        })
      }

    case commentsConstant.deleteComment:
      if(action.payload.comment.data.commentDetails.isReply)
      return {
        ...state,
        comments: [
          ...state.comments.filter(
            comment => comment.id !== action.payload.commentId
          )
        ],
        searchedComments: [
          ...state.searchedComments.filter(
            comment => comment.id !== action.payload.commentId
          )
        ]
      };
      else 
      return {
        ...state,
        comments: [
          ...state.comments.filter(
            comment => comment.id !== action.payload.commentId
          ).filter(comment => {
            if(comment.data.commentDetails.isReply) {
              if(comment.data.commentDetails.parentCommentId !== action.payload.commentId) {
                return comment;
              }
            }
            if(!comment.data.commentDetails.isReply) return comment;
          })
        ],
        searchedComments: [
          ...state.searchedComments.filter(
            comment => comment.id !== action.payload.commentId
          ).filter(comment => {
            if(comment.data.commentDetails.isReply) {
              if(comment.data.commentDetails.parentCommentId !== action.payload.commentId) {
                return comment;
              }
            }
            if(!comment.data.commentDetails.isReply) return comment;
          })
        ]
      }
    case commentsConstant.editComment:
      let newEditState = { ...state };
      let indexNo;
      _.forEach(newEditState.comments, (comment, i) => {
        if (comment.id === action.payload.comment.id) {
          indexNo = i;
        }
      });
      newEditState.comments[indexNo] = { ...newEditState.comments[indexNo], ...action.payload.comment };
      if(newEditState.searchedComments.length > 0) {
        _.forEach(newEditState.searchedComments, (comment, i) => {
          if (comment.id === action.payload.comment.id) {
            indexNo = i;
          }
        });
        newEditState.searchedComments[indexNo] = { ...newEditState.searchedComments[indexNo], ...action.payload.comment };
      }
      return {
        ...newEditState
      };
    case commentsConstant.updateLikeBookmarkComment:
      return {
        ...state,
        comments: state.comments.map(comment => {
          if(comment.id === action.payload.comment.id) 
            return {
              ...comment,
              ...action.payload.comment 
            };
          else return comment;
        }),
        searchedComments: state.searchedComments.map(comment => {
          if(comment.id === action.payload.comment.id) 
            return {
              ...comment,
              ...action.payload.comment 
            };
          else return comment;
        })
      };
      case commentsConstant.clearComments:
        return {
          ...state,
          comments: [],
          searchedComments: []
        } 
      case commentsConstant.clearSearchedComments:
        return {
          ...state,
          searchedComments: []
        }
    case commentsConstant.clearArchivedComments:
      return {
        ...state,
        archivedComments: []
      } 
    default:
      return {
        ...state
      };
  }
};
