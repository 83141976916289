import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "../../styles/scss/bootstrap.scss";
import "../../styles/scss/daterangepicker.scss";

const DateRangePickers = props => {
  const onShowCalender = () => {
    // TODO document why this arrow function is empty
  
    
  };

  const completedActivityProps = {
    ranges: {
      "Today": [
        moment(),
        moment()
      ],
      "Yesterday - Today": [
        moment().subtract(1, "day"),
        moment()
      ],
      "Last 7 days": [
        moment().subtract(6, "day"),
        moment()
      ],
      "Last 14 days": [
        moment().subtract(13, "day"),
        moment()
      ],
      "Last month": [
        moment().subtract(1, "month"),
        moment()
      ],
      "Last 3 months": [
        moment().subtract(3, "month"),
        moment()
      ],
      "Last year": [
        moment().subtract(1, "year"),
        moment()
      ]
    }
  }
  if(props.source === "ADD_ACTIVITY" && props.allowedDays) {
    completedActivityProps.minDate = moment().subtract(props.allowedDays, "day");
    delete completedActivityProps.ranges
  }

  const editReportProps = {
    maxDate: props.maxDate && moment(props.maxDate).format("ddd, DD MMM YY")
  };
  if(props.source === "EDIT_ACTIVITY" && props.allowedDays) {
    editReportProps.minDate = moment().subtract(props.allowedDays, "day").isBefore(props.startDate) ?
      moment().subtract(props.allowedDays, "day") : moment(props.startDate);
    editReportProps.maxDate = moment(props.endDate);
  }

  if(props.source === "ADD_ACTIVITY") {
    if(!props.isCompleted) {
      if (parseInt(props.startDate) && parseInt(props.endDate))
        return (
          <DateRangePicker
            opens="right"
            drops="auto"
            locale={{ format: "ddd, DD MMM YY" }}
            ranges={{
              "Today": [
                moment(),
                moment()
              ],
              "Today - Tomorrow": [
                moment(),
                moment().add(1, "day")
              ],
              "Next 7 days": [
                moment(),
                moment().add(6, "day")
              ],
              "Next 14 days": [
                moment(),
                moment().add(13, "day")
              ],
              "Next month": [
                moment(),
                moment().add(1, "month")
              ],
              "Next 3 months": [
                moment(),
                moment().add(3, "month")
              ],
              "Next year": [
                moment(),
                moment().add(1, "year")
              ],
              "Continuous": [
                moment(),
                moment().add(5, "year")
              ]
            }}
            //minDate={moment(props.startDate).format("M/DD/YYYY")}
            // maxDate={props.maxDate && moment(props.maxDate).format("DD MMM YYYY")}
            startDate={moment(props.startDate).format("ddd, DD MMM YY")}
            endDate={moment(props.endDate).format("ddd, DD MMM YY")}
            onApply={props.onChangeDate}
          >
            {props.children}
          </DateRangePicker>
        )
      else
        return (
          <DateRangePicker
            opens="right"
            drops="auto"
            locale={{ format: "ddd, DD MMM YY" }}
            ranges={{
              "Today": [
                moment(),
                moment()
              ],
              "Today - Tomorrow": [
                moment(),
                moment().add(1, "day")
              ],
              "Next 7 days": [
                moment(),
                moment().add(6, "day")
              ],
              "Next 14 days": [
                moment(),
                moment().add(13, "day")
              ],
              "Next month": [
                moment(),
                moment().add(1, "month")
              ],
              "Next 3 months": [
                moment(),
                moment().add(3, "month")
              ],
              "Next year": [
                moment(),
                moment().add(1, "year")
              ],
              "Continuous": [
                moment(),
                moment().add(5, "year")
              ]
            }}
            //minDate={moment(props.startDate).format("M/DD/YYYY")}
            // maxDate={props.maxDate && moment(props.maxDate).format("DD MMM YYYY")}
            onApply={props.onChangeDate}
          >
            {props.children}
          </DateRangePicker>
        )
    } else {
      if (parseInt(props.startDate) && parseInt(props.endDate))
        return (
          <DateRangePicker
            {...completedActivityProps}
            opens="right"
            drops="auto"
            locale={{ format: "ddd, DD MMM YY" }}
            maxDate={props.maxDate && moment(props.maxDate).format("ddd, DD MMM YY")}
            startDate={moment(props.startDate).format("ddd, DD MMM YY")}
            endDate={moment(props.endDate).format("ddd, DD MMM YY")}
            onApply={props.onChangeDate}
          >
            {props.children}
          </DateRangePicker>
        )
      else
        return (
          <DateRangePicker
            {...completedActivityProps}
            opens="right"
            drops="auto"
            locale={{ format: "ddd, DD MMM YY" }}
            maxDate={props.maxDate && moment(props.maxDate).format("ddd, DD MMM YY")}
            onApply={props.onChangeDate}
          >
            {props.children}
          </DateRangePicker>
        )
    }
  }
  else if(props.source === "EDIT_ACTIVITY") {
    return (
      <DateRangePicker
      {...editReportProps}
      singleDatePicker={true}
      autoApply={true}
      locale={{ format: "ddd, DD MMM YY", customRangeLabel: "Custom date" }}
      startDate={moment(props.startDate).format("ddd, DD MMM YY")}
      endDate={moment(props.endDate).format("ddd, DD MMM YY")}
      onApply={props.onChangeDate}
      opens="right"
      drops="auto"
    >
      {props.children}
    </DateRangePicker>
    )
  }

  else if (props.source === "DASHBOARD")
    return (
      <DateRangePicker
        onShow={onShowCalender}
        opens="right"
        locale={{ format: "ddd, DD MMM YY", customRangeLabel: "Custom range" }}
        ranges={{
          "Today": [
            moment(),
            moment()
          ],
          "Yesterday": [
            moment().subtract(1, "day"),
            moment().subtract(1, "day")
          ],
          "Last 7 days": [
            moment().subtract(6, "days"),
            moment()
          ],
          "Last 30 days": [
            moment().subtract(29, "days"),
            moment()
          ],
          "This month": [
            moment().startOf("month"),
            moment().endOf("month")
          ],
          "Last month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month")
          ],
        }}
        //minDate={moment(props.startDate).format("M/DD/YYYY")}
        maxDate={props.maxDate && moment(props.maxDate).format("ddd, DD MMM YY")}
        startDate={moment(props.startDate).format("ddd, DD MMM YY")}
        endDate={moment(props.endDate).format("ddd, DD MMM YY")}
        onApply={props.onChangeDate}
      >
        {props.children}
      </DateRangePicker>
    );
  else if (props.source === "MISSED_TIME_SHEET") {
    return (
    <DateRangePicker
      singleDatePicker={true}
      autoApply={true}
      locale={{ format: "ddd, DD MMM YY", customRangeLabel: "Custom date" }}
      maxDate={props.maxDate && moment(props.maxDate).format("ddd, DD MMM YY")}
      startDate={moment(props.startDate).format("ddd, DD MMM YY")}
      endDate={moment(props.endDate).format("ddd, DD MMM YY")}
      onApply={props.onChangeDate}
      opens="left"
      ranges={{
        "Today": [
          moment(),
          moment()
        ],
        "Yesterday": [
          moment().subtract(1, "day"),
          moment().subtract(1, "day")
        ]
      }}
    >
      {props.children}
    </DateRangePicker>
    )
  } else if (props.source === "CHECKLIST_ITEM") {
    if (props.startDate && props.endDate) 
      return (
        <DateRangePicker
          singleDatePicker={true}
          autoApply={true}
          locale={{ format: "ddd, DD MMM YY" }}
          startDate={props.startDate ? moment(props.startDate).format("ddd, DD MMM YY") : null}
          endDate={props.endDate ? moment(props.endDate).format("ddd, DD MMM YY") : null}
          onApply={props.onChangeDate}
          opens="right"
          drops="auto"
        >
          {props.children}
        </DateRangePicker>
      )
    else return (
      <DateRangePicker
        singleDatePicker={true}
        autoApply={true}
        locale={{ format: "ddd, DD MMM YY" }}
        onApply={props.onChangeDate}
        opens="right"
        drops="auto"
      >
        {props.children}
      </DateRangePicker>
      )
  }
  else if (parseInt(props.startDate) && parseInt(props.endDate))
    return (
      <DateRangePicker
        onShow={onShowCalender}
        opens="right"
        locale={{ format: "DD MMM YYYY" }}
        //minDate={moment(props.startDate).format("M/DD/YYYY")}
        maxDate={props.maxDate && moment(props.maxDate).format("DD MMM YYYY")}
        startDate={moment(props.startDate).format("DD MMM YYYY")}
        endDate={moment(props.endDate).format("DD MMM YYYY")}
        onApply={props.onChangeDate}
      >
        {props.children}
      </DateRangePicker>
    );
  else
    return (
      <DateRangePicker
        onShow={onShowCalender}
        opens="right"
        locale={{ format: "DD MMM YYYY" }}
        //minDate={moment(props.startDate).format("M/DD/YYYY")}
        maxDate={props.maxDate && moment(props.maxDate).format("DD MMM YYYY")}
        // startDate={moment(props.startDate).format("M/DD/YYYY")}
        // endDate={moment(props.endDate).format("M/DD/YYYY")}
        onApply={props.onChangeDate}
      >
        {props.children}
      </DateRangePicker>
    );
};

export default DateRangePickers;
