import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";


const ID="id";

export const getTimeSheets = ({ auth_token = null, queryParam }, callback) => {
  let url = "/api/v1/timesheet";
  let queryParams = queryParam ? `${queryParam}&is_archived=false` : "";
  // console.log(API_URL + url + queryParams);
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const deleteTimeSheet = ({ auth_token = null, body }, callback) => {
  let userid=localStorage.getItem(ID);
  let url = "/api/v1/new-timesheet?logged_in_user=" + parseInt(userid);
  secureFetch(API_URL + url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    }),
    body: JSON.stringify(body)
  })
    .then(respJson => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const editTimeSheet = ({ body }, callback) => {
  let userid=localStorage.getItem(ID);
  let url = "/api/v1/new-timesheet?logged_in_user=" + parseInt(userid);
  secureFetch(API_URL + url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    }),
    body: JSON.stringify(body)
  })
    .then(respJson => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export function updateActivityReport({ body }, callback) {
  let userid=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid);
  //console.log(title);
  body["performed_by"]=JSON.parse(userid)
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch(err => callback(err));
}

export const  getAllActivityType=({param},callback) => {
  let url = API_URL + "/api/v1/activity-types"+param;
  secureFetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export const  getWpRemovedMembers=(callback) => {
  let url = API_URL + "/api/v1/members-dropdown";
  secureFetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export const  getReport=(reportdata,callback) => {
  let url = API_URL + "/api/v1/timesheet-excel-report";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify(reportdata),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}
