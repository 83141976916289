import React from "react";
import GTDLogo from "../../styles/img/svg/group.svg";
import SmallArrow from "../../styles/img/svg/small-arrow.svg";
import PlayStoreLogo from "../../styles/img/svg/androidplay.svg";
import AppStoreLogo from "../../styles/img/svg/appstore-btn.svg";
import routesHelper from "../../router/routeshelper";

const LandingPage = (props) => {
  return (
    <div>
      <div
        className={
          props.router.location.pathname ===
          routesHelper.getRegistrationPath()
            ? "register-new-logo"
            : "new-logo"
        }
      >
        <img src={GTDLogo} alt="GTD Things Done" />
      </div>

      {props.router.location.pathname ===
        routesHelper.getRegistrationPath() && (
        <React.Fragment>
          <div className="video-time-content">
            Product demo - 2 minutes watch time
          </div>

          <div>
            <iframe
              title="GTD Demo"
              width="379"
              height="210"
              frameBorder="0"
              src="https://www.youtube.com/embed/JI9vUgd3uMQ"
              allowFullScreen
            ></iframe>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "35px"
            }}
          >
            <div className="download-register">
              <a href="https://getthingsdone.io/product/" target="_blank" rel="noopener noreferrer">
                <span>View list of features</span>
                <img
                  src={SmallArrow}
                  alt="GTD Things Done"
                  style={{ marginLeft: "10px" }}
                />
              </a>
            </div>
          </div>
        </React.Fragment>
      )}

      <div
        className={`playstore-link-holder ${
          props.router.location.pathname ===
          routesHelper.getRegistrationPath()
            ? "register-links"
            : ""
        }`}
      >
        <a
          className="app-store-img-holder apple"
          href={routesHelper.getAppStoreUri()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AppStoreLogo} alt="Appstore Logo" />
        </a>
        <a
          className="app-store-img-holder"
          href={routesHelper.getPlayStoreUri()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={PlayStoreLogo} alt="Playstore Logo" />
        </a>
      </div>
      {props.router.location.pathname !==
        routesHelper.getRegistrationPath() && (
        <p className="download-text">
          Download the app. Visit our{" "}
          <a href={routesHelper.getGtdLandingUri()} target="_blank" rel="noopener noreferrer">
            website
          </a>{" "}
          for more info.
        </p>
      )}
    </div>
  );
}

export default LandingPage;
