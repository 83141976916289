import {
  getCustomerDetails,
  getAllPendingRequests,
  updateCustomerDetails,
  createWorkspace,
  getCustomerSuggestions,
  archiveOrUnArchiveActivity,
  getActivitiesForWorkspace,
  getDeprioritizedActivitiesForWorkspace,
  getPrioritizedActivitiesForWorkspace,
  getListOfCurrencies,
  getTimerStatusForWorkspace,
  getAllPendingInvites
} from "../../services/workspaceapi";
import { workspaceConstant } from "../../constants/workspaceconstants";
import { activities } from "../../constants/activitiescontants";
import _ from "lodash";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updatingWorkSpace({ action, state }) {
  return {
    type: action,
    payload: state
  };
}

export function updateArchiveOrActivityList(archivedItem, archiveType) {
  return {
    type: workspaceConstant.archiveOrUnarchive,
    payload: {
      archivedItem,
      archiveType
    }
  };
}

function updatePrioritizeOrDePrioritizeActivity(prioritizeItem, priorityType) {
  return {
    type: workspaceConstant.prioritizeOrDeprioritize,
    payload: {
      prioritizeItem,
      priorityType
    }
  };
}

function updatePrioritizeOrDePrioritizeKanbanActivity(prioritizeItem, priorityType) {
  return {
    type: workspaceConstant.prioritizeOrDeprioritizeKanban,
    payload: {
      prioritizeItem,
      priorityType
    }
  };
}

function updatePrioritizeOrDePrioritizeNotificationActivity(prioritizeItem, priorityType) {
  return {
    type: workspaceConstant.prioritizeOrDeprioritizeNotification,
    payload: {
      prioritizeItem,
      priorityType
    }
  };
}

function updateActivityList({ listOfActivity, key, type = "ACTIVE", page }) {
  return {
    type: workspaceConstant.activityList,
    payload: {
      listOfActivity,
      page,
      type,
      key
    }
  };
}

export function updateArchivedActivityList(listOfActivity, key, type = "ACTIVE") {
  return {
    type: workspaceConstant.archivedActivityList,
    payload: {
      listOfActivity,
      type,
      key
    }
  };
}

function moreArchivedActivityList(listOfActivity, key) {
  return {
    type: workspaceConstant.moreArchivedActivityList,
    payload: {
      listOfActivity,
      key
    }
  };
}

function updateCusomterList(customerList) {
  return {
    type: workspaceConstant.updateCustomerList,
    payload: {
      list: customerList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updatePendingCusomterList(customerList) {
  return {
    type: workspaceConstant.updatePendingCustomerList,
    payload: {
      list: customerList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updatePendingCustomerInviteList(customerList) {
  return {
    type: workspaceConstant.updatePendingCustomerInviteList,
    payload: {
      list: customerList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateSuggestionsresponse(suggetionList) {
  return {
    type: workspaceConstant.suggestWorkspace,
    payload: {
      listOfSuggetions: suggetionList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateOneCustomerUsersList({ id, users }) {
  return {
    type: workspaceConstant.updateOneCustomerUsersList,
    payload: { id, users }
  }
}

function updateEachCustomerUsersList({ id, users, data, display_name, img, img_name, super_admin_id }) {
  return {
    type: workspaceConstant.updateEachCustomerUser,
    payload: {
      users,
      id,
      data,
      display_name,
      img,
      img_name,
      super_admin_id
    }
  };
}

function addRemoveUserFromWorkspace({ id, users }) {
  return {
    type: workspaceConstant.addRemoveUserFromWorkspace,
    payload: {
      users,
      id
    }
  };
}

function updateCustomerForUser({ id, users, super_admin_id }) {
  return {
    type: workspaceConstant.updateCustomerForUser,
    payload: {
      users,
      id,
      super_admin_id
    }
  };
}

function updateActivityCount(count) {
  return {
    type: workspaceConstant.totalActivityCount,
    payload: {
      count
    }
  };
}

function getPrioritizedActivities({ activitiesList }) {
  return {
    type: workspaceConstant.setPrioritizedActivity,
    payload: {
      activities: activitiesList
    }
  };
}

function getDeprioritizedActivities({ response, page }) {
  return {
    type: workspaceConstant.updateDeprioritizedActivities,
    payload: {
      list: response,
      page,
      key: new Date().getTime().toLocaleString()
    }
  };
}
function clearReducerFunction() {
  return {
    type: workspaceConstant.clearReducer,
    payload: {
      list: [],
      key: new Date().getTime().toLocaleString()
    }
  };
}

function clearReducerWithPrioritizeFunction() {
  return {
    type: workspaceConstant.clearReducerWithPrioritize,
    payload: {
      list: [],
      key: new Date().getTime().toLocaleString()
    }
  };
}
export function updateSelectedWorkpaceDetails(selectedWorkspace) {
  if (selectedWorkspace && selectedWorkspace.auth_token) {
    localStorage.setItem(
      activities.customerAuthToken,
      selectedWorkspace.auth_token
    );
    localStorage.setItem(activities.customerId, selectedWorkspace.id);
    localStorage.setItem(activities.customerImage, selectedWorkspace.img);
    localStorage.setItem(activities.customerDisplayName, selectedWorkspace.display_name);
    localStorage.setItem(activities.customerName, selectedWorkspace.name);
  }
  return {
    type: workspaceConstant.selectedCustomerDetails,
    payload: {
      selectedWorkspace: selectedWorkspace
    }
  };
}

export const getAllCustomerForUserAction = (
  { auth_token, loaderKey = workspaceConstant.customerLoaderState },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: loaderKey,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getCustomerDetails({ auth_token }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateCusomterList(response));
    } else {
      dispatch(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getCutomerDetailsActions = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.customerDetailsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getCustomerDetails({ auth_token, queryParam }, response => {
    if (response && !response.error) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.customerDetailsLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      ////dispatch(updateCusomterList(response))
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.customerDetailsLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getAllPendingReuestsAction = (
  auth_token,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.pendingCustomerLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getAllPendingRequests(auth_token, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.pendingCustomerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updatePendingCusomterList(response));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.pendingCustomerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getAllPendingInvitesAction = (
  auth_token,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.pendingCustomerLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getAllPendingInvites(auth_token, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.pendingCustomerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updatePendingCustomerInviteList(response));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.pendingCustomerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateCustomerDetailsToAddUserAction = (
  { auth_token, id, users },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.updateCustomerDetailsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  updateCustomerDetails(
    { auth_token, id, users },
    response => {
      if (response && response.msg) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        dispatch(updateOneCustomerUsersList({ id, users }));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value: response.stdErr
                  ? response.stdErr
                  : "Error occured while updating. Please try again."
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const updateCustomerDetailsAction = (
  { auth_token, display_name, data, id, img, img_name, super_admin_id, users, users_join_request, deleted_users },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.updateCustomerDetailsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  updateCustomerDetails(
    { auth_token, display_name, data, id, img, img_name, super_admin_id, users, users_join_request, deleted_users },
    response => {
      if (response && response.msg) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        if(display_name)
          dispatch(updateEachCustomerUsersList({ id, users, data, display_name, img, img_name, super_admin_id }));
        else dispatch(addRemoveUserFromWorkspace({ id, users }));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value: response.stdErr
                  ? response.stdErr
                  : "Error occured while updating. Please try again."
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const updateCustomerDetailsForUserAction = (
  { auth_token, id, super_admin_id, users },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.updateCustomerDetailsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  updateCustomerDetails(
    { auth_token, id, super_admin_id, users },
    response => {
      if (response && response.msg) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        dispatch(updateCustomerForUser({ id, users, super_admin_id }));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateCustomerDetailsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value: response.stdErr
                  ? response.stdErr
                  : "Error occured while updating. Please try again."
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const createWorkspaceAction = (
  { name, img, img_name, auth_token, super_admin_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.createWorkspaceLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  createWorkspace({ name, img, img_name, auth_token, super_admin_id }, response => {
    if (response && response.id) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.createWorkspaceLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );

      dispatch(
        updatingWorkSpace({
          action: workspaceConstant.createOwnWorkspace,
          state: response
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.createWorkspaceLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while create workpsace. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const suggestWorkspaceAction = (
  { auth_token, user_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.suggestWorkspaceLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );

  getCustomerSuggestions({ auth_token, user_id }, response => {
    if (response && !response.stdError) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.suggestWorkspaceLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      dispatch(updateSuggestionsresponse(response));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.suggestWorkspaceLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while getting suggestions. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getSelectedWorkpsaceArchivedActivities = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.activityLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  getActivitiesForWorkspace(
    {
      auth_token,
      activity_type,
      is_active,
      user_id,
      is_archived,
      page,
      item_per_page
    },
    response => {
      if (response && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        dispatch(updateArchivedActivityList(response, activities.completed));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value: response.stdErr
                  ? response.stdErr
                  : "Error occured while fetching activites. Please try again."
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const archiveOrUnArchiveActivities = (
  { auth_token, archiveData, type },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.archiveOrUnArchiveLoader,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError && !response.message && !response.error) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      type === activities.archive || type === activities.unArchive
        ? dispatch(updateArchiveOrActivityList({...response, completed_on: archiveData.completed_on}, type))
        : dispatch(updatePrioritizeOrDePrioritizeActivity({...response}, type));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while archiving. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const prioritizeDePrioritizeFromKanBanAction = (
  { auth_token, archiveData, type },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.archiveOrUnArchiveLoader,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError && !response.message) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      if(type === activities.dePrioritize || type === activities.prioritize)
        dispatch(updatePrioritizeOrDePrioritizeKanbanActivity({...response}, type));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while archiving. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const prioritizeDePrioritizeFromNotificationAction = (
  { auth_token, archiveData, type, updatedActivity },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.archiveOrUnArchiveLoader,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError && !response.message) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      if(type === activities.dePrioritize || type === activities.prioritize)
        dispatch(updatePrioritizeOrDePrioritizeNotificationActivity({ ...updatedActivity, ...response}, type));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while archiving. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const totalActvityCountAction = ({
  user_id,
  auth_token,
  activity_type
}) => dispatch => {
  getActivitiesForWorkspace(
    { user_id, auth_token, activity_type },
    response => {
      dispatch(updateActivityCount(response.length));
    }
  );
};

export const getMoreSelectedWorkpsaceArchivedActivities = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.activityLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  getActivitiesForWorkspace(
    {
      auth_token,
      activity_type,
      is_active,
      user_id,
      is_archived,
      page,
      item_per_page
    },
    response => {
      if (response && !response.stdError) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        dispatch(moreArchivedActivityList(response));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value: response.stdErr
                  ? response.stdErr
                  : "Error occured while fetching activites. Please try again."
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const getSelectedWorkpsaceActivities = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    item_per_page,
    page
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.activityLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );

  getAllActivities(
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page
  )
    .then(response => {
      dispatch(
        updateActivityList({ listOfActivity: response, activity_type, page })
      );
      dispatch(
        updateLoaderState({
          action: workspaceConstant.activityLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );

      callback && callback(response);
    })
    .catch(err => {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.activityLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: err.stdErr
                ? err.stdErr
                : "Error occured while fetching activites. Please try again."
            }
          }
        })
      );
    });
};

export const getPrioritizedActivitiesForWorkspaceAction = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived
    // page,
    // item_per_page
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.prioritizedActivityLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getPrioritizedActivitiesForWorkspace(
    {
      auth_token,
      activity_type,
      is_active,
      user_id,
      is_archived
    },
    response => {
      if (response && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.prioritizedActivityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        //  write reducer update function and call
        dispatch(getPrioritizedActivities({ activitiesList: response }));

        callback && callback(response);
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.prioritizedActivityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
    }
  );
};

// now
export const getDeprioritizedActivitiesForWorkspaceAction = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.updateDeprioritizedActivitiesLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getDeprioritizedActivitiesForWorkspace(
    {
      auth_token,
      activity_type,
      is_active,
      user_id,
      is_archived,
      page,
      item_per_page
    },
    response => {
      if (response && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateDeprioritizedActivitiesLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );

        //  write reducer update function and call
        dispatch(getDeprioritizedActivities({ response, page }));

        callback && callback(response);
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.updateDeprioritizedActivitiesLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
    }
  );
};

export const getListOfCurrenciesAction = (callback) => dispatch => {
  getListOfCurrencies({}, res => {
    if (res && !res.stdErr) {
      dispatch({ type: workspaceConstant.listOfCurrencies, payload: _.sortBy(res, (currency) => currency.code.toLowerCase()) });
    }
    callback && callback(res);
  })
}

export const getTimerStatusForWorkspaceAction = ({ auth_token }, callback) => dispatch => {
  getTimerStatusForWorkspace({ auth_token }, res => {
    dispatch({ type: workspaceConstant.timerStatus, payload: res })
    callback && callback(res);
  })
}

export const clearReducerAction = () => dispatch => {
  dispatch(clearReducerFunction());
};

export const clearReducerWithPrioritizeAction = () => dispatch => {
  dispatch(clearReducerWithPrioritizeFunction());
};

function getAllPriotizedActivities({
  auth_token,
  activity_type,
  is_active,
  user_id,
  is_archived
}) {
  return new Promise((resolve, reject) => {
    getPrioritizedActivitiesForWorkspace(
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived
      },
      res => {
        if (res.error) {
          return reject(res.error);
        }
        return resolve(res);
      }
    );
  });
}

function getAllDePriotizedActivities({
  auth_token,
  activity_type,
  is_active,
  user_id,
  is_archived,
  page,
  item_per_page
}) {
  return new Promise((resolve, reject) => {
    getDeprioritizedActivitiesForWorkspace(
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page
      },
      res => {
        if (res.error) {
          return reject(res.error);
        }
        return resolve(res);
      }
    );
  });
}
async function getAllActivities(
  auth_token,
  activity_type,
  is_active,
  user_id,
  is_archived,
  page,
  item_per_page
) {
  let lists = {};

  lists.prioritized = await getAllPriotizedActivities({
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived
  });
  lists.nonPrioritized = await getAllDePriotizedActivities({
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page
  });
  return lists;
}

export const clearPrioritizeActivityAction = () => dispatch => {
  dispatch({
    type: activities.CLEAR_PRIORITY_NOTPRIORITY,
  });
};
