import React, { Component } from "react";
import {
  createStripeCustomer,
  updateCardDetails,
  makePayment,
  updateCustomer
} from "../../services/paymentapis";

import { injectStripe } from "react-stripe-elements";

import CheckoutForm from "./checkoutForm";

class BillingPayment extends Component {
  state = {
    transactionDeclined: false
  };

  handleSubmit = ({ name, address, city, state, zip, country }) => {
    const {
      currentWorkSpace,
      billingAmounts,
      // price,
      paymentType,
      gotoFinalStep,
      getChargesInfo,
      getCustomerInfo,
      getCustomerDetails
    } = this.props;

    const userCount = billingAmounts.users || Object.keys(currentWorkSpace.users).length;

    this.props.stripe.createToken({ type: "card", name }).then(res =>
      createStripeCustomer({ stripeToken: res.token.id }, tokenObject => {
        
        if (tokenObject.error) {
          return this.setState({ transactionDeclined: true });
        }
        updateCardDetails(
          {
            paymentObject: {
              card_id: tokenObject.default_source,
              name,
              address,
              city,
              state,
              zip,
              country
            }
          },
          _cardDetails => {
            makePayment(
              { plan: paymentType, quantity: userCount },
              _subscriptionObject => {
                updateCustomer(
                  { name, update_type: "name_update" },
                  _updateObject => {
                    getChargesInfo(_response => {
                      getCustomerInfo(_response => {
                        getCustomerDetails(_response => {
                          this.props.updateBillingAmounts();
                          gotoFinalStep();
                        });
                      });
                    });
                  }
                );
              }
            );
          }
        );
      })
    );
  };

  render() {
    const { currentWorkSpace, paymentType, currencyLogo, billingAmounts } = this.props;
    const { transactionDeclined } = this.state;

    const userCount = Object.keys(currentWorkSpace.users).length;
    const totalAmount =
      paymentType === "yearly" ? billingAmounts.yearly_amount : billingAmounts.monthly_amount;
    return (
      <>
        {" "}
        <div>
          <div
            class="billing-plan-for-container"
            ng-if="!paymentScope.paymentFailed"
          >
            <div class="billing-plan-for">You are paying for</div>
            <div class="billing-plan-overview pm">
              <div class="pm-instruct">
                <span>{billingAmounts.users || userCount}</span>{" "}
                <span class="bill-type">
                  {" "}
                  Users{" "}
                  {paymentType === "monthly"
                    ? "(Billed Monthly)"
                    : "(Billed Annually)"}
                </span>
                {/* <span ng-if="paymentScope.billingObj.interval === 'year'">{ userCount }</span> <span ng-if="paymentScope.billingObj.interval === 'year'" class="bill-type"> Users  {{ '(Billed Annually)'}}</span> */}
              </div>
              <div class="bill-amount">{currencyLogo}{totalAmount && totalAmount.toFixed(2)}</div>
            </div>
          </div>
          <CheckoutForm
            handleSubmit={this.handleSubmit}
            transactionDeclined={transactionDeclined}
          ></CheckoutForm>
        </div>
      </>
    );
  }
}

export default injectStripe(BillingPayment);
