import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import _ from "lodash";

const InputChips = props => {
  const inputEl = useRef(null);
  const submittedRref = useRef(null);
  const mainRref = useRef(null);

  const [readOnly, setReadonly] = useState(props.readOnly);
  const [value, setValue] = useState(props.value);
  const [classname, setClassname] = useState(props.classname);
  const [allProjectActivityType, setAllProjectActivityType] = useState([]);
  const [allType, setAllTyped] = useState(props.allProjectActivityType);
  const [selectedActivityType, setSelectedActivityType] = useState(
    props.selectedActivityType
  );

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (mainRref.current && !mainRref.current.contains(event.target)) {
        setReadonly(true);
        setClassname("read-only");
        if (props.addButton) {
          setValue("+ Add");
        } else {
          setValue(value);
        }
        if (props.showFilter) {
          checkSelectFlag(false);
          props.onCancelAddClick(selectedActivityTypePassed => {
            renderAllType(selectedActivityTypePassed);
          });
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRref, allType]);
  useEffect(() => {
    setAllTyped(props.allProjectActivityType);
  }, [props.allProjectActivityType]);
  useEffect(() => {
    setSelectedActivityType(props.selectedActivityType);
  }, [props.selectedActivityType]);
  useEffect(() => {
    if (allType) {
      let allProjectActivityTypes = allType.map((element, index) => {
        if (selectedActivityType.includes(element)) {
          return getActivityType(element, index, props, true);
        } else {
          return getActivityType(element, index, props, false);
        }
      });
      setAllProjectActivityType(allProjectActivityTypes);
    }
  }, [allType, selectedActivityType]);

  const ADDLABEL = "+ Add";
  const [style, setStyle] = useState({
    backgroundColor: "white",
    width: "80px",
    "max-width": "80px",
    "&:hover": {
      backgroundColor: "white",
    },
  });

  const updateButton = () => {
    setReadonly(true);
    setClassname("read-only");
    if (props.addButton) {
      setValue("+ Add");
    } else {
      setValue(value);
    }
  };
  const enableAddButton = useCallback(
    event => {
      event.preventDefault();
      setReadonly(false);
      setClassname("editable");
      if (props.addButton) {
        setValue("");
      } else {
        if (props.showFilter) {
          const filteredActivity = allType.filter(element => {
            return element.toLowerCase().indexOf(value.toLowerCase()) > -1;
          });
          let allProjectActivityTypes = filteredActivity.map(
            (element, index) => {
              if (selectedActivityType.includes(element)) {
                return getActivityType(element, index, props, true);
              } else {
                return getActivityType(element, index, props, false);
              }
            }
          );
          setAllProjectActivityType(allProjectActivityTypes);
        }
        setValue(value);
      }
      if (value && value.length > 9) {
        setStyle({
          backgroundColor: "white",
          width: value.length * 8 + "px",
          "max-width": value.length * 8 + "px",
          "&:hover": {
            backgroundColor: "white",
          },
        });
      } else {
        setStyle({
          backgroundColor: "white",
          width: "80px",
          "max-width": "80px",
          "&:hover": {
            backgroundColor: "white",
          },
        });
      }
    },
    [readOnly, value, classname]
  );
  const deleteClick = () => {
    props.deleteHandler(
      value,
      props.indexvalue,
      props.type,
      selectedActivityTypePassed => {
        renderAllType(selectedActivityTypePassed);
      },
      props.wfId
    );
  };

  const addClick = event => {
    if (event && (event.key === "Enter" || event.type === "click")) {
      event.preventDefault();
      if (
        inputEl.current &&
        (inputEl.current.value === undefined ||
          inputEl.current.value === null ||
          inputEl.current.value.trim() === "")
      ) {
        setReadonly(true);
        setClassname("read-only");
        if (props.addButton) {
          setValue(ADDLABEL);
        } else {
          setValue(value);
        }
      } else {
        if (
          inputEl.current.value === value ||
          value === ADDLABEL ||
          props.validate(inputEl.current.value)
        ) {
          setReadonly(true);
          setClassname("read-only");
          if (props.addButton) {
            setValue(ADDLABEL);
          } else {
            setValue(value);
          }
        } else {
          if (props.addButton) {
            setReadonly(true);
            setClassname("read-only");
            setValue(ADDLABEL);
            props.handleAdd({
              value: inputEl.current.value,
              addButton: props.addButton,
              indexvalue: props.indexvalue,
            });
          } else {
            let oldValue = value;
            setReadonly(true);
            setClassname("read-only");
            setValue(inputEl.current.value);
            props.handleAdd({
              value: inputEl.current.value,
              addButton: props.addButton,
              indexvalue: props.indexvalue,
              oldValue: oldValue,
            });
          }
        }
      }
    }
  };

  const checkSelectFlag = flag => {
    if (props.allProjectActivityType) {
      let allProjectActivityTypes = allType.map((element, index) => {
        return getActivityType(element, index, props, flag);
      });
      setAllProjectActivityType(allProjectActivityTypes);
    }
  };

  let renderAllType = selectedActivityTypePassed => {
    if (props.allProjectActivityType) {
      let allProjectActivityTypes = allType.map((element, index) => {
        if (!selectedActivityTypePassed.includes(element)) {
          return getActivityType(element, index, props, false);
        } else {
          return getActivityType(element, index, props, true);
        }
      });
      setAllProjectActivityType(allProjectActivityTypes);
    }
  };

  const getActivityType = (element, index, props, flag) => {
    return (
      <div className="workload-search-data-list" key={`${element}${index}`}>
        <input
          type="checkbox"
          checked={flag}
          className="tick-check"
          id={`${element}${element.id}`}
          onChange={e => {
            props.setSelectedActivityType(
              e,
              element,
              false,
              selectedActivityTypePassed => {
                renderAllType(selectedActivityTypePassed);
              }
            );
          }}
        />
        <label
          className="tick-symbol"
          htmlFor={`${element}${element.id}`}
          role="button"
        ></label>
        <div className="user-details-holder">
          <div className="user-name" style={{ height: "25px" }}>
            {element}
          </div>
        </div>
      </div>
    );
  };

  const activateType = event => {
    let value = event.target.value;
    const filteredActivity = allType.filter(element => {
      return element.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    let allProjectActivityTypes = filteredActivity.map((element, index) => {
      if (selectedActivityType.includes(element)) {
        return getActivityType(element, index, props, true);
      } else {
        return getActivityType(element, index, props, false);
      }
    });
    setAllProjectActivityType(allProjectActivityTypes);
  };

  let display = null;

  if (readOnly) {
    if (props.addButton) {
      display = (
        <div>
          <div className="readOnlyButton" onClick={enableAddButton}>
            <span ref={inputEl}>{value}</span>
          </div>
        </div>
      );
    } else {
      display = (
        <div>
          <div className="readOnlyItem">
            <span ref={inputEl} onClick={enableAddButton}>
              {value}
            </span>

            <svg
              className={classname}
              focusable="false"
              onClick={deleteClick}
              viewBox="0 0 24 24"
            >
              {/* <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path> */}
            </svg>
          </div>
        </div>
      );
    }
  } else {
    display = (
      <div className="mainInputElement" ref={mainRref}>
        <div className="inputEdit">
          <input
            autoFocus
            style={style}
            onChange={event => (props.showFilter ? activateType(event) : null)}
            onKeyDown={addClick}
            ref={inputEl}
            defaultValue={value}
            type="text"
          />
          <div
            className="svgHolder"
            onClick={event => {
              addClick(event);
              if (props.showFilter) {
                checkSelectFlag(false);
                props.onCancelAddClick(selectedActivityTypePassed => {
                  renderAllType(selectedActivityTypePassed);
                });
              }
            }}
          >
            <svg
              ref={submittedRref}
              className={classname}
              viewBox="0 0 24 24"
            ></svg>
          </div>
        </div>
        {props.showFilter && (
          <div className="workload-main-search-conatiner">
            <div
              className="workload-search-header"
              onClick={event => {
                checkSelectFlag(true);
                props.setSelectedActivityType(event, "", true);
              }}
            >
              <span className="workload-search-header-title">Select All</span>
            </div>
            <div className="hr-line"></div>

            <div className="workload-search-data-container ">
              {allProjectActivityType}
            </div>

            <div className="add-multi-member-bottom-holder">
              <div
                className="add-multi-member-cancel"
                role="button"
                tabindex="0"
                onClick={e => {
                  //checkSelectFlag(false)
                  props.onCancelAddClick(selectedActivityTypePassed => {
                    renderAllType(selectedActivityTypePassed);
                  });
                  updateButton();
                }}
              >
                Cancel
              </div>
              <button
                className="add-multi-member-confirm"
                //disabled={!props.selectedActivityType || props.selectedActivityType.length === 0}
                onClick={event => {
                  //props.updateActivityType();
                  //checkSelectFlag(false)
                  props.updateActivityType(selectedActivityTypePassed => {
                    renderAllType(selectedActivityTypePassed);
                  });
                  updateButton();
                }}
              >
                {" "}
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <>{display}</>;
};

export default memo(InputChips);
