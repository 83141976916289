import { registerUser, acceptInvite } from "../../services/registrationapi";
import {
  updateAuthKey,
  registerLoaderState
} from "../../helpers/loginconstants";

function updateLoginAuth(user_details) {
  return {
    type: updateAuthKey,
    payload: {
      user_details,
      auth_token: user_details.auth_token,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export const registerUserAction = ({ email, password, name }, callback) => (
  dispach
) => {
  dispach(
    updateLoaderState({
      action: registerLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  registerUser({ email, password, name }, (response) => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: registerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispach(updateLoginAuth(response));
    } else {
      dispach(
        updateLoaderState({
          action: registerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const acceptInviteAction = ({ token, auth_token }, callback) => (
  dispatch
) => {
  dispatch(
    updateLoaderState({
      action: registerLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  acceptInvite({ token, auth_token }, (response) => {
    if (response && !response.error) {
      dispatch(
        updateLoaderState({
          action: registerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: registerLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};
