import React, { Component } from "react";
import notify_member from "../../styles/img/svg/notify-member.svg";
import { Tooltip } from "@material-ui/core";
import { removeTitleDuplication } from "../../helpers/notificationHelper";
import { commonDateFormat } from "../../helpers/generichelper";
import {
  changeIsReadStatusOfYouTabAction,
  changeFlagStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfAllTabAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import firebase from "../../firebase";
import Avatar from "react-avatar";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    }
  };
}

class NotifyRemovedMember extends Component {
  
  youNotificationReadStatus = (notification) => {
    
    firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
    if (notification.is_read === false) {
      notification.is_read = true;
    } else if (notification.is_read === true) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  notificationFlagStatus = (notification) => {
    
    firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }
    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  render() {
    const { notification } = this.props;
    return (
      <div className="notify-card-others">
        <Tooltip
          title={notification.is_read ? "Mark as unread" : "Mark as read"}
        >
          <div
            className={
              notification.is_read === true
                ? "notify-read-unread read"
                : "notify-read-unread"
            }
            onClick={() => this.youNotificationReadStatus(notification)}
          ></div>
        </Tooltip>

        <Tooltip
          title={
            notification.is_flagged
              ? "Unflag this notification"
              : "Flag this notification"
          }
        >
          <div
            className={
              notification.is_flagged === true
                ? "notifi-flag flagged"
                : "notifi-flag"
            }
            onClick={() => this.notificationFlagStatus(notification)}
          ></div>
        </Tooltip>
        <div className="notify-card-left with-user">
          <div className="notify-user-img">
            {notification.sender_info && (
              <React.Fragment>
              {notification.sender_info.img ? <img src={notification.sender_info.img} alt={notification.sender_info.first_name} /> : 
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={notification.sender_info.first_name}
                maxInitials={1}
                />
            }
            </React.Fragment>
            )}
          </div>
          <div className="notify-type-icon user-member">
            <img src={notify_member} alt="member-icon" />
          </div>
        </div>
        <div className="notify-card-right">
          <div className="notify-card-title">
            {removeTitleDuplication(
              notification.message,
              notification.data.activity
                ? notification.data.activity.title
                : notification.data.title
            )}
            <span className="activity-title">
              {notification.data.activity
                ? notification.data.activity.title
                : notification.data.title}
            </span>
            {notification.data.activity && notification.data.project && (
              <span>
                {" "}
                under the project
                <span className="activity-title">
                  {" " + notification.data.project.name}
                </span>
              </span>
            )}
          </div>
          <p className="post_time">
            {commonDateFormat(notification.created_at)}
          </p>
        </div>
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(NotifyRemovedMember);
