/***
 * Author: Madhusudhan Seetharamiah
 */
import React, { Component } from "react";
import { Form, Control, actions as rrfActions } from "react-redux-form";
import { toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
import "../../styles/scss/user_profile.scss";
import feedBack from "../../styles/img/svg/feedback.svg";
import {
  getProfileDetails,
  isEmailValid,
  logout,
  updateProfileDetails
} from "../../helpers/generichelper";
import { isGoogleSignIn, isAppleSignIn } from "../../helpers/loginconstants";
import { connect } from "react-redux";
import { message } from "../../constants/messages";
import { pickAll } from "ramda";
import DialogWrapper from "../common/dialog";
import ChangePasswodDialog from "./changepasswordpopup";
import ChangeGooglePopup from "./changeGooglePopup";
import * as actions from "../../actions/index";

import { Loader } from "../common/loader";
import OutsideClickHandler from "react-outside-click-handler";
import UnsplashDialog from "./unsplashdialog";
import pickFromComp from "../../styles/img/svg/attachment-from-computer.svg";
import pickFromUnsplash from "../../styles/img/svg/pick-unsplash.svg";
import routesHelper from "../../router/routeshelper";
import Avatar from "react-avatar";
import { getRandomImage } from "../../services/unsplashaapi";
import _ from "lodash";
import firebase from "../../firebase";

const mapStateToProps = (state) => {
  return {
    userProfileDetails: state.rrfStatereducer.profile,
    changePasswordDetails: state.rrfStatereducer.changePassword,
    profileLoaderState: state.commonReducer.profileLoaderState,
    userPhotoUpdateLoaderState: state.commonReducer.userPhotoUpdateLoaderState
  };
};

const mapDispacthProps = (dispatch) => {
  return {
    updateFormValues: (argName, argValue) => {
      dispatch(rrfActions.change("rrfState.profile" + argName, argValue));
    },
    updatePwdFormValues: (argName, argValue) => {
      dispatch(
        rrfActions.change("rrfState.changePassword" + argName, argValue)
      );
    },
    updateUserPasswordAction: (
      { old_password, new_password, user_id },
      callback
    ) => {
      dispatch(
        actions.updateUserPasswordAction(
          { old_password, new_password, user_id },
          callback
        )
      );
    },

    updateUserProfileAction: (
      { first_name, id, email, img, tags },
      callback
    ) => {
      dispatch(
        actions.updateUserProfileAction(
          { first_name, id, email, img, tags },
          callback
        )
      );
    },
    uploadPhotoToS3BucketAction: ({ file }, callback) => {
      dispatch(actions.uploadPhotoToS3Bucket({ file }, callback));
    }
  };
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGoogleId: false,
      isPopupEnable: false,
      notValidForm: false,
      img: getProfileDetails().img,
      name: null,
      team: null,
      isValidChangePasswordForm: false,
      changesSaved: false,
      showUnsplashDialog: false,
      showBackgroundChangeDialog: false,
      bgName: "",
      fetching: false,
      loginAgainPopup:false
    };
  }

  componentDidMount() {
    const { updateFormValues } = this.props;
    
    updateFormValues(".name", getProfileDetails().first_name);
    updateFormValues(".userName", getProfileDetails().username);
    updateFormValues(".email", getProfileDetails().email);
    updateFormValues(".team", getProfileDetails().team);
    this.setState({
      name: null,
      team: null
    });
    
  }

  onChangeInitiated = () => {
    firebase.analytics().logEvent("GTD_Account_Profile_Password_Changed");
    let googleKeyIn = localStorage.getItem(isGoogleSignIn) || localStorage.getItem(isAppleSignIn);
    let isGoogleKeyIn = googleKeyIn && googleKeyIn === "true";

    this.setState({
      isGoogleId: isGoogleKeyIn
    });
    if (!isGoogleKeyIn) {
      this.setState({
        isPopupEnable: true
      });
    }
  };

  isValidData = () => {
    const { userProfileDetails } = this.props;
    let isValidBoolList = [];
    let toBeValidatedItems = pickAll(["name"], userProfileDetails);
    
    for (const key in toBeValidatedItems) {
      if (toBeValidatedItems.hasOwnProperty(key)) {
        const element = toBeValidatedItems[key];
        if (!element && key !== "team") {
          isValidBoolList.push(false);
          this.setState({
            [key]: message.filedShouldNotBeEmpty
          });
        } else if (element.length < 2 && key !== "team") {
          isValidBoolList.push(false);
          this.setState({
            [key]: message.enterYourName
          });
        } else if (element.length > 0 && isEmailValid(element) && key !== "team") {
          isValidBoolList.push(false);
          this.setState({
            [key]: message.enterValidFullName
          });
        } else {
          this.setState({
            [key]: null
          });
        }
      }
    }
    if (isValidBoolList.indexOf(false) > -1) {
      this.setState({
        notValidForm: true
      });
      return false;
    }

    this.setState({
      notValidForm: false
    });
    return true;
  };

  onFormUpdated = () => {
    this.isValidData();
  };

  onUploadInitiated = () => {
    firebase.analytics().logEvent("GTD_Account_Profile_Update_Picture");
    this.imgUploadRef.click();
  };

  onFileUpload = () => {
    const {
      uploadPhotoToS3BucketAction,
      userProfileDetails,
      updateUserProfileAction,
      currentUser,
      updateLoginAuth
    } = this.props;
    let selectedFiles =
      this.imgUploadRef && this.imgUploadRef.files && this.imgUploadRef.files;
    this.setState({ fetching: true });
    selectedFiles &&
      selectedFiles.length &&
      uploadPhotoToS3BucketAction({ file: selectedFiles[0] }, (response) => {
        if (response && response.success) {
          let user_img = response.img;
          let user_id = parseInt(getProfileDetails().id);
          updateUserProfileAction(
            {
              first_name: userProfileDetails.name,
              id: user_id,
              img: user_img,
              tags: [userProfileDetails.team]
            },
            (response) => {
              if (response && !response.error && !response.stdErr) {
                updateProfileDetails(response);
                updateLoginAuth({
                  ...currentUser,
                  img: response.img,
                  first_name: response.first_name
                });
                this.setState({
                  dummyUpdate: "",
                  img: response.img
                }, () => {
                  this.setState({ fetching: false })
                });
              }
            }
          );
          
        }
      });
  };

  onPopupCloseCallBack = () => {
    this.setState({
      isPopupEnable: false,
      isValidChangePasswordForm: false
    });
  };

  onCloseGooglePopup = () => {
    if(this.state.loginAgainPopup) {
      this.setState({ loginAgainPopup: false}, () => logout());
    } else
      this.setState({ isGoogleId: false });
  };

  onConfirm = () => {
    const {
      updatePwdFormValues,
      updateUserPasswordAction,
      changePasswordDetails
    } = this.props;
    
    let changePassword = {
      old_password: {
        isValid: true,
        value: changePasswordDetails.old_password.value,
        errMsg: null
      },
      new_password: {
        isValid: true,
        value: changePasswordDetails.new_password.value,
        errMsg: null
      },
      confirm_password: {
        isValid: true,
        value: changePasswordDetails.confirm_password.value,
        errMsg: null
      }
    };

    if (changePasswordDetails.old_password.value.length === 0) {
      changePassword.old_password.errMsg = message.emptyField;
      updatePwdFormValues("", changePassword);
      return;
    }

    if (changePasswordDetails.new_password.value.length === 0) {
      changePassword.new_password.errMsg = message.emptyField;
      updatePwdFormValues("", changePassword);
      return;
    }

    if (changePasswordDetails.confirm_password.value.length === 0) {
      changePassword.confirm_password.errMsg = message.emptyField;
      updatePwdFormValues("", changePassword);
      return;
    }

    if (
      changePasswordDetails.new_password.value &&
      changePasswordDetails.new_password.value.length < 6
    ) {
      
      if (changePasswordDetails.old_password.value.length > 0)
        changePassword.old_password.isValid = true;
      changePassword.new_password.errMsg = message.shortPassword;
      if (changePasswordDetails.confirm_password.value.length > 0)
        changePassword.confirm_password.isValid = true;
      updatePwdFormValues("", changePassword);
      return;
    }

    if (
      changePasswordDetails.confirm_password.value &&
      changePasswordDetails.confirm_password.value.length < 6
    ) {
      if (changePasswordDetails.old_password.value.length > 0)
        changePassword.old_password.isValid = true;
      changePassword.confirm_password.errMsg = message.shortPassword;
      if (changePasswordDetails.new_password.value.length > 0)
        changePassword.new_password.isValid = true;
      updatePwdFormValues("", changePassword);
      return;
    }

    if (
      changePasswordDetails.new_password.value.length >= 6 &&
      changePasswordDetails.confirm_password.value.length >= 6
    ) {
      if (
        changePasswordDetails.new_password.value !==
        changePasswordDetails.confirm_password.value
      ) {
        changePassword.confirm_password.errMsg = message.wrongConfirmPassword;
        if (changePasswordDetails.old_password.value.length > 0)
          changePassword.old_password.isValid = true;
        if (changePasswordDetails.confirm_password.value.length > 0)
          changePassword.confirm_password.isValid = true;
        updatePwdFormValues("", changePassword);
        return;
      }
      updatePwdFormValues("", changePassword);
      updateUserPasswordAction(
        {
          old_password: changePasswordDetails.old_password.value,
          new_password: changePasswordDetails.new_password.value,
          user_id: parseInt(localStorage.getItem("id"))
        },
        (response) => {
          if (response && !response.error) {
            this.setState({ isPopupEnable: false }, () => {
              this.setState({ loginAgainPopup: true });
            });
          }
        }
      );
    }

    
  };

  onShowUnsplashDialog = () => {
    this.setState({ showUnsplashDialog: !this.state.showUnsplashDialog });
  };

  onShowBackgroundChangeDialog = () => {
    firebase.analytics().logEvent("GTD_Account_Profile_Background_Changed");
    if (this.state.showBackgroundChangeDialog)
      this.setState({ showBackgroundChangeDialog: false });
    else this.setState({ showBackgroundChangeDialog: true });
  };

  onOutsideClick = () => {
    this.setState({ showBackgroundChangeDialog: false });
  };

  handleBgFileUpload = (event) => {
    event.stopPropagation();
    const { uploadPhotoToS3BucketAction, onChangeBg } = this.props;
    let file = event.target.files[0];
    
    this.onOutsideClick();
    uploadPhotoToS3BucketAction({ file }, (response) => {
      if (response && response.success) {
        
        let style =
          "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" +
          response.img +
          ") no-repeat";
        onChangeBg({
          style,
          bgName: file.name,
          bgUrl: response.img,
          credits_link: null,
          author_link: null,
          author: null
        });
        
        toast("Background updated successfully!");
      }
    });
  };

  onRandomizeUnsplash = () => {
    let unsplashObj = {
      randomize: true,
      credits_link:
        "https://unsplash.com/?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit",
      author_link: null,
      author: null,
      bgName: null,
      style:
        "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://source.unsplash.com/random') no-repeat"
    };
    getRandomImage((response) => {
      if (response && !response.stdErr) {
        unsplashObj.author_link =
          "https://unsplash.com/@" +
          response.user.username +
          "?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit";
        unsplashObj.author = response.user.name;
        unsplashObj.bgName = response.description;
        unsplashObj.style =
          "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" +
          response.urls.regular +
          ") no-repeat";
      }
      this.props.onChangeBg(unsplashObj);
      toast("Background Randomized!");
    });
  };

  onChangeUnsplash = (response) => {
    let unsplashObj = {
      credits_link:
        "https://unsplash.com/?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit"
    };
    unsplashObj.author_link =
      "https://unsplash.com/@" +
      response.user.username +
      "?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit";
    unsplashObj.author = response.user.name;
    unsplashObj.bgName = response.description;
    unsplashObj.bgUrl = response.urls.regular;
    unsplashObj.style =
      "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" +
      response.urls.regular +
      ") no-repeat";
    this.props.onChangeBg(unsplashObj);
    toast("Background updated successfully!");
  };

  validFormCallBack = (isValidState) => {
    this.setState({
      isValidChangePasswordForm: isValidState
    });
  };

  onSaveProfile = () => {
    const {
      updateUserProfileAction,
      userProfileDetails,
      currentUser,
      updateLoginAuth
    } = this.props;
    let body = {
      first_name: userProfileDetails.name,
      id: parseInt(getProfileDetails().id),
      img: this.state.img,
      tags: [userProfileDetails.team]
    }
    if(!this.state.img) body = _.omit(body, ["img"]);
    updateUserProfileAction(
      { ...body },
      (response) => {
        if (response && !response.error && !response.stdErr) {
          updateProfileDetails(response);
          updateLoginAuth({
            ...currentUser,
            img: response.img,
            first_name: response.first_name
          });
          toast("Changes saved successfully!");
          this.setState({
            changesSaved: true
          });
        }
      }
    );
  };

  render() {
    const {
      userProfileDetails,
      profileLoaderState,
      userPhotoUpdateLoaderState,
      currentWorkSpace,
      currentUser,
      bgName
    } = this.props;
    const {
      isGoogleId,
      img,
      name: name_error,
      // team: team_error,
      notValidForm,
      isPopupEnable,
      isValidChangePasswordForm,
      // changesSaved,
      showUnsplashDialog,
      showBackgroundChangeDialog,
      loginAgainPopup
    } = this.state;
    let isOwner = null;
    if (currentWorkSpace)
      isOwner =
        parseInt(currentWorkSpace.super_admin_id) ===
          parseInt(localStorage.getItem("id")) ? "Creator" :
        (currentWorkSpace.users.hasOwnProperty(localStorage.getItem("id")) &&
          currentWorkSpace.users[localStorage.getItem("id")] === "SUPERADMIN")
          ? "Owner"
          : "Member";
    let dialogProps = {
      className: "dialog_wrapper",
      showPopup: isPopupEnable,
      dialogTitle: () => "Change Password",
      onCloseCallBack: this.onPopupCloseCallBack,
      // topButton: true,
      // confirmTitle: "Done",
      // onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true || !isValidChangePasswordForm
    };
    let dialogPropsGoogle = {
      className: "dialog_wrapper",
      showPopup: isGoogleId,
      dialogTitle: () => "Password cannot be changed",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true
    };
    let dialogPropsLoginAgain = {
      className: "dialog_wrapper",
      showPopup: loginAgainPopup,
      dialogTitle: () => "Password changed successfully",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true
    };
    return (
      <div className="profile-billing">
        <div className="profile-billing-wrapper profile-wrapper">
          <div className="nw-att-loading-holder">
            {/* <ClipLoader sizeUnit={"px"} size={100} color={'#0190e9'} loading={profileLoaderState.fetching || userPhotoUpdateLoaderState.fetching } /> */}
            <Loader
              size={100}
              loading={
                profileLoaderState.fetching ||
                userPhotoUpdateLoaderState.fetching ||
                this.state.fetching
              }
            />
          </div>
          <Scrollbar>
            <div className="profile-main">
              <div className="profile-form">
                <Form model="rrfState.profile" onUpdate={this.onFormUpdated}>
                  <div className="profile-pic">
                    <div className="img-wrap">
                      <div
                        className="img"
                        accept="image/PNG, image/png,  image/jpeg, image/jpg"
                      >
                        {!img ? (
                          <Avatar
                            style={{ width: "100%", height: "100%" }}
                            name={currentUser && currentUser.first_name}
                            maxInitials={1}
                          ></Avatar>
                        ) : (
                          <img
                            src={img}
                            alt="User-img"
                            className="user_img"
                          />
                        )}
                        {userPhotoUpdateLoaderState.error.bool && (
                          <p>{userPhotoUpdateLoaderState.bool.value}</p>
                        )}
                      </div>

                      <div
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                        className="update_img"
                        onClick={this.onUploadInitiated}
                      >
                        Update Picture
                      </div>
                      <input
                        type="file"
                        ref={(ref) => {
                          this.imgUploadRef = ref;
                        }}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                        onChange={this.onFileUpload}
                      />
                    </div>

                    <div className="title-desc">
                      <h1>
                        {/* $$ User profile name comes here */}
                        {currentUser && currentUser.first_name}
                      </h1>
                      <p>
                        {/* $$ user email id comes here */}
                        {isOwner} -{" "}
                        {currentWorkSpace && currentWorkSpace.display_name}
                      </p>
                    </div>
                    <div className="fb-profile">
                      <a href={routesHelper.getFeedbackPath()} target="_blank" rel="noopener noreferrer">
                        <img src={feedBack} alt="feedback" />
                      </a>
                    </div>

                    {/* <div className="delete-account ng-hide">
                                        Delete account
                                    </div> */}
                  </div>

                  <div className="profile-details">
                    <div className="first-name cmn-label">
                      <label>Name</label>
                      <Control.text
                        type="text"
                        model=".name"
                        placeholder="e.g. Caroline Jenkins"
                        className="name_input"
                        value={userProfileDetails.name}
                        onKeyUp={() => firebase.analytics().logEvent("GTD_Account_Profile_Name_Changed")}
                      />
                      {/* $$ if this field isnt filled then display the msg */}
                      {name_error && <p>{name_error}</p>}
                    </div>

                    <div className="progress_circular profile-loader">
                      {/* $$ if profile is still loading, display a loader */}
                    </div>
                    <div className="email cmn-label">
                      <label>Username </label>
                      <Control.text
                        type="text"
                        model=".userName"
                        placeholder="User Name"
                        value={userProfileDetails.userName}
                        onChange={() => { /* TODO document why this arrow function is empty */ }}
                        disabled
                      />
                    </div>
                    <div className="email cmn-label">
                      <label>Email </label>
                      <Control.text
                        type="text"
                        model=".email"
                        placeholder="email"
                        value={userProfileDetails.email}
                        disabled
                      />
                    </div>

                    <div className="password cmn-label">
                      <label>Password</label>
                      <Control.text
                        type="password"
                        model=".password"
                        value="password"
                        disabled
                      />
                      {/* If signed in through google donot allow to change password */}

                      <button
                        onClick={this.onChangeInitiated}
                        className="change-pass button-active button-inactive"
                      >
                        Change
                      </button>
                    </div>
                    {/* <div className="team cmn-label">
                    <label>Team </label>
                    <Control.text
                      type="text"
                      model=".team"
                      value={userProfileDetails.team}
                      placeholder="e.g. Finance"
                    />
                    {team_error && <p>{team_error}</p>}
                    <p>This field cannot be empty</p>
                    <p>Enter team name</p>
                  </div> 
                  for background field {{userProfile.getUrlValue() | limitTo: 27}}{{userProfile.getUrlValue().length > 30 ? '...' : ''}}
                  */}
                    <div className="background_button cmn-label">
                      <label>Background</label>
                      <span>{bgName || "Background"}</span>

                      {/* <button
                      onClick={this.onShowUnsplashDialog}
                      ng-click="userProfile.toggleBackgroundPopUp()"
                      className="change-pass change_button button-active button-inactive"
                    >
                      Change
                    </button> */}
                      <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                        <button
                          className="change-pass randonmize_button button-active button-inactive"
                          onClick={this.onShowBackgroundChangeDialog}
                        >
                          Change
                        </button>
                        {showBackgroundChangeDialog && (
                          <div className="change-bg-box">
                            <div className="change-bg-head">
                              <span className="choose-from">Choose from</span>
                              <span className="hr-line"></span>
                            </div>
                            <div className="change-bg-body">
                              <label className="change-bg-option">
                                <img src={pickFromComp} alt="computer-icon" />
                                <span>Computer</span>
                                <input
                                  type="file"
                                  name="myFile"
                                  onChange={this.handleBgFileUpload}
                                  className="attachmentBox"
                                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                                />
                              </label>
                              <div
                                className="change-bg-option"
                                onClick={this.onShowUnsplashDialog}
                              >
                                <img src={pickFromUnsplash} alt="unsplash-icon" />
                                <span>Unsplash</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </OutsideClickHandler>
                    </div>

                    <div className="profile-save-btn ">
                      {/* $$ if all fields are filled & validated enable save button else disable*/}
                      <button
                        className={!notValidForm ? "active" : ""}
                        disabled={
                          notValidForm ||
                          profileLoaderState.fetching ||
                          userPhotoUpdateLoaderState.fetching
                        }
                        onClick={this.onSaveProfile}
                      >
                        Save Changes
                      </button>
                    </div>
                    {isPopupEnable && (
                      <DialogWrapper dialogProps={dialogProps}>
                        <ChangePasswodDialog
                          validFormCallBack={this.validFormCallBack}
                          isValidChangePasswordForm={isValidChangePasswordForm}
                          onConfirmCallBack={this.onConfirm}
                        />
                      </DialogWrapper>
                    )}
                    {isGoogleId && (
                      <DialogWrapper dialogProps={dialogPropsGoogle}>
                        <ChangeGooglePopup
                          isGoogleId={isGoogleId}
                          onClosePopup={this.onCloseGooglePopup}
                        />
                      </DialogWrapper>
                    )}
                    {loginAgainPopup && (
                      <DialogWrapper dialogProps={dialogPropsLoginAgain}>
                        <ChangeGooglePopup
                          loginAgain={true}
                          onClosePopup={this.onCloseGooglePopup}
                        />
                      </DialogWrapper>
                    )}
                    {/* $$ if changes were made and were successfully saved */}
                  </div>
                </Form>
                {showUnsplashDialog && (
                  <UnsplashDialog
                    onShowUnsplashDialog={this.onShowUnsplashDialog}
                    onRandomizeUnsplash={this.onRandomizeUnsplash}
                    onChangeUnsplash={this.onChangeUnsplash}
                  />
                )}
              </div>
            </div>
          </Scrollbar>
          {/* <div className="billing-pg-container"></div> */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispacthProps)(UserProfile);
