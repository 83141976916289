import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";

export default class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownEnabled: false
    };
  }

  onDropDownClicked = (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDropDownEnabled: !this.state.isDropDownEnabled
    });
    
  };

  onClickDropDownOptions = option => {
    
    const { onClickDropDownOptions } = this.props;
    onClickDropDownOptions(option);
  };

  onOutsideClick = (e) => {
    if (this.showWorkFlowPopupNode && !this.showWorkFlowPopupNode.contains(e.target)) {
      if (this.state.isDropDownEnabled)
        this.setState({
          isDropDownEnabled: !this.state.isDropDownEnabled
        });
  }
  }

  render() {
    
    const dropDownOptions = this.props.dropDownOptions.map(dropDownOption => {
      return (
        <li
          className={
            dropDownOption &&
            this.props.selectedOption &&
            dropDownOption.id === this.props.selectedOption.id
              ? "active"
              : ""
          }
          key={`${dropDownOption.id}${dropDownOption.text}`}
          onClick={() => this.onClickDropDownOptions(dropDownOption)}
        >
          {dropDownOption.text}
        </li>
      );
    });

    return (
      <React.Fragment>
        <div
          className="SortByHeader"
          ref={node => (this.showWorkFlowPopupNode = node)}
        >
          <span>Sort by</span>
          <div
            className={
              this.state.isDropDownEnabled
                ? "workflow-change-ddown-holder clicked"
                : "workflow-change-ddown-holder"
            }
            onClick={this.onDropDownClicked}
          >
            <div className="workflow-change-ddown-text">
              {this.props.selectedOption.text}{" "}
            </div>
            {this.state.isDropDownEnabled && (
              <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <div className="workflow-change-ddown">
                  <ul className="workflow-change-ddown-inner">
                    {dropDownOptions}
                  </ul>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
