import React from "react";
import { getCurrencyLogo, getCurrencyCode } from "../../helpers/generichelper";

export default function BillingPaidPlan({
  currentWorkSpace,
  billingAmounts,
  price,
  changePaymentType,
  paymentType,
  goto2ndStep
}) {
  const noOfUsers = Object.keys(currentWorkSpace.users).length;

  return (
    <>
      <div className="billing-plan-overview">You are on a free plan</div>
      <div className="billing-form-holder">
        <div className="activity-container-loader" ng-show="isLoading">
          <md-progress-circular></md-progress-circular>
        </div>
        <div className="billing-form-title">Move to a paid plan</div>
        <div className="billing-form-inner-holder">
          <div className="billing-form-row">
            <div className="billing-headng">Paying for</div>
            <div className="bil-payingfor-cont-holder">
              <div className="bil-pf-left-part">
                <span>{billingAmounts.users || noOfUsers}</span> users
              </div>
              <div className="bil-pf-right-part">
                <span></span>
                {`${getCurrencyLogo(currentWorkSpace)}${price || ""} per user/month`}
              </div>
            </div>
          </div>

          <div
            className="billing-form-row control"
            ng-init="billingPlan.paymentType"
          >
            <div className="flex-row-sb-top">
              <div className="bil-plan-custom-control-holder">
                <div className="billing-headng plan">Monthly</div>
                <div
                  className={
                    paymentType === "monthly"
                      ? "plan-custom-control active"
                      : "plan-custom-control"
                  }
                  onClick={() => changePaymentType("monthly")}
                >
                  <div className="plan-custom-control-checkbox"></div>
                  <div className="plan-custom-control-text">
                    {getCurrencyLogo(currentWorkSpace)}{billingAmounts.monthly_amount && (billingAmounts.monthly_amount).toFixed(2)}
                    {/* total Cost */}
                  </div>
                </div>
              </div>
              <div className="bil-plan-custom-control-holder">
                <div className="billing-headng plan">Annually</div>
                <div
                  className={
                    paymentType === "yearly"
                      ? "plan-custom-control active"
                      : "plan-custom-control"
                  }
                  onClick={() => changePaymentType("yearly")}
                >
                  <div className="plan-custom-control-checkbox"></div>
                  <div className="plan-custom-control-text">
                    {getCurrencyLogo(currentWorkSpace)}{billingAmounts.yearly_amount && (billingAmounts.yearly_amount).toFixed(2)}
                    {/* total annual cost */}
                  </div>
                </div>
              </div>
            </div>

            <div className="bil-pf-right-part note">
              All the prices mentioned are in {getCurrencyCode(currentWorkSpace)}
            </div>
          </div>

          <div className="billing-form-row control">
            <div className="bill-plan-shortnote">
              You are paying for <span>{billingAmounts.users || noOfUsers}</span> users{" "}
              <span> {paymentType === "yearly" ? "Annually" : "Monthly"}</span>.
              You will be charged on pro rata basis for every new user account
              added to the Workspace. You can add upto 100 users once payment is
              done.
            </div>
          </div>

          <div className="billing-form-row control flex-row-center-center">
            <button
              className="billing-btn"
              // disabled={noOfUsers < 6 ? true : false}
              onClick={() => goto2ndStep()}
            >
              Add Payment
            </button>
          </div>
        </div>
        <div className="billing-benefits-container">
          <div className="billing-headng benefits">Benefits</div>
          <div className="billing-benefits-holder">
            <li>Add unlimited users</li>
            <li>
              Charged only for the number of people added to your workspace
            </li>
            <li>
              For more than 100 users subscription, Please write to &nbsp;
              <a href="mailto:billing@getthingsdone.io">
                billing@getthingsdone.io
              </a>
            </li>
          </div>
        </div>
      </div>
    </>
  );
}
