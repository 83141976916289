import React from "react";
import { getDateValue } from "../../helpers/generichelper";
import closeSvg from "../../styles/img/svg/close_new.svg";


export default function SwitchToAnnualPopup({
  billingInfo,
  hideAnnualPlanPopup,
  updateSubscription
}) {
  return (
    <div>
      <div className="overlay-billing" ng-if="annualPlanPopup">
        <div className="switch-plan">
          <div className="switch-plan-header">
            <p>Switch to Annual Plan</p>
            <img
              src={closeSvg}
              alt="Smiley face"
              height="10"
              width="10"
              onClick={hideAnnualPlanPopup}
            />
          </div>
          <div className="switch-before">
            <p>
              Before you switch to Annual Plan, just a heads up on the total
              amount you will be paying.
            </p>
          </div>
          <div className="switch-users-price">
            <p className="users">
              <span>{billingInfo.data[0].quantity}</span> users
            </p>
            <p className="price-tag">
              {(billingInfo.data[0].quantity * 9.0 * 12).toFixed(2)}
            </p>
          </div>
          <div className="charges">
            <p>
              You will be charged immediately on pro rata basis and your
              contract will be extended by a year, till {getDateValue()}
            </p>
          </div>
          <div className="switch-button">
            <button className="cancel" onClick={hideAnnualPlanPopup}>
              Cancel
            </button>
            <button
              className="switch"
              onClick={() => updateSubscription("yearly")}
            >
              Switch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
