import routesHelper from "../router/routeshelper";

export const workspaceConstant = {
  customerLoaderStateForDroDown: "CUSTOMER_LOADER_STATE_FOR_DROPDOWN",
  customerLoaderState: "CUSTOMER_LOADER_STATE",
  customerDetailsLoaderState: "CUSTOMER_DETAILS_LOADER_STATE",
  pendingCustomerLoaderState: "PENDING_CUSTOMER_LOADER_STATE",
  updateCustomerList: "UPDATE_CUSTOMER_LIST",
  updatePendingCustomerList: "UPDATE_PENDING_CUSTOMER_LIST",
  updatePendingCustomerInviteList: "UPDATE_PENDING_CUSTOMER_INVITE_LIST",
  selectedCustomerDetails: "SELECTED_COSTOMER_DETAILS",
  updateCustomerDetailsLoaderState: "UPDATE_CUSTOMER_DETAILS_LOADER_STATE",
  createWorkspaceLoaderState: "CREATE_WORKSPACE_LOADER_STATE",
  suggestWorkspaceLoaderState: "SUGGEST_WORKSPACE_LOADER_STATE",
  suggestWorkspace: "SUGGEST_WORKSPACE",
  activityLoaderState: "ACTIVITY_LOADER_STATE",
  prioritizedActivityLoaderState: "PRIORITIZED_ACTIVITY_LOADER_STATE",
  deprioritizedActivityLoaderState: "DEPRIORITIZED_ACTIVITY_LOADER_STATE",
  archiveOrUnArchiveLoader: "ARCHIVE_OR_UNARCHIVE_LOADER",
  activityList: "ACTIVITY_LIST",
  totalActivityCount: "TOTAL_ACTIVITY_COUNT",
  archivedActivityList: "ARCHIVED_ACTIVITY_LIST",
  moreArchivedActivityList: "MORE_ARCHIVED_ACTIVITY_LIST",
  archiveOrUnarchive: "ARCHIVE_OR_UNARCHIVE_ACTION",
  prioritizeOrDeprioritize: "PRIORITIZE_OR_DE_PRIORITIZE_ACTION",
  prioritizeOrDeprioritizeKanban: "PRIORITIZE_OR_DE_PRIORITIZE_KANBAN_ACTION",
  prioritizeOrDeprioritizeNotification: "PRIORITIZE_OR_DE_PRIORITIZE_NOTIFICATION_ACTION",
  createOwnWorkspace: "CREATE_WORKSPACE_ACTION",
  updateOwnWorkspace: "UPDATE_WORKSPACE_ACTION",
  updateEachCustomerUser: "UPDATE_EACH_CUSTOMER_USER_LIST",
  addRemoveUserFromWorkspace: "ADD_REMOVE_USER_FROM_WORKSPACE",
  updateOneCustomerUsersList: "UPDATE_ONE_CUSTOMER_USERS_LIST",
  updateCustomerForUser: "UPDATE_CUSTOMER_FOR_USER",
  updateDeprioritizedActivitiesLoaderState:
    "UPDATE_DEPRIORITIZED_ACTIVITIES_LOADER_STATE",
  updateDeprioritizedActivities: "UPDATE_DEPRIORITIZED_ACTIVITIES",
  clearReducer: "CLEAR_REDUCER",
  clearReducerWithPrioritize: "CLEAR_REDUCER_WITH_PRIORITIZE",
  setPrioritizedActivity: "SET_PRIORITIZED_ACTVITY",
  listOfCurrencies: "LIST_OF_CURRENCIES",
  timerStatus: "TIMER_STATUS",
  defaultBreadcrumb: [
    { name: "Workspace", link: routesHelper.getRootPath(), type: "heading" },
    {
      name: "Find a Workspace",
      link: routesHelper.getFindWorkspacePath(),
      type: "list"
    }
  ]
};
