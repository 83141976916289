import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import React, { Component, PureComponent } from "react";
import Avatar from "react-avatar";
import OutsideClickHandler from "react-outside-click-handler";
import TimerMachine from "react-timer-machine";
import { activities } from "../../constants/activitiescontants";
import { convertTimeToString } from "../../helpers/generichelper";
import routesHelper from "../../router/routeshelper";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import play from "../../styles/img/svg/play_new.svg";
import star_active from "../../styles/img/svg/star_active.svg";
import star_disabled from "../../styles/img/svg/star_disabled.svg";
import star_empty from "../../styles/img/svg/star_empty.svg";
import stop from "../../styles/img/svg/stop_new.svg";
import "../../styles/scss/activity.scss";
import firebase from "../../firebase";

momentDurationFormatSetup(moment);
class ActivityTile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      starIcon: "",
      timer: 0,
      tabActiveFlag: true,
      showDeprioritizedCard: false,
      showOptionIcon: false,
      showFlowPopup: false,
      timerShouldBeDisabled: false,
      totalTimeTaken: 0,
      showArchivePopup: false
    };
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.setState({ tabActiveFlag: true });
        this.onTileHover();
      } else {
        this.setState({ tabActiveFlag: false })
      }
    })
  }

  onTileHover = () => {
    const { prioritiZedActivity } = this.props;

    if (prioritiZedActivity.length >= 3) {
      this.setState({
        starIcon: star_disabled,
        showDeprioritizedCard: true
      });
    } else if (prioritiZedActivity.length < 3) {
      this.setState({
        starIcon: star_empty,
        showDeprioritizedCard: false
      });
    }
  };

  onTileLeave = () => {
    this.setState({
      starIcon: ""
    });
  };
  // change
  renderTimerLogic = activity => {
    const currentUserId = parseInt(localStorage.getItem("id"));
    var timerStartTimeInSeconds = 0;
    let timerShouldBeEnabled = null;

    const timesheet = activity && activity.timesheets && activity.timesheets.filter(
      ts => ts.user_id === currentUserId
    );
    // change
    let totalTimeTaken = timesheet && timesheet.reduce((totalTime, current) => {
      return (totalTime + current.time_taken);
    }, 0);

    timerStartTimeInSeconds =
      timesheet &&
      timesheet.reduce((totalTime, current, index) => {
        return (
          (totalTime = 0)
        );
      }, 0);

    const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);

    if (sortedTimesheetData.length > 0) {
      timerShouldBeEnabled =
        (sortedTimesheetData[0].data &&
          _.has(sortedTimesheetData[0].data.TIME[0], "STOP") &&
          _.has(sortedTimesheetData[0].data.TIME[0], "START")) ||
          sortedTimesheetData[0].data.TIME.length <= 0
          ? true
          : false;
    } else {
      timerShouldBeEnabled = true;
    }

    if (
      !timerShouldBeEnabled &&
      sortedTimesheetData[0].data &&
      sortedTimesheetData[0].data.TIME[0] &&
      sortedTimesheetData[0].data.TIME[0].hasOwnProperty("START")
    ) {
      timerStartTimeInSeconds += moment(new Date()).diff(
        sortedTimesheetData[0].data.TIME[0].START,
        "seconds"
      );
    }

    return {
      timerStartTimeInSeconds,
      timerShouldBeEnabled,
      totalTimeTaken
    };
  };

  renderTimerLogicNew = () => {
    const { activityDetails, anyTimerStatus } = this.props;
    const currentUserId = parseInt(localStorage.getItem("id"));
    var timerStartTimeInSeconds = 0;
    let timerShouldBeEnabled = null;

    const timesheet = activityDetails && activityDetails.timesheets && activityDetails.timesheets.filter(
      ts => ts.user_id === currentUserId
    );
    let totalTimeTaken = timesheet && timesheet.reduce((totalTime, current) => {
      return (totalTime + current.time_taken);
    }, 0);

    if(activityDetails && anyTimerStatus?.any_timer_running && anyTimerStatus?.timesheet?.activity_id === activityDetails.id) {
      timerShouldBeEnabled = false;
      timerStartTimeInSeconds += moment(new Date()).diff(
        anyTimerStatus.timesheet.data.TIME[0].START,
        "seconds"
      );
    } else  {
      timerShouldBeEnabled = true;
    }

    return {
      timerStartTimeInSeconds,
      timerShouldBeEnabled,
      totalTimeTaken
    }
  }

  onPlayPauseClicked = (_timerEnabled, selectedTileDetials) => {
    const { startTimerAction, shouldEnablePopup, getTimerStatusForWorkspaceAction, anyTimerStatus, onShowCannotStartPopup } = this.props;
    if (startTimerAction) {
      //fetching Timesheet for current user
      const customer_auth_token = localStorage.getItem("customer_auth_token");
      const currentUserId = parseInt(localStorage.getItem("id"));

      const currentRunningTimeSheet = anyTimerStatus?.timesheet?.activity_id === selectedTileDetials.id ?
        anyTimerStatus.timesheet : null;

      // const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);
      // if (selectedTileDetials.id === 9) {
      //console.log(
      //     "render Logic",
      //     _.has(sortedTimesheetData[0].data.TIME[0], "STOP") &&
      //       sortedTimesheetData[0].data &&
      //       sortedTimesheetData.length
      //   );
      // }
      // this condition to be rewritten
      // if (
      //   sortedTimesheetData.length > 0 &&
      //   _.has(sortedTimesheetData[0].data.TIME[0], "START") &&
      //   !_.has(sortedTimesheetData[0].data.TIME[0], "STOP")
      // ) {
      // New condition from timer API
      if (
        currentRunningTimeSheet &&
        _.has(currentRunningTimeSheet.data.TIME[0], "START") &&
        !_.has(currentRunningTimeSheet.data.TIME[0], "STOP")
      ) {
        shouldEnablePopup(true, selectedTileDetials);
        firebase.analytics().logEvent("GTD_Activity_timer_stop");
        // Create ne timesheet Object and call updateTimesheetAction
      } else {
        const currentTime = moment(new Date()).format();
        const data = {
          TIME: [{ START: currentTime }]
        };
        startTimerAction(
          {
            activity_id: selectedTileDetials.id,
            data,
            date: currentTime,
            user_id: currentUserId,
            customer_auth_token
          },
          response => {
            if(response && response.id) {
              firebase.analytics().logEvent("GTD_Activity_timer_start");
              getTimerStatusForWorkspaceAction({ auth_token: routesHelper.getAuthToken() });
            }
            if(response?.error?.msg === "Timer already running" || response?.error?.msg === "You are not a part of this activity card") {
              onShowCannotStartPopup(response);
            }
          }
        );
      }
    }
  };

  onPrioritize = () => {
    const { archiveOrUnArchiveActivitiesAction, archiveOrUnarchiveLoaderState, activityDetails, onShowCannotPrioritizePopup } = this.props;
    if(!archiveOrUnarchiveLoaderState.fetching) {
    let updatedActivity = { ...activityDetails };
    let user_id = parseInt(localStorage.getItem("id"));
    if (
      !_.isUndefined(updatedActivity.data) &&
      !updatedActivity.data.hasOwnProperty("priority")
    ) {
      updatedActivity.data.priority = [];
    }
    let isItemPrioritized =
      updatedActivity.data && updatedActivity.data.priority
        ? updatedActivity.data.priority.filter(priority => {
          return priority.user_id === user_id;
        })
        : [];
    const customer_auth_token = localStorage.getItem(
      activities.customerAuthToken
    );
    let prioritizedUserObject = {
      user_id: user_id,
      date: moment(new Date()).format()
    };
    let prioritize = {
      data: {
        ...updatedActivity.data,
        priority: updatedActivity.data.priority
          ? [...updatedActivity.data.priority, prioritizedUserObject]
          : [prioritizedUserObject]
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id
    };

    let dePrioritize = {
      data: { ...updatedActivity.data, priority: updatedActivity.data.priority.filter(priority => priority.user_id !== user_id) },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id
    };

    archiveOrUnArchiveActivitiesAction(
      {
        auth_token: customer_auth_token,
        archiveData: isItemPrioritized.length > 0 ? dePrioritize : prioritize,
        type:
          isItemPrioritized.length > 0
            ? activities.dePrioritize
            : activities.prioritize
      },
      response => {
        if(response && response.id)
          firebase.analytics().logEvent("GTD_Activity_priority_dePriority");
        if(response?.error === "Priority limit exceeded") {
          onShowCannotPrioritizePopup(response);
        }
      }
    );
  }
  };

  showWorkFlowPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showFlowPopup: !this.state.showFlowPopup
    });
  };

  renderPeoples = () => {
    const {
      activityDetails: { user_id }
    } = this.props;
    return (
      <div className="people-wrap">
        <div className="people">
          <img
            src="https://s3.amazonaws.com/gtd/users/default.png"
            alt="Default icon"
            className="border"
          />
        </div>
        <div className="people">
          <img
            src="https://s3.amazonaws.com/gtd/users/default.png"
            alt="Default icon"
            className="border"
          />
        </div>

        {<p>{"+" + user_id.length}</p>}
      </div>
    );
  };

  checkIfPriorityFull = prioritiZedActivity => {
    if (prioritiZedActivity && prioritiZedActivity.length >= 3) {
      return;
    } else {
      this.onPrioritize();
    }
  };

  onOptionIconClick = () => {
    this.setState({
      showOptionIcon: !this.state.showOptionIcon
    });
  };

  handleClickOutside = event => {
    event.stopPropagation();
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        showOptionIcon: false,
        showArchivePopup: false
      });
    }

    if (
      this.showWorkFlowPopupNode &&
      !this.showWorkFlowPopupNode.contains(event.target)
    ) {
      if (this.state.showFlowPopup) {
        this.setState({
          showFlowPopup: false
        });
      }
    }
  };

  changeWorkFlow = (workflow, index, is_Priority) => {
    const { activityDetails, changeWorkFlowAction } = this.props;
    const workflowToSend = {
      id: index,
      name: workflow.name,
      moved_from: _.isNull(activityDetails.workflow)
        ? "Backlog"
        : activityDetails.workflow.name
    };
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    changeWorkFlowAction(
      {
        id: activityDetails.id,
        is_Priority,
        workflow: workflowToSend,
        customer_auth_token
      },
      resp => {
        if(resp && resp.id)
          firebase.analytics().logEvent("GTD_Activity_status");
      }
    );
  };

  onViewKanban = () => {
    firebase.analytics().logEvent("GTD_Activity_kanban");
    const { router, activityDetails } = this.props;

    router.push(routesHelper.kanbanPath(activityDetails?.project?.id || activityDetails.project_id));
  };

  onShowArchivePopup = (event) => {
    event.stopPropagation();
    this.setState({ showArchivePopup: true });
  }

  onHideArchivePopup = (event) => {
    event.stopPropagation();
    this.setState({ showArchivePopup: false });
  }

  render() {
    const {
      activityDetails,
      shouldShowIcons,
      prioritiZedActivity,
      handleShowPopup,
      handleClickEditCallback,
      unArchivedProjectList,
      onArchiveItem
    } = this.props;

    const { showArchivePopup } = this.state;

    let loggedInUserId = parseInt(localStorage.getItem("id"));

    let timeSheet =
      activityDetails.timesheets &&
      activityDetails.timesheets.length > 0 &&
      activityDetails.timesheets;

    let currentTimerDetails =
      timeSheet &&
      timeSheet[0] &&
      timeSheet[0].data.TIME &&
      timeSheet[0].data.TIME.length &&
      timeSheet[0].data.TIME[timeSheet[0].data.TIME.length - 1];

    let shouldEnableTimer =
      currentTimerDetails &&
      currentTimerDetails.START &&
      !currentTimerDetails.STOP;

    const renderTimerLogic = this.renderTimerLogicNew();
    // let interValSatrtTime =
    //   currentTimeInSec - renderTimerLogic.timerStartTimeInSeconds;
    //Rakesh Code for timer

    const is_Priority =
      prioritiZedActivity.filter(activity => activity.id === activityDetails.id)
        .length > 0;
    const workflows =
      activityDetails.project && activityDetails.project.workflow;
    const arrayOfObj = Object.entries(workflows).map((e) => ( { [e[0]]: e[1] } ));
    const workflowOptions = arrayOfObj
    .filter(workflow => {
      const workflowId = Object.getOwnPropertyNames(workflow)[0];
      return !workflow[workflowId].is_archived
    }).map((workflow) => {
      const workflowId = Object.getOwnPropertyNames(workflow)[0];
      return (
        <li
          className={
            activityDetails.workflow
              ? activityDetails.workflow.name === workflow[workflowId].name ? "active" : ""
              : workflow[workflowId].name === "Backlog" ? "active" : ""
          }
          key={workflow[workflowId].name}
          onClick={() => this.changeWorkFlow(workflow[workflowId], workflowId, is_Priority)}
        >
          {workflow[workflowId].name}
        </li>
      );
    });

    const shouldViewInKanban = unArchivedProjectList.filter(project => project.id === (activityDetails?.project?.id || activityDetails.project_id)).length > 0;

    const logged_in_user_duration = activityDetails.users_duration && activityDetails.users_duration.length>0 && activityDetails.users_duration.filter(user => user.user_id === loggedInUserId)[0];

    return (
      <div
        className="act-card"
        onMouseOver={this.onTileHover}
        onMouseLeave={this.onTileLeave}
      >
        <div className="act-header">
          <div className="header-wrap">
            <div className="client-logo">
              {activityDetails.project.img ?
                <img src={activityDetails.project.img} alt="project-logo" />
                :
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  name={activityDetails.project && activityDetails.project.name.split("").join(" ")}
                  maxInitials={1}
                />}

              {/* <md-tooltip md-direction="bottom">{{activity.client.name}}</md-tooltip> */}
            </div>
            <div ref={node => (this.showWorkFlowPopupNode = node)}>
              <div
                className={
                  this.state.showFlowPopup
                    ? "workflow-change-ddown-holder clicked"
                    : "workflow-change-ddown-holder"
                }
                onClick={this.showWorkFlowPopup}
              >
                <div className="workflow-change-ddown-text">
                  {(activityDetails.workflow && activityDetails.workflow.name) || "Backlog"}{" "}
                </div>
                {this.state.showFlowPopup && (
                  <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
                    <div className="workflow-change-ddown">
                      <ul className="workflow-change-ddown-inner">
                        {workflowOptions}
                      </ul>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>

          <div className="menu-options options-activity">
            <div
              className="wrap-star-options"
              ref={node => (this.outerDivNode = node)}
            >
              <div className="menu-options wp">
                <div className="star-icon">
                  {shouldShowIcons.priority &&
                    renderTimerLogic.timerShouldBeEnabled && (
                      <Tooltip title="Deprioritize">
                        <span>
                          <img
                            src={star_active}
                            className={`star${
                              renderTimerLogic.timerShouldBeEnabled
                                ? "-disabled"
                                : ""
                              }`}
                            alt="more_vert"
                            onClick={
                              renderTimerLogic.timerShouldBeEnabled
                                ? this.onPrioritize
                                : () => {
                                  // This is intentional
                                }
                            }
                            role="button"
                          />
                        </span>
                      </Tooltip>
                    )}
                  {shouldShowIcons.priority &&
                    !renderTimerLogic.timerShouldBeEnabled && (
                      <span>
                        <img
                          src={star_active}
                          className={`star${
                            renderTimerLogic.timerShouldBeEnabled
                              ? "-disabled "
                              : " priotized-card-timer-enabled"
                            }`}
                          alt="more_vert"
                          onClick={
                            renderTimerLogic.timerShouldBeEnabled
                              ? this.onPrioritize
                              : () => {
                                // This is intentional
                              }
                          }
                        />
                      </span>
                    )}

                  {shouldShowIcons.priority_empty && this.state.starIcon && (
                    <Tooltip
                      title={
                        prioritiZedActivity.length >= 3
                          ? "Deprioritize a card to prioritize this card"
                          : "Prioritize"
                      }
                    >
                      <img
                        src={this.state.starIcon}
                        className={`rest-star${
                          prioritiZedActivity.length >= 3 ? "-disabled" : ""
                          }`}
                        alt="more_vert"
                        onClick={() => {
                          this.checkIfPriorityFull(prioritiZedActivity);
                        }}
                        role="button"
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="option-icon" onClick={this.onOptionIconClick}>
                <div className="dot-holder">
                  <img src={dotIcon} alt="dot-icon" />
                </div>
                {this.state.showOptionIcon && (
                  <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
                    <div className="options-block">
                      {shouldViewInKanban && <div className="act-menu-option" onClick={this.onViewKanban}>View in Kanban</div>}
                      <div
                        className="act-menu-option"
                        onClick={() => handleClickEditCallback(activityDetails)}
                      >
                        Edit
                      </div>
                      {renderTimerLogic.timerShouldBeEnabled && (
                        <React.Fragment>
                        <div
                          className="act-menu-option"
                          onClick={this.onShowArchivePopup}
                        >
                          Archive
                        </div>
                        {showArchivePopup && (
                          <div className="deleteflow-box" onClick={(event) => event.stopPropagation()}>
                            <p>Archive this activity?</p>
                            <div className="button">
                              <span
                                className="cancel"
                                onClick={this.onHideArchivePopup}
                              >
                                Cancel
                              </span>
                              <button
                                className="save"
                                onClick={() => {
                                  onArchiveItem(activityDetails);
                                }}
                              >
                                Archive
                              </button>
                            </div>
                          </div>
                        )}
                        </React.Fragment>
                      )}
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="act-body" onClick={handleShowPopup}>
          <Tooltip title={activityDetails.title}>
            <div className="act-title">{activityDetails.title}</div>
          </Tooltip>
          <div className="proj-title">
            {activityDetails.project && activityDetails.project.name}
          </div>
          {/* <div className="proj-title">{renderTimerLogic.totalTimeTaken}</div> */}
        </div>

        <div className="date-time">
          <div className="duration-wrap">
            <Tooltip title="Due Date exceeded">
            <div
              className={
                moment(
                  moment(logged_in_user_duration?.end_date).format("YYYY-MM-DD")
                ).isSameOrAfter(moment(Date.now()).format("YYYY-MM-DD"))
                  ? "act-due-on-on-target"
                  : "act-due-on-delayed"
              }
            >
              {/* {{activity.due_on | amDateFormat:'ddd, DD MMM YYYY'}} */}
              {moment(logged_in_user_duration?.end_date).format("ddd, DD MMM YY")}
            </div>
            </Tooltip>
            {!moment(
                  moment(logged_in_user_duration?.end_date).format("YYYY-MM-DD")
                ).isSameOrAfter(moment(Date.now()).format("YYYY-MM-DD")) && 
                renderTimerLogic.totalTimeTaken > logged_in_user_duration?.total_time &&
                <div className="separator">|</div>}
            <Tooltip title="Your Time exceeded">
            <div className="planned-upcoming">
              {renderTimerLogic.totalTimeTaken > logged_in_user_duration?.total_time && (
                <span className="yes-delayed">
                  {/* <img src={delayedIcon} alt="delayed-icon" /> */}
                  <span> {convertTimeToString(logged_in_user_duration?.total_time)}</span>
                </span>
              )}

              {renderTimerLogic.totalTimeTaken < logged_in_user_duration?.total_time && (
                <span className="not-delayed">
                  {/* <img src={onTimeIcon} alt="onTime-icon" /> */}
                  <span> {convertTimeToString(logged_in_user_duration?.total_time)}</span>
                </span>
              )}
            </div>
            </Tooltip>
          </div>

          {/* $$ if target on time css class is act-due-on-on-target
                        if target is delayed css class is act-due-on-delayed */}

          {shouldShowIcons.show_play_icon && (
            <div className="timer">
              <div>
                {/* <div>{{activity.INTERVAL}}{{currentTime - activity.INTERVAL*1000}}</div> */}
                {!renderTimerLogic.timerShouldBeEnabled && this.state.tabActiveFlag && (
                  <span className="timer-activity">
                    <TimerMachine
                      timeStart={
                        renderTimerLogic.timerStartTimeInSeconds * 1000
                      }
                      started={!renderTimerLogic.timerShouldBeEnabled}
                      paused={false}
                      countdown={false}
                      interval={1000}
                      formatTimer={(_time, ms) =>
                        moment
                          .duration(ms, "milliseconds")
                          .format("HH:mm:ss", { trim: false })
                      }
                    // onStart={}
                    />
                  </span>
                )}
                {renderTimerLogic.timerShouldBeEnabled && is_Priority && (
                  <span className="timer-activity">
                    {renderTimerLogic.timerStartTimeInSeconds > 0 &&
                      moment
                        .duration(
                          renderTimerLogic.timerStartTimeInSeconds,
                          "seconds"
                        )
                        .format("HH:mm:ss", { trim: false })}
                  </span>
                )}
              </div>

              {/* $$ if activity is running, show stop icon, css class is stop
                            if activity isnt running, show play icon, css class is play */}
              <div
                className="timer-button"
                style={{ cursor: "pointer" }
                  // this.props.timerRunningStatus &&
                  //   renderTimerLogic.timerShouldBeEnabled
                  //   ? { pointerEvents: "none", cursor: "default" }
                  //   : { cursor: "pointer" }
                }
              >
                {" "}
                {is_Priority && (
                  <img
                    src={renderTimerLogic.timerShouldBeEnabled ? play : stop}
                    alt="Play/Pause"
                    className="play-btn pause"
                    onClick={() => {
                      this.onPlayPauseClicked(
                        shouldEnableTimer,
                        activityDetails
                      );
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ActivityTile.defaultProps = {
  shouldShowIcons: {
    archive: true,
    edit: true,
    priority: true
  }
};

export default ActivityTile;