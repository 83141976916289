import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import CommonNav from "./commonnav";
import "../../styles/scss/register.scss";
import JoinWorkspaceTile from "./joinworkspacetile";
import routeshelper from "../../router/routeshelper";
import firebase from "../../firebase";

class JoinWorkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerMessage: ""
    };
  }
  renderItems = () => {
    const { foundCustomersSuggestions } = this.props;
    const items = foundCustomersSuggestions.map((workspace) => {
      return (
        <JoinWorkspaceTile
          workspace={workspace}
          requestToJoin={this.requestToJoinWorkSpace}
        />
      );
    });

    return items;
  };

  requestToJoinWorkSpace = (workspace, callback) => {
    const { requestToJoinWorkspace } = this.props;
    requestToJoinWorkspace &&
      requestToJoinWorkspace(workspace, (response) => {
        callback && callback(response);
      });
  };

  onFindWorkspace = () => {
    const { router } = this.props;
    router.push(routeshelper.getFindWorkspacePath());
  };

  onCreateWorkpsace = () => {
    const { router } = this.props;
    router.push(routeshelper.getCreateWorkspacePath());
  };

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Registration_Flow_Join_Workspace");
    window.onpopstate = (e) => {
      e.preventDefault();
      this.props.router.push(window.location.pathname);
    };
  }

  render() {
    const { router } = this.props;
    let headerMessage =
      this.renderItems().length > 0
        ? "We found the following workspaces"
        : "We couldn’t find any workspaces for you to join";
    // if(items.length > 0) {
    //   this.setState({
    //     headerMessage :
    //   })
    // }else {
    //   this.setState({
    //     headerMessage : "We couldn’t find any workspaces for you to join"
    //   });
    // }
    return (
      <Scrollbar className="add-users-scroll"
        >
      <div className="joinWorkspace-container">
        <div>
          <CommonNav router={router} />
        </div>
        <div>
          <div className="join-container">
            <div
              className={
                this.renderItems().length > 0
                  ? "join-header"
                  : "join-header not-found"
              }
            >
              <p className="join-title">{headerMessage}</p>
            </div>

            {this.renderItems().length > 0 && (
              <div className="choose-workspace join-wsp">{this.renderItems()}</div>
            )}
          </div>

          <div className="join-container-footer">
            <p className="not-expec">
              <span className="find-workspace" onClick={this.onFindWorkspace}>
                {" "}
                Find your workspace{" "}
              </span>
              <span>
                or{" "}
                <span className="find-workspace" onClick={this.onCreateWorkpsace}>
                  {" "}
                  Create new workspace
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      </Scrollbar>
    );
  }
}

export default JoinWorkspace;
