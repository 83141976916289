import { projectConstants } from "../../constants/projectconstants";
import { peopleConstants } from "../../constants/peopleconstants";
// import { ArchivedConstant as archivedConstant } from "../../constants/archivedConstant";

const initialState = {
  projectListLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateProjectLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateRoleLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  singleProjectLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  listOfProjects: {
    list: [],
    key: null
  },
  listOfArchivedProjects: {
    list: [],
    key: null
  }
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case projectConstants.projectListLoaderState:
      return {
        ...state,
        projectListLoaderState: action.payload
      };
    case peopleConstants.updateRoleLoaderState:
      return {
        ...state,
        updateRoleLoaderState: action.payload
      };
    case projectConstants.updateProjectLoaderState:
      return {
        ...state,
        updateProjectLoaderState: action.payload
      };
    case projectConstants.singleProjectLoaderState:
      return {
        ...state,
        singleProjectLoaderState: action.payload
      };
    case projectConstants.projectList:
      return {
        ...state,
        listOfProjects: {
          list: action.payload.list
        }
      };
    case projectConstants.updateProjectDetails:
      //console.log("state",state.listOfProjects.list)
      if (action.payload.archived) {
        const updatedList = state.listOfArchivedProjects.list.map((project) => {
          if (project.id === action.payload.id) return action.payload;
          return project;
        });
        //console.log("update project details case", updatedList);
        return {
          ...state,
          listOfArchivedProjects: {
            list: updatedList
          }
        };
      } else {
        const updatedList = state.listOfProjects.list.map((project) => {
          if (project.id === action.payload.id) return action.payload;
          return project;
        });
        //console.log("update project details case", updatedList);
        return {
          ...state,
          listOfProjects: {
            list: updatedList
          }
        };
      }
    case projectConstants.createProject:
      //console.log("new project from reducer", action.payload);
      let list = [...state.listOfProjects.list, action.payload];
      //list.concat(action.payload)
      //console.log("with new project from reducer", list);
      return {
        ...state,
        listOfProjects: {
          list
        }
      };

    case projectConstants.archivedProjectList:
      return {
        ...state,
        listOfArchivedProjects: {
          ...state.listOfArchivedProjects,
          list: [...action.payload.list]
        }
      };
    case projectConstants.unarchiveProject:
      if (action.payload.project.archived) {
        return {
          ...state,
          listOfProjects: {
            ...state.listOfProjects,
            list: state.listOfProjects.list.filter(
              (project) => project.id !== action.payload.project.id
            )
          },
          listOfArchivedProjects: {
            ...state.listOfArchivedProjects,
            list: [
              {
                ...state.listOfProjects.list.filter(
                  (project) => project.id === action.payload.project.id
                )[0],
                ...action.payload.project
              },
              ...state.listOfArchivedProjects.list
            ]
          }
        };
      }
      return {
        ...state,
        listOfProjects: {
          ...state.listOfProjects,
          list: [
            ...state.listOfProjects.list,
            {
              ...state.listOfArchivedProjects.list.filter(
                (project) => project.id === action.payload.project.id
              )[0],
              archived: false
            }
          ]
        },
        listOfArchivedProjects: {
          ...state.listOfArchivedProjects,
          list: state.listOfArchivedProjects.list.filter(
            (project) => project.id !== action.payload.project.id
          )
        }
      };
    case projectConstants.getProjectById:
      return {
        ...state,
        project: action.payload.project
      };
    case projectConstants.clearProjects:
      return {
        ...state,
        listOfProjects: {
          list: action.payload
        },
        listOfArchivedProjects: {
          list: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
}
