import { peopleConstants } from '../../constants/peopleconstants';
import { sort, ascend, prop, findIndex, propEq } from "ramda";
const initialState = {
    customerLoaderState: {
      fetching: false,
      fetched: false,
      error: false,
      key: null
    },
    updateCustomerLoaderState: {
      fetching: false,
      fetched: false,
      error: {
        bool: false,
        value: ""
      },
      key: null
    },
    updateEachUserProfileLoaderState: {
      fetching: false,
      fetched: false,
      error: {
        bool: false,
        value: ""
      },
      key: null
    },
    listOfCustomer: {
        list: [],
        key: null
      },
    selectedCustomerDetails: {},
    workspaceusers : [],
    invitedEmails: [],
    requestedUsers: []
}
const sortAndMoveCurrentUserItemToFirst = list => {
    if (list) {
      let sortedList = sort(ascend(prop("name")), list.slice());
      const myUserId =
        sessionStorage.getItem("id") && parseInt(sessionStorage.getItem("id"));
      let foundMyWorspaceIndex = findIndex(propEq("super_admin_id", myUserId))(
        sortedList
      );
      if (foundMyWorspaceIndex > -1) {
        sessionStorage.setItem("haveMyWorkspace", true);
        var toBeMovedElement = sortedList[foundMyWorspaceIndex];
        sortedList.splice(foundMyWorspaceIndex, 1);
        sortedList.splice(0, 0, toBeMovedElement);
      }
  
      return sortedList;
    }
    return list;
  };
export default function peopleReducer(state = initialState, action) {
    switch (action.type) {
      case peopleConstants.customerLoaderState:
        return {
          ...state,
          customerLoaderState: action.payload
        };
        case peopleConstants.updateCustomerDetailsLoaderState:
            return {
              ...state,
              updateCustomerLoaderState: action.payload
        };
        case peopleConstants.updateEachUserProfile:
          return {
            ...state,
            updateEachUserProfileLoaderState: action.payload
          }
        case peopleConstants.updateCustomerList:
            return {
              ...state,
              listOfCustomer: {
                list: sortAndMoveCurrentUserItemToFirst(action.payload.list),
                key: action.payload.key
              }
            };
        case peopleConstants.updateWorkspaceUser:
          //console.log("updateworkspaceusers");
            return {
                ...state,
                workspaceusers : action.payload
              }
        case peopleConstants.getInvitedEmails:
          return {
            ...state,
            invitedEmails: action.payload
          }
        case peopleConstants.clearInvitedEmails:
          return {
            ...state,
            invitedEmails: action.payload
          }
        case peopleConstants.getRequestedUsers:
          return {
            ...state,
            requestedUsers: action.payload
          }
        case peopleConstants.removeWorkspaceUser:
          return {
            ...state,
            workspaceusers: state.workspaceusers.filter(user => user.id !== action.payload)
          }
        case peopleConstants.updateEachWorkspaceUserProfile:
        //console.log("updateEachWorkspaceUserProfile reducer is called", action.payload.id );
        return {
          ...state,
          workspaceusers:state.workspaceusers.map(
            user => {
              return user.id === action.payload.id ? action.payload.user: user
            }
           )
        }
      default: 
      return {
          ...state
      }
    }
    
}