import {DialogConstants} from "../../constants/dialogConstants";


export const  updateScrollTopHeight=(scrollHeight)=>{
    return {
        type:DialogConstants.SCROLL_TOP,
        payload:{
            scrollHeight:scrollHeight
        }
    }
}

export const updateScrollTopHeightAction=({scrollHeight})=>(dispatch)=>{
        dispatch(updateScrollTopHeight(scrollHeight));
    };