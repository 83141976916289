import React, { useState, memo } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
// import approveReq from "../../styles/img/svg/approve-proj-req.svg";
// import declineReq from "../../styles/img/svg/decline-proj-req.svg";

const ProjectAccessPersonal = ({
  currentWorkSpace,
  currentUser,
  projectAccessReqPersonal,
  updateProjectAccessReqPersonalAction,
  getProjectAccessReqPersonalAction
}) => {
  const [sortIn, setSortIn] = useState(false);
  const [sortBy, setSortBy] = useState("req_on");

  const onChangeSort = sortOp => {
    if (sortOp === sortBy) {
      setSortIn(!sortIn);
    } else {
      setSortBy(sortOp);
      setSortIn(false);
    }
  };

  const onCancelResend = (proj, action) => {
    let body = {
      project_id: proj.project_id,
      user_id: parseInt(localStorage.getItem("id"))
    };
    if (action === "cancel") {
      body.id = proj.id;
      body.access_status = "CANCELLED";
    }
    if (action === "resend") body.access_status = "REQUEST";
    updateProjectAccessReqPersonalAction(
      {
        auth_token: currentWorkSpace.auth_token,
        body,
        method: action === "resend" ? "POST" : "PUT"
      },
      res => {
        if (res && !res.error && !res.stdErr) {
          const customer_id = parseInt(currentWorkSpace.id);
          const from = moment()
            .subtract(1, "month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          const to = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          const queryParam = `?customer_id=${customer_id}&from_date=${from}&to_date=${to}`;
          getProjectAccessReqPersonalAction({
            auth_token: currentUser.auth_token,
            queryParam: queryParam.concat("&type=project-access-request")
          });
        }
      }
    );
  };

  const renderProjectAccessReqPersonal = () => {
    let sortedProjectAccessReqPersonal = [];
    if (sortBy === "project") {
      if (sortIn) {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.project,
          "asc"
        );
      } else {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.project,
          "desc"
        );
      }
    }
    if (sortBy === "req_on") {
      if (sortIn) {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.requested_on,
          "asc"
        );
      } else {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.requested_on,
          "desc"
        );
      }
    }
    if (sortBy === "status") {
      if (sortIn) {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.status,
          "asc"
        );
      } else {
        sortedProjectAccessReqPersonal = _.orderBy(
          projectAccessReqPersonal,
          proj => proj.status,
          "desc"
        );
      }
    }
    return sortedProjectAccessReqPersonal.filter(proj => proj.status !== "CANCELLED").map(proj => {
      return (
        <React.Fragment>
          <tr>
            {/* <td className="emp-img-name">
              {proj.employee}
            </td> */}
            <td className="proj-img-name" style={{ width: "180px" }}>
            <div>
              {proj.project_img ? (
                <img src={proj.project_img} alt="project-logo" />
              ) : (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={proj.project}
                ></Avatar>
              )}
              {proj.project}
              </div>
              </td>
            <td style={{ width: "165px" }}>
              {moment(proj.requested_on).format("ddd, DD MMM YY")}
            </td>
            {proj.status === "DENIED" && (
              <td style={{ color: "#ef5350", width: "130px" }}>Denied</td>
            )}
            {proj.status === "APPROVED" && (
              <td style={{ color: "#65ac0a", width: "130px" }}>Approved</td>
            )}
            {proj.status === "REQUEST" && (
              <td style={{ color: "#ffa428", width: "130px" }}>Pending</td>
            )}
            <td>
              {proj.status === "REQUEST" && (
                <button
                  className="cancel-req-btn"
                  onClick={() => onCancelResend(proj, "cancel")}
                >
                  Cancel request
                </button>
              )}
              {proj.status === "DENIED" && (
                <button
                  className="req-again-btn"
                  onClick={() => onCancelResend(proj, "resend")}
                >
                  Request again
                </button>
              )}
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
          <div className="over-title">Project Access Request</div>
        </div>
        {_.isArray(projectAccessReqPersonal) && (
          <div className="table-holder">
            <table style={{ width: "98.3%" }}>
              <thead>
                <tr>
                  <th
                    onClick={() => onChangeSort("project")}
                    style={{ width: "180px" }}
                  >
                    <span
                      className={`sort-arrow ${(sortBy === "project" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "project" && sortIn === true && "up")}`}
                    >
                      Project
                    </span>
                  </th>
                  <th
                    onClick={() => onChangeSort("req_on")}
                    style={{ width: "165px" }}
                  >
                    <span
                      className={`sort-arrow ${(sortBy === "req_on" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "req_on" && sortIn === true && "up")}`}
                    >
                      Requested on
                    </span>
                  </th>
                  <th onClick={() => onChangeSort("status")}>
                  <span
                      className={`sort-arrow ${(sortBy === "status" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "status" && sortIn === true && "up")}`}
                    >
                      Status
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
            <Scrollbar>
              <table>
                <tbody>{renderProjectAccessReqPersonal()}</tbody>
              </table>
            </Scrollbar>
          </div>
        )}
        {typeof projectAccessReqPersonal === "object" &&
          projectAccessReqPersonal?.msg && (
            <div className="no-data">{projectAccessReqPersonal.msg}</div>
          )}
      </div>
    </React.Fragment>
  );
};

export default memo(ProjectAccessPersonal);
