import React,{ Component } from 'react';
import routesHelper  from '../../router/routeshelper';
import '../../styles/scss/settings.scss';
import '../../styles/scss/workspaceSettings.scss';
// import WorkSpaceForm from './workspaceform';

class WorkspaceSettings extends Component{
render(){
    //console.log(this.props);
    const { router:{ location:{pathname} }} = this.props;
    const isWrokspaceSettings =  pathname && pathname === routesHelper.getWorkspaceSettingsPath(); 
  
    return(
    <div className="settings-tab-container">
       <div className="settings-tab" >
           <div className="inner-tabs">
               <ul className="settings-tab-holder">
                {isWrokspaceSettings && <li  className='active'>Workspace</li>} 
                   <li ng-click="loadSettings('people')" ng-className="{'active':selectedSettingsView==='people'}">People</li>
                   <li ng-click="getPpl()" ng-className="{'active':selectedSettingsView==='project'}">Project</li>
                   <li ng-click="getPpl()" ng-className="{'active':selectedSettingsView==='holiday'}">Holiday</li>
               </ul>
           </div>
       </div>
        {/* <div>{isWrokspaceSettings && <div><WorkSpaceForm  getCustomerDetails={getCustomerDetails} getUserDetailsAction={getUserDetailsAction} /></div>} */}

        {/* </div> */}
        </div>
    )
}



}

export default WorkspaceSettings;
