import {commonContants} from '../../helpers/commonconstants';
import { workspaceConstant } from "../../constants/workspaceconstants";
// import { actionTypes } from 'react-redux-form';

const navBarState = localStorage.getItem('expandedNavbar') &&  localStorage.getItem('expandedNavbar') === "false" ? false : true;

const initialState = {
    passwordLoaderState:{
            fetching: false,
            fetched: false,
            error: {
                bool : false,
                value: ''
            },
            key: null
        },
    profileLoaderState:{
        fetching: false,
        fetched: false,
        error: {
            bool : false,
            value: ''
        },
        key: null
    },
    userPhotoUpdateLoaderState:{
        fetching: false,
        fetched: false,
        error: {
            bool : false,
            value: ''
        },
        key: null
    },
    customerPhotoUpdateLoaderState:{
        fetching: false,
        fetched: false,
        error: {
            bool : false,
            value: ''
        },
        key: null
    },
    tabState: {
        projectsLanding: "ACTIVE",
        dashboardLanding: "Team"
    },
    searchContent:{
        text: null,
        context: null
    },
    navBarState : {
        expanded : navBarState
    },
    timerIsRunning : false,
    timerStatus: null,
    totalTimeForTheDay : 0
}

        
export default function commonReducer(state = initialState, action) {
    //console.log(action);
    switch (action.type) {

            case commonContants.updateUserPasswordLoaderAction:
            return {
                ...state,
                passwordLoaderState: action.payload
            }

            case commonContants.updateUserProfileLoaderAction:
            return {
                ...state,
                profileLoaderState: action.payload
            }

            case commonContants.updateUserPhotoLoaderAction:
            return{
                ...state,
                userPhotoUpdateLoaderState: action.payload
            }


            case commonContants.updateCustomerPhotoLoaderAction:
            return{
                ...state,
                customerPhotoUpdateLoaderState: action.payload
            }

            case commonContants.updateTabState:
            return {
                ...state,
                tabState: {
                    ...state.tabState,
                    projectsLanding: action.payload.projectsLanding
                }
            }

            case commonContants.updateDashboardTabState:
            return {
                ...state,
                tabState: {
                    ...state.tabState,
                    dashboardLanding: action.payload.dashboardLanding
                }
            }

            case commonContants.updateSearchTextAction:
            return{
                ...state,
                searchContent: action.payload
            }

            case commonContants.handleNavBarAction:
            return {
                ...state,
                navBarState : {expanded : action.payload}
            }
            case commonContants.timerIsRunningAction:
                return {
                    ...state,
                    timerIsRunning : action.payload.timerRunningStatus
                }
            case commonContants.updateTimerData :
                return {
                    ...state,
                    totalTimeForTheDay : action.payload.totalTime 
                }
            case workspaceConstant.timerStatus:
                return {
                    ...state,
                    timerStatus: action.payload
                }

        default:    
        return{
            ...state
        }
    }

}