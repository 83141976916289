import React, { Component } from "react";
import { connect } from "react-redux";

import { Loader } from "../common/loader";
import { commonContants } from "../../helpers/commonconstants";
import { uploadPhotoToS3Bucket } from "../../actions/common/commonactions";

class LogoUploader extends Component {
  state = {
    imageLoading: false,
    clientLogo: null,
  };

  clickOnUploadFile = () => {
    this.uploadFileRef && this.uploadFileRef.click();
  };

  uploadInitiated = () => {
    this.setState(() => ({
      imageLoading: true,
    }));
    const { uploadPhotoToS3BucketAction } = this.props;
    let selectedFiles =
      this.uploadFileRef &&
      this.uploadFileRef.files &&
      this.uploadFileRef.files;
    selectedFiles &&
      selectedFiles.length &&
      uploadPhotoToS3BucketAction(
        {
          file: selectedFiles[0],
          loaderKey: commonContants.updateCustomerPhotoLoaderAction,
        },
        (response) => {
          if (response && response.success) {
            if (response.img) {
              this.setState({
                clientLogo: response.img,
                imageLoading: false,
              });
              this.props.updateLogo(response.img, selectedFiles[0].name);
            }
          }
        }
      );
  };

  render() {
    const uploadFileRef = (ref) => {
      this.uploadFileRef = ref;
    };
    const { imageLoading } = this.state;
    const { image } = this.props;
    return (
      <React.Fragment>
        <Loader
          size={100}
          loading={imageLoading}
          left={this.props.left || 18}
          top={this.props.top || 0}
        />
        {this.props.update ? (
          <React.Fragment>
            <img
              src={image}
              alt="logo"
              onClick={this.clickOnUploadFile}
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
            />
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
              ref={(ref) => uploadFileRef(ref)}
              style={{ display: "none" }}
              onChange={this.uploadInitiated}
            />
            <span onClick={this.clickOnUploadFile} className="update-logo">
              Update <br /> logo
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="add-logo" onClick={this.clickOnUploadFile}>
              Add logo
            </div>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
            ref={(ref) => uploadFileRef(ref)}
            style={{ display: "none" }}
            onChange={this.uploadInitiated}
          />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        uploadPhotoToS3Bucket(
          {
            file,
            loaderKey,
          },
          callback
        )
      );
    },
  };
}

export default connect(null, mapDispatchToProps)(LogoUploader);
