import React, { useState, useRef, memo } from "react";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import arrowDown from "../../styles/img/svg/report-arrow-down.svg";
import arrowUp from "../../styles/img/svg/arrow_up.svg";
import MembersBox from "./membersBox";
import { getAllWorkSpaceUsers } from "../../helpers/reportlanding";

const MemberDropDown = ({
  workSpaceUsers,
  isSuperAdmin,
  selectedMemberId,
  selectedMember,
  onChangeMember
}) => {
  const memberDropdownNode = useRef();
  const [showDropDown, setShowDropDown] = useState(false);

  const onShowDropDown = (e) => {
    if (isSuperAdmin) {
      e.preventDefault();
      e.stopPropagation();
      setShowDropDown(!showDropDown);
    }
  };

  const onOutsideClickDropDown = (e) => {
    if (memberDropdownNode && !memberDropdownNode.current.contains(e.target)) {
      if (showDropDown) setShowDropDown(false);
    }
  };

  const onSelectMember = (event, selectedMemberId, selectedMember) => {
    onChangeMember(selectedMemberId, selectedMember);
    setShowDropDown(false);
  };

  const sortedWorkspaceUsers = _.sortBy(
    workSpaceUsers,
    [(workSpaceUser) => workSpaceUser.first_name.toLowerCase()],
    ["asc"]
  );
  const allWorkSpaceUsers = getAllWorkSpaceUsers(sortedWorkspaceUsers);

  return (
    <div className="comm-filter-control-holder" ref={memberDropdownNode}>
      <div
        className={`project-holder ${!isSuperAdmin && "not-admin"}`}
        onClick={onShowDropDown}
      >
        <span>{selectedMember}</span>
        <img src={showDropDown ? arrowUp : arrowDown} alt="arrow" />
      </div>
      {showDropDown && isSuperAdmin && (
        <OutsideClickHandler onOutsideClick={onOutsideClickDropDown}>
          <MembersBox
            workSpaceUsers={allWorkSpaceUsers}
            onSelectMember={onSelectMember}
            selectedMemberId={selectedMemberId}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default memo(MemberDropDown);
