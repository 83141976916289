import React from 'react';
import runningImg from '../../styles/img/svg/activity-status-pattern.svg';
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { kanbanConstant } from '../../constants/kanbanconstants';
import { workloadConstant } from '../../constants/workloadConstant';
momentDurationFormatSetup(moment);

const KanbanCardStatus = (props) => {
    const activity = props.activity;
    const comingFrom = props.source;
    const statusGreen = '#70b824';
    const statusRed = '#ef5350';
    const statusYellow = '#ffa428';
    let isTimerRunning = false;
    let allottedTime = activity.duration;
    let actualTimeSpent = 0;
    let warningPercentOfAlloted = (allottedTime * 75)/100;
    _.forEach(activity.timesheets,function(timesheet){
        actualTimeSpent = actualTimeSpent + timesheet.time_taken;
        let activityTimesheetDate = moment(timesheet.date).format("DD MMM YYYY");
        let today = moment(new Date()).format("DD MMM YYYY");
        if(moment(activityTimesheetDate).isSame(moment(today))) {
            _.forEach(timesheet.data.TIME,function(time){
                if(time.START && !time.STOP) {
                    isTimerRunning = true;
                }
            });
        }
    });
    let statusStyle = {};
    if(allottedTime > actualTimeSpent) {
        statusStyle.backgroundColor = statusGreen;
        if(isTimerRunning) {
            statusStyle.backgroundImage = 'url('+runningImg+')';
        }
        if(actualTimeSpent >= warningPercentOfAlloted) {
            statusStyle.backgroundColor = statusYellow;
        }
    }
    else if(actualTimeSpent > allottedTime) {
        statusStyle.backgroundColor = statusRed;
        if(isTimerRunning) {
            statusStyle.backgroundImage = 'url('+runningImg+')';
        }
    }
    const className = (comingFrom === kanbanConstant.fromKanban && "project-wf-status-holder") || (comingFrom === workloadConstant.fromWorkload && "project-wl-status-holder")
    let styleObj = {};
    if(comingFrom === kanbanConstant.fromKanban){
        styleObj= {
            opacity: 0,
            display:'inline-block',
            width:'100%'
        }
    }else{
        styleObj={
            display:'none'
        }
    }
    return (
        <div className={className} style={statusStyle}>
            <span style={styleObj}>Drag your activity here</span>

        </div>
    )
};

export default KanbanCardStatus;