/**
 *
 */
import React, { Component } from "react";
import "../../styles/scss/user_profile.scss";
import { TextField } from "@material-ui/core";
import { actions as rrfActions } from "react-redux-form";
import { connect } from "react-redux";
import { message } from "../../constants/messages";

import { updateLoaderState } from "../../actions/common/commonactions";
import { commonContants } from "../../helpers/commonconstants";
import { Loader } from "../common/loader";

const mapStateToProps = (state) => {
  return {
    changePasswordDetails: state.rrfStatereducer.changePassword,
    passwordLoaderState: state.commonReducer.passwordLoaderState
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    updateFormValues: (argName, argValue) => {
      dispatch(
        rrfActions.change("rrfState.changePassword" + argName, argValue)
      );
    },
    updateLoaderState: ({ action, state }) =>
      dispatch(updateLoaderState({ action, state }))
  };
};

class ChangePasswordPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: null,
      new_password: null,
      confirm_password: null
    };
  }

  componentWillUnmount() {
    const { updateFormValues, updateLoaderState } = this.props;
    let changePassword = {
      old_password: {
        isValid: false,
        value: "",
        errMsg: null
      },
      new_password: {
        isValid: false,
        value: "",
        errMsg: null
      },
      confirm_password: {
        isValid: false,
        value: "",
        errMsg: null
      }
    };
    updateFormValues("", changePassword);
    updateLoaderState({
      action: commonContants.updateUserPasswordLoaderAction,
      state: {
        fetching: false,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      old_password: { isValid: isOldPassValid },
      new_password: { isValid: isNewPassValid },
      confirm_password: { isValid: isConfirmPassValid }
    } = nextProps.changePasswordDetails;

    

    if (isOldPassValid && isNewPassValid && isConfirmPassValid) {
      this.updateValidFormState(true);
    } else {
      this.updateValidFormState(false);
    }
  }

  updateValidFormState = (stateValue) => {
    const { validFormCallBack } = this.props;
    validFormCallBack && validFormCallBack(stateValue);
  };

  isValidForm = (_elementKey, element) => {
    
    if (element.length > 0) return true;
    return false;
  };

  onFormUpdate = (event) => {
    const { updateFormValues } = this.props;
    let key = event.target && event.target.name;
    let value = event.target && event.target.value;
    key &&
      updateFormValues("." + key, {
        isValid: this.isValidForm(key, value),
        value
      });

    ////console.log("old_pass", event.target.value, 'target', event.target.name);
  };

  render() {
    
    const {
      passwordLoaderState,
      changePasswordDetails,
      // isValidChangePasswordForm,
      onConfirmCallBack
    } = this.props;
    return (
      <div className="pop-up-password">
        <div className="nw-att-loading-holder">
          {/* <ClipLoader sizeUnit={"px"} size={100} color={'#0190e9'} loading={passwordLoaderState.fetching} /> */}
          <Loader size={100} loading={passwordLoaderState.fetching} />
        </div>
        <div className="change-password-pop">
          <div className="password-main">
            <div className="password-inner">
              <TextField
                id="standard-password-input1"
                label="Old Password"
                name="old_password"
                className="inputStyles"
                type="password"
                margin="normal"
                ref={(ref) => (this.oldPassRef = ref)}
                onChange={this.onFormUpdate}
              />
              {/* $$ Shows up if the old password entered is wrong */}
              {passwordLoaderState.error.bool &&
                passwordLoaderState.error.value && (
                  <p className="validaton-fail old-password-err">
                    {message.incorrectPassword}
                  </p>
                )}
              {changePasswordDetails.old_password.errMsg && (
                <p className="validaton-fail old-password-err">
                  {changePasswordDetails.old_password.errMsg}
                </p>
              )}
            </div>
            <div className="password-inner">
              <TextField
                id="standard-password-input2"
                label="New Password"
                className="inputStyles"
                name="new_password"
                type="password"
                margin="normal"
                ref={(ref) => (this.newPassRef = ref)}
                onChange={this.onFormUpdate}
              />
              {changePasswordDetails.new_password.errMsg && (
                <p className="validaton-fail new-password-err">
                  {changePasswordDetails.new_password.errMsg}
                </p>
              )}
            </div>
            <div className="password-inner">
              <TextField
                id="standard-password-input3"
                label="Confirm Password"
                name="confirm_password"
                className="inputStyles"
                type="password"
                margin="normal"
                ref={(ref) => (this.confirmPassRef = ref)}
                onChange={this.onFormUpdate}
              />
              {/* $$ Shows up if the passwords do not match */}
              {changePasswordDetails.confirm_password.errMsg && (
                <p className="validaton-fail password-err">
                  {changePasswordDetails.confirm_password.errMsg}
                </p>
              )}
            </div>
          </div>
          <div className="act-submit-btn-holder change-password-btn">
            <button
              className="act-submit-btn"
              // disabled={!isValidChangePasswordForm}
              onClick={onConfirmCallBack}
            >
              {/* Enable btn styles add "active" class */}
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchProps)(ChangePasswordPopUp);
