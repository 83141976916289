import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollBar from "react-scrollbars-custom";
import {
  unarchiveActivitiesAction,
  updateArchiveActivitiesWorkFlowAction
} from "../../actions/activities/activitiesaction";
import { unArchivedArchivedProject } from "../../actions/project/projectaction";
import { unarchiveChecklistAction } from "../../actions/checklist/checklist";
import { unarchiveCommentAction, permanentDeleteCommentAction } from "../../actions/comments/commentsaction";
import DialogWrapper from "../common/dialog";
import ActivityPopupTop from "../activity/activityPopupTop";
import ActivityPopupBottom from "../activity/activityPopupBottom";
import ActivityForm from "../activity/addactivity";
import ActivityTile from "./activityTile";
import ChecklistTile from "./checklisTile";
import CommentTile from "./commentTile";
import ArchivedProjectCard from "./archivedProjectCard";
import "../../styles/scss/archiveCenter.scss";
import routesHelper from "../../router/routeshelper";
import _ from "lodash";

import { getIsSuperAdmin } from "../../helpers/reportlanding";
import firebase from "../../firebase";

class ArchivedLanding extends Component {
  state = {
    isPopupEnable: false,
    popupActivityDetails: null,
    popupActivityDetailsEdit: null,
    isPopupEnableEdit: false,
    fromActivityInnerAdd: false
  };

  setIsPopupEnable = (activity) => {
    firebase.analytics().logEvent("GTD_Archive_Item_Clicked");
    this.setState({
      isPopupEnable: true,
      popupActivityDetails: activity,
      popupActivityDetailsEdit: activity
    });
  };

  setIsPopupEnableEdit = (fromActivityInnerAdd) => {
    this.setState({
      isPopupEnableEdit: true,
      fromActivityInnerAdd: fromActivityInnerAdd ? fromActivityInnerAdd : false
    });
  };

  onPopupCloseCallBack = () => {
    this.setState(() => ({
      isPopupEnable: false,
      popupActivityDetails: {}
    }));
  };

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Archive_Visit");
    const checklistArchivedParam = `is_archived=true`;
    this.props.getArchivedChecklistAction(
      { queryParams: checklistArchivedParam },
      (response) => {
        if(!response.stdErr && response?.length >= 0) {
          if(this.props.router.location.action === "PUSH") {
            let queryParam = getIsSuperAdmin(this.props.loggedInUser, this.props.selectedCustomerDetails)
              ? `?archived=true`
              : `?user_id=${parseInt(this.props.loggedInUser.id)}&archived=true`;
            this.props.getArchivedProjectsAction(
              { auth_token: this.props.selectedCustomerDetails.auth_token, queryParam });
            this.props.getSelectedWorkpsaceArchivedActivities(
              {
                auth_token: this.props.selectedCustomerDetails.auth_token,
                user_id: parseInt(this.props.loggedInUser.id),
                activity_type: "ACTIVITY",
                is_archived: true,
                is_active: true,
                page: 1,
                item_per_page: 20
              },
              _response => {
                // write what to do on success
              }
            );
            this.props.getArchivedCommentsAction({ user_id: parseInt(this.props.loggedInUser.id), page: 1, perPage: 10 });
          }
        }
      }
    );
  }

  onUnarchiveActivity = (activity) => {
    const { loggedInUser, unarchiveActivities } = this.props;
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    
    const archiveData = {
      id: activity.id,
      data: {
        ...activity.data,
        lastModifiedBy: parseInt(loggedInUser.id),
        archivedUsers: activity.data.archivedUsers.filter(
          (user) => user !== parseInt(loggedInUser.id)
        ),
        priority: activity.data.priority
          ? activity.data.priority.filter(
              (user) => user.user_id !== parseInt(loggedInUser.id)
            )
          : []
      },
      version_id: activity.version_id,
      is_active: true
    };
    
    unarchiveActivities(
      {
        auth_token: customer_auth_token,
        archiveData,
        user_id: loggedInUser.id
      },
      (_response) => {
        this.onPopupCloseCallBack();
      }
    );
  };

  render() {
    const {
      activityLoaderState,
      projectListLoaderState,
      getChecklistLoaderState,
      getCommentsLoaderState,
      archievedActivities,
      updateArchiveActivitiesWorkFlow,
      unarchiveActivities,
      loggedInUser,
      listOfProjects,
      unArchivedArchivedProjectAction,
      archivedChecklists,
      archivedComments,
      unarchiveChecklist,
      unarchiveComment,
      permanentDeleteComment,
      router
    } = this.props;
    const {
      isPopupEnable,
      popupActivityDetails,
      popupActivityDetailsEdit
    } = this.state;

    const sortedArchivedComments = _.orderBy(archivedComments, [(archivedComment) => {
      let updatedOn = new Date(archivedComment.updated_on);
      return updatedOn.getTime();
    }], "desc");

    const archivedChecklistsByUser = _.orderBy(
      archivedChecklists,
      ["updated_at"],
      ["desc"]
    ).filter(
      (checklist) =>
        checklist.activity &&
        checklist.activity.user_id &&
        checklist.activity.user_id.includes(parseInt(loggedInUser.id))
    );

    const uniqArchivedActivities = _.uniqBy(archievedActivities, "id");

    const activityRender = _.orderBy(
      uniqArchivedActivities,
      [
        (archivedActivity) => {
          let completedOn = new Date(archivedActivity.completed_on);
          return completedOn.getTime();
        }
      ],
      ["desc"]
    )
      // .reverse()
      .map((activity, index) => {
        if (index < 3) {
          if (!activity.workflow) {
            activity = { ...activity, workflow: { id: 1, name: "Backlog" } };
          }
          return (
            <ActivityTile
              key={activity.id}
              activity={activity}
              loggedInUser={loggedInUser}
              setIsPopupEnable={this.setIsPopupEnable}
              updateArchiveActivitiesWorkFlowAction={
                updateArchiveActivitiesWorkFlow
              }
              unarchiveActivitiesAction={unarchiveActivities}
            ></ActivityTile>
          );
        }
        return null;
      });

    const projectRender = listOfProjects
      .sort(
        (d1, d2) =>
          new Date(d2.updated_at).getTime() - new Date(d1.updated_at).getTime()
      )
      .map((project, index) => {
        if (index < 3) {
          return (
            <ArchivedProjectCard
              key={project.id}
              project={project}
              unArchivedArchivedProject={unArchivedArchivedProjectAction}
            ></ArchivedProjectCard>
          );
        }
        return null;
      });

    const checklistRendered = archivedChecklistsByUser.map(
      (checklist, index) => {
        if (index < 3) {
          return (
            <ChecklistTile
              key={checklist.id}
              checklist={checklist}
              unarchiveChecklistAction={unarchiveChecklist}
            ></ChecklistTile>
          );
        }
        return null;
      }
    );

    const commentRendered = sortedArchivedComments.map(
      (comment, index) => {
        if (index < 3) {
          return (
            <CommentTile
              key={comment.id}
              comment={comment}
              unarchiveCommentAction={unarchiveComment}
              permanentlyDeleteCommentAction={permanentDeleteComment}
            ></CommentTile>
          );
        }
        return null;
      }
    );

    const dialogProps = {
      className: "activity-popup-container activity-popup-container-unarchived",
      showPopup: this.state.isPopupEnable,
      dialogTitle: () => { /* TODO document why this method 'dialogTitle' is empty */ },
      onCloseCallBack: this.onPopupCloseCallBack,
      shouldButtonEnable: true,
      shouldScrollUpdate:true
    };
    let dialogPropsEdit = {
      className: "create_activity",
      showPopup: this.state.isPopupEnableEdit,
      dialogTitle: () => "Edit Actvity",
      onCloseCallBack: () =>
        this.setState({
          isPopupEnableEdit: false,
          fromActivityInnerAdd: false,
          popupActivityDetailsEdit: null
        }),
      onConfirmCallBack: () => { /* TODO document why this method 'onConfirmCallBack' is empty */ },
      shouldButtonEnable: true
    };

    return (
      <div className="arch-center-tt-container">
        <ScrollBar>
          {isPopupEnable && (
            <DialogWrapper dialogProps={dialogProps}>
              <ActivityPopupTop
                activityDetails={
                  archievedActivities
                    .filter(
                      (activity) => activity.id === popupActivityDetails.id
                    )
                    .map((activity) => {
                      if (!activity.workflow) {
                        activity = {
                          ...activity,
                          workflow: { id: 1, name: "Backlog" }
                        };
                        return activity;
                      }
                      return activity;
                    })[0]
                }
                setIsPopupEnableEdit={this.setIsPopupEnableEdit}
                onClosePopup={dialogProps.onCloseCallBack}
                onArchiveItem={() =>
                  this.onUnarchiveActivity(this.state.popupActivityDetails)
                }
                source="FROM_ARCHIVE"
                //   is_priority={this.state.is_priority}
                changeWorkFlowAction={updateArchiveActivitiesWorkFlow}
                //   archiveOrUnArchiveActivitiesAction={
                //   unarchiveActivities
                //   }
              />
              <ActivityPopupBottom
                activityDetailsAsProps={this.state.popupActivityDetails}
                is_archived={true}
              />
            </DialogWrapper>
          )}
          {archievedActivities.length > 0 && (
            <div className="arch-categ-outer-container">
              <h3 className="arch-categ-heading">Activities</h3>
              <div className="arch-categ-container">
                <div className="arch-categ-holder">{activityRender}</div>
                {archievedActivities.length > 3 && (
                  <div
                    className="arch-view-more-holder nav-expanded"
                    onClick={() => {
                      firebase.analytics().logEvent("GTD_Archive_View_All");
                      router.push(routesHelper.getAllActivityArchivedPath());
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    View all
                  </div>
                )}
              </div>
            </div>
          )}

          {listOfProjects.length > 0 && (
            <div className="arch-categ-outer-container">
              <h3 className="arch-categ-heading">Projects</h3>
              <div className="arch-categ-container">
                <div className="arch-categ-holder">{projectRender}</div>
                {listOfProjects.length > 3 && (
                  <div
                    className="arch-view-more-holder projects-viewall nav-expanded"
                    onClick={() => {
                      firebase.analytics().logEvent("GTD_Archive_View_All");
                      router.push(routesHelper.getAllProjectsArchivedPath());
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    View all
                  </div>
                )}
              </div>
            </div>
          )}
          {sortedArchivedComments.length > 0 && (
            <div className="arch-categ-outer-container">
              <h3 className="arch-categ-heading">Comments</h3>
              <div className="arch-categ-container">
                <div className="arch-categ-holder">{commentRendered}</div>
                {sortedArchivedComments.length > 3 && (
                  <div
                    className="arch-view-more-holder checklist-viewall nav-expanded"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      firebase.analytics().logEvent("GTD_Archive_View_All");
                      router.push(routesHelper.getAllCommentsArchivedPath());
                    }}
                  >
                    View all
                  </div>
                )}
              </div>
            </div>
          )}
          {archivedChecklistsByUser.length > 0 && (
            <div className="arch-categ-outer-container">
              <h3 className="arch-categ-heading">Checklists</h3>
              <div className="arch-categ-container">
                <div className="arch-categ-holder">{checklistRendered}</div>
                {archivedChecklistsByUser.length > 3 && (
                  <div
                    className="arch-view-more-holder checklist-viewall nav-expanded"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      firebase.analytics().logEvent("GTD_Archive_View_All");
                      router.push(routesHelper.getAllChecklistArchivedPath());
                    }}
                  >
                    View all
                  </div>
                )}
              </div>
            </div>
          )}
          {archievedActivities.length === 0 &&
            activityLoaderState.fetched &&
            listOfProjects.length === 0 &&
            projectListLoaderState.fetched &&
            archivedChecklistsByUser.length === 0 &&
            getChecklistLoaderState.fetched &&
            archivedComments.length === 0 &&
            getCommentsLoaderState.fetched && (
              <div className="not-found-message">No archived items found</div>
            )}
          {this.state.isPopupEnableEdit && (
            <DialogWrapper dialogProps={dialogPropsEdit}>
              <ActivityForm
                logedinUserDetails={loggedInUser}
                fromActivityInnerAdd={this.state.fromActivityInnerAdd}
                selectedActivity={
                  archievedActivities.filter(
                    (activity) => activity.id === popupActivityDetailsEdit.id
                  )[0]
                }
                source="FROM_ARCHIVE"
                {...dialogPropsEdit}
              />
            </DialogWrapper>
          )}
        </ScrollBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loginReducer.loginAuth.user_details,
    activityLoaderState: state.activityReducer.activityLoaderState,
    projectListLoaderState: state.projectReducer.projectListLoaderState,
    getChecklistLoaderState: state.checklistReducer.getChecklistLoaderState,
    archievedActivities: state.activityReducer.listOfArchivedActivity.list,
    listOfProjects: state.projectReducer.listOfArchivedProjects.list,
    archivedChecklists: state.checklistReducer.archivedChecklists.list,
    getCommentsLoaderState: state.commentsReducer.getCommentsLoaderState,
    archivedComments: state.commentsReducer.archivedComments,
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unarchiveActivities: (
      { auth_token, archiveData, user_id },
      callback
    ) => {
      dispatch(
        unarchiveActivitiesAction(
          { auth_token, archiveData, user_id },
          callback
        )
      );
    },
    updateArchiveActivitiesWorkFlow: (
      { id, workflow, customer_auth_token },
      callback
    ) => {
      dispatch(
        updateArchiveActivitiesWorkFlowAction(
          { id, workflow, customer_auth_token },
          callback
        )
      );
    },
    unArchivedArchivedProjectAction: ({ body }, callback) => {
      dispatch(unArchivedArchivedProject({ body }, callback));
    },
    unarchiveChecklist: (
      { activity_id, title, created_by, position, id },
      callback
    ) => {
      dispatch(
        unarchiveChecklistAction(
          { activity_id, title, created_by, position, id },
          callback
        )
      );
    },
    unarchiveComment: ({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, callback) => {
      dispatch(unarchiveCommentAction({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, callback));
    },
    permanentDeleteComment: ({ id }, callback) => {
      dispatch(permanentDeleteCommentAction({ id }, callback));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedLanding);
