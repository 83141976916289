import React from "react";
import "../../styles/scss/user_profile.scss";

const AttachmentErrPop = ({
  fromEditReport,
  onClosePopup
}) => {
  return (
    <div className="pop-up-password google">
      <div className="message">
        <span>
        Something went wrong while saving the attachment. Please {fromEditReport ? "" : " go to Report and"} reupload the attachment for this {fromEditReport ? "report" : "activity"}.
        </span>
      </div>
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default AttachmentErrPop;
