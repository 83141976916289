import React,{useRef,useCallback,memo} from 'react';
import CommentItem from "./commentItem";

 function   CommentSection (props) {
  let commentsTotalLength=props.comments.length;
    const observer=useRef();
    const lastBookElementRef=useCallback(node =>{
      if(!props.hasMore) return;
      if(observer.current) observer.current.disconnect();
      observer.current=new IntersectionObserver(entries=>{
           if(entries[0].isIntersecting  && props.hasMore ){
             props.getComments();
             observer.current.disconnect();
            
           }
      })
      if(node)observer.current.observe(node);
   },[props.hasMore]);

   

   let commentList=null;
   if(commentsTotalLength>0){
    commentList= props.comments.map((comment) => {          
      return  <CommentItem
      key={comment.id}
      comments={props.comments}
      commentDetails={comment}
      isSuperAdmin={props.isSuperAdmin}
      userDetails={props.userDetails}
      changeOpenedEditBox={props.changeOpenedEditBox}
      openedEditBox={props.openedEditBox}
      closeOpenedEditBox={props.closeOpenedEditBox}
      changeOpenedReplyBox={props.changeOpenedReplyBox}
      openedReplyBox={props.openedReplyBox}
      closeOpenedReplyBox={props.closeOpenedReplyBox}
      loggedinUser={props.loggedinUser}
      addCommentAction={props.addCommentAction}
      activityDetails={props.activityDetails}
      fromNotification={props.fromNotification}
      idOfElement={props.idOfElement}
      clickedCommentId={props.clickedCommentId}
      is_priority={props.is_priority}
      updateFetch={props.updateFetch}
      is_archived={props.is_archived}
    />
  
})
commentList.push(<div ref={lastBookElementRef}  key={new Date()}></div>);     
   }
  
   return (
       <>
           {
             commentList
           }

       </>
   );
  
}

export default memo(CommentSection);
