/**
 * Author: Madhusudhan Seetharamiah
 */

import React, { PureComponent } from "react";
import { getProfileDetails } from "../../helpers/generichelper";
import routesHelper from "../../router/routeshelper";
import { Tooltip } from "@material-ui/core";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import Avatar from "react-avatar";
import firebase from "../../firebase";
import { userLogout } from "../../services/logoutapi";
import {
  deleteFirebaseUserToken,
  registerFirebaseUserToken,
} from "../../services/notificationapis";
import { getFcmToken, setFcmToken } from "../../helpers/notificationHelper";

class UserActionDropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initiateDropDown: false,
    };
  }

  onAppLogout = () => {
    const { router } = this.props;
    if (getFcmToken()) {
      deleteFirebaseUserToken(
        { fcm_token: getFcmToken(), device_type: "web" },
        (response) => {
          firebase.messaging().deleteToken(getFcmToken());
          userLogout((res) => {
            if (res) {
              localStorage.clear();
              router.push(routesHelper.getLoginPath());
              window.location.reload();
            }
          });
        }
      );
    } else {
      userLogout((res) => {
        if (res) {
          localStorage.clear();
          router.push(routesHelper.getLoginPath());
          window.location.reload();
        }
      });
    }
  };

  onInitiateDropDown = () => {
    this.setState(
      {
        initiateDropDown: !this.state.initiateDropDown,
      },
      () => {
        firebase.analytics().logEvent("GTD_Account_Icon");
        
      }
    );
  };

  onProfileClicked = () => {
    firebase.analytics().logEvent("GTD_Account_Profile");
    const { router } = this.props;
    router.push({
      pathname: routesHelper.getUserProfilePath(),
      state: {
        prevPath: router.location.pathname,
      },
    });
    
    this.setState({
      initiateDropDown: !this.state.initiateDropDown,
    });
  };

  onRequstNotification = async () => {
    firebase.analytics().logEvent("GTD_Account_Desktop_Notifications");
    // console.log('dfsdfsdf');
    try {
      const permission = await Notification.requestPermission();
      console.log("Notification Permission", permission);
      if (permission === "granted") {
        if (!getFcmToken()) {
          // const messaging = getMessaging();

          const messaging = firebase.messaging();

          // await messaging.requestPermission();
          const token = await messaging.getToken({
            vapidkey: process.env.REACT_APP_FIREBASE_MESSAGING_KEY_PAIR,
          });
          registerFirebaseUserToken(
            { fcm_token: token, device_type: "web" },
            (response) => {
              setFcmToken(token);
            }
          );
          // console.log('user token: ', token);
          return token;
        }
      }
    } catch (error) {
      console.error(error);
    }
    // Notification.requestPermission().then(permission=>console.log('test',permission));
  };

  outsidecloseBox = (e) => {
    let parent = getClosest(e.target, "#user-profile");
    if (!_.isNull(parent)) {
      e.stopPropagation();
      return;
    }
    this.setState(
      {
        initiateDropDown: false,
      },
      () => { /* TODO document why this arrow function is empty */ }
    );
  };
  render() {
    const notificationGranted =
      Notification.permission && Notification.permission === "granted";
    const { selectedCustomerDetails, currentUser } = this.props;
    return (
      <div className="gtd-profile">
        <OutsideClickHandler
          onOutsideClick={(e) => {
            this.outsidecloseBox(e);
          }}
        >
          {getProfileDetails().img === null && (
            <Tooltip title={getProfileDetails().first_name}>
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={getProfileDetails().first_name}
                maxInitials={1}
                onClick={this.onInitiateDropDown}
                className={`user-profile ${
                  this.state.initiateDropDown ? "icon-opacity" : ""
                }`}
              ></Avatar>
            </Tooltip>
          )}

          {getProfileDetails().img !== null && (
            <Tooltip title={getProfileDetails().first_name}>
              <img
                className={`user-profile ${
                  this.state.initiateDropDown ? "icon-opacity" : ""
                }`}
                src={currentUser && currentUser.img}
                onClick={this.onInitiateDropDown}
                alt="icn"
              />
            </Tooltip>
          )}

          {this.state.initiateDropDown && (
            <div
              className="profile-container"
              ref={(ref) => {
                this.profileRef = ref;
              }}
              id="profilecontain"
              onBlur={this.onDiscardDropDown}
            >
              <div className="profile-header">
                <div className="img-container">
                  {getProfileDetails().img === null && (
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      name={getProfileDetails().first_name}
                      maxInitials={1}
                    ></Avatar>
                  )}
                  {getProfileDetails().img !== null && (
                    <img
                      src={getProfileDetails().img}
                      alt="profile-details-img"
                    />
                  )}
                </div>
                <div className="username_email_block">
                  <span
                    className="user-name"
                    title={getProfileDetails().username}
                  >
                    {getProfileDetails().first_name}
                    {/* <span className="user-Name">
                    ({getProfileDetails().username})
                  </span>{" "} */}
                  </span>
                  <p
                    className="user-ddown-email"
                    title={getProfileDetails().email}
                  >
                    {selectedCustomerDetails &&
                    parseInt(selectedCustomerDetails.super_admin_id) ===
                      parseInt(localStorage.getItem("id"))
                      ? "Creator"
                      : selectedCustomerDetails &&
                        selectedCustomerDetails.users.hasOwnProperty(
                          localStorage.getItem("id")
                        ) &&
                        selectedCustomerDetails.users[
                          localStorage.getItem("id")
                        ] === "SUPERADMIN"
                      ? "Owner"
                      : "Member"}{" "}
                    -{" "}
                    {selectedCustomerDetails &&
                      selectedCustomerDetails.display_name}
                  </p>
                </div>
              </div>
              <div className="profile-body">
                <p>
                  <a className="profile_tiem" onClick={this.onProfileClicked}>
                    Profile
                  </a>
                </p>
                <p>
                  <a
                    className="profile_tiem"
                    onClick={(event) => {
                      firebase.analytics().logEvent("GTD_Account_Tutorial");
                      this.props.onTutorialClicked();
                      this.outsidecloseBox(event);
                    }}
                  >
                    Tutorial
                  </a>
                </p>
                {!notificationGranted && (
                  <p>
                    <a
                      className="profile_tiem"
                      onClick={this.onRequstNotification}
                    >
                      Allow desktop notifications
                    </a>
                  </p>
                )}
                {/* <p>
                  <a
                    className="profile_tiem"
                    href={routesHelper.getWebTourPath()}
                    target="_blank"
                  >
                    App tour
                  </a>
                </p> */}
                <p>
                  <a
                    className="profile_tiem"
                    href={routesHelper.getFeedbackPath()}
                    target="_blank"
                    onClick={() =>
                      firebase.analytics().logEvent("GTD_Account_Feedback")
                    }
                  >
                    Feedback
                  </a>
                </p>
                <p>
                  <a
                    className="profile_tiem"
                    // href=""
                    onClick={() => {
                      firebase.analytics().logEvent("GTD_Account_Logout");
                      this.onAppLogout();
                    }}
                  >
                    Logout
                  </a>
                </p>
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    );
  }
}
const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { /* TODO document why this block is empty */ }
        return i > -1;
      };
  }
  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export default UserActionDropDown;
