import React, { PureComponent } from "react";

import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from "../../constants/globalconst";
import AppleSignin from "../../styles/img/svg/apple-black.svg";
import firebase from "../../firebase";

class AppleSignOn extends PureComponent {
  onSignInWithAppleClick = () => {
    firebase.analytics().logEvent("GTD_Apple_Login");
    window.AppleID.auth.init({
      clientId : APPLE_CLIENT_ID,
      scope : 'name email',
      redirectURI : `${APPLE_REDIRECT_URI}`,
      // state : '[STATE]',
      // nonce : '[NONCE]',
      usePopup : false //or false defaults to false
  });
  window.AppleID.auth.signIn();
  //Listen for authorization success
document.addEventListener('AppleIDSignInOnSuccess', (_data) => {
  //handle successful response
  // console.log("apple", data)
  // var ca = "eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLnBlcHBlcnNxdWFyZS5HZXRUaGluZ3NEb25lIiwiZXhwIjoxNTk1NTgwNDIxLCJpYXQiOjE1OTU1Nzk4MjEsInN1YiI6IjAwMDczNC5iYWQ2YzRiZWU3NGQ0ZjZkYmJjMDhlMDEyNDM0MzZhYi4wODM3IiwiY19oYXNoIjoiVEdnaEtSRm5pSEVXbGFHbTl2MTJaUSIsImVtYWlsIjoic3lzYWRtaW5AcGVwcGVyc3F1YXJlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImF1dGhfdGltZSI6MTU5NTU3OTgyMSwibm9uY2Vfc3VwcG9ydGVkIjp0cnVlfQ.cMyxuZGwykUwF3_B5tY1p_7CYxZ5Ty3EV62XT7OpLNpJIk5srIaN2cqPS6WMqvsLRkdDtdWSoRpAaszZuLCj2NGo8HC_W9Gdo0hg7cpwsu2L9VOrd-2j_2IApoT9WQlp3UbE4funVN6Ohrz2zLF3I-opHtJsInYnndVwVGh3bmBspO5SB06Jv-lkgqhAIGYZuWCZ0Tl1vpzOlcWKR1MtxQja9l3hMHicXf4s5aU3BvTAL3xbhuSMg1Rx1RzAojNpeWm2WHd5UyJzk-MeqebrtXh4yBhUpu3vwwqZzvbaSzgRCtfqO5en0KTxMonY1CO7gGQbGGdYTqJcMpPEEk1F0Q" || data.detail.authorization.id_token;
  // var base64Url = ca.split('.')[1];
  // var decodedValue = JSON.parse(window.atob(base64Url));
  // console.log("decoded", decodedValue)
  
});
//Listen for authorization failures
document.addEventListener('AppleIDSignInOnFailure', (_error) => {
  //handle error.
});
  }
  render() {
    const { fromFp } = this.props;
    return (
      <div
            className={`goog appl ${fromFp ? "fp-goog" : ""}`}
            onClick={this.onSignInWithAppleClick}
          >
            <img src={AppleSignin} alt="Apple Signin" />
            <p className="or_sign">Login with Apple </p>
          </div>
      /**$$ update key for dev when using */
      // <AppleLogin
      //   clientId={APPLE_CLIENT_ID}
      //   redirectURI={`${APPLE_REDIRECT_URI}`}
      //   render={renderProps => (
      //     <div
      //       className={`goog appl ${fromFp ? "fp-goog" : ""}`}
      //       onClick={renderProps.onClick}
      //     >
      //       <img src={AppleSignin} alt="Apple Signin" />
      //       <p className="or_sign">Login with Apple </p>
      //     </div>
      //   )}
      //   scope="name email"
      //   callback={onAppleSignInSuccess}
      // />
    );
  }
}

export default AppleSignOn;
