import React, { useState } from "react";
import { CardElement } from "react-stripe-elements";
import { Scrollbar } from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";

import countries from "../../constants/countryconstant";

export default function CheckoutForm({ handleSubmit, transactionDeclined }) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [showCountryDropDown, setCountryDropDown] = useState(false);
  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    if (name.length === 0) {
      setErrors({ name: "Name not entered" });
    } else if (address.length === 0) {
      setErrors({ address: "Address not entered" });
    } else if (city.length === 0) {
      setErrors({ city: "City not entered" });
    } else if (state.length === 0) {
      setErrors({ state: "State not entered" });
    } else if (zip.length === 0) {
      setErrors({ zip: "zip not entered" });
    } else if (country.length === 0) {
      setErrors({ country: "country not entered" });
    } else {
      handleSubmit({ name, address, city, state, zip, country });
    }
  };

  const handleCardElementChange = (_change) => {
    // TODO document why this arrow function is empty
  };

  const renderCountries = countries.map((country) => {
    return (
      <div
        key={country}
        className="billing-select-ddown-list"
        onClick={() => {
          setCountryDropDown(false);
          setCountry(country);
        }}
      >
        {country}
      </div>
    );
  });

  return (
    <div className="billing-form-holder">
      <div className="billing-form-title">Billing Details</div>
      <div className="billing-form-inner-holder">
        {transactionDeclined === true && (
          <div className="bill-payment-failure-msg">
            Oh dear! The transaction was declined. Please use a different card
            or contact your bank.
          </div>
        )}

        <div className="billing-form-row pm">
          <input
            type="text"
            value={name}
            className="billing-pm-control"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <div className="billing-err-holder">
            {errors.name && name.length === 0 && <span>Name not entered</span>}
          </div>
        </div>
        <div className="billing-form-row pm">
          <div id="card-element" className="billing-stripe-holder">
            {
              <CardElement
                // handleCardElementChange={handleCardElementChange}
                onChange={handleCardElementChange}
              ></CardElement>
            }
          </div>

          <div className="billing-err-holder">
            <span id="card-errors" role="alert">
              {" "}
              {}
            </span>
          </div>
        </div>
        <div className="billing-form-row pm pm-details">
          <div className="billing-small-pm-control-holder">
            <input
              type="text"
              value={address}
              className="billing-pm-control"
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />{" "}
            <div className="billing-err-holder">
              {errors.address && address.length === 0 && (
                <span>Address not entered</span>
              )}
            </div>
          </div>
          <div className="billing-small-pm-control-holder">
            <input
              type="text"
              value={city}
              className="billing-pm-control"
              placeholder="City"
              onChange={(e) => setCity(e.target.value)}
            />
            <div className="billing-err-holder">
              {errors.city && city.length === 0 && <span>City not entered</span>}
            </div>
          </div>
        </div>
        <div className="billing-form-row pm pm-details">
          <div className="billing-small-pm-control-holder">
            <input
              type="text"
              value={state}
              className="billing-pm-control"
              placeholder="State/Province"
              onChange={(e) => setState(e.target.value)}
            />
            <div className="billing-err-holder">
              {errors.state && state.length === 0 && (
                <span>State/Province not entered</span>
              )}
            </div>
          </div>
          <div className="billing-small-pm-control-holder">
            <input
              type="text"
              value={zip}
              className="billing-pm-control"
              placeholder="Zip/Postcode"
              onChange={(e) => setZip(e.target.value)}
            />
            <div className="billing-err-holder">
              {errors.zip && zip.length === 0 && (
                <span>Zip/Postcode not entered</span>
              )}
            </div>
          </div>
        </div>
        <div className="billing-form-row pm">
          <OutsideClickHandler onOutsideClick={() => setCountryDropDown(false)}>
            <div
              className="billing-select-control-holder"
              onClick={() => setCountryDropDown(!showCountryDropDown)}
            >
              <div
                className={
                  country.length === 0
                    ? "billing-selected-country placeholder"
                    : "billing-selected-country"
                }
              >
                {" "}
                {country.length === 0 ? "Country" : country}{" "}
              </div>
              {showCountryDropDown === true && (
                <div class="billing-select-ddown-holder">
                  <Scrollbar>{renderCountries}</Scrollbar>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <div className="billing-err-holder">
            {errors.country && country.length === 0 && (
              <span>Select your country</span>
            )}
          </div>
        </div>

        <div className="billing-form-row pm">
          <div className="bill-pm-shortnote">
            You will be charged immediately for your Monthly subscription. Your
            subscripition will be renewed automatically on the first day of each
            billing period using the payment method you have specified above.
          </div>
        </div>

        <div className="billing-form-row control flex-row-center-center">
          {transactionDeclined === false && (
            <button className="billing-btn confirm" onClick={onSubmit}>
              Confirm
            </button>
          )}

          {transactionDeclined === true && (
            <button className="billing-btn retry" onClick={onSubmit}>
              Retry
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
