import React, { useState, useEffect } from "react";
import moment from "moment";
import { Rnd } from "react-rnd";
import starActive from "../../styles/img/svg/star_active.svg";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",

  background: "#ffffff",
  padding: "0 15px",
  borderRadius: "2px"
};

export default function ActivityBlockMonth({
  activity,
  position,
  length,
  heightOfGrid,
  index,
  noOfDays,

  heightIndex
}) {
  // const delayedActivity =
  //   activity.timesheets &&
  //   activity.timesheets.reduce((acc, el) => {
  //     return (acc = acc + el.timetaken);
  //   }, 0) > activity.duration
  //     ? true
  //     : false;

  const positionInX = length * (position.startIndex % 7);
  const userId = parseInt(localStorage.getItem("id"));

  const isPriority =
    activity.data.priority &&
    activity.data.priority.filter(user => user.user_id === userId).length > 0
      ? true
      : false;

  const positionInY =
    Math.floor(position.startIndex / 7) > 0
      ? heightIndex > 0
        ? heightOfGrid * Math.floor(position.startIndex / 7) + heightIndex * 30
        : heightOfGrid * Math.floor(position.startIndex / 7) + 30
      : heightIndex * 25 + 30;
  const activityHours = moment.utc(activity.duration * 1000).format("H");
  const [width, setwidth] = useState(
    length * (position.lastIndex - position.startIndex + 1) - 10
  );
  const [height, setheight] = useState(20);
  const [positionX, setPositionX] = useState(positionInX + 5);
  const [positionY, setPositionY] = useState(positionInY);

  useEffect(() => {
    setPositionX(positionInX + 5);
    setPositionY(positionInY);
    setwidth(length * (position.lastIndex - position.startIndex + 1) - 10);
    setheight(20);
  }, [position, noOfDays]);
  if (heightIndex < 3) {
    return (
      <Rnd
        style={style}
        size={{ width: width, height: height }}
        onDragStop={(e, d) => {
          setPositionX({ x: d.x });
          setPositionY({ y: d.y });
        }}
        position={{ x: positionX, y: positionY }}
        resizeHandleStyles={{
          bottom: { display: "none" },
          top: { display: "none" },
          left: { display: "none" }
        }}
        resizeGrid={[1, 0]}
        onResizeStop={(e, direction, ref, delta, position) => {
          // console.log(position);
          setwidth(Math.ceil(delta.width / length) * length + width);
        }}
      >
        <span className="activity-name-workload">
          {activity.title.substring(
            0,
            (position.lastIndex - position.startIndex + 1) * 8
          )}
        </span>
        {heightIndex > 2 && <div>more activity..</div>}
        <span className="activity-hours-workload">{`${activityHours}h`} </span>
        {isPriority && (
          <span className="activity-star-icon">
            <img src={starActive} alt="star-icon"></img>
          </span>
        )}
      </Rnd>
    );
  } else {
    return <div></div>;
  }
}
