import { getActivitiesForWorkspace, archiveOrUnArchiveActivity } from "../../services/workspaceapi";
import {
  updateActivity,
  createActivity,
  changeActivityWorkFlow,
  getAllBacklogActivity,
  MarkActivityRead
} from "../../services/activityapis";
import { workloadConstant } from "../../constants/workloadConstant";
import { activities } from "../../constants/activitiescontants";

import * as asyncForEach from "async-foreach";
import { updateArchiveOrActivityList } from "../workspace/workspaceaction";

function updateLoadingState(loaderState) {
  return {
    type: workloadConstant.updateGetLoaderState,
    payload: {
      ...loaderState
    }
  };
}

function populateWorkload(activity) {
  return {
    type: workloadConstant.fetchActivity,
    payload: {
      activity: activity,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function clearWorkloadReducerFunction() {
  return {
    type: workloadConstant.clearWorkloadReducer,
    payload: {
      list: [],
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateActivitInReducer({ activity, user_id }) {
  return {
    type: workloadConstant.updateActivity,
    payload: {
      activity,
      user_id,
      key: new Date().getTime.toLocaleString
    }
  };
}

function addActivity(id, activity) {
  return {
    type: workloadConstant.addActivity,
    payload: {
      id,
      activity
    }
  };
}

function removeActivity(id, activity) {
  return {
    type: workloadConstant.removeActivity,
    payload: {
      id,
      activity
    }
  };
}

function updateWorkFlow({ activity, user_id }) {
  return {
    type: workloadConstant.updateWorkFlow,
    payload: {
      activity,
      user_id
    }
  };
}

function unarchiveActivity({ activity, user_id }) {
  return {
    type: workloadConstant.unarchiveActivity,
    payload: {
      activity,
      user_id
    }
  };
}

function populateBacklogActivities(activitiesList) {
  return {
    type: workloadConstant.backlogActivityFetched,
    payload: {
      list: activitiesList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function addBacklogActivity(activity) {
  return {
    type: workloadConstant.addBacklogActivity,
    payload: {
      activity,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function getAllBackLogActivities(activitylist) {
  
  return {
    type: workloadConstant.BACK_LOG_ACTIVITIES,
    payload: {
      listOfactivity: activitylist
    }
  };
}

function removeBackLogActivities(activityId,projectId) {
  
  return {
    type: workloadConstant.REMOVE_BACK_LOG_ACTIVITIES,
    payload: {
      activityId,
      projectId
    }
  };
}

function addBackLogActivities(activity,projectId) {
  
  return {
    type: workloadConstant.ADD_BACK_LOG_ACTIVITIES,
    payload: {
      activity,
      projectId
    }
  };
}

function updateWorkloadPriorityOrNonPriority(activityData) {
  
  return {
    type: workloadConstant.UPDATE_PRIORITY_NONPRIORITY,
    payload: {
      activityData
    }
  };
}

function updatePriorityOrNot(activityData,type) {
  
  return {
    type: workloadConstant.UPDATE_PRIORITY_NOTPRIORITY,
    payload: {
      activity:activityData,
      priorityType:type
    }
  };
}

function clearPriorityOrNot() {
  
  return {
    type: workloadConstant.CLEAR_PRIORITY_NOTPRIORITY,
  };
}




export const getBacklogActivitiesForWorkLoadAction = (
  _,
  callback
) => dispatch => {
  getActivitiesForWorkspace(
    {
      activity_type: "ACTIVITY",
      is_active: true,
      is_archived: false
    },
    response => {
      if (response && !response.stdErr) {
        const backlogActivities = _.filter(
          response,
          activity => activity.user_id.length === 0
        );
        dispatch(populateBacklogActivities(backlogActivities));
        return callback && callback(backlogActivities);
      }
      callback && callback(response);
    }
  );
};

export const getActivitiesForWorkLoadAction = (
  { ids },
  callback
) => dispatch => {
  dispatch(
    updateLoadingState({
      fetching: true,
      fetched: false,
      error: false,
      key: new Date().getTime().toLocaleString()
    })
  );
  asyncForEach.forEach(ids, (id, index) => {
    let toReturn;
    getActivitiesForWorkspace(
      {
        activity_type: "ACTIVITY",
        is_active: true,
        user_id: id,
        is_archived: false
      },
      response => {
        if (response && !response.stdErr) {
          toReturn = {
            id: id,
            activities: response
          };
          dispatch(populateWorkload(toReturn));
        } else {
          dispatch(populateWorkload({ id, activities: [] }));
        }
        
        if(index + 1 === ids.length) {
          dispatch(
            updateLoadingState({
              fetching: false,
              fetched: true,
              error: false,
              key: new Date().getTime().toLocaleString()
            })
          );
        }
        callback && callback(toReturn);
      }
    );
  });
};

export const clearWorkloadReducerAction = () => dispatch => {
  dispatch(clearWorkloadReducerFunction());
  dispatch(clearPriorityOrNot());
};

export const updateActivitiesAction = (
  { user_id, activity_id, fields },
  callback
) => dispatch => {
  updateActivity(
    { user_id, id: activity_id, ...fields, activity_type: "ACTIVITY" },
    response => {
      if (response && !response.error) {
        fields.user_id.forEach(id => 
          dispatch(
          updateActivitInReducer({
            user_id: id,
            activity: { ...response, ...fields }
          })
        ))
      }
      callback && callback(response);
    }
  );
};

export const createActivityAction = (
  {
    activity_type = "ACTIVITY",
    created_by,
    is_active = true,
    due_on,
    start_on,
    duration,
    hours,
    minutes,
    project_id,
    tags,
    title,
    user_id,
    data,
    description,
    project,
    platform = "WEB",
    workflow,
    users_duration,
    continuous_activity
  },
  callback
) => dispatch => {
  createActivity(
    {
      // created_at,
      created_by,
      is_active,
      start_on,
      due_on,
      duration,
      hours,
      minutes,
      project_id,
      tags,
      title,
      user_id,
      data,
      description,
      project,
      activity_type,
      platform,
      workflow,
      users_duration,
      continuous_activity
    },
    response => {
      if (response && !response.stdErr) {
        if (user_id.length > 0) {
          user_id.forEach(id => {
            dispatch(addActivity(id, { ...response }));
          });
        }
        if (user_id.length === 0) {
          dispatch(addBacklogActivity(response));
        }
      }

      callback && callback(response);
    }
  );
};

export const updateWorkloadActivityAction = (
  { activityObj, user_id, activity_id, fields },
  callback
) => dispatch => {
  updateActivity(
    { id: activity_id, ...fields, activity_type: "ACTIVITY" },
    response => {
      if (response && !response.error) {
        user_id.forEach(id => {
          dispatch(
            updateActivitInReducer({
              user_id: id,
              activity: { ...activityObj, ...response, ...fields }
            })
          );
          if (!activityObj.user_id.includes(id)) {
            dispatch(
              addActivity(id, { ...activityObj, ...response, ...fields })
            );
          }
        });
        activityObj.user_id.forEach(id => {
          if (!user_id.includes(id)) {
            dispatch(
              removeActivity(id, { ...activityObj, ...response, ...fields })
            );
          }
        });
      }
      callback && callback(response);
    }
  );
};

export const MarkWorkloadActivityReadAction = ({ body, user_id }, callback) => (dispatch) => {
  MarkActivityRead(body, response => {
    if(response) {
      let activityToUpdate = { id: body.activity_ids[0] };
      if(body.type === "comment")
        activityToUpdate.is_new_comment = false;
      if(body.type === "checklist")
        activityToUpdate.is_new_checklist = false;
      user_id.forEach(id => {
        dispatch(updateActivitInReducer({
          user_id: id,
          activity: { ...activityToUpdate } }));
      })
    }
    callback && callback(response);
  })
}

export const archiveWorkloadActivitiesAction = (
  { auth_token, activity, archiveData, user_id },
  callback
) => dispatch => {
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError) {
      dispatch(unarchiveActivity({ activity: response, user_id }));
      if(user_id === archiveData.data.lastModifiedBy)
        dispatch(updateArchiveOrActivityList({...activity, ...response, completed_on: archiveData.completed_on}, activities.archive))
    }
    callback && callback(response);
  });
};

export const updateWorkloadActivitiesWorkFlowAction = (
  { id, workflow, customer_auth_token, user_id },
  callback
) => dispatch => {
  changeActivityWorkFlow({ id, workflow, customer_auth_token }, response => {
    if (response && !response.stdError) {
      response.workflow = workflow;
      dispatch(updateWorkFlow({ activity: response, user_id }));
    }
    callback && callback(response);
  });
};

export const getAllBacklogActivitiesAction=({params})=>dispatch=>{
  getAllBacklogActivity({params},response=>{
    dispatch(getAllBackLogActivities(response))
  })
};

export const removeBacklogActivitiesAction=({activityId,projectId})=>dispatch=>{
  
      dispatch(removeBackLogActivities(activityId,projectId)) 
};

export const addBacklogActivitiesAction=({activity,projectId})=>dispatch=>{

  dispatch(addBackLogActivities(activity,projectId)) 
};

export const updateWorkloadPriorityOrNonPriorityAction = (
  { auth_token,archiveData,type,popupActivityDetailsData },callback
) => dispatch => {
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError) {
      dispatch(updateWorkloadPriorityOrNonPriority(response));
      dispatch(updatePriorityOrNot(popupActivityDetailsData,type))
    }
    callback(response);
  });
};
