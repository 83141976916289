import React from "react";
import { Tooltip } from "@material-ui/core";
import "../../styles/scss/datefield.scss";

export default function DaysField({ weekDays, onOptionsClick }) {
  // console.log("weekdays", weekDays);
  return (
    <div className="days-container">
      <Tooltip title="Sunday" placement="top">
      <div
        className={
          weekDays.is_sunday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_sunday", !weekDays.is_sunday)}
      >
        {"S"}
      </div>
      </Tooltip>
      <Tooltip title="Monday" placement="top">
      <div
        className={
          weekDays.is_monday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_monday", !weekDays.is_monday)}
      >
        {"M"}
      </div>
      </Tooltip>
      <Tooltip title="Tuesday" placement="top">
      <div
        className={
          weekDays.is_tuesday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_tuesday", !weekDays.is_tuesday)}
      >
        {"T"}
      </div>
      </Tooltip>
      <Tooltip title="Wednesday" placement="top">
      <div
        className={
          weekDays.is_wednesday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_wednesday", !weekDays.is_wednesday)}
      >
        {"W"}
      </div>
      </Tooltip>
      <Tooltip title="Thursday" placement="top">
      <div
        className={
          weekDays.is_thursday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_thursday", !weekDays.is_thursday)}
      >
        {"T"}
      </div>
      </Tooltip>
      <Tooltip title="Friday" placement="top">
      <div
        className={
          weekDays.is_friday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_friday", !weekDays.is_friday)}
      >
        {"F"}
      </div>
      </Tooltip>
      <Tooltip title="Saturday" placement="top">
      <div
        className={
          weekDays.is_saturday
            ? "days-ws-settings selectedDay"
            : "days-ws-settings"
        }
        role="button"
        tabIndex="0"
        onClick={() => onOptionsClick("is_saturday", !weekDays.is_saturday)}
      >
        {"S"}
      </div>
      </Tooltip>
    </div>
  );
}
