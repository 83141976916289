import React, { PureComponent } from "react";
import Mark from "mark.js";
import "../../styles/scss/breadcrumb.scss";
import { workspaceConstant } from "../../constants/workspaceconstants";
import closeSearchIcon from "../../styles/img/svg/comm-close-search.svg";
import searchIcon from "../../styles/img/svg/activity-search.svg";
import forwardArrowSVG from "../../styles/img/svg/forwar_arrow_profile.svg";

import { getActiveTab } from "../../helpers/projectslanding";
import OutsideClickHandler from "react-outside-click-handler";
import firebase from "../../firebase";
import routesHelper from "../../router/routeshelper";
import { getQueryParamsForTimeSheet } from "../../helpers/reportlanding";

class BreadCrumb extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInitiated: false,
      activeClass: "Active",
      // localStorage.getItem(activities.activeActivityTab) || ,
      activeProjClass: "ACTIVE",
      reportSearchFlag: false,
      archivedActivitySearchFlag: false,
      archivedCommentsSearchFlag: false,
    };
  }
  highlightTimer;
  searchTimer;

  // componentDidMount() {
  //   let currentActiveTab = localStorage.getItem(activities.activeActivityTab);
  //   !currentActiveTab &&
  //     localStorage.setItem(
  //       activities.activeActivityTab,
  //       this.state.activeClass
  //     );
  //   localStorage.setItem(
  //     projectConstants.activeTab,
  //     this.state.activeProjClass
  //   );
  // }

  onSearchOutsideClickInitiated = () => {
    const { updatedSearchTextAction, searchContent } = this.props;
    if (this.state.searchInitiated && !searchContent) {
      this.setState(
        {
          searchInitiated: false,
        },
        () => {
          updatedSearchTextAction &&
            updatedSearchTextAction({ searchtext: null, activeClass: null });
        }
      );
    }
  };

  onSearchCloseInitiated = () => {
    var unMarkContext = [
      ...document.querySelectorAll(".act-title"),
      ...document.querySelectorAll(".td_title"),
      ...document.querySelectorAll(".td_des"),
      ...document.querySelectorAll(".project-dashboard-title"),
      ...document.querySelectorAll(".comm-act-title"),
      ...document.querySelectorAll(".checklist-title-txt"),
    ];
    var unMarkInstance = new Mark(unMarkContext);
    unMarkInstance.unmark();
    const {
      updatedSearchTextAction,
      loggedInUser,
      getTimeSheetsAction,
      reportFilters,
      router,
      clearSearchedArchivedActivitiesAction,
      getSelectedWorkpsaceArchivedActivities,
      getArchivedCommentsAction,
    } = this.props;
    if (router.location.pathname === routesHelper.getReportPath()) {
      this.setState({ reportSearchFlag: false });
      let { projectId, memberId, from, to, activityType } = reportFilters;
      let queryParam = getQueryParamsForTimeSheet(
        projectId,
        memberId,
        from,
        to,
        activityType
      );
      getTimeSheetsAction(
        {
          auth_token: localStorage.getItem("customer_auth_token"),
          queryParam,
        },
        _response => {
          // TODO document why this arrow function is empty
        
          
        }
      );
    }
    if (
      router.location.pathname === routesHelper.getAllActivityArchivedPath()
    ) {
      this.setState({ archivedActivitySearchFlag: false });
      clearSearchedArchivedActivitiesAction();
      getSelectedWorkpsaceArchivedActivities(
        {
          auth_token: this.props.selectedCustomerDetails.auth_token,
          user_id: parseInt(this.props.loggedInUser.id),
          activity_type: "ACTIVITY",
          is_archived: true,
          is_active: true,
          page: 1,
          item_per_page: 20,
        },
        _response => {
          // write what to do on success
        }
      );
    }
    if (
      router.location.pathname === routesHelper.getAllCommentsArchivedPath()
    ) {
      this.setState({ archivedCommentsSearchFlag: false });
      
      getArchivedCommentsAction(
        { user_id: parseInt(loggedInUser.id), page: 1, perPage: 10 },
        _response => {
          // write what to do on success
        }
      );
    }
    if (this.state.searchInitiated) {
      this.setState(
        {
          searchInitiated: false,
        },
        () => {
          updatedSearchTextAction &&
            updatedSearchTextAction({ searchtext: null, activeClass: null });
        }
      );
    }
  };

  onSearchInitiated = event => {
    clearTimeout(this.highlightTimer);
    var context = [
      ...document.querySelectorAll(".act-title"),
      ...document.querySelectorAll(".td_title"),
      ...document.querySelectorAll(".td_des"),
      ...document.querySelectorAll(".project-dashboard-title"),
      ...document.querySelectorAll(".comm-act-title"),
      ...document.querySelectorAll(".checklist-title-txt"),
    ];
    var instance = new Mark(context);
    instance.unmark();
    const {
      updatedSearchTextAction,
      getTimeSheetsAction,
      reportFilters,
      loggedInUser,
      router,
      getSearchedArchivedActivitiesAction,
      clearSearchedArchivedActivitiesAction,
      getSelectedWorkpsaceArchivedActivities,
      getArchivedCommentsAction,
    } = this.props;
    const { activeClass } = this.state;
    let searchtext = (event.target && event.target.value) || null;
    updatedSearchTextAction &&
      updatedSearchTextAction({ searchtext, activeClass });
    if (searchtext) {
      if (router.location.pathname === routesHelper.getReportPath()) {
        clearTimeout(this.searchTimer);
        this.setState({ reportSearchFlag: true });
        this.searchTimer = setTimeout(() => {
          let { projectId, memberId, from, to, activityType } = reportFilters;
          let queryParam = getQueryParamsForTimeSheet(
            projectId,
            memberId,
            from,
            to,
            activityType
          );

          queryParam = `${queryParam}&like=${searchtext}`;

          getTimeSheetsAction(
            {
              auth_token: localStorage.getItem("customer_auth_token"),
              queryParam,
            },
            _response => {
              
              this.highlightTimer = setTimeout(() => {
                var updatedContext = [
                  ...document.querySelectorAll(".td_title"),
                  ...document.querySelectorAll(".td_des"),
                ];
                var updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(searchtext, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                });
              }, 100);
            }
          );
        }, 800);
      } else if (
        router.location.pathname === routesHelper.getAllActivityArchivedPath()
      ) {
        clearTimeout(this.searchTimer);
        this.setState({ archivedActivitySearchFlag: true });
        this.searchTimer = setTimeout(() => {
          let queryParam = `?is_archived=true&activity_type=ACTIVITY&user_id=${parseInt(
            localStorage.getItem("id")
          )}&like=${searchtext}`;

          getSearchedArchivedActivitiesAction(queryParam, _response => {
            
            this.highlightTimer = setTimeout(() => {
              var updatedContext = [
                ...document.querySelectorAll(".comm-act-title"),
              ];
              var updatedInstance = new Mark(updatedContext);
              updatedInstance.mark(searchtext, {
                element: "span",
                className: "marked-search",
                accuracy: "partially",
              });
            }, 100);
          });
        }, 800);
      } else if (
        router.location.pathname === routesHelper.getAllCommentsArchivedPath()
      ) {
        clearTimeout(this.searchTimer);
        this.setState({ archivedCommentsSearchFlag: true });
        this.searchTimer = setTimeout(() => {
          getArchivedCommentsAction(
            {
              user_id: parseInt(loggedInUser.id),
              searchText: searchtext,
              page: 1,
              perPage: 10,
            },
            _response => {
              
              this.highlightTimer = setTimeout(() => {
                var updatedContext = [
                  ...document.querySelectorAll(".checklist-title-txt"),
                ];
                var updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(searchtext, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                });
              }, 100);
            }
          );
        }, 800);
      } else
        this.highlightTimer = setTimeout(() => {
          var updatedContext = [
            ...document.querySelectorAll(".act-title"),
            ...document.querySelectorAll(".project-dashboard-title"),
            ...document.querySelectorAll(".comm-act-title"),
            ...document.querySelectorAll(".checklist-title-txt"),
          ];
          var updatedInstance = new Mark(updatedContext);
          updatedInstance.mark(searchtext, {
            element: "span",
            className: "marked-search",
            accuracy: "partially",
          });
        });
    } else {
      if (
        router.location.pathname === routesHelper.getReportPath() &&
        this.state.reportSearchFlag
      ) {
        clearTimeout(this.searchTimer);
        this.setState({ reportSearchFlag: false });
        let { projectId, memberId, from, to, activityType } = reportFilters;
        let queryParam = getQueryParamsForTimeSheet(
          projectId,
          memberId,
          from,
          to,
          activityType
        );
        getTimeSheetsAction(
          {
            auth_token: localStorage.getItem("customer_auth_token"),
            queryParam,
          },
          _response => {
            
            instance.unmark();
          }
        );
      } else if (
        router.location.pathname ===
          routesHelper.getAllActivityArchivedPath() &&
        this.state.archivedActivitySearchFlag
      ) {
        clearTimeout(this.searchTimer);
        this.setState({ archivedActivitySearchFlag: false });
        clearSearchedArchivedActivitiesAction();
        getSelectedWorkpsaceArchivedActivities(
          {
            auth_token: this.props.selectedCustomerDetails.auth_token,
            user_id: parseInt(this.props.loggedInUser.id),
            activity_type: "ACTIVITY",
            is_archived: true,
            is_active: true,
            page: 1,
            item_per_page: 20,
          },
          _response => {
            // write what to do on success
          }
        );
        instance.unmark();
      } else if (
        router.location.pathname ===
          routesHelper.getAllCommentsArchivedPath() &&
        this.state.archivedCommentsSearchFlag
      ) {
        clearTimeout(this.searchTimer);
        this.setState({ archivedCommentsSearchFlag: false });
        
        getArchivedCommentsAction(
          { user_id: parseInt(loggedInUser.id), page: 1, perPage: 10 },
          _response => {
            // write what to do on success
          }
        );
        instance.unmark();
      }
    }
  };

  renderSearchTextBox = parentClassName => {
    const { searchContent } = this.props;
    return (
      <div className={parentClassName}>
        <input
          type="text"
          className="general-page-search-box"
          placeholder="Search"
          onKeyUp={this.onSearchInitiated}
          autoFocus
        />
        {searchContent && (
          <img
            src={closeSearchIcon}
            alt="search-close"
            className="comm-close-search mCS_img_loaded"
            onClick={this.onSearchCloseInitiated}
            tabIndex="0"
          />
        )}
      </div>
    );
  };

  onSearchClicked = className => {
    if (className === "general-page-search-icon")
      firebase.analytics().logEvent("GTD_Activity_search");
    if (className === "general-page-search-icon report-search")
      firebase.analytics().logEvent("GTD_Report_search");
    if (className === "project-search")
      firebase.analytics().logEvent("GTD_Project_Search");
    const { searchInitiated } = this.state;
    const { searchContent } = this.props;
    if (!searchContent)
      this.setState({
        searchInitiated: searchInitiated ? false : true,
      });
  };

  onProjectTabClicked = event => {
    firebase.analytics().logEvent("GTD_Project_Tabs_Changed");
    const { updateTabState } = this.props;
    const activeTab = getActiveTab(event.target.innerText);
    
    this.setState({
      activeProjClass: activeTab,
    });
    
    updateTabState && updateTabState({ projectsLanding: activeTab });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.tabState !== state.activeProjClass) {
      return {
        ...state,
        activeProjClass: "ACTIVE",
      };
    }
    return null;
  }

  onDashboardTabClicked = event => {
    const { updateDashboardTabState } = this.props;
    updateDashboardTabState &&
      updateDashboardTabState({ dashboardLanding: event.target.innerText });
  };

  onTabClicked = tab => {
    const { onTabClickedCallBack } = this.props;
    
    this.setState({
      activeClass: tab,
    });
    this.onSearchCloseInitiated();
    onTabClickedCallBack && onTabClickedCallBack();
  };

  renderBreadcrumb = () => {
    const { breadcrumbList, router, isOwner, tabState, dashboardTabState } =
      this.props;
    const { searchInitiated } = this.state;
    let breadcrumbItems = [];
    breadcrumbList.forEach(breadcrumb => {
      
      if (breadcrumb.type === "heading") {
        breadcrumbItems.push(
          <div
            className="heading"
            key={breadcrumb.link + breadcrumb.type}
            // onClick={() => {
            //   breadcrumb.link && router.push(breadcrumb.link);
            // }}
          >
            <h1>{breadcrumb.name}</h1>
          </div>
        );
      }
      if (breadcrumb.type === "SubHeading") {
        breadcrumbItems.push(
          <div
            className="header-left all-archived"
            role="button"
            tabIndex="0"
            onClick={() => router.push("/archive-center")}
          >
            <img src={forwardArrowSVG} alt="forward-arrow" />{" "}
            <span className="all-archived-title">{breadcrumb.name}</span>
          </div>
        );
      }
      if (breadcrumb.type === "tabs") {
        breadcrumbItems.push(
          <div
            key={breadcrumb.link + breadcrumb.type}
            className={`tab_activity ${
              tabState === getActiveTab(breadcrumb.name) && "active_tab"
            }`}
            //ng-click="projectScope.loadType('ACTIVE')"
            onClick={this.onProjectTabClicked}
          >
            {breadcrumb.name}
          </div>
        );
      }
      if (breadcrumb.type === "dashboard_tabs" && isOwner) {
        breadcrumbItems.push(
          <div
            key={breadcrumb.link + breadcrumb.type}
            className={`tab_dashboard ${
              dashboardTabState === breadcrumb.name ? "active_tab" : ""
            }`}
            onClick={this.onDashboardTabClicked}
          >
            {breadcrumb.name}
          </div>
        );
      }
      if (breadcrumb.type === "search") {
        breadcrumbItems.push(
          <OutsideClickHandler
            key={breadcrumb.link + breadcrumb.type}
            onOutsideClick={this.onSearchOutsideClickInitiated}
          >
            <div className="general-page-search-box-holder ">
              <div className={breadcrumb.className}>
                <img
                  src={searchIcon}
                  alt="search-icon"
                  onClick={() => this.onSearchClicked(breadcrumb.className)}
                />
              </div>
              {searchInitiated &&
                this.renderSearchTextBox(breadcrumb.SearchClassName)}
            </div>
          </OutsideClickHandler>
        );
      }
      if (breadcrumb.type === "button") {
        breadcrumbItems.push(
          <div
            key={breadcrumb.link + breadcrumb.type}
            className={breadcrumb.className}
          >
            <span>{breadcrumb.name}</span>
          </div>
        );
      }
      if (breadcrumb.type === "kanban") {
        breadcrumbItems.push(<span></span>);
      }
    });
    return breadcrumbItems;
  };

  render() {
    const { breadcrumbList } = this.props;
    return (
      <div className="board-wrap">
        {breadcrumbList && breadcrumbList.length && this.renderBreadcrumb()}
        {/*
         */}
      </div>
    );
  }
}

BreadCrumb.defaultProps = {
  breadcrumbList: workspaceConstant.defaultBreadcrumb,
};

export default BreadCrumb;
