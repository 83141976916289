import React, { PureComponent } from "react";
import Avatar from "react-avatar";
import _ from "lodash";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import closePopup from "../../styles/img/svg/close-button-activity.svg";
import "../../styles/scss/activityPopupTop.scss";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import lockedActivity from "../../styles/img/svg/locked-icon.svg";
import unlockedActivity from "../../styles/img/svg/unlocked-icon.svg";
import downArrowIcon from "../../styles/img/svg/act-overview.svg";
import PrioritizedStars from "./prioritizedStars";
import moment from "moment";
import { convertTimeToString, getIsManager } from "../../helpers/generichelper";
import delete_icon from "../../styles/img/svg/user-name-delete.svg";
import { Tooltip } from "@material-ui/core";
import plus_blue from "../../styles/img/svg/plus-blue.svg";
import search_field from "../../styles/img/svg/search-field.svg";
import FloatingSearchBox from "../workspace/partials/floatingsearchbox";
import { convertRGBToHex, renderTimerLogic } from "../../helpers/activityHelper";
import firebase from "../../firebase";
import {
  updateActivityAction,
  updateKanbanActivityAction,
  updateArchiveActivityAction,
  startTimerAction,
  stopTimerAction,
  createTimerAction,
  fetchTimesheetAction,
  updateAllActivityAction,
  addActivityToReducerAction,
  removeActivityFromReducerAction
} from "../../actions/activities/activitiesaction";
import { updateProjectAction } from "../../actions/project/projectaction";
import { updateWorkloadActivityAction,
        removeBacklogActivitiesAction,
        addBacklogActivitiesAction
 } from "../../actions/workload/workload";
import { changeNoItemStatusAction } from "../../actions/notifications/notificationaction";
import Timer from "./timer";
import DialogWrapper from "../common/dialog";
import TimeSheetDescription from "./timesheetdescription";
import UserCannotRemovePopup from "./userCannotRemovePop";
import { changeTimerStatus } from "../../actions/common/commonactions";
import {
  getTimerStatusForWorkspaceAction
} from "../../actions/workspace/workspaceaction";
import { getUpdatedProjectToAddPeople } from "../../helpers/projectsettings";
import routesHelper from "../../router/routeshelper";
import { activities } from "../../constants/activitiescontants";
import { getIsCreator, getIsSuperAdmin } from "../../helpers/reportlanding";
import { getUserDetails } from "../../services/userapi";
import TimerMachine from "react-timer-machine";
import TimerCannotStartPopup from "./timerCannotStartPopup";

class ActivityPopupTop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showOptionIcon: false,
      showFlowPopup: false,
      is_priority: this.props.is_priority,
      openedOverview: false,
      searchText: "",
      filteredUser: [],
      showSearchBox: false,
      selectedUser: [],
      isPopupEnable: false,
      colorOfAvatar: null,
      activityOwner: [],
      showCannotStartPopup: false,
      showCannotPrioritizePopup: false,
      errorObj: null,
      showOwnerChangePopup: false,
      selectedOwner: {},
      userToRemoveId: null,
      showCannotRemovePopup: null,
      showArchivePopup: false,
      tabActiveFlag: true
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const {
      activityDetails,
      workspaceUser,
      // fromNotification,
      // loggedInUser,
      // changeNoItemStatusAction,
      // getActivityByIdAction
    } = this.props;

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        this.setState({ tabActiveFlag: true });
        this.onOptionIconClick();
        this.onOptionIconClick();
      } else {
        this.setState({ tabActiveFlag: false })
      }
    })

    const activityOwner = workspaceUser.filter(
      user => parseInt(user.id) === parseInt(activityDetails?.data?.owner || activityDetails.created_by)
    );
    if(activityOwner.length === 0)
      getUserDetails(
        { queryparams: `?id=${activityDetails?.data?.owner || activityDetails.created_by}` },
        res => {
          if(res && res.length > 0) {
            this.setState({ activityOwner: res })
          }
      })
    else this.setState({ activityOwner })
    this.setState({
      filteredUser: workspaceUser.filter(user => {
        return !activityDetails.user_id.includes(parseInt(user.id));
      })
    });
  }

  componentDidUpdate() {
    if (document.getElementsByClassName("activity-popup-container")[0]) {
      document
        .getElementsByClassName("activity-popup-container")[0]
        .parentElement.classList.add("activity-popup-container-parent");
    }
    const maxHeightofActivityPopup = document.getElementsByClassName(
      "activity-popup-container"
    )[0].offsetHeight;

    if (
      document.getElementsByClassName(
        "timer-description from-activity-popup-top"
      )[0]
    ) {
      document.getElementsByClassName(
        "timer-description from-activity-popup-top"
      )[0].style.maxHeight = `${maxHeightofActivityPopup - 100}px`;
    }
    if (
      this.state.colorOfAvatar === null &&
      !this.props.activityDetails.project.img &&
      document.getElementsByClassName(
        "activity-popup-conatiner-avatar sb-avatar__text"
      ).length > 0 &&
      document.getElementsByClassName(
        "activity-popup-conatiner-avatar sb-avatar__text"
      )[0] instanceof HTMLDivElement
    ) {
      setTimeout(() => {
        this.setState({
          colorOfAvatar: document.getElementsByClassName("activity-popup-conatiner-avatar sb-avatar__text")[0] ?
            window
              .getComputedStyle(
                document.getElementsByClassName(
                  "activity-popup-conatiner-avatar sb-avatar__text"
                )[0],
                null
              )
              .getPropertyValue("background-color") : "rgb(89, 165, 244)"
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.setState({
      colorOfAvatar: null
    });
  }

  onOptionIconClick = () => {
    this.setState({
      showOptionIcon: !this.state.showOptionIcon
    });
  };

  handleClickOutside = event => {
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        showOptionIcon: false,
        showArchivePopup: false
      });
    }
  };

  onShowArchivePopup = (event) => {
    event.stopPropagation();
    this.setState({ showArchivePopup: true });
  }

  onHideArchivePopup = (event) => {
    event.stopPropagation();
    this.setState({ showArchivePopup: false });
  }

  showWorkFlowPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showFlowPopup: !this.state.showFlowPopup
    });
  };

  closeWorkFlowPopup = event => {
    if (
      this.showWorkFlowPopupNode &&
      !this.showWorkFlowPopupNode.contains(event.target)
    )
      this.setState({
        showFlowPopup: false
      });
  };

  onPriorityChange = () => {
    this.setState({
      is_priority: !this.state.is_priority
    });
  };

  changeWorkFlow = (workflow, index, is_priority) => {
    const { activityDetails, changeWorkFlowAction } = this.props;
    const workflowToSend = {
      id: index,
      name: workflow.name,
      moved_from:
        !_.isUndefined(activityDetails.workflow) &&
        !_.isNull(activityDetails.workflow)
          ? activityDetails.workflow.name
          : "Backlog"
    };
    const customer_auth_token = localStorage.getItem("customer_auth_token");

    if (this.props.selectedWorkloadUser) {
      changeWorkFlowAction(
        {
          id: activityDetails.id,
          workflow: workflowToSend,
          customer_auth_token,
          user_id: this.props.selectedWorkloadUser.id
        },
        _resp => {
          this.setState({
            showFlowPopup: false
          });
        }
      );
    } else
      changeWorkFlowAction(
        {
          id: activityDetails.id,
          is_Priority: is_priority,
          workflow: workflowToSend,
          customer_auth_token
        },
        (resp) => {
          if(resp && resp.id)
            firebase.analytics().logEvent("GTD_Activity_inner_status");
          this.setState({
            showFlowPopup: false
          });
        }
      );
  };

  onOpenActOverview = () => {
    this.setState({ openedOverview: true })
  }

  onSearchInitiated = (searchValue, _currentActivity) => {
    const filteredUser = this.props.workspaceUser.filter(element => {
      return (
        element.first_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      );
    });

    this.setState({
      searchText: searchValue,
      filteredUser
      // filteredUser.filter(
      //   user => !currentActivity.user_id.includes(parseInt(user.id)) old activity
      //   user => currentActivity.user_id.includes(parseInt(user.id)) // new activity for owner change
      // )
    });
  };

  OnInputClicked(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onAddUserClicked = (_e, _user) => {
    const {
      activityDetails,
      // projectList,
      // onClosePopup,
      prioritizedActivity,
      unPrioritizedActivity,
      setIsPopupEnableEdit,
      // archiveOrUnArchiveActivitiesAction,
      // prioritizeDePrioritizeFromKanBanAction,
      // selectedWorkloadUser,
      fromWorkload,
      loggedInUser,
      selectedWorkspace,
      // workspaceUser,
      // timerStatus,
      // createTimer,
      handleClickEditCallback,
      // startTimer,
      // stopTimer,
      // fetchTimesheet,
      // changeTimerStatus,
      source
    } = this.props;
    const currentActivityGetted =
      this.props.fromNotification && activityDetails
        ? activityDetails
        : activityDetails && this.state.is_priority && source !== "FROM_KANBAN"
        ? prioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          )[0]
        : activityDetails &&
          unPrioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          ).length > 0
        ? unPrioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          )[0]
        : activityDetails;

    const currentActivity = !_.isUndefined(currentActivityGetted)
      ? currentActivityGetted
      : activityDetails;
    const isSuperAdmin = getIsSuperAdmin(loggedInUser, selectedWorkspace);
    const isUserPartOfCard =
      activityDetails &&
      activityDetails.user_id.includes(parseInt(loggedInUser.id));
    if((isSuperAdmin || isUserPartOfCard) && !fromWorkload) {
      if(setIsPopupEnableEdit) {
        setIsPopupEnableEdit(true);
        this.props.onClosePopup();
      } else {
        handleClickEditCallback(currentActivity, true);
      }
    } else if(fromWorkload) {
      if(setIsPopupEnableEdit) {
        setIsPopupEnableEdit(true);
        this.props.onClosePopup();
      } else {
        handleClickEditCallback(currentActivity, true);
        this.props.onClosePopup();
      }
    }
  // Old open dropdown popup
  //   if (this.state.showSearchBox)
  //     this.setState({
  //       showSearchBox: false
  //     });
  //   else
  //     this.setState({
  //       showSearchBox: true
  //     });
  };

  addUserToSelectedList = (e, user) => {
    if (e.target.checked) {
      this.setState(
        {
          selectedUser: [...this.state.selectedUser, user]
        });
    } else {
      this.setState(
        {
          selectedUser: this.state.selectedUser.filter(
            selecteduser => user.id !== selecteduser.id
          )
        });
    }
  };
  onPopupCloseCallBack = () => {
    this.setState({
      isPopupEnable: false
    });
  };

  onAddUser = (_e, currentActivity, is_priority) => {
    const {
      updateActivity,
      updateKanbanActivity,
      updateArchiveActivity,
      source,
      fromNotification,
      fromWorkload,
      updateWorkloadActivity,
      loggedInUser,
      workspaceUser,
      projectList,
      selectedWorkspace,
      updateActivityState,
      addActivityToReducer,
      router,
      removeBacklogActivities
    } = this.props;
    const selectedUserId = this.state.selectedUser.map(user => parseInt(user.id));
    const selectedProject = projectList.filter(
      project => project.id === currentActivity.project_id
    )[0];

    const updatedActivity = {
      ...currentActivity,
      backFlowFlag:false,
      version_id: currentActivity.version_id + 1,
      data: {
        ...currentActivity.data,
        lastModifiedBy: parseInt(loggedInUser.id)
      },
      is_priority,
      user_id: [...currentActivity.user_id, ...selectedUserId]
    };

    const updatedProjectBody = getUpdatedProjectToAddPeople(
      selectedProject,
      workspaceUser,
      selectedUserId,
      selectedWorkspace.id,
      selectedWorkspace
    );

    if (fromWorkload) {
    
      updateWorkloadActivity(
        {
          activityObj: currentActivity,
          user_id: updatedActivity.user_id,
          activity_id: updatedActivity.id,
          fields: { ...updatedActivity }
        },
        res => {
          if (res && !res.stdErr) {
            this.setState(
              {
                selectedUser: [],
                searchText: "",
                filteredUser: workspaceUser.filter(
                  user => !updatedActivity.user_id.includes(parseInt(user.id))
                ),
                showSearchBox: false
              });
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
            if(currentActivity.backFlowFlag){
              removeBacklogActivities(currentActivity.id,currentActivity.project.id);
              this.props.onClosePopup();
          }
        }
        }
      );
    } else if (source === "FROM_KANBAN") {
      updateKanbanActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              selectedUser: [],
              searchText: "",
              filteredUser: workspaceUser.filter(
                user => !updatedActivity.user_id.includes(parseInt(user.id))
              ),
              showSearchBox: false
            });
          this.props.updateProjectAction(
            { queryParam: "", body: updatedProjectBody });
        }
      });
    } else if (source === "FROM_ARCHIVE") {
      updateArchiveActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              selectedUser: [],
              searchText: "",
              filteredUser: workspaceUser.filter(
                user => !updatedActivity.user_id.includes(parseInt(user.id))
              ),
              showSearchBox: false
            });
          this.props.updateProjectAction(
            { queryParam: "", body: updatedProjectBody });
        }
      });
    } else {
      updateActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              selectedUser: [],
              searchText: "",
              filteredUser: workspaceUser.filter(
                user => !updatedActivity.user_id.includes(parseInt(user.id))
              ),
              showSearchBox: false
            });
          if(fromNotification) {
            updateActivityState(updatedActivity);
            if(selectedUserId.includes(parseInt(loggedInUser.id)) &&
              router.location.pathname === routesHelper.getActivityPath()) {
                addActivityToReducer(updatedActivity)
            }
          }
          this.props.updateProjectAction(
            { queryParam: "", body: updatedProjectBody });
        }
      });
    }
  };

  onShowUserRemovePopup = (userToRemoveId) => {
    this.setState({ userToRemoveId });
  }

  onRemovePopupOutsideClick = (userId) => {
    if(userId === this.state.userToRemoveId)
      this.setState({ userToRemoveId: null });
  }

  onCloseCannotRemovePopup = () => {
    this.setState({ showCannotRemovePopup: null });
  }

  onRemoveUser = (_e, user, currentActivity, is_priority) => {
    let userId=localStorage.getItem("id");

    const updatedData = currentActivity.data && currentActivity.data.priority ? {
      ...currentActivity.data,
      lastModifiedBy: parseInt(userId),
      priority: currentActivity.data.priority.filter(member => member.user_id !== user.id) 
    } : currentActivity.data;

    const updated_users_duration = currentActivity.users_duration.filter(member => member.user_id !== user.id);
    

    // Minimum start date and Maximum due date calculation
    let startDates = updated_users_duration.map(existingUser => {
      if(existingUser?.start_date) {
        return existingUser.start_date
      }
      return undefined;
    }).filter(function( element ) {
      return element !== undefined;
   }).map(date => moment(date));
   let minStartDate = moment.min(startDates).format();

   let endDates = updated_users_duration.map(existingUser => {
    if(existingUser?.end_date) {
      return existingUser.end_date
    }
    return undefined;
  }).filter(function( element ) {
    return element !== undefined;
 }).map(date => moment(date));
 let maxEndDate = moment.max(endDates).format();
 let updated_continuous = [];
 updated_users_duration.forEach(existingUser => {
  if(existingUser?.continuous) {
    updated_continuous.push(true);
  }
})

const updated_duration = updated_continuous.length > 0 ? 0 : 
updated_users_duration.reduce((total, existingUser) => {
    if(existingUser?.total_time) {
      return total + existingUser.total_time
    }
    return total;
  }, 0)
    
    const updatedActivity = {
      ...currentActivity,
      data: updatedData,
      version_id: currentActivity.version_id + 1,
      user_id: currentActivity.user_id.filter(
        removeduser => user.id !== removeduser
      ),
      performed_by:JSON.parse(userId),
      users_duration: updated_users_duration,
      description: currentActivity.description || "",
      tags: currentActivity.tags || [],
      duration: updated_duration,
      start_on: minStartDate,
      due_on: updated_continuous.length > 0 ? moment().add(5, "year").format() : maxEndDate,
      continuous_activity: updated_continuous.length > 0 ? true : false
    };
    if(currentActivity.users_not_in_workspace)
      updatedActivity.users_not_in_workspace = currentActivity.users_not_in_workspace.filter(removeduser => user.id !== removeduser.id);
    const {
      updateActivity,
      updateKanbanActivity,
      // workspaceUser,
      fromWorkload,
      selectedWorkloadUser,
      fromNotification,
      updateWorkloadActivity,
      updateArchiveActivity,
      source,
      updateAllActivity,
      updateActivityState,
      removeActivityFromReducer,
      addBacklogActivities
    } = this.props;

    
    if(user.email)
      this.setState({
        filteredUser: [...this.state.filteredUser, user]
      });
    if (fromWorkload) {
      updateWorkloadActivity(
        {
          activityObj: currentActivity,
          user_id: updatedActivity.user_id,
          activity_id: updatedActivity.id,
          fields: { ...updatedActivity }
        },
        res => {
          if (res && !res.stdErr && !res.error) {
            this.setState({ userToRemoveId: null });
            if(updatedActivity.user_id && updatedActivity.user_id.length<1){
              addBacklogActivities(updatedActivity,currentActivity.project_id);
              this.props.onClosePopup();
              
          }else if(updatedActivity.user_id && !updatedActivity.user_id.includes(selectedWorkloadUser.id)){
            this.props.onClosePopup();
          }
          } else if (res && res.error) {
            this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
          }
        }
      );
    } else if (source === "FROM_KANBAN") {
      updateKanbanActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({ userToRemoveId: null });
        } else if (res && res.error) {
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    } else if (source === "FROM_ARCHIVE") {
      updateArchiveActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({ userToRemoveId: null });
        } else if (res && res.error) {
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    } else{
      updateActivity({ ...updatedActivity, is_priority }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({ userToRemoveId: null });
          if(JSON.parse(userId)  ===user.id){
            removeActivityFromReducer(updatedActivity);
            updateAllActivity(currentActivity.id);
          this.props.onClosePopup();
        } else if(fromNotification) {
          updateActivityState(updatedActivity);
        }
        } else if (res && res.error) {
          console.log("else part")
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    }
       
  };

  shouldEnablePopup = (boolean, selectedActivityDetails) => {
    this.setState(
      {
        isPopupEnable: boolean,
        selectedActivity: selectedActivityDetails || {}
      });
  };

  onShowCannotStartPopup = (res) => {
    this.setState({ showCannotStartPopup: true, errorObj: res.error })
  }

  onHideCannotStartPopup = () => {
    this.setState({ showCannotStartPopup: false, errorObj: null });
  }

  onShowCannotPrioritizePopup = (res) => {
    this.setState({ showCannotPrioritizePopup: true, errorObj: res })
  };

  onHideCannotPrioritizePopup = () => {
    this.setState({ showCannotPrioritizePopup: false, errorObj: null });
  };

  // change
  onPlayPauseClicked = (_timerEnabled, selectedTileDetials) => {
    const { startTimer, shouldEnablePopup } = this.props;
    if (startTimer) {
      //fetching Timesheet for current user
      const customer_auth_token = localStorage.getItem("customer_auth_token");
      const currentUserId = parseInt(localStorage.getItem("id"));
      const timesheet = selectedTileDetials.timesheets.filter(ts => {
        return ts.user_id === currentUserId;
      });

      const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);
      if (
        sortedTimesheetData.length > 0 &&
        _.has(sortedTimesheetData[0].data.TIME[0], "START") &&
        !_.has(sortedTimesheetData[0].data.TIME[0], "STOP")
      ) {
        shouldEnablePopup(true, selectedTileDetials);
        // Create ne timesheet Object and call updateTimesheetAction
      } else {
        const currentTime = moment(new Date()).format();
        const data = {
          TIME: [{ START: currentTime }]
        };
        startTimer(
          {
            activity_id: selectedTileDetials.id,
            data,
            date: currentTime,
            user_id: currentUserId,
            customer_auth_token
          });
      }
    }
  };

  onCancelAddClick = () => {

    this.setState({
      // searchText: "", // commented for new activity owner change search input field issue
      showSearchBox: false,
      selectedUser: [],
      // commented below for new activity owner change search input field issue
      // filteredUser: workspaceUser.filter(user => {
      //   return !activityDetails.user_id.includes(parseInt(user.id));
      // })
    });
  };

  renderTimerLogic = activity => {
    const currentUserId = parseInt(localStorage.getItem("id"));
    var timerStartTimeInSeconds = 0;
    let timerShouldBeEnabled = null;

    const timesheet = activity.timesheets.filter(
      ts => ts.user_id === currentUserId
    );
    // change
    let totalTimeTaken = timesheet.reduce((totalTime, current) => {
      return (totalTime + current.time_taken);
    }, 0);

    timerStartTimeInSeconds = timesheet.reduce((totalTime, current, index) => {
      return (
        (totalTime = 0)
      );
    }, 0);

    const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);

    if (sortedTimesheetData.length > 0) {
      timerShouldBeEnabled =
        sortedTimesheetData[0].data &&
        _.has(sortedTimesheetData[0].data.TIME[0], "STOP")
          ? true
          : false;
    } else {
      timerShouldBeEnabled = true;
    }

    if (
      !timerShouldBeEnabled &&
      sortedTimesheetData[0].data &&
      sortedTimesheetData[0].data.TIME[0] &&
      sortedTimesheetData[0].data.TIME[0].hasOwnProperty("START")
    ) {
      timerStartTimeInSeconds += moment(new Date()).diff(
        sortedTimesheetData[0].data.TIME[0].START,
        "seconds"
      );
    }

    return {
      timerStartTimeInSeconds,
      timerShouldBeEnabled,
      totalTimeTaken
    };
  };

  renderTimerLogicNew = (activityDetails) => {
    const { anyTimerStatus } = this.props;
    const currentUserId = parseInt(localStorage.getItem("id"));
    var timerStartTimeInSeconds = 0;
    let timerShouldBeEnabled = null;

    const timesheet = activityDetails && activityDetails.timesheets && activityDetails.timesheets.filter(
      ts => ts.user_id === currentUserId && !ts.is_archived
    );
    let totalTimeTaken = timesheet && timesheet.reduce((totalTime, current) => {
      return (totalTime + current.time_taken);
    }, 0);

    if(activityDetails && anyTimerStatus?.any_timer_running && anyTimerStatus?.timesheet?.activity_id === activityDetails.id) {
      timerShouldBeEnabled = false;
      timerStartTimeInSeconds += moment(new Date()).diff(
        anyTimerStatus.timesheet.data.TIME[0].START,
        "seconds"
      );
    } else  {
      timerShouldBeEnabled = true;
    }

    return {
      timerStartTimeInSeconds,
      timerShouldBeEnabled,
      totalTimeTaken
    }
  }

  onViewKanban = () => {
    const { router, activityDetails, onPopupCloseCallBack } = this.props;
    firebase.analytics().logEvent("GTD_Activity_inner_kanban");
    onPopupCloseCallBack && onPopupCloseCallBack();
    router.push(routesHelper.kanbanPath(activityDetails.project_id));
  };

  onShowOwnerChangePopup = () => {
    this.setState({ showOwnerChangePopup: !this.state.showOwnerChangePopup });
  }

  onSelectOwner = (e, owner) => {
    if(e.target.checked) {
      this.setState({ selectedOwner: owner })
    } else {
      this.setState({ selectedOwner: {} })
    }
  }

  onOwnerChangeOutsideClick = () => {
    this.setState({
      showOwnerChangePopup: false,
      searchText: "",
      selectedOwner: {}
    });
  }

  onChangeOwner = (_e, currentActivity, is_priority) => {
    const {
      updateActivity,
      updateKanbanActivity,
      updateArchiveActivity,
      source,
      fromNotification,
      fromWorkload,
      updateWorkloadActivity,
      loggedInUser,
      // workspaceUser,
      // projectList,
      // selectedWorkspace,
      updateActivityState,
      // addActivityToReducer,
      // router,
      // removeBacklogActivities
    } = this.props;
    const selectedOwner = this.state.selectedOwner;

    const updatedActivity = {
      ...currentActivity,
      backFlowFlag:false,
      version_id: currentActivity.version_id + 1,
      data: {
        ...currentActivity.data,
        owner: parseInt(selectedOwner.id),
        lastModifiedBy: parseInt(loggedInUser.id)
      },
      description: currentActivity.description || "",
      is_priority
    };

    if (fromWorkload) {
    
      updateWorkloadActivity(
        {
          activityObj: currentActivity,
          user_id: updatedActivity.user_id,
          activity_id: updatedActivity.id,
          fields: { ...updatedActivity }
        },
        res => {
          if (res && !res.stdErr) {
            this.setState(
              {
                showOwnerChangePopup: false,
                searchText: "",
                selectedOwner: {},
                activityOwner: [selectedOwner]
              }
            );
        }
        }
      );
    } else if (source === "FROM_KANBAN") {
      updateKanbanActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              showOwnerChangePopup: false,
                searchText: "",
                selectedOwner: {},
                activityOwner: [selectedOwner]
            });
        }
      });
    } else if (source === "FROM_ARCHIVE") {
      updateArchiveActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              showOwnerChangePopup: false,
                searchText: "",
                selectedOwner: {},
                activityOwner: [selectedOwner]
            });
        }
      });
    } else {
      updateActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          this.setState(
            {
              showOwnerChangePopup: false,
                searchText: "",
                selectedOwner: {},
                activityOwner: [selectedOwner]
            });
          if(fromNotification) {
            updateActivityState(updatedActivity);
          }
        }
      });
    }
  };

  onLockUnlock = (currentActivity, is_priority) => {
    const {
      updateActivity,
      updateKanbanActivity,
      getActivitiesWithProjectId,
      updateArchiveActivity,
      source,
      fromNotification,
      fromWorkload,
      updateWorkloadActivity,
      // loggedInUser,
      // selectedWorkspace,
      updateActivityState,
      // addActivityToReducer,
      // router
    } = this.props;
    let updatedActivity = {
      ...currentActivity,
      is_priority,
      is_locked: !currentActivity.is_locked
    };
    if (!updatedActivity.description) updatedActivity = _.omit(updatedActivity, ["description"]);
    if(fromWorkload) {
      updateWorkloadActivity(
        {
          activityObj: currentActivity,
          user_id: updatedActivity.user_id,
          activity_id: updatedActivity.id,
          fields: { ...updatedActivity }
        });
    } else if (source === "FROM_KANBAN") {
      updateKanbanActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr && res.id) {
          getActivitiesWithProjectId(currentActivity.project?.id || currentActivity.project_id);
        }
      });
    } else if (source === "FROM_ARCHIVE") {
      updateArchiveActivity({ ...updatedActivity });
    } else {
      updateActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr) {
          if(fromNotification) {
            updateActivityState(updatedActivity);
          }
        }
      });
    }
  }

  render() {
    const {
      activityDetails,
      projectList,
      onClosePopup,
      prioritizedActivity,
      unPrioritizedActivity,
      setIsPopupEnableEdit,
      archiveOrUnArchiveActivitiesAction,
      getTimerStatusForWorkspace,
      anyTimerStatus,
      prioritizeDePrioritizeFromKanBanAction,
      updateActivityState,
      selectedWorkloadUser,
      fromWorkload,
      // fromNotification,
      loggedInUser,
      selectedWorkspace,
      workspaceUser,
      timerStatus,
      createTimer,
      handleClickEditCallback,
      startTimer,
      stopTimer,
      fetchTimesheet,
      changeTimerStatus,
      onWorkspaceNavBarClicked,
      source
    } = this.props;
    const {
      is_priority,
      searchText,
      filteredUser,
      showOwnerChangePopup,
      selectedOwner,
      showSearchBox,
      selectedUser,
      isPopupEnable,
      userToRemoveId,
      showCannotStartPopup,
      showCannotPrioritizePopup,
      errorObj,
      showCannotRemovePopup,
      showArchivePopup
    } = this.state;

    const isUserPartOfCard =
      activityDetails &&
      activityDetails.user_id.includes(parseInt(loggedInUser.id));

    const isSuperAdmin = getIsSuperAdmin(loggedInUser, selectedWorkspace);
    const isCreator = getIsCreator(selectedWorkspace);

    const currentActivityGetted =
      (this.props.fromNotification || fromWorkload) && activityDetails
        ? activityDetails
        : activityDetails && is_priority && source !== "FROM_KANBAN"
        ? prioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          )[0]
        : activityDetails &&
          unPrioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          ).length > 0
        ? unPrioritizedActivity.filter(
            activity => activity.id === activityDetails.id
          )[0]
        : activityDetails;

    const currentActivity = !_.isUndefined(currentActivityGetted)
      ? currentActivityGetted
      : activityDetails;

    const isManagerOfProject = getIsManager(projectList.filter(project => project.id === currentActivity?.project_id)[0])
    const isCreatorOfActivity = currentActivity?.created_by === parseInt(loggedInUser.id);
    const isCurrentOwnerOfActivity = currentActivity?.data?.owner === parseInt(loggedInUser.id);

    if (!_.isUndefined(currentActivity)) {
      const renderTimerLogicTimerPopup = this.renderTimerLogicNew(currentActivity);
      const dialogProps = {
        className: "timer-description from-activity-popup-top",
        showPopup: isPopupEnable,
        dialogTitle: () => {
          return (
            <React.Fragment>
              <div className="header-title-inner">
              {!currentActivity.project.img && (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  color={convertRGBToHex(this.state.colorOfAvatar)}
                  maxInitials={1}
                  name={currentActivity.project.name}
                ></Avatar>
              )}
              {currentActivity.project.img && (
                <div class="client-logo">
                  <img src={currentActivity.project.img} alt="project-logo" />
                </div>
              )}
              <h2>{currentActivity.title}</h2>{" "}
              <span>
                {currentActivity.project && currentActivity.project.name}
              </span>
              </div>
              <span className="timer-activity">
                    <TimerMachine
                      timeStart={
                        renderTimerLogicTimerPopup.timerStartTimeInSeconds * 1000
                      }
                      started={!renderTimerLogicTimerPopup.timerShouldBeEnabled}
                      paused={false}
                      countdown={false}
                      interval={1000}
                      formatTimer={(_time, ms) =>
                        moment
                          .duration(ms, "milliseconds")
                          .format("HH:mm:ss", { trim: false })
                      }
                    // onStart={}
                    />
                  </span>
            </React.Fragment>
          );
        },
        onCloseCallBack: this.onPopupCloseCallBack,
        onConfirmCallBack: this.onConfirm,
        shouldButtonEnable: true
      };
      const activityWorkflows =
        currentActivity &&
        currentActivity.project &&
        currentActivity.project.workflow;

      const projWorkflows = projectList.filter(
        project => project.id === currentActivity.project.id
      );
      const projectWorkflows = projWorkflows.length > 0 ? projWorkflows[0].workflow : {};

      const workflows =
        source === "FROM_KANBAN" ? projectWorkflows : activityWorkflows;

      const arrayOfObj = Object.entries(workflows).map(e => ({ [e[0]]: e[1] }));

      const workflowOptions = arrayOfObj
        .filter(workflow => {
          const workflowId = Object.getOwnPropertyNames(workflow)[0];
          return !workflow[workflowId].is_archived;
        })
        .map(workflow => {
          const workflowId = Object.getOwnPropertyNames(workflow)[0];
          return (
            <li
              className={
                currentActivity.workflow
                  ? currentActivity.workflow.name === workflow[workflowId].name ? "active" : ""
                  : workflow[workflowId].name === "Backlog" ? "active" : ""
              }
              key={workflow[workflowId].name}
              onClick={
                (isSuperAdmin || isUserPartOfCard)
                  ? () =>
                      this.changeWorkFlow(
                        workflow[workflowId],
                        workflowId,
                        is_priority
                      )
                  : fromWorkload
                  ? () =>
                      this.changeWorkFlow(
                        workflow[workflowId],
                        workflowId,
                        is_priority
                      )
                  : () => {}
              }
            >
              {workflow[workflowId].name}
            </li>
          );
        });

      const workflowRenderer = () => {
        const currentWorkFLowName =
          currentActivity.workflow &&
          currentActivity.workflow.name &&
          !fromWorkload
            ? currentActivity.workflow.name
            : fromWorkload
            ? activityDetails && activityDetails.workflow?.name
            : "Backlog";
        return (
          <div
            className="activity-flow"
            ref={node => (this.showWorkFlowPopupNode = node)}
          >
            <div
              className={
                this.state.showFlowPopup
                  ? "workflow-change-ddown-holder clicked"
                  : (isSuperAdmin || isUserPartOfCard) && !fromWorkload
                  ? "workflow-change-ddown-holder"
                  : fromWorkload
                  ? "workflow-change-ddown-holder"
                  : "workflow-change-ddown-holder without-arrow"
              }
              onClick={
                (isSuperAdmin || isUserPartOfCard) && !fromWorkload
                  ? this.showWorkFlowPopup
                  : fromWorkload
                  ? this.showWorkFlowPopup
                  : () => {}
              }
            >
              <div className="workflow-change-ddown-text">
                {currentWorkFLowName}{" "}
              </div>
              {this.state.showFlowPopup && (
                <OutsideClickHandler onOutsideClick={this.closeWorkFlowPopup}>
                  <div className="workflow-change-ddown">
                    <ul className="workflow-change-ddown-inner">
                      {workflowOptions}
                    </ul>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
        );
      };
      const withoutOwnerUserArray = workspaceUser.filter(
        user => parseInt(user.id) !== parseInt(currentActivity.created_by)
      );
      const activityOwner = workspaceUser.filter(
        user => parseInt(user.id) === parseInt(currentActivity.created_by)
      );
      let sortedUserArray =
        activityOwner.length > 0
          ? [activityOwner[0]].concat(withoutOwnerUserArray)
          : withoutOwnerUserArray;
      if(currentActivity.users_not_in_workspace)
        sortedUserArray = sortedUserArray.concat(currentActivity.users_not_in_workspace)
      const renderUserAvatar =
        sortedUserArray &&
        _.sortBy(sortedUserArray, (user) => user.first_name.toLowerCase())
          .filter(user => currentActivity.user_id.includes(parseInt(user.id)))
          .map(user => {
            return (
              <OutsideClickHandler key={user.id} onOutsideClick={() => this.onRemovePopupOutsideClick(user.id)}>
              
                <div
                  className={
                    parseInt(user.id) === parseInt(currentActivity.created_by)
                      ? "user_img_holder workspace-owner"
                      : "user_img_holder"
                  }
                  key={user.id}
                >
                  <Tooltip
                title={`${user.first_name}${currentActivity?.users_not_in_workspace?.indexOf(user) > -1 ? " - Currently not a member of this workspace" : ""}`}
                key={`tooltip-${currentActivity.id}${user.id}`}
              >
                  <div
                    className={`${currentActivity?.users_not_in_workspace?.indexOf(user) > -1 ? "not-wp-user" : ""}`}
                    style={{ width: "23px", height: "23px" }}
                  >
                  {!user.img && (
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      name={user.first_name}
                      maxInitials={1}
                    ></Avatar>
                  )}
                  {user.img && (
                    <img
                      src={user.img}
                      alt="user-img"
                      // role="presentation"
                      // aria-hidden="true"
                    />
                  )}

                  {this.state.activityOwner.length > 0 && (isSuperAdmin || 
                  (parseInt(loggedInUser.id) === parseInt(this.state.activityOwner[0].id)) || 
                  (isUserPartOfCard && parseInt(this.state.activityOwner[0].id) !== parseInt(user.id))) && (
                    <div className="user-img-delete">
                      <img
                        src={delete_icon}
                        alt="Delete Icon"
                        onClick={() =>
                          this.onShowUserRemovePopup(user.id)
                        }
                      />
                    </div>
                  )}
                  </div>
                  </Tooltip>
                  {userToRemoveId && userToRemoveId === user.id &&
                  <div className="Edit-board-ddown rm-user-act">
                          <div className="confirmation-box-remove">
                            <p className="user-name">
                            Remove{" "}
                            {parseInt(user.id) === parseInt(loggedInUser.id) ?
                            "yourself" : user.first_name } from this Activity
                            </p>
                            <hr className="divider-remove-box"></hr>
                            <p className="messageremove-box">
                            Are you sure you want to remove{" "}
                            {parseInt(user.id) === parseInt(loggedInUser.id) ?
                            "yourself" : user.first_name } from this activity?
                            </p>
                            <div className="button-box">
                              <button
                                className="cancel-button-remove-box"
                                onClick={() => this.onRemovePopupOutsideClick(user.id)}
                              >
                                Cancel
                              </button>
                              <button
                                className="confirm-button-remove-box"
                                onClick={e =>
                                  this.onRemoveUser(
                                    e,
                                    user,
                                    currentActivity,
                                    is_priority
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>}
                </div>
              
                </OutsideClickHandler>
            );
          });

          let dialogPropsCannotStart = {
            className: "dialog_wrapper",
            showPopup: showCannotStartPopup,
            dialogTitle: () => errorObj?.msg === "You are not a part of this activity card" ? "Activity not accessible" : "Another Activity Running",
            onCloseCallBack: this.onHideCannotStartPopup,
            shouldButtonEnable: true
          };

          let dialogPropsCannotPrioritize = {
            className: "dialog_wrapper",
            showPopup: showCannotPrioritizePopup,
            dialogTitle: () => errorObj?.error === "Priority limit exceeded" && errorObj.error,
            onCloseCallBack: this.onHideCannotPrioritizePopup,
            shouldButtonEnable: true
          };

          let dialogPropsCannotRemove = {
            className: "dialog_wrapper",
            showPopup: showCannotRemovePopup,
            dialogTitle: () => `${parseInt(showCannotRemovePopup.id) === parseInt(loggedInUser.id) ?
              "You" : showCannotRemovePopup.first_name } could not be removed`,
            // onCloseCallBack: this.onCloseGooglePopup,
            shouldButtonEnable: true
          };
      const filteredWorkspaceUser = _.filter(
        searchText ? filteredUser : workspaceUser,
        // user => !currentActivity.user_id.includes(parseInt(user.id)) old activity
        user =>
          this.state.activityOwner.length > 0 &&
          parseInt(this.state.activityOwner[0].id) !== parseInt(user.id) // new activity for owner change popup
      );
      const renderedUserSuggestionList = _.sortBy(
        filteredWorkspaceUser,
        [user => user.first_name.toLowerCase()],
        ["asc"]
      ).map(element => {
        return (
          <div
            className="search-data-list"
            key={`${element.first_name}${element.id}`}
          >
            <input
              type="checkbox"
              model=".add_member"
              checked={element.id === selectedOwner.id}
              className="tick-check"
              id={`${element.first_name}${element.id}`}
              onChange={e => {
                // this.addUserToSelectedList(e, element) old
                this.onSelectOwner(e, element) // new activity
              }}
            />
            <label
              className="tick-symbol"
              htmlFor={`${element.first_name}${element.id}`}
              role="button"
            ></label>
            {/* <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={currentActivity.project.name}
                ></Avatar> */}
            <div className="user-img-icon">
              {(_.isNull(element.img) || _.isEmpty(element.img)) === true ? (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={element.first_name}
                ></Avatar>
              ) : (
                <img src={element.img} alt="User" />
              )}
            </div>
            <div className="user-details-holder">
              <div className="user-name">{element.first_name}</div>
              <div className="user-email">{element.email} </div>
            </div>
          </div>
        );
      });

      const shouldViewInKanban = projectList.filter(project => project.id === (currentActivity.project.id || currentActivity.project_id)).length > 0;

      const logged_in_user_duration = currentActivity.users_duration && currentActivity.users_duration.length>0 && currentActivity.users_duration.filter(user => user.user_id === parseInt(selectedWorkloadUser?.id || loggedInUser.id))[0];

      return (
        <div className="apc-top-container" ref={this.myRef}>
          <div className="apc-upper-top-holder">
            <div className="apc-name-area">
              <div className="apc-client-logo">
                {!currentActivity.project.img && (
                  <Avatar
                    // color={Avatar.getRandomColor("sitebase", [
                    //   "#ff9292",
                    //   "#7c9af1"
                    // ])}
                    className={"activity-popup-conatiner-avatar"}
                    style={{
                      width: "100%",
                      height: "100%",
                      fontFamily: "Roboto",
                      fontSize: "13.7px",
                      fontWeight: "500"
                    }}
                    name={currentActivity.project.name.split("").join(" ")}
                    maxInitials={1}
                  ></Avatar>
                )}

                {currentActivity.project.img && (
                  <img src={currentActivity.project.img} alt="project-logo"></img>
                )}
              </div>
              <div
                className={
                  isUserPartOfCard
                    ? "apc-activity-title"
                    : "apc-activity-title not-a-part-of-card"
                }
              >
                {" "}
                {currentActivity.title}{" "}
              </div>
              {workflowRenderer()}
              {isUserPartOfCard &&
                source &&
                source !== "FROM_ARCHIVE" &&
                !fromWorkload && (
                  <PrioritizedStars
                    onPriorityChange={this.onPriorityChange}
                    prioritizedActivity={prioritizedActivity}
                    updateActivityState={updateActivityState}
                    is_priority={is_priority}
                    loggedInUser={loggedInUser}
                    activityDetails={currentActivity}
                    archiveOrUnArchiveActivitiesAction={
                      source === "FROM_KANBAN" ? prioritizeDePrioritizeFromKanBanAction : archiveOrUnArchiveActivitiesAction
                    }
                    timerRunningStatus={renderTimerLogic(currentActivity).timerShouldBeEnabled ? false : true}
                    onShowCannotPrioritizePopup={this.onShowCannotPrioritizePopup}
                  />
                )}
              {fromWorkload && (
                <PrioritizedStars
                  onPriorityChange={this.onPriorityChange}
                  prioritizedActivity={prioritizedActivity}
                  is_priority={is_priority}
                  selectedWorkloadUser={selectedWorkloadUser}
                  fromWorkload={fromWorkload}
                  loggedInUser={loggedInUser}
                  activityDetails={currentActivity}
                  archiveOrUnArchiveActivitiesAction={
                    archiveOrUnArchiveActivitiesAction
                  }
                  timerRunningStatus={renderTimerLogic(currentActivity).timerShouldBeEnabled ? false : true}
                />
              )}
              {this.state.activityOwner.length > 0 &&
              <OutsideClickHandler onOutsideClick={this.onOwnerChangeOutsideClick}>
              <Tooltip title={`Activity owner - ${this.state.activityOwner[0].first_name}`}>
                <div
                  className="act_owner_img_holder"
                  onClick={this.onShowOwnerChangePopup}
                >
                  {!this.state.activityOwner[0].img && (
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      name={this.state.activityOwner[0].first_name}
                      maxInitials={1}
                    ></Avatar>
                  )}
                  {this.state.activityOwner[0].img && (
                    <img
                      src={this.state.activityOwner[0].img}
                      alt="owner-img"
                      // role="presentation"
                      // aria-hidden="true"
                    />
                  )}
                </div>
              </Tooltip>
              <div className="edit-board-ddown owner-change-ddown">
                        {showOwnerChangePopup && (isCreator || isSuperAdmin || isManagerOfProject || isCreatorOfActivity || isCurrentOwnerOfActivity) && (
                          <React.Fragment>
                            <div className="change-act-owner-title">Change Activity Owner</div>
                            <FloatingSearchBox
                              source={activities.fromActivity}
                              img={search_field}
                              onInputChange={e =>
                                this.onSearchInitiated(
                                  e.target.value,
                                  currentActivity
                                )
                              }
                              OnInputClicked={this.OnInputClicked}
                            ></FloatingSearchBox>
                            <div className="search-data-container inside-activity">
                              {renderedUserSuggestionList}
                            </div>
                            <div className="add-multi-member-bottom-holder">
                              <div
                                className="add-multi-member-cancel"
                                role="button"
                                tabIndex="0"
                                onClick={this.onOwnerChangeOutsideClick}
                              >
                                Cancel
                              </div>
                              <button
                                className="add-multi-member-confirm"
                                disabled={!selectedOwner.id}
                                onClick={e =>
                                  this.onChangeOwner(
                                    e,
                                    currentActivity,
                                    is_priority
                                  )
                                }
                              >
                                {" "}
                                Confirm
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
              </OutsideClickHandler>
              }
              {<Tooltip
                title={(isCreator || isSuperAdmin || isCurrentOwnerOfActivity || isCreatorOfActivity) ? currentActivity.is_locked ?
                  "Unlock Activity - All mentioned members can access" :
                  "Lock Activity - Only activity members can access" : "Disabled"} >
              <div
                className="lock-unlock-act"
                style={!isCreator && !isSuperAdmin && !isCurrentOwnerOfActivity && !isCreatorOfActivity ? { cursor: "default" } : {}}
                onClick={() => {
                  if(currentActivity?.data?.owner) {
                    (isCreator || isSuperAdmin || isCurrentOwnerOfActivity) && this.onLockUnlock(currentActivity, is_priority)
                  } else {
                      (isCreator || isSuperAdmin || isCreatorOfActivity) && this.onLockUnlock(currentActivity, is_priority)
                  }
                }
              }
              >
                <img src={currentActivity.is_locked ? lockedActivity : unlockedActivity} alt="lock-icon" />
              </div>
              </Tooltip>}
            </div>
            <div
              className="apc-controls-area"
              ref={node => (this.outerDivNode = node)}
            >
              {(isSuperAdmin || isUserPartOfCard) && !fromWorkload && (
                <div
                  className="apc-dot-holder"
                  onClick={this.onOptionIconClick}
                >
                  <img src={dotIcon} alt="menu-icon" />
                  {this.state.showOptionIcon && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleClickOutside}
                    >
                      <div className={`apc-options-block ${showArchivePopup ? "with-archive-pop" : ""}`}>
                        {source !== "FROM_KANBAN" && shouldViewInKanban && <div
                          className="apc-options-list"
                          onClick={this.onViewKanban}
                        >
                          View in kanban
                        </div>}
                        <div
                          className="apc-options-list"
                          onClick={
                            setIsPopupEnableEdit
                              ? () => {
                                  setIsPopupEnableEdit();
                                  this.props.onClosePopup();
                                }
                              : () => {
                                  handleClickEditCallback(currentActivity);
                                }
                          }
                        >
                          Edit
                        </div>
                        {this.props.source !== "FROM_ARCHIVE" &&
                          !fromWorkload && isUserPartOfCard &&
                          renderTimerLogic(currentActivity)
                            .timerShouldBeEnabled && (
                            <React.Fragment>
                            <div
                              className="apc-options-list"
                              onClick={this.onShowArchivePopup}
                            >
                              Archive
                            </div>
                            {showArchivePopup && (
                              <div className="deleteflow-box" onClick={(event) => event.stopPropagation()}>
                                <p>Archive this activity?</p>
                                <div className="button">
                                  <span
                                    className="cancel"
                                    onClick={this.onHideArchivePopup}
                                  >
                                    Cancel
                                  </span>
                                  <button
                                    className="save"
                                    onClick={() => {
                                      this.props.onArchiveItem(currentActivity);
                                      this.props.onClosePopup();
                                    }}
                                  >
                                    Archive
                                  </button>
                                </div>
                              </div>
                            )}
                            </React.Fragment>
                          )}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              )}
              {fromWorkload && (
                <div
                  className="apc-dot-holder"
                  onClick={this.onOptionIconClick}
                >
                  <img src={dotIcon} alt="menu-icon" />
                  {this.state.showOptionIcon && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleClickOutside}
                    >
                      <div className={`apc-options-block ${showArchivePopup ? "with-archive-pop" : ""}`}>
                        {shouldViewInKanban && <div
                          className="apc-options-list"
                          onClick={this.onViewKanban}
                        >
                          View in kanban
                        </div>}
                        <div
                          className="apc-options-list"
                          onClick={
                            setIsPopupEnableEdit
                              ? () => {
                                  setIsPopupEnableEdit();
                                  this.props.onClosePopup();
                                }
                              : () => {
                                  handleClickEditCallback(currentActivity);
                                  this.props.onClosePopup();
                                }
                          }
                        >
                          Edit
                        </div>
                        {selectedWorkloadUser &&
                          loggedInUser &&
                          parseInt(selectedWorkloadUser.id) ===
                            parseInt(loggedInUser.id) &&
                          renderTimerLogic(currentActivity)
                            .timerShouldBeEnabled && (
                            <React.Fragment>
                            <div
                              className="apc-options-list"
                              onClick={this.onShowArchivePopup}
                            >
                              Archive
                            </div>
                            {showArchivePopup && (
                              <div className="deleteflow-box" onClick={(event) => event.stopPropagation()}>
                                <p>Archive this activity?</p>
                                <div className="button">
                                  <span
                                    className="cancel"
                                    onClick={this.onHideArchivePopup}
                                  >
                                    Cancel
                                  </span>
                                  <button
                                    className="save"
                                    onClick={() => {
                                      this.props.onArchiveItem(currentActivity);
                                      this.props.onClosePopup();
                                    }}
                                  >
                                    Archive
                                  </button>
                                </div>
                              </div>
                            )}
                            </React.Fragment>
                          )}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              )}

              <div className="apc-close-control" onClick={onClosePopup}>
                <img src={closePopup} alt="close-icon" />
              </div>
            </div>
          </div>
          <div className="activity-dialog-body">
            <div className="title-block">
              {currentActivity.description && <div className="act-inner-overview-holder">
                <div className="opener" onClick={this.onOpenActOverview}>
                  Activity Overview{" "}
                  {this.state.openedOverview ? "-" : <img src={downArrowIcon} alt="arrow-icon" />}
                  </div>
                  {this.state.openedOverview && <div className="overview-text">{currentActivity.description}</div>}
              </div>}
              <div className="proj-title ng-binding">
                Project - {currentActivity.project.name}
              </div>

              {currentActivity.user_id.length > 0 && <div className="timer-wrap">
                <div className="your-wrap">
                <div
                  className="act_due_on_on_target"
                >
                  {selectedWorkloadUser?.id ? `${selectedWorkloadUser.first_name}'s` : "Your"} Time - {logged_in_user_duration?.continuous ?
                    "Continuous"
                    : convertTimeToString(logged_in_user_duration?.total_time)}
                </div>
                <div className="separator">|</div>
                <div
                  className={
                    logged_in_user_duration?.continuous ||
                    currentActivity.timesheets
                    .filter(timesheet => !timesheet.is_archived)
                    .filter(timesheet => timesheet.user_id === parseInt(selectedWorkloadUser?.id || loggedInUser.id))
                    .reduce((acc, time) => {
                      return (acc + time.time_taken);
                    }, 0) <= logged_in_user_duration?.total_time
                      ? "act_due_on_on_target timer-dur"
                      : logged_in_user_duration ? "act_due_on_delayed timer-dur" : "act_due_on_on_target timer-dur"
                  }
                >
                  <span>Time Spent - {convertTimeToString(currentActivity.timesheets
                    .filter(timesheet => !timesheet.is_archived)
                    .filter(timesheet => timesheet.user_id === parseInt(selectedWorkloadUser?.id || loggedInUser.id))
                    .reduce((acc, time) => {
                      return (acc + time.time_taken);
                    }, 0))}</span>
                </div>
                </div>
                <div className="your-wrap">
                <div
                  className="act_due_on_on_target"
                >
                  Total Time - {currentActivity?.continuous_activity ?
                    "Continuous"
                    :
                    convertTimeToString(currentActivity.duration)}
                </div>
                <div className="separator">|</div>
                <div
                  className={
                    currentActivity?.continuous_activity ||
                    currentActivity.timesheets
                    .filter(timesheet => !timesheet.is_archived)
                    .reduce((acc, time) => {
                      return (acc + time.time_taken);
                    }, 0) <= currentActivity.duration
                      ? "act_due_on_on_target timer-dur"
                      : "act_due_on_delayed timer-dur"
                  }
                >
                  <span>Time Spent - {convertTimeToString(currentActivity.timesheets
                    .filter(timesheet => !timesheet.is_archived)
                    .reduce((acc, time) => {
                      return (acc + time.time_taken);
                    }, 0))}</span>
                </div>
                </div>
              </div>}
              {currentActivity.user_id.length > 0 && <div className="timer-wrap due-date">
                <div className={`your-due-date ${moment(
                          moment(logged_in_user_duration?.end_date).format("YYYY-MM-DD")
                        ).isSameOrAfter(moment(Date.now()).format("YYYY-MM-DD")) ||
                        logged_in_user_duration?.continuous
                        ? "" : "delayed"}`}>
                {logged_in_user_duration?.continuous ?
                "No Due Date"
                :
                `Due date - ${moment(logged_in_user_duration?.end_date).format("ddd, DD MMM YY")}`}
                </div>
                <div className={`your-due-date ${moment(
                          moment(currentActivity.due_on).format("YYYY-MM-DD")
                        ).isSameOrAfter(moment(Date.now()).format("YYYY-MM-DD")) ||
                        currentActivity.continuous_activity ? "" : "delayed"}`}>
                Total Duration - {moment(currentActivity.start_on).format("ddd, DD MMM YY")} - {currentActivity.continuous_activity ? "Continuous" : moment(currentActivity.due_on).format("ddd, DD MMM YY")}
                </div>
              </div>}
            </div>
            <div className="user-details-block">
              <div className="user-block">
                {(isSuperAdmin || isUserPartOfCard || fromWorkload) && (
                  <OutsideClickHandler onOutsideClick={this.onCancelAddClick}>
                    <div className="user-add">
                      <img
                        src={plus_blue}
                        alt="Add user"
                        onClick={this.onAddUserClicked}
                        role="button"
                      />

                      <div className="edit-board-ddown activity-popup-ddown">
                        {showSearchBox && (
                          <React.Fragment>
                            <FloatingSearchBox
                              source={activities.fromActivity}
                              img={search_field}
                              onInputChange={e =>
                                this.onSearchInitiated(
                                  e.target.value,
                                  currentActivity
                                )
                              }
                            ></FloatingSearchBox>
                            <div className="search-data-container inside-activity">
                              {renderedUserSuggestionList}
                            </div>
                            <div className="add-multi-member-bottom-holder">
                              <div
                                className="add-multi-member-cancel"
                                role="button"
                                tabindex="0"
                                onClick={this.onCancelAddClick}
                              >
                                Cancel
                              </div>
                              <button
                                className="add-multi-member-confirm"
                                disabled={selectedUser.length === 0}
                                onClick={e =>
                                  this.onAddUser(
                                    e,
                                    currentActivity,
                                    is_priority
                                  )
                                }
                              >
                                {" "}
                                Confirm
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
                {renderUserAvatar}
              </div>

              {source &&
                source !== "FROM_ARCHIVE" &&
                isUserPartOfCard &&
                !this.props.fromNotification && (
                  <div className="timer-block">
                    <Timer
                      activity={currentActivity}
                      getTimerStatusForWorkspaceAction={getTimerStatusForWorkspace}
                      anyTimerStatus={anyTimerStatus}
                      is_priority={is_priority}
                      fromWorkload={fromWorkload}
                      fromKanban={source === "FROM_KANBAN"}
                      timerRunningStatus={timerStatus}
                      startTimerAction={startTimer}
                      shouldEnablePopup={this.shouldEnablePopup}
                      onShowCannotStartPopup={this.onShowCannotStartPopup}
                      tabActiveFlag={this.state.tabActiveFlag}
                    ></Timer>

                    {/* finish */}

                    {isPopupEnable && (
                      <DialogWrapper dialogProps={dialogProps}>
                        <TimeSheetDescription
                          selectedProject={currentActivity.project_id || currentActivity.project.id}
                          timesheet={currentActivity.timesheets}
                          stopTimerAction={stopTimer}
                          shouldEnablePopup={this.shouldEnablePopup}
                          createTimerAction={createTimer}
                          changeTimerStatus={changeTimerStatus}
                          fetchTimesheetAction={fetchTimesheet}
                        />
                      </DialogWrapper>
                    )}
                    {showCannotStartPopup && (
                      <DialogWrapper dialogProps={dialogPropsCannotStart}>
                        <TimerCannotStartPopup
                          onClosePopup={this.onHideCannotStartPopup}
                          errorObj={errorObj}
                          onCloseActivityInner={this.props.onClosePopup}
                          onWorkspaceNavBarClicked={onWorkspaceNavBarClicked}
                        />
                      </DialogWrapper>
                    )}
                    {showCannotPrioritizePopup && (
                      <DialogWrapper dialogProps={dialogPropsCannotPrioritize}>
                        <TimerCannotStartPopup
                          onClosePopup={this.onHideCannotPrioritizePopup}
                          errorObj={errorObj}
                        />
                      </DialogWrapper>
                    )}
                    {showCannotRemovePopup && (
                      <DialogWrapper dialogProps={dialogPropsCannotRemove}>
                        <UserCannotRemovePopup
                          userToRemove={showCannotRemovePopup}
                          loggedInUser={loggedInUser}
                          onClosePopup={this.onCloseCannotRemovePopup} />
                      </DialogWrapper>
                    )}
                  </div>
                )}
              {/*  */}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = state => {
  return {
    prioritizedActivity: state.activityReducer.prioritiZedActivity.list,
    projectList: state.projectReducer.listOfProjects.list,
    unPrioritizedActivity: state.activityReducer.listOfActivity.list,
    selectedWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    loggedInUser: state.loginReducer.loginAuth.user_details,
    workspaceUser: state.peopleReducer.workspaceusers,
    anyTimerStatus: state.commonReducer.timerStatus,
    timerStatus: state.commonReducer.timerIsRunning
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        user_id,
        data,
        project,
        version_id,
        is_archived,
        is_priority,
        performed_by,
        is_locked
      },
      callback
    ) => {
      dispatch(
        updateActivityAction(
          {
            id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        user_id,
        data,
        project,
        version_id,
        is_archived,
        is_priority,
        performed_by,
        is_locked
          },
          callback
        )
      );
    },
    updateKanbanActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        description,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        tags,
        data,
        title,
        user_id,
        version_id,
        project,
        is_priority,
        is_locked
      },
      callback
    ) => {
      dispatch(
        updateKanbanActivityAction(
          {
            id,
            due_on,
            start_on,
            version_id,
            duration,
            hours,
            data,
            minutes,
            project_id,
            description,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            tags,
            title,
            user_id,
            project,
            is_priority,
            is_locked
          },
          callback
        )
      );
    },
    updateArchiveActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        user_id,
        project,
        version_id,
        data,
        is_archived,
        is_priority,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        is_locked
      },
      callback
    ) => {
      dispatch(
        updateArchiveActivityAction(
          {
            id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        user_id,
        project,
        version_id,
        data,
        is_archived,
        is_priority,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        is_locked
          },
          callback
        )
      );
    },
    startTimer: (
      { activity_id, data, date, user_id, customer_auth_token },
      callback
    ) => {
      dispatch(
        startTimerAction(
          { activity_id, data, date, user_id, customer_auth_token },
          callback
        )
      );
    },
    stopTimer: (
      {
        activity_id,
        data,
        time_taken,
        tags,
        description,
        attachments,
        id,
        date,
        user_id,
        customer_auth_token
      },
      formData,
      callback
    ) => {
      dispatch(
        stopTimerAction(
          {
            activity_id,
            data,
            time_taken,
            tags,
            description,
            attachments,
            id,
            date,
            user_id,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    },
    createTimer: (
      {
        tags,
        attachments,
        activity_id,
        data,
        time_taken,
        description,
        date,
        user_id,
        customer_auth_token
      },
      formData,
      callback
    ) => {
      dispatch(
        createTimerAction(
          {
            tags,
            attachments,
            activity_id,
            data,
            time_taken,
            description,
            date,
            user_id,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    },
    getTimerStatusForWorkspace({ auth_token }, callback) {
      dispatch(
        getTimerStatusForWorkspaceAction({ auth_token }, callback)
      );
    },
    changeTimerStatus: timerRunningStatus => {
      dispatch(changeTimerStatus(timerRunningStatus));
    },
    fetchTimesheet: ({ from_date, to_date, user_id }, callback) => {
      dispatch(fetchTimesheetAction({ from_date, to_date, user_id }, callback));
    },
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(updateProjectAction({ auth_token, queryParam, body }, callback));
    },
    updateWorkloadActivity: (
      { activityObj, user_id, activity_id, fields },
      callback
    ) => {
      dispatch(
        updateWorkloadActivityAction(
          { activityObj, user_id, activity_id, fields },
          callback
        )
      );
    },
    addActivityToReducer: (activity) => {
      dispatch(addActivityToReducerAction(activity))
    },
    removeActivityFromReducer: (activity) => {
      dispatch(removeActivityFromReducerAction(activity))
    },
    changeNoItemStatusAction: ({ showStatus }) =>
      dispatch(changeNoItemStatusAction({ showStatus })),
    updateAllActivity : (activity_id) =>{
        dispatch(updateAllActivityAction({ activity_id }))
      } ,
    removeBacklogActivities:(activityId,projectId)=>{
      dispatch(removeBacklogActivitiesAction({activityId,projectId}))
    },
    addBacklogActivities:(activity,projectId)=>{
      dispatch(addBacklogActivitiesAction({activity,projectId}))
    }  
  };
    
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPopupTop);
