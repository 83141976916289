import { combineReducers } from "redux";
import rrfStatereducer from "../reducers/rrfstatecombiner";
import loginReducer from "../reducers/login/login.js";
import wrorkSpaceReducer from "../reducers/workspace/workspace.js";
import projectReducer from "../reducers/project/project.js";
import commonReducer from "../reducers/common/commonreducer";
import activityReducer from "../reducers/activities/activitiesreducer";
import peopleReducer from "../reducers/people/people";
import commentsReducer from "./comments/commentsreducer";
import checklistReducer from "../reducers/checklist/checklist";
import attachmentsReducer from "../reducers/attachments/attachments";
import notificationsReducer from "../reducers/notifications/notification";
import timeSheetReducer from "../reducers/timesheet/timesheet";
import archivedReducer from "./archived/archived";
import workloadReducer from "./workload/workload";
import billingReducer from "./billing/billing";
import dialogReducer from "../reducers/dialogreducer/dialogreducer";
import dashboardReducer from "../reducers/dashboard/dashboard";

export default combineReducers({
  rrfStatereducer,
  loginReducer,
  wrorkSpaceReducer,
  projectReducer,
  commonReducer,
  activityReducer,
  commentsReducer,
  peopleReducer,
  checklistReducer,
  attachmentsReducer,
  notificationsReducer,
  timeSheetReducer,
  archivedReducer,
  workloadReducer,
  billingReducer,
  dialogReducer,
  dashboardReducer
});
