import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";

export const getProjectDetails = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/project";
  let queryParams = queryParam ? queryParam : "";
  //console.log(API_URL + url + queryParams);
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getProjectWidgets = (
  { auth_token = null },
  callback
) => {
  let url = "/api/v1/project-widgets";
  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export function createProject(body, callback) {
  let url = API_URL + "/api/v1/project";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
}

export const updateProjectDetails = ({ auth_token, body, user_id = parseInt(localStorage.getItem("id")) }, callback) => {
  let url = "/api/v1/project";

  secureFetch(API_URL + url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (routesHelper.getCustomerAuthToken() || auth_token)
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    }),
    body: JSON.stringify({ ...body, user_id })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getProjectById = (archived,projectId,
  callback
) => {
  let url = "/api/v1/project";
  let queryParams = `?archived=${archived}&id=${projectId}` ;
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const requestToJoinProject = (
  { auth_token = null, body },
  callback
) => {
  let url = "/api/v1/request-project-access";
  secureFetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getUpdatedProjects = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/updated-projects";
  let queryParams = queryParam ? queryParam : "";
  //console.log(API_URL + url + queryParams);
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

