import React, { useState, useRef, memo } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import arrowDown from "../../styles/img/svg/report-arrow-down.svg";
import arrowUp from "../../styles/img/svg/arrow_up.svg";
import userSearch from "../../styles/img/svg/user-search.svg";


const ActivityTypeDropDown = ({
    activityTypeList,
    selectedActivityType,
    onSelectactivityType
  }) => {
    const projectDropdownNode = useRef();
    const [showDropDown, setShowDropDown] = useState(false);
    const [updatedActivity, setUpdatedActivity] = useState(null);

  
    const onShowDropDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowDropDown(!showDropDown);
    };
  
    const onOutsideClickDropDown = (e) => {
      if (
        projectDropdownNode &&
        !projectDropdownNode.current.contains(e.target)
      ) {
        if (showDropDown){
          setShowDropDown(false);
          setUpdatedActivity(null);
        } 
      }
    };

    const onChangeSearch = event => {
      let searchText = event.target.value;
      let updatedWorkSpaceUsers = activityTypeList.filter(
        activityname =>
        activityname
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1 
      );
      setUpdatedActivity(updatedWorkSpaceUsers);
    };

    let activityData=updatedActivity || activityTypeList;
  
    return (
      <div className="comm-filter-control-holder" ref={projectDropdownNode}>
        <div className="project-holder" onClick={onShowDropDown}>
          <span>{selectedActivityType}</span>
          <img src={showDropDown ? arrowUp : arrowDown} alt="arrow" />
        </div>
        {showDropDown && (
          <OutsideClickHandler onOutsideClick={onOutsideClickDropDown}>
            <div className="member-dropdown">
              <div className="demo-select-header">
                <div className="user-search">
                  <img src={userSearch} alt="search" />
                </div>
                <input
                  type="search"
                  className="demo-header-searchbox md-text"
                  placeholder="Search for a Project"
                  onChange={onChangeSearch}
                />
              </div>
              <div className="member-outside">
                {activityData.map((activityType, index) => (
                  <div
                    key={index}
                    className={`activity-type-data ${activityType === selectedActivityType &&
                      "active-activityType"}`}
                    onClick={(event) =>{
                      onSelectactivityType(event, activityType)
                      setShowDropDown(false);
                      setUpdatedActivity(null);
                    }}
                  >
                    {activityType}
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    );
  };
  
  export default memo(ActivityTypeDropDown);