export const notificationsConstants = {
  getGlobalNotifications: "GET_GLOBAL_NOTIFICATIONS",
  getSystemNotifications: "GET_SYSTEM_NOTIFICATION",
  getAllNotificationsLoaderState: "GET_ALL_NOTIFICATIONS_LOADER_STATE",
  getYourNotificationsLoaderState: "GET_YOUR_NOTIFICATIONS_LOADER_STATE",
  listOfFlaggedYouNotificationLoaderState:
    "LIST OF_FLAGGED_YOU_NOTIFICATION_LOADER_STATE",
  listOfFlaggedAllNotificationLoaderState:
    "LIST OF_FLAGGED_ALL_NOTIFICATION_LOADER_STATE",
  replyToNotificationLoaderState: "REPLY_TO_NOTIFICATION_LOADER_STATE",

  pushNewYouNotification: "PUSH_NEW_NOTIFICATION_YOU",
  pushNewAllNotification: "PUSH_NEW_NOTIFICATION_ALL",
  getAllNotifications: "GET_ALL_NOTIFICATIONS",
  getYourNotifications: "GET_YOUR_NOTIFICATIONS",

  changeNewStatusOfSystemNotification: "CHANGE_NEW_STATUS_OF_SYSTEM_NOTIFICATION",
  changeNewStatusOfNotification: "CHANGE_NEW_STATUS_OF_NOTIFICATION",

  changeReadStatusOfYouNotification: "CHANGE_READ_STATUS_OF_YOU_NOTIFICATION",
  changeFlagStatusOfYouNotification: "CHANGE_FLAG_STATUS_OF_YOU_NOTIFICATION",

  changeReadStatusOfAllNotification: "CHANGE_READ_STATUS_OF_ALL_NOTIFICATION",
  changeFlagStatusOfAllNotification: "CHANGE_FLAG_STATUS_OF_All_NOTIFICATION",

  markAllNotificationAsReadOfYouTab: "MARK_ALL_NOTIFICATION_AS_READ_OF_YOU_TAB",
  markAllNotificationAsReadOfAllTab: "MARK_ALL_NOTIFICATION_AS_READ_OF_ALL_TAB",

  archiveSystemNotification: "ARCHIVE_SYSTEM_NOTIFICATION",
  archiveRequestSystemNotification: "ARCHIVE_REQUEST_SYSTEM_NOTIFICATION",

  replyInYouNotification: "REPLY_IN_YOU_NOTIFICATION",
  replyInAllNotification: "REPLY_IN_ALL_NOTIFICATION",

  searchYouNotification: "SEARCH_YOU_NOTIFICATION",
  searchAllNotification: "SEARCH_ALL_NOTIFICATION",

  searchAllNotificationsLoaderState: "SEARCH_ALL_NOTIFICATIONS_LOADER_STATE",
  searchYourNotificationsLoaderState: "SEARCH_YOUR_NOTIFICATIONS_LOADER_STATE",
  removeNotification: "REMOVE_NOTIFICATION",

  flaggedNotificationOfAllTab: "FLAGGED_NOTIFICATION_OF_ALL_TAB",
  flaggedNotificationOfYouTab: "FLAGGED_NOTIFICATION_OF_YOU_TAB",
  readNotificationOfAllTab: "READ_NOTIFICATION_OF_ALL_TAB",
  readNotificationOfYouTab: "READ_NOTIFICATION_OF_YOU_TAB",
  updateNoItemStatus: "UPDATE_NO_ITEM_STATUS",
  updateNotificationFlag: "UPDATE_NOTIFICATION_FLAG",
  updateNotificationRead: "UPDATE_NOTIFICATION_READ",
  updateNotificationSearchFlag: "UPDATE_NOTIFICATION_SEARCH_FLAG"

};
