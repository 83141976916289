import React, { useState, useContext } from "react";
import searchField from "../../styles/img/svg/search-field.svg";
import removeUser from "../../styles/img/svg/remove-user-icon.svg";
import KanbanContext from "../../contexts/kanban/kabanContext";
import Avatar from "react-avatar";
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { getIsCreator, getIsSuperAdmin } from "../../helpers/reportlanding";
import { getProjectById } from "../../services/projectapi";
momentDurationFormatSetup(moment);

const KanbanUserListDropdown = props => {
  const { userList, setUsersInProject, peopleHavingProjectEditAccess } = props;
  const [filteredUsers, setFilteredUsers] = useState([...userList]);
  const { selectedProject, updateProjectAction, loggedinUser, selectedCustomer } = useContext(
    KanbanContext
  );
  const searchUser = searchText => {
    let users = [...userList];
    users = _.filter(users, user =>
      user.first_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUsers(users);
  };

  const removeUserFromProject = id => {
    getProjectById(selectedProject.archived, selectedProject.id, response => {
      let projectData = response?.length > 0 ? { ...response[0].data } : { ...selectedProject.data };
      projectData.acl = _.filter(projectData.acl, userId => userId !== id);
      if (
        projectData.settings &&
        projectData.settings.member_access &&
        projectData.settings.member_access[id]
      ) {
        delete projectData.settings.member_access[id];
      }
      let projectToUpdate = {
        id: selectedProject.id,
        data: projectData,
        updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      };
      const auth_token = localStorage.getItem("auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: projectToUpdate },
        response => {
          if (response && response.id) {
            let remainingUsers = _.filter(
              userList,
              user => parseInt(user.id) !== parseInt(id)
            );
            setUsersInProject(remainingUsers);
            setFilteredUsers(remainingUsers);
          }
        }
      );
    });
  };

  const ShouldShowRemoveButton = getIsCreator(selectedCustomer.selectedWorkspace)
                                  || getIsSuperAdmin(loggedinUser, selectedCustomer.selectedWorkspace)

  return (
    <div className="users_flow usersin-list-container">
      <div className="edit-board-search-holder">
        <input
          type="text"
          className="edit-board-search-control kanban list"
          placeholder="Search for a User"
          // role="textbox"
          aria-label="Search"
          autoFocus
          onKeyUp={e => searchUser(e.target.value)}
        />
        <div className="edit-search-icon-holder">
          <img src={searchField} alt="search" />
        </div>
      </div>
      <div className="search-data-container inside-activity proj-user-container usersin-list kanban">
        {filteredUsers.length > 0 &&
          _.sortBy(filteredUsers, (user) => user.first_name.toLowerCase()).map(user => (
            <div className="search-data-list" key={user.id}>
              <div className="user-img-icon">
                {user.img ? <img src={user.img} alt="user-img" /> : <Avatar
                    style={{ width: "100%", height: "100%" }}
                    name={user.first_name}
                    maxInitials={1}
                  ></Avatar>}
              </div>
              <div className="user-details-holder">
                <div className="user-name trun-flow kanban">
                  {" "}
                  {user.first_name}{" "}
                </div>
              </div>
              {peopleHavingProjectEditAccess.indexOf(
                parseInt(loggedinUser.id)
              ) !== -1 &&
                (ShouldShowRemoveButton || (
                  selectedProject.data.settings.member_access[parseInt(user.id)] &&
                  selectedProject.data.settings.member_access[parseInt(user.id)].access === "Member"
                  )
                ) &&
                parseInt(loggedinUser.id) !== parseInt(user.id) && (
                  <div
                    className="kanban-remove-user"
                    onClick={() => removeUserFromProject(user.id)}
                  >
                    <img src={removeUser} alt="remove" />
                  </div>
                )}
            </div>
          ))}
        {filteredUsers.length < 1 && (
          <div className="board-noresults projectflow-results-users">
            {" "}
            No results found
          </div>
        )}
      </div>
    </div>
  );
};

export default KanbanUserListDropdown;
