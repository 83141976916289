import React from "react";
import BacklogActivity from './backlogProjectActivity';

const BacklogProjectGroup=(props)=>{
   

    let activityGroup=props.eachProject.activityList.map(activityData=><BacklogActivity activityData={activityData} showActivityPopup={props.showActivityPopup} />)

    return (
        <div className="workload-project-group">
            <div >
                <span className="workload-project-group-display">{props.eachProject.projectname}</span>
            </div> 
            <div className="workload-project-group-activity">
                {activityGroup}
            </div>       
        </div>
    )

}

export default BacklogProjectGroup;