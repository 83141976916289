import { attachmentsConstants } from "../../constants/attachmentconstants";

const initialState = {
  uploadAttachmentLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  updateLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },
  listOfAttachment: {
    list: [],
    key: null
  }
};

export default function attachmentsReducer(state = initialState, action) {
  switch (action.type) {
    case attachmentsConstants.getAllAttachmentsLoaderState:
      return {
        ...state,
        updateLoaderState: action.payload
      };
    case attachmentsConstants.addNewAttachmentLoaderState:
      return {
        ...state,
        uploadAttachmentLoaderState: action.payload
      };
    case attachmentsConstants.getAllAttachments:
      return {
        ...state,
        listOfAttachment: {
          list: action.payload.list,
          key: action.payload.key
        }
      };
    case attachmentsConstants.updateAttachment:
      return {
        ...state,
        listOfAttachment: {
          list: state.listOfAttachment.list.filter(
            attachment => attachment.id !== action.payload.list.id
          ),
          key: action.payload.key
        }
      };
    case attachmentsConstants.addNewAttachment:
      return {
        ...state,
        listOfAttachment: {
          list: [action.payload.list, ...state.listOfAttachment.list],
          key: action.payload.key
        }
      };
    default:
      return {
        ...state
      };
  }
}
