import React, { PureComponent } from "react";
import { GoogleLogin } from "react-google-login";
import routesHelper from "../../router/routeshelper";
import GoogleSignup from "../../styles/img/svg/google-icon-in-btn.svg";
import { isGoogleSignIn } from "../../helpers/loginconstants";
import { updateUserFirstTimeFlagAction } from "../../actions/common/commonactions";
import firebase from "../../firebase";

class GoogleRegistration extends PureComponent {
  componentDidMount() {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: routesHelper.getGoogleDevApiKey(),
        callback: this.handleCredentialResponse,
        context: "signup",
        // cancel_on_tap_outside: false
      });
      window.google.accounts.id.prompt();
    }
  }

  responseGoogle = (response) => {
    // console.log(response);
    this.onGoogleRegister({ payload: response });
  };

  onGoogleRegister = ({ payload = {} }) => {
    firebase.analytics().logEvent("GTD_Google_register");
    const {
      googleAuthenticationAction,
      isValidInvite,
      acceptInviteAction,
      router,
      getAllCustomersAction,
      onShowAlreadyEmailPopup,
    } = this.props;
    if (payload.accessToken) {
      const body = {
        code: payload.accessToken,
        clientId: routesHelper.getGoogleDevApiKey(),
        redirectUri: routesHelper.getBaseUrl(),
        type: "register",
      };
      if (payload.access_type) body.access_type = payload.access_type;
      googleAuthenticationAction({ ...body }, (resposne) => {
        if (resposne && resposne.id) {
          if (resposne.first_time_logged_in)
            firebase.analytics().logEvent("GTD_Google_register_success");
          else firebase.analytics().logEvent("GTD_Google_login_success");
          // if (
          //   router.location.query.token &&
          //   resposne.auth_token &&
          //   isValidInvite
          //   // resposne.is_new_user
          // ) {
          //   acceptInviteAction(
          //     {
          //       token: router.location.query.token,
          //       auth_token: resposne.auth_token
          //     },
          //     res => {
          //       if (
          //         res &&
          //         (res.detail.includes("Invitation accepted successfully") ||
          //           res.detail.includes(
          //             "You are already member of this workspace"
          //           ))
          //       ) {
          //         for (const key in resposne) {
          //           if (resposne.hasOwnProperty(key)) {
          //             const element = resposne[key];
          //             if (key === "data") {
          //               localStorage.setItem(key, JSON.stringify(element));
          //             } else if(key === "auth_token") {
          //               for (const k in resposne.auth_token) {
          //                 const ele = resposne.auth_token[k];
          //                 localStorage.setItem(k, ele);
          //               }
          //             } else {
          //               localStorage.setItem(key, element);
          //             }
          //           }
          //         }
          //         localStorage.setItem(isGoogleSignIn, true);
          //         if(resposne.first_time_logged_in){
          //           updateUserFirstTimeFlagAction({auth_token:resposne.auth_token,id:resposne.id,first_time_logged_in:false})
          //           localStorage.setItem("firsttimeprofile", true);
          //           return router.push(routesHelper.welcomePath());
          //         }else{
          //           router.push(routesHelper.getRootPath());
          //         }
          //       }
          //     }
          //   );
          // } else {
          for (const key in resposne) {
            if (resposne.hasOwnProperty(key)) {
              const element = resposne[key];
              if (key === "data") {
                localStorage.setItem(key, JSON.stringify(element));
              } else if (key === "auth_token") {
                for (const k in resposne.auth_token) {
                  const ele = resposne.auth_token[k];
                  localStorage.setItem(k, ele);
                }
              } else {
                localStorage.setItem(key, element);
              }
            }
          }
          localStorage.setItem(isGoogleSignIn, true);
          const auth_token = resposne.auth_token;
          getAllCustomersAction({ auth_token }, (workspaces) => {
            if (workspaces && !workspaces.stdErr) {
              if (workspaces.length > 0) {
                localStorage.setItem("firsttimeprofile", false);
                router.push(routesHelper.getRootPath());
              } else {
                localStorage.setItem("firsttimeprofile", true);
                router.push(routesHelper.getFinishProfile());
              }
              updateUserFirstTimeFlagAction({
                auth_token: resposne.auth_token,
                id: resposne.id,
                first_time_logged_in: false,
              });
            }
          });
          // }
        } else if (resposne?.error) {
          onShowAlreadyEmailPopup();
        }
      });
    }
  };

  onGoogleSignUp = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: routesHelper.getGoogleDevApiKey(),
      scope: "openid profile email",
      ux_mode: "popup",
      callback: (response) => {
        this.responseGoogle({
          ...response,
          accessToken: response.access_token,
        });
      },
    });
    client.requestAccessToken();
  };

  handleCredentialResponse = (response) => {
    this.responseGoogle({
      ...response,
      accessToken: response.credential,
      access_type: "jwt",
    });
  };

  render() {
    return (
      <div className="goog" onClick={this.onGoogleSignUp}>
        <img src={GoogleSignup} alt="Google Signin" />
        <p className="or_sign">Register with Google </p>
      </div>
      // <GoogleLogin
      //   clientId={routesHelper.getGoogleDevApiKey()}
      //   render={renderProps => (
      //     <div className="goog" onClick={renderProps.onClick}>
      //       <img src={GoogleSignup} alt="Google Signin" />
      //       <p className="or_sign">Register with Google </p>
      //     </div>
      //   )}
      //   buttonText="Register"
      //   onSuccess={this.responseGoogle}
      //   onFailure={this.responseGoogle}
      //   cookiePolicy={"single_host_origin"}
      //   prompt="consent"
      // />
    );
  }
}
export default GoogleRegistration;
