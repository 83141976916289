import React, { memo, useState } from "react";
//importing Icons
import collapseArrowLogo from "../../styles/img/svg/navBar-arrow.svg";
import addIconSvg from "../../styles/img/svg/add-icon-af.svg";
import firebase from "../../firebase";
import BacklogProjectGroup from "./backlogProjectGroup";
function BacklogProject({
  onShowBacklogActivityPopup,
  onBacklogCollapsed,
  searchText,
  searchType,
  listOfBacklogActivites,
  showActivityPopup,
}) {
  const [isCollapsed, setisCollapsed] = useState(false);

  let projecGroup = listOfBacklogActivites.map((eachProject, index) => (
    <BacklogProjectGroup
      eachProject={eachProject}
      key={index}
      showActivityPopup={showActivityPopup}
    />
  ));

  return (
    <div
      className={
        isCollapsed
          ? "activity-backlog-section is-collapsed"
          : "activity-backlog-section"
      }
    >
      <div className="activity-backlog-section-header">
        {!isCollapsed && <span>Activity Backlog</span>}

        <img
          src={collapseArrowLogo}
          onClick={() => {
            firebase
              .analytics()
              .logEvent("GTD_Workload_Activity_Backlog_Toggle");
            setisCollapsed(!isCollapsed);
            onBacklogCollapsed();
          }}
          alt="For Collapsing the img"
        />
      </div>
      <div className="activity-backlog-section-body">
        {!isCollapsed && (
          <div className="add-activity-button">
            <button onClick={onShowBacklogActivityPopup}>
              <img src={addIconSvg} alt="add-icon" /> <span>Add Activity</span>
            </button>
          </div>
        )}
        {!isCollapsed && (
          <div className="project0backlog-group-display">{projecGroup}</div>
        )}
      </div>
    </div>
  );
}

export default memo(BacklogProject);
