import { useDrop } from 'react-dnd';
import React, { memo } from "react";
import { DragTypes } from "../../constants/DragTypes";


const  CheckListHelper=(props)=>{

  const [{ hover }, drop] = useDrop({
    accept: DragTypes.CHECKLISTITEM,
    collect: (monitor) => {
      return {
        isTargetDragging: monitor.isOver(),
        didDrop: monitor.didDrop(),
        hover: monitor.isOver(),
      };
    },

    drop(item, _monitor) {
      item.checkDIdDrop = true;
      onChecklistItemRearranged(0, 0, item);
    },
  });

  let style = { opacity: 0, height: "15px" };

  const onChecklistItemRearranged = (newIndex, _oldIndex, item) => {
    let position = newIndex;
    
    const updatedCheckListItem = {
      checklist_id: props.checklistId,
      created_by: item.created_by,
      id: item.id,
      is_archived: false,
      is_completed: item.is_completed,
      position,
      title: item.title,
    };

    props.updateChecklistItemAction(updatedCheckListItem, (_res) => {
      props.onMoveOfItemToAnotherList(0,0, item);
    });
  };

  if (hover) {
    style = { opacity: 0, height: "50px" };
  }
  return <div ref={drop} style={style} key={new Date()}></div>;
};

export default memo(CheckListHelper);
