export const activities = {
  active: "Active",
  archived: "Archived",
  activity: "ACTIVITY",
  completed: "COMPLETED",
  archive: "ARCHIVE",
  unArchive: "UNARCHIVE",
  prioritize: "PRIORITIZE",
  dePrioritize: "DEPRIORITIZE",
  updateUnarchive: "UPDATE_UNARCHIVE",
  updateUnarchiveActivity: "UPDATE_UNARCHIVE_ACTIVITY",
  changeWorkFLow: "CHANGE_WORKFLOW",
  changeWorkFLowPriority: "CHANGE_WORKFLOW_PRIORITY",
  updateTimesheet: "UPDATE_TIMESHEET",
  createTimesheet: "CREATE_TIMESHEET",
  udateChecklistCount: {
    PRIORITY: "UPDATE_CHECKLISTS_COUNT_PRIORITIZED",
    NOTPRIORITY: "UPDATE_CHECKLISTS_COUNT_DEPRIORITIZED",
    PRIORITY_PASSED_COUNT: "UPDATE_CHECKLISTS_COUNT_PRIORITIZED_PASSED_COUNT",
    NOTPRIORITY_PASSED_COUNT: "UPDATE_CHECKLISTS_COUNT_DEPRIORITIZED_PASSED_COUNT"
  },
  updateChecklistCountAfterAddingInArchivePage:"UPDATE_CHECKLIST_COUNT_AFTER_ADDING_IN_ARCHIVE_PAGE",
  updateChecklistCountAfterArchive: {
    PRIORITY: "UPDATE_PRIORITIZED_CHECKLISTS_COUNT_AFTER_ARCHIVE",
    NOTPRIORITY: "UPDATE_DEPRIORITIZED_CHECKLISTS_COUNT_AFTER_ARCHIVE"
  },
  updateArchiveCardChecklistCountAfterArchive: "UPDATE_ARCHIVE_CARD_CHECKLIST_COUNT_AFTER_ARCHIVE",

  updateAttachmentCount: {
    PRIORITY: "UPDATE_ATTACHMENTS_COUNT_PRIORITIZED",
    NOTPRIORITY: "UPDATE_ATTACHMENTS_COUNT_DEPRIORITIZED"
  },
  updateArchiveCardAttachmentCount:"UPDATE_ARCHIVE_CARD_ATTACHMENT_COUNT",
  updateArchiveCardAttachmentCountAfterDelete: "UPDATE_ARCHIVE_CARD_ATTACHMENT_COUNT_AFTER_DELETE",
  updateAttachmentCountAfterDelete: {
    PRIORITY: "UPDATE_PRIORITIZED_ATTACHMENTS_COUNT_AFTER_DELETE",
    NOTPRIORITY: "UPDATE_DEPRIORITIZED_ATTACHMENTS_COUNT_AFTER_DELETE"
  },
  updateCommentsCount: {
    PRIORITY: "UPDATE_COMMENTS_COUNT_PRIORITIZED",
    NOTPRIORITY: "UPDATE_COMMENTS_COUNT_DEPRIORITIZED"
  },
  updateArchivedCommentsCount: "UPDATE_ARCHIVED_COMMENTS_COUNT",
  updateArchivedCommentsCountForDelete:
    "UPDATE_ARCHIVED_COMMENTS_COUNT_FOR_DELETE",

  updateDeleteCommentsCount: {
    PRIORITY: "UPDATE_DELETE_COMMENTS_COUNT_PRIORITIZED",
    NOTPRIORITY: "UPDATE_DELETE_COMMENTS_COUNT_DEPRIORITIZED"
  },
  add: {
    activity: {
      priority: "ADD_ACTIVITY_PRIORITY",
      notPriority: "ADD_ACTIVITY",
      completed: "ADD_COMPLETED_ACTIVITY"
    },
    project: "ADD_PROJECT",
    client: "ADD_CLIENT",
    user: "ADD_USER"
  },
  remove: {
    activity: "REMOVE_ACTIVITY"
  },
  edit: {
    activity: {
      priority: "EDIT_ACTIVITY_PRIORITY",
      notPriority: "EDIT_ACTIVITY",
      kanbanActivity: "EDIT_KANBAN_ACTIVITY"
    }
  },
  customerAuthToken: "customer_auth_token",
  customerId: "customer_id",
  customerDisplayName: "customer_display_name",
  customerName: "customer_name",
  customerImage: "customer_img",
  activeActivityTab: "activeActivityTab",
  breadCrumb: [
    { name: "Activities", link: "", type: "heading" },
    // {name: "Active" , link: 'activity/active', type: 'tabs' , className:'active-tab'},
    // {name: "Archived", link: 'activity/archived', type: 'tabs',  className:''},
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon",
      SearchClassName: "general-page-search-box-parent"
    }
  ],
  activitiesByProjectLoaderState: "ACTIVITIES_BY_PROJECT_LOADER_STATE",
  getActivitiesByProject: "GET_ACTIVITIES_BY_PROJECT",
  editActivityFromWorkload: "EDIT_ACTIVITY_FROM_WORKLOAD",
  fromActivity: "FROM_ACTIVITY",
  UPDATEALLACTIVITIES:"UPDATE_ALL_ACTIVITY",
  UPDATE_PRIORITY_NOTPRIORITY:"UPDATE_PRIORITY_NOTPRIORITY",
  CLEAR_PRIORITY_NOTPRIORITY:"CLEAR_PRIORITY_NOTPRIORITY"
};
