import React, {Component} from 'react';
import {Editor,EditorState,Modifier,convertToRaw,RichUtils} from "draft-js";

import Avatar from "react-avatar";
import bold from '../../styles/img/svg/bold.svg';
import underline from '../../styles/img/svg/underline.svg';
import italic from '../../styles/img/svg/italic.svg';
import orderlist from '../../styles/img/svg/orderlist.svg';
import numberlist from '../../styles/img/svg/numberlist.svg';
import { connect } from "react-redux";
import * as Immutable from 'immutable';


const INSERT_ACTION_LABEL = `insert-suggestion`
const PREFIX = '@'
const { OrderedSet } = Immutable;


/* ----------*
* Utils
------------*/

function getSelectionRange () {
  const selection = window.getSelection()

  if (selection.rangeCount === 0  || selection.bas)
    return null
  return selection.getRangeAt(0)
}



function getTriggerRange (term) {
  const range = getSelectionRange()
  const text  = range && range.startContainer.textContent.substring(0, range.startOffset)
  if (!text || /\s+$/.test(text))
    return null

  const start = text.lastIndexOf(term)

  if (start === -1  || text === 'Add a comment. To mention someone type @')
    return null

  const end = range.startOffset
  return {
    end,
    start,
    text:  text.substring(start),
  }
}


function getInsertRange (_activeSuggestion, editorState) {
  const selection = editorState.getSelection()
  const content   = editorState.getCurrentContent()
  const anchorKey = selection.getAnchorKey()
  const end       = selection.getAnchorOffset()
  const block     = content.getBlockForKey(anchorKey)
  const text      = block.getText()
  const start     = text.substring(0, end).lastIndexOf(PREFIX)

  return {
    start,
    end,
  }
}

const CaretCoordinates = {
  x: 0,
  y: 0,
}

function getCaretCoordinates (scrollHeightData) {

  const range = getSelectionRange()
  if (range) {
    let { left: x, top: y }  = range.getBoundingClientRect()
    if(scrollHeightData<65){
      y=y-400
    }else{
      y=y-400+scrollHeightData;
    }
    Object.assign(CaretCoordinates, { x, y })
  }
  return CaretCoordinates
}

/* ----------*
* Modifiers
------------*/

const addSuggestion = (editorState, activeSuggestion, content) => {
  const { start, end }   = getInsertRange(activeSuggestion, editorState)
  const contentState     = editorState.getCurrentContent()
  const currentSelection = editorState.getSelection()
  const selection        = currentSelection.merge({
    anchorOffset: start,
    focusOffset: end,
  })
  
  const contentStateWithEntity = contentState.createEntity(
    'SUGGESTION', 'IMMUTABLE', { content:content })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  let inlineStyle=[];

  let  currentStyle = editorState.getCurrentInlineStyle();


  if(currentStyle.has('BOLD')){
    inlineStyle.push('BOLD');
  }
  if(currentStyle.has('UNDERLINE')){
    inlineStyle.push('UNDERLINE');
  }
  if(currentStyle.has('ITALIC')){
    inlineStyle.push('ITALIC');
  }

  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    `${PREFIX}${content}${' '}`,
    OrderedSet.of(...inlineStyle),
    entityKey)
  
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    INSERT_ACTION_LABEL)
  
  return EditorState.moveFocusToEnd(newEditorState)
}




class Suggestions extends Component {
  
  getMatchingSuggestions () {

    let text = this.props.searchText
    if (typeof text !== 'string')
      return null

    const term = text.toLowerCase()
    const filter = item =>  {
      let query = item.username.substring(0, term.length).toLowerCase()
      return query === term
    }

    let results = this.props.dataForMentions.filter(filter)
    if (results.length === 1 && (results[0].length === term.length))
      return null
   return results;
  }
  
  get position () {
    const co = this.props.position
    if (!co)
      return {}
    return {
      top:  co.y,
      left: co.x,
    }
  }
  
  render () {
    const entries = this.getMatchingSuggestions()
    return entries && entries.length>0 && 
      <nav 
        style={ this.position }
        className='overlay suggestions'>
            
        <div className='suggestions-list'>
      
        <div className="allnotify ">
             @act will notify all members in the activity
             </div>
          { entries.map((entry, index) => {

            const select = () => this.props.onSelect(entry.display)
            // return <li onMouseDown={ select }>
            //   <span>{result}</span>
              
            // </li> 
           return <li key={entry.id} className={index === this.props.selectedIndex ? "selected-user" : ""} onMouseDown={ select }>
                    <div
                        className={
                          !entry.image ? "comments-people avatar" : "comments-people"
                        }
                      >
                        {entry.image && <img src={entry.image} className="user-photo" />}
                        {!entry.image && (
                          <Avatar
                            style={{ width: "100%", height: "100%" }}
                            name={entry.display}
                            maxInitials={1}
                          ></Avatar>
                        )}
              
                        <span>{entry.username}</span>
                      </div>
                      <div
                        className={
                          !entry.image
                            ? "mentio-name avatar ng-binding"
                            : "mentio-name ng-binding"
                        }
                      >
                        {entry.display}
                      </div>
                    </li>

          })}
        </div>
      </nav>
  }
}

class CommentsEditor extends Component {
  constructor(props) {
    super(props)
    
    
    this.state = {
      
      activeSuggestion: null,
      falg:true
    }
    
  }


  

  componentDidMount(){
    let contentRaw = convertToRaw(this.props.editorState.getCurrentContent());

    if(contentRaw.blocks && contentRaw.blocks.length>0 && contentRaw.blocks[contentRaw.blocks.length-1]['text'].charAt(contentRaw.blocks[0]['text'].length-1)==='@'){
      setTimeout(() => {
          this.updateSuggestionsState();
      }, 1000); 
    }else{
      setTimeout(() => {
        if(this){
          this.focus();
        }
        
      }, 800);
    }
  }

  componentDidUpdate=()=>{
    let contentRaw = convertToRaw(this.props.editorState.getCurrentContent());
    if(contentRaw.blocks && contentRaw.blocks.length>0 ){
      let textData=contentRaw.blocks[contentRaw.blocks.length-1]['text'];
      if(textData && textData.charAt([textData.length-1])==='@'){
        setTimeout(() => {
          if(this){
            this.focus();
          }
          
        }, 800);
      }
    }
  }

  focus =()=> {
    if(this.refs && this.refs.editor){
      this.refs.editor.focus()
    }
    
  }

  
  
  onChange (editorState,callaback) {

    let currentStyle=this.props.editorState.getCurrentInlineStyle();
    let inlineStyle=[];

    if(currentStyle.has('BOLD')){
      inlineStyle.push('BOLD');
    }
    if(currentStyle.has('UNDERLINE')){
      inlineStyle.push('UNDERLINE');
    }
    if(currentStyle.has('ITALIC')){
      inlineStyle.push('ITALIC');
    }

    this.props.updateInlineState(inlineStyle);
    this.props.updateEditorState(editorState,()=>{
      this.updateSuggestionsState()
    },callaback)
  }
  
  updateSuggestionsState =()=> {
   
    const triggerRange     = getTriggerRange(PREFIX)
    const activeSuggestion = !triggerRange
      ? null
      : {
        position: getCaretCoordinates(this.props.scrollHeight),
        searchText: triggerRange.text.slice(1, triggerRange.text.length),
        selectedIndex: 0 }
    if(activeSuggestion) {
      if(activeSuggestion.searchText) {
        const filter = item =>  {
          let query = item.username.substring(0, activeSuggestion.searchText.length).toLowerCase()
          return query === activeSuggestion.searchText
        }
        activeSuggestion.people = this.props.dataForMentions.filter(filter);
      } else {
        activeSuggestion.people = [ ...this.props.dataForMentions ];
      }
    }
    this.setState({ activeSuggestion })

  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  handleUpArrow = (ev) => {
    if (this.state.activeSuggestion) {
			this.setState(({activeSuggestion}) => ({
        activeSuggestion:{ 
          ...activeSuggestion, 
          selectedIndex: Math.max(0, activeSuggestion.selectedIndex-1)
        }}), () => {
          const inputBox = document.querySelector(".selected-user");
          if (inputBox && !this.isInViewport(inputBox)) {
            inputBox.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        })
      ev.preventDefault()
    }
  }

  handleDownArrow = (ev) => {
    if (this.state.activeSuggestion) {
			this.setState(({activeSuggestion}) => ({
        activeSuggestion:{ 
          ...activeSuggestion, 
          selectedIndex: Math.min(activeSuggestion.selectedIndex+1, activeSuggestion.people.length-1)
        }
      }), () => {
        const inputBox = document.querySelector(".selected-user");
        if (inputBox && !this.isInViewport(inputBox)) {
          inputBox.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      })
      ev.preventDefault()
    }
  }

  handleReturn = (ev, _editorState) => {
    if(this.state.activeSuggestion) {
      const selectedPerson = this.state.activeSuggestion.people[this.state.activeSuggestion.selectedIndex]
      if(selectedPerson) {
        this.handleSuggestionSelected(selectedPerson.display);
      }
      ev.preventDefault()
      return true;
    }
    return false;
  }
  
  handleSuggestionSelected = (text) => {
 
    const { activeSuggestion } = this.state; 
    this.onChange(
      addSuggestion(this.props.editorState, activeSuggestion, text),()=>{
        setTimeout(() => {
          if(this){
            this.focus();
          }
          
        }, 800);
      }
    )
    this.setState({ activeSuggestion: null }, () => {
     // requestAnimationFrame(() => this.focus())
    })
  }
  
  render() {
        const contentState = this.props.editorState.getCurrentContent();
    let showPlaceholder = false;
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() === 'unstyled') {
        showPlaceholder = true;
      }
    }
    return <div 
      className={`RichEditor-editor ${!showPlaceholder ? 'hide-placeholder' : ''} ${this.props.withAttachment > 0 ? "with-att": ""}`} 
      onClick={this.focus}
      >
      
      <Editor
        readOnly={this.props.disabled}
        ref="editor"
        editorState={ this.props.editorState } 
        onChange={this.onChange.bind(this)}
        blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
             
              placeholder="Add a comment. To mention someone type @"
              onUpArrow={this.handleUpArrow}
              onDownArrow={this.handleDownArrow}
              handleReturn={this.handleReturn}
              
        />
      
      <Suggestions 
        { ...this.state.activeSuggestion }
        dataForMentions={this.props.dataForMentions}
        onSelect={ this.handleSuggestionSelected }
        />

    </div>
  }
  }
  
  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };
  
  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }
  
  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {
      let className = 'RichEditor-styleButton'+' '+this.props.style ;
      if (this.props.active) {
        className += ' RichEditor-activeButton'+' '+this.props.style;
      }
      
      if(this.props.style==='BOLD'){
        return (
          <div className={className} onMouseDown={this.onToggle}>
             <img src={bold} alt="B" style={{'margin-left':'2px'}}/>
          </div>
        );
      }else if(this.props.style==='UNDERLINE'){
        return (
          <div className={className} onMouseDown={this.onToggle}>
             <img src={underline} alt="U" style={{'margin-left':'2px'}} />
          </div>
        );
      }else if(this.props.style==='ITALIC'){
        return (
          <div className={className} onMouseDown={this.onToggle}>
             <img src={italic} alt="I" style={{'margin-left':'2px'}} />
          </div>
        );
      }else if(this.props.style==='unordered-list-item'){
        return (
          <div className={className} onMouseDown={this.onToggle}>
             <img src={orderlist} alt="UL" />
          </div>
        );
      }else if(this.props.style==='ordered-list-item'){
        return (
          <div className={className} style={{marginTop:'-1px'}} onMouseDown={this.onToggle}>
             <img src={numberlist} alt="OL" />
          </div>
        );
      }else{
        return (
          <span className={className} onMouseDown={this.onToggle}>
            {this.props.label}
          </span>
        );
      }
  
      
    }
  }
  
  const BLOCK_TYPES = [
    
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  
  ];
  
export const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  
    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };
  
  var INLINE_STYLES = [
    {label: 'B', style: 'BOLD'},
    {label: 'U', style: 'UNDERLINE'},
    {label: 'I', style: 'ITALIC'},
   
  ];
  
  export const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
  
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  const mapStateToProps = (state) => {
    return {
      scrollHeight:state.dialogReducer.scrollHeight
    }  
  } 

  export default connect(mapStateToProps, null)(CommentsEditor);