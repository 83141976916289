import { GOOGLE_KEY } from "../constants/globalconst";

let routesHelper = {};

routesHelper.getBaseUrl = () => {
  return "https://localhost:3000";
};

routesHelper.getWebTourPath = () => {
  return "https://invis.io/MQP6R9AGBCT";
};

routesHelper.getFeedbackPath = () => {
  return "https://surveys.hotjar.com/s?siteId=1174142&surveyId=127510";
};

routesHelper.getAuthToken = () => {
  return localStorage.getItem("token") || localStorage.getItem("auth_token");
};
routesHelper.getCustomerAuthToken = () => {
  return localStorage.getItem("token") || localStorage.getItem("customer_auth_token") || "abcd-1234-efgh-5678";
};

routesHelper.getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
}

routesHelper.getGoogleApiKey = () => {
  return GOOGLE_KEY;
};

routesHelper.getGoogleDevApiKey = () => {
  return GOOGLE_KEY;
};

routesHelper.getForgotPasswordPath = () => {
  return "/forgot-password";
};

routesHelper.getRestPasswordPath = () => {
  return "/reset-password";
};
routesHelper.getLoginPath = () => {
  return "/login";
};
routesHelper.getRegistrationPath = () => {
  return "/register";
};

routesHelper.getRegistrationMessagePath = () => {
  return "/registration-message";
};

routesHelper.getActivityPath = () => {
  return "/activity";
};

routesHelper.getWorkspacePath = () => {
  return "/workspace";
};

routesHelper.getRootPath = () => {
  return "/activity";
};

routesHelper.getProjectsPath = () => {
  return "/projects";
};

routesHelper.getDashboardPath = () => {
  return "/dashboard";
}

routesHelper.getReportPath = () => {
  return "/report";
};

routesHelper.getProjectEditPath = (projId) => {
  return `/project/${projId}/editProjectDetail`;
};

routesHelper.getWorkspaceInvitationsPath = () => {
  return "/workspace/pending-invites";
};

routesHelper.getCreateWorkspacePath = () => {
  return "/workspace/create";
};

routesHelper.getFindWorkspacePath = () => {
  return "/workspace/find";
};

routesHelper.getSettingsPath = () => {
  return "/settings";
};

routesHelper.getWorkspaceSettingsPath = () => {
  return "/workspace/settings";
};

routesHelper.getJoinWorkspacePath = () => {
  return "/workspace/join";
};

routesHelper.getAddUsersToWorkspacePath = () => {
  return "/workspace/add-users";
};

routesHelper.getProjectCreatePath = () => {
  return "/create/project";
};

routesHelper.getUserProfilePath = () => {
  return "/user-profile";
};
routesHelper.getPeopleSettings = () => {
  return "/people";
};

routesHelper.getFinishProfile = () => {
  return "/finish-profile";
};
routesHelper.getGtdLandingUri = () => {
  return "https://getthingsdone.io";
};

routesHelper.getGtdPolicyUri = () => {
  return "https://getthingsdone.io/privacy-policy";
};

routesHelper.getGtdTermsUri = () => {
  return "https://getthingsdone.io/terms-of-service";
};

routesHelper.getPlayStoreUri = () => {
  return "https://play.google.com/store/apps/details?id=com.peppersquare.getthingsdone.customer";
};

routesHelper.getAppStoreUri = () => {
  return "https://apps.apple.com/kh/app/getthingsdone-io/id1385636968?l";
};

routesHelper.getArchivedPath = () => {
  return "/archive-center";
};

routesHelper.getAllActivityArchivedPath = () => {
  return "/archive-center/all-activities";
};
routesHelper.getAllProjectsArchivedPath = () => {
  return "/archive-center/all-projects";
};
routesHelper.getAllChecklistArchivedPath = () => {
  return "/archive-center/all-checklists";
};
routesHelper.getAllCommentsArchivedPath = () => {
  return "/archive-center/all-comments";
};
routesHelper.workloadPath = () => {
  return "/workload";
};
routesHelper.kanbanPath = (projId) => {
  return `/project/${projId}/kanban`;
};

routesHelper.welcomePath = () => {
  return '/welcome';
};

export default routesHelper;
