import React, { memo } from 'react';
import '../../styles/scss/joinWorkspace.scss';
import gtd_out_logo from '../../styles/img/svg/Gtd-logo-outside.svg';
// import   routesHelper from '../../router/routeshelper';

const CommonNav = (props) => {
    return (
        <div>
            {/* <div>
                <a className="top-home-link ng-scope"  onClick={()=>{ router.push(routesHelper.getRootPath())}}>
                    Workspace
                </a>
            </div> */}
            <div> 
                <div className="logo-container">
                    <img src={gtd_out_logo} alt="GTD Logo" />
                </div>
            </div>
        </div>
    )
}

export default memo(CommonNav);