import { billingConstant } from "../../constants/billingConstant";
import {
  getBillingInfo,
  getCharges,
  getCustomer,
  updateCustomer,
  updateCardDetails,
  updateSubscription,
  cancelSubscription,
  reactivateSubscription
} from "../../services/paymentapis";

function getBillingInfoInReducer(billingDetails) {
  return {
    type: billingConstant.getSubscription,
    payload: billingDetails
  };
}

function getChargesInfoInReducer(chargesDetails) {
  return {
    type: billingConstant.getCharges,
    payload: chargesDetails
  };
}

function getCustomerDetailsInfoInReducer(customerDetails) {
  return {
    type: billingConstant.getCustomer,
    payload: customerDetails
  };
}

function updateCustomerdetailsInReducer(data) {
  return {
    type: billingConstant.updateCustomer,
    payload: data
  };
}

function cancelSubscriptionInReducer() {
  return {
    type: billingConstant.cancelSubscription
  };
}

function reactivateSubscriptionInReducer() {
  return {
    type: billingConstant.reactivateSubscription
  };
}

function updateSubscriptionLoaderUpdate(loaderStatus) {
  return {
    type: billingConstant.updateSubscriptionLoader,
    payload: { ...loaderStatus }
  };
}

function cancelOrActivateSubscriptionLoaderUpdate(loaderStatus) {
  return {
    type: billingConstant.cancelOrActivateSubscriptionLoader,
    payload: { ...loaderStatus }
  };
}

export const getCustomerInfo = (callback) => (dispatch) => {
  getBillingInfo((response) => {
    dispatch(getBillingInfoInReducer(response));
    callback(response);
  });
};

export const getChargesInfo = (callback) => (dispatch) => {
  getCharges((response) => {
    dispatch(getChargesInfoInReducer(response));
    callback(response);
  });
};

export const getCustomerDetails = (callback) => (dispatch) => {
  getCustomer((customerDetails) => {
    dispatch(getCustomerDetailsInfoInReducer(customerDetails));
    callback(customerDetails);
  });
};

export const updateCustomerDetails = (paymentObject, callback) => (
  dispatch
) => {
  updateCardDetails({ paymentObject }, (updatePaymentObject) => {
    if (updatePaymentObject && !updatePaymentObject.error) {
      updateCustomer(
        { name: paymentObject.name, update_type: "name_update" },
        (updateCustomerObject) => {
          if (updateCustomerObject && !updateCustomerObject.error) {
            getBillingInfo((billingInfo) => {
              if (billingInfo && !billingInfo.error) {
                dispatch(getBillingInfoInReducer(billingInfo));
                dispatch(getCustomerDetailsInfoInReducer(updateCustomerObject));

                callback("success");
              }
            });
          }
        }
      );
    }
  });
};

export const updateCardDetailsAction = (
  { id, token, name, address, city, state, zip, country },
  callback
) => (dispatch) => {
  updateCustomer(
    { source_id: token, update_type: "source_update" },
    (updatedCustomerDetails) => {
      if (updatedCustomerDetails && !updatedCustomerDetails.error) {
        updateCardDetails(
          {
            paymentObject: {
              card_id: id,
              name,
              address,
              city,
              state,
              zip,
              country
            }
          },
          (updatedCardDetail) => {
            if (updatedCardDetail && !updatedCardDetail.error) {
              getBillingInfo((billingInfo) => {
                if (billingInfo && !billingInfo.error) {
                  dispatch(getBillingInfoInReducer(billingInfo));
                  dispatch(
                    updateCustomerdetailsInReducer({
                      ...updatedCustomerDetails,
                      sources: {
                        ...updatedCustomerDetails.sources,
                        data: [updatedCardDetail]
                      }
                    })
                  );
                }
                callback("success");
              });
            }
          }
        );
      }
    }
  );
};

export const cancelSubscriptionAction = (
  { existingSubscriptionObj },
  callback
) => (dispatch) => {
  dispatch(
    cancelOrActivateSubscriptionLoaderUpdate({
      loading: true,
      loaded: false,
      error: false
    })
  );
  cancelSubscription(
    {
      existingSubscriptionObj
    },
    (response) => {
      if (response && !response.error) {
        dispatch(
          cancelOrActivateSubscriptionLoaderUpdate({
            loading: false,
            loaded: true,
            error: false
          })
        );
        dispatch(getCustomerInfo());
        dispatch(getChargesInfo());

        dispatch(cancelSubscriptionInReducer());
      } else {
        dispatch(
          cancelOrActivateSubscriptionLoaderUpdate({
            loading: true,
            loaded: false,
            error: response.error
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const reactivateSubscriptionAction = (
  { existingSubscriptionObj },
  callback
) => (dispatch) => {
  dispatch(
    cancelOrActivateSubscriptionLoaderUpdate({
      loading: true,
      loaded: false,
      error: false
    })
  );
  reactivateSubscription(
    {
      existingSubscriptionObj
    },
    (response) => {
      if (response && !response.error) {
        dispatch(
          cancelOrActivateSubscriptionLoaderUpdate({
            loading: false,
            loaded: true,
            error: false
          })
        );
        dispatch(getCustomerInfo());
        dispatch(getChargesInfo());
        dispatch(reactivateSubscriptionInReducer());
      } else {
        dispatch(
          cancelOrActivateSubscriptionLoaderUpdate({
            loading: true,
            loaded: false,
            error: response.error
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const updateSubscriptionAction = (
  { existingSubscriptionObj, planType, user_id, action },
  callback
) => (dispatch) => {
  dispatch(
    updateSubscriptionLoaderUpdate({
      loading: true,
      loaded: false,
      error: false
    })
  );
  updateSubscription(
    {
      existingSubscriptionObj,
      planType,
      update_type: "plan_update",
      user_id,
      action
    },
    (response) => {
      callback && callback(response);
      if (response && !response.error) {
        dispatch(
          updateSubscriptionLoaderUpdate({
            loading: false,
            loaded: true,
            error: false
          })
        );
        dispatch(getCustomerInfo());
        dispatch(getChargesInfo());
        dispatch(getBillingInfo());
      } else {
        dispatch(
          updateSubscriptionLoaderUpdate({
            loading: false,
            loaded: true,
            error: response.error
          })
        );
      }
    }
  );
};
