import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";

export const getEffortOverview = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/work-effort";
  let queryParams = queryParam ? queryParam : "";
  //console.log(API_URL + url + queryParams);
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getIndividualEffort = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/dashboard-team";
  let queryParams = queryParam ? queryParam : "";
  //console.log(API_URL + url + queryParams);
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const acceptDeclineProjReq = (
  { auth_token = null, body, method },
  callback
) => {
  let url = "/api/v1/request-project-access";
  secureFetch(API_URL + url, {
    method,
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getEffortOverviewPersonal = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/work-effort-individual";
  let queryParams = queryParam ? queryParam : "";
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getProjectAccessReqPersonal = (
  { auth_token = null, queryParam },
  callback
) => {
  let url = "/api/v1/dashboard-personal";
  let queryParams = queryParam ? queryParam : "";
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};