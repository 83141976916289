import { useDrop } from 'react-dnd'

import React, { useContext, useRef } from "react";
import { DragTypes } from "../../constants/DragTypes";
import KanbanContext from "../../contexts/kanban/kabanContext";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import _ from "lodash";
momentDurationFormatSetup(moment);






export default function MyDropTarget(props) {
  const ref = useRef(null);
  let style={opacity: 0};
  const {
    selectedProject,
    updateProjectAction,
    updateActivityWithWorkflowAction
  } = useContext(KanbanContext);
  const [{ isTargetDragging, didDrop,hover }, drop] = useDrop({
    accept: DragTypes.ACTIVITY,
    collect: (monitor) => {

      return {
        isTargetDragging: monitor.isOver(),
        didDrop: monitor.didDrop(),
        hover:monitor.isOver()
      }

    },
    hover(item, _monitor) {
      
      if (!ref.current) {
        return;
      }
      if (props.workflow.activities && props.workflow.activities > 0) {
        return;
      }

      let workflowListCopy = _.cloneDeep(props.workflowList);
      workflowListCopy.forEach(workFlowDataEach => {
        if (workFlowDataEach.id !== item.sourceWorkFlowid && props.workflow.id !== workFlowDataEach.id) {
          let actvities = workFlowDataEach.activities.filter(activitydata => activitydata.id !== item.activity.id);
          workFlowDataEach.activities = actvities;
        }

      })
      props.setWorkflowList(workflowListCopy);

    },
    drop(item, _monitor) {

      item.activity.workflow.id = props.workflow.id;
      item.activity.workflow.moved_from = item.activity.workflow.name;
      item.activity.workflow.name = props.workflow.name;
      item.activity.version_id = item.activity.version_id + 1;

      const updateActivity = () => {
        updateActivityWithWorkflowAction(

          {
            id: item.activity.id,
            version_id: item.activity.version_id,
            workflow: item.activity.workflow
          },
          _response => { /* TODO document why this arrow function is empty */ }
        );
      };
      let workflowListCopy = _.cloneDeep(props.workflowList);
      workflowListCopy.forEach(workFlowDataEach => {
        if (props.workflow.id !== workFlowDataEach.id) {
          let actvities = workFlowDataEach.activities.filter(activitydata => activitydata.id !== item.activity.id);
          workFlowDataEach.activities = actvities;
        }
        if (parseInt(workFlowDataEach.id) === parseInt(props.workflow.id)) {
          workFlowDataEach['activities'] = [item.activity];
        }
      })
     
      props.setWorkflowList(workflowListCopy);

      if (selectedProject.workflow[item.workflowId].activity_order && selectedProject.workflow[item.workflowId].activity_order.length > 0) {
        selectedProject.workflow[item.workflowId].activity_order.splice(id => id === item.activity.id, 1);
      }
      selectedProject.workflow[props.workflow.id].activity_order = [parseInt(item.activity.id)];

      item.itemDroppedWorkFlowId = true;
      let projectToUpdate = {
        id: selectedProject.id,
        workflow: selectedProject.workflow,
        updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      };

      const auth_token = localStorage.getItem("auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: projectToUpdate },
        response => {
          if (response && response.id) {
            updateActivity();
          }
        }
      );

    }
  });

  if(hover){
    style={opacity:0,height:'50px'};
  }
  return <div ref={drop}  style={style} key={new Date()} >Drag your activity here</div>
}