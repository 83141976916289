import React,{useState, memo} from "react";
import moment from "moment";
import _ from "lodash";
import star_active from "../../styles/img/svg/star_active.svg";
import star_empty from "../../styles/img/svg/star_empty.svg";
import { activities } from "../../constants/activitiescontants";
import star_disabled from "../../styles/img/svg/star_disabled.svg";
import Tooltip from "@material-ui/core/Tooltip";
import firebase from "../../firebase";
import { Loader } from "../common/loader";

const  PrioritizedStars=({
  is_priority,
  onPriorityChange,
  activityDetails,
  prioritizedActivity,
  archiveOrUnArchiveActivitiesAction,
  updateActivityState,
  timerRunningStatus,
  selectedWorkloadUser,
  fromWorkload,
  loggedInUser,
  onShowCannotPrioritizePopup
}) =>{
  const [stariconbutton,setStariconbutton]=useState(false);
  const updateStateStartIcon= (flag) =>{
    setStariconbutton(flag);
  }
  return (
    <div className="menu-options wp">
      <div
        className={`${
          timerRunningStatus === true ? "rest-star-disabled" : "star-icon"
        } `}
      >
        {!fromWorkload && is_priority && (
          <Tooltip
            title={`${timerRunningStatus === true ? "" : "Deprioritize"}`}
          >
            <span>
              <img
                src={star_active}
                //   className={`star${shouldEnableTimer ? "-disabled" : ""}`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true || stariconbutton
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () =>
                        onPrioritize({
                          archiveOrUnArchiveActivitiesAction,
                          updateActivityState,
                          activityDetails,
                          onPriorityChange,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        })
                }
              />
            </span>
          </Tooltip>
        )}

        {!fromWorkload && !is_priority && prioritizedActivity.length < 3 && (
          <Tooltip title="Prioritize">
            <span>
              <img
                src={star_empty}
                className={`star-icon${
                  prioritizedActivity.length < 3 ? "-disabled" : ""
                }`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true || stariconbutton
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () => {
                        checkIfPriorityFull({
                          archiveOrUnArchiveActivitiesAction,
                          activityDetails,
                          onPriorityChange,
                          prioritizedActivity,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        });
                      }
                }
              />
            </span>
          </Tooltip>
        )}
        {!fromWorkload && !is_priority && prioritizedActivity.length >= 3 && (
          <Tooltip title="Deprioritize a card to prioritize this card">
            <span>
              <img
                src={star_disabled}
                className={`rest-star${
                  prioritizedActivity.length >= 3 ? "-disabled" : ""
                }`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true || stariconbutton
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () => {
                        checkIfPriorityFull({
                          archiveOrUnArchiveActivitiesAction,
                          activityDetails,
                          onPriorityChange,
                          prioritizedActivity,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        });
                      }
                }
              />
            </span>
          </Tooltip>
        )}
        {fromWorkload && (selectedWorkloadUser && loggedInUser) && (parseInt(selectedWorkloadUser.id) !== parseInt(loggedInUser.id)) && (
          <Tooltip title="Priority disabled">
            <span>
              <img
                src={star_disabled}
                className={`rest-star${
                  prioritizedActivity.length >= 3 ? "-disabled" : ""
                }`}
                alt="more_vert"
              />
            </span>
          </Tooltip>
        )}
        {fromWorkload && (selectedWorkloadUser && loggedInUser) && (parseInt(selectedWorkloadUser.id) === parseInt(loggedInUser.id)) && is_priority && (
          <Tooltip
            title={`${timerRunningStatus === true ? "" : "Deprioritize"}`}
          >
            <span>
              <img
                src={star_active}
                //   className={`star${shouldEnableTimer ? "-disabled" : ""}`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true || stariconbutton
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () =>
                        onPrioritize({
                          archiveOrUnArchiveActivitiesAction,
                          updateActivityState,
                          activityDetails,
                          onPriorityChange,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        })
                }
              />
            </span>
          </Tooltip>
        )}

        {fromWorkload && (selectedWorkloadUser && loggedInUser) && (parseInt(selectedWorkloadUser.id) === parseInt(loggedInUser.id)) && !is_priority && prioritizedActivity.length < 3 && (
          <Tooltip title="Prioritize">
            <span>
              <img
                src={star_empty}
                className={`star-icon${
                  prioritizedActivity.length < 3 ? "-disabled" : ""
                }`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true || stariconbutton
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () => {
                        checkIfPriorityFull({
                          archiveOrUnArchiveActivitiesAction,
                          activityDetails,
                          onPriorityChange,
                          prioritizedActivity,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        });
                      }
                }
              />
            </span>
          </Tooltip>
        )}
        {fromWorkload && (selectedWorkloadUser && loggedInUser) && (parseInt(selectedWorkloadUser.id) === parseInt(loggedInUser.id)) && !is_priority && prioritizedActivity.length >= 3 && (
          <Tooltip title="Deprioritize a card to prioritize this card">
            <span>
              <img
                src={star_disabled}
                className={`rest-star${
                  prioritizedActivity.length >= 3 ? "-disabled" : ""
                }`}
                alt="more_vert"
                onClick={
                  timerRunningStatus === true
                    ? () => { /* TODO document why this arrow function is empty */ }
                    : () => {
                        checkIfPriorityFull({
                          archiveOrUnArchiveActivitiesAction,
                          activityDetails,
                          onPriorityChange,
                          prioritizedActivity,
                          updateStateStartIcon,
                          onShowCannotPrioritizePopup
                        });
                      }
                }
              />
            </span>
          </Tooltip>
        )}
      </div>
     
      {
          stariconbutton &&
         (
            <Loader size={100} loading={stariconbutton} />
          )}
         
    </div>
  );
}

const onPrioritize = ({
  archiveOrUnArchiveActivitiesAction,
  updateActivityState,
  activityDetails,
  onPriorityChange,
  updateStateStartIcon,
  onShowCannotPrioritizePopup
}) => {
  updateStateStartIcon(true);
  let updatedActivity = { ...activityDetails };
  let user_id = parseInt(localStorage.getItem("id"));
  
  if (
    !_.isUndefined(updatedActivity.data) &&
    !updatedActivity.data.hasOwnProperty("priority")
  ) {
    updatedActivity.data.priority = [];
  }
  let isItemPrioritized =
    !_.isUndefined(updatedActivity.data) &&
    updatedActivity.data.priority.filter(priority => {
      return priority.user_id === user_id;
    });
  
  const customer_auth_token = localStorage.getItem(
    activities.customerAuthToken
  );
  let prioritizedUserObject = {
    user_id: user_id,
    date: moment(new Date()).format()
  };
  let prioritize = !_.isUndefined(updatedActivity.data) && {
    data: {
      ...updatedActivity.data,
      priority: [...updatedActivity.data.priority, prioritizedUserObject]
    },
    version_id: updatedActivity.version_id,
    id: updatedActivity.id
  };

  let dePrioritize = {
    data: { ...updatedActivity.data, priority: updatedActivity.data.priority.filter(priority => priority.user_id !== user_id) },
    version_id: updatedActivity.version_id,
    id: updatedActivity.id
  };

  archiveOrUnArchiveActivitiesAction(
    {
      auth_token: customer_auth_token,
      updatedActivity,
      archiveData: isItemPrioritized.length > 0 ? dePrioritize : prioritize,
      type:
        isItemPrioritized.length > 0
          ? activities.dePrioritize
          : activities.prioritize
    },
    response => {
      updateStateStartIcon(false);
      if(response && response.id) {
        firebase.analytics().logEvent("GTD_Activity_inner_priority_dePriority");
        updateActivityState && updateActivityState({...updatedActivity, ...response});
        onPriorityChange();
      }
      if(response?.error === "Priority limit exceeded") {
        onShowCannotPrioritizePopup(response);
      }
    }
  );
};

const checkIfPriorityFull = ({
  archiveOrUnArchiveActivitiesAction,
  activityDetails,
  prioritizedActivity,
  onPriorityChange,
  updateStateStartIcon,
  onShowCannotPrioritizePopup
}) => {
  if (prioritizedActivity && prioritizedActivity.length >= 3) {
    return;
  } else {
    onPrioritize({
      archiveOrUnArchiveActivitiesAction,
      activityDetails,
      prioritizedActivity,
      onPriorityChange,
      updateStateStartIcon,
      onShowCannotPrioritizePopup
    });
  }
};

export default memo(PrioritizedStars);
