/***
 * Index file of the application
 * Author: Madhusudhan Seetharamiah
 */

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import configureStore from "../src/stores/configurestore";
import Root from "../src/router/routes";
import Redirect from "../src/components/redirect";
import "./styles/scss/app.scss";
import "./styles/scss/attachment.scss";
import "whatwg-fetch";

const store = configureStore.getInstance();
Sentry.init({
  dsn: "https://6b23b326faa0443999f2a3e086ebbe85@sentry.io/1477015"
});

document.addEventListener("DOMContentLoaded", function(event) {
  ReactDOM.render(
    <span className="gtd-parent-wrapper">
      <span className="high-res">
        <Root store={store} />
      </span>
      <span className="low-res">
        <Redirect />
      </span>
    </span>,
    document.getElementById("root")
  );
});
