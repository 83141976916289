import React from "react";
import { Link } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip } from "@material-ui/core";
// import ReactTooltip from "react-tooltip";
import MenuOptions from "./menuOptions";
import { getProjectView } from "../../helpers/projectslanding";
import Avatar from "react-avatar";
import routesHelper from "../../router/routeshelper";
import firebase from "../../firebase";

const ProjectCard = ({ project, router, unArchivedArchivedProject }) => {
  const goToKanban = () => {
    firebase.analytics().logEvent("GTD_Project_Project_Click");
    // router.push(routesHelper.kanbanPath(project.id));
  };

  const projectView = getProjectView(project);
  return (
    <div className="project-dash-card">
      <div className="project-cards-dash">
        {projectView.showMenu && (
          <MenuOptions
            router={router}
            project={project}
            unArchivedArchivedProject={unArchivedArchivedProject}
          />
        )}

        <Link
          to={routesHelper.kanbanPath(project.id)}
          className="project-dashboard-card card-content"
          onClick={goToKanban}
        >
          {projectView.img ? (
            <div className="img-container project-img">
              <img src={projectView.img} alt="project-logo" />
            </div>
          ) : (
            <div className="img-container project-img">
              <Avatar
                className="project-avatar"
                name={project.name}
                maxInitials={1}
              ></Avatar>
            </div>
          )}
          <div className="project-desc-right">
            <div
            //className="title_text title_pointer
            >
              {projectView.name.length > 20 ? (
                <Tooltip placement="top" title={projectView.name}>
                  <span className="md-title truncate-txt project-dashboard-title">
                    {projectView.name}
                  </span>
                </Tooltip>
              ) : (
                <span className="md-title truncate-txt project-dashboard-title">
                  {projectView.name}
                </span>
              )}
            </div>
            <div className="progress_bar">
              <div className="project-percentage">
                <div>{projectView.percentage}%</div>
                <div
                  className={`project-progressbar ${
                    projectView.percentage > 100 ? "more-time" : ""
                  }`}
                >
                  <LinearProgress
                    className="progressbar"
                    style={{ background: "#f0f0f0", height: "2px" }}
                    variant="determinate"
                    value={
                      projectView.percentage > 100
                        ? 100
                        : projectView.percentage
                    }
                  />
                </div>
              </div>
              <div className="progress_status">
                <span className="project-hours-planned">
                  {projectView.ratio}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
