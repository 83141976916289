import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Mark from "mark.js";
import OutsideClickHandler from "react-outside-click-handler";
import commentsNormal from "../../styles/img/svg/comment_act.svg";
import commentsActive from "../../styles/img/svg/comment_blue_active1.svg";
import checklistsNormal from "../../styles/img/svg/checklist-black.svg";
import checklistsActive from "../../styles/img/svg/checklist_active_new.svg";
import SearchIcon from "../../styles/img/svg/settings-search-icon1.svg";
import BlueSearchIcon from "../../styles/img/svg/blue-search.svg";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import CloseIcon from "../../styles/img/svg/search-notification-close.svg";
import Comments from "../comments/comments";
import Checklist from "../checklits/checklist";
import { getActivity } from "../../services/workspaceapi";
import { getAllAttachmentsDetailsAction } from "../../actions/attachments/attachmentsaction";
import {
  searchCommentsAction,
  clearSearchedCommentsAction
} from "../../actions/comments/commentsaction";
import {
  getChecklistAction,
  searchChecklistAction,
  clearChecklistAction,
  clearSearchedChecklistAction
} from "../../actions/checklist/checklist";

class ActivityPopupBottom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.clickedType || "comments",
      onGoingActivity: null,
      fetch: false,
      showSearch: false,
      searchText: "",
      searchWithAttach: false,
      showTabDropdown: false,
      showOptionIcon: false,
      selectedOption: "All",
      searchPageNumber: 1,
      searchHasMore: false
    };
    this.switchTabTo = this.switchTabTo.bind(this);
  }

  searchTimeout;

  switchTabTo(tab) {
    this.setState(() => ({
      activeTab: tab
    }));
  }

  componentDidMount() {
    this.setState({
      onGoingActivity: this.props.activityDetailsAsProps
    });
  }

  componentDidUpdate() {
    const { activityDetailsAsProps, totalActivities } = this.props;

    const isUserActivity =
      activityDetailsAsProps &&
      totalActivities.filter(activity => {
          return activity.id === activityDetailsAsProps.id;
      }).length > 0;

    if (!isUserActivity && this.state.fetch === true) {
      getActivity({ id: activityDetailsAsProps.id }, response => {
        if (response.length > 0) {
          this.setState({
            onGoingActivity: response[0],
            fetch: false
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.clearChecklistAction();
    this.props.clearSearchedChecklistAction();
  }

  updateFetch = () => {
    this.setState({
      fetch: true
    });
  };

  onShowSearch = () => {
    this.setState({ showSearch: true });
  }

  onCloseSearch = () => {
    var context = document.querySelectorAll(".checklists, .ap-comment-desc-holder");
    var instance = new Mark(context);
    instance.unmark();
    this.props.clearSearchedCommentsAction();
    this.props.clearSearchedChecklistAction();
    this.setState({
      showSearch: false,
      searchText: "",
      showTabDropdown: false,
      searchWithAttach: false,
      selectedOption: "All",
      searchPageNumber: 1,
      searchHasMore: false
    });
  }

  onSearchInputChange = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      var context = document.querySelectorAll(".checklists, .ap-comment-desc-holder");
      var instance = new Mark(context);
      if(this.state.searchText.length === 0) {
        instance.unmark();
      }
      clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          const { searchComments, searchChecklist } = this.props;
          const { activeTab, onGoingActivity, searchText, selectedOption } = this.state;
          const currentUserId = parseInt(localStorage.getItem("id"));
          const socialType = selectedOption.toLocaleLowerCase();
          let queryParams = `?parent_activity_id=${onGoingActivity.id}&like=${searchText}&logged_in_user=${currentUserId}&social_type=${socialType}`
          if (activeTab === "comments") {
            if (this.state.searchWithAttach) {
              queryParams = queryParams.concat(`&search_type=attachments&page=1&per_page=10`);
            } else {
              queryParams = queryParams.concat(`&search_type=${activeTab}&page=1&per_page=10`);
            }
            searchComments(queryParams, 1, res => {
              if(res) {
                this.setState({ searchHasMore: res.length >= 10});
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".ap-comment-desc-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially"
                  });
                }, 100)
              }
            }); 
          } else if (activeTab === "checklists") {
            queryParams = queryParams.concat(`&search_type=${activeTab}`);
            searchChecklist(queryParams, res => {
              if(res) {
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".checklists");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".check-count", ".completed-percentage-text", ".add-item"]
                  });
                }, 100)
              }
            });
          }
        }, 800);
    });
  }

  onShowTabDropdown = () => {
    this.setState({ showTabDropdown: !this.state.showTabDropdown })
  }

  handleClickOutside = event => {
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        showTabDropdown: false
      });
    }

    if (
      this.showWorkFlowPopupNode &&
      !this.showWorkFlowPopupNode.contains(event.target)
    ) {
      if (this.state.showTabDropdown) {
        this.setState({
          showTabDropdown: false
        });
      }
    }
  };

  updateSearchHasMore = (searchHasMore) => {
    this.setState({ searchHasMore })
  }

  updatePageNumber = (searchPageNumber) => {
    this.setState({ searchPageNumber });
  }

  onSelectDownOption = (tab) => {
    if(this.state.activeTab === tab && tab !== "comments_with_attach") this.props.clearSearchedCommentsAction();
    if (tab === "comments_with_attach")
      this.setState({ activeTab: "comments", searchWithAttach: true, selectedOption: "All" }, () => {
        var context = document.querySelectorAll(".checklists, .ap-comment-desc-holder");
        var instance = new Mark(context);
        if(this.state.searchText.length === 0) instance.unmark();
        const { searchComments } = this.props;
          const { activeTab, onGoingActivity, searchText, selectedOption } = this.state;
          const currentUserId = parseInt(localStorage.getItem("id"));
          const socialType = selectedOption.toLocaleLowerCase();
          let queryParams = `?parent_activity_id=${onGoingActivity.id}&like=${searchText}&logged_in_user=${currentUserId}&social_type=${socialType}`
          if (activeTab === "comments") {
            queryParams = queryParams.concat(`&search_type=attachments&page=1&per_page=10`);
            searchComments(queryParams, 1, res => {
              if(res) {
                this.setState({ searchHasMore: res.length >= 10});
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".ap-comment-desc-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially"
                  });
                }, 100)
              }
            });
          }
      });
    else
      this.setState({ activeTab: tab, searchWithAttach: false, selectedOption: "All" }, () => {
        var context = document.querySelectorAll(".checklists, .ap-comment-desc-holder");
        var instance = new Mark(context);
        if(this.state.searchText.length === 0) instance.unmark();
        if (this.state.searchText.length > 0) {
          const { searchComments, searchChecklist } = this.props;
          const { activeTab, onGoingActivity, searchText, selectedOption } = this.state;
          const currentUserId = parseInt(localStorage.getItem("id"));
          const socialType = selectedOption.toLocaleLowerCase();
          let queryParams = `?parent_activity_id=${onGoingActivity.id}&like=${searchText}&logged_in_user=${currentUserId}&social_type=${socialType}`
          if (activeTab === "comments") {
            queryParams = queryParams.concat(`&search_type=${activeTab}&page=1&per_page=10`);
            searchComments(queryParams, 1, res => {
              if(res) {
                this.setState({ searchHasMore: res.length >= 10});
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".ap-comment-desc-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially"
                  });
                }, 100)
              }
            });
          } else if (activeTab === "checklists") {
            queryParams = queryParams.concat(`&search_type=${activeTab}`);
            searchChecklist(queryParams, res => {
              if(res) {
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".checklists");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".check-count", ".completed-percentage-text", ".add-item"]
                  });
                }, 100)
              }
            });
          }
        }
      });
  }

  onSelectOption = (option) => {
    this.setState({ selectedOption: option }, () => {
      var context = document.querySelectorAll(".checklists, .ap-comment-desc-holder");
      var instance = new Mark(context);
      if(this.state.searchText.length === 0) instance.unmark();
      const { searchComments, searchChecklist } = this.props;
        const { activeTab, onGoingActivity, searchText, selectedOption } = this.state;
        const currentUserId = parseInt(localStorage.getItem("id"));
        const socialType = selectedOption.toLocaleLowerCase();
        let queryParams = `?parent_activity_id=${onGoingActivity.id}&like=${searchText}&logged_in_user=${currentUserId}&social_type=${socialType}`
        if (activeTab === "comments") {
          if (this.state.searchWithAttach) {
            queryParams = queryParams.concat(`&search_type=attachments&page=1&per_page=10`);
            searchComments(queryParams, 1, res => {
              if(res) {
                this.setState({ searchHasMore: res.length >= 10});
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".ap-comment-desc-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially"
                  });
                }, 100)
              }
            });
          } else {
            queryParams = queryParams.concat(`&search_type=${activeTab}&page=1&per_page=10`);
            searchComments(queryParams, 1, res => {
              if(res) {
                this.setState({ searchHasMore: res.length >= 10});
                this.updatePageNumber(1);
                setTimeout(() => {
                  instance.unmark();
                  let updatedContext = document.querySelectorAll(".ap-comment-desc-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchText, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially"
                  });
                }, 100)
              }
            });
          }
        } else if (activeTab === "checklists") {
          queryParams = queryParams.concat(`&search_type=${activeTab}`);
          searchChecklist(queryParams, res => {
            if(res) {
              this.updatePageNumber(1);
              setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".checklists");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(searchText, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".check-count", ".completed-percentage-text", ".add-item"]
                });
              }, 100)
            }
          });
        }
      // }
    });
  }

  onOptionIconClick = () => {
    this.setState({
      showOptionIcon: !this.state.showOptionIcon
    });
  };

  onOptionOutside = event => {
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        showOptionIcon: false
      });
    }
  };

  render() {
    const {
      activityDetailsAsProps,
      totalActivities,
      is_archived,
      // checklists,
      // allAttachments,
      // comments,
      clickedType,
      // clickedId,
      is_priority
    } = this.props;

    const { activeTab, showSearch, searchText, searchWithAttach, showTabDropdown, selectedOption } = this.state;
   
    const activityDetails =
      this.props.fromNotification && activityDetailsAsProps ? activityDetailsAsProps :
      activityDetailsAsProps &&
      totalActivities.filter(activity => {
          return activity.id === activityDetailsAsProps.id;
      }).length > 0
        ? activityDetailsAsProps &&
          totalActivities.filter(activity => {
              return activity.id === activityDetailsAsProps.id;
          })[0]
        : this.state.onGoingActivity || activityDetailsAsProps;

    return (
      <div className="apc-tab-container">
        {!showSearch && <div className="apc-tab-link-container without-attach">
          <div className="search-icon-holder" onClick={this.onShowSearch}>
            <img src={SearchIcon} alt="search-icon" />
          </div>
          {clickedType && (
            <div className="apc-tab-link-holder without-attach">
              <div
                className={
                  "apc-tab-link-items-holder " +
                  (clickedType === "comments" &&
                  this.state.activeTab === "comments"
                    ? "apc-active"
                    : this.state.activeTab === "comments"
                    ? "apc-active"
                    : "")
                }
                onClick={() => this.switchTabTo("comments")}
              >
                <div className="apc-tab-link-img comments">
                  <img
                    className={`${
                      this.state.activeTab === "comments"
                      ? "tab-active"
                      : ""}`}
                    src={
                      clickedType === "comments" &&
                      this.state.activeTab === "comments"
                        ? commentsActive
                        : this.state.activeTab === "comments"
                        ? commentsActive
                        : commentsNormal
                    }
                    alt="comments-icon"
                  />
                  {activityDetails.is_new_comment &&
                    <div
                      className={`act-inner-notify-dot ${
                        this.state.activeTab === "comments"
                        ? "tab-active-dot"
                        : ""}`}
                    />
                  }
                </div>
                <div className="apc-tab-link-title">
                  Comments - {activityDetails && activityDetails.comments_count}
                </div>
              </div>

              <div
                className={
                  "apc-tab-link-items-holder " +
                  (clickedType === "checklists" &&
                  this.state.activeTab === "checklists"
                    ? "apc-active"
                    : this.state.activeTab === "checklists"
                    ? "apc-active"
                    : "")
                }
                onClick={() => this.switchTabTo("checklists")}
              >
                <div className="apc-tab-link-img">
                  <img
                    src={
                      clickedType === "checklists" &&
                      this.state.activeTab === "checklists"
                        ? checklistsActive
                        : this.state.activeTab === "checklists"
                        ? checklistsActive
                        : checklistsNormal
                    }
                    alt="checklist-icon"
                  />
                  {activityDetails.is_new_checklist &&
                    <div
                      className={`act-inner-notify-dot ${
                        this.state.activeTab === "checklists"
                        ? "tab-active-dot"
                        : ""}`}
                    />
                  }
                </div>
                <div className="apc-tab-link-title">
                  Checklists -{" "}
                  {activityDetails && activityDetails.active_checklist_count}
                </div>
              </div>
            </div>
          )}
          {!clickedType && (
            <div className="apc-tab-link-holder without-attach">
              <div
                className={
                  "apc-tab-link-items-holder " +
                  (this.state.activeTab === "comments" ? "apc-active" : "")
                }
                onClick={() => this.switchTabTo("comments")}
              >
                <div className="apc-tab-link-img comments">
                  <img
                    className={`${
                      this.state.activeTab === "comments"
                      ? "tab-active"
                      : ""}`}
                    src={
                      this.state.activeTab === "comments"
                        ? commentsActive
                        : commentsNormal
                    }
                    alt="comments-icon"
                  />
                  {activityDetails.is_new_comment &&
                    <div
                      className={`act-inner-notify-dot ${
                        this.state.activeTab === "comments"
                        ? "tab-active-dot"
                        : ""}`}
                    />
                  }
                </div>
                <div className="apc-tab-link-title">
                  Comments - {activityDetails && activityDetails.comments_count}
                </div>
              </div>

              <div
                className={
                  "apc-tab-link-items-holder " +
                  (this.state.activeTab === "checklists" ? "apc-active" : "")
                }
                onClick={() => this.switchTabTo("checklists")}
              >
                <div className="apc-tab-link-img">
                  <img
                    src={
                      this.state.activeTab === "checklists"
                        ? checklistsActive
                        : checklistsNormal
                    }
                    alt="checklist-icon"
                  />
                  {activityDetails.is_new_checklist &&
                    <div
                      className={`act-inner-notify-dot ${
                        this.state.activeTab === "checklists"
                        ? "tab-active-dot"
                        : ""}`}
                    />
                  }
                </div>
                <div className="apc-tab-link-title">
                  Checklists -{" "}
                  {activityDetails && activityDetails.active_checklist_count}
                </div>
              </div>
            </div>
          )}
        </div>}
        {showSearch && (
          <div className="act-search-container">
            <div className="edit-board-search-holder">
              <input
                type="text"
                className="edit-board-search-control"
                placeholder={`Search${selectedOption !== "All" ? ` ${selectedOption}` : ""} ${this.state.activeTab === "comments" ? this.state.searchWithAttach ? "Attachments" : "Comments" : "Checklists"}`}
                aria-label="Search"
                onChange={this.onSearchInputChange}
                autoFocus
              />
              <div className="edit-search-icon-holder">
                <img src={BlueSearchIcon} alt="search-icon" />
              </div>
            </div>
            <div ref={node => (this.showWorkFlowPopupNode = node)} className="tab-dropdown-holder">
              <div
                className={
                  showTabDropdown
                    ? "workflow-change-ddown-holder clicked"
                    : "workflow-change-ddown-holder"
                }
                onClick={this.onShowTabDropdown}
              >
                <div className="workflow-change-ddown-text">
                  {searchWithAttach ? "Attachments" : activeTab[0].toUpperCase() + activeTab.substring(1)}{" "}
                </div>
                {showTabDropdown && (
                  <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
                    <div className="workflow-change-ddown">
                      <ul className="workflow-change-ddown-inner">
                        <li
                          className={activeTab === "comments" && !searchWithAttach ? "active" : ""}
                          onClick={() => this.onSelectDownOption("comments")}
                        >
                          Comments
                        </li>
                        <li
                          className={activeTab === "checklists" ? "active" : ""}
                          onClick={() => this.onSelectDownOption("checklists")}
                        >
                          Checklists
                        </li>
                        <li
                          className={activeTab === "comments" && searchWithAttach ? "active" : ""}
                          onClick={() => this.onSelectDownOption("comments_with_attach")}
                        >
                          Attachments
                        </li>
                      </ul>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
            <div ref={node => (this.outerDivNode = node)}>
            <div
              className="apc-dot-holder"
              onClick={this.onOptionIconClick}
            >
                  <img src={dotIcon} alt="menu-option" />
                  {this.state.showOptionIcon && (
                    <OutsideClickHandler
                      onOutsideClick={this.onOptionOutside}
                    >
                      <div className="apc-options-block">
                        <div
                          className={`apc-options-list ${selectedOption === "All" ? "active" : ""}`}
                          onClick={() => this.onSelectOption("All")}
                        >
                          All
                        </div>
                        <div
                          className={`apc-options-list ${selectedOption === "Bookmarked" ? "active" : ""}`}
                          onClick={() => this.onSelectOption("Bookmarked")}
                        >
                          Bookmarked
                        </div>
                        {activeTab !== "checklists" && <div
                          className={`apc-options-list ${selectedOption === "Liked" ? "active" : ""}`}
                          onClick={() => this.onSelectOption("Liked")}
                        >
                          Liked
                        </div>}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                </div>
            <div className="search-close" onClick={this.onCloseSearch}>
              <img src={CloseIcon} alt="close-icon" />
            </div>
          </div>
        )}

        {clickedType && (
          <div className="apc-tab-content-container">
            {clickedType === "comments" && this.state.activeTab === "comments" && (
              <div className="apc-tab-content-holder">
                <Comments
                  activityDetails={activityDetails}
                  searchText={searchText}
                  selectedOption={selectedOption}
                  searchWithAttachment={this.state.searchWithAttach}
                  searchHasMore={this.state.searchHasMore}
                  updateSearchHasMore={this.updateSearchHasMore}
                  searchPageNumber={this.state.searchPageNumber}
                  updatePageNumber={this.updatePageNumber}
                  clickedCommentId={this.props.clickedId}
                  fromNotification={this.props.fromNotification}
                  is_priority={is_priority}
                  is_archived={is_archived}
                  updateFetch={this.updateFetch}
                  closeDisplayDialogWrapper={
                    this.props.closeDisplayDialogWrapper
                  }
                  parentCommentId={this.props.parentCommentId}
                  source={this.props.source}
                />
              </div>
            )}
            {/* Complaring clickedType "comment" and  this.state.activeTab */}
            {clickedType === "comments" &&
              this.state.activeTab === "checklists" && (
                <div className="apc-tab-content-holder">
                  <Checklist
                    activityDetails={activityDetails}
                    searchText={searchText}
                    selectedOption={selectedOption}
                    is_archived={is_archived}
                    is_priority={is_priority}
                    updateFetch={this.updateFetch}
                    closeDisplayDialogWrapper={
                      this.props.closeDisplayDialogWrapper
                    }
                    source={this.props.source}
                  ></Checklist>
                </div>
              )}
            {clickedType === "checklists" &&
              this.state.activeTab === "checklists" && (
                <div className="apc-tab-content-holder">
                  <Checklist
                    activityDetails={activityDetails}
                    searchText={searchText}
                    selectedOption={selectedOption}
                    is_archived={is_archived}
                    clickedChecklistId={this.props.clickedId}
                    checklistItemId={this.props.checklistItemId}
                    fromNotification={this.props.fromNotification}
                    is_priority={is_priority}
                    updateFetch={this.updateFetch}
                    closeDisplayDialogWrapper={
                      this.props.closeDisplayDialogWrapper
                    }
                    source={this.props.source}
                  ></Checklist>
                </div>
              )}
            {/* Complaring clickedType "checklists" and  this.state.activeTab */}
            {clickedType === "checklists" &&
              this.state.activeTab === "comments" && (
                <div className="apc-tab-content-holder">
                  <Comments
                    activityDetails={activityDetails}
                    searchText={searchText}
                    selectedOption={selectedOption}
                    searchWithAttachment={this.state.searchWithAttach}
                    searchHasMore={this.state.searchHasMore}
                    updateSearchHasMore={this.updateSearchHasMore}
                    searchPageNumber={this.state.searchPageNumber}
                    updatePageNumber={this.updatePageNumber}
                    is_priority={is_priority}
                    updateFetch={this.updateFetch}
                    is_archived={is_archived}
                    closeDisplayDialogWrapper={
                      this.props.closeDisplayDialogWrapper
                    }
                    source={this.props.source}
                  />
                </div>
              )}
            {clickedType === "attachments" &&
              this.state.activeTab === "comments" && (
                <div className="apc-tab-content-holder">
                  <Comments
                    activityDetails={activityDetails}
                    searchText={searchText}
                    selectedOption={selectedOption}
                    searchWithAttachment={this.state.searchWithAttach}
                    searchHasMore={this.state.searchHasMore}
                    updateSearchHasMore={this.updateSearchHasMore}
                    searchPageNumber={this.state.searchPageNumber}
                    updatePageNumber={this.updatePageNumber}
                    is_priority={is_priority}
                    updateFetch={this.updateFetch}
                    is_archived={is_archived}
                    closeDisplayDialogWrapper={
                      this.props.closeDisplayDialogWrapper
                    }
                    source={this.props.source}
                  />
                </div>
              )}
            {clickedType === "attachments" &&
              this.state.activeTab === "checklists" && (
                <div className="apc-tab-content-holder">
                  <Checklist
                    activityDetails={activityDetails}
                    searchText={searchText}
                    selectedOption={selectedOption}
                    is_archived={is_archived}
                    is_priority={is_priority}
                    updateFetch={this.updateFetch}
                    closeDisplayDialogWrapper={
                      this.props.closeDisplayDialogWrapper
                    }
                    source={this.props.source}
                  ></Checklist>
                </div>
              )}
          </div>
        )}
        {!clickedType && (
          <div className="apc-tab-content-container">
            {this.state.activeTab === "comments" && (
              <div className="apc-tab-content-holder">
                <Comments
                  activityDetails={activityDetails}
                  searchText={searchText}
                  selectedOption={selectedOption}
                  searchWithAttachment={this.state.searchWithAttach}
                  searchHasMore={this.state.searchHasMore}
                  updateSearchHasMore={this.updateSearchHasMore}
                  searchPageNumber={this.state.searchPageNumber}
                  updatePageNumber={this.updatePageNumber}
                  is_priority={is_priority}
                  updateFetch={this.updateFetch}
                  is_archived={is_archived}
                  source={this.props.source}
                />
              </div>
            )}

            {this.state.activeTab === "checklists" && (
              <div className="apc-tab-content-holder">
                <Checklist
                  activityDetails={activityDetails}
                  searchText={searchText}
                  selectedOption={selectedOption}
                  is_archived={is_archived}
                  is_priority={is_priority}
                  updateFetch={this.updateFetch}
                  source={this.props.source}
                ></Checklist>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    totalActivities: [
      ...state.activityReducer.prioritiZedActivity.list,
      ...state.activityReducer.listOfActivity.list,
      ...state.activityReducer.listOfArchivedActivity.list
    ],
    comments: state.commentsReducer.comments,
    checklists: state.checklistReducer.checklists.list,
    allAttachments: state.attachmentsReducer.listOfAttachment.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchComments: (queryParams, pageNumber, callback) => {
      dispatch(searchCommentsAction(queryParams, pageNumber, callback));
    },
    getChecklistAction: ({ queryParams }, callback) => {
      dispatch(getChecklistAction({ queryParams }, callback));
    },
    searchChecklist: (queryParams, callback) => {
      dispatch(searchChecklistAction({ queryParams }, callback));
    },
    getAllAttachmentsDetailsAction: (
      { auth_token, activity_id, customer_id, is_archived },
      callback
    ) => {
      dispatch(
        getAllAttachmentsDetailsAction(
          { auth_token, activity_id, customer_id, is_archived },
          callback
        )
      );
    },
    clearChecklistAction: () => {
      dispatch(clearChecklistAction());
    },
    clearSearchedCommentsAction(){
      dispatch(clearSearchedCommentsAction());
    },
    clearSearchedChecklistAction: () => {
      dispatch(clearSearchedChecklistAction());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityPopupBottom);
