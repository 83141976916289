import React from "react";
import { useDragLayer } from 'react-dnd';
import ChecklistItem from "./checklistItem";
import { DragTypes } from "../../constants/DragTypes";
import { connect } from "react-redux";




const DragChecklist = (props) => {
    let hoverStyle = {
        position: "fixed",
        pointerEvents: "none",
        top:'0px',
        left:'0px',
        zIndex: 100,
        width: '100%',
        height: '100%',
        
      };
    const {
        item,
        isDragging,
        initialOffset,
        currentOffset
    } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset()
    }))

    if (!isDragging) {
        return null
    }

    function getItemStyles(initialOffset, currentOffset) {
      if (!initialOffset || !currentOffset || item.type === DragTypes.CHECKLIST) {
        return {
          display: "none"
        };
      }
      let { x, y } = currentOffset;

      let transform=null;
      
        let calculatedHeight=y+props.scrollHeight;
       
        transform= `translate(${x}px, ${calculatedHeight}px)`;
      return {
        borderRadius: "10px",
        maxWidth:'650px',
        boxShadow: "0 2px 4px 1px rgba(0, 0, 0, 0.09) ",
        backgroundColor: "#fff",
        padding: "5px 0",
        margin: "5px 0",
        transform,
        WebkitTransform: transform
      };
    }

    function renderItem() {   
        switch (item.type) {
          case DragTypes.CHECKLISTITEM:            
            if (!item.showCompleted) {
              if (!item.is_completed) {
                return (
                  <ChecklistItem
                    key={item.id}
                    toHideAddItemInput={item.toHideAddItemInput}
                    activityDetails={item.activityDetails}
                    isUserPartOfCard={item.isUserPartOfCard}
                    isSuperAdmin={item.isSuperAdmin}
                    checklistItemId={item.checklistItemId}
                    checklistItem={item.checklistItem}
                    checklistItems={item.checklistItems}
                    index={item.index}
                    users={item.users}
                    updateChecklistItemAction={item.updateChecklistItemAction}
                    clickedChecklistId={item.clickedChecklistId}
                    moveCard={item.moveCard}
                    onMoveOfItemToAnotherList={item.onMoveOfItemToAnotherList}
                    onchecklistUpdated={item.onchecklistUpdated}
                  ></ChecklistItem>
                );
              }
            } else {
              return (
                <ChecklistItem
                  key={item.id}
                  toHideAddItemInput={item.toHideAddItemInput}
                  checklistItem={item.checklistItem}
                  activityDetails={item.activityDetails}
                  isUserPartOfCard={item.isUserPartOfCard}
                  isSuperAdmin={item.isSuperAdmin}
                  checklistItemId={item.checklistItemId}
                  checklistItems={item.checklistItems}
                  index={item.index}
                  users={item.users}
                  updateChecklistItemAction={item.updateChecklistItemAction}
                  clickedChecklistId={item.clickedChecklistId}
                  moveCard={item.moveCard}
                  onMoveOfItemToAnotherList={item.onMoveOfItemToAnotherList}
                  onchecklistUpdated={item.onchecklistUpdated}
                ></ChecklistItem>
              );
            }
          break;
          default:
            return null;
        }
      }

    return (
        <div style={hoverStyle}> 
          <div style={getItemStyles(initialOffset,currentOffset)}>     
                {renderItem()}
          </div>      
        </div>
    )

}

const mapStateToProps = (state) => {
  return {
    scrollHeight:state.dialogReducer.scrollHeight
  }  
} 

export default React.memo(connect(mapStateToProps, null)(DragChecklist));