import { combineForms } from "react-redux-form";

const allRRFFormData = combineForms(
  {
    login: {
      email: "",
      password: ""
    },
    forgot: {
      email: ""
    },
    registration: {
      name: "",
      email: "",
      password: ""
      // confirm_password:''
    },
    resetPassword: {
      password: "",
      retypePassword: ""
    },
    workpsaceForm: {
      display_name: "",
      customer_id: "",
      weekly_working_day: 0,
      daily_working_hrs: 0
    },
    profile: {
      name: "",
      userName: "",
      email: "",
      password: "",
      team: ""
    },
    changePassword: {
      old_password: {
        isValid: false,
        value: "",
        errMsg: null
      },
      new_password: {
        isValid: false,
        value: "",
        errMsg: null
      },
      confirm_password: {
        isValid: false,
        value: "",
        errMsg: null
      }
    },
    projectSettingsForm: {
      name: "",
      group: "",
      status: "",
      data: {
        start: "",
        due: "",
        settings: {
          estimated_hours: ""
        }
      }
    }
  },
  "rrfState"
);

export default allRRFFormData;
