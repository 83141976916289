import React, { Component } from "react";
import "../../styles/scss/notifications.scss";

import notify_attachment from "../../styles/img/svg/notify-attachment.svg";
import "../../styles/scss/notifications.scss";
import { commonDateFormat } from "../../helpers/generichelper";
import { Tooltip } from "@material-ui/core";
import {
  changeIsReadStatusOfYouTabAction,
  changeFlagStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfAllTabAction,
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import firebase from "../../firebase";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, searchText },
          callback
        )
      );
    },
  };
}
class AttachmentNotify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  youNotificationReadStatus = (event, notification) => {
    firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
    event.stopPropagation();
    if (notification.is_read === false) {
      notification.is_read = true;
    } else if (notification.is_read === true) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      searchText,
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        searchText,
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        searchText,
      });
    }
  };
  notificationFlagStatus = (event, notification) => {
    firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
    event.stopPropagation();
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }
    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      searchText,
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab({
        notificationArray,
        ddownOption,
        searchText,
      });
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab({
        notificationArray,
        ddownOption,
        searchText,
      });
    }
  };
  render() {
    const { notification } = this.props;
    return (
      <div
        className="notify-card-others attachment"
        onClick={() => this.props.goToAttachment(notification)}
      >
        <Tooltip
          title={notification.is_read ? "Mark as unread" : "Mark as read"}
        >
          <div
            className={
              notification.is_read === true
                ? "notify-read-unread read"
                : "notify-read-unread"
            }
            onClick={(event) =>
              this.youNotificationReadStatus(event, notification)
            }
          ></div>
        </Tooltip>
        <Tooltip
          title={
            notification.is_flagged
              ? "Unflag this notification"
              : "Flag this notification"
          }
        >
          <div
            className={
              notification.is_flagged === true
                ? "notifi-flag flagged"
                : "notifi-flag "
            }
            onClick={(event) =>
              this.notificationFlagStatus(event, notification)
            }
          ></div>
        </Tooltip>
        <div className="notify-card-left with-user">
          <div className="notify-user-img">
          {notification.sender_info.img ? <img src={notification.sender_info.img} alt={notification.sender_info.first_name} /> : 
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={notification.sender_info.first_name}
                maxInitials={1}
                />
            }
          </div>
          <div className="notify-type-icon">
            <img src={notify_attachment} alt="attachment-icon" />
          </div>
        </div>
        <div className="notify-card-right">
          {notification.type === "ATTACHMENT" &&
            !notification.message.includes("deleted a attachment") && (
              <div
                className="notify-card-title"
                // onClick={() => this.props.goToAttachment(notification)}
                style={{ cursor: "pointer" }}
              >
                {notification.sender_info.first_name} attached
                <span class="activity-title">
                  {" " + notification.data.attachment.title + " "}
                </span>
                to the activity{" "}
                <span class="activity-title">
                  {" "}
                  {notification.data.activity.title}{" "}
                </span>
                {notification.data.project && (
                  <>
                    {" "}
                    under the project{" "}
                    <span class="activity-title">
                      {" "}
                      {notification.data.project.name}{" "}
                    </span>
                  </>
                )}
              </div>
            )}
          {notification.type === "DELETE_ATTACHMENT" && (
            <div class="notify-card-title">
              {notification.sender_info.first_name} deleted{" "}
              <span class="user-removed">
                {notification.data.attachment.title + " "}
              </span>
              from the activity{" "}
              <span class="user-removed">
                {notification.data.activity.title}
              </span>
              <>
                {" "}
                under the project{" "}
                <span class="user-removed">
                  {notification.data.project.name}
                </span>{" "}
              </>
            </div>
          )}
          <p class="post_time">{commonDateFormat(notification.created_at)}</p>
        </div>
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(AttachmentNotify);
