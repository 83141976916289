import React, { Component } from "react";
import searchField from "../../styles/img/svg/settings-search-icon1.svg";

import checkMark from "../../styles/img/svg/checkmark.svg";
import _ from "lodash";
import * as action from "../../actions/index";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import firebase from "../../firebase";

const mapDispatchToProps = dispatch => {
  return {
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    }
  };
};
class AssignProjectSearchBox extends Component {
  state = {
    searchText: "",
    updatedDisplayedProjects: null,
    selectedProject: []
  };
  onChangeSearch = event => {
    let searchText = event.target.value;
    this.setState({ searchText });
    let updatedDisplayedProjects = this.props.projectsForSearchBox.filter(
      project =>
        project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    this.setState({ searchText, updatedDisplayedProjects });
  };
  onCancel = event => {
    event.stopPropagation();
    this.props.onHideSearch();
  };
  onSelectWorkSpaceUser = (_event, selectedProject) => {
    const { projectsForSearchBox } = this.props;

    if (
      this.state.selectedProject.filter(
        project => project.id === selectedProject.id
      ).length > 0
    ) {
      return this.setState({
        selectedProject: this.state.selectedProject.filter(
          project => project.id !== selectedProject.id
        )
      });
    }
    const projectSelected = projectsForSearchBox.filter(
      project => project.id === selectedProject.id
    )[0];
    this.setState({
      selectedProject: [...this.state.selectedProject, projectSelected]
    });
  };

  addProjectToPeople = e => {
    const { selectedProject } = this.state;
    const { userToShow, isSelectedUserAdmin, currentWorkSpaceId, updateProjectAction } = this.props;
    this.onCancel(e);
    selectedProject.forEach(project => {
      const acl =
        project.data &&
        project.data.settings &&
        !_.isUndefined(project.data.acl)
          ? [...project.data.acl, userToShow.id]
          : [userToShow.id];
      const member_access = project.data &&
        project.data.settings &&
        project.data.settings.member_access && {
          ...project.data.settings.member_access,
          [userToShow.id]: {
            access: isSelectedUserAdmin ? "Owner" : "Member",
            team:
              userToShow.data &&
              userToShow.data.settings &&
              userToShow.data.settings[currentWorkSpaceId.id]
                ? userToShow.data.settings[currentWorkSpaceId.id].team
                : null,
            widget: isSelectedUserAdmin ? ["Reports", "Add/Remove members"] : []
          }
        };

      const updateProject = {
        id: project.id,
        data: {
          ...project.data,
          acl,
          settings: {
            ...project.data.settings,

            member_access
          }
        }
      };
      const auth_token = localStorage.getItem("customer_auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: updateProject },
        response => {
          if (response && response.id) {
            firebase.analytics().logEvent("GTD_People_Project_Assigned");
          }
        }
      );
    });
  };

  render() {
    let searchBoxProjects = [];
    if (_.isNull(this.state.updatedDisplayedProjects) === true) {
      searchBoxProjects = this.props.projectsForSearchBox;
    } else {
      searchBoxProjects = this.state.updatedDisplayedProjects;
    }
    return (
      <div className="edit-board-ddown proj-ddown assign-ppl-ddown">
        <div className="edit-board-search-holder assign-proj-search">
          <input
            type="text"
            className="edit-board-search-control proj-search-box"
            placeholder="Search"
            onChange={this.onChangeSearch}
            value={this.state.searchText}
            autoFocus
          />
          <div className="edit-search-icon-holder">
            <img src={searchField} alt="search-icon" />
          </div>
        </div>

        <div className="search-data-container proj-data-container">
          {searchBoxProjects.length > 0 &&
            searchBoxProjects.map((project, index) => (
              <div
                key={index}
                onClick={event => this.onSelectWorkSpaceUser(event, project)}
                className="search-data-list"
              >
                <div className="user-sqimg-icon project-assign-sqrimg">
                  {!project.img && (
                    <Avatar
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "10px !important"
                      }}
                      name={project.name}
                      maxInitials={1}
                    ></Avatar>
                  )}

                  {project.img && <img src={project.img} alt="project-logo" />}
                </div>
                <div className="user-details-holder user-workspace">
                  <div className="user-name">{project.name}</div>
                  {/* <div className="user-email">{project.group}</div> */}
                </div>
                {this.state.selectedProject.filter(
                  selectproject => selectproject.id === project.id
                ).length > 0 && (
                  <img
                    className="user-add-action check-select"
                    src={checkMark}
                    alt="check-icon"
                  />
                )}
              </div>
            ))}
          {this.props.projectsForSearchBox.length === 0 && !this.state.searchText && (
            <div className="no-result-found">No projects to assign</div>
          )}
          {this.state.searchText && searchBoxProjects.length === 0 && (
            <div className="no-result-found">No results found</div>
          )}
        </div>
        <div className="project-assign-hr" />

        <div className="project-assign-bottom">
          <button className="project-assign-cancel" onClick={this.onCancel}>
            Cancel
          </button>
          <button
            className="applyBtn btn btn-sm btn-primary project-assign-confirm"
            disabled={this.state.selectedProject.length < 1 ? true : false}
            onClick={this.addProjectToPeople}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AssignProjectSearchBox);
