import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";
import { logout } from '../helpers/generichelper';

export const getRefreshToken = (callback) => {
    const url = API_URL + "/api/v1/refresh-token";
    const method = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Origin: API_URL,
          "X-ACCESS-TOKEN": routesHelper.getRefreshToken()
        })
    };
    fetch(url, method).then(response => {
        if(response.status === 401) {
            logout();
        } else {
            response.json().then(jsonRes => {
                for (const k in jsonRes) {
                    const ele = jsonRes[k];
                    localStorage.setItem(k, ele);
                }
                callback && callback(jsonRes);
            })
        }
    })
}