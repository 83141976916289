import React, { Component } from "react";
import { connect } from "react-redux";
import CommonNav from "./commonnav";
// import { getDefaultClientLogo } from "../../helpers/generichelper";
import "../../styles/scss/joinWorkspace.scss";
// import routesHelper from '../../router/routeshelper';
// import {ClipLoader} from 'react-spinners';
import { uploadPhotoToS3Bucket } from "../../actions/common/commonactions";
import { commonContants } from "../../helpers/commonconstants";
import { Loader } from "../common/loader";
import addLogo from "../../styles/img/svg/Add_Logo_Icon.svg";
import { createProject } from "../../services/projectapi";
import routesHelper from "../../router/routeshelper";
import MaterialButton from "../common/materialbutton";
import firebase from "../../firebase";

class CreateFirstProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogo: addLogo,
      isInValidForm: true,
      imageLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    const { router } = this.props;
    const auth_token = routesHelper.getAuthToken();
    if (!auth_token) {
      router.push(routesHelper.getLoginPath());
    }
    // const first_time_logged_in = localStorage.getItem("first_time_logged_in");
    // if (!first_time_logged_in || first_time_logged_in === "null") {
    //   this.props.router.push("/workspace");
    // }
  }

  clickOnUploadFile = () => {
    this.uploadFileRef && this.uploadFileRef.click();
  };

  uploadInitiated = () => {
    const { uploadPhotoToS3BucketAction } = this.props;
    let selectedFiles =
    this.uploadFileRef &&
    this.uploadFileRef.files &&
    this.uploadFileRef.files;
    if(selectedFiles &&
      selectedFiles.length) {
        this.setState(() => ({
          imageLoading: true
        }));
        
        uploadPhotoToS3BucketAction(
          {
            file: selectedFiles[0],
            loaderKey: commonContants.updateCustomerPhotoLoaderAction
          },
          response => {
            if (response && response.success) {
              response.img &&
                this.setState({
                  clientLogo: response.img,
                  imageLoading: false
                });
            }
          }
        );
      }
  };

  // onProjectNameUpdated=()=>{
  //     let textvalue = this.workspaceNameRef.value;
  //     if(textvalue){
  //         this.setState({
  //             isInValidForm: false
  //         })
  //     } else{
  //         this.setState({
  //             isInValidForm: true
  //         })
  //     }
  // }

  onInputKeyUp = (e) => {
    if(e.key === "Enter") this.onFormSubmitted();
  }

  onFormSubmitted = () => {
    let { clientLogo } = this.state;
    const { router } = this.props;
    let user_id = localStorage.getItem("id");
    // let auth_token = localStorage.getItem('auth_token');
    const name = this.workspaceNameRef.value;
    const img = clientLogo === addLogo ? "" : clientLogo;
    if(name) {
    let body = {
      name,
      img,
      // group: name,
      data: {
        acl: [parseInt(user_id)]
      }
    };
    createProject(body, response => {
      if (response && response.id) {
        firebase.analytics().logEvent("GTD_Registration_Flow_Add_Project_Success");
       if(localStorage.getItem("firsttimeprofile")  && localStorage.getItem("firsttimeprofile") === 'true' ){
          return  router.push(routesHelper.welcomePath());
        }
        return router.push(routesHelper.getRootPath());
      }
    });
  }
  if(localStorage.getItem("firsttimeprofile")  && localStorage.getItem("firsttimeprofile") === 'true' ){
    router.push(routesHelper.welcomePath());
  }else{
    router.push(routesHelper.getRootPath());
  }  
    
  };

  render() {
    const { router } = this.props;
    const { clientLogo } = this.state;
    return (
      <div className="joinWorkspace-container">
        <div>
          <CommonNav router={router} />
        </div>
        <div className="nw-att-loading-holder">
          {/* <ClipLoader sizeUnit={"px"} size={100} color={'#0190e9'} loading={createWorkpsaceLoaderState.fetching} /> */}
          {/* <Loader size={100} content={""} loading={true} /> */}
        </div>
        <div className="workspace-main">
          <div className="create-workspace">
            <div className="create-title">Create your first project</div>

            <div className="workspace-profile">
              {/* $$ --Create workspace container starts here-- */}
              <div className="workspace-upload-image project-image-upload">
                <div className="upload-image">
                  <img
                    src={clientLogo}
                    alt="logo"
                    onClick={this.clickOnUploadFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                  />

                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                    ref={ref => {
                      this.uploadFileRef = ref;
                    }}
                    style={{ display: "none" }}
                    onChange={this.uploadInitiated}
                  />
                </div>
                {this.state.imageLoading && (
                  <div className="loader-image-div">
                    <Loader
                      size={100}
                      content={"Uploading Image"}
                      loading={this.state.imageLoading}
                    />
                  </div>
                )}
              </div>

              <div className="workspace-name">
                <input
                  type="text"
                  placeholder="Project name"
                  // onKeyUp={this.onWorkspaceNameUpdated}
                  ref={node => (this.workspaceNameRef = node)}
                  onKeyUp={this.onInputKeyUp}
                  autoFocus
                />
              </div>
            </div>

            <div className="proceed-btn">
              <MaterialButton
                buttonText="Proceed"
                variant="contained"
                className="active-proceed"
                color="primary"
                onClick={this.onFormSubmitted}
              ></MaterialButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        uploadPhotoToS3Bucket(
          {
            file,
            loaderKey
          },
          callback
        )
      );
    }
  };
}

export default connect(null, mapDispatchToProps)(CreateFirstProject);
