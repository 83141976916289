import React from "react";
import closeSvg from "../../styles/img/svg/close_new.svg";

export default function CancelSubscriptionPopup({
  onCancelSubscriptionPopup,
  cancelSubscription
}) {
  return (
    <div className="overlay-billing">
      <div className="cancel-plan">
        <div className="header">
          <p>Cancel subscription</p>
          <img
            src={closeSvg}
            alt="Smiley face"
            height="10"
            width="10"
            onClick={onCancelSubscriptionPopup}
          />
        </div>
        <div className="downgrade">
          <p>Are you sure you want to cancel your paid plan subscription?</p>
        </div>
        <div className="resume-switch">
          <div className="switch-free"></div>
          <button className="switch-free" onClick={cancelSubscription}>
            Cancel subscription
          </button>
        </div>
      </div>
    </div>
  );
}
