import React, { useState, useRef } from "react";
import closeIcon from "../../styles/img/svg/settings-close-icon.svg";
import addLogo from "../../styles/img/svg/Add_Logo_Icon.svg";
import { Loader } from "../common/loader";
import { commonContants } from "../../helpers/commonconstants";
// import { getIsCreator } from "../../helpers/reportlanding";

const AddProjectDialog = ({
  onShowDialog,
  currentWorkSpace,
  uploadPhotoToS3BucketAction,
  createProjectAction,
  onAddProject
}) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [clientLogo, setClientLogo] = useState(addLogo);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);

  const uploadFileRef = useRef();

  const clickOnUploadFile = () => {
    uploadFileRef && uploadFileRef.current.click();
  };

  const uploadInitiated = () => {
    let selectedFiles =
    uploadFileRef &&
    uploadFileRef.current.files &&
    uploadFileRef.current.files;
    if(selectedFiles &&
      selectedFiles.length) {
        setImageLoading(true);

        uploadPhotoToS3BucketAction(
          {
            file: selectedFiles[0],
            loaderKey: commonContants.updateCustomerPhotoLoaderAction
          },
          (response) => {
            if (response && response.success) {
              if (response.img) {
                setClientLogo(response.img);
                setImageLoading(false);
              }
            }
          }
        );
      }
  };

  const onChange = (event) => setName(event.target.value);

  const handleClick = () => {
    if (name === "") {
      setNameErr(true);
    } else {
      const img = clientLogo === addLogo ? "" : clientLogo;

      let body = {
        name,
        img,
        // group: name,
        data: {
          acl: [parseInt(localStorage.getItem("id"))]
        }
      };
      createProjectAction(body, (response) => {
        if (response && response.id) {
          onAddProject(response);
        }
      });
    }
  };

  const onInputKeyUp = (e) => {
    if (e.key === "Enter") handleClick();
  };

  return (
    <React.Fragment>
      <div className="overlay-billing">
        <div className="ppl-settings-add project-settings-add">
          <div className="add-project-title-header">
            <div className="wrap-img-projSet">
              <span className="add-new"> Add a new Project</span>
              <img src={closeIcon} onClick={onShowDialog} alt="close-icon" />
            </div>
          </div>
          <div className="ppl-settings-add-inner ">
            <div className="project-image-name-wrap">
              {/* <Loader size={100} loading={imageLoading} /> */}
              <div className="img-container project-settings-img-add add-project-logo">
                <img
                  src={clientLogo}
                  alt="project-logo"
                  onClick={clickOnUploadFile}
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                />
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                  ref={uploadFileRef}
                  style={{ display: "none" }}
                  onChange={uploadInitiated}
                />
                {imageLoading && (
                  <div className="loader-image-div">
                    <Loader
                      size={100}
                      content={"Uploading Image"}
                      loading={imageLoading}
                    />
                  </div>
                )}
              </div>
              <div className="input-add-container">
                <input
                  type="text"
                  className="input project-settings-first"
                  value={name}
                  onChange={onChange}
                  onKeyUp={onInputKeyUp}
                  autoFocus
                  ng-model="projectName"
                  placeholder="Project name"
                />
                {nameErr && (
                  <p className="err-add">This field cannot be empty</p>
                )}
              </div>
            </div>
            <button className="add-proj-setting" onClick={handleClick}>
              Add
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProjectDialog;
