import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";

//importing actions
// import { fetchTimesheetAction } from "../../actions/activities/activitiesaction";
import {
  archiveOrUnArchiveActivities,
  getPrioritizedActivitiesForWorkspaceAction,
} from "../../actions/workspace/workspaceaction";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import {
  getBacklogActivitiesForWorkLoadAction,
  getActivitiesForWorkLoadAction,
  updateActivitiesAction,
  updateWorkloadActivitiesWorkFlowAction,
  archiveWorkloadActivitiesAction,
  clearWorkloadReducerAction,
  getAllBacklogActivitiesAction,
  updateWorkloadPriorityOrNonPriorityAction,
} from "../../actions/workload/workload";
import * as action from "../../actions/index";
// importing Services
// import { getActivitiesForWorkspace } from "../../services/workspaceapi";

//importing helpers

import { getMonthDateRange } from "../../helpers/workloadHelper";
// importing stylesheets
import "../../styles/scss/workload.scss";

import { daysOfWeek } from "../../helpers/workloadHelper";

// importing Components
import WorkloadProjectSelectArea from "./workloadProjectSelectArea";
import WokloadSearchContentArea from "./wokloadSearchContentArea";
import CaleanderHeader from "./caleanderHeader";
import BacklogProject from "./backlogProject";
import UserList from "./userList.jsx";
import ActivityForm from "../activity/addactivity";
import DialogWrapper from "../common/dialog";
import WorkloadViewComponent from "./workloadViewComponent";
import ActivityPopupTop from "../activity/activityPopupTop";
import ActivityPopupBottom from "../activity/activityPopupBottom";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
import { workloadConstant } from "../../constants/workloadConstant";
import firebase from "../../firebase";
import { getWorkspaceUser } from "../../services/workspaceapi";
// import { element } from "prop-types";

class Workload extends PureComponent {
  state = {
    isCollapsedBacklog: false,
    showAddActivityPopup: false,
    showBacklogAddActivityPopup: false,
    currentdateValue: daysOfWeek(0).days,
    currentMode: {
      id: 1,
      name: "Week",
    },
    selectedUser: null,
    selectedProject: {
      id: 0,
      name: "All Projects",
    },
    searchText: "",
    searchType: "SEARCH_ACTIVITIES",
    showActivityPopup: false,
    isPopupEnableEdit: false,
    fromActivityInnerAdd: false,
    popupActivityDetails: null,
  };

  UNSAFE_componentWillReceiveProps(props) {
    let projectData = props.listOfProjects.find(
      projectData => projectData.id === this.state.selectedProject.id
    );
    if (
      projectData &&
      projectData.data &&
      projectData.data.acl &&
      projectData.data.settings &&
      projectData.data.settings.member_access
    ) {
      let userTobeAdded = projectData.data.acl.filter(
        id => this.state.selectedProject.data.acl.indexOf(id) < 0
      );
      if (userTobeAdded) {
        let selectedProject = _.cloneDeep(this.state.selectedProject);
        userTobeAdded.forEach(userId => {
          selectedProject.data.acl.push(userId);
          selectedProject.data.settings.member_access[userId] = _.cloneDeep(
            projectData.data.settings.member_access[userId]
          );
        });
        this.setState({
          selectedProject: selectedProject,
        });
      }
    }
  }

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Workload_Visit");
    const { workspaceUsers, workspace } = this.props;
    if (!workspace) {
      setTimeout(() => {
        if (!workspaceUsers || workspaceUsers.length <= 0) {
          getWorkspaceUser(response => {
            this.workloadinit(response);
          });
        } else {
          this.workloadinit([]);
        }
      }, 3000);
    } else {
      this.workloadinit([]);
    }

    // getAllCustomerForPeopleAction({}, (response) => {
    //   this.props.getPrioritizedActivitiesForWorkspaceAction(
    //     {
    //       auth_token: localStorage.getItem("customer_auth_token"),
    //       user_id: parseInt(logedinUserDetails.id),
    //       activity_type: "ACTIVITY",
    //       is_archived: false,
    //       is_active: true
    //     },
    //     res => {}
    //   );
    //   getAllBacklogActivities('?activity_type=ACTIVITY&no_users=true&is_active=true&is_archived=false');
    //   if (response && !response.stdErr && logedinUserDetails) {
    //     // if(!workspace){
    //     //   getWorkspaceUser();
    //     // }
    //     let isSuperAdmin = getIsSuperAdmin(logedinUserDetails, workspace);
    //     if (isSuperAdmin) {
    //       this.setState(
    //         {
    //           selectedUser:
    //             this.state.selectedUser ||
    //             _.sortBy(
    //               workspaceUsers,
    //               [(workspaceUser) => workspaceUser.first_name.toLowerCase()],
    //               ["asc"]
    //             )[0]
    //         },
    //         () => {
    //           const ids = response.map((el) => el.id);
    //           getActivitiesForWorkLoad({ ids }, (res) => {});
    //         }
    //       );
    //     } else {
    //       this.setState(
    //         {
    //           selectedUser: this.props.workspaceUsers.filter(
    //             (el) => el.id === parseInt(logedinUserDetails.id)
    //           )[0]
    //         },
    //         () => {
    //           const ids = response
    //             .filter(
    //               (el) => parseInt(el.id) == parseInt(logedinUserDetails.id)
    //             )
    //             .map((el) => el.id);
    //           getActivitiesForWorkLoad({ ids }, (res) => {});
    //         }
    //       );
    //     }
    //   }
    // });

    // if (router.location.state && router.location.state.selectedProject) {
    //   const { selectedProject } = router.location.state;
    //   filteredWorkspaceUsers = _.filter(workspaceUsers, (workspaceUser) => {
    //     if (getIsSuperAdmin(workspaceUser, workspace)) return true;
    //     if (
    //       selectedProject.id !== 0 &&
    //       selectedProject.data.settings.member_access.hasOwnProperty(
    //         workspaceUser.id
    //       )
    //     )
    //       return true;
    //     if (selectedProject.id === 0) return true;
    //   });
    //   if (!getIsSuperAdmin(logedinUserDetails, workspace)) {
    //     filteredWorkspaceUsers = _.filter(
    //       workspaceUsers,
    //       (workspaceUser) =>
    //         parseInt(logedinUserDetails.id) === workspaceUser.id
    //     );
    //   }
    //   this.setState({
    //     selectedProject,
    //     selectedUser: _.sortBy(
    //       filteredWorkspaceUsers,
    //       [(workspaceUser) => workspaceUser.first_name.toLowerCase()],
    //       ["asc"]
    //     )[0]
    //   });
    // }
    // getBacklogActivitiesForWorkLoad({}, response => {
    //   console.log("backlog activities", response)
    // })
  }

  workloadinit = workspaceuserData => {
    const {
      // getBacklogActivitiesForWorkLoad,
      getActivitiesForWorkLoad,
      getAllCustomerForPeopleAction,
      workspaceUsers,
      logedinUserDetails,
      workspace,
      router,
      getAllBacklogActivities,
    } = this.props;
    let filteredWorkspaceUsers;
    getAllCustomerForPeopleAction({}, response => {
      if (!response.stdErr && response?.length >= 0) {
        this.props.getPrioritizedActivitiesForWorkspaceAction(
          {
            auth_token: localStorage.getItem("customer_auth_token"),
            user_id: parseInt(logedinUserDetails.id),
            activity_type: "ACTIVITY",
            is_archived: false,
            is_active: true,
          },
          res => {}
        );
        getAllBacklogActivities(
          "?activity_type=ACTIVITY&no_users=true&is_active=true&is_archived=false"
        );
        if (response && !response.stdErr && logedinUserDetails) {
          let isSuperAdmin = getIsSuperAdmin(logedinUserDetails, workspace);
          if (isSuperAdmin) {
            this.setState(
              {
                selectedUser:
                  this.state.selectedUser ||
                  _.sortBy(
                    workspaceUsers && workspaceUsers.length > 0
                      ? workspaceUsers
                      : workspaceuserData,
                    [workspaceUser => workspaceUser.first_name.toLowerCase()],
                    ["asc"]
                  )[0],
              },
              () => {
                const ids = response.map(el => el.id);
                getActivitiesForWorkLoad({ ids }, res => {});
              }
            );
          } else {
            this.setState(
              {
                selectedUser:
                  this.props.workspaceUsers &&
                  this.props.workspaceUsers.length > 0
                    ? this.props.workspaceUsers.filter(
                        el => el.id === parseInt(logedinUserDetails.id)
                      )[0]
                    : workspaceuserData.filter(
                        el => el.id === parseInt(logedinUserDetails.id)
                      )[0],
              },
              () => {
                const ids = response
                  .filter(
                    el => parseInt(el.id) === parseInt(logedinUserDetails.id)
                  )
                  .map(el => el.id);
                getActivitiesForWorkLoad({ ids }, res => {});
              }
            );
          }
        }
      }
    });

    if (router.location.state && router.location.state.selectedProject) {
      const { selectedProject } = router.location.state;
      filteredWorkspaceUsers = _.filter(
        workspaceUsers && workspaceUsers.length > 0
          ? workspaceUsers
          : workspaceuserData,
        workspaceUser => {
          if (getIsSuperAdmin(workspaceUser, workspace)) return true;
          if (
            selectedProject.id !== 0 &&
            selectedProject.data.settings.member_access.hasOwnProperty(
              workspaceUser.id
            )
          )
            return true;
          if (selectedProject.id === 0) return true;
        }
      );
      if (!getIsSuperAdmin(logedinUserDetails, workspace)) {
        filteredWorkspaceUsers = _.filter(
          workspaceUsers && workspaceUsers.length > 0
            ? workspaceUsers
            : workspaceuserData,
          workspaceUser => parseInt(logedinUserDetails.id) === workspaceUser.id
        );
      }
      this.setState({
        selectedProject,
        selectedUser: _.sortBy(
          filteredWorkspaceUsers,
          [workspaceUser => workspaceUser.first_name.toLowerCase()],
          ["asc"]
        )[0],
      });
    }
  };

  componentWillUnmount() {
    this.props.clearWorkloadReducerAction();
  }

  onSearchInitiated = searchText => {
    this.setState({
      searchText: searchText,
    });
  };

  onUserSelected = user => {
    this.setState({
      selectedUser: user,
    });
  };

  onShowActivityPopup = ({ defaultDate }) => {
    this.setState({
      showAddActivityPopup: true,
      defaultDate: defaultDate,
    });
  };

  onShowBacklogActivityPopup = () => {
    this.setState({
      showBacklogAddActivityPopup: true,
    });
  };

  onCloseActivityPopup = () => {
    this.setState({
      showBacklogAddActivityPopup: false,
      showAddActivityPopup: false,
    });
  };

  onConfirm = () => {};

  onPopupCloseEditCallBack = () => {
    this.setState({
      isPopupEnableEdit: false,
      fromActivityInnerAdd: false,
      // isPopupEnableInner: true
    });
  };

  handleClickEditCallback = (activity, fromActivityInnerAdd) => {
    this.setState({
      isPopupEnableEdit: true,
      fromActivityInnerAdd: fromActivityInnerAdd ? fromActivityInnerAdd : false,
      selectedActivity: activity,
    });
  };

  onPopupCloseCallBack = () => {
    this.setState({
      showActivityPopup: false,
    });
  };

  onBacklogCollapsed = () => {
    this.setState({
      isCollapsedBacklog: !this.state.isCollapsedBacklog,
    });
  };

  onSettingCurrentMode = (currentMode, dateValue) => {
    if (currentMode && currentMode.id === 0)
      firebase.analytics().logEvent("GTD_Workload_Day_View");
    if (currentMode && currentMode.id === 1)
      firebase.analytics().logEvent("GTD_Workload_Week_View");
    this.setState({
      currentdateValue: dateValue,
      currentMode: currentMode,
    });
  };

  onProjectSelected = selectedProject => {
    const { workspaceUsers, workspace, logedinUserDetails } = this.props;
    let filteredWorkspaceUsers = _.filter(workspaceUsers, workspaceUser => {
      if (getIsSuperAdmin(workspaceUser, workspace)) return true;
      if (
        selectedProject.id !== 0 &&
        selectedProject.data.settings.member_access.hasOwnProperty(
          workspaceUser.id
        )
      )
        return true;
      if (selectedProject.id === 0) return true;
    });
    if (!getIsSuperAdmin(logedinUserDetails, workspace)) {
      filteredWorkspaceUsers = _.filter(
        workspaceUsers,
        workspaceUser => parseInt(logedinUserDetails.id) === workspaceUser.id
      );
    }
    this.setState({
      selectedProject: selectedProject,
      selectedUser: _.sortBy(
        filteredWorkspaceUsers,
        [workspaceUser => workspaceUser.first_name.toLowerCase()],
        ["asc"]
      )[0],
    });
  };
  onChangingSearchMode = searchType => {
    this.setState({
      searchType: searchType,
    });
  };
  onArchiveActivity = activity => {
    const { logedinUserDetails, archiveWorkloadActivitiesAction } = this.props;
    const { selectedUser } = this.state;
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    // console.log("here", loggedInUser.id, activity.data.archivedUsers);
    const archiveData = {
      id: this.state.popupActivityDetails.id,
      data: {
        ...this.state.popupActivityDetails.data,
        lastModifiedBy: parseInt(logedinUserDetails.id),
        archivedUsers: [...activity.data.archivedUsers, selectedUser.id],
      },
      version_id: activity.version_id,
      completed_on: moment.utc(new Date()).format(),
    };
    archiveWorkloadActivitiesAction(
      {
        auth_token: customer_auth_token,
        activity,
        archiveData,
        user_id: selectedUser.id,
      },
      response => {
        this.onPopupCloseCallBack();
      }
    );
  };

  showActivityPopup = activity => {
    // console.log(activity.activity);
    this.setState({
      popupActivityDetails: activity.activity,
      showActivityPopup: true,
    });
  };

  updateWorkloadPriorityOrNonPriorityAction = (
    { auth_token, archiveData, type },
    callback
  ) => {
    let popupActivityDetailsData = _.cloneDeep(this.state.popupActivityDetails);
    popupActivityDetailsData.data = archiveData.data;
    this.props.updateWorkloadPriorityOrNonPriorityAction(
      { auth_token, archiveData, type, popupActivityDetailsData },
      callback
    );
  };

  render() {
    const {
      listOfProjects,
      logedinUserDetails,
      fetchTimesheetAction,
      getUserDetailsAction,
      // totalActivityCount,
      // prioritiZedActivity,
      workspaceUsers,
      workspace,
      listOfActivities,
      updateActivitiesAction,
      // archiveWorkloadActivitiesAction,
      // archiveOrUnArchiveActivitiesAction,
      updateWorkloadActivitiesWorkFlowAction,
      router,
      listOfBacklogActivites,
    } = this.props;

    const {
      showAddActivityPopup,
      showBacklogAddActivityPopup,
      isPopupEnableEdit,
      isCollapsedBacklog,
      popupActivityDetails,
      currentMode,
      currentdateValue,
      selectedUser,
      selectedProject,
      searchText,
      searchType,
    } = this.state;

    let activitybacklogList = null;

    if (
      searchText &&
      searchType &&
      searchType.length > 0 &&
      searchType === "SEARCH_ACTIVITIES" &&
      searchText.length > 0
    ) {
      let listOfBacklogActivitesCopy = _.cloneDeep(listOfBacklogActivites);

      listOfBacklogActivitesCopy.forEach(element => {
        element.activityList = element.activityList.filter(activity => {
          return (
            activity.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          );
        });
      });
      activitybacklogList = listOfBacklogActivitesCopy.filter(
        element => element.activityList && element.activityList.length > 0
      );
    } else {
      activitybacklogList = listOfBacklogActivites;
    }

    let selecteduserid = selectedUser && selectedUser.id;
    let dialogProps = {
      className: "create_activity",
      showPopup: showAddActivityPopup,
      dialogTitle: () => {
        // if (totalActivityCount === 0) {
        //   return "Add Your First Activity";
        // }
        return "Add Activity";
      },
      onCloseCallBack: this.onCloseActivityPopup,
      onConfirmCallBack: () => {},
      shouldButtonEnable: true,
    };

    let dialogPropsBacklog = {
      className: "create_activity",
      showPopup: showBacklogAddActivityPopup,
      dialogTitle: () => {
        // if (totalActivityCount === 0) {
        //   return "Add Your First Activity";
        // }
        return "Add Activity";
      },
      onCloseCallBack: this.onCloseActivityPopup,
      onConfirmCallBack: () => {},
      shouldButtonEnable: true,
    };

    let dialogPropsEdit = {
      className: "create_activity",
      showPopup: isPopupEnableEdit,
      dialogTitle: () => "Edit Actvity",
      onCloseCallBack: this.onPopupCloseEditCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true,
    };

    const dialogPropsActivityPopup = {
      className: "activity-popup-container",
      showPopup: this.state.showActivityPopup,
      dialogTitle: () => {},
      onCloseCallBack: this.onPopupCloseCallBack,
      shouldButtonEnable: true,
      shouldScrollUpdate: true,
    };

    let isSuperAdmin = getIsSuperAdmin(logedinUserDetails, workspace);

    let listOfActivity =
      selectedUser &&
      listOfActivities.filter(el => el.id === selectedUser.id).length > 0
        ? listOfActivities.filter(el => el.id === selectedUser.id)[0].activities
        : [];

    if (selectedProject.id !== 0) {
      listOfActivity = listOfActivity.filter(
        activity =>
          (activity.project_id || activity.project.id) === selectedProject.id
      );
    }

    let filteredActivity = [];
    // let tempFiltered;
    let filteredActivityMapped = [];
    let noOfGrids;
    let datesArray;
    let groupedByDate = [];
    let previousIndexMap;
    let previousIndexArray;
    let groupByCopy = [];
    let userId = localStorage.getItem("id");

    if (listOfActivity) {
      switch (currentMode.id) {
        case 0:
          filteredActivity =
            listOfActivity &&
            listOfActivity.filter(activity => {
              const selected_user_duration =
                activity.users_duration &&
                activity.users_duration.length > 0 &&
                activity.users_duration.filter(
                  user => user.user_id === parseInt(selecteduserid)
                )[0];
              if (
                selected_user_duration &&
                selected_user_duration.start_date &&
                selected_user_duration.end_date
              ) {
                return moment(currentdateValue).isBetween(
                  selected_user_duration.start_date,
                  selected_user_duration.end_date,
                  "days",
                  "[]"
                );
              } else if (
                selected_user_duration &&
                selected_user_duration.continuous &&
                selected_user_duration.start_date
              ) {
                return moment(currentdateValue).isBetween(
                  selected_user_duration.start_date,
                  activity.due_on,
                  "days",
                  "[]"
                );
              }
              return moment(currentdateValue).isBetween(
                activity.start_on,
                activity.due_on,
                "days",
                "[]"
              );
            });

          groupedByDate[currentdateValue] = filteredActivity;

          filteredActivityMapped = !_.isNull(filteredActivity)
            ? _.sortBy(
                filteredActivity,
                activity => {
                  return moment(activity.created_at);
                },
                ["asc"]
              ).map(activity => {
                return {
                  activity: { ...activity },
                  startIndex: 0,
                  lastIndex: 0,
                };
              })
            : [];

          datesArray = currentdateValue;
          noOfGrids = 1;
          break;
        case 1:
          let startdayweeek = currentdateValue[0];
          let enddayweeek = currentdateValue[6];

          filteredActivity =
            listOfActivity &&
            listOfActivity.filter(activity => {
              if (
                !activity.user_id ||
                !activity.user_id.includes(selecteduserid)
              ) {
                return false;
              }
              const selected_user_duration =
                activity.users_duration &&
                activity.users_duration.length > 0 &&
                activity.users_duration.filter(
                  user => user.user_id === parseInt(selecteduserid)
                )[0];
              if (
                activity.user_id &&
                activity.user_id.includes(selecteduserid) &&
                selected_user_duration &&
                selected_user_duration.start_date &&
                selected_user_duration.end_date &&
                !selected_user_duration.continuous
              ) {
                return (
                  //   moment(selected_user_duration.start_date).isBetween(
                  //     currentdateValue[0],
                  //     currentdateValue[6],
                  //     "days",
                  //     "[]"
                  //   ) ||
                  //   moment(selected_user_duration.end_date).isBetween(
                  //     currentdateValue[0],
                  //     currentdateValue[6],
                  //     "days",
                  //     "[]"
                  //   )
                  // );

                  moment(startdayweeek).isBetween(
                    selected_user_duration.start_date,
                    selected_user_duration.end_date,
                    "days",
                    "[]"
                  ) ||
                  moment(enddayweeek).isBetween(
                    selected_user_duration.start_date,
                    selected_user_duration.end_date,
                    "days",
                    "[]"
                  ) ||
                  moment(selected_user_duration.start_date).isBetween(
                    startdayweeek,
                    enddayweeek,
                    "days",
                    "[]"
                  ) ||
                  moment(selected_user_duration.end_date).isBetween(
                    startdayweeek,
                    enddayweeek,
                    "days",
                    "[]"
                  )
                );
              } else if (
                selected_user_duration &&
                selected_user_duration.continuous &&
                selected_user_duration.start_date
              ) {
                return (
                  moment(startdayweeek).isSameOrAfter(
                    selected_user_duration.start_date,
                    "days"
                  ) ||
                  moment(enddayweeek).isSameOrAfter(
                    selected_user_duration.start_date,
                    "days"
                  )
                );
              } else {
                return (
                  moment(currentdateValue[0]).isBetween(
                    activity.start_on,
                    activity.due_on,
                    "days",
                    "[]"
                  ) ||
                  moment(currentdateValue[6]).isBetween(
                    activity.start_on,
                    activity.due_on,
                    "days",
                    "[]"
                  )
                );
              }
            });
          _.sortBy(
            currentdateValue,
            date => {
              return moment(date);
            },
            ["asc"]
          ).forEach((date, index) => {
            groupedByDate[index] = filteredActivity.filter(activity => {
              const selected_user_duration =
                activity.users_duration &&
                activity.users_duration.length > 0 &&
                activity.users_duration.filter(
                  user => user.user_id === parseInt(selecteduserid)
                )[0];
              if (
                selected_user_duration &&
                selected_user_duration.start_date &&
                selected_user_duration.end_date
              ) {
                return moment(date).isBetween(
                  selected_user_duration.start_date,
                  selected_user_duration.end_date,
                  "days",
                  "[]"
                );
              } else if (
                selected_user_duration &&
                selected_user_duration.continuous &&
                selected_user_duration.start_date
              ) {
                return moment(date).isBetween(
                  selected_user_duration.start_date,
                  activity.due_on,
                  "days",
                  "[]"
                );
              } else {
                return moment(date).isBetween(
                  activity.start_on,
                  activity.due_on,
                  "days",
                  "[]"
                );
              }
            });
            let groupByDate = groupedByDate[index]
              ? _.sortBy(
                  groupedByDate[index],
                  activity => {
                    return activity.title;
                  },
                  ["asc"]
                )
              : [];
            let length = groupByDate.length;
            if (groupByDate && length > 0 && previousIndexMap) {
              let activityArray = groupByDate.map(activity => activity.id);
              let newArrayIndex = [];
              var intersection = previousIndexArray.filter(obj => {
                return activityArray.indexOf(obj) > -1;
              });

              previousIndexMap.forEach((index, activityId) => {
                if (intersection.indexOf(activityId) > -1) {
                  newArrayIndex[index] = activityId;
                }
              });
              let newArrayIndexPos = 0;

              groupByDate.forEach(activity => {
                if (previousIndexMap.get(activity.id) === undefined) {
                  while (newArrayIndex[newArrayIndexPos] !== undefined) {
                    newArrayIndexPos++;
                  }
                  newArrayIndex[newArrayIndexPos] = activity.id;
                }
              });
              groupByCopy[index] = newArrayIndex;
              previousIndexMap = new Map();
              newArrayIndex.forEach((activity, index) => {
                if (activity !== undefined) {
                  previousIndexMap.set(activity, index);
                }
              });
              previousIndexArray = [...previousIndexMap.keys()];
            } else if (groupByDate && length > 0) {
              let newArray = new Array(length);
              groupByDate.forEach((activityData, index) => {
                newArray[index] = activityData.id;
              });
              groupByCopy[index] = newArray;
              previousIndexMap = new Map(
                groupByDate.map((activity, index) => [activity.id, index])
              );

              previousIndexArray = [...previousIndexMap.keys()];
            } else {
              groupByCopy[index] = groupByDate;
              previousIndexMap = undefined;
              previousIndexArray = undefined;
            }
          });

          groupedByDate = groupByCopy;
          filteredActivityMapped = !_.isNull(filteredActivity)
            ? _.sortBy(
                filteredActivity,
                activity => {
                  return activity.title;
                },
                ["asc"]
              ).map(activity => {
                const selected_user_duration =
                  activity.users_duration &&
                  activity.users_duration.length > 0 &&
                  activity.users_duration.filter(
                    user => user.user_id === parseInt(selecteduserid)
                  )[0];
                let startIndex;
                let lastIndex;
                if (activity)
                  currentdateValue.forEach((date, index) => {
                    if (
                      selected_user_duration &&
                      selected_user_duration.start_date &&
                      selected_user_duration.end_date
                    ) {
                      if (
                        moment(date).isSame(
                          selected_user_duration.start_date,
                          "day"
                        )
                      ) {
                        startIndex = index;
                      }
                      if (
                        moment(date).isSame(
                          selected_user_duration.end_date,
                          "day"
                        )
                      ) {
                        // console.log("due_on matched", date, index)
                        lastIndex = index;
                      }
                    } else if (
                      selected_user_duration &&
                      selected_user_duration.continuous &&
                      selected_user_duration.start_date
                    ) {
                      if (
                        moment(date).isSame(
                          selected_user_duration.start_date,
                          "day"
                        )
                      ) {
                        startIndex = index;
                      }
                      if (moment(date).isSame(activity.due_on, "day")) {
                        // console.log("due_on matched", date, index)
                        lastIndex = index;
                      }
                    } else {
                      if (moment(date).isSame(activity.start_on, "day")) {
                        startIndex = index;
                      }
                      if (moment(date).isSame(activity.due_on, "day")) {
                        // console.log("due_on matched", date, index)
                        lastIndex = index;
                      }
                    }
                  });

                return {
                  activity: { ...activity },
                  startIndex: startIndex ? startIndex : 0,
                  lastIndex: lastIndex === 0 || lastIndex ? lastIndex : 6,
                };
              })
            : [];
          filteredActivityMapped = _.sortBy(
            filteredActivityMapped,
            activity => {
              return activity.startIndex;
            },
            ["asc"]
          );

          datesArray = currentdateValue;
          noOfGrids = currentdateValue.length;
          break;

        case 2:
          const dateRanges = getMonthDateRange(currentdateValue);
          filteredActivity =
            listOfActivity &&
            listOfActivity.filter(activity => {
              return (
                moment(activity.start_on).isBetween(
                  dateRanges[0],
                  dateRanges[dateRanges.length - 1],
                  "days",
                  "[]"
                ) ||
                moment(activity.due_on).isBetween(
                  dateRanges[0],
                  dateRanges[dateRanges.length - 1],
                  "days",
                  "[]"
                )
              );
            });

          _.sortBy(
            dateRanges,
            date => {
              return moment(date);
            },
            ["asc"]
          ).forEach((date, index) => {
            groupedByDate[index] = filteredActivity.filter(activity => {
              // console.log(
              //   "grouping logic",
              //   index,
              //   date,
              //   activity.id,
              //   moment(date).isBetween(
              //     activity.start_on,
              //     activity.due_on,
              //     "days",
              //     "[]"
              //   )
              // );

              return moment(date).isBetween(
                activity.start_on,
                activity.due_on,
                "days",
                "[]"
              );
            });
            // console.log("---------------------");
          });

          filteredActivityMapped = !_.isNull(filteredActivity)
            ? _.sortBy(
                filteredActivity,
                activity => {
                  return moment(activity.created_at);
                },
                ["desc"]
              ).map(activity => {
                var startIndex;
                var lastIndex;
                if (activity)
                  dateRanges.forEach((date, index) => {
                    if (moment(date).isSame(activity.start_on, "day")) {
                      // console.log("activity.start_on", index);
                      startIndex = index;
                    }
                    if (moment(date).isSame(activity.due_on, "day")) {
                      lastIndex = index;
                    }
                  });

                return {
                  activity: { ...activity },
                  startIndex: startIndex ? startIndex : 0,

                  lastIndex: lastIndex ? lastIndex : dateRanges.length,
                };
              })
            : [];

          datesArray = dateRanges;
          noOfGrids = 35;
          break;
        default:
      }
    }

    return (
      <div className="settings-tab-container workload-view">
        <div className="top-bar people-search">
          <div className="ppl-settings-contact-container">
            <div className="ppl-settings-search-area-wl">
              <WorkloadProjectSelectArea
                selectedProject={selectedProject}
                listOfProjects={listOfProjects}
                logedinUserDetails={logedinUserDetails}
                workspace={workspace}
                onProjectSelected={this.onProjectSelected}
              ></WorkloadProjectSelectArea>
              <WokloadSearchContentArea
                onSearchInitiated={this.onSearchInitiated}
                onChangingSearchMode={this.onChangingSearchMode}
              ></WokloadSearchContentArea>
            </div>
          </div>
          <CaleanderHeader
            onSettingCurrentMode={this.onSettingCurrentMode}
            selectedProject={selectedProject}
            router={router}
          ></CaleanderHeader>
        </div>
        <div className="ppl-settings-container">
          <div className="bottom-section display-caleander">
            <BacklogProject
              onShowBacklogActivityPopup={this.onShowBacklogActivityPopup}
              onBacklogCollapsed={this.onBacklogCollapsed}
              searchText={searchText}
              searchType={searchType}
              listOfBacklogActivites={activitybacklogList}
              showActivityPopup={this.showActivityPopup}
            ></BacklogProject>
            <UserList
              workspace={workspace}
              workspaceUsers={
                isSuperAdmin && workspaceUsers && workspaceUsers.length > 0
                  ? workspaceUsers
                  : workspaceUsers.filter(
                      el => el.id === parseInt(logedinUserDetails.id)
                    )
              }
              logedinUserDetails={logedinUserDetails}
              isCollapsedBacklog={isCollapsedBacklog}
              listOfActivities={listOfActivities}
              onUserSelected={this.onUserSelected}
              selectedUser={selectedUser}
              currentMode={currentMode}
              currentdateValue={datesArray}
              selectedProject={selectedProject}
              searchText={searchText}
              searchType={searchType}
            ></UserList>
            <WorkloadViewComponent
              filteredActivity={filteredActivityMapped}
              selectedUser={selectedUser}
              noOfGrids={noOfGrids}
              datesArray={datesArray}
              currentMode={currentMode}
              groupedByDate={groupedByDate}
              updateActivitiesAction={updateActivitiesAction}
              onShowActivityPopup={this.onShowActivityPopup}
              currentdateValue={currentdateValue}
              showActivityPopup={this.showActivityPopup}
            ></WorkloadViewComponent>
          </div>
        </div>
        {showAddActivityPopup && (
          <DialogWrapper dialogProps={dialogProps}>
            <ActivityForm
              logedinUserDetails={logedinUserDetails}
              fetchTimesheetAction={fetchTimesheetAction}
              selectedProject={
                selectedProject.id !== 0 ? selectedProject : null
              }
              defaultUsers={[selectedUser.id]}
              source={workloadConstant.fromWorkload}
              defaultDate={this.state.defaultDate}
              {...dialogProps}
            />
          </DialogWrapper>
        )}{" "}
        {showBacklogAddActivityPopup && (
          <DialogWrapper dialogProps={dialogPropsBacklog}>
            <ActivityForm
              logedinUserDetails={logedinUserDetails}
              fetchTimesheetAction={fetchTimesheetAction}
              selectedProject={
                selectedProject.id !== 0 ? selectedProject : null
              }
              defaultUsers={[]}
              source={workloadConstant.fromWorkload}
              defaultDate={this.state.defaultDate}
              workLoadBacklog={true}
              {...dialogProps}
            />
          </DialogWrapper>
        )}
        {isPopupEnableEdit && (
          <DialogWrapper dialogProps={dialogPropsEdit}>
            <ActivityForm
              getUserDetailsAction={getUserDetailsAction}
              logedinUserDetails={logedinUserDetails}
              fromActivityInnerAdd={this.state.fromActivityInnerAdd}
              source={workloadConstant.fromWorkload}
              selectedActivity={
                this.state.selectedActivity &&
                this.state.selectedActivity.backFlowFlag
                  ? this.state.selectedActivity
                  : listOfActivity.filter(
                      activity => activity.id === popupActivityDetails.id
                    )[0]
              }
              {...dialogPropsEdit}
            />
          </DialogWrapper>
        )}
        {this.state.showActivityPopup && (
          <DialogWrapper dialogProps={dialogPropsActivityPopup}>
            <ActivityPopupTop
              activityDetails={
                popupActivityDetails.backFlowFlag
                  ? popupActivityDetails
                  : listOfActivity.some(
                      activity => activity.id === popupActivityDetails.id
                    )
                  ? listOfActivity
                      .filter(
                        activity => activity.id === popupActivityDetails.id
                      )
                      .map(activity => {
                        if (!activity.workflow) {
                          activity = {
                            ...activity,
                            workflow: { id: 1, name: "Backlog" },
                          };
                          return activity;
                        }
                        return activity;
                      })[0]
                  : popupActivityDetails
              }
              onClosePopup={dialogPropsActivityPopup.onCloseCallBack}
              onArchiveItem={() =>
                this.onArchiveActivity(this.state.popupActivityDetails)
              }
              router={router}
              selectedWorkloadUser={selectedUser}
              source="FROM_ACTIVE_WORKLOAD"
              is_priority={
                popupActivityDetails &&
                popupActivityDetails.data &&
                popupActivityDetails.data.priority.some(
                  useridData => useridData.user_id === parseInt(userId)
                )
              }
              changeWorkFlowAction={updateWorkloadActivitiesWorkFlowAction}
              handleClickEditCallback={this.handleClickEditCallback}
              updateActivitiesAction={updateActivitiesAction}
              fromWorkload={true}
              archiveOrUnArchiveActivitiesAction={
                this.updateWorkloadPriorityOrNonPriorityAction
              }
            />
            <ActivityPopupBottom
              activityDetailsAsProps={this.state.popupActivityDetails}
              is_archived={false}
            />
          </DialogWrapper>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfProjects: state.projectReducer.listOfProjects.list,
    logedinUserDetails: state.loginReducer.loginAuth.user_details,
    totalActivityCount: state.activityReducer.totalActivityCount,
    workspaceUsers: state.peopleReducer.workspaceusers,
    workspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    listOfBacklogActivities: state.workloadReducer.listOfBacklogActivities.list,
    listOfActivities: state.workloadReducer.listOfActivities.list,
    prioritiZedActivity: state.activityReducer.prioritiZedActivity.list,
    listOfBacklogActivites: state.workloadReducer.listOfBacklogActivites,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllCustomerForPeopleAction(_, callback) {
      dispatch(getAllCustomerForPeopleAction({}, callback));
    },
    getBacklogActivitiesForWorkLoad(_, callback) {
      dispatch(getBacklogActivitiesForWorkLoadAction({}, callback));
    },
    getActivitiesForWorkLoad({ ids }, callback) {
      dispatch(getActivitiesForWorkLoadAction({ ids }, callback));
    },
    updateActivitiesAction({ user_id, activity_id, fields }, callback) {
      dispatch(
        updateActivitiesAction({ user_id, activity_id, fields }, callback)
      );
    },
    updateWorkloadActivitiesWorkFlowAction(
      { id, workflow, customer_auth_token, user_id },
      callback
    ) {
      dispatch(
        updateWorkloadActivitiesWorkFlowAction(
          { id, workflow, customer_auth_token, user_id },
          callback
        )
      );
    },
    archiveWorkloadActivitiesAction(
      { auth_token, activity, archiveData, user_id },
      callback
    ) {
      dispatch(
        archiveWorkloadActivitiesAction(
          { auth_token, activity, archiveData, user_id },
          callback
        )
      );
    },
    clearWorkloadReducerAction() {
      dispatch(clearWorkloadReducerAction());
    },
    getUserDetailsAction({ queryparams }, callback) {
      dispatch(action.getUserDetailsAction({ queryparams }, callback));
    },
    archiveOrUnArchiveActivitiesAction(
      { auth_token, archiveData, type },
      callback
    ) {
      dispatch(
        archiveOrUnArchiveActivities(
          { auth_token, archiveData, type },
          callback
        )
      );
    },
    getAllBacklogActivities(params) {
      dispatch(getAllBacklogActivitiesAction({ params }));
    },
    updateWorkloadPriorityOrNonPriorityAction(
      { auth_token, archiveData, type, popupActivityDetailsData },
      callback
    ) {
      dispatch(
        updateWorkloadPriorityOrNonPriorityAction(
          { auth_token, archiveData, type, popupActivityDetailsData },
          callback
        )
      );
    },
    getPrioritizedActivitiesForWorkspaceAction: (
      { auth_token, activity_type, is_active, user_id, is_archived },
      callback
    ) => {
      dispatch(
        getPrioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
          },
          callback
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workload);
