import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import { getBillingAmounts } from "../../services/paymentapis";
import BillingFaqContainer from "./billingFaqContainer";
import BillingPaidPlan from "./billingPaidPlan";
import InjectedBillingPayment from "./billingPayment";
import BillingConfirmation from "./billingConfirmation";
import { Elements } from "react-stripe-elements";

import CancelSubscriptionPopup from "./cancelSubscriptionPopup";
import SwitchToAnnualPopup from "./switchToAnnualPopup";

import { Loader } from "../common/loader";
import "../../styles/scss/billing.scss";

import {
  getCustomerInfo,
  getChargesInfo,
  getCustomerDetails,
  updateCustomerDetails,
  updateCardDetailsAction,
  cancelSubscriptionAction,
  reactivateSubscriptionAction,
  updateSubscriptionAction,
} from "../../actions/billing/billing";
import { getCurrencyLogo } from "../../helpers/generichelper";
import firebase from "../../firebase";

class BiliingView extends PureComponent {
  state = {
    currentStep: 0,
    faqStatus: false,
    paymentType: "monthly",
    cancelSubscriptionPopup: false,
    showAnnualPopup: false,
    showMonthlyPopup: false,
    updateSubscriptionRes: null,
    billingAmounts: {},
  };

  UNSAFE_componentWillMount() {
    firebase.analytics().logEvent("GTD_Billing_Visited");
    const { getCustomerInfo, getChargesInfo, getCustomerDetails } = this.props;
    getBillingAmounts(response => {
      if (response && !response.stdErr) {
        this.setState({ billingAmounts: response });
        getCustomerInfo(response => {
          if (response && !response.error) {
            if (response.data && response.data[0].status === "canceled") {
              return this.setState({
                currentStep: 0,
              });
            }
            if (
              response.data[0].status === "active" &&
              response.data[0].cancel_at_period_end === true
            ) {
              return this.setState({
                currentStep: 2,
                subscriptionStatus: "cancelled",
              });
            }
            getChargesInfo();
            getCustomerDetails();
            this.setState({
              currentStep: 2,
              subscriptionStatus: "active",
            });
          }
        });
      }
    });
  }

  updateBillingAmounts = () => {
    getBillingAmounts(response => {
      if (response && !response.stdErr) {
        this.setState({ billingAmounts: response });
      }
    });
  };

  toggleFaq = () => {
    firebase.analytics().logEvent("GTD_Billing_FAQ");
    this.setState(
      {
        faqStatus: !this.state.faqStatus,
      },
      () => {
        let element = document.getElementById("faq-bill");
        if (element !== null) {
          element.scrollIntoView(false);
        }
      }
    );
  };

  changePaymentType = type => {
    this.setState({
      paymentType: type,
    });
  };

  goto2ndStep = () => {
    firebase.analytics().logEvent("GTD_Billing_Add_Payment");
    this.setState({
      currentStep: 1,
    });
  };

  gotoFinalStep = () => {
    this.setState({
      currentStep: 2,
    });
  };

  onShowCancelSubscriptionPopup = () => {
    this.setState({
      cancelSubscriptionPopup: true,
    });
  };
  onCancelSubscriptionPopup = () => {
    this.setState({
      cancelSubscriptionPopup: false,
    });
  };

  cancelSubscription = () => {
    const { billingInfo, cancelSubscriptionAction } = this.props;
    cancelSubscriptionAction(
      { existingSubscriptionObj: billingInfo },
      response => {
        if (response && !response.error) {
          firebase.analytics().logEvent("GTD_Billing_Cancel_Subscription");
          
        }
      }
    );
    this.setState({
      cancelSubscriptionPopup: false,
    });
  };
  reactivateSubscription = () => {
    const { billingInfo, reactivateSubscriptionAction } = this.props;
    reactivateSubscriptionAction(
      { existingSubscriptionObj: billingInfo });
  };

  hideAnnualPlanPopup = () => {
    firebase.analytics().logEvent("GTD_Billing_Annually_Cancelled");
    this.setState({
      showAnnualPopup: false,
    });
  };

  hideMonthlyPlanPopup = () => {
    this.setState({
      showMonthlyPopup: false,
    });
  };

  onShowAnnualPopup = () => {
    this.setState({
      showAnnualPopup: true,
    });
  };

  updateSubscription = planType => {
    const { billingInfo, updateSubscriptionAction } = this.props;

    updateSubscriptionAction(
      {
        existingSubscriptionObj: billingInfo,
        planType: planType,
      },
      response => {
        
        if (response && !response.error && response.id) {
          if (planType === "monthly")
            firebase.analytics().logEvent("GTD_Billing_Monthly_Selected");
          if (planType === "yearly")
            firebase.analytics().logEvent("GTD_Billing_Annually_Selected");
          this.setState({
            updateSubscriptionRes: response,
          });
          this.updateBillingAmounts();
        }
      }
    );
    this.setState({
      showAnnualPopup: false,
    });
  };
  onShowMonthlyPopup = () => {
    
    this.setState({
      showMonthlyPopup: true,
    });
  };

  render() {
    const {
      currentWorkSpace,
      billingInfo,
      chargesInfo,
      customerDetails,
      updateCustomerDetails,
      updateCardDetailsAction,
      getChargesInfo,
      getCustomerInfo,
      getCustomerDetails,
      updateSubscriptionLoader,
      cancelOrActivateSubscriptionLoader,
    } = this.props;
    const {
      billingAmounts,
      currentStep,
      faqStatus,
      paymentType,
      // subscriptionStatus,
      cancelSubscriptionPopup,
      showAnnualPopup,
      updateSubscriptionRes,
      // showMonthlyPopup
    } = this.state;

    const subscriptionStatus =
      billingInfo.data &&
      billingInfo.data.length > 0 &&
      billingInfo.data[0].status === "active" &&
      billingInfo.data[0].cancel_at_period_end === true
        ? "cancelled"
        : "active";
    return (
      <div className="billing-tab">
        <Scrollbar>
          <div className="nw-att-loading-holder">
            {<Loader size={100} loading={updateSubscriptionLoader.loading} />}
            {
              <Loader
                size={100}
                loading={cancelOrActivateSubscriptionLoader.loading}
              />
            }
          </div>
          <div layout="column" className="popup-body-area">
            <div className="billing-bcrumb-holder ">
              <div className="billing-bcrumb-circle-container">
                <div
                  className={
                    currentStep >= 0
                      ? "billing-bcrumb-circle active"
                      : "billing-bcrumb-circle"
                  }
                >
                  1
                </div>
                <div
                  className={
                    currentStep > 0
                      ? "billing-bcrumb-circle active"
                      : "billing-bcrumb-circle"
                  }
                >
                  2
                </div>
                <div
                  className={
                    currentStep > 1
                      ? "billing-bcrumb-circle active"
                      : "billing-bcrumb-circle"
                  }
                >
                  3
                </div>
              </div>
              <div className="billing-bcrumb-txt-holder">
                <div
                  className={
                    currentStep >= 0
                      ? "billing-bcrumb-txt active"
                      : "billing-bcrumb-txt"
                  }
                >
                  Select Plan
                </div>
                <div
                  className={
                    currentStep > 0
                      ? "billing-bcrumb-txt active"
                      : "billing-bcrumb-txt"
                  }
                >
                  Confirm & Pay
                </div>
                <div
                  className={
                    currentStep > 1
                      ? "billing-bcrumb-txt active"
                      : "billing-bcrumb-txt"
                  }
                >
                  Done!
                </div>
              </div>
            </div>

            <div className="billing-form-container">
              {currentStep === 0 && (
                <div className="biling-pp-container step1">
                  <BillingPaidPlan
                    currentWorkSpace={currentWorkSpace}
                    price={billingAmounts.amount_per_month_user}
                    billingAmounts={billingAmounts}
                    changePaymentType={this.changePaymentType}
                    paymentType={paymentType}
                    goto2ndStep={this.goto2ndStep}
                  ></BillingPaidPlan>
                </div>
              )}
              {currentStep === 1 && (
                <div className="biling-pp-container payment">
                  <Elements>
                    <InjectedBillingPayment
                      currencyLogo={getCurrencyLogo(currentWorkSpace)}
                      currentWorkSpace={currentWorkSpace}
                      price={billingAmounts.amount_per_month_user}
                      billingAmounts={billingAmounts}
                      paymentType={paymentType}
                      getChargesInfo={getChargesInfo}
                      getCustomerInfo={getCustomerInfo}
                      getCustomerDetails={getCustomerDetails}
                      updateBillingAmounts={this.updateBillingAmounts}
                      gotoFinalStep={this.gotoFinalStep}
                    ></InjectedBillingPayment>
                  </Elements>
                </div>
              )}
              {currentStep === 2 && (
                <div className="biling-pp-container confirmation">
                  <Elements>
                    <BillingConfirmation
                      currencyLogo={getCurrencyLogo(currentWorkSpace)}
                      billingAmounts={billingAmounts}
                      subscriptionStatus={subscriptionStatus}
                      billingInfo={billingInfo}
                      chargesInfo={chargesInfo}
                      customerDetails={customerDetails}
                      updateCustomerDetails={updateCustomerDetails}
                      updateCardDetailsAction={updateCardDetailsAction}
                      onShowCancelSubscriptionPopup={
                        this.onShowCancelSubscriptionPopup
                      }
                      reactivateSubscription={this.reactivateSubscription}
                      updateSubscription={this.updateSubscription}
                      updateSubscriptionRes={updateSubscriptionRes}
                      onShowAnnualPopup={this.onShowAnnualPopup}
                      hideAnnualPlanPopup={this.hideAnnualPlanPopup}
                      onShowMonthlyPopup={this.onShowMonthlyPopup}
                    ></BillingConfirmation>
                  </Elements>
                </div>
              )}
            </div>
            {cancelSubscriptionPopup && (
              <CancelSubscriptionPopup
                cancelSubscription={this.cancelSubscription}
                onCancelSubscriptionPopup={this.onCancelSubscriptionPopup}
              ></CancelSubscriptionPopup>
            )}
            {showAnnualPopup && (
              <SwitchToAnnualPopup
                updateSubscription={this.updateSubscription}
                billingInfo={billingInfo}
                hideAnnualPlanPopup={this.hideAnnualPlanPopup}
              ></SwitchToAnnualPopup>
            )}
            {/* {showMonthlyPopup && (
            <SwitchToMonthlyPlan
              updateSubscription={this.updateSubscription}
            ></SwitchToMonthlyPlan>
          )} */}
            <BillingFaqContainer
              currencyLogo={getCurrencyLogo(currentWorkSpace)}
              price={billingAmounts.amount_per_month_user}
              status={faqStatus}
              toggleFaq={this.toggleFaq}
            ></BillingFaqContainer>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    billingInfo: state.billingReducer.billingInfo,
    chargesInfo: state.billingReducer.chargesInfo,
    customerDetails: state.billingReducer.customerDetails,
    updateSubscriptionLoader: state.billingReducer.updateSubscriptionLoader,
    cancelOrActivateSubscriptionLoader:
      state.billingReducer.cancelOrActivateSubscriptionLoader,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomerInfo: callback => {
      dispatch(getCustomerInfo(callback));
    },
    getChargesInfo: callback => {
      dispatch(getChargesInfo(callback));
    },
    getCustomerDetails: callback => {
      dispatch(getCustomerDetails(callback));
    },
    updateCustomerDetails: (paymentObject, callback) => {
      dispatch(updateCustomerDetails(paymentObject, callback));
    },
    updateCardDetailsAction: (
      { id, token, name, address, city, state, zip, country },
      callback
    ) => {
      dispatch(
        updateCardDetailsAction(
          {
            id,
            token,
            name,
            address,
            city,
            state,
            zip,
            country,
          },
          callback
        )
      );
    },
    cancelSubscriptionAction: ({ existingSubscriptionObj }, callback) => {
      dispatch(cancelSubscriptionAction({ existingSubscriptionObj }, callback));
    },
    reactivateSubscriptionAction: ({ existingSubscriptionObj }, callback) => {
      dispatch(
        reactivateSubscriptionAction({ existingSubscriptionObj }, callback)
      );
    },
    updateSubscriptionAction: (
      { existingSubscriptionObj, planType },
      callback
    ) => {
      dispatch(
        updateSubscriptionAction(
          { existingSubscriptionObj, planType },
          callback
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BiliingView);
