import React, { Component } from "react";
import _ from "lodash";
import transactionSuccessSVG from "../../styles/img/svg/transaction-success.svg";
import transactionCancelledSVG from "../../styles/img/svg/plan_canceled.svg";
import BillingForm from "./billingForm";
import { injectStripe } from "react-stripe-elements";

class BillingConfirmation extends Component {
  onUpdatingCardDetails = (
    { name, address, city, state, zip, country },
    callback
  ) => {
    const { updateCardDetailsAction } = this.props;
    this.props.stripe.createToken({ type: "card" }).then((res) => {
      if (res && !res.error) {
        const stripeToken = res.token.id;
        updateCardDetailsAction(
          {
            id: res.token.card.id,
            name,
            address,
            city,
            state,
            zip,
            country,
            token: stripeToken
          },
          (res) => {
            callback && callback(res);
            
          }
        );
      }
    });
  };

  render() {
    const {
      subscriptionStatus,
      currencyLogo,
      billingAmounts,
      billingInfo,
      chargesInfo,
      customerDetails,
      updateCustomerDetails,
      onShowCancelSubscriptionPopup,
      reactivateSubscription,
      updateSubscription,
      updateSubscriptionRes,
      onShowAnnualPopup,
      // hideAnnualPlanPopup,
      onShowMonthlyPopup
    } = this.props;
    const invoiceData =
      customerDetails &&
      customerDetails.sources &&
      customerDetails.sources.data[0];
    
    return (
      <>
        {!_.isUndefined(billingInfo.data) && subscriptionStatus === "active" && (
          <div className="bill-plan-info">
            <div className="bill-plan-info-inner">
              <div className="bill-plan-info-icon">
                <img src={transactionSuccessSVG} alt="transaction-success" />
              </div>
              {billingInfo.data[0].plan.nickname === "Monthly" && (
                <span>{billingInfo.data[0].plan.nickname} Plan is active</span>
              )}
              {billingInfo.data[0].plan.nickname === "Yearly" && (
                <span>Annual Plan is active</span>
              )}
            </div>
          </div>
        )}
        {!_.isUndefined(billingInfo.data) && subscriptionStatus === "cancelled" && (
          <div class="bill-plan-info-fail">
            <div class="bill-plan-info-inner">
              <div class="bill-plan-info-icon">
                <img src={transactionCancelledSVG} alt="transaction-cancel" />
              </div>
              {billingInfo.data[0].plan.nickname === "Monthly" && (
                <span>
                  {billingInfo.data[0].plan.nickname} Plan is cancelled
                </span>
              )}
              {billingInfo.data[0].plan.nickname === "Yearly" && (
                <span>Annual Plan is cancelled</span>
              )}
            </div>
          </div>
        )}
        {/* <div class="bill-plan-info renew" ng-if="subscriptionExpired">
          <div class="bill-plan-info-inner">
            <span>You are on a Free Plan - 5 users</span>
          </div>
        </div> */}
        {!_.isUndefined(billingInfo) && (
          <BillingForm
            currencyLogo={currencyLogo}
            billingAmounts={billingAmounts}
            subscriptionStatus={subscriptionStatus}
            billingInfo={billingInfo}
            chargesInfo={chargesInfo}
            invoiceData={invoiceData}
            customerDetails={customerDetails}
            updateCustomerDetails={updateCustomerDetails}
            onUpdatingCardDetails={this.onUpdatingCardDetails}
            onShowCancelSubscriptionPopup={onShowCancelSubscriptionPopup}
            reactivateSubscription={reactivateSubscription}
            onShowAnnualPopup={onShowAnnualPopup}
            onShowMonthlyPopup={onShowMonthlyPopup}
            updateSubscription={updateSubscription}
            updateSubscriptionRes={updateSubscriptionRes}
          ></BillingForm>
        )}
      </>
    );
  }
}

export default injectStripe(BillingConfirmation);
