import { awsConf } from "../constants/awsconstants";
import AWS from "aws-sdk";

//Optional Import
// import { uploadFile } from "react-s3";

export var abortUpload;

export function uploadDataToS3Bucket({ file, isAttachment }, callback) {
  // const config = {
  //     region: awsConf.region,
  //     secretAccessKey: awsConf.secret_key,
  //     bucketName: awsConf.buckets.name,
  //     dirName: awsConf.buckets.subpaths.attachments ,
  //     accessKeyId: awsConf.access_key,
  //     basePath: awsConf.root_path

  // }

  // const config = {
  //   bucketName: "gtd",
  //   dirName: "attachments" /* optional */,
  //   region: awsConf.region,
  //   accessKeyId: awsConf.access_key,
  //   secretAccessKey: awsConf.secret_key,
  //   s3Url: "https://s3.amazonaws.com/gtd"
  // };

  AWS.config.update({
    accessKeyId: awsConf.access_key,
    secretAccessKey: awsConf.secret_key,
    region: awsConf.region
  });
  // AWS.config.region = config.region;
  var bucket = new AWS.S3({
    params: {
      Bucket: awsConf.buckets.name
    }
  });
  var params = {
    Key: `${awsConf.buckets.subpaths.folder}/${file.name}`,
    ContentType: file.type,
    Body: file,
    ServerSideEncryption: "AES256",
    ACL: "public-read-write"
    // ContentDisposition: "attachment"
  };

  /* turn off the timeout (Optional) */
  AWS.config.httpOptions.timeout = 0;

  // upload file
  var request = bucket.upload(params)
  request.on("httpUploadProgress", function(evt) {
      var uploaded = Math.round((evt.loaded / evt.total) * 100);
      // console.log(`File uploaded: ${uploaded}%`);
      // if (document.querySelector(".upload-percentage")) {
      //   document.querySelector(
      //     ".upload-percentage"
      //   ).innerHTML = `Adding attachment - ${uploaded}%`;
      // }
      callback && callback({ uploadPercentage: uploaded });
    })
    .send(function(err, data) {
      if (err) {
        // an error occurred, handle the error
        // console.log(err, err.stack);
        return;
      }

      var fileUrl = data.Location;
      // console.log("File URL:", fileUrl);

      callback && callback({ success: true, img: fileUrl });
      // alert("File is uploaded successfully!");
    });

    abortUpload = function() { setTimeout(request.abort.bind(request)); }

    // setTimeout(request.abort.bind(request), 1000);

  // bucket.putObject(params, function (err, data) {
  //     if (err) {
  //         // There Was An Error With Your S3 Config
  //         //console.log("upload-failed", err);
  //         callback && callback({success: false})
  //         return false;
  //     } else {
  //         //console.log("upload-success", data);
  //         callback && callback({success: true, 'img': config.basePath + file.name});
  //     }
  // })

  // uploadFile(file, config)
  //   .then((data) => {
  //     // console.log("upload-success", data);

  //     callback && callback({ success: true, img: data.location });
  //   })
  //   .catch((err) => console.error(err));
}
