import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";

// get user details
export function getUserDetails({ queryparams, auth_token }, callback) {
  const auth_token_user = auth_token ? auth_token : routesHelper.getAuthToken();
  let url = queryparams ? "/api/v1/user" + queryparams : "/api/v1/user";
  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token_user
      "X-ACCESS-TOKEN": auth_token_user
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}
// update password
export function updateUserPassword(
  { old_password, new_password, user_id },
  callback
) {
  let url = "/api/v1/update-password";
  secureFetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify({ old_password, new_password, user_id }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function updateUserProfile(
  {
    auth_token,
    first_name,
    id,
    organization_name,
    country,
    email,
    img,
    tags = [],
    data,
    platform = "WEB"
  },
  callback
) {
  let url = "/api/v1/user";
  // console.log("1updateUserProfile is called")
  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify({
      first_name,
      id,
      email,
      img,
      tags,
      organization_name,
      country,
      data,
      platform
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function findUserLike({ key }, callback) {
  let url = key ? "/api/v1/user?like=" + key : "/api/v1/user";
  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //console.log(error);
    });
}


export function updateUserFirstTimeLogin(
  {
    auth_token,
    first_time_logged_in,
    id
  }
) {
  // console.log('calling');
  let url = "/api/v1/user";
  // console.log("1updateUserProfile is called")
  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify({
      first_time_logged_in,
      id
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {})
    .catch((error) => {
      //
    });
}
