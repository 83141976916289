import React, { Component } from "react";

import routesHelper from "../../router/routeshelper";
import "../../styles/scss/forgot_password.scss";
import { connect } from "react-redux";
import { isEmailValid } from "../../helpers/generichelper";
import { message } from "../../constants/messages";
import * as actions from "../../actions/index";
import ForgotPasswordEmailSent from "./forgotpassword/forgotpsswordemailsent";
import ForgotPasswordForm from "./forgotpassword/forgotpasswordform";
import ForgotPasswordGoogleSignIn from "./forgotpassword/forgotpasswordgooglesignin";
import ForgotPasswordAppleSignIn from "./forgotpassword/forgotpasswordapplesignin";
import { toast } from "react-toastify";
const mapStateToProps = state => {
  return {
    forgotPasswordDetails: state.rrfStatereducer.forgot,
    forgotPasswordLoaderState: state.loginReducer.forgotPasswordLoaderState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPasswordAction({ email }, callback) {
      dispatch(actions.forgotPasswordAction({ email }, callback));
    }
  };
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notValidForm: false,
      email: null
    };
  }

  onGoBackToLogin = () => {
    const { router } = this.props;
    router.push(routesHelper.getLoginPath());
  };

  isValidData = () => {
    const { forgotPasswordDetails } = this.props;
    let isValidBoolList = [];
    for (const key in forgotPasswordDetails) {
      if (forgotPasswordDetails.hasOwnProperty(key)) {
        const element = forgotPasswordDetails[key];
        if (!element) {
          isValidBoolList.push(false);
        }
      }
    }
    if (isValidBoolList.indexOf(false) > -1) {
      this.setState({ notValidForm: true });
      return false;
    }
    this.setState({ notValidForm: false });
    return true;
  };

  onFormChange = () => {
    this.isValidData();
  };

  onForgotEmailRequest = () => {
    const { forgotPasswordAction, forgotPasswordDetails } = this.props;
    let noOfErrors = [];
    for (const key in forgotPasswordDetails) {
      if (forgotPasswordDetails.hasOwnProperty(key)) {
        const element = forgotPasswordDetails[key];
        if (key === "email") {
          if (element.length < 1) {
            noOfErrors.push(false);
            this.setState({ [key]: message.filedShouldNotBeEmpty });
          } else if (!isEmailValid(element)) {
            noOfErrors.push(false);
            this.setState({
              email: message.emailNotValid
            });
          } else {
            this.setState({
              email: null
            });
          }
        }
      }
    }
    if (noOfErrors.length === 0) {
      forgotPasswordAction({ email: forgotPasswordDetails.email }, response => {
        if (response && !response.error) {
          toast("Email sent successfully!");
          // console.log("Email sent successfully")
        } else {
          this.setState({
            email: message.emailNotRegistered || "unknown error occurred."
          });
        }
      });
    }
  };

  render() {
    const { forgotPasswordLoaderState } = this.props;
    if (
      forgotPasswordLoaderState &&
      forgotPasswordLoaderState.fetched &&
      forgotPasswordLoaderState.error &&
      forgotPasswordLoaderState.errorMessage === message.signedInWithGoogle
    ) {
      return <ForgotPasswordGoogleSignIn />;
    } else if (forgotPasswordLoaderState &&
      forgotPasswordLoaderState.fetched &&
      forgotPasswordLoaderState.error &&
      forgotPasswordLoaderState.errorMessage === message.signedInWithApple) {
        return <ForgotPasswordAppleSignIn />
    } else if (
      forgotPasswordLoaderState &&
      forgotPasswordLoaderState.fetched &&
      !forgotPasswordLoaderState.error
    ) {
      return (
        <ForgotPasswordEmailSent
          onForgotEmailRequest={this.onForgotEmailRequest}
        />
      );
    } else {
      return (
        <ForgotPasswordForm
          onFormChange={this.onFormChange}
          email={this.state.email}
          notValidForm={this.state.notValidForm}
          onForgotEmailRequest={this.onForgotEmailRequest}
          forgotPasswordLoaderState={forgotPasswordLoaderState}
          onGoBackToLogin={this.onGoBackToLogin}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
