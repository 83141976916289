import { attachmentsConstants } from "../../../src/constants/attachmentconstants";
import {
  getAllAttachmentsDetails,
  updateAttachment,
  addAttachment
} from "../../services/attachmentapi";
import { uploadDataToS3Bucket } from "../../services/awsapi";
import { activities } from "../../constants/activitiescontants";
function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}
function updateAttachmentList(attachmentList) {
  return {
    type: attachmentsConstants.getAllAttachments,
    payload: {
      list: attachmentList,
      key: new Date().getTime().toLocaleString()
    }
  };
}
function updateAttachmentAfterDelete(attachmentList) {
  return {
    type: attachmentsConstants.updateAttachment,
    payload: {
      list: attachmentList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function addNewAttachment(response) {
  return {
    type: attachmentsConstants.addNewAttachment,
    payload: {
      list: response,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateAttachmentCountAction({ activity_id, is_priority }) {
  return {
    type: is_priority
      ? activities.updateAttachmentCount.PRIORITY
      : activities.updateAttachmentCount.NOTPRIORITY,
    payload: {
      activity_id
    }
  };
}
// *****
function updateAttachmentCountActionAfterArchive({ activity_id, is_priority }) {
  return {
    type: is_priority
      ? activities.updateAttachmentCountAfterDelete.PRIORITY
      : activities.updateAttachmentCountAfterDelete.NOTPRIORITY,
    payload: {
      activity_id
    }
  };
}
function updateArchiveCardAttachmentCountAfterDelete({ activity_id }) {
  return {
    type: activities.updateArchiveCardAttachmentCountAfterDelete,
    payload: {
      activity_id
    }
  };
}
function updateArchiveCardAttachmentCount({ activity_id }) {
  return {
    type: activities.updateArchiveCardAttachmentCount,
    payload: {
      activity_id
    }
  };
}
export const getAllAttachmentsDetailsAction = (
  {
    auth_token,
    activity_id,
    is_archived
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: attachmentsConstants.getAllAttachmentsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getAllAttachmentsDetails(
    { auth_token, activity_id, is_archived },
    response => {
      if (response && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: attachmentsConstants.getAllAttachmentsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        dispatch(updateAttachmentList(response));
      } else {
        dispatch(
          updateLoaderState({
            action: attachmentsConstants.getAllAttachmentsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};
export const updateAttachmentAction = (
  {
    auth_token,
    id,
    title,
    description,
    url,
    size,
    activity_id,
    uploaded_by,
    is_archived,
    is_priority,
    is_Activity_archived
  },
  callback
) => dispatch => {
  updateAttachment(
    {
      auth_token,
      id,
      title,
      description,
      url,
      size,
      activity_id,
      uploaded_by,
      is_archived,
      is_priority
    },
    response => {
      if (response) {
        dispatch(updateAttachmentAfterDelete(response));

        if (is_Activity_archived) {
          dispatch(
            updateArchiveCardAttachmentCountAfterDelete({ activity_id })
          );
        } else {
          dispatch(
            updateAttachmentCountActionAfterArchive({
              activity_id,
              is_priority
            })
          );
        }
      } else {
        dispatch(updateAttachmentAfterDelete(response));
        dispatch(
          updateAttachmentCountActionAfterArchive({
            activity_id,
            is_priority
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const AddAttachmentAction = (
  body,
  file,
  is_priority,
  is_Activity_archived,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: attachmentsConstants.addNewAttachmentLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );

  uploadDataToS3Bucket({ file }, response => {
    if (response && !response.stdErr && !response.error && !response.uploadPercentage && response.img) {
      let url = response.img;

      // received file url and update body
      body = { ...body, url };
      addAttachment(body, res => {
        if (res && !res.error) {
          dispatch(
            updateLoaderState({
              action: attachmentsConstants.addNewAttachmentLoaderState,
              state: {
                fetching: false,
                fetched: true,
                error: false
              }
            })
          );

          dispatch(addNewAttachment(res));

          if (is_Activity_archived) {
            dispatch(
              updateArchiveCardAttachmentCount({
                activity_id: body.activity_id
              })
            );
          } else {
            dispatch(
              updateAttachmentCountAction({
                activity_id: body.activity_id,
                is_priority
              })
            );
          }
        } else {
          dispatch(
            updateLoaderState({
              action: attachmentsConstants.addNewAttachmentLoaderState,
              state: {
                fetching: false,
                fetched: true,
                error: true
              }
            })
          );
        }
        callback && callback(res);
      });
    } response.uploadPercentage && callback && callback(response);
  });
};

export const AddExternalAttachmentAction = (
  body,
  is_priority,
  is_Activity_archived,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: attachmentsConstants.addNewAttachmentLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );

  addAttachment(body, response => {
    if (response && !response.error) {
      dispatch(
        updateLoaderState({
          action: attachmentsConstants.addNewAttachmentLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(addNewAttachment(response));

      if (is_Activity_archived) {
        dispatch(
          updateArchiveCardAttachmentCount({
            activity_id: body.activity_id
          })
        );
      } else {
        dispatch(
          updateAttachmentCountAction({
            activity_id: body.activity_id,
            is_priority
          })
        );
      }
    } else {
      dispatch(
        updateLoaderState({
          action: attachmentsConstants.addNewAttachmentLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};
