import React, { Component } from "react";
import searchIcon from '../../styles/img/svg/settings-search-icon.svg';
import closeIcon from '../../styles/img/svg/settings-close-icon.svg'

class SettingsSearch extends Component {
  state = {
    searchText: ""
  };

  onChange = (event) => {
    this.props.onSearch(event.target.value)
    this.setState({ searchText: event.target.value })
  }

  render() {
      const { onShowSearch, placeholder } = this.props;
    return (
      <div ng-if="projectSettings.searchInput" className="wrap-input-cross">
        <img src={searchIcon} alt="search-icon" />
        <input
          value={this.state.searchText}
          onChange={this.onChange}
          autoComplete="off"
          className="ppl-search-control searchBar"
          type="text"
          id="search-people"
          placeholder={`Search ${placeholder.toLowerCase()} ${placeholder.toLowerCase() !== "all projects" ? "projects" : ""}`}
          autoFocus
        />
        <div className="search-settings-project">
          <img
            src={closeIcon}
            alt="close"
            onClick={onShowSearch}
          />
        </div>
      </div>
    );
  }
}

export default SettingsSearch;
