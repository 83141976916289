import React, { PureComponent } from "react";
import Mark from "mark.js";
import {
  MarkActivityReadAction,
  MarkKanbanActivityReadAction,
} from "../../actions/activities/activitiesaction";
import {
  getCommentsAction,
  searchCommentsAction,
  addCommentAction,
  addCommentAttachmentsAction,
  clearCommentReducer,
  clearCommentsAction,
  getSingleCommentChainAction,
} from "../../actions/comments/commentsaction";
import { connect } from "react-redux";
import "../../styles/scss/comments.scss";

import { Loader } from "../common/loader";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import { changeNoItemStatusAction } from "../../actions/notifications/notificationaction";
import Avatar from "react-avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import closeIcon from "../../styles/img/svg/Close_White.svg";

import OutsideClickHandler from "react-outside-click-handler";
import AttachmentDropdown from "../attachments/attachmentDropdown";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { Tooltip } from "@material-ui/core";

import _ from "lodash";
import { sortComments } from "../../helpers/generichelper";
import CommentsHelper from "./commentsHelper";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
import { abortUpload } from "../../services/awsapi";
import CommentsEditor, {
  InlineStyleControls,
  BlockStyleControls,
} from "./commentsEditor";
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  Modifier,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import richblock from "../../styles/img/svg/aa.svg";
import mentionSymbol from "../../styles/img/svg/at.svg";
import * as Immutable from "immutable";
const { OrderedSet } = Immutable;

momentDurationFormatSetup(moment);

class Comments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      commentTyped: "",
      openedEditBox: parseInt(localStorage.getItem("openedEditBoxId")) || null,
      openedReplyBox:
        parseInt(localStorage.getItem("openedReplyBoxId")) || null,
      openAttachmentDropdown: false,
      uploadPercentage: null,
      attachmentFrom: "",
      hasAttachment:
        localStorage.getItem("addAttachmentCache") &&
        JSON.parse(localStorage.getItem("addAttachmentCache")).id ===
          props.activityDetails.id
          ? true
          : false,
      displayErrMsg: false,
      attachmentsErr: false,
      noItem: true,
      pageNumber: 1,
      hasMore: false,
      hasMoreSearch: false,
      parentCount: 0,
      editorState:
        localStorage.getItem("addCommentCache") &&
        JSON.parse(localStorage.getItem("addCommentCache")).id ===
          props.activityDetails.id
          ? EditorState.moveFocusToEnd(
              EditorState.createWithContent(
                convertFromRaw(
                  JSON.parse(
                    JSON.parse(localStorage.getItem("addCommentCache")).text
                  )
                )
              )
            )
          : EditorState.createEmpty(),
      showcustomtoolbar: false,
      attachmentsToAdd:
        localStorage.getItem("addAttachmentCache") &&
        JSON.parse(localStorage.getItem("addAttachmentCache")).id ===
          props.activityDetails.id
          ? JSON.parse(
              JSON.parse(localStorage.getItem("addAttachmentCache"))
                .attachmentsToAdd
            )
          : [],
      attachmentsFile: [],
      attachmentOneDrive: false,
      inlineStyleData: [],
      clickedImg: null,
      shouldShowLoader: true,
      // attachmentsToAdd: [{ title: "kuch bhi", size: 31236, src: "https://gtd.s3.amazonaws.com/attachments/0-mahakal-bholenath-lord-shiva-mahadev-hd-mobile-wallpapers-images.jpg" }]
    };
  }

  componentDidMount() {
    if (this.props.activityDetails) {
      const { id } = this.props.activityDetails;
      const {
        getCommentsAction,
        getSingleCommentChain,
        // comments,
        // getAllCustomerForPeopleAction,
        clickedCommentId,
        fromNotification,
        changeNoItemStatus,
      } = this.props;

      const currentStyle = this.state.editorState.getCurrentInlineStyle();

      let inlineStyle = [];

      if (currentStyle.has("BOLD")) {
        inlineStyle.push("BOLD");
      }
      if (currentStyle.has("UNDERLINE")) {
        inlineStyle.push("UNDERLINE");
      }
      if (currentStyle.has("ITALIC")) {
        inlineStyle.push("ITALIC");
      }
      let queryParam = null;
      if (fromNotification) {
        queryParam =
          "COMMENT&page=" +
          this.state.pageNumber +
          "&comment_id=" +
          this.props.parentCommentId +
          "&action=UPTO";
      } else {
        queryParam = "COMMENT&page=" + this.state.pageNumber + "&per_page=10";
      }

      if (fromNotification && clickedCommentId) {
        getSingleCommentChain(this.props.parentCommentId, _response => {
          this.setState({ shouldShowLoader: false });
          const activeComments = _.filter(
            this.props.comments,
            comment => comment.is_active
          ).reverse();
          
          const sortedComments = sortComments(activeComments);
          const isClickedCommentAvailable =
            sortedComments.filter(
              comment => comment.is_active && comment.id === clickedCommentId
            ).length > 0
              ? true
              : false;

          !isClickedCommentAvailable &&
            changeNoItemStatus({ showStatus: true });

          getCommentsAction(queryParam, id, this.state.pageNumber, response => {
            this.setState({ shouldShowLoader: true });
            let parentCountNotification = 0;
            if (fromNotification) {
              parentCountNotification = this.props.comments.filter(
                comment => !comment.data.commentDetails.isReply
              ).length;
            }
            const elementsToScroll =
              document.querySelectorAll('[role="dialog"]')[0];
            let element = document.getElementById(
              `ap-comments-item-container-${clickedCommentId}`
            );
            if (!_.isNull(element)) {
              // scrolling 250 is just an offset
              elementsToScroll.scrollTo({
                top: element.offsetTop + 250,
                behavior: "auto",
              });
            }
            this.setState(() => ({
              ...this.state,
              comments: response,
              hasMore: response && response.length >= 10,
              parentCount: parentCountNotification,
              inlineStyleData: inlineStyle,
            }));
          });
        });
      } else {
        getCommentsAction(queryParam, id, this.state.pageNumber, response => {
          let parentCountNotification = 0;
          if (fromNotification) {
            const activeComments = _.filter(
              this.props.comments,
              comment => comment.is_active
            ).reverse();
            parentCountNotification = this.props.comments.filter(
              comment => !comment.data.commentDetails.isReply
            ).length;
            const sortedComments = sortComments(activeComments);
            const isClickedCommentAvailable =
              sortedComments.filter(
                comment => comment.is_active && comment.id === clickedCommentId
              ).length > 0
                ? true
                : false;

            !isClickedCommentAvailable &&
              changeNoItemStatus({ showStatus: true });
          }
          this.setState(() => ({
            ...this.state,
            comments: response,
            hasMore: response && response.length >= 10,
            parentCount: parentCountNotification,
            inlineStyleData: inlineStyle,
          }));
        });
      }

      // getAllCustomerForPeopleAction({}, (response) => {
      //   // console.log("people", response);
      // });

      // check the presence of comments
    }
  }

  componentWillUnmount() {
    
    this.props.clearCommentsAction();
  }

  getComments = () => {
    if (this.props.searchText || this.props.selectedOption !== "All") {
      // this.setState({ searchPageNumber: this.props.searchPageNumber + 1 }, () => {
      var context = document.querySelectorAll(".ap-comment-desc-holder");
      var instance = new Mark(context);
      const {
        activityDetails,
        searchText,
        selectedOption,
        searchWithAttachment,
        searchComments,
        updateSearchHasMore,
        updatePageNumber,
      } = this.props;
      const { id } = activityDetails;
      const currentUserId = parseInt(localStorage.getItem("id"));
      const socialType = selectedOption.toLocaleLowerCase();
      let queryParams = `?parent_activity_id=${id}&like=${searchText}&logged_in_user=${currentUserId}&social_type=${socialType}&search_type=${
        searchWithAttachment ? "attachments" : "comments"
      }&page=${this.props.searchPageNumber + 1}&per_page=10`;
      searchComments(
        queryParams,
        this.props.searchPageNumber + 1,
        res => {
          if (res) {
            setTimeout(() => {
              instance.unmark();
              let updatedContext = document.querySelectorAll(
                ".ap-comment-desc-holder"
              );
              let updatedInstance = new Mark(updatedContext);
              updatedInstance.mark(searchText, {
                element: "span",
                className: "marked-search",
                accuracy: "partially",
              });
              
              updateSearchHasMore(res && res.length >= 10);
              updatePageNumber(this.props.searchPageNumber + 1);
              this.setState(() => ({
                ...this.state,
                comments: res,
                // hasMoreSearch:res && res.length>=10,
                // parentCount:parentCountNotification
              }));
            }, 100);
          }
        }
      );
      // })
    } else
      this.setState(
        {
          pageNumber: this.state.pageNumber + 1,
        },
        () => {
          const { id } = this.props.activityDetails;
          const {
            getCommentsAction,
            clickedCommentId,
            fromNotification,
            changeNoItemStatus,
          } = this.props;
          let queryParam = null;
          if (fromNotification) {
            queryParam =
              "COMMENT&page=" +
              this.state.pageNumber +
              "&per_page=10&total_items=" +
              this.state.parentCount;
          } else {
            queryParam =
              "COMMENT&page=" + this.state.pageNumber + "&per_page=10";
          }
          getCommentsAction(queryParam, id, this.state.pageNumber, response => {
            let parentCountNotification = 0;
            if (fromNotification) {
              const activeComments = _.filter(
                this.props.comments,
                comment => comment.is_active
              ).reverse();
              parentCountNotification = this.props.comments.filter(
                comment => !comment.data.commentDetails.isReply
              ).length;
              const sortedComments = sortComments(activeComments);
              const isClickedCommentAvailable =
                sortedComments.filter(
                  comment =>
                    comment.is_active && comment.id === clickedCommentId
                ).length > 0
                  ? true
                  : false;
              !isClickedCommentAvailable &&
                changeNoItemStatus({ showStatus: true });
            }
            this.setState(() => ({
              ...this.state,
              comments: response,
              hasMore: response && response.length >= 10,
              parentCount: parentCountNotification,
            }));
          });
        }
      );
  };

  componentDidUpdate() {
    setTimeout(() => {
      if (
        document.getElementsByClassName(
          "comment-text-area__suggestions__list"
        )[0]
      ) {
        const liElement = document.createElement("li");
        liElement.classList += "checklist-textarea-input__suggestions__item";
        var para = document.createElement("span");
        para.className += "allnotify";
        const textInsideSpan = document.createTextNode(
          "@act will notify all members in the activity"
        );
        para.appendChild(textInsideSpan);
        const elementWhereToInsert = document.getElementsByClassName(
          "comment-text-area__suggestions__list"
        )[0];
        
        if (document.getElementsByClassName("allnotify").length > 0) {
          return;
        } else {
          elementWhereToInsert.insertBefore(
            para,
            elementWhereToInsert.firstChild
          );
        }
      }
    }, 10);
    // console.log("comment component did update", this.props.scrollHeight)
    if (
      this.props.scrollHeight > 40 &&
      this.props.activityDetails.is_new_comment &&
      !this.props.archiveOrUnarchiveLoaderState.fetching
    ) {
      const body = {
        activity_ids: [this.props.activityDetails.id],
        type: "comment",
      };
      if (this.props.source === "FROM_ACTIVE")
        this.props.MarkActivityReadAction({
          body,
          is_priority: this.props.is_priority,
        });
      if (this.props.source === "FROM_KANBAN")
        MarkKanbanActivityReadAction({
          body,
          is_priority: this.props.is_priority,
        });
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  handleCommentOnChange = (_event, newValue, _newPlainTextValue, _mentions) => {
    const inputBox = document.querySelector(".add-comment-container");
    if (inputBox && this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    const newString = newValue === "\n" ? [] : newValue.split(" ");

    
    const manipulatedString = newString.map(value => {
      
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });

    let finalValue = manipulatedString.join(" ");
    if (finalValue.length === 0) localStorage.removeItem("addCommentCache");
    else
      localStorage.setItem(
        "addCommentCache",
        JSON.stringify({
          id: this.props.activityDetails.id,
          text: finalValue,
        })
      );

    this.setState({
      commentTyped: finalValue,
    });
  };
  addComment = () => {
    const {
      addComment,
      // addCommentAttachmentsAction,
      loggedinUser,
      activityDetails,
      is_priority,
      is_archived,
    } = this.props;
    
    const commentData = {
      checklist: [],
      commentDetails: {
        isReply: false,
        hasAttachment: this.state.hasAttachment,
      },
      lastModifiedBy: parseInt(loggedinUser.id),
    };
    let commentAdded = convertToRaw(this.state.editorState.getCurrentContent());
    const markup = this.state.editorState.getCurrentContent().hasText()
      ? draftToHtml(commentAdded)
      : "";
    const commentObj = {
      activity_type: "COMMENT",
      created_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      created_by: parseInt(loggedinUser.id),
      data: commentData,
      description: markup.replaceAll("&nbsp;", " "),
      parent_activity_id: activityDetails.id,
      platform: "WEB",
      project_id: activityDetails.project_id,
      title: markup.replaceAll("&nbsp;", " "),
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      user_id: [parseInt(loggedinUser.id)],
      version: "2.0.1",
      is_priority,
      is_Activity_archived: is_archived,
      // attachments: this.state.attachmentsToAdd
    };

    // Multipart/form-data attachments start
    const { attachmentsFile } = this.state;
    let formData;
    if (attachmentsFile.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else formData.append(`attachment${index + 1}`, JSON.stringify(file));
      });
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "comment");
      
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end

    // console.log("Add comment obj", commentObj)
    addComment(commentObj, formData, (response, attRes) => {
      if (response && response.id) {
        this.props.updateFetch();
        localStorage.removeItem("addCommentCache");
        localStorage.removeItem("addAttachmentCache");
        this.setState({
          commentTyped: "",
          hasAttachment: false,
          editorState: EditorState.createEmpty(),
          attachmentsToAdd: [],
          attachmentsFile: [],
        });
        if (attRes?.stdErr) {
          this.setState({ attachmentsErr: true }, () => {
            setTimeout(() => {
              this.setState({ attachmentsErr: false });
            }, 5000);
          });
        }
      }
    });
  };
  changeOpenedEditBox = id => {
    if (this.state.openedReplyBox === id) {
      this.setState(
        {
          ...this.state,
          openedEditBox: id,
          openedReplyBox: null,
        },
        () => {
          const inputBox = document.querySelector(
            ".comment-edit-box-container"
          );
          if (inputBox && this.isInViewport(inputBox) === false) {
            inputBox.scrollIntoView({ behavior: "auto", block: "end" });
          }
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          openedEditBox: id,
        },
        () => {
          const inputBox = document.querySelector(
            ".comment-edit-box-container"
          );
          if (inputBox && this.isInViewport(inputBox) === false) {
            inputBox.scrollIntoView({ behavior: "auto", block: "end" });
          }
        }
      );
    }
  };

  closeOpenedEditBox = () => {
    localStorage.removeItem("openedEditBoxId");
    localStorage.removeItem("commentEditCache");
    this.setState({
      ...this.state,
      openedEditBox: null,
    });
  };

  clearCommentTyped = _e => {
    this.setState(
      {
        ...this.state,
        commentTyped: "",
        editorState: EditorState.createEmpty(),
        attachmentsToAdd: [],
        attachmentsFile: [],
      },
      () => localStorage.removeItem("addCommentCache")
    );
  };
  changeOpenedReplyBox = id => {
    this.setState({
      ...this.state,
      openedReplyBox: id,
    });
  };

  closeOpenedReplyBox = () => {
    localStorage.removeItem("openedReplyBoxId");
    localStorage.removeItem("commentReplyCache");
    this.setState({
      ...this.state,
      openedReplyBox: null,
    });
  };

  renderSuggestion = (entry, _search, _highlightedDisplay, _index, _focused) => {
    return (
      <>
        <div
          className={
            !entry.image ? "comments-people avatar" : "comments-people"
          }
        >
          {entry.image && (
            <img src={entry.image} alt="user-img" className="user-photo" />
          )}
          {!entry.image && (
            <Avatar
              style={{ width: "100%", height: "100%" }}
              name={entry.display}
              maxInitials={1}
            ></Avatar>
          )}

          <span>{entry.username}</span>
        </div>
        <div
          className={
            !entry.image
              ? "mentio-name avatar ng-binding"
              : "mentio-name ng-binding"
          }
        >
          {entry.display}
        </div>
      </>
    );
  };
  openAttachmentDropdown = () => {
    this.setState({
      openAttachmentDropdown: !this.state.openAttachmentDropdown,
    });
  };

  //----------------------Close Attachment dropdown new activity design--------------------
  closeAttachmentDropdown = (response, attachments) => {
    
    if (
      response &&
      response.length > 0 &&
      (response[0].src || response[0].url || response[0].type)
    ) {
      this.setState(
        {
          attachmentFrom: "",
          uploadPercentage: null,
          openAttachmentDropdown: false,
          hasAttachment: true,
          attachmentsToAdd: [...this.state.attachmentsToAdd, ...response],
          attachmentsFile: [...this.state.attachmentsFile, ...attachments],
        },
        () => {
          // localStorage.setItem(
          //   "addAttachmentCache",
          //   JSON.stringify({
          //     id: this.props.activityDetails.id,
          //     attachmentsToAdd: JSON.stringify(this.state.attachmentsToAdd)
          //   })
          // )
        }
      );
    } else if (response && response.url) {
      this.setState({
        attachmentFrom: "",
        uploadPercentage: null,
        openAttachmentDropdown: false,
        hasAttachment: true,
        attachmentsToAdd: [...this.state.attachmentsToAdd, response],
        attachmentsFile: [...this.state.attachmentsFile, response],
      });
    } else {
      if (!this.state.attachmentOneDrive) {
        this.setState({
          attachmentFrom: "",
          openAttachmentDropdown: false,
        });
      }
    }
  };

  onRemoveAttachment = attachment => {
    this.setState(
      {
        attachmentsToAdd: this.state.attachmentsToAdd.filter(att => {
          if (att.src && attachment.src) return att.src !== attachment.src;
          else if (att.url && attachment.url) return att.url !== attachment.url;
          else if (att.size && att.name)
            return att.size !== attachment.size && att.name !== attachment.name;
          else return true;
        }),
        attachmentsFile: this.state.attachmentsFile.filter(file => {
          if (file.size && file.name)
            return (
              file.size !== attachment.size && file.name !== attachment.name
            );
          else if (attachment.url && file.url)
            return file.url !== attachment.url;
          else return true;
        }),
      },
      () => {
        if (this.state.attachmentsToAdd.length > 0) {
          // localStorage.setItem(
          //   "addAttachmentCache",
          //   JSON.stringify({
          //     id: this.props.activityDetails.id,
          //     attachmentsToAdd: JSON.stringify(this.state.attachmentsToAdd)
          //   })
          // )
        } else {
          this.setState({ hasAttachment: false });
          
        }
      }
    );
  };

  handleAttachFrom = from => {
    this.setState({
      attachmentFrom: from,
    });
  };

  chkMaxSize = size => {
    return size > 30000000;
  };

  handleMaxSize = (size, _type) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (sizeOfFile) {
      this.setState(
        {
          displayErrMsg: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ displayErrMsg: false });
          }, 5000);
        }
      );
    } else {
      this.setState({
        displayErrMsg: false,
      });
    }
  };

  moveCursorAtEnd(e) {
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }
  closePopup = () => {
    this.setState(
      {
        noItem: false,
      },
      () => {
        this.props.closeDisplayDialogWrapper();
        
      }
    );
  };

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = inlineStyle => {
    let editorstateToggle = RichUtils.toggleInlineStyle(
      this.state.editorState,
      inlineStyle
    );

    let currentStyle = editorstateToggle.getCurrentInlineStyle();
    let inlineStyledata = [];

    if (currentStyle.has("BOLD")) {
      inlineStyledata.push("BOLD");
    }
    if (currentStyle.has("UNDERLINE")) {
      inlineStyledata.push("UNDERLINE");
    }
    if (currentStyle.has("ITALIC")) {
      inlineStyledata.push("ITALIC");
    }

    this.updateInlineState(inlineStyledata);
    this.onChange(editorstateToggle);
  };

  updateInlineState = inlineStyle => {
    this.setState({ inlineStyleData: inlineStyle });
  };

  onChange = editorState => this.setState({ editorState });

  updateEditorState = (editorState, updateData, callback) => {
    this.setState({ editorState }, () => {
      let contentRaw = convertToRaw(this.state.editorState.getCurrentContent());

      if (
        !contentRaw.blocks ||
        contentRaw.blocks.length === 0 ||
        !contentRaw.blocks[0]["text"] ||
        contentRaw.blocks[0]["text"].trim().length === 0
      ) {
        localStorage.getItem("addCommentCache") &&
          JSON.parse(localStorage.getItem("addCommentCache")).id ===
            this.props.activityDetails.id &&
          localStorage.removeItem("addCommentCache");
      } else {
        localStorage.setItem(
          "addCommentCache",
          JSON.stringify({
            id: this.props.activityDetails.id,
            text: JSON.stringify(contentRaw),
          })
        );
      }

      updateData();
      if (callback) {
        callback();
      }
    });
  };

  addMention = () => {
    const currentContent = this.state.editorState.getCurrentContent(),
      currentSelection = this.state.editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      "@",
      OrderedSet.of(...this.state.inlineStyleData)
    );

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContent,
      "insert-fragment"
    );
    return this.setState({ editorState: newEditorState });
  };

  isImage = url => {
    
    if (url.indexOf(";base64") > -1) {
      return url.indexOf("image") > -1;
    } else {
      return false;
    }
  };

  getSize = size => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  updateAttchmetnOneDrive = flag => {
    this.setState({ attachmentOneDrive: flag });
  };

  onClickImg = url => {
    this.setState({ clickedImg: url });
    document
      .querySelector('[role="dialog"]')
      .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    document
      .querySelector('[role="dialog"]')
      .removeAttribute("id", "noscrolldialog");
  };

  render() {
    const {
      comments,
      searchText,
      selectedOption,
      searchedComments,
      searchWithAttachment,
      getCommentsLoaderState,
      userDetails,
      loggedinUser,
      selectedWorkspace,
      addCommentLoaderState,
      addComment,
      activityDetails,
      // updateLoaderState,
      // uploadAttachmentLoaderState,
      // clickedCommentId,
      is_priority,
      is_archived,
    } = this.props;
    const {
      // commentTyped,
      openedEditBox,
      openedReplyBox,
      // attachmentFrom,
      showcustomtoolbar,
      clickedImg,
    } = this.state;

    const isUserPartOfCard =
      activityDetails &&
      activityDetails.user_id.includes(parseInt(localStorage.getItem("id")));

    const isSuperAdmin = getIsSuperAdmin(loggedinUser, selectedWorkspace);

    const shouldMentionInputDisabled =
      (isSuperAdmin || isUserPartOfCard) && !addCommentLoaderState.fetching
        ? false
        : true;

    const dataForMentions = [
      {
        id: 0,
        display: "act",
        image: "",
        username: "Act",
      },
      ..._.sortBy(
        (activityDetails.is_locked
          ? userDetails.filter(user =>
              activityDetails.user_id.includes(user.id)
            )
          : userDetails
        ).map(user => {
          return {
            id: user.id,
            display: user.username,
            image: user.img || "",
            username: user.first_name,
          };
        }),
        user => user.username.toLowerCase()
      ),
    ];
    

    
    let hasMore;
    if (searchText || searchedComments.length > 0)
      hasMore = this.props.searchHasMore;
    else hasMore = this.state.hasMore;
    let commentSection = null;
    if (
      comments &&
      comments.length > 0 &&
      userDetails &&
      userDetails.length > 0
    ) {
      commentSection = (
        <CommentsHelper
          comments={
            searchText ||
            selectedOption !== "All" ||
            searchedComments.length > 0 ||
            searchWithAttachment
              ? searchedComments
              : comments
          }
          searchText={searchText}
          userDetails={userDetails}
          isSuperAdmin={isSuperAdmin}
          changeOpenedEditBox={this.changeOpenedEditBox}
          openedEditBox={openedEditBox}
          closeOpenedEditBox={this.closeOpenedEditBox}
          changeOpenedReplyBox={this.changeOpenedReplyBox}
          openedReplyBox={openedReplyBox}
          closeOpenedReplyBox={this.closeOpenedReplyBox}
          loggedinUser={loggedinUser}
          addCommentAction={addComment}
          activityDetails={activityDetails}
          fromNotification={this.props.fromNotification}
          idOfElement={`ap-comments-item-container-${this.props.clickedCommentId}`}
          clickedCommentId={this.props.clickedCommentId}
          is_priority={this.props.is_priority}
          updateFetch={this.props.updateFetch}
          is_archived={is_archived}
          getComments={this.getComments}
          hasMore={hasMore}
        />
      );
    }
    return (
      <div className="comments-container">
        {/* <h1 class="ap-comments-title"> Comments</h1> */}
        {!searchText && selectedOption === "All" && (
          <div className="add-comment-container">
            <div className="ap-user-profile-holder">
              {loggedinUser && !loggedinUser.img && (
                <Avatar
                  // color={Avatar.getRandomColor("sitebase", [
                  //   "#ff9292",
                  //   "#7c9af1"
                  // ])}
                  style={{ width: "100%", height: "100%" }}
                  name={loggedinUser.first_name}
                  maxInitials={1}
                ></Avatar>
              )}
              {loggedinUser && loggedinUser.img && (
                <img src={loggedinUser.img} alt={loggedinUser.first_name} />
              )}
            </div>
            <div className="add-comment-box-holder">
              {/* <textarea name="comment" id="main-comment-box" placeholder="Add a comment" class="comment-text-area" 
              value={commentTyped} onChange={(e) => this.handleCommentOnChange(e)}></textarea> */}
              {/* <MentionsInput
              placeholder="Add a comment. To mention someone type @"
              value={commentTyped}
              className="comment-text-area"
              onChange={(event, newValue, newPlainTextValue, mentions) =>
                this.handleCommentOnChange(
                  event,
                  newValue,
                  newPlainTextValue,
                  mentions
                )
              }
              autoFocus={true}
              onFocus={this.moveCursorAtEnd}
              disabled={shouldMentionInputDisabled}
            >
              <Mention
                type="user"
                trigger="@"
                markup="@{{__display__}}"
                data={dataForMentions}
                renderSuggestion={this.renderSuggestion}
                displayTransform={(id, display) => `@${display}`}
                appendSpaceOnAdd={true}
              ></Mention>
            </MentionsInput> */}
              <CommentsEditor
                updateEditorState={this.updateEditorState}
                editorState={this.state.editorState}
                propsCheck={false}
                dataForMentions={dataForMentions}
                withAttachment={this.state.attachmentsToAdd.length}
                disabled={shouldMentionInputDisabled}
                updateInlineState={this.updateInlineState}
              ></CommentsEditor>
              <div className="comm-att-holder add-edit">
                {this.state.attachmentsToAdd.length > 0 && (
                  <React.Fragment>
                    {this.state.attachmentsToAdd.map((attachment, index) => {
                      const attachmentURL =
                        attachment.src || attachment.url || attachment.type;
                      const isImage = attachment.src ? true : false;
                      const isCloud = attachment.url ? true : false;
                      const isDocument = attachment.type ? true : false;
                      return (
                        <div className="nw-att-row-container" key={index}>
                          <div className="nw-att-row-left">
                            <div
                              className={
                                isImage
                                  ? "nw-att-img-holder comm-att"
                                  : "nw-att-img-holder notimage comm-att"
                              }
                            >
                              {isImage && (
                                <img
                                  style={
                                    addCommentLoaderState.fetching
                                      ? { cursor: "auto" }
                                      : {}
                                  }
                                  src={decodeURI(attachmentURL)}
                                  alt="attachment"
                                  onClick={() =>
                                    !addCommentLoaderState.fetching &&
                                    this.onClickImg(decodeURI(attachmentURL))
                                  }
                                />
                              )}
                              {isCloud &&
                                attachmentURL.indexOf(".google.com") > -1 && (
                                  <a
                                    href={attachmentURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="spanDisplay">
                                      Google Drive
                                    </span>
                                  </a>
                                )}
                              {isCloud &&
                                attachmentURL.indexOf("www.dropbox.com") >
                                  -1 && (
                                  <a
                                    href={attachmentURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="spanDisplay">Dropbox</span>
                                  </a>
                                )}
                              {isCloud &&
                                attachmentURL.indexOf("https://1drv.ms/") >
                                  -1 && (
                                  <a
                                    href={attachmentURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="spanDisplay">
                                      One Drive
                                    </span>
                                  </a>
                                )}
                              {isDocument && (
                                // attachmentURL.indexOf(".google.com") < 0 &&
                                // attachmentURL.indexOf("www.dropbox.com") < 0 &&
                                // attachmentURL.indexOf("https://1drv.ms/") < 0 &&
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                            </div>
                          </div>
                          {/*  */}
                          <div className="nw-att-row-right comm-att">
                            <div className="nw-att-details-holder">
                              <div className="nw-att-details-name comm-att truncate-text">
                                <a
                                  style={
                                    isImage && !addCommentLoaderState.fetching
                                      ? { cursor: "pointer" }
                                      : {}
                                  }
                                  onClick={() =>
                                    isImage &&
                                    !addCommentLoaderState.fetching &&
                                    this.onClickImg(decodeURI(attachmentURL))
                                  }
                                >
                                  {attachment.title || attachment.name}
                                </a>
                              </div>
                              <div className="nw-att-details-time comm-att">
                                <span>{this.getSize(attachment.size)}</span>
                              </div>
                              <a
                                style={
                                  addCommentLoaderState.fetching
                                    ? { cursor: "auto" }
                                    : {}
                                }
                                className="nw-att-link download comm-att"
                                // href={decodeURI(attachment.url)}
                                // download={attachment.title}
                                // target="_blank"
                                onClick={() =>
                                  !addCommentLoaderState.fetching &&
                                  this.onRemoveAttachment(attachment)
                                }
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}
              </div>
              <div className="comment-bottom-btn-holder">
                <div className="comment-bottom-left-holder">
                  <button
                    className={
                      "add-comment-btn " +
                      (!(
                        this.state.editorState.getCurrentContent().hasText() ||
                        this.state.attachmentsToAdd.length > 0
                      ) || addCommentLoaderState.fetching
                        ? "disable"
                        : "")
                    }
                    onClick={this.addComment}
                  >
                    Add
                  </button>
                  {(this.state.editorState.getCurrentContent().hasText() ||
                    this.state.attachmentsToAdd.length > 0) &&
                    !addCommentLoaderState.fetching && (
                      <div
                        className="comment-edit-cancel-btn"
                        onClick={this.clearCommentTyped}
                      ></div>
                    )}
                  {addCommentLoaderState.fetching &&
                    !addCommentLoaderState.error && (
                      <div className="loading-div">
                        <CircularProgress
                          classes={{ root: "circular-progress" }}
                          size={20}
                          style={{ marginLeft: "10px" }}
                        ></CircularProgress>
                        <span className="upload-percentage"></span>
                      </div>
                    )}
                  {this.state.uploadPercentage && (
                    <div className="loading-div">
                      <CircularProgress
                        classes={{ root: "circular-progress" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                        variant="static"
                        value={this.state.uploadPercentage}
                      ></CircularProgress>
                      <span className="upload-percentage">
                        Adding attachment - {this.state.uploadPercentage}%
                      </span>
                      <div
                        className="comment-edit-cancel-btn"
                        style={{ marginTop: "4px" }}
                        onClick={() => {
                          abortUpload();
                          this.setState({ uploadPercentage: null });
                        }}
                      ></div>
                    </div>
                  )}
                  {this.state.displayErrMsg && (
                    <div class="new-comm-attacherror">
                      The file size exceeds 30 MB{" "}
                    </div>
                  )}
                  {this.state.attachmentsErr && (
                    <div class="new-comm-attacherror">
                      Please add the attachments again{" "}
                    </div>
                  )}
                </div>
                <div className="comment-right-sction">
                  {(isSuperAdmin || isUserPartOfCard) && (
                    <div className="comment-rict-text">
                      {showcustomtoolbar && (
                        <div className="customtoolbar">
                          <InlineStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleInlineStyle}
                          />

                          <BlockStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleBlockType}
                          />
                        </div>
                      )}

                      <Tooltip
                        title={
                          showcustomtoolbar
                            ? "Hide formatting"
                            : "Show formatting"
                        }
                      >
                        <div
                          className="rickBlock"
                          onClick={_event => {
                            this.setState({
                              showcustomtoolbar: !this.state.showcustomtoolbar,
                            });
                          }}
                        >
                          <img src={richblock} alt="OL" />
                        </div>
                      </Tooltip>

                      <Tooltip title="Mention">
                        <div
                          className="mentionsymbol"
                          onClick={this.addMention}
                        >
                          <img src={mentionSymbol} alt="@" />
                        </div>
                      </Tooltip>
                    </div>
                  )}

                  {(isSuperAdmin || isUserPartOfCard) && (
                    <div className="comment-attachment-area">
                      <OutsideClickHandler
                        onOutsideClick={this.closeAttachmentDropdown}
                      >
                        <Tooltip title="Attach file">
                          <div
                            className="comment-attachment-img-holder"
                            onClick={this.openAttachmentDropdown}
                          ></div>
                        </Tooltip>
                        {this.state.openAttachmentDropdown && (
                          <AttachmentDropdown
                            closeAttachmentDropdown={
                              this.closeAttachmentDropdown
                            }
                            activityDetails={activityDetails}
                            handleMaxSize={this.handleMaxSize}
                            handleAttachFrom={this.handleAttachFrom}
                            attachedFrom={"mainComment"}
                            is_priority={is_priority}
                            updateFetch={this.props.updateFetch}
                            is_archived={is_archived}
                            updateAttchmetnOneDrive={
                              this.updateAttchmetnOneDrive
                            }
                          />
                        )}
                      </OutsideClickHandler>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {getCommentsLoaderState.fetched &&
          !(searchText || selectedOption !== "All") &&
          comments &&
          comments.length < 1 &&
          !(
            (this.state.editorState &&
              this.state.editorState.getCurrentContent().hasText()) ||
            this.state.attachmentsToAdd.length > 0
          ) && <div className="empty-comments">No comments added yet</div>}
        {(searchText || selectedOption !== "All") &&
          getCommentsLoaderState.fetched &&
          searchedComments &&
          searchedComments.length < 1 && (
            <div className="empty-comments">No results found</div>
          )}
        {getCommentsLoaderState &&
          getCommentsLoaderState.fetching &&
          !getCommentsLoaderState.error &&
          this.state.shouldShowLoader && (
            <Loader size={100} loading={getCommentsLoaderState.fetching} />
          )}
        {commentSection}
        {clickedImg && (
          <div className="attachmentpopviews">
            <img
              src={clickedImg}
              alt="attachment"
              className="attachmentimage"
            />
            <img
              className="closeattachmentpopview"
              src={closeIcon}
              alt="close-icon"
              onClick={this.onClosePreview}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    comments: _.uniqBy(state.commentsReducer.comments, "id"),
    searchedComments: _.uniqBy(state.commentsReducer.searchedComments, "id"),
    getCommentsLoaderState: state.commentsReducer.getCommentsLoaderState,
    updateCommentLoaderState: state.commentsReducer.updateCommentLoaderState,
    addCommentLoaderState: state.commentsReducer.addCommentLoaderState,
    userDetails: state.peopleReducer.workspaceusers,
    loggedinUser: state.loginReducer.loginAuth.user_details,
    selectedWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    updateLoaderState: state.attachmentsReducer.updateLoaderState,
    uploadAttachmentLoaderState:
      state.attachmentsReducer.uploadAttachmentLoaderState,
    scrollHeight: state.dialogReducer.scrollHeight,
    archiveOrUnarchiveLoaderState:
      state.activityReducer.archiveOrUnarchiveLoaderState,
  };
};

const mapDispatchProps = dispatch => {
  return {
    MarkActivityReadAction({ body, is_priority }, callback) {
      dispatch(MarkActivityReadAction({ body, is_priority }, callback));
    },
    MarkKanbanActivityReadAction({ body, is_priority }, callback) {
      dispatch(MarkKanbanActivityReadAction({ body, is_priority }, callback));
    },
    getCommentsAction(activity_type, parent_activity_id, pageNumber, callback) {
      dispatch(
        getCommentsAction(
          activity_type,
          parent_activity_id,
          pageNumber,
          callback
        )
      );
    },
    getSingleCommentChain(comment_id, callback) {
      dispatch(getSingleCommentChainAction(comment_id, callback));
    },
    searchComments: (queryParams, pageNumber, callback) => {
      dispatch(searchCommentsAction(queryParams, pageNumber, callback));
    },
    addComment(
      {
        activity_type,
        created_at,
        created_by,
        data,
        description,
        parent_activity_id,
        platform,
        project_id,
        title,
        updated_on,
        user_id,
        version,
        is_priority,
        is_Activity_archived,
        attachments,
      },
      formData,
      callback
    ) {
      dispatch(
        addCommentAction(
          {
            activity_type,
            created_at,
            created_by,
            data,
            description,
            parent_activity_id,
            platform,
            project_id,
            title,
            updated_on,
            user_id,
            version,
            is_priority,
            is_Activity_archived,
            attachments,
          },
          formData,
          callback
        )
      );
    },
    addCommentAttachmentsAction({ formData, commentId }, callback) {
      dispatch(addCommentAttachmentsAction({ formData, commentId }, callback));
    },
    getAllCustomerForPeopleAction({}, callback) {
      dispatch(getAllCustomerForPeopleAction({}, callback));
    },
    clearCommentReducer() {
      dispatch(clearCommentReducer());
    },
    changeNoItemStatus: ({ showStatus }) =>
      dispatch(changeNoItemStatusAction({ showStatus })),
    clearCommentsAction() {
      dispatch(clearCommentsAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Comments);
