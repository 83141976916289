export const peopleConstants = {
    customerLoaderState : 'CUSTOMER_LOADER_STATE',
    updateCustomerDetailsLoaderState: 'UPDATE_CUSTOMER_DETAILS_LOADER_STATE',
    updateCustomerList : 'UPDATE_CUSTOMER_LIST',
    fetchPeople: 'FETCH_PEOPLE',
    getInvitedEmails: 'GET_INVITED_EMAILS',
    clearInvitedEmails: "CLEAR_INVITED_EMAILS",
    getRequestedUsers: "GET_REQUESTED_USERS",
    clearRequestedUsers: "CLEAR_REQUESTED_USERS",
    updateWorkspaceUser : 'UPDATE_WORKSPACE_USERS',
    updateWorkspacePeople: 'UPDATE_WORKSPACE_PEOPLE',
    updateEachUserProfile: "UPDATE_EACH_USER_PROFILE",
    updateEachWorkspaceUserProfile: "UPDATE_EACH_WORKSPACE_USER_PROFILE",
    updateEachCustomer : "UPDATE_EACH_CUSTOMER",
    updateRoleLoaderState: 'UPDATE_ROLE_LOADER_STATE',
    removeWorkspaceUser: 'REMOVE_WORKSPACE_USER'
}