import React from "react";
import "../../styles/scss/user_profile.scss";

const EmailAlreadyRegisteredPopup = ({ onClosePopup }) => {
  return (
    <div className="pop-up-password email-already-registered">
      <div className="message">
        <span>
        A Get Things Done account is already registered with this Email ID. Login using this Email ID or Register with a different Email ID.
        </span>
      </div>
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default EmailAlreadyRegisteredPopup;
