import { dashboardConstants } from "../../constants/dashboardConstants";

const initialState = {
  effortOverviewLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  effortOverview: {
    data: null,
    key: null
  },
  individualEffortLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  individualEffort: {
    list: null,
    key: null
  },
  projectAccessReqTeamLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  projectAccessReqTeam: {
    list: null,
    key: null
  },
  missedTimeSheetsLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  missedTimeSheets: {
    list: null,
    key: null
  },
  effortOverviewPersonalLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  effortOverviewPersonal: {
    data: null,
    key: null
  },
  projectAccessReqPersonalLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  projectAccessReqPersonal: {
    list: null,
    key: null
  },
  projectStatus: {
    list: null,
    key: null
  },
  leaderBoard: {
    list: null,
    key: null
  }
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.getEffortOverviewLoader:
      return {
        ...state,
        effortOverviewLoaderState: action.payload
      };
    case dashboardConstants.getEffortOverview:
      return {
        ...state,
        effortOverview: action.payload
      };
    case dashboardConstants.getIndividualEffortLoader:
      return {
        ...state,
        individualEffortLoaderState: action.payload
      };
    case dashboardConstants.getIndividualEffort:
      return {
        ...state,
        individualEffort: action.payload
      };
    case dashboardConstants.getProjectAccessTeamLoader:
      return {
        ...state,
        projectAccessReqTeamLoaderState: action.payload
      };
    case dashboardConstants.getProjectAccessTeam:
      return {
        ...state,
        projectAccessReqTeam: action.payload
      };
    case dashboardConstants.removeProjectAccessTeam:
      return {
        ...state,
        projectAccessReqTeam: {
          ...state.projectAccessReqTeam,
          list: state.projectAccessReqTeam.list.filter(
            proj => proj.id !== action.payload.id
          )
        }
      };
    case dashboardConstants.getMissedTimeSheetsLoader:
      return {
        ...state,
        missedTimeSheetLoaderState: action.payload
      };
    case dashboardConstants.getMissedTimeSheets:
      return {
        ...state,
        missedTimeSheets: action.payload
      };
    case dashboardConstants.getEffortOverviewPersonalLoader:
      return {
        ...state,
        effortOverviewPersonalLoaderState: action.payload
      };
    case dashboardConstants.getEffortOverviewPersonal:
      return {
        ...state,
        effortOverviewPersonal: action.payload
      };
    case dashboardConstants.getProjectAccessPersonalLoader:
      return {
        ...state,
        projectAccessReqPersonalLoaderState: action.payload
      };
    case dashboardConstants.getProjectAccessPersonal:
      return {
        ...state,
        projectAccessReqPersonal: action.payload
      };
    case dashboardConstants.getProjectStatus:
      return {
        ...state,
        projectStatus: action.payload
      };
    case dashboardConstants.getLeaderBoard:
      return {
          ...state,
          leaderBoard: action.payload
        };
    case dashboardConstants.clearDashboardReducer:
      return {
        ...state,
        effortOverview: {
          data: null,
          key: action.payload.key
        },
        individualEffort: {
          list: null,
          key: action.payload.key
        },
        projectAccessReqTeam: {
          list: null,
          key: action.payload.key
        },
        missedTimeSheets: {
          list: null,
          key: action.payload.key
        },
        effortOverviewPersonal: {
          data: null,
          key: action.payload.key
        },
        projectAccessReqPersonal: {
          list: null,
          key: action.payload.key
        },
        projectStatus: {
          list: null,
          key: action.payload.key
        },
        leaderBoard: {
          list: null,
          key: action.payload.key
        }
      };
    default:
      return {
        ...state
      };
  }
}
