import React, { useState } from "react";
import "../../styles/scss/app.scss";
import "../../styles/scss/joinWorkspace.scss";
import GTDLogo from "../../styles/img/svg/gtdLogo.svg";
import { resendVerificationEmail } from "../../services/registrationapi";
import { ToastContainer } from "react-toastify";
import "react-toastify/scss/main.scss";
import { Slide } from "react-toastify";
export default function VerifyEmail({ location }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFadeOut, setShowFadeOut] = useState(false);
  const sendVerificationEmail = (email) => {
    //console.log(email);
    setShowSuccess(true);
    // setTimeout(() => setShowFadeOut(true), 5000);
    // setTimeout(() => {
    //   setShowSuccess(false);
    //   setShowFadeOut(false);
    // }, 6000);
    resendVerificationEmail({ email }, (res) => {
      if (res && res.detail) {
        // toast("Email sent successfully!");
        setShowSuccess(true);
        setTimeout(() => setShowFadeOut(true), 5000);
        setTimeout(() => {
          setShowSuccess(false);
          setShowFadeOut(false);
        }, 6000);
      }
    });
  };
  return (
    <div className="joinWorkspace-container verify-email">
      <ToastContainer autoClose={2000} transition={Slide} />
      <div className="logo-container">
        <img src={GTDLogo} alt="GTD-logo" />
      </div>

      <div className="verify-account">
        <p className="main-title-verify">Verify Your Account</p>
        <p className="title-verify">
          Thank you for registering with Get Things Done
        </p>
        <div className="content-verify">
          {`An email has been sent to
${location.state.email} with a link to verify your account.`}
        </div>
        {/* <hr className="separator-send-verify">location.state.email</hr> */}
        <div className="not-received">
          If you haven’t received the email after a few minutes, please check
          your <span>spam folder</span>.
          <p
            onClick={() => sendVerificationEmail(location.state.email)}
            role="button"
            tabindex="0"
          >
            Resend Verification Email
          </p>
        </div>
      </div>
      {showSuccess && (
        <div className={`resend-success ${showFadeOut ? "show-success" : ""}`}>
          <div className="resend-text">
            {`Verification email sent successfully to ${location.state.email}`}
            <br />
            Please check your <span>spam folder</span>.
          </div>
        </div>
      )}
    </div>
  );
}
