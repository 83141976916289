import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";
import { secureFetch } from "../helpers/fetchhelper";

export const userLogout = (callback) => {
    secureFetch(API_URL + "/api/v1/logout", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Origin: API_URL,
          "X-ACCESS-TOKEN": routesHelper.getAuthToken()
        })
      }).then(res => {
          if(res) {
            console.log("logout success");
            callback(res);
          }
      }).catch(error => {
        //
      });
}