import React, { useState } from "react";
import MasterCardSVG from "../../styles/img/svg/sample-card-small.png";
import AmericanExpressSVG from "../../styles/img/svg/card-logo-amex.svg";
import VisaSVG from "../../styles/img/svg/card-logo-visa.svg";
import DiscoverSVG from "../../styles/img/svg/discover.png";
import UnknownCardSVG from "../../styles/img/svg/card-logo-unknown.svg";
import countries from "../../constants/countryconstant";
import { CardElement } from "react-stripe-elements";
import { Scrollbar } from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";

export default function PaymentDetails({
  invoiceData,
  chargesInfo,
  updateCustomerDetails,
  onUpdatingCardDetails,
  customerDetails
}) {
  const [editCardDetails, setEditCardDetails] = useState(false);
  const [editBillingDetails, setEditBillingDetails] = useState(false);
  const [showCountryDropDown, setshowCountryDropDown] = useState(false);
  const [name, setName] = useState(customerDetails.name);
  const [address, setAddress] = useState(invoiceData.address_line1);
  const [city, setCity] = useState(invoiceData.address_city);
  const [state, setState] = useState(invoiceData.address_state);
  const [zip, setZip] = useState(invoiceData.address_zip);
  const [country, setCountry] = useState(invoiceData.address_country);
  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    if (name.length === 0) {
      setErrors({ name: "Name not entered" });
    } else if (address.length === 0) {
      setErrors({ address: "Address not entered" });
    } else if (city.length === 0) {
      setErrors({ city: "City not entered" });
    } else if (state.length === 0) {
      setErrors({ state: "State not entered" });
    } else if (zip.length === 0) {
      setErrors({ zip: "zip not entered" });
    } else if (country.length === 0 || country === "Country") {
      setErrors({ country: "Country not selected" });
    } else {
      updateCustomerDetails(
        {
          card_id: invoiceData.id,
          name,
          address,
          city,
          state,
          zip,
          country
        },
        (response) => {
          if (response && response === "success") {
            setEditBillingDetails(false);
          }
        }
      );
    }
  };

  const renderCountryOption = countries.map((country) => (
    <div
      className="billing-select-ddown-list"
      key={country}
      onClick={() => {
        setCountry(country);
      }}
    >
      {country}
    </div>
  ));
  
  const onCardDetailUpdate = () => {
    onUpdatingCardDetails(
      {
        name,
        address,
        city,
        state,
        zip,
        country
      },
      (response) => {
        if (response && response === "success") setEditCardDetails(false);
      }
    );
  };

  return (
    <div className="bill-cnf-details-container">
      <div className="bill-cnf-details-row">
        <div className="bill-cnf-heading">Payment Method</div>
        {!editCardDetails && (
          <div className="bill-cnf-edit-container">
            <div className="bill-cnf-edit-left">
              <div className="bill-cnf-card-icon">
                {invoiceData.brand === "MasterCard" && (
                  <img src={MasterCardSVG} width="25" alt="master_card" />
                )}
                {invoiceData.brand === "American Express" && (
                  <img src={AmericanExpressSVG} width="25" alt="american_express" />
                )}
                {invoiceData.brand === "Visa" && (
                  <img src={VisaSVG} width="25" alt="visa" />
                )}
                {invoiceData.brand === "Discover" && (
                  <img src={DiscoverSVG} width="25" alt="discover" />
                )}
                {(chargesInfo &&
                  chargesInfo.data &&
                  invoiceData.brand !== "American Express" &&
                  chargesInfo.data[0].source.brand === "Discover" &&
                  chargesInfo.data[0].source.brand === "Visa" &&
                  chargesInfo.data[0].source.brand === "Master Card") ||
                  (chargesInfo &&
                    chargesInfo.data &&
                    chargesInfo.data[0].source.brand === "Unknown" && (
                      <img src={UnknownCardSVG} width="25" alt="card-img" />
                    ))}
              </div>
              <div className="bill-cnf-card-dots-container">
                <div className="bill-cnf-card-dots-holder">
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                </div>
                <div className="bill-cnf-card-dots-holder">
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                </div>
                <div className="bill-cnf-card-dots-holder">
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                  <div className="bill-cnf-card-dot"></div>
                </div>
                <div className="bill-cnf-card-ldigit">{invoiceData.last4}</div>
              </div>
            </div>
            <div
              className="bill-switch-plan"
              onClick={() => {
                setEditCardDetails(true);
                setEditBillingDetails(false);
                setErrors({});
              }}
            >
              Edit
            </div>
          </div>
        )}

        {editCardDetails && (
          <div className="billing-form-row pm" ng-show="editCard">
            <div className="billing-err-holder">
              <CardElement></CardElement>
              {/* <span ng-show="fieldError.card">{"Card details not valid"}</span> */}
            </div>
            <div className="billing-btn-holder">
              <button
                className="billing-btn cancel"
                onClick={() => setEditCardDetails(false)}
              >
                Cancel
              </button>
              <button className="billing-btn save" onClick={onCardDetailUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="bill-cnf-details-row">
        <div className="bill-cnf-heading">Billing Details</div>
        {!editBillingDetails && (
          <div className="bill-cnf-edit-container address">
            <div className="bill-cnf-edit-left">
              <div className="bill-cnf-address">
                {customerDetails.name +
                  ", " +
                  invoiceData.address_line1 +
                  ", " +
                  invoiceData.address_city +
                  ", " +
                  invoiceData.address_state +
                  ", " +
                  invoiceData.address_zip +
                  ", " +
                  invoiceData.country}
              </div>
            </div>
            <div
              className="bill-switch-plan"
              onClick={() => {
                setEditBillingDetails(true);
                setEditCardDetails(false);
              }}
            >
              Edit
            </div>
          </div>
        )}
        {editBillingDetails && (
          <div
            className="bill-cnf-deatails-edit-container"
            ng-if="billingDetails"
          >
            <div className="billing-form-row pm">
              <input
                type="text"
                className="billing-pm-control"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="billing-err-holder">
                {errors.name && <span>{errors.name}</span>}
              </div>
            </div>
            <div className="billing-form-row pm pm-details">
              <div className="billing-small-pm-control-holder">
                <input
                  type="text"
                  className="billing-pm-control"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div className="billing-err-holder">
                  {errors.address && <span>{errors.address}</span>}
                </div>
              </div>
              <div className="billing-small-pm-control-holder">
                <input
                  type="text"
                  className="billing-pm-control"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <div className="billing-err-holder">
                  {errors.city && <span>{errors.city}</span>}
                </div>
              </div>
            </div>
            <div className="billing-form-row pm pm-details">
              <div className="billing-small-pm-control-holder">
                <input
                  type="text"
                  className="billing-pm-control"
                  placeholder="State/Province"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <div className="billing-err-holder">
                  {errors.state && <span>{errors.state}</span>}
                </div>
              </div>
              <div className="billing-small-pm-control-holder">
                <input
                  type="text"
                  className="billing-pm-control"
                  placeholder="Zip/Postcode"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
                <div className="billing-err-holder">
                  {errors.zip && <span>{errors.zip}</span>}
                </div>
              </div>
            </div>
            <div className="billing-form-row pm">
              <OutsideClickHandler
                onOutsideClick={() => setshowCountryDropDown(false)}
              >
                <div
                  className="billing-select-control-holder"
                  onClick={() => setshowCountryDropDown(!showCountryDropDown)}
                >
                  <div
                    className={
                      country === "Country"
                        ? "billing-selected-country placeholder"
                        : "billing-selected-country"
                    }
                  >
                    {" "}
                    {country}{" "}
                  </div>
                  {showCountryDropDown && (
                    <div className="billing-select-ddown-holder">
                      <Scrollbar>{renderCountryOption}</Scrollbar>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <div className="billing-err-holder">
                {errors.country && <span>{errors.country}</span>}
              </div>
            </div>
            <div className="billing-btn-holder">
              <button
                className="billing-btn cancel"
                onClick={() => {
                  setEditBillingDetails(false);
                  setErrors({});
                }}
              >
                Cancel
              </button>
              <button className="billing-btn save" onClick={onSubmit}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
