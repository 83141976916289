import React, { PureComponent } from "react";
import _ from "lodash";
import moment from "moment";
import minus from "../../styles/img/svg/settings-minus.svg";
import plus from "../../styles/img/svg/settings-plus.svg";

import "../../styles/scss/peopleSettings.scss";
import "../../styles/scss/organization.scss";

import * as actions from "../../actions";
import { updateEachUserProfileAction } from "../../actions/people/peopleaction";
import { updateCustomerDetailsForUserAction } from "../../actions/workspace/workspaceaction";
import { updateLoginAuth } from "../../actions/login/loginaction";
import { connect } from "react-redux";
import Scrollbar from "react-scrollbars-custom";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import ProjectAccessSection from "./projectAccessSection";
import firebase from "../../firebase";

const mapDispatchToProps = dispatch => {
  return {
    getUserDetailsAction: ({ queryparams }, callback) => {
      dispatch(actions.getUserDetailsAction({ queryparams }, callback));
    },
    updateEachUserProfileAction(
      {
        auth_token,
        first_name,
        id,
        organization_name,
        country,
        email,
        img,
        tags,
        data,
      },
      callback
    ) {
      dispatch(
        updateEachUserProfileAction(
          {
            auth_token,
            first_name,
            id,
            organization_name,
            country,
            email,
            img,
            tags,
            data,
          },
          callback
        )
      );
    },
    updateCustomerDetailsAction(auth_token, super_admin_id, users, id) {
      dispatch(
        updateCustomerDetailsForUserAction(
          auth_token,
          super_admin_id,
          users,
          id
        )
      );
    },
    updateLoginAuth(user_details) {
      dispatch(updateLoginAuth(user_details));
    },
  };
};
class PeopleDetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userToShow: {},
      selectedTeam: "",
      teamDropdown: false,
      displayJobTitleddwn: false,
      selectedJobTitle: "",
      is_billable: false,
      hourly_rate: 0,
      cost_to_company: 0,
      showRemove: false,
      dailyWorkingHours: 8,
      currentWorkspace: "",
      firstUserDetails: [],
      savedSuccessfully: false,
      costErrMsgDisplay: false,
      billingErrMsgDisplay: false,
      currentWorkspaceDetails: null,
      UserRole: "USER",
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.clickedUserDetailsId !== state.userToShow &&
      !_.isUndefined(props.currentWorkSpaceId)
    ) {
      const id = props.currentWorkSpaceId.id.toString();
      const settingsObj =
        props.clickedUserDetailsId.data &&
        props.clickedUserDetailsId.data.settings
          ? props.clickedUserDetailsId.data.settings
          : {};

      if (!settingsObj.hasOwnProperty(id)) {
        return {
          userToShow: props.clickedUserDetailsId,
          selectedTeam: "",
          selectedJobTitle: "",
          is_billable: false,
          hourly_rate: 0,
          dailyWorkingHours: 8,
          cost_to_company: 0,
          UserRole:
            props.currentWorkSpaceId.users[props.clickedUserDetailsId.id],
        };
      }
      return {
        currentWorkspaceDetails: props.currentWorkSpaceId,
        userToShow: props.clickedUserDetailsId,
        UserRole: props.currentWorkSpaceId.users[props.clickedUserDetailsId.id],
        selectedTeam:
          props.clickedUserDetailsId.data &&
          props.clickedUserDetailsId.data.settings &&
          props.clickedUserDetailsId.data.settings[id].team,
        selectedJobTitle:
          props.clickedUserDetailsId.data &&
          props.clickedUserDetailsId.data.settings &&
          props.clickedUserDetailsId.data.settings[id].job_title,
        is_billable:
          props.clickedUserDetailsId.data &&
          props.clickedUserDetailsId.data.settings &&
          props.clickedUserDetailsId.data.settings[id].is_billable,
        hourly_rate:
          (props.clickedUserDetailsId.data &&
            props.clickedUserDetailsId.data.settings &&
            props.clickedUserDetailsId.data.settings[id].hourly_rate) ||
          0,
        dailyWorkingHours:
          props.clickedUserDetailsId.data &&
          props.clickedUserDetailsId.data.settings &&
          props.clickedUserDetailsId.data.settings[id].daily_working_hrs,
        cost_to_company:
          (props.clickedUserDetailsId.data &&
            props.clickedUserDetailsId.data.settings &&
            props.clickedUserDetailsId.data.settings[id].cost_to_company) ||
          0,
      };
    }
    return null;
  }

  handleTeamddwClick = e => {
    
    this.setState({
      selectedTeam: e.target.innerText,
    });
  };
  toggleTeamDropdown = () => {
    this.setState({
      teamDropdown: !this.state.teamDropdown,
    });
  };
  removeTeamDropdown = () => {
    this.setState({
      teamDropdown: false,
    });
  };
  handleJobTitleddwnClick = e => {
    
    this.setState({
      selectedJobTitle: e.target.innerText,
    });
  };
  toggleJobTitleDropdown = () => {
    this.setState({
      displayJobTitleddwn: !this.state.displayJobTitleddwn,
    });
  };
  removeJobTitleDropdown = () => {
    this.setState({
      displayJobTitleddwn: false,
    });
  };
  switchBillable = () => {
    this.setState({
      is_billable: !this.state.is_billable,
    });
  };
  showRemove = () => {
    this.setState({
      showRemove: !this.state.showRemove,
    });
  };
  dontShowRemove = () => {
    this.setState({
      showRemove: false,
    });
  };
  decrementHours = () => {
    let num = this.state.dailyWorkingHours;
    if (num > 1) {
      num = num - 1;
      this.setState({
        dailyWorkingHours: num,
      });
    }
  };
  incrementHours = () => {
    let num = this.state.dailyWorkingHours;
    if (num < 24) {
      num = num + 1;
      this.setState({
        dailyWorkingHours: num,
      });
    }
  };
  billingAmount = e => {
    if (e.target.value.length > 0) {
      return this.setState({
        hourly_rate: parseInt(e.target.value),
      });
    }
    this.setState({
      hourly_rate: 0,
    });
  };
  costToCompany = e => {
    if (e.target.value.length > 0) {
      return this.setState({
        cost_to_company: parseInt(e.target.value),
      });
    }
    this.setState({
      cost_to_company: 0,
    });
  };
  filteredValue = value => {
    
    if (isNaN(value)) {
      return (value = 0);
    } else {
      return value;
    }
  };
  updatePeople = () => {
    const {
      getUserDetailsAction,
      updateEachUserProfileAction,
      updateCustomerDetailsAction,
      getProjectsAction,
    } = this.props;
    let { userToShow, UserRole } = this.state;

    const currentWorkSpaceId = this.props.currentWorkSpaceId.id;
    const users = this.props.currentWorkSpaceId.users;
    const auth_token = localStorage.getItem("customer_auth_token");
    const id = this.props.currentWorkSpaceId.id;

    getUserDetailsAction(
      { queryparams: `?id=${parseInt(userToShow.id)}` },
      response => {
        if (response && Array.isArray(response) && response.length > 0) {
          userToShow = response[0];
          const updatedUser = { [userToShow.id]: UserRole };
          const updatedUsers = Object.assign(users, updatedUser);

          let data = null;
          if (userToShow.data && userToShow.data.hasOwnProperty("settings")) {
            data = {
              ...userToShow.data,
              settings: {
                ...userToShow.data.settings,
                [currentWorkSpaceId.toString()]: {
                  team:
                    this.state.selectedTeam === "Select"
                      ? ""
                      : this.state.selectedTeam,
                  job_title:
                    this.state.selectedJobTitle === "Select"
                      ? ""
                      : this.state.selectedJobTitle,
                  is_billable: this.state.is_billable,
                  cost_to_company: this.state.cost_to_company,
                  daily_working_hrs: this.state.dailyWorkingHours,
                  hourly_rate: this.state.hourly_rate,
                },
              },
            };
          } else {
            data = {
              ...userToShow.data,
              settings: {
                [currentWorkSpaceId.toString()]: {
                  team: this.state.selectedTeam,
                  job_title: this.state.selectedJobTitle,
                  is_billable: this.state.is_billable,
                  cost_to_company: this.state.cost_to_company,
                  daily_working_hrs: this.state.dailyWorkingHours,
                  hourly_rate: this.state.hourly_rate,
                },
              },
            };
          }

          if (!userToShow.tags) {
            userToShow.tags = [];
          }

          if (this.state.is_billable === false) {
            updateEachUserProfileAction(
              {
                auth_token,
                first_name: userToShow.first_name,
                organization_name: userToShow.organization_name || "",
                country: userToShow.country || "",
                email: userToShow.email,
                // img: userToShow.img,
                tags: userToShow.tags,
                id: userToShow.id,
                data,
              },
              res => {
                if (!res.hasOwnProperty("stdErr")) {
                  if (
                    res.id &&
                    parseInt(res.id) === parseInt(this.props.currentUser.id)
                  ) {
                    localStorage.setItem("data", JSON.stringify(res.data));
                    this.props.updateLoginAuth({
                      ...this.props.currentUser,
                      ...res,
                      data: res.data,
                    });
                  }
                  updateCustomerDetailsAction(
                    {
                      auth_token,
                      super_admin_id: this.props.currentWorkSpaceId
                        .super_admin_id,
                      users: updatedUsers,
                      id,
                    },
                    res => {
                      if (res) {
                        this.setState({
                          savedSuccessfully: !this.state.savedSuccessfully,
                          costErrMsgDisplay: false,
                          billingErrMsgDisplay: false,
                        });
                        firebase.analytics().logEvent("GTD_People_Save");
                        toast("Changes saved successfully!");
                        getProjectsAction(
                          {
                            auth_token: localStorage.getItem(
                              "customer_auth_token"
                            ),
                            queryParam: "?archived=false",
                          },
                          response => {
                            if (response && response.length > 0) {
                              // TODO document why this block is empty
                            
                            }
                          }
                        );
                      } else {
                        // TODO document why this block is empty
                      
                        
                      }
                    }
                  );
                } else {
                  // TODO document why this block is empty
                
                  
                }
              }
            );
          } else {
            if (
              this.state.cost_to_company === 0 &&
              this.state.hourly_rate === 0
            ) {
              
              this.setState({
                is_billable: false,
                costErrMsgDisplay: true,
                billingErrMsgDisplay: true,
              });
            } else if (this.state.cost_to_company === 0) {
              
              this.setState({
                is_billable: false,
                costErrMsgDisplay: true,
                billingErrMsgDisplay: false,
              });
            } else if (this.state.hourly_rate === 0) {
              
              this.setState({
                is_billable: false,
                billingErrMsgDisplay: true,
                costErrMsgDisplay: false,
              });
            } else {
              
              this.setState({
                is_billable: true,
              });
              updateEachUserProfileAction(
                {
                  auth_token,
                  first_name: userToShow.first_name,
                  organization_name: userToShow.organization_name || "",
                  country: userToShow.country || "",
                  email: userToShow.email,
                  // img: userToShow.img,
                  tags: userToShow.tags,
                  id: userToShow.id,
                  data,
                },
                res => {
                  if (
                    res.id &&
                    parseInt(res.id) === parseInt(this.props.currentUser.id)
                  ) {
                    localStorage.setItem("data", JSON.stringify(res.data));
                    this.props.updateLoginAuth({
                      ...this.props.currentUser,
                      ...res,
                      data: res.data,
                    });
                  }
                  // update user role in workspace
                  this.setState({
                    savedSuccessfully: !this.state.savedSuccessfully,
                    costErrMsgDisplay: false,
                    billingErrMsgDisplay: false,
                  });
                  updateCustomerDetailsAction(
                    {
                      auth_token,
                      super_admin_id: this.props.currentWorkSpaceId
                        .super_admin_id,
                      users: updatedUsers,
                      id,
                    },
                    res => {
                      if (res) {
                        firebase.analytics().logEvent("GTD_People_Save");
                        toast("Changes saved successfully!");
                        getProjectsAction(
                          {
                            auth_token: localStorage.getItem(
                              "customer_auth_token"
                            ),
                            queryParam: "?archived=false",
                          },
                          response => {
                            if (response && response.length > 0) {
                              // TODO document why this block is empty
                            
                            }
                          }
                        );
                      } else {
                        // TODO document why this block is empty
                      
                        
                      }
                    }
                  );
                }
              );
            }
          }
        }
      }
    );
  };
  onRoleChange = e => {
    console.log(e.target.value);
    if (e.target.value === "SUPERADMIN")
      firebase.analytics().logEvent("GTD_People_Made_Owner");
    if (e.target.value === "USER")
      firebase.analytics().logEvent("GTD_People_Made_Member");
    this.setState({
      UserRole: e.target.value,
    });
    
  };

  onRemoveCurrentUser = () => {
    
    const {
      removeFromSelectedOwnerArray,
      showDetails,
      totalUsers,
    } = this.props;
    this.showRemove();
    removeFromSelectedOwnerArray(this.state.userToShow.id);
    showDetails(
      totalUsers.filter(user => user.id !== this.state.userToShow.id)[0]
    );
  };
  render() {
    
    const super_admin_id = localStorage.getItem("id");

    let UserRole =
      this.state.UserRole ||
      (this.props.currentWorkspaceDetails &&
      this.props.currentWorkspaceDetails.users.hasOwnProperty(
        this.state.userToShow.id
      )
        ? this.state.currentWorkspaceDetails.users[this.state.userToShow.id]
        : "USER");
    let name = this.props.clickedUserDetailsId.first_name;
    let firstLetter = name.charAt(0).toUpperCase();
    let length = name.length;
    let newName = firstLetter + " " + name.slice(1, length).toLowerCase();
    return (
      <Scrollbar>
        <div className="ppl-settings-control-inner-holder">
          <div className="Ppl-settings-control-outer-holder">
            {/* 1 */}
            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Created date</div>
              <div className="ppl-settings-indv-control-holder">
                {this.state.userToShow &&
                  moment(this.state.userToShow.created_at).format(
                    "ddd, DD MMM YY"
                  )}
              </div>
            </div>
            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title img-ppl-container">
                {this.state.userToShow && !this.state.userToShow.img && (
                  <Avatar
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: "50%",
                    }}
                    name={newName}
                    maxInitials={1}
                  ></Avatar>
                )}
                {this.state.userToShow.img && (
                  <img src={this.state.userToShow.img} alt="user-img" />
                )}
              </div>
              <div className="ppl-settings-indv-control-holder">
                {this.state.userToShow && this.state.userToShow.first_name}
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Role</div>
              <div
                className="ppl-settings-indv-control-holder align-horizontal"
                onChange={this.onRoleChange}
              >
                {this.props.currentWorkSpaceId?.super_admin_id ===
                this.state.userToShow.id ? (
                  <div>Creator</div>
                ) : (
                  <div className="ppl-settings-radio-holder">
                    <input
                      type="radio"
                      name="role"
                      value="SUPERADMIN"
                      id="owner-radio"
                      checked={UserRole === "SUPERADMIN" ? true : false}
                    />
                    <label htmlFor="owner-radio">Owner</label>
                  </div>
                )}
                {super_admin_id !== this.state.userToShow.id &&
                  this.props.currentWorkSpaceId &&
                  this.props.currentWorkSpaceId.super_admin_id !==
                    this.state.userToShow.id && (
                    <div className="ppl-settings-radio-holder">
                      <input
                        type="radio"
                        name="role"
                        value="USER"
                        id="member-radio"
                        checked={UserRole === "USER" ? true : false}
                      />
                      <label htmlFor="member-radio">Member</label>
                    </div>
                  )}
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Email</div>
              <div className="ppl-settings-indv-control-holder">
                {this.state.userToShow && this.state.userToShow.email}
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Team</div>
              <div className="ppl-settings-indv-control-holder settings-team">
                <OutsideClickHandler onOutsideClick={this.removeTeamDropdown}>
                  <div
                    className="ppl-settings-dropdown-holder"
                    onClick={this.toggleTeamDropdown}
                  >
                    {" "}
                    {this.state.selectedTeam || "Select"}
                    {this.state.teamDropdown && (
                      <div className="ppl-settings-ddown team-ddown">
                        <div className="ppl-settings-ddown-inner-holder">
                          {(
                            (this.props.currentWorkSpaceId.data &&
                              this.props.currentWorkSpaceId.data.settings &&
                              this.props.currentWorkSpaceId.data.settings
                                .teams &&
                              _.sortBy(
                                this.props.currentWorkSpaceId.data.settings
                                  .teams,
                                team => team
                              )) || ["Design", "Development", "HR"]
                          ).map(team => (
                            <div
                              className="ppl-settings-ddown-list settings-list-options"
                              value={team}
                              onClick={e => this.handleTeamddwClick(e)}
                            >
                              {" "}
                              {team}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Job title</div>
              <div className="ppl-settings-indv-control-holder job-title-control-holder">
                <OutsideClickHandler
                  onOutsideClick={this.removeJobTitleDropdown}
                >
                  <div
                    className="ppl-settings-dropdown-holder big"
                    onClick={this.toggleJobTitleDropdown}
                  >
                    {this.state.selectedJobTitle || "Select"}
                    {this.state.displayJobTitleddwn && (
                      <div
                        className="ppl-settings-ddown team-ddown"
                        // style={{ width: "220px" }}
                      >
                        <div className="ppl-settings-ddown-inner-holder">
                          {(
                            (this.props.currentWorkSpaceId.data &&
                              this.props.currentWorkSpaceId.data.settings &&
                              this.props.currentWorkSpaceId.data.settings
                                .job_titles &&
                              _.sortBy(
                                this.props.currentWorkSpaceId.data.settings
                                  .job_titles
                              )) || ["Growth catalyst", "HR", "UX designer"]
                          ).map(job_title => (
                            <div
                              className="ppl-settings-ddown-list"
                              value={job_title}
                              onClick={e => this.handleJobTitleddwnClick(e)}
                            >
                              {" "}
                              {job_title}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">
                Daily working hours
              </div>
              <div className="ppl-settings-indv-control-holder">
                <div className="ppl-settings-billing-control-holder">
                  <div className="stepper-wrapper minus-plus-settings">
                    <span
                      className="minus"
                      value="{this.state.dailyWorkingHours}"
                      onClick={this.decrementHours}
                    >
                      <img src={minus} alt="decrease" />
                    </span>
                    <span
                      className="day-hours-settings"
                      // style={{
                      //   position: "absolute",
                      //   top: "5px",
                      //   marginLeft: "20px",
                      // }}
                    >
                      {this.state.dailyWorkingHours}
                    </span>
                    <span
                      className="plus"
                      value="{this.state.dailyWorkingHours}"
                      onClick={this.incrementHours}
                    >
                      <img src={plus} alt="increase" />
                    </span>
                  </div>
                  <div
                    className="ppl-settings-error-msg"
                    style={{ display: "none" }}
                  >
                    Please enter daily working hours
                  </div>
                </div>
              </div>
            </div>

            <div className="ppl-settings-control-holder">
              <div className="ppl-settings-control-title">Billable</div>
              <div className="ppl-settings-indv-control-holder">
                <div className="ppl-settings-switch-holder">
                  <div
                    className={
                      this.state.is_billable
                        ? "ppl-settings-switch on"
                        : "ppl-settings-switch off"
                    }
                    onClick={this.switchBillable}
                  ></div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.is_billable
                  ? "ppl-settings-control-holder"
                  : "ppl-settings-control-holder disabled"
              }
            >
              <div className="ppl-settings-control-title">
                Hourly billing rate
              </div>
              <div className="ppl-settings-indv-control-holder">
                <div className="ppl-settings-billing-control-holder">
                  <div className="ppl-settings-billing-unit">
                    {this.props.currencyLogo}
                  </div>
                  <input
                    type="number"
                    className="ppl-settings-billing-control"
                    placeholder="Enter amount"
                    onChange={e => this.billingAmount(e)}
                    value={
                      this.state.hourly_rate !== 0 && this.state.hourly_rate
                    }
                  />
                  {this.state.billingErrMsgDisplay ? (
                    <div className="ppl-settings-error-msg">
                      Please enter hourly billing rate
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                this.state.is_billable
                  ? "ppl-settings-control-holder"
                  : "ppl-settings-control-holder disabled"
              }
            >
              <div className="ppl-settings-control-title">Cost to company</div>
              <div className="ppl-settings-indv-control-holder">
                <div className="ppl-settings-billing-control-holder">
                  <div className="ppl-settings-billing-unit">
                    {this.props.currencyLogo}
                  </div>
                  <input
                    type="number"
                    className="ppl-settings-billing-control"
                    placeholder="Enter amount"
                    onChange={e => this.costToCompany(e)}
                    value={
                      this.state.cost_to_company !== 0 &&
                      this.state.cost_to_company
                    }
                  />
                  {this.state.costErrMsgDisplay ? (
                    <div className="ppl-settings-error-msg">
                      Please enter cost to company
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="button-row">
              <button className="settings-btn" onClick={this.updatePeople}>
                Save
              </button>

              {super_admin_id !== this.state.userToShow.id &&
                this.props.currentWorkSpaceId &&
                this.props.currentWorkSpaceId.super_admin_id !==
                  this.state.userToShow.id && (
                  <div className="ppl-remove-member-container">
                    <OutsideClickHandler onOutsideClick={this.dontShowRemove}>
                      <span
                        className="ppl-remove-member"
                        onClick={this.showRemove}
                        // onMouseOver="this.style.backgroundColor='none'"
                      >
                        Remove member
                      </span>
                      {/* delete people */}
                      {this.state.showRemove && (
                        <div className="Edit-board-ddown rm-user-wp">
                          <div className="confirmation-box-remove">
                            <p className="user-name">
                              Remove {this.state.userToShow.first_name}?
                            </p>
                            <hr className="divider-remove-box"></hr>
                            <p className="messageremove-box msg-rm-wp">
                              The Users access from this Workspace will be
                              removed but all the data entered by the user for
                              the Workspace will be retained.
                            </p>
                            <div className="button-box">
                              <button
                                className="cancel-button-remove-box"
                                onClick={this.showRemove}
                              >
                                Cancel
                              </button>
                              <button
                                className="confirm-button-remove-box"
                                onClick={this.onRemoveCurrentUser}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>
                )}
            </div>
          </div>
          <ProjectAccessSection
            userIdToShow={this.state.userToShow}
            currentWorkSpaceId={this.props.currentWorkSpaceId}
            totalUsers={this.props.totalUsers}
          />
        </div>
      </Scrollbar>
    );
  }
}

export default connect(null, mapDispatchToProps)(PeopleDetailsForm);
