import React, { Component } from "react";
import AppleSignOn from "../applesignon";

class ForgotPasswordAppleSignIn extends Component {

  render() {
    return (
      <div className="signIn">
        <div className="fp-header-area fp-google-header">
          <div className="fp-main-heading">Sign in with Apple</div>
          <div className="fp-sub-heading fp-google-sub-heading">
            This account is created using Apple sign up. Try signing in with
            the same credentials.
          </div>

          <div>
            <AppleSignOn
              fromFp={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordAppleSignIn;

