import { peopleConstants } from "../../../src/constants/peopleconstants";

import {
  getWorkspaceUser,
  getInvitedEmails,
  getRequestedUsers
} from "../../services/workspaceapi";
import { getUserDetails, updateUserProfile } from "../../services/userapi";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function removeWorkspaceUser({ id }) {
  return {
    type: peopleConstants.removeWorkspaceUser,
    payload: id
  }
}

function updateWorkSpaceUsers(users) {
  return {
    type: peopleConstants.updateWorkspaceUser,
    payload: users
  };
}

// Update the list of invited emails in reducer
function updateInvitedEmails(users) {
  return {
    type: peopleConstants.getInvitedEmails,
    payload: users
  };
}

// Update the list of requested users in reducer
function updateRequestedUsers(users) {
  return {
    type: peopleConstants.getRequestedUsers,
    payload: users
  };
}

export const getInvitedEmailsAction = (
  { auth_token },
  callback
) => dispatch => {
  getInvitedEmails({ auth_token }, response => {
    if (response && !response.error) {
      dispatch(updateInvitedEmails(response));
    }
    callback && callback(response);
  });
};

export const clearInvitedEmailsAction = () => dispatch => {
  dispatch({ type: peopleConstants.clearInvitedEmails, payload: [] })
}

export const getRequestedUsersAction = (
  { auth_token },
  callback
) => dispatch => {
  getRequestedUsers({ auth_token }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(updateRequestedUsers(response));
    }
    callback && callback(response);
  });
};

export const getUserDetailsAction = (
  { queryparams, auth_token },
  callback
) => dispatch => {
  getUserDetails({ queryparams, auth_token }, response => {
    if (response && !response.error) {
      dispatch(updateWorkSpaceUsers(response));
    }
    callback && callback(response);
  });
};

export const getAllCustomerForPeopleAction = (
  { loaderKey = peopleConstants.customerLoaderState },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: loaderKey,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );

  getWorkspaceUser(response => {
    if (response && !response.error) {
      dispatch(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateWorkSpaceUsers(response));
    } else {
      dispatch(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateWorkspaceUsersAfterRemoveUser = ({ id }) => dispatch => {
  dispatch(removeWorkspaceUser({ id }))
}

export const updateEachUserProfileAction = (
  { auth_token, first_name, id, organization_name, country, email, img, tags, data },
  callback
) => dispatch => {
  updateUserProfile(
    { auth_token, first_name, id, organization_name, country, email, img, tags, data },
    response => {
      dispatch(
        updateLoaderState({
          action: peopleConstants.updateEachUserProfile,
          state: {
            fetching: true,
            fetched: false,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      if (response && !response.hasOwnProperty("stdErr") && !response.error) {
        dispatch(
          updateLoaderState({
            action: peopleConstants.updateEachUserProfile,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
        dispatch(updateEachWorkspaceUserProfile({ id, userDetails: response }));
      } else {
        dispatch(
          updateLoaderState({
            action: peopleConstants.updateEachUserProfile,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value:
                  response.error || response.stdErr || "Some Error Occured!"
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const updateEachWorkspaceUserProfile = ({ id, userDetails }) => {
  return {
    type: peopleConstants.updateEachWorkspaceUserProfile,
    payload: {
      id: id,
      user: userDetails
    }
  };
};
