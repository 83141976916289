import React, { Component } from 'react';
import { UserAgentApplication } from 'msal';
// import DialogWrapper from "../common/dialog";
import { GraphFileBrowser } from '@microsoft/file-browser';
import ReactModal from 'react-modal';

const SCOPES = ['Files.ReadWrite'];

class OneDriveAttachment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: null,
      isPopupEnable: false
    };
    this.msal = new UserAgentApplication({
      auth: {
        clientId: props.appKey,
        redirectUri: props.replayurl
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    });
  }

  authenticate = () => {
    let  loginFlag=this.msal.getLoginInProgress();
    if(loginFlag){
      this.msal.setloginInProgress(false);
    }
   
      this.msal.loginPopup(SCOPES).then(idtoken => {
        this.msal.acquireTokenSilent({ scopes: SCOPES }).then(token => {
          this.setState({ token: token.accessToken, isPopupEnable: true });
          this.props.updateAttchmetnOneDrive(true);
          console.log('token',token)

        }, err => {
          this.msal.acquireTokenPopup({ scopes: SCOPES }).then(tokendata=>{
            console.log('tokendata',tokendata.accessToken)
            this.setState({ token: tokendata.accessToken, isPopupEnable: true });

          },err=>{
            
          })
        })
      },err=>{
      })
  }

  authData = (keys) => {

    keys.forEach(singledrive => {
      for (let data in singledrive) {
        fetch(singledrive[data][0] + '/drive/items/' + singledrive[data][2], {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'bearer ' + this.state.token
          },
        }).then(data => {
          data.json().then(responsedata => {
            this.props.oneDriveUpload(responsedata);
          })
        }, err => {
        });
      }
    })
    this.props.updateAttchmetnOneDrive(false);
    this.setState({isPopupEnable:false,token:null})
  }

  
  handleCloseModal= ()=> {
    this.props.updateAttchmetnOneDrive(false);
    this.setState({isPopupEnable:false,token:null})
  }

  render() {

    return (
      <>
        <label className="attch-from-link from-onedrive" onClick={() => !this.state.token ? this.authenticate() : ''}> OneDrive</label>
        <div
          ref={node => { this.node = node; }}
          style={{'zIndex':'5000'}}
          >
        {this.state.isPopupEnable && (
         
            <ReactModal isOpen={this.state.isPopupEnable}
              onRequestClose={this.handleCloseModal}
            >
              <GraphFileBrowser
                getAuthenticationToken={() => Promise.resolve(this.state.token)}
                onSuccess={(keys) => { this.authData(keys) }}
                onCancel={(data) =>{
                  this.props.updateAttchmetnOneDrive(false);
                  this.setState({isPopupEnable:false,token:null})
                 }}
              />
            </ReactModal >
        )}
         </div>
      </>
    )
  }

}

export default OneDriveAttachment;