import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";
// import { activities } from "../constants/activitiescontants";


const ID="id";

export function getUserDetails({ queryparams }, callback) {
  let url = API_URL + "/api/v1/client";
  secureFetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function createProject({ name, img = "" }, callback) {
  let url = API_URL + "/api/v1/project";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify({ name, img }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export const changeActivityWorkFlow = (
  { id, workflow, customer_auth_token },
  callback
) => {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({ id, workflow,"performed_by":JSON.parse(userId) }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + customer_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};

export const startTimesheetEntry = (
  {
    activity_id,
    data,
    action = "START",
    date,
    is_archived = false,
    user_id,
    customer_auth_token
  },
  callback
) => {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/new-timesheet?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify({
      activity_id,
      data,
      user_id,
      action,
      date,
      is_archived
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + customer_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};

export const stopTimesheetEntry = (
  {
    activity_id,
    data,
    action = "STOP",
    time_taken,
    tags,
    description,
    attachments,
    id,
    date,
    is_archived = false,
    user_id,
    customer_auth_token
  },
  callback
) => {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/new-timesheet?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      activity_id,
      data,
      action,
      time_taken,
      tags,
      description,
      attachments,
      id,
      date,
      is_archived,
      user_id
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + customer_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};

export const createTimesheetEntry = (
  {
    tags,
    attachments,
    activity_id,
    data,
    action = "CREATE",
    time_taken,
    description,
    id,
    date,
    is_archived = false,
    user_id,
    customer_auth_token
  },
  callback
) => {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/new-timesheet?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify({
      tags,
      attachments,
      activity_id,
      data,
      action,
      time_taken,
      description,
      id,
      date,
      is_archived,
      user_id
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + customer_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};

export function fetchTimesheet({ from_date, to_date, user_id }, callback) {
  let url = API_URL + "/api/v1/timesheet";

  let queryParams =
    from_date && to_date && user_id
      ? `?from_date=${from_date}&to_date=${to_date}&user_id=${user_id}&is_archived=false`
      : "";
  //console.log(url + queryParams);
  secureFetch(url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
}

//Old Activity POST
// export function createActivity(
//   {
//     activity_type,
//     created_at,
//     updated_on,
//     created_by,
//     due_on,
//     duration,
//     hours,
//     is_active,
//     minutes,
//     platform,
//     project_id,
//     start_on,
//     tags,
//     title,
//     user_id,
//     data,
//     workflow,
//     completed_on,
//     description
//   },
//   callback
// ) {
//   let url = API_URL + "/api/v1/activity";

//   secureFetch(url, {
//     method: "POST",
//     body: JSON.stringify({
//       activity_type,
//       created_at,
//       updated_on,
//       created_by,
//       due_on,
//       duration,
//       hours,
//       is_active,
//       minutes,
//       platform,
//       project_id,
//       start_on,
//       tags,
//       title,
//       user_id,
//       workflow,
//       data,
//       completed_on,
//       description
//     }),
//     headers: new Headers({
//       "Content-Type": "application/json",
//       Origin: API_URL,
//       Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
//     })
//   })
//     .then((respJson) => {
//       //console.log(respJson);
//       callback(respJson);
//     })
//     .catch((err) => callback(err));
// }

//New Activity POST
export function createActivity(body, callback) {
  let url = API_URL + "/api/v1/activity";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
}

export function updateActivity(
  {
    id,
    due_on,
    duration,
    hours,
    minutes,
    project_id,
    start_on,
    tags,
    title,
    description,
    users_duration,
    continuous_activity,
    user_id,
    data,
    activity_type = "ACTIVITY",
    version_id,
    performed_by,
    is_locked
  },
  callback
) {
  let userid=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid);
  //console.log(title);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      id,
      activity_type,
      due_on,
      duration,
      hours,
      minutes,
      project_id,
      start_on,
      data,
      tags,
      title,
      description,
      users_duration,
      continuous_activity,
      user_id,
      version_id,
      "performed_by":JSON.parse(userid),
      is_locked
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
}

export function updateActivityFromWorkload(
  { activity_type = "ACTIVITY", id, version_id, workflow },
  callback
) {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userId);
  //console.log(title);

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      activity_type,
      id,
      version_id,
      workflow,
      "performed_by":JSON.parse(userId)
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
}

export const getArchivedActivities = (
  {
    activity_type = "ACTIVITY",
    is_active,
    user_id,
    is_archieved = true,
    page,
    per_page
  },
  callback
) => {
  let url = API_URL + "/api/v1/activity";
  let isActive = (is_active !== undefined && "&is_active=" + is_active) || "";
  let userId = (user_id && "&user_id=" + user_id) || "";
  let isArchived =
    (is_archieved !== undefined && "&is_archived=" + is_archieved) || "";
  let page_no = (page && "&page=" + page) || "";
  let item_per_page = (per_page && "&per_page=" + per_page) || "";
  let activity_type_param =
    (activity_type && "&activity_type=" + activity_type) || "";
  let params =
    activity_type_param +
    userId +
    isActive +
    isArchived +
    page_no +
    item_per_page;

  secureFetch(url + `?${params}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
};

export const getActivitiesByProject = (
  {
    activity_type = "ACTIVITY",
    is_active = true,
    project_id,
    is_archived = false,
    user_id
  },
  callback
) => {
  let url = API_URL + "/api/v1/activity";
  let isActive = (is_active !== undefined && "&is_active=" + is_active) || "";
  let projectId = (project_id && "&project_id=" + project_id) || "";
  let isArchived =
    (is_archived !== undefined && "&is_archived=" + is_archived) || "";
  let activity_type_param =
    (activity_type && "activity_type=" + activity_type) || "";
  let userId = user_id ? "&user_id=" + user_id : null;

  let params = activity_type_param + projectId + isActive + isArchived;
  if (userId) {
    params = activity_type_param + projectId + isActive + isArchived + userId;
  }

  secureFetch(url + `?${params}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      callback(respJson);
    })
    .catch((err) => callback(err));
};


export const getAllBacklogActivity=({params},callback)=>{
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  secureFetch(API_URL + "/api/v1/activity" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });

}

export const getActivitiesForDropdown = ({ userId, auth_token }, callback) => {
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  const params = `?logged_in_user=${userId}`
  secureFetch(API_URL + "/api/v1/get-activity-cards" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
}

export const getSearchedArchivedActivities=(params, callback)=>{
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  secureFetch(API_URL + "/api/v1/search-activity" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
}

export function MarkActivityRead(body, callback) {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/mark-activity-card-as-read?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
}

export const addTimesheetEntry = (
  {
    activity_id,
    time_taken,
    action = "ADD_TIME",
    tags,
    description,
    date,
    is_archived = false,
    user_id,
    completed_durations,
    customer_auth_token
  },
  callback
) => {
  let userId=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/new-timesheet?logged_in_user=" + parseInt(userId);
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify({
      tags,
      activity_id,
      action,
      time_taken,
      description,
      date,
      is_archived,
      user_id,
      completed_durations
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + customer_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};
