/**
 * This is the configuration file for routing.
 * All the routes in the application need to be placed here
 *
 * author Madhusudhan Seetharamiah
 */

import React from "react";
import Application from "../components/app.jsx";
import { Provider } from "react-redux";
import Login from "../components/login/login";
import {
  Router,
  Route,
  browserHistory,
  Redirect
} from "react-router";
import { StripeProvider } from "react-stripe-elements";
import { REACT_APP_STRIPE_KEY } from "../constants/globalconst";
// import Notification from "../components/notifications/notification.jsx";
// import WrapperComponent from "../components/notifications/wrappercomponent.jsx";
// import NotificationComponent from "../components/notifications/notification.jsx";
// import CommentNotify from "../components/notifications/commentnotify.jsx";
// import MainNotifyComponent from "../components/notifications/mainnotifycomponent.jsx";
import FindWorkspace from "../components/workspace/findworkspace";
import WorkspaceInvitations from "../components/workspace/workspaceinvitations.jsx";
import JoinWorkspace from "../components/workspace/joinworkspace";
import CreateWorkspace from "../components/profile/changepasswordpopup";
import AddUsersToWorkspace from "../components/workspace/adduserstoworkspace";
// import SendVerification from "../components/registration/sendVerification";
import FinishProfileView from "../components/profile/finishProfileView";
// import ImageUploader from "../components/common/imageuploader";
// import ProjectSettings from "../components/settings/projectSettings";
import CreateFirstProject from "../components/workspace/createfirstproject";
// import SettingsLanding from "../components/settings/settingslanding";
// import PeopleSettings from "../components/people/peoplesettings";
import VerifyEmail from "../components/registration/sendVerification";
import { ConfigProvider } from "react-avatar";
import Welcome from '../components/webtour/welcome';

const Root = ({ store }) => (
  <Provider store={store}>
    <StripeProvider apiKey={REACT_APP_STRIPE_KEY}>
      <ConfigProvider
        colors={[
          "#7C9AF1",
          "#FF9292",
          "#9F6DF8",
          "#8373D5",
          "#72D76F",
          "#FFAE60",
          "#59A5F4",
          "#DF8669",
          "#54B4B0",
          "#C785D4"
        ]}
      >
        <Router history={browserHistory}>
          <Redirect from="/workspace" to="/activity" exact />
          <Route path="/workspace" component={Application}>
            <Route path="create" component={Application} />
            <Route path="add-users" component={AddUsersToWorkspace} />
            <Route path="create" component={CreateWorkspace} />
            {/* <Route path="settings" component={SettingsLanding} /> */}
            <Route path="find" component={FindWorkspace} />
            <Route path="pending-invites" component={WorkspaceInvitations} />
            <Route path="join" component={JoinWorkspace} />
          </Route>
          {/* <Route path='/login' component={ResetPassword}/> */}
          <Route path="/verify-email" component={VerifyEmail} />
          {/* <Route path="/settings" component={SettingsLanding} /> */}
          <Route path="/settings" component={Application} />
          <Route path="/activity" component={Application} />
          <Route path="/projects" component={Application} />
          <Route path="/workload" component={Application}></Route>
          <Route path="/report" component={Application} />
          <Route path="/dashboard" component={Application} />
          <Route path="/archive-center" component={Application}>
            <Route
              path="/archive-center/all-activities"
              component={Application}
            />
            <Route
              path="/archive-center/all-projects"
              component={Application}
            />
            <Route
              path="/archive-center/all-checklists"
              component={Application}
            />
            <Route
              path="/archive-center/all-comments"
              component={Application}
            />
          </Route>
          <Route
            path="/project/:projId/editProjectDetail"
            component={Application}
          />
          
          <Route path="/login" component={Login} />
          <Route path="/welcome" component={Welcome} />
          <Route path="/forgot-password" component={Login} />
          <Route path="/user-profile" component={Application} />
          <Route path="/register" component={Login} />
          <Route path="/reset-password" component={Login} />
          {/* <Route path="/userverification" component={SendVerification} /> */}
          <Route path="/finish-profile" component={FinishProfileView}></Route>
          <Route path="/create/project" component={CreateFirstProject}></Route>
          <Route path="/project/:projId/kanban" component={Application}></Route>
          <Redirect from="*" to="/login" />
        </Router>
      </ConfigProvider>
    </StripeProvider>
  </Provider>
);

export default Root;
