import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../actions/index";
import {
  getWorkSpaceUsersView,
  getUpdatedProjectToAddPeople
} from "../../helpers/projectsettings";
import Avatar from "react-avatar";
import searchField from "../../styles/img/svg/settings-search-icon1.svg";
import checkMark from "../../styles/img/svg/checkmark.svg";
import _ from "lodash";
import firebase from "../../firebase";
import { getProjectById } from "../../services/projectapi";
class AssignPeopleSearchBox extends Component {
  state = {
    searchText: "",
    updatedWorkSpaceUsers: null,
    selectedWorkSpaceUsers: []
  };

  onChangeSearch = (event) => {
    let searchText = event.target.value;
    const { workSpaceUsers } = this.props;
    let updatedWorkSpaceUsers = workSpaceUsers.filter(
      (workSpaceUser) =>
        workSpaceUser.first_name
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1 ||
        workSpaceUser.email.toLowerCase().indexOf(searchText.toLowerCase()) !==
          -1
    );
    this.setState({ searchText, updatedWorkSpaceUsers });
  };

  onSelectWorkSpaceUser = (event, workSpaceUser) => {
    const { workSpaceUsers } = this.props;
    let selectedWorkSpaceUsers = [...this.state.selectedWorkSpaceUsers];
    workSpaceUsers.forEach((user) => {
      if (user.id === workSpaceUser.id) {
        if (selectedWorkSpaceUsers.indexOf(workSpaceUser.id) > -1)
          selectedWorkSpaceUsers.splice(
            selectedWorkSpaceUsers.indexOf(workSpaceUser.id),
            1
          );
        else selectedWorkSpaceUsers.push(workSpaceUser.id);
      }
    });
    this.setState({ selectedWorkSpaceUsers });
  };

  onCancel = (event) => {
    event.stopPropagation();
    this.props.onHideSearch();
  };

  onAssignPeople = (event) => {
    const { selectedWorkSpaceUsers } = this.state;
    const {
      workSpaceUsers,
      projectDetails,
      currentWorkSpaceId,
      currentWorkSpace
    } = this.props;
    getProjectById(projectDetails.archived, projectDetails.id, response => {
      const body = getUpdatedProjectToAddPeople(
        response?.length > 0 ? response[0] : projectDetails,
        workSpaceUsers,
        selectedWorkSpaceUsers,
        currentWorkSpaceId,
        currentWorkSpace
      );
      this.props.updateProjectAction({ queryParam: "", body }, (response) => {
        if (response && response.id) {
          firebase.analytics().logEvent("GTD_Project_Member_Assigned");
          event.stopPropagation();
          this.props.onHideSearch();
        }
      });
    });
  };

  // handleClickOutside = event => {
  //   if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
  //     this.props.onHideSearch();
  //   }
  // };

  // UNSAFE_componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClickOutside, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside, false);
  // }

  render() {
    const {
      updatedWorkSpaceUsers,
      selectedWorkSpaceUsers,
      searchText
    } = this.state;
    const {
      workSpaceUsers,
      currentWorkSpaceId,
      projectDetails,
      currentWorkSpace
    } = this.props;
    const workSpaceUsersView = getWorkSpaceUsersView(
      updatedWorkSpaceUsers || workSpaceUsers,
      currentWorkSpaceId,
      currentWorkSpace,
      projectDetails
    );
    return (
      <div
        className="edit-board-ddown proj-ddown assign-ppl-ddown"
        // ref={node => (this.outerDivNode = node)}
      >
        <div className="edit-board-search-holder">
          <input
            type="text"
            className="edit-board-search-control ppl-search-box"
            placeholder="Search"
            //aria-label="Search"
            onChange={this.onChangeSearch}
            value={this.state.searchText}
            autoFocus
          />
          <div className="edit-search-icon-holder">
            <img src={searchField} alt="search-field-icon" />
          </div>
        </div>

        <div className="search-data-container ppl-search-container">
          {workSpaceUsersView.length > 0 &&
            _.sortBy(workSpaceUsersView, "name").map((workSpaceUser, index) => (
              <div
                key={index}
                onClick={(event) =>
                  this.onSelectWorkSpaceUser(event, workSpaceUser)
                }
                className="search-data-list ppl-list"
              >
                {(_.isNull(workSpaceUser.img) ||
                  _.isEmpty(workSpaceUser.img)) === true ? (
                  <div className="user-img-icon project-assign-img">
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      maxInitials={1}
                      name={workSpaceUser.name}
                    ></Avatar>
                  </div>
                ) : (
                  <div className="user-img-icon project-assign-img">
                    <img src={workSpaceUser.img} alt="workspace-user-icon" />
                  </div>
                )}

                <div className="user-details-holder user-workspace-proj">
                  <div className="user-name">{workSpaceUser.name}</div>
                  <div className="user-email">{workSpaceUser.job_title}</div>
                </div>
                {selectedWorkSpaceUsers.indexOf(workSpaceUser.id) > -1 && (
                  <img
                    className="user-add-action check-selected"
                    src={checkMark}
                    alt="checkbox-icon"
                  />
                )}
              </div>
            ))}
          {workSpaceUsersView.length === 0 && !searchText && (
            <div className="no-result-found">No people to assign</div>
          )}
          {workSpaceUsersView.length === 0 && searchText && (
            <div className="no-result-found">No results found</div>
          )}
        </div>

        <div className="project-assign-hr" />

        <div className="project-assign-bottom">
          <button className="project-assign-cancel" onClick={this.onCancel}>
            Cancel
          </button>
          <button
            className="applyBtn btn btn-sm btn-primary project-assign-confirm"
            disabled={selectedWorkSpaceUsers.length < 1 ? true : false}
            onClick={this.onAssignPeople}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     workSpaceUsers: state.peopleReducer.workspaceusers
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    // getAllCustomer: ({ auth_token }, callback) => {
    //   dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    // },
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    }
  };
};

export default connect(null, mapDispatchToProps)(AssignPeopleSearchBox);
