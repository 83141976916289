import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import "../../styles/scss/dashboardNew.scss";
import EffortOverview from "./effortOverview";
import IndividualEffort from "./individualEffort";
import ProjectAccessTeam from "./projAccessCard";
import ProjectStatus from "./projectStatus";
import MissedTimeSheets from "./missedTimeSheets";
import EffortOverviewPersonal from "./effortOverviewPersonal";
import LeaderBoard from "./leaderBoard";
import ProjectAccessPersonal from "./projectAccessPersonal";
import * as action from "../../actions/index";
import {
  getListOfCurrenciesAction
} from "../../actions/workspace/workspaceaction";
// import {
//   individualEffort,
//   effortOverviewPersonal,
//   projectAccessReqTeam,
//   missedTimeSheets,
//   projectAccessReqPersonal,
//   leaderBoard,
//   projectStatus
// } from "./dummyData";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
// import { getListOfCurrencies } from "../../services/workspaceapi";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      effOverTeamStartDate: moment()
      .subtract(1, "week")
      .format("ddd, DD MMM YY"),
      effOverTeamEndDate: moment()
      .subtract(1, "day")
      .format("ddd, DD MMM YY"),
      effOverPersonalStartDate: moment()
      .subtract(1, "week")
      .format("ddd, DD MMM YY"),
      effOverPersonalEndDate: moment()
      .subtract(1, "day")
      .format("ddd, DD MMM YY"),
      individualEffStartDate: moment()
      .subtract(1, "week")
      .format("ddd, DD MMM YY"),
      individualEffEndDate: moment()
      .subtract(1, "day")
      .format("ddd, DD MMM YY"),
      missedTimeSheetsDate: moment()
      .subtract(1, "day")
      .format("ddd, DD MMM YY"),
      projectStatusSortBy: { id: 1, name: "Most Critical" },
      leaderBoardSortBy: { id: 2, name: "This month" }
    };
  }

  componentDidMount() {
    const {
      router,
      currentWorkSpace,
      currentUser,
      updateDashboardTabStateAction,
      getEffortOverviewAction,
      getIndividualEffortAction,
      getProjectAccessReqTeamAction,
      getMissedTimeSheetsAction,
      getEffortOverviewPersonalAction,
      getProjectAccessReqPersonalAction,
      getProjectStatusAction,
      getLeaderBoardAction,
      getListOfCurrenciesAction
    } = this.props;
    getListOfCurrenciesAction(res => {
      if(!res.stdErr && res?.length >= 0) {
        if (router.location.action === "PUSH") {
          const customer_id = parseInt(currentWorkSpace.id);
          const from = moment()
            .subtract(1, "week")
            .format("YYYY-MM-DD");
          const to = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          const queryParam = `?customer_id=${customer_id}&from_date=${from}&to_date=${to}`;
          if(getIsSuperAdmin(currentUser, currentWorkSpace)) {
            getEffortOverviewAction(
              { auth_token: currentWorkSpace.auth_token, queryParam },
              res => {}
            );
            getIndividualEffortAction(
              {
                auth_token: currentWorkSpace.auth_token,
                queryParam: queryParam.concat("&type=individual-effort")
              },
              res => {}
            );
            getProjectAccessReqTeamAction(
              {
                auth_token: currentWorkSpace.auth_token,
                queryParam: queryParam.concat("&type=project-access-request")
              },
              res => {}
            );
            getMissedTimeSheetsAction(
              {
                auth_token: currentWorkSpace.auth_token,
                queryParam: `?customer_id=${customer_id}&from_date=${to}&to_date=${to}&type=missed-timesheet-entries`
              },
              res => {}
            );
            getProjectStatusAction(
              {
                auth_token: currentWorkSpace.auth_token,
                queryParam: queryParam.concat("&type=project-status&project_critical=high")
              },
              res => {}
            );
          } else {
            updateDashboardTabStateAction({ dashboardLanding: "Personal" })
          }
          getEffortOverviewPersonalAction(
            {
              auth_token: currentUser.auth_token,
              queryParam
            }
          );
          getProjectAccessReqPersonalAction(
            {
              auth_token: currentUser.auth_token,
              queryParam: queryParam.concat("&type=project-access-request")
            }
          );
          getLeaderBoardAction(
            {
              auth_token: currentUser.auth_token,
              queryParam: `?customer_id=${customer_id}&from_date=${moment().startOf("month").format("YYYY-MM-DD")}&to_date=${moment().endOf("month").format("YYYY-MM-DD")}&type=leaderboard`
            }
          );
        }
      }
    });
  }

  componentDidUpdate() {
    if (this.props.dashboardTabState === "Team" && document.getElementsByClassName("daterangepicker").length > 0) {
      let i;
      for(i = 0; i < document.getElementsByClassName("daterangepicker").length; i++) {
        document
        .getElementsByClassName("daterangepicker")[i]
        .classList.add("dash-picker");
      }
      if(document.getElementsByClassName("auto-apply").length > 0)
        document
          .getElementsByClassName("auto-apply")[0]
          .classList.replace("dash-picker", "dash-picker-single");
    }
    if (this.props.dashboardTabState === "Personal" && document.getElementsByClassName("daterangepicker").length > 0) {
      document
        .getElementsByClassName("daterangepicker")[0]
        .classList.add("dash-picker");
    }
  }

  componentWillUnmount() {
    this.props.clearDashboardReducerAction();
  }

  onChangeEffortOverviewDate = (startDate, endDate) => {
    const { currentWorkSpace, getEffortOverviewAction } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    const queryParam = `?customer_id=${customer_id}&from_date=${fromDate}&to_date=${toDate}`;
    getEffortOverviewAction(
      { auth_token: currentWorkSpace.auth_token, queryParam },
      res => {
        if(res) {
          this.setState({ effOverTeamStartDate: startDate, effOverTeamEndDate: endDate })
        }
      }
    );
  };

  onChangeIndividualEffortDate = (startDate, endDate) => {
    const { currentWorkSpace, getIndividualEffortAction } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    const queryParam = `?customer_id=${customer_id}&from_date=${fromDate}&to_date=${toDate}&type=individual-effort`;
    getIndividualEffortAction(
      { auth_token: currentWorkSpace.auth_token, queryParam },
      res => {
        if(res) {
          this.setState({ individualEffStartDate: startDate, individualEffEndDate: endDate })
        }
      }
    );
  };

  onChangeMissedTimeSheetsDate = (date) => {
    const { currentWorkSpace, getMissedTimeSheetsAction } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
    const toDate = moment(date).format("YYYY-MM-DD");
    getMissedTimeSheetsAction(
      {
        auth_token: currentWorkSpace.auth_token,
        queryParam: `?customer_id=${customer_id}&from_date=${toDate}&to_date=${toDate}&type=missed-timesheet-entries`
      },
      res => {
        if(res) {
          this.setState({ missedTimeSheetsDate: date })
        }
      }
    );
  }

  onChangeEffortOverviewPersonalDate = (startDate, endDate) => {
    const { currentWorkSpace, currentUser, getEffortOverviewPersonalAction } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    const queryParam = `?customer_id=${customer_id}&from_date=${fromDate}&to_date=${toDate}`;
    getEffortOverviewPersonalAction(
      { auth_token: currentUser.auth_token, queryParam },
      res => {
        if(res) {
          this.setState({ effOverPersonalStartDate: startDate, effOverPersonalEndDate: endDate })
        }
      }
    );
  };

  onChangeProjectStatusSortBy = (sortOp) => {
    const {
      currentWorkSpace,
      getProjectStatusAction
    } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
      const from = moment()
        .subtract(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      const to = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      let queryParam = `?customer_id=${customer_id}&from_date=${from}&to_date=${to}&type=project-status&project_critical=`;
      switch(sortOp.id) {
        case 0:
          queryParam = queryParam.concat("all");
          break;
        case 1:
          queryParam = queryParam.concat("high");
          break;
        case 2:
          queryParam = queryParam.concat("medium");
          break;
        case 3:
          queryParam = queryParam.concat("low");
          break;
        default:
          queryParam = queryParam.concat("high");
      }
    getProjectStatusAction(
      {
        auth_token: currentWorkSpace.auth_token,
        queryParam
      },
      res => {
        if(res)
        this.setState({ projectStatusSortBy: sortOp });
      }
    );
  }

  onChangeLeaderBoardSortBy = (sortOp) => {
    const {
      currentWorkSpace,
      currentUser,
      getLeaderBoardAction
    } = this.props;
    const customer_id = parseInt(currentWorkSpace.id);
    let queryParam = `?customer_id=${customer_id}&`;
    switch(sortOp.id) {
      case 0:
        queryParam = queryParam.concat(`from_date=${moment().startOf("week").format("YYYY-MM-DD")}&to_date=${moment().endOf("week").format("YYYY-MM-DD")}&type=leaderboard`);
        break;
      case 1:
        queryParam = queryParam.concat(`from_date=${moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD")}&to_date=${moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")}&type=leaderboard`);
        break;
      case 2:
        queryParam = queryParam.concat(`from_date=${moment().startOf("month").format("YYYY-MM-DD")}&to_date=${moment().endOf("month").format("YYYY-MM-DD")}&type=leaderboard`);
        break;
      case 3:
        queryParam = queryParam.concat(`from_date=${moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")}&to_date=${moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")}&type=leaderboard`);
        break;
      default:
        queryParam = queryParam.concat(`from_date=${moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")}&to_date=${moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")}&type=leaderboard`);
    }
    getLeaderBoardAction(
      {
        auth_token: currentUser.auth_token,
        queryParam
      },
      res => {
        if(res)
        this.setState({ leaderBoardSortBy: sortOp });
      }
    );
  }

  render() {
    const {
      dashboardTabState,
      currentUser,
      currentWorkSpace,
      effortOverview,
      effortOverviewPersonal,
      individualEffort,
      projectAccessReqTeam,
      projectAccessReqPersonal,
      updateProjectAccessReqTeamAction,
      updateProjectAccessReqPersonalAction,
      getProjectAccessReqPersonalAction,
      missedTimeSheets,
      projectStatus,
      leaderBoard
    } = this.props;
    const currencyLogo = (currentWorkSpace && currentWorkSpace.data.settings &&
      currentWorkSpace.data.settings.currency && this.props.listOfCurrencies.length > 0 &&
      this.props.listOfCurrencies.filter(d =>
        currentWorkSpace.data.settings.currency.includes(d.code)
      )[0].symbol) || "$"
    return (
      <div className="dashboard-main-card">
        <div className="dashboard-block">
          {dashboardTabState === "Team" && <Scrollbar>
            <div className="dash-outer">
              <div className="dash-inner">
                <div className="over-individual-holder">
                  {effortOverview && (
                    <div className="eff-over-holder">
                      <EffortOverview
                        startDate={this.state.effOverTeamStartDate}
                        endDate={this.state.effOverTeamEndDate}
                        effortOverview={effortOverview}
                        onChangeEffortOverviewDate={
                          this.onChangeEffortOverviewDate
                        }
                      />
                    </div>
                  )}
                  {individualEffort && (
                    <div className="individual-eff-holder">
                      <IndividualEffort
                        currencyLogo={currencyLogo}
                        startDate={this.state.individualEffStartDate}
                        endDate={this.state.individualEffEndDate}
                        individualEffort={individualEffort}
                        onChangeIndividualEffortDate={
                          this.onChangeIndividualEffortDate
                        }
                      />
                    </div>
                  )}
                  {missedTimeSheets && <div className="missed-time-sheet-holder">
                  <MissedTimeSheets
                    date={this.state.missedTimeSheetsDate}
                    missedTimeSheets={missedTimeSheets}
                    onChangeMissedTimeSheetsDate={
                      this.onChangeMissedTimeSheetsDate
                    }
                  />
                </div>}
                </div>
                <div className="proj-access-status-holder">
                {projectAccessReqTeam && (
                  <div className="proj-access-req-holder">
                    <ProjectAccessTeam
                      currentWorkSpace={currentWorkSpace}
                      projectAccessReqTeam={projectAccessReqTeam}
                      updateProjectAccessReqTeamAction={
                        updateProjectAccessReqTeamAction
                      }
                    />
                  </div>
                )}
                {projectStatus && <div className="proj-status-card-holder">
                  <ProjectStatus
                  sortBy={this.state.projectStatusSortBy}
                  onChangeSortBy={this.onChangeProjectStatusSortBy}
                  projectStatus={projectStatus} />
                </div>}
                </div>
              </div>
            </div>
          </Scrollbar>}
          {dashboardTabState === "Personal" && <Scrollbar>
            <div className="dash-outer">
              <div className="dash-inner">
                <div className="over-individual-holder">
                  {effortOverviewPersonal && (
                    <div className="eff-over-holder personal">
                      <EffortOverviewPersonal
                        startDate={this.state.effOverPersonalStartDate}
                        endDate={this.state.effOverPersonalEndDate}
                        effortOverview={effortOverviewPersonal}
                        onChangeEffortOverviewDate={
                          this.onChangeEffortOverviewPersonalDate
                        }
                      />
                    </div>
                  )}
                  {leaderBoard && (
                    <div className="proj-status-card-holder personal">
                      <LeaderBoard
                        sortBy={this.state.leaderBoardSortBy}
                        onChangeSortBy={this.onChangeLeaderBoardSortBy}
                        leaderBoard={leaderBoard}
                        onChangeLeaderBoardDate={
                          this.onChangeLeaderBoardDate
                        }
                      />
                    </div>
                  )}
                </div>
                {projectAccessReqPersonal && (
                  <div className="proj-access-req-holder">
                    <ProjectAccessPersonal
                      currentWorkSpace={currentWorkSpace}
                      currentUser={currentUser}
                      projectAccessReqPersonal={projectAccessReqPersonal}
                      updateProjectAccessReqPersonalAction={
                        updateProjectAccessReqPersonalAction
                      }
                      getProjectAccessReqPersonalAction={getProjectAccessReqPersonalAction}
                    />
                  </div>
                )}
              </div>
            </div>
          </Scrollbar>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfCurrencies: state.wrorkSpaceReducer.listOfCurrencies,
    effortOverview: state.dashboardReducer.effortOverview.data,
    individualEffort: state.dashboardReducer.individualEffort.list,
    // individualEffort,
    projectAccessReqTeam: state.dashboardReducer.projectAccessReqTeam.list,
    // projectAccessReqTeam,
    missedTimeSheets: state.dashboardReducer.missedTimeSheets.list,
    // missedTimeSheets,
    effortOverviewPersonal: state.dashboardReducer.effortOverviewPersonal.data,
    // effortOverviewPersonal,
    projectAccessReqPersonal: state.dashboardReducer.projectAccessReqPersonal.list,
    // projectAccessReqPersonal,
    projectStatus: state.dashboardReducer.projectStatus.list,
    // projectStatus,
    leaderBoard: state.dashboardReducer.leaderBoard.list,
    // leaderBoard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProjectAccessReqTeamAction({ auth_token, body }, callback) {
      dispatch(
        action.updateProjectAccessReqTeamAction({ auth_token, body }, callback)
      );
    },
    updateProjectAccessReqPersonalAction({ auth_token, body, method }, callback) {
      dispatch(
        action.updateProjectAccessReqPersonalAction({ auth_token, body, method }, callback)
      );
    },
    getListOfCurrenciesAction: (callback) => {
      dispatch(getListOfCurrenciesAction(callback))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
