import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  deleteCommentAction,
  updateCommentsCountAfterDelete,
  editCommentAction,
  updateLikeBookmarkCommentAction
} from "../../actions/comments/commentsaction";
import OutsideClickHandler from "react-outside-click-handler";
import dotSeparator from "../../styles/img/svg/Dot.svg";
import likeIcon from "../../styles/img/svg/like.svg";
import closeIcon from "../../styles/img/svg/Close_White.svg";
import likedIcon from "../../styles/img/svg/liked.svg";
import bookmarkIcon from "../../styles/img/svg/bookmark.svg";
import bookmarkedIcon from "../../styles/img/svg/bookmarked.svg";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import Avatar from "react-avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tooltip } from "@material-ui/core";
import { commonDateFormat, parseComment,parseCommentEditor } from "../../helpers/generichelper";
import ReactHtmlParser from "react-html-parser";
import AttachmentDropdown from "../attachments/attachmentDropdown";
import _ from "lodash";
import moment from "moment";
import { abortUpload } from "../../services/awsapi";
import CommentsEditor, { InlineStyleControls, BlockStyleControls } from "./commentsEditor";
import { EditorState, RichUtils, convertToRaw, convertFromRaw, Modifier, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';

import richblock from '../../styles/img/svg/aa.svg';
import mentionSymbol from '../../styles/img/svg/at.svg';
import * as Immutable from 'immutable';
const { OrderedSet } = Immutable;

class CommentItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popupId: null,
      user: {},
      commentTyped:
        localStorage.getItem("commentEditCache") &&
          JSON.parse(localStorage.getItem("commentEditCache")).id ===
          props.commentDetails.id
          ? JSON.parse(localStorage.getItem("commentEditCache")).text
          : (this.props.commentDetails.title || this.props.commentDetails.description),
      replyCommentTyped:
        localStorage.getItem("commentReplyCache") &&
          JSON.parse(localStorage.getItem("commentReplyCache")).id ===
          props.commentDetails.id
          ? JSON.parse(localStorage.getItem("commentReplyCache")).text
          : "",
      openReplyAttachmentDropdown: false,
      openEditAttachmentDropdown: false,
      attachmentsToEdit: [],
      attachmentsToReply: [],
      attachmentsFile: [],
      attachmentsToRemove: "",
      uploadPercentage: null,
      attachmentFrom: "",
      hasAttachment: false,
      displayErrMsgEdit: false,
      displayErrMsgReply: false,
      attachmentsErr: false,
      showMoreOptions: false,
      showArchivePopup: false,
      noItem: false,
      commentTypedEditorState: localStorage.getItem("commentEditCache") &&
        JSON.parse(localStorage.getItem("commentEditCache")).id ===
        props.commentDetails.id
        ? EditorState.moveFocusToEnd(EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.parse(localStorage.getItem("commentEditCache")).text))))
        : EditorState.createEmpty(),
      replyCommentTypedEditorState: localStorage.getItem("commentReplyCache") &&
        JSON.parse(localStorage.getItem("commentReplyCache")).id ===
        props.commentDetails.id
        ? EditorState.moveFocusToEnd(EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.parse(localStorage.getItem("commentReplyCache")).text))))
        : EditorState.createEmpty(),
      showcustomtoolbar: false,
      attachmentOneDrive:false,
      inlineStyleData:[],
      clickedImg: null
    };
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  showConfirmPopup = (comment) => {
    if (comment.id === this.state.popupId)
      this.setState(() => ({
        popupId: null
      }));
    else
      this.setState(() => ({
        popupId: comment.id
      }));
  };
  cancelPopup = () => {
    this.setState(() => ({
      popupId: null
    }));
  };
  deleteComment = (comment) => {
    const {
      deleteComment,
      is_priority,
      is_archived,
      updateCommentsCountAfterDeleteAction,
      comments
    } = this.props;
    const commentObjectToDelete = {
      activity_type: "COMMENT",
      user_id: comment.user_id,
      created_by: comment.created_by,
      created_at: comment.created_at,
      title: comment.title,
      id: comment.id,
      data: comment.data,
      is_active: false,
      version_id: comment.version_id,
      parent_activity_id: comment.parent_activity_id,
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      is_priority,
      is_Activity_archived: is_archived
    };
    this.setState(() => ({
      popupId: null
    }));
    deleteComment(commentObjectToDelete, (_response) => {
      let count;
      if (comment.data.commentDetails.isReply) {
        count = comments.filter((cmnt) => cmnt.id !== comment.id).length;
      } else {
        count = comments
          .filter((cmnt) => cmnt.id !== comment.id)
          .filter((cmnt) => {
            if (cmnt.data.commentDetails.isReply) {
              if (cmnt.data.commentDetails.parentCommentId !== comment.id) {
                return cmnt;
              }
            }
            if (!cmnt.data.commentDetails.isReply) return cmnt;
          }).length;
      }
      updateCommentsCountAfterDeleteAction({
        is_priority,
        is_Activity_archived: is_archived,
        parent_activity_id: comment.parent_activity_id,
        count
      });
      this.props.updateFetch();
    });
  };

  editComment = () => {
    const { loggedinUser, editComment } = this.props;
    const comment = this.props.commentDetails;
    let commentAdded = convertToRaw(this.state.commentTypedEditorState.getCurrentContent());
    const markup = this.state.commentTypedEditorState.getCurrentContent().hasText() ? draftToHtml(
      commentAdded,
    ) : "";
    const commentObjectToEdit = {
      activity_type: "COMMENT",
      is_active: true,
      created_at: comment.created_at,
      created_by: comment.created_by,
      data: comment.data,
      id: comment.id,
      parent_activity_id: comment.parent_activity_id,
      platform: "WEB",
      title: markup.replaceAll('&nbsp;', ' '),
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      user_id: comment.user_id,
      version_id: comment.version_id,
      attachments: this.state.attachmentsToEdit.filter(attachment => attachment.id)
    };
    commentObjectToEdit.data.lastModifiedBy = parseInt(loggedinUser.id);
    commentObjectToEdit.data.commentDetails.hasAttachment = this.state.hasAttachment || comment.data.commentDetails.hasAttachment;

    // Multipart/form-data attachments start
    const { attachmentsFile, attachmentsToRemove } = this.state;
    let formData;
    if(attachmentsFile.length > 0 || attachmentsToRemove.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else
          formData.append(`attachment${index + 1}`, JSON.stringify(file));
      })
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "comment");
      formData.append("entity_id", comment.id);
      attachmentsToRemove.length > 0 && formData.append("removed_attachment_id", attachmentsToRemove);
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end

    editComment(commentObjectToEdit, formData, (response, attRes) => {
      if(response && response.id) {
        const { closeOpenedEditBox } = this.props;
        closeOpenedEditBox();
        this.setState({
          hasAttachment: false,
          commentTypedEditorState: EditorState.createEmpty(),
          attachmentsToEdit: [],
          attachmentsFile: [],
          attachmentsToRemove: "" 
        }, () => {
          localStorage.removeItem("commentEditCache");
        });
        if(attRes?.stdErr) {
          this.setState({ attachmentsErr: true }, () => {
            setTimeout(() => {
              this.setState({ attachmentsErr: false })
            }, 5000)
          })
        }
        comment.title = this.state.commentTyped;
        if (comment.description) {
          comment.description = markup.replaceAll('&nbsp;', ' ');
        }
      }
    });
  };
  replyToComment = () => {
    const {
      addCommentAction,
      loggedinUser,
      closeOpenedReplyBox,
      is_priority,
      is_archived
    } = this.props;
    const comment = this.props.commentDetails;
    let commentAdded = convertToRaw(this.state.replyCommentTypedEditorState.getCurrentContent());
    const markup = this.state.replyCommentTypedEditorState.getCurrentContent().hasText() ? draftToHtml(
      commentAdded,
    ) : "";
    const commentData = {
      checklist: [],
      commentDetails: {
        isReply: true,
        hasAttachment: this.state.hasAttachment,
        parentCommentId: comment.data.commentDetails.isReply
          ? comment.data.commentDetails.parentCommentId
          : comment.id
      },
      lastModifiedBy: parseInt(loggedinUser.id)
    };
    const commentObj = {
      activity_type: "COMMENT",
      created_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      created_by: parseInt(loggedinUser.id),
      data: commentData,
      description: markup.replaceAll('&nbsp;', ' '),
      parent_activity_id: comment.parent_activity_id,
      platform: "WEB",
      project_id: comment.project_id,
      title: markup.replaceAll('&nbsp;', ' '),
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      user_id: [parseInt(loggedinUser.id)],
      version: "2.0.1",
      is_priority,
      is_Activity_archived: is_archived,
      // attachments: this.state.attachmentsToReply
    };

    // Multipart/form-data attachments start
    const { attachmentsFile } = this.state;
    let formData;
    if(attachmentsFile.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else
          formData.append(`attachment${index + 1}`, JSON.stringify(file));
      })
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "comment");
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end

    addCommentAction(commentObj, formData, (response, attRes) => {
      if(response && response.id) {
        this.setState({
          replyCommentTyped: "",
          hasAttachment: false,
          replyCommentTypedEditorState: EditorState.createEmpty(),
          attachmentsToReply: [],
          attachmentsFile: []
        }, () => {
          localStorage.removeItem("commentReplyCache");
        });
        if(attRes?.stdErr) {
          this.setState({ attachmentsErr: true }, () => {
            setTimeout(() => {
              this.setState({ attachmentsErr: false })
            }, 5000)
          })
        }
        localStorage.removeItem("openedReplyBoxId");
        closeOpenedReplyBox();
      }
    });
  };

  closeEditBox = () => {
    this.props.closeOpenedEditBox();
    this.setState({
      commentTyped:
        this.props.commentDetails.title || this.props.commentDetails.description,
      editorState: EditorState.createEmpty(),
      attachmentsToEdit: [],
      attachmentsFile: [],
      attachmentsToRemove: ""
    });
  };
  // new addition
  componentDidMount() {
    const {
      // activityDetails,
      commentDetails,
      userDetails,
      idOfElement
    } = this.props;
    const user = userDetails.filter(
      (user) => user.id === commentDetails.created_by
    );
    this.setState(() => ({
      user: user[0]
    }));

    if (document.querySelectorAll('[role="dialog"]').length > 0) {
      // selecting dialog for the scroll
      const elementsToScroll = document.querySelectorAll('[role="dialog"]')[0];
      // Element to which scrolled
      let element = document.getElementById(idOfElement);

      // Is use part of the card
      // const isUserPartOfCard = activityDetails.user_id.includes(
      //   parseInt(localStorage.getItem("id"))
      // );

      
      if (!_.isNull(element) && idOfElement.includes(`${commentDetails.id}`)) {
        // scrolling 250 is just an offset
        elementsToScroll.scrollTo({
          top: element.offsetTop + 250,
          behavior: "auto"
        });

        element.classList.add("new-comment");

        setTimeout(function () {
          element.classList.remove("new-comment");
        }, 3000);
      }
    }
  }
  // new addition ends
  componentDidUpdate() {
    setTimeout(() => {
      if (
        document.getElementsByClassName(
          "comment-text-area__suggestions__list"
        )[0]
      ) {
        const liElement = document.createElement("li");
        liElement.classList += "checklist-textarea-input__suggestions__item";
        var para = document.createElement("span");
        para.className += "allnotify";
        const textInsideSpan = document.createTextNode(
          "@act will notify all members in the activity"
        );
        para.appendChild(textInsideSpan);
        const elementWhereToInsert = document.getElementsByClassName(
          "comment-text-area__suggestions__list"
        )[0];
        
        if (document.getElementsByClassName("allnotify").length > 0) {
          return;
        } else {
          elementWhereToInsert.insertBefore(
            para,
            elementWhereToInsert.firstChild
          );
        }
      }
    }, 10);
  }
  isEdited = (created_at, updated_on) => {
    return moment(moment(updated_on).format("YYYY-MM-DDTHH:mm:ss")).isAfter(
      moment(created_at).format("YYYY-MM-DDTHH:mm:ss")
    );
  };
  handleCommentOnEdit = (_event, newValue, _newPlainTextValue, _mentions) => {
    const inputBox = document.querySelector(".comment-edit-box-container");
    if (inputBox && this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    const newString = newValue === "\n" ? [] : newValue.split(" ");
    const manipulatedString = newString.map((value) => {
      if (value.indexOf("@") > -1) {
        
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });
    let finalValue = manipulatedString.join(" ");
    localStorage.setItem(
      "commentEditCache",
      JSON.stringify({
        id: this.props.commentDetails.id,
        text: finalValue
      })
    );
    localStorage.setItem("openedEditBoxId", this.props.commentDetails.id);
    this.setState({
      commentTyped: finalValue
    });
  };
  openReplyBox = (comment) => {
    const { changeOpenedReplyBox } = this.props;
    changeOpenedReplyBox(comment.id);
    const { loggedinUser, userDetails } = this.props;
    const user = _.filter(
      userDetails,
      (user) => parseInt(user.id) === parseInt(comment.created_by)
    );
    const whomToReply =
      user.length > 0 && parseInt(loggedinUser.id) !== parseInt(user[0].id)
        ? "@" + user[0].username + " "
        : "";
        const currentContent = this.state.replyCommentTypedEditorState.getCurrentContent(),
        currentSelection = this.state.replyCommentTypedEditorState.getSelection();
  
      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        whomToReply
      );
  
      const newEditorState = EditorState.push(this.state.replyCommentTypedEditorState, newContent, 'insert-fragment');
      
    this.setState({
      replyCommentTypedEditorState: newEditorState,
      replyCommentTyped: whomToReply,
      attachmentsToReply: []
    }, () => {
      const inputBox = document.querySelector(".comment-edit-box-container");
      if (inputBox && this.isInViewport(inputBox) === false) {
        inputBox.scrollIntoView({ behavior: "auto", block: "end" });
      }
    });
  };

  handleReplyOnChange = (_event, newValue, _newPlainTextValue, _mentions) => {
    const inputBox = document.querySelector(".comment-edit-box-container")
    if (this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    const newString = newValue === "\n" ? [] : newValue.split(" ");
    const manipulatedString = newString.map((value) => {
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });

    let finalValue = manipulatedString.join(" ");
    localStorage.setItem(
      "commentReplyCache",
      JSON.stringify({
        id: this.props.commentDetails.id,
        text: finalValue
      })
    );
    localStorage.setItem("openedReplyBoxId", this.props.commentDetails.id);

    this.setState({
      replyCommentTyped: finalValue
    });
  };

  renderSuggestion = (entry, _search, _highlightedDisplay, _index, _focused) => {
    return (
      <>
        <div
          className={
            !entry.image ? "comments-people avatar" : "comments-people"
          }
        >
          {entry.image && <img src={entry.image} alt="user-logo" className="user-photo" />}
          {!entry.image && (
            <Avatar
              style={{ width: "100%", height: "100%" }}
              name={entry.display}
              maxInitials={1}
            ></Avatar>
          )}

          <span>{entry.username}</span>
        </div>
        <div
          className={
            !entry.image
              ? "mentio-name avatar ng-binding"
              : "mentio-name ng-binding"
          }
        >
          {entry.display}
        </div>
      </>
    );
  };
  openAttachmentDropdown = (type) => {
    if (type === "edit") {
      if (this.state.openEditAttachmentDropdown)
        this.setState({
          openEditAttachmentDropdown: false
        });
      else
        this.setState({
          openEditAttachmentDropdown: true
        });
    } else {
      if (this.state.openReplyAttachmentDropdown)
        this.setState({
          openReplyAttachmentDropdown: false
        });
      else
        this.setState({
          openReplyAttachmentDropdown: true
        });
    }
  };

  //----------Old closeAttachmentDropdownOnEdit--------------------------------
  // closeAttachmentDropdownOnEdit = (response) => {
  //   if (response && response.url) {
  //     this.setState({
  //       attachmentFrom: "",
  //       uploadPercentage: null,
  //       hasAttachment: true,
  //       openEditAttachmentDropdown: false,
  //       commentTyped: this.state.commentTyped
  //         ? this.state.commentTyped + " " + response.url + " "
  //         : response.url + " "
  //     }, () => {
  //       localStorage.setItem(
  //         "commentEditCache",
  //         JSON.stringify({
  //           id: this.props.commentDetails.id,
  //           text: this.state.commentTyped
  //         })
  //       )
  //     });
  //   } else if (response && response.uploadPercentage) {
  //     this.setState({ uploadPercentage: response.uploadPercentage })
  //   } else {
  //     this.setState({
  //       attachmentFrom: "",
  //       openEditAttachmentDropdown: false
  //     });
  //   }
  // };

  //---------New activity design closeAttachmentDropdownOnEdit----------------
  closeAttachmentDropdownOnEdit = (response, attachments) => {
    if (response && response.length > 0 && (response[0].src || response[0].url || response[0].type)) {
      this.setState({
        attachmentFrom: "",
        uploadPercentage: null,
        hasAttachment: true,
        openEditAttachmentDropdown: false,
        attachmentsToEdit: [ ...this.state.attachmentsToEdit, ...response ],
        attachmentsFile: [ ...this.state.attachmentsFile, ...attachments],
        commentTyped: this.state.commentTyped
          ? this.state.commentTyped + " " + response.url + " "
          : response.url + " "
      }, () => {
        // localStorage.setItem(
        //   "commentEditCache",
        //   JSON.stringify({
        //     id: this.props.commentDetails.id,
        //     text: this.state.commentTyped
        //   })
        // )
      });
    } else if (response && response.url) {
      this.setState({
        attachmentFrom: "",
        uploadPercentage: null,
        hasAttachment: true,
        openEditAttachmentDropdown: false,
        attachmentsToEdit: [ ...this.state.attachmentsToEdit, response ],
        attachmentsFile: [ ...this.state.attachmentsFile, response],
        commentTyped: this.state.commentTyped
          ? this.state.commentTyped + " " + response.url + " "
          : response.url + " "
      });
    } else {
      if(!this.state.attachmentOneDrive){
        this.setState({
          attachmentFrom: "",
          openEditAttachmentDropdown: false
        });
      }
      
    }
  };

  onRemoveAttachmentEdit = (attachment) => {
    this.setState({ attachmentsToEdit: this.state.attachmentsToEdit.filter(att => {
      if(att.id && attachment.id)
        return att.id !== attachment.id
      else if(att.src && attachment.src)
        return att.src !== attachment.src
      else if(att.url && attachment.url)
        return att.url !== attachment.url
      else if(att.size && att.name) 
        return (att.size !== attachment.size && att.name !== attachment.name)
      else return true
    }
      ),
      attachmentsFile: !attachment.id ?
        this.state.attachmentsFile.filter(file => {
          if(file.size && file.name) 
            return (file.size !== attachment.size && file.name !== attachment.name)
        else if(attachment.url && file.url)
          return file.url !== attachment.url
        else return true;
      })
      : this.state.attachmentsFile,
      attachmentsToRemove: attachment.id ?
      this.state.attachmentsToRemove.length === 0 ? this.state.attachmentsToRemove.concat(attachment.id) : this.state.attachmentsToRemove.concat(`,${attachment.id}`)
      : this.state.attachmentsToRemove
    }, () => {
      if(this.state.attachmentsToEdit.length > 0) {
        // localStorage.setItem(
        //   "addAttachmentCache",
        //   JSON.stringify({
        //     id: this.props.activityDetails.id,
        //     attachmentsToAdd: JSON.stringify(this.state.attachmentsToAdd)
        //   })
        // )
      } else {
        this.setState({ hasAttachment: false });
        
      }
    });
  }

  //--------Old closeattreply----------------------
  // closeAttachmentDropdownOnReply = (response) => {
  //   if (response && response.url) {
  //     this.setState(
  //       {
  //         attachmentFrom: "",
  //         uploadPercentage: null,
  //         hasAttachment: true,
  //         openReplyAttachmentDropdown: false,
  //         replyCommentTyped: this.state.replyCommentTyped
  //           ? this.state.replyCommentTyped + " " + response.url + " "
  //           : response.url + " "
  //       },
  //       () =>
  //         localStorage.setItem(
  //           "commentReplyCache",
  //           JSON.stringify({
  //             id: this.props.commentDetails.id,
  //             text: this.state.replyCommentTyped
  //           })
  //         )
  //     );
  //   } else if (response && response.uploadPercentage) {
  //     this.setState({ uploadPercentage: response.uploadPercentage })
  //   } else {
  //     this.setState({
  //       attachmentFrom: "",
  //       openReplyAttachmentDropdown: false
  //     });
  //   }
  // };

  //---------New activity close att reply-------------
  closeAttachmentDropdownOnReply = (response, attachments) => {
    if (response && response.length > 0 && (response[0].src || response[0].url || response[0].type)) {
      this.setState(
        {
          attachmentFrom: "",
          uploadPercentage: null,
          hasAttachment: true,
          openReplyAttachmentDropdown: false,
          attachmentsToReply: [ ...this.state.attachmentsToReply, ...response ],
          attachmentsFile: [ ...this.state.attachmentsFile, ...attachments],
          replyCommentTyped: this.state.replyCommentTyped
            ? this.state.replyCommentTyped + " " + response.url + " "
            : response.url + " "
        },
        () => {
          // localStorage.setItem(
          //   "commentReplyCache",
          //   JSON.stringify({
          //     id: this.props.commentDetails.id,
          //     text: this.state.replyCommentTyped
          //   })
          // )
        }
      );
    } else if (response && response.url) {
      this.setState(
        {
          attachmentFrom: "",
          uploadPercentage: null,
          hasAttachment: true,
          openReplyAttachmentDropdown: false,
          attachmentsToReply: [ ...this.state.attachmentsToReply, response ],
          attachmentsFile: [ ...this.state.attachmentsFile, response],
          replyCommentTyped: this.state.replyCommentTyped
            ? this.state.replyCommentTyped + " " + response.url + " "
            : response.url + " "
        }
      );
    } else {
      if(!this.state.attachmentOneDrive){
        this.setState({
          attachmentFrom: "",
          openReplyAttachmentDropdown: false
        });
      }
     
    }
  };

  onRemoveAttachmentReply = (attachment) => {
    this.setState({ attachmentsToReply: this.state.attachmentsToReply.filter(att => {
      if(att.src && attachment.src)
        return att.src !== attachment.src
      else if(att.url && attachment.url)
        return att.url !== attachment.url
      else if(att.size && att.name) 
        return (att.size !== attachment.size && att.name !== attachment.name)
      else return true
    }
      ),
      attachmentsFile: this.state.attachmentsFile.filter(file => {
        if(file.size && file.name) 
          return (file.size !== attachment.size && file.name !== attachment.name)
        else if(attachment.url && file.url)
          return file.url !== attachment.url
        else return true;
      })
    }, () => {
      if(this.state.attachmentsToReply.length > 0) {
        // localStorage.setItem(
        //   "addAttachmentCache",
        //   JSON.stringify({
        //     id: this.props.activityDetails.id,
        //     attachmentsToAdd: JSON.stringify(this.state.attachmentsToAdd)
        //   })
        // )
      } else {
        this.setState({ hasAttachment: false });
        
      }
    });
  }

  handleAttachFrom = (from) => {
    this.setState({
      attachmentFrom: from
    });
  };
  chkMaxSize = (size) => {
    return size > 30000000;
  };

  handleMaxSize = (size, type) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (type === "editComment") {
      if (sizeOfFile) {
        this.setState({
          displayErrMsgEdit: true
        }, () => {
          setTimeout(() => {
            this.setState({ displayErrMsgEdit: false })
          }, 5000)
        });
      } else {
        this.setState({
          displayErrMsgEdit: false
        });
      }
    } else if (type === "replyComment") {
      if (sizeOfFile) {
        this.setState({
          displayErrMsgReply: true
        }, () => {
          setTimeout(() => {
            this.setState({ displayErrMsgReply: false })
          }, 5000)
        });
      } else {
        this.setState({
          displayErrMsgReply: false
        });
      }
    }
  };

  handleMaxSizeReply = (size) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (sizeOfFile) {
      this.setState({
        displayErrMsgReply: true
      });
    } else {
      this.setState({
        displayErrMsgReply: false
      });
    }
  };
  moveCursorAtEnd(e) {
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  closePopup = () => {
    
    this.setState(
      {
        noItem: false
      });
  };

  onShowMoreOptions = () => {
    this.setState({ showMoreOptions: !this.state.showMoreOptions, showArchivePopup: false });
  }

  onOutsideMoreOptions = () => {
    this.setState({ showMoreOptions: false, showArchivePopup: false });
  }

  onShowArchivePopup = () => {
    this.setState({ showArchivePopup: true });
  }

  onHideArchivePopup = () => {
    this.setState({ showArchivePopup: false });
  }

  onLikeDislike = () => {
    const { commentDetails, loggedinUser, updateLikeBookmarkComment } = this.props;
    const loggedinUserId = parseInt(loggedinUser.id);
    const body = {
      activity_type: "COMMENT",
      is_active: true,
      created_at: commentDetails.created_at,
      created_by: commentDetails.created_by,
      data: {
        ...commentDetails.data,
        lastModifiedBy: loggedinUserId
      },
      id: commentDetails.id,
      parent_activity_id: commentDetails.parent_activity_id,
      platform: "WEB",
      title: commentDetails.title,
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      user_id: commentDetails.user_id,
      version_id: commentDetails.version_id,
      performed_by: commentDetails.performed_by,
      only_social_data_updated: true
    };
    if(commentDetails?.social_data?.liked_by_logged_in_user) {
      body.social_data = {
        likes: commentDetails.social_data.likes.filter(userId => userId !== loggedinUserId),
        bookmarked: commentDetails.social_data.bookmarked
      }
    } else if(commentDetails?.social_data?.liked_by_logged_in_user === false) {
      body.social_data = {
        likes: [...commentDetails.social_data.likes, loggedinUserId],
        bookmarked: commentDetails.social_data.bookmarked
      }
    } else if(commentDetails?.social_data?.bookmarked) {
      body.social_data = {
        likes: [loggedinUserId],
        bookmarked: commentDetails.social_data.bookmarked
      }
    } else {
      body.social_data = {
        likes: [loggedinUserId]
      }
    }
    updateLikeBookmarkComment(body);
  }

  onBookmarkUnBookmark = () => {
    const { commentDetails, loggedinUser, updateLikeBookmarkComment } = this.props;
    const loggedinUserId = parseInt(loggedinUser.id);
    const body = {
      activity_type: "COMMENT",
      is_active: true,
      created_at: commentDetails.created_at,
      created_by: commentDetails.created_by,
      data: {
        ...commentDetails.data,
        lastModifiedBy: loggedinUserId
      },
      id: commentDetails.id,
      parent_activity_id: commentDetails.parent_activity_id,
      platform: "WEB",
      title: commentDetails.title,
      updated_on: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      user_id: commentDetails.user_id,
      version_id: commentDetails.version_id,
      performed_by: commentDetails.performed_by,
      only_social_data_updated: true
    };
    if(commentDetails?.social_data?.bookmarked_by_logged_in_user) {
      body.social_data = {
        likes: commentDetails.social_data.likes,
        bookmarked: commentDetails.social_data.bookmarked.filter(userId => userId !== loggedinUserId)
      }
    } else if(commentDetails?.social_data?.bookmarked_by_logged_in_user === false) {
      body.social_data = {
        likes: commentDetails.social_data.likes,
        bookmarked: [...commentDetails.social_data.bookmarked, loggedinUserId]
      }
    } else if(commentDetails?.social_data?.likes) {
      body.social_data = {
        likes: commentDetails.social_data.likes,
        bookmarked: [loggedinUserId]
      }
    } else {
      body.social_data = {
        bookmarked: [loggedinUserId]
      }
    }
    updateLikeBookmarkComment(body);
  }

  toggleBlockTypeReplay = (blockType) => {
    this.onChangeReplay(
      RichUtils.toggleBlockType(
        this.state.replyCommentTypedEditorState,
        blockType
      )
    );
  }



  toggleInlineStyleReplay = (inlineStyle) => {
    let editorstateToggle=RichUtils.toggleInlineStyle(this.state.replyCommentTypedEditorState,inlineStyle);
    let currentStyle=editorstateToggle.getCurrentInlineStyle();
    let inlineStyledata=[];

    if(currentStyle.has('BOLD')){
      inlineStyledata.push('BOLD');
    }
    if(currentStyle.has('UNDERLINE')){
      inlineStyledata.push('UNDERLINE');
    }
    if(currentStyle.has('ITALIC')){
      inlineStyledata.push('ITALIC');
    }
    this.updateInlineState(inlineStyledata);
    this.onChangeReplay(editorstateToggle);
  }

  toggleInlineStyleEdit = (inlineStyle) => {
    let editorstateToggle=RichUtils.toggleInlineStyle(this.state.commentTypedEditorState,inlineStyle)
    let currentStyle=editorstateToggle.getCurrentInlineStyle();
    let inlineStyledata=[];

    if(currentStyle.has('BOLD')){
      inlineStyledata.push('BOLD');
    }
    if(currentStyle.has('UNDERLINE')){
      inlineStyledata.push('UNDERLINE');
    }
    if(currentStyle.has('ITALIC')){
      inlineStyledata.push('ITALIC');
    }

    this.updateInlineState(inlineStyledata);
    this.onChangeEdit(editorstateToggle);
  }

  toggleBlockTypeEdit = (blockType) => {
    this.onChangeEdit(
      RichUtils.toggleBlockType(
        this.state.commentTypedEditorState,
        blockType
      )
    );
  }

  updateInlineState=(inlineStyle)=>{
    this.setState({inlineStyleData:inlineStyle});
  }



  onChangeReplay = (editorState) => this.setState({ replyCommentTypedEditorState: editorState });

  onChangeEdit = (editorState) => this.setState({ commentTypedEditorState: editorState });


  updateEditorStateReplay = (editorState, updateData, callback) => {
    this.setState({ replyCommentTypedEditorState: editorState }, () => {
      let contentRaw = convertToRaw(this.state.replyCommentTypedEditorState.getCurrentContent());

      if (!contentRaw.blocks || contentRaw.blocks.length === 0 || !contentRaw.blocks[0]['text'] || contentRaw.blocks[0]['text'].trim().length === 0) {
        if(localStorage.getItem("commentReplyCache") &&
        JSON.parse(localStorage.getItem("commentReplyCache")).id ===
        this.props.commentDetails.id) {
          localStorage.removeItem('commentReplyCache');
          localStorage.removeItem("openedReplyBoxId");
        }

      } else {
        localStorage.setItem(
          "commentReplyCache",
          JSON.stringify({
            id: this.props.commentDetails.id,
            text: JSON.stringify(contentRaw)
          })
        );
        localStorage.setItem("openedReplyBoxId", this.props.commentDetails.id);

      }

      updateData();
      if (callback) {
        callback();
      }

    });
  }

  updateEditorStateEditting = (editorState, updateData, callback) => {
    this.setState({ commentTypedEditorState: editorState }, () => {
      let contentRaw = convertToRaw(this.state.commentTypedEditorState.getCurrentContent());

      if (!contentRaw.blocks || contentRaw.blocks.length === 0 || !contentRaw.blocks[0]['text'] || contentRaw.blocks[0]['text'].trim().length === 0) {
        if(localStorage.getItem("commentEditCache") &&
        JSON.parse(localStorage.getItem("commentEditCache")).id ===
        this.props.commentDetails.id) {
          localStorage.removeItem('commentEditCache');
          localStorage.removeItem('openedEditBoxId');
        }
      } else {
        localStorage.setItem(
          "commentEditCache",
          JSON.stringify({
            id: this.props.commentDetails.id,
            text: JSON.stringify(contentRaw)
          })
        );
        localStorage.setItem("openedEditBoxId", this.props.commentDetails.id);
      }

      updateData();
      if (callback) {
        callback();
      }

    });
  }

  addMentionEdit = () => {
    const currentContent = this.state.commentTypedEditorState.getCurrentContent(),
      currentSelection = this.state.commentTypedEditorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      '@',
      OrderedSet.of(...this.state.inlineStyleData)
    );

    const newEditorState = EditorState.push(this.state.commentTypedEditorState, newContent, 'insert-fragment');
    return this.setState({ commentTypedEditorState: newEditorState });
  }

  addMentionReplay = () => {
    const currentContent = this.state.replyCommentTypedEditorState.getCurrentContent(),
      currentSelection = this.state.replyCommentTypedEditorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      '@',
      OrderedSet.of(...this.state.inlineStyleData)
    );

    const newEditorState = EditorState.push(this.state.replyCommentTypedEditorState, newContent, 'insert-fragment');
    return this.setState({ replyCommentTypedEditorState: newEditorState });
  }

  isImage = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    if(url.indexOf(";base64") > -1) {
      return url.indexOf("image") > -1;
    } else if (
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif"
    ) {
      return true;
    } else {
      return false;
    }
  };

  isImageDisplay = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    return !!(ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif");
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  updateAttchmetnOneDrive=(flag)=>{
    this.setState({attachmentOneDrive:flag})
  }

  onClickImg = (url) => {
    this.setState({ clickedImg: url });
    document
      .querySelector('[role="dialog"]')
      .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    document
      .querySelector('[role="dialog"]')
      .removeAttribute("id", "noscrolldialog");
  };

  render() {
    const comment = this.props.commentDetails;
    const {
      // popupId,
      user,
      // commentTyped,
      // replyCommentTyped,
      // attachmentFrom,
      showcustomtoolbar,
      clickedImg
    } = this.state;
    let {
      changeOpenedEditBox,
      isSuperAdmin,
      openedEditBox,
      editCommentLoaderState,
      // closeOpenedEditBox,
      openedReplyBox,
      closeOpenedReplyBox,
      replyCommentLoaderState,
      userDetails,
      activityDetails,
      // updateLoaderState,
      // uploadAttachmentLoaderState,
      loginUserId,
      clickedCommentId,
      is_priority,
      is_archived
    } = this.props;
    const isUserPartOfCard = activityDetails && activityDetails.user_id.includes(
      parseInt(localStorage.getItem("id"))
    );
   
    const dataForMentions = [
      {
        id: 0,
        display: "act",
        image: "",
        username: "Act"
      },
      ..._.sortBy(
        (activityDetails.is_locked ? userDetails.filter(user => activityDetails.user_id.includes(user.id)) : userDetails)
        .map((user) => {
          return {
            id: user.id,
            display: user.username,
            image: user.img || "",
            username: user.first_name
          };
        }),
        (user) => user.username.toLowerCase()
      )
    ];
    return (
      <div
        className={
          "ap-comments-item-container " +
          (comment.data.commentDetails.isReply ? "reply-comment" : "")
        }
        id={`ap-comments-item-container-${comment.id}`}
      // onClick={() => console.log("comment details", is_priority)}
      >
        <div className="ap-comments-item-holder">
          {user ?
          <div className="ap-user-profile-holder">
            {user && !user.img && (
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={user?.first_name}
                maxInitials={1}
              ></Avatar>
            )}
            {user && user.img && 
              <img src={user?.img} alt={user?.first_name} />}
          </div>
          :
          <Tooltip title="Currently not a member of this workspace">
          <div className="ap-user-profile-holder not-wp-user">
            {comment?.created_by_user && !comment.created_by_user.img && (
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={comment?.created_by_user?.first_name}
                maxInitials={1}
              ></Avatar>
            )}
            {comment?.created_by_user && comment.created_by_user.img && 
              <img src={comment.created_by_user.img} alt={user?.first_name} />}
          </div>
          </Tooltip>}
          {openedEditBox !== comment.id && (
            <div className="ap-comments-item-right-holder">
              <div className="ap-user-details-holder">
                <div className="ap-user-name">
                  {(user && user.first_name) || (comment?.created_by_user && comment.created_by_user?.first_name)}
                  <img
                    src={dotSeparator}
                    alt="Smiley face"
                    height="2"
                    width="2"
                  />
                  <span className="ap-comment-updatedon">
                    {commonDateFormat(comment.created_at)}
                    {this.isEdited(comment.created_at, comment.updated_on) &&
                      <Tooltip
                        title={commonDateFormat(comment.updated_on)}
                        placement="top"
                        ><span> (edited)</span></Tooltip>}
                  </span>
                </div>
                <div className={`bookmark-option-holder ${this.state.showMoreOptions ? "hovered" : ""}`}>
                {(isSuperAdmin || isUserPartOfCard || comment.id === clickedCommentId || comment.created_by === parseInt(loginUserId)) &&
                <Tooltip
                  title={comment?.social_data?.bookmarked_by_logged_in_user ? "Remove from bookmarked" : "Bookmark this comment"}
                >
                  <img
                    className={`bookmark-img ${comment?.social_data?.bookmarked_by_logged_in_user ? "bookmarked-img" : ""}`}
                    onClick={this.onBookmarkUnBookmark}
                    src={comment?.social_data?.bookmarked_by_logged_in_user ? bookmarkedIcon : bookmarkIcon}
                    alt="bookmark-icon"
                  />
                </Tooltip>}
                {comment.created_by === parseInt(loginUserId) && 
                <OutsideClickHandler onOutsideClick={this.onOutsideMoreOptions}>
                  <div className={`option-holder ${this.state.showMoreOptions ? "hovered" : ""}`} onClick={this.onShowMoreOptions}>
                    <img src={dotIcon} alt="menu-option" />
                  </div>
                  {this.state.showMoreOptions &&
                    <div className="flow-options">
                    <div className="options">
                      <p
                        className="kanban-link"
                        onClick={() => {
                          changeOpenedEditBox(comment.id);
                          this.onOutsideMoreOptions();
                          if (this.props.commentDetails.title) {
                            let htmlData='';
                            if(this.props.commentDetails.title.startsWith("<p>")
                               || this.props.commentDetails.title.startsWith("<ul>")
                               || this.props.commentDetails.title.startsWith("<ol>")){
                                htmlData=this.props.commentDetails.title;
                               }else{
                                 
                                htmlData=parseCommentEditor(this.props.commentDetails.title);
                               }
                               const blocksFromHTML = convertFromHTML(htmlData);
                                const state = ContentState.createFromBlockArray(
                                  blocksFromHTML.contentBlocks,
                                  blocksFromHTML.entityMap,
                                );
                                let editorStateData = EditorState.moveFocusToEnd(EditorState.createWithContent(state));
                                this.setState({ commentTypedEditorState: editorStateData });
                            
                          }
                          this.setState({ attachmentsToEdit: this.props.commentDetails?.attachments?.length > 0 ? this.props.commentDetails.attachments : [] })
                        }}
                      >
                        {" "}
                        Edit
                      </p>
                      <p
                        className="kanban-link"
                        onClick={this.onShowArchivePopup}
                      >
                        Archive
                      </p>
                      {this.state.showArchivePopup && (
                        <div className="deleteflow-box">
                          <p>Archive this comment?</p>
                          <div className="button">
                            <span
                              className="cancel"
                              onClick={this.onHideArchivePopup}
                            >
                              Cancel
                            </span>
                            <button
                              className="save"
                              onClick={() => this.deleteComment(comment)}
                            >
                              Archive
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  }
                </OutsideClickHandler>}
                </div>
              </div>
              <div className="ap-comment-desc-holder">
                <div
                  className="ap-comment-desc"
                  id={`ap-comment-desc${comment.id}`}
                >
                  {/* <ReactMarkdown
                    source={parseComment(this.props.commentDetails.title)}
                    escapeHtml={false}
                  /> */}
                  {/* <Editor editorState={this.state.editorState} readOnly /> */}

                  {ReactHtmlParser(parseComment(this.props.commentDetails.title))}
                </div>
                <div className="comm-att-holder">
                  {comment?.attachments?.length > 0 && 
                    <React.Fragment>
                      {comment.attachments.map((attachment, index) => {
                        return <div
                        className="nw-att-row-container comm-att"
                        key={index}
                        id={`nw-att-row-container-${attachment.id}`}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImageDisplay(attachment.url)
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {this.isImageDisplay(attachment.url) && (
                              <img
                                src={decodeURI(attachment.url)}
                                alt="attachment"
                                onClick={() => this.onClickImg(decodeURI(attachment.url))}
                              />
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") < 0 &&
                              attachment.url.indexOf("www.dropbox.com") < 0 &&
                              attachment.url.indexOf("https://1drv.ms/") < 0 && (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.url.substr(
                                      attachment.url.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
              
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={this.isImageDisplay(attachment.url) ? { cursor: "pointer" } : {}}
                                onClick={() => this.isImageDisplay(attachment.url) && this.onClickImg(decodeURI(attachment.url))}
                              >
                                {attachment.title}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              href={decodeURI(attachment.url)}
                              download={attachment.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </div>
                          {/* <div className="nw-att-link-container"></div> */}
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
              </div>
              <div className="ap-comment-actions-container">
                <Tooltip
                  title={(isSuperAdmin || isUserPartOfCard || comment.id === clickedCommentId || comment.created_by === parseInt(loginUserId))
                    ? comment?.social_data?.liked_by_logged_in_user ? "Dislike" : "Like"
                    : ""
                    }>
                  <img
                    className={(isSuperAdmin || isUserPartOfCard || comment.id === clickedCommentId || comment.created_by === parseInt(loginUserId))
                    ? "have-access"
                    : "no-access"}
                    onClick={(isSuperAdmin || isUserPartOfCard || comment.id === clickedCommentId || comment.created_by === parseInt(loginUserId)) &&
                      this.onLikeDislike}
                    src={comment?.social_data?.liked_by_logged_in_user ? likedIcon : likeIcon}
                    alt="like-icon"
                  />
                </Tooltip>
                <span>{comment?.social_data?.likes_count}</span>
                {(isSuperAdmin || isUserPartOfCard || comment.created_by === parseInt(loginUserId)) && <button
                  className="ap-comment-action-btn"
                  onClick={() => this.openReplyBox(comment)}
                >
                  Reply
                </button>}
                {!isSuperAdmin && !isUserPartOfCard && comment.id === clickedCommentId && <button
                  className="ap-comment-action-btn"
                  onClick={() => this.openReplyBox(comment)}
                >
                  Reply
                </button>}
                {
                this.state.attachmentsErr && 
                (
                  <div class="new-comm-attacherror">
                    Please add the attachments again{" "}
                  </div>
                )}

              </div>
            </div>
          )}
          {openedEditBox === comment.id && (
            <div className="comment-edit-box-container">
              <div
                className={
                  "add-comment-box-holder " +
                  (comment.data.commentDetails.isReply ? "reply" : "")
                }
              >
                {/* <MentionsInput
                  value={commentTyped}
                  className="comment-text-area"
                  onChange={(event, newValue, newPlainTextValue, mentions) =>
                    this.handleCommentOnEdit(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions
                    )
                  }
                  autoFocus={true}
                  onFocus={this.moveCursorAtEnd}
                >
                  <Mention
                    type="user"
                    trigger="@"
                    markup="@{{__display__}}"
                    data={dataForMentions}
                    renderSuggestion={this.renderSuggestion}
                    displayTransform={(id, display) => `@${display}`}
                    appendSpaceOnAdd={true}
                  ></Mention>
                </MentionsInput> */}
                <CommentsEditor
                  updateEditorState={this.updateEditorStateEditting}
                  editorState={this.state.commentTypedEditorState}
                  propsCheck={false}
                  dataForMentions={dataForMentions}
                  disabled={editCommentLoaderState.fetching}
                  withAttachment={this.state.attachmentsToEdit.length}
                  updateInlineState={this.updateInlineState}
                >

                </CommentsEditor>
                <div className="comm-att-holder add-edit">
                  {this.state.attachmentsToEdit.length > 0 && 
                    <React.Fragment>
                      {this.state.attachmentsToEdit.map((attachment, index) => {
                        const attachmentURL = (attachment.src || attachment.url || attachment.type);
                        const isImage = attachment.src ? true : false;
                        return <div
                        className="nw-att-row-container"
                        key={index}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImage(attachmentURL) || isImage
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {(this.isImage(attachmentURL) || isImage) && (
                              <img
                                style={editCommentLoaderState.fetching ? { cursor: "auto" } : {}}
                                src={decodeURI(attachmentURL)}
                                alt="attachment"
                                onClick={() => !editCommentLoaderState.fetching && this.onClickImg(decodeURI(attachmentURL))}
                              />
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf(".google.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf(".google.com") < 0 &&
                              attachmentURL.indexOf("www.dropbox.com") < 0 &&
                              attachmentURL.indexOf("https://1drv.ms/") < 0 && (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.title && attachment.title.substr(
                                      attachment.title.lastIndexOf(".") + 1
                                    )}
                                    {attachment.name && attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={(this.isImage(attachmentURL) || isImage) && !editCommentLoaderState.fetching ? { cursor: "pointer" } : {}}
                                onClick={() => (this.isImage(attachmentURL) || isImage) && !editCommentLoaderState.fetching && this.onClickImg(decodeURI(attachmentURL))}
                              >
                                {attachment.title || attachment.name}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              style={editCommentLoaderState.fetching ? { cursor: "auto" } : {}}
                              className="nw-att-link download comm-att"
                              // href={decodeURI(attachment.url)}
                              // download={attachment.title}
                              // target="_blank"
                              onClick={() => !editCommentLoaderState.fetching && this.onRemoveAttachmentEdit(attachment)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
                <div className="comment-bottom-btn-holder">
                  <div className="comment-bottom-left-holder">
                    <button
                      className={
                        "add-comment-btn " +
                        (!(this.state.commentTypedEditorState.getCurrentContent().hasText() ||
                          this.state.attachmentsToEdit.length > 0) ||
                          editCommentLoaderState.fetching
                          ? "disable"
                          : "")
                      }
                      onClick={this.editComment}
                    >
                      Update
                    </button>
                    {!editCommentLoaderState.fetching && <div
                      className="comment-edit-cancel-btn"
                      onClick={this.closeEditBox}
                    ></div>}
                    {(editCommentLoaderState.fetching &&
                      !editCommentLoaderState.error) && (
                        <div className="loading-div">
                          <CircularProgress
                            classes={{ root: "circular-progress" }}
                            size={20}
                            style={{ marginLeft: "10px" }}
                          ></CircularProgress>
                          <span className="upload-percentage"></span>
                        </div>
                      )}
                    {this.state.uploadPercentage && <div className="loading-div">
                      <CircularProgress
                        classes={{ root: "circular-progress" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                        variant="static"
                        value={this.state.uploadPercentage}
                      ></CircularProgress>
                      <span className="upload-percentage">Adding attachment - {this.state.uploadPercentage}%</span>
                      <div
                        className="comment-edit-cancel-btn"
                        style={{ marginTop: "4px" }}
                        onClick={() => {
                          abortUpload();
                          this.setState({ uploadPercentage: null })
                        }}
                      ></div>
                    </div>}
                    {this.state.displayErrMsgEdit && (
                      <div class="new-comm-attacherror">
                        The file size exceeds 30 MB{" "}
                      </div>
                    )}
                  </div>
                  <div className="comment-right-sction">
                    <div className="comment-rict-text">
                      {showcustomtoolbar &&
                        (<div className="customtoolbar">
                          <InlineStyleControls
                            editorState={this.state.commentTypedEditorState}
                            onToggle={this.toggleInlineStyleEdit}
                          />

                          <div className="blocklevel">
                            <BlockStyleControls
                              editorState={this.state.commentTypedEditorState}
                              onToggle={this.toggleBlockTypeEdit}
                            />
                          </div>

                        </div>)}


                      <Tooltip title={showcustomtoolbar ? "Hide formatting" : "Show formatting"}>
                        <div className="rickBlock" onClick={_event => {
                          this.setState({ showcustomtoolbar: !this.state.showcustomtoolbar })
                        }} >
                          <img src={richblock} alt="OL" />
                        </div>
                      </Tooltip>

                      <Tooltip title="Mention">
                        <div className="mentionsymbol" onClick={this.addMentionEdit}>
                          <img src={mentionSymbol} alt="@" />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="comment-attachment-area edit">
                      <OutsideClickHandler
                        onOutsideClick={this.closeAttachmentDropdownOnEdit}
                      >
                        <Tooltip title="Attach file">
                          <div
                            className="comment-attachment-img-holder"
                            onClick={() => this.openAttachmentDropdown("edit")}
                          ></div>
                        </Tooltip>
                        {this.state.openEditAttachmentDropdown && (
                          <AttachmentDropdown
                            closeAttachmentDropdown={
                              this.closeAttachmentDropdownOnEdit
                            }
                            activityDetails={activityDetails}
                            handleMaxSize={this.handleMaxSize}
                            handleAttachFrom={this.handleAttachFrom}
                            attachedFrom={"editComment"}
                            is_priority={is_priority}
                            updateFetch={this.props.updateFetch}
                            is_archived={is_archived}
                            updateAttchmetnOneDrive={this.updateAttchmetnOneDrive}
                          />
                        )}
                      </OutsideClickHandler>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {openedReplyBox === comment.id && (
            <div className="comment-edit-box-container on-reply">
              <div
                className={
                  "add-comment-box-holder " +
                  (comment.data.commentDetails.isReply ? "reply-to-reply" : "")
                }
              >
                {/* <textarea name="comment" id="edit-comment-box" class="comment-text-area" 
                            value={ replyCommentTyped } onChange={(e) => this.handleReplyOnChange(e)} autoFocus={true}></textarea> */}
                {/* <MentionsInput
                  value={replyCommentTyped}
                  className="comment-text-area"
                  onChange={(event, newValue, newPlainTextValue, mentions) =>
                    this.handleReplyOnChange(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions
                    )
                  }
                  autoFocus={true}
                  onFocus={this.moveCursorAtEnd}
                >
                  <Mention
                    type="user"
                    trigger="@"
                    markup="@{{__display__}}"
                    data={dataForMentions}
                    renderSuggestion={this.renderSuggestion}
                    displayTransform={(id, display) => `@${display}`}
                    appendSpaceOnAdd={true}
                  ></Mention>
                </MentionsInput> */}
                <CommentsEditor
                  updateEditorState={this.updateEditorStateReplay}
                  editorState={this.state.replyCommentTypedEditorState}
                  propsCheck={false}
                  dataForMentions={dataForMentions}
                  disabled={replyCommentLoaderState.fetching}
                  withAttachment={this.state.attachmentsToReply.length}
                  updateInlineState={this.updateInlineState}
                />
                <div className="comm-att-holder add-edit">
                  {this.state.attachmentsToReply.length > 0 && 
                    <React.Fragment>
                      {this.state.attachmentsToReply.map((attachment, index) => {
                        const attachmentURL = (attachment.src || attachment.url);
                        const isImage = attachment.src ? true : false;
                        const isCloud = attachment.url ? true : false;
                        const isDocument = attachment.type ? true : false;
                        return <div
                        className="nw-att-row-container"
                        key={index}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              isImage
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {isImage && (
                              <img
                                style={replyCommentLoaderState.fetching ? { cursor: "auto" } : {}}
                                src={decodeURI(attachmentURL)}
                                alt="attachment"
                                onClick={() => !replyCommentLoaderState.fetching && this.onClickImg(decodeURI(attachmentURL))}
                              />
                            )}
                            {isCloud &&
                              attachmentURL.indexOf(".google.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {isCloud &&
                              attachmentURL.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {isCloud &&
                              attachmentURL.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {isDocument &&
                              // attachmentURL.indexOf(".google.com") < 0 &&
                              // attachmentURL.indexOf("www.dropbox.com") < 0 &&
                              // attachmentURL.indexOf("https://1drv.ms/") < 0 && 
                              (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={isImage && !replyCommentLoaderState.fetching ? { cursor: "pointer" } : {}}
                                onClick={() => isImage && !replyCommentLoaderState.fetching && this.onClickImg(decodeURI(attachmentURL))}
                              >
                                {attachment.title || attachment.name}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              style={replyCommentLoaderState.fetching ? { cursor: "auto" } : {}}
                              className="nw-att-link download comm-att"
                              // href={decodeURI(attachment.url)}
                              // download={attachment.title}
                              // target="_blank"
                              onClick={() => !replyCommentLoaderState.fetching && this.onRemoveAttachmentReply(attachment)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
                <div className="comment-bottom-btn-holder">
                  <div className="comment-bottom-left-holder">
                    <button
                      className={
                        "add-comment-btn " +
                        (!(this.state.replyCommentTypedEditorState.getCurrentContent().hasText() ||
                        this.state.attachmentsToReply.length > 0) ||
                          replyCommentLoaderState.fetching
                          ? "disable"
                          : "")
                      }
                      onClick={this.replyToComment}
                    >
                      Add
                    </button>

                    {!replyCommentLoaderState.fetching && <div
                      className="comment-edit-cancel-btn"
                      onClick={(event) => {
                        closeOpenedReplyBox(event)
                        this.setState({ replyCommentTypedEditorState: EditorState.createEmpty(), attachmentsToReply: [], attachmentsFile: [] }, () => {
                          localStorage.removeItem('replyCommentTypedEditorState');
                        })
                      }}
                    ></div>}
                    {(replyCommentLoaderState.fetching &&
                      !replyCommentLoaderState.error) && (
                        <div className="loading-div">
                          <CircularProgress
                            classes={{ root: "circular-progress" }}
                            size={20}
                            style={{ marginLeft: "10px" }}
                          ></CircularProgress>
                          <span className="upload-percentage"></span>
                        </div>
                      )}
                    {this.state.uploadPercentage && <div className="loading-div">
                      <CircularProgress
                        classes={{ root: "circular-progress" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                        variant="static"
                        value={this.state.uploadPercentage}
                      ></CircularProgress>
                      <span className="upload-percentage">Adding attachment - {this.state.uploadPercentage}%</span>
                      <div
                        className="comment-edit-cancel-btn"
                        style={{ marginTop: "4px" }}
                        onClick={() => {
                          abortUpload();
                          this.setState({ uploadPercentage: null })
                        }}
                      ></div>
                    </div>}
                    {this.state.displayErrMsgReply && (
                      <div class="new-comm-attacherror">
                        The file size exceeds 30 MB{" "}
                      </div>
                    )}
                  </div>
                  <div className="comment-right-sction">
                    <div className="comment-rict-text">
                      {showcustomtoolbar &&
                        (
                          <div className="customtoolbar">
                            <InlineStyleControls
                              editorState={this.state.replyCommentTypedEditorState}
                              onToggle={this.toggleInlineStyleReplay}
                            />

                            <BlockStyleControls
                              editorState={this.state.replyCommentTypedEditorState}
                              onToggle={this.toggleBlockTypeReplay}
                            />
                          </div>)}

                      <Tooltip title={showcustomtoolbar ? "Hide formatting" : "Show formatting"}>
                        <div className="rickBlock" onClick={_event => {
                          this.setState({ showcustomtoolbar: !this.state.showcustomtoolbar })
                        }} >
                          <img src={richblock} alt="OL" />
                        </div>
                      </Tooltip>

                      <Tooltip title="Mention">
                        <div className="mentionsymbol" onClick={this.addMentionReplay}>
                          <img src={mentionSymbol} alt="@" />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="comment-attachment-area edit">
                      <OutsideClickHandler
                        onOutsideClick={this.closeAttachmentDropdownOnReply}
                      >
                        <Tooltip title="Attach file">
                          <div
                            className="comment-attachment-img-holder"
                            onClick={() => this.openAttachmentDropdown("reply")}
                          ></div>
                        </Tooltip>
                        {this.state.openReplyAttachmentDropdown && (
                          <AttachmentDropdown
                            closeAttachmentDropdown={
                              this.closeAttachmentDropdownOnReply
                            }
                            activityDetails={activityDetails}
                            handleMaxSize={this.handleMaxSize}
                            handleAttachFrom={this.handleAttachFrom}
                            attachedFrom={"replyComment"}
                            is_priority={is_priority}
                            updateFetch={this.props.updateFetch}
                            is_archived={is_archived}
                            updateAttchmetnOneDrive={this.updateAttchmetnOneDrive}
                          />
                        )}
                      </OutsideClickHandler>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {clickedImg && (
            <div className="attachmentpopviews">
              <img src={clickedImg} alt="attachment" className="attachmentimage" />
              <img
                className="closeattachmentpopview"
                src={closeIcon}
                alt="close-icon"
                onClick={this.onClosePreview}
              />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editCommentLoaderState: state.commentsReducer.editCommentLoaderState,
    replyCommentLoaderState: state.commentsReducer.replyCommentLoaderState,
    updateLoaderState: state.attachmentsReducer.updateLoaderState,
    loginUserId: state.loginReducer.loginAuth.user_details.id,
    uploadAttachmentLoaderState:
      state.attachmentsReducer.uploadAttachmentLoaderState
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    deleteComment(
      {
        activity_type,
        user_id,
        created_by,
        created_at,
        title,
        id,
        data,
        is_active,
        version_id,
        parent_activity_id,
        updated_on,
        is_priority,
        is_Activity_archived
      },
      callback
    ) {
      dispatch(
        deleteCommentAction(
          {
            activity_type,
            user_id,
            created_by,
            created_at,
            title,
            id,
            data,
            is_active,
            version_id,
            parent_activity_id,
            updated_on,
            is_priority,
            is_Activity_archived
          },
          callback
        )
      );
    },
    updateCommentsCountAfterDeleteAction({
      is_Activity_archived,
      parent_activity_id,
      is_priority,
      count
    }) {
      dispatch(
        updateCommentsCountAfterDelete({
          is_Activity_archived,
          parent_activity_id,
          is_priority,
          count
        })
      );
    },
    editComment(
      {
        activity_type,
        is_active,
        created_at,
        created_by,
        data,
        id,
        parent_activity_id,
        platform,
        title,
        updated_on,
        user_id,
        version,
        version_id,
        attachments
      },
      formData,
      callback
    ) {
      dispatch(
        editCommentAction(
          {
            activity_type,
            is_active,
            created_at,
            created_by,
            data,
            id,
            parent_activity_id,
            platform,
            title,
            updated_on,
            user_id,
            version,
            version_id,
            attachments
          },
          formData,
          callback
        )
      );
    },
    updateLikeBookmarkComment(
      body,
      callback
    ) {
      dispatch(
        updateLikeBookmarkCommentAction(
          body,
          callback
        )
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchProps)(CommentItem);
