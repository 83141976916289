export const message = {
  emptyField: "This field cannot be empty",
  shortPassword: "Password cannot be less than 6 characters",
  passwordMissMatch: "Incorrect email or password",
  incorrectPassword: "Incorrect password",
  wrongConfirmPassword: "Password doesn't match",
  emailNotValid: "Enter a valid email address",
  filedShouldNotBeEmpty: "This field cannot be empty",
  enterValidName: "Enter valid name",
  enterValidFullName: "Enter a valid full name",
  enterYourName: "Enter your name",
  emailAlreadyExist: "This email is already registered.",
  enterValidTeamName: "Enter valid team name",
  signedInWithGoogle: "Your account is signed in with google",
  signedInWithApple: "Your account is signed in with apple",
  emailNotRegistered: "Email not registered",
  passwordNotMatch: "Password doesn't match",
  invitedEmailNotMatch: "This email doesn't match with the invited email",
  emailNotVerified: "This email is not verified."
};
