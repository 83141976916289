import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";

export function getChecklist({ queryParams }, callback) {
  let userid=parseInt(localStorage.getItem("id"));
  const queryparams = queryParams ? queryParams : "";
  secureFetch(API_URL + `/api/v1/checklists?${queryparams}&logged_in_user=${userid}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function createChecklist(
  { activity_id, title, created_by, is_archived },
  callback
) {
  secureFetch(API_URL + "/api/v1/checklists", {
    method: "POST",
    body: JSON.stringify({
      title: title,
      activity_id: activity_id,
      created_by: created_by,
      is_archived: is_archived,
      is_open: true
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}

export function updateChecklist(
  { activity_id, title, created_by, is_archived, position, id, is_open, is_show },
  callback
) {
  //console.log('data in api', is_archived);
  let reqBody = { activity_id, title, created_by, is_archived, position, id, is_open, is_show };
  if(!reqBody.created_by) delete reqBody.created_by;
  secureFetch(API_URL + "/api/v1/checklists", {
    method: "PUT",
    body: JSON.stringify({
      ...reqBody
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}

// For Creating checklist Item

export function createChecklistItem(
  { checklist_id, created_by, is_archived, is_completed, required, title },
  callback
) {
  secureFetch(API_URL + "/api/v1/checklist-items", {
    method: "POST",
    body: JSON.stringify({
      title: title,
      checklist_id: checklist_id,
      created_by: created_by,
      is_archived: is_archived,
      is_completed: is_completed,
      required: required
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}

export function updateChecklistItem(
  {
    checklist_id,
    completed_by,
    created_at,
    created_by,
    id,
    is_archived,
    is_completed,
    position,
    required,
    title,
    social_data,
    data
  },
  callback
) {
  let userid=parseInt(localStorage.getItem("id"));
  secureFetch(API_URL + "/api/v1/checklist-items?logged_in_user=" + userid, {
    method: "PUT",
    body: JSON.stringify({
      checklist_id,
      completed_by,
      // created_at,
      created_by,
      id,
      is_archived,
      is_completed,
      position,
      required,
      title,
      social_data,
      data
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}

export function copyChecklist(
  { copy_object, object_id },
  callback
) {
  secureFetch(API_URL + "/api/v1/clipboard-copy", {
    method: "POST",
    body: JSON.stringify({
      copy_object,
      object_id
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}

export function pasteChecklist(
  { activity_id },
  callback
) {
  secureFetch(API_URL + "/api/v1/clipboard-paste", {
    method: "POST",
    body: JSON.stringify({
      activity_id
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      //console.log(respJson);
      callback(respJson);
    })
    .catch((error) => {
      //
    });
}