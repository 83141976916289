import React, { useState, useContext, useRef, Fragment } from "react";
import activityDots from "../../styles/img/svg/activity_dots.svg";
import activityDuration from "../../styles/img/svg/duration_act.svg";
import { activities } from "../../constants/activitiescontants";
import DialogWrapper from "../common/dialog";
import ActivityForm from "../activity/addactivity";
import ActivityPopupTop from "../activity/activityPopupTop";
import ActivityPopupBottom from "../activity/activityPopupBottom";
import KanbanCardStatus from "./kanabnCardStatus";
import calendarFlow from "../../styles/img/svg/calendar-flow.svg";
import { secondsToHms } from "../../helpers/generichelper";
import KanbanContext from "../../contexts/kanban/kabanContext";
import { DragTypes } from "../../constants/DragTypes";
import { useDrag, useDrop } from "react-dnd";
import Avatar from "react-avatar";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { kanbanConstant } from "../../constants/kanbanconstants";
momentDurationFormatSetup(moment);

const KanbanActivityCard = ({
  activitiyList,
  activity,
  index,
  workflow,
  workflowList,
  setWorkflowList,
  moveCard
}) => {
  const ref = useRef(null);
  const activityHours = secondsToHms(activity.duration).hours.toString() + "h";
  const activityMins = secondsToHms(activity.duration).mins.toString() + "m";
  const {
    // userMap,
    selectedProject,
    updateProjectAction,
    updateActivityWithWorkflowAction,
    getUserDetailsAction,
    changeWorkFlowAction,
    loggedinUser,
    getActivitiesWithProjectId,
    archiveOrUnArchiveActivitiesAction,
    prioritizeDePrioritizeFromKanBanAction,
    getSelectedWorkpsaceArchivedActivities,
    prioritizedActivity
  } = useContext(KanbanContext);
  let { userMap } = useContext(KanbanContext);
  if(activity?.users_not_in_workspace) {
    activity.users_not_in_workspace.forEach(user => {
      userMap[user.id] = user;
    })
  }
  const [optionsDropdown, setOptionsDropdown] = useState(null);
  let draggedActivity = {};
  const moveActivity = (_targetArray, _dragIndex, hoverIndex, draggedItem,workflowListCopy) => {
    draggedActivity = draggedItem.activity;
    if (draggedActivity.workflow) {
      if (workflow.name !== draggedActivity.workflow.name) {
        draggedActivity.workflow.id = workflow.id;
        draggedActivity.workflow.moved_from = draggedActivity.workflow.name;
        draggedActivity.workflow.name = workflow.name;
      } else {
        draggedActivity.workflow.id = workflow.id;
        
        draggedActivity.workflow.name = workflow.name;
      }
    } else {
      draggedActivity.workflow = {};
      draggedActivity.workflow.id = workflow.id;
      draggedActivity.workflow.moved_from = draggedActivity.workflow.name;
      draggedActivity.workflow.name = workflow.name;
    }


    workflowListCopy.forEach(workFlowEachData =>{
      if(workFlowEachData.id === draggedItem.currentWorkFlowId  ){
        
        let activityList=workFlowEachData.activities.filter(activityData=>activityData.id!==draggedActivity.id);
        activityList.splice(hoverIndex,0,draggedActivity);
        workFlowEachData.activities=activityList;
      }
      else{
        let activityList=workFlowEachData.activities.filter(activityData=>activityData.id!==draggedActivity.id);
        workFlowEachData.activities=activityList;
      }

    })
      setWorkflowList(workflowListCopy);  
  };
  const [{ isTargetDragging }, drop] = useDrop({
    accept: DragTypes.ACTIVITY,

    collect: (monitor) => {
      return {
        isTargetDragging: monitor.isOver()
      }
    },
    hover(item, _monitor){
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index
      const hoverIndex = index
      if ( item.currentWorkFlowId===workflow.id &&  dragIndex === hoverIndex) {
        return
      }

      let currentWorkFlowId=item.currentWorkFlowId;
      if(item.currentWorkFlowId !== workflow.id ){
        item.currentWorkFlowId=workflow.id
     }
      moveCard(dragIndex,hoverIndex,item.activity,currentWorkFlowId,item.sourceWorkFlowid);
      item.index=hoverIndex;
    },
    drop(item, _monitor) {
      if (!ref.current) {
        return;
      }     
      const dragIndex = item.index;
      const hoverIndex = index;
   
      let  workflowListCopy = _.cloneDeep(workflowList);
      moveActivity(activitiyList, dragIndex, hoverIndex, item,workflowListCopy);
     
      const createActivityOrder = () => {
        let activityOrder = [];
        _.forEach(workflowListCopy, innerWorkflow => {
          if (parseInt(innerWorkflow.id) === parseInt(workflow.id)) {
            _.forEach(innerWorkflow.activities, activity => {
              if (activityOrder.indexOf(parseInt(activity.id)) === -1) {
                activityOrder.push(parseInt(activity.id));
              }
            });
          }
        });
        selectedProject.workflow[workflow.id].activity_order = activityOrder;
      };
      const updateActivity = () => {
        if (
          draggedActivity.workflow.name !== draggedActivity.workflow.moved_from
        )
          updateActivityWithWorkflowAction(
            {
              id: draggedActivity.id,
              version_id: draggedActivity.version_id + 1,
              workflow: draggedActivity.workflow
            },
            _response => { /* TODO document why this arrow function is empty */ }
          );
      };
      createActivityOrder();
      let projectToUpdate = {
        id: selectedProject.id,
        // workflow: selectedProject.workflow,
        updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        workflow_changes: {
          activity_order_changed: true,
          new_activity_order: [ { id: workflow.id, new: selectedProject.workflow[workflow.id].activity_order } ] }
      };
      const auth_token = localStorage.getItem("auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: projectToUpdate },
        response => {
          if (response && response.id) {
            updateActivity();
          }
        }
      );
      
      item.itemDroppedWorkFlowId=true;
    }
  });
  const [{ isSourceDragging }, drag] = useDrag({
    item: {
      type: DragTypes.ACTIVITY,
      activity: activity,
      workflowId: workflow.id,
      currentWorkFlowId:workflow.id,
      sourceWorkFlowid:workflow.id,
      itemDroppedWorkFlowId:false,
      index
    },
    start(item, _monitor){
      item.itemDroppedWorkFlowId=false;
    },
    end(item, _monitor){
      if( !item.itemDroppedWorkFlowId){
        
       
        let workflowListCopy = _.cloneDeep(workflowList);
        workflowListCopy.forEach(workFlowDataEach=>{
          if(workflow.id !==workFlowDataEach.id ){
             let  actvities=workFlowDataEach.activities.filter(activitydata=> activitydata.id !== item.activity.id);
             workFlowDataEach.activities=actvities;
          }     
        })
        setWorkflowList(workflowListCopy);
        return;
      }
    },
    collect: (monitor) => {
      

      return {
        isSourceDragging: monitor.isDragging()
      }

    }
      
     
  });

  const opacity = isSourceDragging || isTargetDragging ? 0: 1;

  drag(drop(ref));
  const defineHeight = () => {
    if (!activity.workflow) {
      return "without-moved-from";
    } else if (activity.workflow && parseInt(activity.workflow.id) === 1) {
      if (
        !activity.workflow.moved_from ||
        activity.workflow.moved_from === "Backlog"
      ) {
        return "without-moved-from";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const [isPopupEnableEdit, setIsPopupEnableEdit] = useState(false);
  const [fromActivityInnerAdd, setFromActivityInnerAdd] = useState(false);
  const onPopupCloseEditCallBack = () => {
    setIsPopupEnableEdit(false);
    setFromActivityInnerAdd(false);
    getActivitiesWithProjectId(selectedProject.id);
    setOptionsDropdown(null);
  };

  const onConfirm = () => { /* TODO document why this arrow function is empty */ };

  let dialogPropsEdit = {
    className: "create_activity",
    showPopup: isPopupEnableEdit,
    dialogTitle: () => "Edit Actvity",
    onCloseCallBack: onPopupCloseEditCallBack,
    onConfirmCallBack: onConfirm,
    shouldButtonEnable: true
  };
  const checkPriority = () => {
    if (activity.data.priority) {
      let priority = false;
      _.forEach(activity.data.priority, user => {
        if (parseInt(user.user_id) === parseInt(loggedinUser.id)) {
          priority = true;
        }
      });
      return priority;
    } else {
      return false;
    }
  };

  const onArchiveItem = activityDetails => {
    let updatedActivity = { ...activityDetails };
    let user_id = parseInt(localStorage.getItem("id"));
    const customer_auth_token = localStorage.getItem(
      activities.customerAuthToken
    );
    let archiveData = {
      completed_on: moment.utc(new Date()).format(),
      data: {
        ...updatedActivity.data,
        lastModifiedBy: user_id,
        archivedUsers: updatedActivity.data.archivedUsers
          ? [...updatedActivity.data.archivedUsers, user_id]
          : [user_id]
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id
    };

    archiveOrUnArchiveActivitiesAction(
      {
        auth_token: customer_auth_token,
        archiveData: archiveData,
        type: activities.archive
      },
      _response => {
        getActivitiesWithProjectId(selectedProject.id);
        getSelectedWorkpsaceArchivedActivities(
          {
            auth_token: customer_auth_token,
            user_id: user_id,
            activity_type: "ACTIVITY",
            is_archived: true,
            is_active: true,
            page: 1,
            item_per_page: 20
          },
          _response => {
            // write what to do on success
          }
        );
        setOptionsDropdown(null);
      }
    );
  };
  const handleEditActivity = () => {
    setIsPopupEnableEdit(true);
    setOptionsDropdown(null);
  };
  const showOptionsDropdown = (e, id) => {
    e.stopPropagation();
    if(optionsDropdown === id)
      setOptionsDropdown(null);
    else setOptionsDropdown(id);
  };
  const [activityMainPopup, setActivityMainPopup] = useState(false);
  const onMainPopupClose = () => {
    setActivityMainPopup(false);
    getActivitiesWithProjectId(selectedProject.id);
  };
  const showMainPopup = () => {
    setActivityMainPopup(true);
  };
  const handleClickEditCallback = (activity, fromActivityInnerAdd) => {
    fromActivityInnerAdd && setFromActivityInnerAdd(true);
    setActivityMainPopup(false);
    handleEditActivity();
  };
  let dialogPropsPopup = {
    className: "activity-popup-container",
    showPopup: activityMainPopup,
    dialogTitle: () => { /* TODO document why this method 'dialogTitle' is empty */ },
    onCloseCallBack: onMainPopupClose,
    shouldButtonEnable: true,
    shouldScrollUpdate:true
  };
  const styleObj = {
    opacity: 0,
    display:'inline-block',
    width:'100%'
  }

  const isUserPartOfCard =
      activity &&
      activity.user_id.includes(parseInt(loggedinUser.id));
  
  return (
    <Fragment>
      {isPopupEnableEdit && (
        <DialogWrapper dialogProps={dialogPropsEdit}>
          <ActivityForm
            getUserDetailsAction={getUserDetailsAction}
            fromActivityInnerAdd={fromActivityInnerAdd}
            source="FROM_KANBAN"
            logedinUserDetails={loggedinUser}
            selectedActivity={activity}
            {...dialogPropsEdit}
          />
        </DialogWrapper>
      )}

      {activityMainPopup && (
        <DialogWrapper dialogProps={dialogPropsPopup}>
          <ActivityPopupTop
            activityDetails={activity}
            onClosePopup={dialogPropsPopup.onCloseCallBack}
            onArchiveItem={() => onArchiveItem(activity)}
            source="FROM_KANBAN"
            is_priority={checkPriority()}
            changeWorkFlowAction={changeWorkFlowAction}
            archiveOrUnArchiveActivitiesAction={
              archiveOrUnArchiveActivitiesAction
            }
            prioritizeDePrioritizeFromKanBanAction={
              prioritizeDePrioritizeFromKanBanAction
            }
            prioritiZedActivity={prioritizedActivity}
            handleClickEditCallback={handleClickEditCallback}
            getActivitiesWithProjectId={getActivitiesWithProjectId}
          />
          <ActivityPopupBottom
            activityDetailsAsProps={activity}
            is_archived={false}
            is_priority={checkPriority()}
            source="FROM_KANBAN"
          />
        </DialogWrapper>
      )}

      <div
        ref={ref}
        className={"flow-card-container " + defineHeight()}
        onClick={showMainPopup}
        style={{ opacity }}
      >
        <div className="flow-card-title">
          <div className="flow-card-title-holder">{activity.title}</div>
          <div className="pw-activity-edit-container activity-options-pw">
            <OutsideClickHandler
              onOutsideClick={() => setOptionsDropdown(null)}
            >
              <div
                className="option-icon"
                onClick={e => showOptionsDropdown(e, activity.id)}
              >
                <div className="dot-holder">
                  <img src={activityDots} alt="more-option" />
                </div>
                {optionsDropdown && optionsDropdown === activity.id && (
                  <div className="options-block">
                    <div onClick={handleEditActivity}>Edit</div>
                    {isUserPartOfCard && <div onClick={() => onArchiveItem(activity)}>Archive</div>}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        {/* <div className="flow-card-tag">
          <span>{activity.tags[0]}</span>
        </div> */}
        <div className="clear"></div>
        <div className="flow-card-date">
          <div className="flow-time">
            <img src={activityDuration} alt="duration" className="timer-image" />{" "}
            <span className="time">{activityHours + " " + activityMins}</span>
          </div>
          <div className="calendar">
            <img className="calendar-image" src={calendarFlow} alt="cal-icon" />
            <span className="time">
              {" "}
              {moment(activity.start_on).format("DD MMM YYYY")} -{" "}
              {moment(activity.due_on).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
        <div className="users-flow">
          {activity.user_id
            .filter(id => userMap.hasOwnProperty(id))
            .map((user, index) => {
              if (index <= 4) {
                return (
                  <div
                    className={`img-holder ${
                      user === (activity?.data?.owner || activity.created_by) ? "owner" : ""
                    } ${!userMap[user].email ? "not-wp-user" : ""}`}
                    key={user.id}
                  >
                    {userMap[user].img ? (
                      <img
                        className="calendar-image"
                        src={
                          userMap[user]
                            ? userMap[user].img ||
                              "//d30rgbmectrk9r.cloudfront.net/users/default.png"
                            : "//d30rgbmectrk9r.cloudfront.net/users/default.png"
                        }
                        alt="user-img"
                      />
                    ) : (
                      <Avatar
                        style={{ width: "100%", height: "100%" }}
                        name={userMap[user].first_name}
                        maxInitials={1}
                      ></Avatar>
                    )}
                  </div>
                );
              }
              return null;
            })}

          {activity.user_id.filter(id => userMap.hasOwnProperty(id)).length >
            5 && (
            <div className="remaining-users-count">
              {" "}
              +{activity.user_id.length - 5}{" "}
            </div>
          )}
        </div>
        {activity.workflow && parseInt(activity.workflow.id) !== 1 && (
          <KanbanCardStatus
            activity={activity}
            source={kanbanConstant.fromKanban}
          ></KanbanCardStatus>
        )}
        {activity.workflow &&
          parseInt(activity.workflow.id) === 1 &&
          activity.workflow.moved_from &&
          activity.workflow.moved_from !== "Backlog" && (
            <div className="backlog-details">
              {" "}
              Moved from :<span>{activity.workflow.moved_from}</span>
            </div>
          )}
        {((activity.workflow && parseInt(activity.workflow.id) === 1) ||
          !activity.workflow) && <div className="backlog-bg-holder">
              <span style={styleObj}>Drag your activity here</span>
             </div>}
      </div>
    </Fragment>
  );
};

export default KanbanActivityCard;
