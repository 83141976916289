import React, { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Mark from "mark.js";
import { Control, actions } from "react-redux-form";
import DateRangePicker from "../common/dateRangePicker";
import AttachmentDropdown from "../attachments/attachmentDropdown";
import { Loader } from "../common/loader";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@material-ui/core/TextField';
import user_add from "../../styles/img/svg/plus-blue.svg";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import closeIcon from "../../styles/img/svg/Close_White.svg";
import CloseIcon from "../../styles/img/svg/close_new.svg";
import { toast } from "react-toastify";
import "../../styles/scss/activity.scss";
import "../../styles/scss/reports.scss";
import "react-md/src/scss/_react-md.scss";
import "../../styles/scss/billing.scss";
import "../../styles/scss/activityUserView.scss";
import "../../styles/scss/addActivity.scss";
import delete_icon from "../../styles/img/svg/user-name-delete.svg";
import calIcon from "../../styles/img/svg/calendar-flow.svg";
import Avatar from "react-avatar";
import { secondsToHms } from "../../helpers/generichelper";
import FloatingSearchBox from "../workspace/partials/floatingsearchbox";
import OutsideClickHandler from "react-outside-click-handler";
import {
  getProjectsAction,
  createProjectAction,
  updateProjectAction
} from "../../actions/project/projectaction";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import search_field from "../../styles/img/svg/search-field.svg";
import {
  createUpcomingActivityAction,
  createCompletedActivityAction,
  updateActivityAction,
  updateAllActivityAction,
  updateKanbanActivityAction,
  updateArchiveActivityAction,
  addActivityToReducerAction,
  removeActivityFromReducerAction,
  getActivitiesForDropdownAction,
  createTimerAction,
  addTimerAction
} from "../../actions/activities/activitiesaction";
import { getSelectedWorkpsaceArchivedActivities as getSelectedWorkspaceActiveActivities } from "../../actions/workspace/workspaceaction";
import UserCannotRemovePopup from "./userCannotRemovePop";
import routesHelper from "../../router/routeshelper";
import AttachmentErrPop from "./attachmentErrPop";

import {
  createActivityAction,
  updateWorkloadActivityAction,
  addBacklogActivitiesAction,
  removeBacklogActivitiesAction
} from "../../actions/workload/workload";
import DialogWrapper from "../common/dialog";

import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { getUpdatedProjectToAddPeople } from "../../helpers/projectsettings";
import {
  getIsSuperAdmin,
  getQueryParamsForTimeSheet
} from "../../helpers/reportlanding";
import { workloadConstant } from "../../constants/workloadConstant";
import { activities } from "../../constants/activitiescontants";
import firebase from "../../firebase";
import { withStyles } from "@material-ui/core/styles";
import { getTotalHrsMins } from "../../helpers/addActivityFormValidator";
import { getProjectById } from "../../services/projectapi";

const styles = _theme => ({
  input: {
    height: 45,
    "&.MuiOutlinedInput-focused": {
      "fieldset": {
        borderColor: "#C52328",
        borderWidth: "1px"
      }
    }
  },
  label: {
    transform: "translate(14px, 15px) scale(1)"
  },
  overviewInput: {
    minHeight: 81
  },
  dateInput: {
    height: 45,
    width: 285,
    background: `url(${calIcon}) no-repeat right 18px center`,
    backgroundSize: 16
  }
});

class AddActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      shouldShoulClientDropDown: false,
      activityTitle: "",
      showActivityDropdown: false,
      activitiesForDropdown: [],
      selectedCompletedActivity: null,
      showOverview: false,
      activityOverview: "",
      showSearchBox: false,
      showProjectDropDown: false,
      userDetails: {
        users: [],
        selectedUser: []
      },
      users_duration: [{}],
      completed_durations: {
        durations: [{}]
      },
      filteredUser: [],
      projectList: [],
      filteredProjectList: [],
      isCompleted: false,
      showTypeDropDownUI: false,
      selectedTags: [],
      selectedProject:
        props.source === "FROM_KANBAN" ? props.selectedProject : null,
      projectFieldValue: "",
      hours: "",
      minutes: "",
      activityTagFieldValue: "",
      userToRemoveId: null,
      showCannotRemovePopup: null,
      start_on: "",
      due_on: "",
      description: "",
      openAttachmentDropdown: false,
      attachmentFrom: "",
      displayErrMsg:false,
      attachmentOneDrive: false,
      attachmentsToAdd: [],
      attachmentsFile: [],
      showAttachmentErrPop: false,
      showMoreOption: null,
      continuous: false,
      clickedImg: null,
      fetching: false,
      fetchingActivities: false
    };
    this.actTitleRef = React.createRef();
    this.projectFieldRef = React.createRef();
  }
  highlightTimer;

  componentDidMount() {
    const {
      // getProjectsAction,
      projectList,
      // selectedWorkspace,
      // getAllCustomerForPeopleAction,
      loggedInUser
    } = this.props;

    // getProjectsAction({ queryParam }, response => {
      this.setState({
        projectList,
        filteredProjectList: projectList,
        filteredUser: this.props.selectedActivity
          ? this.props.workspaceUser.filter(user => {
              return !this.props.selectedActivity.user_id.includes(
                parseInt(user.id)
              );
            })
          :this.props.workLoadBacklog || (this.props.selectedActivity && this.props.selectedActivity.backFlowFlag)? this.props.workspaceUser:this.props.workspaceUser.filter(user => {
              return user.id !== parseInt(loggedInUser.id);
            })
      });
    // });

    if (this.props.fromReport) {
      if(this.state.activitiesForDropdown.length === 0) {
        this.setState({ fetchingActivities: true });
        this.props.getActivitiesForDropdownAction({
          userId: parseInt(this.props.loggedInUser.id)
        }, response => {
            this.setState({ activitiesForDropdown: response, showActivityDropdown: true, fetchingActivities: false });
        })
      }
      if(this.props.defaultDate) {
        const picker = {
          startDate: { _d: this.props.defaultDate },
          endDate: { _d: this.props.defaultDate }
        }
        this.onChangeCompletedDate(null, picker, 0);
      }
      this.setState({ isCompleted: true });
    }

    if(this.props.fromActivityInnerAdd) this.setState({ showSearchBox: true });

    if (
      this.props.selectedActivity &&
      this.props.workspaceUser &&
      this.props.projectList
    ) {
      const { selectedActivity, workspaceUser, projectList } = this.props;
      const { hours, mins } = secondsToHms(selectedActivity.duration);
      let users = workspaceUser.filter(user => {
        return selectedActivity.user_id.includes(parseInt(user.id));
      });
      if(this.props.selectedActivity.users_not_in_workspace)
        users = users.concat(this.props.selectedActivity.users_not_in_workspace);

      const selectedProject = projectList.filter(
        project =>
          project.id ===
          (selectedActivity.project_id || selectedActivity.project.id)
      )[0];

      const { users_duration } = selectedActivity;

      let users_duration_to_edit = users_duration && users_duration.map(user => {
        if(user?.continuous) {
          return {
            start_date: user.start_date,
            end_date: moment(user.start_date).add(5, "year").format(),
            continuous: user.continuous,
            hrs: secondsToHms(user.time_per_day).hours,
            mins: secondsToHms(user.time_per_day).mins,
            user_id: user.user_id,
            user_img: user.user_img,
            user_first_name: user.user_first_name,
            user_last_name: user.user_last_name
          }
        } else {
          return {
            start_date: user.start_date,
            end_date: user.end_date,
            continuous: user.continuous,
            hrs: secondsToHms(user.time_per_day).hours,
            mins: secondsToHms(user.time_per_day).mins,
            user_id: user.user_id,
            user_img: user.user_img,
            user_first_name: user.user_first_name,
            user_last_name: user.user_last_name
          }
        }
      });

      return this.setState({
        isEdit: true,
        id: selectedActivity.id,
        activityTitle: selectedActivity.title,
        activityOverview: selectedActivity.description,
        userDetails: {
          users: [...users],
          selectedUser: []
        },
        selectedTags: selectedProject ? selectedActivity.tags : [],
        projectFieldValue: selectedProject ? selectedActivity.project.name : "",
        hours: hours,
        minutes: mins,
        selectedProject,
        start_on: moment(selectedActivity.start_on).format("YYYY-MM-DD") || "",
        due_on: moment(selectedActivity.due_on).format("YYYY-MM-DD") || "",
        continuous: selectedActivity.continuous_activity,
        users_duration: users_duration_to_edit
      });
    }
    if (this.props.defaultUsers && this.props.workspaceUser) {
      const {
        defaultUsers,
        defaultDate,
        workspaceUser,
        change,
        selectedProject
      } = this.props;
      const users = workspaceUser.filter(user => {
        return defaultUsers.includes(parseInt(user.id));
      });
      change(
        "rrfState.addActivity.activity_start",
        moment(defaultDate)
          .startOf("day")
          .format()
      );
      change(
        "rrfState.addActivity.activity_due",
        moment(defaultDate)
          .endOf("day")
          .format()
      );
      return this.setState({
        userDetails: {
          users: [...users],
          selectedUser: []
        },
        filteredUser: this.props.workspaceUser.filter(user => {
          return user.id !== parseInt(defaultUsers[0]);
        }),
        projectFieldValue: selectedProject ? selectedProject.name : "",
        selectedProject: selectedProject ? selectedProject : null,
        users_duration:
        defaultUsers.length > 0 ?
          [{
            start_date: moment(defaultDate).format(),
            end_date: moment(defaultDate).format(),
            continuous: false,
            hrs: 0,
            mins: 0,
            user_id: parseInt(users[0].id),
            user_img: users[0].img,
            user_first_name: users[0].first_name,
            user_last_name: users[0].last_name
          }]
          :
          [],
        start_on: defaultUsers.length > 0 ? moment(defaultDate).format() : moment().format(),
        due_on: defaultUsers.length > 0 ? moment(defaultDate).format() : moment().format()
      });
    }

    return this.setState(
      {
        userDetails: {
          ...this.state.userDetails,
          users: [loggedInUser]
        },
        users_duration: [{
          user_id: parseInt(loggedInUser.id),
          user_img: loggedInUser.img,
          user_first_name: loggedInUser.first_name,
          user_last_name: loggedInUser.last_name
        }]
      },
      () => {}
    );
  }

  componentDidUpdate() {
    if (document.getElementsByClassName("daterangepicker").length > 0) {
      let i;
      for(i = 0; i < document.getElementsByClassName("daterangepicker").length; i++) {
        document
        .getElementsByClassName("daterangepicker")[i]
        .classList.add("add-act-picker");
      }
    }
    if(document.querySelectorAll('[role="dialog"]').length > 1) {
      const innerHeight = document.querySelectorAll('[role="dialog"]')[1]?.childNodes[1]?.childNodes[0]?.clientHeight;
      const outerHeight = document.querySelectorAll('[role="dialog"]')[1]?.childNodes[1]?.clientHeight
      if(innerHeight > outerHeight) {
        document.querySelectorAll('[role="dialog"]')[1].childNodes[1].style.height = "auto";
      }
    }
    const innerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].childNodes[0].clientHeight;
    const outerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].clientHeight
    // console.log(outerHeight, innerHeight)
    if(innerHeight > outerHeight) {
      document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "auto";
    }
    // else if(innerHeight < outerHeight) {
    //   document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "100%";
    // }
    // if(document.getElementsByClassName("completed-description").length > 0) {
    //   console.log(document.getElementsByClassName("completed-description")[0].lastElementChild.childNodes[1].childNodes[2])
    //   document.getElementsByClassName("completed-description")[0].lastElementChild.childNodes[1].childNodes[2].style.height = 
    //     `${document.getElementsByClassName("completed-description")[0].lastElementChild.childNodes[1].childNodes[2].clientHeight + 1}px`;
    // }
  }

  componentWillUnmount() {
    const { reset } = this.props;

    reset("rrfState.addActivity");
  }

  onTitleChange = e => {
    const { change } = this.props;
    change("rrfState.addActivity.title", e.target.value);
    this.setState({
      activityTitle: e.target.value,
      selectedCompletedActivity: null,
      selectedProject: this.state.selectedCompletedActivity ? null : this.state.selectedProject,
      activityTagFieldValue: this.state.selectedCompletedActivity ? "" : this.state.activityTagFieldValue,
      projectFieldValue: this.state.selectedCompletedActivity ? "" : this.state.projectFieldValue
    });
  };

  onActivityFocus = () => {
    this.setState({ showActivityDropdown: true })
  }

  onActivityOptionSelected = (activity) => {
    const selectedProject = this.state.projectList.filter(proj => proj.id === activity.project.id)[0];
    let durations = [ ...this.state.completed_durations.durations ];
    durations.length = 1;
    this.setState({
      selectedCompletedActivity: activity,
      activityTitle: activity.name,
      showActivityDropdown: false,
      selectedProject: selectedProject,
      completed_durations: {
        durations
      },
      attachmentsToAdd: [],
      attachmentsFile: []
    })
  }

  onClearActivityField = () => {
    this.setState({
      activityTitle: "",
      selectedCompletedActivity: null,
      selectedProject: this.state.selectedCompletedActivity ? null : this.state.selectedProject,
      projectFieldValue: this.state.selectedCompletedActivity ? "" : this.state.projectFieldValue
    }, () => {
      this.onActivityFocus();
      this.actTitleRef.current.focus();
    });
  }

  onShowOverview = () => {
    this.setState({ showOverview: true });
  }

  onOverviewChange = e => {
    const { change } = this.props;
    change("rrfState.addActivity.overview", e.target.value);
    this.setState({
      activityOverview: e.target.value
    });
  };

  onProjectFocus = () => {
    if (this.state.selectedProject) {
      return this.setState({
        showProjectDropDown: true,
        showTypeDropDownUI: false,
        filteredProjectList: [this.state.selectedProject]
      });
    }
    if (this.state.projectList.length > 0)
      this.setState({
        showProjectDropDown: true,
        showTypeDropDownUI: false
      });
  };

  onClearProjectField = () => {
    this.setState({
      activityTitle: this.state.selectedCompletedActivity ? "" : this.state.activityTitle,
      selectedCompletedActivity: null,
      selectedProject: null,
      projectFieldValue: "",
      filteredProjectList: this.props.projectList,
      showProjectDropDown: this.props.projectList.length === 0 ? false : this.state.showProjectDropDown
    }, () => {
      this.onProjectFocus();
      this.projectFieldRef.current.focus();
    });
  }

  onAddUserClicked = (_e, _user) => {
    this.setState({
      showSearchBox: !this.state.showSearchBox,
      userDetails: {
        ...this.state.userDetails,
        selectedUser: []
      }
    });
  };

  showActivityTypeDropdown = () => {
    this.setState({
      showTypeDropDownUI: true
    });
  };

  onSearchInitiated = (searchValue, _callback) => {
    const existingAddedUserId = this.state.userDetails.users.map(user =>
      parseInt(user.id)
    );

    const filteredUser =
      searchValue.target.value.length > 0
        ? this.props.workspaceUser.filter(element => {
            return (
              element.first_name
                .toLowerCase()
                .indexOf(searchValue.target.value.toLowerCase()) > -1 &&
              !existingAddedUserId.includes(parseInt(element.id))
            );
          })
        : this.props.workspaceUser.filter(
            user => !existingAddedUserId.includes(parseInt(user.id))
          );

    this.setState({
      filteredUser: filteredUser
    });
  };

  onAddUserCallBack = () => {
    this.setState(
      {
        userDetails: {
          users: [
            ...this.state.userDetails.users,
            ...this.state.userDetails.selectedUser
          ],
          selectedUser: []
        },
        users_duration: [
          ...this.state.users_duration,
          ...this.state.userDetails.selectedUser.map(user => {
            return {
              user_id: parseInt(user.id),
              user_img: user.img,
              user_first_name: user.first_name,
              user_last_name: user.last_name
            }
          })
        ],

        showSearchBox: false
      },
      () => {
        const existingAddedUserId = this.state.userDetails.users.map(user =>
          parseInt(user.id)
        );

        const filteredUser = this.props.workspaceUser.filter(
          user =>
            user.id !== parseInt(this.props.loggedInUser.id) &&
            !existingAddedUserId.includes(parseInt(user.id))
        );

        this.setState({
          filteredUser: filteredUser
        });
      }
    );
  };
  onCancelAddClick = () => {
    this.setState(
      {
        userDetails: {
          users: [...this.state.userDetails.users],
          selectedUser: []
        },
        filteredUser: [],
        showSearchBox: false
      },
      () => {
        const existingAddedUserId = this.state.userDetails.users.map(user =>
          parseInt(user.id)
        );

        const filteredUser = this.props.workspaceUser.filter(
          user =>
            // user.id !== parseInt(this.props.loggedInUser.id) &&
            !existingAddedUserId.includes(parseInt(user.id))
        );

        this.setState({
          filteredUser: filteredUser
        });
      }
    );
  };

  onRemoveUserFromUserList = (_e, removedUser) => {
    this.setState(
      {
        userDetails: {
          ...this.state.userDetails,
          users: this.state.userDetails.users.filter(
            user => user.id !== removedUser.id
          )
        },
        users_duration: this.state.users_duration.filter(
          user => parseInt(user.user_id) !== parseInt(removedUser.id)
        )
      },
      () => {
        const existingAddedUserId = this.state.userDetails.users.map(user =>
          parseInt(user.id)
        );

        const filteredUser = this.props.workspaceUser.filter(
          user =>
            // user.id !== parseInt(this.props.loggedInUser.id) &&
            !existingAddedUserId.includes(parseInt(user.id))
        );

        let startDates = this.state.users_duration.map(user => {
          if(user?.start_date) {
            return user.start_date
          }
        }).filter(function( element ) {
          return element !== undefined;
       }).map(date => moment(date));
       let minStartDate = moment.min(startDates).format("YYYY-MM-DD");
  
       let endDates = this.state.users_duration.map(user => {
        if(user?.end_date) {
          return user.end_date
        }
      }).filter(function( element ) {
        return element !== undefined;
     }).map(date => moment(date));
     let maxEndDate = moment.max(endDates).format("YYYY-MM-DD");

        this.setState({
          filteredUser: filteredUser,
          start_on: minStartDate,
          due_on: maxEndDate,
          isCompleted: existingAddedUserId.length === 0 ? false : this.state.isCompleted
        });
      }
    );
  };

  onShowUserRemovePopup = (userToRemoveId) => {
    this.setState({ userToRemoveId });
  }

  onRemovePopupOutsideClick = (userId) => {
    if(userId === this.state.userToRemoveId)
      this.setState({ userToRemoveId: null });
  }

  onCloseCannotRemovePopup = () => {
    this.setState({ showCannotRemovePopup: null });
  }

  onRemoveUserEditMode = (_e, user, currentActivity) => {
    let userId=localStorage.getItem("id");

    const updatedData = currentActivity.data && currentActivity.data.priority ? {
      ...currentActivity.data,
      lastModifiedBy: parseInt(userId),
      priority: currentActivity.data.priority.filter(member => member.user_id !== user.id) 
    } : currentActivity.data;

    const updated_users_duration = currentActivity.users_duration.filter(member => member.user_id !== user.id);

    // Minimum start date and Maximum due date calculation
    let startDates = updated_users_duration.map(existingUser => {
      if(existingUser?.start_date) {
        return existingUser.start_date
      }
    }).filter(function( element ) {
      return element !== undefined;
   }).map(date => moment(date));
   let minStartDate = moment.min(startDates).format();

   let endDates = updated_users_duration.map(existingUser => {
    if(existingUser?.end_date) {
      return existingUser.end_date
    }
  }).filter(function( element ) {
    return element !== undefined;
 }).map(date => moment(date));
 let maxEndDate = moment.max(endDates).format();
 let updated_continuous = [];
 updated_users_duration.forEach(existingUser => {
  if(existingUser?.continuous) {
    updated_continuous.push(true);
  }
})
  //  this.setState({
  //    start_on: minStartDate,
  //    due_on: updated_continuous.length > 0 ? moment().add(5, "year").format("YYYY-MM-DD") : maxEndDate,
  //    continuous: updated_continuous.length > 0 ? true : false
  //   })
  
  const updated_duration = updated_continuous.length > 0 ? 0 : 
  updated_users_duration.reduce((total, existingUser) => {
    if(existingUser?.total_time) {
      return total + existingUser.total_time
    }
  }, 0)
    
    const updatedActivity = {
      ...currentActivity,
      data: updatedData,
      version_id: currentActivity.version_id + 1,
      user_id: currentActivity.user_id.filter(
        removeduser => user.id !== removeduser
      ),
      performed_by:JSON.parse(userId),
      users_duration: updated_users_duration,
      users_not_in_workspace: currentActivity.users_not_in_workspace.filter(removeduser => user.id !== removeduser.id),
      description: currentActivity.description || "",
      tags: currentActivity.tags || [],
      duration: updated_duration,
      start_on: minStartDate,
      due_on: updated_continuous.length > 0 ? moment().add(5, "year").format() : maxEndDate,
      continuous_activity: updated_continuous.length > 0 ? true : false
    };
    const {
      updateActivity,
      updateKanbanActivity,
      // workspaceUser,
      // fromWorkload,
      fromNotification,
      updateWorkloadActivity,
      updateArchiveActivity,
      isPopupEnableInnerDisableClicked,
      source,
      updateAllActivity,
      updateActivityState,
      removeActivityFromReducer,
      addBacklogActivities
    } = this.props;

    
    if(user.email)
      this.setState({
        filteredUser: [...this.state.filteredUser, user]
      });
    if (source === "FROM_WORKLOAD") {
      updateWorkloadActivity(
        {
          activityObj: currentActivity,
          user_id: updatedActivity.user_id,
          activity_id: updatedActivity.id,
          fields: { ...updatedActivity }
        },
        res => {
          if (res && !res.stdErr && !res.error) {
            this.setState({
              userToRemoveId: null,
              userDetails: {
                ...this.state.userDetails,
                users: this.state.userDetails.users.filter(
                  removedUser => removedUser.id !== user.id
                )
              },
              users_duration: this.state.users_duration.filter(member => member.user_id !== user.id),
              start_on: minStartDate,
              due_on: updated_continuous.length > 0 ? moment().add(5, "year").format("YYYY-MM-DD") : maxEndDate,
              continuous: updated_continuous.length > 0 ? true : false
            });
            if(updatedActivity.user_id && updatedActivity.user_id.length<1){
              addBacklogActivities(updatedActivity,currentActivity.project_id);
              this.props.onCloseCallBack();
          }
          } else if (res && res.error) {
            this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
          }
        }
      );
    } else if (source === "FROM_KANBAN") {
      updateKanbanActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({
            userToRemoveId: null,
            userDetails: {
              ...this.state.userDetails,
              users: this.state.userDetails.users.filter(
                removedUser => removedUser.id !== user.id
              )
            },
            users_duration: this.state.users_duration.filter(member => member.user_id !== user.id),
            start_on: minStartDate,
            due_on: updated_continuous.length > 0 ? moment().add(5, "year").format("YYYY-MM-DD") : maxEndDate,
            continuous: updated_continuous.length > 0 ? true : false
          });
        } else if (res && res.error) {
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    } else if (source === "FROM_ARCHIVE") {
      updateArchiveActivity({ ...updatedActivity }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({
            userToRemoveId: null,
            userDetails: {
              ...this.state.userDetails,
              users: this.state.userDetails.users.filter(
                removedUser => removedUser.id !== user.id
              )
            },
            users_duration: this.state.users_duration.filter(member => member.user_id !== user.id),
            start_on: minStartDate,
            due_on: updated_continuous.length > 0 ? moment().add(5, "year").format("YYYY-MM-DD") : maxEndDate,
            continuous: updated_continuous.length > 0 ? true : false
          });
        } else if (res && res.error) {
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    } else{
      const is_priority =
      currentActivity.data.priority &&
      currentActivity.data.priority.filter(
        user => user.user_id === parseInt(localStorage.getItem("id"))
      ).length > 0
        ? true
        : false;
      updateActivity({ ...updatedActivity, is_priority }, res => {
        if (res && !res.stdErr && !res.error) {
          this.setState({
            userToRemoveId: null,
            userDetails: {
              ...this.state.userDetails,
              users: this.state.userDetails.users.filter(
                removedUser => removedUser.id !== user.id
              )
            },
            users_duration: this.state.users_duration.filter(member => member.user_id !== user.id),
            start_on: minStartDate,
            due_on: updated_continuous.length > 0 ? moment().add(5, "year").format("YYYY-MM-DD") : maxEndDate,
            continuous: updated_continuous.length > 0 ? true : false
          });
          if(fromNotification) {
            updateActivityState(updatedActivity);
          }
          if(JSON.parse(userId) === user.id){
            removeActivityFromReducer(updatedActivity);
            updateAllActivity(currentActivity.id);
            this.props.onCloseCallBack();
            isPopupEnableInnerDisableClicked && isPopupEnableInnerDisableClicked();
          }
        } else if (res && res.error) {
          this.setState({ showCannotRemovePopup: user, userToRemoveId: null });
        }
      });
    }
  // } // first else part of if end
       
  };

  onProjectInputChange = e => {
    this.setState({
      showProjectDropDown: true,
      projectFieldValue: e.target.value,
      onEditProjectField: true,
      // selectedProject: null,
      selectedTags: []
    });
    const searchTerm = e.target.value;
    if (e.target.value.length > 0) {
      const filteredProjectList = this.props.projectList.filter(
        element =>
          element.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
      this.setState({
        filteredProjectList: filteredProjectList
      });
    } else if (this.props.projectList.length === 0) {
      this.setState({ showProjectDropDown: false });
    } else {
      const filteredProjectList = this.props.projectList;
      this.setState({
        filteredProjectList: filteredProjectList,
        selectedProject: null
      });
    }
  };

  OnInputClicked(e) {
    e.stopPropagation();
  }

  addUserToSelectedList = (e, user) => {
    if (e.target.checked === true) {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          selectedUser: [...this.state.userDetails.selectedUser, user]
        }
      });
    } else {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          selectedUser: this.state.userDetails.selectedUser.filter(
            existingUser => existingUser.id !== user.id
          )
        }
      });
    }
  };

  renderCheckbox = ({ input, label }) => (
    <Checkbox
      label={label}
      checked={input.value ? true : false}
      onCheck={input.onChange}
    />
  );

  onChangeTypeField = e => {
    this.setState({
      activityTagFieldValue: e.target.value
    });
    if (e.target.value.length > 0) {
      this.setState({
        showTypeDropDownUI: true
      });
    }
    if (e.key === "Enter") {
      e.preventDefault();
      this.props.change("rrfState.addActivity.type", e.target.value);
      this.setState({
        selectedTags: [e.target.value],
        activityTagFieldValue: "",
        showTypeDropDownUI: false
      });
    }
  };

  onTypeOptionSelected = tag => {
    this.props.change("rrfState.addActivity.type", tag);
    this.setState({
      selectedTags: [tag],
      showTypeDropDownUI: false,
      activityTagFieldValue: tag
    });
  };
  onRemovingSelectedTags = _tag => {
    this.props.change("rrfState.addActivity.type", "");
    this.setState({
      selectedTags: [],
      activityTagFieldValue: ""
    });
  };

  onIsCompletedChecked = e => {
    // console.log("check", e.target.checked)
    if(this.state.activitiesForDropdown.length === 0) {
      this.setState({ fetchingActivities: true });
      this.props.getActivitiesForDropdownAction({
        userId: parseInt(this.props.loggedInUser.id)
      }, response => {
          this.setState({ activitiesForDropdown: response, fetchingActivities: false });
      })
    }
    this.props.change("rrfState.addActivity.activity_type", e.target.checked);
    this.props.change("rrfState.addActivity.activity_start", null);
    this.props.change("rrfState.addActivity.activity_due", null);
    this.props.change("rrfState.addActivity.activity_desc", null);
    this.setState({
      isCompleted: e.target.checked,
      selectedCompletedActivity: null,
      activityTitle: "",
      selectedProject: null,
      activityTagFieldValue: "",
      projectFieldValue: "",
      showActivityDropdown: false
      // hours: "",
      // minutes: "",
      // start_on: "",
      // due_on: ""
    });
  };

  onIsCompletedCheckedLabel = _e => {
    // console.log("check lael", this.state.isCompleted)
    if(this.state.activitiesForDropdown.length === 0) {
      this.setState({ fetchingActivities: true });
      this.props.getActivitiesForDropdownAction({
        userId: parseInt(this.props.loggedInUser.id)
      }, response => {
          this.setState({ activitiesForDropdown: response, fetchingActivities: false })
      })
    }
    this.props.change(
      "rrfState.addActivity.activity_type",
      !this.state.isCompleted
    );
    this.props.change("rrfState.addActivity.activity_start", null);
    this.props.change("rrfState.addActivity.activity_due", null);
    this.props.change("rrfState.addActivity.activity_desc", null);
    this.setState({
      isCompleted: !this.state.isCompleted,
      selectedCompletedActivity: null,
      activityTitle: "",
      selectedProject: null,
      activityTagFieldValue: "",
      projectFieldValue: ""
      // hours: "",
      // minutes: "",
      // start_on: "",
      // due_on: ""
    });
  };

  onProjectOptionClicked = project => {
    this.props.change("rrfState.addActivity.project", project.name);
    this.setState({
      selectedProject: project,
      showProjectDropDown: false,
      // projectFieldValue: project.name
      projectFieldValue: ""
    });
  };

  // Hours change new activity design
  onHoursChange = (e, userId) => {
    const re = /^[0-9]*$/;

    if (re.test(e.target.value)) {
      let maxHoursForActivity = 23;
      let hoursForActivity = e.target.value < maxHoursForActivity ? e.target.value : maxHoursForActivity;
      if(this.state.isCompleted) {
        this.setState({
          completed_durations: {
            durations: this.state.completed_durations.durations.map((duration, index) => {
              if(userId === index) {
                return {
                  ...duration,
                  hrs: parseInt(hoursForActivity) || 0
                }
              }
              return duration;
            })
          }
        })
      } else {
        this.setState({
        users_duration: this.state.users_duration.map(user => {
          if(user.user_id === userId)
            return {
              ...user,
              hrs: parseInt(hoursForActivity) || 0
            }
          return user;
        })
       }) 
      }
    }
  };

  // new activity mins change
  onMinutesChange = (e, userId) => {
    const re = /^[0-9]*$/;

    if (re.test(e.target.value)) {
      let maxMinutes = 59;
      let minsForActivity = e.target.value < maxMinutes ? e.target.value : maxMinutes;
      if(this.state.isCompleted) {
        this.setState({
          completed_durations: {
            durations: this.state.completed_durations.durations.map((duration, index) => {
              if(userId === index) {
                return {
                  ...duration,
                  mins: parseInt(minsForActivity) || 0
                }
              }
              return duration;
            })
          }
        })
      } else {
        this.setState({
        users_duration: this.state.users_duration.map(user => {
          if(user.user_id === userId)
            return {
              ...user,
              mins: parseInt(minsForActivity) || 0
            }
          return user;
        })
      });
      }
      
    } 
  };

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        // rect.top >= 0 &&
        // rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  onDescriptionChange = (e) => {
    this.props.change("rrfState.addActivity.activity_desc", e.target.value);
    this.setState({
      description: e.target.value
    });
    const inputBox = document.querySelector(".create_activity");
    if(inputBox && this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
      inputBox.scrollTop -= 48;
    }

  }

  onFocusDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "#0090e9";
    document.getElementsByClassName("completed-description")[0].classList.add("textfield-focused");
  }

  onBlurDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "rgba(0, 0, 0, 0.23)";
    document.getElementsByClassName("completed-description")[0].classList.remove("textfield-focused");
  }

  isInViewportWhole(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  openAttachmentDropdown = () => {
    this.setState({
      openAttachmentDropdown: !this.state.openAttachmentDropdown
    }, () => {
      const inputBox = document.querySelector(".attach-files-dropdown-container");
    if (inputBox && this.isInViewportWhole(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    });
  };

  chkMaxSize = (size) => {
    return size > 30000000;
  };

  handleMaxSize = (size, _type) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (sizeOfFile) {
      this.setState({
        displayErrMsg: true
      }, () => {
        setTimeout(() => {
          this.setState({ displayErrMsg: false })
        }, 5000)
      });
    } else {
      this.setState({
        displayErrMsg: false
      });
    }
  };

  handleAttachFrom = (from) => {
    this.setState({
      attachmentFrom: from
    });
  };

  closeAttachmentDropdown = (response, attachments) => {
    if (response && response.length > 0 && (response[0].src || response[0].url || response[0].type)) {
  
    this.setState(
        {
          attachmentFrom: "",
          openAttachmentDropdown: false,
          attachmentsToAdd: [ ...this.state.attachmentsToAdd, ...response ],
          attachmentsFile: [ ...this.state.attachmentsFile, ...attachments]
        });
    } else if (response && response.url) {
      this.setState(
        {
          attachmentFrom: "",
          openAttachmentDropdown: false,
          attachmentsToAdd: [ ...this.state.attachmentsToAdd, response ],
          attachmentsFile: [ ...this.state.attachmentsFile, response ]
        });
    } else {
      if(!this.state.attachmentOneDrive){
        this.setState({
          attachmentFrom: "",
          openAttachmentDropdown: false
        });
      }
    }
  };

  onNewActivityFormValidate = () => {
    const validTitle = this.state.activityTitle.length > 0;
    const validProject = this.state.selectedProject;
    const activityType = this.state.activityTagFieldValue.length > 0;
    const description = this.state.description;
    if(this.state.isCompleted) {
      let validCompletedDuration = [];
    this.state.completed_durations.durations.forEach(duration => {
      if(duration.start_date &&
        duration.end_date &&
        (duration.hrs > 0 ||
        duration.mins > 0)
      ) {
        // TODO document why this block is empty
      }
      else {
        validCompletedDuration.push(false);
      }
    })
    return validTitle && validProject && validCompletedDuration.length === 0 && activityType && description;
    } else {
      let validUsersDuration = [];
      this.state.users_duration.forEach(user => {
        if(user?.start_date &&
          user?.end_date &&
          (user?.hrs > 0 ||
          user?.mins > 0)
        ) {
          // TODO document why this block is empty
        }
        else {
          validUsersDuration.push(false);
        }
      })
      const validNumberOfUser = this.props.source === "FROM_WORKLOAD" ? true : this.state.userDetails.users.length > 0 
      return validTitle && validProject && validNumberOfUser && validUsersDuration.length === 0;
    }
  }

  isValidCompletedDuration = () => {
    let validCompletedDuration = [];
    this.state.completed_durations.durations.forEach(duration => {
      if(duration.start_date &&
        duration.end_date &&
        (duration.hrs > 0 ||
        duration.mins > 0)
      ) {
        // TODO document why this block is empty
      }
      else {
        validCompletedDuration.push(false);
      }
    })
    return validCompletedDuration.length === 0;
  }

  onAddMoreDuration = () => {
    this.setState({ completed_durations: {
      durations: [...this.state.completed_durations.durations, {}]
    }
  })
  }

  onChangeDate = (_event, picker, userId) => {
    this.setState({
    users_duration: this.state.users_duration.map(user => {
      if(user.user_id === userId)
        return {
          ...user,
          start_date: moment(picker.startDate._d).format(),
          end_date: moment(picker.endDate._d).format(),
          continuous: moment(picker.endDate._d).diff(moment(picker.startDate._d), "year") >= 5 ? true : false
        }
      return user;
    })
  }, () => {
      let startDates = this.state.users_duration.map(user => {
        if(user?.start_date) {
          return user.start_date
        }
      }).filter(function( element ) {
        return element !== undefined;
     }).map(date => moment(date));
     let minStartDate = moment.min(startDates).format("YYYY-MM-DD");

     let endDates = this.state.users_duration.map(user => {
      if(user?.end_date) {
        return user.end_date
      }
    }).filter(function( element ) {
      return element !== undefined;
   }).map(date => moment(date));
   let maxEndDate = moment.max(endDates).format("YYYY-MM-DD");
     this.setState({
       start_on: minStartDate,
       due_on: maxEndDate,
       continuous: moment(maxEndDate).diff(moment(minStartDate), "year") >= 5 ? true : false
      })
    })
  }

  onChangeCompletedDate = (_event, picker, indexToUpdate) => {
    this.setState({
      completed_durations: {
        durations: this.state.completed_durations.durations.map((duration, index) => {
          if(indexToUpdate === index) {
            return {
              ...duration,
              start_date: moment(picker.startDate._d).format(),
              end_date: moment(picker.endDate._d).format(),
            }
          }
          return duration;
        })
      }
    })
  } 

  onOptionIconClick = (userId) => {
    if(this.state.showMoreOption && this.state.showMoreOption === userId) {
      this.setState({ showMoreOption: null });
    } else {
      this.setState({ showMoreOption: userId });
    }
  }

  onOptionOutside = event => {
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        showMoreOption: null
      });
    }
  };

  onSelectOption = userDataToSet => {
    this.setState({ users_duration: this.state.users_duration.map(user => {
      return {
        ...user,
        start_date: userDataToSet.start_date || "",
        end_date: userDataToSet.end_date || "",
        continuous: userDataToSet.continuous || false,
        hrs: userDataToSet.hrs || 0,
        mins: userDataToSet.mins || 0
      }
    }) 
  }, () => {
      let startDates = this.state.users_duration.map(user => {
        if(user?.start_date) {
          return user.start_date
        }
      }).filter(function( element ) {
        return element !== undefined;
     }).map(date => moment(date));
     let minStartDate = moment.min(startDates).format("YYYY-MM-DD");

     let endDates = this.state.users_duration.map(user => {
      if(user?.end_date) {
        return user.end_date
      }
    }).filter(function( element ) {
      return element !== undefined;
   }).map(date => moment(date));
   let maxEndDate = moment.max(endDates).format("YYYY-MM-DD");
     this.setState({
       start_on: minStartDate,
       due_on: maxEndDate,
       continuous: moment(maxEndDate).diff(moment(minStartDate), "year") >= 5 ? true : false
      })
    })
  }

  onEditFieldChangeStart = selectedDay => {
    if (this.state.isEdit) {
      if (moment(selectedDay).isSameOrBefore(this.state.due_on))
        this.setState({
          start_on: moment(selectedDay).toISOString()
        });
      else
        this.setState({
          start_on: moment(selectedDay).toISOString(),
          due_on: ""
        });
    } else
      this.setState({
        start_on: moment(selectedDay).toISOString(),
        due_on: moment(selectedDay).toISOString()
      });
  };

  onEditFieldChangeEnd = selectedDay => {
    this.setState({
      due_on: moment(selectedDay).toISOString()
    });
  };

  onNewActivitySubmit = e => {
    e.preventDefault();
    const {
      loggedInUser,
      // addActivityFormDetails,
      createUpcomingActivity,
      fromPriority,
      totalActivityCount,
      source,
      searchContent,
      createCompletedActivity,
      addTimer,
      workspaceUser,
      selectedWorkspace,
      createActivity,
      addBacklogActivities
    } = this.props;
    const {
      activityTitle,
      selectedCompletedActivity,
      activityOverview,
      description,
      userDetails: { users },
      users_duration,
      completed_durations,
      // attachmentsToAdd,
      selectedProject,
      isCompleted,
      start_on: activityStartDate,
      due_on: activityDueDate,
      activityTagFieldValue,
      continuous
    } = this.state;
    const totalTimeSeconds = this.getTotalActivityTime("FROM_SUBMIT");

    const start_on = moment(activityStartDate).format();
    const due_on = moment(activityDueDate).format();
    const created_by = parseInt(loggedInUser.id);
    const duration = continuous ? 0 : totalTimeSeconds;
    const hours = secondsToHms(totalTimeSeconds).hours;
    const minutes = secondsToHms(totalTimeSeconds).mins;
    const project_id = selectedProject.id;
    const user_id = users.map(user => parseInt(user.id));
    const workflow = { id: 1, name: "Backlog" };
    const tags = isCompleted ? [activityTagFieldValue] : [];
    const data = {
      checklists: [],
      commentDetails: { isReply: false },
      archivedUsers: [],
      priority: [],
      owner: created_by
    };

    let users_duration_to_send = [];
    let completed_durations_to_send = {
      durations: []
    };
    if(isCompleted) {
      completed_durations.durations.forEach(duration => {
        completed_durations_to_send.durations.push({
          start_date: duration.start_date,
          end_date: duration.end_date,
          time_per_day: (duration.hrs || 0) * 3600 + (duration.mins || 0) * 60
        })
      })
    } else {
      users_duration_to_send = users_duration.map(user => {
        if(user.continuous) {
          return {
            start_date: user.start_date,
            continuous: user.continuous,
            time_per_day: (user.hrs || 0) * 3600 + (user.mins || 0) * 60,
            user_id: user.user_id,
            user_img: user.user_img,
            user_first_name: user.user_first_name,
            user_last_name: user.user_last_name
          }
        } else {
          return {
            start_date: user.start_date,
            end_date: user.end_date,
            continuous: user.continuous,
            time_per_day: (user.hrs || 0) * 3600 + (user.mins || 0) * 60,
            total_time: ((user.hrs || 0) * 3600 + (user.mins || 0) * 60)
                * (moment(user.end_date).diff(moment(user.start_date), "day") + 1),
            user_id: user.user_id,
            user_img: user.user_img,
            user_first_name: user.user_first_name,
            user_last_name: user.user_last_name
          }
        }
      });
    }

    this.setState({ fetching: true });

    getProjectById(selectedProject.archived, selectedProject.id, response => {
      const updatedProjectBody = getUpdatedProjectToAddPeople(
        response?.length > 0 ? response[0] : selectedProject,
        workspaceUser,
        user_id,
        selectedWorkspace.id,
        selectedWorkspace
      );

    if (source === workloadConstant.fromWorkload) {
      return createActivity(
        {
          // created_at,
          created_by,
          due_on,
          duration,
          hours,
          minutes,
          project_id,
          start_on,
          tags,
          title: activityTitle,
          description: activityOverview,
          user_id,
          data,
          workflow: { id: 1, name: "Backlog" },
          users_duration: users_duration_to_send,
          continuous_activity: continuous
        },
        (response) => {
          if (user_id.length > 0) {
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
          }else{
            addBacklogActivities(response,response.project.id);
            toast("Activity added to Activity Backlog in Workload!");
          }
          this.props.onCloseCallBack();
        }
      );
    }

    if (source === "FROM_KANBAN") {
      return createActivity(
        {
          // created_at,
          created_by,
          due_on,
          duration,
          hours,
          minutes,
          project_id,
          start_on,
          tags,
          title: activityTitle,
          description: activityOverview,
          user_id,
          data,
          workflow: this.props.callWorkflow(),
          users_duration: users_duration_to_send,
          continuous_activity: continuous
        },
        () => {
          this.props.onCloseCallBack();
        }
      );
    }

    if ((totalActivityCount < 3 || fromPriority) && !isCompleted) {
      const priority = [
        {
          user_id: parseInt(loggedInUser.id),
          date: moment(new Date()).format()
        }
      ];
      return createUpcomingActivity(
        {
          activity_type: "ACTIVITY",
          // created_at,
          data: { ...data, priority },
          created_by,
          due_on,
          duration,
          is_active: true,
          platform: "WEB",
          hours,
          minutes,
          project_id,
          start_on,
          tags,
          title: activityTitle,
          description: activityOverview,
          user_id,
          workflow,
          users_duration: users_duration_to_send,
          continuous_activity: continuous
        },
        response => {
          if (response && response.id) {
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
            toast("Activity added successfully!");
            this.props.onCloseCallBack();
          }
        }
      );
    } else if (isCompleted) {
      const totalSecondsSpent = completed_durations_to_send.durations.reduce((total, duration) => {
        total = total + duration.time_per_day;
        return total;
      }, 0);
      // Multipart/form-data attachments start
    const { attachmentsFile } = this.state;
    let formData;
    if(attachmentsFile.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else
          formData.append(`attachment${index + 1}`, JSON.stringify(file));
      })
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "completed_activity");
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end
    // Add TimeSheet against existing activity
    if(selectedCompletedActivity?.id) {
      return addTimer(
        {
          activity_id: selectedCompletedActivity.id,
          description,
          time_taken: totalSecondsSpent,
          date: moment(new Date()).format(),
          user_id: user_id[0],
          tags,
          completed_durations: completed_durations_to_send,
          customer_auth_token: localStorage.getItem("customer_auth_token")
        }, formData, (response, attRes) => {
          if (response 
            // && response.id
            ) {
            if (this.props.fromReport) {
              const {
                projectId,
                memberId,
                from,
                to,
                activityType
              } = this.props.reportFilters;
              let queryParam = getQueryParamsForTimeSheet(
                projectId,
                memberId,
                from,
                to,
                activityType
              );

              if(searchContent) {
                queryParam = `${queryParam}&like=${searchContent}`
              }

              this.props.getTimeSheetsAction(
                {
                  auth_token: localStorage.getItem("customer_auth_token"),
                  queryParam
                },
                response => {
                  if(searchContent) {
                    clearTimeout(this.highlightTimer);
                    var context = [
                      ...document.querySelectorAll(".td_title"),
                      ...document.querySelectorAll(".td_des")
                    ];
                    var instance = new Mark(context);
                    instance.unmark();
                    this.highlightTimer = setTimeout(() => {
                      var updatedContext = [
                        ...document.querySelectorAll(".td_title"),
                        ...document.querySelectorAll(".td_des")
                      ];
                      var updatedInstance = new Mark(updatedContext);
                      updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
                    }, 100);
                  }
                }
              );
            }
            if(attRes?.stdErr) this.setState({ showAttachmentErrPop: true });
            else this.props.onCloseCallBack();
            this.props.fetchTimesheetAction(
              {
                from_date: moment()
                  .startOf("day")
                  .format("YYYY-MM-DD"),
                to_date: moment().format("YYYY-MM-DD"),
                user_id: loggedInUser.id
              });
            toast("Activity added successfully!");
          }
        }
      )
    }
      return createCompletedActivity(
        {
          activity_type: "COMPLETED",
          // created_at,
          // updated_on: created_at,
          created_by,
          due_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
          duration: totalSecondsSpent,
          hours: secondsToHms(totalSecondsSpent).hours,
          minutes: secondsToHms(totalSecondsSpent).mins,
          is_active: false,
          platform: "WEB",
          project_id,
          data: {
            ...data,
            archivedUsers: user_id
          },
          description,
          completed_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
          // attachments: attachmentsToAdd,
          user_id,
          tags,
          title: activityTitle,
          completed_durations: completed_durations_to_send
        }, formData, (response, attRes) => {
          if (response && response.id) {
            if (this.props.fromReport) {
              const {
                projectId,
                memberId,
                from,
                to,
                activityType
              } = this.props.reportFilters;
              let queryParam = getQueryParamsForTimeSheet(
                projectId,
                memberId,
                from,
                to,
                activityType
              );

              if(searchContent) {
                queryParam = `${queryParam}&like=${searchContent}`
              }

              this.props.getTimeSheetsAction(
                {
                  auth_token: localStorage.getItem("customer_auth_token"),
                  queryParam
                },
                response => {
                  if(searchContent) {
                    clearTimeout(this.highlightTimer);
                    var context = [
                      ...document.querySelectorAll(".td_title"),
                      ...document.querySelectorAll(".td_des")
                    ];
                    var instance = new Mark(context);
                    instance.unmark();
                    this.highlightTimer = setTimeout(() => {
                      var updatedContext = [
                        ...document.querySelectorAll(".td_title"),
                        ...document.querySelectorAll(".td_des")
                      ];
                      var updatedInstance = new Mark(updatedContext);
                      updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
                    }, 100);
                  }
                }
              );
            }
            if(attRes?.stdErr) this.setState({ showAttachmentErrPop: true });
            else this.props.onCloseCallBack();
            this.props.fetchTimesheetAction(
              {
                from_date: moment()
                  .startOf("day")
                  .format("YYYY-MM-DD"),
                to_date: moment().format("YYYY-MM-DD"),
                user_id: loggedInUser.id
              });
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
            toast("Activity added successfully!");
          }
        }
      )
    } else {
      return createUpcomingActivity(
        {
          activity_type: "ACTIVITY",
          // created_at,
          data,
          created_by,
          due_on,
          duration,
          is_active: true,
          platform: "WEB",
          hours,
          minutes,
          project_id,
          start_on,
          tags,
          title: activityTitle,
          description: activityOverview,
          user_id,
          workflow,
          users_duration: users_duration_to_send,
          continuous_activity: continuous
        },
        response => {
          if (response && response.id) {
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
            toast("Activity added successfully!");
            this.props.onCloseCallBack();
          }
        }
      );
    }
  });
  }

  onNewActivityEdit = e => {
    e.preventDefault();
    const {
      selectedActivity,
      updateActivity,
      fromNotification,
      updateActivityState,
      // updateAllActivity,
      loggedInUser,
      addActivityToReducer,
      router,
      source,
      updateArchiveActivity,
      updateWorkloadActivity,
      workspaceUser,
      selectedWorkspace,
      addBacklogActivities,
      removeBacklogActivities
    } = this.props;
    const {
      // userDetails,
      users_duration,
      continuous
    } = this.state;
    const totalTimeSeconds = this.getTotalActivityTime("FROM_SUBMIT");
    const id = this.state.id;
    const newTitle = this.state.activityTitle;
    const project_id = this.state.selectedProject.id;
    const start_on = moment(this.state.start_on).format();
    const due_on = moment(this.state.due_on).format();
    const hours = secondsToHms(totalTimeSeconds).hours;
    const minutes = secondsToHms(totalTimeSeconds).mins;
    const duration = this.state.continuous ? 0 : totalTimeSeconds;
    const user_id = this.state.userDetails.users.map(user => parseInt(user.id));
    const project = {
      id: this.state.selectedProject.id,
      name: this.state.selectedProject.name,
      img: this.state.selectedProject.img,
      workflow: this.state.selectedProject.workflow
    };
    const version_id = parseInt(selectedActivity.version_id) + 1;
    const data = selectedActivity.data;
    data.lastModifiedBy = parseInt(localStorage.getItem("id"));
    data.priority = data && data.priority ?
      data.priority.filter(member => user_id.includes(parseInt(member.user_id))) : [];
    const is_priority =
      selectedActivity.data.priority &&
      selectedActivity.data.priority.filter(
        user => user.user_id === parseInt(localStorage.getItem("id"))
      ).length > 0
        ? true
        : false;
    
    let users_duration_to_send = users_duration.map(user => {
      if(user.continuous) {
        return {
          start_date: user.start_date,
          continuous: user.continuous,
          time_per_day: (user.hrs || 0) * 3600 + (user.mins || 0) * 60,
          user_id: user.user_id,
          user_img: user.user_img,
          user_first_name: user.user_first_name,
          user_last_name: user.user_last_name
        }
      } else {
        return {
          start_date: user.start_date,
          end_date: user.end_date,
          continuous: user.continuous,
          time_per_day: (user.hrs || 0) * 3600 + (user.mins || 0) * 60,
          total_time: ((user.hrs || 0) * 3600 + (user.mins || 0) * 60)
              * (moment(user.end_date).diff(moment(user.start_date), "day") + 1),
          user_id: user.user_id,
          user_img: user.user_img,
          user_first_name: user.user_first_name,
          user_last_name: user.user_last_name
        }
      }
    });

    this.setState({ fetching: true });

    getProjectById(this.state.selectedProject.archived, this.state.selectedProject.id, response => {
      const updatedProjectBody = getUpdatedProjectToAddPeople(
        response?.length > 0 ? response[0] : this.state.selectedProject,
        workspaceUser,
        user_id,
        selectedWorkspace.id,
        selectedWorkspace
        );

    

    
      if (source === "FROM_ARCHIVE") {
        return updateArchiveActivity(
          {
            id: id,
            title: newTitle,
            description: this.state.activityOverview || "",
            project_id,
            tags: selectedActivity.tags || [],
            start_on,
            due_on,
            hours,
            minutes,
            duration,
            project,
            user_id,
            version_id,
            data,
            is_archived: true,
            users_duration: users_duration_to_send,
            users_not_in_workspace: selectedActivity.users_not_in_workspace,
            continuous_activity: continuous
          },
          response => {
            this.props.updateProjectAction(
              { queryParam: "", body: updatedProjectBody });
            this.props.onCloseCallBack();
          }
        );
      }

      if (source === workloadConstant.fromWorkload) {
        return updateWorkloadActivity(
          {
            activityObj: selectedActivity,
            user_id,
            activity_id: id,
            fields: {
              title: newTitle,
              description: this.state.activityOverview || "",
              project_id,
              tags: selectedActivity.tags || [],
              start_on,
              due_on,
              hours,
              minutes,
              user_id,
              duration,
              project,
              version_id,
              data,
              is_priority,
              users_duration: users_duration_to_send,
              continuous_activity: continuous
            }
          },
          response => {
           
            if (user_id.length > 0) {
              this.props.updateProjectAction(
                { queryParam: "", body: updatedProjectBody });
              if(selectedActivity.backFlowFlag){
                removeBacklogActivities(selectedActivity.id,selectedActivity.project.id);
              }
            }else{
              let updatedActivity=_.cloneDeep(selectedActivity);
              if(parseInt(project_id)!== parseInt(selectedActivity.project.id)){
                removeBacklogActivities(selectedActivity.id,selectedActivity.project.id);
              }
              updatedActivity.title=newTitle;
              updatedActivity.description=this.state.activityOverview || "";
              updatedActivity.project_id=project_id;
              updatedActivity.tags=selectedActivity.tags || [];
              updatedActivity.start_on=start_on;
              updatedActivity.due_on=due_on;
              updatedActivity.project.id=project_id;
              updatedActivity.duration=duration;
              updatedActivity.version_id=version_id;
              updatedActivity.is_priority=is_priority;
              updatedActivity.data=data;
              updatedActivity.project.name=this.state.selectedProject.name;
              updatedActivity.project.img=this.state.selectedProject.img;
              updatedActivity.project.workflow=this.state.selectedProject.workflow;
              updatedActivity.users_duration={};
  
              addBacklogActivities(updatedActivity,updatedActivity.project.id);
            }
          
            this.props.onCloseCallBack();
          }
        );
      }

        updateActivity(
          {
            id: id,
            title: newTitle,
            description: this.state.activityOverview || "",
            project_id,
            tags: selectedActivity.tags || [],
            start_on,
            due_on,
            hours,
            minutes,
            duration,
            project,
            user_id,
            version_id,
            data,
            is_priority,
            users_duration: users_duration_to_send,
            users_not_in_workspace: selectedActivity.users_not_in_workspace,
            continuous_activity: continuous,
            is_locked: selectedActivity.is_locked
          },
          response => {
            if(response.id) {
              firebase.analytics().logEvent("GTD_Activity_edited");
              this.props.updateProjectAction(
                { queryParam: "", body: updatedProjectBody });
              if(fromNotification) {
                updateActivityState({
                  ...selectedActivity,
                  id: id,
                  title: newTitle,
                  description: this.state.activityOverview || "",
                  project_id,
                  tags: selectedActivity.tags || [],
                  start_on,
                  due_on,
                  hours,
                  minutes,
                  duration,
                  project,
                  user_id,
                  version_id,
                  data,
                  is_priority,
                  users_duration: users_duration_to_send,
                  continuous_activity: continuous
                });
                if(user_id.includes(parseInt(loggedInUser.id)) &&
                  !selectedActivity.user_id.includes(parseInt(loggedInUser.id)) &&
                  router.location.pathname === routesHelper.getActivityPath()) {
                  addActivityToReducer({
                    ...selectedActivity,
                    id: id,
                    title: newTitle,
                    description: this.state.activityOverview || "",
                    project_id,
                    tags: selectedActivity.tags || [],
                    start_on,
                    due_on,
                    hours,
                    minutes,
                    duration,
                    project,
                    user_id,
                    version_id,
                    data,
                    is_priority,
                    users_duration: users_duration_to_send,
                    continuous_activity: continuous
                  })
                }
              }

              this.props.onCloseCallBack();
            }
          }
        );
    });
  }

  OutsideClickHandlerMethod = e => {
    var parent = getClosest(e.target, ".DayPickerInput");
    var parentChecklist = getClosest(e.target, ".checkbox-input");
    if (!_.isNull(parent) || !_.isNull(parentChecklist)) {
      return;
    }
    if (!_.isUndefined(e.target)) {
      if (
        e.target.classList.contains("cus-autocomp-ddown-list") ||
        e.target.classList.contains("cus-autocomp-control") ||
        e.target.classList.contains("add-act-project-search") ||
        e.target.classList.contains("add-client-acomp") ||
        e.target.classList.contains("add-option") ||
        e.target.classList.contains("checkbox-activity") ||
        e.target.classList.contains("type-li") ||
        e.target.classList.contains("type-li-span") ||
        e.target.classList.contains("tick-symbol") ||
        e.target.classList.contains("edit-board-search-control") ||
        e.target.classList.contains("add-multi-member-bottom-holder") ||
        e.target.classList.contains("add-multi-member-confirm")
      ) {
        return;
      }
    }

    if (this.state.projectFieldValue && !this.state.selectedProject) {
      this.setState({
        projectFieldValue: "",
        filteredProjectList: this.props.projectList
      });
    } else if (this.state.selectedProject) {
      this.setState({ projectFieldValue: this.state.selectedProject.name });
    }

    if (this.state.activityTagFieldValue.length > 0) {
      this.setState({
        selectedTags: [this.state.activityTagFieldValue],
        // activityTagFieldValue: "",
        showTypeDropDownUI: false
      });
    }

    const existingAddedUserId = this.state.userDetails.users.map(user =>
      parseInt(user.id)
    );

    this.setState({
      showProjectDropDown: false,
      showTypeDropDownUI: false,
      showActivityDropdown: false,
      showSearchBox: false,
      userDetails: {
        ...this.state.userDetails,
        selectedUser: []
      },
      filteredUser: this.props.workspaceUser.filter(
        user => !existingAddedUserId.includes(parseInt(user.id))
      )
    });
  };

  getTotalActivityTime = (fromSubmit=null) => {
    if(this.state.start_on &&
      this.state.due_on &&
      this.state.continuous) {
        return "Continuous";
      }
    if(this.state.start_on &&
        this.state.due_on &&
        !this.state.continuous) {
      let totalSeconds = this.state.users_duration.reduce((total, user) => {
        if(user?.start_date &&
          user?.end_date &&
          (user?.hrs || user?.mins)
          ) {
            let { hours, mins } = getTotalHrsMins(
              moment(user.end_date).diff(moment(user.start_date), "days") + 1,
              user?.hrs || 0,
              user?.mins || 0)
          return total + 
            (
              hours * 3600 + 
              mins * 60
            )
        }
      }, 0);
      if(totalSeconds) {
        if(fromSubmit) return totalSeconds;
        const { hours, mins } = secondsToHms(totalSeconds);
        return `${hours}h ${mins}m`
      }
    }
  }

  isImage = (url) => {
    if(url.indexOf(";base64") > -1) {
      return url.indexOf("image") > -1;
    } else {
      return false;
    }
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  onRemoveAttachment = (attachment) => {
    this.setState({ attachmentsToAdd: this.state.attachmentsToAdd.filter(att => {
      if(att.src && attachment.src)
        return att.src !== attachment.src
      else if(att.url && attachment.url)
        return att.url !== attachment.url
      else if(att.size && att.name) 
        return (att.size !== attachment.size && att.name !== attachment.name)
      else return true
    }
      ),
      attachmentsFile: this.state.attachmentsFile.filter(file => {
        if(file.size && file.name) 
          return (file.size !== attachment.size && file.name !== attachment.name)
        else if(attachment.url && file.url)
          return file.url !== attachment.url
        else return true;
      })
    });
  }

  updateAttchmetnOneDrive=(flag)=>{
    this.setState({attachmentOneDrive:flag})
  }

  onClickImg = (url) => {
    this.setState({ clickedImg: url });
    document
      .querySelector('[role="dialog"]')
      .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    document
      .querySelector('[role="dialog"]')
      .removeAttribute("id", "noscrolldialog");
  };

  onCloseAttachmentErrPop = () => {
    this.props.onCloseCallBack();
    this.setState({ showAttachmentErrPop: false });
  }

  render() {
    const {
      showOverview,
      showSearchBox,
      // selectedTags,
      filteredProjectList,
      showActivityDropdown,
      activitiesForDropdown,
      showTypeDropDownUI,
      filteredUser,
      userDetails,
      users_duration,
      completed_durations,
      showProjectDropDown,
      selectedProject,
      projectFieldValue,
      isCompleted,
      // hours,
      // minutes,
      isEdit,
      activityTitle,
      activityTagFieldValue,
      userToRemoveId,
      showCannotRemovePopup,
      showAttachmentErrPop,
      start_on,
      due_on,
      clickedImg,
      fetchingActivities
    } = this.state;
    const {
      // addActivityFormDetails,
      selectedActivity,
      // listOfCustomer,
      loggedInUser,
      // projectList,
      selectedWorkspace,
      workspaceUser,
      classes,
      source
    } = this.props;
    const loggedInUserId = localStorage.getItem("id");
    const isSuperAdmin = getIsSuperAdmin(loggedInUser, selectedWorkspace);
    const isUserPartOfCard =
      selectedActivity &&
      selectedActivity.user_id.includes(parseInt(loggedInUser.id));
    const tags =
      this.state.selectedProject &&
      this.state.selectedProject.data &&
      this.state.selectedProject.data.settings &&
      _.sortBy(this.state.selectedProject.data.settings.activity_type);

    const renderedProjectList = () => {
      if (this.state.projectFieldValue.length > 0) {
        return filteredProjectList &&
          filteredProjectList.map(project => {
            return (
              <div
              className="cus-autocomp-ddown-list add-act-proj-ddown"
              key={project.id}
              onClick={() => this.onProjectOptionClicked(project)}
            >
              {(_.isNull(project.img) || _.isEmpty(project.img)) === true ? (
                  <div className="img-container">
                    <Avatar
                      style={{
                      width: "100%",
                      height: "100%"
                      }}
                      name={project.name}
                      maxInitials={1}
                      ></Avatar>
                  </div>
                  ) : (
                    <div className="img-container">
                      <img src={project.img} alt="proj-img" />
                    </div>
                )}
              {project.name}
            </div>
            );
          });
      }
      return filteredProjectList &&
        filteredProjectList.map(project => {
          return (
            <div
              className="cus-autocomp-ddown-list add-act-proj-ddown"
              key={project.id}
              onClick={() => this.onProjectOptionClicked(project)}
            >
              {(_.isNull(project.img) || _.isEmpty(project.img)) === true ? (
                  <div className="img-container">
                    <Avatar
                      style={{
                      width: "100%",
                      height: "100%"
                      }}
                      name={project.name}
                      maxInitials={1}
                      ></Avatar>
                  </div>
                  ) : (
                    <div className="img-container">
                      <img src={project.img} alt="proj-img" />
                    </div>
                )}
              {project.name}
            </div>
          );
        });
    };

    const renderedUserSuggestionList = _.sortBy(
      filteredUser,
      [wpUser => wpUser.first_name.toLowerCase()],
      ["asc"]
    ).map(element => {
      return (
        <div
          className="search-data-list"
          key={`${element.first_name}${element.id}`}
        >
          <Control.text
            type="checkbox"
            model=".add_member"
            className="tick-check"
            id={`${element.first_name}${element.id}`}
            onChange={e => this.addUserToSelectedList(e, element)}
            validators={{
              required: val => val && val.length
            }}
          />
          <label
            className="tick-symbol"
            htmlFor={`${element.first_name}${element.id}`}
            role="button"
          ></label>
          <div className="user-img-icon">
            {(_.isNull(element.img) || _.isEmpty(element.img)) === true ||
            element.img ===
              "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
            (element.img &&
              element.img.trim() ===
                "https://s3.amazonaws.com/gtd/users/default.png") ? (
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={element.first_name}
                maxInitials={1}
              ></Avatar>
            ) : (
              <img src={element.img} alt="User" />
            )}
          </div>
          <div className="user-details-holder">
            {element.first_name.length > 22 ? (
              <Tooltip title={element.first_name}>
                <div className="user-name">{element.first_name}</div>
              </Tooltip>
            ) : (
              <div className="user-name">{element.first_name}</div>
            )}
            {element.email.length > 22 ? (
              <Tooltip title={element.email}>
                <div className="user-email">{element.email}</div>
              </Tooltip>
            ) : (
              <div className="user-email">{element.email} </div>
            )}
          </div>
        </div>
      );
    });

    let activityOwner;

    if(isEdit && selectedActivity) {
      activityOwner = workspaceUser.filter(user => user.id === (selectedActivity.data?.owner || selectedActivity.created_by))[0];
    } else activityOwner = loggedInUser;

    const sortedUserArray = isCompleted ? workspaceUser.filter(user => parseInt(user.id) === parseInt(loggedInUserId)) : userDetails.users;

    const renderUserAvatar =
      selectedWorkspace &&
      _.sortBy(sortedUserArray, (user) => user.first_name.toLowerCase()).map(user => {
        return (
          <OutsideClickHandler onOutsideClick={() => this.onRemovePopupOutsideClick(user.id)}>
            <div
              className={
                activityOwner && parseInt(user.id) === parseInt(activityOwner.id)
                  ? "user-img-holder workspace-owner"
                  : "user-img-holder add-activity-avatar"
              }
            >
              <Tooltip
                title={`${user.first_name}${selectedActivity?.users_not_in_workspace?.indexOf(user) > -1 ? " - Currently not a member of this workspace" : ""}`}
                key={user.id}
              >
              <div
                className={`user-inner-holder${selectedActivity?.users_not_in_workspace?.indexOf(user) > -1 ? " not-wp-user" : ""}`}
                // style={{ width: "35px", height: "35px" }} 
              >
              {(_.isNull(user.img) || _.isEmpty(user.img)) === true ||
              user.img === "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
              (user.img &&
                user.img.trim() ===
                  "https://s3.amazonaws.com/gtd/users/default.png") ? (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  name={user.first_name}
                  maxInitials={1}
                ></Avatar>
              ) : (
                <img src={user.img} alt="user-img" />
              )}
              {((isEdit && activityOwner && (isSuperAdmin || 
                  (parseInt(loggedInUser.id) === (parseInt(activityOwner.id))) || 
                  isUserPartOfCard && (parseInt(activityOwner.id) !== parseInt(user.id))))
                  ||
                  (isEdit && !activityOwner)
                  ||
                  (activityOwner && !isEdit 
                    // && parseInt(activityOwner.id) !== parseInt(user.id) // commented to allow user to remove owner while add activity
                  )) && !isCompleted &&
                <div className="user-img-delete">
                <img
                  src={delete_icon}
                  alt="Delete Icon"
                  onClick={e => {
                    if (isEdit && selectedActivity.user_id.includes(user.id)) this.onShowUserRemovePopup(user.id)
                    else this.onRemoveUserFromUserList(e, user)
                  }}
                />
              </div>}
              </div>
          </Tooltip>
          {userToRemoveId && userToRemoveId === user.id &&
                  <div className="Edit-board-ddown rm-user-act edit">
                          <div className="confirmation-box-remove">
                            <p className="user-name">
                            Remove{" "}
                            {parseInt(user.id) === parseInt(loggedInUser.id) ?
                            "yourself" : user.first_name } from this Activity
                            </p>
                            <hr className="divider-remove-box"></hr>
                            <p className="messageremove-box">
                            Are you sure you want to remove{" "}
                            {parseInt(user.id) === parseInt(loggedInUser.id) ?
                            "yourself" : user.first_name } from this activity?
                            </p>
                            <div className="button-box">
                              <button
                                className="cancel-button-remove-box"
                                onClick={() => this.onRemovePopupOutsideClick(user.id)}
                              >
                                Cancel
                              </button>
                              <button
                                className="confirm-button-remove-box"
                                onClick={e =>
                                  this.onRemoveUserEditMode(
                                    e,
                                    user,
                                    selectedActivity
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>}
            </div>
          </OutsideClickHandler>
        );
      });
    const renderActivityTypes =
      tags &&
      tags
        .filter(
          tag =>
            tag.toLowerCase().indexOf(activityTagFieldValue.toLowerCase()) > -1
        )
        .map(tag => {
          return (
            <li
              key={`${tag}`}
              className="type-li"
              onClick={() => this.onTypeOptionSelected(tag)}
            >
              <span class="type-li-span">{tag}</span>
            </li>
          );
        });

        const renderDropdownActivities =
        activitiesForDropdown &&
        activitiesForDropdown
          .filter(
            activity =>
              activity.name.toLowerCase().indexOf(activityTitle.toLowerCase()) > -1
          )
          .filter(activity => {
            if(selectedProject) {
              return activity.project.id === selectedProject.id
            } else
              return true;
          })
          .map(activity => {
            return (
              <li
                key={`${activity.id}`}
                className="type-li"
                onClick={() => this.onActivityOptionSelected(activity)}
              >
                <span className="type-li-span">{activity.name}</span>
              </li>
            );
          });


        let dialogPropsCannotRemove = {
          className: "dialog_wrapper",
          showPopup: showCannotRemovePopup,
          dialogTitle: () => `${parseInt(showCannotRemovePopup.id) === parseInt(loggedInUser.id) ?
            "You" : showCannotRemovePopup.first_name } could not be removed`,
          // onCloseCallBack: this.onCloseGooglePopup,
          shouldButtonEnable: true
        };

        let dialogPropsAttErr = {
          className: "dialog_wrapper",
          showPopup: showAttachmentErrPop,
          dialogTitle: () => `Attachment Error`,
            // onCloseCallBack: this.onCloseGooglePopup,
            shouldButtonEnable: true
        };
    return (
      <div className="create_activity_wrapper">
        {fetchingActivities && (
              <div className="nw-att-loading-holder">
                <Loader size={100} loading={fetchingActivities} />
              </div>
            )}
        {/* <Form model="rrfState.addActivity"> */}
          <div className="re-controls-container edit-board-look">
          {source !== workloadConstant.fromWorkload && !isEdit && source !== "FROM_KANBAN" && (
              <div
                className={
                  isEdit || source === "FROM_KANBAN" || userDetails.users.length === 0
                    ? "re-control-holder for-checkbox edit"
                    : "re-control-holder for-checkbox"
                }
              >
                <div className="checkbox-activity">
                  <div className="checkbox-input">
                  <Checkbox
                          checked={this.state.isCompleted}
                          id="isCompleted"
                          label="Is this activity already completed?"
                          color="primary"
                          className={
                            this.state.isCompleted
                              ? "checkBoxInput-parentSpan checked"
                              : "checkBoxInput-parentSpan"
                          }
                          onChange={this.onIsCompletedChecked}
                          disabled={
                            isEdit || source === "FROM_KANBAN" || userDetails.users.length === 0 ? true : false
                          }
                        />
                  </div>
                  <div>
                    <span
                      className={
                        isEdit || source === "FROM_KANBAN" || userDetails.users.length === 0
                          ? "labelStyles edit"
                          : "labelStyles"
                      }
                      onClick={
                        isEdit || source === "FROM_KANBAN" || userDetails.users.length === 0 ? () => { /* TODO document why this arrow function is empty */ } : this.onIsCompletedCheckedLabel
                      }
                    >
                      {/* Is this activity already completed? */}
                      Add a completed activity
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="re-control-holder first-act-title">
            <TextField
              id="outlined-name"
              autoComplete="off"
              autoFocus
              label="Activity Title"
              className="description-box activity-title"
              placeholder="Enter a title for this Activity"
              variant="outlined"
              inputRef={this.actTitleRef}
              InputProps={{
                className: classes.input
              }}
              InputLabelProps={{
                className: classes.label
              }}
              color="primary"
              model=".title"
              value={this.state.activityTitle}
              onClick={isCompleted ? this.onActivityFocus : undefined}
              onChange={this.onTitleChange}
              size="small"
              fullWidth={true}
              onFocus={() => {
                document.getElementsByClassName("activity-title")[0].classList.add("textfield-focused");
              }}
              onBlur={() => {
                document.getElementsByClassName("activity-title")[0].classList.remove("textfield-focused");
              }}
            />
            {isCompleted && activityTitle && <div className="act-title-clr-btn" onClick={this.onClearActivityField} >
              <img src={CloseIcon} alt="close" />
            </div>}
            {showActivityDropdown && (
              <div className="act-title-ddown-holder">
                    <OutsideClickHandler onOutsideClick={this.OutsideClickHandlerMethod}>
                    <div className="edit-board-ddown activity-type-down">
                      <div className="activityTagsInput">
                        <ul className="activity-tags-ui">
                          {renderDropdownActivities}
                        </ul>
                      </div>
                    </div>
                    </OutsideClickHandler>
                    </div>
                  )}
            </div>
            {!showOverview && !isCompleted && <div className={`act-overview`} onClick={this.onShowOverview}>
              Activity overview
            </div>}
            {showOverview && !isCompleted && <div className="re-control-holder first">
            <TextField
              id="outlined-name"
              label="Activity Overview"
              placeholder="What is this Activity about?"
              className="description-box completed-description activity-overview"
              variant="outlined"
              model=".title"
              InputProps={{
                className: classes.overviewInput
              }}
              value={this.state.activityOverview}
              onChange={this.onOverviewChange}
              fullWidth={true}
              color="#0090e9"
              multiline
              rows="2"
              size='medium'
              rowsMax="200"
              onFocus={() => {
                document.getElementsByClassName("activity-overview")[0].classList.add("textfield-focused");
              }}
              onBlur={() => {
                document.getElementsByClassName("activity-overview")[0].classList.remove("textfield-focused");
              }}
            />
            </div>}


            {/* $$ -- no clients message for user access starts here -- */}
            {/* <div className="re-control-holder pos-rel">
                            <span className="title-when-nc">
                                Client/Department
                            </span>
                            <span className="replacement-txt no-clients">
                                No clients found
                            </span>
                        </div> */}
            {/* $$ -- no clients message for user access ends here -- */}

            <div className="re-control-holder">
              {/* $$ -- project dropdown control starts here -- */}
              <div className="cus-autocomp-holder">
                {/* <div className="cus-autocomp-title">Project</div> */}
                <div className="cus-autocomp-inner-holder">
                  {/* <div
                    className={`proj-arrow ${showProjectDropDown ? "rotate" : ""}`}
                    onClick={this.onProjectFocus}
                  >
                      <img src={arrow} />
                  </div> */}
                  <TextField
                    type="text"
                    label="Project"
                    placeholder="Select a Project for this Activity"
                    className="description-box activity-project"
                    variant="outlined"
                    inputRef={this.projectFieldRef}
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    value={
                      this.state.selectedProject
                      && this.state.projectFieldValue.length === 0
                        ? // !this.state.onEditProjectField
                          this.state.selectedProject.name
                        : this.state.projectFieldValue
                    }
                    onClick={this.state.selectedCompletedActivity?.id ? undefined : this.onProjectFocus}
                    // className="cus-autocomp-control"
                    autoComplete="off"
                    fullWidth={true}
                    model=".project"
                    onChange={this.onProjectInputChange}
                    innerRef={node => (this.projectNameNode = node)}
                    onFocus={() => {
                      document.getElementsByClassName("activity-project")[0].classList.add("textfield-focused");
                    }}
                    onBlur={() => {
                      document.getElementsByClassName("activity-project")[0].classList.remove("textfield-focused");
                    }}
                    disabled={this.state.selectedCompletedActivity?.id ? true : false}
                  />
                  {isCompleted && (projectFieldValue || selectedProject) && !this.state.selectedCompletedActivity?.id &&
                  <div className="act-title-clr-btn" onClick={this.onClearProjectField} >
                    <img src={CloseIcon} alt="close" />
                  </div>}
                  {/* $$ -- project dropdown container starts here -- */}

                  {showProjectDropDown && filteredProjectList && filteredProjectList.length > 0 && (
                    <OutsideClickHandler
                      onOutsideClick={this.OutsideClickHandlerMethod}
                    >
                      {" "}
                      <div
                        className={`cus-autocomp-ddown-holder`
                        // ${
                        //   // !getIsSuperAdmin(loggedInUser, selectedWorkspace) &&
                        //   filteredProjectList.length === 0 ? "no-display" : ""
                        // }
                      }
                      >
                        {/* <input
                    type="text"
                    className="add-act-project-search"
                    // autoFocus
                    id="projectlist"
                    placeholder="Search"
                    onKeyUp={this.onProjectInputChange}
                  /> */}
                        <div className="cus-autocomp-ddown-inner-holder">
                          {filteredProjectList &&
                            filteredProjectList.length > 0 &&
                            renderedProjectList()}
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}

                  {/* $$ -- project dropdown container ends here -- */}
                </div>
              </div>
              {/* $$ -- project dropdown control ends here -- */}
            </div>

            {/* $$ -- no projects message for user access starts here -- */}
            {/* <div className="re-control-holder pos-rel">
                            <span className="title-when-nc">
                                Project
                            </span>
                            <span className="replacement-txt no-clients">
                                No projects for this client
                            </span>
                        </div> */}
            {/* $$ -- no projects message for user access ends here -- */}

            <div className="re-control-holder">
              <div className="re-member-area edit-activity">
                <div className="add-member-activity ng-binding">
                  Members
                  {/* ({sortedUserArray.length}) */}
                </div>
                <div className="add-member-row inside-activity">

                  {!isCompleted && <OutsideClickHandler
                    onOutsideClick={this.OutsideClickHandlerMethod}
                  >
                    <div className="user-add">
                      <img
                        src={user_add}
                        alt="Add user"
                        onClick={this.onAddUserClicked}
                        role="button"
                      />

                      <div className="edit-board-ddown">
                        {showSearchBox && (
                          <React.Fragment>
                            <FloatingSearchBox
                              source={activities.fromActivity}
                              img={search_field}
                              onInputChange={this.onSearchInitiated}
                              OnInputClicked={this.OnInputClicked}
                            ></FloatingSearchBox>
                            <div className="search-data-container inside-activity">
                              {renderedUserSuggestionList}
                            </div>
                            <div className="add-multi-member-bottom-holder">
                              <div
                                className="add-multi-member-cancel"
                                role="button"
                                tabindex="0"
                                onClick={this.onCancelAddClick}
                              >
                                Cancel
                              </div>
                              <button
                                className="add-multi-member-confirm"
                                disabled={userDetails.selectedUser.length === 0}
                                onClick={this.onAddUserCallBack}
                              >
                                {" "}
                                Confirm
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </OutsideClickHandler>}
                  {renderUserAvatar}
                </div>
              </div>
            </div>
            
            {!isCompleted &&
              <React.Fragment>
                {users_duration.map(user => 
                  <div className="duration-time-holder" key={user.user_id}>
                    <div className="act-duration-user-holder">
                    {(_.isNull(user.user_img) || _.isEmpty(user.user_img)) === true ||
                      user.user_img === "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
                      (user.user_img &&
                        user.user_img.trim() === "https://s3.amazonaws.com/gtd/users/default.png") ? (
                        <Avatar
                          style={{ width: "100%", height: "100%" }}
                          name={user.user_first_name}
                          maxInitials={1}
                        />
                      ) : (
                        <img src={user.user_img} alt="user-img" />
                    )}
                    </div>
                    <div className="add-act-date-range-holder">
                      <DateRangePicker
                        startDate={user?.start_date ? moment(new Date(user.start_date)).format("M/DD/YYYY") : ""}
                        endDate={user?.end_date ? moment(new Date(user.end_date)).format("M/DD/YYYY") : ""}
                        maxDate={moment(new Date()).format("M/DD/YYYY")}
                        onChangeDate={(event, picker) => this.onChangeDate(event, picker, user.user_id)}
                        source={"ADD_ACTIVITY"}
                        isCompleted={false}
                      >
                        <TextField
                          label="Duration"
                          placeholder="Select Start date and Due Date"
                          variant="outlined"
                          className={`description-box date-input act-date-picker-${user.user_id}`}
                          value={user?.start_date && user?.end_date && !user?.continuous ?
                            `${moment(new Date(user.start_date)).format("ddd, DD MMM YY")} - ${moment(new Date(user.end_date)).format("ddd, DD MMM YY")}`
                            : user?.start_date && user?.end_date && user?.continuous
                            ? `${moment(new Date(user.start_date)).format("ddd, DD MMM YY")} - Continuous`
                            : ""
                          }
                          InputProps={{
                            className: classes.dateInput
                          }}
                          InputLabelProps={{
                            className: classes.label
                          }}
                          onFocus={() => {
                            document.getElementsByClassName(`act-date-picker-${user.user_id}`)[0].classList.add("textfield-focused");
                          }}
                          onBlur={() => {
                            document.getElementsByClassName(`act-date-picker-${user.user_id}`)[0].classList.remove("textfield-focused");
                          }}
                        />
                      </DateRangePicker>
                    </div>
                    <div className={`act-time-holder ${userDetails.users.length < 2 ? "one-user" : ""}`}>
                    <div className="rd-tt-time-area">
              <span className="time-planned">Time per day</span>
              <div className="act-time-inner-holder">
              <div className="rd-hours-area">
                <Control.text
                  type="number"
                  model=".hours"
                  placeholder="hh"
                  className="activityTime"
                  value={user.hrs &&
                    parseInt(user.hrs) > 0 ?
                    user.hrs : ""}
                  onChange={(e) => this.onHoursChange(e, user.user_id)}
                  onKeyPress={(e) => {
                    if (
                      e.which === 46 ||
                      e.which === 45 ||
                      e.which === 101 ||
                      e.which === 43
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              :
              <div className="rd-hours-area">
                <Control.text
                  type="number"
                  value={user.mins &&
                    parseInt(user.mins) > 0 ?
                    user.mins : ""}
                  model=".minutes"
                  placeholder="mm"
                  className="activityTime"
                  onChange={(e) => this.onMinutesChange(e, user.user_id)}
                  onKeyPress={(e) => {
                    if (
                      e.which === 46 ||
                      e.which === 45 ||
                      e.which === 101 ||
                      e.which === 43
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              </div>
            </div>
            <div className="total-indiv-time-holder">
              {parseInt(loggedInUser.id) === parseInt(user.user_id) ? "Your " : `${user.user_first_name}'s `} 
              Time -{" "}
              {user.start_date &&
              user.end_date &&
              (user.hrs > 0 || user.mins > 0) &&
              moment(user.end_date)
                .diff(moment(user.start_date), "year") >= 5 && "Continuous"}
              {user.start_date &&
              user.end_date &&
              (user.hrs > 0 || user.mins > 0) &&
              moment(user.end_date)
                .diff(moment(user.start_date), "year") < 5 &&
              `${getTotalHrsMins(moment(user.end_date).diff(moment(user.start_date), "days") + 1,
              user.hrs || 0,
              user.mins || 0).hours}h 
              ${getTotalHrsMins(moment(user.end_date).diff(moment(user.start_date), "days") + 1,
              user.hrs || 0,
              user.mins || 0).mins}m`
              }
            </div>
            </div>
            {userDetails.users.length > 1 &&
            <div className="act-more-op-holder" ref={node => (this.outerDivNode = node)}>
            <div
              className="apc-dot-holder"
              onClick={() => this.onOptionIconClick(user.user_id)}
            >
                  <img src={dotIcon} alt="more-option" />
                  {this.state.showMoreOption && this.state.showMoreOption === user.user_id && (
                    <OutsideClickHandler
                      onOutsideClick={this.onOptionOutside}
                    >
                      <div className="apc-options-block">
                        <div
                          className={`apc-options-list`}
                          onClick={() => this.onSelectOption(user)}
                        >
                          Set this for All
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                </div>}
                  </div>
                )}
              </React.Fragment>
            }
            {/* <TimeField
              props={{
                isCompleted,
                start_on: this.state.start_on,
                due_on: this.state.due_on,
                isEdit: this.state.id ? true : false,
                source,
                addActivityFormDetails,
                hours,
                minutes,
                onEditFieldChangeEnd: this.onEditFieldChangeEnd,
                onEditFieldChangeStart: this.onEditFieldChangeStart,
                onFieldChangeStart: this.onFieldChangeStart,
                onHoursChange: this.onHoursChange,
                onMinutesChange: this.onMinutesChange
              }}
            ></TimeField> */}

            {isCompleted &&
              <React.Fragment>
                {completed_durations.durations.map((_duration, index) => 
                  <div className="duration-time-holder comp-act">
                    <div className="add-act-date-range-holder">
                      <DateRangePicker
                        startDate={completed_durations?.durations?.[index]?.start_date ? moment(new Date(completed_durations.durations[index].start_date)).format("M/DD/YYYY") : ""}
                        endDate={completed_durations?.durations?.[index]?.end_date ? moment(new Date(completed_durations.durations[index].end_date)).format("M/DD/YYYY") : ""}
                        maxDate={moment(new Date()).format("M/DD/YYYY")}
                        onChangeDate={(event, picker) => this.onChangeCompletedDate(event, picker, index)}
                        source={"ADD_ACTIVITY"}
                        isCompleted={true}
                        allowedDays={
                          selectedWorkspace.data.past_timesheet_restricted ?
                          selectedWorkspace.data.allowed_days : null
                        }
                      >
                        <TextField
                          label="Duration"
                          placeholder="Select Start Date and End Date"
                          variant="outlined"
                          className={`description-box date-input act-comp-date-picker-${index}`}
                          value={completed_durations?.durations?.[index]?.start_date && completed_durations?.durations?.[index]?.end_date ?
                            `${moment(new Date(completed_durations.durations[index].start_date)).format("ddd, DD MMM YY")} - ${moment(new Date(completed_durations.durations[index].end_date)).format("ddd, DD MMM YY")}`
                            : ""
                          }
                          InputProps={{
                            className: classes.dateInput
                          }}
                          InputLabelProps={{
                            className: classes.label
                          }}
                          onFocus={() => {
                            document.getElementsByClassName(`act-comp-date-picker-${index}`)[0].classList.add("textfield-focused");
                          }}
                          onBlur={() => {
                            document.getElementsByClassName(`act-comp-date-picker-${index}`)[0].classList.remove("textfield-focused");
                          }}
                        />
                      </DateRangePicker>
                    </div>
                    <div className={`act-time-holder`}>
                    <div className="rd-tt-time-area">
              <div className="time-planned for-completed">
                <div>Time Spent</div>
                <div className="per-user-day">per user - per day</div>
              </div>
              <div className="act-time-inner-holder">
              <div className="rd-hours-area">
                <Control.text
                  type="number"
                  model=".hours"
                  placeholder="hh"
                  className="activityTime"
                  value={completed_durations?.durations?.[index]?.hrs &&
                    parseInt(completed_durations.durations[index].hrs) > 0 ?
                    completed_durations.durations[index].hrs : ""}
                  onChange={(e) => this.onHoursChange(e, index)}
                  onKeyPress={(e) => {
                    if (
                      e.which === 46 ||
                      e.which === 45 ||
                      e.which === 101 ||
                      e.which === 43
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              :
              <div className="rd-hours-area">
                <Control.text
                  type="number"
                  value={completed_durations?.durations?.[index]?.mins &&
                    parseInt(completed_durations.durations[index].mins) > 0 ?
                    completed_durations.durations[index].mins : ""}
                  model=".minutes"
                  placeholder="mm"
                  className="activityTime"
                  onChange={(e) => this.onMinutesChange(e, index)}
                  onKeyPress={(e) => {
                    if (
                      e.which === 46 ||
                      e.which === 45 ||
                      e.which === 101 ||
                      e.which === 43
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              </div>
            </div>
            <div className="total-indiv-time-holder">
              Total Time -{" "}
              {completed_durations?.durations?.[index]?.start_date &&
              completed_durations?.durations?.[index]?.end_date &&
              (completed_durations?.durations?.[index]?.hrs > 0 || completed_durations?.durations?.[index]?.mins > 0) &&
              `${getTotalHrsMins(moment(completed_durations.durations[index].end_date).diff(moment(completed_durations.durations[index].start_date), "days") + 1,
              completed_durations?.durations?.[index]?.hrs || 0,
              completed_durations?.durations?.[index]?.mins || 0).hours}h 
              ${getTotalHrsMins(moment(completed_durations.durations[index].end_date).diff(moment(completed_durations.durations[index].start_date), "days") + 1,
              completed_durations?.durations?.[index]?.hrs || 0,
              completed_durations?.durations?.[index]?.mins || 0).mins}m`
              }
            </div>
            </div>
                  </div>
                )}
              {this.isValidCompletedDuration() && !this.state.selectedCompletedActivity &&
                <div
                  onClick={this.onAddMoreDuration}
                  className="add-more-comp">Add more</div>
              }
              {this.state.selectedProject && <div className="re-control-holder">
              <div className="cus-autocomp-holder">
                <div className="cus-autocomp-inner-holder">
                  <TextField
                    type="text"
                    label="Activity Type"
                    placeholder="Search an Activity Type or add a custom one for this Activity"
                    variant="outlined"
                    className="description-box activity-act-type"
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    value={activityTagFieldValue}
                    onClick={this.showActivityTypeDropdown}
                    // className="cus-autocomp-control"
                    autoComplete="off"
                    fullWidth={true}
                    model=".project"
                    onChange={this.onChangeTypeField}
                    innerRef={node => (this.projectNameNode = node)}
                    onFocus={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.add("textfield-focused");
                    }}
                    onBlur={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.remove("textfield-focused");
                    }}
                  />
                  {showTypeDropDownUI && (
                    <OutsideClickHandler onOutsideClick={this.OutsideClickHandlerMethod}>
                    <div className="edit-board-ddown activity-type-down">
                      <div className="activityTagsInput">
                        <ul className="activity-tags-ui">
                          {renderActivityTypes}
                        </ul>
                      </div>
                    </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            </div>}
            <div className="re-control-holder first">
            <TextField
              id="outlined-name"
              label="Description"
              placeholder="What did you do?"
              variant="outlined"
              model=".title"
              InputProps={{
                className: classes.overviewInput
              }}
              className={`description-box completed-description ${this.state.attachmentsToAdd.length > 0 ? "with-attachment" : ""}`}
              value={this.state.description}
              onChange={this.onDescriptionChange}
              onFocus={this.onFocusDescription}
              onBlur={this.onBlurDescription}
              fullWidth={true}
              color="#0090e9"
              multiline
              rows="2"
              size='medium'
              rowsMax="200"
            />
                  {this.state.attachmentsToAdd.length > 0 && 
            <div className="comm-att-holder add-comp-act">
                    <React.Fragment>
                      {this.state.attachmentsToAdd.map((attachment, index) => {
                        const attachmentURL = (attachment.src || attachment.url || attachment.type);
                        const isImage = attachment.src ? true : false;
                        const isCloud = attachment.url ? true : false;
                        const isDocument = attachment.type ? true : false;
                        return <div
                        className="nw-att-row-container"
                        key={index}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              isImage
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {isImage && (
                              <img
                                src={decodeURI(attachmentURL)}
                                alt="att-img"
                                onClick={() => this.onClickImg(decodeURI(attachmentURL))}
                              />
                            )}
                            {isCloud &&
                              attachmentURL.indexOf(".google.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {isCloud &&
                              attachmentURL.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {isCloud &&
                              attachmentURL.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {isDocument &&
                              // attachmentURL.indexOf(".google.com") < 0 &&
                              // attachmentURL.indexOf("www.dropbox.com") < 0 &&
                              // attachmentURL.indexOf("https://1drv.ms/") < 0 && 
                              (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={isImage ? { cursor: "pointer" } : {}}
                                onClick={() => isImage && this.onClickImg(decodeURI(attachmentURL))}
                              >
                                {attachment.title || attachment.name}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              // href={decodeURI(attachment.url)}
                              // download={attachment.title}
                              // target="_blank"
                              onClick={() => this.onRemoveAttachment(attachment)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                </div>
                  }
            </div>
            <div className="act-att-holder">
            <div className="comment-attachment-area">
                  <OutsideClickHandler
                    onOutsideClick={this.closeAttachmentDropdown}
                  >
                      <div
                        className="comp-act-att"
                        onClick={this.openAttachmentDropdown}
                      >Add Attachment</div>
                    {this.state.openAttachmentDropdown && (
                      <AttachmentDropdown
                        closeAttachmentDropdown={this.closeAttachmentDropdown}
                        // activityDetails={activityDetails}
                        handleMaxSize={this.handleMaxSize}
                        handleAttachFrom={this.handleAttachFrom}
                        attachedFrom={"completedActivity"}
                        // is_priority={is_priority}
                        // updateFetch={this.props.updateFetch}
                        // is_archived={is_archived}
                        updateAttchmetnOneDrive={this.updateAttchmetnOneDrive}
                      />
                    )}
                  </OutsideClickHandler>
                </div>
            </div>
            {
            this.state.displayErrMsg && 
            (
                      <div class="new-comm-attacherror add-act">
                        The file size exceeds 30 MB{" "}
                      </div>
                    )}
              </React.Fragment>
            }

            <div className="act-submit-btn-holder with-total">
            <div className="act-total-date-time-holder">
             {userDetails.users.length > 1 && !isCompleted &&
             <React.Fragment>
             <div className="total-duration">Total Duration - {start_on && due_on && !this.state.continuous ? 
              `${moment(start_on).format("ddd, DD MMM YY")} - ${moment(due_on).format("ddd, DD MMM YY")}`
              : start_on && due_on && this.state.continuous
              ? `${moment(start_on).format("ddd, DD MMM YY")} - Continuous` : ""}
              </div>
              <div>Total Time - {this.getTotalActivityTime()}</div>
              </React.Fragment>
              }
            </div>
              {/* $$ After checking if all fields are filled properly,
                                enable the button */}
              <div className="add-act-btn-holder">
              {this.state.fetching && <CircularProgress
                        classes={{ root: "circular-progress" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                      ></CircularProgress>}
              <button
                className="act-submit-btn"
                disabled={
                  !this.onNewActivityFormValidate() || this.state.fetching
                }
                onClick={isEdit ? this.onNewActivityEdit : this.onNewActivitySubmit}
              >
                {/* Enable btn styles add "active" class */}
                {isEdit ? "Save" : "Add"}
              </button>
              </div>
              {/* $$ for completed activity show this button */}
              {/* <button className="act-submit-btn ">
                                Add
                            </button> */}
            </div>
          </div>
        {/* </Form> */}
        {clickedImg && (
            <div className="attachmentpopviews">
              <img src={clickedImg} className="attachmentimage" alt="att-img" />
              <img
                className="closeattachmentpopview"
                src={closeIcon}
                alt="close"
                onClick={this.onClosePreview}
              />
            </div>
          )}
        {showCannotRemovePopup && (
                      <DialogWrapper dialogProps={dialogPropsCannotRemove}>
                        <UserCannotRemovePopup
                          userToRemove={showCannotRemovePopup}
                          loggedInUser={loggedInUser}
                          onClosePopup={this.onCloseCannotRemovePopup} />
                      </DialogWrapper>
                    )}
        {showAttachmentErrPop && (
            <DialogWrapper dialogProps={dialogPropsAttErr}>
              <AttachmentErrPop
                fromEditReport={this.props.fromReport}
                onClosePopup={this.onCloseAttachmentErrPop} />
            </DialogWrapper>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addActivityFormDetails: state.rrfStatereducer.forms.addActivity,
    projectList: state.projectReducer.listOfProjects.list,
    listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list,
    workspaceUser: state.peopleReducer.workspaceusers,
    loggedInUser: state.loginReducer.loginAuth.user_details,
    totalActivityCount: [...state.activityReducer.prioritiZedActivity.list,
      ...state.activityReducer.listOfActivity.list].length,
    selectedWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectsAction: ({ queryParam }, callback) =>
      dispatch(getProjectsAction({ queryParam }, callback)),
    getAllCustomerForPeopleAction: callback => {
      dispatch(getAllCustomerForPeopleAction(callback));
    },
    getSelectedWorkspaceActiveActivities: ({
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page
      },
      callback
    ) => dispatch(getSelectedWorkspaceActiveActivities({ auth_token,
      activity_type,
      is_active,
      user_id,
      is_archived,
      page,
      item_per_page }, callback)),
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(updateProjectAction({ auth_token, queryParam, body }, callback));
    },
    change: (field, value) => {
      dispatch(actions.change(field, value, { enableReinitialize: true }));
    },
    reset: formName => {
      dispatch(actions.reset(formName));
    },
    validate: (field, validator) => {
      dispatch(actions.change(field, validator));
    },
    createProjectAction: ({ name, img, group, data }, callback) => {
      dispatch(createProjectAction({ name, img, group, data }, callback));
    },
    createUpcomingActivity: (body, callback) => {
      dispatch(createUpcomingActivityAction(body, callback));
    },
    createCompletedActivity: (body, formData, callback) => {
      dispatch(createCompletedActivityAction(body, formData, callback));
    },
    updateActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        user_id,
        data,
        project,
        version_id,
        is_archived,
        is_priority,
        performed_by,
        is_locked
      },
      callback
    ) => {
      dispatch(
        updateActivityAction(
          {
            id,
            due_on,
            start_on,
            duration,
            hours,
            minutes,
            project_id,
            tags,
            title,
            description,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            user_id,
            project,
            version_id,
            data,
            is_archived,
            is_priority,
            performed_by,
            is_locked
          },
          callback
        )
      );
    },
    updateArchiveActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        tags,
        title,
        description,
        user_id,
        project,
        version_id,
        data,
        is_archived,
        is_priority,
        users_duration,
        users_not_in_workspace,
        continuous_activity
      },
      callback
    ) => {
      dispatch(
        updateArchiveActivityAction(
          {
            id,
            due_on,
            start_on,
            duration,
            hours,
            minutes,
            project_id,
            tags,
            title,
            description,
            user_id,
            project,
            version_id,
            data,
            is_archived,
            is_priority,
            users_duration,
            users_not_in_workspace,
            continuous_activity
          },
          callback
        )
      );
    },
    createActivity: (
      {
        created_at,
        created_by,
        due_on,
        duration,
        hours,
        minutes,
        project_id,
        start_on,
        tags,
        title,
        description,
        user_id,
        data,
        project,
        workflow,
        users_duration,
        continuous_activity
      },
      callback
    ) => {
      dispatch(
        createActivityAction(
          {
            created_at,
            created_by,
            due_on,
            duration,
            hours,
            minutes,
            project_id,
            start_on,
            tags,
            title,
            description,
            user_id,
            data,
            project,
            workflow,
            users_duration,
            continuous_activity
          },
          callback
        )
      );
    },
    updateWorkloadActivity: (
      { activityObj, user_id, activity_id, fields },
      callback
    ) => {
      dispatch(
        updateWorkloadActivityAction(
          { activityObj, user_id, activity_id, fields },
          callback
        )
      );
    },
    updateKanbanActivity: (
      {
        id,
        due_on,
        start_on,
        duration,
        hours,
        minutes,
        project_id,
        description,
        users_duration,
        users_not_in_workspace,
        continuous_activity,
        tags,
        data,
        title,
        user_id,
        version_id,
        project,
        is_priority
      },
      callback
    ) => {
      dispatch(
        updateKanbanActivityAction(
          {
            id,
            due_on,
            start_on,
            version_id,
            duration,
            hours,
            data,
            minutes,
            project_id,
            description,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            tags,
            title,
            user_id,
            project,
            is_priority
          },
          callback
        )
      );
    },
    addBacklogActivities:(activity,projectId)=>{
      dispatch(addBacklogActivitiesAction({activity,projectId}))
    },
    updateAllActivity : (activity_id) =>{
      dispatch(updateAllActivityAction({ activity_id }))
    },
    addActivityToReducer: (activity) => {
      dispatch(addActivityToReducerAction(activity))
    },
    removeActivityFromReducer: (activity) => {
      dispatch(removeActivityFromReducerAction(activity))
    },
    removeBacklogActivities:(activityId,projectId)=>{
      dispatch(removeBacklogActivitiesAction({activityId,projectId}))
    },
    getActivitiesForDropdownAction: ({ userId, auth_token }, callback) => {
      dispatch(getActivitiesForDropdownAction({ userId, auth_token }, callback))
    },
    createTimerAction(
      {
        tags,
        attachments,
        activity_id,
        data,
        time_taken,
        description,
        date,
        user_id,
        customer_auth_token
      },
      formData,
      callback
    ) {
      dispatch(
        createTimerAction(
          {
            tags,
            attachments,
            activity_id,
            data,
            time_taken,
            description,
            date,
            user_id,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    },
    addTimer(
      {
        tags,
        activity_id,
        time_taken,
        description,
        date,
        user_id,
        completed_durations,
        customer_auth_token
      },
      formData,
      callback
    ) {
      dispatch(
        addTimerAction(
          {
            tags,
            activity_id,
            time_taken,
            description,
            date,
            user_id,
            completed_durations,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    }
  };
};

const getClosest = function(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { /* TODO document why this block is empty */ }
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddActivity));
