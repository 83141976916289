import {
  forgotPasswordLoaderState, loginLoaderState,

  resetPasswordLoaderState, updateAuthKey
} from "../../helpers/loginconstants";
import {
  authenticateLogin,
  authenticateAppleLogin,
  autherizeGoogleUser,
  forgotPasswordReuest,
  resetPasswordRequest
} from "../../services/loginapi";


export const updateLoginAuth = user_details => {
  return {
    type: updateAuthKey,
    payload: {
      user_details,
      auth_token: user_details.auth_token,
      key: new Date().getTime().toLocaleString()
    }
  };
};

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export const authenticateUser = ({ email, password }, callback) => dispach => {
  dispach(
    updateLoaderState({
      action: loginLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  authenticateLogin({ email, password }, response => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispach(updateLoginAuth(response));
    } else {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const authenticateAppleUser = ({ apple_token }, callback) => dispach => {
  dispach(
    updateLoaderState({
      action: loginLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  authenticateAppleLogin({ apple_token }, response => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispach(updateLoginAuth(response));
    } else {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const googleAuthentication = (
  { code, clientId, redirectUri, type, access_type },
  callback
) => dispach => {
  dispach(
    updateLoaderState({
      action: loginLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  autherizeGoogleUser({ code, clientId, redirectUri, type, access_type }, response => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      //dispach(updateLoginAuth(response.auth_token))
    } else {
      dispach(
        updateLoaderState({
          action: loginLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const forgotPasswordAction = ({ email }, callback) => dispach => {
  dispach(
    updateLoaderState({
      action: forgotPasswordLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  forgotPasswordReuest({ email }, response => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: forgotPasswordLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      //dispach(updateLoginAuth(response.auth_token))
    } else {
      dispach(
        updateLoaderState({
          action: forgotPasswordLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true,
            errorMessage: response.error
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const resetPasswordAction = (
  { email, token, retypePassword },
  callback
) => dispach => {
  dispach(
    updateLoaderState({
      action: resetPasswordLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  resetPasswordRequest({ email, token, retypePassword }, response => {
    if (response && !response.error) {
      dispach(
        updateLoaderState({
          action: resetPasswordLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      //dispach(updateLoginAuth(response.auth_token))
    } else {
      dispach(
        updateLoaderState({
          action: resetPasswordLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};
