export const awsConf ={
    root_path: 'https://s3.amazonaws.com/gtd/clients',
    access_key: process.env.REACT_APP_AWS_ACCESS_KEY,
    secret_key: process.env.REACT_APP_AWS_SECRET_KEY,
    region: 'us-east-1',
    buckets:{
    name: process.env.REACT_APP_AWS_BUCKET_NAME,
    subpaths: {
        attachments :'attachments',
        clients: 'clients',
        testApi: 'test_api',
        folder: process.env.REACT_APP_AWS_FOLDER_NAME
    }
}
}