import React from "react";
import arrowBack from "../../styles/img/svg/forwar_arrow_profile.svg";
import "../../styles/scss/user_profile.scss";
import routesHelper from "../../router/routeshelper";

export default function HeaderWrapperSettings(props) {
  
  const goBack = () => {
    const { router } = props;
    
    router.push(routesHelper.getActivityPath());
  };
  if (props.currentWorkSpace) {
    return (
      <div>
        <div className="profile-header-wrap">
          <div className="right-flow-profile">
            <div
              className="back-arrow-container"
              onClick={goBack}
              role="button"
              tabIndex="0"
            >
              <img src={arrowBack} alt="arrow-back" />
              <span className="back-button-profile">Back</span>
            </div>

            <span className="profile-title ng-binding">
              {props.currentWorkSpace.display_name + " "}Settings
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="profile-header-wrap">
          <div className="right-flow-profile">
            <div
              className="back-arrow-container"
              onClick={goBack}
              role="button"
              tabIndex="0"
            >
              <img src={arrowBack} alt="arrow-back" />
              <span className="back-button-profile">Back</span>
            </div>

            <span className="profile-title ng-binding">Settings</span>
          </div>
        </div>
      </div>
    );
  }
}
