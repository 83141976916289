/**
 *
 * Author       Madhusudhan Seetharamiah
 */

import React, { Component } from "react";
import { Form, Control } from "react-redux-form";
import routesHelper from "../../router/routeshelper";
import { connect } from "react-redux";
import { actions as rrfActions } from "react-redux-form";
import * as actions from "../../actions/index";
import "../../styles/scss/register.scss";
import { message } from "../../constants/messages";
import DialogWrapper from "../common/dialog";
import InviteExpirePopup from "./inviteExpirePopup";
import EmailAlreadyRegisteredPopup from "../login/emailAlreadyRegisteredPop";
import { isEmailValid } from "../../helpers/generichelper";
import { validateInvite } from "../../services/registrationapi";
import GoogleRegistration from "./googleregistration";
import AppleRegistration from "./appleRegistration";
import EmailInfo from "../../styles/img/svg/email_info.svg";
import MaterialButton from "../common/materialbutton";
import firebase from "../../firebase";
import { getAllCustomerForUserAction } from "../../actions/workspace/workspaceaction";

const mapStateToProps = state => {
  return {
    registrationUserDetails: state.rrfStatereducer.registration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFormValues: (argName, argValue) => {
      dispatch(
        rrfActions.change("rrfState.registration" + argName, argValue)
      );
    },
    registerUserAction({ email, password, name }, callback) {
      dispatch(actions.registerUserAction({ email, password, name }, callback));
    },
    acceptInviteAction({ token, auth_token }, callback) {
      dispatch(actions.acceptInviteAction({ token, auth_token }, callback));
    },
    suggestWorkspaceActions: ({ auth_token, user_id }, callback) => {
      dispatch(
        actions.suggestWorkspaceAction({ auth_token, user_id }, callback)
      );
    },
    googleAuthenticationAction({ code, clientId, redirectUri, type, access_type }, callback) {
      dispatch(
        actions.googleAuthentication(
          {
            code,
            clientId,
            redirectUri,
            type,
            access_type
          },
          callback
        )
      );
    },
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey
          },
          callback
        )
      );
    }
  };
};

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      // confirm_password: null,
      name: null,
      notValidForm: false,
      showPassword: false,
      infoEmail: false,
      isValidInvite: false,
      showInvalidPopup: false,
      showAlreadyEmailPopup: false,
      invitedEmail: null
    };
  }

  componentDidMount() {
    if (this.props.router.location.query.token) {
      validateInvite(
        { token: this.props.router.location.query.token },
        response => {
          if (response.status && response.status === "not-invited") {
            this.setState({
              isValidInvite: false,
              showInvalidPopup: true,
              invitedEmail: response.email
            });
          }
          if(response.status && response.status === "invited") {
            // this.props.registrationUserDetails.email = response.email;
            this.props.updateFormValues(".email", response.email);
            this.setState({
              isValidInvite: true,
              showInvalidPopup: false,
              invitedEmail: response.email
            });
          }
        }
      );
    }
  }

  onCloseExpirePopup = () => {
    this.setState({ showInvalidPopup: false })
  }

  showEmailinfo = () => {
    this.setState({
      infoEmail: true
    });
  };
  hideEmailinfo = () => {
    this.setState({
      infoEmail: false
    });
  };
  isValidData = () => {
    const { registrationUserDetails } = this.props;
    let isValidBoolList = [];
    for (const key in registrationUserDetails) {
      if (registrationUserDetails.hasOwnProperty(key)) {
        const element = registrationUserDetails[key];
        if (!element) {
          isValidBoolList.push(false);
        }
      }
    }
    if (isValidBoolList.indexOf(false) > -1) {
      this.setState({ notValidForm: true });
      return false;
    }
    this.setState({ notValidForm: false });
    return true;
  };

  findIfAnySuggetions = () => {
    const { router, suggestWorkspaceActions } = this.props;
    let auth_token = localStorage.getItem("auth_token");
    let user_id = localStorage.getItem("id");
    suggestWorkspaceActions({ auth_token, user_id }, response => {
      if (response && !response.error) {
        if (response.length > 0) {
          router.push(routesHelper.getRegistrationMessagePath());
        } else {
          router.push(routesHelper.getFindWorkspacePath());
        }
      } else {
        router.push(routesHelper.getRootPath());
      }
    });
  };

  onInputKeyUp = e => {
    if (e.key === "Enter") this.onRegisterInitiate();
  };

  onRegisterInitiate = () => {
    firebase.analytics().logEvent("GTD_Custom_register_website");
    const {
      registrationUserDetails,
      registerUserAction,
      // router,
      // acceptInviteAction
    } = this.props;
    const { isValidInvite, invitedEmail } = this.state;
    let noOfErrors = [];
    var noErrorInPassword = false;
    for (const key in registrationUserDetails) {
      if (registrationUserDetails.hasOwnProperty(key)) {
        const element = registrationUserDetails[key];
        if (key === "name") {
          if (element.length < 1) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.emptyField });
          } else if (isEmailValid(element)) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.enterValidFullName });
          } else {
            this.setState({ [key]: null });
          }
        } else if (key === "email") {
          if (element.length < 1) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.emptyField });
          } else if (!isEmailValid(element)) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.emailNotValid });
          } else if(isEmailValid(element) && isValidInvite && invitedEmail && invitedEmail !== element) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.invitedEmailNotMatch });
          } else {
            this.setState({ [key]: null });
          }
        } else if (key === "password") {
          if (element.length < 1) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.emptyField });
          } else if (element && element.length < 6) {
            noOfErrors.push(false) &&
              this.setState({ [key]: message.shortPassword });
          } else {
            noErrorInPassword = true;
            this.setState({ [key]: null });
          }
        }
        // else if (key === "confirm_password" && noErrorInPassword) {
        //   if (element !== registrationUserDetails["password"]) {
        //     noOfErrors.push(false) &&
        //       this.setState({ [key]: message.wrongConfirmPassword });
        //   } else {
        //     this.setState({ [key]: null });
        //   }
        // }
      }
    }
    if (noOfErrors.length === 0) {
      registerUserAction(registrationUserDetails, response => {
        if (response && !response.error) {
          firebase.analytics().logEvent("GTD_Custom_register_website_success");
          // for (const key in response) {
          //   if (response.hasOwnProperty(key)) {
          //     const element = response[key];
          //     localStorage.setItem(key, element);
          //   }
          // }

          // Accepting the invite after register
          // if (router.location.query.token && response.auth_token && this.state.isValidInvite) {
          //   acceptInviteAction(
          //     {
          //       token: router.location.query.token,
          //       auth_token: response.auth_token
          //     },
          //     res => {
          //       if (
          //         res &&
          //         res.detail.includes("Invitation accepted successfully")
          //       ) {
          //         this.props.router.push({
          //           pathname: "/verify-email",
          //           state: { email: registrationUserDetails.email }
          //         });
          //       }
          //     }
          //   );
          // } else
            this.props.router.push({
              pathname: "/verify-email",
              state: { email: registrationUserDetails.email }
            });
        } else {
          firebase
            .analytics()
            .logEvent("GTD_Custom_register_error", { error: response.error });
          this.setState({
            email:
              response.error === "email already exist"
                ? message.emailAlreadyExist
                : response.error
          });
        }
      });
    }
  };

  onFormChange = () => {
    this.isValidData();
  };

  onLogin = () => {
    const { router } = this.props;
    router.push(routesHelper.getLoginPath());
  };
  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  onShowAlreadyEmailPopup = () => {
    this.setState({ showAlreadyEmailPopup: true });
  };

  onCloseAlreadyEmailPopup = () => {
    this.setState({ showAlreadyEmailPopup: false });
  };

  render() {
    const {
      googleAuthenticationAction,
      acceptInviteAction,
      router,
      getAllCustomersAction
    } = this.props;
    let dialogPropsInviteExpired = {
      className: "dialog_wrapper",
      showPopup: this.state.showInvalidPopup,
      dialogTitle: () => "This invite link is no longer active.",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true
    };
    let dialogPropsEmailAlreadyRegistered = {
      className: "dialog_wrapper",
      showPopup: this.state.showAlreadyEmailPopup,
      dialogTitle: () => "Email already registered",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true
    };
    return (
      <div>
        <div className="signIn">
          <Form
            className="login-form"
            name="userForm"
            model="rrfState.registration"
            onUpdate={this.onFormChange}
            noValidate
          >
            <div className="login-controls-container cus-reg">
              <div className="login-control-holder">
                <Control.input
                  type="text"
                  placeholder="Your full name"
                  model=".name"
                  name="name"
                  // onKeyUp={this.onNameKeyUp}
                  required
                  className="common-input-ctrl login-nopad"
                  onKeyUp={this.onInputKeyUp}
                  autoFocus
                  autoComplete="off"
                />
                {this.state.name && (
                  <p className="common-err-msg"> {this.state.name}</p>
                )}
              </div>

              <div className="login-control-holder info-input">
                <Control.input
                  type="text"
                  placeholder="Email address"
                  model=".email"
                  name="email"
                  value={this.props.registrationUserDetails.email}
                  // onKeyUp={this.onEmailKeyUp}
                  className="common-input-ctrl login-nopad"
                  required
                  onKeyUp={this.onInputKeyUp}
                  autoComplete="off"
                />
                <img
                  src={EmailInfo}
                  alt="email_info"
                  onMouseOver={this.showEmailinfo}
                  onMouseLeave={this.hideEmailinfo}
                />
                {this.state.infoEmail && (
                  <div className="info-detail">
                    <p>
                      {" "}
                      Providing a business email would help us find your
                      organization for you.
                    </p>
                  </div>
                )}
                {this.state.email && (
                  <p className="common-err-msg">
                    {this.state.email}{" "}
                    {this.state.email === message.emailAlreadyExist && (
                      <React.Fragment>
                        Try <span onClick={this.onLogin}>Login</span> instead.
                      </React.Fragment>
                    )}
                  </p>
                )}
              </div>

              <div className="login-control-holder view-password-holder">
                <Control.input
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder="Create a password"
                  className="common-input-ctrl login-nopad"
                  // onKeyUp={this.onPasswdKeyUp}
                  model=".password"
                  name="password"
                  required
                  onKeyUp={this.onInputKeyUp}
                  autoComplete="off"
                />
                <div
                  onClick={this.toggleShowPassword}
                  style={{ cursor: "pointer" }}
                  className={
                    this.state.showPassword
                      ? "eye-seen password-viewer"
                      : "eye-not-seen password-viewer"
                  }
                ></div>
                {this.state.password && (
                  <p className="common-err-msg">{this.state.password}</p>
                )}
              </div>
            </div>
            <div className="log-log reg-butt">
              <MaterialButton
                buttonText="Register"
                variant="contained"
                className="signin"
                color="primary"
                onClick={this.onRegisterInitiate}
              ></MaterialButton>
            </div>
            <div className="or-container">
              <div className="or-hr"></div>
              <div className="or-register">or</div>
              <div className="or-hr"></div>
            </div>
            <GoogleRegistration
              googleAuthenticationAction={googleAuthenticationAction}
              isValidInvite={this.state.isValidInvite}
              acceptInviteAction={acceptInviteAction}
              router={router}
              getAllCustomersAction={getAllCustomersAction}
              onShowAlreadyEmailPopup={this.onShowAlreadyEmailPopup}
            />
            <AppleRegistration />
            <div className="log-reg regis" style={{ marginBottom: "20px" }}>
              <p className="for-pass">Already using Get Things Done? </p>
              <a className="for-register" onClick={this.onLogin}>
                Login
              </a>
            </div>
          </Form>
        </div>
        <div className="log-service">
          <p>
            By registering, you agree to our{" "}
            <a href={routesHelper.getGtdTermsUri()} target="_blank" rel="noopener noreferrer">
              <span> Terms of Service </span>
            </a>{" "}
            and{" "}
            <a href={routesHelper.getGtdPolicyUri()} target="_blank" rel="noopener noreferrer">
              <span> Privacy Policy</span>
            </a>
            .
          </p>
        </div>
        {this.state.showInvalidPopup && (
          <DialogWrapper dialogProps={dialogPropsInviteExpired}>
            <InviteExpirePopup onClosePopup={this.onCloseExpirePopup} />
          </DialogWrapper>
        )}
        {this.state.showAlreadyEmailPopup && (
          <DialogWrapper dialogProps={dialogPropsEmailAlreadyRegistered}>
            <EmailAlreadyRegisteredPopup onClosePopup={this.onCloseAlreadyEmailPopup} />
          </DialogWrapper>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
