import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";

export const registerFirebaseUserToken = (body, callback) => {
  let url = "/api/v1/mobile-token";

  secureFetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
      // console.log(
      //   "getGlobalNotifications action response after api call",
      //   respJson
      // );
    })
    .catch(error => {
      // console.log("getGlobalNotifications action error", error);
    });
};

export const deleteFirebaseUserToken = (body, callback) => {
  let url = "/api/v1/mobile-token";

  secureFetch(API_URL + url, {
    method: "DELETE",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
      // console.log(
      //   "getGlobalNotifications action response after api call",
      //   respJson
      // );
    })
    .catch(error => {
      // console.log("getGlobalNotifications action error", error);
    });
};

export const getGlobalNotifications = callback => {
  let url = "/api/v1/global-notification";

  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
      // console.log(
      //   "getGlobalNotifications action response after api call",
      //   respJson
      // );
    })
    .catch(error => {
      // console.log("getGlobalNotifications action error", error);
    });
};

export const getSystemNotifications = (
  { auth_token, recipient_id = parseInt(localStorage.getItem("id")) },
  callback
) => {
  let url = "/api/v1/system-notification?";
  let Params = "recipient_id=" + recipient_id + "&type=ADD_USER";

  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {});
};

export const getAllNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  let url = "/api/v1/notification?";
  let Params =
    "page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";

  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
      // console.log(
      //   "getAllNotifications action response after api call",
      //   respJson
      // );
    })
    .catch(error => {
      // console.log("getAllNotifications action error", error);
    });
};

export const getYourNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  // console.log("page  no", page);
  let url = "/api/v1/notification?";
  let Params =
    "page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";

  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getYourNotifications action error", error);
    });
};

export const ChangeIsReadStatusOfYou = (
  { notificationArray, recipient_id },
  callback
) => {
  let url = API_URL + "/api/v1/notification?recipient_id=" + recipient_id;

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(notificationArray),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const changeIsNewStatusOfSystemNotification = (
  { notificationArray, recipient_id },
  callback
) => {
  let queryParam = recipient_id ? `?recipient_id=${recipient_id}` : "";
  let url = API_URL + "/api/v1/system-notification" + queryParam;

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(notificationArray),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const markAllAsRead = (
  { notificationArray, recipient_id, type },
  callback
) => {
  let url =
    API_URL +
    "/api/v1/notification?recipient_id=" +
    recipient_id +
    "&type=" +
    type;

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(notificationArray),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const archiveSystemNotification = ({ notificationData }, callback) => {
  let url = API_URL + "/api/v1/global-notification";

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(notificationData),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {});
};

export const replyInNotification = (
  { notificationsObjectArray, recipient_id },
  callback
) => {
  let url = API_URL + "/api/v1/notification?recipient_id=" + recipient_id;

  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify(notificationsObjectArray),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("error in replyInNotification api calling function", error);
    });
};

export const getSearchNotifications = (
  { like, page, recipient_id, type, is_flagged, is_read },
  callback
) => {
  let url = "/api/v1/search-notification?";

  let Params =
    is_flagged === true
      ? "is_flagged=true&" +
        "like=" +
        like +
        "&page=" +
        page +
        "&recipient_id=" +
        recipient_id +
        "&type=" +
        type +
        "&logged_in_user=" +
        recipient_id
      : "like=" +
        like +
        "&page=" +
        page +
        "&recipient_id=" +
        recipient_id +
        "&type=" +
        type +
        "&logged_in_user=" +
        recipient_id;
  if(is_read) Params = Params.concat("&is_read=true");
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getSearchNotifications api error", error);
    });
};

export const getAllFlaggedNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  let url = "/api/v1/notification?";
  let Params =
    "is_flagged=true" +
    "&like=" +
    "&page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getAllFlaggedNotifications api call error", error);
    });
};

export const getYouFlaggedNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  let url = "/api/v1/notification?";
  let Params =
    "is_flagged=true" +
    "&like=" +
    "&page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getYouFlaggedNotifications api call error", error);
    });
};

export const getYouReadNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  let url = "/api/v1/notification?";
  let Params =
    "is_read=true" +
    "&like=" +
    "&page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getYouFlaggedNotifications api call error", error);
    });
};

export const getAllReadNotifications = (
  { auth_token, page, per_page, recipient_id },
  callback
) => {
  let url = "/api/v1/notification?";
  let Params =
    "is_read=true" +
    "&like=" +
    "&page=" +
    page +
    "&per_page=" +
    per_page +
    "&recipient_id=" +
    recipient_id +
    "&type=COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      // console.log("getAllFlaggedNotifications api call error", error);
    });
};