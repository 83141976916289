import React from "react";

const CurrencyDropDown = ({ listOfCurrencies, onSelectCurrency }) => {
  const renderCurrencyOptions = listOfCurrencies.map(el => {
    return (
      <div
        key={el.code}
        className="ppl-settings-ddown-list"
        onClick={event => onSelectCurrency(el)}
      >
        {el.code} - {el.symbol}
      </div>
    );
  });

  return (
    <div className="ppl-settings-ddown wsp-settings-ddown">
      <div className="ppl-settings-ddown-inner-holder wsp">
        {renderCurrencyOptions}
      </div>
    </div>
  );
};

export default CurrencyDropDown;
