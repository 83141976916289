import { commentsConstant } from "../../constants/commentsconstants";
import {
  getComments,
  addComment,
  deleteComment,
  editComment,
  searchActivityInner,
  updateLikeBookmarkComment,
  getArchivedComments,
  permanentDeleteArchivedComment,
  getSingleCommentChain
} from "../../services/commentsapi";
import _ from "lodash";
import { activities } from "../../constants/activitiescontants";
import { addAttachmentFiles } from "../../services/attachmentapi";

function updateLoaderState(type, state) {
  return {
    type,
    payload: { state }
  };
}
const getCommentsByActivity = (comments,pageNumber) => {
  return {
    type: commentsConstant.getComments,
    payload: {
      comments: comments,
      pageNumber:pageNumber
    }
  };
};

const searchCommentsByActivity = (comments, pageNumber) => {
  return {
    type: commentsConstant.searchComments,
    payload: {
      comments: comments,
      pageNumber:pageNumber
    }
  };
};

const getArchivedCommentsByWorkspace = (comments) => {
  return {
    type: commentsConstant.getArchivedComments,
    payload: {
      comments
    }
  };
};

const getMoreArchivedCommentsByWorkspace = (comments) => {
  return {
    type: commentsConstant.getMoreArchivedComments,
    payload: {
      comments
    }
  };
};

const  clearComments= ()=> {
  return {
    type: commentsConstant.clearComments,
  };
}

const  clearSearchedComments= ()=> {
  return {
    type: commentsConstant.clearSearchedComments,
  };
}

const  clearArchivedComments= ()=> {
  return {
    type: commentsConstant.clearArchivedComments
  };
}


const addCommentInActivity = comment => {
  return {
    type: commentsConstant.addComment,
    payload: {
      comment: comment
    }
  };
};

const addAttachmentsToReducer = (commentId, attachments) => {
  return {
    type: commentsConstant.addAttachments,
    payload: {
      commentId,
      attachments
    }
  };
};

const deleteCommentInActivity = comment => {
  return {
    type: commentsConstant.deleteComment,
    payload: {
      commentId: comment.id,
      comment
    }
  };
};
const editCommentInActivity = comment => {
  return {
    type: commentsConstant.editComment,
    payload: {
      comment: comment
    }
  };
};

const updateLikeBookmarkCommentInActivity = comment => {
  return {
    type: commentsConstant.updateLikeBookmarkComment,
    payload: {
      comment
    }
  }
}

const updateCommentsCount = (activity_id, is_priority) => {
  if (is_priority) {
    return {
      type: activities.updateCommentsCount.PRIORITY,
      payload: {
        activity_id
      }
    };
  } else {
    return {
      type: activities.updateCommentsCount.NOTPRIORITY,
      payload: {
        activity_id
      }
    };
  }
};
// new
const updateArchivedCommentsCount = activity_id => {
  return {
    type: activities.updateArchivedCommentsCount,
    payload: {
      activity_id
    }
  };
};
const updateArchivedCommentsCountForDelete = (activity_id, count) => {
  return {
    type: activities.updateArchivedCommentsCountForDelete,
    payload: {
      activity_id,
      count
    }
  };
};
const updateDeleteCommentsCount = (activity_id, is_priority, count) => {
  if (is_priority) {
    return {
      type: activities.updateDeleteCommentsCount.PRIORITY,
      payload: {
        activity_id,
        count
      }
    };
  } else {
    return {
      type: activities.updateDeleteCommentsCount.NOTPRIORITY,
      payload: {
        activity_id,
        count
      }
    };
  }
};

const unArchiveComment = (comment) => {
  return {
    type: commentsConstant.unArchiveComment,
    payload: {
      comment
    }
  }
}

export const getCommentsAction = (
  
  activity_type,
  parent_activity_id,
  pageNumber,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.getCommentsLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  getComments(activity_type, parent_activity_id, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
     
      dispatch(getCommentsByActivity(response,pageNumber));
    } else {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const getSingleCommentChainAction = (
  comment_id,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.getCommentsLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  getSingleCommentChain(comment_id, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
     
      dispatch(getCommentsByActivity(response,1));
    } else {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const searchCommentsAction = (
  queryParams,
  pageNumber,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.getCommentsLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  searchActivityInner(queryParams, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
     
      dispatch(searchCommentsByActivity(response, pageNumber));
    } else {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const addCommentAction = (
  {
    activity_type,
    created_at,
    created_by,
    data,
    description,
    parent_activity_id,
    platform,
    project_id,
    title,
    updated_on,
    user_id,
    version,
    is_priority,
    is_Activity_archived,
    attachments
  },
  formData,
  callback
) => dispatch => {
  if (data.commentDetails.isReply) {
    dispatch(
      updateLoaderState(commentsConstant.replyCommentLoaderState, {
        fetching: true,
        fetched: false,
        error: false
      })
    );
  } else {
    dispatch(
      updateLoaderState(commentsConstant.addCommentLoaderState, {
        fetching: true,
        fetched: false,
        error: false
      })
    );
  }
  addComment(
    {
      activity_type,
      created_at,
      created_by,
      data,
      description,
      parent_activity_id,
      platform,
      project_id,
      title,
      updated_on,
      user_id,
      version,
      attachments
    },
    response => {
      if (response && _.isUndefined(response.stdErr)) {
        if(formData) {
          formData.append("entity_id", response.id);
          addAttachmentFiles(formData, res => {
            if (data.commentDetails.isReply) {
              dispatch(
                updateLoaderState(commentsConstant.replyCommentLoaderState, {
                  fetching: false,
                  fetched: true,
                  error: null
                })
              );
            } else {
              dispatch(
                updateLoaderState(commentsConstant.addCommentLoaderState, {
                  fetching: false,
                  fetched: true,
                  error: null
                })
              );
            }
            // check is_archived
            if (is_Activity_archived) {
              dispatch(updateArchivedCommentsCount(parent_activity_id));
            } else {
              dispatch(updateCommentsCount(parent_activity_id, is_priority));
            }
            if(res && !res.stdErr && res?.length > 0) {
              dispatch(addCommentInActivity({ ...response, attachments: res}));
            } else {
              dispatch(addCommentInActivity(response));
            }
            callback && callback(response, res);
          })
        } else {
          if (data.commentDetails.isReply) {
            dispatch(
              updateLoaderState(commentsConstant.replyCommentLoaderState, {
                fetching: false,
                fetched: true,
                error: null
              })
            );
          } else {
            dispatch(
              updateLoaderState(commentsConstant.addCommentLoaderState, {
                fetching: false,
                fetched: true,
                error: null
              })
            );
          }
          dispatch(addCommentInActivity(response));
          // check is_archived
          if (is_Activity_archived) {
            dispatch(updateArchivedCommentsCount(parent_activity_id));
          } else {
            dispatch(updateCommentsCount(parent_activity_id, is_priority));
          }
        }
      } else {
        if (data.commentDetails.isReply) {
          dispatch(
            updateLoaderState(commentsConstant.replyCommentLoaderState, {
              fetching: false,
              fetched: true,
              error: response.stdErr
            })
          );
        } else {
          dispatch(
            updateLoaderState(commentsConstant.addCommentLoaderState, {
              fetching: false,
              fetched: true,
              error: response.stdErr
            })
          );
        }
      }
      callback && !formData && callback(response);
    }
  );
};

//Separate action to add attachments to comment
export const addCommentAttachmentsAction = ({ formData, commentId }, callback) => dispatch => {
  addAttachmentFiles(formData, response => {
    if(response && !response.stdErr && response?.length > 0) {
      dispatch(addAttachmentsToReducer(commentId, response))
    }
    callback && callback(response);
  })
}

export const deleteCommentAction = (
  {
    activity_type,
    user_id,
    created_by,
    created_at,
    title,
    id,
    data,
    is_active,
    version_id,
    parent_activity_id,
    updated_on
  },
  callback
) => dispatch => {
  deleteComment(
    {
      activity_type,
      user_id,
      created_by,
      created_at,
      title,
      id,
      data,
      is_active,
      version_id,
      parent_activity_id,
      updated_on
    },
    response => {
      dispatch(deleteCommentInActivity(response));
      callback && callback(response);
    }
  );
};

export const unarchiveCommentAction = ({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, callback) => dispatch => {
  editComment({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, res => {
    if (res && _.isUndefined(res.stdErr)) {
      dispatch(unArchiveComment({ id, is_active }));
    }
    callback && callback(res);
  });
};

export const permanentDeleteCommentAction = ({ id }, callback) => dispatch => {
  permanentDeleteArchivedComment(id, res => {
    if (res && _.isUndefined(res.stdErr)) {
      dispatch(unArchiveComment({ id }));
    }
    callback && callback(res);
  });
};

export const updateCommentsCountAfterDelete = ({ is_Activity_archived, parent_activity_id, is_priority, count }) => dispatch => {
  if (is_Activity_archived) {
    dispatch(updateArchivedCommentsCountForDelete(parent_activity_id, count));
  } else {
    dispatch(updateDeleteCommentsCount(parent_activity_id, is_priority, count));
  }
}

export const editCommentAction = (
  {
    activity_type,
    is_active,
    created_at,
    created_by,
    data,
    id,
    parent_activity_id,
    platform,
    title,
    updated_on,
    user_id,
    version,
    version_id,
    attachments
  },
  formData,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.editCommentLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  editComment(
    {
      activity_type,
      is_active,
      created_at,
      created_by,
      data,
      id,
      parent_activity_id,
      platform,
      title,
      updated_on,
      user_id,
      version,
      version_id,
      // attachments
    },
    response => {
      if (response && _.isUndefined(response.stdErr)) {
        if(formData) {
          addAttachmentFiles(formData, res => {
            dispatch(
              updateLoaderState(commentsConstant.editCommentLoaderState, {
                fetching: false,
                fetched: true,
                error: null
              })
            );
            if(res && !res.stdErr && res?.length >= 0) {
              dispatch(
                editCommentInActivity({
                  activity_type,
                  is_active,
                  created_at,
                  created_by,
                  data,
                  id,
                  parent_activity_id,
                  platform,
                  title,
                  updated_on,
                  user_id,
                  version,
                  version_id,
                  attachments: [...attachments, ...res]
                })
              );
            } else if (res && !res.stdErr) {
              dispatch(
                editCommentInActivity({
                  activity_type,
                  is_active,
                  created_at,
                  created_by,
                  data,
                  id,
                  parent_activity_id,
                  platform,
                  title,
                  updated_on,
                  user_id,
                  version,
                  version_id,
                  attachments
                })
              );
            } else {
              dispatch(
                editCommentInActivity({
                  activity_type,
                  is_active,
                  created_at,
                  created_by,
                  data,
                  id,
                  parent_activity_id,
                  platform,
                  title,
                  updated_on,
                  user_id,
                  version,
                  version_id
                })
              );
            }
            callback && callback(response, res);
          })
        } else {
          dispatch(
            updateLoaderState(commentsConstant.editCommentLoaderState, {
              fetching: false,
              fetched: true,
              error: null
            })
          );
          dispatch(
            editCommentInActivity({
              activity_type,
              is_active,
              created_at,
              created_by,
              data,
              id,
              parent_activity_id,
              platform,
              title,
              updated_on,
              user_id,
              version,
              version_id,
              // attachments: response.attachments || []
            })
          );
        }
      } else {
        dispatch(
          updateLoaderState(commentsConstant.editCommentLoaderState, {
            fetching: false,
            fetched: true,
            error: response.stdErr
          })
        );
      }
      callback && !formData && callback(response);
    }
  );
};

export const updateLikeBookmarkCommentAction = (
  body,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.editCommentLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  updateLikeBookmarkComment(
    body,
    response => {
      if (response && _.isUndefined(response.stdErr)) {
        dispatch(
          updateLoaderState(commentsConstant.editCommentLoaderState, {
            fetching: false,
            fetched: true,
            error: null
          })
        );
        dispatch(
          updateLikeBookmarkCommentInActivity(response)
        );
      } else {
        dispatch(
          updateLoaderState(commentsConstant.editCommentLoaderState, {
            fetching: false,
            fetched: true,
            error: response.stdErr
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const getArchivedCommentsAction = ({ user_id, searchText, page, perPage }, callback) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.getCommentsLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  getArchivedComments(user_id, searchText, page, perPage, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
     
      dispatch(getArchivedCommentsByWorkspace(response));
    } else {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const getMoreArchivedCommentsAction = ({ user_id, searchText, page, perPage }, callback) => dispatch => {
  dispatch(
    updateLoaderState(commentsConstant.getCommentsLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  getArchivedComments(user_id, searchText, page, perPage, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
     
      dispatch(getMoreArchivedCommentsByWorkspace(response));
    } else {
      dispatch(
        updateLoaderState(commentsConstant.getCommentsLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const clearCommentReducer = () => dispatch => {
  dispatch(getCommentsByActivity([],1));
};

export const clearCommentsAction = () => dispatch => {
  dispatch(clearComments());
};

export const clearSearchedCommentsAction = () => dispatch => {
  dispatch(clearSearchedComments());
};

export const clearArchivedCommentsAction = () => dispatch => {
  dispatch(clearArchivedComments());
};
