import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Mark from "mark.js";
import _ from "lodash";
import * as action from "../../actions/index";
import { updateTotalTimeAction } from "../../actions/activities/activitiesaction";
import "../../styles/scss/reports.scss";
import "../../styles/scss/filter.scss";
import ProjectDropDown from "./projectDropDown";
import MemberDropDown from "./memberDropDown";
import DateRangePicker from "../common/dateRangePicker";
import { reports } from "../../constants/reportsconstants";
import { updateTimeSheetList,getAllActivityTypeAction, clearTimesheetAction } from "../../actions/timesheet/timesheetaction";
import { getWpRemovedMembers } from "../../services/timesheetapi";
import { getProjectsByAccess } from "../../helpers/projectslanding";
import {
  getIsAccessToReports,
  getIsCreator,
  getIsSuperAdmin,
  getQueryParamsForTimeSheet
} from "../../helpers/reportlanding";
// import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import firebase from "../../firebase";
import TimeSheetTable from "./timeSheetTable";
import ActivityTypeDropDown from "./activityTypeDropDown";
import { getUpdatedProjects } from "../../services/projectapi";

class ReportLanding extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      from: moment(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      ).format("ddd, DD MMM YY"),
      to: moment(new Date()).format("ddd, DD MMM YY"),
      projectId: "all",
      selectedProject: "All",
      memberId: localStorage.getItem("id"),
      selectedMember: localStorage.getItem("first_name"),
      activityType:'All',
      applyClick:false,
      notifyTimeSheetDate: null,
      wpRemovedMembers: []
    };
    // this.projectDropdown = React.createRef();
    // this.memberDropDown = React.createRef();
  }
  highlightTimer;

  UNSAFE_componentWillMount() {
    // this.props.updateTimeSheetList({
    //   action: reports.timeSheetList,
    //   payload: { list: [] }
    // });
    const {
      projectId,
      selectedProject,
      memberId,
      selectedMember,
      from,
      to,
      activityType
    } = this.props.filters;
    this.setState({
      projectId,
      selectedProject,
      memberId: memberId ? memberId : parseInt(localStorage.getItem("id")),
      selectedMember: selectedMember
        ? selectedMember
        : localStorage.getItem("first_name"),
      from,
      to,
      activityType
    });
  }

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Report_page");
    let reportDate = this.props.router.location.state?.reportDate || this.props.router.location.query.timesheet_date || this.props.router.location.query.date;
    if(parseInt(localStorage.getItem("customer_id")) !== parseInt(this.props.router.location.query.customer_id))
      reportDate = null;
    if(!reportDate) {
    const {
      //   getProjectAction,
      //   getAllCustomer,
      allProjectList,
      currentUser,
      currentWorkSpace,
      getProjectAction,
      updateFilterAction,
      getTimeSheetsAction,
      filters,
      loggedInUserDetails,
      getAllActivityType,
      router
    } = this.props;
    let {
      projectId,
      selectedProject,
      memberId,
      from,
      to,
      showResetFilter,
      activityType
    } = filters;
    if(reportDate) {
      from = moment(router.location.state?.reportDate).format("ddd, DD MMM YY");
      to = moment(router.location.state?.reportDate).format("ddd, DD MMM YY");
      showResetFilter = true;
      this.setState({ from, to });
    }
    // if (!memberId) {
      updateFilterAction({
        projectId,
        selectedProject,
        memberId: parseInt(loggedInUserDetails.id),
        selectedMember: loggedInUserDetails.first_name,
        from,
        to,
        showResetFilter,
        activityType
      });
    // }
    let param='';
    
    const queryParam = getQueryParamsForTimeSheet(
      projectId,
      memberId || parseInt(loggedInUserDetails.id),
      from,
      to,
      activityType
    );
    // let currentUserId = loggedInUserDetails.id;
    // getAllCustomer({}, response => {
    //   if (response && !response.error) {
    //   }
    // });
    // let fromDateWithTime1 = moment(
    //   new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
    // ).format("LL");
    // let fromDateWithTime = moment(fromDateWithTime1)
    //   .add(0, "h")
    //   .add(0, "m")
    //   .add(0, "s");
    // let fromDateUtc = moment(fromDateWithTime).format();
    // let toDateUtc = moment(new Date()).format();
    // let queryParam = `?from_date=${fromDateUtc.replace(
    //   "+",
    //   "%2B"
    // )}&to_date=${toDateUtc.replace("+", "%2B")}&user_id=${currentUserId}`;

    getTimeSheetsAction({
      auth_token: localStorage.getItem("customer_auth_token"),
      queryParam
    }, res => {
      if(!res.stdErr && res?.length >= 0) {
        getUpdatedProjects({}, response => {
          if(allProjectList) {
            if(!_.isEqual(allProjectList.map(proj => proj.id).sort((a, b) => a - b), response)) {
              const queryParamUnarchive = getIsSuperAdmin(currentUser, currentWorkSpace)
                ? `?archived=false` : `?user_id=${parseInt(
                  currentUser.id
                )}&archived=false`;
              getProjectAction(
                { auth_token: null, queryParam: queryParamUnarchive },
                response => {
                  if (response && response.length > 0) {
                  }
                }
              );
            }
          }
        })
        getAllActivityType({param,displaySpinner:false});
        getWpRemovedMembers(res => {
          if(res?.length > 0) {
            this.setState({ wpRemovedMembers: res });
          }
        })
      }
    });
  }
  }

  componentDidUpdate() {
    if(document.getElementsByClassName("applyBtn").length > 0)
      document.getElementsByClassName("applyBtn")[0].innerHTML = "Select";
  }

  static getDerivedStateFromProps(props, state) {
    let reportDate = props.router.location.state?.reportDate || props.router.location.query.timesheet_date || props.router.location.query.date;
    reportDate = reportDate && reportDate.substring(0, reportDate.indexOf(" "));
    if(parseInt(localStorage.getItem("customer_id")) !== parseInt(props.router.location.query.customer_id))
      reportDate = null;
    if(reportDate &&
        moment(reportDate).format("ddd, DD MMM YY") !== state.notifyTimeSheetDate) {
      const {
        updateFilterAction,
        getTimeSheetsAction,
        filters,
        loggedInUserDetails,
        getAllActivityType,
        router
      } = props;
      let {
        // projectId,
        // selectedProject,
        // memberId,
        from,
        to,
        showResetFilter,
        // activityType
      } = filters;
      if(reportDate) {
        from = moment(reportDate).format("ddd, DD MMM YY");
        to = moment(reportDate).format("ddd, DD MMM YY");
        showResetFilter = true;
      }
        updateFilterAction({
          projectId: "all",
          selectedProject: "All",
          memberId: parseInt(loggedInUserDetails.id),
          selectedMember: loggedInUserDetails.first_name,
          from,
          to,
          showResetFilter,
          activityType: "All"
        });
      let param='';
      getAllActivityType({param,displaySpinner:false});
      const queryParam = getQueryParamsForTimeSheet(
        "all", // projectId
        parseInt(loggedInUserDetails.id), // memberId
        from,
        to,
        "All" // activityType
      );
      getTimeSheetsAction({
        auth_token: localStorage.getItem("customer_auth_token"),
        queryParam
      });
      return {
        projectId: "all",
        selectedProject: "All",
        memberId: parseInt(loggedInUserDetails.id),
        selectedMember: loggedInUserDetails.first_name,
        from,
        to,
        activityType: "All",
        notifyTimeSheetDate: from
      };
    }
    return null;
  }

  componentWillUnmount() {
    const { loggedInUserDetails, updateFilterAction, clearTimesheetAction, updateSortAction } = this.props;
    clearTimesheetAction();
    let currentUserId = parseInt(loggedInUserDetails.id);
    updateFilterAction({
      projectId: "all",
      selectedProject: "All",
      memberId: currentUserId,
      selectedMember: loggedInUserDetails.first_name,
      from: moment(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      ).format("ddd, DD MMM YY"),
      to: moment(new Date()).format("ddd, DD MMM YY"),
      showResetFilter: false,
      activityType:'All'
    });
    updateSortAction({ sortType: false, sortBy: reports.date });
  }

  onResetFilter = () => {
    const {
      loggedInUserDetails,
      getTimeSheetsAction,
      updateFilterAction,
      updateSortAction,
      getAllActivityType,
      searchContent
    } = this.props;
    let currentUserId = loggedInUserDetails.id;
    let fromDateWithTime1 = moment(
      new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
    ).format("LL");
    let fromDateWithTime = moment(fromDateWithTime1)
      .add(0, "h")
      .add(0, "m")
      .add(0, "s");
    let fromDateUtc = moment(fromDateWithTime).format();
    let toDateUtc = moment(new Date()).format();
    let queryParam = `?from_date=${fromDateUtc.replace(
      "+",
      "%2B"
    )}&to_date=${toDateUtc.replace("+", "%2B")}&user_id=${currentUserId}`;

    if(searchContent) {
      queryParam = `${queryParam}&like=${searchContent}`
    }

    getTimeSheetsAction(
      {
        auth_token: localStorage.getItem("customer_auth_token"),
        queryParam
      },
      (response) => {
        if (response && !response.stdErr) {
          this.setState({
            projectId: "all",
            selectedProject: "All",
            memberId: currentUserId,
            selectedMember: loggedInUserDetails.first_name,
            from: moment(
              new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            ).format("ddd, DD MMM YY"),
            to: moment(new Date()).format("ddd, DD MMM YY"),
            activityType:'All'
          });
          let param='';
          getAllActivityType({param,displaySpinner:false});
          updateFilterAction({
            projectId: "all",
            selectedProject: "All",
            memberId: currentUserId,
            selectedMember: loggedInUserDetails.first_name,
            from: moment(
              new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            ).format("ddd, DD MMM YY"),
            to: moment(new Date()).format("ddd, DD MMM YY"),
            showResetFilter: false,
            activityType:'All'
          });
          updateSortAction({ sortType: false, sortBy: reports.date });
          // this.projectDropdown.current.onResetProject();
          // this.memberDropDown.current.onResetMember();
          if(searchContent) {
            clearTimeout(this.highlightTimer);
            var context = [
              ...document.querySelectorAll(".td_title"),
              ...document.querySelectorAll(".td_des")
            ];
            var instance = new Mark(context);
            instance.unmark();
            this.highlightTimer = setTimeout(() => {
              var updatedContext = [
                ...document.querySelectorAll(".td_title"),
                ...document.querySelectorAll(".td_des")
              ];
              var updatedInstance = new Mark(updatedContext);
              updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
            }, 100);
          }
        }
      }
    );
  };

  onChangeProject = (projectId, selectedProject) => {
    firebase.analytics().logEvent("GTD_Report_filter_project");
    const { currentUser, currentWorkSpace } = this.props;
    this.setState({
      projectId,
      selectedProject,
      activityType: 'All',
      memberId: getIsCreator(currentWorkSpace) || getIsSuperAdmin(currentUser, currentWorkSpace)
        ? this.state.memberId : parseInt(localStorage.getItem("id")),
      selectedMember: getIsCreator(currentWorkSpace) || getIsSuperAdmin(currentUser, currentWorkSpace)
        ? this.state.selectedMember : localStorage.getItem("first_name")
    });
    if(projectId !== 'all' && this.state.projectId !== projectId){
      let param='?project_id='+projectId
      this.props.getAllActivityType({param,displaySpinner:true});
    }else if(projectId === 'all') {
      this.props.getAllActivityType({param:'',displaySpinner:true});
    }
  };

  onChangeMember = (memberId, selectedMember) => {
    firebase.analytics().logEvent("GTD_Report_filter_member");
    this.setState({ memberId, selectedMember });
  };

  onChangeDate = (event, picker) => {
    firebase.analytics().logEvent("GTD_Report_filter_date");
    document.querySelector(
      ".daterangepicker .drp-selected"
    ).innerHTML = `${moment(this.state.from).format(
      "ddd, DD MMM, YYYY"
    )} - ${moment(this.state.to).format("ddd, DD MMM, YYYY")}`;
    const from = moment(picker.startDate._d).format("ddd, DD MMM YY");
    const to = moment(picker.endDate._d).format("ddd, DD MMM YY");
    this.setState({ from, to });
  };

  onApply = () => {
    firebase.analytics().logEvent("GTD_Report_apply");
    const {
      projectId,
      selectedProject,
      memberId,
      selectedMember,
      from,
      to,
      activityType
    } = this.state;
    const {
      getTimeSheetsAction,
      updateFilterAction,
      loggedInUserDetails,
      searchContent
    } = this.props;
    let filters = {};
    this.setState({applyClick:true});
    if (
      projectId !== "all" ||
      activityType !== "All" ||
      memberId !== loggedInUserDetails.id ||
      from !==
        moment(new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)).format(
          "ddd, DD MMM YY"
        ) ||
      to !== moment(new Date()).format("ddd, DD MMM YY")
    )
      filters = {
        projectId,
        selectedProject,
        memberId,
        selectedMember,
        from,
        to,
        activityType,
        showResetFilter: true
      };
    else
      filters = {
        projectId,
        selectedProject,
        memberId,
        selectedMember,
        from,
        to,
        activityType,
        showResetFilter: false
      };
    let queryParam = getQueryParamsForTimeSheet(
      projectId,
      memberId,
      from,
      to,
      activityType
    );

    if(searchContent) {
      queryParam = `${queryParam}&like=${searchContent}`
    }

    getTimeSheetsAction(
      {
        auth_token: localStorage.getItem("customer_auth_token"),
        queryParam
      },
      (response) => {
        // console.log("filter response", response);
        updateFilterAction(filters);
        if(searchContent) {
          clearTimeout(this.highlightTimer);
          var context = [
            ...document.querySelectorAll(".td_title"),
            ...document.querySelectorAll(".td_des")
          ];
          var instance = new Mark(context);
          instance.unmark();
          this.highlightTimer = setTimeout(() => {
            var updatedContext = [
              ...document.querySelectorAll(".td_title"),
              ...document.querySelectorAll(".td_des")
            ];
            var updatedInstance = new Mark(updatedContext);
            updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
          }, 100);
        }
      }
    );
  };

  onSelectactivityType=(event,activityTypedata)=>{
      const {
      projectId,
      selectedProject,
      memberId,
      from,
      to,
      showResetFilter,
      selectedMember
    } = this.props.filters;
    this.props.updateFilterAction({
      projectId,
      selectedProject,
      memberId,
      selectedMember,
      from,
      to,
      showResetFilter,
      activityType:activityTypedata
    });
    this.setState({activityType:activityTypedata});
  }
  
  updateApplyClick=(flag)=>{
    this.setState({applyClick:flag});
  }

  render() {
    const {
      from,
      to,
      projectId,
      selectedProject,
      memberId,
      selectedMember,
      activityType,
      applyClick
    } = this.state;
    const {
      allProjectList,
      currentUser,
      currentWorkSpace,
      getUserDetailsAction,
      loggedInUserDetails,
      fetchTimesheetAction,
      getTimeSheetsAction,
      workSpaceUsers,
      timeSheetListLoaderState,
      listOfTimeSheet,
      searchContent,
      // searchedListOfTimeSheet,
      filters,
      updateSortAction,
      sort,
      deleteTimeSheetAction,
      totalTimeForTheDay,
      updateTimeSheetList,
      updateTotalTimeAction,
      getSelectedWorkpsaceActivities,
      activityTypeData,
      router
    } = this.props;
    let projectListByAccess = [];
    let isSuperAdmin = null;
    if (allProjectList && currentWorkSpace) {
      projectListByAccess = getProjectsByAccess(
        allProjectList,
        currentUser,
        currentWorkSpace
      );
      isSuperAdmin = getIsCreator(currentWorkSpace) || getIsSuperAdmin(currentUser, currentWorkSpace)
        ? true
        : getIsAccessToReports(projectListByAccess.filter(project => project.id === projectId)[0]);
    }
    const workspaceUsersWithDeleted = _.uniqBy([ ...workSpaceUsers, ...this.state.wpRemovedMembers ], "id");
    const selectedProjectMembers = projectId !== "all" ? workspaceUsersWithDeleted
      .filter(user => projectListByAccess.filter(proj => proj.id === projectId)[0]?.data.acl.includes(user.id)) : [];
    return (
      <React.Fragment>
        <div className="report-filter-box">
          <div className="comm-filter-container">
            <div className="comm-filter-outer-holder newdesign">
              <div className="comm-filter-control-title">Project </div>
              {
                <ProjectDropDown
                  // ref={this.projectDropdown}
                  projectList={projectListByAccess}
                  selectedProjectId={projectId}
                  selectedProject={selectedProject}
                  onChangeProject={this.onChangeProject}
                />
              }
            </div>

            <div className="comm-filter-control-container newdesign">
              <div className="comm-filter-control-title">Activity Type </div>
              {
                <ActivityTypeDropDown
                
                  activityTypeList={activityTypeData}
                  selectedActivityType={activityType}
                  onSelectactivityType={this.onSelectactivityType}
                />
              }
            </div>

            <div className="comm-filter-control-container newdesign">
              <div className="comm-filter-control-title">Member </div>
              {
                <MemberDropDown
                  // ref={this.memberDropDown}
                  isSuperAdmin={isSuperAdmin}
                  workSpaceUsers={projectId !== "all" ? selectedProjectMembers : workspaceUsersWithDeleted}
                  selectedMemberId={memberId}
                  selectedMember={selectedMember}
                  onChangeMember={this.onChangeMember}
                />
              }
            </div>
            <div className="comm-filter-control-container date-range">
            <div className="comm-filter-control-title date-title">Date </div>
              <DateRangePicker
                startDate={moment(from).format("M/DD/YYYY")}
                endDate={moment(to).format("M/DD/YYYY")}
                maxDate={moment(new Date()).format("M/DD/YYYY")}
                onChangeDate={this.onChangeDate}
              >
                <div className="date-img date-picker">{`${from} - ${to}`}</div>
              </DateRangePicker>
            </div>
            <div className="btn-filter-apply" onClick={this.onApply}>
              Apply
            </div>
            {filters.showResetFilter && (
              <div className="clear-filter" onClick={this.onResetFilter}>
                Reset filter
                {/* <span onClick={this.onResetFilter}>Reset filter</span> */}
              </div>
            )}
          </div>
        </div>
        <TimeSheetTable
          router={router}
          listOfTimeSheet={listOfTimeSheet}
          searchContent={searchContent}
          updateSortAction={updateSortAction}
          getUserDetailsAction={getUserDetailsAction}
          loggedInUserDetails={loggedInUserDetails}
          fetchTimesheetAction={fetchTimesheetAction}
          getTimeSheetsAction={getTimeSheetsAction}
          sort={sort}
          filters={filters}
          timeSheetListLoaderState={timeSheetListLoaderState}
          deleteTimeSheetAction={deleteTimeSheetAction}
          totalTimeForTheDay={totalTimeForTheDay}
          updateTotalTimeAction={updateTotalTimeAction}
          projectList={projectListByAccess}
          updateTimeSheetList={updateTimeSheetList}
          getSelectedWorkpsaceActivities={getSelectedWorkpsaceActivities}
          from={from}
          to={to}
          applyClick={applyClick}
          updateApplyClick={this.updateApplyClick}
          selectedProject={selectedProject}
          selectedActivityType={activityType}
          selectedMember={selectedMember}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentWorkSpace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    workSpaceUsers: state.peopleReducer.workspaceusers,
    loggedInUserDetails: state.loginReducer.loginAuth.user_details,
    timeSheetListLoaderState: state.timeSheetReducer.timeSheetListLoaderState,
    listOfTimeSheet: state.timeSheetReducer.listOfTimeSheet.list,
    filters: state.timeSheetReducer.filters,
    sort: state.timeSheetReducer.sort,
    totalTimeForTheDay: state.commonReducer.totalTimeForTheDay,
    searchContent: state.commonReducer.searchContent.text,
    allProjectList: state.projectReducer.listOfProjects.list.sort((a, b) => {
      if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
      if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;
      return 0;
    }),
    activityTypeData:state.timeSheetReducer.activityTypeData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetailsAction({ queryparams }, callback) {
      dispatch(action.getUserDetailsAction({ queryparams }, callback));
    },
    updateFilterAction: (filters) => {
      dispatch(action.updateFilterAction(filters));
    },
    updateSortAction: (sort) => {
      dispatch(action.updateSortAction(sort));
    },
    getTimeSheetsAction: ({ auth_token, queryParam }, callback) => {
      dispatch(
        action.getTimeSheetsAction({ auth_token, queryParam }, callback)
      );
    },
    updateTimeSheetList: ({ action, payload }) => {
      dispatch(updateTimeSheetList({ action, payload }));
    },
    deleteTimeSheetAction: ({ auth_token, body }, callback) => {
      dispatch(action.deleteTimeSheetAction({ auth_token, body }, callback));
    },
    updateTotalTimeAction: (totalTime) => {
      dispatch(updateTotalTimeAction(totalTime));
    },
    getAllActivityType: ({param,displaySpinner}) =>{
      dispatch(getAllActivityTypeAction({param,displaySpinner}));
    },
    getProjectAction({ auth_token, queryParam }, callback) {
      dispatch(action.getProjectsAction({ auth_token, queryParam }, callback));
    },
    clearTimesheetAction() {
      dispatch(clearTimesheetAction());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportLanding);
