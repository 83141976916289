import {
  archiveOrUnArchiveActivity
} from "../../services/workspaceapi";
import { ArchivedConstant as archivedConstant } from "../../constants/archivedConstant";
import { workspaceConstant } from "../../constants/workspaceconstants";
import { activities } from "../../constants/activitiescontants";
import { updateArchivedActivityList } from "../workspace/workspaceaction"
import {
  changeActivityWorkFlow,
  updateActivity,
  getArchivedActivities,
  getSearchedArchivedActivities
} from "../../services/activityapis";
import {
  getProjectDetails,
  updateProjectDetails
} from "../../services/projectapi";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: state
  };
}

function getArchivedActivitiesForReducer({ activitiesList, item_per_page, page }) {
  return {
    type: archivedConstant.getActivity,
    payload: {
      activities: activitiesList,
      key: new Date().getTime().toLocaleString(),
      item_per_page,
      page
    }
  };
}

function getArchivedSearchedActivitiesForReducer({ activitiesList }) {
  return {
    type: archivedConstant.getSearchedArchivedActivity,
    payload: {
      activities: activitiesList,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function unarchiveActivities(activity) {
  return {
    type: archivedConstant.unarchiveActivity,
    payload: { activity }
  };
}

function updateWorkflow(activity) {
  return {
    type: archivedConstant.updateArchiveActivityWorkFlow,
    payload: {
      activity
    }
  };
}

function updateActivityArray(activity) {
  return {
    type: archivedConstant.updateActivity,
    payload: {
      activity
    }
  };
}

function getArchivedprojects(projects) {
  return {
    type: archivedConstant.getProject,
    payload: { projects }
  };
}

function unarchiveProjects(project) {
  return {
    type: archivedConstant.unarchiveProject,
    payload: { project }
  };
}

export const getArchivedActivitiesAction = (
  { activity_type, is_active, user_id, is_archieved = true, page, per_page },
  callback
) => dispatch => {
  getArchivedActivities(
    { activity_type, is_active, user_id, is_archieved, page, per_page },
    response => {
      if (response && !response.stdError) {
        dispatch(
          getArchivedActivitiesForReducer({
            activitiesList: response,
            item_per_page: per_page,
            page: page
          })
        );
      }

      callback && callback(response);
    }
  );
};

export const unarchiveActivitiesAction = (
  { auth_token, archiveData },
  callback
) => dispatch => {
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError) {
      dispatch(unarchiveActivities(response));
    }
    callback && callback(response);
  });
};

export const updateArchiveActivitiesWorkFlowAction = (
  { id, workflow, customer_auth_token },
  callback
) => dispatch => {
  changeActivityWorkFlow({ id, workflow, customer_auth_token }, response => {
    if (response && !response.stdError) {
      response.workflow = workflow;
      dispatch(updateWorkflow(response));
    }
    callback && callback(response);
  });
};

export const updateArchiveActivityAction = (
  {
    id,
    due_on,
    start_on,
    duration,
    hours,
    minutes,
    project_id,
    tags,
    title,
    user_id,
    project,
    version_id,
    data
  },
  callback
) => dispatch => {
  updateActivity(
    {
      id,
      due_on,
      start_on,
      duration,
      hours,
      minutes,
      project_id,
      tags,
      title,
      user_id,
      project,
      version_id,
      data
    },
    response => {
      dispatch(
        updateActivityArray({
          id,
          due_on,
          start_on,
          duration,
          hours,
          minutes,
          project_id,
          tags,
          title,
          user_id,
          version_id,
          project,
          data
        })
      );
      callback && callback(response);
    }
  );
};

export const getArchivedProject = ({ queryParam }, callback) => dispatch => {
  getProjectDetails({ queryParam }, response => {
    if (response && !response.stdError) {
      dispatch(getArchivedprojects(response));
      callback && callback(response);
    }
  });
};

export const unArchivedArchivedProject = (
  { auth_token, body },
  callback
) => dispatch => {
  updateProjectDetails({ auth_token, body }, response => {
    if (response && !response.stdError) {
      dispatch(unarchiveProjects(response));
      callback && callback(response);
    }
  });
};

export const getSearchedArchivedActivitiesAction = (params, callback) => dispatch => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.activityLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getSearchedArchivedActivities(params, response => {
      if (response && !response.stdError) {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: null
            }
          })
        );
        dispatch(updateArchivedActivityList(response, activities.completed));
      } else {
        dispatch(
          updateLoaderState({
            action: workspaceConstant.activityLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: response.stdErr
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const clearSearchedArchivedActivitiesAction = () => dispatch => {
  dispatch(getArchivedSearchedActivitiesForReducer({ activitiesList: [] }));
}