/**
 * Author: Madhusudhan Seetharamiah
 */
import {
  getUserDetails,
  updateUserPassword,
  updateUserProfile,
  updateUserFirstTimeLogin
} from "../../services/userapi";
import { uploadDataToS3Bucket } from "../../services/awsapi";
import { commonContants } from "../../helpers/commonconstants";

export function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export function updateTabState({ projectsLanding }) {
  return {
    type: commonContants.updateTabState,
    payload: {
      projectsLanding
    }
  };
}

export function updateDashboardTabState({ dashboardLanding }) {
  return {
    type: commonContants.updateDashboardTabState,
    payload: {
      dashboardLanding
    }
  };
}

export function updatedSearchText({ searchtext, activeClass }) {
  return {
    type: commonContants.updateSearchTextAction,
    payload: {
      text: searchtext,
      context: activeClass
    }
  };
}

export function expandNavBar(navbarState) {
  return {
    type: commonContants.handleNavBarAction,
    payload: navbarState
  };
}

export function changeTimerStatus(timerRunningStatus) {
  return {
    type: commonContants.timerIsRunningAction,
    payload: { timerRunningStatus }
  };
}

export function updateOwners(owners) {
  return {
    type: commonContants.getOwners,
    payload: { owners }
  }
}

export function updateMembers(members) {
  return {
    type: commonContants.getMembers,
    payload: { members }
  }
}

export const getUserDetailsAction = ({ queryparams, type }, callback) => (
  dispatch
) => {
  getUserDetails({ queryparams }, (response) => {
    if (response && !response.error) {
      if(type === "OWNERS") dispatch(updateOwners(response));
      if(type === "MEMBERS") dispatch(updateMembers(response));
    }
    callback && callback(response);
  });
};

export const updateUserPasswordAction = (
  { old_password, new_password, user_id },
  callback
) => (dispach) => {
  dispach(
    updateLoaderState({
      action: commonContants.updateUserPasswordLoaderAction,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  updateUserPassword({ old_password, new_password, user_id }, (response) => {
    if (response && !response.stdErr && !response.error) {
      dispach(
        updateLoaderState({
          action: commonContants.updateUserPasswordLoaderAction,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      //
    } else {
      dispach(
        updateLoaderState({
          action: commonContants.updateUserPasswordLoaderAction,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.error || response.stdErr || "Some Error Occured!"
            }
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateUserProfileAction = (
  { first_name, id, organization_name, country, email, img, tags, data },
  callback
) => (dispach) => {
  dispach(
    updateLoaderState({
      action: commonContants.updateUserProfileLoaderAction,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  updateUserProfile(
    {
      first_name,
      id,
      organization_name,
      country,
      email,
      img,
      tags,
      data
    },
    (response) => {
      if (response && !response.stdErr && !response.error) {
        dispach(
          updateLoaderState({
            action: commonContants.updateUserProfileLoaderAction,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: false,
                value: ""
              }
            }
          })
        );
      } else {
        dispach(
          updateLoaderState({
            action: commonContants.updateUserProfileLoaderAction,
            state: {
              fetching: false,
              fetched: true,
              error: {
                bool: true,
                value:
                  response.error || response.stdErr || "Some Error Occured!"
              }
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const updateUserFirstTimeFlagAction = ({auth_token,first_time_logged_in,id}) =>{
  updateUserFirstTimeLogin( {
    auth_token,
    first_time_logged_in,
    id
  })
}

export const uploadPhotoToS3Bucket = (
  { file, loaderKey = commonContants.updateUserPhotoLoaderAction },
  callback
) => (dispach) => {
  dispach(
    updateLoaderState({
      action: loaderKey,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  uploadDataToS3Bucket({ file }, (response) => {
    if (response && !response.stdErr && !response.error) {
      dispach(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      //
    } else {
      dispach(
        updateLoaderState({
          action: loaderKey,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value:
                response.error ||
                response.stdErr ||
                "Error occured during upload!"
            }
          }
        })
      );
    }
    callback && callback(response);
  });


};
