import React, { useState } from "react";
import moment from "moment";
import { getDateString } from "../../helpers/generichelper";
import MasterCardSVG from "../../styles/img/svg/sample-card-small.png";
import AmericanExpressSVG from "../../styles/img/svg/card-logo-amex.svg";
import VisaSVG from "../../styles/img/svg/card-logo-visa.svg";
import DiscoverSVG from "../../styles/img/svg/discover.png";
import UnknownCardSVG from "../../styles/img/svg/card-logo-unknown.svg";

import PaymentDetails from "./paymentDetails";
import firebase from "../../firebase";

function BillingForm({
  subscriptionStatus,
  currencyLogo,
  billingAmounts,
  billingInfo,
  chargesInfo,
  invoiceData,
  updateCustomerDetails,
  onUpdatingCardDetails,
  customerDetails,
  onShowCancelSubscriptionPopup,
  reactivateSubscription,
  onShowAnnualPopup,
  updateSubscription,
  updateSubscriptionRes
}) {
  const subsDate =
    billingInfo.data &&
    moment.unix(billingInfo.data[0].current_period_end).format("LL");

  const annualChangeDateStart =
    billingInfo.data &&
    moment
      .unix(billingInfo.data[0].current_period_end)
      .subtract(1, "month")
      .format("LL");

  const annualChangeDateEnd =
    billingInfo.data &&
    moment
      .unix(billingInfo.data[0].current_period_end)
      .subtract(1, "day")
      .format("LL");

  const shouldShowSwitchToMonthly =
    billingInfo.data &&
    moment(new Date()).isBetween(
      moment
        .unix(billingInfo.data[0].current_period_end)
        .subtract(1, "month")
        .format(),
      moment
        .unix(billingInfo.data[0].current_period_end)
        .subtract(1, "day")
        .format(),
      null,
      "[]"
    );

  

  

  const [billingHistory, setbillingHistory] = useState(false);

  const previousChargesDetails =
    chargesInfo &&
    chargesInfo.data &&
    chargesInfo.data.map(charge => {
      return (
        <div className="bill-history-row" key={charge.id}>
          <div className="bill-history-date">
            {getDateString(charge.created)}
          </div>
          <div className="bill-history-card">
            <div className="bill-history-icon">
              {charge.source.brand === "Master Card" && (
                <img src={MasterCardSVG} width="25" alt="master-card" />
              )}
              {charge.source.brand === "American Express" && (
                <img src={AmericanExpressSVG} width="25" alt="american" />
              )}
              {charge.source.brand === "Visa" && (
                <img src={VisaSVG} width="25" alt="visa" />
              )}
              {charge.source.brand === "Discover" && (
                <img src={DiscoverSVG} width="25" alt="discover" />
              )}
              {(charge.source.brand !== "American Express" &&
                charge.source.brand === "Discover" &&
                charge.source.brand === "Visa" &&
                charge.source.brand === "Master Card") ||
                (charge.source.brand === "Unknown" && (
                  <img src={UnknownCardSVG} width="25" alt="unknown" />
                ))}
            </div>
            <div className="bill-history-number">XXXX</div>
            <div className="bill-history-number">XXXX</div>
            <div className="bill-history-number">XXXX</div>
            <div className="bill-history-number">{charge.source.last4}</div>
          </div>
          <div className="bill-history-date">
            Paid: {(charge.amount / 100).toFixed(2)}
          </div>
          <a
            className="bill-switch-plan"
            href={charge.receipt_url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => firebase.analytics().logEvent("GTD_Billing_Receipt")}
          >
            Receipt
          </a>
        </div>
      );
    });
  if (billingInfo.data) {
    return (
      <div className="billing-form-holder">
        <div className="billing-form-inner-holder">
          {subscriptionStatus === "active" && (
            <div className="bill-switch-plan-container">
              {billingInfo.data[0].plan.nickname === "Monthly" && (
                <div className="bill-thanks-subscribe">
                  Thank you for Subscribing. You will be next billed{" "}
                  <span>{currencyLogo}{billingAmounts.next_billing_amount && (billingAmounts.next_billing_amount).toFixed(2)}</span>{" "}
                  on {subsDate}.
                </div>
              )}

              {billingInfo.data[0].plan.nickname === "Yearly" && (
                <div className="bill-thanks-subscribe">
                  {updateSubscriptionRes
                    ? "Switched to annual plan."
                    : "Thank you for Subscribing."}{" "}
                  You will be next billed{" "}
                  <span>
                    {currencyLogo}{billingAmounts.next_billing_amount && (billingAmounts.next_billing_amount).toFixed(2)}
                  </span>{" "}
                  on {subsDate}.{" "}
                  {updateSubscriptionRes && (
                    <div className="switch-month-msg">
                      You can switch back to monthly plan from{" "}
                      {annualChangeDateStart} to {annualChangeDateEnd}.
                    </div>
                  )}
                </div>
              )}

              {billingInfo.data[0].plan.nickname === "Monthly" && (
                <div className="bill-switch-plan" onClick={onShowAnnualPopup}>
                  Switch to Annual Plan
                </div>
              )}
              {billingInfo.data[0].plan.nickname === "Yearly" &&
                shouldShowSwitchToMonthly && (
                  <div
                    className="bill-switch-plan"
                    onClick={() => {
                      
                      updateSubscription("monthly");
                    }}
                  >
                    Switch to Monthly Plan
                  </div>
                )}
            </div>
          )}
          {/* for the case of expired */}
          {/* <div className="bill-plan-renew-container" ng-if="subscriptionExpired">
          <div className="bill-thanks-subscribe">
            Your Paid Plan has expired. To redefine your work, renew
            subscription NOW.
          </div>
          <button className="billing-btn renew">Renew Subscription</button>
        </div> */}
          {subscriptionStatus === "cancelled" && (
            <div className="bill-plan-renew-container">
              {billingInfo.data[0].plan.nickname === "Monthly" && (
                <div className="bill-thanks-subscribe">
                  You have cancelled Get Things Done Monthly Plan. Your Paid
                  Plan will be active till {subsDate}.
                </div>
              )}
              {billingInfo.data[0].plan.nickname === "Yearly" && (
                <div className="bill-thanks-subscribe">
                  You have cancelled Get Things Done Annual Plan. Your Paid Plan
                  will be active till {subsDate}.
                </div>
              )}
              <button
                className="billing-btn reactivate"
                onClick={reactivateSubscription}
              >
                Reactivate Plan
              </button>
            </div>
          )}
          {invoiceData && chargesInfo && (
            <PaymentDetails
              invoiceData={invoiceData}
              chargesInfo={chargesInfo}
              updateCustomerDetails={updateCustomerDetails}
              onUpdatingCardDetails={onUpdatingCardDetails}
              customerDetails={customerDetails}
            ></PaymentDetails>
          )}
          {subscriptionStatus === "active" && (
            <div
              className="bill-cancel-subscription"
              onClick={onShowCancelSubscriptionPopup}
            >
              cancel subscription
            </div>
          )}
          {/* <div
            className="bill-cancel-subscription"
            ng-if="subscriptionCancelled"
          ></div> */}
          <div
            className={
              billingHistory
                ? "active bill-faq-heading billing-history"
                : "bill-faq-heading billing-history"
            }
            onClick={() => {
              firebase.analytics().logEvent("GTD_Billing_History");
              setbillingHistory(!billingHistory)
            }}
          >
            Billing History
          </div>
          {billingHistory && (
            <div className="bill-history-container">
              {previousChargesDetails}
              {chargesInfo &&
                chargesInfo.data &&
                chargesInfo.data.length > 3 && (
                  <div className="bill-switch-plan sm">Show more</div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default BillingForm;
