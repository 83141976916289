import React,{ Component } from 'react';
import GTDLogo from "../../styles/img/svg/GTD-WEB.png";
import RightArrow from "../../styles/img/svg/archive-view-more.svg";
import "../../styles/scss/web_tour.scss";
import routesHelper from "../../router/routeshelper";
import firebase from "../../firebase";



class  WelcomePage extends Component {

    submitAppTour=()=>{
        firebase.analytics().logEvent("GTD_Registration_Flow_Tutorial");
        const { router } = this.props;
        localStorage.setItem("firsttimeprofile",false);
        localStorage.setItem("firstTime", true);
        router.push(routesHelper.getRootPath());
    }
    render(){
      
        return(
            <div   className="mainStyle">
                <div  className="welcomediv" >
                    <div className="welcomelogo" >
                      <img src={GTDLogo}  alt="Get Things Done" />
                    </div>
                    
                    <div className="welcomemessage">
                      <span>
                         Welcome to Get Things Done
                        </span> 
                    </div>    
                    
                    <div className="welcomequote">
                        <span>
                             Prioritize work and achieve 10x productivity
                        </span>
                    </div>  
    
                    <div className="welcometour" onClick={this.submitAppTour} >
                        <span className="welcometourname">
                                Tutorial
                        </span>
                        <div className="welcometourarrow" >
                            <img src={RightArrow}  alt="Get Things Done Tour" />
                        </div> 
                    </div>      
                      
                </div>
                 
                    
                
            </div>    
        )
    }
   
}

export default WelcomePage;