// import routerHelper from "../router/routeshelper";
import _ from "lodash";
import { getRandomImage } from "../services/unsplashaapi";
import { differenceWith, slice } from "ramda";
import moment from "moment";
import firebase from "../firebase";
import { getFcmToken } from "../helpers/notificationHelper";
import { deleteFirebaseUserToken } from "../services/notificationapis";
import momentDurationFormatSetup from "moment-duration-format";
// import color from "@material-ui/core/colors/blue";
import { projectConstants } from "../constants/projectconstants";
momentDurationFormatSetup(moment);

export const parseJSONData = (response) => {
  return response
    .text()
    .then((text) => {
      return text ? JSON.parse(text) : {};
    })
    .catch((error) => {
      return {
        data: [],
        stdErr: "error"
      };
    });
};

export function logout() {
  //destroy the user token
  if(getFcmToken()) {
    deleteFirebaseUserToken({ fcm_token: getFcmToken(), device_type: "web" }, response => {
      firebase.messaging().deleteToken(getFcmToken());
    });
  }
  // localStorage.removeItem("auth_token");
  localStorage.clear();
  // location.replace(routerHelper.getLoginPath());
  window.location.reload();
}

export const isEmailValid = (email) => {
  if (email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }
  return false;
};

export const getUnsplashImage = () => {
  let splashImage = "https://source.unsplash.com/random";
  let author = null;
  let author_link = null;
  let credits_link =
    "https://unsplash.com/?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit";
  getRandomImage((response) => {
    if (response && !response.stdErr) {
      splashImage = response.urls.regular;
      author = response.user.name;
      author_link =
        "https://unsplash.com/@" +
        response.user.username +
        "?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit";
    }
  });

  return {
    credits_link,
    author_link: author_link,
    author: author,
    style:
      "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" +
      splashImage +
      ") no-repeat"
  };
};

export const getProfileDetails = () => {
  return {
    username: localStorage.getItem("username"),
    id: localStorage.getItem("id"),
    first_name: localStorage.getItem("first_name"),
    last_name: localStorage.getItem("last_name"),
    email:
      localStorage.getItem("email") && localStorage.getItem("email") !== "null"
        ? localStorage.getItem("email")
        : "",
    team:
      localStorage.getItem("tags") && localStorage.getItem("tags") !== "null"
        ? localStorage.getItem("tags")
        : "",
    img:
      localStorage.getItem("img") && localStorage.getItem("img") !== "null"
        ? localStorage.getItem("img")
        : null,
    created_at:
      localStorage.getItem("created_at") &&
      localStorage.getItem("created_at") !== "null"
        ? localStorage.getItem("created_at")
        : null,
    data: localStorage.getItem("data")
      ? JSON.parse(localStorage.getItem("data"))
      : {},
    first_time_logged_in: localStorage.getItem("first_time_logged_in")
      ? localStorage.getItem("first_time_logged_in")
      : false
  };
};

export const updateProfileDetails = (userProfile) => {
  localStorage.setItem("username", userProfile.username);
  localStorage.setItem("id", userProfile.id);
  localStorage.setItem("first_name", userProfile.first_name);
  localStorage.setItem("email", userProfile.email);
  localStorage.setItem("tags", userProfile.tags);
  localStorage.setItem("img", userProfile.img);
};

export const updateLocalStorgae = (response) => {
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const element = response[key];
      if (key === "data") {
        //console.log(key);
        localStorage.removeItem(key, JSON.stringify(element));
        localStorage.setItem(key, JSON.stringify(element));
      } else {
        localStorage.setItem(key, element);
      }
    }
  }
};

export const getDefaultClientLogo = () => {
  return "https://s3.amazonaws.com/gtd/gtd-placeholder.png";
};

export const getDefaultUserLogo = () => {
  return "https://s3.amazonaws.com/gtd/users/default.png ";
};

export const storeObjectInSessionStorage = (key, object = {}) => {
  key && localStorage.setItem(key, JSON.stringify(object));
};

export const getObjectFromSessionStorage = (key) => {
  return key && JSON.parse(localStorage.getItem(key));
};

export const removeObjectFromSessionStorage = (key) => {
  key && localStorage.removeItem(key);
};

export const omitItemsFromList = (key = "id", toBeOmitedList, list) => {
  let omitedList = list;
  const cmp = (x, y) => x[key] === y[key];
  if (toBeOmitedList && toBeOmitedList.length) {
    omitedList = differenceWith(cmp, list, toBeOmitedList);
  }
  return omitedList;
};

export const buildQueryParams = (params) => {
  let queryParams = "";
  params.forEach((element, index) => {
    if (index === 0) {
      queryParams.concat("?" + element.key + "=" + element.value);
    } else {
      queryParams.concat("&" + element.key + "=" + element.value);
    }
  });
  return queryParams;
};

export const getBuketItemsFromList = (list, bucketSize) => {
  if (list && list.length && bucketSize) {
    return list.length < bucketSize ? list : slice(0, bucketSize, list);
  }

  return list;
};

export const convertTimeToString = (ttSeconds) => {
  var hours = Math.floor(ttSeconds / 3600);
  var mins = Math.floor((ttSeconds % 3600) / 60);
  var hoursString = hours > 0 ? hours.toString() + "h" : false;
  var minsString = mins > 0 ? mins.toString() + "m" : false;

  if (hoursString && minsString) {
    return hoursString + " " + minsString;
  } else if (hoursString && !minsString) {
    return `${hoursString} 0m`;
  } else if (!hoursString && minsString) {
    return `0h ${minsString}`;
  } else if (!hoursString && !minsString) {
    return "0h 0m";
  }
};

export const asyncOrderBy = (array, key, callback) => {
  const sortedArray = _.orderBy(array, key, ["desc"]);

  callback(sortedArray);
};

export const sort_by_key = (array, key) => {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hours = h > 0 ? h : 0;
  var mins = m > 0 ? m : 0;
  var seconds = s > 0 ? s : 0;
  return { hours, mins, seconds };
};

export function time_convert(num)
 { 
  var hours = Math.floor((num*60) / 60);  
  var minutes = Math.floor((num*60) % 60);
  return { hours, minutes };         
}

export const sortComments = (comments) => {
  let parentComments = _.filter(
    comments,
    (comment) => comment.data && !comment.data.commentDetails.isReply
  );
  parentComments = _.sortBy(parentComments, function(o) {
    return new moment(o.created_at);
  }).reverse();
  let replyComments = {};
  _.forEach(comments, (comment) => {
    if (comment.data && comment.data.commentDetails.isReply) {
      if (replyComments[comment.data.commentDetails.parentCommentId]) {
        replyComments[comment.data.commentDetails.parentCommentId].push(
          comment
        );
      } else {
        replyComments[comment.data.commentDetails.parentCommentId] = [comment];
      }
    }
  });
  for (let i = 0; i < parentComments.length; i++) {
    if (!_.isArray(parentComments[i])) {
      if (replyComments[parentComments[i].id]) {
        let sortedWithDate = _.sortBy(
          replyComments[parentComments[i].id],
          function(o) {
            return new moment(o.created_at);
          }
        );
        parentComments.splice(i + 1, 0, sortedWithDate);
      }
    }
  }
  return parentComments.flat();
};

export const commonDateFormat = (date) => {
  var dateObj = date;
  var yesterday = moment(new Date())
    .subtract(1, "days")
    .format();
  if (
    moment(dateObj).format("YYYY-MM-DD") >
    moment(yesterday).format("YYYY-MM-DD")
  ) {
    var dateObjUtc = moment.utc(dateObj).format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
    var nowUtc = moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
    var dateFormat = moment(dateObjUtc)
      .startOf("seconds")
      .from(nowUtc);
    return dateFormat;
  }
  if (
    moment(dateObj).format("YYYY-MM-DD") ===
    moment(yesterday).format("YYYY-MM-DD")
  ) {
    var dateFormat = moment(dateObj).calendar();
    return dateFormat;
  }
  if (
    moment(dateObj).format("YYYY-MM-DD") <
    moment(yesterday).format("YYYY-MM-DD")
  ) {
    // var day = moment(dateObj).format('ddd, D MMM');
    var day = moment(dateObj).format("ddd, DD MMM YY");
    var time = moment(dateObj).format("hh:mm A");
    var dateFormat = day.toString() + " at " + time.toString();
    return dateFormat;
  }
};

export const parseComment = (comment, notifyId=null) => {
  
  var urls = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
  var emails = /[^@]+@[^\.]+\..+/g;
  var newMsg = comment.split(" ");
  var arrayWithNewLineAndSpace = [];


  newMsg.forEach(function(item) {
    var newLine = item.split("\n");
    newLine.forEach(function(eachItem, index) {
      if (newLine.length - 1 !== index) {
        newLine[index] = eachItem + "\n";
      }
    });
    arrayWithNewLineAndSpace = arrayWithNewLineAndSpace.concat(newLine);
  });
  var addedMsg = "";
  arrayWithNewLineAndSpace.forEach(function(item, index, arr) {
  
    if (item.charAt(0) === "@") {
      arr[index] = '<span class="mention-highlight">' + item + "</span>";
    }else if(item.includes('@') && item.charAt(item.indexOf('@')-1)==='>'){

      let startString=item.substring(0,item.indexOf('@'));
      if( item.includes('</')){
        arr[index] =startString+'<span class="mention-highlight">' + item.substring(item.indexOf('@'),item.indexOf('</')) + "</span>"+item.substring(item.indexOf('</'));
      }else{
        arr[index] =startString+'<span class="mention-highlight">' + item.substring(item.indexOf('@')) + "</span>";
      }
      
    } else
    if(item === "\n" || item === "<p></p>\n") {
      arr[index] = '<br />';
    } else
    if (item.match(urls) && !notifyId && item.indexOf("href=") < 0 && item.indexOf('target="_self"') < 0) {
      var link =
        '<a class="notifi-anchor-link" href="' +
        decodeURI(item.replace( /(<([^>]+)>)/ig, '')) +
        '" target="_blank" onClick="event.stopPropagation()">' +
        decodeURI(item.replace( /(<([^>]+)>)/ig, '')) +
        "</a>";
      arr[index] = item.replace(urls, link);
    } else
    if (item.indexOf("href=") > -1) {
      let aTagWithClass = notifyId ? 
        item.concat(` class="notifi-anchor-link notifi-a-tag-${notifyId}"`)
        :
        item.concat(' class="notifi-anchor-link"');
      arr[index] = item.replace(item, aTagWithClass);
    } else
    if (item.indexOf('target="_self"') > -1) {
      arr[index] = item.replace('target="_self"', 'target="_blank"')
    } else
    if (item.match(urls) && notifyId) {
      var link =
        '<a class="notifi-anchor-link notifi-a-tag-'+ notifyId +'" href="' +
        decodeURI(item.replace( /(<([^>]+)>)/ig, '')) +
        '" target="_blank" onClick="event.stopPropagation()">' +
        decodeURI(item.replace( /(<([^>]+)>)/ig, '')) +
        "</a>";
      arr[index] = item.replace(urls, link);
    } else
    if (item.match(emails)) {
      var mailLink =
        '<a class="notifi-anchor-link" href="mailto:' +
        item +
        '" onClick="event.stopPropagation()">' +
        item +
        "</a>";
      arr[index] = item.replace(emails, mailLink);
    }
    addedMsg = addedMsg + arr[index] + " ";
  });
  return addedMsg;
};

export const parseCommentEditor = (comment) => {
  
  let commentArray=comment.split('\n');
  let lengthArray=commentArray.length;
  let finalCommentMsg='<p>'+commentArray[0]+'</p>';
  
  if(commentArray && lengthArray>1){
    for(let i=0;i<lengthArray;i++){
      finalCommentMsg=finalCommentMsg+'\n<p>'+commentArray[i]+'</p>';
    }
    
  }
  return  finalCommentMsg;
};

export const getShouldCreateWorkspace = (listOfCustomer, userDetails) => {
  let shouldCreateWorkspace = true;
  if (Array.isArray(listOfCustomer) && listOfCustomer.length > 0) {
    listOfCustomer.forEach((customer) => {
      if (customer.super_admin_id === parseInt(userDetails.id)) {
        shouldCreateWorkspace = false;
      }
    });
  }
  // console.log("create Workspace", shouldCreateWorkspace)
  return shouldCreateWorkspace;
};

export const getDateValue = (date) => {
  return moment(new Date())
    .add(1, "years")
    .format("LL");
};

export const getDateString = (date) => {
  return moment.unix(date)
    .format("LL");
};

export const getPosition = (string, subString, index) => {
  return string.split(subString, index).join(subString).length;
}

export const getCurrencyLogo = (workspace) => {
  if(workspace && workspace.data && workspace.data.settings) {
    let currency = workspace.data.settings.currency;
    return currency.substring(currency.indexOf("(") + 1, currency.indexOf(")"));
  }
  return "$";
}

export const getCurrencyCode = (workspace) => {
  if(workspace && workspace.data && workspace.data.settings) {
    let currency = workspace.data.settings.currency;
    return currency.substring(0, currency.indexOf("("));
  }
  return "USD";
}

export const getIsAccessToAddRemoveMember = selectedProject => {
  if(selectedProject.data &&
      selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]) {
    return selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]
              .widget.includes(projectConstants.widgets.addRemoveMember);
  }
  return false;
}

export const getIsManager = selectedProject => {
  if(selectedProject?.data &&
      selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]) {
    return selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]
              .access === "Manager";
  }
  return false;
}

export const getCustomRoutePath = (route, query) => {
  let queryString = `${route}?customer_id=${query.customer_id}`;
  if(query.activity_id) queryString = queryString.concat(`&activity_id=${query.activity_id}`);
  if(query.comment_id) queryString = queryString.concat(`&comment_id=${query.comment_id}`);
  if(query.parent_comment_id) queryString = queryString.concat(`&parent_comment_id=${query.parent_comment_id}`);
  if(query.checklist_id) queryString = queryString.concat(`&checklist_id=${query.checklist_id}`);
  if(query.checklistItemId) queryString = queryString.concat(`&checklistItemId=${query.checklistItemId}`);
  if(query.timesheet_date) queryString = queryString.concat(`&timesheet_date=${query.timesheet_date}`);
  if(query.timesheet_id) queryString = queryString.concat(`&timesheet_id=${query.timesheet_id}`);
  if(query.date) queryString = queryString.concat(`&date=${query.date}`);
  return queryString;
}
