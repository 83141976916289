import React, { useState, useEffect, useRef, memo } from "react";
import moment from "moment";
import _ from "lodash";
import ActivityBlockWeek from "./activityBlockweek.jsx";
import ActivityBlockMonth from "./activityBlockMonth.jsx";
import ActivityBlockDay from "./activityBlockDay.jsx";

function WorkloadViewComponent({
  filteredActivity,
  selectedUser,
  noOfGrids,
  currentMode,
  datesArray,
  groupedByDate,
  updateActivitiesAction,
  onShowActivityPopup,
  currentdateValue,
  showActivityPopup,
}) {
  const [filtered, setfiltered] = useState(filteredActivity);
  const [, setIsDragging] = useState(false);
  let renderFakeGridDiv = [];
  const [showAddActivityy, setshowAddActivityy] = useState(false);
  const ref = useRef(null);
  const spanRef = useRef(null);
  const refWeek = React.useRef(new Array(7));
  const spanWeek = React.useRef(new Array(7));
  const [scrollHeight, setScrollHeight] = useState(0);

  // const style = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "left",
  //   cursor: "pointer",
  //   background: "#ffffff",
  //   padding: "0 15px",
  //   borderRadius: "2px",

  // };
  let addActivityStyle = {
    opacity: 0,
    positon: "absolute",
    top: "1",
  };

  const mappedFilteredActivity = filtered.map(activity => {
    if (activity.data) {
      // console.log(activity.data.priority);
    }

    return {
      ...activity,

      prioritized:
        activity.data &&
        activity.data.priority.filter(user => user.user_id === selectedUser.id)
          .length > 0
          ? true
          : false,
    };
  });
  for (let i = 1; i <= noOfGrids; i++) {
    // console.log("day", moment(datesArray[0]).day());
    renderFakeGridDiv.push(
      <div
        className={
          noOfGrids > 7
            ? "fake-grid-div month"
            : noOfGrids === 1
            ? "fake-grid-div day"
            : "fake-grid-div"
        }
        key={Math.random()}
      >
        {noOfGrids > 7 &&
          i > moment(datesArray[0]).day() &&
          i - datesArray[0].day() <= datesArray.length && (
            <span>{i - datesArray[0].day()}</span>
          )}
      </div>
    );
  }

  let dayAddActivitty = null;
  let verticalheight = mappedFilteredActivity
    ? `${mappedFilteredActivity.length * 50 + 10}px`
    : "10px";

  let showAddActivityButton = event => {
    let element = ref.current.getBoundingClientRect();
    let spanelement = spanRef.current.getBoundingClientRect();

    if (spanelement.y > element.y - 10 && spanelement.x > element.x - 40) {
      document.getElementById(
        "dayIndex0"
      ).style.transform = `translate(${event.clientX -
        element.x -
        50}px, ${event.clientY - element.y - 10}px)`;
      document.getElementById("dayIndex0").style.opacity = "1";
    } else {
      document.getElementById("dayIndex0").style.opacity = "0";
      document.getElementById(
        "dayIndex0"
      ).style.transform = `translate(${event.screenX}px, ${event.screenY}px)`;
    }
  };

  let hideActivityButton = event => {
    document.getElementById("dayIndex0").style.opacity = "0";
  };

  let showAddActivityButtonWeekly = (event, index) => {
    let mainDivWeek = refWeek.current[index].getBoundingClientRect().y;
    if (event.clientY + 5 < mainDivWeek || !showAddActivityy) {
      setshowAddActivityy(false);
      document.getElementById("week" + index).style.opacity = "0";
      document.getElementById(
        "week" + index
      ).style.transform = `translate(5px, ${mainDivWeek - 30}px)`;
    } else {
      let value = event.clientY - mainDivWeek - 10;
      document.getElementById(
        "week" + index
      ).style.transform = `translate(5px, ${value}px)`;
      document.getElementById("week" + index).style.opacity = "1";
    }
  };

  let hideWeeklyAddActivity = (event, index) => {
    document.getElementById("week" + index).style.opacity = "0";
    setshowAddActivityy(false);
  };

  let setShowAddActvityFlag = event => {
    setshowAddActivityy(true);
  };

  {
    dayAddActivitty = currentMode.id === 0 && (
      <div
        ref={ref}
        onMouseLeave={hideActivityButton}
        onMouseMove={showAddActivityButton}
        className="workLoadRemainingwidth"
        style={{ transform: `translate(10px, ${verticalheight})` }}
      >
        <div
          ref={spanRef}
          id="dayIndex0"
          style={addActivityStyle}
          onClick={e => {
            const defaultDate =
              currentMode.id === 0 ? currentdateValue : datesArray[0];
            onShowActivityPopup({ defaultDate });
          }}
          className="content-holder content-holder-daily"
        >
          <span className="hover-activity">+ Add Activity </span>
        </div>
      </div>
    );
  }
  let initialX;
  let xWidth;
  if (document.getElementsByClassName("fake-grid-div")[0]) {
    xWidth =
      document.getElementsByClassName("fake-grid-div")[0].offsetWidth - 14;
  }

  let weekAddActivity =
    currentMode.id === 1 &&
    groupedByDate.map((data, index) => {
      let indexPositon = "week" + index;

      let verticalheightWeekLy =
        data.length && data.length > 0 ? `${data.length * 50 + 10}px` : "10px";
      if (document.getElementsByClassName("fake-grid-div")[0]) {
        initialX =
          document.getElementsByClassName("fake-grid-div")[0].offsetWidth *
          index;
      }
      return (
        <div
          key={index}
          ref={ref => {
            refWeek.current[index] = ref;
          }}
          onMouseEnter={setShowAddActvityFlag}
          className="workLoadRemainingwidthWeekely"
          onMouseLeave={event => hideWeeklyAddActivity(event, index)}
          onMouseMove={event => showAddActivityButtonWeekly(event, index)}
          style={{
            transform: `translate(${initialX}px, ${verticalheightWeekLy})`,
          }}
        >
          <div
            key={index}
            id={indexPositon}
            style={{ ...addActivityStyle, width: xWidth }}
            ref={ref => (spanWeek.current[index] = ref)}
            onClick={e => {
              const defaultDate =
                currentMode.id === 0 ? currentdateValue : datesArray[index];
              onShowActivityPopup({ defaultDate });
            }}
            className="content-holder content-holder-weekely"
          >
            <span className="hover-activity">+ Add Activity </span>
          </div>
        </div>
      );
    });

  let renderedActivityBlocks;

  switch (currentMode.id) {
    case 0:
      renderedActivityBlocks = mappedFilteredActivity.map((activity, index) => {
        return (
          <div
            className="activity-display-div activity-display-div-day"
            key={activity.activity && activity.activity.id}
            onClick={e => {
              // console.log("events", e);

              e.stopPropagation();
              showActivityPopup(activity);
            }}
          >
            <ActivityBlockDay
              activity={activity.activity}
              index={index}
              length={
                document.getElementsByClassName("fake-grid-div").length > 0
                  ? document.getElementsByClassName("fake-grid-div")[0]
                      .offsetWidth
                  : 0
              }
              selectedUser={selectedUser}
              scrollHeight={scrollHeight}
            ></ActivityBlockDay>
          </div>
        );
      });

      renderedActivityBlocks.push(dayAddActivitty);

      break;
    case 1:
      renderedActivityBlocks = mappedFilteredActivity.map((activity, index) => {
        return (
          <div
            className="activity-display-div activity-display-div-week"
            key={activity.activity && activity.activity.id}
            onClick={e => {
              // console.log("events", e);
              e.stopPropagation();
              showActivityPopup(activity);
            }}
          >
            <ActivityBlockWeek
              activity={activity.activity}
              noOfDays={datesArray.length}
              widthOfSingleGrid={100 / 7}
              position={{
                startIndex: activity.startIndex,
                lastIndex: activity.lastIndex,
              }}
              heightIndex={
                // index
                // // groupedByDate[activity.startIndex] &&
                groupedByDate[activity.startIndex].indexOf(activity.activity.id)

                // _.chain(groupedByDate[activity.startIndex])
                //   .orderBy(["start_on", "created_at"], ["asc"])
                //   .map(el => el.id)
                //   .value()
                //   .indexOf(activity.activity.id)
              }
              length={
                document.getElementsByClassName("fake-grid-div").length > 0
                  ? document.getElementsByClassName("fake-grid-div")[0]
                      .offsetWidth
                  : 0
              }
              index={index}
              selectedUser={selectedUser}
              prioritized={activity.prioritized}
              updateActivitiesAction={updateActivitiesAction}
              datesArray={datesArray}
              setIsDragging={value => setIsDragging(value)}
              scrollHeight={scrollHeight}
            ></ActivityBlockWeek>
          </div>
        );
      });
      // return true;
      renderedActivityBlocks.push(weekAddActivity);
      break;
    case 2:
      renderedActivityBlocks = mappedFilteredActivity.map((activity, index) => {
        return (
          <div
            className="activity-display-div activity-display-div-month"
            key={activity.activity.id}
          >
            <ActivityBlockMonth
              activity={activity.activity}
              heightIndex={
                groupedByDate[activity.startIndex] &&
                // _.chain(groupedByDate[activity.startIndex])
                //   .sortBy(
                //     activity => {
                //       return moment(activity.created_at);
                //     },
                //     ["desc"]
                //   )
                //   .sortBy(activity => {
                //     return moment(activity.start_on);
                //   })
                //   .map(el => el.id)
                //   .value()
                //   .indexOf(activity.activity.id)
                _.chain(groupedByDate[activity.startIndex])
                  .orderBy(["start_on", "created_at"], ["asc"])
                  .map(el => el.id)
                  .value()
                  .indexOf(activity.activity.id)
              }
              noOfDays={datesArray.length}
              widthOfSingleGrid={100 / 7}
              position={{
                startIndex: activity.startIndex + moment(datesArray[0]).day(),
                lastIndex: activity.lastIndex + moment(datesArray[0]).day(),
              }}
              length={
                document.getElementsByClassName("fake-grid-div").length > 0
                  ? document.getElementsByClassName("fake-grid-div")[0]
                      .offsetWidth
                  : 0
              }
              heightOfGrid={
                document.getElementsByClassName("fake-grid-div").length > 0
                  ? document.getElementsByClassName("fake-grid-div")[0]
                      .offsetHeight
                  : 0
              }
              index={index + moment(datesArray[0]).day()}
              prioritized={activity.prioritized}
              selectedUser={selectedUser}
            ></ActivityBlockMonth>
          </div>
        );
      });
      break;
    // return true;
    default:
      renderedActivityBlocks = [];
  }

  useEffect(() => {
    setfiltered(filteredActivity);
  }, [filteredActivity]);

  return (
    <div
      className="workload-main-div"
      onScroll={event => setScrollHeight(event.target.scrollTop)}
    >
      <div className="fake-grid-div-outer" id="fake-grid-div-outer">
        {renderFakeGridDiv}
      </div>
      <div className="activity-display-outer-div">{renderedActivityBlocks}</div>
    </div>
  );
}

export default memo(WorkloadViewComponent);
