import React from "react";

const BacklogActivity = (props) => {
  

    return (
        <div className="backlog-project-activity-display-group"
        onClick={e => {
            // console.log("events", e);
            e.stopPropagation();
            let activity={};
            props.activityData["backFlowFlag"]=true;
            activity["activity"]=props.activityData;
            props.showActivityPopup(activity);
          }} >
            <div className="backlog-project-activity-display">
                <span className="backlog-project-activity-display-title">
                    {props.activityData.title}
                </span>
            </div>
        </div>
    )

}

export default BacklogActivity;
