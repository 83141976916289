import React, { PureComponent, useEffect } from "react";
import routesHelper from "../../router/routeshelper";
import GoogleSignin from "../../styles/img/svg/google-icon-in-btn.svg";

class GoogleSignOn extends PureComponent {
  constructor(props) {
    super(props);
    window.handleCredentialResponse = this.handleCredentialResponse.bind(this);
  }

  componentDidMount() {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: routesHelper.getGoogleDevApiKey(),
        callback: this.handleCredentialResponse,
        // cancel_on_tap_outside: false
      });
      window.google.accounts.id.prompt();
    }
  }

  onGoogleSignIn = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: routesHelper.getGoogleDevApiKey(),
      scope: "openid profile email",
      ux_mode: "popup",
      callback: (response) => {
        this.props.onGoogleSignInSuccess({
          ...response,
          accessToken: response.access_token,
        });
      },
    });
    client.requestAccessToken();
  };

  handleCredentialResponse = (response) => {
    this.props.onGoogleSignInSuccess({
      ...response,
      accessToken: response.credential,
      access_type: "jwt",
    });
  };

  render() {
    const { fromFp } = this.props;
    return (
      /**$$ update key for dev when using */
      <React.Fragment>
        <div
          id="new-google-login"
          className={`goog ${fromFp ? "fp-goog" : ""}`}
          onClick={this.onGoogleSignIn}
        >
          <img src={GoogleSignin} alt="Google Signin" />
          <p className="or_sign">Login with Google </p>
        </div>

        {/* <div
          id="g_id_onload"
          data-client_id="122542955817-a7lbv1ckvldacot85lc8gldodqvkagh4.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-callback="handleCredentialResponse"
          data-auto_prompt="false"
        >
        </div>
        <div
          className="g_id_signin"
          style={{ "width": "fit-content", "margin": "15px auto 0" }}
          data-type="standard"
          data-shape="rectangular"
          // data-theme="outline"
          data-text="loginin_with"
          data-size="large"
          data-logo_alignment="left"
          data-width="203"
        >
        </div> */}

        {/* <GoogleLogin
        clientId={routesHelper.getGoogleDevApiKey()}
        render={(renderProps) => (
          <div
            className={`goog ${fromFp ? "fp-goog" : ""}`}
            onClick={renderProps.onClick}
          >
            <img src={GoogleSignin} alt="Google Signin" />
            <p className="or_sign">Login with Google </p>
          </div>
        )}
        buttonText="Login"
        onSuccess={onGoogleSignInSuccess}
        onFailure={onGoogleSignInFailure}
        cookiePolicy={"single_host_origin"}
      /> */}
      </React.Fragment>
    );
  }
}

export default GoogleSignOn;
