import React, { useState, memo } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
import approveReq from "../../styles/img/svg/approve-proj-req.svg";
import declineReq from "../../styles/img/svg/decline-proj-req.svg";

const ProjectAccessTeam = ({
  currentWorkSpace,
  projectAccessReqTeam,
  updateProjectAccessReqTeamAction
}) => {
  const [sortIn, setSortIn] = useState(false);
  const [sortBy, setSortBy] = useState("req_on");

  const onChangeSort = sortOp => {
    if (sortOp === sortBy) {
      setSortIn(!sortIn);
    } else {
      setSortBy(sortOp);
      setSortIn(false);
    }
  };

  const onApproveDecline = (proj, action) => {
    let body = {
      id: proj.id,
      project_id: proj.project_id,
      user_id: proj.employee_id,
      approver_id: parseInt(localStorage.getItem("id"))
    };
    if (action === "approve") body.access_status = "APPROVED";
    if (action === "decline") body.access_status = "DENIED";
    updateProjectAccessReqTeamAction({
      auth_token: currentWorkSpace.auth_token,
      body
    });
  };

  const renderProjectAccessReqTeam = () => {
    let sortedProjectAccessReqTeam = [];
    if (sortBy === "emp") {
      if (sortIn) {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.employee.toLowerCase(),
          "asc"
        );
      } else {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.employee.toLowerCase(),
          "desc"
        );
      }
    }
    if (sortBy === "project") {
      if (sortIn) {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.project,
          "asc"
        );
      } else {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.project,
          "desc"
        );
      }
    }
    if (sortBy === "req_on") {
      if (sortIn) {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.requested_on,
          "asc"
        );
      } else {
        sortedProjectAccessReqTeam = _.orderBy(
          projectAccessReqTeam,
          proj => proj.requested_on,
          "desc"
        );
      }
    }
    return sortedProjectAccessReqTeam.map(proj => {
      return (
        <React.Fragment>
          <tr>
            <td className="emp-img-name">
              <div>
            {proj.employee_img ? (
                <img src={proj.employee_img} alt="emp-img" />
              ) : (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={proj.employee}
                ></Avatar>
              )}
              {proj.employee}
              </div>
            </td>
            <td className="proj-img-name">
              <div>
              {proj.project_img ? (
                <img src={proj.project_img} alt="proj-img" />
              ) : (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={proj.project}
                ></Avatar>
              )}
              {proj.project}
              </div>
              </td>
            <td style={{ width: "109px" }}>
              {moment(proj.requested_on).format("ddd, DD MMM YY")}
            </td>
            <td>
              <div className="tick-cross-holder">
              <img
                alt="approve"
                src={approveReq}
                onClick={() => onApproveDecline(proj, "approve")}
              />
              <img
                alt="decline"
                src={declineReq}
                onClick={() => onApproveDecline(proj, "decline")}
              />
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
          <div className="over-title">Project Access Request
          <span>{_.isArray(projectAccessReqTeam) ? projectAccessReqTeam.length : 0} pending requests</span>
          </div>
        </div>
        {_.isArray(projectAccessReqTeam) && (
          <div className="table-holder">
            <table style={{ width: "98.3%" }}>
              <thead>
                <tr>
                  <th
                    onClick={() => onChangeSort("emp")}
                    style={{ width: "198px" }}
                  >
                    <span
                      className={`sort-arrow ${(sortBy === "emp" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "emp" && sortIn === true && "up")}`}
                    >
                      Employee
                    </span>
                  </th>
                  <th
                    onClick={() => onChangeSort("project")}
                    style={{ width: "207px" }}
                  >
                    <span
                      className={`sort-arrow ${(sortBy === "project" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "project" && sortIn === true && "up")}`}
                    >
                      Project
                    </span>
                  </th>
                  <th
                    onClick={() => onChangeSort("req_on")}
                    style={{ width: "165px" }}
                  >
                    <span
                      className={`sort-arrow ${(sortBy === "req_on" &&
                        sortIn === false &&
                        "down") ||
                        (sortBy === "req_on" && sortIn === true && "up")}`}
                    >
                      Requested on
                    </span>
                  </th>
                  {/* <th className="actions">
                    <span className="sort-arrow">Actions</span>
                  </th> */}
                </tr>
              </thead>
            </table>
            <Scrollbar>
              <table>
                <tbody>{renderProjectAccessReqTeam()}</tbody>
              </table>
            </Scrollbar>
          </div>
        )}
        {typeof projectAccessReqTeam === "object" &&
          projectAccessReqTeam?.msg && (
            <div className="no-data">{projectAccessReqTeam.msg}</div>
          )}
      </div>
    </React.Fragment>
  );
};

export default memo(ProjectAccessTeam);
