import _ from "lodash";
// import ChipInput from "material-ui-chip-input";
import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as action from "../../actions/index";
import {
  getProjectMembers,
  getUpdatedProjectObj
} from "../../helpers/projectsettings";
import RoleDropDown from "./roleDropDown";
import accessAdd from "../../styles/img/svg/access-add.svg";
import firebase from "../../firebase";
import { getIsCreator, getIsSuperAdmin } from "../../helpers/reportlanding";
import { getIsAccessToAddRemoveMember } from "../../helpers/generichelper";
import { getProjectById } from "../../services/projectapi";

class PeopleTable extends Component {
  state = {
    showDropDown: false,
    index: 0,
    showAccessDropDown: false,
    accessIndex: 0
  };

  onShowDropDown = (e, index = 0) => {
    if(index === this.state.index && this.state.showDropDown) this.setState({ showDropDown: false, index: 0 });
    else this.setState({ showDropDown: !this.state.showDropDown, index });
  };

  onOutsideClickRole = (e, index) => {
      if (this.state.showDropDown && index === this.state.index)
        this.setState({ showDropDown: false, index: 0 });
  };

  onShowAccessDropDown = (e, index = 0) => {
    const { workSpaceUsers, projectDetails, currentWorkSpace } = this.props;
      const projectMembers = getProjectMembers(
        projectDetails,
        workSpaceUsers,
        currentWorkSpace
      );
    if(index === this.state.accessIndex && this.state.showAccessDropDown)
      this.setState({ showAccessDropDown: false, accessIndex: 0 });
    else this.setState({ showAccessDropDown: !this.state.showAccessDropDown, accessIndex: index },
      () => {
        let element = document.getElementById("access-lvl-ddown");
        if (element !== null && index > 2 && index + 1 === projectMembers.length) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      });
  };

  onOutsideClickAccess = (e, index) => {
      if (this.state.showAccessDropDown && index === this.state.accessIndex)
        this.setState({ showAccessDropDown: false, accessIndex: 0 });
  };

  onSelectAccess = (projectMember, access) => {
    getProjectById(this.props.projectDetails.archived, this.props.projectDetails.id, response => {
      const project = getUpdatedProjectObj(
        response?.length > 0 ? response[0] : this.props.projectDetails,
        projectMember,
        access
      );
      this.props.updateProjectAction(
        { queryParam: "", body: project },
        response => {
          if (response && response.id) {
            if(access === "Remove") firebase.analytics().logEvent("GTD_Project_Member_Removed");
            else firebase.analytics().logEvent("GTD_Project_Role_Change");
            this.setState({ showDropDown: false, index: 0 });
            toast("Changes saved successfully!");
          }
        }
      );
    });
  };

  handleAddWidget = (chip, user_id) => {
    const project = this.props.projectDetails;
    getProjectById(project.archived, project.id, response => {
      const updatedProject = response?.length > 0 ? response[0] : project;
      let body = {
        ...updatedProject,
        client: null,
        client_id: null,
        data: {
          ...updatedProject.data,
          settings: {
            ...updatedProject.data.settings,
            member_access: {
              ...updatedProject.data.settings.member_access,
              [user_id]: {
                ...updatedProject.data.settings.member_access[user_id],
                widget: [
                  ...updatedProject.data.settings.member_access[user_id].widget,
                  chip
                ]
              }
            }
          }
        }
      };
      delete body.created_at;
      delete body.updated_at;
      body = _.pickBy(body, _.identity);
      // console.log("body", body)
      this.setState({ showAccessDropDown: false, accessIndex: 0 });
      this.props.updateProjectAction({ queryParam: "", body }, response => {
        if (response && response.id) {
          toast("Changes saved successfully!");
        }
      });
    });
  };

  handleDeleteWidget = (chip, user_id) => {
    const project = this.props.projectDetails;
    getProjectById(project.archived, project.id, response => {
      const updatedProject = response?.length > 0 ? response[0] : project;
      let body = {
        ...updatedProject,
        client: null,
        client_id: null,
        data: {
          ...updatedProject.data,
          settings: {
            ...updatedProject.data.settings,
            member_access: {
              ...updatedProject.data.settings.member_access,
              [user_id]: {
                ...updatedProject.data.settings.member_access[user_id],
                widget: [
                  ...updatedProject.data.settings.member_access[user_id].widget.filter(
                    wgt => wgt !== chip
                  )
                ]
              }
            }
          }
        }
      };
      delete body.created_at;
      delete body.updated_at;
      body = _.pickBy(body, _.identity);
      // console.log("body", body)
      this.props.updateProjectAction({ queryParam: "", body }, response => {
        if (response && response.id) {
          toast("Changes saved successfully!");
        }
      });
    });
  };

  render() {
    if (this.props.projectDetails && this.props.workSpaceUsers.length > 0) {
      const { workSpaceUsers, projectDetails, currentUser, currentWorkSpace, widgets } = this.props;
      const projectMembers = getProjectMembers(
        projectDetails,
        workSpaceUsers,
        currentWorkSpace
      );
      const { showDropDown, showAccessDropDown } = this.state;
      const isCreator = getIsCreator(currentWorkSpace)
      const isOwner = getIsSuperAdmin(currentUser, currentWorkSpace)
      return (
        <div className="prj-access-table-container">
          <table className="prj-access-table">
            <thead>
              <tr>
                <td width="16%">People</td>
                <td width="16%">Role</td>
                <td width="40%">Access level</td>
              </tr>
            </thead>
            <tbody>
              {projectMembers.length > 0 &&
                projectMembers.map((projectMember, index) => (
                  <tr key={index}>
                    <td>
                      <p className="prj-access-prj-name">
                        {projectMember.name}
                      </p>
                    </td>
                    {projectMember.access === "Creator" ? (
                      <td>
                        <div className="owner-ps">Creator</div>
                      </td>
                    ) : (
                      <td>
                        {/* <div ref={node => (this.roleDropdownNode = node)}> */}
                        <OutsideClickHandler
                                onOutsideClick={e =>
                                  this.onOutsideClickRole(e, index)
                                }
                              >
                          <div
                            className={`prj-access-role-holder ${(isCreator ||
                              (projectMember.access === "Manager" && isOwner && getIsAccessToAddRemoveMember(projectDetails)) ||
                              (projectMember.access === "Member" && getIsAccessToAddRemoveMember(projectDetails))) ? "" : "no-down-arrow"}`}
                            onClick={e => this.onShowDropDown(e, index)}
                          >
                            {/* <span> */}
                              {projectMember.access || "Select Access"}
                            {/* </span> */}
                            {(isCreator ||
                            (projectMember.access === "Manager" && isOwner && getIsAccessToAddRemoveMember(projectDetails)) ||
                            (projectMember.access === "Member" && getIsAccessToAddRemoveMember(projectDetails))) && 
                            showDropDown && index === this.state.index && (
                              
                                <RoleDropDown
                                  projectMember={projectMember}
                                  shouldShowRoleChange={isCreator || isOwner}
                                  onSelectAccess={this.onSelectAccess}
                                  onShowDropDown={this.onShowDropDown}
                                />
                              
                            )}
                          </div>
                          </OutsideClickHandler>
                          
                        {/* </div> */}
                      </td>
                    )}
                    {(projectMember.access === "Creator" ||
                      (projectMember.access === "Owner" && !isCreator) ||
                      (projectMember.access === "Manager" && !(isCreator || isOwner))) && (
                      <td>
                        <div className="widget-tags-container">
                          {projectMember.widget.length > 0 &&
                            projectMember.widget.map((accessLevel, index) => (
                              <div key={index} className="widget-tags-holder">
                                <span>{accessLevel}</span>
                              </div>
                            ))}
                        </div>
                      </td>
                    )}
                    {((projectMember.access === "Owner" && isCreator) ||
                      (projectMember.access === "Manager" && (isCreator || isOwner))) && (
                      <td>
                        <div className="widget-tags-container">
                          {projectMember.widget.length >= 0 && 
                            <div className="input-chips access-level">
                              {projectMember.widget.map(access => 
                                <div>
                                  <div className="readOnlyItem">
                                    <span>
                                      {access}
                                    </span>
                                    <svg
                                      className="read-only"
                                      focusable="false"
                                      onClick={() => this.handleDeleteWidget(access, projectMember.userId)}>
                                    </svg>
                                  </div> 
                                </div>
                                )} 
                              {widgets.filter(widget => !projectMember.widget.includes(widget.name)).length > 0 && <div>
                                <OutsideClickHandler onOutsideClick={e => this.onOutsideClickAccess(e, index)}>
                                <img style={{ paddingTop: "6px" }} role="button" src={accessAdd} alt="add" onClick={e => this.onShowAccessDropDown(e, index)} />
                                {showAccessDropDown && index === this.state.accessIndex &&
                                <div id="access-lvl-ddown" className="prj-access-role-ddown access-ddown">
                                  {widgets.filter(widget => !projectMember.widget.includes(widget.name)).map(widget =>
                                  <div className="prj-access-role-ddown-list access-ddown-list" onClick={() => this.handleAddWidget(widget.name, projectMember.userId)}>
                                    {widget.name}
                                  </div>
                                )}
                                </div>
                                }
                                </OutsideClickHandler>
                              </div>}
                            </div>
                          //   <ChipInput
                          //   value={projectMember.widget}
                          //   onAdd={chip => this.handleAddWidget(chip, projectMember.userId)}
                          //   onDelete={chip =>
                          //     this.handleDeleteWidget(chip, projectMember.userId)
                          //   }
                          //   className="chip-input widget"
                          // />
                            }
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    } else return null;
  }
}

// const mapStateToProps = state => {
//   return {
//     workSpaceUsers: state.peopleReducer.workspaceusers
//   };
// };

const mapDispatchToProps = dispatch => {
  return {
    // getAllCustomer: ({ auth_token }, callback) => {
    //   dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    // },
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    }
  };
};

export default connect(null, mapDispatchToProps)(PeopleTable);
