import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Avatar from "react-avatar";
import { ToastContainer, toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
import * as action from "../../actions/index";
import { uploadPhotoToS3Bucket } from "../../actions/common/commonactions";
import { unArchivedArchivedProject } from "../../actions/project/projectaction";
import addIcon from "../../styles/img/svg/plus-add.svg";
import "../../styles/scss/peopleSettings.scss";
import SettingsSearch from "./settingsSearch";
import SettingsDropDown from "../common/settingsDropDown";
import AddProjectDialog from "./addProjectDialog";
import ProjectSettingsForm from "./projectSettingsForm";
import PeopleAccessSection from "./peopleAccessSection";
import { Loader } from "../common/loader";
import NotSubscribed from "./notSubscribed";
import firebase from "../../firebase";
import _ from "lodash";

class ProjectSettings extends PureComponent {
  state = {
    showDialog: false,
    showDropDown: false,
    showSearch: false,
    selectedValue: "ALL",
    selectedValueView: "All projects",
    projectView: [],
    selectedProject: "",
    groupList: [],
    searchText: "",
    allProjectActivityType: [],
    selectedActivityType: [],
  };

  onShowSearch = () => {
    firebase.analytics().logEvent("GTD_Project_Search");
    const { showSearch, selectedValue } = this.state;
    const { projectList, archivedProjects } = this.props;
    if (showSearch) {
      let filtered = [];
      if (selectedValue === "ARCHIVED") filtered = [...archivedProjects];
      else if (selectedValue !== "ALL") {
        filtered = projectList.filter(
          project => project.status === selectedValue
        );
      } else {
        filtered = projectList;
      }
      this.setState({ projectView: filtered });
    }
    this.setState({ showSearch: !this.state.showSearch, searchText: "" });
  };

  onSelectValue = (selectedValue, selectedValueView) => {
    const { projectList, archivedProjects } = this.props;
    let filtered = [];
    if (selectedValue === "ARCHIVED") filtered = [...archivedProjects];
    else if (selectedValue !== "ALL") {
      filtered = projectList.filter(
        project => project.status === selectedValue
      );
    } else {
      filtered = projectList;
    }
    this.setState({
      selectedValueView,
      selectedValue,
      showDropDown: !this.state.showDropDown,
      projectView: filtered,
      selectedProject:
        filtered.length > 0 ? filtered[0].id : this.state.selectedProject,
    });
  };

  onShowDropDown = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showDropDown: !this.state.showDropDown });
  };

  onOutsideClickDropDown = () => {
    this.setState({ showDropDown: false });
  };

  onShowDialog = () => {
    firebase.analytics().logEvent("GTD_Project_Add_Project");
    this.setState({ showDialog: !this.state.showDialog });
  };

  onSearch = searchText => {
    const { selectedValue } = this.state;
    const { projectList, archivedProjects } = this.props;
    let searchResult = [];
    if (searchText === "") {
      if (selectedValue === "ARCHIVED") searchResult = [...archivedProjects];
      else if (selectedValue === "ALL") {
        searchResult = projectList;
      } else
        searchResult = projectList.filter(
          project => project.status === selectedValue
        );
    } else {
      if (selectedValue === "ARCHIVED") {
        searchResult = archivedProjects.filter(
          project =>
            project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      } else if (selectedValue === "ALL") {
        searchResult = projectList.filter(
          project =>
            project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      } else {
        searchResult = projectList.filter(
          project =>
            project.status === selectedValue &&
            project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      }
    }
    this.setState({
      searchText,
      projectView: searchResult,
    });
  };

  onSelectProject = selectedProject => {
    const projectDetails = this.props.projectList.filter(
      project => project.id === selectedProject
    )[0];
    this.props.setDefaultProjectDetails(projectDetails);
    this.setState({ selectedProject });
  };

  onAddProject = newProject => {
    toast("Project added successfully!");
    let groupList = [...this.state.groupList];
    if (!groupList.includes(newProject.group)) groupList.push(newProject.group);
    this.setState({
      projectView: this.props.projectList,
      selectedProject: newProject.id,
      showDialog: !this.state.showDialog,
      groupList,
    });
  };

  getProjectDetails = () => {
    const projectDetails = [
      ...this.props.projectList,
      ...this.props.archivedProjects,
    ].filter(project => project.id === this.state.selectedProject)[0];
    // console.log("getProjectDetails", projectDetails)
    return projectDetails;
  };

  onArchiveUnarchive = project => {
    let body = {
      id: project.id,
    };
    if (project.archived) {
      body.archived = false;
      body.data = project.data;
    } else body.archived = true;
    this.props.unArchivedArchivedProject({ body }, response => {
      if (response && response.id) {
        let projectView = this.state.projectView.filter(
          project => project.id !== response.id
        );
        this.setState({
          projectView,
          selectedProject: projectView.length > 0 ? projectView[0].id : "",
        });
      }
    });
  };

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Project_Visited");
    const {
      getProjectAction,
      getArchivedProjectsAction,
      projectList,
      router,
    } = this.props;

    if (router.location.action === "POP" && projectList.length > 0) {
      this.setLocalState(projectList);
    } else
      getProjectAction(
        {
          auth_token: localStorage.getItem("customer_auth_token"),
          queryParam: "?archived=false",
        },
        response => {
          if (response && response.length > 0) {
            this.setLocalState(response);
          }
          if (!response.stdErr && response?.length >= 0) {
            getArchivedProjectsAction(
              {
                auth_token: localStorage.getItem("customer_auth_token"),
                queryParam: `?archived=true`,
              },
              response => {}
            );
          }
        }
      );
  }

  setLocalState = response => {
    const groupList = [
      ...new Set(
        response.reduce((acc, project) => {
          if (project.group) acc.push(project.group);
          return acc;
        }, [])
      ),
    ];
    let activityTypedata = new Set();
    response.forEach(projectData => {
      projectData.data.settings.activity_type.map(activity =>
        activityTypedata.add(activity)
      );
    });
    let sertedactivityTypedata = _.sortBy(
      Array.from(activityTypedata),
      [type => type.toLowerCase()],
      ["asc"]
    );

    this.setState({
      projectView: response,
      selectedProject: response[0].id,
      groupList,
      allProjectActivityType: sertedactivityTypedata,
    });
  };

  updateAcitvityType = (newActivityType, projectid) => {
    let newList = new Set();
    this.state.projectView.forEach(projectData => {
      if (projectData.id !== projectid) {
        projectData.data.settings.activity_type.map(activity =>
          newList.add(activity)
        );
      }
    });
    newActivityType.map(type => newList.add(type));
    let sertedactivityTypedata = _.sortBy(
      Array.from(newList),
      [type => type.toLowerCase()],
      ["asc"]
    );
    this.setState({ allProjectActivityType: sertedactivityTypedata });
  };

  renderProjects = () => {
    const {
      projectView,
      selectedValueView,
      selectedProject,
      searchText,
    } = this.state;
    if (projectView && projectView.length > 0) {
      const sortedProjects = projectView.sort((project1, project2) => {
        let p1 = project1.name.toUpperCase();
        let p2 = project2.name.toUpperCase();
        if (p1 < p2) {
          return -1;
        }
        if (p1 > p2) {
          return 1;
        }
        return 0;
      });
      return (
        <Scrollbar className="project-scroll">
          {sortedProjects.map((project, index) => (
            <div
              key={index}
              className={`ppl-details-holder ${selectedProject === project.id &&
                "active"}`}
              onClick={() => this.onSelectProject(project.id)}
            >
              <div className="ppl-details-img-holder project-img">
                {project.img ? (
                  <img src={project.img} alt="proj-logo" />
                ) : (
                  <Avatar
                    style={{ width: "100%", height: "100%" }}
                    name={project.name[0]}
                  ></Avatar>
                )}
              </div>
              <div className="ppl-job-details-holder">
                <div className="ppl-detail-name">{project.name}</div>
                {/* <div className="ppl-detail-job-title">
                  {project.group || project.name}
                </div> */}
              </div>
            </div>
          ))}
        </Scrollbar>
      );
    } else {
      if (
        searchText &&
        this.props.projectListLoaderState.fetched &&
        [...this.props.projectList, ...this.props.archivedProjects].length > 0
      )
        return (
          <div className="settings-noresults">
            {" "}
            No results found in '{selectedValueView}'
          </div>
        );
    }
  };

  updateList = updatedProject => {
    const projectView = this.state.projectView.map(project => {
      if (project.id === updatedProject.id) {
        return updatedProject;
      }
      return project;
    });
    this.setState({ projectView });
  };

  render() {
    const {
      showDialog,
      showSearch,
      selectedValueView,
      projectView,
      showDropDown,
      searchText,
      groupList,
      allProjectActivityType,
    } = this.state;
    const {
      projectList,
      archivedProjects,
      projectListLoaderState,
      updateProjectLoaderState,
      uploadPhotoToS3BucketAction,
      createProjectAction,
      currentWorkSpace,
      showNoSubscriptionWarning,
      onTabClick,
    } = this.props;
    const allProjects = [...projectList, ...archivedProjects];
    const projectDetails = this.getProjectDetails();
    return (
      <div>
        <div className="ppl-settings-container project-setting-main-container">
          <Loader
            size={100}
            loading={
              projectListLoaderState.fetching ||
              updateProjectLoaderState.fetching
            }
          />
          <div className="ppl-settings-contact-container">
            <div className="ppl-settings-search-area">
              <div className="ppl-search-control-holder project-ppl-search">
                {!showSearch ? (
                  <SettingsDropDown
                    selectedValueView={selectedValueView}
                    projectCount={projectView.length}
                    onSelectValue={this.onSelectValue}
                    showDropDown={showDropDown}
                    onShowDropDown={this.onShowDropDown}
                    onOutsideClickDropDown={this.onOutsideClickDropDown}
                    onShowSearch={this.onShowSearch}
                  />
                ) : (
                  <SettingsSearch
                    onShowSearch={this.onShowSearch}
                    placeholder={selectedValueView}
                    searchText={searchText}
                    onSearch={this.onSearch}
                  />
                )}
              </div>
            </div>
            <div className="add-projectSettings-container">
              <div
                className="add-projectSettings-wrap"
                onClick={this.onShowDialog}
              >
                <img src={addIcon} alt="add" />
                <span className="add-projectSettings-title">
                  Add a new Project
                </span>
              </div>
              {showDialog && (
                <AddProjectDialog
                  onShowDialog={this.onShowDialog}
                  onAddProject={this.onAddProject}
                  uploadPhotoToS3BucketAction={uploadPhotoToS3BucketAction}
                  createProjectAction={createProjectAction}
                  currentWorkSpace={currentWorkSpace}
                />
              )}
            </div>

            <div className="ppl-details-container">
              {allProjects.length > 0 && this.renderProjects()}
              {projectListLoaderState.fetched &&
                // !projectListLoaderState.error &&
                projectView.length === 0 &&
                !searchText && (
                  <div
                    //ng-if="proList.length === 0 && projectSettings.projectSearch.name "
                    className="settings-noresults"
                  >
                    {" "}
                    Nothing to show in '{selectedValueView}'
                  </div>
                )}
            </div>
          </div>
          {projectListLoaderState.fetched &&
          (allProjects.length === 0 || projectView.length === 0) ? (
            <div className=" settings-noresults hide-mgs-ps no-results-ps-msg">
              Details of selected projects would show up here
            </div>
          ) : (
            <div className="ppl-settings-control-container">
              <Scrollbar className="settings-form-scroll">
                <div className="ppl-settings-control-inner-holder">
                  <ProjectSettingsForm
                    projectDetails={projectDetails}
                    currentWorkSpace={currentWorkSpace}
                    currencyLogo={this.props.currencyLogo}
                    updateList={this.updateList}
                    groupList={groupList}
                    edit={"edit"}
                    onArchiveUnarchive={this.onArchiveUnarchive}
                    allProjectActivityType={allProjectActivityType}
                    updateAcitvityType={this.updateAcitvityType}
                  />
                  <PeopleAccessSection
                    projectDetails={projectDetails}
                    currentWorkSpace={currentWorkSpace}
                  />
                </div>
              </Scrollbar>
            </div>
          )}
        </div>
        {currentWorkSpace && Object.keys(currentWorkSpace.users).length > 5 && (
          <NotSubscribed
            showNoSubscriptionWarning={showNoSubscriptionWarning}
            onTabClick={onTabClick}
          ></NotSubscribed>
        )}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth_token: state.loginReducer.loginAuth.auth_token,
    loginActionState: state.loginReducer.loginActionState,
    projectListLoaderState: state.projectReducer.projectListLoaderState,
    updateProjectLoaderState: state.projectReducer.updateProjectLoaderState,
    projectList: state.projectReducer.listOfProjects.list,
    archivedProjects: state.projectReducer.listOfArchivedProjects.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectAction({ auth_token, queryParam }, callback) {
      dispatch(action.getProjectsAction({ auth_token, queryParam }, callback));
    },
    getArchivedProjectsAction({ auth_token, queryParam }, callback) {
      dispatch(
        action.getArchivedProjectsAction({ auth_token, queryParam }, callback)
      );
    },
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        uploadPhotoToS3Bucket(
          {
            file,
            loaderKey,
          },
          callback
        )
      );
    },
    createProjectAction: (body, callback) => {
      dispatch(action.createProjectAction(body, callback));
    },
    unArchivedArchivedProject: ({ body }, callback) => {
      dispatch(unArchivedArchivedProject({ body }, callback));
    },
    setDefaultProjectDetails: values =>
      actions.merge("rrfState.projectSettingsForm", values),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
