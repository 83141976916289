import update from "immutability-helper";
import { projectConstants } from "../constants/projectconstants";

export const getActiveTab = status => {
  if (status === "Active") return "ACTIVE";
  if (status === "On hold") return "ON_HOLD";
  if (status === "Completed") return "COMPLETED";
  if (status === "Cancelled") return "CANCELLED";
};

export const getUpdatedGroupList = (projectList, tabState) => {
  return projectList.reduce((acc, project) => {
    if (
      project.status === tabState &&
      !acc.includes(project.group || project.name)
    ) {
      acc.push(project.group || project.name);
    }
    return acc
  }, []).sort(
    (a, b) => {
      if(b.toLowerCase() > a.toLowerCase()) return -1;
      if(b.toLowerCase() < a.toLowerCase()) return 1;
      return 0;
    }
  );
};

export const getProjectView = project => {
  let projectView = {};
  projectView.img = project.img;
  projectView.name = project.name;
  projectView.showMenu = project.showMenu;
  let estimatedHours =
    (project.data.settings.hasOwnProperty("planned_hours") &&
      project.data.settings.planned_hours) ||
    (project.data.settings.hasOwnProperty("estimated_hours") &&
      project.data.settings.estimated_hours);
  if (project.data.time_taken && estimatedHours)
    projectView.percentage = Math.round(
      (project.data.time_taken / (estimatedHours * 60 * 60)) * 100
    );
  else projectView.percentage = 0;
  projectView.ratio = `Hrs: ${Math.round(
    (project.data.time_taken / 3600) || 0
  )}/${estimatedHours || 0}`;
  return projectView;
};

export const getProjectsByAccess = (
  projectList,
  currentUser,
  currentWorkSpace,
  tabState = null
) => {
  let projectsByAccess = [];
  if (
    parseInt(currentWorkSpace.super_admin_id) === parseInt(currentUser.id) ||
    (currentWorkSpace.users.hasOwnProperty(currentUser.id) &&
      currentWorkSpace.users[currentUser.id] === projectConstants.superAdmin)
  ) {
    projectsByAccess = projectList.map(project => {
      let updatedProject = update(project, { showMenu: { $set: true } });
      return updatedProject;
    });
    if(!tabState) projectsByAccess.unshift({ id: "all", name: "All", archived: false })
  } else {
    projectList.forEach(project => {
      if (project.data.settings.hasOwnProperty("member_access")) {
        if (
          project.data.settings.member_access.hasOwnProperty(currentUser.id) && project.data.settings.member_access[currentUser.id]
        ) {
          let updatedProject = {};
          if (
            project.data.settings.member_access[currentUser.id].access ===
            projectConstants.manager
          )
            updatedProject = update(project, { showMenu: { $set: true } });
          if (
            project.data.settings.member_access[currentUser.id].access ===
            projectConstants.member
          )
            updatedProject = update(project, { showMenu: { $set: false } });
          projectsByAccess.push(updatedProject);
        }
      }
    });
    if(!tabState) projectsByAccess.unshift({ id: "all", name: "All", archived: false })
  }
  // console.log("Projects By Access", projectsByAccess);
  if (tabState) return getProjectsByStatus(projectsByAccess, tabState);
  return projectsByAccess;
};

export const getProjectsByStatus = (projects, tabState) => {
  return projects.filter(project => project.status === tabState);
};

export const getProjectsByGroup = (projects, group) => {
  return projects.filter(project => (project.group || project.name) === group);
};

export const getFilteredProjects = (projects, tabState, searchContent) => {
  return projects.filter(
    project =>
      project.status === tabState &&
      project.name.toLowerCase().indexOf(searchContent.toLowerCase()) !== -1
  );
};

export const getShouldRenderAddButton = (currentUser, currentWorkSpace) => {
  if (
    currentWorkSpace.users.hasOwnProperty(currentUser.id) &&
    currentWorkSpace.users[currentUser.id] === projectConstants.superAdmin
  )
    return true;
  return false;
}
