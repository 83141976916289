import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";


const ID="id";

export function getComments(activity_type, parent_activity_id, callback) {
  let userid=parseInt(localStorage.getItem(ID));
  let url = API_URL + "/api/v1/activity";
  let queryParams =
    activity_type && parent_activity_id
      ? `?activity_type=${activity_type}&parent_activity_id=${parent_activity_id}&logged_in_user=${userid}`
      : "";
  secureFetch(url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function getSingleCommentChain(comment_id, callback) {
  let userid=parseInt(localStorage.getItem(ID));
  let url = API_URL + "/api/v1/single-comment";
  let queryParams =
    comment_id
      ? `?comment_id=${comment_id}&logged_in_user=${userid}`
      : "";
  secureFetch(url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export const addComment = (
  {
    activity_type,
    created_at,
    created_by,
    data,
    description,
    parent_activity_id,
    platform,
    project_id,
    title,
    updated_on,
    user_id,
    version,
    attachments
  },
  callback
) => {
  let url = API_URL + "/api/v1/activity";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify({
      activity_type,
      // created_at,
      created_by,
      data,
      description,
      parent_activity_id,
      platform,
      project_id,
      title,
      // updated_on,
      user_id,
      version,
      attachments
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
};

export const deleteComment = (
  {
    activity_type = "COMMENT",
    user_id,
    created_by,
    created_at,
    title,
    id,
    data,
    is_active = false,
    platform = "Web",
    version_id,
    parent_activity_id,
    updated_on
  },
  callback
) => {
  let userid=localStorage.getItem(ID);
  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      activity_type,
      user_id,
      created_by,
      // created_at,
      title,
      id,
      data,
      is_active,
      platform,
      version_id,
      parent_activity_id,
      // updated_on,
      "performed_by":JSON.parse(userid)
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
};

export const editComment = (
  {
    activity_type = "COMMENT",
    is_active = true,
    created_at,
    created_by,
    data,
    id,
    parent_activity_id,
    platform = "WEB",
    title,
    updated_on,
    user_id,
    version,
    version_id,
    attachments
  },
  callback
) => {
  let userid=localStorage.getItem(ID);

  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      activity_type,
      is_active,
      // created_at,
      created_by,
      data,
      id,
      parent_activity_id,
      platform,
      title,
      // updated_on,
      user_id,
      version,
      version_id,
      attachments,
      "performed_by":JSON.parse(userid)
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
};

export const updateLikeBookmarkComment = (
  body,
  callback
) => {
  let userid=localStorage.getItem(ID);

  let url = API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid);
  secureFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      ...body,
      performed_by: parseInt(userid)
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => {
      // console.log(respJson);
      callback(respJson);
    })
    .catch((err) => callback(err));
};

export function searchActivityInner(queryParams, callback) {
  let url = API_URL + "/api/v1/search-in-activity-card";
  secureFetch(url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function getArchivedComments(user_id, searchText, page, perPage, callback) {
  let url = API_URL + "/api/v1/archived-comments";
  let queryParams =
    searchText
      ? `?user_id=${user_id}&like=${searchText}&page=${page}&page_size=${perPage}`
      : `?user_id=${user_id}&page=${page}&page_size=${perPage}`;
  secureFetch(url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function permanentDeleteArchivedComment(activity_id, callback) {
  let url = API_URL + `/api/v1/permanently-delete-comment/${activity_id}`;
  secureFetch(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}