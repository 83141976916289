import React, { memo, useState } from "react";
import firebase from "../../firebase";
import { workloadConstant } from "../../constants/workloadConstant";
import settingsSearchIcon from "../../styles/img/svg/workload-search-icon.svg";
import settingsCloseIconSVG from "../../styles/img/svg/Close_White.svg";
import reverseIconSvg from "../../styles/img/svg/reverse.svg";

function WokloadSearchContentArea({ onSearchInitiated, onChangingSearchMode }) {
  const [searchText, setSearchText] = useState(null);

  const [searchType, setsearchType] = useState(
    workloadConstant.searchActivities
  );

  return (
    <div className="ppl-search-control-holder">
      <div className="ppl-search-icon-holder">
        <img src={settingsSearchIcon} alt="search-icon" />
      </div>
      <input
        onFocus={() => {
          firebase.analytics().logEvent("GTD_Workload_Search");
        }}
        value={searchText ? searchText : ""}
        className="ppl-search-control"
        onChange={(e) => {
          setSearchText(e.target.value);
          onSearchInitiated(e.target.value);
        }}
        placeholder={
          searchType === workloadConstant.searchActivities
            ? "Search backlog"
            : "Search people"
        }
      />
      {searchText && (
        <div
          class="ppl-clear-icon-holder"
          onClick={() => {
            setSearchText(null);
            onSearchInitiated("");
          }}
          role="button"
          tabIndex="0"
        >
          <img src={settingsCloseIconSVG} alt="close" ></img>
        </div>
      )}
      {!searchText && (
        <div
          className="ppl-clear-icon-holder"
          onClick={() => {
            firebase.analytics().logEvent("GTD_Workload_Search");
            const newSearchType =
              searchType === workloadConstant.searchActivities
                ? workloadConstant.searchUsers
                : workloadConstant.searchActivities;
            onChangingSearchMode(newSearchType);
            setsearchType(newSearchType);
          }}
          role="button"
          tabIndex="0"
        >
          <img src={reverseIconSvg} alt="revert" ></img>
        </div>
      )}
    </div>
  );
}

export default memo(WokloadSearchContentArea);
