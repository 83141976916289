import React, { Component } from "react";
import "../../styles/scss/notifications.scss";
import notify_comment from "../../styles/img/svg/notify-comments.svg";
import notify_edit_comment from "../../styles/img/svg/edit_comment_notify.svg";
import notify_attachment_comment from "../../styles/img/svg/att-comm-notify.svg";
import { Tooltip } from "@material-ui/core";
import ReactMarkdown from "react-markdown/with-html";
import { parseComment, commonDateFormat, getPosition } from "../../helpers/generichelper";
import ReactHtmlParser from "react-html-parser";

import { MentionsInput, Mention } from "react-mentions";
import {
  changeIsReadStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfYouTabAction,
  changeFlagStatusOfAllTabAction,
  replyInYouNotificationAction,
  replyInAllNotificationAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import moment from "moment";
import { addCommentAction } from "../../actions/comments/commentsaction";
import Avatar from "react-avatar";
import _ from "lodash";
import firebase from "../../firebase";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },

    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    replyInYouNotification: (
      { notification, comment, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        replyInYouNotificationAction(
          { notification, comment, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    replyInAllNotification: (
      { notification, comment, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        replyInAllNotificationAction(
          { notification, comment, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    addCommentAction: (comment, callback) => {
      dispatch(addCommentAction(comment, callback));
    }
  };
}
const mapStateToProps = state => {
  return {
    userDetails: state.peopleReducer.workspaceusers,
    firstAllNotification: state.notificationsReducer.listOfNotification.list[0],
    firstYourNotification: state.notificationsReducer.listOfYourNotification.list[0],
    replyToNotificationLoaderState: state.notificationsReducer.replyToNotificationLoaderState
  };
};
class CommentNotify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textareaValue:
        localStorage.getItem("notificationReplyCache") &&
        JSON.parse(localStorage.getItem("notificationReplyCache")).id ===
          props.notification.id
          ? JSON.parse(localStorage.getItem("notificationReplyCache")).text
          : "",
      fetching: false
    };
    
  }

  componentDidMount() {
    let links = document.getElementsByClassName(`notifi-a-tag-${this.props.notification.id}`);
    if(links.length > 0) {
      for(const element of links){
        element.onclick = (event) => {
          event.stopPropagation();
          this.youNotificationReadStatus(this.props.notification, "REPLY");
      }
      }
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      const { notification, firstYourNotification, firstAllNotification } = this.props;
      if (
        document.getElementsByClassName(
          "notify-comment-text-area__suggestions__list"
        )[0]
      ) {
        const el = document.querySelector('.notify-comment-text-area__suggestions');
        const rect = el.getBoundingClientRect();
        
        if(
          rect.top < 350 || 
          (notification.id === firstYourNotification.id || notification.id === firstAllNotification.id) 
          ) {
          // el.setAttribute("class", "notify-comment-text-area__suggestions top")
          document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='20px';
          document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="auto";
        } else {
            el.setAttribute("class", "notify-comment-text-area__suggestions")
            document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='auto';
            document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="20px";
        }
        const liElement = document.createElement("li");
        liElement.classList += "checklist-textarea-input__suggestions__item";
        var para = document.createElement("span");
        para.className += "allnotify";
        const textInsideSpan = document.createTextNode(
          "@act will notify all members in the activity"
        );
        para.appendChild(textInsideSpan);
        const elementWhereToInsert = document.getElementsByClassName(
          "notify-comment-text-area__suggestions__list"
        )[0];

        if (document.getElementsByClassName("allnotify").length > 0) {
          return;
        } else {
          elementWhereToInsert.insertBefore(
            para,
            elementWhereToInsert.firstChild
          );
        }
      }
    }, 10);

    if (
      localStorage.getItem("notificationReplyCache") &&
      JSON.parse(localStorage.getItem("notificationReplyCache")).id !==
        this.props.notification.id &&
      this.state.textareaValue.length > 0 &&
      this.state.textareaValue[0] !== "@"
    )
      this.setState({ textareaValue: "" });
  }
  youNotificationReadStatus = (notification, reply = null) => {
    
    if (reply === "REPLY") {
      notification.is_read = true;
    }
    if (notification.is_read === false && !reply) {
      notification.is_read = true;
    } else if (notification.is_read === true && !reply) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  notificationFlagStatus = notification => {
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }

    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab(
        { notificationArray, ddownOption, ddownOption2, searchText },
        res => {
          // console.log(" you tab flag status has been changed!");
        }
      );
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab(
        { notificationArray, ddownOption, ddownOption2, searchText },
        res => {
          // console.log("all tab flag status has been changed!");
        }
      );
    }
  };
  addTag = (_event, notification) => {
    const el = document.querySelector('.notify-comment-text-area__suggestions');
    const { firstYourNotification, firstAllNotification } = this.props;
  
    if(el){
      const rect = el.getBoundingClientRect();
      if(
        rect.top < 350 || 
        (notification.id === firstYourNotification.id || notification.id === firstAllNotification.id) 
        )  {
          el.style.zIndex=100;
        // el.setAttribute("class", "notify-comment-text-area__suggestions top")
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='20px';
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="auto";
        document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.zIndex="100";
      } else {
        el.style.zIndex=100;
          el.setAttribute("class", "notify-comment-text-area__suggestions")
          document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='auto';
          document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="20px";
          document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.zIndex="100";

      }
    }
    if (this.state.textareaValue.length === 0) {
      this.setState(
        {
          textareaValue: "@" + notification.sender_info.username + " "
        }
        
      );
    }
  };

  moveCursorAtEnd=(e)=> {

    const { notification, firstYourNotification, updateShowDown } = this.props;

    updateShowDown(notification.id === firstYourNotification.id );
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  handleCommentOnChange = (_event, newValue, _newPlainTextValue, _mentions) => {
    const newString = newValue === "\n" ? [] : newValue.split(" ");

    
    const manipulatedString = newString.map(value => {
      
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });

    this.setState({
      commentTyped: manipulatedString.join(" ")
    });
  };

  handleChange = (_event, newValue, _newPlainTextValue, _mentions) => {
    const newString = newValue === "\n" ? [] : newValue.split(" ");
    const { notification } = this.props;
    
    const manipulatedString = newString.map(value => {
      
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });

    let finalValue = manipulatedString.join(" ");
    if (finalValue.length > 0)
      localStorage.setItem(
        "notificationReplyCache",
        JSON.stringify({
          id: notification.id,
          text: finalValue
        })
      );
    else localStorage.removeItem("notificationReplyCache");
    this.setState({
      textareaValue: finalValue
    });
  };

  replyToComment = notification => {
    let comment = {};
    const commentData = {
      checklist: [],
      commentDetails: {
        isReply: true,
        parentCommentId:
          notification.data.comment.data.commentDetails.isReply === true
            ? notification.data.comment.data.commentDetails.parentCommentId
            : notification.data.comment.id
      },
      lastModifiedBy: parseInt(localStorage.getItem("id"))
    };
    comment.version = "2.0.1";
    comment.platform = "WEB";
    comment.data = commentData;
    comment.title = this.state.textareaValue;
    comment.description = this.state.textareaValue;
    comment.activity_type = "COMMENT";
    comment.user_id = [parseInt(localStorage.getItem("id"))];
    comment.project_id = notification.data.project.id;
    comment.parent_activity_id = notification.data.parent_activity.id;
    comment.created_at = moment().utc().format("YYYY-MM-DDTHH:mm:ss:SSSSSSZ");
    comment.created_by = parseInt(localStorage.getItem("id"));
    comment.updated_on = moment().utc().format("YYYY-MM-DDTHH:mm:ss:SSSSSSZ");

    const {
      replyInYouNotification,
      replyInAllNotification,
      // addCommentAction,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    const { optionValue } = this.props;
    // call isread action
    // this.youNotificationReadStatus(notification, "REPLY");
    this.setState({ fetching: true });
    if (optionValue === "you") {
          replyInYouNotification(
            {
              notification,
              comment,
              ddownOption,
              ddownOption2,
              searchText
            },
            response => {
              this.setState({ fetching: false });
              if (response && !response.stdErr) {
                
                firebase.analytics().logEvent("GTD_Notifications_Reply");
                localStorage.removeItem("notificationReplyCache");
                this.setState({ textareaValue: "" });
                // call addCommentAction
                // addCommentAction(comment, res => {
                //   if (res && res.id) {
                //     // console.log("NOW response from addCommentAction", res);
                //   } else {
                //     // console.log("NOW Error from addCommentAction!");
                //   }
                // });
              }
            }
          );
    } else if (optionValue === "all") {
          replyInAllNotification(
            {
              notification,
              comment,
              ddownOption,
              ddownOption2,
              searchText
            },
            response => {
              this.setState({ fetching: false });
              if (response && !response.stdErr) {
                firebase.analytics().logEvent("GTD_Notifications_Reply");
                localStorage.removeItem("notificationReplyCache");
                this.setState({ textareaValue: "" });
                // call addCommentAction
                // addCommentAction(comment, res => {
                //   // console.log(res);
                //   if (res && res.id) {
                //     // console.log("response from addCommentAction", res);
                //   }
                // });
              }
            }
          );
        }
  };
  renderSuggestion = (entry, _search, _highlightedDisplay, _index, _focused) => {
    const el = document.querySelector('.notify-comment-text-area__suggestions');
    const { notification, firstYourNotification, firstAllNotification } = this.props;
    if(el){
          const rect = el.getBoundingClientRect();
          if(
            rect.top < 350 || 
            ((notification.id === firstYourNotification.id || notification.id === firstAllNotification.id) )
            )   {
              el.style.zIndex=100;
            // el.setAttribute("class", "notify-comment-text-area__suggestions top")
            document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='20px';
            document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="auto";
            document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.zIndex="100";
          } else {
            el.style.zIndex=100;
              el.setAttribute("class", "notify-comment-text-area__suggestions")
              document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.top='auto';
              document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.bottom="20px";
              document.getElementsByClassName('notify-comment-text-area__suggestions__list')[0].style.zIndex="100";

          }
        }

        
    return (
      <>
        <div
          className={
            !entry.image ? "comments-people avatar" : "comments-people"
          }
        >
          {entry.image && <img src={entry.image} className="user-photo" alt="user-img" />}
          {!entry.image && (
            <Avatar
              style={{ width: "100%", height: "100%" }}
              name={entry.display}
              maxInitials={1}
            ></Avatar>
          )}

          <span>{entry.username}</span>
        </div>
        <div className={!entry.image ? "mentio-name avatar" : "mentio-name"}>
          {entry.display}
        </div>
      </>
    );
  };

  isImageDisplay = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    return !!(ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif");
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  render() {
    const { notification, userDetails } = this.props;
    const commentTitleArr = notification.data.comment.title.split(" ");
    const manipulatedString = commentTitleArr.map(value => {
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });
    let commentTitle = manipulatedString.join(" ");
    let finalCommentTitle = notification.message.includes("edit")
      ? commentTitle.substr(0, getPosition(commentTitle, "\n", 2))
      : commentTitle;
    const shouldShow3Dots = notification.message.includes("edit") && finalCommentTitle.length < commentTitle.length;
    const dataForMentions = [
      {
        id: 0,
        display: "act",
        image: "",
        username: "Act"
      },
      ..._.sortBy(
        userDetails.map((user) => {
          return {
            id: user.id,
            display: user.username,
            image: user.img || "",
            username: user.first_name
          };
        }),
        (user) => user.username.toLowerCase()
      )
    ];

    let activitTypeMention=notification.type === "MENTIONS";
    let sameUser=notification.sender_id === notification.recipient_id;
    return (
      <div
        id={`comment-notify-parent-${notification.id}`}
        className="notify-card"
        onClick={() => {
          if (!this.props.delete) {
            this.props.goToActivity(notification);
          }
        }}
      >
        <div className="right-comment-notification-title comments-notifi">
          <Tooltip
            title={
              notification.is_read === true ? "Mark as unread" : "Mark as read"
            }
          >
            <div
              className={
                notification.is_read === true
                  ? "notify-read-unread read"
                  : "notify-read-unread"
              }
              onClick={e => {
                firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
                e.stopPropagation();
                this.youNotificationReadStatus(notification);
              }}
            ></div>
          </Tooltip>
          <Tooltip
            title={
              notification.is_flagged
                ? "Unflag this notification"
                : "Flag this notification"
            }
          >
            <div
              className={
                notification.is_flagged === true
                  ? "notifi-flag flagged"
                  : "notifi-flag"
              }
              onClick={e => {
                firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
                e.stopPropagation();
                this.notificationFlagStatus(notification);
              }}
            ></div>
          </Tooltip>
          <div className="right-notfi-title-img">
            {notification.sender_info.img ? <img src={notification.sender_info.img} alt={notification.sender_info.first_name} /> : 
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={notification.sender_info.first_name}
                maxInitials={1}
                />
            }
          </div>
          <div className="Card_Title">
            {notification.data.parent_activity &&
              (notification.type === "MENTIONS" ||
                notification.type === "COMMENT" ||
                notification.type === "ACT") && (
                <p
                  className="notify-card-title"
                  // onClick={() => this.props.goToActivity(notification)}
                >
                  {!notification.data.project && (
                    <span>
                      {!activitTypeMention  && notification.sender_info.first_name}
                      {activitTypeMention && !sameUser  && notification.sender_info.first_name }
                      {notification.type === "MENTIONS"
                        ? notification.message.includes("edit")
                          ? sameUser?'You have edited your comment in the activity ':" edited a comment in the activity "
                          : sameUser ?'You mentioned yourself in the activity ':" mentioned you in the activity "
                        : " commented on the activity"}
                      <span className="activity-title">
                        {" " + notification.data.parent_activity.title}
                      </span>
                    </span>
                  )}
                  {notification.data.project && (
                    <span>
                      {!activitTypeMention  && notification.sender_info.first_name}
                      {activitTypeMention && !sameUser  && notification.sender_info.first_name }
                      {notification.type === "MENTIONS"
                        ? notification.message.includes("edit")
                        ? sameUser?'You have edited your comment in the activity ':" edited a comment in the activity "
                        : sameUser ?'You mentioned yourself in the activity ':" mentioned you in the activity "
                        : " commented on the activity "}
                      <span className="activity-title">
                        {notification.data.parent_activity.title}
                      </span>{" "}
                      under the project
                      <span className="activity-title">
                        {" " + notification.data.project.name}
                      </span>
                    </span>
                  )}
                </p>
              )}
          </div>
          {notification.data.parent_activity &&
            (notification.type === "EDIT_COMMENT" ||
              notification.type === "DELETE_COMMENT" ||
              notification.type === "DELETE_MENTIONED_COMMENT") && (
              <div className="notify-card-title">
                {notification.sender_id !== notification.recipient_id && (
                  <span>
                    {notification.sender_info.first_name}{" "}
                    {notification.type === "EDIT_COMMENT"
                      ? " edited a comment in the activity "
                      : " deleted a comment from the activity "}
                    <span className="activity-title">
                      {notification.data.parent_activity.title + " "}
                    </span>
                    {notification.data.project && (
                      <span>
                        {" "}
                        under the project
                        <span className="activity-title">
                          {" " + notification.data.project.name}
                        </span>
                      </span>
                    )}
                  </span>
                )}
                {notification.sender_id === notification.recipient_id && (
                  <span>
                    {notification.message}{" "}
                    {notification.type === "EDIT_COMMENT"
                      ? " in the activity"
                      : " from the activity"}
                    <span className="activity-title">
                      {notification.data.parent_activity.title}
                    </span>
                    {notification.data.project && (
                      <span>
                        {" "}
                        under the project{" "}
                        <span className="activity-title">
                          {notification.data.project.name}
                        </span>{" "}
                      </span>
                    )}
                  </span>
                )}
              </div>
            )}

          <div className="clear"></div>
          <p className="comment_post_time">
            {commonDateFormat(notification.created_at)}
          </p>
        </div>

        <div className="right-comment-desc-you">
          <div className="right-comment-notfi-icon">
            <img
              src={
                notification.message.includes("edited")              
                ? notify_edit_comment
                : notification.data.comment.data?.commentDetails?.hasAttachment
                  ? notify_attachment_comment
                  : notify_comment
              }
              alt="comment-icon"
            />
          </div>
          <div className="right-comment-notifi-desc">
            <div className="notifi-comments-desc-holder">
              <div className="description">
                {/* <ReactMarkdown
                  source={parseComment(commentTitle, notification.id)}
                  escapeHtml={false}
                /> */}
                {ReactHtmlParser(parseComment(finalCommentTitle, notification.id))}{shouldShow3Dots && <span>...</span>}
                {/* {ReactHtmlParser(parseComment(commentTitle))} */}
              </div>
              <div className="comm-att-holder">
                  {!notification.message.includes("edit") && notification?.data?.comment?.attachments?.length > 0 && 
                    <React.Fragment>
                      {notification.data.comment.attachments.map((attachment, index) => {
                        return <div
                        className="nw-att-row-container comm-att"
                        key={index}
                        id={`nw-att-row-container-${attachment.id}`}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImageDisplay(attachment.url)
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {this.isImageDisplay(attachment.url) && (
                              <img
                                src={decodeURI(attachment.url)}
                                alt="attachment-img"
                                // onClick={() => this.onClickImg(decodeURI(attachment.url))}
                              />
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") < 0 &&
                              attachment.url.indexOf("www.dropbox.com") < 0 &&
                              attachment.url.indexOf("https://1drv.ms/") < 0 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">
                                    {attachment.url.substr(
                                      attachment.url.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
              
                            <div className="nw-att-details-name comm-att truncate-text notify" onclick={e => e.stopPropagation()}>
                              {attachment.title.length > 22 ? <Tooltip title={attachment.title}>
                              <a href={decodeURI(attachment.url)} target="_blank" rel="noopener noreferrer">
                                {attachment.title}
                              </a>
                              </Tooltip> : <a href={decodeURI(attachment.url)} target="_blank" rel="noopener noreferrer">
                                {attachment.title}
                              </a>}
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              href={decodeURI(attachment.url)}
                              download={attachment.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </div>
                          {/* <div className="nw-att-link-container"></div> */}
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
            </div>
          </div>
          <div className="clear"></div>
          {notification.data.replies && notification.data.replies.length > 0 && !this.state.fetching && !this.state.textareaValue && (
            <div className="Reply-comment-notification-holder">
              <span className="highlight">You replied </span> -{" "}
              <span className="description">
                <ReactMarkdown
                  source={parseComment(notification.data.replies[0], notification.id)}
                  escapeHtml={false}
                />
              </span>
            </div>
          )}
          {((!notification.data.replies && !this.props.delete) || this.state.fetching || this.state.textareaValue) && (
            <div
              className="right-comments-reply-container"
              onClick={e => e.stopPropagation()}
              // onFocus={() => this.addTag(notification)}
            >
              <MentionsInput
                name="notification-reply"
                className="notify-comment-text-area"
                placeholder="Type your reply here"
                // style={{ overflowY: "hidden" }}
                value={this.state.textareaValue}
                onChange={(event, newValue, newPlainTextValue, mentions) =>
                  this.handleChange(
                    event,
                    newValue,
                    newPlainTextValue,
                    mentions
                  )
                }
                onClick={event => {
                  this.addTag(event, notification);
                }}
                onFocus={this.moveCursorAtEnd}
                autoFocus={
                  localStorage.getItem("notificationReplyCache") &&
                  JSON.parse(localStorage.getItem("notificationReplyCache"))
                    .id === notification.id
                    ? true
                    : false
                }
              >
                <Mention
                  type="user"
                  trigger="@"
                  markup="@{{__display__}}"
                  data={dataForMentions}
                  renderSuggestion={this.renderSuggestion}
                  displayTransform={(id, display) => `@${display}`}
                  appendSpaceOnAdd={true}
                ></Mention>
              </MentionsInput>
              <div
                className={
                  this.state.textareaValue &&
                  this.state.textareaValue.length > 0 &&
                  !this.state.fetching
                    ? "right-comments-reply-link active"
                    : "right-comments-reply-link"
                }
                onClick={() =>
                  this.state.textareaValue.length > 0 &&
                  !this.state.fetching &&
                  this.replyToComment(notification)
                }
              >
                Reply
              </div>
            </div>
          )}
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentNotify);
