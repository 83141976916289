export const projectConstants = {
  projectListLoaderState: "PROJECT_LIST_LOADER_STATE",
  projectList: "PROJECT_LIST",
  archivedProjectList: "ARCHIVED_PROJECT_LIST",
  updateProjectLoaderState: "UPDATE_PROJECT_LOADER_STATE",
  createProject: "CREATE_PROJECT",
  updateProjectDetails: "UPDATE_PROJECT_DETAILS",
  clearProjects: "CLEAR_PROJECTS",
  unarchiveProject: "UNARCHIVE_PROJECT",
  superAdmin: "SUPERADMIN",
  manager: "Manager",
  member: "Member",
  activeTab: "active_tab",
  breadCrumb: [
    { name: "Projects", link: "", type: "heading" },
    { name: "Active", link: "active", type: "tabs", className: "active_tab" },
    { name: "On hold", link: "onHold", type: "tabs", className: "" },
    { name: "Completed", link: "completed", type: "tabs", className: "" },
    { name: "Cancelled", link: "cancelled", type: "tabs", className: "" },
    {
      name: "",
      link: "project",
      type: "search",
      className: "project-search",
      SearchClassName: "project-search-parent"
    },
    // {name: 'Project access request', link: 'accessRequest', type: "button", className: "project-request-btn"}
  ],
  singleProjectLoaderState :"SINGLE_PROJECT_LOADER_STATE",
  getProjectById:'GET_PROJECT_BY_ID',
  widgets: {
    reports: "Reports",
    addRemoveMember: "Add/Remove members"
  }
};
