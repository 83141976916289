import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";

//Old API authentication
// export function authenticateLogin({ email, password }, callback) {
//   secureFetch(API_URL + "/api/v1/login", {
//     method: "POST",
//     body: JSON.stringify({
//       email: email,
//       password: password
//     }),
//     headers: new Headers({
//       "Content-Type": "application/json",
//       Origin: API_URL,
//       Authorization: "Bearer: " + routesHelper.getAuthToken()
//     })
//   })
//     .then((respJson) => callback(respJson))
//     .catch((error) => {
//       //
//     });
// }

// New API authentication
export function authenticateLogin({ email, password }, callback) {
  secureFetch(API_URL + "/api/v2/login", {
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

//Old API authentication
// export function authenticateAppleLogin({ apple_token }, callback) {
//   secureFetch(API_URL + "/api/v1/login?client=apple", {
//     method: "POST",
//     // body: JSON.stringify({
//     //   apple_token
//     // }),
//     headers: new Headers({
//       "Content-Type": "application/json",
//       Origin: API_URL,
//       Authorization: "Bearer: " + apple_token
//     })
//   })
//     .then((respJson) => callback(respJson))
//     .catch((error) => {
//       //
//     });
// }

// New API authentication
export function authenticateAppleLogin({ apple_token }, callback) {
  secureFetch(API_URL + "/api/v2/login?client=apple", {
    method: "POST",
    body: JSON.stringify({
      auth_token: apple_token,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function updateLastActive({ lastActiveOn, lastUsedTimeZone }, callback) {
  secureFetch(API_URL + "/api/v1/user-location", {
    method: "PUT",
    body: JSON.stringify({
      id: parseInt(localStorage.getItem("id")),
      last_active_on: lastActiveOn,
      last_used_timezone: lastUsedTimeZone,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken(),
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

//Old API authentication
// export function autherizeGoogleUser({ code, clientId, redirectUri }, callback) {
//   secureFetch(API_URL + "/api/v1/auth/google", {
//     method: "POST",
//     body: JSON.stringify({
//       code,
//       clientId,
//       redirectUri,
//       prompt: "consent",
//     }),
//     headers: new Headers({
//       "Content-Type": "application/json",
//       Origin: API_URL,
//       Authorization:
//         "Bearer: " + (routesHelper.getAuthToken() || "abcd-1234-efgh-5678"),
//     }),
//   })
//     .then((respJson) => callback(respJson))
//     .catch((error) => {
//       //
//     });
// }

// New API authentication
export function autherizeGoogleUser(
  { code, clientId, redirectUri, type, access_type },
  callback
) {
  const url = type ? "?type=" + type : "";
  secureFetch(API_URL + "/api/v2/auth/google" + url, {
    method: "POST",
    body: JSON.stringify({
      code,
      clientId,
      redirectUri,
      prompt: "consent",
      access_type,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function autherizeAppleUser({ code }, callback) {
  secureFetch(API_URL + "/api/v1/auth/apple", {
    method: "POST",
    body: JSON.stringify({ code }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      Authorization: "Bearer: " + routesHelper.getAuthToken(),
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function forgotPasswordReuest({ email }, callback) {
  secureFetch(API_URL + "/api/v1/forgot-password", {
    method: "POST",
    body: JSON.stringify({
      email: email,
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function resetPasswordRequest(
  { email, token, retypePassword },
  callback
) {
  secureFetch(API_URL + "/api/v1/reset-password", {
    method: "POST",
    body: JSON.stringify({
      email,
      token,
      new_password: retypePassword,
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function verifyEmail({ token }, callback) {
  secureFetch(API_URL + "/api/v1/verify-email", {
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
    }),
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}
