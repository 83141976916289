export const dashboardConstants = {
  breadCrumb: [
    { name: "Dashboard", link: "", type: "heading" },
    {
      name: "Team",
      link: "team",
      type: "dashboard_tabs",
      className: "active_tab"
    },
    { name: "Personal", link: "personal", type: "dashboard_tabs", className: "" }
  ],
  getEffortOverviewLoader: "GET_EFFORT_OVERVIEW_LOADER",
  getEffortOverview: "GET_EFFORT_OVERVIEW",
  getIndividualEffortLoader: "GET_INDIVIDUAL_EFFORT_LOADER",
  getIndividualEffort: "GET_INDIVIDUAL_EFFORT",
  getProjectAccessTeamLoader: "GET_PROJECT_ACCESS_TEAM_LOADER",
  getProjectAccessTeam: "GET_PROJECT_ACCESS_TEAM",
  updateProjectAccessTeamLoader: "UPDATE_PROJECT_ACCESS_TEAM_LOADER",
  removeProjectAccessTeam: "REMOVE_PROJECT_ACCESS_TEAM",
  getMissedTimeSheetsLoader: "GET_MISSED_TIME_SHEETS_LOADER",
  getMissedTimeSheets: "GET_MISSED_TIME_SHEETS",
  getEffortOverviewPersonalLoader: "GET_EFFORT_OVERVIEW_PERSONAL_LOADER",
  getEffortOverviewPersonal: "GET_EFFORT_OVERVIEW_PERSONAL",
  getProjectAccessPersonalLoader: "GET_PROJECT_ACCESS_PERSONAL_LOADER",
  getProjectAccessPersonal: "GET_PROJECT_ACCESS_PERSONAL",
  updateProjectAccessPersonalLoader: "UPDATE_PROJECT_ACCESS_PERSONAL_LOADER",
  getProjectStatus: "GET_PROJECT_STATUS",
  getLeaderBoard: "GET_LEADER_BOARD",
  clearDashboardReducer: "CLEAR_DASHBOARD_REDUCER"
};
