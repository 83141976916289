import { notificationsConstants } from "../../../src/constants/notificationconstants";
import {
  getGlobalNotifications,
  getSystemNotifications,
  getAllNotifications,
  getYourNotifications,
  ChangeIsReadStatusOfYou,
  changeIsNewStatusOfSystemNotification,
  markAllAsRead,
  archiveSystemNotification,
  replyInNotification,
  getSearchNotifications,
  getAllFlaggedNotifications,
  getYouFlaggedNotifications,
  getYouReadNotifications,
  getAllReadNotifications
} from "../../services/notificationapis";
import _ from "lodash";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateNotificationList(notificationList) {
  return {
    type: notificationsConstants.getAllNotifications,
    payload: {
      list: notificationList,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: localStorage.getItem("customer_auth_token")
    }
  };
}

function pushNewYouNotification(notification) {
  return {
    type: notificationsConstants.pushNewYouNotification,
    payload: {
      notification
    }
  };
}
function pushNewAllNotification(notification) {
  return {
    type: notificationsConstants.pushNewAllNotification,
    payload: {
      notification
    }
  };
}

function updateYourNotificationList({ response, customer_auth_token }) {
  return {
    type: notificationsConstants.getYourNotifications,
    payload: {
      list: response,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: customer_auth_token
    }
  };
}

function getFlaggedAllNotification({ res, page, customer_auth_token }) {
  return {
    type: notificationsConstants.flaggedNotificationOfAllTab,
    payload: {
      list: res,
      page: page,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: customer_auth_token
    }
  };
}
function getFlaggedYouNotification({ res, page, customer_auth_token }) {
  return {
    type: notificationsConstants.flaggedNotificationOfYouTab,
    payload: {
      list: res,
      page: page,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: customer_auth_token
    }
  };
}

function getReadAllNotification({ res, page, customer_auth_token }) {
  return {
    type: notificationsConstants.readNotificationOfAllTab,
    payload: {
      list: res,
      page: page,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: customer_auth_token
    }
  };
}
function getReadYouNotification({ res, page, customer_auth_token }) {
  return {
    type: notificationsConstants.readNotificationOfYouTab,
    payload: {
      list: res,
      page: page,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: customer_auth_token
    }
  };
}

function removeNotificationFunction() {
  return {
    type: notificationsConstants.removeNotification
  };
}

function changeNoItemStatus(showStatus) {
  return {
    type: notificationsConstants.updateNoItemStatus,
    payload: { showStatus }
  };
}


function updateNotificationFlag() {
  return {
    type: notificationsConstants.updateNotificationFlag
  };
}

function updateNotificationRead() {
  return {
    type: notificationsConstants.updateNotificationRead
  };
}

function updateNotificationSearchFlag() {
  return {
    type: notificationsConstants.updateNotificationSearchFlag
  };
}



// you tab
function changeIsReadStatusOfYou(notificationArray, ddownOption, ddownOption2, searchText) {
  // console.log(
  //   "from action changed read status,ddownOption",
  //   notificationArray[0].is_read,
  //   ddownOption,
  //   searchText
  // );

  return {
    type: notificationsConstants.changeReadStatusOfYouNotification,
    payload: {
      id: notificationArray[0].id,
      is_read: notificationArray[0].is_read,
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function changeFlagStatusOfYou(notificationArray, ddownOption, ddownOption2, searchText) {
  // console.log(
  //   "called changeFlagStatusOfYou action",
  //   notificationArray[0].is_flagged,
  //   ddownOption,
  //   searchText
  // );
  return {
    type: notificationsConstants.changeFlagStatusOfYouNotification,
    payload: {
      id: notificationArray[0].id,
      is_flagged: notificationArray[0].is_flagged,
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function changeNewStatusSystemNotification(notificationArray) {
  return {
    type: notificationsConstants.changeNewStatusOfSystemNotification,
    payload: {
      // id: notificationArray[0].id,
      is_new: false
    }
  };
}

function archiveRequestSystemNotification(notificationArray) {
  return {
    type: notificationsConstants.archiveRequestSystemNotification,
    payload: {
      // id: notificationArray[0].id,
      is_archived : true
    }
  };
}

function ChangeNewStatus(notificationArray) {
  return {
    type: notificationsConstants.changeNewStatusOfNotification,
    payload: {
      // id: notificationArray[0].id,
      is_new: false
    }
  };
}
// All Tab
function changeIsReadStatusOfAll(notificationArray, ddownOption, ddownOption2, searchText) {
  // console.log("from action  ddownOption", ddownOption, searchText);
  return {
    type: notificationsConstants.changeReadStatusOfAllNotification,
    payload: {
      id: notificationArray[0].id,
      is_read: notificationArray[0].is_read,
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function changeFlagStatusOfAll(notificationArray, ddownOption, ddownOption2, searchText) {
  // console.log("from action all tab ddownOption value", ddownOption, searchText);
  return {
    type: notificationsConstants.changeFlagStatusOfAllNotification,
    payload: {
      id: notificationArray[0].id,
      is_flagged: notificationArray[0].is_flagged,
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function markAllAsReadOfYouTab(notificationArray, ddownOption, ddownOption2, searchText) {
  return {
    type: notificationsConstants.markAllNotificationAsReadOfYouTab,
    payload: {
      ids: notificationArray.map(notification => notification.id),
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function markAllAsReadOfAllTab(notificationArray, ddownOption, ddownOption2, searchText) {
  return {
    type: notificationsConstants.markAllNotificationAsReadOfAllTab,
    payload: {
      ids: notificationArray.map(notification => notification.id),
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}
function updateGlobalNotificationList(notificationList) {
  return {
    type: notificationsConstants.getGlobalNotifications,
    payload: {
      list: notificationList,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: localStorage.getItem("customer_auth_token")
    }
  };
}
function updateSystemNotificationList(notificationList) {
  return {
    type: notificationsConstants.getSystemNotifications,
    payload: {
      list: notificationList,
      key: new Date().getTime().toLocaleString(),
      customer_auth_token: localStorage.getItem("customer_auth_token")
    }
  };
}
function archiveSystemNotificationFunction(notificationList) {
  return {
    type: notificationsConstants.archiveSystemNotification,
    payload: {
      id: notificationList.id,
      list: notificationList.archived_by_users,
      key: new Date().getTime().toLocaleString()
    }
  };
}
function replyInYouNotificationFunction(
  notificationsObjectArray,
  ddownOption,
  ddownOption2,
  searchText
) {
  return {
    type: notificationsConstants.replyInYouNotification,
    payload: {
      id: notificationsObjectArray[0].id,
      is_read: notificationsObjectArray[0].is_read,
      reply: notificationsObjectArray[0].data.replies[0],
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}
function replyInAllNotificationFunction(
  notificationsObjectArray,
  ddownOption,
  ddownOption2,
  searchText
) {
  return {
    type: notificationsConstants.replyInAllNotification,
    payload: {
      id: notificationsObjectArray[0].id,
      is_read: notificationsObjectArray[0].is_read,
      reply: notificationsObjectArray[0].data.replies[0],
      ddownOption: ddownOption,
      ddownOption2,
      searchText: !_.isEmpty(searchText)
    }
  };
}

function getSearchYouNotificationFunction({ response, searchtext }) {
  return {
    type: notificationsConstants.searchYouNotification,
    payload: {
      list: response,
      searchtext: searchtext,
      key: new Date().getTime().toLocaleString()
    }
  };
}
function getSearchAllNotificationFunction({ response, searchtext }) {
  return {
    type: notificationsConstants.searchAllNotification,
    payload: {
      list: response,
      searchtext: searchtext,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export const getGlobalNotificationsAction = callback => dispatch => {
  getGlobalNotifications(response => {
    if (response && !response.error) {
      let updatedResponse = null;
      if (localStorage.getItem("created_at")) {
        updatedResponse = response.filter(
          globalNotification =>
            globalNotification.created_at > localStorage.getItem("created_at")
        );
      }
      dispatch(updateGlobalNotificationList(updatedResponse || response));
    }
    callback && callback(response);
  });
};

export const getSystemNotificationsAction = ({auth_token}, callback) => dispatch => {
  getSystemNotifications({auth_token}, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(updateSystemNotificationList(response));
    }
    callback && callback(response);
  });
};

export const archiveSystemNotificationAction = (
  { notificationData },
  callback
) => dispatch => {
  archiveSystemNotification({ notificationData }, response => {
    if (response && !response.error) {
      dispatch(archiveSystemNotificationFunction(response));
    }
    callback && callback(response);
  });
};
export const getAllNotificationsAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.getAllNotificationsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getAllNotifications(
    { auth_token, page, per_page, recipient_id },
    response => {
      if (response && !response.error) {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.getAllNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        dispatch(updateNotificationList(response));
      } else {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.getAllNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const getYourNotificationsAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.getYourNotificationsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getYourNotifications(
    { auth_token, page, per_page, recipient_id },
    response => {
      if (response && !response.error) {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.getYourNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        dispatch(
          updateYourNotificationList({
            response,
            // response: [timerNotification , ...response],
            customer_auth_token: auth_token
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.getYourNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const removeNotifications = () => dispatch => {
  dispatch(removeNotificationFunction());
};

export const changeNoItemStatusAction = ({ showStatus }) => dispatch => {
  dispatch(changeNoItemStatus(showStatus));
};

export const changeIsReadStatusOfYouTabAction = (
  { notificationArray, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  let recipient_id = localStorage.getItem("id");
  ChangeIsReadStatusOfYou({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(
        changeIsReadStatusOfYou(notificationArray, ddownOption, ddownOption2, searchText)
      );
    }
    callback && callback(response);
  });
};
export const changeIsReadStatusOfAllTabAction = (
  { notificationArray, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  let recipient_id = localStorage.getItem("id");
  ChangeIsReadStatusOfYou({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(
        changeIsReadStatusOfAll(notificationArray, ddownOption, ddownOption2, searchText)
      );
    }
    callback && callback(response);
  });
};

export const changeIsNewStatusOfSystemNotificationAction = (
  { notificationArray },
  callback
) => dispatch => {
  changeIsNewStatusOfSystemNotification({ notificationArray }, response => {
    if (response && !response.error) {
      dispatch(changeNewStatusSystemNotification(notificationArray));
    }
    callback && callback(response);
  });
};

export const archiveRequestSystemNotificationAction = (
  { notificationArray, recipient_id },
  callback
) => dispatch => {
  changeIsNewStatusOfSystemNotification({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(archiveRequestSystemNotification(notificationArray));
    }
    callback && callback(response);
  });
};

export const changeIsNewStatusAction = (
  { notificationArray },
  callback
) => dispatch => {
  let recipient_id = localStorage.getItem("id");
  ChangeIsReadStatusOfYou({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(ChangeNewStatus(notificationArray));
    }
    callback && callback(response);
  });
};

export const changeFlagStatusOfYouTabAction = (
  { notificationArray, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  let recipient_id = localStorage.getItem("id");
  ChangeIsReadStatusOfYou({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(
        changeFlagStatusOfYou(notificationArray, ddownOption, ddownOption2, searchText)
      );
    }
    callback && callback(response);
  });
};

export const changeFlagStatusOfAllTabAction = (
  { notificationArray, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  let recipient_id = localStorage.getItem("id");
  ChangeIsReadStatusOfYou({ notificationArray, recipient_id }, response => {
    if (response && !response.error) {
      dispatch(
        changeFlagStatusOfAll(notificationArray, ddownOption, ddownOption2, searchText)
      );
    }
    callback && callback(response);
  });
};

export const markAllAsReadOfYouTabAction = (
  { notificationArray, recipient_id = localStorage.getItem("id"), ddownOption, searchText },
  callback
) => dispatch => {
  markAllAsRead({ notificationArray, recipient_id, type: "you" }, response => {
    if (response && !response.error) {
      dispatch(
        markAllAsReadOfYouTab(notificationArray, ddownOption, searchText)
      );
    }
    callback && callback(response);
  });
};

export const markAllAsReadOfAllTabAction = (
  { notificationArray, recipient_id = localStorage.getItem("id"), ddownOption, searchText },
  callback
) => dispatch => {
  markAllAsRead({ notificationArray, recipient_id, type: "all" }, response => {
    if (response && !response.error) {
      dispatch(
        markAllAsReadOfAllTab(notificationArray, ddownOption, searchText)
      );
    }
    callback && callback(response);
  });
};

export const replyInYouNotificationAction = (
  { notification, comment, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.replyToNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  let notificationsObjectArray = [];
  let obj = {};
  obj["id"] = notification.id;
  obj["is_read"] = true;
  obj["data"] = notification.data;
  obj.data.replies = [comment.title];
  obj.comment_details = comment;
  notificationsObjectArray.push(obj);
  let recipient_id = localStorage.getItem("id");

  replyInNotification({ notificationsObjectArray, recipient_id }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: notificationsConstants.replyToNotificationLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        replyInYouNotificationFunction(
          notificationsObjectArray,
          ddownOption,
          ddownOption2,
          searchText
        )
      );
    } else {
      dispatch(
        updateLoaderState({
          action: notificationsConstants.replyToNotificationLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};
export const replyInAllNotificationAction = (
  { notification, comment, ddownOption, ddownOption2, searchText },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.replyToNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  let notificationsObjectArray = [];
  let obj = {};
  obj["id"] = notification.id;
  obj["is_read"] = true;
  obj["data"] = notification.data;
  obj.data.replies = [comment.title];
  obj.comment_details = comment;
  notificationsObjectArray.push(obj);
  let recipient_id = localStorage.getItem("id");

  replyInNotification({ notificationsObjectArray, recipient_id }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: notificationsConstants.replyToNotificationLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        replyInAllNotificationFunction(
          notificationsObjectArray,
          ddownOption,
          ddownOption2,
          searchText
        )
      );
    } else {
      dispatch(
        updateLoaderState({
          action: notificationsConstants.replyToNotificationLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getSearchYouNotificationAction = (
  { like, page, recipient_id, type, is_flagged, is_read },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.searchYourNotificationsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getSearchNotifications(
    { like, page, recipient_id, type, is_flagged, is_read },
    response => {
      if (response && !response.error) {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.searchYourNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );

        dispatch(
          getSearchYouNotificationFunction({
            response: response,
            searchtext: like
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.searchYourNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: true
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const getSearchAllNotificationAction = (
  { like, page, recipient_id, type, is_flagged, is_read },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.searchAllNotificationsLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getSearchNotifications(
    { like, page, recipient_id, type, is_flagged, is_read },
    response => {
      if (response && !response.error) {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.searchAllNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );

        dispatch(
          getSearchAllNotificationFunction({
            response: response,
            searchtext: like
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action: notificationsConstants.searchAllNotificationsLoaderState,
            state: {
              fetching: false,
              fetched: false,
              error: true
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const getNewYouNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  getYourNotifications(
    { auth_token, page, per_page, recipient_id },
    response => {
      if (response && !response.error) {
        dispatch(pushNewYouNotification(response));
      }
      callback && callback(response);
    }
  );
};

export const getNewAllNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  getAllNotifications(
    { auth_token, page, per_page, recipient_id },
    response => {
      if (response && !response.error) {
        dispatch(pushNewAllNotification(response));
      }
      callback && callback(response);
    }
  );
};
export const getFlaggedYouNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.listOfFlaggedYouNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getYouFlaggedNotifications(
    { auth_token, page, per_page, recipient_id },
    res => {
      if (res && !res.error) {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedYouNotificationLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        dispatch(
          getFlaggedYouNotification({
            res,
            page,
            customer_auth_token: auth_token
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedYouNotificationLoaderState,
            state: {
              fetching: false,
              fetched: false,
              error: true
            }
          })
        );
      }
      callback && callback(res);
    }
  );
};

export const getFlaggedAllNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.listOfFlaggedAllNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );

  getAllFlaggedNotifications(
    { auth_token, page, per_page, recipient_id },
    res => {
      if (res && !res.error) {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedAllNotificationLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );

        dispatch(
          getFlaggedAllNotification({
            res,
            page,
            customer_auth_token: auth_token
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedAllNotificationLoaderState,
            state: {
              fetching: false,
              fetched: false,
              error: true
            }
          })
        );
      }
      callback && callback(res);
    }
  );
};

export const getReadYouNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.listOfFlaggedYouNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getYouReadNotifications(
    { auth_token, page, per_page, recipient_id },
    res => {
      if (res && !res.error) {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedYouNotificationLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );
        dispatch(
          getReadYouNotification({
            res,
            page,
            customer_auth_token: auth_token
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedYouNotificationLoaderState,
            state: {
              fetching: false,
              fetched: false,
              error: true
            }
          })
        );
      }
      callback && callback(res);
    }
  );
};

export const getReadAllNotificationAction = (
  { auth_token, page, per_page, recipient_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: notificationsConstants.listOfFlaggedAllNotificationLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );

  getAllReadNotifications(
    { auth_token, page, per_page, recipient_id },
    res => {
      if (res && !res.error) {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedAllNotificationLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: false
            }
          })
        );

        dispatch(
          getReadAllNotification({
            res,
            page,
            customer_auth_token: auth_token
          })
        );
      } else {
        dispatch(
          updateLoaderState({
            action:
              notificationsConstants.listOfFlaggedAllNotificationLoaderState,
            state: {
              fetching: false,
              fetched: false,
              error: true
            }
          })
        );
      }
      callback && callback(res);
    }
  );
};

export const updateNotificationFlagAction=()=>dispatch=>{
        dispatch(updateNotificationFlag());
}

export const updateNotificationReadAction=()=>dispatch=>{
  dispatch(updateNotificationRead());
}

export const updateNotificationFlagSearchAction=()=>dispatch=>{
  dispatch(updateNotificationSearchFlag());
}
