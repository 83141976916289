import React, { useState, useEffect } from "react";
import moment from "moment";
import { Rnd } from "react-rnd";
import starActive from "../../styles/img/svg/star_active.svg";
import KanbanCardStatus from "../kanban/kanabnCardStatus";
import { workloadConstant } from "../../constants/workloadConstant";
import onTimeIcon from "../../styles/img/svg/duration_act.svg";
import calendarIcon from "../../styles/img/svg/calendar-flow.svg";
import { secondsToHms } from "../../helpers/generichelper";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  cursor: "pointer",
  background: "#ffffff",
  padding: "0 15px",
  borderRadius: "2px"
};

export default function ActivityBlockDay({ activity, index, length,scrollHeight, selectedUser }) {
  const totalTimeElapsed =
    activity.timesheets &&
    activity.timesheets.reduce((acc, el) => {
      return (acc += el.time_taken);
    }, 0);
  const delayedActivity = totalTimeElapsed > activity.duration ? true : false;
  const userId = parseInt(localStorage.getItem("id"));
  const selected_user_duration = activity.users_duration && activity.users_duration.length>0 &&  activity.users_duration.filter(user => user.user_id === parseInt(selectedUser.id))[0];
  const activityMins = secondsToHms(selected_user_duration?.total_time || activity.duration).mins.toString() + "m";

  const isPriority =
    activity.data.priority &&
    activity.data.priority.filter(user => user.user_id === userId).length > 0
      ? true
      : false;

  const positionInX = 0;

  const positionInY = index > 0 ? index * 50 + 10 : 10;
  const activityHours = Math.floor((selected_user_duration?.total_time || activity.duration) / 3600)
  const [width, setwidth] = useState(length);
  // const [height, setheight] = useState(40);
  const height = 40;
  const [positionX, setPositionX] = useState(positionInX + 5);
  const [positionY, setPositionY] = useState(positionInY);
  const [showActivity, setShowActivity] = useState(false);


  useEffect(() => {
    setwidth(length);
    setPositionY(index > 0 ? index * 50 + 10 : 10);
  }, [length, index]);

  const showActivityPopup = (event) => {
    setShowActivity(true);
    let tooltip=document.getElementsByClassName("workload-showActivity")[0];
    if(tooltip && tooltip.style){
      tooltip.style.transform=`translate(${positionInX+width/2}px, ${positionY-130-scrollHeight}px)`;
        tooltip.style.opacity=1;
   
    }
    setShowActivity(true);
  }

  const hideActivityPopup = () => {
    setShowActivity(false);

  }

  return (
    <div onMouseMove={showActivityPopup} onMouseLeave={hideActivityPopup}>
      {showActivity &&
        <div style={{opacity:0}} className="workload-showActivity" >
          <div className="workload-act-header">
            <div className="workload-act-wrap">
              <span className="workload-act-title">
                {activity.title}
              </span>
              {isPriority && (
                <span className="workload-activity-star-icon">
                  <img src={starActive} alt="star-icon"></img>
                </span>
              )}
            </div>
            <div className="workload-proj-title">
              {activity.project && activity.project.name}
            </div>
          </div>
          {/* <div className="workload-act-body">
              <span className="workload-act-body-tag">{activity.tags[0]}</span>
          </div> */}
          <div className="workload-date-time">
            <div className="worload-timer">
              <img src={onTimeIcon} alt="onTime-icon" />
              {" "}
              <span >{selected_user_duration?.continuous ? "Continuous" : `${activityHours}h ${activityMins}`} </span>
            </div>
            <div className="worload-date">
                <img src={calendarIcon} alt="date-picker" />
                <span className="workload-time">
              {" "}
              {moment(selected_user_duration?.start_date || activity.start_on).format("DD MMM YYYY")} -{" "}
              {selected_user_duration?.continuous ? "Continuous" : moment(selected_user_duration?.end_date || activity.due_on).format("DD MMM YYYY")}
            </span>
            </div>


          </div>
        </div>
      
      }
      <Rnd
      style={style}
      size={{ width: width - 10, height: height }}
      onDragStop={(e, d) => {
        e.stopPropagation();
        e.preventDefault();
        setPositionX({ x: d.x });
        setPositionY({ y: d.y });
      }}
      position={{ x: positionX, y: positionY }}
      resizeHandleStyles={{
        bottom: { display: "none" },
        top: { display: "none" },
        left: { display: "none" }
      }}
      resizeGrid={[1, 0]}
      onResizeStop={(e, direction, ref, delta, position) => {
        // console.log(position);
        setwidth(Math.ceil(delta.width / length) * length + width);
      }}
      className={delayedActivity ? "activity-delayed" : "activity-on-time"}
    >
      <span className="activity-name-workload">
        {/* {activity.title.substring(0, 56)} */}
        {activity.title}
      </span>
      <span className="activity-hours-workload">{selected_user_duration?.continuous ? "" : `${activityHours}h`} </span>
      {isPriority && (
        <span className="activity-star-icon">
          <img src={starActive} alt="star-icon"></img>
        </span>
      )}
      <KanbanCardStatus activity={activity} source={workloadConstant.fromWorkload}></KanbanCardStatus>
      </Rnd>
    </div>
  );
}
