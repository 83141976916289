import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";

export const getBillingAmounts = callback => {
  let url = API_URL + "/api/v1/billing";
  secureFetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const createStripeCustomer = (
  { auth_token = null, stripeToken },
  callback
) => {
  let url = "/api/v1/stripe-customer";
  secureFetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify({ stripe_token: stripeToken }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //console.log("project error",error)
    });
};

export const getBillingInfo = callback => {
  let url = API_URL + "/api/v1/subscription";
  secureFetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getCustomer = callback => {
  let url = "/api/v1/stripe-customer";

  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getCharges = callback => {
  let url = "/api/v1/stripe-charge";

  secureFetch(API_URL + url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const updateSubscription = (
  { existingSubscriptionObj, planType, update_type, user_id, action },
  callback
) => {
  let url = "/api/v1/subscription";
  let changePlanType = {};
  if(user_id && action) {
    changePlanType = {
      stripe_subscription_id: existingSubscriptionObj.data[0].id,
      update_type: update_type,
      plan: planType,
      quantity: existingSubscriptionObj.data[0].quantity,
      user_id,
      action
    };
  } else {
    changePlanType = {
      stripe_subscription_id: existingSubscriptionObj.data[0].id,
      update_type: update_type,
      plan: planType,
      quantity: existingSubscriptionObj.data[0].quantity
    };
  }

  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify(changePlanType),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const makePayment = ({ plan, quantity }, callback) => {
  let url = "/api/v1/subscription";
  secureFetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify({ plan, quantity }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const cancelSubscription = ({ existingSubscriptionObj }, callback) => {
  let url = "/api/v1/subscription";

  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify({
      stripe_subscription_id: existingSubscriptionObj.data[0].id,
      update_type: "cancel_or_reactivate",
      cancel_at_period_end: true
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const reactivateSubscription = (
  { existingSubscriptionObj },
  callback
) => {
  let url = "/api/v1/subscription";

  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify({
      stripe_subscription_id: existingSubscriptionObj.data[0].id,
      update_type: "cancel_or_reactivate",
      cancel_at_period_end: false
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
      return respJson;
    })
    .catch(error => {
      //
    });
};

export const updateCardDetails = ({ paymentObject }, callback) => {
  let url = "/api/v1/stripe-card";
  // console.log(" I am Here", paymentObject);
  const objectToBeSend = {
    card_id: paymentObject.card_id,
    address: paymentObject.address,
    city: paymentObject.city,
    zip: paymentObject.zip,
    country: paymentObject.country,
    state: paymentObject.state,
    name: paymentObject.name
  };

  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify(objectToBeSend),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const updateCustomer = (body, callback) => {
  let url = "/api/v1/stripe-customer";

  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};
