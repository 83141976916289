import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import searchIcon from "../../styles/img/svg/settings-search-icon1.svg";


const SettingsDropDown = props => {
  let showDropdownNode = null;

  const [selectedValue, setSelectedValue] = useState("All projects");

  const onSelectValue = (event, value) => {
    props.onSelectValue(value, event.target.innerText);
    setSelectedValue(event.target.innerText);
  };

  const onOutsideClickDropDown = e => {
    if (showDropdownNode && !showDropdownNode.contains(e.target)) {
      if (props.showDropDown) {
        props.onOutsideClickDropDown();
      }
    }
  };

  const renderDropDown = selectedValue => {
    return (
      <div className="project-all-options">
        <p
          className={`op ${
            selectedValue === "All projects" ? "tick-settings" : ""
          }`}
          onClick={event => onSelectValue(event, "ALL")}
        >
          All projects{" "}
        </p>
        <p
          className={`op ${selectedValue === "Active" ? "tick-settings" : ""}`}
          onClick={event => onSelectValue(event, "ACTIVE")}
        >
          Active
        </p>
        <p
          className={`op ${selectedValue === "On hold" ? "tick-settings" : ""}`}
          onClick={event => onSelectValue(event, "ON_HOLD")}
        >
          On hold{" "}
        </p>
        <p
          className={`op ${
            selectedValue === "Completed" ? "tick-settings" : ""
          }`}
          onClick={event => onSelectValue(event, "COMPLETED")}
        >
          Completed{" "}
        </p>
        <p
          className={`op ${
            selectedValue === "Cancelled" ? "tick-settings" : ""
          }`}
          onClick={event => onSelectValue(event, "CANCELLED")}
        >
          Cancelled{" "}
        </p>
        <p
          className={`op ${
            selectedValue === "Archived" ? "tick-settings" : ""
          }`}
          onClick={event => onSelectValue(event, "ARCHIVED")}
        >
          Archived{" "}
        </p>
      </div>
    );
  };

  const {
    projectCount,
    showDropDown,
    onShowSearch,
    onShowDropDown,
    selectedValueView,
  } = props;
  return (
    <React.Fragment>
      <div className="project-filter-container">
        <div ref={node => (showDropdownNode = node)}>
          <span
            className={`select-filter ${
              showDropDown ? "select-filter-open" : ""
            }`}
            onClick={e => onShowDropDown(e)}
          >
            {selectedValueView} - {projectCount}
          </span>
          {showDropDown && (
            <OutsideClickHandler onOutsideClick={onOutsideClickDropDown}>
              {renderDropDown(selectedValueView)}
            </OutsideClickHandler>
          )}
        </div>
        <div className="search-settings-project" onClick={onShowSearch}>
          <img src={searchIcon} alt="search-icon" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsDropDown;
