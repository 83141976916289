import React, { PureComponent } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Mark from "mark.js";
import AddFeatures from "../activity/addfeatures";
// import { toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
import { Tooltip } from "@material-ui/core";
import { CSVLink } from "react-csv";
import downloadReport from "../../styles/img/svg/download_report.svg";
import closeIcon from "../../styles/img/svg/Close_White.svg";
import editReport from "../../styles/img/svg/edit _blues.svg";
import deleteReport from "../../styles/img/svg/Delete_report.svg";
import { reports } from "../../constants/reportsconstants";
import {
  getTimeSheetsView,
  getSortedGroupView,
  getTotalDuration,
  getReportToDownload,
  getReportToDownloadCSV
} from "../../helpers/reportlanding";
import DialogWrapper from "../common/dialog";
import ActivityForm from "../activity/addactivity";
import EditReport from "./editReport";
import moment from "moment";
import firebase from "../../firebase";
import { connect } from "react-redux";
import { getReportAction } from "../../actions/timesheet/timesheetaction";
import { getReport } from '../../services/timesheetapi';
import routesHelper from "../../router/routeshelper";

class TimeSheetTable extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    showEditDialog: false,
    showAddDialog: false,
    showAddButton: false,
    selectedTimeSheet: "",
    showDeleteDialog: false,
    indexToShow: 0,
    sortBy: reports.date,
    sortType: false,
    showFooter: false,
    top: 0,
    showReportDownload: false,
    clickedImg: null,
    notifyTimeSheetDate: null,
    notifyTimeSheetId: null
  };
  highlightTimer;

  UNSAFE_componentWillMount() {
    const { sort } = this.props;
    this.setState({ sortBy: sort.sortBy, sortType: sort.sortType });
  }


  componentDidUpdate() {
    let reportDiv = document.getElementById("report-div");
    let reportDivInner = document.getElementById("report-div-inner");
    if (reportDiv) {
      if (reportDivInner.offsetHeight < reportDiv.offsetHeight) {
        if (!this.state.showFooter)
          this.setState({ showFooter: true, showAddButton: true });
      }
    } else if (!this.state.showAddButton)
      this.setState({ showAddButton: true });
  }

  static getDerivedStateFromProps(props, state) {
    let reportDate = props.router.location.state?.reportDate || props.router.location.query.timesheet_date || props.router.location.query.date;
    reportDate = reportDate && decodeURI(reportDate);
    if(parseInt(localStorage.getItem("customer_id")) !== parseInt(props.router.location.query.customer_id))
      reportDate = null;
    const reportId = props.router.location.state?.reportId || props.router.location.query.timesheet_id;
    if(reportDate && props.timeSheetListLoaderState.fetched && 
      !props.timeSheetListLoaderState.fetching 
      ) {
        if(reportId && 
          reportId !== state.notifyTimeSheetId &&
          props.listOfTimeSheet.length > 0) {
          const timeSheetsToView = getTimeSheetsView(props.listOfTimeSheet);
          const selectedTimeSheet = timeSheetsToView.filter(ts => ts.id === parseInt(reportId))[0];
          // console.log("Selected timesheet", selectedTimeSheet)
          if(selectedTimeSheet?.id)
            return {
              showEditDialog: true,
              selectedTimeSheet,
              notifyTimeSheetDate: moment(reportDate).format("ddd, DD MMM YY"),
              notifyTimeSheetId: reportId
            }
        }
        else if(!reportId &&
          moment(reportDate).format("ddd, DD MMM YY") !== state.notifyTimeSheetDate)
        return {
          showAddDialog : true,
          notifyTimeSheetDate: moment(reportDate).format("ddd, DD MMM YY"),
          notifyTimeSheetId: null,
          selectedTimeSheet: ""
        }
    }
    return null;
  }

  onShowDeleteDialog = (indexToShow = 0) => {
    this.setState({
      showDeleteDialog: !this.state.showDeleteDialog,
      indexToShow
    });
  };

  onSort = sortBy => {
    const { updateSortAction, sort, searchContent } = this.props;
    if (sortBy === this.state.sortBy) {
      this.setState({ sortType: !this.state.sortType });
      updateSortAction({ sortType: !sort.sortType, sortBy: sort.sortBy });
    } else {
      this.setState({ sortBy, sortType: true });
      updateSortAction({ sortType: true, sortBy });
    }
    if(searchContent) {
      clearTimeout(this.highlightTimer);
      var context = [
        ...document.querySelectorAll(".td_title"),
        ...document.querySelectorAll(".td_des")
      ];
      var instance = new Mark(context);
      instance.unmark();
      this.highlightTimer = setTimeout(() => {
        var updatedContext = [
          ...document.querySelectorAll(".td_title"),
          ...document.querySelectorAll(".td_des")
        ];
        var updatedInstance = new Mark(updatedContext);
        updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
      }, 100);
    }
  };

  onShowEditDialog = timeSheet => {
    firebase.analytics().logEvent("GTD_Report_edit");
    this.setState({ showEditDialog: true, selectedTimeSheet: timeSheet });
  };

  onPopupCloseEditCallBack = () => {
    this.props.router.push({
      pathname: routesHelper.getReportPath(),
      state: undefined
    });
    this.setState({
      showEditDialog: false,
      notifyTimeSheetDate: null,
      notifyTimeSheetId: null
    });
  };

  handleClickCallback = () => {
    this.setState({ showAddDialog: true });
  };

  onConfirm = () => { };

  onPopupCloseCallBack = () => {
    this.props.router.push({
      pathname: routesHelper.getReportPath(),
      state: undefined
    });
    this.setState({
      showAddDialog: false,
      notifyTimeSheetDate: null
    });
  };

  onDeleteTimeSheet = timeSheet => {
    // let body = {};
    let body = {
      activity_id: timeSheet.activity_obj.id,
      data: { ...timeSheet.data },
      description: timeSheet.description.trim(),
      attachments: timeSheet.attachments,
      id: timeSheet.id,
      local_date_time: timeSheet.local_date_time,
      member: [timeSheet.user_id],
      tags: timeSheet.activity_type ? [timeSheet.activity_type] : timeSheet.activity_type,
      timeTaken: timeSheet.time_taken,
      title: timeSheet.title,
      user_id: timeSheet.user_id,
      is_archived: true
    };
    // body.data = { TIME: [] };
    // body.id = timeSheet.id;
    // body.entryStart = timeSheet.entryStart;
    // body.entryStop = timeSheet.entryStop;
    // body.user_id = parseInt(this.props.loggedInUserDetails.id);
    this.props.deleteTimeSheetAction({ body }, response => {
      if (response && !response.error && !response.stdErr) {
        firebase.analytics().logEvent("GTD_Report_delete");
        this.setState({ showDeleteDialog: false });
        // toast("Deleted Successfully");
        if(moment(timeSheet.date).isSame(new Date(), "day")) {
          let totalTimeForTheDay =
            this.props.totalTimeForTheDay - timeSheet.time_taken;
          this.props.updateTotalTimeAction(totalTimeForTheDay);
        }
        const { searchContent } = this.props;
        if(searchContent) {
          clearTimeout(this.highlightTimer);
          var context = [
            ...document.querySelectorAll(".td_title"),
            ...document.querySelectorAll(".td_des")
          ];
          var instance = new Mark(context);
          instance.unmark();
          this.highlightTimer = setTimeout(() => {
            var updatedContext = [
              ...document.querySelectorAll(".td_title"),
              ...document.querySelectorAll(".td_des")
            ];
            var updatedInstance = new Mark(updatedContext);
            updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
          }, 100);
        }
      }
    });
  };

  onTimeSheetScrollStart = event => {
    // if (event.scrollTop > 0) {
    //   this.setState({ showFooter: true });
    // console.log("Scroll Event start", event.scrollTop);
    // }
    // if (event.scrollTop === 0) this.setState({ showFooter: false });

    let scroll=document.getElementsByClassName('report-table-body');
   
    if (!this.state.showFooter && event.scrollTop !== event.scrollHeight - event.clientHeight) {
      if((this.state.top===0 || this.props.applyClick) && scroll && scroll[0]){
        if(this.props.applyClick){
         this.props.updateApplyClick(false);
        }
        this.setState({ showFooter: true, showAddButton: true,top:scroll[0].getBoundingClientRect().top });
      }else{
        this.setState({ showFooter: true, showAddButton: true });
      }
    }else if((this.state.top===0 || this.props.applyClick) && scroll && scroll[0]){
      this.setState({ top:scroll[0].getBoundingClientRect().top });
      if(this.props.applyClick){
        this.props.updateApplyClick(false);
      }
    }
  };

  onTimeSheetScrollStop = event => {
    let scroll=document.getElementsByClassName('report-table-body');
    let totalduraton=document.getElementsByClassName('total-durtaion-row');
    if (this.state.showFooter && (scroll[0] && totalduraton[0] && !((scroll[0].scrollHeight+this.state.top) <(totalduraton[0].getBoundingClientRect().top+30)))){
        this.setState({ showFooter: false, showAddButton: false });
    }
      
    // else this.setState({ showFooter: true })
  };

  onUpdateScroll = (event) => {
    // console.log("onUpdateScroll", e.scrollTop === e.scrollHeight - e.clientHeight);
    if (!this.state.showFooter && event.scrollTop !== event.scrollHeight - event.clientHeight) {
      this.setState({ showFooter: true, showAddButton: true });
    }
  };

  isImageDisplay = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    if (
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif"
    ) {
      return true;
    } else {
      return false;
    }
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  downloadExcel=()=> {
    
    const { sortBy, sortType } = this.props.sort;
    let reportToDownload = [];
    const timeSheetsView = getTimeSheetsView(this.props.listOfTimeSheet, sortBy, sortType);
    const timeSheetsToView = getTimeSheetsView(this.props.listOfTimeSheet);
    let projectName = localStorage.getItem('customer_display_name').replaceAll(' ', '_')+'_All_Projects';
    let excelprojectName = 'All Projects';
    let duration = moment(this.props.from).format('DD MMM YY') + " - " + moment(this.props.to).format('DD MMM YY')
    if (this.props.selectedProject !== 'All') {
      excelprojectName = this.props.selectedProject;
      projectName = this.props.selectedProject.replaceAll(' ', '_');
      if(this.props.selectedActivityType !=='All'){
        projectName=projectName+'_'+this.props.selectedActivityType;
      }
      if(this.props.selectedMember !=='All'){
        projectName=projectName+'_'+this.props.selectedMember.replaceAll(' ', '_');
      } else {
        projectName += '_All_Users';
      }
      projectName=projectName+'_'+moment(this.props.from).format('DDMMMYY') + "_" + moment(this.props.to).format('DDMMMYY') + '.xlsx';
    } else {
      if(this.props.selectedActivityType !=='All'){
        projectName=projectName+'_'+this.props.selectedActivityType;
      }
      if(this.props.selectedMember !=='All'){
        projectName=projectName+'_'+this.props.selectedMember.replaceAll(' ', '_');
      } else {
        projectName += '_All_Users';
      }
      projectName += '_' + moment(this.props.from).format('DDMMMYY') + "_" + moment(this.props.to).format('DDMMMYY') + '.xlsx';
    }
    if (
      timeSheetsView &&
      timeSheetsView.length > 0 &&
      typeof timeSheetsView[0] === "string"
    ) {
      let timeSheetByGroup = getSortedGroupView(
        timeSheetsToView,
        timeSheetsView,
        sortBy,
        sortType
      );
      reportToDownload = getReportToDownload(timeSheetByGroup, excelprojectName, duration);
    } else {
      reportToDownload = getReportToDownload(timeSheetsView, excelprojectName, duration);
    }
   
    getReport(reportToDownload, response => {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.file}`;
    const downloadLink = document.createElement("a");
     downloadLink.href = linkSource;
    downloadLink.download = projectName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
     document.body.removeChild(downloadLink);
      this.updateReportDropDown();
    })
  }

  updateReportDropDown = () => {
    this.setState((prevState) => ({
      showReportDownload: !prevState.showReportDownload
    }))
}

  updateReportDropDownWithPrev = (e) => {
    if (
      this.myRef &&
      !this.myRef.current.contains(e.target)
    ) {
      if (this.state.showReportDownload) {
        this.setState({showReportDownload:false});
      };
    }

  }

  onClickImg = (url) => {
    this.setState({ clickedImg: url });
    // document
    //   .querySelector('[role="dialog"]')
    //   .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    // document
    //   .querySelector('[role="dialog"]')
    //   .removeAttribute("id", "noscrolldialog");
  };



  render() {
    const {
      showEditDialog,
      showAddDialog,
      showAddButton,
      selectedTimeSheet,
      showDeleteDialog,
      indexToShow,
      // sortBy,
      // sortType,
      showFooter,
      showReportDownload,
      clickedImg
    } = this.state;
    const {
      listOfTimeSheet,
      getUserDetailsAction,
      getTimeSheetsAction,
      searchContent,
      loggedInUserDetails,
      fetchTimesheetAction,
      timeSheetListLoaderState,
      projectList,
      totalTimeForTheDay,
      updateTotalTimeAction,
      updateTimeSheetList,
      getSelectedWorkpsaceActivities,
      filters,
      from,
      to,
      sort
    } = this.props;
    const { sortBy, sortType } = sort;
    let timeSheetByGroup = null;
    // let timeSheetByDuration = null;
    let reportToDownload = [];
    const timeSheetsToView = getTimeSheetsView(listOfTimeSheet);
    const totalDuration = getTotalDuration(timeSheetsToView);
    const timeSheetsView = getTimeSheetsView(listOfTimeSheet, sortBy, sortType);
    let dialogPropsEdit = {
      className: "report-edit",
      showPopup: showEditDialog,
      dialogTitle: () => "Edit Report",
      onCloseCallBack: this.onPopupCloseEditCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true
    };
    let dialogProps = {
      className: "create_activity",
      showPopup: showAddDialog,
      dialogTitle: () => {
        // if (totalActivityCount === 0) {
        //   return "Add Your First Activity";
        // }
        return "New Activity";
      },
      onCloseCallBack: this.onPopupCloseCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true
    };
    let projectName = localStorage.getItem('customer_display_name').replaceAll(' ', '_')+'_All_Projects';
    let excelprojectName = 'All Projects';
    let duration = moment(this.props.from).format('DD MMM YY') + " - " + moment(this.props.to).format('DD MMM YY')
    if (this.props.selectedProject !== 'All') {
      excelprojectName = this.props.selectedProject;
      projectName = this.props.selectedProject.replaceAll(' ', '_');
      if(this.props.selectedActivityType !=='All'){
        projectName=projectName+'_'+this.props.selectedActivityType.replaceAll(' ', '_');
      }
      if(this.props.selectedMember !=='All'){
        projectName=projectName+'_'+this.props.selectedMember.replaceAll(' ', '_');
      } else {
        projectName += '_All_Users';
      }
      projectName=projectName+'_' + moment(this.props.from).format('DDMMMYY') + "_" + moment(this.props.to).format('DDMMMYY') + '.csv';
    } else {
      if(this.props.selectedActivityType !=='All'){
        projectName=projectName+'_'+this.props.selectedActivityType.replaceAll(' ', '_');
      }
      if(this.props.selectedMember !=='All'){
        projectName=projectName+'_'+this.props.selectedMember.replaceAll(' ', '_');
      } else {
        projectName += '_All_Users';
      }
      projectName += '_' + moment(this.props.from).format('DDMMMYY') + "_" + moment(this.props.to).format('DDMMMYY') + '.csv';
    }
    if (
      timeSheetsView &&
      timeSheetsView.length > 0 &&
      typeof timeSheetsView[0] === "string"
    ) {
      timeSheetByGroup = getSortedGroupView(
        timeSheetsToView,
        timeSheetsView,
        sortBy,
        sortType
      );
      reportToDownload = showReportDownload && getReportToDownloadCSV(timeSheetByGroup,excelprojectName,duration);
    } else {
      reportToDownload = showReportDownload && getReportToDownloadCSV(timeSheetsView,excelprojectName,duration);
    }
    
    return (
      <React.Fragment>
        {timeSheetsView.length > 0 && (
          <div className="searchbar-report">
           
            <OutsideClickHandler onOutsideClick={this.updateReportDropDownWithPrev}>

                <div className="table-export" ref={this.myRef} onClick={this.updateReportDropDown}>
                  <span onClick={() => {firebase.analytics().logEvent("GTD_Report_download")}}>
                  <img src={downloadReport} alt="download" />
                  Download
                </span>
              </div>
              { showReportDownload &&
                <div className="report-type-list">
                  <span className="select-format">Select Format</span>
                  <CSVLink data={reportToDownload} filename={projectName}>
                    <div className="format-download" onClick={this.updateReportDropDown}>
                      CSV
                  </div>
                  </CSVLink>
                  <div className="format-download" onClick={this.downloadExcel}>
                    XLSX
                </div>

                </div>
              
               }  
              
              </OutsideClickHandler>
            

            <div className="mdt-header">
              <table className="md-table report-table">
                <thead>
                  <tr>
                    <th
                      className="md-date"
                      onClick={() => this.onSort(reports.date)}
                    >
                      <span
                        className={`sort-arrow ${(sortBy === reports.date &&
                          sortType === false &&
                          "down") ||
                          (sortBy === reports.date &&
                            sortType === true &&
                            "up")}`}
                      >
                        Date
                      </span>
                    </th>
                    <th
                      className="md-time"
                      onClick={() => this.onSort(reports.duration)}
                    >
                      <span
                        className={`sort-arrow ${(sortBy === reports.duration &&
                          sortType === false &&
                          "down") ||
                          (sortBy === reports.duration &&
                            sortType === true &&
                            "up")}`}
                      >
                        Duration
                      </span>
                    </th>
                    <th
                      className="md-project"
                      onClick={() => this.onSort(reports.project)}
                    >
                      <span
                        className={`sort-arrow ${(sortBy === reports.project &&
                          sortType === false &&
                          "down") ||
                          (sortBy === reports.project &&
                            sortType === true &&
                            "up")}`}
                      >
                        Project
                      </span>
                    </th>
                    <th className="md-activity">
                      <span>Activity Title</span>
                    </th>
                    <th
                      className="md-type"
                      onClick={() => this.onSort(reports.activityType)}
                    >
                      <span
                        className={`sort-arrow ${(sortBy ===
                          reports.activityType &&
                          sortType === false &&
                          "down") ||
                          (sortBy === reports.activityType &&
                            sortType === true &&
                            "up")}`}
                      >
                        Activity Type
                      </span>
                    </th>
                    <th
                      className="md-mem"
                      onClick={() => this.onSort(reports.member)}
                    >
                      <span
                        className={`sort-arrow ${(sortBy === reports.member &&
                          sortType === false &&
                          "down") ||
                          (sortBy === reports.member &&
                            sortType === true &&
                            "up")}`}
                      >
                        Member
                      </span>
                    </th>
                    <th className="md-des">
                      <span>Description</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        )}
        {timeSheetsView.length > 0 && (
          <Scrollbar
            id="report-div"
            className={`report-scroll ${showFooter ? "with-footer" : ""}`}
            onScrollStart={this.onTimeSheetScrollStart}
            scrollDetectionThreshold={3000}
            onScroll={this.onUpdateScroll}
            onScrollStop={this.onTimeSheetScrollStop}
         
           
          >
            <div
              id="report-div-inner"
              className="row-fluid-report report-table-body-area"
              // ng-class="{'with-footer':reportScope.footerVisibility,'when-single-row':reportScope.timesheet.length === 1}"
            >
              <div className="custom-table-row">
                <div className="mdtable-header">
                  {timeSheetsView &&
                    timeSheetsView.length > 0 &&
                    typeof timeSheetsView[0] === "object" && (
                      <table id="timesheet" className="report-table-body">
                        <tbody>
                          {timeSheetsView.map((timeSheet, index) => (
                            <Tooltip title={`Added by ${timeSheet.created_by_first_name}`} >
                            <tr className={`custom_row ${timeSheet.data?.timer_stopped_by_app ? "stopped-by-app" : ""}`} key={index}>
                              <td className="custom td_date">
                                {moment(timeSheet.date_view).format(
                                  "ddd, DD MMM YY"
                                )}
                                {/* {timeSheet.date_view} */}
                              </td>
                              <td className="custom td_dur">
                                {timeSheet.time_taken_view}
                              </td>
                              <td className="custom td_project">
                                {timeSheet.project_name}
                              </td>
                              <td className="custom td_title">
                                {timeSheet.title}
                              </td>
                              <td className="custom td_tags">
                                {timeSheet.activity_type}
                              </td>
                              <td className="custom td_name">
                                {timeSheet.user_name}
                              </td>
                              <td className="custom td_des">
                              <div>
                                    <div>{timeSheet.description}</div>
                                    <div className="comm-att-holder">
                  {timeSheet?.attachments?.length > 0 && 
                    <React.Fragment>
                      {timeSheet.attachments.map((attachment, index) => {
                        return <div
                        className="nw-att-row-container comm-att"
                        key={index}
                        id={`nw-att-row-container-${attachment.id}`}
                      >
                        <div  className="attachment-report">
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImageDisplay(attachment.url)
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {this.isImageDisplay(attachment.url) && (
                              <img
                                src={decodeURI(attachment.url)}
                                alt="att-img"
                                onClick={() => this.onClickImg(decodeURI(attachment.url))}
                              />
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") < 0 &&
                              attachment.url.indexOf("www.dropbox.com") < 0 &&
                              attachment.url.indexOf("https://1drv.ms/") < 0 && (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.url.substr(
                                      attachment.url.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
              
                            <div className="nw-att-details-name comm-att truncate-text attachment-report-width">
                            <Tooltip title={attachment.title}>
                              <a
                                style={this.isImageDisplay(attachment.url) ? { cursor: "pointer" } : {}}
                                onClick={() => this.isImageDisplay(attachment.url) && this.onClickImg(decodeURI(attachment.url))}
                              >
                                {attachment.title}
                              </a>
                            </Tooltip>  
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              href={decodeURI(attachment.url)}
                              download={attachment.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
                                    </div>
                                <div className="td-edit">
                                  <img
                                    src={editReport}
                                    className="edit-img"
                                    alt="more_vert"
                                    onClick={() =>
                                      this.onShowEditDialog(timeSheet)
                                    }
                                    // ng-click="reportScope.editTimesheet(t, $event)"
                                  />
                                </div>
                                <div className="td-delete">
                                  <img
                                    src={deleteReport}
                                    className="edit-img"
                                    alt="more_vert"
                                    onClick={() =>
                                      this.onShowDeleteDialog(index)
                                    }
                                  />
                                </div>
                                {showDeleteDialog && index === indexToShow && (
                                  <OutsideClickHandler
                                    onOutsideClick={this.onShowDeleteDialog}
                                  >
                                    <div className="delconfirm duration-delconfirm">
                                      <div className="pop-over-header js-pop-over-header">
                                        <span className="pop-over-header-title">
                                          Delete Report
                                        </span>
                                      </div>
                                      <div className="del-body">
                                        <p>
                                          Are you sure you want to delete
                                          <br />
                                          this entry?
                                        </p>
                                        <div className="btn-wrap">
                                          <button
                                            className="can-del"
                                            onClick={() =>
                                              this.onShowDeleteDialog(index)
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="bt-del"
                                            onClick={() =>
                                              this.onDeleteTimeSheet(timeSheet)
                                            }
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </OutsideClickHandler>
                                )}
                              </td>
                            </tr>
                            </Tooltip>
                          ))}
                        </tbody>
                      </table>
                    )}
                  {timeSheetsView &&
                    timeSheetsView.length > 0 &&
                    typeof timeSheetsView[0] === "string" &&
                    timeSheetByGroup && (
                      <table id="timesheet" className="report-table-body">
                        <tbody>
                          {timeSheetByGroup.map((timeSheet, index) => {
                            if (!timeSheet.hasOwnProperty("total")) {
                              return (
                                <Tooltip title={`Added by ${timeSheet.created_by_first_name}`} >
                                <tr className={`custom_row ${timeSheet.data?.timer_stopped_by_app ? "stopped-by-app" : ""}`} key={index}>
                                  <td className="custom td_date">
                                    {moment(timeSheet.date_view).format(
                                      "ddd, DD MMM YY"
                                    )}
                                    {/* {timeSheet.date_view} */}
                                  </td>
                                  <td className="custom td_dur">
                                    {timeSheet.time_taken_view}
                                  </td>
                                  <td className="custom td_project">
                                    {timeSheet.project_name}
                                  </td>
                                  <td className="custom td_title">
                                    {timeSheet.title}
                                  </td>
                                  <td className="custom td_tags">
                                    {timeSheet.activity_type}
                                  </td>
                                  <td className="custom td_name">
                                    {timeSheet.user_name}
                                  </td>
                                  <td className="custom td_des">
                                    <div>
                                    <div>{timeSheet.description}</div>
                                    <div className="comm-att-holder">
                  {timeSheet?.attachments?.length > 0 && 
                    <React.Fragment>
                      {timeSheet.attachments.map((attachment, index) => {
                        return <div
                        className="nw-att-row-container comm-att"
                        key={index}
                        id={`nw-att-row-container-${attachment.id}`}
                      >
                        <div  className="attachment-report">
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImageDisplay(attachment.url)
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {this.isImageDisplay(attachment.url) && (
                              <img
                                src={decodeURI(attachment.url)}
                                alt="att-img"
                                onClick={() => this.onClickImg(decodeURI(attachment.url))}
                              />
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") < 0 &&
                              attachment.url.indexOf("www.dropbox.com") < 0 &&
                              attachment.url.indexOf("https://1drv.ms/") < 0 && (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.url.substr(
                                      attachment.url.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
              
                            <div className="nw-att-details-name comm-att truncate-text attachment-report-width">
                            <Tooltip title={attachment.title}>
                              <a
                                style={this.isImageDisplay(attachment.url) ? { cursor: "pointer" } : {}}
                                onClick={() => this.isImageDisplay(attachment.url) && this.onClickImg(decodeURI(attachment.url))}
                              >
                                {attachment.title}
                              </a>
                              </Tooltip>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              href={decodeURI(attachment.url)}
                              download={attachment.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                        </div>
                       
                        {/*  */}
                       
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
                                    </div>
                                    <div className="td-edit">
                                      <img
                                        src={editReport}
                                        className="edit-img"
                                        alt="more_vert"
                                        onClick={() =>
                                          this.onShowEditDialog(timeSheet)
                                        }
                                        // ng-click="reportScope.editTimesheet(t, $event)"
                                      />
                                    </div>
                                    <div className="td-delete">
                                      <img
                                        src={deleteReport}
                                        className="edit-img"
                                        alt="more_vert"
                                        onClick={() =>
                                          this.onShowDeleteDialog(index)
                                        }
                                      />
                                    </div>
                                    {showDeleteDialog && index === indexToShow && (
                                      <OutsideClickHandler
                                        onOutsideClick={this.onShowDeleteDialog}
                                      >
                                        <div className="delconfirm">
                                          <div className="pop-over-header js-pop-over-header">
                                            <span className="pop-over-header-title">
                                              Delete Report
                                            </span>
                                          </div>
                                          <div className="del-body">
                                            <p>
                                              Are you sure you want to delete
                                              <br />
                                              this entry?
                                            </p>
                                            <div className="btn-wrap">
                                              <button
                                                className="can-del"
                                                onClick={() =>
                                                  this.onShowDeleteDialog(index)
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="bt-del"
                                                onClick={() =>
                                                  this.onDeleteTimeSheet(
                                                    timeSheet
                                                  )
                                                }
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </OutsideClickHandler>
                                    )}
                                  </td>
                                </tr>
                                </Tooltip>
                              );
                            } else if (timeSheet.hasOwnProperty("total")) {
                              return (
                                <tr className="timesheet-footer" key={index}>
                                  <td className="total"></td>
                                  <td className="td-value">
                                    {timeSheet.total}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
            </div>
            {showEditDialog && (
              <DialogWrapper dialogProps={dialogPropsEdit}>
                <EditReport
                  timeSheet={selectedTimeSheet}
                  searchContent={searchContent}
                  projectList={projectList}
                  onPopupCloseEditCallBack={this.onPopupCloseEditCallBack}
                  totalTimeForTheDay={totalTimeForTheDay}
                  updateTimeSheetList={updateTimeSheetList}
                  updateTotalTimeAction={updateTotalTimeAction}
                  getSelectedWorkpsaceActivities={
                    getSelectedWorkpsaceActivities
                  }
                  listOfTimeSheet={listOfTimeSheet}
                  from={from}
                  to={to}
                />
              </DialogWrapper>
            )}
          </Scrollbar>
        )}
        {timeSheetsView.length > 0 && (
          <div
            className="total-durtaion-row"
            // ng-show="reportScope.footerVisibility"
          >
            <div className="td-value total">Total Duration:</div>
            <div className="td-value total">{totalDuration}</div>
          </div>
        )}
        {showAddDialog && (
          <DialogWrapper dialogProps={dialogProps}>
            <ActivityForm
              fromReport={true}
              defaultDate={this.state.notifyTimeSheetDate}
              searchContent={searchContent}
              reportFilters={filters}
              getUserDetailsAction={getUserDetailsAction}
              logedinUserDetails={loggedInUserDetails}
              fetchTimesheetAction={fetchTimesheetAction}
              getTimeSheetsAction={getTimeSheetsAction}
              {...dialogProps}
            />
          </DialogWrapper>
        )}
        {timeSheetListLoaderState.fetched &&
          !timeSheetListLoaderState.error &&
          timeSheetsView.length === 0 && (
            <div className="timesheet-search-message report-message">
              No results found
            </div>
          )}
        {showAddButton && (
          <AddFeatures reportpage={true} handleClickCallback={this.handleClickCallback} />
        )}
        {clickedImg && (
            <div className="attachmentpopviews">
              <img src={clickedImg} className="attachmentimage" alt="att-img" />
              <img
                className="closeattachmentpopview"
                src={closeIcon}
                alt="close"
                onClick={this.onClosePreview}
              />
            </div>
          )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getReport: (reportdata, callback) => {
      dispatch(getReportAction(reportdata, callback));
    }
  }
}

export default connect(null, mapDispatchToProps)(TimeSheetTable);
