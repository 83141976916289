import { activities } from "../../constants/activitiescontants";
import { workspaceConstant } from "../../constants/workspaceconstants";
import {
  changeActivityWorkFlow,
  startTimesheetEntry,
  stopTimesheetEntry,
  createTimesheetEntry,
  fetchTimesheet,
  createActivity,
  updateActivity,
  MarkActivityRead,
  getActivitiesForDropdown,
  addTimesheetEntry,
  getActivitiesByProject,
  updateActivityFromWorkload
} from "../../services/activityapis";
import { commonContants } from "../../helpers/commonconstants";
import { archiveOrUnArchiveActivity, getActivity } from "../../services/workspaceapi";
import { addAttachmentFiles } from "../../services/attachmentapi";
import { getProfileDetails } from "../../helpers/generichelper";

function updateWorkFlowPrioritize({ id, workflow, data }) {
  return {
    type: activities.changeWorkFLowPriority,
    payload: {
      id,
      workflow,
      data: data
    }
  };
}

function updateWorkFlow({ id, workflow, data, type }) {
  return {
    type: activities.changeWorkFLow,
    payload: {
      id,
      workflow,
      data: data,
      type
    }
  };
}

function updateActivityTimesheet({ id, timesheet }) {
  return {
    type: activities.updateTimesheet,
    payload: {
      id,
      timesheet
    }
  };
}

function startActivityTimesheet({ id, timesheet }) {
  return {
    type: activities.createTimesheet,
    payload: {
      id,
      timesheet
    }
  };
}

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export function updateTotalTimeAction(totalTime) {
  return {
    type: commonContants.updateTimerData,
    payload: {
      totalTime
    }
  };
}

function addPrioritizedActivityArray(activity) {
  return {
    type: activities.add.activity.priority,
    payload: {
      activity: activity
    }
  };
}

function addActivityArray(activity) {
  return {
    type: activities.add.activity.notPriority,
    payload: {
      activity: activity
    }
  };
}

function updatePrioritizedActivityArray(activity) {
  return {
    type: activities.edit.activity.priority,
    payload: {
      activity: activity
    }
  };
}

function updateActivityArray(activity) {
  return {
    type: activities.edit.activity.notPriority,
    payload: {
      activity: activity
    }
  };
}

function updateKanbanActivityArray(activity) {
  return {
    type: activities.edit.activity.kanbanActivity,
    payload: {
      activity: activity
    }
  };
}

function updateArchievedActivityArray(activity) {
  return {
    type: activities.updateUnarchiveActivity,
    payload: {
      activity: activity
    }
  };
}

function unarchiveActivity(activity) {
  return {
    type: activities.updateUnarchive,
    payload: {
      activity: activity
    }
  };
}
const updateAllActivity = id => {
  return {
    type: activities.UPDATEALLACTIVITIES,
    payload: {
      activity_id: id
    }
  };
};

const getActivitiesByProjectId = response => {
  return {
    type: activities.getActivitiesByProject,
    payload: {
      activities: response.filter(activity => activity.user_id.length !== 0)
    }
  };
};

export const changeWorkFlowAction = (
  { id, workflow, is_Priority, customer_auth_token },
  callback
) => dispatch => {
  changeActivityWorkFlow({ id, workflow, customer_auth_token }, response => {
    if (response && response.data && !response.stdErr) {
      if (is_Priority) {
        dispatch(
          updateWorkFlowPrioritize({ id, workflow, data: response.data })
        );
      }
      dispatch(updateWorkFlow({ id, workflow, data: response.data }));
    }
    callback && callback(response);
  });
};
export const changeWorkFlowFromKanbanAction = (
  { id, workflow, is_Priority, customer_auth_token },
  callback
) => dispatch => {
  changeActivityWorkFlow({ id, workflow, customer_auth_token }, response => {
    if (response && response.data && !response.stdErr) {
      if (is_Priority) {
        dispatch(
          updateWorkFlowPrioritize({ id, workflow, data: response.data })
        );
      }
      dispatch(updateWorkFlow({ id, workflow, data: response.data, type: "KANBAN" }));
    }
    callback && callback(response);
  });
};

export const startTimerAction = (
  {
    activity_id,
    data,
    action = "START",
    date,
    is_archived = false,
    user_id,
    customer_auth_token
  },
  callback
) => dispatch => {
  startTimesheetEntry(
    {
      activity_id,
      data,
      action,
      date,
      is_archived,
      user_id,
      customer_auth_token
    },
    response => {
      if (response && response.id) {
        dispatch(
          startActivityTimesheet({ id: activity_id, timesheet: response })
        );
      }

      callback && callback(response);
    }
  );
};

export const stopTimerAction = (
  {
    activity_id,
    data,
    action,
    time_taken,
    tags,
    description,
    id,
    date,
    user_id,
    customer_auth_token
  },
  formData,
  callback
) => dispatch => {
  stopTimesheetEntry(
    {
      activity_id,
      data,
      action,
      time_taken,
      tags,
      description,
      id,
      date,
      user_id,
      customer_auth_token
    },
    response => {
      if (response && response.id) {
        if(formData) {
          formData.set("entity_id", response.id);
          addAttachmentFiles(formData, res => {
            if(res && !res.stdErr && res?.length > 0) {
              dispatch(
                updateActivityTimesheet({
                  id: activity_id,
                  timesheet: { ...response, is_archived: false, user_id, tags, description, attachments: res }
                })
              );
            } else {
              dispatch(
                updateActivityTimesheet({
                  id: activity_id,
                  timesheet: { ...response, is_archived: false, user_id, tags, description }
                })
              );
            }
            callback && callback(response, res);
          })
        } else
          dispatch(
            updateActivityTimesheet({
              id: activity_id,
              timesheet: { ...response, is_archived: false, user_id, tags, description }
            })
          );
      }

      callback && !formData && callback(response);
    }
  );
};

export const createTimerAction = (
  {
    tags,
    attachments,
    activity_id,
    data,
    action,
    time_taken,
    description,
    date,
    is_archived,
    user_id,
    customer_auth_token
  },
  formData,
  callback
) => dispatch => {
  createTimesheetEntry(
    {
      tags,
      attachments,
      activity_id,
      data,
      action,
      time_taken,
      description,
      date,
      is_archived,
      user_id,
      customer_auth_token
    },
    response => {
      if (response && response.id) {
        if(formData) {
          formData.set("entity_id", response.id);
          addAttachmentFiles(formData, res => {
            if(res && !res.stdErr && res?.length > 0) {
              dispatch(
                startActivityTimesheet({
                  id: activity_id,
                  timesheet: { ...response, is_archived: false, user_id, description, attachments: res }
                })
              );
            } else {
              dispatch(
                startActivityTimesheet({
                  id: activity_id,
                  timesheet: { ...response, is_archived: false, user_id, description }
                })
              );
            }
            callback && callback(response, res);
          })
        } else
          dispatch(
            startActivityTimesheet({
              id: activity_id,
              timesheet: { ...response, is_archived: false, user_id, description }
            })
          );
      }

      callback && !formData && callback(response);
    }
  );
};

export const fetchTimesheetAction = (
  { from_date, to_date, user_id },
  callback
) => dispatch => {
  fetchTimesheet({ from_date, to_date, user_id }, response => {
    if (response.length > 0) {
      calculateTotalTime(response).then(totalTime => {
        dispatch(updateTotalTimeAction(totalTime));
      });
    } else dispatch(updateTotalTimeAction(0));
    callback && callback(response);
  });
};


export const createUpcomingActivityAction = (body, callback) => dispatch => {
  createActivity(body, response => {
      if (response?.id && response.user_id.includes(body.created_by)) {
        if (body.data.priority.length > 0) {
          dispatch(addPrioritizedActivityArray(response));
        } else {
          dispatch(addActivityArray(response));
        }
      }
      callback && callback(response);
    }
  );
};


export const createCompletedActivityAction = (body, formData, callback) => _dispatch => {
    createActivity(body, response => {
      if(response && response.id) {
        if(formData) {
          formData.set("entity_id", response.id);
          addAttachmentFiles(formData, res => {
            callback && callback(response, res);
          })
        }
      }
        callback && !formData && callback(response);
      }
    );
  };

export const updateActivityAction = (
  {
    id,
    due_on,
    start_on,
    duration,
    hours,
    minutes,
    project_id,
    tags,
    title,
    description,
    users_duration,
    users_not_in_workspace,
    continuous_activity,
    user_id,
    project,
    version_id,
    data,
    performed_by,
    is_priority,
    is_locked
  },
  callback
) => dispatch => {
  updateActivity(
    {
      id,
      due_on,
      start_on,
      duration,
      hours,
      minutes,
      project_id,
      tags,
      title,
      description,
      users_duration,
      continuous_activity,
      user_id,
      project,
      version_id,
      data,
      performed_by,
      is_locked
    },
    response => {
      if(response && !response.error) {
        if (is_priority) {
          dispatch(
            updatePrioritizedActivityArray({
              id,
              due_on,
              start_on,
              duration,
              hours,
              minutes,
              project_id,
              tags,
              title,
              description,
              users_duration,
              users_not_in_workspace,
              continuous_activity,
              user_id,
              version_id,
              project,
              data,
              is_locked
            })
          );
        }
        dispatch(
          updateActivityArray({
            id,
            due_on,
            start_on,
            duration,
            hours,
            minutes,
            project_id,
            tags,
            title,
            description,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            user_id,
            version_id,
            project,
            data,
            is_locked
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const MarkActivityReadAction = ({ body, is_priority}, callback) => (dispatch) => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.archiveOrUnArchiveLoader,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  MarkActivityRead(body, response => {
    if(response) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      let activityToUpdate = { id: body.activity_ids[0] };
      if(body.type === "comment")
        activityToUpdate.is_new_comment = false;
      if(body.type === "checklist")
        activityToUpdate.is_new_checklist = false;
      if(is_priority)
        dispatch(updatePrioritizedActivityArray({ ...activityToUpdate }));
      dispatch(updateActivityArray({ ...activityToUpdate }));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while reading. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  })
}

export const updateKanbanActivityAction = (
  {
    id,
    due_on,
    start_on,
    duration,
    hours,
    minutes,
    project_id,
    description,
    users_duration,
    users_not_in_workspace,
    continuous_activity,
    tags,
    title,
    user_id,
    project,
    version_id,
    data,
    is_locked
  },
  callback
) => dispatch => {
  updateActivity(
    {
      id,
      due_on,
      start_on,
      duration,
      hours,
      minutes,
      project_id,
      description,
      users_duration,
      continuous_activity,
      tags,
      title,
      user_id,
      project,
      version_id,
      data,
      is_locked
    },
    response => {
      if(response && !response.error)
        dispatch(
          updateKanbanActivityArray({
            id,
            due_on,
            start_on,
            duration,
            hours,
            minutes,
            project_id,
            description,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            tags,
            title,
            user_id,
            version_id,
            project,
            data
          })
        );
      callback && callback(response);
    }
  );
};

export const MarkKanbanActivityReadAction = ({ body, _is_priority}, callback) => (dispatch) => {
  MarkActivityRead(body, response => {
    if(response) {
      let activityToUpdate = { id: body.activity_ids[0] };
      if(body.type === "comment")
        activityToUpdate.is_new_comment = false;
      if(body.type === "checklist")
        activityToUpdate.is_new_checklist = false;
      dispatch(updateKanbanActivityArray({ ...activityToUpdate }));
    }
    callback && callback(response);
  })
}

export const updateActivityWithWorkflowAction = (
  { id, version_id, workflow },
  callback
) => dispatch => {
  updateActivityFromWorkload(
    {
      id,
      version_id,
      workflow
    },
    response => {
      if(response && response.id) {
        dispatch(updateKanbanActivityArray({ ...response, workflow }))
      }
      callback && callback(response);
    }
  );
};

export const unarchiveActivitiesAction = (
  { auth_token, archiveData, _user_id },
  callback
) => dispatch => {
  archiveOrUnArchiveActivity({ auth_token, archiveData }, response => {
    if (response && !response.stdError) {
      dispatch(unarchiveActivity({ ...response }));
    }
    callback && callback(response);
  });
};

export const updateArchiveActivitiesWorkFlowAction = (
  { id, workflow, customer_auth_token },
  callback
) => dispatch => {
  changeActivityWorkFlow({ id, workflow, customer_auth_token }, response => {
    if (response && !response.stdError) {
      response.workflow = workflow;
      dispatch(updateWorkFlow({ ...response, type: "UNARCHIVE" }));
    }
    callback && callback(response);
  });
};

export const updateArchiveActivityAction = (
  {
    id,
    due_on,
    start_on,
    duration,
    hours,
    minutes,
    project_id,
    tags,
    title,
    description,
    user_id,
    project,
    version_id,
    data,
    is_archived = true,
    users_duration,
    users_not_in_workspace,
    continuous_activity,
    is_locked
  },
  callback
) => dispatch => {
  updateActivity(
    {
      id,
      due_on,
      start_on,
      duration,
      hours,
      minutes,
      project_id,
      tags,
      title,
      description,
      user_id,
      project,
      version_id,
      data,
      is_archived,
      users_duration,
      continuous_activity,
      is_locked
    },
    response => {
      if(response && !response.error)
        dispatch(
          updateArchievedActivityArray({
            id,
            due_on,
            start_on,
            duration,
            hours,
            minutes,
            project_id,
            tags,
            title,
            description,
            user_id,
            version_id,
            project,
            data,
            users_duration,
            users_not_in_workspace,
            continuous_activity,
            is_locked
          })
        );
      callback && callback(response);
    }
  );
};

export const MarkArchiveActivityReadAction = ({ body }, callback) => (dispatch) => {
  dispatch(
    updateLoaderState({
      action: workspaceConstant.archiveOrUnArchiveLoader,
      state: {
        fetching: true,
        fetched: false,
        error: {
          bool: false,
          value: ""
        }
      }
    })
  );
  MarkActivityRead(body, response => {
    if(response) {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: false,
              value: ""
            }
          }
        })
      );
      let activityToUpdate = { id: body.activity_ids[0] };
      if(body.type === "comment")
        activityToUpdate.is_new_comment = false;
      if(body.type === "checklist")
        activityToUpdate.is_new_checklist = false;
      dispatch(updateArchievedActivityArray({ ...activityToUpdate }));
    } else {
      dispatch(
        updateLoaderState({
          action: workspaceConstant.archiveOrUnArchiveLoader,
          state: {
            fetching: false,
            fetched: true,
            error: {
              bool: true,
              value: response.stdErr
                ? response.stdErr
                : "Error occured while reading. Please try again."
            }
          }
        })
      );
    }
    callback && callback(response);
  })
}

export const getActivitiesByProjectAction = (
  { activity_type, is_active, project_id, is_archived, user_id },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: activities.activitiesByProjectLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getActivitiesByProject(
    {
      activity_type,
      is_active,
      project_id,
      is_archived,
      user_id
    },
    response => {
      if (response && !response.error && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: activities.activitiesByProjectLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: null
            }
          })
        );
        dispatch(getActivitiesByProjectId(response));
      } else {
        dispatch(
          updateLoaderState({
            action: activities.activitiesByProjectLoaderState,
            state: {
              fetching: false,
              fetched: true,
              error: response.stdErr
            }
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const clearActivitiesByProjectAction = () => dispatch => {
  dispatch(getActivitiesByProjectId([]));
}

async function calculateTotalTime(timesheetsForDay) {
  return timesheetsForDay.reduce((totalTime, element) => {
    return (totalTime + element.time_taken);
  }, 0);
}

export const updateAllActivityAction=(id) =>dispatch=>{
        dispatch(updateAllActivity(id));
}

export const addActivityToReducerAction = activity => dispatch => {
  dispatch(addActivityArray(activity));
}

export const removeActivityFromReducerAction = activity => dispatch => {
  dispatch({ type: activities.remove.activity, payload: activity });
}

export const getActivitiesForDropdownAction = ({ userId, auth_token }, callback) => _dispatch => {
  getActivitiesForDropdown({ userId, auth_token }, response => {
    callback && callback(response);
  })
}

export const addTimerAction = (
  {
    tags,
    activity_id,
    action,
    time_taken,
    description,
    date,
    is_archived,
    user_id,
    completed_durations,
    customer_auth_token
  },
  formData,
  callback
) => dispatch => {
  addTimesheetEntry(
    {
      tags,
      activity_id,
      action,
      time_taken,
      description,
      date,
      is_archived,
      user_id,
      completed_durations,
      customer_auth_token
    },
    response => {
      if (response?.id) {
        if(formData) {
          formData.set("entity_id", response.id.join(","));
          formData.set("entity_name", "completed_timesheet");
          addAttachmentFiles(formData, res => {
            getActivity({ id: activity_id }, actRes => {
              if(actRes?.length > 0) {
                dispatch(updatePrioritizedActivityArray(actRes[0]));
                dispatch(updateActivityArray(actRes[0]));
              }
            });
            callback && callback(response, res);
          })
        } else {
          getActivity({ id: activity_id }, actRes => {
            if(actRes?.length > 0) {
              dispatch(updatePrioritizedActivityArray(actRes[0]));
              dispatch(updateActivityArray(actRes[0]));
            }
          });
        }
      }

      callback && !formData && callback(response);
    }
  );
};

export const getActivityByIdAction = ({ id, user_id }, callback) => dispatch => {
  getActivity({ id, user_id }, actRes => {
    if(actRes?.length > 0) {
      dispatch(updatePrioritizedActivityArray(actRes[0]));
      dispatch(updateActivityArray(actRes[0]));
    }
    callback && callback(actRes);
  });
}

export const getActivityByIdForNotificationAction = ({ id, user_id }, callback) => dispatch => {
  getActivity({ id, user_id }, actRes => {
    if(actRes?.length > 0) {
      const isPriority = actRes[0].data.priority.filter(user => user.user_id === parseInt(getProfileDetails().id));
      if(isPriority.length > 0)
        dispatch(addPrioritizedActivityArray(actRes[0]));
      else
        dispatch(addActivityArray(actRes[0]));
    }
    callback && callback(actRes);
  });
}