
import moment from "moment";
import React, { useRef, useState, memo } from "react";
import Avatar from "react-avatar";
import OutsideClickHandler from "react-outside-click-handler";
import { convertTimeToString } from "../../helpers/generichelper";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import onTimeIcon from "../../styles/img/svg/duration_act.svg";
import ActivityForm from "../activity/addactivity";
import DialogWrapper from "../common/dialog";
import firebase from "../../firebase";

function ActivityTile({
  activity,
  setIsPopupEnable,
  loggedInUser,
  unarchiveActivitiesAction,
  updateArchiveActivitiesWorkFlowAction,
}) {
  const [showWorkFlowPopup, setShowWorkFlowPopup] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isPopupEnableEdit, setIsPopupEnableEdit] = useState(false);
  const showWorkFlowPopupNode = useRef(null);
  const showOptionsNode = useRef(null);

  let dialogPropsEdit = {
    className: "create_activity",
    showPopup: isPopupEnableEdit,
    dialogTitle: () => "Edit Actvity",
    onCloseCallBack: () => setIsPopupEnableEdit(false),
    onConfirmCallBack: () => { /* TODO document why this method 'onConfirmCallBack' is empty */ },
    shouldButtonEnable: true,
  };

  const workflows = activity.project && activity.project.workflow;

  const arrayOfObj = Object.entries(workflows).map(e => ({ [e[0]]: e[1] }));

  const workflowOptions = arrayOfObj
    .filter(workflow => {
      const workflowId = Object.getOwnPropertyNames(workflow)[0];
      return !workflow[workflowId].is_archived;
    })
    .map((workflow) => {
      const workflowId = Object.getOwnPropertyNames(workflow)[0];
      return (
        <li
          className={
            activity.workflow
              ? activity.workflow.name === workflow[workflowId].name
                ? "active"
                : ""
              : workflow[workflowId].name === "Backlog"
              ? "active"
              : ""
          }
          key={workflow[workflowId].name}
          onClick={() => {
            changeWorkFlow(
              workflow[workflowId],
              workflowId,
              activity,
              updateArchiveActivitiesWorkFlowAction
            );
          }}
        >
          {workflow[workflowId].name}
        </li>
      );
    });

  

  const changeWorkFlow = (
    workflow,
    index,
    activity,
    updateArchiveActivitiesWorkFlowAction
  ) => {
    const workflowToSend = {
      id: index,
      name: workflow.name,
      moved_from: activity.workflow.name,
    };
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    updateArchiveActivitiesWorkFlowAction(
      {
        id: activity.id,
        workflow: workflowToSend,
        customer_auth_token,
      });
  };

  const unarchiveActivity = () => {
    const archiveData = {
      id: activity.id,
      data: {
        ...activity.data,
        lastModifiedBy: parseInt(loggedInUser.id),
        archivedUsers: activity.data.archivedUsers.filter(
          user => user !== parseInt(loggedInUser.id)
        ),
        priority: activity.data.priority
          ? activity.data.priority.filter(
              user => user.user_id !== parseInt(loggedInUser.id)
            )
          : [],
      },
      version_id: activity.version_id,
      is_active: true,
    };
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    unarchiveActivitiesAction(
      {
        auth_token: customer_auth_token,
        archiveData,
        user_id: loggedInUser.id,
      },
      _response => {
        firebase.analytics().logEvent("GTD_Archive_Activity_Unarchived");
        setShowOptions(false);
      }
    );
  };

  const logged_in_user_duration = activity.users_duration.filter(
    user => user.user_id === parseInt(loggedInUser.id)
  )[0];

  return (
    <div className="comm-act-card archive-center">
      <div className="comm-act-header">
        <div className="comm-header-wrap">
          <div className="comm-client-logo" aria-label="">
            {!activity.project.img && (
              <Avatar
                // color={Avatar.getRandomColor("sitebase", [
                //   "#ff9292",
                //   "#7c9af1"
                // ])}
                style={{ width: "100%", height: "100%" }}
                name={activity.project.name.split("").join(" ")}
                maxInitials={1}
              ></Avatar>
            )}

            {activity.project.img && (
              <img src={activity.project.img} alt="project-img"></img>
            )}
          </div>
          <div ref={showWorkFlowPopupNode}>
            <div
              className={
                showWorkFlowPopup
                  ? "workflow-change-ddown-holder clicked"
                  : "workflow-change-ddown-holder"
              }
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowWorkFlowPopup(!showWorkFlowPopup);
              }}
            >
              <div className="workflow-change-ddown-text">
                {(activity.workflow && activity.workflow.name) || "Backlog"}{" "}
              </div>
              {showWorkFlowPopup && (
                <OutsideClickHandler
                  onOutsideClick={e => {
                    if (
                      showWorkFlowPopupNode &&
                      !showWorkFlowPopupNode.current.contains(e.target)
                    )
                      setShowWorkFlowPopup(false);
                  }}
                >
                  <div className="workflow-change-ddown">
                    <ul className="workflow-change-ddown-inner">
                      {workflowOptions}
                    </ul>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
        </div>
        <div className="comm-wrap-star-options">
          <div className="menu-options options-activity">
            <div className="archive-edit-wrap">
              <div
                className="comm-option-icon"
                tabIndex="0"
                ref={showOptionsNode}
              >
                <div
                  className="comm-dot-holder"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    
                    setShowOptions(!showOptions);
                  }}
                >
                  <img src={dotIcon} alt="dot-icon" />
                </div>
                {showOptions && (
                  <OutsideClickHandler
                    onOutsideClick={e => {
                      if (
                        showOptionsNode &&
                        !showOptionsNode.current.contains(e.target)
                      )
                        setShowOptions(false);
                    }}
                  >
                    <div className="comm-options-block">
                      <div
                        className="comm-option-list"
                        role="button"
                        tabIndex="0"
                        onClick={unarchiveActivity}
                      >
                        Unarchive
                      </div>
                      <div
                        className="comm-option-list"
                        role="button"
                        tabIndex="0"
                        onClick={setIsPopupEnableEdit}
                      >
                        Edit
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="comm-act-body"
        role="button"
        tabIndex="0"
        onClick={() => setIsPopupEnable(activity)}
      >
        <div className="comm-act-title">{activity.title}</div>

        <div className="comm-proj-title">{activity.project.name}</div>
      </div>

      <div className="comm-date-time">
        <div className="comm-duration-wrap">
          <div
            className={
              moment(logged_in_user_duration?.end_date).isBefore(Date.now())
                ? "comm-act-due-on act-due-on-delayed"
                : "comm-act-due-on act-due-on-on-target"
            }
          >
            {moment
              .utc(logged_in_user_duration?.end_date)
              .format("ddd, DD MMM YY")}
          </div>
          <div className="comm-planned-upcoming">

            <span className="not-delayed">
              <img src={onTimeIcon} alt="ontime-icon" />
              <span>
                {" "}
                {convertTimeToString(logged_in_user_duration?.total_time)}
              </span>
            </span>
          </div>
        </div>
      </div>

      {isPopupEnableEdit && (
        <DialogWrapper dialogProps={dialogPropsEdit}>
          <ActivityForm
            logedinUserDetails={loggedInUser}
            selectedActivity={activity}
            source="FROM_ARCHIVE"
            {...dialogPropsEdit}
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default memo(ActivityTile);
