import React, { Component } from "react";
import PropsTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../styles/img/svg/close_new.svg";

import Button from "@material-ui/core/Button";

import Zoom from "@material-ui/core/Zoom";
import { connect } from "react-redux";
import { updateScrollTopHeightAction } from "../../actions/dialog/dialogaction";

const Dialog = withStyles((_theme) => ({
  paper: {
    borderRadius: "10px",
    maxWidth: "700px"
    // width: "700px"
  }
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit + 2.8,
    color: theme.palette.grey[500]
  }
}))((props) => {
  const {
    children,
    classes,
    onClose,
    topButton,
    confirmTitle,
    handleConfirm,
    shouldButtonEnable
  } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} header-wrap`}
      id="dialog_title_wrapper"
    >
      <div className="header-text">{children}</div>

      {topButton && (
        <IconButton
          aria-label="done"
          id="confirm_top_button"
          onClick={handleConfirm}
          disabled={shouldButtonEnable}
          className={`${shouldButtonEnable ? "" : "password-done"}`}
        >
          {confirmTitle}
        </IconButton>
      )}
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src={CloseIcon} alt="" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class DialogWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    const {
      dialogProps: { showPopup }
    } = this.props;
    this.showOrHideConfirmationDialog(showPopup);
  }

  componentDidUpdate(prevProps, _prevState) {
    const {
      dialogProps: { showPopup }
    } = this.props;
    const {
      dialogProps: { showPopup: _showPopup }
    } = prevProps;

    if (!_showPopup && showPopup) {
      this.setState({
        open: true
      });
    }
  }

  showOrHideConfirmationDialog = (enableState) => {
    this.setState({
      open: enableState
    });
  };

  handleConfirm = () => {
    const {
      dialogProps: { onConfirmCallBack }
    } = this.props;
    onConfirmCallBack && onConfirmCallBack();
  };

  handleClose = () => {
    const {
      dialogProps: { onCloseCallBack }
    } = this.props;
    onCloseCallBack && onCloseCallBack();
  };

  scrollEvent= (event) =>{
        if(this.props.dialogProps.shouldScrollUpdate){
          this.props.updateScrollTopHeight(event.target.scrollTop);
        } 
  }

  render() {
    const {
      dialogProps: {
        // dialogId,
        className,
        dialogTitle,
        enableActions,
        confirmTitle,
        cancelTtitle,
        onCloseCallBack,
        topButton,
        shouldButtonEnable,
        // isTitle
      },
      children
    } = this.props;
    
    return (
      <Dialog
       onScroll={this.scrollEvent}
        open={this.state.open}
        onClose={this.handleClose}
        classes={{
          paper: className
        }}
        TransitionComponent={Zoom}
        keepMounted={false}
      >
        {dialogTitle() && (
          <DialogTitle
            onClose={onCloseCallBack ? this.handleClose : null}
            topButton={topButton}
            shouldButtonEnable={shouldButtonEnable}
            handleConfirm={this.handleConfirm}
            confirmTitle={confirmTitle}
          >
            {dialogTitle()}
          </DialogTitle>
        )}

        {children}
        {enableActions && (
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {cancelTtitle}
            </Button>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              {confirmTitle}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

DialogWrapper.defaultProps = {
  dialogId: "dialog_wrapper",
  dialogProps: {
    children: [],
    onCloseCallBack: () => { /* TODO document why this method 'onCloseCallBack' is empty */ },
    onConfirmCallBack: () => { /* TODO document why this method 'onConfirmCallBack' is empty */ }
  }
};

DialogWrapper.propTypes = {
  children: PropsTypes.element.isRequired,
  dialogProps: PropsTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
      return{
        updateScrollTopHeight:(scrollHeight)=>{
          dispatch(updateScrollTopHeightAction({scrollHeight}));
        }
      }
}
export default connect(null, mapDispatchToProps)(DialogWrapper);
