import React, {Component} from 'react';
import "../../styles/scss/kanban.scss";
import KanbanColumnArea from './kanbanColumnArea';
import { getProjectByIdAction, updateProjectAction, getProjectsAction } from '../../actions/project/projectaction';
import { getActivitiesByProjectAction, clearActivitiesByProjectAction, changeWorkFlowFromKanbanAction, updateActivityWithWorkflowAction } from '../../actions/activities/activitiesaction';
import { connect } from "react-redux";
import KanbanContext from '../../contexts/kanban/kabanContext';
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import _ from "lodash";
import * as action from "../../actions/index";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
import routesHelper from '../../router/routeshelper';

class Kanban extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedProject : {},
        // activitiesList :[],
        userMap:{},
        projectList:[]
      };
    }
    getActivitiesWithProjectId = (projId) => {
      const {getActivitiesByProjectAction,loggedinUser,selectedCustomer} = this.props;
      const isSuperAdmin = getIsSuperAdmin(loggedinUser, selectedCustomer.selectedWorkspace)
      const user_id = isSuperAdmin ? null : parseInt(loggedinUser.id);
      getActivitiesByProjectAction({activity_type:'ACTIVITY',is_active:true,project_id:projId,is_archived:false,user_id},response => {
        // this.setState({
        //     activitiesList : response.filter(activity => activity.user_id.length !== 0)
        // })
      })
    }
     getProjectById = (projId) => {
      const {project, router} = this.props;
      if(project.length > 0) {
        const selectedProject = project.filter(project => project.id === parseInt(projId))[0]
        this.setState({
          selectedProject
      },this.getActivitiesWithProjectId(projId))
      } 
      // else {
      //   getProjectByIdAction(false,projId,response => {
      //     this.setState({
      //         selectedProject : response
      //     },this.getActivitiesWithProjectId(projId))
      //   });
      // }
      router.push(routesHelper.kanbanPath(projId));
    }
    componentDidMount(){
        const {router,getAllCustomerForPeopleAction,loggedinUser,selectedCustomer,workspaceUsers,project} = this.props;
        const {router:{params:{projId}}} = this.props;
        // this.getProjectById(projId);
        if(router.location.query.customer_id && (router.location.query.customer_id !== localStorage.getItem("customer_id"))) {
          console.log("Kanban did mount")
          router.push(routesHelper.getProjectsPath());
          return;
        }
        if(router.location.action === "POP") {
          if(loggedinUser && loggedinUser.id
            && selectedCustomer.selectedWorkspace
            && selectedCustomer.selectedWorkspace.id) {
            this.getActivitiesWithProjectId(projId)
          }
        getAllCustomerForPeopleAction({}, response => {
            let userMap = {};
            _.forEach(response,(user) => {
                userMap[user.id] = user;
            })
            this.setState({
                userMap
            })
        });
        
      } else {
          this.getActivitiesWithProjectId(projId)
          let userMap = {};
            _.forEach(workspaceUsers,(user) => {
                userMap[user.id] = user;
            })
            this.setState({
                userMap,
                projectList: project
            })
          }

    }

    static getDerivedStateFromProps(props, state) {
      if(props.project.length !== state.projectList.length) {
        if(props.project.length > 0) {
          const {router:{params:{projId}}} = props;
          const selectedProject = props.project.filter(project => project.id === parseInt(projId))[0]
          return {
            projectList: props.project,
            selectedProject
          }
        }
      }
      return null;
    }

    updateSelectedProjectState = (selectedProject) => {
      this.setState({ selectedProject });
    }

    componentWillUnmount() {
      this.props.clearActivitiesByProjectAction();
    }

    render (){
        const {selectedProject,userMap,projectList} = this.state;
        const {loggedinUser,updateProjectAction,updateActivityWithWorkflowAction,selectedCustomer,
          getUserDetailsAction,fetchTimesheetAction,archiveOrUnArchiveActivitiesAction,activities,
          prioritizeDePrioritizeFromKanBanAction,changeWorkFlowAction,prioritizedActivity,
          getSelectedWorkpsaceArchivedActivities} = this.props;
        const router = this.props.router;
        const getProjectById = this.getProjectById;
        const getActivitiesWithProjectId = this.getActivitiesWithProjectId;
        return (
            <div className="projectflow-container">
                <KanbanContext.Provider value={{selectedProject,updateSelectedProjectState: this.updateSelectedProjectState,
                  activitiesList: activities,userMap,loggedinUser,updateProjectAction,updateActivityWithWorkflowAction,projectList,
                  router,prioritizeDePrioritizeFromKanBanAction,
                  getProjectById,selectedCustomer,getUserDetailsAction,fetchTimesheetAction,getSelectedWorkpsaceArchivedActivities,
                  getActivitiesWithProjectId,archiveOrUnArchiveActivitiesAction,changeWorkFlowAction,prioritizedActivity}}>
                    <KanbanColumnArea></KanbanColumnArea>
                </KanbanContext.Provider>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
      project: state.projectReducer.listOfProjects.list,
      activities:state.activityReducer.activitiesByProject,
      loggedinUser: state.loginReducer.loginAuth.user_details,
      selectedCustomer : state.wrorkSpaceReducer.selectedCustomerDetails,
      prioritizedActivity: state.activityReducer.prioritiZedActivity.list,
      workspaceUsers: state.peopleReducer.workspaceusers
    };
  };
  
  const mapDispatchProps = dispatch => {
    return {
        getProjectByIdAction(archived,projectId,callback) {
         dispatch(getProjectByIdAction(archived,projectId,callback));
      },
        getActivitiesByProjectAction({activity_type,is_active,project_id,is_archived,user_id},callback){
          dispatch(getActivitiesByProjectAction({activity_type,is_active,project_id,is_archived,user_id},callback))
      },
        clearActivitiesByProjectAction() {
          dispatch(clearActivitiesByProjectAction());
        },
        getAllCustomerForPeopleAction(_, callback) {
            dispatch(getAllCustomerForPeopleAction({}, callback));
      },
      updateProjectAction({ auth_token, queryParam, body }, callback) {
        dispatch(
          updateProjectAction({ auth_token, queryParam, body }, callback)
        );
      },
      updateActivityWithWorkflowAction: (
        {
          id,
          version_id,
          workflow
        },
        callback
      ) => {
        dispatch(
            updateActivityWithWorkflowAction(
            {
            id,
            version_id,
            workflow
            },
            callback
          )
        );
      },

    getProjectsAction: ({ queryParam }, callback) => {
      dispatch(getProjectsAction({ queryParam }, callback));
    },
    getUserDetailsAction({ queryparams }, callback) {
      dispatch(action.getUserDetailsAction({ queryparams }, callback));
    },
    archiveOrUnArchiveActivitiesAction(
      { auth_token, archiveData, type },
      callback
    ) {
      dispatch(
        action.archiveOrUnArchiveActivities(
          { auth_token, archiveData, type },
          callback
        )
      );
    },
    prioritizeDePrioritizeFromKanBanAction(
      { auth_token, archiveData, type },
      callback
    ) {
      dispatch(
        action.prioritizeDePrioritizeFromKanBanAction(
          { auth_token, archiveData, type },
          callback
        )
      );
    },
    changeWorkFlowAction(
      { id, workflow, is_Priority, customer_auth_token },
      callback
    ) {
      dispatch(
        changeWorkFlowFromKanbanAction(
          { id, workflow, is_Priority, customer_auth_token },
          callback
        )
      );
    },
    getSelectedWorkpsaceArchivedActivities(
      {
        auth_token,
        client_id,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page
      },
      callback
    ) {
      dispatch(
        action.getSelectedWorkpsaceArchivedActivities(
          {
            auth_token,
            client_id,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page
          },
          callback
        )
      );
    }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(Kanban);