import React, { useState, memo } from 'react';
import '../../styles/scss/addFeatures.scss';
import activity_icon from '../../styles/img/svg/activity-icon-af.svg';
import activity_icon_hover from '../../styles/img/svg/activity-icon-hover-af.svg';
import add_icon from '../../styles/img/svg/add-icon-af.svg';
import add_icon_hover from '../../styles/img/svg/add-icon-hover-af.svg';
import {activities}  from '../../constants/activitiescontants';
import OutsideClickHandler from 'react-outside-click-handler';
import Tooltip from "@material-ui/core/Tooltip";

const AddFeatures = (props) => {
    const [icon, setIcon] = useState("add-icon");
    const [menu, setMenu] = useState("ng-leave");

    const handleButtonClicked = (clikedItem) => {
        const { handleClickCallback, onShowDialog } = props;
        onShowDialog && onShowDialog();
        handleClickCallback && handleClickCallback(clikedItem)
    }

    const handleClickOutside = () => {
        setIcon('add-icon');
        setMenu('ng-leave');
    }

    return (
        
        <OutsideClickHandler
        onOutsideClick={handleClickOutside}
      >
          <div data-tut="add-feature-container" className={props.reportpage?"add-feature-container report-page-display":"add-feature-container"}>
            <div className="add-feature-holder">
                <div className="af-main-holder">
                    <div className={`af-inner-holder ${menu}`}>
                        {/* <div className="af-toggle-holder">
                            {/* <md-tooltip class="af" md-direction="left">Add User</md-tooltip> 
                            <div 
                                className="af-circular-icon"
                                onClick={()=>{handleButtonClicked(activities.add.user)}}
                            >
                                <img 
                                    src={user_icon} 
                                    alt="User icon"
                                    className="normal"
                                />
                                <img 
                                    src={user_icon_hover} 
                                    alt="User icon"
                                    className="hover"
                                />
                            </div>
                        </div> */}

                        {/* <div className="af-toggle-holder">
                            {/* <md-tooltip class="af" md-direction="left">Add Client</md-tooltip> 
                            <div 
                                className="af-circular-icon"
                                onClick={()=>{handleButtonClicked(activities.add.client)}}    
                            >
                                <img 
                                    src={client_icon} 
                                    alt="Client icon"
                                    className="normal"
                                />
                                <img 
                                    src={client_icon_hover}
                                    alt="Client icon"
                                    className="hover"
                                />
                            </div>
                        </div> */}

                        {/* <div className="af-toggle-holder">
                            {/* <md-tooltip class="af" md-direction="left">Add Project</md-tooltip> 
                            <div 
                                className="af-circular-icon"
                                onClick={()=>{handleButtonClicked(activities.add.project)}}
                            >
                                <img 
                                    src={project_icon} 
                                    alt="Project icon"
                                    className="normal"
                                />
                                <img 
                                    src={project_icon_hover}
                                    alt="Project icon"
                                    className="hover"
                                /> 
                            </div>
                        </div> */}

                        <div className="af-toggle-holder">
                            {/* <md-tooltip class="af" md-direction="left">Add Activity</md-tooltip> */}
                            <div 
                                className="af-circular-icon"
                                onClick={()=>{handleButtonClicked(activities.add.activity)}}
                            >
                                <img 
                                    src={activity_icon}
                                    alt="Activity icon"
                                    className="normal"
                                />
                                <img 
                                    src={activity_icon_hover} 
                                    alt="Activity icon"
                                    className="hover"
                                /> 
                            </div>
                        </div>
                    </div> 
                </div>
                                                
                                                
                <div className="af-toggle-holder add no-margin" id="af-toggle-holder">
                    <Tooltip
                        title={!props.reportpage && props.handleClickCallback ? "New Activity" : "" }
                        placement="top"
                    >
                    <div 
                        className={`af-circular-icon ${icon}`} 
                        onClick = {()=>{handleButtonClicked(activities.add.activity)}}
                    >
                        <img 
                            src={add_icon}
                            alt="Add icon" 
                            className="normal"
                        />
                        <img 
                            src={add_icon_hover} 
                            alt="Add icon"
                            className="hover"
                        /> 
                    </div>
                    </Tooltip>
                </div>
            </div>
        </div>
      </OutsideClickHandler>
        
    )
}
                                            
export default memo(AddFeatures);