export const commentsConstant = {
    getComments : 'GET_COMMENTS',
    searchComments: 'SEARCH_COMMENTS',
    addComment : 'ADD_COMMENT',
    addAttachments: 'ADD_ATTACHMENTS',
    editComment : 'EDIT_COMMENT',
    updateLikeBookmarkComment: "UPDATE_LIKE_BOOKMARK_COMMENT",
    deleteComment : 'DELETE_COMMENT',
    unArchiveComment: 'UNARCHIVE_COMMENT',
    getCommentsLoaderState : 'GET_COMMENTS_LOADER',
    addCommentLoaderState : 'ADD_COMMENT_LOADER',
    editCommentLoaderState : 'EDIT_COMMENT_LOADER',
    replyCommentLoaderState : 'REPLY_COMMENT_LOADER',
    clearComments:'CLEAR_COMMENTS',
    clearSearchedComments: 'CLEAR_SEARCHED_COMMENTS',
    clearArchivedComments: "CLEAR_ARCHIVED_COMMENTS",
    getArchivedComments: 'GET_ARCHIVED_COMMENTS',
    getMoreArchivedComments: 'GET_MORE_ARCHIVED_COMMENTS'
};