import React, { PureComponent } from "react";
// import AppleLogin from "react-apple-login";
import routesHelper from "../../router/routeshelper";
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from "../../constants/globalconst";
import AppleSignup from "../../styles/img/svg/apple-black.svg";
import { isGoogleSignIn, isAppleSignIn } from "../../helpers/loginconstants";
import { autherizeAppleUser } from "../../services/loginapi";
import { updateUserFirstTimeFlagAction } from "../../actions/common/commonactions";
import firebase from "../../firebase";


class AppleRegistration extends PureComponent {
  responseApple = response => {
    // console.log(response.code);
    // this.onGoogleRegister({ payload: response });
    autherizeAppleUser({ code: response.code }, (res) => {
      console.log("Response from backend apple", res);
    })
  };

  onRegisterWithAppleClick = () => {
    firebase.analytics().logEvent("GTD_Apple_Register");
    window.AppleID.auth.init({
      clientId : APPLE_CLIENT_ID,
      scope : 'name email',
      redirectURI : `${APPLE_REDIRECT_URI}`,
      // state : '[STATE]',
      // nonce : '[NONCE]',
      usePopup : false //or false defaults to false
  });
  window.AppleID.auth.signIn();
  //Listen for authorization success
document.addEventListener('AppleIDSignInOnSuccess', (data) => {
  //handle successful response
  // console.log("apple", data)
  // var ca = "eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLnBlcHBlcnNxdWFyZS5HZXRUaGluZ3NEb25lIiwiZXhwIjoxNTk1NTgwNDIxLCJpYXQiOjE1OTU1Nzk4MjEsInN1YiI6IjAwMDczNC5iYWQ2YzRiZWU3NGQ0ZjZkYmJjMDhlMDEyNDM0MzZhYi4wODM3IiwiY19oYXNoIjoiVEdnaEtSRm5pSEVXbGFHbTl2MTJaUSIsImVtYWlsIjoic3lzYWRtaW5AcGVwcGVyc3F1YXJlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImF1dGhfdGltZSI6MTU5NTU3OTgyMSwibm9uY2Vfc3VwcG9ydGVkIjp0cnVlfQ.cMyxuZGwykUwF3_B5tY1p_7CYxZ5Ty3EV62XT7OpLNpJIk5srIaN2cqPS6WMqvsLRkdDtdWSoRpAaszZuLCj2NGo8HC_W9Gdo0hg7cpwsu2L9VOrd-2j_2IApoT9WQlp3UbE4funVN6Ohrz2zLF3I-opHtJsInYnndVwVGh3bmBspO5SB06Jv-lkgqhAIGYZuWCZ0Tl1vpzOlcWKR1MtxQja9l3hMHicXf4s5aU3BvTAL3xbhuSMg1Rx1RzAojNpeWm2WHd5UyJzk-MeqebrtXh4yBhUpu3vwwqZzvbaSzgRCtfqO5en0KTxMonY1CO7gGQbGGdYTqJcMpPEEk1F0Q" || data.detail.authorization.id_token;
  // var base64Url = ca.split('.')[1];
  // var decodedValue = JSON.parse(window.atob(base64Url));
  // console.log("decoded", decodedValue)
  
});
//Listen for authorization failures
document.addEventListener('AppleIDSignInOnFailure', (error) => {
  //handle error.
});
  }

  onAppleRegister = ({ payload = {} }) => {
    const {
      googleAuthenticationAction,
      acceptInviteAction,
      router
    } = this.props;
    payload.code &&
      googleAuthenticationAction(
        {
          code: payload.code,
          clientId: routesHelper.getGoogleDevApiKey(),
          redirectUri: routesHelper.getBaseUrl()
        },
        resposne => {
          if (resposne && resposne.id) {
            // if (
            //   router.location.query.token &&
            //   resposne.auth_token
            //   // resposne.is_new_user
            // ) {
            //   acceptInviteAction(
            //     {
            //       token: router.location.query.token,
            //       auth_token: resposne.auth_token
            //     },
            //     res => {
            //       if (
            //         res &&
            //         (res.detail.includes("Invitation accepted successfully") ||
            //           res.detail.includes(
            //             "You are already member of this workspace"
            //           ))
            //       ) {
            //         for (const key in resposne) {
            //           if (resposne.hasOwnProperty(key)) {
            //             const element = resposne[key];
            //             if (key === "data") {
            //               localStorage.setItem(key, JSON.stringify(element));
            //             } else {
            //               localStorage.setItem(key, element);
            //             }
            //           }
            //         }
            //         localStorage.setItem(isGoogleSignIn, true);
            //         if(resposne.first_time_logged_in){
            //           updateUserFirstTimeFlagAction({auth_token:resposne.auth_token,id:resposne.id,first_time_logged_in:false})
            //           localStorage.setItem("firsttimeprofile", true);
            //           return router.push(routesHelper.welcomePath());
            //         }else{
            //           router.push(routesHelper.getRootPath());
            //         } 
            //       }
            //     }
            //   );
            // } else {
              for (const key in resposne) {
                if (resposne.hasOwnProperty(key)) {
                  const element = resposne[key];
                  if (key === "data") {
                    localStorage.setItem(key, JSON.stringify(element));
                  } else {
                    localStorage.setItem(key, element);
                  }
                }
              }
              localStorage.setItem(isAppleSignIn, true);
              localStorage.setItem("firsttimeprofile", true);
              updateUserFirstTimeFlagAction({auth_token:resposne.auth_token,id:resposne.id,first_time_logged_in:false})
              localStorage.setItem(isGoogleSignIn, true);
              router.push(routesHelper.getFinishProfile());
            // }
          }
        }
      );
  };

  render() {
    return (
      <div className="goog appl" onClick={this.onRegisterWithAppleClick}>
            <img src={AppleSignup} alt="Apple Signup" />
            <p className="or_sign">Register with Apple </p>
          </div>
    )
    // return (
    //   <AppleLogin
    //     clientId={APPLE_CLIENT_ID}
    //     redirectURI={`${APPLE_REDIRECT_URI}/register`}
    //     scope="name email"
    //     render={renderProps => (
    //       <div className="goog appl" onClick={renderProps.onClick}>
    //         <img src={AppleSignup} alt="Apple Signup" />
    //         <p className="or_sign">Register with Apple </p>
    //       </div>
    //     )}
    //     callback={this.responseApple}
    //   />
    // );
  }
}
export default AppleRegistration;
