import React, { PureComponent } from "react";
import "../../styles/scss/notifications.scss";
import OutsideClickHandler from "react-outside-click-handler";
import ProjectAccessReqBox from "../projects/accessReqDropdown";
import notificationBoardLogo from "../../styles/img/svg/notification_board.svg";
import notificationAletLogo from "../../styles/img/svg/oval-3.svg";
import { convertTimeToString } from "../../helpers/generichelper";
import MainNotifyComponent from "../../components/notifications/mainnotifycomponent";
import routesHelper from "../../router/routeshelper";
import DialogWrapper from "../common/dialog";
import ActivityPopupTop from "../activity/activityPopupTop";
import ActivityPopupBottom from "../activity/activityPopupBottom";
import {
  changeIsNewStatusAction,
  changeNoItemStatusAction,
  changeIsNewStatusOfSystemNotificationAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import moment from "moment";
import { activities } from "../../constants/activitiescontants";
import { changeWorkFlowAction } from "../../actions/activities/activitiesaction";
import {
  archiveOrUnArchiveActivities,
  prioritizeDePrioritizeFromNotificationAction,
  getDeprioritizedActivitiesForWorkspaceAction,
} from "../../actions/workspace/workspaceaction";
import { Tooltip } from "@material-ui/core";
import ActivityForm from "../activity/addactivity";
import NoItem from "../../components/noitem/noItem";
import _ from "lodash";
import firebase from "../../firebase";
const mapStateToProps = state => {
  return {
    allNotifications: state.notificationsReducer.listOfNotification.list,
    yourNotification: state.notificationsReducer.listOfYourNotification.list,
    globalNotification: [
      ...state.notificationsReducer.listOfGlobalNotification.list,
      ...state.notificationsReducer.listOfSystemNotification.list,
    ],
    uploadNotificationLoaderState:
      state.notificationsReducer.uploadNotificationLoaderState,
    noItem: state.notificationsReducer.noItem,
    prioritiZedActivity: state.activityReducer.prioritiZedActivity,
    loggedInUserId: state.loginReducer.loginAuth.user_details.id,
    userDetails: state.loginReducer.loginAuth.user_details,
    currentWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,

    prioritizedActivity: state.activityReducer.prioritiZedActivity.list,
    unPrioritizedActivity: state.activityReducer.listOfActivity.list,
    archivedActivity: state.activityReducer.listOfArchivedActivity.list,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    archiveOrUnArchiveActivitiesAction(
      { auth_token, archiveData, type },
      callback
    ) {
      dispatch(
        archiveOrUnArchiveActivities(
          { auth_token, archiveData, type },
          callback
        )
      );
    },
    prioritizeDePrioritizeFromNotification(
      { auth_token, archiveData, type, updatedActivity },
      callback
    ) {
      dispatch(
        prioritizeDePrioritizeFromNotificationAction(
          { auth_token, archiveData, type, updatedActivity },
          callback
        )
      );
    },
    changeWorkFlow(
      { id, workflow, is_Priority, customer_auth_token },
      callback
    ) {
      dispatch(
        changeWorkFlowAction(
          { id, workflow, is_Priority, customer_auth_token },
          callback
        )
      );
    },
    changeIsNewStatus: ({ notificationArray }, callback) => {
      dispatch(changeIsNewStatusAction({ notificationArray }, callback));
    },
    changeIsNewStatusOfSystemNotification: (
      { notificationArray },
      callback
    ) => {
      dispatch(
        changeIsNewStatusOfSystemNotificationAction(
          { notificationArray },
          callback
        )
      );
    },
    changeNoItemStatusAction: ({ showStatus }) =>
      dispatch(changeNoItemStatusAction({ showStatus })),
    getDeprioritizedActivities: ({
      auth_token,
      activity_type,
      user_id,
      is_active,
      is_archived,
    }) => {
      dispatch(
        getDeprioritizedActivitiesForWorkspaceAction({
          auth_token,
          activity_type,
          user_id,
          is_active,
          is_archived,
        })
      );
    },
  };
}
class PerformaActions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayNotification: false,
      newNotificationAlert: true,
      activityDetails: null,
      clickedNotificationDetails: {},
      is_priority: false,
      displayDialogWrapper: false,
      clickedType: "comments",
      clickedId: null,
      checklistItemId: null,
      isPopupEnableEdit: false,
      fromActivityInnerAdd: false,
      previousYouNotification: this.props.yourNotification,
      parentCommentId: null,
      showProjAccessReqBox: false,
    };
  }

  // componentDidMount() {
  //   const user_id = parseInt(localStorage.getItem("id"));
  //   const archiveStatus =
  //     this.state.activityDetails.data.archivedUsers &&
  //     this.state.activityDetails.data.archivedUsers.includes(user_id);
  //   this.setState({ archiveStatus });
  // }

  openNotificationBox = () => {
    const {
      allNotifications,
      yourNotification,
      changeIsNewStatus,
      changeIsNewStatusOfSystemNotification,
      globalNotification,
    } = this.props;
    this.setState(
      {
        displayNotification: !this.state.displayNotification,
      },
      () => {
        if (this.state.displayNotification)
          firebase.analytics().logEvent("GTD_Notifications_Icon_Clicked");
        else firebase.analytics().logEvent("GTD_Notifications_Closed");
        let notifications = yourNotification.concat(allNotifications);
        let all = notifications.concat(globalNotification);

        let checkNewStatusofYourNotification = globalNotification
          .concat(yourNotification)
          .filter(notification => {
            return notification.is_new === true;
          });

        if (
          this.state.displayNotification &&
          checkNewStatusofYourNotification.length > 0
        ) {
          let notificationArray = [];
          let systemNotificationArray = [];
          all.forEach(function (item) {
            if (item.is_new && !item.users_data) {
              let notifyObj = {
                id: item.id,
                is_new: false,
              };
              notificationArray.push(notifyObj);
            } else if (item.is_new && item.users_data) {
              let notifyObj = {
                id: item.id,
                is_new: false,
              };
              systemNotificationArray.push(notifyObj);
            }
          });

          changeIsNewStatusOfSystemNotification(
            { notificationArray: systemNotificationArray },
            res => {
              if (res) {
                this.setState({
                  newNotificationAlert: false,
                });
              }
            }
          );

          changeIsNewStatus({ notificationArray }, res => {
            if (res) {
              this.setState({
                newNotificationAlert: false,
              });
            }
          });
        }
      }
    );
  };
  closeNotificationBox = (e, value) => {
    firebase.analytics().logEvent("GTD_Notifications_Closed");
    const {
      allNotifications,
      yourNotification,
      changeIsNewStatus,
      globalNotification,
    } = this.props;
    let parent = getClosest(e.target, "#notify-toggle");
    if (!_.isNull(parent)) {
      return;
    }
    this.setState(
      {
        displayNotification: value,
      },
      () => {
        let notifications = yourNotification.concat(allNotifications);
        let all = notifications.concat(globalNotification);

        let checkNewStatusofYourNotification = globalNotification
          .concat(yourNotification)
          .filter(notification => {
            return notification.is_new === true;
          });

        if (checkNewStatusofYourNotification.length > 0) {
          let notificationArray = [];
          all.forEach(function (item) {
            if (item.is_new) {
              let notifyObj = {
                id: item.id,
                is_new: false,
              };
              notificationArray.push(notifyObj);
            }
          });

          changeIsNewStatus({ notificationArray }, res => {
            if (res) {
              this.setState({
                newNotificationAlert: false,
              });
            }
          });
        }
      }
    );
  };
  onClickTime = () => {
    const { router } = this.props;
    if (router.location.pathname !== routesHelper.getReportPath())
      router.push(routesHelper.getReportPath());
  };
  controlDisplayNotification = displayNotification => {
    this.setState({
      displayNotification: displayNotification,
    });
  };

  currentActivityUpdater = id => {
    const { prioritizedActivity, unPrioritizedActivity, archivedActivity } =
      this.props;

    let currentActivity = null;
    currentActivity = unPrioritizedActivity.find(
      activity => activity.id === id
    );
    if (!currentActivity) {
      currentActivity = prioritizedActivity.find(
        activity => activity.id === id
      );
    }
    if (!currentActivity) {
      currentActivity = archivedActivity.find(activity => activity.id === id);
    }
    if (!currentActivity) {
      // Activity not found in store, get from server
    }
    return currentActivity;
  };

  isUserPartOfCard = (activity, userId) => {
    return activity && activity.user_id.includes(parseInt(userId));
  };

  controlDisplayDialogWrapper = (
    id,
    { Id, type, checklistItemId, parentCommentId }
  ) => {
    const activityDetails =
      id instanceof Object ? id : this.currentActivityUpdater(id);
    if (activityDetails.is_locked) {
      if (this.isUserPartOfCard(activityDetails, this.props.loggedInUserId))
        this.setState({
          displayDialogWrapper: true,
          activityDetails: activityDetails,
          isNonOwner: id instanceof Object,
          clickedType: type,
          clickedId: Id,
          checklistItemId: checklistItemId,
          parentCommentId: parentCommentId,
        });
      else this.props.changeNoItemStatusAction({ showStatus: true });
    } else
      this.setState({
        displayDialogWrapper: true,
        activityDetails: activityDetails,
        isNonOwner: id instanceof Object,
        clickedType: type,
        clickedId: Id,
        checklistItemId: checklistItemId,
        parentCommentId: parentCommentId,
      });
  };
  closeDisplayDialogWrapper = () => {
    this.setState({
      displayDialogWrapper: false,
      activityDetails: null,
      clickedNotificationDetails: {},
      is_priority: false,
      clickedType: "comments",
      clickedId: null,
      checklistItemId: null,
    });
  };
  closePopup = () => {
    this.props.changeNoItemStatusAction({ showStatus: false });
    this.closeDisplayDialogWrapper();
    this.setState({ displayNotification: true });
  };
  onArchiveItem = activityDetails => {
    const { archiveOrUnArchiveActivitiesAction: archiveOrUnArchiveActivities } =
      this.props;
    let updatedActivity = { ...activityDetails };
    let user_id = parseInt(localStorage.getItem("id"));
    let archiveStatus =
      activityDetails.data.archivedUsers &&
      activityDetails.data.archivedUsers.includes(user_id);

    const customer_auth_token = localStorage.getItem(
      activities.customerAuthToken
    );

    let archiveData = {
      completed_on: moment(new Date(updatedActivity.completed_on)).format(
        "YYYYMMDD HH:mmZ"
      ),
      data: {
        ...updatedActivity.data,
        archivedUsers: updatedActivity.data.archivedUsers
          ? [...updatedActivity.data.archivedUsers, user_id]
          : [user_id],
        priority: updatedActivity.data.priority.filter(
          user => user.user_id !== user_id
        ),
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id,
    };

    let unArchiveData = {
      data: {
        ...updatedActivity.data,
        archivedUsers: activityDetails.data.archivedUsers
          ? activityDetails.data.archivedUsers.filter(user => user !== user_id)
          : [],
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id,
    };

    archiveOrUnArchiveActivities(
      {
        auth_token: customer_auth_token,
        archiveData: archiveStatus ? unArchiveData : archiveData,
        type: archiveStatus ? activities.unArchive : activities.archive,
      });
  };

  onPopupCloseCallBack = () => {
    this.setState(() => ({
      displayDialogWrapper: false,
      activityDetails: null,
      clickedNotificationDetails: {},
      is_priority: false,
      clickedType: "comments",
      clickedId: null,
      checklistItemId: null,
    }));
  };

  onPopupCloseEditCallBack = () => {
    this.setState({
      isPopupEnableEdit: false,
      fromActivityInnerAdd: false,
      displayDialogWrapper: true,
      displayNotification: true,
    });
  };

  handleClickEditCallback = (activity, fromActivityInnerAdd) => {
    this.setState({
      isPopupEnableEdit: true,
      fromActivityInnerAdd: fromActivityInnerAdd ? fromActivityInnerAdd : false,
      displayNotification: false,
      displayDialogWrapper: false,
      activityDetails: activity,
      // selectedActivity: activity
    });
  };

  updateActivityStateAfterAddUser = activityDetails => {
    let isNonOwner = this.state.isNonOwner;
    if (
      isNonOwner &&
      activityDetails.user_id.some(
        userid => userid === parseInt(localStorage.getItem("id"))
      )
    ) {
      isNonOwner = false;
    }
    if (!activityDetails.user_id.includes(parseInt(localStorage.getItem("id"))))
      isNonOwner = true;
    this.setState({ activityDetails: activityDetails, isNonOwner: isNonOwner });
  };

  onProjAccessOutsideClick = () => {
    this.setState({ showProjAccessReqBox: false });
  };

  onToggleProjAccessReq = () => {
    this.setState({ showProjAccessReqBox: !this.state.showProjAccessReqBox });
  };

  render() {
    const {
      allNotifications,
      yourNotification,
      totalTimeForTheDay,
      router,
      changeWorkFlow,
      // controlDisplayNotification,
      archiveOrUnArchiveActivitiesAction,
      prioritizeDePrioritizeFromNotification,
      loggedInUserId,
      currentWorkspace,
      globalNotification,
    } = this.props;

    const checkStatusofYourNotification = globalNotification
      .concat(yourNotification)
      .filter(notification => {
        return notification.is_new === true;
      });

    let dialogProps = {
      className: "activity-popup-container",
      showPopup: this.state.displayDialogWrapper,
      dialogTitle: () => { /* TODO document why this method 'dialogTitle' is empty */ },
      onCloseCallBack: this.onPopupCloseCallBack,
      shouldButtonEnable: true,
      shouldScrollUpdate: true,
    };
    let dialogPropsEdit = {
      className: "create_activity",
      showPopup: this.state.isPopupEnableEdit,
      dialogTitle: () => "Edit Actvity",
      onCloseCallBack: this.onPopupCloseEditCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true,
    };
    return (
      <div className="performa-wrapper">
        {router.location.pathname === routesHelper.getProjectsPath() && (
          <div className="proj-access-req-btn-holder">
            <OutsideClickHandler onOutsideClick={this.onProjAccessOutsideClick}>
              <div
                className="project-request-btn"
                onClick={this.onToggleProjAccessReq}
              >
                <span>Project access request</span>
              </div>
              {this.state.showProjAccessReqBox && (
                <ProjectAccessReqBox
                  onHideSearch={this.onToggleProjAccessReq}
                />
              )}
            </OutsideClickHandler>
          </div>
        )}
        <div className="today-yesterday">
          <div
            className={`today ${
              router.location.pathname === routesHelper.getReportPath()
                ? "on-report-page"
                : ""
            }`}
          >
            <Tooltip title="View your Productivity">
              <div className="time">
                <span className="hours" onClick={this.onClickTime}>
                  <a>{convertTimeToString(totalTimeForTheDay)}</a>
                </span>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="notification-icon-holder">
          <div className="notification-icon">
            {/* change logo with state update */}
            <Tooltip title="Notifications">
              <img
                src={notificationBoardLogo}
                alt="notifications"
                id="notify-toggle"
                className={
                  this.state.displayNotification === true
                    ? "notify open"
                    : "notify"
                }
                onClick={this.openNotificationBox}
              />
            </Tooltip>
            {checkStatusofYourNotification.length > 0 && (
              <img
                id="badge"
                className="button-badge"
                src={notificationAletLogo}
                alt="notification-badge"
              />
            )}
          </div>
        </div>

        {this.state.displayNotification && !this.state.displayDialogWrapper && (
          <MainNotifyComponent
            id="notificationBox"
            controlDisplayNotification={this.controlDisplayNotification}
            controlDisplayDialogWrapper={this.controlDisplayDialogWrapper}
            getClickedCommentId={this.getClickedCommentId}
            getClickedAttachmentId={this.getClickedAttachmentId}
            getChecklistId={this.getChecklistId}
            allNotifications={allNotifications}
            yourNotification={yourNotification}
            closeNotificationBox={this.closeNotificationBox}
            currentWorkspace={currentWorkspace}
            selectedCustomerDetails={this.props.currentWorkspace}
            userDetails={this.props.userDetails}
            router={router}
          />
        )}
        {/* DISPLAY DIALOG WRAPPER */}
        {this.state.displayDialogWrapper && (
          <DialogWrapper dialogProps={dialogProps}>
            <ActivityPopupTop
              key={`performa-action-${this.state.activityDetails.id}`}
              //activityDetails={this.state.activityDetails}
              activityDetails={
                this.state.isNonOwner
                  ? this.state.activityDetails
                  : this.currentActivityUpdater(this.state.activityDetails.id)
                  ? this.currentActivityUpdater(this.state.activityDetails.id)
                  : this.state.activityDetails
              }
              updateActivityState={this.updateActivityStateAfterAddUser}
              router={router}
              onClosePopup={dialogProps.onCloseCallBack}
              onArchiveItem={activity =>
                // this.onArchiveItem(
                //   this.state.popupActivityDetails,
                //   archiveOrUnArchiveActivities
                // )
                this.onArchiveItem(activity)
              }
              source={
                this.state.activityDetails.data.archivedUsers &&
                this.state.activityDetails.data.archivedUsers.includes(
                  parseInt(localStorage.getItem("id"))
                )
                  ? "FROM_ARCHIVE"
                  : "FROM_ACTIVE"
              }
              is_priority={
                this.state.activityDetails &&
                this.state.activityDetails.data &&
                this.state.activityDetails.data.priority &&
                this.state.activityDetails.data.priority.filter(item => {
                  return item.user_id === parseInt(loggedInUserId);
                }).length > 0
                  ? true
                  : false
              }
              changeWorkFlowAction={changeWorkFlow}
              archiveOrUnArchiveActivitiesAction={
                router.location.pathname === routesHelper.getActivityPath()
                  ? archiveOrUnArchiveActivitiesAction
                  : prioritizeDePrioritizeFromNotification
              }
              handleClickEditCallback={this.handleClickEditCallback}
              onPopupCloseCallBack={this.onPopupCloseCallBack}
              fromNotification={true}
              // is_priority={
              //   this.state.activityDetails &&
              //   this.state.activityDetails.data &&
              //   this.state.activityDetails.data.priority &&
              //   this.state.activityDetails.data.priority.filter(
              //     user => user.id === parseInt(this.props.userDetails.id)
              //   ).length > 0
              //     ? true
              //     : false
              // }
            />
            <ActivityPopupBottom
              activityDetailsAsProps={this.state.activityDetails}
              closeDisplayDialogWrapper={this.closeDisplayDialogWrapper}
              is_archived={false}
              clickedType={this.state.clickedType}
              clickedId={this.state.clickedId}
              checklistItemId={this.state.checklistItemId}
              fromNotification={true}
              is_priority={
                this.state.activityDetails &&
                this.state.activityDetails.data &&
                this.state.activityDetails.data.priority &&
                this.state.activityDetails.data.priority.filter(item => {
                  return item.user_id === parseInt(loggedInUserId);
                }).length > 0
                  ? true
                  : false
              }
              parentCommentId={this.state.parentCommentId}
            />
          </DialogWrapper>
        )}
        {this.props.noItem && (
          <NoItem
            closePopup={() => this.closePopup()}
            noItem={this.props.noItem}
            dummytext="comments"
          />
        )}

        {this.state.isPopupEnableEdit && (
          <DialogWrapper dialogProps={dialogPropsEdit}>
            <ActivityForm
              fromActivityInnerAdd={this.state.fromActivityInnerAdd}
              selectedActivity={this.state.activityDetails}
              // isPopupEnableInnerDisableClicked={this.closeDisplayDialogWrapper}
              fromNotification={true}
              router={router}
              updateActivityState={this.updateActivityStateAfterAddUser}
              {...dialogPropsEdit}
            />
          </DialogWrapper>
        )}
      </div>
    );
  }
}

const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { /* TODO document why this block is empty */ }
        return i > -1;
      };
  }
  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};
export default connect(mapStateToProps, mapDispatchToProps)(PerformaActions);
