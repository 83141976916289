import {DialogConstants} from "../../constants/dialogConstants";


const initialState={
    scrollHeight:0
}

export default function dialogReducer(state = initialState, action){
    switch (action.type) {
        case DialogConstants.SCROLL_TOP:
            return{
                ...state,
                scrollHeight:action.payload.scrollHeight  
            }
        default:
            return {
                ...state
            }    
    } 
}