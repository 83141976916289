import {
  getTimeSheets,
  deleteTimeSheet,
  editTimeSheet,
  updateActivityReport,
  getAllActivityType,
  getReport
} from "../../services/timesheetapi";
import { reports } from "../../constants/reportsconstants";
import { addAttachmentFiles } from "../../services/attachmentapi";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

export function updateTimeSheetList({ action, payload }) {
  return {
    type: action,
    payload
  };
}

export const getTimeSheetsAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: reports.timeSheetListLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getTimeSheets({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: reports.timeSheetListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateTimeSheetList({
          action: reports.timeSheetList,
          payload: { list: response }
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: reports.timeSheetListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const deleteTimeSheetAction = (
  { auth_token, body },
  callback
) => dispatch => {
  deleteTimeSheet({ auth_token, body }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch({ type: reports.deleteTimeSheet, payload: response });
    }
    callback && callback(response);
  });
};

export const editTimeSheetAction = ({ body }, formData, callback) => dispatch => {
  editTimeSheet({ body }, response => {
    if (response && !response.error && !response.stdErr) {
      if(formData) {
        addAttachmentFiles(formData, res => {
          if(res && !res.stdErr && res?.length >= 0) {
            dispatch({ type: reports.editTimeSheet, payload: { ...response, attachments: [...body.attachments, ...res] } });
          }
          callback && callback(response, res);
        })
      } else
        dispatch({ type: reports.editTimeSheet, payload: response });
    }
    callback && !formData && callback(response);
  });
};

export const updateActivityReportAction = ({ body }, callback) => dispatch => {
  updateActivityReport({ body }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch({ type: reports.updateActivityReport, payload: response });
    }
    callback && callback(response);
  });
};

export const updateFilterAction = filters => dispatch => {
  dispatch({ type: reports.filterTimeSheet, payload: filters });
};

export const updateSortAction = sort => dispatch => {
  dispatch({ type: reports.sortTimeSheet, payload: sort });
};

export const clearTimesheetAction = () => dispatch => {
  dispatch(
    updateTimeSheetList({
      action: reports.timeSheetList,
      payload: { list: [] }
    })
  );
};


export const getAllActivityTypeAction= ({param,displaySpinner}) => dispatch =>{
  if(displaySpinner){
    dispatch(
      updateLoaderState({
        action: reports.timeSheetListLoaderState,
        state: {
          fetching: true,
          error: false
        }
      })
    );
    getAllActivityType({param},response=>{
      if (response && !response.error && !response.stdErr) {
        dispatch(
          updateLoaderState({
            action: reports.timeSheetListLoaderState,
            state: {
              fetching: false,
              error: false
            }
          })
        );
        dispatch({type:reports.FETCH_ALL_ACTIVITY_TYPE,payload:response})
      }else{
        dispatch(
          updateLoaderState({
            action: reports.timeSheetListLoaderState,
            state: {
              fetching: false,
              error: true
            }
          })
        );
      }
    })
  }else{
    getAllActivityType({param},response=>{
      dispatch({type:reports.FETCH_ALL_ACTIVITY_TYPE,payload:response})
   })
  }
 
}

export const getReportAction=(reportdata,callback)=>{
          getReport(reportdata,callback)
}
