import React, { PureComponent } from "react";
import Mark from "mark.js";
import "../../styles/scss/notifications.scss";
import searchIcon from "../../styles/img/svg/settings-search-icon1.svg";
import dots from "../../styles/img/svg/activity_dots.svg";
import Notification from "./notification";
import NotificationSearch from "../common/notificationSearch";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import {
  markAllAsReadOfYouTabAction,
  markAllAsReadOfAllTabAction,
  getGlobalNotificationsAction,
  getSystemNotificationsAction,
  getAllNotificationsAction,
  getYourNotificationsAction,
  removeNotifications,
  getFlaggedYouNotificationAction,
  getFlaggedAllNotificationAction,
  getReadYouNotificationAction,
  getReadAllNotificationAction,
  getSearchAllNotificationAction,
  getSearchYouNotificationAction,
  updateNotificationFlagAction,
  updateNotificationFlagSearchAction,
  updateNotificationReadAction
} from "../../actions/notifications/notificationaction";
import * as action from "../../actions/workspace/workspaceaction";

import firebase from "../../firebase";
import { connect } from "react-redux";

import routesHelper from "../../router/routeshelper";

function mapDispatchToProps(dispatch) {
  return {
    markAllAsReadOfYouTabAction: (
      { notificationArray, recipient_id, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        markAllAsReadOfYouTabAction(
          { notificationArray, recipient_id, ddownOption, searchText },
          callback
        )
      );
    },
    markAllAsReadOfAllTabAction: (
      { notificationArray, recipient_id, ddownOption, searchText },
      callback
    ) => {
      dispatch(
        markAllAsReadOfAllTabAction(
          { notificationArray, recipient_id, ddownOption, searchText },
          callback
        )
      );
    },
    getGlobalNotificationsAction: callback => {
      dispatch(getGlobalNotificationsAction(callback));
    },
    getSystemNotificationsAction: ({ auth_token }, callback) => {
      dispatch(getSystemNotificationsAction({ auth_token }, callback));
    },
    getAllNotificationsAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getAllNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getYourNotificationsAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getYourNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getTimerStatusForWorkspaceAction({ auth_token }, callback) {
      dispatch(
        action.getTimerStatusForWorkspaceAction({ auth_token }, callback)
      );
    },
    getFlaggedYouNotificationAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getFlaggedYouNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getFlaggedAllNotificationAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getFlaggedAllNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getReadYouNotificationAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getReadYouNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getReadAllNotificationAction: (
      { auth_token, page, per_page, recipient_id },
      callback
    ) => {
      dispatch(
        getReadAllNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getSearchYouNotificationAction: (
      { like, page, recipient_id, type, is_flagged, is_read },
      callback
    ) => {
      dispatch(
        getSearchYouNotificationAction(
          { like, page, recipient_id, type, is_flagged, is_read },
          callback
        )
      );
    },
    getSearchAllNotificationAction: (
      { like, page, recipient_id, type, is_flagged, is_read },
      callback
    ) => {
      dispatch(
        getSearchAllNotificationAction(
          { like, page, recipient_id, type, is_flagged, is_read },
          callback
        )
      );
    },
    removeNotifications: () => {
      dispatch(removeNotifications());
    },
    updateNotificationFlagAction:()=>{
      dispatch(updateNotificationFlagAction())
    },
    updateNotificationReadAction:()=>{
      dispatch(updateNotificationReadAction())
    },
    updateNotificationFlagSearchAction :()=>{
      dispatch(updateNotificationFlagSearchAction())
    }
  };
}
const mapStateToProps = state => {
  return {
    globalNotification: _.orderBy([
      ...state.notificationsReducer.listOfSystemNotification.list.filter(el => el?.users_data?.[0]?.users_requested.length > 0 || el?.users_data?.[0]?.users_added.length > 0),
      ...state.notificationsReducer.listOfGlobalNotification.list
    ], [
      (notification) => {
        let createdDate = new Date(notification?.type === "ADD_USER" ? notification.updated_at : notification.created_at);
        return createdDate.getTime();
      }
    ], ["desc"]),
    listOfSearchYouNotification:
      state.notificationsReducer.listOfSearchYouNotification.list,
    listOfSearchAllNotification:
      state.notificationsReducer.listOfSearchAllNotification.list,
    listOfAllNotification: state.notificationsReducer.listOfNotification.list,
    listOfYourNotification:
      state.notificationsReducer.listOfYourNotification.list,
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    listOfFlaggedAllNotification:
      state.notificationsReducer.listOfFlaggedAllNotification.list,
    listOfFlaggedYouNotification:
      state.notificationsReducer.listOfFlaggedYouNotification.list,
    listOfReadAllNotification:
      state.notificationsReducer.listOfReadAllNotification.list,
    listOfReadYouNotification:
      state.notificationsReducer.listOfReadYouNotification.list
  };
};

class MainNotifyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationSearch: false,
      showDropdown: false,
      option: "you",
      ddownOption: "View flagged",
      ddownOption2: "View read",
      flaggedNotificationOfYouTab: [],
      flaggedNotificationOfAllTab: [],
      pageNoOfAllTab: 1,
      pageNoOfYouTab: 1,
      pageNoOfFlaggedAllTab: 1,
      pageNoOfFlaggedYouTab: 1,
      pageNoOfReadAllTab: 1,
      pageNoOfReadYouTab: 1,
      searchPageNoOfAllTab: 1,
      searchPageNoOfYouTab: 1,
      searchText: ""
    };
  }
  searchTimer;

  UNSAFE_componentWillMount() {
    const {
      // listOfAllNotification,
      // listOfYourNotification,
      getSystemNotificationsAction,
      getAllNotificationsAction,
      getYourNotificationsAction,
      getGlobalNotificationsAction,
      getTimerStatusForWorkspaceAction
    } = this.props;
    
    
    let pageForNotification = 1;
    let per_pageForNotification = 10;
    let recipient_id = this.props.userDetails.id;

    getYourNotificationsAction(
      {
        auth_token:
          (this.props.selectedCustomerDetails &&
            this.props.selectedCustomerDetails.auth_token) ||
          localStorage.getItem("customer_auth_token"),
        page: pageForNotification,
        per_page: per_pageForNotification,
        recipient_id
      },
      res => {
        if(!res.stdErr && res?.length >= 0) {
          getGlobalNotificationsAction();
          getSystemNotificationsAction({ 
            auth_token:
                (this.props.selectedCustomerDetails &&
                  this.props.selectedCustomerDetails.auth_token) ||
                localStorage.getItem("customer_auth_token")
          });
          getAllNotificationsAction(
            {
              auth_token:
                (this.props.selectedCustomerDetails &&
                  this.props.selectedCustomerDetails.auth_token) ||
                localStorage.getItem("customer_auth_token"),
              page: pageForNotification,
              per_page: per_pageForNotification,
              recipient_id
            },
            _res => { /* TODO document why this arrow function is empty */ }
          );
          if(res && res.length > 0) {
            res.forEach(notification => {
              if(notification.type === "ACTIVITY_TIMER" && notification.is_new)
                getTimerStatusForWorkspaceAction({ auth_token: routesHelper.getAuthToken() });
            })
          }
        }
      }
    );
    // }
  }

  // componentDidMount() {
  //   const {
  //     getGlobalNotificationsAction
  //   } = this.props;
  //   getGlobalNotificationsAction();
  //   setInterval(() => {
  //     console.log("Called global Notification action")
  //     getGlobalNotificationsAction();
  //   }, 60000);
  // }

  componentWillUnmount() {
    const { removeNotifications } = this.props;
    removeNotifications();
  }

  dontShowDropdown = () => {
    this.setState({
      showDropdown: false
    });
  };

  updatePageNo = () => {
    // console.log("Updated page no is called!");
    // condition 1(search text present, View all option is selected)
    var context = document.querySelectorAll(".notify-temp-holder");
    var instance = new Mark(context);
    // var marked = document.querySelectorAll(".marked-search");
    // if(marked && marked.length > 0) instance.unmark();
    if (this.state.searchText && this.state.ddownOption == "View flagged" && this.state.ddownOption2 === "View read") {
      if (this.state.option === "you") {
        this.setState(
          {
            searchPageNoOfYouTab: this.state.searchPageNoOfYouTab + 1
          },
          () => {
            const { getSearchYouNotificationAction } = this.props;
            let younotificationTypeList =
              "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfYouTab;
            getSearchYouNotificationAction({
              like,
              page,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                  setTimeout(() => {
                    instance.unmark();
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      } else if (this.state.option === "all") {
        this.setState(
          {
            searchPageNoOfAllTab: this.state.searchPageNoOfAllTab + 1
          },
          () => {
            
            const { getSearchAllNotificationAction } = this.props;
            let notificationTypeList =
              "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfAllTab;
            getSearchAllNotificationAction({
              like,
              page,
              recipient_id,
              type: notificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      }
    }
    // condition 2(No search text, view all option selected)
    if (!this.state.searchText && this.state.ddownOption == "View flagged" && this.state.ddownOption2 === "View read") {
      const recipient_id = localStorage.getItem("id");
      const {
        getYourNotificationsAction,
        getAllNotificationsAction
      } = this.props;
      if (this.state.option === "you") {
        this.setState(
          {
            pageNoOfYouTab: this.state.pageNoOfYouTab + 1
          },
          () => {
            

            getYourNotificationsAction({
              auth_token: localStorage.getItem("customer_auth_token"),
              page: this.state.pageNoOfYouTab,
              per_page: 10,
              recipient_id
            });
          }
        );
      } else if (this.state.option === "all") {
        this.setState(
          {
            pageNoOfAllTab: this.state.pageNoOfAllTab + 1
          },
          () => {
            

            getAllNotificationsAction({
              auth_token: localStorage.getItem("customer_auth_token"),
              page: this.state.pageNoOfAllTab,
              per_page: 10,
              recipient_id
            });
          }
        );
      }
    }
    // condition 3(No search text, View flagged option is selected)
    if (!this.state.searchText && this.state.ddownOption == "View all" && this.state.ddownOption2 === "View read") {
      const {
        getFlaggedYouNotificationAction,
        getFlaggedAllNotificationAction,
        // userDetails,
        selectedCustomerDetails
      } = this.props;

      let per_page = 10;
      let recipient_id = localStorage.getItem("id");
      const auth_token = selectedCustomerDetails.auth_token;

      if (this.state.option === "all") {
        this.setState(
          {
            pageNoOfFlaggedAllTab: this.state.pageNoOfFlaggedAllTab + 1
          },
          () => {
            getFlaggedAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfFlaggedAllTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
                
              }
            );
          }
        );
      } else if (this.state.option === "you") {
        this.setState(
          {
            pageNoOfFlaggedYouTab: this.state.pageNoOfFlaggedYouTab + 1
          },
          () => {
            getFlaggedYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfFlaggedYouTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
                
              }
            );
          }
        );
      }
    }
    // // condition 4(search text present,View flagged option is selected)
    if (this.state.searchText && this.state.ddownOption === "View all" && this.state.ddownOption2 === "View read") {
      const {
        getSearchAllNotificationAction,
        getSearchYouNotificationAction
      } = this.props;
      let younotificationTypeList =
        "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
      let notificationTypeList =
        "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
      let recipient_id = localStorage.getItem("id"),
        like = this.state.searchText;

      if (this.state.option === "you") {
        this.setState(
          {
            searchPageNoOfYouTab: this.state.searchPageNoOfYouTab + 1
          },
          () => {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      } else if (this.state.option === "all") {
        this.setState(
          {
            searchPageNoOfAllTab: this.state.searchPageNoOfAllTab + 1
          },
          () => {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      }
    }
    // condition 5(No search text, View read option selected)
    if (!this.state.searchText && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View unread") {
      const {
        getReadYouNotificationAction,
        getReadAllNotificationAction,
        selectedCustomerDetails
      } = this.props;

      let per_page = 10;
      let recipient_id = localStorage.getItem("id");
      const auth_token = selectedCustomerDetails.auth_token;

      if (this.state.option === "all") {
        this.setState(
          {
            pageNoOfReadAllTab: this.state.pageNoOfReadAllTab + 1
          },
          () => {
            getReadAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfReadAllTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                } else {
                }
              }
            );
          }
        );
      } else if (this.state.option === "you") {
        this.setState(
          {
            pageNoOfReadYouTab: this.state.pageNoOfReadYouTab + 1
          },
          () => {
            getReadYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfReadYouTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                } else {
                  // console.log("no res from getReadYouNotificationAction");
                }
              }
            );
          }
        );
      }
    }
    // condition 6(Search text present, View read option selected)
    if (this.state.searchText && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View unread") {
      const {
        getSearchAllNotificationAction,
        getSearchYouNotificationAction
      } = this.props;
      let younotificationTypeList =
        "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
      let notificationTypeList =
        "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
      let recipient_id = localStorage.getItem("id"),
        like = this.state.searchText;

      if (this.state.option === "you") {
        this.setState(
          {
            searchPageNoOfYouTab: this.state.searchPageNoOfYouTab + 1
          },
          () => {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      } else if (this.state.option === "all") {
        this.setState(
          {
            searchPageNoOfAllTab: this.state.searchPageNoOfAllTab + 1
          },
          () => {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        );
      }
    }
  };

  searchNotification = text => {
    var context = document.querySelectorAll(".notify-temp-holder");
    var instance = new Mark(context);
    if(text === null) instance.unmark();
    this.setState({
      searchText: text
    });

    if(!text){
      this.props.updateNotificationFlagSearchAction();
    }
    

    clearTimeout(this.searchTimer)

    this.searchTimer = setTimeout(() => {
      const {
        getSearchAllNotificationAction,
        getSearchYouNotificationAction
      } = this.props;
      let notificationType = this.state.option;
      let younotificationTypeList =
        "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
      let notificationTypeList =
        "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
      let recipient_id = localStorage.getItem("id"),
        like = text,
        page = 1;
      // if no flagged then do
      if (text && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View read") {
        if (notificationType === "you") {
          getSearchYouNotificationAction({
            like,
            page,
            recipient_id,
            type: younotificationTypeList,
            is_flagged: false
          }, res => {
            if(res) {
              setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        } else {
          getSearchAllNotificationAction({
            like,
            page,
            recipient_id,
            type: notificationTypeList,
            is_flagged: false
          }, res => {
            if(res) {
              setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        }
      }
      // if flagged then do
      else if (text && this.state.ddownOption === "View all" && this.state.ddownOption2 === "View read") {
        if (notificationType === "you") {
          getSearchYouNotificationAction({
            like,
            page,
            recipient_id,
            type: younotificationTypeList,
            is_flagged: true
          }, res => {
            if(res) {
                setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        } else {
          getSearchAllNotificationAction({
            like,
            page,
            recipient_id,
            type: notificationTypeList,
            is_flagged: true
          }, res => {
            if(res) {
                setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        }
      }
      // if read then do
      else if (text && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View unread") {
        if (notificationType == "you") {
          getSearchYouNotificationAction({
            like,
            page,
            recipient_id,
            type: younotificationTypeList,
            is_read: true
          }, res => {
            if(res) {
                setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        } else {
          getSearchAllNotificationAction({
            like,
            page,
            recipient_id,
            type: notificationTypeList,
            is_read: true
          }, res => {
            if(res) {
                setTimeout(() => {
                instance.unmark();
                let updatedContext = document.querySelectorAll(".notify-temp-holder");
                let updatedInstance = new Mark(updatedContext);
                updatedInstance.mark(text, {
                  element: "span",
                  className: "marked-search",
                  accuracy: "partially",
                  exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                });
              }, 100)
            }
          });
        }
      }
    }, 800)
  };

  showNotificationSearch = () => {
    firebase.analytics().logEvent("GTD_Notifications_Search");
    this.setState({
      showNotificationSearch: !this.state.showNotificationSearch
    });
  };

  onCloseSearch = () => {
    var context = document.querySelectorAll(".notify-temp-holder");
    var instance = new Mark(context);
    instance.unmark();
    this.props.updateNotificationFlagSearchAction();
    this.setState({
      showNotificationSearch: false,
      searchText: "",
      // pageNoOfAllTab: 1,
      // pageNoOfYouTab: 1,
      searchPageNoOfAllTab: 1,
      searchPageNoOfYouTab: 1
    });
  };

  toggleDropdown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown
    });
  };

  changeOption = e => {
    var context = document.querySelectorAll(".notify-temp-holder");
    var instance = new Mark(context);
    var marked = document.querySelectorAll(".marked-search");
    if(marked && marked.length > 0) instance.unmark();
    let value = e.target.getAttribute("value");
    this.setState(
      {
        option: value,
        // pageNoOfYouTab: 1,
        searchPageNoOfYouTab: 1,
        // pageNoOfAllTab: 1,
        searchPageNoOfAllTab: 1
      },
      () => {
        if (this.state.option === "you")
          firebase.analytics().logEvent("GTD_Notifications_You_Tab");
        if (this.state.option === "all")
          firebase.analytics().logEvent("GTD_Notifications_All_Tab");
        // console.log(
        //   this.state.pageNoOfYouTab,
        //   this.state.searchPageNoOfYouTab,
        //   this.state.pageNoOfAllTab,
        //   this.state.searchPageNoOfAllTab
        // );
        if (this.state.searchText && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View read") {
          if (this.state.option === "you") {
            const { getSearchYouNotificationAction } = this.props;
            let younotificationTypeList =
              "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfYouTab;
            getSearchYouNotificationAction({
              like,
              page,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            const { getSearchAllNotificationAction } = this.props;
            let notificationTypeList =
              "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfAllTab;
            getSearchAllNotificationAction({
              like,
              page,
              recipient_id,
              type: notificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        }
        // condition 2(No search text, view all option selected)
        if (
          !this.state.searchText &&
          this.state.ddownOption === "View flagged" &&
          this.state.ddownOption2 === "View read"
        ) {
          const recipient_id = localStorage.getItem("id");
          const {
            getYourNotificationsAction,
            getAllNotificationsAction
          } = this.props;
          if (this.state.option === "you") {
            getYourNotificationsAction({
              auth_token: localStorage.getItem("customer_auth_token"),
              page: this.state.pageNoOfYouTab,
              per_page: 10,
              recipient_id
            });
          } else if (this.state.option === "all") {
            getAllNotificationsAction({
              auth_token: localStorage.getItem("customer_auth_token"),
              page: this.state.pageNoOfAllTab,
              per_page: 10,
              recipient_id
            });
          }
        }
        // condition 3(No search text, View flagged option is selected)
        if (!this.state.searchText && this.state.ddownOption === "View all" && this.state.ddownOption2 === "View read") {
          const {
            getFlaggedYouNotificationAction,
            getFlaggedAllNotificationAction,
            // userDetails,
            selectedCustomerDetails
          } = this.props;

          let per_page = 10;
          let recipient_id = localStorage.getItem("id");
          const auth_token = selectedCustomerDetails.auth_token;

          if (this.state.option === "all") {
            getFlaggedAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfAllTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
               
              }
            );
          } else if (this.state.option === "you") {
            getFlaggedYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfYouTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
                
              }
            );
          }
        }
        // // condition 4(search text present,View flagged option is selected)
        if (this.state.searchText && this.state.ddownOption === "View all" && this.state.ddownOption2 === "View read") {
          const {
            getSearchAllNotificationAction,
            getSearchYouNotificationAction
          } = this.props;
          let younotificationTypeList =
            "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
          let notificationTypeList =
            "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
          let recipient_id = localStorage.getItem("id"),
            like = this.state.searchText;

          if (this.state.option === "you") {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        }
        // Condition 5 (No search text, View read option is selected)
        if (!this.state.searchText && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View unread") {
          const {
            getReadYouNotificationAction,
            getReadAllNotificationAction,
            userDetails,
            selectedCustomerDetails
          } = this.props;

          let per_page = 10;
          let recipient_id = localStorage.getItem("id");
          const auth_token = selectedCustomerDetails.auth_token;

          if (this.state.option == "all") {
            getReadAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfAllTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                } else {
                }
              }
            );
          } else if (this.state.option == "you") {
            getReadYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfYouTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                } else {
                  // console.log("no res from getReadYouNotificationAction");
                }
              }
            );
          }
        }
        // condition 6 (search text present,View read option is selected)
        if (this.state.searchText && this.state.ddownOption === "View flagged" && this.state.ddownOption2 === "View unread") {
          const {
            getSearchAllNotificationAction,
            getSearchYouNotificationAction
          } = this.props;
          let younotificationTypeList =
            "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
          let notificationTypeList =
            "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
          let recipient_id = localStorage.getItem("id"),
            like = this.state.searchText;

          if (this.state.option === "you") {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        }
      }
    );
  };

  changeDropdownOption = value => {
    const {
      getFlaggedYouNotificationAction,
      getFlaggedAllNotificationAction,
      // userDetails,
      selectedCustomerDetails
    } = this.props;

    let per_page = 10;
    let recipient_id = localStorage.getItem("id");
    const auth_token = selectedCustomerDetails.auth_token;
    if (value === "View all") {
      this.props.updateNotificationFlagAction();
      this.setState({
        ddownOption: "View flagged",
        ddownOption2: "View read",
        // pageNoOfAllTab: 1,
        // pageNoOfYouTab: 1,
        pageNoOfFlaggedAllTab: 1,
        pageNoOfFlaggedYouTab: 1,
        searchPageNoOfAllTab: 1,
        searchPageNoOfYouTab: 1
      }, () => {
        if(this.state.searchText) {

          if (this.state.option === "you") {
            const { getSearchYouNotificationAction } = this.props;
            let younotificationTypeList =
              "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfYouTab;
            getSearchYouNotificationAction({
              like,
              page,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            const { getSearchAllNotificationAction } = this.props;
            let notificationTypeList =
              "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfAllTab;
            getSearchAllNotificationAction({
              like,
              page,
              recipient_id,
              type: notificationTypeList,
              is_flagged: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }

          

        }
      });
    } else if (value === "View flagged") {
      firebase.analytics().logEvent("GTD_Notifications_View_Flagged");
      this.setState(
        {
          ddownOption: "View all",
          ddownOption2: "View read",
          // pageNoOfAllTab: 1,
          // pageNoOfYouTab: 1,
          searchPageNoOfAllTab: 1,
          searchPageNoOfYouTab: 1
        },
        () => {

        // condition 1(search text present,View flagged option is selected)
        if (this.state.searchText) {
          const {
            getSearchAllNotificationAction,
            getSearchYouNotificationAction
          } = this.props;
          let younotificationTypeList =
            "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
          let notificationTypeList =
            "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
          let recipient_id = localStorage.getItem("id"),
            like = this.state.searchText;

          if (this.state.option === "you") {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_flagged: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        } else {
          if (this.state.option === "all") {
            getFlaggedAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfFlaggedAllTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
                
              }
            );
          } else if (this.state.option === "you") {
            getFlaggedYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfFlaggedYouTab,
                per_page,
                recipient_id
              },
              _res => {
                // TODO document why this arrow function is empty
              
                
              }
            );
          } 
        }
        }
      );
    }
  };

  changeDropdownOption2 = value => {
    const {
      getReadYouNotificationAction,
      getReadAllNotificationAction,
      userDetails,
      selectedCustomerDetails
    } = this.props;

    let per_page = 10;
    let recipient_id = localStorage.getItem("id");
    const auth_token = selectedCustomerDetails.auth_token;
    if (value === "View unread") {
      this.props.updateNotificationReadAction();
      this.setState({
        ddownOption: "View flagged",
        ddownOption2: "View read",
        // pageNoOfAllTab: 1,
        // pageNoOfYouTab: 1,
        pageNoOfReadAllTab: 1,
        pageNoOfReadYouTab: 1,
        searchPageNoOfAllTab: 1,
        searchPageNoOfYouTab: 1
      }, () => {
        if(this.state.searchText) {

          if (this.state.option === "you") {
            const { getSearchYouNotificationAction } = this.props;
            let younotificationTypeList =
              "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfYouTab;
            getSearchYouNotificationAction({
              like,
              page,
              recipient_id,
              type: younotificationTypeList,
              is_read: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            const { getSearchAllNotificationAction } = this.props;
            let notificationTypeList =
              "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
            let recipient_id = localStorage.getItem("id"),
              like = this.state.searchText,
              page = this.state.searchPageNoOfAllTab;
            getSearchAllNotificationAction({
              like,
              page,
              recipient_id,
              type: notificationTypeList,
              is_read: false
            }, res => {
              if(res) {
                setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }

          // var context = document.querySelectorAll(".notify-temp-holder");
          // var instance = new Mark(context);
          // setTimeout(() => {
          //   instance.unmark();
          //   let updatedContext = document.querySelectorAll(".notify-temp-holder");
          //   let updatedInstance = new Mark(updatedContext);
          //   updatedInstance.mark(this.state.searchText, {
          //     element: "span",
          //     className: "marked-search",
          //     accuracy: "partially",
          //     exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
          //   });
          // }, 100)

        }
      });
    } else if (value === "View read") {
      firebase.analytics().logEvent("GTD_Notifications_View_Read");
      this.setState(
        {
          ddownOption: "View flagged",
          ddownOption2: "View unread",
          // pageNoOfAllTab: 1,
          // pageNoOfYouTab: 1,
          searchPageNoOfAllTab: 1,
          searchPageNoOfYouTab: 1
        },
        () => {

        // condition 1(search text present,View read option is selected)
        if (this.state.searchText) {
          const {
            getSearchAllNotificationAction,
            getSearchYouNotificationAction
          } = this.props;
          let younotificationTypeList =
            "MENTIONS&type=ACT&type=PEOPLE&type=CHECKLIST_MENTIONS&type=WORK_HOURS&type=DELETE_MENTIONED_COMMENT&type=ACTIVITY_TIMER&type=LIKE_COMMENT";
          let notificationTypeList =
            "COMMENT&type=DELETE_COMMENT&type=EDIT_COMMENT&type=ACTIVITY&type=ATTACHMENT&type=DELETE_ATTACHMENT";
          let recipient_id = localStorage.getItem("id"),
            like = this.state.searchText;

          if (this.state.option === "you") {
            getSearchYouNotificationAction({
              like,
              page: this.state.searchPageNoOfYouTab,
              recipient_id,
              type: younotificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          } else if (this.state.option === "all") {
            getSearchAllNotificationAction({
              like,
              page: this.state.searchPageNoOfAllTab,
              recipient_id,
              type: notificationTypeList,
              is_read: true
            }, res => {
              if(res) {
                  setTimeout(() => {
                  let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  let updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(like, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                    exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  });
                }, 100)
              }
            });
          }
        } else {
          if (this.state.option === "all") {
            getReadAllNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfReadAllTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                  // if(this.state.searchText) {
                  //   var context = document.querySelectorAll(".notify-temp-holder");
                  //   var instance = new Mark(context);
                  //   setTimeout(() => {
                  //     instance.unmark();
                  //     let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  //     let updatedInstance = new Mark(updatedContext);
                  //     updatedInstance.mark(this.state.searchText, {
                  //       element: "span",
                  //       className: "marked-search",
                  //       accuracy: "partially",
                  //       exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  //     });
                  //   }, 100)
                  // }
                } else {
                  // console.log("no res from getReadAllNotificationAction");
                }
              }
            );
          } else if (this.state.option === "you") {
            getReadYouNotificationAction(
              {
                auth_token,
                page: this.state.pageNoOfReadYouTab,
                per_page,
                recipient_id
              },
              res => {
                if (res) {
                  // if(this.state.searchText) {
                  //   var context = document.querySelectorAll(".notify-temp-holder");
                  //   var instance = new Mark(context);
                  //   setTimeout(() => {
                  //     instance.unmark();
                  //     let updatedContext = document.querySelectorAll(".notify-temp-holder");
                  //     let updatedInstance = new Mark(updatedContext);
                  //     updatedInstance.mark(this.state.searchText, {
                  //       element: "span",
                  //       className: "marked-search",
                  //       accuracy: "partially",
                  //       exclude: [".comment_post_time", ".post_time", ".right-comments-reply-link", ".highlight"]
                  //     });
                  //   }, 100)
                  // }
                } else {
                  // console.log("no res from getReadYouNotificationAction");
                }
              }
            );
          } 
        }
        }
      );
    }
  };

  markAllAsRead = () => {
    firebase.analytics().logEvent("GTD_Notifications_Mark_All");
    const {
      markAllAsReadOfYouTabAction,
      markAllAsReadOfAllTabAction
    } = this.props;
    let recipient_id = localStorage.getItem("id");
    let flaggedNotificationOfAllTab =
      this.state.ddownOption === "View all" && this.state.option === "all"
        ? this.state.searchText
          ? this.props.listOfSearchAllNotification
          : this.props.listOfFlaggedAllNotification
        : this.state.searchText
        ? this.props.listOfSearchAllNotification
        : this.props.allNotifications;
    let flaggedNotificationOfYouTab =
      this.state.ddownOption === "View all" && this.state.option === "you"
        ? this.state.searchText
          ? this.props.listOfSearchYouNotification
          : this.props.listOfFlaggedYouNotification
        : this.state.searchText
        ? this.props.listOfSearchYouNotification
        : this.props.yourNotification;

    if (this.state.option === "you") {
      let notificationArray = [];

      flaggedNotificationOfYouTab.map(notification => {
        notification.is_read = true;
        let obj = {};
        obj.id = notification.id;
        obj.is_read = true;
        return notificationArray.push(obj);
      });

      markAllAsReadOfYouTabAction({
        notificationArray,
        recipient_id,
        ddownOption: this.state.ddownOption,
        searchText: this.state.searchText
      });
    } else if (this.state.option === "all") {
      let notificationArray = [];

      flaggedNotificationOfAllTab.map(notification => {
        notification.is_read = true;
        let obj = {};
        obj.id = notification.id;
        obj.is_read = true;
        return notificationArray.push(obj);
      });

      markAllAsReadOfAllTabAction({
        notificationArray,
        recipient_id,
        ddownOption: this.state.ddownOption,
        searchText: this.state.searchText
      });
    }
  };

  render() {
    const {
      listOfSearchYouNotification,
      listOfSearchAllNotification,
      allNotifications,
      yourNotification,
      currentWorkspace,
      listOfFlaggedAllNotification,
      listOfFlaggedYouNotification,
      listOfReadAllNotification,
      listOfReadYouNotification,
      router
    } = this.props;
    

    

    

    const flaggedNotificationOfAllTab =
      this.state.ddownOption2 === "View unread" && this.state.option === "all"
        ? this.state.searchText
          ? listOfSearchAllNotification.filter(notification => {
              return notification.is_read === true;
            })
          : listOfReadAllNotification
      : this.state.ddownOption === "View all" && this.state.option === "all"
        ? this.state.searchText
          ? listOfSearchAllNotification.filter(notification => {
              return notification.is_flagged === true;
            })
          : listOfFlaggedAllNotification
        : this.state.searchText
        ? listOfSearchAllNotification
        : allNotifications;

    // sort by date
    const sortedFlaggedNotificationOfAllTab = _.sortBy(
      flaggedNotificationOfAllTab,
      function(obj) {
        return obj.created_at;
      }
    );
    

    const flaggedNotificationOfYouTab =
      this.state.ddownOption2 === "View unread" && this.state.option === "you"
        ? this.state.searchText
          ? listOfSearchYouNotification.filter(notification => {
              return notification.is_read === true;
            })
          : listOfReadYouNotification
      : this.state.ddownOption === "View all" && this.state.option === "you"
        ? this.state.searchText
          ? listOfSearchYouNotification.filter(notification => {
              return notification.is_flagged === true;
            })
          : listOfFlaggedYouNotification
        : this.state.searchText
        ? listOfSearchYouNotification
        : yourNotification;

    //  sort by date
    const sortedFlaggedNotificationOfYouTab = _.sortBy(
      flaggedNotificationOfYouTab,
      function(obj) {
        return obj.created_at;
      }
    );

    return (
      <OutsideClickHandler
        onOutsideClick={e => {
          this.props.closeNotificationBox(e, false);
        }}
      >
        <div className="notification" id="notification">
          <div className="notification-wrapper">
            {!this.state.showNotificationSearch && (
              <React-Fragment>
                <div className="head">
                  <i className="icon-bell"></i>
                  <h2>Notifications</h2>
                </div>
                <div
                  className="notification-search-icon on-search"
                  onClick={this.showNotificationSearch}
                  style={{ paddingLeft: "0" }}
                >
                  <img src={searchIcon} alt="search-icon" />
                </div>
              </React-Fragment>
            )}
            <OutsideClickHandler onOutsideClick={this.dontShowDropdown}>
              <div
                className="notifi-options-holder"
                id="notifi-options-holder"
                onClick={this.toggleDropdown}
              >
                <img src={dots} alt="more-option" />
                {this.state.showDropdown && (
                  <div
                    className="notifi-options-ddown-holder"
                    id="notifi-options-ddown-holder"
                  >
                    <div
                      className="notifi-options-ddown-option"
                      onClick={this.markAllAsRead}
                    >
                      Mark all as read
                    </div>
                    <div
                      className="notifi-options-ddown-option"
                      onClick={e =>
                        this.changeDropdownOption(e.target.innerText)
                      }
                    >
                      {this.state.ddownOption}
                    </div>
                    <div
                      className="notifi-options-ddown-option"
                      onClick={e => {
                        let selected = e.target.innerText === "View all" ? "View unread" : e.target.innerText
                        this.changeDropdownOption2(selected)
                      }}
                    >
                      {this.state.ddownOption2 === "View unread" ? "View all" : this.state.ddownOption2}
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            {this.state.showNotificationSearch && (
              <NotificationSearch
                onCloseSearch={this.onCloseSearch}
                searchNotification={this.searchNotification}
              />
            )}
            <ul className="tabs list-inline">
              {/* $$ Should toggle between the li items when clicked on */}
              <li className={this.state.option === "you" ? "active" : ""}>
                <span value="you" onClick={e => this.changeOption(e)}>
                  You
                </span>
              </li>
              <li className={this.state.option === "all" ? "active" : ""}>
                <span value="all" onClick={e => this.changeOption(e)}>
                  All
                </span>
              </li>
            </ul>
          </div>
          <Notification
            key={"notification-box"}
            globalNotification={this.props.globalNotification}
            optionValue={this.state.option}
            ddownOption={this.state.ddownOption}
            ddownOption2={this.state.ddownOption2}
            allNotifications={sortedFlaggedNotificationOfAllTab}
            yourNotification={sortedFlaggedNotificationOfYouTab}
            controlDisplayNotification={this.props.controlDisplayNotification}
            controlDisplayDialogWrapper={this.props.controlDisplayDialogWrapper}
            closeDisplayDialogWrapper={this.props.closeDisplayDialogWrapper}
            getClickedCommentId={this.props.getClickedCommentId}
            getClickedAttachmentId={this.props.getClickedAttachmentId}
            getChecklistId={this.props.getChecklistId}
            getPageNo={this.getPageNo}
            updatePageNo={this.updatePageNo}
            pageNoOfAllTab={this.state.pageNoOfAllTab}
            pageNoOfYouTab={this.state.pageNoOfYouTab}
            searchNotification={this.searchNotification}
            searchText={this.state.searchText}
            currentWorkspace={currentWorkspace}
            router={router}
          />
        </div>
      </OutsideClickHandler>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNotifyComponent);
