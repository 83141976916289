export const reports = {
  date: "DATE",
  duration: "DURATION",
  project: "PROJECT",
  activityType: "ACTIVITY_TYPE",
  member: "MEMBER",
  ascending: "ASCENDING",
  descending: "DESCENDING",
  timeSheetListLoaderState: "TIME_SHEET_LIST_LOADER_STATE",
  timeSheetList: "TIME_SHEET_LIST",
  filterTimeSheet: "FILTER_TIME_SHEET",
  sortTimeSheet: "SORT_TIME_SHEET",
  updateTimeSheetLoaderState: "UPDATE_TIME_SHEET_LOADER_STATE",
  deleteTimeSheet: "DELETE_TIME_SHEET",
  editTimeSheet: "EDIT_TIME_SHEET",
  updateActivityReport: "UPDATE_ACTIVITY_REPORT",
  breadCrumb: [
    { name: "Report", link: "", type: "heading" },
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon report-search",
      SearchClassName: "general-page-search-box-parent"
    }
  ],
  FETCH_ALL_ACTIVITY_TYPE:"FETCH_ALL_ACTIVITY_TYPE"

};
