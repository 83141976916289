/***
 * Author: Madhusudhan Seetharamiah
 */
import React, { Component } from "react";
import { Form, Control } from "react-redux-form";
import routesHelper from "../../router/routeshelper";
import { connect } from "react-redux";
import * as actions from "../../actions/index";
import { resendVerificationEmail } from "../../services/registrationapi";
import { getCustomerDetails } from "../../services/workspaceapi";
import { getAllCustomerForUserAction } from "../../actions/workspace/workspaceaction";
import { withRouter } from "react-router";
import GoogleSignOn from "./googlesignon";
import AppleSignOn from "./applesignon";
import DialogWrapper from "../common/dialog";
import EmailAlreadyRegisteredPopup from "./emailAlreadyRegisteredPop";
import { isEmailValid } from "../../helpers/generichelper";
import { message } from "../../constants/messages";
import { isGoogleSignIn, isAppleSignIn } from "../../helpers/loginconstants";
import { GOOGLE_KEY, APPLE_CLIENT_ID } from "../../constants/globalconst";
import MaterialButton from "../common/materialbutton";
import firebase from "../../firebase";
import { updateUserFirstTimeFlagAction } from "../../actions/common/commonactions";


const mapStateToProps = state => {
  return {
    userDetails: state.rrfStatereducer.login,
    auth_token: state.loginReducer.loginAuth.auth_token,
    loginActionState: state.loginReducer.loginActionState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticateUserAction({ email, password }, callback) {
      dispatch(
        actions.authenticateUser(
          {
            email,
            password
          },
          callback
        )
      );
    },
    googleAuthenticationAction({ code, clientId, redirectUri, access_type }, callback) {
      dispatch(
        actions.googleAuthentication(
          {
            code,
            clientId,
            redirectUri,
            access_type
          },
          callback
        )
      );
    },
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey
          },
          callback
        )
      );
    }
  };
};

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      notValidForm: true,
      showPassword: false,
      showAlreadyEmailPopup: false
    };
  }

  componentDidMount() {
    const { router } = this.props;
    if (router.location.query.client && 
      router.location.query.message === "This email is already registered") {
      this.setState({ showAlreadyEmailPopup: true });
    }
  }

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onForgotPassword = () => {
    firebase.analytics().logEvent("GTD_Forgot_password");
    const { router } = this.props;
    router.push(routesHelper.getForgotPasswordPath());
  };

  isValidData = () => {
    const { userDetails } = this.props;
    let isValidBoolList = [];
    for (const key in userDetails) {
      if (userDetails.hasOwnProperty(key)) {
        const element = userDetails[key];
        if (!element) {
          isValidBoolList.push(false);
        } else if (key === "email") {
          if (element.length <= 0) {
            isValidBoolList.push(false);
          }
        }
      }
    }

    if (isValidBoolList.indexOf(false) > -1) {
      this.setState({ notValidForm: true });
      return false;
    }

    this.setState({ notValidForm: false });
    return true;
  };

  onFormChange = () => {
    this.isValidData();
  };

  onInputKeyUp = e => {
    if(this.state.email === message.emailNotVerified) this.setState({ email: null });
    if (e.key === "Enter") this.onBasicLogin();
  };

  onBasicLogin = () => {
    firebase.analytics().logEvent("GTD_Custom_login");
    const { authenticateUserAction, getAllCustomersAction, userDetails, router } = this.props;
    let noOfErrors = [];
    for (const key in userDetails) {
      if (userDetails.hasOwnProperty(key)) {
        const element = userDetails[key];
        if (key === "email") {
          if (element.length < 1) {
            noOfErrors.push(false);
            this.setState({ [key]: message.filedShouldNotBeEmpty });
          } else if (!isEmailValid(element)) {
            noOfErrors.push(false);
            this.setState({
              [key]: message.emailNotValid
            });
          } else {
            this.setState({
              [key]: null
            });
          }
        } else {
          if (element.length < 1) {
            noOfErrors.push(false);
            this.setState({ [key]: message.filedShouldNotBeEmpty });
            // } else if (element.length < 6) {
            //   noOfErrors.push(false);
            //   this.setState({
            //     [key]: message.shortPassword
            //   });
          } else {
            this.setState({
              [key]: null
            });
          }
        }
      }
    }
    if (noOfErrors.length === 0) {
      authenticateUserAction(userDetails, response => {
        if (response && response.auth_token) {
          firebase.analytics().logEvent("GTD_Custom_login_success");
          for (const key in response) {
            if (response.hasOwnProperty(key)) {
              const element = response[key];
              if (key === "data") {
                localStorage.setItem(key, JSON.stringify(element));
              } else if(key === "auth_token") {
                for (const k in response.auth_token) {
                  const ele = response.auth_token[k];
                  localStorage.setItem(k, ele);
                }
              } else {
                localStorage.setItem(key, element);
              }
            }
          }
        
          const auth_token = response.auth_token.token;
          localStorage.setItem(isGoogleSignIn, false);
          getAllCustomersAction({ auth_token }, workspaces => {
            if (workspaces && !workspaces.stdErr) {
              if (workspaces.length > 0) {
                localStorage.setItem("hasAnyWorkspace", true);
                if(response.first_time_logged_in){
                  updateUserFirstTimeFlagAction({auth_token:auth_token,id:response.id,first_time_logged_in:false})
                  localStorage.setItem("firsttimeprofile", true);
                  return router.push(routesHelper.welcomePath());
                }else{
                  return router.push(routesHelper.getRootPath());
                } 
              }
              localStorage.setItem("hasAnyWorkspace", false);
              localStorage.setItem("firsttimeprofile", true);
              updateUserFirstTimeFlagAction({auth_token:auth_token,id:response.id,first_time_logged_in:false})
              return router.push("/finish-profile");
            }
          });
        } else if (response.error || response.stdErr) {
          firebase.analytics().logEvent("GTD_Custom_login_error", {
            error: response.error || response.stdErr
          });
          if(response && response.error && response.error === "Please complete your email verification.") {
            this.setState({ email: message.emailNotVerified })
          } else {
            this.setState({
              password: message.passwordMissMatch
            });
          }
        }
      });
    }
  };

  onGoogleSignIn = ({ payload = {} }) => {
    firebase.analytics().logEvent("GTD_Google_login");
    const { googleAuthenticationAction, getAllCustomersAction, router } = this.props;

    if (payload.accessToken) {
      const body = {
        code: payload.accessToken,
        clientId: GOOGLE_KEY,
        redirectUri: routesHelper.getBaseUrl()
      };
      if (payload.access_type) body.access_type = payload.access_type;
      googleAuthenticationAction(
        { ...body },
        resposne => {
          if (resposne && resposne.id) {
            if(resposne.first_time_logged_in) firebase.analytics().logEvent("GTD_Google_register_success");
            else firebase.analytics().logEvent("GTD_Google_login_success");
            for (const key in resposne) {
              if (resposne.hasOwnProperty(key)) {
                const element = resposne[key];
                if (key === "data") {
                  localStorage.setItem(key, JSON.stringify(element));
                } else if(key === "auth_token") {
                  for (const k in resposne.auth_token) {
                    const ele = resposne.auth_token[k];
                    localStorage.setItem(k, ele);
                  }
                } else {
                  localStorage.setItem(key, element);
                }
              }
            }
            localStorage.setItem(isGoogleSignIn, true);
            const auth_token = resposne.auth_token;
            getAllCustomersAction({ auth_token }, workspaces => {
              if (workspaces && !workspaces.stdErr) {
                if (workspaces.length > 0) {
                  localStorage.setItem("hasAnyWorkspace", true);
                  if(resposne.first_time_logged_in){
                    updateUserFirstTimeFlagAction({auth_token:auth_token,id:resposne.id,first_time_logged_in:false})
                    localStorage.setItem("firsttimeprofile", true);
                    return router.push(routesHelper.welcomePath());
                  }else{
                    return router.push(routesHelper.getRootPath());
                  } 
                }
                localStorage.setItem("hasAnyWorkspace", false);
                localStorage.setItem("firsttimeprofile", true);
                updateUserFirstTimeFlagAction({auth_token:auth_token,id:resposne.id,first_time_logged_in:false});
                return router.push("/finish-profile");
              }
            });
          }
        }
      );
    }
  };

  onAppleSignIn = ({ payload = {} }) => {
    firebase.analytics().logEvent("GTD_Apple_login");
    const { googleAuthenticationAction, router } = this.props;
    payload.code &&
      googleAuthenticationAction(
        {
          code: payload.code,
          clientId: APPLE_CLIENT_ID,
          redirectUri: routesHelper.getBaseUrl()
        },
        resposne => {
          if (resposne && resposne.id) {
            for (const key in resposne) {
              if (resposne.hasOwnProperty(key)) {
                const element = resposne[key];
                if (key === "data") {
                  localStorage.setItem(key, JSON.stringify(element));
                } else {
                  localStorage.setItem(key, element);
                }
              }
            }
            localStorage.setItem(isAppleSignIn, true);
            const auth_token = resposne.auth_token;
            getCustomerDetails({ auth_token }, workspaces => {
              if (workspaces && !workspaces.stdErr) {
                if (workspaces.length > 0) {
                  localStorage.setItem("hasAnyWorkspace", true);
                  if(resposne.first_time_logged_in){
                    updateUserFirstTimeFlagAction({auth_token:auth_token,id:resposne.id,first_time_logged_in:false})
                    localStorage.setItem("firsttimeprofile", true);
                    return router.push(routesHelper.welcomePath());
                  }else{
                    return router.push(routesHelper.getRootPath());
                  } 
                }
                localStorage.setItem("firsttimeprofile", true);
                updateUserFirstTimeFlagAction({auth_token:auth_token,id:resposne.id,first_time_logged_in:false})
                localStorage.setItem("hasAnyWorkspace", false);
                return router.push("/finish-profile");
              }
            });
          }
        }
      );
  };

  onGoogleSignInSuccess = resposne => {
    if (resposne && resposne.accessToken) {
      
      this.onGoogleSignIn({ payload: resposne });
    }
  };

  onGoogleSignInFailure = _resposne => { /* TODO document why this method 'onGoogleSignInFailure' is empty */ };

  onAppleSignInSuccess = response => {
    if (response && response.code) {
      firebase.analytics().logEvent("GTD_Apple_login_success");
      console.log("Code from Apple", response);
      this.onAppleSignIn({ payload: response });
    }
  };

  onRegister = () => {
    const { router } = this.props;
    router.push(routesHelper.getRegistrationPath());
  };

  sendVerificationEmail = () => {
    const { userDetails, setShowSuccess, setShowFadeOut } = this.props;
    if (userDetails.email.length < 1) {
      this.setState({ email: message.filedShouldNotBeEmpty });
    } else if (!isEmailValid(userDetails.email)) {
      this.setState({
        email: message.emailNotValid
      });
    } else {
      
      resendVerificationEmail({ email: userDetails.email }, (res) => {
        if (res && res.detail) {
          setShowSuccess(true);
          setTimeout(() => setShowFadeOut(true), 5000);
          setTimeout(() => {
            setShowSuccess(false);
            setShowFadeOut(false);
          }, 6000);
        }
      });
    }
  };

  onCloseAlreadyEmailPopup = () => {
    this.setState({ showAlreadyEmailPopup: false });
  }

  render() {
    const { showPassword } = this.state;
    
    let dialogPropsEmailAlreadyRegistered = {
      className: "dialog_wrapper",
      showPopup: this.state.showAlreadyEmailPopup,
      dialogTitle: () => "Email already registered",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true
    };
    return (
      <div className="signIn">
        <Form
          className="login-form"
          name="userForm"
          onUpdate={this.onFormChange}
          model="rrfState.login"
        >
          <div className="login-controls-container">
            <div className="login-control-holder">
              <Control.text
                type="text"
                placeholder="Email"
                model=".email"
                id="sample"
                name="email"
                className="common-input-ctrl"
                onKeyUp={this.onInputKeyUp}
                autoFocus
              />
              {this.state.email && (
                <p className="common-err-msg">
                  {this.state.email}
                  {this.state.email === message.emailNotVerified && (
                      <React.Fragment>
                        {" "}<span onClick={this.sendVerificationEmail}>Click here</span> to verify.
                      </React.Fragment>
                    )}
                </p>
              )}
            </div>
            <div className="login-control-holder view-password-holder">
              <Control.text
                type={showPassword ? "text" : "password"}
                placeholder="Your password"
                className="common-input-ctrl"
                model=".password"
                name="password"
                onKeyUp={this.onInputKeyUp}
              />
              <div className="password-viewer">
                <div
                  className={showPassword ? "eye-seen" : "eye-not-seen"}
                  onClick={this.toggleShowPassword}
                ></div>
              </div>
              {this.state.password && (
                <p className="common-err-msg">{this.state.password}</p>
              )}
            </div>
          </div>
          <a className="forgot-password-login" onClick={this.onForgotPassword}>
            {" "}
            Forgot password?
          </a>
          <div className="log-log">
            <MaterialButton
              buttonText="Login"
              variant="contained"
              className="signin"
              color="primary"
              onClick={this.onBasicLogin}
            ></MaterialButton>
          </div>
          <div className="or-container">
            <div className="or-hr"></div>
            <div className="or-register">or</div>
            <div className="or-hr"></div>
          </div>
          <GoogleSignOn
            onGoogleSignInSuccess={this.onGoogleSignInSuccess}
            onGoogleSignInFailure={this.onGoogleSignInFailure}
          />
          <AppleSignOn onAppleSignInSuccess={this.onAppleSignInSuccess} />
          <div className="log-reg">
            <p className="for-pass">New to Get Things Done? </p>
            <a className="for-register" onClick={this.onRegister}>
              Register
            </a>
          </div>
        </Form>
        {this.state.showAlreadyEmailPopup && (
          <DialogWrapper dialogProps={dialogPropsEmailAlreadyRegistered}>
            <EmailAlreadyRegisteredPopup onClosePopup={this.onCloseAlreadyEmailPopup} />
          </DialogWrapper>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
