export const API_URL = process.env.REACT_APP_API_URL;

// export const API_URL = "http://staging.getthingsdone.io:9091";

export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;

// export const APPLE_CLIENT_ID = "0d9ac0d5ba29";

// export const APPLE_REDIRECT_URI = "https://ef31331e8dfc.ngrok.io/api/v1/apple/callback";

export const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const PAGE_SIZE = 5;

export const ONE_DRIVE_APP_KEY= process.env.REACT_APP_ONE_DRIVE_API_KEY

export const ONE_DRIVE_REPLAY_URL= process.env.REACT_APP_ONE_DRIVE_REPLAY_URL

