import React, { Fragment, PureComponent } from "react";
import _ from "lodash";
import moment from "moment";
import { getDefaultClientLogo } from "../../helpers/generichelper";
import addLogo from "../../styles/img/svg/Add_Logo_Icon.svg";
import LogoUploader from "../common/logoUploader";
import minus from "../../styles/img/svg/settings-minus.svg";
import plus from "../../styles/img/svg/settings-plus.svg";
// import ChipInput from "material-ui-chip-input";
import DaysField from "./daysField";
import Scrollbar from "react-scrollbars-custom";
import "../../styles/scss/workspaceSettings.scss";
import NotSubscribed from "./notSubscribed";
// import { getListOfCurrencies } from "../../services/workspaceapi";
import { ToastContainer, Slide, toast } from "react-toastify";
import CurrencyDropDown from "./currencyDropdown";
import OutsideClickHandler from "react-outside-click-handler";
import { Loader } from "../common/loader";
import InputChips from "./inputChips";
import firebase from "../../firebase";

export default class WorkspaceSettings extends PureComponent {
  state = {
    workspaceToEdit: {},
    formField: {
      workspaceName: "",
      workspaceImg: "",
      imgName: "",
      weekly_working_days: {
        is_friday: false,
        is_monday: false,
        is_saturday: false,
        is_sunday: false,
        is_thursday: false,
        is_tuesday: false,
        is_wednesday: false,
      },
      daily_working_hours: 0,
      teams: [],
      job_titles: [],
      engagement_types: [],
      currency: "",
    },
    successMessage: null,
    showCurrencyDropdown: false,
    is_restricted: false,
    allowed_days: 0
    // listOfCurrencies: []
  };

  allowedDaysInput = React.createRef();

  // componentWillMount() {
  //   getListOfCurrencies({}, res => {
  //     if (res && !res.stdErr) this.setState({ listOfCurrencies: res });
  //   });
  // }

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Workspace_Visited");
    const { currentWorkSpace } = this.props;
    // const currentUserId = localStorage.getItem("id");
    if (currentWorkSpace) {
      const workspaceToEdit = currentWorkSpace;
      if (workspaceToEdit) {
        // getListOfCurrencies({}, res => {
        //   if (res && !res.stdErr) {
        let indexOfId =
          workspaceToEdit.display_name &&
          workspaceToEdit.display_name.indexOf(workspaceToEdit.id);
        let displayName =
          indexOfId && indexOfId > -1
            ? workspaceToEdit.display_name.substring(0, indexOfId - 1)
            : workspaceToEdit.display_name;
        this.setState({
          workspaceToEdit,
          // listOfCurrencies: _.sortBy(res, (currency) => currency.code.toLowerCase()),
          formField: {
            workspaceName: displayName || "",
            workspaceImg:
              workspaceToEdit.img &&
              workspaceToEdit.img !==
                "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
              workspaceToEdit.img !== getDefaultClientLogo()
                ? workspaceToEdit.img
                : "",
            imgName: workspaceToEdit.img_name,
            weekly_working_days: (workspaceToEdit.data.settings &&
              workspaceToEdit.data.settings.weekly_working_days) || {
              is_friday: true,
              is_monday: true,
              is_saturday: false,
              is_sunday: false,
              is_thursday: true,
              is_tuesday: true,
              is_wednesday: true,
            },
            daily_working_hours:
              (workspaceToEdit.data.settings &&
                workspaceToEdit.data.settings.daily_working_hours) ||
              8,
            teams: (workspaceToEdit.data.settings &&
              _.sortBy(workspaceToEdit.data.settings.teams)) || [
              "Design",
              "Development",
              "HR",
            ],
            engagement_types: (workspaceToEdit.data.settings &&
              _.sortBy(workspaceToEdit.data.settings.engagement_types)) || [
              "Fixed",
              "Retailer",
              "T&M",
            ],
            // currency: (workspaceToEdit.data.settings &&
            //   workspaceToEdit.data.settings.currency &&
            //   this.props.listOfCurrencies.length > 0 &&
            //   this.props.listOfCurrencies.filter(d =>
            //     workspaceToEdit.data.settings.currency.includes(d.code)
            //   )[0]) || {
            //   code: "USD",
            //   symbol: "$"
            // },
            job_titles: (workspaceToEdit.data.settings &&
              _.sortBy(workspaceToEdit.data.settings.job_titles)) || [
              "Growth catalyst",
              "HR",
              "UX designer",
            ],
          },
          is_restricted: workspaceToEdit.data.past_timesheet_restricted || false,
          allowed_days: workspaceToEdit.data.allowed_days || 0
        });
        //   }
        // });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.listOfCurrencies.length > 0 && !state.formField.currency) {
      return {
        formField: {
          ...state.formField,
          currency: props.listOfCurrencies.filter(
            d =>
              props.currentWorkSpace.data.settings &&
              props.currentWorkSpace.data.settings.currency.includes(d.code)
          )[0] || {
            code: "USD",
            symbol: "$",
          },
        },
      };
    }
    return null;
  }

  updateLogo = (clientLogo, imgName) => {
    firebase.analytics().logEvent("GTD_Workspace_Image_Added");
    this.setState({
      formField: {
        ...this.state.formField,
        workspaceImg: clientLogo,
        imgName,
      },
    });
  };

  handleOnChange = e => {
    // console.log("event fired", e);
    firebase.analytics().logEvent("GTD_Workspace_Name_Changed");
    this.setState({
      formField: {
        ...this.state.formField,
        workspaceName: e.target.value,
      },
    });
  };

  incrementHours = () => {
    this.setState({
      formField: {
        ...this.state.formField,
        daily_working_hours:
          this.state.formField.daily_working_hours > 23.5
            ? this.state.formField.daily_working_hours
            : this.state.formField.daily_working_hours + 0.5,
      },
    });
  };

  decrementHours = () => {
    this.setState({
      formField: {
        ...this.state.formField,
        daily_working_hours:
          this.state.formField.daily_working_hours < 1.5
            ? this.state.formField.daily_working_hours
            : this.state.formField.daily_working_hours - 0.5,
      },
    });
  };

  handleAddATeams = ({ value, addButton, indexvalue }) => {
    if (addButton) {
      firebase.analytics().logEvent("GTD_Workspace_Teams_Added");
      firebase.analytics().logEvent("GTD_Workspace_Team_Name", { name: value });
      this.setState({
        formField: {
          ...this.state.formField,
          teams: [...this.state.formField.teams, value],
        },
      });
    } else {
      let teamCopy = [...this.state.formField.teams];
      teamCopy[indexvalue] = value;
      this.setState({
        formField: {
          ...this.state.formField,
          teams: teamCopy,
        },
      });
    }
  };

  handleDeleteTeams = (value, key, type) => {
    this.setState({
      formField: {
        ...this.state.formField,
        teams: this.state.formField.teams.filter((el, ind) => ind !== key),
      },
    });
  };

  handleAddEnagementTypes = ({ value, addButton, indexvalue }) => {
    if (addButton) {
      firebase
        .analytics()
        .logEvent("GTD_Workspace_Engagement_Name", { name: value });
      this.setState({
        formField: {
          ...this.state.formField,
          engagement_types: [...this.state.formField.engagement_types, value],
        },
      });
    } else {
      let engagementCopy = [...this.state.formField.engagement_types];
      engagementCopy[indexvalue] = value;
      this.setState({
        formField: {
          ...this.state.formField,
          engagement_types: engagementCopy,
        },
      });
    }
  };

  handleDeleteEnagementTypes = (value, key, type) => {
    this.setState({
      formField: {
        ...this.state.formField,
        engagement_types: this.state.formField.engagement_types.filter(
          (el, ind) => ind !== key
        ),
      },
    });
  };

  handleAddJobTitles = ({ value, addButton, indexvalue }) => {
    if (addButton) {
      firebase.analytics().logEvent("GTD_Workspace_Jobs_Added");
      firebase
        .analytics()
        .logEvent("GTD_Workspace_Job_Title", { title: value });
      this.setState({
        formField: {
          ...this.state.formField,
          job_titles: [...this.state.formField.job_titles, value],
        },
      });
    } else {
      let jobTitles = [...this.state.formField.job_titles];
      jobTitles[indexvalue] = value;
      this.setState({
        formField: {
          ...this.state.formField,
          job_titles: jobTitles,
        },
      });
    }
  };

  handleDeleteJobTitles = (value, key, type) => {
    this.setState({
      formField: {
        ...this.state.formField,
        job_titles: this.state.formField.job_titles.filter(
          (el, ind) => ind !== key
        ),
      },
    });
  };

  ondaysChangeClick = (index, value) => {
    this.setState({
      formField: {
        ...this.state.formField,
        weekly_working_days: {
          ...this.state.formField.weekly_working_days,
          [index]: value,
        },
      },
    });
  };

  onShowCurrencyDropdown = () => {
    this.setState({ showCurrencyDropdown: !this.state.showCurrencyDropdown });
  };

  onOutsideClickCurrency = () => {
    this.setState({ showCurrencyDropdown: false });
  };

  onSelectCurrency = currency => {
    firebase
      .analytics()
      .logEvent("GTD_Workspace_Currency", { name: currency.code });
    this.setState({
      formField: {
        ...this.state.formField,
        currency,
      },
      showCurrencyDropdown: false,
    });
  };

  onFormValidate = () => {
    const { formField, is_restricted, allowed_days } = this.state;
    const workspaceName = formField.workspaceName ? true : false;
    const weekly_working_days = Object.values(
      formField.weekly_working_days
    ).filter(value => value).length;
    const daily_working_hours = formField.daily_working_hours;
    const timesheetRestriction = is_restricted ? allowed_days ? true : false : true;
    return workspaceName && weekly_working_days && daily_working_hours && timesheetRestriction;
  };

  onUpdateWorkspace = () => {
    const {
      updateCustomerDetailsAction,
      currentWorkSpace,
      getWorkspaceUsers,
      getProjectsAction,
    } = this.props;
    const auth_token = currentWorkSpace.auth_token;
    const data = {
      ...this.state.workspaceToEdit.data,
      past_timesheet_restricted: this.state.is_restricted,
      allowed_days: this.state.allowed_days,
      settings: {
        weekly_working_days: this.state.formField.weekly_working_days,
        daily_working_hours: this.state.formField.daily_working_hours,
        teams: _.sortBy(this.state.formField.teams, team => team.toLowerCase()),
        engagement_types: _.sortBy(
          this.state.formField.engagement_types,
          engagement_type => engagement_type.toLowerCase()
        ),
        currency:
          this.state.formField.currency.code +
          "(" +
          this.state.formField.currency.symbol +
          ")",
        job_titles: _.sortBy(this.state.formField.job_titles, job_title =>
          job_title.toLowerCase()
        ),
      },
    };
    updateCustomerDetailsAction(
      {
        auth_token,
        data,
        id: this.state.workspaceToEdit.id,
        display_name: this.state.formField.workspaceName,
        img: this.state.formField.workspaceImg,
        img_name: this.state.formField.imgName,
        super_admin_id: this.state.workspaceToEdit.super_admin_id,
        users: this.state.workspaceToEdit.users,
      },
      res => {
        if (res.msg === "Success") {
          toast("Changes saved successfully!");
          this.setState({
            formField: {
              ...this.state.formField,
              teams: _.sortBy(this.state.formField.teams, team =>
                team.toLowerCase()
              ),
              job_titles: _.sortBy(this.state.formField.job_titles, job_title =>
                job_title.toLowerCase()
              ),
              engagement_types: _.sortBy(
                this.state.formField.engagement_types,
                engagement_type => engagement_type.toLowerCase()
              ),
            },
          });
          getWorkspaceUsers({}, response => {
            if (response && !response.stdErr) {
            }
          });
          getProjectsAction(
            {
              auth_token,
              queryParam: `?archived=false`,
            },
            response => {}
          );
        }
      }
    );
  };

  validateTeam = value => {
    return this.state.formField.teams
      ? this.state.formField.teams.includes(value)
      : false;
  };

  validateJobTitle = value => {
    return this.state.formField.job_titles
      ? this.state.formField.job_titles.includes(value)
      : false;
  };

  validateEngagementTypes = value => {
    return this.state.formField.engagement_types
      ? this.state.formField.engagement_types.includes(value)
      : false;
  };

  switchRestriction = () => {
    this.setState({
      is_restricted: !this.state.is_restricted,
    }, () => {
      if(!this.state.is_restricted)
        this.setState({ 
          allowed_days: this.props.currentWorkSpace.data.allowed_days || 0
        })
      else
        this.allowedDaysInput.current.focus();
    });
  };

  onChangeAllowedDays = (e) => {
    const value = parseInt(e.target.value);
    const re = /^[0-9]*$/;
    if(re.test(value)) {
      let maxDays = 365;
      let daysForWp = value < maxDays ? value : maxDays;
      this.setState({ allowed_days: daysForWp || 0 });
    } else {
      this.setState({ allowed_days: 0 });
    }
  }

  render() {
    const {
      currentWorkSpace,
      showNoSubscriptionWarning,
      onTabClick,
      updateCustomerLoaderState,
    } = this.props;
    const { formField } = this.state;

    let teamType = null;
    teamType = formField.teams.map((value, index) => {
      return (
        <InputChips
          readOnly={true}
          classname="read-only"
          value={value}
          indexvalue={index}
          deleteHandler={this.handleDeleteTeams}
          type="activity_type"
          key={value + "team" + index}
          addButton={false}
          handleAdd={this.handleAddATeams}
          validate={this.validateTeam}
        />
      );
    });
    teamType.push(
      <InputChips
        validate={this.validateTeam}
        deleteHandler={this.handleDeleteTeams}
        readOnly={true}
        handleAdd={this.handleAddATeams}
        classname="read-only"
        addButton={true}
        key="+ teamAdd"
        type="activity_type"
        value="+ Add"
      />
    );

    let jobTitles = null;
    jobTitles = formField.job_titles.map((value, index) => {
      return (
        <InputChips
          readOnly={true}
          classname="read-only"
          value={value}
          indexvalue={index}
          deleteHandler={this.handleDeleteJobTitles}
          type="activity_type"
          key={value + "jobTitle" + index}
          addButton={false}
          handleAdd={this.handleAddJobTitles}
          validate={this.validateJobTitle}
        />
      );
    });
    jobTitles.push(
      <InputChips
        validate={this.validateJobTitle}
        deleteHandler={this.handleDeleteJobTitles}
        readOnly={true}
        handleAdd={this.handleAddJobTitles}
        classname="read-only"
        addButton={true}
        key="+ jobAdd"
        type="activity_type"
        value="+ Add"
      />
    );

    let engaementTypes = null;
    engaementTypes = formField.engagement_types.map((value, index) => {
      return (
        <InputChips
          readOnly={true}
          classname="read-only"
          value={value}
          indexvalue={index}
          deleteHandler={this.handleDeleteEnagementTypes}
          type="activity_type"
          key={value + "eng" + index}
          addButton={false}
          handleAdd={this.handleAddEnagementTypes}
          validate={this.validateEngagementTypes}
        />
      );
    });
    engaementTypes.push(
      <InputChips
        validate={this.validateEngagementTypes}
        deleteHandler={this.handleDeleteEnagementTypes}
        readOnly={true}
        handleAdd={this.handleAddEnagementTypes}
        classname="read-only"
        addButton={true}
        key="+ engAdd"
        type="activity_type"
        value="+ Add"
      />
    );

    return (
      <div className="ppl-settings-control-outer-holder workspace-setting-holder-container">
        <Loader size={100} loading={updateCustomerLoaderState.fetching} />
        <ToastContainer autoClose={2000} transition={Slide} />
        <Scrollbar>
          <div className="ppl-settings-control-holder">
            <div className="cus-autocomp-holders wp-create-date">
              <div className="ppl-settings-control-title">Created date</div>
              {moment(currentWorkSpace.created_at).format("ddd, DD MMM YY")}
            </div>
          </div>
          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">
              <div className="img-container project-settings-img workspace-settings-edit-user-img">
                <LogoUploader
                  image={formField.workspaceImg || addLogo}
                  update={formField.workspaceImg ? true : false}
                  updateLogo={this.updateLogo}
                  left="0"
                />
              </div>
            </div>
            <div className="ppl-settings-indv-control-holder workspace-settings-edit-user-name">
              <input
                className="settings-input-area"
                autoComplete="off"
                type="text"
                value={this.state.formField.workspaceName}
                onChange={this.handleOnChange}
                name="name"
                placeholder="Workspace name"
              />
            </div>
          </div>
          <div className="ppl-settings-control-holder">
            <div className="cus-autocomp-holders">
              <div className="ppl-settings-control-title">
                Weekly working days
              </div>
              <DaysField
                weekDays={formField.weekly_working_days}
                onOptionsClick={this.ondaysChangeClick}
              ></DaysField>
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">
              Daily working hours
            </div>
            <div className="ppl-settings-indv-control-holder">
              <div className="ppl-settings-billing-control-holder">
                <div className="stepper-wrapper minus-plus-settings">
                  <span
                    className="minus"
                    value="{this.state.dailyWorkingHours}"
                    onClick={this.decrementHours}
                  >
                    <img src={minus} alt="decrease" />
                  </span>
                  <span
                    className="day-hours-settings"
                    // style={{
                    //   position: "absolute",
                    //   top: "5px",
                    //   marginLeft: "20px"
                    // }}
                  >
                    {formField.daily_working_hours}
                  </span>
                  <span
                    className="plus"
                    value="{this.state.dailyWorkingHours}"
                    onClick={this.incrementHours}
                  >
                    <img src={plus} alt="increase" />
                  </span>
                </div>
                <div
                  className="ppl-settings-error-msg"
                  style={{ display: "none" }}
                >
                  Please enter daily working hours
                </div>
              </div>
            </div>
          </div>

          <div className="ppl-settings-control-holder no-icon">
            <div className="ppl-settings-control-title">Teams</div>
            <div className="ppl-settings-indv-control-holder  add-team-project">
              {/* <ChipInput
                value={formField.teams}
                onAdd={chip => this.handleAddATeams(chip)}
                onDelete={(chip, index) => this.handleDeleteTeams(chip, index)}
                className="chip-input"
              /> */}
              <div className="input-chips">{teamType}</div>
            </div>
          </div>

          <div className="ppl-settings-control-holder no-icon kanban-project-settings">
            <div className="ppl-settings-control-title ">Job titles</div>
            <div className="ppl-settings-indv-control-holder  add-team-project">
              {/* <ChipInput
                value={formField.job_titles}
                onAdd={chip => this.handleAddJobTitles(chip)}
                onDelete={(chip, index) =>
                  this.handleDeleteJobTitles(chip, index)
                }
                className="chip-input"
              /> */}

              <div className="input-chips">{jobTitles}</div>
            </div>
          </div>

          <div className="ppl-settings-control-holder no-icon kanban-project-settings">
            <div className="ppl-settings-control-title ">Engagement type</div>
            <div className="ppl-settings-indv-control-holder  add-team-project">
              {/* <ChipInput
                value={formField.engagement_types}
                onAdd={chip => this.handleAddEnagementTypes(chip)}
                onDelete={(chip, index) =>
                  this.handleDeleteEnagementTypes(chip, index)
                }
                className="chip-input"
              /> */}
              <div className="input-chips">{engaementTypes}</div>
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="cus-autocomp-holders">
              <div className="ppl-settings-control-title">Currency</div>
              <div
                className="ppl-settings-indv-control-holder wsp-ddown-holder"
                onFocus={() => {}}
                // onBlur={this.onBlurGroup}
              >
                <OutsideClickHandler
                  onOutsideClick={this.onOutsideClickCurrency}
                >
                  <div
                    onClick={this.onShowCurrencyDropdown}
                    onChange={e => {}}
                    name="currency"
                    className="ppl-settings-ddown-holder currency"
                    placeholder="Select"
                  >
                    {formField.currency && formField.currency.code} -{" "}
                    {formField.currency && formField.currency.symbol}
                  </div>
                  {this.state.showCurrencyDropdown &&
                    this.props.listOfCurrencies.length > 0 && (
                      <CurrencyDropDown
                        onSelectCurrency={this.onSelectCurrency}
                        listOfCurrencies={this.props.listOfCurrencies}
                      />
                    )}
                </OutsideClickHandler>
                {/* {this.state.showGroupDropDown &&
                this.state.filteredGroupList.length > 0 && (
                  <GroupDropDown
                    groupList={this.state.filteredGroupList}
                    onSelectGroup={this.onSelectGroup}
                    onShowGroupDropDown={this.onShowGroupDropDown}
                  />
                )} */}
              </div>
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title wp-restrict-ts">Completed Activity Timesheet Duration Entry</div>
              <div className="ppl-settings-indv-control-holder wp-ts-switch">
                <div className="ppl-settings-switch-holder">
                  <div
                    className={
                      this.state.is_restricted
                        ? "ppl-settings-switch on"
                        : "ppl-settings-switch off"
                    }
                    onClick={this.switchRestriction}
                  ></div>
                </div>
              </div>
              <div className="restrict-txt">{this.state.is_restricted ? "Restricted to past" : "No restrictions"}</div>
              {this.state.is_restricted &&
                <Fragment>
                  <div className="restricted-days-input-box">
                    <input
                      ref={this.allowedDaysInput}
                      type="number"
                      value={this.state.allowed_days > 0 ? this.state.allowed_days : ""}
                      onKeyPress={(e) => {
                        if (
                          e.which === 46 ||
                          e.which === 45 ||
                          e.which === 101 ||
                          e.which === 43
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={this.onChangeAllowedDays}
                    />
                  </div>
                  <span className="days-txt" style={{ marginLeft: "10px" }}>days</span>
                </Fragment>}
          </div>

          <div className="button-div workspace-settings">
            <button
              disabled={!this.onFormValidate()}
              className="settings-btn proj-settings-save"
              onClick={this.onUpdateWorkspace}
            >
              Save
            </button>
            {/* {this.state.successMessage && (
              <p className="changes-saved workspacesettings-success-message">
                <span>{this.state.successMessage}</span>
              </p>
            )} */}
          </div>
        </Scrollbar>
        {Object.keys(currentWorkSpace.users).length > 5 && (
          <NotSubscribed
            showNoSubscriptionWarning={showNoSubscriptionWarning}
            onTabClick={onTabClick}
          ></NotSubscribed>
        )}
      </div>
    );
  }
}
