import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ChecklistDetail from "./checklistdetail.jsx";
import ChecklistPaginationHelper from "./checklistPaginationHelper";

import {
  createChecklistAction,
  createChecklistItemAction,
  updateChecklistItemAction,
  updateChecklistAction,
  getChecklistAction,
  getChecklistAfterRearrangeAction,
  updateChecklistCountAction,
} from "../../actions/checklist/checklist";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import { changeNoItemStatusAction } from "../../actions/notifications/notificationaction";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import "../../styles/scss/checklist.scss";
import ChecklistRearrangeMain from "./checklistRearrangeMain";

import { Loader } from "../common/loader.jsx";
import { getIsSuperAdmin } from "../../helpers/reportlanding.js";
import DragChecklist from "./dragChecklistItem";
import {
  MarkActivityReadAction,
  MarkKanbanActivityReadAction,
} from "../../actions/activities/activitiesaction";
import OutsideClickHandler from "react-outside-click-handler";
import activityDotsSVG from "../../styles/img/svg/activity_dots.svg";
import { pasteChecklist } from "../../services/checklist.js";

class Checklist extends PureComponent {
  state = {
    rearrangeChecklist: false,
    noItem: false,
    arrangedChecklists: [],
    movedToDiffList: null,
    movedChecklist: false,
    checkListItems: [],
    showOptionImageContainer: false,
    pasteErrMsg: false,
  };

  componentDidMount() {
    
    const activity_id = this.props.activityDetails.id;
    const queryParams = `activity_id=${activity_id}&is_archived=false`;
    this.props.getChecklistAction({ queryParams }, (response) => {
      if (
        this.props.activityDetails.active_checklist_count !== response.length
      ) {
        this.props.activityDetails.active_checklist_count = response.length;
        this.props.updateChecklistCount(
          activity_id,
          this.props.is_priority,
          response.length
        );
      }
    });
    const presentStatus =
      this.props.checklists &&
      this.props.checklists.filter(
        (checklist) => checklist.id === this.props.clickedChecklistId
      );
    let noItemFlag = false;
    
    if (this.props.checklists && presentStatus.length > 0) {
      const clickedItem = presentStatus[0].checklist_items.filter(
        (item) => !item.is_archived && item.id === this.props.checklistItemId
      );
      if (clickedItem.length === 0) {
        this.props.changeNoItemStatusAction({ showStatus: true });
        noItemFlag = true;
      }
    }

    this.setState({
      noItem: noItemFlag,
      checkListItems: this.props.checklists,
    });
  }

  // componentWillReceiveProps(props){
  //   if (props.searchText || props.selectedOption !== "All" || props.searchedChecklists.length > 0) {
  //     this.setState({
  //       checkListItems:props.searchedChecklists
  //     });
  //   } else
  //   this.setState({
  //     checkListItems:props.checklists
  //   });
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.searchText ||
      nextProps.selectedOption !== "All" ||
      nextProps.searchedChecklists.length > 0
    ) {
      if (!_.isEqual(nextProps.searchedChecklists, prevState.checkListItems))
        return {
          checkListItems: nextProps.searchedChecklists,
        };
    } else if (!_.isEqual(nextProps.checklists, prevState.checkListItems))
        return {
          checkListItems: _.uniqBy(nextProps.checklists, "id"),
        };
    else return null;
  }

  componentDidUpdate() {
    // console.log("checklist component did update", this.props.scrollHeight)
    if (
      this.props.scrollHeight > 40 &&
      this.props.activityDetails.is_new_checklist &&
      !this.props.archiveOrUnarchiveLoaderState.fetching
    ) {
      const body = {
        activity_ids: [this.props.activityDetails.id],
        type: "checklist",
      };
      if (this.props.source === "FROM_ACTIVE")
        this.props.MarkActivityReadAction({
          body,
          is_priority: this.props.is_priority,
        });
      if (this.props.source === "FROM_KANBAN")
        MarkKanbanActivityReadAction({
          body,
          is_priority: this.props.is_priority,
        });
    }
  }

  setChecklist = (checklist) => {
    this.setState({ checkListItems: checklist });
  };

  closePopup = () => {
    this.setState(
      {
        noItem: false,
      },
      () => {
        this.props.closeDisplayDialogWrapper();
      }
    );
  };

  onChecklistRearrangeDone = () => {
    const { updateChecklist } = this.props;
    this.setState({
      rearrangeChecklist: false,
    });
    this.state.arrangedChecklists.forEach((checklist) => {
      updateChecklist(checklist, () => {
        // TODO document why this arrow function is empty
      });
    });
  };

  onAddChecklistClicked = () => {
    const { activityDetails, is_archived, is_priority } = this.props;
    const checklist = {
      activity_id: activityDetails.id,
      created_by: parseInt(localStorage.getItem("id")),
      is_archived: false,
      title: "Checklist",
      is_priority,
      is_Activity_archived: is_archived,
    };

    this.props.createChecklistAction({ ...checklist }, (response) => {
      if (response && !response.error) {
        this.props.updateFetch();
      }
    });
  };

  onRearrangeClick = () => {
    this.setState({
      rearrangeChecklist: true,
    });
  };
  popUpStatus = () => {
    this.setState({
      noItem: true,
    });
  };

  setArrangedChecklist = (checklists) => {
    this.setState({
      arrangedChecklists: checklists,
    });
  };

  onChecklistRearrangeCancel = () => {
    this.setState({
      rearrangeChecklist: false,
    });
  };

  onMoveOfItemToAnotherList = (...data) => {
    const activity_id = this.props.activityDetails.id;
    const queryParams = `activity_id=${activity_id}&is_archived=false`;
    this.props.getChecklistAfterRearrangeAction(
      { queryParams });
    
  };

  handleClickOutside = () => {
    this.setState({
      showOptionImageContainer: false,
    });
  };

  showOptionImageContainer = () => {
    this.setState({
      showOptionImageContainer: !this.state.showOptionImageContainer,
    });
  };

  onPasteChecklist = () => {
    pasteChecklist({ activity_id: this.props.activityDetails.id }, (res) => {
      this.handleClickOutside();
      if (res?.message) {
        
        const activity_id = this.props.activityDetails.id;
        const queryParams = `activity_id=${this.props.activityDetails.id}&is_archived=false`;
        this.props.getChecklistAfterRearrangeAction(
          { queryParams },
          (response) => {
            if (
              this.props.activityDetails.active_checklist_count !==
              response.length
            ) {
              this.props.activityDetails.active_checklist_count =
                response.length;
              this.props.updateChecklistCount(
                activity_id,
                this.props.is_priority,
                response.length
              );
            }
          }
        );
      }
      if (res?.error) {
        this.setState(
          {
            pasteErrMsg: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ pasteErrMsg: false });
            }, 5000);
          }
        );
      }
    });
  };

  render() {
    const {
      // checklists,
      searchText,
      selectedOption,
      // searchedChecklists,
      // clickedChecklistId,
      getChecklistLoaderState,
      users,
      loggedInUser,
      selectedWorkspace,
      createChecklistItem,
      activityDetails,
      is_archived,
      updateChecklistItem,
      updateChecklist,
      // updateChecklistItemLoaderState,
      is_priority,
      // checklistItemId
    } = this.props;
    

    const isUserPartOfCard =
      activityDetails &&
      activityDetails.user_id.includes(parseInt(localStorage.getItem("id")));

    const isSuperAdmin = getIsSuperAdmin(loggedInUser, selectedWorkspace);

    const { rearrangeChecklist } = this.state;
    const checklistsForActivity =
      this.state.checkListItems &&
      this.state.checkListItems.filter(
        (checklist) => checklist.activity_id === activityDetails.id
      );

    const unArchieveChecklist =
      checklistsForActivity &&
      checklistsForActivity.filter(
        (checklist) => checklist.is_archived === false
      );
    const sortedunArchieveChecklist = _.orderBy(
      unArchieveChecklist,
      "position",
      ["desc"]
    );

    const checklistRendered =
      sortedunArchieveChecklist.length > 0 ? (
        sortedunArchieveChecklist.map((checklist, index) => {
          if (checklist.id instanceof Object) {
            return;
          }
          return (
            <ChecklistDetail
              key={checklist.id + checklist.activity_id}
              checklist={checklist}
              searchText={searchText}
              selectedOption={selectedOption}
              idOfElement={`checklist-${!this.state.noItem &&
                this.props.clickedChecklistId}`}
              checklistItemId={this.props.checklistItemId}
              users={users}
              isSuperAdmin={isSuperAdmin}
              createChecklistItemAction={createChecklistItem}
              activityDetails={activityDetails}
              is_archived={is_archived}
              updateChecklistItemAction={updateChecklistItem}
              updateChecklistAction={updateChecklist}
              is_priority={is_priority}
              index={index}
              onRearrangeClick={this.onRearrangeClick}
              checklistOpenStatus={
                this.props.clickedChecklistId === checklist.id ? true : false
              }
              updateFetch={this.props.updateFetch}
              onMoveOfItemToAnotherList={this.onMoveOfItemToAnotherList}
              setChecklist={this.setChecklist}
              checklistsArray={this.state.checkListItems}
            ></ChecklistDetail>
          );
        })
      ) : (
        <div>
          <p class="checklist-message">
            {" "}
            {searchText || selectedOption !== "All"
              ? getChecklistLoaderState.fetched &&
                !getChecklistLoaderState.error &&
                "No results found"
              : "No checklists added yet"}{" "}
          </p>
        </div>
      );

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="checklist-container">
          {(isSuperAdmin || isUserPartOfCard) &&
            !searchText &&
            selectedOption === "All" && (
              <div className="checklist-header-container">
                <div className="checklist-header-container-wrap">
                  {/* <!--<span className="checklist-count" >Checklists ({{checklist.checklists.length || 0}})</span>--> */}

                  {!rearrangeChecklist && (
                    <div className="wrap-hide-show">
                      <div
                        className="add-new-button"
                        onClick={this.onAddChecklistClicked}
                      >
                        Add Checklist
                      </div>{" "}
                    </div>
                  )}
                  {this.state.pasteErrMsg && (
                    <div class="new-comm-attacherror checklist-paste-err">
                      No checklist to paste{" "}
                    </div>
                  )}
                  {!rearrangeChecklist && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleClickOutside}
                    >
                      <div
                        className="option-img-container"
                        onClick={this.showOptionImageContainer}
                      >
                        {" "}
                        <img
                          role="button"
                          className="checklist-three-dots"
                          src={activityDotsSVG}
                        />
                      </div>
                      {this.state.showOptionImageContainer && (
                        <div className="paste-checklist-options-container">
                          <div
                            className="paste-checklist-options"
                            onClick={this.onPasteChecklist}
                          >
                            Paste Checklist
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  )}

                  {rearrangeChecklist && (
                    <div className="wrap-hide-show rearrange-zone">
                      <div
                        className="add-new-button check-done"
                        onClick={this.onChecklistRearrangeDone}
                      >
                        Save
                      </div>
                      <div
                        className="add-new-button cancel-button"
                        onClick={this.onChecklistRearrangeCancel}
                      >
                        Cancel
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {getChecklistLoaderState.fetching &&
            !getChecklistLoaderState.error &&
            (sortedunArchieveChecklist.length === 0 || searchText) && (
              <Loader size={100} loading={true} />
            )}
          {((getChecklistLoaderState.fetched &&
            !getChecklistLoaderState.error) ||
            (sortedunArchieveChecklist.length > 0 && !searchText)) &&
            !rearrangeChecklist &&
            checklistRendered}
          {getChecklistLoaderState.error &&
            checklistRendered &&
            !rearrangeChecklist && (
              <div>
                <p className="checklist-message">Something went wrong</p>
              </div>
            )}

          {rearrangeChecklist && (
            <ChecklistRearrangeMain
              checklists={sortedunArchieveChecklist}
              updateChecklistAction={updateChecklist}
              setArrangedChecklist={this.setArrangedChecklist}
            ></ChecklistRearrangeMain>
          )}

          <DragChecklist key={new Date().toString()} users={users} />
        </div>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checklists: state.checklistReducer.checklists.list,
    searchedChecklists: state.checklistReducer.searchedChecklists.list,
    getChecklistLoaderState: state.checklistReducer.getChecklistLoaderState,
    addChecklistLoaderState: state.checklistReducer.addChecklistLoaderState,
    updateChecklistLoaderState:
      state.checklistReducer.updateChecklistLoaderState,
    selectedWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    loggedInUser: state.loginReducer.loginAuth.user_details,
    updateChecklistItemLoaderState:
      state.checklistReducer.updateChecklistItemLoaderState,
    users: state.peopleReducer.workspaceusers,
    scrollHeight: state.dialogReducer.scrollHeight,
    archiveOrUnarchiveLoaderState:
      state.activityReducer.archiveOrUnarchiveLoaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createChecklistAction: (
      {
        activity_id,
        title,
        created_by,
        is_archived,
        is_priority,
        is_Activity_archived,
      },
      callback
    ) => {
      dispatch(
        createChecklistAction(
          {
            activity_id,
            title,
            created_by,
            is_archived,
            is_priority,
            is_Activity_archived,
          },
          callback
        )
      );
    },
    getChecklistAction: ({ queryParams }, callback) => {
      dispatch(getChecklistAction({ queryParams }, callback));
    },
    getChecklistAfterRearrangeAction: ({ queryParams }, callback) => {
      dispatch(getChecklistAfterRearrangeAction({ queryParams }, callback));
    },
    getAllCustomerForPeopleAction: (callback) => {
      dispatch(getAllCustomerForPeopleAction(callback));
    },
    createChecklistItem: (
      { activity_id, created_by, is_archived, required, title, checklist_id },
      callback
    ) => {
      dispatch(
        createChecklistItemAction(
          {
            activity_id,
            created_by,
            is_archived,
            required,
            title,
            checklist_id,
          },
          callback
        )
      );
    },
    updateChecklistItem: (
      {
        checklist_id,
        completed_by,
        created_at,
        created_by,
        id,
        is_completed,
        position,
        title,
        social_data,
        data,
        is_archived,
        is_Activity_archived,
      },
      callback
    ) => {
      dispatch(
        updateChecklistItemAction(
          {
            checklist_id,
            completed_by,
            created_at,
            created_by,
            id,
            is_completed,
            position,
            title,
            social_data,
            data,
            is_archived,
            is_Activity_archived,
          },
          callback
        )
      );
    },
    updateChecklist: (
      {
        activity_id,
        title,
        created_by,
        is_archived,
        position,
        id,
        is_open,
        is_show,
        is_priority,
        is_Activity_archived,
      },
      callback
    ) => {
      dispatch(
        updateChecklistAction(
          {
            activity_id,
            title,
            created_by,
            is_archived,
            position,
            id,
            is_open,
            is_show,
            is_priority,
            is_Activity_archived,
          },
          callback
        )
      );
    },
    MarkActivityReadAction({ body, is_priority }, callback) {
      dispatch(MarkActivityReadAction({ body, is_priority }, callback));
    },
    MarkKanbanActivityReadAction({ body, is_priority }, callback) {
      dispatch(MarkKanbanActivityReadAction({ body, is_priority }, callback));
    },
    changeNoItemStatusAction: ({ showStatus }) =>
      dispatch(changeNoItemStatusAction({ showStatus })),
    updateChecklistCount: (activity_id, is_priority, checklistCount) =>
      dispatch(
        updateChecklistCountAction({ activity_id, is_priority, checklistCount })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
