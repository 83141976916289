import React, { Component } from "react";
import LandingPage from "./landingpage";
import LoginForm from "./loginform";
import * as actions from "../../actions/index";
import { getAllCustomerForUserAction } from "../../actions/workspace/workspaceaction";
import routesHelper from "../../router/routeshelper";
import "../../styles/scss/app.scss";
import ForgotPassword from "./forgotpassword";
import { withRouter } from "react-router";
import Registration from "../registration/registration";

import { connect } from "react-redux";
import ResetPassword from "../login/restpassword";
import { Loader } from "../common/loader";
import { verifyEmail } from "../../services/loginapi";
import { isAppleSignIn } from "../../helpers/loginconstants";
import firebase from "../../firebase";
import { updateUserFirstTimeFlagAction } from "../../actions/common/commonactions";
import { isAuthenticated } from "../../helpers/isauthenticated";

const mapStateToProps = (state) => {
  return {
    userDetails: state.rrfStatereducer.login,
    loginActionState: state.loginReducer.loginActionState,
    registrationActionState: state.loginReducer.registrationActionState,
    forgotPasswordLoaderState: state.loginReducer.forgotPasswordLoaderState,
    resetPasswordLoaderState: state.loginReducer.resetPasswordLoaderState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateAppleUserAction({ apple_token }, callback) {
      dispatch(
        actions.authenticateAppleUser(
          {
            apple_token
          },
          callback
        )
      );
    },
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey
          },
          callback
        )
      );
    }
  };
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
      showFadeOut: false
    };
  }
  
  setShowSuccess = (bol) => {
    this.setState({ showSuccess: bol });
  }

  setShowFadeOut = (bol) => {
    this.setState({ showFadeOut: bol })
  }

  componentWillMount() {
    if(isAuthenticated()) {
      this.props.router.push(routesHelper.getRootPath());
    }
  }

  componentDidMount() {
    const { router, authenticateAppleUserAction, getAllCustomersAction } = this.props;
    if (
      router.location.pathname === routesHelper.getLoginPath() &&
      router.location.query.token
    ) {
      verifyEmail({ token: router.location.query.token });
    }
    if (
      router.location.pathname === routesHelper.getLoginPath() &&
      router.location.query.apple_token
    ) {
        authenticateAppleUserAction({ apple_token: router.location.query.apple_token }, response => {
          if (response && response.auth_token) {
            if(response.first_time_logged_in) firebase.analytics().logEvent("GTD_Apple_Register_Success");
            else firebase.analytics().logEvent("GTD_Apple_Login_Success");
            for (const key in response) {
              if (response.hasOwnProperty(key)) {
                const element = response[key];
                if (key === "data") {
                  localStorage.setItem(key, JSON.stringify(element));
                } else if(key === "auth_token") {
                  for (const k in response.auth_token) {
                    const ele = response.auth_token[k];
                    localStorage.setItem(k, ele);
                  }
                } else {
                  localStorage.setItem(key, element);
                }
              }
            }
            const auth_token = response.auth_token;
            localStorage.setItem(isAppleSignIn, true);
            getAllCustomersAction({ auth_token }, workspaces => {
              if (workspaces && !workspaces.stdErr) {
                if (workspaces.length > 0) {
                  localStorage.setItem("hasAnyWorkspace", true);
                  if(response.first_time_logged_in){
                    updateUserFirstTimeFlagAction({auth_token:auth_token,id:response.id,first_time_logged_in:false})
                    localStorage.setItem("firsttimeprofile", true);
                    return router.push(routesHelper.welcomePath());
                  }else{
                    return router.push(routesHelper.getRootPath());
                  }   
                }
                localStorage.setItem("firsttimeprofile", true);
                updateUserFirstTimeFlagAction({auth_token:auth_token,id:response.id,first_time_logged_in:false})
                localStorage.setItem("hasAnyWorkspace", false);
                return router.push("/finish-profile");
              }
            });
          } 
        })
    }
    let auth_token = localStorage.getItem("auth_token");
    let haveMyWorkspace = localStorage.getItem("haveMyWorkspace");
    let hasAnyWorkspace = localStorage.getItem("hasAnyWorkspace");
    if (auth_token) {
      router.push(
        hasAnyWorkspace === "true" || haveMyWorkspace === "true"
          ? routesHelper.getRootPath()
          : routesHelper.getFinishProfile()
      );
      
    }
  }

  render() {
    const {
      router: {
        location: { pathname }
      },
      userDetails,
      loginActionState,
      registrationActionState,
      forgotPasswordLoaderState,
      resetPasswordLoaderState
    } = this.props;

    return (
      <div className={`ng-view ${this.state.showSuccess ? "ng-verify-email" : ""}`}>
        <div id="loginbox" className="ng-scope">
          <Loader
            size={100}
            loading={
              loginActionState.fetching ||
              registrationActionState.fetching ||
              forgotPasswordLoaderState.fetching ||
              resetPasswordLoaderState.fetching
            }
          />

          <div className="left">
            <LandingPage router={this.props.router} />
          </div>

          <div className="right">
            <div className="signIn-container">
              {pathname && pathname === routesHelper.getForgotPasswordPath() ? (
                <ForgotPassword router={this.props.router} />
              ) : pathname &&
                pathname === routesHelper.getRegistrationPath() ? (
                <Registration router={this.props.router} />
              ) : pathname &&
                pathname === routesHelper.getRestPasswordPath() ? (
                <ResetPassword router={this.props.router} />
              ) : (
                <LoginForm router={this.props.router} setShowSuccess={this.setShowSuccess} setShowFadeOut={this.setShowFadeOut} />
              )}
            </div>
          </div>
        </div>
        {this.state.showSuccess && (
        <div className={`resend-success login-view ${this.state.showFadeOut ? "show-success" : ""}`}>
          <div className="resend-text">
            {`Verification email sent successfully to ${userDetails.email}`}
            <br />
            Please check your <span>spam folder</span>.
          </div>
        </div>
      )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
