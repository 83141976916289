import moment from "moment";

export const daysOfWeek = weekIndex => {
  let startOfWeek;
  let endOfWeek;
  const days = [];

  let weekNumber;
  if (weekIndex < 0) {
    startOfWeek = moment()
      .subtract(-1 * weekIndex, "weeks")
      .startOf("week", weekIndex);
    endOfWeek = moment()
      .subtract(-1 * weekIndex, "weeks")
      .endOf("week", weekIndex);
    let day = startOfWeek;
    weekNumber = moment().week();
    weekNumber = weekNumber - weekIndex;
    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }
    // console.log("from here sub");
    return { days, weekNumber };
  } else if (weekIndex > 0) {
    startOfWeek = moment()
      .add(weekIndex, "weeks")
      .startOf("week", weekIndex);
    endOfWeek = moment()
      .add(weekIndex, "weeks")
      .endOf("week", weekIndex);
    let day = startOfWeek;
    weekNumber = moment().week();
    weekNumber = weekNumber + weekIndex;
    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }
    return { days, weekNumber };
  } else {
    startOfWeek = moment().startOf("week");
    endOfWeek = moment().endOf("week");
    let day = startOfWeek;
    let weekNumber = moment().week();
    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }
    return { days, weekNumber };
  }
};

export const nameOfTheMonth = monthIndex => {
  if (monthIndex > 0) {
    // console.log(
    //   moment()
    //     .add(monthIndex, "months")
    //     .format("MMMM YYYY")
    // );
    return moment()
      .add(monthIndex, "months")
      .format("MMMM YYYY");
  } else {
    // console.log(
    //   moment()
    //     .subtract(-1 * monthIndex, "months")
    //     .format("MMMM YYYY")
    // );
    return moment()
      .subtract(-1 * monthIndex, "months")
      .format("MMMM YYYY");
  }
};

export const nameOfTheDay = dayIndex => {
  if (dayIndex > 0) {
    // console.log(
    //   moment()
    //     .add(dayIndex, "day")
    //     .format("ddd, D MMM YYYY")
    // );
    return moment()
      .add(dayIndex, "day")
      .format("ddd, D MMM YYYY");
  } else {
    // console.log(
    //   moment()
    //     .subtract(-1 * dayIndex, "day")
    //     .format("ddd, D MMM YYYY")
    // );
    return moment()
      .subtract(-1 * dayIndex, "day")
      .format("ddd, D MMM YYYY");
  }
};

export const calculateTotalPlannedHours = 
(
  mode,
  dateValues,
  userId,
  activityArray
) => {
  const userActivities =
    activityArray.filter(el => el.id === userId).length > 0
      ? activityArray.filter(el => el.id === userId)[0].activities
      : [];
  // console.log("userActivities", userActivities);
  var filteredActivityByDate;
  switch (mode.id) {
    case 0:
      filteredActivityByDate = userActivities.filter(el =>
        moment(el.start_on).isSame(moment(dateValues), "day")
      );
      break;
    case 1:
      filteredActivityByDate = userActivities.filter(el =>
        moment(el.start_on).isBetween(
          moment(dateValues[0]),
          moment(dateValues[6]),
          null,
          "[]"
        )
      );
      break;

    case 2:
      filteredActivityByDate = userActivities.filter(el =>
        moment(el.start_on).isBetween(
          dateValues[0],
          dateValues[dateValues.length],
          "days",
          "[]"
        )
      );
      break;

    default:
      filteredActivityByDate = [];
  }

  let totalPlannedHours =
    filteredActivityByDate &&
    filteredActivityByDate.reduce((acc, val) => {
      return (acc = acc + val.duration);
    }, 0);
  

  return totalPlannedHours;
};

export const getMonthDateRange = currentdate => {
  // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
  // array is 'year', 'month', 'day', etc
  // const monthNames = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December"
  // ];

  // const splitedYear = currentdate.trim().split(" ");

  // const monthIndex = monthNames.indexOf(splitedYear[0]);
  // const year = parseInt(splitedYear[1]);
  // const startDate = moment([year, monthIndex]);

  // Clone the value before .endOf()
  // var month = moment(startDate).month();
  // console.log(month);
  const days = daysInMonth(currentdate);

  // make sure to call toDate() for plain JavaScript date type
  return days;
};

function daysInMonth(currentdate) {
  var count = moment(currentdate, "MMMM YYYY").daysInMonth();
  var days = [];
  for (var i = 1; i < count + 1; i++) {
    days.push(moment(currentdate).date(i));
  }
  return days;
}

// export const datesIndex = (da) => {
//   const
// };

export const groupBydays = activities => {};
