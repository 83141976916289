import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";

export const getAllAttachmentsDetails = (
  { auth_token, activity_id, is_archived },
  callback
) => {
  //console.log("getAllAttachmentsDetails function is called from attachmentapi.js")
  let url = "/api/v1/attachments?";
  let Params = "activity_id=" + activity_id + "&is_archived=" + is_archived;
  //console.log("API_URL+ url+Params",API_URL+ url+Params)
  secureFetch(API_URL + url + Params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const updateAttachment = (
  {
    auth_token,
    id,
    title,
    description,
    url,
    size,
    activity_id,
    uploaded_by,
    is_archived
  },
  callback
) => {
  let URL = "/api/v1/attachments";
  secureFetch(API_URL + URL, {
    method: "PUT",
    body: JSON.stringify({
      is_archived,
      id,
      title,
      description,
      url,
      size,
      activity_id,
      uploaded_by,
      // is_archived
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: auth_token ? "Bearer: " + auth_token : "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log("Api call from updateAttachment function (attachmentapi.js)")
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const addAttachment = (body, callback) => {
  let url = API_URL + "/api/v1/attachments";
  secureFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log("AddAttachment POST method response", respJson)
      callback(respJson);
    })
    .catch(error => {
      //console.log("error in post method for adding attachment")
    });
};

export const addAttachmentFiles = (body, callback) => {
  let url = API_URL + "/api/v1/save-attachments";
  secureFetch(url, {
    method: "POST",
    body: body,
    headers: new Headers({
      // "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log("AddAttachment POST method response", respJson)
      callback(respJson);
    })
    .catch(error => {
      //console.log("error in post method for adding attachment")
    });
};
