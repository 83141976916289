export const billingConstant = {
  price: 9.0,
  getSubscription: "GET_SUBSCRIPTION",
  updateSubscription: "UPDATE_SUBSCRIPTION",
  getCustomer: "GET_CUSTOMER",
  getCharges: "GET_CHARGES",
  updateCardDetails: "UPDATE_CREDENTIALS",
  updateCustomer: "UPDATE_CUSTOMER",
  reactivateSubscription: "REACTIVATE_SUBSCRIPTION",
  cancelSubscription: "CANCEL_SUBSCRIPTION",
  updateSubscriptionLoader: "UPDATE_SUBSCRIPTION_LOADER",
  cancelOrActivateSubscriptionLoader: "CANCEL_OR_UPDATE_SUBSCRIPTION_LOADER"
};
