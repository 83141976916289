import React from "react";

export default function SettingsTab(props) {
  //console.log(props);
  const { onTabClick, selectedTab } = props;
  return (
    <div>
      <div className="settings-tab">
        <div className="inner-tabs">
          <ul className="settings-tab-holder">
            <li
              onClick={() => onTabClick("people")}
              role="button"
              tabIndex="0"
              className={selectedTab === "people" ? "active" : ""}
            >
              People
            </li>
            <li
              onClick={() => onTabClick("project")}
              role="button"
              tabIndex="0"
              className={selectedTab === "project" ? "active" : ""}
            >
              Project
            </li>
            {
              <li
                onClick={() => onTabClick("billing")}
                role="button"
                tabIndex="0"
                className={selectedTab === "billing" ? "active" : ""}
              >
                Billing
              </li>
            }
            {
              <li
                onClick={() => onTabClick("workspace")}
                role="button"
                tabIndex="0"
                className={selectedTab === "workspace" ? "active" : ""}
              >
                Workspace
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}
