import React, { Component } from "react";
import moment from "moment";
import no_timesheet from "../../styles/img/svg/no-timesheet.svg";
import partial_timesheet from "../../styles/img/svg/partial-timesheet.svg";
import { Tooltip } from "@material-ui/core";

import {
  // commonDateFormat,
  convertTimeToString
} from "../../helpers/generichelper";
import {
  changeIsReadStatusOfYouTabAction,
  changeFlagStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfAllTabAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import firebase from "../../firebase";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    }
  };
}

class NotifyTimeSheet extends Component {
 
  youNotificationReadStatus = (event, notification) => {
    
    firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
    event.stopPropagation();
    if (notification.is_read === false) {
      notification.is_read = true;
    } else if (notification.is_read === true) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  notificationFlagStatus = (event, notification) => {
    
    firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
    event.stopPropagation();
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }
    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  render() {
    const { notification } = this.props;
    return (
      <div className="notify-card-others notify-timer">
        <Tooltip
          title={notification.is_read ? "Mark as unread" : "Mark as read"}
        >
          <div
            className={
              notification.is_read === true
                ? "notify-read-unread read"
                : "notify-read-unread"
            }
            onClick={(event) => this.youNotificationReadStatus(event, notification)}
          ></div>
        </Tooltip>

        <Tooltip
          title={
            notification.is_flagged
              ? "Unflag this notification"
              : "Flag this notification"
          }
        >
          <div
            className={
              notification.is_flagged === true
                ? "notifi-flag flagged"
                : "notifi-flag"
            }
            onClick={(event) => this.notificationFlagStatus(event, notification)}
          ></div>
        </Tooltip>
        <div className="notify-card-left">
          <div className="notify-type-icon">
            <img
              className="timesheet-icon"
              src={
                notification.message.includes("worked")
                  ? partial_timesheet
                  : no_timesheet
              }
              alt="timer-icon"
            />
          </div>
        </div>
        <div className="notify-card-right">
          <div className="notify-card-title timesheet">
            <span>{notification.message}</span>
            {notification.data.time_spent > 0 && (
              <React.Fragment>
                <span className="time-spent">
                  {" "}
                  {convertTimeToString(notification.data.time_spent)}{" "}
                </span>
                on
              </React.Fragment>
            )}
            {notification.type === "WORK_HOURS" && <span>
              {" "}
              {moment(notification.data.date).format("ddd, DD MMM YY")}
            </span>}
          </div>
          <div className="update-timesheet">Update timesheet</div>
        </div>
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(NotifyTimeSheet);
