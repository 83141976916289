import React, { Component } from "react";
import close from "../../styles/img/svg/settings-close-icon.svg";
import search from "../../styles/img/svg/settings-search-icon.svg";
import randomizeIcon from "../../styles/img/svg/noun-shuffle-2785513.svg";
import { getListOfImages, searchPhotos } from "../../services/unsplashaapi";
import { isArray } from "util";
import InfiniteScroll from "react-infinite-scroll-component";

class UnsplashDialog extends Component {
  state = {
    pageNumber: 1,
    searchPageNumber: 1,
    searchText: "",
    listOfImages: [{ randomize: true }],
    searchedListOfImages: [],
    selectedArray: []
  };

  componentDidMount() {
    getListOfImages(1, (response) => {
      
      if (isArray(response))
        this.setState({
          listOfImages: [...this.state.listOfImages, ...response]
        });
    });
  }

  onChangeSearch = (event) => {
    this.setState({ searchText: event.target.value });
    if (event.target.value.length > 0) {
      searchPhotos(event.target.value, 1, (response) => {
        
        if (isArray(response))
          this.setState({ searchedListOfImages: response });
      });
    }
    if (event.target.value.length === 0)
      this.setState({ searchedListOfImages: [] });
  };

  onScroll = () => {
    
    if (this.state.searchText.length > 0) {
      searchPhotos(
        this.state.searchText,
        this.state.searchPageNumber + 1,
        (response) => {
          
          if (isArray(response))
            this.setState({
              searchedListOfImages: [
                ...this.state.searchedListOfImages,
                ...response
              ],
              searchPageNumber: this.state.searchPageNumber + 1
            });
        }
      );
    } else {
      getListOfImages(this.state.pageNumber + 1, (response) => {
        
        if (isArray(response))
          this.setState({
            listOfImages: [...this.state.listOfImages, ...response],
            pageNumber: this.state.pageNumber + 1
          });
      });
    }
  };

  onSelectImage = (object) => {
    this.setState({ selectedArray: [object] });
  };

  onSave = () => {
    if (this.state.selectedArray[0].hasOwnProperty("randomize"))
      this.props.onRandomizeUnsplash();
    else this.props.onChangeUnsplash(this.state.selectedArray[0]);
    this.props.onShowUnsplashDialog();
  };

  render() {
    const {
      searchText,
      selectedArray,
      listOfImages,
      searchedListOfImages
    } = this.state;
    const imageListToRender =
      searchedListOfImages.length > 0 ? searchedListOfImages : listOfImages;
    return (
      <div className="unsplash-wrapper">
        <div className="unsplash-container">
          {/* <div className="unsplash-container-loader" ng-show="userProfile.unsplash_isLoading">
                                <md-progress-circular></md-progress-circular>
                            </div> */}
          <div className="unsplash-header">
            <p> Select Background</p>
            <div onClick={() => this.props.onShowUnsplashDialog()}>
              <img src={close} alt="close-modal-icon" />
            </div>
          </div>
          <div className="search">
            <img src={search} alt="search-icon" />
            <input
              type="text"
              className="input-search"
              placeholder="Search"
              value={searchText}
              onChange={this.onChangeSearch}
            />
          </div>
          <div
            className="unsplash-img-container"
            id="unsplash-img-container-scroll"
          >
            {imageListToRender.length > 0 && (
              <InfiniteScroll
                dataLength={imageListToRender.length}
                next={this.onScroll}
                hasMore={true}
                scrollableTarget="unsplash-img-container-scroll"
              >
                {imageListToRender.map((object, index) => {
                  if (object.hasOwnProperty("randomize")) {
                    return (
                      <div
                        key={index}
                        className={`randomize-container ${
                          selectedArray.length > 0 &&
                          selectedArray[0].randomize === object.randomize
                            ? "active"
                            : ""
                        }`}
                        onClick={() => this.onSelectImage(object)}
                      >
                        <img src={randomizeIcon} alt="randomize-icon" />
                        <div>Randomize</div>
                      </div>
                    );
                  } else
                    return (
                      <div
                        key={index}
                        className={`img-container ${
                          selectedArray.length > 0 &&
                          selectedArray[0].id === object.id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => this.onSelectImage(object)}
                      >
                        <img
                          src={object.urls.small}
                          alt="bg-img"
                        />
                      </div>
                    );
                })}
              </InfiniteScroll>
            )}
          </div>
          <button
            className={`save-button ${
              selectedArray.length === 0 ? "disabled" : ""
            }`}
            onClick={this.onSave}
            disabled={selectedArray.length === 0}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default UnsplashDialog;
