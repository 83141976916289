import React, { Component } from "react";

class RoleDropDown extends Component {
  state = {};

  onSelectAccess = (event, projectMember, access) => {
      this.props.onSelectAccess(projectMember, access)
  }

  // handleClickOutside = event => {
  //   if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
  //     this.props.onShowDropDown(event, 0);
  //   }
  // };

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClickOutside, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside, false);
  // }

  render() {
      const { projectMember, shouldShowRoleChange } = this.props;
    return (
      <div
        className={`prj-access-role-ddown ${projectMember.access === "Owner" || !shouldShowRoleChange ? "less-top" : ""}`}
        // ref={node => (this.outerDivNode = node)}
      >
        {projectMember.access !== "Owner" && shouldShowRoleChange && projectMember.access !== "Member" &&
          <div
            className="prj-access-role-ddown-list"
            onClick={event =>
              this.onSelectAccess(event, projectMember, "Member")
            }
          >
            Give member access
          </div>}
        {projectMember.access !== "Owner" && shouldShowRoleChange && projectMember.access !== "Manager" &&
        <div
        className="prj-access-role-ddown-list"
        onClick={event =>
          this.onSelectAccess(event, projectMember, "Manager")
        }
      >
        Give manager access
      </div>}
        <div
          className="prj-access-role-ddown-list"
          onClick={event => this.onSelectAccess(event, projectMember, "Remove")}
        >
          Remove from project
        </div>
      </div>
    );
  }
}

export default RoleDropDown;
