import React, { Component } from "react";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import { getProjectMembers } from "../../helpers/projectsettings";
import AssignPeopleSearchBox from "./assignPeopleSearchBox";
import PeopleTable from "./peopleTable";
import { getProjectWidgets } from "../../services/projectapi";
import { getIsAccessToAddRemoveMember } from "../../helpers/generichelper";
import { getIsCreator,
  // getIsSuperAdmin
} from "../../helpers/reportlanding";

class PeopleAccessSection extends Component {
  state = {
    showSearch: false,
    projectWidgets: []
  };

  componentDidMount() {
    let auth_token = localStorage.getItem("customer_auth_token");
    // this.props.getAllCustomer({}, response => {
    //   if (response && !response.error) {
    //   }
    // });
    getProjectWidgets({ auth_token }, response => {
      if(response && response.length > 0) this.setState({ projectWidgets: response })
    })
  }

  onShowSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showSearch: !this.state.showSearch });
  };

  onOutsideClickShowSearch = e => {
    if (
      this.outerSearchDivNode &&
      !this.outerSearchDivNode.contains(e.target)
    ) {
      if (this.state.showSearch) this.setState({ showSearch: false });
    }
  };

  onHideSearch = () => {
    this.setState({ showSearch: false });
  };

  render() {
    const { showSearch, projectWidgets } = this.state;
    const { currentWorkSpace, workSpaceUsers } = this.props;
    if (this.props.projectDetails && currentWorkSpace) {
      // const { data } = this.props.projectDetails;
      let membersLength = getProjectMembers(
        this.props.projectDetails,
        workSpaceUsers,
        currentWorkSpace
      );
      return (
        <div className="prj-access-tt-container project-set">
          <div className="prj-title-wrapper">
            <div className="prj-access-header">
              People access{" "}
              <span>
                {membersLength && membersLength.length === 1 && "1 person is assigned to this project"}
                {membersLength && membersLength.length > 1
                  ? `${membersLength.length} people are assigned to this project`
                  : membersLength.length === 0 && "No people are assigned to this project"}
                
              </span>
            </div>
            {(getIsCreator(currentWorkSpace) ||
              // getIsSuperAdmin(this.props.loggedInUserDetails, currentWorkSpace) ||
              getIsAccessToAddRemoveMember(this.props.projectDetails)) && 
            <div className="prj-addmember" ref={node => (this.outerSearchDivNode = node)}>
              <span onClick={this.onShowSearch}>Assign people</span>
              {showSearch && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClickShowSearch}>
                <AssignPeopleSearchBox
                  onHideSearch={this.onHideSearch}
                  workSpaceUsers={workSpaceUsers}
                  currentWorkSpaceId={currentWorkSpace.id}
                  currentWorkSpace={currentWorkSpace}
                  projectDetails={this.props.projectDetails}
                />
                </OutsideClickHandler>
              )}
            </div>}
          </div>
          <PeopleTable
            projectDetails={this.props.projectDetails}
            currentUser={this.props.loggedInUserDetails}
            currentWorkSpace={currentWorkSpace}
            workSpaceUsers={workSpaceUsers}
            widgets={projectWidgets}
          />
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    loggedInUserDetails: state.loginReducer.loginAuth.user_details,
    workSpaceUsers: state.peopleReducer.workspaceusers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllCustomer: ({ auth_token }, callback) => {
      dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleAccessSection);
