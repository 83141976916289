import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import CommonNav from "./commonnav";
import { getDefaultClientLogo } from "../../helpers/generichelper";
import "../../styles/scss/joinWorkspace.scss";
import routesHelper from "../../router/routeshelper";
import ProjectEditHeader from "../common/projectEditHeader";
// import { ClipLoader } from "react-spinners";
import { commonContants } from "../../helpers/commonconstants";
import { Loader } from "../common/loader";
import addLogo from "../../styles/img/svg/Add_Logo_Icon.svg";
import { message } from "../../constants/messages";
import MaterialButton from "../common/materialbutton";
import { switchWorkspace } from "../../services/workspaceapi";
// import { updateSelectedWorkpaceDetails } from "../../actions/workspace/workspaceaction";
import firebase from "../../firebase";

class CreateWorkspace extends Component {
  constructor(props) {
    super(props);
    window.history.forward();
    this.state = {
      clientLogo: addLogo,
      clientLogo_name: "",
      isInValidForm: true,
      imageLoading: false,
      displayErrMsg: false
    };
  }

  UNSAFE_componentWillMount() {
    firebase.analytics().logEvent("GTD_Registration_Flow_Create_Workspace");
    const haveMyWorkspace = localStorage.getItem("haveMyWorkspace");
    if (haveMyWorkspace === 'true') {
      this.props.router.push("/workspace");
    }
  }

  clickOnUploadFile = () => {
    this.uploadFileRef && this.uploadFileRef.click();
  };

  uploadInitiated = () => {
    const { uploadPhotoToS3BucketAction } = this.props;
    let selectedFiles =
    this.uploadFileRef &&
    this.uploadFileRef.files &&
    this.uploadFileRef.files;
    
    if(selectedFiles &&
      selectedFiles.length) {
        this.setState(() => ({
          imageLoading: true
        }));

        uploadPhotoToS3BucketAction(
          {
            file: selectedFiles[0],
            loaderKey: commonContants.updateCustomerPhotoLoaderAction
          },
          (response) => {
            if (response && response.success) {
              response.img &&
                this.setState({
                  clientLogo: response.img,
                  imageLoading: false,
                  clientLogo_name: selectedFiles[0].name
                });
            }
          }
        );
      }
  };

  onWorkspaceNameUpdated = (e) => {
    if (e.key === "Enter") this.onCheckSubmit();
    else {
      let textvalue = this.workspaceNameRef.value;
      if (textvalue) {
        this.setState({
          isInValidForm: false
        });
      } else {
        this.setState({
          isInValidForm: true
        });
      }
    }
  };

  onCheckSubmit = () => {
    if (!this.state.isInValidForm) {
      this.onFormSubmitted();
    } else {
      this.setState({
        displayErrMsg: true
      });
    }
  };
  onFormSubmitted = () => {
    const {
      createWorkspaceAction,
      router,
      getAllCustomersAction,
      updateSelectedWorkpaceDetailsAction
    } = this.props;
    let { clientLogo } = this.state;
    let user_id = parseInt(localStorage.getItem("id"));
    let auth_token = localStorage.getItem("auth_token");
    clientLogo = clientLogo === addLogo ? "" : clientLogo;

    createWorkspaceAction(
      {
        auth_token,
        name: this.workspaceNameRef.value,
        img: clientLogo,
        super_admin_id: user_id,
        img_name: clientLogo || getDefaultClientLogo()
      },
      (response) => {
        if (response && !response.stdErr) {
          firebase.analytics().logEvent("GTD_Registration_Flow_Workspace_Success");
          localStorage.setItem("haveMyWorkspace", true);
          if (response.has_workspace === true) {
            getAllCustomersAction({ auth_token }, (res) => {
              updateSelectedWorkpaceDetailsAction(res[0]);
              return router.push(routesHelper.getAddUsersToWorkspacePath());
            });
          } else {
            // console.log("res", response);
            getAllCustomersAction({ auth_token }, (res) => {
              switchWorkspace({ customer_id: response.id });
            updateSelectedWorkpaceDetailsAction(response);
            router.push(routesHelper.getAddUsersToWorkspacePath());
            });
          }
        }
      }
    );
  };

  onFindWorkspaceCallback = () => {
    const { router } = this.props;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    if (fromNavbar) {
      // console.log("from onFindWorkspaceCallback");
      router.push({
        pathname: routesHelper.getFindWorkspacePath(),
        state: {
          from: "nav-bar",
          listOfCustomer: router.location.state.listOfCustomer,
          currentUser: router.location.state.currentUser
        }
      });
    } else router.push(routesHelper.getFindWorkspacePath());
  };

  render() {
    const { router, createWorkpsaceLoaderState } = this.props;
    const { clientLogo } = this.state;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    return (
      <React.Fragment>
        {fromNavbar && (
          <ProjectEditHeader router={router} shouldRenderFindWorkspace={true} currentUser={router.location.state?.currentUser} />
        )}
        <Scrollbar
          className={`add-users-scroll ${fromNavbar ? "scroll-height" : ""}`}
        >
          <div className="joinWorkspace-container">
            <div>
              <CommonNav router={router} />
            </div>
            <div className="nw-att-loading-holder">
              {/* <ClipLoader sizeUnit={"px"} size={100} color={'#0190e9'} loading={createWorkpsaceLoaderState.fetching} /> */}
              {/* <Loader
              size={100}
              content={"Creating Workspace"}
              loading={createWorkpsaceLoaderState.fetching}
            /> */}
            </div>
            <div className="workspace-main">
              <div
                className="create-workspace"
                style={{ marginBottom: "25px" }}
              >
                <div className="create-title">Create new workspace</div>

                <div className="workspace-profile">
                  {/* $$ --Create workspace container starts here-- */}
                  <div className="workspace-upload-image">
                    <div className="upload-image">
                      <img
                        alt="workspace-logo"
                        src={clientLogo}
                        onClick={this.clickOnUploadFile}
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                      />

                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                        ref={(ref) => {
                          this.uploadFileRef = ref;
                        }}
                        style={{ display: "none" }}
                        onChange={this.uploadInitiated}
                      />
                    </div>
                    {this.state.imageLoading && (
                      <div className="loader-image-div">
                        <Loader
                          size={100}
                          content={"Uploading Image"}
                          loading={this.state.imageLoading}
                        />
                      </div>
                    )}
                  </div>

                  <div className="workspace-name">
                    <input
                      type="text"
                      placeholder="Workspace name"
                      onKeyUp={this.onWorkspaceNameUpdated}
                      ref={(node) => (this.workspaceNameRef = node)}
                      autoFocus
                    />
                    {this.state.displayErrMsg && (
                      <p className="common-err-msg">{message.emptyField}</p>
                    )}
                  </div>
                </div>

                <div className="proceed-btn">
                  <MaterialButton
                    buttonText="Proceed"
                    variant="contained"
                    className={"active-proceed"}
                    color="primary"
                    onClick={this.onCheckSubmit}
                  ></MaterialButton>

                  {createWorkpsaceLoaderState.fetched &&
                    !createWorkpsaceLoaderState.error.bool && (
                      <div className="changes-saved ng-scope">
                        Changes saved successfully!
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="find-your-workspace">
              Remembered the workspace you were looking for?
              <span
                className="find-workspace"
                onClick={this.onFindWorkspaceCallback}
              >
                Find your workspace
              </span>
            </div>
          </div>
        </Scrollbar>
      </React.Fragment>
    );
  }
}

export default CreateWorkspace;
