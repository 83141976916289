import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
import _ from "lodash";
import routesHelper from "../../router/routeshelper";
import * as action from "../../actions/index";
import {
  getProjectsByAccess,
  // getUpdatedGroupList,
  getFilteredProjects,
  getShouldRenderAddButton,
} from "../../helpers/projectslanding";
import { updateTabState } from "../../actions/common/commonactions";
import { getStatusView } from "../../helpers/projectsettings";
import { unArchivedArchivedProject } from "../../actions/project/projectaction";
import { uploadPhotoToS3Bucket } from "../../actions/common/commonactions";
import "../../styles/scss/projects.scss";
import "../../styles/scss/peopleSettings.scss";
import { Loader } from "../common/loader";
// import ProjectGroups from "./projectGroups";
import ProjectCard from "./projectCard";
import AddFeatures from "../activity/addfeatures";
import AddProjectDialog from "../settings/addProjectDialog";
// import { getCustomerDetails } from "../../services/workspaceapi";
// import { getProfileDetails } from "../../helpers/generichelper";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
import firebase from "../../firebase";

class ProjectsLanding extends Component {
  state = {
    showDialog: false,
  };

  onShowDialog = () => {
    this.setState({ showDialog: !this.state.showDialog });
  };

  onAddProject = newProject => {
    toast("Project added successfully!");
    this.props.router.push(routesHelper.getProjectEditPath(newProject.id));
  };

  componentDidMount() {
    firebase.analytics().logEvent("GTD_Project_Visit");
    const { router, getProjectAction, currentWorkSpace, currentUser } =
      this.props;
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    if (router.location.action === "PUSH") {
      const queryParamUnarchive = getIsSuperAdmin(currentUser, currentWorkSpace)
        ? `?archived=false`
        : `?user_id=${parseInt(currentUser.id)}&archived=false`;
      getProjectAction(
        { auth_token: customer_auth_token, queryParam: queryParamUnarchive },
        (response) => {
          if (response && response.length > 0) {
          }
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.updateTabStateAction({ projectsLanding: "ACTIVE" });
  }

  render() {
    const { showDialog } = this.state;
    let filteredProjects = [];
    const {
      auth_token,
      projectListLoaderState,
      uploadPhotoToS3BucketAction,
      createProjectAction,
      projectList,
      currentUser,
      tabState,
      router,
      searchContent,
      unArchivedArchivedProject,
      currentWorkSpace,
    } = this.props;
    if (
      auth_token &&
      currentWorkSpace &&
      projectList &&
      currentWorkSpace.hasOwnProperty("id")
    ) {
      const projectsByAccess = getProjectsByAccess(
        projectList,
        currentUser,
        currentWorkSpace,
        tabState
      );
      // const filteredGroupList =
      //   projectsByAccess.length > 0
      //     ? getUpdatedGroupList(projectsByAccess, tabState)
      //     : [];
      // console.log("filteredGroupList", filteredGroupList);
      if (searchContent)
        filteredProjects = getFilteredProjects(
          projectsByAccess,
          tabState,
          searchContent
        );
      const shouldRenderAddButton = getShouldRenderAddButton(
        currentUser,
        currentWorkSpace
      );
      return (
        <React.Fragment>
          {/* <Header/> */}
          <div className="project-dashboard-main-card">
            <div className="project_block project-dashboard">
              {searchContent && filteredProjects.length === 0 && (
                <div
                  className="wl-filter-description not-found"
                  // ng-if="s.search && projectScope.groupedProjects.length === 0"
                >
                  {" "}
                  No results found
                </div>
              )}
              {projectListLoaderState.fetched &&
                projectsByAccess.length === 0 &&
                !searchContent && (
                  <div
                    className="wl-filter-description not-found"
                    // ng-if="s.search && projectScope.groupedProjects.length === 0"
                  >
                    {" "}
                    No projects found in {getStatusView(tabState)}
                  </div>
                )}
              <Scrollbar className="project-land-scroll">
                {!searchContent && projectsByAccess.length > 0 ? (
                  // Added "project-dashboard-cards-wrapper" div to show in same line after removing gruops
                  <div className="project-dashboard-cards-wrapper">
                    {/* filteredGroupList changed to projectsByAccess in map function to remove groups */}
                    {_.sortBy(projectsByAccess, project =>
                      project.name.toLowerCase()
                    ).map((project, index) => (
                      <ProjectCard
                        key={index}
                        project={project}
                        router={router}
                        unArchivedArchivedProject={unArchivedArchivedProject}
                      />
                    ))}
                    {/* <ProjectGroups
                      key={index}
                      group={group}
                      projects={projectsByAccess}
                      searchContent={searchContent}
                      unArchivedArchivedProject={unArchivedArchivedProject}
                      router={router}
                    /> */}
                  </div>
                ) : (
                  <div className="project-dashboard-cards-wrapper">
                    {filteredProjects.map((project, index) => (
                      <ProjectCard
                        key={index}
                        project={project}
                        router={router}
                        unArchivedArchivedProject={unArchivedArchivedProject}
                      />
                    ))}
                  </div>
                )}
                {shouldRenderAddButton && (
                  <AddFeatures onShowDialog={this.onShowDialog} />
                )}
                <div className="md-dialog-container">
                  <div className="add-project-fe project-setting-main-container">
                    {/* <div className="add-projectSettings-container"> */}
                    {showDialog && shouldRenderAddButton && (
                      <AddProjectDialog
                        onShowDialog={this.onShowDialog}
                        onAddProject={this.onAddProject}
                        uploadPhotoToS3BucketAction={
                          uploadPhotoToS3BucketAction
                        }
                        createProjectAction={createProjectAction}
                        currentWorkSpace={currentWorkSpace}
                      />
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (projectListLoaderState.fetched && projectListLoaderState.error) {
      return <Loader size={100} loading={true} />;
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    auth_token: state.loginReducer.loginAuth.auth_token,
    projectListLoaderState: state.projectReducer.projectListLoaderState,
    projectList: state.projectReducer.listOfProjects.list,
    tabState: state.commonReducer.tabState.projectsLanding,
    searchContent: state.commonReducer.searchContent.text,
    currentWorkSpace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectAction({ auth_token, queryParam }, callback) {
      dispatch(action.getProjectsAction({ auth_token, queryParam }, callback));
    },
    unArchivedArchivedProject: ({ body }, callback) => {
      dispatch(unArchivedArchivedProject({ body }, callback));
    },
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        uploadPhotoToS3Bucket(
          {
            file,
            loaderKey,
          },
          callback
        )
      );
    },
    createProjectAction: (body, callback) => {
      dispatch(action.createProjectAction(body, callback));
    },
    updateTabStateAction({ projectsLanding }) {
      dispatch(updateTabState({ projectsLanding }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsLanding);
