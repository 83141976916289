import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import CommonNav from "./commonnav";
import searchIcon from "../../styles/img/svg/search-join.svg";
import cancelIcon from "../../styles/img/svg/cancel-join.svg";
import JoinWorkspaceTile from "./joinworkspacetile";
import "../../styles/scss/joinWorkspace.scss";
import { findCustomers } from "../../services/workspaceapi";
import routesHelper from "../../router/routeshelper";
import ProjectEditHeader from "../common/projectEditHeader";
import {
  // getUnsplashImage,
  getShouldCreateWorkspace
} from "../../helpers/generichelper";
import firebase from "../../firebase";

class FindWorkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundCustomers: [],
      searchText: null
    };
  }

  componentDidMount() {
    const { router } = this.props;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    if(!fromNavbar) {
      firebase.analytics().logEvent("GTD_Registration_Flow_Find_Workspace");
    }
    const auth_token = localStorage.getItem("auth_token");
    this.props.getAllPendingListAction(auth_token, response => {
      if (!response.stdErr && response?.length >= 0) {
        //  //console.log("costomers", pendingResponse)
        this.props.getAllPendingInviteListAction(auth_token, pendingResponse => {
          if (pendingResponse && !pendingResponse.error) {
            //  //console.log("costomers", pendingResponse)
          }
        });
      }
    });
  }

  findCustomersInitiated = (event) => {
    let searchvalue = event.target.value;
    this.setState({
      searchText: searchvalue,
      foundCustomers: []
    });
    const auth_token = localStorage.getItem("auth_token");
    const user_id = localStorage.getItem("id");
    searchvalue &&
      findCustomers(
        { auth_token, key: searchvalue, user_id },
        (response, searchKey) => {
          if (response && !response.error) {
            searchKey === this.searchBoxRef.value &&
              this.setState({
                foundCustomers: response.length > 0 ? response : null
              });
          } else {
            this.setState({
              foundCustomers: []
            });
          }
        }
      );
  };

  onClearText = () => {
    this.searchBoxRef.value = "";
    this.setState({
      searchText: null,
      foundCustomers: []
    });
  };

  requestToJoinWorkSpace = (workspace, callback) => {
    const { requestToJoinWorkSpace } = this.props;
    requestToJoinWorkSpace &&
      requestToJoinWorkSpace(workspace, (response) => {
        this.setState({ foundCustomers: this.state.foundCustomers.filter(fc => fc.customer_id !== workspace.customer_id) })
        callback && callback(response);
      });
  };

  renderFoundWorkSpaces = () => {
    const { foundCustomers } = this.state;
    const { requestedToJoinWorkspace, listOfPendingCustomerInvites } = this.props;
    const customerCards = foundCustomers
    .filter(fc => requestedToJoinWorkspace.list.every(rj => fc.customer_id !== rj.customer_id))
    .filter(fc => listOfPendingCustomerInvites.list.every(pi => fc.customer_id !== pi.id))
    .map((workspace) => {
      if (!workspace.is_requested) {
        return (
          <JoinWorkspaceTile
            key={workspace.customer_id}
            workspace={workspace}
            requestToJoin={this.requestToJoinWorkSpace}
            type="notRequested"
          />
        );
      }
      return null;
    });
    // foundCustomers.forEach(workspace => {
    //   customerCards.push()
    // });
    // if(customerCards.length > 0) {
    //     return customerCards;
    // }
    return customerCards;
  };

  createWorkspaceCallback = () => {
    const { router } = this.props;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    if (fromNavbar) {
      router.push({
        pathname: routesHelper.getCreateWorkspacePath(),
        state: {
          from: "nav-bar",
          listOfCustomer: router.location.state.listOfCustomer,
          currentUser: router.location.state.currentUser
        }
      });
    } else router.push(routesHelper.getCreateWorkspacePath());
  };
  renderRequestedToJoinWorkspace = () => {
    const { requestedToJoinWorkspace } = this.props;
    const renderedItems = requestedToJoinWorkspace.list.map((requested) => {
      return (
        <JoinWorkspaceTile
          key={requested.customer_id}
          workspace={requested}
          requestToJoin={this.requestToJoinWorkSpace}
          type="requested"
        />
      );
    });
    return renderedItems;
  };

  render() {
    const {
      router,
      requestedToJoinWorkspace,
      totalTimeForTheDay,
      selectedCustomerDetails,
      currentUser
    } = this.props;

    const { searchText, foundCustomers } = this.state;
    // const haveMyWorkspace = localStorage.getItem("haveMyWorkspace");
    // const { style } = getUnsplashImage();
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    const listOfCustomer =
      router.location.state && router.location.state.listOfCustomer
        ? router.location.state.listOfCustomer
        : [];
    const userDetails =
      router.location.state && router.location.state.currentUser
        ? router.location.state.currentUser
        : {};
    const shouldCreateWorkspace = getShouldCreateWorkspace(
      listOfCustomer,
      userDetails
    );
    return (
      <React.Fragment>
        {fromNavbar && (
          <ProjectEditHeader
            router={router}
            shouldRenderFindWorkspace={true}
            totalTimeForTheDay={totalTimeForTheDay}
            selectedCustomerDetails={selectedCustomerDetails}
            currentUser={currentUser}
          />
        )}
        <Scrollbar
          className={`add-users-scroll ${fromNavbar ? "scroll-height" : ""}`}
        >
          <div className="joinWorkspace-container">
            {!fromNavbar ? (
              <div>
                <CommonNav router={router} />
              </div>
            ) : (
              <div className="create-find-head"></div>
            )}
            <div
              className={`container-find ${
                fromNavbar
                  ? `from-nav-bar-container-find ${
                      searchText || requestedToJoinWorkspace.list.length > 0
                        ? "more-height"
                        : ""
                    }`
                  : searchText || requestedToJoinWorkspace.list.length > 0
                  ? "more-height"
                  : ""
              }`}
            >
              <div className="join-header">
                <p className="join-title">Find your workspace </p>
              </div>
              {/* --Workspace search code starts here-- */}

              <div className="join-search-container">
                <div className="search-line">
                  <img className="search-icon-join" src={searchIcon} alt="search-icon" />
                  <input
                    type="text"
                    id="joinSearch"
                    ref={(node) => {
                      this.searchBoxRef = node;
                    }}
                    className="search-holder"
                    onKeyUp={this.findCustomersInitiated}
                    autoFocus
                    placeholder="Enter the name of the workspace you wish to find"
                  />

                  {searchText && (
                    <img
                      className="cancel-icon-join"
                      src={cancelIcon}
                      alt="cancel-icon"
                      onClick={this.onClearText}
                    />
                  )}
                </div>
              </div>

              {/* $$ Should appear showing the workspaces being searched for */}
              <div
                className={`choose-workspace searched-workspace ${
                  fromNavbar
                    ? `from-navbar-searched-workspace ${
                        searchText || requestedToJoinWorkspace.list.length > 0
                          ? "more-height"
                          : ""
                      }`
                    : searchText || requestedToJoinWorkspace.list.length > 0
                    ? "more-height"
                    : ""
                }`}
              >
                {foundCustomers &&
                  foundCustomers.length > 0 &&
                  this.renderFoundWorkSpaces()}
                {!foundCustomers && (
                  <div className="join-no-results">
                    <p className="join-no-title">No workspaces found</p>
                  </div>
                )}
                {requestedToJoinWorkspace.list.length > 0 && (
                  <p className="join-title-pending">Pending List</p>
                )}
                {requestedToJoinWorkspace &&
                  this.renderRequestedToJoinWorkspace()}
              </div>

              {/* $$ If workspace isnt found then the following div should appear */}

              {/* $$ pending requests of workspace is shown here- if the user has already requested to join that
                workspace*/}
              {/* <div className="pending-container">
                    <p className="join-title-pending">
                        Pending Request
                    </p>
                    <div className="choose-workspace">
                        <JoinWorkspaceTile img="https://s3.amazonaws.com/gtd/gtd-placeholder.png" />
                    </div>
                </div> */}
            </div>
            {shouldCreateWorkspace && (
              <div className="join-container-footer">
                <p className="not-expec">
                  Didn’t find the workspace you were looking for?{" "}
                  <span
                    onClick={this.createWorkspaceCallback}
                    className="find-workspace"
                  >
                    {" "}
                    Create a workspace
                  </span>
                </p>
              </div>
            )}
          </div>
        </Scrollbar>
      </React.Fragment>
    );
  }
}

export default FindWorkspace;
