import routesHelper from "../router/routeshelper";

export const kanbanConstant = {
  defaultBreadcrumb: [
    {
      name: "",
      link: routesHelper.kanbanPath,
      type: "kanban"
    }
  ],
  fromKanban: "FROM_KANBAN"
};
