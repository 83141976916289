import React, { Component } from "react";
import { connect } from "react-redux";
import routesHelper from "../../router/routeshelper";
// import { getUnsplashImage } from "../../helpers/generichelper";
import HeaderWrapperSettings from "../common/headerWrapperSettings";
import "../../styles/scss/settingspage.scss";
import SettingsTab from "./settingsTab.jsx";
import ProjectSettings from "./projectSettings";
import PeopleSettings from "../people/peoplesettings";
import BiliingView from "../billing/biliingView";
import _ from "lodash";
import {
  getAllCustomerForUserAction,
  updateCustomerDetailsAction,
  getListOfCurrenciesAction
} from "../../actions/workspace/workspaceaction";
import { getProjectsAction } from "../../actions/project/projectaction";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import WorkspaceSettings from "./workspaceSettings";
import { getCustomerInfo } from "../../actions/billing/billing";

class SettingsLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: undefined
    };
    this.onTabClick = this.onTabClick.bind(this);
  }

  componentDidMount() {
    const { router, getListOfCurrenciesAction } = this.props;
    const auth_token = routesHelper.getAuthToken();
    if (!auth_token) {
      router.push(routesHelper.getLoginPath());
    } else {
        getListOfCurrenciesAction();
      // this.props.getAllCustomersAction(response => {});
      // getCustomerInfo(response => {});
    }
  }

  // currentWorkSpace() {
  //   const currentUserId = localStorage.getItem("id");
  //   return this.props.listOfCustomer.list.filter(
  //     element => element.id == currentUserId
  //   )[0];
  // }

  onTabClick(selectedTab) {
    const { router } = this.props;
    router.push({
      pathname: "/settings",
      state: {
        tabToGo: selectedTab
      }
    });
    this.setState({
      selectedTab: selectedTab
    });
  }

  render() {
    const {
      // location,
      updateCustomerDetailsAction,
      billingInfo,
      router,
      listOfCustomer,
      updateCustomerLoaderState
    } = this.props;
    const selectedTab =
      this.state.selectedTab ||
      (router.location.state &&
        router.location.state.hasOwnProperty("tabToGo") &&
        router.location.state.tabToGo) ||
      "people";

    // const currentUserId = localStorage.getItem("id");
    const currentCustomerId = parseInt(localStorage.getItem("customer_id"));
    const currentWorkSpace = listOfCustomer.list.filter(
      element => element.id === currentCustomerId
    )[0];
    const showNoSubscriptionWarning = billingInfo.error
      ? true
      : billingInfo.data &&
        billingInfo.data.length > 0 &&
        billingInfo.data[0].status === "expired"
      ? true
      : false;
    const currencyLogo = (currentWorkSpace && currentWorkSpace.data.settings &&
      currentWorkSpace.data.settings.currency && this.props.listOfCurrencies.length > 0 &&
      this.props.listOfCurrencies.filter(d =>
        currentWorkSpace.data.settings.currency.includes(d.code)
      )[0].symbol) || "$"
    return (
      <div className="main-container">
        <HeaderWrapperSettings
          router={this.props.router}
          currentWorkSpace={currentWorkSpace}
        ></HeaderWrapperSettings>
        <div className="settings-container">
          <SettingsTab
            onTabClick={this.onTabClick}
            selectedTab={selectedTab}
          ></SettingsTab>
          {selectedTab === "project" && (
            <ProjectSettings
              currentWorkSpace={currentWorkSpace}
              currencyLogo={currencyLogo}
              showNoSubscriptionWarning={showNoSubscriptionWarning}
              onTabClick={this.onTabClick}
              router={router}
            ></ProjectSettings>
          )}
          {selectedTab === "people" &&
            !_.isUndefined(this.props.listOfCustomer) && (
              <PeopleSettings
                listOfCustomer={this.props.listOfCustomer}
                currencyLogo={currencyLogo}
                currentWorkSpace={currentWorkSpace}
                showNoSubscriptionWarning={showNoSubscriptionWarning}
                onTabClick={this.onTabClick}
                router={router}
                getProjectsAction={this.props.getProjectsAction}
              ></PeopleSettings>
            )}

            {selectedTab === "workspace" &&
              !_.isUndefined(this.props.listOfCustomer) &&
              this.props.listOfCustomer.list.length > 0 && (
                <WorkspaceSettings
                  listOfCustomer={this.props.listOfCustomer}
                  listOfCurrencies={this.props.listOfCurrencies}
                  currentWorkSpace={currentWorkSpace}
                  currentUser={this.props.currentUser}
                  updateCustomerDetailsAction={updateCustomerDetailsAction}
                  updateCustomerLoaderState={updateCustomerLoaderState}
                  showNoSubscriptionWarning={showNoSubscriptionWarning}
                  onTabClick={this.onTabClick}
                  getWorkspaceUsers={this.props.getAllCustomer}
                  getProjectsAction={this.props.getProjectsAction}
                ></WorkspaceSettings>
              )}

          {selectedTab === "billing" &&
            !_.isUndefined(this.props.listOfCustomer) &&
            this.props.listOfCustomer.list.length > 0 && (
              <BiliingView
                currentWorkSpace={currentWorkSpace}
                currentUser={this.props.currentUser}
                updateCustomerDetailsAction={updateCustomerDetailsAction}
              ></BiliingView>
            )}
        </div>

        {/* <div className="unslpash-credits clients-credits">
            Photo by &nbsp;
            <a href={author_link} target="_blank">
              {author}
            </a>
            <a href={credits_link} target="_blank">
              Unsplash
            </a>
          </div> */}
      </div>
      // {/* </div> */}
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfCustomer: state.wrorkSpaceReducer.listOfCustomer,
    listOfCurrencies: state.wrorkSpaceReducer.listOfCurrencies,
    updateCustomerLoaderState: state.wrorkSpaceReducer.updateCustomerLoaderState,
    currentUser: state.loginReducer.loginAuth.user_details,
    billingInfo: state.billingReducer.billingInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllCustomersAction: ({ auth_token }, callback) => {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token
          },
          callback
        )
      );
    },
    updateCustomerDetailsAction: (
      {
        auth_token,
        display_name,
        data,
        id,
        img,
        img_name,
        super_admin_id,
        users
      },
      callback
    ) => {
      dispatch(
        updateCustomerDetailsAction(
          {
            auth_token,
            display_name,
            data,
            id,
            img,
            img_name,
            super_admin_id,
            users
          },
          callback
        )
      );
    },
    getCustomerInfo: callback => {
      dispatch(getCustomerInfo(callback));
    },
    getListOfCurrenciesAction: (callback) => {
      dispatch(getListOfCurrenciesAction(callback))
    },
    getAllCustomer: ({ auth_token }, callback) => {
      dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    },
    getProjectsAction({ auth_token, queryParam }, callback) {
      dispatch(getProjectsAction({ auth_token, queryParam }, callback));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsLanding);
