export const ArchivedConstant = {
  activity: "ACTIVITY",
  project: "PROJECT",
  checklist: "CHECKLIST",
  getActivity: "GET_ACTIVITY",
  getSearchedArchivedActivity: "GET_SEARCHED_ARCHIVED_ACTIVITY",
  clearSearchedArchivedActivity: "CLEAR_SEARCHED_ARCHIVED_ACTIVITY",
  getProject: "GET_PROJECT",
  getChecklist: "GET_CHECKLIST",
  unarchiveActivity: "UNARCHIVE_ACTIVITY",
  updateActivity: "UPDATE_ARCHIVE_ACTIVITY",
  updateArchiveActivityWorkFlow: "CHANGE_WORKFLOW_ARCHIVE_ACTIVITY",
  unarchiveProject: "UNARCHIVE_PROJECT",
  breadCrumbMain: [
    { name: "Archive", link: "/archive-center", type: "heading" }
  ],
  breadCrumbAllActivity: [
    {
      name: "Archived Activities",
      link: "/archive-center/all-activities",
      type: "SubHeading"
    },
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon",
      SearchClassName: "general-page-search-box-parent"
    }
  ],
  breadCrumbAllProject: [
    {
      name: "Archived Projects",
      link: "/archive-center/all-projects",
      type: "SubHeading"
    },
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon",
      SearchClassName: "general-page-search-box-parent"
    }
  ],
  breadCrumbAllChecklists: [
    {
      name: "Archived Checklists",
      link: "/archive-center/all-checklists",
      type: "SubHeading"
    },
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon",
      SearchClassName: "general-page-search-box-parent"
    }
  ],
  breadCrumbAllComments: [
    {
      name: "Archived Comments",
      link: "/archive-center/all-comments",
      type: "SubHeading"
    },
    {
      name: "",
      link: "",
      type: "search",
      className: "general-page-search-icon",
      SearchClassName: "general-page-search-box-parent"
    }
  ]

  // {name: "Archived Activity" , link: 'activity/active', type: 'tabs' , className:'active-tab'},
  // {name: "Archived Project", link: 'activity/archived', type: 'tabs',  className:''},
  // {name: 'Archived Checklist', link: '', type: 'search', className: 'general-page-search-icon', SearchClassName: 'general-page-search-box-parent'}
};
