import { workspaceConstant } from "../../constants/workspaceconstants";
import { findIndex, propEq, remove } from "ramda";
import _ from "lodash";
import { ArchivedConstant as archivedConstant } from "../../constants/archivedConstant";

import { activities } from "../../constants/activitiescontants";

const initialState = {
  activityLoaderState: {
    fetching: false,
    fetched: false,
    error: {
      bool: false,
      value: ""
    },
    key: null
  },

  archiveOrUnarchiveLoaderState: {
    fetching: false,
    fetched: false,
    error: {
      bool: false,
      value: ""
    },
    key: null
  },
  activitiesByProjectLoaderState: {
    fetching: false,
    fetched: false,
    error: false
  },

  listOfActivity: {
    list: [],
    type: null,
    key: null
  },

  listOfArchivedActivity: {
    list: [],
    type: null,
    key: null
  },

  listOfSearchedArchivedActivity: {
    list: [],
    type: null,
    key: null
  },

  prioritiZedActivity: {
    list: [],
    type: null,
    key: null
  },
  activitiesByProject: [],
  activityTypeList: {
    list: [],
    key: null
  },
  totalActivityCount: 0
};

export default function activityReducer(state = initialState, action) {
  switch (action.type) {
    case workspaceConstant.activityLoaderState:
      return {
        ...state,
        activityLoaderState: action.payload
      };

    case workspaceConstant.archiveOrUnArchiveLoader:
      return {
        ...state,
        archiveOrUnarchiveLoaderState: action.payload
      };

    case activities.activitiesByProjectLoaderState:
      return {
        ...state,
        activitiesByProjectLoaderState: action.payload
      };

    case workspaceConstant.archiveOrUnarchive:
      const completeUnarchievedActivities = [
        ...state.listOfActivity.list,
        ...state.prioritiZedActivity.list
      ];
      let archivedActivities =
        action.payload.archiveType === activities.unArchive
          ? {
              ...state.listOfArchivedActivity,
              list: removeItemFromList(
                action.payload.archivedItem,
                state.listOfArchivedActivity.list
              )
            }
          : {
              ...state.listOfArchivedActivity,
              list: [
                ...state.listOfArchivedActivity.list,
                {
                  ...completeUnarchievedActivities.filter(
                    activity => activity.id === action.payload.archivedItem.id
                  )[0],
                  ...action.payload.archivedItem,
                  data: action.payload.archivedItem.data,
                  completed_on: action.payload.archivedItem.completed_on
                }
              ]
            };

      let listOfActivity =
        action.payload.archiveType === activities.archive
          ? {
              ...state.listOfActivity,
              list: removeItemFromList(
                action.payload.archivedItem,
                state.listOfActivity.list
              )
            }
          : state.listOfActivity;
      let prioritiZedActivity =
        action.payload.archiveType === activities.archive
          ? {
              ...state.prioritiZedActivity,
              list: removeItemFromList(
                action.payload.archivedItem,
                state.prioritiZedActivity.list
              )
            }
          : state.prioritiZedActivity;
      return {
        ...state,
        listOfArchivedActivity: archivedActivities,
        listOfActivity,
        prioritiZedActivity
      };

    case workspaceConstant.setPrioritizedActivity:
      return {
        ...state,
        prioritiZedActivity: {
          list: action.payload.activities
        }
      };
    case workspaceConstant.prioritizeOrDeprioritize: {
      let listOfActivity;
      let prioritiZedActivity;
      if (action.payload.priorityType === activities.prioritize) {
        const itemTobeRemoved = state.listOfActivity.list.filter(
          list => list.id === action.payload.prioritizeItem.id
        )[0];
        const changedRemovedItem = {
          ...itemTobeRemoved,
          data: { ...action.payload.prioritizeItem.data }
        };
        listOfActivity = state.listOfActivity.list.filter(
          list => list.id !== action.payload.prioritizeItem.id
        );
        prioritiZedActivity = state.prioritiZedActivity.list.concat(
          changedRemovedItem
        );
        return {
          ...state,
          listOfActivity: { ...state.listOfActivity, list: listOfActivity },
          prioritiZedActivity: {
            ...state.prioritiZedActivity,
            list: prioritiZedActivity
          }
        };
      }

      if (action.payload.priorityType === activities.dePrioritize) {
        const itemTobeRemoved = state.prioritiZedActivity.list.filter(
          list => list.id === action.payload.prioritizeItem.id
        )[0];
        const changedRemovedItem = {
          ...itemTobeRemoved,
          data: { ...action.payload.prioritizeItem.data }
        };
        prioritiZedActivity = state.prioritiZedActivity.list.filter(
          list => list.id !== action.payload.prioritizeItem.id
        );
        listOfActivity = state.listOfActivity.list.concat(changedRemovedItem);
        return {
          ...state,
          listOfActivity: { ...state.listOfActivity, list: listOfActivity },
          prioritiZedActivity: {
            ...state.prioritiZedActivity,
            list: prioritiZedActivity
          }
        };
      }
    }
    break;

    case workspaceConstant.prioritizeOrDeprioritizeKanban: {
      let activitiesByProject;
      let prioritiZedActivity;
      if (action.payload.priorityType === activities.prioritize) {
        const itemTobeAddedInPrioritize = state.activitiesByProject.filter(
          list => list.id === action.payload.prioritizeItem.id
        )[0];
        const changedItem = {
          ...itemTobeAddedInPrioritize,
          data: { ...action.payload.prioritizeItem.data }
        };
        activitiesByProject = state.activitiesByProject.map(list =>
          list.id === action.payload.prioritizeItem.id ? changedItem : list
        );
        prioritiZedActivity = state.prioritiZedActivity.list.concat(
          changedItem
        );
        return {
          ...state,
          prioritiZedActivity: {
            ...state.prioritiZedActivity,
            list: prioritiZedActivity
          },
          activitiesByProject
        }
      }
      if (action.payload.priorityType === activities.dePrioritize) {
        const itemTobeRemovedFromPrioritize = state.activitiesByProject.filter(
          list => list.id === action.payload.prioritizeItem.id
        )[0];
        const changedItem = {
          ...itemTobeRemovedFromPrioritize,
          data: { ...action.payload.prioritizeItem.data }
        };
        activitiesByProject = state.activitiesByProject.map(list =>
          list.id === action.payload.prioritizeItem.id ? changedItem : list
        );
        prioritiZedActivity = state.prioritiZedActivity.list.filter(
          list => list.id !== action.payload.prioritizeItem.id
        );
        return {
          ...state,
          prioritiZedActivity: {
            ...state.prioritiZedActivity,
            list: prioritiZedActivity
          },
          activitiesByProject
        }
      }
    }
    break;

    case workspaceConstant.prioritizeOrDeprioritizeNotification: {
      if (action.payload.priorityType === activities.prioritize)
        return {
          ...state,
          prioritiZedActivity: {
            ...state.prioritiZedActivity,
            list: [ ...state.prioritiZedActivity.list, action.payload.prioritizeItem ] 
          }
        }
        if (action.payload.priorityType === activities.dePrioritize)
          return {
            ...state,
            prioritiZedActivity: {
              ...state.prioritiZedActivity,
              list: state.prioritiZedActivity.list.filter(item => item.id !== action.payload.prioritizeItem.id) 
            }
          }
    }
    break;

    case workspaceConstant.activityList: {
      return {
        ...state,
        listOfActivity: {
          list: action.payload.listOfActivity.nonPrioritized,
          type: action.payload.type,
          page: action.payload.page,
          key: action.payload.key
        },
        prioritiZedActivity: {
          list: action.payload.listOfActivity.prioritized,
          type: action.payload.type,
          key: action.payload.key
        },
        activityTypeList: {
          list: action.payload.listOfActivity.tagsList,
          key: action.payload.key
        }
      };
    }

    case workspaceConstant.archivedActivityList: {
      return {
        ...state,
        listOfArchivedActivity: {
          list: [...action.payload.listOfActivity],
          type: action.payload.type,
          key: action.payload.key
        },
        activityTypeList: {
          list: activities.tagsList,
          key: action.payload.key
        }
      };
    }

    case workspaceConstant.moreArchivedActivityList: {
      return {
        ...state,
        listOfArchivedActivity: {
          list: [
            ...state.listOfArchivedActivity.list,
            ...action.payload.listOfActivity
          ],
          key: action.payload.key
        },
        activityTypeList: {
          list: activities.tagsList,
          key: action.payload.key
        }
      };
    }

    case archivedConstant.getSearchedArchivedActivity: {
      return {
        ...state,
        listOfSearchedArchivedActivity: {
          list: [...action.payload.activities],
          type: action.payload.type,
          key: action.payload.key
        }
      };
    }

    case activities.changeWorkFLowPriority: {
      return {
        ...state,
        prioritiZedActivity: {
          list: state.prioritiZedActivity.list.map(listIn => {
            return listIn.id === action.payload.id
              ? {
                  ...listIn,
                  data: action.payload.data,
                  workflow: action.payload.workflow
                }
              : { ...listIn };
          })
        }
      };
    }
    case activities.changeWorkFLow: {
      if (action.payload.type && action.payload.type === "UNARCHIVE") {
        return {
          ...state,
          listOfArchivedActivity: {
            list: state.listOfArchivedActivity.list.map(listIn => {
              return listIn.id === action.payload.id
                ? {
                    ...listIn,
                    data: action.payload.data,
                    workflow: action.payload.workflow
                  }
                : listIn;
            })
          }
        };
      } else if (action.payload.type && action.payload.type === "KANBAN") {
        return {
          ...state,
          activitiesByProject: state.activitiesByProject.map(listIn => {
              return listIn.id === action.payload.id
                ? {
                    ...listIn,
                    data: action.payload.data,
                    workflow: action.payload.workflow
                  }
                : listIn;
            })
        };
      } else
      return {
        ...state,
        listOfActivity: {
          list: state.listOfActivity.list.map(listIn => {
            return listIn.id === action.payload.id
              ? {
                  ...listIn,
                  data: action.payload.data,
                  workflow: action.payload.workflow
                }
              : listIn;
          })
        }
      };
    }

    case activities.createTimesheet: {
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(listIn => {
            return listIn.id === action.payload.id
              ? {
                  ...listIn,
                  timesheets: [...listIn.timesheets, action.payload.timesheet]
                }
              : listIn;
          })
        }
      };
    }

    case activities.updateTimesheet: {
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(listIn => {
            return listIn.id === action.payload.id
              ? {
                  ...listIn,
                  timesheets: listIn.timesheets.map(timesheetExisting => {
                    return timesheetExisting.id === action.payload.timesheet.id
                      ? action.payload.timesheet
                      : timesheetExisting;
                  })
                }
              : listIn;
          })
        }
      };
    }
    case workspaceConstant.totalActivityCount: {
      return {
        ...state,
        totalActivityCount: action.payload.count
      };
    }

    case activities.add.activity.priority: {
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: _.uniqBy([...state.prioritiZedActivity.list, action.payload.activity], "id")
        },
        // totalActivityCount: state.totalActivityCount + 1
      };
    }

    case activities.add.activity.notPriority: {
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: _.uniqBy([...state.listOfActivity.list, action.payload.activity], "id")
        },
        // totalActivityCount: state.totalActivityCount + 1
      };
    }
    case activities.remove.activity: {
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.filter(a => a.id !== action.payload.id)
        },
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.filter(a => a.id !== action.payload.id)
        }
      }
    }
    case activities.updateUnarchive: {
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.filter(
            activity => activity.id !== action.payload.activity.id
          )
        }
      };
    }
    case activities.edit.activity.priority: {
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(list =>
            list.id === action.payload.activity.id
              ? { ...list, ...action.payload.activity }
              : list
          )
        }
      };
    }

    case activities.edit.activity.notPriority: {
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(list =>
            list.id === action.payload.activity.id
              ? { ...list, ...action.payload.activity }
              : list
          )
        }
      };
    }

    case activities.edit.activity.kanbanActivity:
      return {
        ...state,
        activitiesByProject: state.activitiesByProject.map(list =>
            list.id === action.payload.activity.id
              ? { ...list, ...action.payload.activity }
              : list
          )
      }

    case activities.updateUnarchiveActivity:
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity.id
              ? { ...activity, ...action.payload.activity }
              : activity
          )
        }
      };

    case activities.getActivitiesByProject:
      return {
        ...state,
        activitiesByProject: action.payload.activities
      };

    case workspaceConstant.updateDeprioritizedActivities:
      // console.log("action.payload.list", action.payload.list);
      return {
        ...state,
        listOfActivity: {
          list: _.uniqBy(
            [...state.listOfActivity.list, ...action.payload.list],
            "id"
          ),
          page: action.payload.page,
          key: action.payload.key
        }
      };
    case workspaceConstant.clearReducer:
      return {
        ...state,
        listOfActivity: {
          list: action.payload.list,
          key: action.payload.key
        }
      };
    case workspaceConstant.clearReducerWithPrioritize:
      return {
        ...state,
        listOfActivity: {
          list: action.payload.list,
          key: action.payload.key
        },
        prioritiZedActivity: {
          list: action.payload.list,
          key: action.payload.key
        },
        listOfArchivedActivity: {
          list: action.payload.list,
          key: action.payload.key
        }
      };

    case activities.udateChecklistCount.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count + 1
                }
              : activity
          )
        }
      };

    case activities.udateChecklistCount.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count + 1
                }
              : activity
          )
        }
      };
    // ****
    case activities.updateChecklistCountAfterArchive.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count - 1
                }
              : activity
          )
        }
      };

    case activities.updateChecklistCountAfterArchive.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count - 1
                }
              : activity
          )
        }
      };
    case activities.updateChecklistCountAfterAddingInArchivePage:
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count + 1
                }
              : activity
          )
        }
      };
    case activities.updateArchiveCardChecklistCountAfterArchive:
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  active_checklist_count: activity.active_checklist_count - 1
                }
              : activity
          )
        }
      };

    case activities.updateCommentsCount.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: activity.comments_count + 1
                }
              : activity
          )
        }
      };
    case activities.updateCommentsCount.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: activity.comments_count + 1
                }
              : activity
          )
        }
      };
    // new
    case activities.updateArchivedCommentsCount:
      // console.log("updateArchivedCommentsCount reducer is called");
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: activity.comments_count + 1
                }
              : activity
          )
        }
      };
    case activities.updateArchivedCommentsCountForDelete:
      // console.log("updateArchivedCommentsCountForDelete reducer is called");
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: action.payload.count
                }
              : activity
          )
        }
      };

    case activities.updateDeleteCommentsCount.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: action.payload.count
                }
              : activity
          )
        }
      };
    case activities.updateDeleteCommentsCount.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  comments_count: action.payload.count
                }
              : activity
          )
        }
      };

    case activities.updateAttachmentCount.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count: activity.attachments_count + 1
                }
              : activity
          )
        }
      };
    case activities.updateAttachmentCount.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count: activity.attachments_count + 1
                }
              : activity
          )
        }
      };
    // for deleting attachment
    case activities.updateAttachmentCountAfterDelete.PRIORITY:
      return {
        ...state,
        prioritiZedActivity: {
          ...state.prioritiZedActivity,
          list: state.prioritiZedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count:
                    activity.attachments_count === 0
                      ? 0
                      : activity.attachments_count - 1
                }
              : activity
          )
        }
      };
    case activities.updateAttachmentCountAfterDelete.NOTPRIORITY:
      return {
        ...state,
        listOfActivity: {
          ...state.listOfActivity,
          list: state.listOfActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count:
                    activity.attachments_count === 0
                      ? 0
                      : activity.attachments_count - 1
                }
              : activity
          )
        }
      };
    //
    case activities.updateArchiveCardAttachmentCountAfterDelete:
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count:
                    activity.attachments_count === 0
                      ? 0
                      : activity.attachments_count - 1
                }
              : activity
          )
        }
      };
    case activities.updateArchiveCardAttachmentCount:
      return {
        ...state,
        listOfArchivedActivity: {
          ...state.listOfArchivedActivity,
          list: state.listOfArchivedActivity.list.map(activity =>
            activity.id === action.payload.activity_id
              ? {
                  ...activity,
                  attachments_count: activity.attachments_count + 1
                }
              : activity
          )
        }
      };
      case activities.UPDATEALLACTIVITIES:
        let listofActivityUpdate=[];
        let prioritiZedActivityUpdate=[];
        console.log('state',state);

        if(state.listOfActivity && state.listOfActivity.list && state.listOfActivity.list.length>0){
          listofActivityUpdate=state.listOfActivity.list.filter(indActivity=>indActivity.id !== action.payload.activity_id.activity_id);
        }
        console.log('listofActivityUpdate',listofActivityUpdate)


        if(state.prioritiZedActivity && state.prioritiZedActivity.list && state.prioritiZedActivity.list.length>0){
          prioritiZedActivityUpdate=state.prioritiZedActivity.list.filter(indActivity=>indActivity.id !== action.payload.activity_id.activity_id);
        }
  
        console.log('prioritiZedActivityUpdate',prioritiZedActivityUpdate)
        
        let prviousState= {
          ...state
        };
        let previousStatelistofActivity= { ...prviousState.listOfActivity};
        previousStatelistofActivity.list=listofActivityUpdate;
  
    
        let previousStateprioritiZedActivity= { ...prviousState.prioritiZedActivity};
        previousStateprioritiZedActivity.list=prioritiZedActivityUpdate;
  
        return{
          ...state,
           listOfActivity:previousStatelistofActivity,
           prioritiZedActivity:previousStateprioritiZedActivity
        } 
        case activities.udateChecklistCount.PRIORITY_PASSED_COUNT:
          return {
            ...state,
            prioritiZedActivity: {
              ...state.prioritiZedActivity,
              list: state.prioritiZedActivity.list.map(activity =>
                activity.id === action.payload.activity_id
                  ? {
                      ...activity,
                      active_checklist_count: action.payload.checkListCount
                    }
                  : activity
              )
            }
          };
    
        case activities.udateChecklistCount.NOTPRIORITY_PASSED_COUNT:
          return {
            ...state,
            listOfActivity: {
              ...state.listOfActivity,
              list: state.listOfActivity.list.map(activity =>
                activity.id === action.payload.activity_id
                  ? {
                      ...activity,
                      active_checklist_count: action.payload.checkListCount
                    }
                  : activity
              )
            }
          };       
          case activities.UPDATE_PRIORITY_NOTPRIORITY:
            let prioritiZedActivityCopy=_.cloneDeep(state.prioritiZedActivity);
            if (action.payload.priorityType === activities.prioritize) {
              prioritiZedActivityCopy.list.push(action.payload.activity);
            }else{
              prioritiZedActivityCopy.list.splice(activityData=>activityData.id ===action.payload.activity.id,1);
            }
            return {
              ...state,
              prioritiZedActivity:prioritiZedActivityCopy
            }
        case activities.CLEAR_PRIORITY_NOTPRIORITY:
          return{
            ...state,
            prioritiZedActivity: {
              list: [],
              type: null,
              key: null
            }
          }
    default:
      return {
        ...state
      };
  }
  
}

const removeItemFromList = (toBeRemovedItem, List) => {
  let updatedList = [];
  let copyOfList = List.slice();
  let foundIndex = findIndex(propEq("id", toBeRemovedItem.id))(List);
  if (foundIndex > -1) {
    updatedList = remove(foundIndex, 1, copyOfList);
    return updatedList;
  }

  return copyOfList;
};

// async function getActivities(lists) {
//   var prioritized = [];
//   let nonPrioritized = [];
//   let tagsList = [];
//   const user_id = localStorage.getItem("id");

//   lists.forEach(list => {
//     tagsList = list.tags && [...tagsList, ...list.tags];
//     if (list.data.priority) {
//       list.data.priority.forEach(priorityObject => {
//         if (priorityObject.user_id == user_id) {
//           prioritized.push(list);
//         } else {
//           nonPrioritized.push(list);
//         }
//       });
//     }
//   });

//   tagsList = uniq(tagsList);

//   return Promise.resolve({
//     prioritized,
//     nonPrioritized,
//     tagsList
//   });
// }
