import React, { useState, useContext } from "react";
import searchField from "../../styles/img/svg/search-field.svg";
import KanbanContext from "../../contexts/kanban/kabanContext";
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import Avatar from "react-avatar";
import { getProjectById } from "../../services/projectapi";
momentDurationFormatSetup(moment);

const KanbanAddUsersDropdown = props => {
  const { selectedProject, selectedCustomer, updateProjectAction, userMap } = useContext(
    KanbanContext
  );
  const { setUsersInProject, hideAddUsersDropdown, usersInProject } = props;
  const [usersYetToAdd, setUsersYetToAdd] = useState([]);
  const usersInProjectAsObject = {};
  _.forEach(usersInProject, user => {
    usersInProjectAsObject[user.id] = user;
  });
  const remainingWorkspaceUsers = [];
  _.forIn(userMap, (val, key) => {
    if (!usersInProjectAsObject[key]) {
      remainingWorkspaceUsers.push(val);
    }
  });
  const [filteredUsers, setFilteredUsers] = useState([
    ...remainingWorkspaceUsers
  ]);
  const searchUser = searchText => {
    let users = [...remainingWorkspaceUsers];
    users = _.filter(users, user =>
      user.first_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUsers(users);
  };
  const usersYetToAddInner = [...usersYetToAdd];
  const toggleUserAddition = user => {
    if (usersYetToAddInner.indexOf(user.id) === -1) {
      usersYetToAddInner.push(user.id);
    } else {
      let userIndex = usersYetToAddInner.indexOf(user.id);
      usersYetToAddInner.splice(userIndex, 1);
    }
    setUsersYetToAdd(usersYetToAddInner);
  };
  const addUsersToProject = () => {
    getProjectById(selectedProject.archived, selectedProject.id, response => {
      let projectData = response?.length > 0 ? { ...response[0].data } : { ...selectedProject.data };
      _.forEach(usersYetToAdd, user => {
        if (projectData.acl.indexOf(user) === -1) {
          projectData.acl.push(user);
        }
      });
      if (projectData.settings && projectData.settings.member_access) {
        _.forEach(usersYetToAdd, user => {
          if (!projectData.settings.member_access[user]) {
            projectData.settings.member_access[user] = {
              widget: selectedCustomer.selectedWorkspace.users[user] === "SUPERADMIN" ? ["Reports", "Add/Remove members"] : [],
              access: selectedCustomer.selectedWorkspace.users[user] === "SUPERADMIN" ? "Owner" : "Member",
              team: userMap[user].data && 
                userMap[user].data.settings &&
                userMap[user].data.settings[selectedCustomer.selectedWorkspace.id] ?
                userMap[user].data.settings[selectedCustomer.selectedWorkspace.id].team
                : null
            };
          }
        });
      }
      let projectToUpdate = {
        id: selectedProject.id,
        data: projectData,
        updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      };
      const auth_token = localStorage.getItem("auth_token");
      updateProjectAction(
        { auth_token, queryParam: "", body: projectToUpdate },
        response => {
          if (response && response.id) {
            let addedUsers = [];
            let filteredUsersInner = [...filteredUsers];
            _.forEach(usersYetToAdd, userId => {
              if (userMap[userId]) {
                addedUsers.push(userMap[userId]);
              }
            });
            addedUsers = usersInProject.concat(addedUsers);
            filteredUsersInner = _.filter(filteredUsersInner, user => {
              return usersYetToAdd.indexOf(user.id) === -1;
            });
            setUsersInProject(addedUsers);
            setFilteredUsers(filteredUsersInner);
            hideAddUsersDropdown();
          }
        }
      );
    });
  };

  return (
    <div className="users_flow">
      <div className="edit-board-search-holder">
        <input
          type="text"
          className="edit-board-search-control kanban"
          placeholder="Search for a User"
          aria-label="Search"
          autoFocus
          onKeyUp={e => searchUser(e.target.value)}
        />
        <div className="edit-search-icon-holder">
          <img src={searchField} alt="search" />
        </div>
      </div>

      <div className="search-data-container inside-activity proj-user-container">
        {filteredUsers &&
          filteredUsers.length > 0 &&
          _.sortBy(filteredUsers, (user) => user.first_name.toLowerCase()).map(user => (
            <div className="search-data-list" key={user.id}>
              <input
                type="checkbox"
                id={"add-member" + user.id}
                className="tick-check"
                onChange={() => toggleUserAddition(user)}
              />
              <label
                htmlFor={"add-member" + user.id}
                className="tick-symbol"
              ></label>
              <div className="user-img-icon">
                {(_.isNull(user.img) || _.isEmpty(user.img)) === true ? (
                  <Avatar
                    style={{ width: "100%", height: "100%" }}
                    name={user.first_name}
                    maxInitials={1}
                  ></Avatar>
                ) : (
                  <img src={user.img} alt="user-img" />
                )}
              </div>
              <div className="user-details-holder">
                <div className="user-name trun-flow">{user.first_name}</div>
                <div className="user-email trun-flow">{user.email}</div>
              </div>
            </div>
          ))}
        {filteredUsers && filteredUsers.length < 1 && (
          <div className="board-noresults projectflow-results">
            {" "}
            No results found
          </div>
        )}
      </div>
      <div className="button">
        <span className="cancel" onClick={hideAddUsersDropdown}>
          Cancel
        </span>
        <button
          className={"save"}
          disabled={usersYetToAdd && usersYetToAdd.length < 1}
          onClick={addUsersToProject}
        >
          {" "}
          Confirm
        </button>
      </div>
    </div>
  );
};

export default KanbanAddUsersDropdown;
