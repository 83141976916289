import React, { useState, memo } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import moment from "moment";
import Avatar from "react-avatar";
import _ from "lodash";
import DateRangePicker from "../common/dateRangePicker";

const MissedTimeSheets = ({
  missedTimeSheets,
  date,
  onChangeMissedTimeSheetsDate
}) => {
  // const [date, setDate] = useState(
  //   moment()
  //     .subtract(1, "day")
  //     .format("ddd, DD MMM YY")
  // );
  const [sortIn, setSortIn] = useState(false);
  const [sortBy, setSortBy] = useState("time");

  const onChangeDate = (event, picker) => {
    const from = moment(picker.startDate._d).format("ddd, DD MMM YY");
    // const to = moment(picker.endDate._d).format("ddd, DD MMM YY");
    // setDate(from);
    onChangeMissedTimeSheetsDate(from);
  };

  const onChangeSort = sortOp => {
    if (sortOp === sortBy) {
      setSortIn(!sortIn);
    } else {
      setSortBy(sortOp);
      setSortIn(false);
    }
  };

  const renderMissedTimeSheets = () => {
    let sortedMissedTimeSheets = [];
    if (sortBy === "emp") {
      if (sortIn) {
        sortedMissedTimeSheets = _.orderBy(
          missedTimeSheets,
          emp => emp.name.toLowerCase(),
          "asc"
        );
      } else {
        sortedMissedTimeSheets = _.orderBy(
          missedTimeSheets,
          emp => emp.name.toLowerCase(),
          "desc"
        );
      }
    }
    if (sortBy === "time") {
      if (sortIn) {
        sortedMissedTimeSheets = _.orderBy(
          missedTimeSheets,
          emp => emp.time_in_hours * 60 + emp.time_in_minutes,
          "asc"
        );
      } else {
        sortedMissedTimeSheets = _.orderBy(
          missedTimeSheets,
          emp => emp.time_in_hours * 60 + emp.time_in_minutes,
          "desc"
        );
      }
    }
    return sortedMissedTimeSheets.map(emp => {
      return (
        <React.Fragment>
          <tr>
            <td className="emp-img-name">
              {emp.img ? (
                <img src={emp.img} alt="emp-img" />
              ) : (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={emp.name}
                ></Avatar>
              )}
              <span>{emp.name}</span>
            </td>
            <td>{parseInt(emp.time_in_hours)}h {parseInt(emp.time_in_minutes)}m</td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
        <div className="over-title">Missed Timesheet 
        <span>{_.isArray(missedTimeSheets) ? missedTimeSheets.length : 0} defaulters</span>
        </div>
          <div className="date-range-picker">
            <div className="date-range">
              <DateRangePicker
                source={"MISSED_TIME_SHEET"}
                startDate={moment(new Date(date)).format("M/DD/YYYY")}
                endDate={moment(new Date(date)).format("M/DD/YYYY")}
                maxDate={moment(new Date()).format("M/DD/YYYY")}
                onChangeDate={onChangeDate}
              >
                <div className="date-img date-picker">{`${date}`}</div>
              </DateRangePicker>
            </div>
          </div>
        </div>
        {_.isArray(missedTimeSheets) && <div className="table-holder">
        <table>
            <thead>
              <tr>
                <th
                  onClick={() => onChangeSort("emp")}
                  style={{ width: "218px" }}
                >
                  <span
                    className={`sort-arrow ${(sortBy === "emp" &&
                      sortIn === false &&
                      "down") ||
                      (sortBy === "emp" && sortIn === true && "up")}`}
                  >
                    Employee
                  </span>
                </th>
                <th onClick={() => onChangeSort("time")}>
                  <span
                    className={`sort-arrow ${(sortBy === "time" &&
                      sortIn === false &&
                      "down") ||
                      (sortBy === "time" && sortIn === true && "up")}`}
                  >
                    Time missed
                  </span>
                </th>
              </tr>
            </thead>
          </table>
          <Scrollbar>
            <table>
              <tbody>{renderMissedTimeSheets()}</tbody>
            </table>
          </Scrollbar>
        </div>}
        {typeof missedTimeSheets === "object" && missedTimeSheets.msg && 
        <div className="no-data">{missedTimeSheets.msg}</div>}
      </div>
    </React.Fragment>
  );
};

export default memo(MissedTimeSheets);
