import React, { useState, useContext, useEffect, memo } from "react";
import infoIcon from "../../styles/img/svg/workflow_i.svg";
import greyStripe from "../../styles/img/svg/grey-strips.svg";
import searchIcon from "../../styles/img/svg/activity-search.svg";
import userAdd from "../../styles/img/svg/plus-blue.svg";
import closeSearch from "../../styles/img/svg/comm-close-search.svg";
import expand from "../../styles/img/svg/flow-expand.svg";
import OutsideClickHandler from "react-outside-click-handler";
import KanbanContext from "../../contexts/kanban/kabanContext";
import KanbanUserListDropdown from "./kanbanUserListDropdown";
import KanbanAddUsersDropdown from "./kanbanAddUsersDropdown";
import _ from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import Avatar from "react-avatar";
import { Tooltip } from "@material-ui/core";
momentDurationFormatSetup(moment);

const KanbanTopControls = ({
  kanbanSearchText,
  setKanbanSearchText,
  collapsedWorkflows,
  setCollapsedWorkflow,
  workflowList,
  setWorkflowList,
  workflowListBackup,
  peopleHavingProjectEditAccess,
}) => {
  const [colorInfo, setColorInfo] = useState(false);
  const [search, setSearch] = useState(false);
  const [collapsedWorkflowDropdown, setCollapsedWorkflowDropdown] = useState(
    false
  );
  const [projectsDropdown, setProjectsDropdown] = useState(false);
  const [userListDropdown, setUserListDropdown] = useState(false);
  const [addUsersDropdown, setAddUsersDropdown] = useState(false);
  
  const toggleSearch = () => {
    if (!kanbanSearchText) setSearch(!search);
  };
  const handleClickOutside = () => {
    if (!kanbanSearchText) setSearch(false);
  };
  const closeCollapsedWorkflowDropdown = () => {
    setCollapsedWorkflowDropdown(false);
  };
  const closeProjectsDropdown = () => {
    setProjectsDropdown(false);
  };
  const toggleProjectsDropdown = () => {
    setFilteredProjects(projectList);
    setProjectsDropdown(!projectsDropdown);
  };
  const toggleuserListDropdown = id => {
    if (userListDropdown === id) setUserListDropdown(false);
    else setUserListDropdown(id);
  };
  const showAddUsersDropdown = () => {
    setAddUsersDropdown(!addUsersDropdown);
  };
  const hideAddUsersDropdown = () => {
    setAddUsersDropdown(false);
  };
  const {
    selectedProject,
    updateSelectedProjectState,
    selectedCustomer,
    updateProjectAction,
    projectList,
    userMap,
    router,
    getProjectById,
    loggedinUser,
  } = useContext(KanbanContext);
  const [usersInProject, setUsersInProject] = useState([]);

  const goToWorkload = () => {
    router.push({
      pathname: "/workload",
      state: {
        selectedProject,
      },
    });
  };
  const defineUsers = () => {
    let listWithoutFalsy = [];
    let allUsersInProject =
      selectedProject.data && selectedProject.data.acl
        ? [...selectedProject.data.acl]
        : [];
    if (
      selectedProject.data &&
      selectedProject.data.settings &&
      selectedProject.data.settings.member_access
    ) {
      let memberList = [];
      _.forIn(selectedProject.data.settings.member_access, (_v, k) => {
        memberList.push(parseInt(k));
      });
      allUsersInProject = allUsersInProject.concat(memberList);
      allUsersInProject = Array.from(new Set(allUsersInProject));
      _.forEach(allUsersInProject, userId => {
        if (
          userId !== null &&
          userId !== "null" &&
          userId !== undefined &&
          userId !== "undefined" &&
          userId !== "" &&
          userMap[userId]
        ) {
          listWithoutFalsy.push(userMap[userId]);
        }
      });
    }
    if (
      userMap &&
      selectedCustomer &&
      selectedCustomer.selectedWorkspace &&
      !_.find(listWithoutFalsy, function(e) {
        return e.id === selectedCustomer.selectedWorkspace.super_admin_id;
      })
    ) {
      if (userMap[selectedCustomer.selectedWorkspace.super_admin_id])
        listWithoutFalsy.unshift(
          userMap[selectedCustomer.selectedWorkspace.super_admin_id]
        );
    }
    setUsersInProject(listWithoutFalsy);
  };
  useEffect(() => {
    defineUsers();
  }, [selectedProject, userMap]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const expandWorkflow = collapsedWorkflow => {
    
    workflowList = workflowList.map(workFlow => {
      
      if (parseInt(workFlow.id) === parseInt(collapsedWorkflow.id)) {
        workFlow.is_collapsed = false;
        return workFlow;
      }
      return workFlow;
    });
    let filteredCollapsedWorkflows = _.filter(
      collapsedWorkflows,
      workflow => parseInt(workflow.id) !== parseInt(collapsedWorkflow.id)
    );
    
    let projectToUpdate = {
      id: selectedProject.id,
      // workflow: selectedProject.workflow,
      // data: selectedProject.data,
      updated_at: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      workflow_changes: {
        uncollapsed: [
          { id: collapsedWorkflow.id, name: collapsedWorkflow.name },
        ],
      },
    };
    const auth_token = localStorage.getItem("auth_token");
    updateProjectAction(
      { auth_token, queryParam: "", body: projectToUpdate },
      response => {
        if (response && response.id) {
          updateSelectedProjectState(response);
          setWorkflowList([...workflowList]);
          setCollapsedWorkflow([...filteredCollapsedWorkflows]);
          setCollapsedWorkflowDropdown(false);
        }
      }
    );
  };
  const searchProject = searchText => {
    let internalFilteredProjects = [...projectList];
    internalFilteredProjects = _.filter(internalFilteredProjects, project =>
      project.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredProjects([...internalFilteredProjects]);
  };
  const changeProject = id => {
    getProjectById(id);
    closeProjectsDropdown();
  };
  const clearSearch = () => {
    setKanbanSearchText("");
    setSearch(false);
  };
  const workflowListOnFilter = JSON.parse(JSON.stringify(workflowListBackup));
  useEffect(() => {
    const searchResults = _.filter([...workflowListOnFilter], workflow => {
      workflow.activities = _.filter(workflow.activities, activity =>
        activity.title.toLowerCase().includes(kanbanSearchText.toLowerCase())
      );
      if (
        workflow.activities.length > 0 ||
        workflow.name
          .toLowerCase()
          .includes(kanbanSearchText.toLocaleLowerCase())
      ) {
        return workflow;
      }
    });
    setWorkflowList(searchResults);
  }, [kanbanSearchText]);
  return (
    <React.Fragment>
      <div className="projectflow-container-header">
        <div className="kanban-proj-title-area">
          <div className="kanban-proj-title">{selectedProject.name}</div>
          <div className="left-flow">
            <h4>Kanban</h4>
            <div className="color-info">
              <img
                src={infoIcon}
                alt="info-icon"
                onMouseEnter={() => setColorInfo(true)}
                onMouseLeave={() => setColorInfo(false)}
              />
              {colorInfo && (
                <div className="color-description">
                  <div>
                    <section className="green" />
                    <span>Actual time spent is within the planned time</span>
                  </div>
                  <div>
                    <section className="yellow" />
                    <span>Actual time spent is nearing the planned time</span>
                  </div>
                  <div>
                    <section className="red" />
                    <span>Actual time spent has crossed the planned time</span>
                  </div>
                  <div>
                    <img src={greyStripe} className="grey" alt="grey-stripe" />
                    <span>Timer is currently running for this activity</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="left-flow-project">
          <span className="projects-ddrop">Project</span>
          <OutsideClickHandler onOutsideClick={closeProjectsDropdown}>
            <div className="board project-workflow">
              <span className="board-name" onClick={toggleProjectsDropdown}>
                <div
                  className={`workflow-prj-name-holder ${
                    projectsDropdown ? "active-drop" : ""
                  }`}
                >
                  {selectedProject.name}
                </div>
              </span>

              {projectsDropdown && (
                <div className="flow">
                  <input
                    type="text"
                    className="search_board"
                    id="projectlist"
                    placeholder="Search "
                    onKeyUp={e => searchProject(e.target.value)}
                  />
                  <div className="workspace-scroll prj-wf">
                    <div className="board-list">
                      {filteredProjects.length === 0 && (
                        <div className="wf-prj-noresults">
                          {" "}
                          No results found
                        </div>
                      )}
                      {filteredProjects &&
                        filteredProjects.length > 0 &&
                        filteredProjects.map(project => {
                          return (
                            <div
                              className="wf-project-list"
                              onClick={() => changeProject(project.id)}
                              key={project.id}
                            >
                              <div className="boards-cards">
                                <div className="card-content">
                                  {(_.isNull(project.img) ||
                                    _.isEmpty(project.img)) === true ? (
                                    <div className="img-container">
                                      <Avatar
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        name={project.name}
                                        maxInitials={1}
                                      ></Avatar>
                                    </div>
                                  ) : (
                                    <div className="img-container">
                                      <img src={project.img} alt="proj-icon" />
                                    </div>
                                  )}

                                  <div className="client-name">
                                    <span className="md-title">
                                      {project.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <OutsideClickHandler onOutsideClick={handleClickOutside}>
            <div className="general-page-search-holder kanban">
              <div
                className="general-page-search-icon"
                onClick={() => toggleSearch()}
              >
                <img src={searchIcon} alt="search-icon" />
              </div>
              {search && (
                <div className="general-page-search-box-holder">
                  <input
                    type="text"
                    className="general-page-search-box kanban"
                    autoFocus="true"
                    placeholder="Search"
                    onKeyUp={e => setKanbanSearchText(e.target.value)}
                  />
                  {kanbanSearchText && (
                    <img
                      src={closeSearch}
                      className="comm-close-search"
                      role="button"
                      onClick={clearSearch}
                      alt="cross-icon"
                    />
                  )}
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="right-flow">
          <button className="view-workload-btn" onClick={goToWorkload}>
            {" "}
            View in Workload{" "}
          </button>
          <OutsideClickHandler onOutsideClick={closeCollapsedWorkflowDropdown}>
            {collapsedWorkflows && collapsedWorkflows.length > 0 && (
              <div className="flow-list">
                <div
                  className={
                    "flow-names " + (collapsedWorkflowDropdown && "list-drop")
                  }
                  onClick={() =>
                    setCollapsedWorkflowDropdown(!collapsedWorkflowDropdown)
                  }
                >
                  {collapsedWorkflows.length} collapsed list
                </div>
                {collapsedWorkflowDropdown && (
                  <div className="flow-list-holder">
                    {collapsedWorkflows.map(workflow => {
                      return (
                        <div className="collapsed-cards">
                          <div className="title-list">
                            <div className="title-list-inner">
                              {workflow.name}
                            </div>
                            <span>
                              {" "}
                              - {workflow.activities.length} Activities
                            </span>
                          </div>
                          <div
                            className="flow-expand"
                            key={workflow.id}
                            onClick={() => expandWorkflow(workflow)}
                          >
                            <img src={expand} alt="expand-icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </OutsideClickHandler>

          <div className="users-list">
            <div className="add-users">
              <OutsideClickHandler onOutsideClick={hideAddUsersDropdown}>
                {peopleHavingProjectEditAccess.length > 0 &&
                  peopleHavingProjectEditAccess.indexOf(
                    parseInt(loggedinUser.id)
                  ) !== -1 && (
                    <div className="add-users-dropdown-holder">
                      <img
                        src={userAdd}
                        className="add-users-img"
                        onClick={showAddUsersDropdown}
                        alt="user-add"
                      />
                      {addUsersDropdown && (
                        <KanbanAddUsersDropdown
                          hideAddUsersDropdown={hideAddUsersDropdown}
                          setUsersInProject={setUsersInProject}
                          usersInProject={usersInProject}
                        ></KanbanAddUsersDropdown>
                      )}
                    </div>
                  )}
              </OutsideClickHandler>

              <OutsideClickHandler
                onOutsideClick={() => setUserListDropdown(null)}
              >
                <div className="users-list-img">
                  {usersInProject &&
                    usersInProject.length > 0 &&
                    usersInProject.map(
                      (user, index) =>
                        index <= 4 && (
                          <div className="users-list-img-holder" key={user.id}>
                            <Tooltip title={user.first_name}>
                              {user.img ? (
                                <img
                                  src={
                                    user.img ||
                                    "//d30rgbmectrk9r.cloudfront.net/users/default.png"
                                  }
                                  alt="user-img"
                                  className="users-list-img-img"
                                  onClick={() =>
                                    toggleuserListDropdown(user.id)
                                  }
                                />
                              ) : (
                                <div
                                  onClick={() =>
                                    toggleuserListDropdown(user.id)
                                  }
                                >
                                  <Avatar
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    name={user.first_name}
                                    maxInitials={1}
                                  ></Avatar>
                                </div>
                              )}
                            </Tooltip>
                            {userListDropdown === user.id && (
                              <KanbanUserListDropdown
                                key={user.id}
                                userList={usersInProject}
                                setUsersInProject={setUsersInProject}
                                peopleHavingProjectEditAccess={
                                  peopleHavingProjectEditAccess
                                }
                              ></KanbanUserListDropdown>
                            )}
                          </div>
                        )
                    )}
                  {usersInProject && usersInProject.length > 5 && (
                    <div
                      className="user-list-more"
                      onClick={() => toggleuserListDropdown(-1)}
                    >
                      {"+" + (usersInProject.length - 5)}
                      {userListDropdown === -1 && (
                        <KanbanUserListDropdown
                          key={"user-0"}
                          userList={usersInProject}
                          setUsersInProject={setUsersInProject}
                          peopleHavingProjectEditAccess={
                            peopleHavingProjectEditAccess
                          }
                        ></KanbanUserListDropdown>
                      )}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(KanbanTopControls);
