import routesHelper from "../router/routeshelper";

export const workloadConstant = {
  searchActivities: "SEARCH_ACTIVITIES",
  addActivity: "ADD_ACTIVITY_WORKLOAD",
  removeActivity: "REMOVE_ACTIVITY_WORKLOAD",
  updateWorkFlow: "UPDATE_WORKLOAD_WORKFLOW",
  unarchiveActivity: "UNARCHIVE_WORLOAD_ACTIVITY",
  searchUsers: "SEARCH_USERS",
  fetchActivity: "FETCH_ACTIVITY",
  backlogActivityFetched: "BACKLOG_ACTIVITY_FETCHED",
  addBacklogActivity: "ADD_BACKLOG_ACTIVITY",
  updateActivity: "UPDATE_ACTIVITY",
  updateGetLoaderState: "UPDATE_GET_LOADER_STATE",
  clearWorkloadReducer: "CLEAR_WORKLOAD_REDUCER",
  fromWorkload: "FROM_WORKLOAD",
  defaultBreadcrumb: [
    {
      name: "Workload",
      link: routesHelper.workloadPath,
      type: "heading"
    }
  ],
  BACK_LOG_ACTIVITIES:"BACK_LOG_ACTIVITIES",
  ADD_BACK_LOG_ACTIVITIES:"ADD_BACK_LOG_ACTIVITIES",
  REMOVE_BACK_LOG_ACTIVITIES:"REMOVE_BACK_LOG_ACTIVITIES",
  UPDATE_PRIORITY_NONPRIORITY:"UPDATE_PRIORITY_NONPRIORITY",
  UPDATE_PRIORITY_NOTPRIORITY:"UPDATE_PRIORITY_NOTPRIORITY",
  CLEAR_PRIORITY_NOTPRIORITY:"CLEAR_PRIORITY_NOTPRIORITY"
};
