import { checklistConstant } from "../../constants/checklistconstant";
import { activities } from "../../constants/activitiescontants";
import {
  createChecklist,
  getChecklist,
  createChecklistItem,
  updateChecklistItem,
  updateChecklist
} from "../../services/checklist";
import _ from "lodash";
import { searchActivityInner } from "../../services/commentsapi";

function updateLoaderState(type, state) {
  return {
    type,
    payload: { state }
  };
}

function addChecklistAction(checklist) {
  checklist.recent = true;
  return {
    type: checklistConstant.addChecklist,
    payload: {
      checklist
    }
  };
}

function fetchChecklistAction(checklists, pageNumber) {
  return {
    type: checklistConstant.getChecklist,
    payload: {
      checklists,
      page: pageNumber,
      key: new Date().toISOString()
    }
  };
}

function searchChecklistsByActivity(checklists) {
  return {
    type: checklistConstant.searchChecklist,
    payload: {
      checklists,
      key: new Date().toISOString()
    }
  };
}

function fetchArchivedChecklistAction(checklists) {
  return {
    type: checklistConstant.getChecklistArchived,
    payload: {
      checklists,
      key: new Date().toISOString()
    }
  };
}

function updateChecklistInStoreAction(checklist) {
  return {
    type: checklistConstant.updateChecklist,
    payload: { ...checklist }
  };
}

function unarchiveChecklist(checklist) {
  return {
    type: checklistConstant.unarchiveChecklist,
    payload: { ...checklist }
  };
}

function updateChecklistCount({ activity_id, is_priority }) {
  if (is_priority) {
    return {
      type: activities.udateChecklistCount.PRIORITY,
      payload: { activity_id }
    };
  } else {
    return {
      type: activities.udateChecklistCount.NOTPRIORITY,
      payload: { activity_id }
    };
  }
}

function updateChecklistCountByGettingCount( activity_id, is_priority,checkListCount ) {
  
  if (is_priority) {
    return {
      type: activities.udateChecklistCount.PRIORITY_PASSED_COUNT,
      payload: { activity_id,checkListCount }
    };
  } else {
    return {
      type: activities.udateChecklistCount.NOTPRIORITY_PASSED_COUNT,
      payload: { activity_id,checkListCount }
    };
  }
}

function updateChecklistCountAfterAddingInArchivePage({ activity_id }) {
  return {
    type: activities.updateChecklistCountAfterAddingInArchivePage,
    payload: { activity_id }
  };
}
// updateChecklistCount after archive action
function updateChecklistCountAfterArchive({ activity_id, is_priority }) {
  if (is_priority) {
    return {
      type: activities.updateChecklistCountAfterArchive.PRIORITY,
      payload: { activity_id }
    };
  } else {
    return {
      type: activities.updateChecklistCountAfterArchive.NOTPRIORITY,
      payload: { activity_id }
    };
  }
}

function updateArchiveCardChecklistCountAfterArchive({ activity_id }) {
  return {
    type: activities.updateArchiveCardChecklistCountAfterArchive,
    payload: { activity_id }
  };
}

function addChecklistItemAction({
  id,
  title,
  required,
  required_output,
  checklist_id,
  created_by,
  is_completed,
  is_archived,
  position,
  created_at,
  updated_at
}) {
  return {
    type: checklistConstant.addChecklistItem,
    payload: {
      id,
      title,
      required,
      required_output,
      checklist_id,
      created_by,
      is_completed,
      is_archived,
      position,
      created_at,
      updated_at
    }
  };
}

function updateChecklistItemInReducerAction({
  checklist_id,
  completed_by,
  created_at,
  created_by,
  id,
  is_archived = false,
  is_completed,
  position,
  required = "NONE",
  title,
  social_data,
  data
}) {
  return {
    type: checklistConstant.updateChecklistItem,
    payload: {
      checklist_id,
      completed_by,
      created_at,
      created_by,
      id,
      is_archived,
      is_completed,
      position,
      required,
      title,
      social_data,
      data
    }
  };
}

function deletedChecklistItemInReducerAction(checklistItem) {
  return {
    type: checklistConstant.deleteChecklistItem,
    payload: checklistItem
  };
}

export const createChecklistAction = (
  {
    activity_id,
    title,
    created_by,
    is_archived,
    is_priority,
    is_Activity_archived
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(checklistConstant.addChecklistLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  createChecklist({ activity_id, title, created_by, is_archived }, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(checklistConstant.addChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
      dispatch(addChecklistAction({ ...response, checklist_items: [] }));

      if (is_Activity_archived) {
        dispatch(updateChecklistCountAfterAddingInArchivePage({ activity_id }));
      } else {
        dispatch(updateChecklistCount({ activity_id, is_priority }));
      }
    } else {
      dispatch(
        updateLoaderState(checklistConstant.addChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const getChecklistAction = ({ queryParams, pageNumber }, callback) => dispatch => {
  dispatch(
    updateLoaderState(checklistConstant.getChecklistLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );

  getChecklist({ queryParams }, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(checklistConstant.getChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
      dispatch(fetchChecklistAction(response, pageNumber));
    } else {
      dispatch(
        updateLoaderState(checklistConstant.getChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const searchChecklistAction = ({ queryParams }, callback) => dispatch => {
  dispatch(
    updateLoaderState(checklistConstant.getChecklistLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );

  searchActivityInner(queryParams, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(checklistConstant.getChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: null
        })
      );
      dispatch(searchChecklistsByActivity(response));
    } else {
      dispatch(
        updateLoaderState(checklistConstant.getChecklistLoaderState, {
          fetching: false,
          fetched: true,
          error: response.stdErr
        })
      );
    }
    callback && callback(response);
  });
};

export const getChecklistAfterRearrangeAction = ({ queryParams, pageNumber }, callback) => dispatch => {
  getChecklist({ queryParams }, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(fetchChecklistAction(response, pageNumber));
    }
    callback && callback(response);
  });
};

export const updateChecklistAction = (
  {
    activity_id,
    title,
    created_by,
    is_archived,
    position,
    id,
    is_open,
    is_show,
    is_priority,
    is_Activity_archived
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(checklistConstant.updateChecklistLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );

  updateChecklist(
    { activity_id, title, created_by, is_archived, position, id, is_open, is_show },
    response => {
      if (response && _.isUndefined(response.stdErr)) {
        dispatch(
          updateLoaderState(checklistConstant.updateChecklistLoaderState, {
            fetching: false,
            fetched: true,
            error: null
          })
        );
        dispatch(updateChecklistInStoreAction(response));
        // reduce checklist count

        if (is_Activity_archived) {
          dispatch(
            updateArchiveCardChecklistCountAfterArchive({ activity_id })
          );
        } else if(!_.isUndefined(is_priority)) {
          dispatch(
            updateChecklistCountAfterArchive({ activity_id, is_priority })
          );
        }
      } else {
        dispatch(
          updateLoaderState(checklistConstant.updateChecklistLoaderState, {
            fetching: false,
            fetched: true,
            error: response.stdErr
          })
        );
      }
      callback && callback(response);
    }
  );
};

export const createChecklistItemAction = (
  { created_by, checklist_id, is_archived, is_completed = false, required, title },
  callback
) => dispatch => {
  createChecklistItem(
    {
      created_by,
      is_archived,
      is_completed,
      required,
      title,
      checklist_id
    },
    res => {
      if (res && !res.stdErr) {
        dispatch(addChecklistItemAction({ ...res }));
      }
      callback && callback(res);
    }
  );
};

export const updateChecklistItemAction = (
  {
    checklist_id,
    completed_by,
    created_at,
    created_by,
    id,
    is_archived = false,
    is_completed,
    position,
    title,
    social_data,
    data
  },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(checklistConstant.updateChecklistItemLoaderState, {
      fetching: true,
      fetched: false,
      error: false
    })
  );
  updateChecklistItem(
    {
      checklist_id,
      completed_by,
      created_at,
      created_by,
      id,
      is_archived,
      is_completed,
      position,
      title,
      social_data,
      data
    },
    res => {
      if (res && !res.stdErr) {
        if (is_archived === true) {
          dispatch(
            updateLoaderState(
              checklistConstant.updateChecklistItemLoaderState,
              {
                fetching: false,
                fetched: true,
                error: false
              }
            )
          );
          return dispatch(deletedChecklistItemInReducerAction({ ...res }));
        }
        if (!_.isUndefined(position)) {
          const queryParams = `id=${checklist_id}`;
          getChecklist({ queryParams }, checklist => {
            if (checklist && !checklist.stdErr) {
              checklist[0].checklist_items.forEach(checklist_item => {
                if (checklist_item.is_archived === false) {
                  dispatch(
                    updateChecklistItemInReducerAction({
                      ...checklist_item
                    })
                  );
                }
              });
              dispatch(
                updateLoaderState(
                  checklistConstant.updateChecklistItemLoaderState,
                  {
                    fetching: false,
                    fetched: true,
                    error: null
                  }
                )
              );
            } else {
              dispatch(
                updateLoaderState(
                  checklistConstant.updateChecklistItemLoaderState,
                  {
                    fetching: false,
                    fetched: false,
                    error: true
                  }
                )
              );
            }
          });
        } else {
          dispatch(
            updateLoaderState(
              checklistConstant.updateChecklistItemLoaderState,
              {
                fetching: false,
                fetched: true,
                error: null
              }
            )
          );
          dispatch(
            updateChecklistItemInReducerAction({
              ...res
            })
          );
        }
      }
      callback && callback(res);
    }
  );
};

export const getArchivedChecklistAction = (
  { queryParams },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState(
      checklistConstant.getChecklistLoaderState,
      {
        fetching: true,
        fetched: false,
        error: false,
        key: new Date().getTime().toLocaleString()
      }
    )
  );
  getChecklist({ queryParams }, response => {
    if (response && _.isUndefined(response.stdErr)) {
      dispatch(
        updateLoaderState(
          checklistConstant.getChecklistLoaderState,
          {
            fetching: false,
            fetched: true,
            error: false,
            key: new Date().getTime().toLocaleString()
          }
        )
      );
      dispatch(fetchArchivedChecklistAction(response));
    }
    callback && callback(response);
  });
};

export const clearArchivedChecklistAction = () => dispatch => {
  dispatch(fetchArchivedChecklistAction([]));
}

export const unarchiveChecklistAction = (
  { activity_id, title, created_by, is_archived = false, position, id },
  callback
) => dispatch => {
  updateChecklist(
    { activity_id, title, created_by, is_archived, position, id },
    response => {
      if (response && _.isUndefined(response.error)) {
        dispatch(unarchiveChecklist(response));
        dispatch(updateChecklistCountAfterAddingInArchivePage({ activity_id }));
      }
      callback && callback(response);
    }
  );
};

export const clearChecklistAction = () => dispatch => {
  dispatch(fetchChecklistAction([]));
};

export const clearSearchedChecklistAction = () => dispatch => {
  dispatch(searchChecklistsByActivity([]));
};

export const updateChecklistCountAction=(props)=>dispatch=>{
  dispatch(updateChecklistCountByGettingCount(props.activity_id, props.is_priority,props.checklistCount));
}
