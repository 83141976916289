import React, { useState, useCallback, useEffect, memo } from "react";
import update from "immutability-helper";
import ChecklistRearrange from "./checklistRearranged.jsx";

function ChecklistRearrangeMain({
  checklists,
  updateChecklistAction,
  setArrangedChecklist
}) {
  const [cards, setCards] = useState(checklists);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      );
    },
    [cards]
  );

  useEffect(() => {
    
    const arrangedChecklist = cards.map((card, index) => {
      return { ...card, position: cards.length - index };
    });
    setArrangedChecklist(arrangedChecklist);
  }, [cards]);

  

  const renderCard = (card, index) => {
    return (
      <ChecklistRearrange
        key={card.id}
        checklist={card}
        checklists={checklists}
        index={index}
        updateChecklistAction={updateChecklistAction}
        moveCard={moveCard}
        totalCount={cards.length}
      ></ChecklistRearrange>
    );
  };

  return <div>{cards.map((card, i) => renderCard(card, i))}</div>;
}

export default memo(ChecklistRearrangeMain);