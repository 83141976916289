import React, { Component } from "react";
import _ from "lodash";
import "../../styles/scss/simplebar.scss";
import AddFeatures from "./addfeatures";
import ActivityTiles from "./activeactivities";
import { connect } from "react-redux";
import { activities } from "../../constants/activitiescontants";
import ActivityForm from "./addactivity";
import DialogWrapper from "../common/dialog";
import * as action from "../../actions/index";
import {
  changeWorkFlowAction,
  startTimerAction,
  stopTimerAction,
  createTimerAction
} from "../../actions/activities/activitiesaction";
import {
  getDeprioritizedActivitiesForWorkspaceAction,
  getPrioritizedActivitiesForWorkspaceAction,
  totalActvityCountAction,
  clearReducerAction,
  clearPrioritizeActivityAction
} from "../../actions/workspace/workspaceaction";
import { changeTimerStatus } from "../../actions/common/commonactions";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import firebase from "../../firebase";
import routesHelper from "../../router/routeshelper";

const mapStateToProps = state => {
  return {
    listOfActivity: state.activityReducer.listOfActivity,
    prioritiZedActivity: state.activityReducer.prioritiZedActivity,
    logedinUserDetails: state.loginReducer.loginAuth.user_details,
    listOfArchivedActivity: state.activityReducer.listOfArchivedActivity.list,
    searchContent: state.commonReducer.searchContent,
    timerRunningStatus: state.commonReducer.timerIsRunning,
    anyTimerStatus: state.commonReducer.timerStatus,
    totalActivityCount: state.activityReducer.totalActivityCount,
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace
  };
};

const mapDispacthProps = dispatch => {
  return {
    getUserDetailsAction({ queryparams }, callback) {
      dispatch(action.getUserDetailsAction({ queryparams }, callback));
    },
    getSelectedWorkpsaceArchivedActivities(
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        client_id,
        is_archieved
      },
      callback
    ) {
      dispatch(
        action.getSelectedWorkpsaceArchivedActivities(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            client_id,
            is_archieved
          },
          callback
        )
      );
    },
    totalActvityCountAction({ activity_type, user_id, auth_token }) {
      dispatch(totalActvityCountAction({ activity_type, user_id, auth_token }));
    },
    archiveOrUnArchiveActivitiesAction(
      { auth_token, archiveData, type },
      callback
    ) {
      dispatch(
        action.archiveOrUnArchiveActivities(
          { auth_token, archiveData, type },
          callback
        )
      );
    },
    changeWorkFlow(
      { id, workflow, is_Priority, customer_auth_token },
      callback
    ) {
      dispatch(
        changeWorkFlowAction(
          { id, workflow, is_Priority, customer_auth_token },
          callback
        )
      );
    },
    startTimer(
      { activity_id, data, date, user_id, customer_auth_token },
      callback
    ) {
      dispatch(
        startTimerAction(
          { activity_id, data, date, user_id, customer_auth_token },
          callback
        )
      );
    },
    stopTimer(
      {
        activity_id,
        data,
        time_taken,
        tags,
        description,
        attachments,
        id,
        date,
        user_id,
        customer_auth_token
      },
      formData,
      callback
    ) {
      dispatch(
        stopTimerAction(
          {
            activity_id,
            data,
            time_taken,
            tags,
            description,
            attachments,
            id,
            date,
            user_id,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    },
    createTimer(
      {
        tags,
        attachments,
        activity_id,
        data,
        time_taken,
        description,
        date,
        user_id,
        customer_auth_token
      },
      formData,
      callback
    ) {
      dispatch(
        createTimerAction(
          {
            tags,
            attachments,
            activity_id,
            data,
            time_taken,
            description,
            date,
            user_id,
            customer_auth_token
          },
          formData,
          callback
        )
      );
    },
    changeTimerStatusAct(timerRunningStatus) {
      dispatch(changeTimerStatus(timerRunningStatus));
    },
    getAllCustomerForPeopleAction(callback) {
      dispatch(getAllCustomerForPeopleAction(callback));
    },
    getPrioritizedActivitiesForWorkspace: (
      { auth_token, activity_type, is_active, user_id, is_archived },
      callback
    ) => {
      dispatch(
        getPrioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived
          },
          callback
        )
      );
    },
    getDeprioritizedActivitiesForWorkspace: (
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page
      },
      callback
    ) => {
      dispatch(
        getDeprioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page
          },
          callback
        )
      );
    },
    clearReducer: callback => {
      dispatch(clearReducerAction(callback));
    },
    clearPrioritizeActivity:()=>{
      dispatch(clearPrioritizeActivityAction())
    }
  };
};

class ActivityWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityActive: false,
      clientActive: false,
      projectActive: false,
      userActive: false,
      isPopupEnable: false,
      fromPriority: false,
      isPopupEnableEdit: false,
      fromActivityInnerAdd: false,
      isPopupEnableInner: false
    };
  }
  componentDidMount() {
    const {
      selectedCustomerDetails,
      // getSelectedWorkpsaceActivities,
      getTimerStatusForWorkspaceAction,
      getPrioritizedActivitiesForWorkspace,
      getDeprioritizedActivitiesForWorkspace,
      // totalActvityCountAction,
      // getSelectedWorkpsaceArchivedActivities,
      logedinUserDetails,
      listOfActivity,
      clearPrioritizeActivity
    } = this.props;

    if (selectedCustomerDetails) {
      clearPrioritizeActivity();
      getPrioritizedActivitiesForWorkspace(
        {
          auth_token: selectedCustomerDetails.auth_token,
          user_id: parseInt(logedinUserDetails.id),
          activity_type: "ACTIVITY",
          is_archived: false,
          is_active: true
        },
        res => {
          console.log("Activity res", res);
          if(!res.stdErr && res?.length >= 0) {
            // Get timer running status
            getTimerStatusForWorkspaceAction({ auth_token: routesHelper.getAuthToken() })
            getDeprioritizedActivitiesForWorkspace(
              {
                auth_token: selectedCustomerDetails.auth_token,
                user_id: parseInt(logedinUserDetails.id),
                activity_type: "ACTIVITY",
                is_archived: false,
                is_active: true,
                page: _.isUndefined(listOfActivity.page) ? 1 : listOfActivity.page,
                item_per_page: 50
              }
            );
          }
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    // New way of updating the global timer status (valid across workspaces)
    if(nextProps.anyTimerStatus !== prevProps.anyTimerStatus) {
      nextProps.changeTimerStatusAct(nextProps.anyTimerStatus?.any_timer_running);
    }
    return null;
  }

  handleActivityClick = () => {
    this.setState({
      activityActive: true
    });
  };

  handleClientClick = () => {
    this.setState({
      clientActive: true
    });
  };

  handleProjectClick = () => {
    this.setState({
      projectActive: true
    });
  };

  handleUserClick = () => {
    this.setState({
      userActive: true
    });
  };

  onPopupCloseCallBack = () => {
    this.setState({
      isPopupEnable: false,
      fromPriority: false
    });
  };

  handleClickCallback = clickedItem => {
    switch (clickedItem) {
      case activities.add.activity:
        firebase.analytics().logEvent("GTD_Activity_add_button");
        this.setState({
          isPopupEnable: true
        });
        break;
      case activities.add.activity.priority:
        firebase.analytics().logEvent("GTD_Activity_added_ghost_card");
        this.setState({
          isPopupEnable: true,
          fromPriority: true
        });
        break;

      default:
        break;
    }
  };

  popuEnableCallback = shouldEnable => {
    this.setState({
      isPopupEnable: shouldEnable
    });
  };

  // For Editing Activity Popup

  onPopupCloseEditCallBack = () => {
    this.setState({
      isPopupEnableEdit: false,
      fromActivityInnerAdd: false
      // isPopupEnableInner: true
    });
  };

  handleClickEditCallback = (activity, fromActivityInnerAdd) => {
    this.setState({
      isPopupEnableEdit: true,
      fromActivityInnerAdd: fromActivityInnerAdd ? fromActivityInnerAdd : false,
      selectedActivity: activity
    });
  };

  popuEnableEditCallback = shouldEnable => {
    this.setState({
      isPopupEnableEdit: shouldEnable
    });
  };

  getFilteredResults = (list, searchText) => {
    let toBeSearchedPattern = searchText && searchText.toLowerCase();
    let toBeComparedText = "";
    let udpatedList = [];
    if (list.length && toBeSearchedPattern) {
      list.forEach(activity => {
        toBeComparedText = activity.title.toLowerCase();
        toBeComparedText.indexOf(toBeSearchedPattern) > -1 &&
          udpatedList.push(activity);
      });
      return udpatedList;
    }

    return list;
  };

  isPopupEnableInnerClicked = () => {
    this.setState({
      isPopupEnableInner: true
    });
  };
  isPopupEnableInnerDisableClicked = () => {
    if (this.state.isPopupEnableEdit === true) {
      return;
    }
    this.setState({
      isPopupEnableInner: false
    });
  };

  componentWillUnmount() {
    const { clearReducer } = this.props;
    clearReducer();
  }

  render() {
    const {
      listOfActivity,
      prioritiZedActivity,
      // listOfArchivedActivity,
      logedinUserDetails,
      getUserDetailsAction,
      // getSelectedWorkpsaceArchivedActivities,
      getSelectedWorkpsaceActivities,
      searchContent,
      archiveOrUnArchiveActivitiesAction,
      changeWorkFlow,
      selectedCustomerDetails,
      startTimer,
      stopTimer,
      createTimer,
      timerRunningStatus,
      anyTimerStatus,
      changeTimerStatusAct,
      fetchTimesheetAction,
      // totalActivityCount,
      updateSelectedWorkpaceDetailsAction,
      router,
      onWorkspaceNavBarClicked
    } = this.props;
    const {
      isPopupEnable,
      fromPriority,
      isPopupEnableEdit,
      selectedActivity,
      isPopupEnableInner
    } = this.state;
    let activeTab = localStorage.getItem(activities.activeActivityTab);
    let dialogProps = {
      className: "create_activity",
      showPopup: isPopupEnable,
      dialogTitle: () => {
        return "New Activity";
      },
      onCloseCallBack: this.onPopupCloseCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true
    };

    let dialogPropsEdit = {
      className: "create_activity",
      showPopup: isPopupEnableEdit,
      dialogTitle: () => "Edit Actvity",
      onCloseCallBack: this.onPopupCloseEditCallBack,
      onConfirmCallBack: this.onConfirm,
      shouldButtonEnable: true
    };
    return (
      <div className="activity-container">
        {/* <ScrollBar style={{ height: "90vh" }} className="scrollbar" id="scroll"> */}
        <ActivityTiles
          selectedCustomerDetails={selectedCustomerDetails}
          searchContent={searchContent}
          getSelectedWorkpsaceActivities={getSelectedWorkpsaceActivities}
          activeTab={activeTab}
          getFilteredResults={this.getFilteredResults}
          popuEnableCallback={this.popuEnableCallback}
          prioritiZedActivity={prioritiZedActivity.list}
          archiveOrUnArchiveActivitiesAction={
            archiveOrUnArchiveActivitiesAction
          }
          listOfActivity={listOfActivity}
          changeWorkFlowAction={changeWorkFlow}
          startTimerAction={startTimer}
          stopTimerAction={stopTimer}
          createTimerAction={createTimer}
          timerRunningStatus={timerRunningStatus}
          anyTimerStatus={anyTimerStatus}
          changeTimerStatus={changeTimerStatusAct}
          fetchTimesheetAction={fetchTimesheetAction}
          handleClickCallback={this.handleClickCallback}
          handleClickEditCallback={this.handleClickEditCallback}
          updateSelectedWorkpaceDetailsAction={
            updateSelectedWorkpaceDetailsAction
          }
          isPopupEnableInner={isPopupEnableInner}
          isPopupEnableInnerClicked={this.isPopupEnableInnerClicked}
          isPopupEnableInnerDisableClicked={
            this.isPopupEnableInnerDisableClicked
          }
          router={router}
          onWorkspaceNavBarClicked={onWorkspaceNavBarClicked}
        />
        {isPopupEnable && (
          <DialogWrapper dialogProps={dialogProps}>
            <ActivityForm
              fromPriority={fromPriority}
              getUserDetailsAction={getUserDetailsAction}
              logedinUserDetails={logedinUserDetails}
              fetchTimesheetAction={fetchTimesheetAction}
              {...dialogProps}
            />
          </DialogWrapper>
        )}
        {isPopupEnableEdit && (
          <DialogWrapper dialogProps={dialogPropsEdit}>
            <ActivityForm
              getUserDetailsAction={getUserDetailsAction}
              fromActivityInnerAdd={this.state.fromActivityInnerAdd}
              isPopupEnableInnerDisableClicked={
                this.isPopupEnableInnerDisableClicked
              }
              logedinUserDetails={logedinUserDetails}
              selectedActivity={selectedActivity}
              {...dialogPropsEdit}
            />
          </DialogWrapper>
        )}
        <AddFeatures handleClickCallback={this.handleClickCallback} />
        {/* </ScrollBar> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispacthProps)(ActivityWrapper);
