import React, { Component } from "react";
import selfRequestImg from "../../styles/img/svg/self-reqest.svg";
import downArrow from "../../styles/img/svg/board_arrow.svg";
import Avatar from "react-avatar";
import systemNotificationClose from "../../styles/img/svg/system-notification-close.svg";
import { updateCustomerDetailsAction } from "../../actions/workspace/workspaceaction";
import { getSystemNotificationsAction } from "../../actions/notifications/notificationaction";
import { updateSubscriptionAction } from "../../actions/billing/billing";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import { connect } from "react-redux";
class SystemNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  componentDidMount() {
    const allUsers = [
      ...this.props.notification.users_requested,
      ...this.props.notification.users_added
    ];
    if(allUsers.length > 0 && allUsers.length < 2) {
      this.setState({ showMore: true })
    }
  }

  onAddUser = user => {
    const {
      currentWorkspace,
      updateCustomerDetails,
      getSystemNotifications,
      getAllCustomerForPeople,
      billingInfo,
      workspaceUsersCount,
      updateSubscription
    } = this.props;
    const auth_token = localStorage.getItem("customer_auth_token");
    const myWorkspace = { ...currentWorkspace };
    const newUsers = Object.assign(myWorkspace.users, { [user.id]: "USER" });
    myWorkspace.users = newUsers;
    updateCustomerDetails(
      {
        auth_token,
        users: newUsers,
        id: myWorkspace.id
      },
      response => {
        if (response) {
          getSystemNotifications({ auth_token });
          getAllCustomerForPeople({}, _response => { /* TODO document why this arrow function is empty */ });
          if (!billingInfo.error) {
            let updatedBillingInfo = { ...billingInfo };
            updatedBillingInfo.data[0].quantity = workspaceUsersCount + 1;
            updateSubscription(
              {
                existingSubscriptionObj: updatedBillingInfo,
                planType: billingInfo.data[0].plan.nickname.toLowerCase(),
                user_id: parseInt(user.id),
                action: "ADD"
              },
              res => {
                if (res && !res.error) {
                  // TODO document why this block is empty
                
                  
                }
              }
            );
          }
        }
      }
    );
  };

  onViewAddedUser = user => {
    this.props.goToPeopleSettings(user);
  };

  render() {
    const { showMore } = this.state;
    const { notification, currentWorkspace, notificationObj } = this.props;
    const allUsers = [
      ...notification.users_requested,
      ...notification.users_added
    ];
    let usersRequestedAdded = [];
    if (allUsers.length > 0)
      if (showMore) usersRequestedAdded = allUsers;
      else usersRequestedAdded.push(allUsers[0]);
    return (
      <div className="system-notify-card">
        <div className="system-notify-header">
          Request
          <div
            className="system-notify-close req-close"
            onClick={() => this.props.archiveRequestSystemNotification(notificationObj)}
          >
            <img src={systemNotificationClose} alt="close-icon" />
          </div>
        </div>
        <div className="system-notify-req-body">
          <div className="system-notify-req-body-header">
            <div className="system-notify-head-img">
              {notification.self_request && <img src={selfRequestImg} alt="request-icon" />}
              {!notification.self_request &&
                (notification.requested_by.img ? (
                  <img src={notification.requested_by.img} alt="user-img" />
                ) : (
                  <Avatar
                    style={{ width: "25px", height: "25px" }}
                    name={notification.requested_by.first_name}
                    maxInitials={1}
                  />
                ))}
            </div>
            <div class="system-notify-req-body-heading">
              {notification.self_request
                ? `The following people requested to join ${currentWorkspace.display_name} Workspace`
                : `${notification.requested_by.first_name} requested you to add the following members to ${currentWorkspace.display_name} Workspace`}
            </div>
          </div>
          <div class="system-notify-req-body-content">
            {usersRequestedAdded.map(user => {
              return (
                <div className="user-container">
                  <div className="user-img">
                    {user.img ? (
                      <img src={user.img} alt="user-img" />
                    ) : (
                      <Avatar
                        style={{ width: "40px", height: "40px" }}
                        name={user.first_name}
                        maxInitials={1}
                      />
                    )}
                  </div>
                  <div className="name-container">
                    <div className="user-name">{user.first_name}</div>
                    <div className="user-email">
                      {user.added_by ? `Added by ${user.added_by}` : user.email}
                    </div>
                  </div>
                  {user.added_by ? (
                    <button
                      className="add-view-btn"
                      onClick={() => this.onViewAddedUser(user)}
                    >
                      View
                    </button>
                  ) : (
                    <button
                      className="add-view-btn"
                      onClick={() => this.onAddUser(user)}
                    >
                      Add
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          {!showMore && (
            <div
              className="show-more-req"
            >
              <div onClick={() => this.setState({ showMore: true })}>
                Show more
                <img src={downArrow} alt="down-arrow" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentWorkspace:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    billingInfo: state.billingReducer.billingInfo,
    workspaceUsersCount: state.peopleReducer.workspaceusers.length,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerDetails(auth_token, users, id) {
      dispatch(updateCustomerDetailsAction(auth_token, users, id));
    },
    getSystemNotifications: ({ auth_token }, callback) => {
      dispatch(getSystemNotificationsAction({ auth_token }, callback));
    },
    getAllCustomerForPeople(callback) {
      dispatch(getAllCustomerForPeopleAction(callback));
    },
    updateSubscription: (
      { existingSubscriptionObj, planType, user_id, action },
      callback
    ) => {
      dispatch(
        updateSubscriptionAction(
          { existingSubscriptionObj, planType, user_id, action },
          callback
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotification);
