import React from "react";
import "../../styles/scss/user_profile.scss";

const InviteExpirePopup = ({ onClosePopup }) => {
  return (
    <div className="pop-up-password invite-expire">
      <div className="message">
        <span>
          Please check with the person who shared it with you to request another
          one. You can also register to Get Things Done and request to join any
          workspace once you’re signed in.
        </span>
      </div>
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default InviteExpirePopup;
