import React, { useState, useRef, memo } from "react";

import { Tooltip, Zoom } from "@material-ui/core";
import activityDotSVG from "../../styles/img/svg/activity_dots.svg";
import OutsideClickHandler from "react-outside-click-handler";
import firebase from "../../firebase";
import { commonDateFormat } from "../../helpers/generichelper";

function CommentTile({
  comment,
  unarchiveCommentAction,
  permanentlyDeleteCommentAction,
}) {
  const [showMoreOption, setShowMoreOption] = useState(false);
  const showOptionsNode = useRef(null);

  const onUnarchiveComment = () => {
    unarchiveCommentAction(
      {
        id: comment.id,
        is_active: true,
        title: comment.title,
        parent_activity_id: comment.parent_activity_id,
        version_id: comment.version_id,
        user_id: comment.user_id,
        data: comment.data,
        created_by: comment.created_by,
      },
      _response => {
        firebase.analytics().logEvent("GTD_Archive_Comment_Unarchived");
      }
    );
    setShowMoreOption(false);
  };

  const onPermanentDeleteComment = () => {
    permanentlyDeleteCommentAction({ id: comment.id }, _res => {
      firebase.analytics().logEvent("GTD_Delete_Comment_Permanent");
    });
    setShowMoreOption(false);
  };

  const onOutsideClick = e => {
    if (showOptionsNode && !showOptionsNode.current.contains(e.target))
      setShowMoreOption(false);
  };

  return (
    <div className="checklist-card-main-container archive-center">
      <div className="checklist-top-container">
        <div className="comm-act-header checklist">
          <span className="ap-comment-updatedon archived-comm-updated-on">
            {commonDateFormat(comment.updated_on)}
          </span>
          <div className="options-activity comm-opts">
            <div className="archive-edit-wrap">
              <div
                className="comm-option-icon"
                role="button"
                tabIndex="0"
                ref={showOptionsNode}
              >
                <div
                  className="comm-dot-holder"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowMoreOption(!showMoreOption);
                  }}
                >
                  <img src={activityDotSVG} alt="dot-option" />
                </div>
                <OutsideClickHandler onOutsideClick={e => onOutsideClick(e)}>
                  {showMoreOption && (
                    <div className="comm-options-block ">
                      <div
                        className="comm-option-list"
                        onClick={onUnarchiveComment}
                        role="button"
                        tabIndex="0"
                      >
                        Unarchive
                      </div>
                      <div
                        className="comm-option-list comm-per-del"
                        onClick={onPermanentDeleteComment}
                        role="button"
                      >
                        Permanently Delete
                      </div>
                    </div>
                  )}
                </OutsideClickHandler>
              </div>
            </div>
          </div>
        </div>
        <div className="checklist-content-container comm-content-container">
          <div className="checklist-card-title comm-card-title">
            <Tooltip
              TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <div style={{ wordBreak: "break-all" }}>
                    {comment.title.replace(/(<([^>]+)>)/gi, "")}
                  </div>
                  {`${
                    comment.attachments?.length > 0
                      ? "+ " +
                        comment.attachments?.length +
                        `${
                          comment.attachments?.length === 1
                            ? " attachment"
                            : " attachments"
                        }`
                      : ""
                  }`}
                </React.Fragment>
              }
            >
              <div className="truncate-txt checklist-title-txt">
                {" "}
                {comment.title.replace(/(<([^>]+)>)/gi, "")}
                {comment.attachments?.length > 0 && (
                  <div style={{ fontSize: "13px" }}>
                    {`+ ${comment.attachments?.length} ${
                      comment.attachments?.length === 1
                        ? "attachment"
                        : "attachments"
                    }`}
                  </div>
                )}
              </div>
            </Tooltip>
            <span className="count ">
            </span>
          </div>
          <div className="progress_bar">
            <div className="checklist-progress-bar"></div>
            <div className="completed-percentage-text">
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className="checklist-bottom-container truncate-text ">
        <span className="left-col ">{comment.parent_activity_title} - </span>
        {comment.project}
      </div>
    </div>
  );
}

export default memo(CommentTile);
