import { billingConstant } from "../../constants/billingConstant";

const initialState = {
  updateSubscriptionLoader: {
    loading: false,
    loaded: false,
    error: false
  },
  cancelOrActivateSubscriptionLoader: {
    loading: false,
    loaded: false,
    error: false
  },
  billingInfo: {},
  chargesInfo: {},
  customerDetails: {}
};

export default function billingReducer(state = initialState, action) {
  switch (action.type) {
    case billingConstant.updateSubscriptionLoader:
      return {
        ...state,
        updateSubscriptionLoader: action.payload
      };

    case billingConstant.cancelOrActivateSubscriptionLoader:
      return {
        ...state,
        cancelOrActivateSubscriptionLoader: action.payload
      };
    case billingConstant.getSubscription:
      return {
        ...state,
        billingInfo: action.payload
      };

    case billingConstant.getCharges:
      return {
        ...state,
        chargesInfo: action.payload
      };

    case billingConstant.getCustomer:
      return {
        ...state,
        customerDetails: action.payload
      };

    case billingConstant.updateCustomer:
      return {
        ...state,
        customerDetails: action.payload
      };

    case billingConstant.cancelSubscription:
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          data: [
            ...state.billingInfo.data,
            {
              ...state.billingInfo.data[0],
              cancel_at_period_end: true
            }
          ]
        }
      };

    case billingConstant.reactivateSubscription:
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          data: [
            {
              ...state.billingInfo.data[0],
              cancel_at_period_end: false
            }
          ]
        }
      };
    default:
      return state;
  }
}
