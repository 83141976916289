import React, { Component } from "react";
import { connect } from "react-redux";
import Mark from "mark.js";

import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { unarchiveChecklistAction } from "../../actions/checklist/checklist";
import ChecklistTile from "./checklisTile";

import InfiniteScroll from "react-infinite-scroll-component";

class MoreChecklist extends Component {
  state = {
    noOfChecklist: 20,
    selectedProject: { id: 0, name: "All" },
    selectedActivity: { id: 0, title: " All" },
    showProjectDropDown: false,
    filteredArchivedChecklists: null,
    showActivityDropDown: false,
  };

  onShowMore = () => {
    
    this.setState({
      noOfChecklist: this.state.noOfChecklist + 10,
    });
  };

  onShowProjectDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showProjectDropDown: !this.state.showProjectDropDown,
    });
  };

  onShowActivityDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showActivityDropDown: !this.state.showActivityDropDown,
    });
  };

  onProjectClicked = (project) => {
    this.setState({
      selectedProject: project,
      selectedActivity: { id: 0, title: " All" },
    });
  };
  onActivityClicked = (activity) => {
    this.setState({
      selectedActivity: activity,
    });
  };

  applyingSelection = () => {
    const {
      // listOfProjects,
      // activities,
      archivedChecklists,
      loggedInUser,
    } = this.props;
    if (
      this.state.selectedActivity.id === 0 &&
      this.state.selectedProject.id === 0
    ) {
      this.setState({
        filteredArchivedChecklists: _.orderBy(archivedChecklists, [(archivedChecklist) => {
              let updatedAt = new Date(archivedChecklist.updated_at);
              return updatedAt.getTime();
            }], ["desc"])
          .filter((checklist) => checklist.activity.user_id.includes(parseInt(loggedInUser.id))),
      });
    }

    if (
      this.state.selectedActivity.id === 0 &&
      this.state.selectedProject.id !== 0
    ) {
      this.setState({
        filteredArchivedChecklists: _.orderBy(archivedChecklists, [(archivedChecklist) => {
              let updatedAt = new Date(archivedChecklist.updated_at);
              return updatedAt.getTime();
            }], ["desc"])
          .filter((checklist) =>
            checklist.activity.user_id.includes(parseInt(loggedInUser.id))
          )
          .filter(
            (checklist) =>
              checklist.activity.project.id === this.state.selectedProject.id
          ),
      });
    }

    if (this.state.selectedActivity.id !== 0) {
      this.setState({
        filteredArchivedChecklists: _.orderBy(archivedChecklists, [(archivedChecklist) => {
              let updatedAt = new Date(archivedChecklist.updated_at);
              return updatedAt.getTime();
            }], ["desc"])
          .filter((checklist) =>
            checklist.activity.user_id.includes(parseInt(loggedInUser.id))
          )
          .filter(
            (checklist) =>
              checklist.activity_id === this.state.selectedActivity.id
          ),
      });
    }

    if(this.props.searchContent.text) {
      var context = document.querySelectorAll(".checklist-title-txt");
      var instance = new Mark(context);
      instance.unmark();
      setTimeout(() => {
        var updatedContext = document.querySelectorAll(".checklist-title-txt");
        var updatedInstance = new Mark(updatedContext);
        updatedInstance.mark(this.props.searchContent.text, { element: "span", className: "marked-search", accuracy: "partially" });
      }, 100)
    }
  };

  onOutsideClick = (e) => {
    if (this.projectOptionsNode && !this.projectOptionsNode.contains(e.target))
      this.setState({
        showProjectDropDown: false,
      });
    if (
      this.activityOptionsNode &&
      !this.activityOptionsNode.contains(e.target)
    )
      this.setState({
        showActivityDropDown: false,
      });
  };
  render() {
    const {
      getChecklistLoaderState,
      searchContent,
      archivedChecklists,
      unarchiveChecklist,
      listOfProjects,
      activities,
      loggedInUser,
    } = this.props;
    const {
      showProjectDropDown,
      selectedProject,
      showActivityDropDown,
      selectedActivity,
      filteredArchivedChecklists,
    } = this.state;

    const filteredActivity =
      selectedProject.id !== 0
        ? activities.filter(
            (activity) => activity.project.id === selectedProject.id
          )
        : activities;
    const renderProjectOptions = listOfProjects.map((project) => {
      return (
        <li
          key={project.id}
          className="project-options"
          onClick={() => this.onProjectClicked(project)}
        >
          {project.name}
        </li>
      );
    });
    const renderActvitiesOptions = filteredActivity.map((activity) => {
      return (
        <li
          key={activity.id}
          className="project-options"
          onClick={() => this.onActivityClicked(activity)}
        >
          {activity.title}
        </li>
      );
    });

    const renderChecklists =
      filteredArchivedChecklists === null
        ? searchContent.text
          ? _.orderBy(archivedChecklists,
            [(archivedChecklist) => {
              let updatedAt = new Date(archivedChecklist.updated_at);
              return updatedAt.getTime();
            }],
            ["desc"])
          .filter((checklist) =>
            checklist.activity.user_id.includes(parseInt(loggedInUser.id)) &&
            checklist.title.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1
          )
          // .filter(checklist => checklist.title.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1)
          .map((checklist, index) => {
            // if (index < this.state.noOfChecklist) {
              return (
                <ChecklistTile
                  key={checklist.id}
                  unarchiveChecklistAction={unarchiveChecklist}
                  checklist={checklist}
                ></ChecklistTile>
              );
            // }
            // return;
          })
          : _.orderBy(archivedChecklists, [(archivedChecklist) => {
            let updatedAt = new Date(archivedChecklist.updated_at);
            return updatedAt.getTime();
          }], ["desc"])
            .filter((checklist) =>
              checklist.activity.user_id.includes(parseInt(loggedInUser.id))
            )
            .map((checklist, index) => {
              if (index < this.state.noOfChecklist) {
                return (
                  <ChecklistTile
                    key={checklist.id}
                    unarchiveChecklistAction={unarchiveChecklist}
                    checklist={checklist}
                  ></ChecklistTile>
                );
              }
              return null;
            })
        :
        searchContent.text
          ? filteredArchivedChecklists
              .filter(checklist => checklist.title.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1)
              .map((checklist, index) => {
                // if (index < this.state.noOfChecklist) {
                  return (
                    <ChecklistTile
                      key={checklist.id}
                      unarchiveChecklistAction={unarchiveChecklist}
                      checklist={checklist}
                    ></ChecklistTile>
                );
              // }
              // return;
            })
          : filteredArchivedChecklists.map(
              (checklist, index) => {
                if (index < this.state.noOfChecklist) {
                  return (
                    <ChecklistTile
                      key={checklist.id}
                      unarchiveChecklistAction={unarchiveChecklist}
                      checklist={checklist}
                    ></ChecklistTile>
                  );
                }
                return null;
              }
            );
    return (
      <div className="arch-center-tt-container with-no-scroll">
        <div className="comm-filter-control-tt-container filter-view archive-project">
          <div
            className="comm-filter-control-container archive-project"
            ref={(node) => (this.projectOptionsNode = node)}
          >
            <div className="comm-filter-control-title">Project :</div>
            <div
              className={
                showProjectDropDown
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown clicked"
                  : "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown"
              }
              onClick={this.onShowProjectDropdown}
            >
              <div class="workflow-change-ddown-text">
                {" "}
                {selectedProject.name}
              </div>
              {showProjectDropDown && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                  <div class="workflow-change-ddown">
                    {/* <ScrollBar> */}
                      <ul class="workflow-change-ddown-inner">
                        <li
                          className="project-options"
                          onClick={() =>
                            this.onProjectClicked({ id: 0, name: "All" })
                          }
                        >
                          All
                        </li>
                        {renderProjectOptions}
                      </ul>
                    {/* </ScrollBar> */}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <div
            className="comm-filter-control-container archive-project"
            ref={(node) => (this.activityOptionsNode = node)}
          >
            <div className="comm-filter-control-title">Activity :</div>
            <div
              className={
                selectedProject.id === 0
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown disabled"
                  : showActivityDropDown
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown clicked"
                  : "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown"
              }
              onClick={
                selectedProject.id === 0
                  ? () => {}
                  : (e) => this.onShowActivityDropdown(e)
              }
            >
              <div class="workflow-change-ddown-text">
                {" "}
                {selectedActivity.title}
              </div>
              {showActivityDropDown && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                  <div class="workflow-change-ddown">
                    {/* <ScrollBar> */}
                      <ul class="workflow-change-ddown-inner">
                        <li
                          className="project-options"
                          onClick={() =>
                            this.onActivityClicked({ id: 0, title: "All" })
                          }
                        >
                          All
                        </li>
                        {renderActvitiesOptions}
                      </ul>
                    {/* </ScrollBar> */}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <button
            className="comm-filter-btn apply"
            onClick={this.applyingSelection}
          >
            Apply
          </button>
        </div>
        <div
          className="arch-categ-outer-container"
          id="arch-categ-outer-container-scroll"
        >
          <InfiniteScroll
            dataLength={this.state.noOfChecklist}
            next={this.onShowMore}
            hasMore={true}
            scrollableTarget="arch-categ-outer-container-scroll"
          >
            <div className="arch-categ-container arch-categ-container-checklist">
              <div className="arch-categ-holder all-activities">
                {renderChecklists}
              </div>
            </div>
          </InfiniteScroll>
        </div>
        {getChecklistLoaderState.fetched &&
          ((filteredArchivedChecklists !== null && filteredArchivedChecklists.length === 0) ||
          (searchContent.text && renderChecklists.length === 0))
          && (
            <div className="no-checklist-found">
              No archived checklists found
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loginReducer.loginAuth.user_details,
    searchContent: state.commonReducer.searchContent,
    activities: [
      ...state.activityReducer.listOfArchivedActivity.list,
      ...state.activityReducer.listOfActivity.list,
      ...state.activityReducer.prioritiZedActivity.list,
    ],

    listOfProjects: [
      ...state.projectReducer.listOfProjects.list,
      ...state.projectReducer.listOfArchivedProjects.list,
    ],
    getChecklistLoaderState: state.checklistReducer.getChecklistLoaderState,
    archivedChecklists: state.checklistReducer.archivedChecklists.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unarchiveChecklist: (
      { activity_id, title, created_by, position, id },
      callback
    ) => {
      dispatch(
        unarchiveChecklistAction(
          { activity_id, title, created_by, position, id },
          callback
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreChecklist);
