import React, { useState, useRef, memo } from "react";
import OutsideClickHandler from "react-outside-click-handler";
// import { Scrollbar } from "react-scrollbars-custom";
import arrowDown from "../../styles/img/svg/report-arrow-down.svg";
import arrowUp from "../../styles/img/svg/arrow_up.svg";
import userSearch from "../../styles/img/svg/user-search.svg";


const ProjectDropDown = ({
  projectList,
  selectedProjectId,
  selectedProject,
  onChangeProject
}) => {
  const projectDropdownNode = useRef();
  const [showDropDown, setShowDropDown] = useState(false);
  const [updatedProject, setUpdatedProject] = useState(null);

  const onShowDropDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropDown(!showDropDown);
  };

  const onOutsideClickDropDown = (e) => {
    if (
      projectDropdownNode &&
      !projectDropdownNode.current.contains(e.target)
    ) {
      if (showDropDown){
        setShowDropDown(false);
        setUpdatedProject(null);
      } 
    }
  };

  const onSelectProject = (event, selectedProject, selectedProjectId) => {
    onChangeProject(selectedProjectId, selectedProject);
    setShowDropDown(false);
  };

  const onChangeSearch = event => {
    let searchText = event.target.value;
    let updatedWorkSpaceUsers = projectList.filter(
      project =>
      project.name
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1 
    );
    setUpdatedProject(updatedWorkSpaceUsers);
  };

  let projectData= updatedProject  ||  projectList;

  return (
    <div className="comm-filter-control-holder" ref={projectDropdownNode}>
      <div className="project-holder" onClick={onShowDropDown}>
        <span>{selectedProject}</span>
        <img src={showDropDown ? arrowUp : arrowDown} alt="arrow" />
      </div>
     
      {showDropDown && (
        <OutsideClickHandler onOutsideClick={onOutsideClickDropDown}>
          <div className="member-dropdown">
            <div className="demo-select-header">
              <div className="user-search">
                <img src={userSearch} alt="search" />
              </div>
              <input
                type="search"
                className="demo-header-searchbox md-text"
                placeholder="Search for a Project"
                onChange={onChangeSearch}
              />
            </div>
            <div className="member-outside">
              {/* <Scrollbar className="report-proj-scroll"> */}
              {projectData.map((project, index) => (
                <div
                  key={index}
                  className={`proj-name ${project.id === selectedProjectId &&
                    "active-proj"}`}
                  onClick={(event) =>{
                    onSelectProject(event, project.name, project.id);
                    setUpdatedProject(null);
                  }
                  }
                >
                  {project.name}
                </div>
              ))}
              {/* </Scrollbar> */}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default memo(ProjectDropDown);
