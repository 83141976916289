import { createStore, applyMiddleware, compose } from 'redux';
//for sync
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/index';

let configureStore = (function () {
  let instance;
  function configureStore() {
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;
    const enhancer = composeEnhancers(
      applyMiddleware(
        thunkMiddleware
      )
    );
    const store = createStore(rootReducer, enhancer);
    return store;
  }
  return {
    getInstance: function () {
      if (!instance) {
        instance = configureStore();
      }
      return instance;
    }
  };
})();

export default configureStore;
