export const checklistConstant = {
  getChecklistLoaderState: "GET_CHECKLIST_LOADER_STATE",
  addChecklistLoaderState: "ADD_CHECKLIST_LOADER_STATE",
  updateChecklistLoaderState: "UPDATE_CHECKLIST_LOADER_STATE",
  addChecklist: "ADD_CHECKLIST",
  getChecklist: "GET_CHECKLIST",
  searchChecklist: "SEARCH_CHECKLIST",
  getChecklistArchived: "GET_ARCHIVED_CHECLIST",
  unarchiveChecklist: "UNARCHIVE_CHECKLIST",
  removeChecklist: "REMOVE_CHECKLIST",
  updateChecklist: "UPDATE_CHECKLIST",
  addChecklistItem: "ADD_CHECKLIST_ITEM",
  updateChecklistItemLoaderState: "UPDATE_CHECKLIST_ITEM_LOADER_STATE",
  updateChecklistItem: "UPDATE_CHECKLIST_ITEM",
  deleteChecklistItem: "DELETE_CHECKLIST_ITEM"
};
