import { reports } from "../../constants/reportsconstants";
import moment from "moment";
import _ from "lodash";

const initialState = {
  timeSheetListLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateTimeSheetLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  listOfTimeSheet: {
    list: [],
    key: null
  },
  filters: {
    projectId: "all",
    selectedProject: "All",
    memberId: parseInt(localStorage.getItem("id")),
    selectedMember: localStorage.getItem("first_name"),
    from: moment(
      new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
    ).format("ddd, DD MMM YY"),
    to: moment(new Date()).format("ddd, DD MMM YY"),
    showResetFilter: false,
    activityType:'All'
  },
  sort: {
    sortBy: reports.date,
    sortType: false
  },
  activityTypeData:[]

};

export default function timeSheetReducer(state = initialState, action) {
  switch (action.type) {
    case reports.timeSheetListLoaderState:
      return {
        ...state,
        timeSheetListLoaderState: action.payload
      };
    case reports.timeSheetList:
      return {
        ...state,
        listOfTimeSheet: {
          list: action.payload.list
        }
      };
    case reports.deleteTimeSheet:
      let updatedList = [...state.listOfTimeSheet.list].filter(
        timeSheet => timeSheet.id !== action.payload.id
      );
      return {
        ...state,
        listOfTimeSheet: {
          list: updatedList
        }
      };
    case reports.filterTimeSheet:
      return {
        ...state,
        filters: action.payload
      };
    case reports.sortTimeSheet:
      return {
        ...state,
        sort: action.payload
      };
    case  reports.FETCH_ALL_ACTIVITY_TYPE: {
     let sortedActivityType= _.sortBy( [...action.payload],[type => type.toLowerCase()],["asc"])
        return{
          ...state,
          activityTypeData: ['All',...sortedActivityType]
        }  
      }   
    default:
      return {
        ...state
      };
  }
}
