import React, { Component } from "react";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";

import * as action from "../../actions/index";
import RoleDropdwn from "./roleDropdwn";
import {
  getUpdatedProjectObj,
  // getProjectMembers
} from "../../helpers/projectsettings";
import _ from "lodash";
import { toast } from "react-toastify";
import accessAdd from "../../styles/img/svg/access-add.svg";
import { getIsCreator, getIsSuperAdmin } from "../../helpers/reportlanding";
import { getIsAccessToAddRemoveMember } from "../../helpers/generichelper";
import { getProjectById } from "../../services/projectapi";

class ProjectTable extends Component {
  state = {
    index: -1,
    displayDdwn: false,
    accessIndex: -1,
    showAccessDropDown: false,
    selectedProject: null
  };
  onClickRole = (_e, index, project) => {
    // console.log("Index opened", index, this.state.index, this.state.displayDdwn, this.state.selectedProject)
    if (index === this.state.index && this.state.displayDdwn)
      this.setState({
        displayDdwn: false,
        index: -1,
        selectedProject: null
      });
    else
      this.setState({
        displayDdwn: true,
        index,
        selectedProject: project
      });
  };
  onClickDdwnClose = (_e, index) => {
    if (index === this.state.index && this.state.displayDdwn)
      this.setState({
        displayDdwn: false,
        index: -1,
        selectedProject: null
      });
    
  };

  onShowAccessDropDown = (_e, index = -1) => {
    const { projects } = this.props;
    if(index === this.state.accessIndex && this.state.showAccessDropDown)
      this.setState({ showAccessDropDown: false, accessIndex: -1 });
    else this.setState({ showAccessDropDown: !this.state.showAccessDropDown, accessIndex: index },
      () => {
        let element = document.getElementById("access-lvl-ddown");
        if (element !== null && index > 2 && index + 1 === projects.length) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      });
  };

  onOutsideClickAccess = (_e, index) => {
    if (this.state.showAccessDropDown && index === this.state.accessIndex)
      this.setState({ showAccessDropDown: false, accessIndex: -1 });
};

  onSelectAccess = access => {
    const { selectedProject } = this.state;
    let team =
      this.props.userToShow.data &&
      this.props.userToShow.data.settings &&
      !_.isNull(this.props.userToShow.data.settings) &&
      !_.isUndefined(
        this.props.userToShow.data.settings[this.props.currentWorkSpaceId.id]
      )
        ? this.props.userToShow.data.settings[this.props.currentWorkSpaceId.id]
            .team
        : null;
    getProjectById(selectedProject.archived, selectedProject.id, response => {
      const project = getUpdatedProjectObj(
        response?.length > 0 ? response[0] : this.state.selectedProject,
        { userId: this.props.userToShow.id, team },
        access
      );
      this.props.updateProjectAction(
        { queryParam: "", body: project },
        response => {
          if (response && response.id) {
            toast("Changes saved successfully!");
            this.setState({
              displayDdwn: false,
              index: -1,
              selectedProject: null
            });
          }
        }
      );
    })
  };

  handleAddWidget = (chip, project) => {
    const user_id = parseInt(this.props.selectedUserId);
    getProjectById(project.archived, project.id, response => {
      const updatedProject = response?.length > 0 ? response[0] : project;
      let body = {
        ...updatedProject,
        client: null,
        client_id: null,
        data: {
          ...updatedProject.data,
          settings: {
            ...updatedProject.data.settings,
            member_access: {
              ...updatedProject.data.settings.member_access,
              [user_id]: {
                ...updatedProject.data.settings.member_access[user_id],
                widget: [
                  ...updatedProject.data.settings.member_access[user_id].widget,
                  chip
                ]
              }
            }
          }
        }
      };
      delete body.created_at;
      delete body.updated_at;
      body = _.pickBy(body, _.identity);
      // console.log("body", body)
      this.setState({ showAccessDropDown: false, accessIndex: -1 });
      this.props.updateProjectAction({ queryParam: "", body }, response => {
        if (response && response.id) {
          toast("Changes saved successfully!");
        }
      });
    });
  };

  handleDeleteWidget = (chip, project) => {
    const user_id = parseInt(this.props.selectedUserId);
    getProjectById(project.archived, project.id, response => {
      const updatedProject = response?.length > 0 ? response[0] : project;
      let body = {
        ...updatedProject,
        client: null,
        client_id: null,
        data: {
          ...updatedProject.data,
          settings: {
            ...updatedProject.data.settings,
            member_access: {
              ...updatedProject.data.settings.member_access,
              [user_id]: {
                ...updatedProject.data.settings.member_access[user_id],
                widget: [
                  ...updatedProject.data.settings.member_access[user_id].widget.filter(
                    wgt => wgt !== chip
                  )
                ]
              }
            }
          }
        }
      };
      delete body.created_at;
      delete body.updated_at;
      body = _.pickBy(body, _.identity);
      // console.log("body", body)
      this.props.updateProjectAction({ queryParam: "", body }, response => {
        if (response && response.id) {
          toast("Changes saved successfully!");
        }
      });
    });
  };

  render() {
    const { currentUser, currentWorkSpaceId, widgets } = this.props;
    const ownerWidget = ["Reports", "Add/Remove members"];
    const isCreator = getIsCreator(currentWorkSpaceId);
    const isOwner = getIsSuperAdmin(currentUser, currentWorkSpaceId);

    return (
      <div className="prj-access-table-container">
        <table className="prj-access-table">
          <thead>
            <tr>
              <td width="25%">Project</td>
              <td width="20%">Role</td>
              <td width="35%">Access level</td>
            </tr>
          </thead>
          <tbody>
            {this.props.projects.length > 0 &&
              this.props.projects.map((project, index) => (
                <tr key={index}>
                  {/* display project name */}
                  <td>
                    <p className="prj-access-prj-name">{project.name}</p>
                  </td>
                  {/* display role for creator*/}
                  {this.props.showCreatorDetails && (
                    <td>
                      <div className="owner-ps">Creator</div>
                    </td>
                  )}
                  {/* display role for owner*/}
                  {
                  // this.props.showOwnerDetails &&
                    !isCreator &&
                    !this.props.showCreatorDetails &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Owner" && (
                    <td>
                      <div className="owner-ps">Owner</div>
                    </td>
                  )}
                  {/* display role for owner with dropdown access*/}
                  {
                  // this.props.showOwnerDetails &&
                    isCreator &&
                    !this.props.showCreatorDetails &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Owner" && (
                      <td>
                      <OutsideClickHandler
                        onOutsideClick={e => this.onClickDdwnClose(e, index)}
                      >
                        <div
                          className="prj-access-role-holder"
                          onClick={e => this.onClickRole(e, index, project)}
                        >
                          Owner
                        </div>
                        {this.state.displayDdwn &&
                          index === this.state.index && (
                            <RoleDropdwn
                              projectMember="Owner"
                              index={index}
                              onSelectAccess={this.onSelectAccess}
                              onClickDdwnClose={this.onClickDdwnClose}
                            />
                          )}
                      </OutsideClickHandler>
                    </td>
                  )}
                  {/* display role for manager*/}
                  {
                  // this.props.showOwnerDetails &&
                    !(isCreator || getIsAccessToAddRemoveMember(project)) &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Manager" && (
                    <td>
                      <div className="owner-ps">Manager</div>
                    </td>
                  )}
                  {/* display role for manager with dropdown access*/}
                  {
                  // !this.props.showOwnerDetails &&
                    (isCreator || getIsAccessToAddRemoveMember(project)) &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Manager" && (
                      <td>
                        <OutsideClickHandler
                          onOutsideClick={e => this.onClickDdwnClose(e, index)}
                        >
                          <div
                            className="prj-access-role-holder"
                            onClick={e => this.onClickRole(e, index, project)}
                          >
                            Manager
                          </div>
                          {this.state.displayDdwn &&
                            index === this.state.index && (
                              <RoleDropdwn
                                projectMember="Manager"
                                index={index}
                                onSelectAccess={this.onSelectAccess}
                                onClickDdwnClose={this.onClickDdwnClose}
                              />
                            )}
                        </OutsideClickHandler>
                      </td>
                    )}
                  {/* display role for member*/}
                  {
                  // this.props.showOwnerDetails &&
                    !(isCreator || getIsAccessToAddRemoveMember(project)) &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Member" && (
                    <td>
                      <div className="owner-ps">Member</div>
                    </td>
                  )}
                  {/* display role for member with dropdown access*/}
                  {
                  // !this.props.showOwnerDetails &&
                    (isCreator || getIsAccessToAddRemoveMember(project)) &&
                    project.data.settings.member_access &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ] &&
                    project.data.settings.member_access[
                      this.props.selectedUserId
                    ].access === "Member" && (
                      <td>
                        <OutsideClickHandler
                          onOutsideClick={e => this.onClickDdwnClose(e, index)}
                        >
                          <div
                            className="prj-access-role-holder"
                            onClick={e => this.onClickRole(e, index, project)}
                          >
                            {" "}
                            Member
                          </div>

                          {this.state.displayDdwn &&
                            index === this.state.index && (
                              <RoleDropdwn
                                projectMember="Member"
                                index={index}
                                onSelectAccess={this.onSelectAccess}
                                onClickDdwnClose={this.onClickDdwnClose}
                              />
                            )}
                        </OutsideClickHandler>
                      </td>
                    )}
                  
                  <td>
                    <div className="widget-tags-container">
                      {/* display access level for Creator */}
                      {this.props.showCreatorDetails &&
                        ownerWidget.map((widget, index) => (
                          <div
                            className="widget-tags-holder"
                            style={{ marginRight: "10px !important" }}
                            key={index}
                          >
                            <span>{widget}</span>
                          </div>
                      ))}
                      {/* display access level for Owner */}
                      {
                      this.props.showOwnerDetails &&
                        isOwner && !isCreator &&
                        project.data.settings.member_access &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ] &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ].access === "Owner" &&
                        project.data.settings.member_access[this.props.selectedUserId].widget.map((widget, index) => (
                          <div
                            className="widget-tags-holder"
                            style={{ marginRight: "10px !important" }}
                            key={index}
                          >
                            <span>{widget}</span>
                          </div>
                      ))}
                      {/* display access level for Owner with edit access */}
                      {
                      // !this.props.showOwnerDetails &&
                        isCreator &&
                        !this.props.showCreatorDetails &&
                        project.data.settings.member_access &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ] &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ].access === "Owner" &&
                        project.data.settings.member_access[this.props.selectedUserId].widget.length >= 0 && (
                          <div className="input-chips access-level">
                            {project.data.settings.member_access[this.props.selectedUserId].widget.length > 0 &&
                            project.data.settings.member_access[this.props.selectedUserId].widget.map(access =>
                              <div>
                              <div className="readOnlyItem">
                                <span>
                                  {access}
                                </span>
                                <svg
                                  className="read-only"
                                  focusable="false"
                                  onClick={() => this.handleDeleteWidget(access, project)}>
                                </svg>
                              </div> 
                            </div>)}
                            {widgets.filter(widget => !project.data.settings.member_access[this.props.selectedUserId].widget.includes(widget.name)).length > 0 && 
                            <div>
                            <OutsideClickHandler onOutsideClick={e => this.onOutsideClickAccess(e, index)}>
                            <img style={{ paddingTop: "6px" }} role="button" src={accessAdd} alt="add" onClick={e => this.onShowAccessDropDown(e, index)} />
                            {this.state.showAccessDropDown && index === this.state.accessIndex &&
                            <div id="access-lvl-ddown" className="prj-access-role-ddown access-ddown">
                              {widgets.filter(widget => !project.data.settings.member_access[this.props.selectedUserId].widget.includes(widget.name)).map(widget =>
                              <div className="prj-access-role-ddown-list access-ddown-list" onClick={() => this.handleAddWidget(widget.name, project)}>
                                {widget.name}
                              </div>
                            )}
                            </div>
                            }
                            </OutsideClickHandler>
                          </div>}
                          </div>
                          // <ChipInput
                          //   value={
                          //     project.data.settings.member_access[
                          //       this.props.selectedUserId
                          //     ].widget
                          //   }
                          //   onAdd={chip => this.handleAddWidget(chip, project)}
                          //   onDelete={chip =>
                          //     this.handleDeleteWidget(chip, project)
                          //   }
                          //   className="chip-input widget"
                          // />
                        )}
                      
                      {/* display access level for Manager with edit access */}
                      {
                      // !this.props.showOwnerDetails &&
                        (isCreator || isOwner) &&
                        project.data.settings.member_access &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ] &&
                        project.data.settings.member_access[
                          this.props.selectedUserId
                        ].access === "Manager" &&
                        project.data.settings.member_access[this.props.selectedUserId].widget.length >= 0 && (
                          <div className="input-chips access-level">
                            {project.data.settings.member_access[this.props.selectedUserId].widget.length > 0 &&
                            project.data.settings.member_access[this.props.selectedUserId].widget.map(access =>
                              <div>
                              <div className="readOnlyItem">
                                <span>
                                  {access}
                                </span>
                                <svg
                                  className="read-only"
                                  focusable="false"
                                  onClick={() => this.handleDeleteWidget(access, project)}>
                                </svg>
                              </div> 
                            </div>)}
                            {widgets.filter(widget => !project.data.settings.member_access[this.props.selectedUserId].widget.includes(widget.name)).length > 0 && 
                            <div>
                            <OutsideClickHandler onOutsideClick={e => this.onOutsideClickAccess(e, index)}>
                            <img style={{ paddingTop: "6px" }} alt="add" role="button" src={accessAdd} onClick={e => this.onShowAccessDropDown(e, index)} />
                            {this.state.showAccessDropDown && index === this.state.accessIndex &&
                            <div id="access-lvl-ddown" className="prj-access-role-ddown access-ddown">
                              {widgets.filter(widget => !project.data.settings.member_access[this.props.selectedUserId].widget.includes(widget.name)).map(widget =>
                              <div className="prj-access-role-ddown-list access-ddown-list" onClick={() => this.handleAddWidget(widget.name, project)}>
                                {widget.name}
                              </div>
                            )}
                            </div>
                            }
                            </OutsideClickHandler>
                          </div>}
                          </div>
                          // <ChipInput
                          //   value={
                          //     project.data.settings.member_access[
                          //       this.props.selectedUserId
                          //     ].widget
                          //   }
                          //   onAdd={chip => this.handleAddWidget(chip, project)}
                          //   onDelete={chip =>
                          //     this.handleDeleteWidget(chip, project)
                          //   }
                          //   className="chip-input widget"
                          // />
                        )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    // getAllCustomer: ({ auth_token }, callback) => {
    //   dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    // },
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    }
  };
};
export default connect(null, mapDispatchToProps)(ProjectTable);
