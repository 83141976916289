import React, { useState, useEffect, memo } from "react";
import checkedOvalSVG from "../../styles/img/svg/checked_oval.svg";
import closeNewSVG from "../../styles/img/svg/close_new.svg";
import { MentionsInput, Mention } from "react-mentions";
import Avatar from "react-avatar";
import _ from "lodash";
import { connect } from "react-redux";
const ChecklistItemInput = ({
  users,
  checklist,
  className,
  activityDetails,
  // is_archived,
  createChecklistItemAction,
  value,
  setEditfalse,
  checklistItem,
  updateChecklistItemAction,
  toHideAddItemInput,
  onchecklistUpdated,
  scrollHeight,
}) => {
  const [inputText, setInputText] = useState(value || "");
  const [textBoxActive, setTextBoxActive] = useState(false);
  const [fetching, setFetching] = useState(false);

  const dataForMentions = [
    {
      id: 0,
      display: "act",
      image: "",
      username: "Act",
    },
    ..._.sortBy(
      (activityDetails?.is_locked
        ? users.filter((user) => activityDetails.user_id.includes(user.id))
        : users
      ).map((user) => {
        return {
          id: user.id,
          display: user.username,
          image: user.img || "",
          username: user.first_name,
        };
      }),
      (user) => user.username.toLowerCase()
    ),
  ];

  useEffect(() => {
    setTimeout(() => {
      if (
        document.getElementsByClassName(
          "checklist-textarea-input__suggestions__list"
        )[0]
      ) {
        const liElement = document.createElement("li");
        liElement.classList += "checklist-textarea-input__suggestions__item";
        var para = document.createElement("span");
        para.className += "allnotify";
        const textInsideSpan = document.createTextNode(
          "@act will notify all members in the activity"
        );
        para.appendChild(textInsideSpan);
        const elementWhereToInsert = document.getElementsByClassName(
          "checklist-textarea-input__suggestions__list"
        )[0];
        
        if (document.getElementsByClassName("allnotify").length > 0) {
          return;
        } else {
          elementWhereToInsert.insertBefore(
            para,
            elementWhereToInsert.firstChild
          );
        }
      }
    }, 10);
  });

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      // rect.top >= 0 &&
      // rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const onSaveCheckListItem = (addingWithEnter) => {
    setFetching(true);
    const currentUserId = localStorage.getItem("id");
    const updatedcheckListItem = {
      title: inputText,
      checklist_id: checklist.id,
      is_archived: false,
      required: "NONE",
      created_by: parseInt(currentUserId),
    };
    createChecklistItemAction({ ...updatedcheckListItem }, (res) => {
      
      setFetching(false);
      if (res && !res.stdErr) {
        setInputText("");

        !addingWithEnter && toHideAddItemInput();
        onchecklistUpdated();
      }
    });
  };

  

  const onCheckListItemTitleUpdate = () => {
    setFetching(true);
    const updatedCheckListItem = {
      checklist_id: checklistItem.checklist_id,
      created_by: checklistItem.created_by,
      id: checklistItem.id,
      is_archived: checklistItem.is_archived,
      is_completed: checklistItem.is_completed,
      title: inputText,
    };

    updateChecklistItemAction({ ...updatedCheckListItem }, (res) => {
      
      setFetching(false);
      if (res && !res.stdErr) {
        setEditfalse();
        onchecklistUpdated();
      }
    });
  };

  function moveCursorAtEnd(e) {
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
    const inputBox = document.querySelector(".add-item-input");
    if (inputBox && isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
  }

  const renderSuggestion = (
    entry,
    _search,
    _highlightedDisplay,
    _index,
    _focused
  ) => {
    return (
      <>
        <div
          className={
            !entry.image ? "comments-people avatar" : "comments-people"
          }
        >
          {entry.image && (
            <img src={entry.image} alt="user-img" className="user-photo" />
          )}
          {!entry.image && (
            <Avatar
              style={{ width: "100%", height: "100%" }}
              name={entry.display}
              maxInitials={1}
            ></Avatar>
          )}

          <span>{entry.username}</span>
        </div>
        <div
          className={
            !entry.image
              ? "mentio-name avatar ng-binding"
              : "mentio-name ng-binding"
          }
        >
          {entry.display}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="add-item-input"
        draggable
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="wrap-text-cross">
          <img
            src={checkedOvalSVG}
            alt="Smiley face"
            className="check-round"
            pcomment
            role="button"
            tabIndex="0"
          />
          <MentionsInput
            value={inputText}
            onChange={(_event, newValue, _newPlainTextValue, _mentions) => {
              const inputBox = document.querySelector(".add-item-input");
              if(inputBox && isInViewport(inputBox) === false) {
                
                const elementsToScroll = document.querySelectorAll('[role="dialog"]')[0];
                elementsToScroll.scrollTo({ top: scrollHeight + 40, behavior: 'smooth' });
                
              }
              const newString = newValue === "\n" ? [] : newValue.split(" ");
              const manipulatedString = newString.map((val) => {
                if (val.indexOf("@") === 0) {
                  let newThing = val.replace(/{/g, "");
                  newThing = newThing.replace(/}/g, "");
                  return newThing;
                }
                return val;
              });

              setInputText(manipulatedString.join(" "));
            }}
            onKeyPress={(e) => {
              setTextBoxActive(true);
              if (e.key === "Enter" && inputText.length > 0) {
                e.preventDefault();
                return checklistItem
                  ? !fetching && onCheckListItemTitleUpdate()
                  : !fetching && onSaveCheckListItem(true);
              }
              if (e.key === "Enter" && inputText.length === 0) {
                
                return setInputText("");
              }
              return;
            }}
            className={className}
            autoFocus
            onFocus={moveCursorAtEnd}
            // singleLine = {true}
          >
            <Mention
              type="user"
              trigger="@"
              markup="@{{__display__}}"
              data={dataForMentions}
              renderSuggestion={renderSuggestion}
              displayTransform={(_id, display) => `@${display}`}
              appendSpaceOnAdd={true}
            ></Mention>
          </MentionsInput>
          <img
            src={closeNewSVG}
            alt="Smiley face"
            className="cross"
            height="10"
            width="10"
            role="button"
            tabIndex="0"
            aria-hidden="false"
            onClick={setEditfalse}
          />
        </div>

        <button
          className="save-button"
          onClick={
            checklistItem && !fetching
              ? () => {
                  toHideAddItemInput();
                  onCheckListItemTitleUpdate();
                }
              : () => {
                  
                  !fetching && onSaveCheckListItem();
                }
          }
          disabled={inputText.length > 0 ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    scrollHeight: state.dialogReducer.scrollHeight,
  };
};

export default memo(connect(mapStateToProps, null)(ChecklistItemInput));
