import React, { Component } from "react";
import { connect } from "react-redux";

import routesHelper from "../../router/routeshelper";
import GTDLogo from "../../styles/img/svg/Gtd-logo-outside.svg";
import "../../styles/scss/register.scss";
import "../../styles/scss/finishprofile.scss";
import { commonContants } from "../../helpers/commonconstants";
import ImageUploader from "../common/imageuploader";

import { message } from "../../constants/messages";
import { Scrollbar } from "react-scrollbars-custom";
import { Autocomplete } from "react-md";
import * as actions from "../../actions";
import { getAllCustomerForUserAction, getAllPendingInvitesAction } from "../../actions/workspace/workspaceaction";
import countries from "../../constants/countryconstant";
import Fuse from "fuse.js";
import MaterialButton from "../common/materialbutton";

import firebase from "../../firebase";
import {
  getProfileDetails,
  isEmailValid,
  updateProfileDetails
} from "../../helpers/generichelper";

const mapStateToProps = (state) => {
  return {
    customerLoaderState: state.wrorkSpaceReducer.customerLoaderState,
    listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list,
    userProfileDetails: state.rrfStatereducer.profile,
    changePasswordDetails: state.rrfStatereducer.changePassword,
    profileLoaderState: state.commonReducer.profileLoaderState,
    userPhotoUpdateLoaderState: state.commonReducer.userPhotoUpdateLoaderState,
    registrationActionState: state.loginReducer.registrationActionState,
    loginAuth: state.loginReducer.loginAuth
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey
          },
          callback
        )
      );
    },
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        actions.uploadPhotoToS3Bucket(
          {
            file,
            loaderKey
          },
          callback
        )
      );
    },
    getUserDetailsAction: ({ queryparams }, callback) => {
      dispatch(actions.getUserDetailsAction({ queryparams }, callback));
    },
    updateUserProfileAction: (
      { first_name, id, email, organization_name, country, img, tags },
      callback
    ) => {
      dispatch(
        actions.updateUserProfileAction(
          {
            first_name,
            organization_name,
            country,
            id,
            email,
            img,
            tags
          },
          callback
        )
      );
    },
    suggestWorkspaceActions: ({ auth_token, user_id }, callback) => {
      dispatch(
        actions.suggestWorkspaceAction({ auth_token, user_id }, callback)
      );
    },
    getAllPendingInviteListAction(auth_token, callback) {
      dispatch(getAllPendingInvitesAction(auth_token, callback));
    }
  };
};

class FinishProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogo: null,
      imageLoading: false,
      filterType: Autocomplete.fuzzyFilter,
      name: localStorage.getItem("first_name") || "",
      nameErr: null,
      organizationName: "",
      country: "",
      filterText: "",
      noCountryFound: false,
      show: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  indexer = new Fuse(
    countries.map((lang) => ({ primaryText: lang })),
    {
      keys: [{ name: "primaryText", weight: 1 }]
    }
  );

  filterWithFuseJS = (_data, filterText, _dataLabel) => {
    // eslint-disable-line no-unused-vars, arrow-body-style
    this.setState({ filterText });
    const pattern = `^${filterText}`;
    const regex = new RegExp(pattern, "i");
    const filteredCountries = [];
    countries.forEach((country) => {
      
      if (regex.test(country)) {
        filteredCountries.push({ primaryText: country });
      }
    });
    if (filteredCountries.length < 1) {
      //filteredCountries.push({ primaryText: `No countries matching "${filterText}" were found.` })
      this.setState({ noCountryFound: true, show: true });
    } else {
      this.setState({ noCountryFound: false });
    }
    ////console.log("manual filter",filteredCountries)
    // //console.log(this.indexer.search(filterText))
    return filteredCountries;
    
  };

  clickOnUploadFile = () => {
    
    this.uploadFileRef && this.uploadFileRef.click();
  };

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onChangeCountry = (event) => {
    this.setState({
      country: event
    });
  };

  findIfAnySuggetions = () => {
    
    const { router, suggestWorkspaceActions } = this.props;
    let auth_token = localStorage.getItem("auth_token");
    let user_id = localStorage.getItem("id");
    
    suggestWorkspaceActions({ auth_token, user_id }, (_response) => {
      router.push(routesHelper.getJoinWorkspacePath());
    });
  };

  findIfAnyInvitations = () => {
    
    const { router, getAllPendingInviteListAction } = this.props;
    let auth_token = localStorage.getItem("auth_token");
    
    getAllPendingInviteListAction(auth_token, (response) => {
      if(response && response.length > 0)
        router.push(routesHelper.getWorkspaceInvitationsPath());
      else
        this.findIfAnySuggetions();
    });
  };

  componentWillMount() {
    
    if (this.props.customerLoaderState.fetched && this.props.listOfCustomer) {
      this.getUserDetails(this.props.listOfCustomer)
    } else {
      const auth_token = localStorage.getItem("auth_token");
      this.props.getAllCustomersAction({ auth_token }, (response) => {
        this.getUserDetails(response)
      });
    }
  }

  getUserDetails = (response) => {
    if (response && !response.stdErr) {
      
      if (response.length > 0) {
        this.props.router.push("/activity");
      } else {
        this.props.getUserDetailsAction(
          {
            queryparams: `?id=${localStorage.getItem("id")}`
          },
          (response) => {
            if (response && !response.stdErr) {
              this.setState({
                clientLogo: response[0].img,
                organizationName: response[0].organization_name || "",
                country: response[0].country || "",
                filterText: response[0].country || ""
              });
            }
          }
        );
      }
    }
  }

  uploadInitiated = () => {
    
    this.setState(() => ({
      imageLoading: true
    }));
    const { uploadPhotoToS3BucketAction } = this.props;
    let selectedFiles =
      this.uploadFileRef &&
      this.uploadFileRef.files &&
      this.uploadFileRef.files;
    if (selectedFiles && selectedFiles.length) {
      uploadPhotoToS3BucketAction(
        {
          file: selectedFiles[0],
          loaderKey: commonContants.updateCustomerPhotoLoaderAction
        },
        (response) => {
          if (response && response.success) {
            response.img &&
              this.setState({
                clientLogo: response.img,
                imageLoading: false
              });
          }
        }
      );
    } else {
      this.setState({
        imageLoading: false
      });
    }
  };

  onAutocompleteChange(countryList) {
    
    this.setState({
      country: countryList[0]
    });
  }

  onFocusCountry = () => {
    if (this.state.noCountryFound) {
      this.setState({ show: true });
    }
  };

  onBlurCountry = () => {
    this.setState({ show: false });
  };

  onInputKeyUp = (e) => {
    if (e.key === "Enter") this.onFormSubmit(e);
  };

  onFormSubmit(e) {
    e.preventDefault();
    
    let noOfErrors = [];
    if (!this.state.name || this.state.name.length === 0) {
      noOfErrors.push(false);
      this.setState({ nameErr: message.filedShouldNotBeEmpty });
    } else if (this.state.name && isEmailValid(this.state.name)) {
      noOfErrors.push(false);
      this.setState({ nameErr: message.enterValidFullName });
    } else if (this.state.name || this.state.name.length > 0) {
      this.setState({ nameErr: null });
    }

    
    if (noOfErrors.length === 0) {
      const first_name = this.state.name;
      
      const organization_name = this.state.organizationName;
      const img = this.state.clientLogo || "";
      const id = parseInt(getProfileDetails().id);
      const country = this.state.country;
      this.props.updateUserProfileAction(
        { first_name, organization_name, img, id, country },
        (response) => {
          if (response && !response.error && !response.stdErr) {
            firebase.analytics().logEvent("GTD_Registration_Flow_Organization_Success");
            updateProfileDetails(response);
            
            this.findIfAnyInvitations();
          }
        }
      );
    }
  }

  componentDidMount() {
    window.onpopstate = (e) => {
      e.preventDefault();
      localStorage.clear();
      this.props.router.push("/login");
    };
  }

  componentWillUnmount() {
    // TODO document why this method 'componentWillUnmount' is empty
  
    
  }

  render() {
    const uploadFileRef = (ref) => {
      this.uploadFileRef = ref;
    };
    

    return (
      <Scrollbar>
        <div className="joinWorkspace-container">
          <div className="logo-container">
            <img src={GTDLogo} alt="GTD-logo" />
          </div>

          <div className="register-successfull">
            <p className="title-sucess">Registration successful!</p>
            <p className="comple-profile">
              You have successfully registered with Get Things Done. Now
              complete finishing your profile.{" "}
            </p>
            <form>
              <div className="profile-register">
                <div className="left-profile">
                  <ImageUploader
                    uploadFileRef={uploadFileRef}
                    clickOnUploadFile={this.clickOnUploadFile}
                    uploadInitiated={this.uploadInitiated}
                    clientLogo={this.state.clientLogo}
                    imageLoading={this.state.imageLoading}
                  />
                </div>
                <div className="right-profile">
                  <div className="input-hold">
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={(event) => this.onChangeInput(event)}
                      className="finish-profile-input"
                      placeholder="Name"
                      onKeyUp={this.onInputKeyUp}
                      autoFocus
                    />
                    {this.state.nameErr && (
                      <p className="common-err-msg">{this.state.nameErr}</p>
                    )}
                  </div>
                  <div className="input-hold">
                    <input
                      type="text"
                      name="organizationName"
                      value={this.state.organizationName}
                      onChange={(event) => this.onChangeInput(event)}
                      className="finish-profile-input"
                      placeholder="Organization name"
                      onKeyUp={this.onInputKeyUp}
                    />
                  </div>
                  <Autocomplete
                    className="finish-profile-input-wrapper"
                    autocompleteWithLabel
                    id="country"
                    placeholder="Your country"
                    value={this.state.country}
                    onChange={(event) => this.onChangeCountry(event)}
                    data={countries}
                    showUnfilteredData
                    filter={this.filterWithFuseJS}
                    onAutocomplete={(...args) =>
                      this.onAutocompleteChange(args)
                    }
                    onFocus={this.onFocusCountry}
                    onBlur={this.onBlurCountry}
                    onKeyUp={this.onInputKeyUp}
                  />
                  {this.state.noCountryFound && this.state.show && (
                    <ul id="country-menu-list">
                      <li>{`No countries matching "${this.state.filterText}" were found.`}</li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="profile-continue">
                <MaterialButton
                  buttonText="Continue"
                  variant="contained"
                  className="common-submit-btn md-raised"
                  color="primary"
                  onClick={this.onFormSubmit}
                ></MaterialButton>
                {/* <button className="md-raised common-submit-btn" type="submit">
                <span>Continue</span>
              </button> */}
              </div>
            </form>
          </div>
        </div>
      </Scrollbar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchProps)(FinishProfileView);
