import React from "react";
import { getProjectView } from "../../helpers/projectslanding";
import Avatar from "react-avatar";

import { Tooltip } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuOptions from "../projects/menuOptions";
import firebase from "../../firebase";

export default function ArchivedProjectCard({
  project,
  unArchivedArchivedProject
}) {
  const unArchiveArchivedProject = () => {
    const body = {
      id: project.id,
      data: project.data,
      archived: false
    };
    unArchivedArchivedProject({ body }, _response => {
      firebase.analytics().logEvent("GTD_Archive_Project_Unarchived");
    });
  };

  const projectView = getProjectView(project);
  return (
    <div className="project-dash-card">
      <div className="project-cards-dash">
        <MenuOptions
          project={project}
          source="FROM_ARCHIVED"
          unArchiveArchivedProject={unArchiveArchivedProject}
        />

        <div className="project-dashboard-card card-content archived-card">
          {projectView.img ? (
            <div
              className="img-container project-img"
              // style={{
              //   backgroundImage: "url(" + projectView.img + " ) "
              // }}
            >
              <img src={projectView.img} alt="project-logo" />
            </div>
          ) : (
            <div className="img-container project-img">
              <Avatar
                className="project-avatar"
                // color={Avatar.getRandomColor("sitebase", [
                //   "#ff9292",
                //   "#7c9af1"
                // ])}
                name={project.name
                  .substring(0, 2)
                  .split("")
                  .join(" ")}
                maxInitials={1}
              ></Avatar>
            </div>
          )}
          <div className="project-desc-right">
            <div>
              {projectView.name.length > 20 ? (
                <Tooltip placement="top" title={projectView.name}>
                  <span className="md-title truncate-txt project-dashboard-title">
                    {projectView.name}
                  </span>
                </Tooltip>
              ) : (
                <span className="md-title truncate-txt project-dashboard-title">
                  {projectView.name}
                </span>
              )}
            </div>
            <div className="progress_bar">
              <div className="project-percentage">
                <div>{projectView.percentage}%</div>
                <div className="project-progressbar">
                  <LinearProgress
                    className="progressbar"
                    style={{ background: "#f0f0f0", height: "2px" }}
                    variant="determinate"
                    value={
                      projectView.percentage > 100
                        ? 100
                        : projectView.percentage
                    }
                  />
                </div>
              </div>
              <div className="progress_status">
                <span className="project-hours-planned">
                  {projectView.ratio}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
