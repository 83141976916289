import React from "react";

import loaderImg from "../../styles/img/svg/loader-icon.svg";

export const Loader = (props) => {
  const size = {
    width: props.size + "px",
    height: props.size + "px"
  };
  const containerStyle = {
    width: props.loading ? "100%" : "0px",
    height: props.loading ? "100%" : "0px",
    display: props.loading ? "block" : "none",
    position: "absolute",
    zIndex: 999,
    left: props.left ? props.left : 0,
    top: props.top ? props.top : 0
  };
  return (
    <div style={containerStyle}>
      {props.loading && (
        <div className="loader-holder">
          {props.content && (
            <div className="loader-content">{props.content}</div>
          )}
          <div className="loder-icon-holder" style={size}>
            <img src={loaderImg} alt="loader" />
            {/* <CircularProgress
              classes={{ root: "circular-progress" }}
              size={50}
            ></CircularProgress> */}
          </div>
        </div>
      )}
    </div>
  );
};
