import { secureFetch } from "../helpers/fetchhelper";
import routesHelper from "../router/routeshelper";
import { API_URL } from "../constants/globalconst";
import { type } from "ramda";
// import { activities } from "../constants/activitiescontants";


const ID="id";

export const getCustomerDetails = ({ auth_token, queryParam }, callback) => {
  let url = "/api/v1/customer";
  let queryParams = queryParam ? queryParam : "";
  secureFetch(API_URL + url + queryParams, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

// ****
export const updateCustomerDetails = (
  {
    auth_token = undefined,
    display_name,
    data,
    id,
    img,
    img_name,
    super_admin_id,
    user_id = parseInt(localStorage.getItem("id")),
    users,
    deleted_users,
    users_join_request
  },
  callback
) => {
  let url = "/api/v1/customer";
  secureFetch(API_URL + url, {
    method: "PUT",
    body: JSON.stringify({
      display_name,
      data,
      id,
      img,
      img_name,
      super_admin_id,
      user_id,
      users,
      deleted_users,
      users_join_request
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: auth_token ? "Bearer: " + auth_token : "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getAllPendingRequests = (auth_token, callback) => {
  secureFetch(API_URL + "/api/v1/pending-requests", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token || routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getAllPendingInvites = (auth_token, callback) => {
  secureFetch(API_URL + "/api/v1/get-pending-invites", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token || routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const acceptRejectWorkspaceInvites = (
  body,
  callback
) => {
  secureFetch(API_URL + "/api/v1/accept-workspace-invite", {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log("request", respJson);
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const findCustomers = ({ auth_token, key, user_id }, callback) => {
  secureFetch(
    API_URL + "/api/v1/search-customer?like=" + key + "&user_id=" + user_id,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",

        Origin: API_URL,
        // Authorization: "Bearer: " + auth_token || routesHelper.getAuthToken()
        "X-ACCESS-TOKEN": routesHelper.getAuthToken()
      })
    }
  )
    .then(respJson => callback(respJson, key))
    .catch(error => {
      //
    });
};

export const requestToJoinWorkspace = (
  { customer_id, requested_user_id },
  callback
) => {
  secureFetch(API_URL + "/api/v1/send-request", {
    method: "POST",
    body: JSON.stringify({ customer_id, requested_user_id }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      //console.log("request", respJson);
      callback(respJson);
    })
    .catch(error => {
      //
    });
};

export const createWorkspace = (
  { name, img, auth_token, img_name, super_admin_id },
  callback
) => {
  secureFetch(API_URL + "/api/v1/customer", {
    method: "POST",
    body: JSON.stringify({ name, img, img_name, auth_token, super_admin_id }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getCustomerSuggestions = ({ auth_token, user_id }, callback) => {
  secureFetch(API_URL + "/api/v1/customer-suggestion?user_id=" + user_id, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token || routesHelper.getAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

function buildArrayParams(params, key) {
  let strng = "";
  params.forEach((element, index) => {
    strng =
      (index === 0 && strng + key + "=" + element) ||
      strng + "&" + key + "=" + element;
  });

  return strng;
}

export const archiveOrUnArchiveActivity = (
  { auth_token, archiveData },
  callback
) => {
  let userid=localStorage.getItem(ID);
  secureFetch(API_URL + "/api/v1/activity?logged_in_user=" + parseInt(userid), {
    method: "PUT",
    body: JSON.stringify({ ...archiveData,"performed_by":JSON.parse(userid) }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const sentInviteToJoinWorkspace = (
  { auth_token, customer_id, user_id = parseInt(localStorage.getItem("id")), email },
  callback
) => {
  secureFetch(API_URL + "/api/v1/send-invite", {
    method: "POST",
    body: JSON.stringify({ auth_token, customer_id, user_id, email }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //console.log(error);
    });
};

export const sendInviteToEmailsToJoinWorkspace = (
  { auth_token, customer_id, user_id = parseInt(localStorage.getItem("id")), email, existing_users, action },
  callback
) => {
  secureFetch(API_URL + "/api/v2/send-invite", {
    method: "POST",
    body: JSON.stringify({ auth_token, customer_id, user_id, email, existing_users, action }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //console.log(error);
    });
};

export const getInvitedEmails = ({ auth_token}, callback) => {
  secureFetch(API_URL + "/api/v1/invited-emails", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      // console.log("orig", respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log(error);
    });
};

export const resendCancelInvite = (
  { auth_token, email_invited, invited_by, action, user_id = parseInt(localStorage.getItem("id")) },
  callback
) => {
  secureFetch(API_URL + "/api/v1/invited-emails", {
    method: "PUT",
    body: JSON.stringify({ email_invited, invited_by, action, user_id }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //console.log(error);
    });
};

export const getWorkspaceUser = callback => {
  secureFetch(API_URL + "/api/v1/workspace-users", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getCustomerAuthToken()
    })
  })
    .then(respJson => {
      // console.log("orig", respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log(error);
    });
};
// now
export const getActivitiesForWorkspace = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page,
    id
  },
  callback
) => {
  let isActive = (is_active !== undefined && "&is_active=" + is_active) || "";
  let userId = (user_id && "&user_id=" + user_id) || "";
  let isArchived =
    (is_archived !== undefined && "&is_archived=" + is_archived) || "";
  // let isClientId = client_id && '&client_id='+client_id ||'';
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  //console.log('cust_auth_token calling api',cust_auth_token);
  let activity_type_param =
    (activity_type &&
      type(activity_type) === "Array" &&
      "?" + buildArrayParams(activity_type, "activity_type")) ||
    "?activity_type=" + activity_type;
  let pageNo = (page && "&page=" + page) || "";
  let item_per_page_on = (item_per_page && "&per_page=" + item_per_page) || "";
  let activityId = (id && "&id=" + id) || "";
  let params =
    activity_type_param +
    userId +
    isActive +
    isArchived +
    pageNo +
    item_per_page_on +
    activityId;
  secureFetch(API_URL + "/api/v1/activity" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getPrioritizedActivitiesForWorkspace = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    // page,
    // item_per_page,
    id
  },
  callback
) => {
  let isActive = (is_active !== undefined && "&is_active=" + is_active) || "";
  let userId = (user_id && "&user_id=" + user_id) || "";
  let isArchived =
    (is_archived !== undefined && "&is_archived=" + is_archived) || "";
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  let activity_type_param =
    (activity_type &&
      type(activity_type) === "Array" &&
      "?" + buildArrayParams(activity_type, "activity_type")) ||
    "?activity_type=" + activity_type;
  // let pageNo = (page && "&page=" + page) || "";
  // let item_per_page_on = (item_per_page && "&per_page=" + item_per_page) || "";
  let activityId = (id && "&id=" + id) || "";
  let params =
    activity_type_param + userId + isActive + isArchived + activityId;
  // console.log(
  //   "**getPrioritizedActivitiesForWorkspace api is called",
  //   API_URL + "/api/v1/priority-activity" + params + "**"
  // );
  secureFetch(API_URL + "/api/v1/priority-activity" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getDeprioritizedActivitiesForWorkspace = (
  {
    auth_token,
    activity_type,
    is_active,
    user_id,
    is_archived,
    page,
    item_per_page,
    id
  },
  callback
) => {
  let isActive = (is_active !== undefined && "&is_active=" + is_active) || "";
  let userId = (user_id && "&user_id=" + user_id) || "";
  let isArchived =
    (is_archived !== undefined && "&is_archived=" + is_archived) || "";
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  let activity_type_param =
    (activity_type &&
      type(activity_type) === "Array" &&
      "?" + buildArrayParams(activity_type, "activity_type")) ||
    "?activity_type=" + activity_type;
  let pageNo = (page && "&page=" + page) || "";
  let item_per_page_on = (item_per_page && "&per_page=" + item_per_page) || "";
  let activityId = (id && "&id=" + id) || "";
  let params =
    activity_type_param +
    userId +
    isActive +
    isArchived +
    pageNo +
    item_per_page_on +
    activityId;

  secureFetch(API_URL + "/api/v1/list-activities" + params, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getActivity = ({ id, user_id }, callback) => {
  // const cust_auth_token = localStorage.getItem(activities.customerAuthToken);
  let activityId = (id && "?id=" + id) || "";
  const params = user_id ? activityId + "&logged_in_user=" + user_id : activityId;
  secureFetch(API_URL + "/api/v1/activity" + params + "&activity_type=ACTIVITY", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + cust_auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getListOfCurrencies = ({ id }, callback) => {
  secureFetch(API_URL + "/api/v1/currencies", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getCustomerAuthToken()
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getTimerStatusForWorkspace = (
  {
    auth_token = routesHelper.getAuthToken()
  },
  callback
) => {
  secureFetch(API_URL + "/api/v1/timer", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": auth_token
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //
    });
};

export const getRequestedUsers = ({ auth_token}, callback) => {
  secureFetch(API_URL + "/api/v1/requested-users", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + (auth_token || routesHelper.getCustomerAuthToken())
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => {
      // console.log("orig", respJson);
      callback(respJson);
    })
    .catch(error => {
      //console.log(error);
    });
};

export const switchWorkspace = (
  { customer_id },
  callback
) => {
  secureFetch(API_URL + "/api/v1/switch-workspace", {
    method: "PUT",
    body: JSON.stringify({ customer_id }),
    headers: new Headers({
      "Content-Type": "application/json",
      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then(respJson => callback(respJson))
    .catch(error => {
      //console.log(error);
    });
};