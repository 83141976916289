import moment from "moment";
import _ from "lodash";
import { reports } from "../constants/reportsconstants";
import { projectConstants } from "../constants/projectconstants";

export const getIsCreator = currentWorkSpace => {
  return currentWorkSpace?.super_admin_id === parseInt(localStorage.getItem("id"));
}

export const getIsSuperAdmin = (currentUser, currentWorkSpace) => {
  if (currentWorkSpace && currentUser) {
    if (
      currentWorkSpace.users.hasOwnProperty(currentUser.id) &&
      currentWorkSpace.users[currentUser.id] === projectConstants.superAdmin
    )
      return true;
    return false;
  }
};

export const getIsAccessToReports = selectedProject => {
  if(selectedProject.data &&
      selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]) {
    return selectedProject.data.settings.member_access[parseInt(localStorage.getItem("id"))]
              .widget.includes(projectConstants.widgets.reports);
  }
  return false;
}

export const getQueryParamsForTimeSheet = (projectId, memberId, from, to,activityType) => {
  var today = moment(new Date()).format("LL");
  let fromDate = moment(new Date(from)).format("LL");
  let toDate = moment(new Date(to)).format("LL");
  let fromDate1 = moment(fromDate)
    .add(0, "h")
    .add(0, "m")
    .add(0, "s");
  let fromDateUtc = moment(fromDate1).format();
  if (moment(toDate).isSame(today)) {
    var toDateUtc = moment(new Date()).format();
  } else {
    var hours = 23;
    var minutes = 59;
    var seconds = 59;
    var toDateUtc = moment(toDate)
      .add(hours, "h")
      .add(minutes, "m")
      .add(seconds, "s");
    toDateUtc = moment(toDateUtc).format();
  }
  let queryParam = `?from_date=${fromDateUtc.replace(
    "+",
    "%2B"
  )}&to_date=${toDateUtc.replace("+", "%2B")}`;
  if (projectId !== "all") {
    queryParam = queryParam.concat(`&project_id=${parseInt(projectId)}`);
  }
  if (memberId !== "all") {
    queryParam = queryParam.concat(`&user_id=${parseInt(memberId)}`);
  }
  if(activityType && activityType!== 'All' ){
    queryParam = queryParam.concat(`&tags=${activityType}`);
  }
  
  return queryParam;
};

export const getAllWorkSpaceUsers = (workSpaceUsers) => {
  const allWorkSpaceUsers = Array.isArray(workSpaceUsers)
    ? [...workSpaceUsers]
    : [];
  allWorkSpaceUsers.unshift({
    id: "all",
    first_name: "All",
    email: "all",
    img: ""
  });
  return allWorkSpaceUsers;
};

export const getTimeSheetsView = (
  listOfTimeSheet,
  sortBy = null,
  sortType = null
) => {
  let timeSheetsView = [];
  listOfTimeSheet.map((timeSheet) => {
    let tDate = moment(timeSheet.date).format("ddd, DD MMM YYYY");
    let hoursOnEntry = Math.floor(timeSheet.time_taken / 3600) + "h";
    let minsOnEntry = Math.floor((timeSheet.time_taken % 3600) / 60) + "m";
    let time_taken_view = `${hoursOnEntry} ${minsOnEntry}`;
    timeSheetsView.push({
      id: timeSheet.id,
      entryStart: timeSheet.data.TIME[0] &&  timeSheet.data.TIME[0].START,
      entryStop: timeSheet.data.TIME[0] && timeSheet.data.TIME[0].STOP,
      data: timeSheet.data,
      date: timeSheet.date,
      local_date_time: timeSheet.local_date_time,
      date_view: tDate,
      time_taken: timeSheet.time_taken,
      time_taken_view,
      project_name: timeSheet.activity.project.name,
      project_obj: timeSheet.activity.project,
      activity_obj: timeSheet.activity,
      title: timeSheet.activity.title,
      // activity_type: timeSheet.activity.tags[0], old activity design
      activity_type: timeSheet?.tags?.[0] || timeSheet.activity.tags[0], // new activity design
      attachments: timeSheet.attachments, // new activity design
      created_by_first_name: timeSheet.created_by_first_name,
      user_name: timeSheet.user.first_name,
      description: timeSheet.description,
      user_img: timeSheet.user.img,
      user_id: timeSheet.user_id
    });
  });
  if (sortBy && sortType !== null) {
    const sortedView = getSortedView(timeSheetsView, sortBy, sortType);
    return sortedView;
  }
  return timeSheetsView;
};

const getSortedView = (timeSheetsView, sortBy, sortType) => {
  let sortedTimeSheetView = [];
  if (sortBy === reports.date) {
    let uniqueDates = timeSheetsView.map((timeSheet) =>
      moment(timeSheet.date).format("YYYY-MM-DD")
    );
    uniqueDates = [...new Set(uniqueDates)];
    if (!sortType)
      uniqueDates = uniqueDates.sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime()
      );
    else
      uniqueDates = uniqueDates.sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
    return uniqueDates;
  }
  if (sortBy === reports.duration) {
    let timeSheetByDuration = [];
    if (sortType)
      timeSheetByDuration = timeSheetsView.sort(
        (a, b) => a.time_taken - b.time_taken
      );
    else
      timeSheetByDuration = timeSheetsView.sort(
        (a, b) => b.time_taken - a.time_taken
      );
    return timeSheetByDuration;
  }
  if (sortBy === reports.project) {
    let uniqueProjects = timeSheetsView.map(
      (timeSheet) => timeSheet.project_name
    );
    uniqueProjects = [...new Set(uniqueProjects)];
    if (sortType)
      uniqueProjects = uniqueProjects.sort((a, b) => {
        a = a.toUpperCase();
        b = b.toUpperCase();
        return a > b ? 1 : b > a ? -1 : 0;
      });
    else
      uniqueProjects = uniqueProjects.sort((a, b) => {
        b = b.toUpperCase();
        a = a.toUpperCase();
        return a > b ? -1 : b > a ? 1 : 0;
      });
    return uniqueProjects;
  }
  if (sortBy === reports.activityType) {
    let uniqueActivityTypes = timeSheetsView.map(
      (timeSheet) => timeSheet.activity_type
    );
    uniqueActivityTypes = [...new Set(uniqueActivityTypes)];
    if (sortType)
      uniqueActivityTypes = uniqueActivityTypes.sort((a, b) => {
        a = a.toUpperCase();
        b = b.toUpperCase();
        return a > b ? 1 : b > a ? -1 : 0;
      });
    else
      uniqueActivityTypes = uniqueActivityTypes.sort((a, b) => {
        b = b.toUpperCase();
        a = a.toUpperCase();
        return a > b ? -1 : b > a ? 1 : 0;
      });
    return uniqueActivityTypes;
  }
  if (sortBy === reports.member) {
    let uniqueMembers = timeSheetsView.map((timeSheet) => timeSheet.user_name);
    uniqueMembers = [...new Set(uniqueMembers)];
    if (sortType)
      uniqueMembers = uniqueMembers.sort((a, b) => {
        a = a.toUpperCase();
        b = b.toUpperCase();
        return a > b ? 1 : b > a ? -1 : 0;
      });
    else
      uniqueMembers = uniqueMembers.sort((a, b) => {
        b = b.toUpperCase();
        a = a.toUpperCase();
        return a > b ? -1 : b > a ? 1 : 0;
      });
    return uniqueMembers;
  }
};

export const getSortedGroupView = (
  timeSheetsToView,
  timeSheetsView,
  sortBy,
  sortType
) => {
  let sortedTimeSheetView = [];
  if (sortBy === reports.date) {
    timeSheetsView.map((date) => {
      let totalDuration = 0;
      timeSheetsToView = timeSheetsToView.reverse();
      if (sortType)
        timeSheetsToView = _.orderBy(timeSheetsToView, "date", "asc");
      else timeSheetsToView = _.orderBy(timeSheetsToView, "date", "desc");
      timeSheetsToView.map((timeSheet) => {
        if (moment(timeSheet.date).format("YYYY-MM-DD") === date) {
          totalDuration += timeSheet.time_taken;
          sortedTimeSheetView.push(timeSheet);
        }
      });
      let hoursOnEntry = Math.floor(totalDuration / 3600) + "h";
      let minsOnEntry = Math.floor((totalDuration % 3600) / 60) + "m";
      let time_taken_view = `${hoursOnEntry} ${minsOnEntry}`;
      sortedTimeSheetView.push({ total: time_taken_view });
    });
  }
  if (sortBy === reports.project) {
    timeSheetsView.map((project) => {
      let totalDuration = 0;
      timeSheetsToView.map((timeSheet) => {
        if (timeSheet.project_name === project) {
          totalDuration += timeSheet.time_taken;
          sortedTimeSheetView.push(timeSheet);
        }
      });
      let hoursOnEntry = Math.floor(totalDuration / 3600) + "h";
      let minsOnEntry = Math.floor((totalDuration % 3600) / 60) + "m";
      let time_taken_view = `${hoursOnEntry} ${minsOnEntry}`;
      sortedTimeSheetView.push({ total: time_taken_view });
    });
  }
  if (sortBy === reports.activityType) {
    timeSheetsView.map((activityType) => {
      let totalDuration = 0;
      timeSheetsToView.map((timeSheet) => {
        if (timeSheet.activity_type === activityType) {
          totalDuration += timeSheet.time_taken;
          sortedTimeSheetView.push(timeSheet);
        }
      });
      let hoursOnEntry = Math.floor(totalDuration / 3600) + "h";
      let minsOnEntry = Math.floor((totalDuration % 3600) / 60) + "m";
      let time_taken_view = `${hoursOnEntry} ${minsOnEntry}`;
      sortedTimeSheetView.push({ total: time_taken_view });
    });
  }
  if (sortBy === reports.member) {
    timeSheetsView.map((member) => {
      let totalDuration = 0;
      timeSheetsToView.map((timeSheet) => {
        if (timeSheet.user_name === member) {
          totalDuration += timeSheet.time_taken;
          sortedTimeSheetView.push(timeSheet);
        }
      });
      let hoursOnEntry = Math.floor(totalDuration / 3600) + "h";
      let minsOnEntry = Math.floor((totalDuration % 3600) / 60) + "m";
      let time_taken_view = `${hoursOnEntry} ${minsOnEntry}`;
      sortedTimeSheetView.push({ total: time_taken_view });
    });
  }
  // console.log("timeSheetsToView",timeSheetsToView);
  return sortedTimeSheetView;
};

export const getTotalDuration = (timeSheetsToView) => {
  let totalDuration = timeSheetsToView.reduce((acc, curr) => {
    acc += curr.time_taken;
    return acc;
  }, 0);
  let hoursOnEntry = Math.floor(totalDuration / 3600) + "h";
  let minsOnEntry = Math.floor((totalDuration % 3600) / 60) + "m";
  let totalDurationView = `${hoursOnEntry} ${minsOnEntry}`;
  return totalDurationView;
};

export const secondsToHHMMSS = (time) => {
  var hoursOnEntry = Math.floor(time / 3600);
  var minsOnEntry = Math.floor((time % 3600) / 60);
  var secondsOnEntry = Math.floor((time % 3600) % 60);
  var totalformat = hoursOnEntry + ":" + minsOnEntry + ":" + secondsOnEntry;
  return totalformat;
};

export const getReportToDownload = (timeSheets,excelprojectname,duration) => {
  let reportToDownload = {};
  let totalTime = 0;
  let items=[];
  timeSheets.forEach((timeSheet) => {
    if (!timeSheet.hasOwnProperty("total")) {
      totalTime += timeSheet.time_taken;
      let time = secondsToHHMMSS(timeSheet.time_taken);
      let des = timeSheet.description
        ? timeSheet.description.replace(/,/g, " ").trim()
        : "N/A";
        items.push({
        'date':timeSheet.date_view,
        'project':timeSheet.project_name,
        'activity_type':timeSheet.activity_type,
        'activity_name':timeSheet.title,
        'people':timeSheet.user_name,
        'description':des,
        'time_taken':time,
        'comments':""
        });
    }
  });
  reportToDownload["items"]=items;
  let total={'time_taken':`${secondsToHHMMSS(totalTime)}`,
             'project_name':excelprojectname,
            'duration':duration};
  reportToDownload["total"]=total;

  return reportToDownload;
};

export const getReportToDownloadCSV = (timeSheets,projectName,duration) => {
  let reportToDownload = [[projectName+": "+duration]];
  let totalTime = 0;
  reportToDownload.push([]);
  reportToDownload.push([
    "Date",
    "Project",
    "Activity Type",
    "Activity Title",
    "People",
    "Description",
    "Time Taken",
    "Comments"
  ]);
  timeSheets.forEach((timeSheet) => {
    if (!timeSheet.hasOwnProperty("total")) {
      totalTime += timeSheet.time_taken;
      let time = secondsToHHMMSS(timeSheet.time_taken);
      let des = timeSheet.description
        ? timeSheet.description.replace(/,/g, " ").trim()
        : "N/A";
      reportToDownload.push([
        timeSheet.date_view,
        timeSheet.project_name,
        timeSheet.activity_type,
        timeSheet.title,
        timeSheet.user_name,
        des,
        time,
        ""
      ]);
    }
  });
  reportToDownload.push([]);
  reportToDownload.push([
    "",
    "",
    "",
    "",
    "",
    "Total",
    secondsToHHMMSS(totalTime)
  ]);
  reportToDownload.push([]);
  reportToDownload.push([["Created by www.GetThingsDone.io"]]);

  return reportToDownload;
};
