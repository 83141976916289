import _ from "lodash";
import moment from "moment";

export const renderTimerLogic = activity => {
  const currentUserId = parseInt(localStorage.getItem("id"));
  var timerStartTimeInSeconds = 0;
  let timerForTheSession = 0;
  let timerShouldBeEnabled = null;

  const timesheet =
    activity &&
    activity.timesheets.filter(
      timesheet => timesheet.user_id === currentUserId
    );

  timerStartTimeInSeconds = timesheet.reduce((totalTime, current, index) => {
    return (totalTime += current.time_taken);
  }, 0);

  const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);

  if (sortedTimesheetData.length > 0) {
    timerShouldBeEnabled =
      (sortedTimesheetData[0].data &&
        _.has(sortedTimesheetData[0].data.TIME[0], "STOP") &&
        _.has(sortedTimesheetData[0].data.TIME[0], "START")) ||
      sortedTimesheetData[0].data.TIME.length <= 0
        ? true
        : false;
  } else {
    timerShouldBeEnabled = true;
  }

  if (
    !timerShouldBeEnabled &&
    sortedTimesheetData[0].data &&
    sortedTimesheetData[0].data.TIME[0] &&
    sortedTimesheetData[0].data.TIME[0].hasOwnProperty("START")
  ) {
    timerStartTimeInSeconds += moment(new Date()).diff(
      sortedTimesheetData[0].data.TIME[0].START,
      "seconds"
    );
    timerForTheSession = moment(new Date()).diff(
      sortedTimesheetData[0].data.TIME[0].START,
      "seconds"
    );
  }

  return {
    timerStartTimeInSeconds,
    timerForTheSession,
    timerShouldBeEnabled
  };
};

export const getUserTimerStatus = (activity, user) => {
  const currentUserId = parseInt(user.id);
  let timerShouldBeEnabled = null;

  const timesheet =
    activity &&
    activity.timesheets.filter(
      timesheet => timesheet.user_id === currentUserId
    );

  const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);

  if (sortedTimesheetData.length > 0) {
    timerShouldBeEnabled =
      (sortedTimesheetData[0].data &&
        _.has(sortedTimesheetData[0].data.TIME[0], "STOP") &&
        _.has(sortedTimesheetData[0].data.TIME[0], "START")) ||
      sortedTimesheetData[0].data.TIME.length <= 0
        ? true
        : false;
  } else {
    timerShouldBeEnabled = true;
  }

  return timerShouldBeEnabled;
};

export const convertRGBToHex = rgb => {
  var hexDigits = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f"
  ];

  //Function to convert rgb color to hex format
  function rgb2hex(rgb) {
    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
  }

  function hex(x) {
    return isNaN(x) ? "00" : hexDigits[(x - (x % 16)) / 16] + hexDigits[x % 16];
  }

  return rgb2hex(rgb);
};
