export  const commonContants = {
    updateBreadcrumbListAction : 'UPDATE_BREADCRUMB_LIST',
    updateUserPasswordLoaderAction: 'UPDATE_USER_PASSWORD_LOADER_ACTION',
    updateUserProfileLoaderAction: 'UPDATE_USER_PROFILE_LOADER_ACTION',
    updateUserPhotoLoaderAction: 'UPDATE_USER_PHOTO_LOADER_ACTION',
    updateCustomerPhotoLoaderAction: 'UPDATE_CUSTOMER_PHOTO_LOADER_ACTION',
    updateTabState: 'UPDATE_TAB_STATE',
    updateDashboardTabState: 'UPDATE_DASHBOARD_TAB_STATE',
    updateSearchTextAction : 'UPDATE_SEARCH_TEXT_ACTION',
    handleNavBarAction : 'UPDATE_NAVBAR_STATE',
    timerIsRunningAction : 'UPDATE_TIMER_STATUS',
    updateTimerData : "UPDATE_TIMER_DATA",
    getOwners: "GET_OWNERS",
    getMembers: "GET_MEMBERS"
}