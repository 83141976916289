import React from "react";
import Avatar from "react-avatar";
import "../../styles/scss/searchpeoplesuggestion.scss";
import crossIcon from "../../styles/img/svg/remove-user-icon.svg";
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
export default function SearchPeopleSuggestions(props) {
  const { user, onRemoveUser } = props;
  if (user.id) {
    let email = user.first_name;
    let firstLetter = email.charAt(0).toUpperCase();
    let length = email.length;
    let newEmail = firstLetter + " " + email.slice(1, length).toLowerCase();

    return (
      <div className="ppl-add-invite-ppl-list-items">
        <div className="ppl-add-invite-ppl-list-left-side">
          <div
            className="ppl-add-invite-ppl-list-user-img"
            style={{ width: "35px", height: "35px", paddingLeft: "0" }}
          >
            {/*  sb-avatar sb-avatar--text */}
            {(_.isNull(user.img) || _.isEmpty(user.img)) && (
              <Avatar name={newEmail} maxInitials={1}></Avatar>
            )}

            {user.img && <img src={user.img} alt="user-img" />}
          </div>
          <div className="ppl-add-invite-ppl-list-user-details">
            <p className="user-name">{user.first_name}</p>
            <p className="user-domain">{user.email}</p>
          </div>
        </div>
        <Tooltip title="Remove" placement="top">
        <div className="ppl-add-invite-ppl-list-right-side cross-button" onClick={() => onRemoveUser(user)}>
          <img src={crossIcon} alt="remove" />
        </div>
        </Tooltip>
      </div>
    );
  } else {
    let email = user.email;
    let firstLetter = email.charAt(0).toUpperCase();
    let length = email.length;
    let newEmail = firstLetter + " " + email.slice(1, length).toLowerCase();
    
    return (
      <div className="ppl-add-invite-ppl-list-items" style={{ padding: "13.5px 31px 13.5px 20px" }}>
        <div className="ppl-add-invite-ppl-list-left-side">
          {/* <div className="ppl-add-invite-ppl-list-user-img">
                <img src={user.img || "//s3.amazonaws.com/gtd/users/default.png"} />
            </div> */}
          <div
            className="ppl-add-invite-ppl-list-user-img"
            style={{
              width: "35px",
              height: "35px",
              paddingLeft: "0",
              paddingTop: "0"
            }}
          >
            {/*  sb-avatar sb-avatar--text */}
            {!user.img && (
              <Avatar
                color={Avatar.getRandomColor("sitebase", [
                  "#ff9292",
                  "#7c9af1"
                ])}
                name={newEmail}
                maxInitials={1}
              ></Avatar>
            )}

            {user.img && <img src={user.img} alt="user-img" />}
          </div>
          <div className="ppl-add-invite-ppl-list-user-details">
            <p className="user-name">{user.email}</p>
          </div>
        </div>
        <Tooltip title="Remove" placement="top">
        <div className="ppl-add-invite-ppl-list-right-side cross-button" onClick={() => onRemoveUser(user)}>
          <img src={crossIcon} alt="remove" />
        </div>
        </Tooltip>
      </div>
    );
  }
}
