import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { toast } from "react-toastify";
import CommonNav from "./commonnav";
import JoinWorkspaceTile from "./joinworkspacetile";
import "../../styles/scss/joinWorkspace.scss";
import { acceptRejectWorkspaceInvites } from "../../services/workspaceapi";
import routesHelper from "../../router/routeshelper";
import ProjectEditHeader from "../common/projectEditHeader";
// import {
//   getUnsplashImage,
//   getShouldCreateWorkspace
// } from "../../helpers/generichelper";
// import firebase from "../../firebase";

class WorkspaceInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedRejectedWorkspace: []
    };
  }

  componentDidMount() {
    const { router } = this.props;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    if(!fromNavbar) {
    //   firebase.analytics().logEvent("GTD_Registration_Flow_Find_Workspace");
    }
    if(fromNavbar || router.location.action === "POP") {
      const auth_token = localStorage.getItem("auth_token");
      this.props.getAllPendingInviteListAction(auth_token, pendingResponse => {
        if (pendingResponse && !pendingResponse.error) {
          //  //console.log("costomers", pendingResponse)
        }
      });
    }
  }

  onAcceptRejectInvite = (workspace, action) => {
    const { router, listOfCustomer } = this.props;
    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
    let body = { accepted_all: false };
    let toastMessage = "";
    let shouldCallCustomer = false;
    let shouldGotoWelcomePage = false;
    let shouldGotoSuggestionsPage = false;
    if(action === "ACCEPT") {
      body.accepted_invite = workspace.id;
      toastMessage = `Added to ${workspace.display_name} Workspace`;
      shouldCallCustomer = true;
      // if(!fromNavbar) shouldGotoWelcomePage = true;
    }
    if(action === "REJECT") {
      if(!fromNavbar) shouldGotoSuggestionsPage = true;
      body.rejected_invite = workspace.id;
    }
    if(action === "ACCEPT_ALL") {
      body.accepted_all = true;
      toastMessage = "Added to All Workspaces";
      shouldCallCustomer = true;
      if(!fromNavbar) shouldGotoWelcomePage = true;
    }
    acceptRejectWorkspaceInvites(body, res => {
      if(res) {
        const auth_token = localStorage.getItem("auth_token");
        this.props.getAllPendingInviteListAction(auth_token, pendingResponse => {
          if(pendingResponse) {
            this.setState({ acceptedRejectedWorkspace: [ ...this.state.acceptedRejectedWorkspace, workspace ] });
            toastMessage && toast(toastMessage);
            if(shouldGotoSuggestionsPage && pendingResponse.length === 0) {
              listOfCustomer?.length === 0 && this.findIfAnySuggestions();
              listOfCustomer?.length > 0 && router.push(routesHelper.welcomePath());
            }
            shouldCallCustomer &&
              this.props.getAllCustomersAction({ auth_token }, customerRes => {
                if(shouldGotoWelcomePage)
                  router.push(routesHelper.welcomePath());
                else {
                  if(!fromNavbar && pendingResponse.length === 0 && customerRes?.length > 0)
                    router.push(routesHelper.welcomePath())
                }
              });
          }
        });
      }
    })
  }

  findIfAnySuggestions = () => {
    const { router, suggestWorkspaceActions } = this.props;
    let auth_token = localStorage.getItem("auth_token");
    let user_id = localStorage.getItem("id");
    suggestWorkspaceActions({ auth_token, user_id }, (response) => {
      router.push(routesHelper.getJoinWorkspacePath());
    });
  };

  renderInvitesToJoinWorkspace = () => {
    const { listOfPendingCustomerInvites } = this.props;
    const renderedItems = listOfPendingCustomerInvites.list.map((invite) => {
      return (
        <JoinWorkspaceTile
          key={invite.id}
          workspace={invite}
          onAcceptRejectInvite={this.onAcceptRejectInvite}
          type="invite"
        />
      );
    });
    return renderedItems;
  };

  render() {
    const {
      router,
      pendingCustomerLoaderState,
      listOfPendingCustomerInvites,
      totalTimeForTheDay,
      selectedCustomerDetails,
      currentUser,
      listOfCustomer
    } = this.props;

    const { acceptedRejectedWorkspace } = this.state;

    const fromNavbar =
      router.location.state && router.location.state.from === "nav-bar";
      
    return (
      <React.Fragment>
        {fromNavbar && (
          <ProjectEditHeader
            router={router}
            shouldRenderWorkspaceInvitations={true}
            totalTimeForTheDay={totalTimeForTheDay}
            selectedCustomerDetails={selectedCustomerDetails}
            currentUser={currentUser}
          />
        )}
        <Scrollbar
          className={`add-users-scroll ${fromNavbar ? "scroll-height" : ""}`}
        >
          <div className="joinWorkspace-container">
            {!fromNavbar ? (
              <div>
                <CommonNav router={router} />
              </div>
            ) : (
              <div className="create-find-head"></div>
            )}
            <div
              className={`container-find ${
                fromNavbar
                  ? `from-nav-bar-container-find ${
                      listOfPendingCustomerInvites.list.length > 0
                        ? "more-height"
                        : ""
                    }`
                  : listOfPendingCustomerInvites.list.length > 0
                  ? "more-height"
                  : ""
              }`}
            >
              <div className="join-header">
                <p className="join-title">You've been invited to the following workspaces</p>
              </div>

              {/* $$ Should appear showing the workspaces being searched for */}
              <div
                className={`choose-workspace searched-workspace wp-invites ${
                  fromNavbar
                    ? `from-navbar-searched-workspace ${
                        listOfPendingCustomerInvites.list.length > 0
                          ? "more-height"
                          : ""
                      }`
                    : listOfPendingCustomerInvites.list.length > 0
                    ? "more-height"
                    : ""
                }`}
              >
                {pendingCustomerLoaderState.fetched && listOfPendingCustomerInvites.list.length === 0 && (
                  <div className="join-no-results">
                    <p className="join-no-title">No Pending Invitations</p>
                  </div>
                )}
                {listOfPendingCustomerInvites &&
                  this.renderInvitesToJoinWorkspace()}
              </div>
            </div>
            {listOfPendingCustomerInvites.list.length > 0 && (
              <div className="join-container-footer accept-all-invite">
                <button
                  onClick={() => this.onAcceptRejectInvite(null, "ACCEPT_ALL")}
                >Accept All</button>
                {!fromNavbar && <p className="not-expec">
                  <a
                    onClick={listOfCustomer?.length > 0
                      ? () => router.push(routesHelper.welcomePath())
                      : this.findIfAnySuggestions
                    }
                    className="find-workspace"
                  >
                    {" "}
                    {acceptedRejectedWorkspace.length > 0 ? "Proceed" : "Skip for now"}
                  </a>
                </p>}
              </div>
            )}
          </div>
        </Scrollbar>
      </React.Fragment>
    );
  }
}

export default WorkspaceInvitations;
