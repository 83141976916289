import React, { useState, useContext, useEffect, memo } from "react";
import KanbanContext from "../../contexts/kanban/kabanContext";
import KanbanWorkflow from "./kanbanWorkflow";
import { Scrollbar } from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import KanbanTopControls from "./kanbanTopControls";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { getIsAccessToAddRemoveMember } from "../../helpers/generichelper";
momentDurationFormatSetup(moment);

const KanbanColumnArea = () => {
  const {
    selectedProject,
    updateSelectedProjectState,
    activitiesList,
    loggedinUser,
    updateProjectAction,
    selectedCustomer,
  } = useContext(KanbanContext);
  const workflowWidth = 340;
  const addWorkflowWidth = 230;
  const [kanbanSearchText, setKanbanSearchText] = useState("");
  const [workflowList, setWorkflowList] = useState([]);
  const [collapsedWorkflows, setCollapsedWorkflow] = useState([]);
  const [newListDropdown, setNewListDropdown] = useState(false);
  const workflowBackupForFilter = JSON.parse(JSON.stringify(workflowList));
  const [filteredWorkflowList, setFilteredWorkflowList] = useState([
    ...workflowBackupForFilter,
  ]);
  const [workflowNameGettingAdded, setWorkflowNameGettingAdded] = useState("");
  
  let innerWorkflowList = [];
  let allUsers = [];
  let collapsedWorkflowBackup = [];
  let peopleHavingProjectEditAccess = [];
  const defineProjectEditAccess = () => {
    if (
      selectedCustomer.selectedWorkspace &&
      selectedCustomer.selectedWorkspace.users &&
      selectedProject &&
      selectedProject.data
    ) {
      _.forIn(selectedCustomer.selectedWorkspace.users, (val, key) => {
        if (
          val === "SUPERADMIN" &&
          peopleHavingProjectEditAccess.indexOf(parseInt(key)) === -1
        ) {
          peopleHavingProjectEditAccess.push(parseInt(key));
        }
      });
      if (
        selectedProject.data.settings &&
        selectedProject.data.settings.member_access
      ) {
        _.forIn(selectedProject.data.settings.member_access, (val, key) => {
          if (
            val &&
            val.access === "Manager" &&
            getIsAccessToAddRemoveMember(selectedProject) &&
            peopleHavingProjectEditAccess.indexOf(parseInt(key)) === -1
          ) {
            peopleHavingProjectEditAccess.push(parseInt(key));
          }
        });
      }
    }
  };
  defineProjectEditAccess();
  const handleCollapsedWorkflow = () => {
    const createCollapsedWorkflow = (collapsedWorkflowBackup, activity) => {
      _.forEach(collapsedWorkflowBackup, (workflow, i, arr) => {
        if (activity.workflow) {
          if (parseInt(workflow.id) === parseInt(activity.workflow.id)) {
            arr[i].activities.push(activity);
          }
        } else {
          if (parseInt(workflow.id) === 1) {
            arr[i].activities.push(activity);
          }
        }
      });
    };

    _.forEach(activitiesList, (activity) => {
      if (activity.data.archivedUsers) {
        if (
          activity.data.archivedUsers.indexOf(parseInt(loggedinUser.id)) === -1
        ) {
          createCollapsedWorkflow(collapsedWorkflowBackup, activity);
        }
      } else {
        createCollapsedWorkflow(collapsedWorkflowBackup, activity);
      }
    });
  };

  const createWorkflowObj = (
    innerWorkflowList,
    workflow,
    index,
    isAvailable
  ) => {
    let workflowObj = {};
    workflowObj.name = workflow.name;
    workflowObj.id = isAvailable ? workflow.id : index;
    workflowObj.is_archived = workflow.is_archived;
    workflowObj.is_collapsed = workflow.is_collapsed;
    workflowObj.activities = [];
    _.forEach(activitiesList, (activity) => {
      if (
        activity.workflow &&
        parseInt(activity.workflow.id) === parseInt(workflowObj.id)
      ) {
        if (activity.data.archivedUsers) {
          if (
            activity.data.archivedUsers.indexOf(parseInt(loggedinUser.id)) ===
            -1
          ) {
            workflowObj.activities.push(activity);
          }
        } else {
          workflowObj.activities.push(activity);
        }
      }
      if (
        !activity.workflow &&
        parseInt(workflowObj.id) === 1 &&
        activity.data.archivedUsers.indexOf(parseInt(loggedinUser.id)) === -1
      ) {
        // old activities without workflow will be pushed to backlog
        if (activity.data.archivedUsers) {
          if (
            activity.data.archivedUsers.indexOf(parseInt(loggedinUser.id)) ===
            -1
          ) {
            workflowObj.activities.push(activity);
          }
        } else {
          workflowObj.activities.push(activity);
        }
      }
      allUsers = allUsers.concat(activity.user_id);
    });
    innerWorkflowList.push(workflowObj);
  };

  const createWorkflowList = () => {
    if (
      selectedProject.data &&
      (!selectedProject.data.workflowOrder ||
        selectedProject.data.workflowOrder.length < 1)
    ) {
      _.forIn(selectedProject.workflow, (workflow, index) => {
        if (!workflow.is_archived) {
          createWorkflowObj(innerWorkflowList, workflow, index, false);
        }
        if (workflow.is_collapsed) {
          let collapsedWfObj = {};
          collapsedWfObj.id = index;
          collapsedWfObj.name = workflow.name;
          collapsedWfObj.is_archived = workflow.is_archived;
          collapsedWfObj.is_collapsed = workflow.is_collapsed;
          collapsedWfObj.activities = [];
          collapsedWorkflowBackup.push(collapsedWfObj);
        }
      });
    }
    if (
      selectedProject.data &&
      selectedProject.data.workflowOrder &&
      selectedProject.data.workflowOrder.length > 0
    ) {
      _.forEach(selectedProject.data.workflowOrder, (workflow, index) => {
        if (!workflow.is_archived) {
          createWorkflowObj(innerWorkflowList, workflow, index, true);
        }
        if (workflow.is_collapsed) {
          let collapsedWfObj = {};
          collapsedWfObj.id = workflow.id;
          collapsedWfObj.name = workflow.name;
          collapsedWfObj.is_archived = workflow.is_archived;
          collapsedWfObj.is_collapsed = workflow.is_collapsed;
          collapsedWfObj.activities = [];
          collapsedWorkflowBackup.push(collapsedWfObj);
        }
      });
    }
  };

  allUsers = Array.from(new Set(allUsers));
  

  const sortActivitiesInOrder = (workflowList, projectWorkflowList) => {
    _.forEach(workflowList, function(workflow) {
      var activityOrder = projectWorkflowList[workflow.id].activity_order || [];
      if (activityOrder && activityOrder.length > 0) {
        var sorderActivities = _.sortBy(workflow.activities, function(
          activity
        ) {
          return activityOrder.indexOf(activity.id);
        });
        workflow.activities = sorderActivities;
      }
    });
    innerWorkflowList = [...workflowList];
  };
  useEffect(() => {
    createWorkflowList();
    handleCollapsedWorkflow();
    setWorkflowList([...innerWorkflowList]);
    sortActivitiesInOrder(innerWorkflowList, selectedProject.workflow);
    let backupList = JSON.parse(JSON.stringify(innerWorkflowList));
    setFilteredWorkflowList(backupList);
    setCollapsedWorkflow([...collapsedWorkflowBackup]);
  }, [selectedProject, activitiesList, newListDropdown]);

  const addNewList = () => {
    var projectToUpdate = {
      // workflow: { ...selectedProject.workflow },
      // data: { ...selectedProject.data }
    };
    projectToUpdate.id = selectedProject.id;
    projectToUpdate.updated_at = moment
      .utc(new Date())
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
    
    let unarchived = [];
    _.forEach(selectedProject.workflow, (value, key) => {
      if (value.name === workflowNameGettingAdded) {
        unarchived.push({ id: key, name: workflowNameGettingAdded });
        return false;
      }
    });
    if (unarchived.length > 0)
      projectToUpdate.workflow_changes = { unarchived };
    else
      projectToUpdate.workflow_changes = { added: [workflowNameGettingAdded] };

    const auth_token = localStorage.getItem("auth_token");
    updateProjectAction(
      { auth_token, queryParam: "", body: projectToUpdate },
      (response) => {
        if (response && response.id) {
          
          updateSelectedProjectState(response);
          setNewListDropdown(false);
          setWorkflowNameGettingAdded("");
        }
      }
    );
  };
  const addNewWorkflowName = (name) => {
    setWorkflowNameGettingAdded(name);
    
  };
  
  const filterCollapsed = filteredWorkflowList.filter(
    (workflow) => !workflow.is_collapsed
  );
  return (
    <React.Fragment>
      <KanbanTopControls
        kanbanSearchText={kanbanSearchText}
        setKanbanSearchText={setKanbanSearchText}
        collapsedWorkflows={collapsedWorkflows}
        workflowList={filteredWorkflowList}
        setWorkflowList={setFilteredWorkflowList}
        setCollapsedWorkflow={setCollapsedWorkflow}
        workflowListBackup={workflowList}
        peopleHavingProjectEditAccess={peopleHavingProjectEditAccess}
      ></KanbanTopControls>
      <div
        className="projectflow-kanban-container"
        id="project-scroll-identifier"
      >
        {kanbanSearchText && filteredWorkflowList.length < 1 && (
          <div className="wl-filter-description not-found proj-workflow-res">
            No results found
          </div>
        )}
        <Scrollbar>
          <div
            className="kanban-wrapper"
            style={{
              width:
                filterCollapsed.length * workflowWidth + addWorkflowWidth + 10,
            }}
          >
            <ul className="kanban-board-holder">
              {filterCollapsed.map((workflow, index) => {
                return (
                  <DndProvider backend={HTML5Backend} key={index}>
                    <KanbanWorkflow
                      workflowList={filteredWorkflowList}
                      setWorkflowList={setFilteredWorkflowList}
                      workflow={workflow}
                      index={index}
                      collapsedWorkflows={collapsedWorkflows}
                      setCollapsedWorkflow={setCollapsedWorkflow}
                    ></KanbanWorkflow>
                  </DndProvider>
                );
              })}
            </ul>
            {!kanbanSearchText && (
              <div className="add-workflow-container">
                <div
                  className="add-workflow"
                  onClick={() => setNewListDropdown(true)}
                >
                  <p> + Add new list</p>
                </div>
                {newListDropdown && (
                  <OutsideClickHandler
                    onOutsideClick={() => setNewListDropdown(false)}
                  >
                    {" "}
                    <div className="add-workflow-box">
                      <div className="title-addflow">
                        <div className="add-workflow-title">Add new list</div>
                        <input
                          autoFocus
                          type="text"
                          placeholder="Type workflow name"
                          id="addNewflow"
                          onChange={(e) => addNewWorkflowName(e.target.value)}
                          value={workflowNameGettingAdded}
                        />
                      </div>
                      <div className="button">
                        <span
                          className="cancel"
                          onClick={() => setNewListDropdown(false)}
                        >
                          Cancel
                        </span>
                        <button
                          className="save"
                          onClick={addNewList}
                          disabled={!workflowNameGettingAdded.length}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
};

export default memo(KanbanColumnArea);
