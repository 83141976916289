import React, { useState, useRef, memo } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import activityDotSVG from "../../styles/img/svg/activity_dots.svg";
import OutsideClickHandler from "react-outside-click-handler";
import firebase from "../../firebase";

function ChecklistTile({ checklist, unarchiveChecklistAction }) {
  const [showMoreOption, setShowMoreOption] = useState(false);
  const showOptionsNode = useRef(null);

  const checklistItemTotal = checklist.checklist_items.filter(
    (item) => item.is_archived === true
  );
  const checklistItemCompleted = checklistItemTotal.filter(
    (item) => item.is_completed === true
  );

  const onArchiveChecklist = () => {
    const data = {
      activity_id: checklist.activity_id,
      title: checklist.title,
      created_by: checklist.created_by,
      position: checklist.position,
      id: checklist.id,
    };
    unarchiveChecklistAction({ ...data }, (_response) => {
      firebase.analytics().logEvent("GTD_Archive_Checklists_Unarchived");
    });
    setShowMoreOption(false);
  };

  const onOutsideClick = (e) => {
    if (showOptionsNode && !showOptionsNode.current.contains(e.target))
      setShowMoreOption(false);
  };

  return (
    <div className="checklist-card-main-container archive-center">
      <div className="checklist-top-container">
        <div className="comm-act-header checklist">
          <div className="options-activity">
            <div className="archive-edit-wrap">
              <div
                className="comm-option-icon"
                role="button"
                tabIndex="0"
                ref={showOptionsNode}
              >
                <div
                  className="comm-dot-holder"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowMoreOption(!showMoreOption);
                  }}
                >
                  <img src={activityDotSVG} alt="more-options" />
                </div>
                <OutsideClickHandler onOutsideClick={(e) => onOutsideClick(e)}>
                  {showMoreOption && (
                    <div className="comm-options-block ">
                      <div
                        className="comm-option-list"
                        onClick={onArchiveChecklist}
                        role="button"
                        tabIndex="0"
                      >
                        Unarchive
                      </div>
                    </div>
                  )}
                </OutsideClickHandler>
              </div>
            </div>
          </div>
        </div>
        <div className="checklist-content-container">
          <div className="checklist-card-title">
            <div className="truncate-txt checklist-title-txt">
              {" "}
              {checklist.title}
            </div>
            <span className="count ">
              {`(${checklistItemCompleted.length}/${checklistItemTotal.length})`}
            </span>
          </div>
          <div className="progress_bar">
            <div className="checklist-progress-bar">
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={
                  checklistItemTotal.length > 0
                    ? (checklistItemCompleted.length /
                        checklistItemTotal.length) *
                      100
                    : 0
                }
                className="checklistProgressBar"
              />
            </div>
            <div className="completed-percentage-text">
              <span>
                {checklistItemTotal.length > 0
                  ? Math.round(
                      (checklistItemCompleted.length /
                        checklistItemTotal.length) *
                        100
                    )
                  : 0}
                %
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="checklist-bottom-container truncate-text ">
        <span className="left-col ">{checklist.activity.title} - </span>
        {checklist.activity.project.name}
      </div>
    </div>
  );
}

export default memo(ChecklistTile);
