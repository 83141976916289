import React, { useState, memo } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import moment from "moment";
import Avatar from "react-avatar";
import _ from "lodash";
import DateRangePicker from "../common/dateRangePicker";
import { time_convert } from "../../helpers/generichelper";

const IndividualEffort = ({
  currencyLogo,
  individualEffort,
  startDate,
  endDate,
  onChangeIndividualEffortDate
}) => {
  // const [startDate, setStartDate] = useState(
  //   moment()
  //     .subtract(1, "month")
  //     .subtract(1, "day")
  //     .format("ddd, DD MMM YY")
  // );
  // const [endDate, setEndDate] = useState(
  //   moment()
  //     .subtract(1, "day")
  //     .format("ddd, DD MMM YY")
  // );
  const [sortIn, setSortIn] = useState(false);
  const [sortBy, setSortBy] = useState("bill");

  const onChangeDate = (event, picker) => {
    const from = moment(picker.startDate._d).format("ddd, DD MMM YY");
    const to = moment(picker.endDate._d).format("ddd, DD MMM YY");
    // setStartDate(from);
    // setEndDate(to);
    onChangeIndividualEffortDate(from, to);
  };

  const onChangeSort = sortOp => {
    if (sortOp === sortBy) {
      setSortIn(!sortIn);
    } else {
      setSortBy(sortOp);
      setSortIn(false);
    }
  };

  const renderIndividualEffort = () => {
    let sortedIndividualEffort = [];
    if (sortBy === "emp") {
      if (sortIn) {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.name.toLowerCase(),
          "asc"
        );
      } else {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.name.toLowerCase(),
          "desc"
        );
      }
    }
    if (sortBy === "bill") {
      if (sortIn) {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.total_billable_amount,
          "asc"
        );
      } else {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.total_billable_amount,
          "desc"
        );
      }
    }
    if (sortBy === "time") {
      if (sortIn) {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.total_billable_time_in_hours,
          "asc"
        );
      } else {
        sortedIndividualEffort = _.orderBy(
          individualEffort,
          emp => emp.total_billable_time_in_hours,
          "desc"
        );
      }
    }
    return sortedIndividualEffort.map(emp => {
      return (
        <React.Fragment>
          <tr>
            <td className="emp-img-name">
              {emp.img ? (
                <img src={emp.img} alt="emp_img" />
              ) : (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={emp.name}
                ></Avatar>
              )}
              <span>{emp.name}</span>
            </td>
            <td>{currencyLogo}{" "}{emp.total_billable_amount}</td>
            <td>{time_convert(emp.total_billable_time_in_hours).hours}h {time_convert(emp.total_billable_time_in_hours).minutes}m</td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
          <div className="over-title">Individual Effort</div>
          <div className="date-range-picker">
            <div className="date-range">
              <DateRangePicker
                source={"DASHBOARD"}
                startDate={moment(new Date(startDate)).format("M/DD/YYYY")}
                endDate={moment(new Date(endDate)).format("M/DD/YYYY")}
                maxDate={moment(new Date()).format("M/DD/YYYY")}
                onChangeDate={onChangeDate}
              >
                <div className="date-img date-picker">{`${startDate} - ${endDate}`}</div>
              </DateRangePicker>
            </div>
          </div>
        </div>
        {_.isArray(individualEffort) && <div className="table-holder">
          <table>
            <thead>
              <tr>
                <th
                  onClick={() => onChangeSort("emp")}
                  style={{ width: "152px" }}
                >
                  <span
                    className={`sort-arrow ${(sortBy === "emp" &&
                      sortIn === false &&
                      "down") ||
                      (sortBy === "emp" && sortIn === true && "up")}`}
                  >
                    Employee
                  </span>
                </th>
                <th onClick={() => onChangeSort("bill")} style={{ width: "83px" }}>
                  <span
                    className={`sort-arrow ${(sortBy === "bill" &&
                      sortIn === false &&
                      "down") ||
                      (sortBy === "bill" && sortIn === true && "up")}`}
                  >
                    Billable
                  </span>
                </th>
                <th onClick={() => onChangeSort("time")}>
                  <span
                    className={`sort-arrow ${(sortBy === "time" &&
                      sortIn === false &&
                      "down") ||
                      (sortBy === "time" && sortIn === true && "up")}`}
                  >
                    Time
                  </span>
                </th>
              </tr>
            </thead>
          </table>
          <Scrollbar>
            <table>
              <tbody>{renderIndividualEffort()}</tbody>
            </table>
          </Scrollbar>
        </div>}
        {typeof individualEffort === "object" && individualEffort.msg && 
        <div className="no-data">{individualEffort.msg}</div>}
      </div>
    </React.Fragment>
  );
};

export default memo(IndividualEffort);
