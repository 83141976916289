import React, { PureComponent } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import moreOption from "../../styles/img/svg/3-dots.svg";
import announcementSpeakerIcon from "../../styles/img/svg/announcement-speaker-icon.svg";

import downArrow from "../../styles/img/svg/board_arrow.svg";
import ReactMarkdown from "react-markdown/with-html";
import { parseComment, getPosition } from "../../helpers/generichelper";
import firebase from "../../firebase";
export default class GlobalNotification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      readMore: false,
      showDropdown: false
    }
  }

  onToggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  }

  onOutsideClick = () => {
    this.setState({ showDropdown: false });
  }

  onCloseThis = () => {
    firebase.analytics().logEvent("GTD_Notifications_System_Notification_Closed");
    this.props.archiveSystemNotification(this.props.notification);
  }

  onCloseAll = () => {
    firebase.analytics().logEvent("GTD_Notifications_System_Notification_Closed");
    this.props.archiveAllGlobalNotification();
  }

  render() {
    const { notification } = this.props;
    return (
      <div class="system-notify-card">
        <div class="system-notify-header">
          Announcement
          <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
          <div
            class="system-notify-close"
            onClick={this.onToggleDropdown}
          >
            <div className="global-more-option-holder">
              <img src={moreOption} alt="more-option" />
            </div>
            {this.state.showDropdown && (
                  <div
                    className="notifi-options-ddown-holder global"
                    id="notifi-options-ddown-holder"
                  >
                    <div
                      className="notifi-options-ddown-option"
                      onClick={this.onCloseThis}
                    >
                      Close this
                    </div>
                    <div
                      className="notifi-options-ddown-option"
                      onClick={this.onCloseAll}
                    >
                      Close all
                    </div>
                  </div>
                )}
          </div>
          </OutsideClickHandler>
        </div>
        <div class="system-notify-body">
          <div class="system-notify-body-header">
            <div class="system-notify-speaker">
              <img src={announcementSpeakerIcon} alt="speaker-icon" />
            </div>
            <div class="system-notify-body-heading">{notification.title}</div>
          </div>

          {!this.state.readMore ? <div class="system-notify-body-content with-ellipsis">
            <ReactMarkdown
              source={parseComment(notification.description.substr(0, getPosition(notification.description, "\n\n", 2)))}
              escapeHtml={false}
            />
          </div> : <div class="system-notify-body-content">
            <ReactMarkdown
              source={parseComment(notification.description)}
              escapeHtml={false}
            />
          </div>}
          {!this.state.readMore && (
            <div
              className="read-more"
            >
              <div onClick={() => this.setState({ readMore: true })}>
                Read more
                <img src={downArrow} alt="down-arrow" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
