import React, { memo } from "react";

function BillingFaqContainer({ currencyLogo, price, status, toggleFaq }) {
  return (
    <div
      className={
        status === true ? "bill-faq-container active" : "bill-faq-container"
      }
    >
      <div
        className={
          status === true ? "bill-faq-heading active" : "bill-faq-heading"
        }
        onClick={toggleFaq}
      >
        Frequently Asked Questions
      </div>{" "}
      {status === true && (
        <div className="bill-faq-qa-container" id="faq-bill">
          <div className="bill-faq-qa-holder">
            <div className="bill-faq-question">How does your pricing work?</div>
            <div className="bill-faq-answer">
              Get Things Done is a subscription service so the pricing depends
              on the number of people in your team. Our pricing is{" "}
              {currencyLogo}
              {price} per user/month.
            </div>
          </div>
          <div className="bill-faq-qa-holder">
            <div className="bill-faq-question">
              What forms of payments are accepted?
            </div>
            <div className="bill-faq-answer">
              We accept credit cards for monthly and annual subscriptions.
            </div>
          </div>
          <div className="bill-faq-qa-holder">
            <div className="bill-faq-question">
              We need to add more people to our team. How will that be billed?
            </div>
            <div className="bill-faq-answer">
              We’ll automatically charge your credit card when new user accounts
              are added.
            </div>
          </div>
          <div className="bill-faq-qa-holder">
            <div className="bill-faq-question">
              Can we cancel the plan any time?
            </div>
            <div className="bill-faq-answer">
              There's no minimum contract for Get Things Done cancel at any time
              without hassle. There won’t be any refund for the unused month.
            </div>
          </div>
          <div className="bill-faq-qa-holder">
            <div className="bill-faq-question">Can I try it before buying?</div>
            <div className="bill-faq-answer">
              You can use up to 5 members for as long as you want.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(BillingFaqContainer);
