import * as loginConstants from "../../helpers/loginconstants";

const initialState = {
  loginAuth: {
    auth_token: null,
    key: null
  },
  loginActionState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  registrationActionState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  forgotPasswordLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  resetPasswordLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  }
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case loginConstants.updateAuthKey:
      return {
        ...state,
        loginAuth: {
          user_details: action.payload.user_details,
          auth_token: action.payload.auth_token,
          key: action.payload.key
        }
      };

    case loginConstants.loginLoaderState:
      return {
        ...state,
        loginActionState: action.payload
      };

    case loginConstants.registerLoaderState:
      return {
        ...state,
        registrationActionState: action.payload
      };

    case loginConstants.forgotPasswordLoaderState:
      return {
        ...state,
        forgotPasswordLoaderState: action.payload
      };

    case loginConstants.resetPasswordLoaderState:
      return {
        ...state,
        resetPasswordLoaderState: action.payload
      };
    // case loginConstants.updateLastWorkedWorkspace:
    //   return {
    //     ...state,
    //     loginAuth: {
    //       ...state,
    //       user_details: action.payload.user_details,
    //       key: action.payload.key
    //     }
    //   };

    default:
      return {
        ...state
      };
  }
}
