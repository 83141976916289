import {
  getProjectDetails,
  createProject,
  updateProjectDetails,
  getProjectById
} from "../../services/projectapi";
import { projectConstants } from "../../constants/projectconstants";
import { peopleConstants } from "../../constants/peopleconstants";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateProjectList({ action, payload }) {
  // console.log("updateProjectList function is called")
  return {
    type: action,
    payload
  };
}

function updateArchiveProjectList(project) {
  return {
    type: projectConstants.unarchiveProject,
    payload: {
      project
    }
  };
}

const getProjectByIdValue = project => {
  return {
    type: projectConstants.getProjectById,
    payload: {
      project: project
    }
  };
};

export const getProjectsAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: projectConstants.projectListLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getProjectDetails({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: projectConstants.projectListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateProjectList({
          action: projectConstants.projectList,
          payload: { list: response }
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: projectConstants.projectListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getArchivedProjectsAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: projectConstants.projectListLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getProjectDetails({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: projectConstants.projectListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateProjectList({
          action: projectConstants.archivedProjectList,
          payload: { list: response }
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: projectConstants.projectListLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const clearProjectsAction = () => dispatch => {
  dispatch(
    updateProjectList({
      action: projectConstants.clearProjects,
      payload: []
    })
  );
}

export const createProjectAction = (body, callback) => dispatch => {
  dispatch(
    updateLoaderState({
      action: projectConstants.updateProjectLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  createProject(body, response => {
    if (response && !response.error) {
      dispatch(
        updateLoaderState({
          action: projectConstants.updateProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateProjectList({
          action: projectConstants.createProject,
          payload: response
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: projectConstants.updateProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateProjectAction = (
  { auth_token, queryParam, body },
  callback
) => dispatch => {
  // console.log("updateProjectAction is called","auth_token",auth_token,"queryParam",queryParam, "body",body)
 
  dispatch(
    updateLoaderState({
      action: projectConstants.updateProjectLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
 

  dispatch(
    updateLoaderState({
      action: peopleConstants.updateRoleLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
 

  updateProjectDetails({ auth_token, queryParam, body }, response => {
    if (response && !response.error) {

      dispatch(
        updateLoaderState({
          action: projectConstants.updateProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateLoaderState({
          action: peopleConstants.updateRoleLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(
        updateProjectList({
          action: projectConstants.updateProjectDetails,
          payload: response
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: projectConstants.updateProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
      dispatch(
        updateLoaderState({
          action: peopleConstants.updateRoleLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const unArchivedArchivedProject = (
  { auth_token, body },
  callback
) => dispatch => {
  updateProjectDetails({ auth_token, body }, response => {
    if (response && !response.stdError) {
      dispatch(updateArchiveProjectList({ ...response }));
      callback && callback(response);
    }
  });
};

export const getProjectByIdAction = (
  archived,
  projectId,
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: projectConstants.singleProjectLoaderState,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getProjectById(archived, projectId, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: projectConstants.singleProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      getProjectByIdValue(response[0]);
    } else {
      dispatch(
        updateLoaderState({
          action: projectConstants.singleProjectLoaderState,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response[0]);
  });
};
