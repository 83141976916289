import React from "react";
import _ from "lodash";
import TimerMachine from "react-timer-machine";
import moment from "moment";
import firebase from "../../firebase";
import play from "../../styles/img/svg/play_new.svg";
import stop from "../../styles/img/svg/stop_new.svg";
import routesHelper from "../../router/routeshelper";

export default function Timer({
  activity,
  getTimerStatusForWorkspaceAction,
  anyTimerStatus,
  onShowCannotStartPopup,
  is_priority,
  startTimerAction,
  shouldEnablePopup,
  fromWorkload,
  fromKanban,
  tabActiveFlag
}) {
  

  const {
    // timerStartTimeInSeconds,
    timerShouldBeEnabled,
    timerForTheSession
  } = renderTimerLogicNew(activity, anyTimerStatus);

  return (
    <>
      <div className="timer">
        <div className="timer-outer-div">
          {/* <div>{{activity.INTERVAL}}{{currentTime - activity.INTERVAL*1000}}</div> */}
          {/* commented for new activity design */}
          {/* <span
            className={
              !timerShouldBeEnabled
                ? "total-time total-timer-running"
                : "total-time"
            }
          >
            Total time spent -{" "}
            {timerShouldBeEnabled &&
              moment
                .duration(timerStartTimeInSeconds, "seconds")
                .format("HH:mm:ss", { trim: false })}
            {!timerShouldBeEnabled && (
              <TimerMachine
                timeStart={timerStartTimeInSeconds * 1000}
                started={!timerShouldBeEnabled}
                paused={false}
                countdown={false}
                interval={1000}
                formatTimer={(time, ms) =>
                  moment
                    .duration(ms, "milliseconds")
                    .format("HH:mm:ss", { trim: false })
                }
                // onStart={}
              />
            )}
          </span> */}
          {!timerShouldBeEnabled && tabActiveFlag && (
            <span className="timer-activity">
              <TimerMachine
                timeStart={timerForTheSession * 1000}
                started={!timerShouldBeEnabled}
                paused={false}
                countdown={false}
                interval={1000}
                formatTimer={(_time, ms) =>
                  moment
                    .duration(ms, "milliseconds")
                    .format("HH:mm:ss", { trim: false })
                }
                // onStart={}
              />
            </span>
          )}
          {timerShouldBeEnabled && (
            <span className="timer-activity">
              {timerForTheSession > 0 &&
                moment
                  .duration(renderTimerLogic.timerForTheSession, "seconds")
                  .format("HH:mm:ss", { trim: false })}
            </span>
          )}
        </div>

        {/* $$ if activity is running, show stop icon, css class is stop
                            if activity isnt running, show play icon, css class is play */}
        {!fromWorkload && !fromKanban && <div
          className="timer-button stop"
          style={{ cursor: "pointer" }
            // timerRunningStatus && timerShouldBeEnabled
            //   ? { pointerEvents: "none", cursor: "default" }
            //   : {}
          }
        >
          {" "}
          {is_priority && (
            <img
              src={timerShouldBeEnabled ? play : stop}
              alt="Play/Pause"
              className="play-btn pause"
              onClick={() => {
                onPlayPauseClicked(
                  timerShouldBeEnabled,
                  activity,
                  startTimerAction,
                  shouldEnablePopup,
                  getTimerStatusForWorkspaceAction,
                  onShowCannotStartPopup,
                  anyTimerStatus
                );
              }}
            />
          )}
        </div>}
      </div>
    </>
  );
}

const renderTimerLogic = activity => {
  const currentUserId = parseInt(localStorage.getItem("id"));
  var timerStartTimeInSeconds = 0;
  let timerForTheSession = 0;
  let timerShouldBeEnabled = null;

  const timesheet = activity.timesheets.filter(
    ts => ts.user_id === currentUserId
  );

  timerStartTimeInSeconds = timesheet.reduce((totalTime, current) => {
    return (totalTime + current.time_taken);
  }, 0);

  const sortedTimesheetData = _.orderBy(timesheet, "id", ["desc"]);

  if (sortedTimesheetData.length > 0) {
    timerShouldBeEnabled =
      (sortedTimesheetData[0].data &&
        _.has(sortedTimesheetData[0].data.TIME[0], "STOP")) ||
      sortedTimesheetData[0].data.TIME.length <= 0
        ? true
        : false;
  } else {
    timerShouldBeEnabled = true;
  }

  if (
    !timerShouldBeEnabled &&
    sortedTimesheetData[0].data &&
    sortedTimesheetData[0].data.TIME[0] &&
    sortedTimesheetData[0].data.TIME[0].hasOwnProperty("START")
  ) {
    timerStartTimeInSeconds += moment(new Date()).diff(
      sortedTimesheetData[0].data.TIME[0].START,
      "seconds"
    );
    timerForTheSession = moment(new Date()).diff(
      sortedTimesheetData[0].data.TIME[0].START,
      "seconds"
    );
  }

  return {
    timerStartTimeInSeconds,
    timerForTheSession,
    timerShouldBeEnabled
  };
};

const renderTimerLogicNew = (activityDetails, anyTimerStatus) => {
  const currentUserId = parseInt(localStorage.getItem("id"));
  var timerStartTimeInSeconds = 0;
  let timerForTheSession = 0;
  let timerShouldBeEnabled = null;

  const timesheet = activityDetails && activityDetails.timesheets && activityDetails.timesheets.filter(
    ts => ts.user_id === currentUserId
  );
  timerStartTimeInSeconds = timesheet && timesheet.reduce((totalTime, current) => {
    return (totalTime + current.time_taken);
  }, 0);

  if(activityDetails && anyTimerStatus?.any_timer_running && anyTimerStatus?.timesheet?.activity_id === activityDetails.id) {
    timerShouldBeEnabled = false;
    timerStartTimeInSeconds += moment(new Date()).diff(
      anyTimerStatus.timesheet.data.TIME[0].START,
      "seconds"
    );
    timerForTheSession = moment(new Date()).diff(
      anyTimerStatus.timesheet.data.TIME[0].START,
      "seconds"
    );
  } else  {
    timerShouldBeEnabled = true;
  }

  return {
    timerStartTimeInSeconds,
    timerForTheSession,
    timerShouldBeEnabled
  }
}

const onPlayPauseClicked = (
  _timerEnabled,
  selectedTileDetials,
  startTimerAction,
  shouldEnablePopup,
  getTimerStatusForWorkspaceAction,
  onShowCannotStartPopup,
  anyTimerStatus
) => {
  if (startTimerAction) {
    //fetching Timesheet for current user
    const customer_auth_token = localStorage.getItem("customer_auth_token");
    const currentUserId = parseInt(localStorage.getItem("id"));
    

    const currentRunningTimeSheet = anyTimerStatus?.timesheet?.activity_id === selectedTileDetials.id ?
        anyTimerStatus.timesheet : null;

    

    // if (
    //   sortedTimesheetData.length > 0 &&
    //   _.has(sortedTimesheetData[0].data.TIME[0], "START") &&
    //   !_.has(sortedTimesheetData[0].data.TIME[0], "STOP")
    // ) {
    // New condition from timer API
    if (
      currentRunningTimeSheet &&
      _.has(currentRunningTimeSheet.data.TIME[0], "START") &&
      !_.has(currentRunningTimeSheet.data.TIME[0], "STOP")
    ) {
      firebase.analytics().logEvent("GTD_Activity_inner_timer_stop");
      shouldEnablePopup(true, selectedTileDetials);
      // Create ne timesheet Object and call updateTimesheetAction
    } else {
      const currentTime = moment(new Date()).format();
      const data = {
        TIME: [{ START: currentTime }]
      };
      startTimerAction(
        {
          activity_id: selectedTileDetials.id,
          data,
          date: currentTime,
          user_id: currentUserId,
          customer_auth_token
        },
        response => {
          if(response && response.id) {
            firebase.analytics().logEvent("GTD_Activity_inner_timer_start");
            getTimerStatusForWorkspaceAction({ auth_token: routesHelper.getAuthToken() });
          }
          if(response?.error?.msg === "Timer already running" || response?.error?.msg === "You are not a part of this activity card") {
            onShowCannotStartPopup(response);
          }
        }
      );
    }
  }
};
