import routesHelper from "../router/routeshelper";
import { secureFetch } from "../helpers/fetchhelper";
import { API_URL } from "../constants/globalconst.js";

export function registerUser({ email, password, name }, callback) {
  secureFetch(API_URL + "/api/v1/user", {
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
      first_name: name,
      last_name: name
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      // "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function validateInvite({ token }, callback) {
  secureFetch(API_URL + "/api/v1/validate-invite", {
    method: "POST",
    body: JSON.stringify({
      token
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      // "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function acceptInvite({ token, auth_token }, callback) {
  secureFetch(API_URL + "/api/v1/accept-invite", {
    method: "POST",
    body: JSON.stringify({
      token
    }),
    headers: new Headers({
      "Content-Type": "application/json",

      Origin: API_URL,
      // Authorization: "Bearer: " + auth_token
      "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((respJson) => callback(respJson))
    .catch((error) => {
      //
    });
}

export function verifyUser({ email }, callback) {
  secureFetch(API_URL + "/api/v1/verify-email", {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      // "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((res) => callback(res))
    .catch((err) => console.log(err));
}

export function resendVerificationEmail({ email }, callback) {
  secureFetch(API_URL + "/api/v1/resend-verification-email", {
    method: "POST",
    body: JSON.stringify({
      email: email
    }),
    headers: new Headers({
      "content-type": "application/json",
      // Authorization: "Bearer: " + routesHelper.getAuthToken()
      // "X-ACCESS-TOKEN": routesHelper.getAuthToken()
    })
  })
    .then((res) => callback(res))
    .catch((err) => console.log(err));
}
