import React from 'react';
import firebase from "../../firebase";

const ArrowBottom = (props) => {
    let navigation = null;

  const showTooltip=()=> {
        let tooltip = document.getElementById("tooltip");
        if(tooltip){
            tooltip.innerHTML = 'Skip Tutorial';
            tooltip.style.display = "block";
        }
      }
      
     const   hideTooltip=()=> {
        var tooltip = document.getElementById("tooltip");
        if(tooltip){
            tooltip.style.display = "none";

        }
      }
    if (props.showPrev) {
        navigation = <div className="footerTour">
            <span className="stepCount">
                {props.step + 1} of 6 
            </span>
            <div className="prevnextButton">
                <span className="previousButton" onClick={() => props.goTo(props.prevStep)}>
                  Previous
                </span>
                <span className="nextButton" onClick={() => props.goTo(props.nextstep)}>
                     Next
                 </span>
            </div>
         </div>
    } else {
        navigation = <div className="footerTour">

            <span className="stepCount">
                {props.step + 1} of 6 
            </span>
            <div className="prevnextButton">
                <span className="nextButton" onClick={() => props.goTo(props.nextstep)}>
                    Next
                 </span>
            </div>
        </div>
    }

    return (
        <div className={props.classData}>
            <div id="tooltip"  style={{position: 'absolute', display: 'none'}} />
            <svg className="skipClass" onMouseMove={showTooltip} onMouseOut={hideTooltip} onClick={()=>{
                firebase.analytics().logEvent("GTD_Registration_Flow_Tutorial_Skip");
                props.closeTour(true)}} focusable="false"  viewBox="0 0 24 24" />
            <h5 className="header">{props.header}</h5>
            <span className="contentData">
                {props.data}
            </span>
            <br />
            {navigation}
        </div>
    )

}

export default ArrowBottom;