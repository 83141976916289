import React from "react";

import { Form, Control } from "react-redux-form";
import MaterialButton from "../../common/materialbutton";

const ForgotPasswordForm = props => {
  const onInputKeyUp = (e) => {
    if(e.key === "Enter") props.onForgotEmailRequest();
  }
  return (
    <div className="signIn">
    
      <div className="fp-header-area">
        <div className="fp-main-heading">Forgot Password?</div>
        <div className="fp-sub-heading">
          Enter your registered email to receive <br />
          password reset link
        </div>
      </div>
      <Form
        name="resetPasswordForm"
        onUpdate={props.onFormChange}
        model="rrfState.forgot"
      >
        <div className="rp-controls-container fp">
          <div className="login-control-holder">
            <Control.text
              type="text"
              placeholder="Email"
              model=".email"
              name="email"
              //required
              className="common-input-ctrl"
              onKeyUp={onInputKeyUp}
              autoFocus
            />
            {props.email && <p className="common-err-msg">{props.email}</p>}
          </div>
        </div>
        <div className="rp-submit-holder fp">
          <MaterialButton
            buttonText="Submit"
            color="primary"
            variant="contained"
            className="common-submit-btn"
            //disabled={props.notValidForm}
            onClick={props.onForgotEmailRequest}
          ></MaterialButton>
        </div>
        <div className="rf-bottom-area back-login">
          <p>
            Back to
            <a className="back-to-login-link" onClick={props.onGoBackToLogin}>
              Login
            </a>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
