import React, { useState, memo } from "react";
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Legend, ColumnSeries
} from 'react-jsx-highcharts';
import { Scrollbar } from "react-scrollbars-custom";
import _ from "lodash";
// import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

const ProjectStatus = ({
  sortBy,
  onChangeSortBy,
  leaderBoard
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const onShowDropdown = () => {
      setShowDropdown(!showDropdown);
  }
  const handleClickOutside = () => {
      setShowDropdown(false);
  }

  const options = [
    {id: 0, name: "This week"},
    {id: 1, name: "Last week"},
    {id: 2, name: "This month"},
    {id: 3, name: "Last month"}
  ];

  const dropdownOptions = 
      options.map((workflow) => {
        return (
          <li
            className={
                sortBy.id === workflow.id
                ? "active"
                : ""
            }
            key={workflow.id}
            onClick={() => onChangeSortBy(workflow)}
            style={{ fontSize: "11px" }}
          >
            {workflow.name}
          </li>
        );
      });

      const currentUserData = _.isArray(leaderBoard) && leaderBoard.filter(user => user.current_user);
      const otherHrs = _.isArray(leaderBoard) && leaderBoard.filter(user => !user.current_user).map(user => user.hours_worked);

  return (
    <React.Fragment>
      <div className="over-upper">
        <div className="title-date-holder">
          <div className="over-title">Leader Board
          </div>
          <OutsideClickHandler onOutsideClick={handleClickOutside}>
          <div
                className={
                  showDropdown
                    ? "workflow-change-ddown-holder clicked"
                    : "workflow-change-ddown-holder"
                }
                onClick={onShowDropdown}
              >
                <div className="workflow-change-ddown-text" style={{ fontSize: "11px" }}>
                  {sortBy.name}{" "}
                </div>
                {showDropdown && (
                    <div className="workflow-change-ddown" style={{ width: "160px" }}>
                      <ul className="workflow-change-ddown-inner">
                        {dropdownOptions}
                      </ul>
                    </div>
                )}
              </div>
                </OutsideClickHandler>
        </div>
    {_.isArray(leaderBoard) &&
    <React.Fragment>
    <Scrollbar>
        <div id="container">
    <HighchartsChart
        plotOptions={{
            series: {
                pointWidth: 5,
                events: {
                  legendItemClick: function() {
                    return false;
                  }
              }
            }
        }}
    >
          <Chart 
            height={210}
            // width={leaderBoard.length*50}
          />

          <Legend align="left" verticalAlign="top" />

          <XAxis visible={false} />

          <YAxis>
          <YAxis.Title>hours</YAxis.Title>
            <ColumnSeries name="You" data={[currentUserData[0].hours_worked]} />
            <ColumnSeries name="Others" data={[null, ...otherHrs]} />
          </YAxis>
        </HighchartsChart>
        </div>
        </Scrollbar>
        <div className="performer-percent">
        You are among the top {currentUserData[0].performance_percentage}% performers {sortBy.name.toLowerCase()}.
        </div>
        </React.Fragment>
    }
        {typeof leaderBoard === "object" &&
          leaderBoard?.msg && (
            <div className="no-data">{leaderBoard.msg}</div>
          )}
      </div>
    </React.Fragment>
  );
};

export default memo(withHighcharts(ProjectStatus, Highcharts));
