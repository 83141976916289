import React, { Component } from "react";

class StatusDropDown extends Component {
  state = {};

  onSelectValue = (event, value) => {
    this.props.onSelectValue(value, event.target.innerText)
    this.setState({ selectedValue: event.target.innerText })
  }

  // handleClickOutside = event => {

  //   if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
  //     // this.setState({
  //     //   activeDropDown: false
  //     // });
  //     this.props.onShowStatusDropDown(event);
  //   }
  // };

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClickOutside, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside, false);
  // }

  render() {
    return (
      <div
        className="ppl-settings-ddown proj-settings-ddown"
        // ref={node => (this.outerDivNode = node)}
      >
        <div
          className="ppl-settings-ddown-list"
          onClick={(event) => this.onSelectValue(event, "ACTIVE")}
        >
          {/* statusProject.display_name */}
          Active
        </div>
        <div
          className="ppl-settings-ddown-list"
          onClick={(event) => this.onSelectValue(event, "ON_HOLD")}
        >
          {/* statusProject.display_name */}
          On Hold
        </div>
        <div
          className="ppl-settings-ddown-list"
          onClick={(event) => this.onSelectValue(event, "COMPLETED")}
        >
          {/* statusProject.display_name */}
          Completed
        </div>
        <div
          className="ppl-settings-ddown-list"
          onClick={(event) => this.onSelectValue(event, "CANCELLED")}
        >
          {/* statusProject.display_name */}
          Cancelled
        </div>
      </div>
    );
  }
}

export default StatusDropDown;
