import React, { Component } from "react";
import _ from "lodash";

class EngagementDropDown extends Component {
  state = {};

  render() {
    const renderEngagementOptions =
      (this.props.currentWorkspace.data &&
      this.props.currentWorkspace.data.settings &&
      this.props.currentWorkspace.data.settings.engagement_types &&
      (_.sortBy(this.props.currentWorkspace.data.settings.engagement_types) ||
      ["Fixed", "Retailer", "T&M"])).map(
        (el) => {
          return (
            <div
              className="ppl-settings-ddown-list"
              onClick={(event) => this.props.onSelectEngagementType(el)}
            >
              {/* statusProject.display_name */}
              {el}
            </div>
          );
        }
      );

    return (
      <div
        className="ppl-settings-ddown proj-settings-ddown"
        // ref={node => (this.outerDivNode = node)}
      >
        <div className="ppl-settings-ddown-inner-holder">
          {renderEngagementOptions}
        </div>
      </div>
    );
  }
}

export default EngagementDropDown;
