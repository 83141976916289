import React, { useState, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import activityDots from "../../styles/img/svg/activity_dots.svg";
import routesHelper from "../../router/routeshelper";

const MenuOptions = ({
  router,
  project,
  unArchivedArchivedProject,
  unArchiveArchivedProject,
  source
}) => {
  const [showProjectOptions, setShowProjectOptions] = useState(false);

  const [showArchivePopup, setShowArchivePopup] = useState(false);

  const menuOptionsNode = useRef();

  const onShowProjectOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProjectOptions(!showProjectOptions);
  };

  const onHideProjectOptions = (e) => {
    if (menuOptionsNode && !menuOptionsNode.current.contains(e.target)) {
      if (showProjectOptions) {
        setShowProjectOptions(false);
        onHideArchivePopup();
      }
    }
  };

  const onProjectEdit = () => {
    project && router.push(routesHelper.getProjectEditPath(project.id));
  };

  const onShowArchivePopup = () => {
    setShowArchivePopup(true);
  }

  const onHideArchivePopup = () => {
    setShowArchivePopup(false);
  }

  const onProjectArchive = () => {
    const body = {
      id: project.id,
      archived: true
    };
    unArchivedArchivedProject({ body }, (response) => {
      setShowProjectOptions(false);
    });
  };

  return (
    <div className="menu_options" ref={menuOptionsNode}>
      <div className="dot-holder-pd" onClick={onShowProjectOptions}>
        <img src={activityDots} className="mCS_img_loaded" alt="more-options" />
      </div>
      {showProjectOptions && (
        <OutsideClickHandler onOutsideClick={onHideProjectOptions}>
          <div className="options-block">
            {!source && <div className="proj-menu-option" onClick={onShowArchivePopup}>Archive</div>}
            {showArchivePopup && (
                        <div className="deleteflow-box">
                          <p>Archive this project?</p>
                          <div className="button">
                            <span
                              className="cancel"
                              onClick={onHideArchivePopup}
                            >
                              Cancel
                            </span>
                            <button
                              className="save"
                              onClick={onProjectArchive}
                            >
                              Archive
                            </button>
                          </div>
                        </div>
                      )}
            <div
              className="proj-menu-option"
              onClick={
                source === "FROM_ARCHIVED"
                  ? unArchiveArchivedProject
                  : onProjectEdit
              }
            >
              {source === "FROM_ARCHIVED" ? "Unarchive" : "Edit"}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default MenuOptions;
