import React, { Component } from "react";
import "../../styles/scss/notifications.scss";

import notify_time from "../../styles/img/svg/notify-time.svg";
import { commonDateFormat } from "../../helpers/generichelper";

class NotifyWorkHours extends Component {
  
  render() {
    
    const { notification } = this.props;
    return (
      <div classNameName="notify-card-others">
        <div
          className={
            notification.is_read === true
              ? "notify-read-unread read"
              : "notify-read-unread"
          }
          ng-click="notificationScope.readNotification(notification,'All');$event.stopPropagation()"
        >
          {/* <md-tooltip md-direction="bottom"> {notification.is_read ? 'Mark as unread' : 'Mark as read' } </md-tooltip> */}
        </div>
        <div
          className="notifi-flag"
          ng-className="{'flagged':notification.is_flagged}"
        ></div>
        <div className="notify-card-left">
          <div className="notify-type-icon">
            <img src={notify_time} alt="timer-icon" />
          </div>
        </div>
        <div className="notify-card-right">
          <div className="notify-card-title">{notification.message}</div>
          <p className="post_time">
            {commonDateFormat(notification.created_at)}
          </p>
        </div>
      </div>
    );
  }
}
export default NotifyWorkHours;
