import React from "react";
import arrowBack from "../../styles/img/svg/forwar_arrow_profile.svg";
import PerformaActions from "../activity/performaactions";
import UserActionDropDown from "../common/useractiondropdown";
import "../../styles/scss/user_profile.scss";

export default function ProjectEditHeader(props) {
  const {
    router,
    updatedSearchTextAction,
    selectedCustomerDetails,
    updateLoginAuth,
    currentUser,
    updateTabState,
    shouldRenderProfile,
    shouldRenderEditProject,
    shouldRenderFindWorkspace,
    shouldRenderWorkspaceInvitations
  } = props;
  const goBack = () => {
    updatedSearchTextAction &&
      updatedSearchTextAction({ searchtext: null, activeClass: null });
    updateTabState && updateTabState({ projectsLanding: "ACTIVE" });
    if (router.location.state && router.location.state.prevPath)
      router.push(router.location.state.prevPath);
    else router.goBack();
  };
  return (
    <div className="profile-header-wrap">
      <div className="right-flow-profile">
        {shouldRenderEditProject && (
          <div
            className="back-arrow-container"
            onClick={goBack}
            role="button"
            tabIndex="0"
          >
            <img src={arrowBack} alt="back-arrow" />
            <span className="back-button-profile">Project Settings</span>
          </div>
        )}
        {(shouldRenderProfile || shouldRenderFindWorkspace || shouldRenderWorkspaceInvitations) && (
          <React.Fragment>
            <div
              className="back-arrow-container profile-back-container"
              onClick={goBack}
              role="button"
              tabIndex="0"
            >
              <img src={arrowBack} alt="back-arrow" />
              <span className="back-from-profile">Back</span>
            </div>
            <div className="profile-title">
              {(shouldRenderProfile && "Profile") ||
                (shouldRenderFindWorkspace && "Create/Find a workspace") || 
                (shouldRenderWorkspaceInvitations && "Workspace Invitations")}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="proj-edit-performa">
        <PerformaActions
          totalTimeForTheDay={props.totalTimeForTheDay}
          router={router}
        />
        <UserActionDropDown
          router={props.router}
          updateLoginAuth={updateLoginAuth}
          currentUser={currentUser}
          selectedCustomerDetails={selectedCustomerDetails}
        />
      </div>
    </div>
  );
}
