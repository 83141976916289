import React, { PureComponent } from "react";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";

import triangleSVG from "../../styles/img/svg/triangle-reverse.svg";
import reverseTriangleSVG from "../../styles/img/svg/triangle180.svg";
import activityDotsSVG from "../../styles/img/svg/activity_dots.svg";
import closeNewSVG from "../../styles/img/svg/close_new.svg";
import ChecklistItemInput from "./checklistItemInput";
import ChecklistItemRearrangeMain from "./checklistItemRearrange";
import OutsideClickHandler from "react-outside-click-handler";
import { copyChecklist } from "../../services/checklist";

export default class ChecklistDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hideList:
        this.props.checklist.recent === true
          ? false
          : this.props.checklistOpenStatus === true
          ? false
          : this.props.checklist.is_open
          ? false
          : true,
      showOptionImageContainer: false,
      showAddItemTextBox: this.props.checklist.recent ? true : false,
      isChecklistEdit: false,
      editChecklistName: this.props.checklist.title,
      showCompleted: this.props.checklist.is_show,
      noItem: false,
    };
  }
  componentDidMount() {
    const { checklist, users, idOfElement } = this.props;
    const user = users.filter((eachUser) => eachUser.id === checklist.created_by);
    this.setState(() => ({
      user: user[0],
    }));

    // Taking show completed state from local storage
    // const checklistCaching = localStorage.getItem("checklistCaching")
    //   ? JSON.parse(localStorage.getItem("checklistCaching"))
    //   : [];
    // const filteredChecklistCaching = checklistCaching.filter(
    //   (cached) => cached.id === checklist.id
    // );
    // if (filteredChecklistCaching.length > 0) {
    //   this.setState({
    //     showCompleted: filteredChecklistCaching[0].hasOwnProperty(
    //       "showCompleted"
    //     )
    //       ? filteredChecklistCaching[0].showCompleted
    //       : true
    //   });
    // }

    if (document.querySelectorAll('[role="dialog"]').length > 0) {
      // selecting dialog for the scroll
      const elementsToScroll = document.querySelectorAll('[role="dialog"]')[0];
      // Element to which scrolled
      let element = document.getElementById(idOfElement);

      // Is use part of the card
      // const isUserPartOfCard = activityDetails.user_id.includes(
      //   parseInt(localStorage.getItem("id"))
      // );

      if (!_.isNull(element)) {
        // scrolling 250 is just an offset
        elementsToScroll.scrollTo({
          top: element.offsetTop + 250,
          behavior: "smooth",
        });

        element.classList.add("new-checklist");

        setTimeout(function() {
          element.classList.remove("new-checklist");
        }, 3000);
      }
    }
  }
  // for Hiding and showing the list
  onHideListClick = (hideListComp) => {
    const { checklist, updateChecklistAction } = this.props;
    const checklistCaching = localStorage.getItem("checklistCaching")
      ? JSON.parse(localStorage.getItem("checklistCaching"))
      : [];
    const updateChecklistCaching =
      checklistCaching.filter((cached) => cached.id === checklist.id).length > 0
        ? checklistCaching.map((cached) =>
            cached.id === checklist.id
              ? { ...cached, stateOpen: hideListComp }
              : cached
          )
        : [...checklistCaching, { id: checklist.id, stateOpen: hideListComp }];
    localStorage.setItem(
      "checklistCaching",
      JSON.stringify(updateChecklistCaching)
    );

    const updateChecklist = {
      activity_id: checklist.activity_id,
      created_by: checklist.created_by,
      id: checklist.id,
      is_archived: checklist.is_archived,
      position: checklist.position,
      title: this.state.editChecklistName,
      is_open: hideListComp,
    };
    updateChecklistAction({ ...updateChecklist }, (_res) => { /* TODO document why this arrow function is empty */ });

    this.setState({
      hideList: !hideListComp,
    });
  };
  showOptionImageContainer = () => {
    this.setState({
      showOptionImageContainer: !this.state.showOptionImageContainer,
    });
  };

  onAddItemButtonClick = () => {
    this.setState({
      showAddItemTextBox: true,
    });
  };
  toHideAddItemInput = () => {
    this.setState({
      showAddItemTextBox: false,
    });
  };

  onEditChecklistTitle = () => {
    this.setState({
      isChecklistEdit: true,
      editChecklistName: this.props.checklist.title,
    });
  };
  onCancelEditChecklistTitle = () => {
    this.setState({
      isChecklistEdit: false,
      editChecklistName: this.props.checklist.title,
      showOptionImageContainer: false,
    });
  };

  onChecklistTitleEdit = (event) => {
    this.setState({
      editChecklistName: event.target.value,
    });
  };

  onChecklistTitleSave = () => {
    const { checklist, updateChecklistAction } = this.props;
    this.setState({
      isChecklistEdit: false,
      showOptionImageContainer: false,
    });
    const updateChecklist = {
      activity_id: checklist.activity_id,
      created_by: checklist.created_by,
      id: checklist.id,
      is_archived: checklist.is_archived,
      position: checklist.position,
      title: this.state.editChecklistName,
    };
    updateChecklistAction({ ...updateChecklist }, (_res) => { /* TODO document why this arrow function is empty */ });
  };

  checkForEnter = (e) => {
    if (e.key === "Enter") {
      if (this.state.editChecklistName.length > 0) {
        this.onChecklistTitleSave();
        return this.setState({
          isChecklistEdit: false,
        });
      }
      return e.preventDefault();
    }
  };

  onShowCompleted = (hideListComp) => {
    const { checklist, updateChecklistAction } = this.props;
    const checklistCaching = localStorage.getItem("checklistCaching")
      ? JSON.parse(localStorage.getItem("checklistCaching"))
      : [];
    const updateChecklistCaching =
      checklistCaching.filter((cached) => cached.id === checklist.id).length > 0
        ? checklistCaching.map((cached) =>
            cached.id === checklist.id
              ? { ...cached, showCompleted: true }
              : cached
          )
        : [
            ...checklistCaching,
            { id: checklist.id, stateOpen: hideListComp, showCompleted: true },
          ];
    localStorage.setItem(
      "checklistCaching",
      JSON.stringify(updateChecklistCaching)
    );

    const updateChecklist = {
      activity_id: checklist.activity_id,
      created_by: checklist.created_by,
      id: checklist.id,
      is_archived: checklist.is_archived,
      position: checklist.position,
      title: this.state.editChecklistName,
      is_show: true,
    };
    updateChecklistAction({ ...updateChecklist }, (_res) => { /* TODO document why this arrow function is empty */ });

    this.setState({
      showCompleted: true,
      showOptionImageContainer: false,
    });
  };

  onHideCompleted = (hideListComp) => {
    const { checklist, updateChecklistAction } = this.props;
    const checklistCaching = localStorage.getItem("checklistCaching")
      ? JSON.parse(localStorage.getItem("checklistCaching"))
      : [];
    const updateChecklistCaching =
      checklistCaching.filter((cached) => cached.id === checklist.id).length > 0
        ? checklistCaching.map((cached) =>
            cached.id === checklist.id
              ? { ...cached, showCompleted: false }
              : cached
          )
        : [
            ...checklistCaching,
            { id: checklist.id, stateOpen: hideListComp, showCompleted: false },
          ];
    localStorage.setItem(
      "checklistCaching",
      JSON.stringify(updateChecklistCaching)
    );

    const updateChecklist = {
      activity_id: checklist.activity_id,
      created_by: checklist.created_by,
      id: checklist.id,
      is_archived: checklist.is_archived,
      position: checklist.position,
      title: this.state.editChecklistName,
      is_show: false,
    };
    updateChecklistAction({ ...updateChecklist }, (_res) => { /* TODO document why this arrow function is empty */ });

    this.setState({
      showCompleted: false,
      showOptionImageContainer: false,
    });
  };

  onIsArchieveClick = () => {
    const {
      checklist,
      updateChecklistAction,
      is_priority,
      is_archived,
    } = this.props;
    this.setState({
      isChecklistEdit: false,
      showOptionImageContainer: false,
    });
    const updateChecklist = {
      activity_id: checklist.activity_id,
      created_by: checklist.created_by,
      id: checklist.id,
      is_archived: true,
      position: checklist.position,
      title: checklist.title,
      is_priority: is_priority,
      is_Activity_archived: is_archived,
    };
    updateChecklistAction(updateChecklist, (_res) => {
      this.props.updateFetch();
    });
  };

  onCopyChecklist = () => {
    copyChecklist({ copy_object: "checklist", object_id: this.props.checklist.id }, _res => {
      // TODO document why this arrow function is empty
    })
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      showOptionImageContainer: false,
    });
  };

  moveCursorAtEnd(e) {
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  render() {
    const {
      checklist,
      searchText,
      selectedOption,
      users,
      createChecklistItemAction,
      activityDetails,
      is_archived,
      isSuperAdmin,
      updateChecklistItemAction,
      // index,
      onRearrangeClick,
      onMoveOfItemToAnotherList,
    } = this.props;

    const {
      hideList,
      showOptionImageContainer,
      showAddItemTextBox,
      isChecklistEdit,
      showCompleted,
      // checklistItemState
    } = this.state;

    const isUserPartOfCard =
      activityDetails &&
      activityDetails.user_id.includes(parseInt(localStorage.getItem("id")));

    const hideListComp = hideList

    const showTextBox = checklist.recent === true ? true : showAddItemTextBox;

    const notArchievedItems =
      checklist.checklist_items &&
      checklist.checklist_items.filter(
        (checklist_item) =>
          checklist_item.is_archived === false || checklist_item.showUndo
      );

    const sortchecklist_items =
      !hideListComp && checklist.checklist_items
        ? _.orderBy(notArchievedItems, "position")
        : searchText && selectedOption !== "All"
        ? _.orderBy(
            notArchievedItems
              .filter((item) => item?.social_data?.bookmarked_by_logged_in_user)
              .filter((item) =>
                item.title.toLocaleLowerCase().includes(searchText)
              ),
            "position"
          )
        : searchText
        ? _.orderBy(
            notArchievedItems.filter((item) =>
              item.title.toLocaleLowerCase().includes(searchText)
            ),
            "position"
          )
        : selectedOption !== "All" &&
          _.orderBy(
            notArchievedItems.filter(
              (item) => item?.social_data?.bookmarked_by_logged_in_user
            ),
            "position"
          );

    // const checklistItemRendered =
    //   sortchecklist_items &&
    //   sortchecklist_items.map((checklistItem, index) => {
    //     return (

    //     );
    //   });
    const completedItems =
      notArchievedItems &&
      notArchievedItems.filter(
        (checklist_item) => checklist_item.is_completed === true
      );
    const completedPercentage =
      completedItems && completedItems.length > 0
        ? (completedItems.length / notArchievedItems.length) * 100
        : 0;

    return (
      <div className="checklists" id={`checklist-${checklist.id}`}>
        <div
          className={`checklist-title-container ${
            isChecklistEdit ? "checklist-title-container-edit" : ""
          }`}
        >
          {!isChecklistEdit && (
            <div
              className="checklist-title-container-child"
              onClick={() => this.onHideListClick(hideListComp)}
            >
              {!hideListComp && (
                <div className="checklist-arrow-container">
                  <Tooltip title="Close">
                    <img src={triangleSVG} alt="close" />
                  </Tooltip>
                </div>
              )}
              {hideListComp && (
                <div className="checklist-arrow-container">
                  <Tooltip title="Open">
                    <img src={reverseTriangleSVG} alt="open" />
                  </Tooltip>
                </div>
              )}
              <div className="title-description" role="button" tabIndex="0">
                {this.state.editChecklistName}
                <span className="check-count">
                  {" "}
                  {checklist.checklist_items
                    ? `(${completedItems.length}/${notArchievedItems.length})`
                    : "(0/0)"}
                </span>
              </div>
            </div>
          )}
          {isChecklistEdit && (
            <OutsideClickHandler
              onOutsideClick={this.onCancelEditChecklistTitle}
            >
              <div className="checklist-title-container-edit">
                <div className="checklist-title-container-edit-inner">
                  <textarea
                    className="checklist-title-input"
                    placeholder="Add title"
                    id="{{'title-input'+$index}}"
                    value={this.state.editChecklistName}
                    onChange={this.onChecklistTitleEdit}
                    onKeyPress={this.checkForEnter}
                    autoFocus
                    onFocus={this.moveCursorAtEnd}
                  ></textarea>
                  <img
                    src={closeNewSVG}
                    alt="Smiley face"
                    className="clearEdit-title"
                    height="10"
                    width="10"
                    role="button"
                    tabIndex="0"
                    aria-hidden="false"
                    onClick={this.onCancelEditChecklistTitle}
                  />
                </div>
                <button
                  className="save-button"
                  onClick={this.onChecklistTitleSave}
                  disabled={
                    this.state.editChecklistName.length > 0 ? false : true
                  }
                >
                  Save
                </button>
              </div>
            </OutsideClickHandler>
          )}

          {(isSuperAdmin || isUserPartOfCard) && (
            <div
              className={
                isChecklistEdit
                  ? "checklist-title-edit-container isEdit"
                  : "checklist-title-edit-container"
              }
            >
              <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
                <div
                  className="option-img-container"
                  onClick={this.showOptionImageContainer}
                >
                  {" "}
                  <img
                    role="button"
                    className="checklist-three-dots"
                    src={activityDotsSVG}
                    alt="more-options"
                  />
                </div>
                {showOptionImageContainer && (
                  <div className="title-edit-options-container">
                    <div
                      className="title-edit-options"
                      onClick={onRearrangeClick}
                    >
                      Rearrange
                    </div>
                    {showCompleted && (
                      <div
                        className="title-edit-options"
                        onClick={() => this.onHideCompleted(hideListComp)}
                      >
                        Hide completed
                      </div>
                    )}
                    {!showCompleted && (
                      <div
                        className="title-edit-options"
                        onClick={() => this.onShowCompleted(hideListComp)}
                      >
                        Show completed
                      </div>
                    )}

                    <div
                      className="title-edit-options"
                      onClick={this.onEditChecklistTitle}
                    >
                      Rename
                    </div>
                    <div
                      className="title-edit-options"
                      onClick={this.onIsArchieveClick}
                    >
                      Archive
                    </div>
                    <div
                      className="title-edit-options"
                      onClick={this.onCopyChecklist}
                    >
                      Copy Checklist
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          )}
        </div>

        <div className="checklist-progress-bar">
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={completedPercentage}
            className="checklistProgressBar"
          />
        </div>
        <div className="completed-percentage-text">
          <span>{`${parseInt(completedPercentage)}%`}</span>
        </div>

        {(!hideListComp || searchText || selectedOption !== "All") && (
          <div
            className={`checklistitem-container ${
              sortchecklist_items.length === 0 ? "with-no-items" : ""
            }`}
          >
            {" "}
            <ChecklistItemRearrangeMain
              showCompleted={showCompleted}
              activityDetails={activityDetails}
              isUserPartOfCard={isUserPartOfCard}
              isSuperAdmin={isSuperAdmin}
              toHideAddItemInput={this.toHideAddItemInput}
              checklistItemId={this.props.checklistItemId}
              checklistItems={sortchecklist_items}
              users={users}
              updateChecklistItemAction={updateChecklistItemAction}
              clickedChecklistId={this.props.clickedChecklistId}
              onchecklistUpdated={this.onchecklistUpdated}
              onMoveOfItemToAnotherList={onMoveOfItemToAnotherList}
              checklistId={this.props.checklist.id}
              setChecklist={this.props.setChecklist}
              checklistsArray={this.props.checklistsArray}
            ></ChecklistItemRearrangeMain>
          </div>
        )}
        {!hideListComp && (isSuperAdmin || isUserPartOfCard) && (
          <>
            <OutsideClickHandler onOutsideClick={() => this.toHideAddItemInput()}>
              {(!showTextBox || !showAddItemTextBox) &&
                !searchText &&
                selectedOption === "All" && (
                  <div
                    className={`add-checklistitems-container ${
                      sortchecklist_items.length === 0 ? "with-no-items" : ""
                    }`}
                  >
                    <div
                      className="additem-wrap ng-scope"
                      role="button"
                      tabIndex="0"
                    >
                      <span
                        className="add-item"
                        onClick={this.onAddItemButtonClick}
                      >
                        Add item
                      </span>
                    </div>
                  </div>
                )}

              {showTextBox && showAddItemTextBox && (
                <ChecklistItemInput
                  checklist={checklist}
                  setEditfalse={this.toHideAddItemInput}
                  users={users}
                  className="checklist-textarea-input"
                  activityDetails={activityDetails}
                  is_archived={is_archived}
                  createChecklistItemAction={createChecklistItemAction}
                  toHideAddItemInput={this.toHideAddItemInput}
                ></ChecklistItemInput>
              )}
            </OutsideClickHandler>
          </>
        )}
      </div>
    );
  }
}
