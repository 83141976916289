import React, { Component } from "react";
import searchIcon from "../../styles/img/svg/settings-search-icon1.svg";
import closeIcon from "../../styles/img/svg/search-notification-close.svg";

class NotificationSearch extends Component {
  state = {
    searchText: ""
  };

  onSearchInitiated = event => {
    const { searchNotification } = this.props;
    let searchtext = (event.target && event.target.value) || null;
    this.setState({ searchText: searchtext });
    searchNotification(searchtext);
  };
  onCloseSearch = () => {
    this.setState({ searchText: "" }, () => this.props.onCloseSearch());
  };

  render() {
    
    return (
      <div className="notification_search_holder">
        <img src={searchIcon} alt="search-icon" />

        <input
          value={this.state.searchText}
          onInput={() => {
            // TODO document why this arrow function is empty
          
            
          }}
          onChange={this.onSearchInitiated}
          className="notification-search-input"
          type="text"
          placeholder="Search notifications"
          autoFocus
        />
        <div className="notification-search-close">
          <img src={closeIcon} onClick={this.onCloseSearch} alt="close-icon" />
        </div>
      </div>
    );
  }
}
// Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2 Test for Notification Check2
export default NotificationSearch;
