import { dashboardConstants } from "../../constants/dashboardConstants";
import {
  getEffortOverview,
  getIndividualEffort,
  acceptDeclineProjReq,
  getEffortOverviewPersonal,
  getProjectAccessReqPersonal
} from "../../services/dashboardapi";

function updateLoaderState({ action, state }) {
  return {
    type: action,
    payload: {
      ...state,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateEffortOverview(effortOverview) {
  return {
    type: dashboardConstants.getEffortOverview,
    payload: {
      data: effortOverview,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateEffortOverviewPersonal(effortOverview) {
  return {
    type: dashboardConstants.getEffortOverviewPersonal,
    payload: {
      data: effortOverview,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateIndividualEffort(individualEffort) {
  return {
    type: dashboardConstants.getIndividualEffort,
    payload: {
      list: individualEffort,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateProjectAccessReqTeam(projectAccessReqTeam) {
  return {
    type: dashboardConstants.getProjectAccessTeam,
    payload: {
      list: projectAccessReqTeam,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateProjectAccessReqPersonal(projectAccessReqPersonal) {
  return {
    type: dashboardConstants.getProjectAccessPersonal,
    payload: {
      list: projectAccessReqPersonal,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateMissedTimeSheets(missedTimeSheets) {
  return {
    type: dashboardConstants.getMissedTimeSheets,
    payload: {
      list: missedTimeSheets,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateProjectStatus(projectStatus) {
  return {
    type: dashboardConstants.getProjectStatus,
    payload: {
      list: projectStatus,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function updateLeaderBoard(leaderBoard) {
  return {
    type: dashboardConstants.getLeaderBoard,
    payload: {
      list: leaderBoard,
      key: new Date().getTime().toLocaleString()
    }
  };
}

function removeProjectAccessReqTeam(project) {
  return {
    type: dashboardConstants.removeProjectAccessTeam,
    payload: project
  };
}

function clearDashboardReducer() {
  return {
    type: dashboardConstants.clearDashboardReducer,
    payload: {
      key: new Date().getTime().toLocaleString()
    }
  };
}

export const getEffortOverviewAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getEffortOverviewLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getEffortOverview({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getEffortOverviewLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateEffortOverview(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getEffortOverviewLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getIndividualEffortAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getIndividualEffortLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getIndividualEffort({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateIndividualEffort(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getProjectAccessReqTeamAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getProjectAccessTeamLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getIndividualEffort({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getProjectAccessTeamLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateProjectAccessReqTeam(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getProjectAccessTeamLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateProjectAccessReqTeamAction = (
  { auth_token, body },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.updateProjectAccessTeamLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  acceptDeclineProjReq({ auth_token, body, method: "PUT" }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.updateProjectAccessTeamLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(removeProjectAccessReqTeam(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.updateProjectAccessTeamLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getMissedTimeSheetsAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getMissedTimeSheetsLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getIndividualEffort({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getMissedTimeSheetsLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateMissedTimeSheets(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getMissedTimeSheetsLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getEffortOverviewPersonalAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getEffortOverviewPersonalLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getEffortOverviewPersonal({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getEffortOverviewPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateEffortOverviewPersonal(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getEffortOverviewPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getProjectAccessReqPersonalAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getProjectAccessPersonalLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getProjectAccessReqPersonal({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getProjectAccessPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateProjectAccessReqPersonal(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getProjectAccessPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const updateProjectAccessReqPersonalAction = (
  { auth_token, body, method },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.updateProjectAccessPersonalLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  acceptDeclineProjReq({ auth_token, body, method }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.updateProjectAccessPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.updateProjectAccessPersonalLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getProjectStatusAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getIndividualEffortLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getIndividualEffort({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateProjectStatus(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const getLeaderBoardAction = (
  { auth_token, queryParam },
  callback
) => dispatch => {
  dispatch(
    updateLoaderState({
      action: dashboardConstants.getIndividualEffortLoader,
      state: {
        fetching: true,
        fetched: false,
        error: false
      }
    })
  );
  getProjectAccessReqPersonal({ auth_token, queryParam }, response => {
    if (response && !response.error && !response.stdErr) {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: false
          }
        })
      );
      dispatch(updateLeaderBoard(response));
    } else {
      dispatch(
        updateLoaderState({
          action: dashboardConstants.getIndividualEffortLoader,
          state: {
            fetching: false,
            fetched: true,
            error: true
          }
        })
      );
    }
    callback && callback(response);
  });
};

export const clearDashboardReducerAction = () => dispatch => {
  dispatch(clearDashboardReducer());
};
