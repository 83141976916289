import { workloadConstant } from "../../constants/workloadConstant";
import _ from "lodash";

const initialState = {
  workloadActivitiesLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  //   addChecklistLoaderState: {
  //     fetching: false,
  //     fetched: false,
  //     error: false,
  //     key: null
  //   },
  //   updateChecklistLoaderState: {
  //     fetching: false,
  //     fetched: false,
  //     error: false,
  //     key: null
  //   },
  //   updateChecklistItemLoaderState: {
  //     fetching: false,
  //     fetched: false,
  //     error: false,
  //     key: null
  //   },
  listOfBacklogActivities: {
    list: [],
    key: null
  },
  listOfActivities: {
    list: [],
    key: null
  },
  listOfBacklogActivites:[]
};

export default function workloadReducer(state = initialState, action) {
  switch (action.type) {
    case workloadConstant.updateGetLoaderState:
      return {
        ...state,
        workloadActivitiesLoaderState: {
          ...action.payload
        }
      };
    case workloadConstant.backlogActivityFetched:
      return {
        ...state,
        listOfBacklogActivities: {
          list: [...state.listOfBacklogActivities.list, ...action.payload.list],
          key: action.payload.key
        }
      };
    case workloadConstant.addBacklogActivity:
      return {
        ...state,
        listOfBacklogActivities: {
          list: [...state.listOfBacklogActivities.list, action.payload.activity],
          key: action.payload.key
        }
      }
    case workloadConstant.fetchActivity:
      return {
        ...state,
        listOfActivities: {
          list: [...state.listOfActivities.list, action.payload.activity],
          key: action.payload.key
        }
      };
    case workloadConstant.clearWorkloadReducer:
      return {
        ...state,
        listOfBacklogActivities: {
          list: action.payload.list,
          key: action.payload.key
        },
        listOfBacklogActivites: action.payload.list,
        listOfActivities: {
          list: action.payload.list,
          key: action.payload.key
        }
      };
    case workloadConstant.updateActivity:
      return {
        ...state,
        listOfActivities: {
          list: state.listOfActivities.list.map(user =>
            user.id === action.payload.user_id
              ? {
                  ...user,
                  activities: user.activities.map(activity =>
                    activity.id === action.payload.activity.id
                      ? { ...activity, ...action.payload.activity }
                      : activity
                  )
                }
              : user
          ),
          key: action.payload.key
        }
      };

    case workloadConstant.addActivity:
      // console.log("action.payload.id", action.payload.id)
      return {
        ...state,
        listOfActivities: {
          list: state.listOfActivities.list.map(user => {
            // console.log("action.payload.id", action.payload.id, "current id",user.id)
            return user.id === action.payload.id
              ? {
                  ...user,
                  activities:
                    user.activities.filter(
                      activity => activity.id === action.payload.activity.id
                    ).length === 0
                      ? [...user.activities, action.payload.activity]
                      : [...user.activities]
                }
              : user;
          })
        }
      };

    case workloadConstant.removeActivity:
      return {
        ...state,
        listOfActivities: {
          list: state.listOfActivities.list.map(user => {
            return user.id === action.payload.id
              ? {
                  ...user,
                  activities: user.activities.filter(
                    activity => activity.id !== action.payload.activity.id
                  )
                }
              : user;
          })
        }
      };

    case workloadConstant.updateWorkFlow:
      return {
        ...state,
        listOfActivities: {
          list: state.listOfActivities.list.map(user => {
            return user.id === action.payload.user_id
              ? {
                  ...user,
                  activities: user.activities.map(activity =>
                    activity.id === action.payload.activity.id
                      ? {
                          ...activity,
                          workflow: action.payload.activity.workflow
                        }
                      : activity
                  )
                }
              : user;
          })
        }
      };

    case workloadConstant.unarchiveActivity:
      return {
        ...state,
        listOfActivities: {
          list: state.listOfActivities.list.map(user => {
            return user.id === action.payload.user_id
              ? {
                  ...user,
                  activities: user.activities.filter(
                    activity => activity.id !== action.payload.activity.id
                  )
                }
              : user;
          })
        }
      };

      case  workloadConstant.BACK_LOG_ACTIVITIES:  
      let groupedData=_.chain(action.payload.listOfactivity)
      .groupBy("project.id")
      .map((value, key) => {
          let valueSort= value.sort((obj1,obj2)=>obj1.title.localeCompare(obj2.title));
               return { projectname: valueSort[0].project.name, activityList: valueSort,id:key }
         }
       )
      .value();
      groupedData= groupedData.sort((obj1,obj2)=>obj1.projectname.localeCompare(obj2.projectname));
      return {
       ...state,
       listOfBacklogActivites: groupedData
     }
    case workloadConstant.ADD_BACK_LOG_ACTIVITIES:
      
      let addlistOfBacklogActivites = _.cloneDeep(state.listOfBacklogActivites);
      let project = addlistOfBacklogActivites.some(project => parseInt(project.id) === parseInt(action.payload.projectId));
      if (project) {
        addlistOfBacklogActivites.forEach(projectData => {
          if (parseInt(projectData.id) === parseInt(action.payload.projectId)) {
            let activityList=projectData.activityList.filter(activitydata=>parseInt(activitydata.id)!==parseInt(action.payload.activity.id));
            activityList.push(action.payload.activity);
            let valueSort = activityList.sort((obj1,obj2)=>obj1.title.localeCompare(obj2.title));
            projectData.activityList = valueSort;
          }
        })

      } else {
        let valueSort = [action.payload.activity];
        addlistOfBacklogActivites.push({ projectname: action.payload.activity.project.name, activityList: valueSort, id: action.payload.projectId });
        addlistOfBacklogActivites =addlistOfBacklogActivites.sort((obj1,obj2)=>obj1.projectname.localeCompare(obj2.projectname));
      }
      return {
        ...state,
        listOfBacklogActivites: addlistOfBacklogActivites
      }     
    case workloadConstant.REMOVE_BACK_LOG_ACTIVITIES:
      let listOfBacklogActivitesRemove = _.cloneDeep(state.listOfBacklogActivites);
      let splicelag = false
      listOfBacklogActivitesRemove.forEach(projectData => {
        if (parseInt(projectData.id) === parseInt(action.payload.projectId)) {
          let activityList = projectData.activityList.filter(activityData => parseInt(activityData.id) !== parseInt(action.payload.activityId))
          let valueSort = activityList.sort((obj1,obj2)=>obj1.title.localeCompare(obj2.title));
          projectData.activityList = valueSort;
          if (activityList.length === 0) {
            splicelag = true;
          }
        }
      })
      if (splicelag) {
        let projectIndex=listOfBacklogActivitesRemove.findIndex(projectData => parseInt(projectData.id) === parseInt(action.payload.projectId));
        listOfBacklogActivitesRemove.splice(projectIndex,1);
        listOfBacklogActivitesRemove = listOfBacklogActivitesRemove.sort((obj1,obj2)=>obj1.projectname.localeCompare(obj2.projectname));
      }
      return {
        ...state,
        listOfBacklogActivites: listOfBacklogActivitesRemove
      }
      case workloadConstant.UPDATE_PRIORITY_NONPRIORITY:
          let listOfActivity = _.cloneDeep(state.listOfActivities);
          listOfActivity.list.forEach(activities=>{
            activities.activities.forEach(activity=>{
              if(activity.id===action.payload.activityData.id){
                activity.data=action.payload.activityData.data;
              }
          })
        })
            
          return {
            ...state,
            listOfActivities: listOfActivity
          }
    default:
      return {
        ...state
      };
  }
}
