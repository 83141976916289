/**
 * Author: Madhusudhan Seetharamiah
 * Description: custom function to fetch and return the response if status is success and return custom response if status is fail
 * @param {*} url
 * @param {*} preRequests
 * @param {*} actionCallback
 */
import { API_URL } from '../constants/globalconst';
// import { logout } from '../helpers/generichelper';
import { getRefreshToken } from '../services/refreshTokenapi';


const parseJSONData = (url, response) => {
  return response
    .text()
    .then(text => {
      return text ? JSON.parse(text) : {};
    })
    .catch(error => {
      return { data: [], stdErr: `Request '${url}' failed with error '${error.message}'.` };
    });
};

// const unauthorized = response => {
//   return (
//     response.status === 401 &&
//     response.text().then(errorText => {
//       let errorMessage = errorText || 'Logged out due to unknown error!';
//       logout();
//       return false;
//     })
//   );
// };

const serverNotReachable = response => {
  let obj = { data: [], stdErr: 'Unable to perform operation. Please contact your administrator.' };
  if (response.status === 500) {
    return response
      .clone()
      .text()
      .then(txt => {
        return { ...obj, stdErr: txt };
      });
  }
  if (response.status > 500) {
    return obj;
  }
};

export const dataFetch = (url, request = { method: 'GET' }) => {
  return fetch(encodeURI(url), request).then(response => {
    return response;
  });
};

export const secureFetch = (url, request = { method: 'GET' }, noJson) => {
  return new Promise(function (resolve, reject) {
    dataFetch(url, request)
      .then(response => {
        // if (unauthorized(response)) {
        //   return;
        // }
        if(response.status === 401) {
          response.json().then(err => {
            if(err?.title === "401 Token Expired") {
              getRefreshToken(res => {
                if(res?.token) {
                  let newHeader = {headers: new Headers({
                    "Content-Type": "application/json",
                    Origin: API_URL,
                    "X-ACCESS-TOKEN": res.token
                  })}
                  let newRequest = { ...request, ...newHeader }
                  secureFetch(url, newRequest).then(someData => resolve(someData));
                  // return;
                }
              })
            }
            if(err?.title === "password changed") {
              window.alertMessage();
              // resolve(noJson ? response : parseJSONData(url, response));
            }
            if(err?.title === "Access denied for the workspace") {
              window.alertWorkspaceMsg();
            }
          })
        } else if (serverNotReachable(response)) {
          resolve(serverNotReachable(response));
          return;
        } else {
          resolve(noJson ? response : parseJSONData(url, response));
        }
      })
      .catch(error => {
        resolve({ data: [], stdErr: error.message });
      });
  });
};

