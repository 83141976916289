import { workspaceConstant } from "../../constants/workspaceconstants";
// import { sort, ascend, prop, findIndex, propEq, move } from "ramda";
import _ from "lodash";
import { commonContants } from "../../helpers/commonconstants";

const initialState = {
  customerLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateCustomerLoaderState: {
    fetching: false,
    fetched: false,
    error: {
      bool: false,
      value: ""
    },
    key: null
  },
  suggestWorkspaceLoaderState: {
    fetching: false,
    fetched: false,
    error: {
      bool: false,
      value: ""
    },
    key: null
  },

  createWorkpsaceLoaderState: {
    fetching: false,
    fetched: false,
    error: {
      bool: false,
      value: ""
    },
    key: null
  },
  customerLoaderStateForDroDown: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  pendingCustomerLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  customerDetailsLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  // now
  updatePrioritizedActivitiesLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  updateDeprioritizedActivitiesLoaderState: {
    fetching: false,
    fetched: false,
    error: false,
    key: null
  },
  listOfCustomer: {
    list: [],
    key: null
  },
  listOfPendingCustomer: {
    list: [],
    key: null
  },
  listOfPendingCustomerInvites: {
    list: [],
    key: null
  },
  suggestWorkspaceList: {
    listOfSuggetions: [],
    key: null
  },
  selectedCustomerDetails: {},
  listOfCurrencies: [],
  owners: [],
  members: []
  // now
  // deprioritizedActivities: {
  //   list: [],
  //   key: null
  // }
};

const sortAndMoveCurrentUserItemToFirst = list => {
  if (list) {
    const sortedList = _.sortBy(list, (w) => {
      if(w.display_name) return w.display_name.toLowerCase();
      if(w.customer_display_name) return w.customer_display_name.toLowerCase();
      if(w.customer_identifier) return w.customer_identifier.toLowerCase();
    })
    // let sortedList = sort(ascend(prop("name")), list.slice());
    // const myUserId =
    //   localStorage.getItem("id") && parseInt(localStorage.getItem("id"));
    // let foundMyWorspaceIndex = findIndex(propEq("super_admin_id", myUserId))(
    //   sortedList
    // );
    // if (foundMyWorspaceIndex > -1) {
    //   localStorage.setItem("haveMyWorkspace", true);
    //   var toBeMovedElement = sortedList[foundMyWorspaceIndex];
    //   sortedList.splice(foundMyWorspaceIndex, 1);
    //   sortedList.splice(0, 0, toBeMovedElement);
    // }

    return sortedList;
  }
  return list;
};

export default function wrorkSpaceReducer(state = initialState, action) {
  switch (action.type) {
    case workspaceConstant.customerLoaderState:
      return {
        ...state,
        customerLoaderState: action.payload
      };

    case workspaceConstant.updateCustomerDetailsLoaderState:
      return {
        ...state,
        updateCustomerLoaderState: action.payload
      };

    case workspaceConstant.customerLoaderStateForDroDown:
      return {
        ...state,
        listOfCustomer: initialState.listOfCustomer,
        customerLoaderStateForDroDown: action.payload
      };

    case workspaceConstant.pendingCustomerLoaderState:
      return {
        ...state,
        pendingCustomerLoaderState: action.payload
      };

    case workspaceConstant.customerDetailsLoaderState:
      return {
        ...state,
        customerDetailsLoaderState: action.payload
      };

    case workspaceConstant.createWorkspaceLoaderState:
      return {
        ...state,
        createWorkpsaceLoaderState: action.payload
      };

    case workspaceConstant.suggestWorkspaceLoaderState:
      return {
        ...state,
        suggestWorkspaceLoaderState: action.payload
      };
    case workspaceConstant.prioritizedActivityLoaderState:
      return {
        ...state,
        updatePrioritizedActivitiesLoaderState: action.payload
      };
    case workspaceConstant.updateDeprioritizedActivitiesLoaderState:
      return {
        ...state,
        updateDeprioritizedActivitiesLoaderState: action.payload
      };
    case workspaceConstant.suggestWorkspace:
      return {
        ...state,
        suggestWorkspaceList: action.payload
      };

    case workspaceConstant.selectedCustomerDetails:
      //console.log('in reducer');
      return {
        ...state,
        selectedCustomerDetails: action.payload
      };

    case workspaceConstant.updateCustomerList:
      return {
        ...state,
        listOfCustomer: {
          list: sortAndMoveCurrentUserItemToFirst(action.payload.list),
          key: action.payload.key
        }
      };

    case workspaceConstant.updatePendingCustomerList:
      return {
        ...state,
        listOfPendingCustomer: {
          list: sortAndMoveCurrentUserItemToFirst(action.payload.list),
          key: action.payload.key
        }
      };
    case workspaceConstant.updatePendingCustomerInviteList:
      return {
        ...state,
        listOfPendingCustomerInvites: {
          list: action.payload.list,
          key: action.payload.key
        }
      };
    case workspaceConstant.createOwnWorkspace:
      return {
        ...state,
        updatingWorkspace: action.payload
      };

    case workspaceConstant.updateOneCustomerUsersList: 
      return {
        ...state,
        listOfCustomer: {
          ...state.listOfCustomer,
          list: state.listOfCustomer.list.map(ls => {
            return ls.id === action.payload.id
              ? {
                  ...ls,
                  users: action.payload.users
                }
              : ls;
          })
        },
        selectedCustomerDetails: state.selectedCustomerDetails.selectedWorkspace ?
          {...state.selectedCustomerDetails,
          selectedWorkspace: state.selectedCustomerDetails.selectedWorkspace.id === action.payload.id ? {
            ...state.selectedCustomerDetails.selectedWorkspace,
            users: action.payload.users
          } : { ...state.selectedCustomerDetails.selectedWorkspace}
          } : { ...state.selectedCustomerDetails }
      }

    case workspaceConstant.addRemoveUserFromWorkspace:
      return {
        ...state,
        listOfCustomer: {
          ...state.listOfCustomer,
          list: state.listOfCustomer.list.map(ls => {
            return ls.id === action.payload.id
              ? {
                  ...ls,
                  users: action.payload.users
                }
              : ls;
          })
        },
        selectedCustomerDetails: {
          ...state.selectedCustomerDetails,
          selectedWorkspace: state.selectedCustomerDetails.hasOwnProperty("selectedWorkspace") ? {
            ...state.selectedCustomerDetails.selectedWorkspace,
            users: action.payload.users
          } : { ...state.listOfCustomer.list.filter(ls => ls.id === action.payload.id)[0],
            users: action.payload.users
           }
        }
      }

    case workspaceConstant.updateEachCustomerUser:
      //console.log(' I  am in reducer')
      return {
        ...state,
        listOfCustomer: {
          ...state.listOfCustomer,
          list: state.listOfCustomer.list.map(ls => {
            return ls.id === action.payload.id
              ? {
                  ...ls,
                  users: action.payload.users,
                  data: action.payload.data,
                  display_name: action.payload.display_name,
                  img: action.payload.img,
                  img_name: action.payload.img_name,
                  super_admin_id: action.payload.super_admin_id
                }
              : ls;
          })
        },
        selectedCustomerDetails: {
          ...state.selectedCustomerDetails,
          selectedWorkspace: state.selectedCustomerDetails.hasOwnProperty("selectedWorkspace") ? {
            ...state.selectedCustomerDetails.selectedWorkspace,
            users: action.payload.users,
            data: action.payload.data,
            display_name: action.payload.display_name,
            img: action.payload.img,
            img_name: action.payload.img_name,
            super_admin_id: action.payload.super_admin_id
          } : { ...state.listOfCustomer.list.filter(ls => ls.id === action.payload.id)[0],
            users: action.payload.users,
            data: action.payload.data,
            display_name: action.payload.display_name,
            img: action.payload.img,
            img_name: action.payload.img_name,
            super_admin_id: action.payload.super_admin_id
           }
        }
      };

    case workspaceConstant.updateCustomerForUser:
      return {
        ...state,
        listOfCustomer: {
          ...state.listOfCustomer,
          list: state.listOfCustomer.list.map(ls => {
            return ls.id === action.payload.id
              ? {
                  ...ls,
                  users: action.payload.users,
                  super_admin_id: action.payload.super_admin_id
                }
              : ls;
          })
        },
        selectedCustomerDetails: {
          ...state.selectedCustomerDetails,
          selectedWorkspace: state.selectedCustomerDetails.hasOwnProperty("selectedWorkspace") ? {
            ...state.selectedCustomerDetails.selectedWorkspace,
            users: action.payload.users,
            super_admin_id: action.payload.super_admin_id
          } : { ...state.listOfCustomer.list.filter(ls => ls.id === action.payload.id)[0],
            users: action.payload.users,
            super_admin_id: action.payload.super_admin_id
           }
        }
      }

      case workspaceConstant.listOfCurrencies:
        return {
          ...state,
          listOfCurrencies: action.payload
        }

    case commonContants.getOwners:
      return {
        ...state,
        owners: action.payload.owners
      }

    case commonContants.getMembers:
      return {
        ...state,
        members: action.payload.members
      }

    default:
      return {
        ...state
      };
  }
}
