import React, { useState, useEffect } from "react";
import moment from "moment";
import { Rnd } from "react-rnd";

import starActive from "../../styles/img/svg/star_active.svg";
import KanbanCardStatus from "../kanban/kanabnCardStatus";
import { workloadConstant } from "../../constants/workloadConstant";
import onTimeIcon from "../../styles/img/svg/duration_act.svg";
import calendarIcon from "../../styles/img/svg/calendar-flow.svg";
import { secondsToHms } from "../../helpers/generichelper";



const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  cursor: "pointer",
  background: "#ffffff",
  padding: "0 10px",
  borderRadius: "2px",
  // zIndex: '50'
};

export default function ActivityBlockWeek({
  activity,
  position,
  length,
  index,
  datesArray,
  selectedUser,
  noOfDays,
  heightIndex,
  updateActivitiesAction,
  scrollHeight
}) {
  const FAKE_GRID_DIV = "fake-grid-div";
  const TRANSPARENT = "transparent";
  const BOX_SHADOW = 'rgba(255, 255, 255, 0.2)';
  const totalTimeElapsed =
    activity.timesheets &&
    activity.timesheets.reduce((acc, el) => {
      return (acc += el.time_taken);
    }, 0);

  const [isDragging, setisDragging] = useState(false);

  const [isResizing, setIsResizing] = useState(false);

  const [showActivity, setShowActivity] = useState(false);

  const userId = parseInt(localStorage.getItem("id"));

  const isPriority =
    activity.data.priority &&
      selectedUser.id === userId && activity.data.priority.filter(user => user.user_id === selectedUser.id).length > 0
      ? true
      : false;
  const delayedActivity = totalTimeElapsed > activity.duration ? true : false;

  const positionInX = length * position.startIndex;

  const selected_user_duration = activity.users_duration && activity.users_duration.length>0 &&  activity.users_duration.filter(user => user.user_id === parseInt(selectedUser.id))[0];

  const activityHours = activity && selected_user_duration
    && Math.floor((selected_user_duration?.total_time || activity.duration) / 3600);
  const activityMins = secondsToHms(selected_user_duration?.total_time || activity.duration).mins.toString() + "m";
  const [width, setwidth] = useState(
    length * (position.lastIndex - position.startIndex + 1) - 10
  );
  const [height, setheight] = useState(noOfDays > 7 ? 20 : 38);
  const [positionX, setPositionX] = useState(positionInX + 5);
  const [positionY, setPositionY] = useState(
    heightIndex > 0 ? heightIndex * 50 + 10 : 10
  );

  useEffect(() => {
    // console.log(position);
    setPositionX(positionInX + 5);
    setPositionY(heightIndex > 0 ? heightIndex * 50 + 10 : 10);
    setwidth(length * (position.lastIndex - position.startIndex + 1) - 10);
    setheight(noOfDays > 7 ? 20 : 38);
  }, [position, noOfDays, activity]);

  const showActivityPopup = (event) => {
    setShowActivity(true);
   
    let tooltip=document.getElementsByClassName("workload-showActivity")[0];
    if(tooltip && tooltip.style){
      if(position.startIndex === 5  || position.startIndex === 6 ){
        tooltip.style.transform=`translate(${positionInX-200}px, ${positionY-130-scrollHeight}px)`;
        tooltip.style.opacity=1;
      }else if(position.startIndex<=4 && position.lastIndex===6){
        tooltip.style.transform=`translate(${positionInX+(width/2)-50}px, ${positionY-130-scrollHeight}px)`;
        tooltip.style.opacity=1;
      }else{
        tooltip.style.transform=`translate(${positionInX+(width/2)}px, ${positionY-130-scrollHeight}px)`;
        tooltip.style.opacity=1;
      }
   
    }
    setShowActivity(true);
  }

  const hideActivityPopup = () => {
    setShowActivity(false);

  }



  return (
    <div onMouseMove={showActivityPopup} onMouseLeave={hideActivityPopup}>
      {showActivity &&
        <div style={{opacity:0}} className="workload-showActivity" >
          <div className="workload-act-header">
            <div className="workload-act-wrap">
              <span className="workload-act-title">
                {activity.title}
              </span>
              {isPriority && (
                <span className="workload-activity-star-icon">
                  <img src={starActive}  alt="star-icon"></img>
                </span>
              )}
            </div>
            <div className="workload-proj-title">
              {activity.project && activity.project.name}
            </div>
          </div>
          {/* <div className="workload-act-body">
              <span className="workload-act-body-tag">{activity.tags[0]}</span>
          </div> */}
          <div className="workload-date-time">
            <div className="worload-timer">
              <img src={onTimeIcon} alt="onTime-icon" />
              {" "}
              <span >{selected_user_duration?.continuous ? "Continuous" : `${activityHours}h ${activityMins}`} </span>
            </div>
            <div className="worload-date">
                <img src={calendarIcon} alt="cal-icon" />
                <span className="workload-time">
              {" "}
              {moment(selected_user_duration?.start_date || activity.start_on).format("DD MMM YYYY")} -{" "}
              {selected_user_duration?.continuous ? "Continuous" : moment(selected_user_duration?.end_date || activity.due_on).format("DD MMM YYYY")}
            </span>
            </div>


          </div>
        </div>
      
      }
      <Rnd
        style={style}
        size={{ width: width, height: height }}
        onClick={e => {
          // console.log("onClick called all");
          e.preventDefault();
          if (isDragging || isResizing) {
            // console.log("onClick called");
            e.preventDefault();
            e.stopPropagation();
          }
          setisDragging(false);
          setIsResizing(false);
        }}
        onDrag={(e, d) => {
          setShowActivity(false);
          e.stopImmediatePropagation();
          setisDragging(true);
          for (let i = 0; i <= 6; i++) {
            document.getElementsByClassName(FAKE_GRID_DIV)[i].style.backgroundColor = TRANSPARENT;
          }
          let xValue = Math.floor(d.x / length);
          xValue--;
          for (let i = position.startIndex; i <= position.lastIndex; i++) {
            ++xValue;
            if (xValue <= 6 && document.getElementsByClassName(FAKE_GRID_DIV)[xValue]) {
              document.getElementsByClassName(FAKE_GRID_DIV)[xValue].style.backgroundColor = BOX_SHADOW;
            }
          }
        }}

        onDragStop={(e, d) => {
          // console.log("On Drag Stop called", isDragging);
          if (isDragging) {
            e.stopPropagation();
            e.preventDefault();
            const positionAfterDrag = d.x < 0 ? 1 : d.x;
            // setisDragging(false);
            // const startIndex = Math.floor(positionAfterDrag / length);
            // console.log("Start Index", startIndex);
            const newStartDate = moment(
              datesArray[Math.floor(positionAfterDrag / length)]
            )
              .startOf("day")
              .format();
            // console.log("Start Date", newStartDate);
            const endIndex =
              Math.floor(positionAfterDrag / length) -
              position.startIndex +
              position.lastIndex;
            // console.log("End Index", endIndex);
            let endDate = moment(datesArray[6])
              .endOf("day")
              .format();
            if (endIndex < datesArray.length) {
              endDate = moment(datesArray[endIndex])
                .endOf("day")
                .format();
              // console.log("End Date before", endDate);
            }
            if (endIndex > datesArray.length - 1) {
              // console.log("to add", endIndex + 1 - datesArray.length);
              endDate = moment(endDate)
                .add(endIndex + 1 - datesArray.length, "days")
                .endOf("day")
                .format();
              // console.log("End Date after", endDate);
            }
            setPositionX(positionAfterDrag);
            // const selected_user_duration = activity.users_duration.filter(user => user.user_id === parseInt(selecteduserid))[0];
            // let userActivityDetail= activity.users_duration && selectedUser.id && activity.users_duration[selectedUser.id];
           let userchangedDuration= activity.users_duration.map(user => {
             if(user.user_id === parseInt(selectedUser.id)) {
               if(user.continuous)
               return {
                 ...user,
                 start_date: moment(newStartDate).format()
               };
               else
                return {
                  ...user,
                  start_date: moment(newStartDate).format(),
                  end_date: moment(endDate).format()
                };
             }
             return user;
           });

           //Minimum Start and maximum end date calculation
           let startDates = userchangedDuration.map(user => {
            if(user?.start_date) {
              return user.start_date
            }
            return undefined
          }).filter(function( element ) {
            return element !== undefined;
         }).map(date => moment(date));
         let minStartDate = moment.min(startDates).format("YYYY-MM-DD");
    
         let endDates = userchangedDuration.map(user => {
          if(user?.end_date) {
            return user.end_date
          }
          return undefined
        }).filter(function( element ) {
          return element !== undefined;
       }).map(date => moment(date));
       let maxEndDate = moment.max(endDates).format("YYYY-MM-DD");
           
            updateActivitiesAction({
              user_id: selectedUser.id,
              activity_id: activity.id,
              fields: {
                start_on: moment(minStartDate).format(),
                project_id: activity.project_id,
                created_at: activity.created_at,
                data: activity.data,
                user_id: activity.user_id,
                tags: activity.tags,
                duration: activity.duration,
                due_on: activity.continuous_activity ? activity.due_on : moment(maxEndDate).format(),
                title: activity.title,
                users_duration:userchangedDuration,
                continuous_activity: activity.continuous_activity
              }
            });

          }
          e.target.style.cursor = "pointer";
          // setPositionY(d.y);
        }}
        // disableDragging={true}
        position={{ x: positionX, y: positionY }}
        resizeHandleStyles={{
          bottom: { display: "none" },
          top: { display: "none" }
        }}
        resizeGrid={[1, 0]}
        onResizeStart={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsResizing(true);
        }}
        enableResizing={{top:true, right:true, bottom:false, left:true, topRight:false, bottomRight:true, bottomLeft:true, topLeft:false}}
        onResize={(e, dir, ref, delta) => {
          setShowActivity(false);
          for (let i = 0; i <= 6; i++) {
            document.getElementsByClassName(FAKE_GRID_DIV)[i].style.backgroundColor = TRANSPARENT;
          }
          if (dir === "left" || dir === 'bottomLeft' || dir === 'topLeft') {
            const newPositionX = Math.ceil(delta.width / length);
            let startIndex = position.startIndex - newPositionX;
            for (let i = startIndex; i <= position.lastIndex; i++) {
              if (document.getElementsByClassName(FAKE_GRID_DIV)[i]) {
                document.getElementsByClassName(FAKE_GRID_DIV)[i].style.backgroundColor = BOX_SHADOW;
              }
            }
          } else {
            const newLastIndex = Math.floor(
              (Math.ceil(delta.width / length) * length + width + positionX) /
              length
            );
            for (let i = position.startIndex; i <= newLastIndex; i++) {
              if (document.getElementsByClassName(FAKE_GRID_DIV)[i]) {
                document.getElementsByClassName(FAKE_GRID_DIV)[i].style.backgroundColor = BOX_SHADOW;
              }
            }

          }

          // if (dir === "left") {
          // }  
          //console.log('position',e.targetTouches[0].clientX/length )
        }}
        onResizeStop={(e, direction, ref, delta) => {
          e.stopPropagation();
          e.stopImmediatePropagation();
          e.preventDefault();
          if (direction === "left" || direction === 'bottomLeft' || direction === 'topLeft') {
            setPositionX(
              delta.width < 0
                ? Math.ceil(delta.width / length) === 0
                  ? positionX +
                  Math.abs(Math.ceil(delta.width / length) + 1) * length
                  : positionX + Math.abs(Math.ceil(delta.width / length)) * length
                : positionX - Math.ceil(delta.width / length) * length
            );
            setwidth(
              delta.width < 0
                ? Math.ceil(delta.width / length) === 0
                  ? width + Math.ceil(delta.width / length) - 1 * length
                  : width + Math.ceil(delta.width / length) * length
                : width + Math.ceil(delta.width / length) * length
            );
            const newPositionX = Math.ceil(delta.width / length);
            let startIndex = position.startIndex - newPositionX;
            const newStartDate = moment(
              datesArray[startIndex]
            )
              .startOf("day")
              .format();
              // let userActivityDetail= activity.users_duration && selectedUser.id && activity.users_duration[selectedUser.id];
              let userchangedDuration= activity.users_duration.map(user => {
                if(user.user_id === parseInt(selectedUser.id)) {
                  if(user.continuous)
                  return {
                    ...user,
                    start_date: moment(newStartDate).format()
                  };
                  else
                   return {
                     ...user,
                     start_date: moment(newStartDate).format(),
                     total_time: user.time_per_day * (moment(user.end_date).diff(moment(newStartDate), "day") + 1)
                   };
                }
                return user;
              }); 

              //Minimum Start and maximum end date calculation
           let startDates = userchangedDuration.map(user => {
            if(user?.start_date) {
              return user.start_date
            }
            return undefined
          }).filter(function( element ) {
            return element !== undefined;
         }).map(date => moment(date));
         let minStartDate = moment.min(startDates).format("YYYY-MM-DD");

         let totalDuration = userchangedDuration.reduce((total, curr) => {
           if(curr.total_time)
            return total + curr.total_time
           return total
         }, 0);

            updateActivitiesAction({
              user_id: selectedUser.id,
              activity_id: activity.id,
              fields: {
                start_on: moment(minStartDate).format(),
                project_id: activity.project_id,
                created_at: activity.created_at,
                data: activity.data,
                user_id: activity.user_id,
                tags: activity.tags,
                duration: activity.continuous_activity ? 0 : totalDuration,
                due_on: activity.due_on,
                title: activity.title,
                users_duration:userchangedDuration,
                continuous_activity: activity.continuous_activity
              }
            });
          } else {
            const newLastIndex = Math.floor(
              (Math.ceil(delta.width / length) * length + width + positionX) /
              length
            );

            setwidth(Math.ceil(delta.width / length) * length + width);
            const endDate = moment(datesArray[newLastIndex])
              .endOf("day")
              .format();
              // let userActivityDetail= activity.users_duration && selectedUser.id && activity.users_duration[selectedUser.id];
              let userchangedDuration= activity.users_duration.map(user => {
                if(user.user_id === parseInt(selectedUser.id)) {
                  if(user.continuous)
                  return {
                    ...user
                  };
                  else
                   return {
                     ...user,
                     end_date: moment(endDate).format(),
                     total_time: user.time_per_day * (moment(endDate).diff(moment(user.start_date), "day") + 1)
                   };
                }
                return user;
              });

              //Minimum Start and maximum end date calculation
         let endDates = userchangedDuration.map(user => {
          if(user?.end_date) {
            return user.end_date
          }
          return undefined
        }).filter(function( element ) {
          return element !== undefined;
       }).map(date => moment(date));
       let maxEndDate = moment.max(endDates).format("YYYY-MM-DD");

       let totalDuration = userchangedDuration.reduce((total, curr) => {
        if(curr.total_time)
         return total + curr.total_time
        return total
      }, 0);

            updateActivitiesAction({
              user_id: selectedUser.id,
              activity_id: activity.id,
              fields: {
                start_on: activity.start_on,
                project_id: activity.project_id,
                created_at: activity.created_at,
                data: activity.data,
                user_id: activity.user_id,
                tags: activity.tags,
                duration: activity.continuous_activity ? 0 : totalDuration,
                due_on: moment(maxEndDate).format(),
                title: activity.title,
                users_duration:userchangedDuration,
                continuous_activity: activity.continuous_activity
              }
            });
          }
        }}
        className={delayedActivity ? "activity-delayed" : "activity-on-time"}
      >
        <span className="activity-name-workload">
          {/* {activity.title.substring(
          0,
          8 * (position.lastIndex - position.startIndex + 1)
        )} */}
          {activity.title}
        </span>
        <span className="activity-hours-workload">{selected_user_duration?.continuous ? "" : `${activityHours}h`} </span>
        {isPriority && (
          <span className="activity-star-icon">
            <img src={starActive}  alt="star-icon"></img>
          </span>
        )}
        <KanbanCardStatus
          activity={activity}
          source={workloadConstant.fromWorkload}
        ></KanbanCardStatus>
      </Rnd>
    </div>

  );
}
