import React, { Component } from "react";
import "../styles/scss/app.scss";
import gtd_logo from "../styles/img/svg/GTD-mobile-logo.svg";
import app_store_logo from "../styles/img/svg/mobile-app-store.svg";
import playstore_logo from "../styles/img/svg/mobile-google-play-store.svg";
import routesHelper from "../router/routeshelper";

class RedirectComponent extends Component {
  render() {
    var platform = window.navigator.platform;
    var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if(routesHelper.getRestPasswordPath() && iosPlatforms.indexOf(platform) !== -1) return null;
    return (
      <div className="low-res">
        <div className="gtd-mobile-view">
          <div className="gtd-mobile-logo">
            <img src={gtd_logo} alt="gtd-logo" />
          </div>
          <div className="mobile-view-desc">
            <h1>This resolution is currently not supported</h1>
            <p>
              The web app is currently not supported for this resolution. Open
              the link on a web browser on a Laptop or PC.
            </p>
            <p className="download-para">
              Download our mobile app to get work done on the go.
            </p>
          </div>
          <div className="mobile-view-apps-icon">
            <a
              className="mobile-app-store-img-holder apple"
              href={routesHelper.getAppStoreUri()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={app_store_logo} alt="app-store-logo" />
            </a>
            <a
              className="mobile-app-store-img-holder"
              href={routesHelper.getPlayStoreUri()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playstore_logo} alt="playstore-logo" />
            </a>
          </div>
          <div className="mobile-website">
            <a href={routesHelper.getGtdLandingUri()} target="_blank" rel="noopener noreferrer">
              getthingsdone.io
            </a>
          </div>
        </div>
        <div className="high-res"></div>
      </div>
    );
  }
}

export default RedirectComponent;
