import React, { useRef, memo } from "react";
import { DragTypes } from "../../constants/DragTypes";
import { useDrag, useDrop } from "react-dnd";
import ChecklistDNDSVG from "../../styles/img/svg/checklist-dnd.svg";

function ChecklistRearrange({
  checklist,
  index,
  // updateChecklistAction,
  moveCard,
  // totalCount
}) {
  const ref = useRef(null);

  const HoverStyle = {
    borderRadius: "10px",
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.09)",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    padding: "16px 0"
  };

  

  const [, drop] = useDrop({
    accept: DragTypes.CHECKLIST,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // console.log("Indexes", dragIndex, hoverIndex);
      // console.log("dropped");
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      
      moveCard(dragIndex, hoverIndex);

      // onPositionChanged(item.index, hoverIndex, item);
      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;

      // onChecklistItemRearranged(hoverIndex,item.index, item)
    }
    // drop(item, monitor) {
    //   if (!ref.current) {
    //     return;
    //   }

    
  });

  const [{ opacity, isDragging }, drag] = useDrag({
    item: { type: DragTypes.CHECKLIST, ...checklist, index },
    collect: monitor => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging()
      };
    }
  });

  const notArchievedItems =
      checklist.checklist_items &&
      checklist.checklist_items.filter(
        checklist_item => checklist_item.is_archived === false
      );

  drag(drop(ref));
  
  return (
    <div
      className={
        isDragging
          ? "checklists from-rearrange hovering"
          : "checklists from-rearrange"
      }
    >
      <div
        className={
          isDragging
            ? "checklist-title-container rearrange-section hovering"
            : "checklist-title-container rearrange-section"
        }
        ref={ref}
        style={isDragging ? HoverStyle : {}}
      >
        <img
          src={ChecklistDNDSVG}
          alt="Smiley face"
          className="drag_image"
          role="button"
          tabIndex="0"
        />

        <div
          className="title-description ng-binding"
          role="button"
          tabIndex="0"
        >
          {checklist.title}{" "}
          <span className="check-count ng-binding">
            {checklist.checklist_items.length > 0
              ? `(${
                  checklist.checklist_items.filter(
                    checkListItem => checkListItem.is_completed === true
                  ).length
                }/${notArchievedItems.length})`
              : "(0/0)"}
          </span>
        </div>

        {/* <div className="checklist-title-edit-container">
          <div
            className="wrap-delete-arrow"
            ng-class="{'pos-rel':checklist.deleteIndex==$index}"
          ></div>
        </div> */}
      </div>
    </div>
  );
}

export default memo(ChecklistRearrange);