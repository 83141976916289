import React, { Component } from "react";
class NoItem extends Component {

  render() {
    const { closePopup, noItem } = this.props;

    if (noItem) {
      return (
        <div className="no-item-container">
          <div className="innerDiv">
            <div className="divHeader">
              <span className="add-or-invite">Item not available</span>
            </div>

            <div className="message">
              <span>
                The item you’re looking for is not currently available. It has
                either been removed or you don’t have access to it anymore.
              </span>
            </div>
            <div className="right-side">
              <button onClick={closePopup}>OK</button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default NoItem;
