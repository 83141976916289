export const validTitle = (title) => {
    return title && title.length > 0
}

export const validProject = (project_name ) => {
    return project_name && project_name.length > 0 
}

export const validActivityTags = (tag) => {
    return tag && tag.length > 0
}

export const validTime = (time, isCompleted, startDate, duedate) => {
    if(isCompleted) {
        
    }
}

export const validDescription = (desc) => {

}

export const getTotalHrsMins = (days, hrs, mins) => {
    let hrsToCal = hrs || 0;
    let minsToCal = mins || 0;
    let totalSeconds = days * (hrsToCal * 3600 + minsToCal * 60);
    let d = Number(totalSeconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hours = h > 0 ? h : 0;
    var mins = m > 0 ? m : 0;
    var seconds = s > 0 ? s : 0;
    return { hours, mins, seconds }; 
}