import React, { useState, memo } from "react";
import "../../styles/scss/activity.scss";
import "../../styles/scss/workload.scss";
import addClue from "../../styles/img/svg/add-activity-clue-card.svg";
import ActivityTile from "./activitytile";
import DialogWrapper from "../common/dialog";
import { activities } from "../../constants/activitiescontants";
import TimeSheetDescription from "./timesheetdescription";
import TimerCannotStartPopup from "./timerCannotStartPopup";
import Avatar from "react-avatar";
import { Tooltip } from "@material-ui/core";

import orderBy from "lodash/orderBy";
import has from "lodash/has";
import firebase from "../../firebase";
import TimerMachine from "react-timer-machine";
import moment from "moment";

const PriorityActivity = props => {
  const [isPopupEnable, setIsPopupEnable] = useState(false);
  const [showCannotStartPopup, setShowCannotStartPopup] = useState(false);
  const [errorObj, setErrorObj] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState({});

  const renderActivities = () => {
    const {
      prioritiZedActivity,
      activeTab,
      getFilteredResults,
      searchContent,
      unArchivedProjectList,
      archiveOrUnArchiveActivitiesAction,
      archiveOrUnarchiveLoaderState,
      changeWorkFlowAction,
      startTimerAction,
      // stopTimerAction,
      getTimerStatusForWorkspaceAction,
      timerRunningStatus,
      anyTimerStatus,
      handleShowPopup,
      handleClickEditCallback,
      onArchiveItem,
      router,
    } = props;
    let isFilterEnabled = searchContent.context === activities.active;
    let list = isFilterEnabled
      ? getFilteredResults(prioritiZedActivity, searchContent.text)
      : prioritiZedActivity;

    let renderActivities = [];
    orderBy(
      list,
      [
        prioritizedActivity => {
          let dueOn = new Date(prioritizedActivity.due_on);
          return dueOn.getTime();
        },
      ],
      ["asc"]
    ).forEach(activityDetails => {
      renderActivities.push(
        <ActivityTile
          key={activityDetails.id}
          startTimerAction={startTimerAction}
          changeWorkFlowAction={changeWorkFlowAction}
          archiveOrUnArchiveActivitiesAction={
            archiveOrUnArchiveActivitiesAction
          }
          archiveOrUnarchiveLoaderState={archiveOrUnarchiveLoaderState}
          shouldEnablePopup={shouldEnablePopup}
          onShowCannotStartPopup={onShowCannotStartPopup}
          unArchivedProjectList={unArchivedProjectList}
          activeTab={activeTab}
          activityDetails={activityDetails}
          shouldShowIcons={{
            archive: true,
            edit: true,
            priority: true,
            show_play_icon: true,
          }}
          prioritiZedActivity={prioritiZedActivity}
          getTimerStatusForWorkspaceAction={getTimerStatusForWorkspaceAction}
          timerRunningStatus={timerRunningStatus}
          anyTimerStatus={anyTimerStatus}
          handleShowPopup={() => handleShowPopup(activityDetails)}
          handleClickEditCallback={handleClickEditCallback}
          onArchiveItem={onArchiveItem}
          router={router}
        />
      );
    });

    return renderActivities;
  };

  const onShowAddActivityPopup = () => {
    props.handleClickCallback(activities.add.activity.priority);
  };

  const shouldEnablePopup = (boolean, selectedActivityDetails) => {
    setIsPopupEnable(boolean);
    setSelectedActivity(selectedActivityDetails || {});
  };

  const onShowCannotStartPopup = res => {
    setErrorObj(res.error);
    setShowCannotStartPopup(true);
  };

  const onHideCannotStartPopup = () => {
    setShowCannotStartPopup(false);
    setErrorObj(null);
  };

  const onPopupCloseCallBack = () => {
    firebase.analytics().logEvent("GTD_Activity_closed_description");
    setIsPopupEnable(false);
  };

  const onConfirm = () => {
    setIsPopupEnable(false);
  };

  const renderEmptyView = () => {
    const { prioritiZedActivity } = props;
    switch (prioritiZedActivity.length) {
      case 0:
        return (
          <div className="empty-card-wrapper">
            <div className="empty-card-clue 1" onClick={onShowAddActivityPopup}>
              <div className="card-content">
                <div className="add-activity-clue-card">
                  <img src={addClue} alt="add-clue" /> Add Activity
                </div>
              </div>
            </div>
            <div className="empty-card-clue 2" onClick={onShowAddActivityPopup}>
              <div className="card-content">
                <div className="add-activity-clue-card">
                  <img src={addClue} alt="add-clue" /> Add Activity
                </div>
              </div>
            </div>

            <div className="empty-card-clue 3" onClick={onShowAddActivityPopup}>
              <div className="card-content">
                <div className="add-activity-clue-card">
                  <img src={addClue} alt="add-clue" /> Add Activity
                </div>
              </div>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="empty-card-wrapper">
            <div className="empty-card-clue 2" onClick={onShowAddActivityPopup}>
              <div className="card-content">
                <div className="add-activity-clue-card">
                  <img src={addClue} alt="add-clue" /> Add Activity
                </div>
              </div>
            </div>

            <div className="empty-card-clue 3" onClick={onShowAddActivityPopup}>
              <div className="card-content">
                <div className="add-activity-clue-card">
                  <img src={addClue} alt="add-clue" /> Add Activity
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="empty-card-clue 3" onClick={onShowAddActivityPopup}>
            <div className="card-content">
              <div className="add-activity-clue-card">
                <img src={addClue} alt="add-clue" /> Add Activity
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  const renderTimerLogicNew = activityDetails => {
    const { anyTimerStatus } = props;
    const currentUserId = parseInt(localStorage.getItem("id"));
    var timerStartTimeInSeconds = 0;
    let timerShouldBeEnabled = null;

    const timesheet =
      activityDetails &&
      activityDetails.timesheets &&
      activityDetails.timesheets.filter(
        timesheet => timesheet.user_id === currentUserId
      );
    let totalTimeTaken =
      timesheet &&
      timesheet.reduce((totalTime, current) => {
        return (totalTime + current.time_taken);
      }, 0);

    if (
      activityDetails &&
      anyTimerStatus?.any_timer_running &&
      anyTimerStatus?.timesheet?.activity_id === activityDetails.id
    ) {
      timerShouldBeEnabled = false;
      timerStartTimeInSeconds += moment(new Date()).diff(
        anyTimerStatus.timesheet.data.TIME[0].START,
        "seconds"
      );
    } else {
      timerShouldBeEnabled = true;
    }

    return {
      timerStartTimeInSeconds,
      timerShouldBeEnabled,
      totalTimeTaken,
    };
  };

  const {
    searchContent,
    stopTimerAction,
    createTimerAction,
    changeTimerStatus,
    fetchTimesheetAction,
    onWorkspaceNavBarClicked,
  } = props;
  let isFilterEnabled = searchContent.context === activities.active;
  
  const renderTimerLogic = renderTimerLogicNew(selectedActivity);
  let dialogProps = {
    className: "timer-description",
    showPopup: isPopupEnable,
    dialogTitle: () => {
      return (
        <React.Fragment>
          <div className="header-title-inner">
            {!selectedActivity.project.img && (
              <Avatar
                // color={Avatar.getRandomColor("sitebase", [
                //   "#ff9292",
                //   "#7c9af1"
                // ])}
                style={{ width: "100%", height: "100%" }}
                name={selectedActivity.project.name[0]}
              ></Avatar>
            )}
            {selectedActivity.project.img && (
              <div className="client-logo">
                <img src={selectedActivity.project.img} />
              </div>
            )}
            <Tooltip title={selectedActivity.title}>
              <h2>{selectedActivity.title}</h2>
            </Tooltip>{" "}
            <Tooltip title={selectedActivity.project.name}>
              <span>
                {selectedActivity.project && selectedActivity.project.name}
              </span>
            </Tooltip>
          </div>
          <span className="timer-activity">
            <TimerMachine
              timeStart={renderTimerLogic.timerStartTimeInSeconds * 1000}
              started={!renderTimerLogic.timerShouldBeEnabled}
              paused={false}
              countdown={false}
              interval={1000}
              formatTimer={(time, ms) =>
                moment
                  .duration(ms, "milliseconds")
                  .format("HH:mm:ss", { trim: false })
              }
              // onStart={}
            />
          </span>
        </React.Fragment>
      );
    },
    onCloseCallBack: onPopupCloseCallBack,
    onConfirmCallBack: onConfirm,
    shouldButtonEnable: true,
  };
  let dialogPropsCannotStart = {
    className: "dialog_wrapper",
    showPopup: showCannotStartPopup,
    dialogTitle: () =>
      errorObj?.msg === "You are not a part of this activity card"
        ? "Activity not accessible"
        : "Another Activity Running",
    onCloseCallBack: onHideCannotStartPopup,
    shouldButtonEnable: true,
  };
  return (
    <div>
      <div className="priority">
        <div className="priority-class">
          {renderActivities()}
          {!isFilterEnabled && renderEmptyView()}
          {isPopupEnable && (
            <DialogWrapper dialogProps={dialogProps}>
              <TimeSheetDescription
                selectedProject={
                  selectedActivity.project_id || selectedActivity.project.id
                }
                timesheet={selectedActivity.timesheets}
                stopTimerAction={stopTimerAction}
                shouldEnablePopup={shouldEnablePopup}
                createTimerAction={createTimerAction}
                changeTimerStatus={changeTimerStatus}
                fetchTimesheetAction={fetchTimesheetAction}
              />
            </DialogWrapper>
          )}
          {showCannotStartPopup && (
            <DialogWrapper dialogProps={dialogPropsCannotStart}>
              <TimerCannotStartPopup
                onClosePopup={onHideCannotStartPopup}
                errorObj={errorObj}
                onWorkspaceNavBarClicked={onWorkspaceNavBarClicked}
              />
            </DialogWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(PriorityActivity);
