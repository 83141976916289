import React, { Fragment } from "react";
import Scrollbar from "react-scrollbars-custom";
import workspaceArrow from "../../../styles/img/svg/create-workspace-arrow.svg";
import routesHelper from "../../../router/routeshelper";
import { getDefaultClientLogo } from "../../../helpers/generichelper";
import Avatar from "react-avatar";
import { activities } from "../../../constants/activitiescontants";
import firebase from "../../../firebase";
export default function NavbarDrop(props) {
  const {
    listOfCustomer,
    onWorkspaceNavBarClicked,
    selectedWorkspace,
    currentUser
  } = props;

  const workspaceList = listOfCustomer.map((customer) => {
    let indexOfIdDN =
      customer.display_name && customer.display_name.indexOf(customer.id);
    let displayName =
      indexOfIdDN && indexOfIdDN > -1
        ? customer.display_name.substring(0, indexOfIdDN - 1)
        : customer.display_name || "";
    let indexOfIdN = customer.name && customer.name.indexOf(customer.id);
    let name =
      indexOfIdN && indexOfIdN > -1
        ? customer.name.substring(0, indexOfIdN - 1)
        : customer.name || "";
    return (
      <div className="workspaces-list-navBar" key={customer.id}>
        <div
          className={
            selectedWorkspace.id === customer.id
              ? "navBar-workspace-dropdown-header not-dispaly"
              : "navBar-workspace-dropdown-header"
          }
          onClick={
            selectedWorkspace.id !== customer.id
              ? (e) => {
                  onWorkspaceNavBarClicked(e, customer);
                }
              : () => { /* TODO document why this arrow function is empty */ }
          }
        >
          <div className="navBar-workspace-dropdown-header-left">
            {customer.img &&
              customer.img !== "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
              customer.img !== getDefaultClientLogo() && (
                <img src={customer.img} alt="workspace-icon" />
              )}
            {(!customer.img ||
              customer.img === "//s3.amazonaws.com/gtd/gtd-placeholder.png" ||
              customer.img === getDefaultClientLogo()) && (
              <Avatar name={displayName} maxInitials={1}></Avatar>
            )}
          </div>
          <div className="navBar-workspace-dropdown-header-right">
            <p className="navBar-workspace-dropdown-name">{displayName}</p>
            <p className="navBar-workspace-dropdown-identifier"> {name}</p>
          </div>
        </div>
      </div>
    );
  });
  const onProjectSettingsClick = (tabToGo) => {
    const { router } = props;
    router.push({
      pathname: "/settings",
      state: {
        tabToGo: tabToGo
      }
    });
  };

  const onCreateFindWorkspace = () => {
    firebase.analytics().logEvent("GTD_Workspace_Create_Find_Workspace");
    const { router } = props;
    router.push({
      pathname: routesHelper.getFindWorkspacePath(),
      state: {
        prevPath: router.location.pathname,
        from: "nav-bar",
        listOfCustomer,
        currentUser
      }
    });
  };

  const onWorkspaceInvitations = () => {
    firebase.analytics().logEvent("GTD_Workspace_Invitations");
    const { router } = props;
    router.push({
      pathname: routesHelper.getWorkspaceInvitationsPath(),
      state: {
        prevPath: router.location.pathname,
        from: "nav-bar",
        listOfCustomer,
        currentUser
      }
    });
  };

  const isCurrentUserAdmin =
    selectedWorkspace.users &&
    selectedWorkspace.users[currentUser.id] === "SUPERADMIN";

  let indexOfIdDN =
    selectedWorkspace.display_name &&
    selectedWorkspace.display_name.indexOf(selectedWorkspace.id);
  let displayName =
    indexOfIdDN && indexOfIdDN > -1
      ? selectedWorkspace.display_name.substring(0, indexOfIdDN - 1)
      : selectedWorkspace.display_name || localStorage.getItem(activities.customerDisplayName) ||  "";
  let indexOfIdN =
    selectedWorkspace.name &&
    selectedWorkspace.name.indexOf(selectedWorkspace.id);
  let name =
    indexOfIdN && indexOfIdN > -1
      ? selectedWorkspace.name.substring(0, indexOfIdN - 1)
      : selectedWorkspace.name || localStorage.getItem(activities.customerName) || "";
  return (
    <div>
      <div className="navBar-workspace-dropdown">
        <div className="navBar-workspace-dropdown-header">
          <div className="navBar-workspace-dropdown-header-left">
            {selectedWorkspace.img &&
              selectedWorkspace.img !==
                "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
              selectedWorkspace.img !== getDefaultClientLogo() && (
                <img src={selectedWorkspace.img} alt="workspace-icon" />
              )}
            {selectedWorkspace.id && (!selectedWorkspace.img ||
              selectedWorkspace.img ===
                "//s3.amazonaws.com/gtd/gtd-placeholder.png" ||
              selectedWorkspace.img === getDefaultClientLogo()) && (
              <Avatar name={displayName} maxInitials={1}></Avatar>
            )}
            {!selectedWorkspace.id && localStorage.getItem(activities.customerImage) &&
              localStorage.getItem(activities.customerImage) !== "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
              localStorage.getItem(activities.customerImage) !== getDefaultClientLogo() && 
                <img src={localStorage.getItem(activities.customerImage)} alt="workspace-icon" />
            }
            {!selectedWorkspace.id && (!localStorage.getItem(activities.customerImage) ||
                  localStorage.getItem(activities.customerImage) === "//s3.amazonaws.com/gtd/gtd-placeholder.png" ||
                  localStorage.getItem(activities.customerImage) === getDefaultClientLogo()) && 
                  <Avatar
                  // style={{ width: "100%", height: "100%" }}
                  name={displayName}
                  maxInitials={1}
                ></Avatar>
            }

          </div>
          <div className="navBar-workspace-dropdown-header-right">
            <p className="navBar-workspace-dropdown-Name">{displayName} </p>
            <p className="navBar-workspace-dropdown-identifier"> {name}</p>
          </div>
        </div>

        {isCurrentUserAdmin && (
          <Fragment>
            <div className="settings-liner">
              <span className="settings-text">Settings</span>{" "}
              <span className="liner-border"></span>
            </div>
            <div className="settings-workspace-navBar">
              <p
                className="settings-workspace-navBar-tabs"
                onClick={() => onProjectSettingsClick("people")}
              >
                People
              </p>
              <p
                className="settings-workspace-navBar-tabs"
                onClick={() => onProjectSettingsClick("project")}
              >
                Project
              </p>
              {/* <p className="settings-workspace-navBar-tabs">Project</p> */}
              <p
                className="settings-workspace-navBar-tabs"
                onClick={() => onProjectSettingsClick("billing")}
              >
                Billing
              </p>
              <p
                className="settings-workspace-navBar-tabs"
                onClick={() => onProjectSettingsClick("workspace")}
              >
                Workspace
              </p>
              {/* <p className="settings-workspace-navBar-tabs">Integrations</p> */}
            </div>
          </Fragment>
        )}
        {listOfCustomer.length > 1 && (
          <div className="navBar-switch-workspace">
            <p className="navBar-switch-workspace-title">
              {" "}
              Switch to another workspace
            </p>
            <Scrollbar style={{ height: "calc(100% - 20px)" }}>
              {workspaceList}
            </Scrollbar>
          </div>
        )}

        <div className="navBar-create" onClick={() => onCreateFindWorkspace()}>
          <p className="navBar-create-title">Create/Find a workspace</p>
          <img className="arrow-img" src={workspaceArrow} alt="workspace-arrow" />
        </div>
        <div className="navBar-create" onClick={() => onWorkspaceInvitations()}>
          <p className="navBar-create-title">Workspace Invitations</p>
          <img className="arrow-img" src={workspaceArrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
}
