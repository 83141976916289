import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollBar from "react-scrollbars-custom";

import {
  getArchivedProject,
  unArchivedArchivedProject
} from "../../actions/archived/archivedActions";
import ArchivedProjectCard from "./archivedProjectCard";

class MoreArchivedProjects extends Component {
  state = {
    noOfProjects: 10
  };

  render() {
    const {
      projectListLoaderState,
      searchContent,
      listOfProjects,
      unArchivedArchivedProjectAction
    } = this.props;
    let searchedProjects = null;
    if(searchContent.text)
      searchedProjects = listOfProjects
        .filter(project => project.name.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1)

    const projectRender = (searchedProjects || listOfProjects)
      .sort(
        (d1, d2) =>
          new Date(d2.updated_at).getTime() - new Date(d1.updated_at).getTime()
      )
      .map((project) => {
        return (
          <ArchivedProjectCard
            key={project.id}
            project={project}
            unArchivedArchivedProject={unArchivedArchivedProjectAction}
          ></ArchivedProjectCard>
        );
      });

    return (
      <div className="arch-center-tt-container with-no-scroll">
        <div className="arch-categ-outer-container">
          <h3 className="arch-categ-heading">Projects</h3>
          <ScrollBar>
            <div className="arch-categ-container">
              <div className="arch-categ-holder">{projectRender}</div>
              {projectListLoaderState.fetched &&
                (searchedProjects?.length === 0 || listOfProjects.length === 0) && (
                  <div className="no-checklist-found">
                    No archived projects found
                  </div>
                )}
            </div>
          </ScrollBar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loginReducer.loginAuth.user_details,
    projectListLoaderState: state.projectReducer.projectListLoaderState,
    searchContent: state.commonReducer.searchContent,
    listOfProjects: state.projectReducer.listOfArchivedProjects.list
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArchivedProject: ({ queryParam }, callback) => {
      dispatch(getArchivedProject({ queryParam }, callback));
    },
    unArchivedArchivedProjectAction: ({ body }, callback) => {
      dispatch(unArchivedArchivedProject({ body }, callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreArchivedProjects);
