import React, { Component } from "react";
import "../../styles/scss/notifications.scss";
import notify_comment from "../../styles/img/svg/notify-comments.svg";
import notify_edit_comment from "../../styles/img/svg/edit_comment_notify.svg";
import notify_attachment_comment from "../../styles/img/svg/att-comm-notify.svg";
import notify_like_comment from "../../styles/img/svg/like-notify.svg";
import { Tooltip } from "@material-ui/core";
import { parseComment, commonDateFormat, getPosition } from "../../helpers/generichelper";
import ReactHtmlParser from "react-html-parser";
import {
  changeIsReadStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfYouTabAction,
  changeFlagStatusOfAllTabAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import firebase from "../../firebase";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },

    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    }
  };
}
const mapStateToProps = state => {
  return {
    userDetails: state.peopleReducer.workspaceusers
  };
};
class LikeNotify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let links = document.getElementsByClassName(`notifi-a-tag-${this.props.notification.id}`);
    if(links.length > 0) {
      for(const element of links){
        element.onclick = (event) => {
          event.stopPropagation();
          this.youNotificationReadStatus(this.props.notification, "REPLY");
      }
      }
    }
  }

  youNotificationReadStatus = (notification, reply = null) => {
    
    if (reply === "REPLY") {
      notification.is_read = true;
    }
    if (notification.is_read === false && !reply) {
      notification.is_read = true;
    } else if (notification.is_read === true && !reply) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };

  notificationFlagStatus = notification => {
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }

    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab(
        { notificationArray, ddownOption, ddownOption2, searchText },
        res => {
          // console.log(" you tab flag status has been changed!");
        }
      );
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab(
        { notificationArray, ddownOption, ddownOption2, searchText },
        res => {
          // console.log("all tab flag status has been changed!");
        }
      );
    }
  };

  isImageDisplay = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    return !!(ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif");
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  render() {
    const { notification } = this.props;
    const commentTitleArr = notification.data.comment.title.split(" ");
    const manipulatedString = commentTitleArr.map(value => {
      if (value.indexOf("@") > -1) {
        let newThing = value.replace(/{/g, "");
        newThing = newThing.replace(/}/g, "");
        return newThing;
      }
      return value;
    });
    let commentTitle = manipulatedString.join(" ");
    let finalCommentTitle = notification.message.includes("edit")
      ? commentTitle.substr(0, getPosition(commentTitle, "\n", 2))
      : commentTitle;
    const shouldShow3Dots = notification.message.includes("edit") && finalCommentTitle.length < commentTitle.length;
    let sameUser=notification.sender_id === notification.recipient_id;

    return (
      <div
        id={`comment-notify-parent-${notification.id}`}
        className="notify-card"
        onClick={() => {
          if (!this.props.delete) {
            this.props.goToActivity(notification);
          }
        }}
      >
        <div className="right-comment-notification-title comments-notifi">
          <Tooltip
            title={
              notification.is_read === true ? "Mark as unread" : "Mark as read"
            }
          >
            <div
              className={
                notification.is_read === true
                  ? "notify-read-unread read"
                  : "notify-read-unread"
              }
              onClick={e => {
                firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
                e.stopPropagation();
                this.youNotificationReadStatus(notification);
              }}
            ></div>
          </Tooltip>
          <Tooltip
            title={
              notification.is_flagged
                ? "Unflag this notification"
                : "Flag this notification"
            }
          >
            <div
              className={
                notification.is_flagged === true
                  ? "notifi-flag flagged"
                  : "notifi-flag"
              }
              onClick={e => {
                firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
                e.stopPropagation();
                this.notificationFlagStatus(notification);
              }}
            ></div>
          </Tooltip>
          <div className="right-notfi-title-img like-notify">
            {notification.sender_info.img ? <img src={notification.sender_info.img} alt="user-img" /> : 
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={notification.sender_info.first_name}
                maxInitials={1}
                />
            }
            <img className="like-notify-icon" src={notify_like_comment} alt="like" />
          </div>
          <div className="Card_Title">
                <p
                  className="notify-card-title like-notify-title"
                >
                    <span>
                      {sameUser
                        ? "Yay! You have liked your comment in the activity "
                        : `${notification.message} `}
                      <span className="activity-title">
                        {notification.data.parent_activity.title}
                      </span>
                      {notification.data.project && (
                      <span>
                        {" "}
                        under the project
                        <span className="activity-title">
                          {" " + notification.data.project.name}
                        </span>
                      </span>
                    )}
                    </span>
                </p>
          </div>
          <p className="comment_post_time like-notify-time">
            {commonDateFormat(notification.created_at)}
          </p>
        </div>
        <div className="right-comment-desc-you like-notify-desc">
          <div className="right-comment-notfi-icon">
            <img
              src={
                notification.message.includes("edited")              
                ? notify_edit_comment
                : notification.data.comment.data?.commentDetails?.hasAttachment
                  ? notify_attachment_comment
                  : notify_comment
              }
              alt="comm-img"
            />
          </div>
          <div className="right-comment-notifi-desc">
            <div className="notifi-comments-desc-holder">
              <div className="description">
                {/* <ReactMarkdown
                  source={parseComment(commentTitle, notification.id)}
                  escapeHtml={false}
                /> */}
                {ReactHtmlParser(parseComment(finalCommentTitle, notification.id))}{shouldShow3Dots && <span>...</span>}
                {/* {ReactHtmlParser(parseComment(commentTitle))} */}
              </div>
              <div className="comm-att-holder">
                  {!notification.message.includes("edit") && notification?.data?.comment?.attachments?.length > 0 && 
                    <React.Fragment>
                      {notification.data.comment.attachments.map((attachment, index) => {
                        return <div
                        className="nw-att-row-container comm-att"
                        key={index}
                        id={`nw-att-row-container-${attachment.id}`}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImageDisplay(attachment.url)
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {this.isImageDisplay(attachment.url) && (
                              <img
                                src={decodeURI(attachment.url)}
                                alt="att-img"
                                // onClick={() => this.onClickImg(decodeURI(attachment.url))}
                              />
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImageDisplay(attachment.url) &&
                              attachment.url.indexOf(".google.com") < 0 &&
                              attachment.url.indexOf("www.dropbox.com") < 0 &&
                              attachment.url.indexOf("https://1drv.ms/") < 0 && (
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">
                                    {attachment.url.substr(
                                      attachment.url.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
              
                            <div className="nw-att-details-name comm-att truncate-text notify" onclick={e => e.stopPropagation()}>
                              {attachment.title.length > 22 ? <Tooltip title={attachment.title}>
                              <a href={decodeURI(attachment.url)} target="_blank" rel="noopener noreferrer">
                                {attachment.title}
                              </a>
                              </Tooltip> : <a href={decodeURI(attachment.url)} target="_blank" rel="noopener noreferrer">
                                {attachment.title}
                              </a>}
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              href={decodeURI(attachment.url)}
                              download={attachment.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </div>
                          {/* <div className="nw-att-link-container"></div> */}
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                  }
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeNotify);
