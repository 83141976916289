import React, { Component } from "react";
import { connect } from "react-redux";
import Mark from "mark.js";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { unarchiveCommentAction,
    permanentDeleteCommentAction,
    getMoreArchivedCommentsAction
} from "../../actions/comments/commentsaction";
import CommentTile from "./commentTile";
import InfiniteScroll from "react-infinite-scroll-component";

class MoreComments extends Component {
  state = {
    page: 2,
    selectedProject: { id: 0, name: "All" },
    selectedActivity: { id: 0, title: " All" },
    showProjectDropDown: false,
    filteredArchivedComments: null,
    showActivityDropDown: false,
  };

  onShowMore = () => {
    const { loggedInUser, getMoreArchivedCommentsAction, searchContent } = this.props;
    if(searchContent.text) {
        getMoreArchivedCommentsAction({ user_id: parseInt(loggedInUser.id), searchText: searchContent.text, page: this.state.page, perPage: 10 }, _response => {
            this.setState({ page: this.state.page + 1 });
        });
    } else {
        getMoreArchivedCommentsAction({ user_id: parseInt(loggedInUser.id), page: this.state.page, perPage: 10 }, _response => {
            this.setState({ page: this.state.page + 1 });
        });
    }
  };

  componentDidUpdate(prevProps) {
    if ((this.props.searchContent.text && !prevProps.searchContent.text) ||
        (!this.props.searchContent.text && prevProps.searchContent.text) ||
        (this.props.searchContent.text !== prevProps.searchContent.text)) {
       this.setState({
        page: 2,
        filteredArchivedComments: null,
        selectedProject: { id: 0, name: "All" },
        selectedActivity: { id: 0, title: " All" }
       });
    }
  }

  onShowProjectDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showProjectDropDown: !this.state.showProjectDropDown,
    });
  };

  onShowActivityDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showActivityDropDown: !this.state.showActivityDropDown,
    });
  };

  onProjectClicked = (project) => {
    this.setState({
      selectedProject: project,
      selectedActivity: { id: 0, title: " All" },
    });
  };
  onActivityClicked = (activity) => {
    this.setState({
      selectedActivity: activity,
    });
  };

  applyingSelection = () => {
    const {
      // listOfProjects,
      // activities,
      archivedComments,
      // loggedInUser,
    } = this.props;
    if (
      this.state.selectedActivity.id === 0 &&
      this.state.selectedProject.id === 0
    ) {
      this.setState({
        filteredArchivedComments: null
      });
    }

    if (
      this.state.selectedActivity.id === 0 &&
      this.state.selectedProject.id !== 0
    ) {
      this.setState({
        filteredArchivedComments: _.orderBy(archivedComments, [(archivedComment) => {
              let updatedOn = new Date(archivedComment.updated_on);
              return updatedOn.getTime();
            }], ["desc"])
          .filter(
            (comment) =>
              comment.project_id === this.state.selectedProject.id
          ),
      });
    }

    if (this.state.selectedActivity.id !== 0) {
      this.setState({
        filteredArchivedComments: _.orderBy(archivedComments, [(archivedComment) => {
              let updatedOn = new Date(archivedComment.updated_on);
              return updatedOn.getTime();
            }], ["desc"])
          .filter(
            (comment) =>
              comment.activity_id === this.state.selectedActivity.id
          ),
      });
    }

    if(this.props.searchContent.text) {
      var context = document.querySelectorAll(".checklist-title-txt");
      var instance = new Mark(context);
      instance.unmark();
      setTimeout(() => {
        var updatedContext = document.querySelectorAll(".checklist-title-txt");
        var updatedInstance = new Mark(updatedContext);
        updatedInstance.mark(this.props.searchContent.text, { element: "span", className: "marked-search", accuracy: "partially" });
      }, 100)
    }
  };

  onOutsideClick = (e) => {
    if (this.projectOptionsNode && !this.projectOptionsNode.contains(e.target))
      this.setState({
        showProjectDropDown: false,
      });
    if (
      this.activityOptionsNode &&
      !this.activityOptionsNode.contains(e.target)
    )
      this.setState({
        showActivityDropDown: false,
      });
  };
  render() {
    const {
      getCommentsLoaderState,
      searchContent,
      archivedComments,
      unarchiveCommentAction,
      permanentDeleteCommentAction,
      listOfProjects,
      activities,
    } = this.props;
    const {
      showProjectDropDown,
      selectedProject,
      showActivityDropDown,
      selectedActivity,
      filteredArchivedComments,
    } = this.state;

    const filteredActivity =
      selectedProject.id !== 0
        ? activities.filter(
            (activity) => activity.project.id === selectedProject.id
          )
        : activities;
    const renderProjectOptions = listOfProjects.map((project) => {
      return (
        <li
          key={project.id}
          className="project-options"
          onClick={() => this.onProjectClicked(project)}
        >
          {project.name}
        </li>
      );
    });
    const renderActvitiesOptions = filteredActivity.map((activity) => {
      return (
        <li
          key={activity.id}
          className="project-options"
          onClick={() => this.onActivityClicked(activity)}
        >
          {activity.title}
        </li>
      );
    });

    const renderComments =
      filteredArchivedComments === null
        ? searchContent.text
          ? _.orderBy(archivedComments,
            [(archivedComment) => {
              let updatedAt = new Date(archivedComment.updated_on);
              return updatedAt.getTime();
            }],
            ["desc"])
          .filter((comment) =>
            comment.title.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1
          )
          .map((comment, index) => {
              return (
                <CommentTile
                  key={comment.id}
                  unarchiveCommentAction={unarchiveCommentAction}
                  permanentlyDeleteCommentAction={permanentDeleteCommentAction}
                  comment={comment}
                ></CommentTile>
              );
          })
          : _.orderBy(archivedComments, [(archivedComment) => {
            let updatedAt = new Date(archivedComment.updated_on);
            return updatedAt.getTime();
          }], ["desc"])
            .map((comment) => {
                return (
                  <CommentTile
                    key={comment.id}
                    unarchiveCommentAction={unarchiveCommentAction}
                    permanentlyDeleteCommentAction={permanentDeleteCommentAction}
                    comment={comment}
                  ></CommentTile>
                );
            })
        :
        searchContent.text
          ? filteredArchivedComments
              .filter(comment => comment.title.toLowerCase().indexOf(searchContent.text.toLowerCase()) !== -1)
              .map((comment) => {
                  return (
                    <CommentTile
                      key={comment.id}
                      unarchiveCommentAction={unarchiveCommentAction}
                      permanentlyDeleteCommentAction={permanentDeleteCommentAction}
                      comment={comment}
                    ></CommentTile>
                );
            })
          : filteredArchivedComments.map(
              (comment, index) => {
                  return (
                    <CommentTile
                      key={comment.id}
                      unarchiveCommentAction={unarchiveCommentAction}
                      permanentlyDeleteCommentAction={permanentDeleteCommentAction}
                      comment={comment}
                    ></CommentTile>
                  );
              }
            );
    return (
      <div className="arch-center-tt-container with-no-scroll">
        <div className="comm-filter-control-tt-container filter-view archive-project">
          <div
            className="comm-filter-control-container archive-project"
            ref={(node) => (this.projectOptionsNode = node)}
          >
            <div className="comm-filter-control-title">Project :</div>
            <div
              className={
                showProjectDropDown
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown clicked"
                  : "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown"
              }
              onClick={this.onShowProjectDropdown}
            >
              <div class="workflow-change-ddown-text">
                {" "}
                {selectedProject.name}
              </div>
              {showProjectDropDown && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                  <div class="workflow-change-ddown">
                    {/* <ScrollBar> */}
                      <ul class="workflow-change-ddown-inner">
                        <li
                          className="project-options"
                          onClick={() =>
                            this.onProjectClicked({ id: 0, name: "All" })
                          }
                        >
                          All
                        </li>
                        {renderProjectOptions}
                      </ul>
                    {/* </ScrollBar> */}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <div
            className="comm-filter-control-container archive-project"
            ref={(node) => (this.activityOptionsNode = node)}
          >
            <div className="comm-filter-control-title">Activity :</div>
            <div
              className={
                selectedProject.id === 0
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown disabled"
                  : showActivityDropDown
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown clicked"
                  : "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown"
              }
              onClick={
                selectedProject.id === 0
                  ? () => {}
                  : (e) => this.onShowActivityDropdown(e)
              }
            >
              <div class="workflow-change-ddown-text">
                {" "}
                {selectedActivity.title}
              </div>
              {showActivityDropDown && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                  <div class="workflow-change-ddown">
                    {/* <ScrollBar> */}
                      <ul class="workflow-change-ddown-inner">
                        <li
                          className="project-options"
                          onClick={() =>
                            this.onActivityClicked({ id: 0, title: "All" })
                          }
                        >
                          All
                        </li>
                        {renderActvitiesOptions}
                      </ul>
                    {/* </ScrollBar> */}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <button
            className="comm-filter-btn apply"
            onClick={this.applyingSelection}
          >
            Apply
          </button>
        </div>
        <div
          className="arch-categ-outer-container"
          id="arch-categ-outer-container-scroll"
        >
          <InfiniteScroll
            dataLength={archivedComments.length}
            next={this.onShowMore}
            hasMore={true}
            scrollableTarget="arch-categ-outer-container-scroll"
          >
            <div className="arch-categ-container arch-categ-container-checklist">
              <div className="arch-categ-holder all-activities">
                {renderComments}
              </div>
            </div>
          </InfiniteScroll>
        </div>
        {getCommentsLoaderState.fetched &&
          ((filteredArchivedComments !== null && filteredArchivedComments.length === 0) ||
          (searchContent.text && renderComments.length === 0))
          && (
            <div className="no-checklist-found">
              No archived comments found
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loginReducer.loginAuth.user_details,
    searchContent: state.commonReducer.searchContent,
    activities: [
      ...state.activityReducer.listOfArchivedActivity.list,
      ...state.activityReducer.listOfActivity.list,
      ...state.activityReducer.prioritiZedActivity.list,
    ],

    listOfProjects: [
      ...state.projectReducer.listOfProjects.list,
      ...state.projectReducer.listOfArchivedProjects.list,
    ],
    getChecklistLoaderState: state.checklistReducer.getChecklistLoaderState,
    archivedChecklists: state.checklistReducer.archivedChecklists.list,
    getCommentsLoaderState: state.commentsReducer.getCommentsLoaderState,
    archivedComments: state.commentsReducer.archivedComments
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMoreArchivedCommentsAction: ({ user_id, searchText, page, perPage }, callback) => {
        dispatch(getMoreArchivedCommentsAction({ user_id, searchText, page, perPage }, callback));
    },
    unarchiveCommentAction: ({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, callback) => {
        dispatch(unarchiveCommentAction({ id, is_active, title, parent_activity_id, version_id, user_id, data, created_at, created_by, version, updated_on }, callback));
    },
    permanentDeleteCommentAction: ({ id }, callback) => {
        dispatch(permanentDeleteCommentAction({ id }, callback));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreComments);
