/***
 * Author : Madhusudhan Seetharamiah
 ***
 */
import React, { Component } from "react";
import { Form, Control } from "react-redux-form";
import { connect } from "react-redux";
import { message } from "../../constants/messages";
import routesHelper from "../../router/routeshelper";
import * as actions from "../../actions/index";

const mapStateToProps = state => {
  return {
    resetPasswordDetails: state.rrfStatereducer.resetPassword,
    resetPasswordLoaderState: state.loginReducer.resetPasswordLoaderState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordAction({ email, token, retypePassword }, callback) {
      dispatch(
        actions.resetPasswordAction({ email, token, retypePassword }, callback)
      );
    },
    authenticateUserAction({ email, password }, callback) {
      dispatch(
        actions.authenticateUser(
          {
            email,
            password
          },
          callback
        )
      );
    }
  };
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      retypePassword: null,
      showPassword: false,
      showConfirmPassword: false
    };
    
    var platform = window.navigator.platform;
    var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (iosPlatforms.indexOf(platform) !== -1
      && props.router.location?.query?.email
      && props.router.location?.query?.token)
        window.location.replace(`com.peppersquare.GetThingsDone://token=${props.router.location.query.token}&email=${props.router.location.query.email}`);
  }

  // componentWillMount() {
    // window.location.replace("com.peppersquare.GetThingsDone://");
  // }

  toggleShowPassword = event => {
    const field = event.target.getAttribute("name");
    if (field === "password") {
      this.setState({ showPassword: !this.state.showPassword });
    }
    if (field === "confirm-password") {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }
  };

  onInputKeyUp = (e) => {
    if(e.key === "Enter") this.onResetRequest();
  }

  onResetRequest = () => {
    const {
      resetPasswordDetails,
      resetPasswordAction,
      router,
      // authenticateUserAction,
      // resetPasswordLoaderState
    } = this.props;

    const {
      location: { query }
    } = router;

    let noOfErrors = [];
    for (const key in resetPasswordDetails) {
      if (resetPasswordDetails.hasOwnProperty(key)) {
        const element = resetPasswordDetails[key];
        if (key === "password") {
          if (element.length < 1) {
            noOfErrors.push(false);
            this.setState({ [key]: message.filedShouldNotBeEmpty });
          } else if (element.length < 6) {
            noOfErrors.push(false);
            this.setState({
              [key]: message.shortPassword
            });
          } else {
            this.setState({
              [key]: null
            });
          }
        } else if (key === "retypePassword") {
          if (element.length < 1) {
            noOfErrors.push(false);
            this.setState({ [key]: message.filedShouldNotBeEmpty });
          }
          //   else if (element.length < 6) {
          //     noOfErrors.push(false);
          //     this.setState({
          //       [key]: message.shortPassword
          //     });
          //   }
          else if (
            resetPasswordDetails["password"].length > 5 &&
            element !== resetPasswordDetails["password"]
          ) {
            noOfErrors.push(false);
            this.setState({ [key]: message.passwordNotMatch });
          } else {
            this.setState({
              [key]: null
            });
          }
        }
      }
    }
    noOfErrors.length === 0 &&
      query.email &&
      query.token &&
      resetPasswordAction(
        {
          retypePassword: resetPasswordDetails.password,
          token: query.token,
          email: query.email
        },
        response => {
          ////console.log("resetPasswordLoaderState", resetPasswordLoaderState);
          ////console.log("response", response);
          if (response && response.error) {
            this.setState({ password: response.error });
          }
          
        }
      );
  };

  onLogin = () => {
    const { router } = this.props;
    router.push(routesHelper.getLoginPath());
  };

  render() {
    const { showPassword, showConfirmPassword } = this.state;
    const { resetPasswordLoaderState } = this.props;
    if (
      resetPasswordLoaderState &&
      resetPasswordLoaderState.fetched &&
      !resetPasswordLoaderState.error
    ) {
      return (
        <div className="signIn">
          <div className="fp-header-area reset-new-link">
            <div className="fp-main-heading">Password successfully reset! </div>
            <div className="fp-sub-heading new-link-sent password-success">
              You can now use your new password to <br />
              login to your account
            </div>
          </div>
          <div name="resetPasswordForm">
            <div className="rp-submit-holder fp res-lin">
              <button
                className="md-raised common-submit-btn"
                type="submit"
                onClick={this.onLogin}
              >
                <span>Login</span>
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="signIn">
          <div className="fp-header-area">
            <div className="fp-main-heading">Reset Password</div>
          </div>
          <Form name="createPasswordForm" model="rrfState.resetPassword">
            <div className="rp-controls-container cp">
              <div className="login-control-holder view-password-holder">
                <Control.input
                  type={showPassword ? "text" : "password"}
                  placeholder="New password"
                  className="common-input-ctrl"
                  model=".password"
                  name="password"
                  onKeyUp={this.onInputKeyUp}
                  autoFocus
                />
                <div className="password-viewer">
                  <div
                    name="password"
                    className={showPassword ? "eye-seen" : "eye-not-seen"}
                    onClick={this.toggleShowPassword}
                  ></div>
                </div>
                {this.state.password && (
                  <p className="common-err-msg">{this.state.password}</p>
                )}
              </div>
              <div className="login-control-holder view-password-holder">
                <Control.input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  className="common-input-ctrl"
                  model=".retypePassword"
                  name="retypePassword"
                  onKeyUp={this.onInputKeyUp}
                />
                <div className="password-viewer">
                  <div
                    name="confirm-password"
                    className={
                      showConfirmPassword ? "eye-seen" : "eye-not-seen"
                    }
                    onClick={this.toggleShowPassword}
                  ></div>
                </div>
                {this.state.retypePassword && (
                  <p className="common-err-msg">{this.state.retypePassword}</p>
                )}
              </div>
            </div>
            <div className="rp-submit-holder">
              <button
                className="md-raised common-submit-btn"
                type="submit"
                onClick={this.onResetRequest}
              >
                <span>Submit</span>
              </button>
            </div>
            <div className="rf-bottom-area">
            </div>
          </Form>
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
