import React, { useState, memo } from "react";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
// import calendarIcon from "../../styles/img/svg/workload-day-picker.svg";
import RenderDatePicker from "../common/renderDatePicker";
// import Scrollbar from "react-scrollbars-custom";

// importing icons
import workloadArrowRightSVG from "../../styles/img/svg/workload-arrow-right.svg";
import workloadArrowLeftSVG from "../../styles/img/svg/workload-arrow-left.svg";

//importing helper functions
import {
  daysOfWeek,
  nameOfTheMonth,
  nameOfTheDay,
} from "../../helpers/workloadHelper";
import firebase from "../../firebase";

function CaleanderHeader({ onSettingCurrentMode, selectedProject, router }) {
  const [showOptionsDropdown, setshowOptionsDropdown] = useState(false);
  const [selectedOption, setselectedOption] = useState({
    id: 1,
    name: "Week",
  });
  const [selectedWeek, setselectedWeek] = useState(daysOfWeek(0).days);
  const [selectedMonth, setselectedMonth] = useState(
    moment().format("MMMM YYYY")
  );
  const [selectedDay, setselectedDay] = useState(
    moment().format("ddd, D MMM YYYY")
  );
  const [, setweekNumber] = useState(daysOfWeek(0).weekNumber);
  const [weekIndex, setweekIndex] = useState(0);
  const [monthIndex, setmonthIndex] = useState(0);
  const [dayIndex, setdayIndex] = useState(0);
  // const [nextweekIndex, setnextweekIndex] = useState(0);

  const [, setDatePickerInput] = useState("");

  const daysOfTheMonth = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const renderWeekDays = selectedWeek.map((day, index) => {
    return (
      <div className="Week-days" key={moment(day).format()}>
        {index === 0 && (
          <span className="weeknumber">{`W${moment(
            selectedWeek[3]
          ).isoWeek()}`}</span>
        )}
        <span
          className={
            moment(day).isSame(new Date(), "day")
              ? "week-day-name selected"
              : "week-day-name"
          }
        >{`${moment(day).format("ddd ")}`}</span>
        <span
          className={
            moment(day).isSame(new Date(), "day")
              ? "week-day-date selected"
              : "week-day-date"
          }
        >
          {moment(day).format("DD")}
        </span>
      </div>
    );
  });

  const renderMonthdays = daysOfTheMonth.map((day) => {
    return (
      <div className="Week-days" key={day}>
        <span className="week-day-name">{day}</span>
      </div>
    );
  });

  const renderTodaysDay = () => {
    return (
      <div className="Week-days" key={moment(selectedDay).format()}>
        <span
          className={
            moment(selectedDay).isSame(new Date(), "day")
              ? "week-day-name selected"
              : "week-day-name"
          }
        >{`${moment(selectedDay).format("ddd ")}`}</span>
        <span
          className={
            moment(selectedDay).isSame(new Date(), "day")
              ? "week-day-date selected"
              : "week-day-date"
          }
        >
          {moment(selectedDay).format("DD")}
        </span>
      </div>
    );
  };

  const goToKanban = () => {
    router.push(`/project/${selectedProject.id}/kanban`);
  };

  const selectedDate = () => {
    switch (selectedOption.id) {
      case 0:
        return selectedDay;
      case 1:
        return `${moment(selectedWeek[0]).format("MMM D")} - ${moment(
          selectedWeek[6]
        ).format("MMM D, YYYY")}`;
      case 2:
        return selectedMonth;
      default:
        return moment().format("ddd, D MMM YYYY");
    }
  };

  const previousClicked = () => {
    switch (selectedOption.id) {
      case 0:
        const newdayIndex = dayIndex - 1;
        setdayIndex(newdayIndex);
        onSettingCurrentMode(selectedOption, nameOfTheDay(newdayIndex));
        return setselectedDay(nameOfTheDay(newdayIndex));
      case 1:
        const newweekIndex = weekIndex - 1;
        setweekIndex(newweekIndex);
        setselectedWeek(daysOfWeek(newweekIndex).days);
        onSettingCurrentMode(selectedOption, daysOfWeek(newweekIndex).days);
        return setweekNumber(daysOfWeek(newweekIndex).weekNumber);
      case 2:
        const newMonthIndex = monthIndex - 1;
        setmonthIndex(newMonthIndex);
        onSettingCurrentMode(selectedOption, nameOfTheMonth(newMonthIndex));
        return setselectedMonth(nameOfTheMonth(newMonthIndex));
      default:
        return moment().format("ddd, D MMM YYYY");
    }
  };

  const nextClicked = () => {
    switch (selectedOption.id) {
      case 0:
        const newdayIndex = dayIndex + 1;
        setdayIndex(newdayIndex);
        onSettingCurrentMode(selectedOption, nameOfTheDay(newdayIndex));
        return setselectedDay(nameOfTheDay(newdayIndex));
      case 1:
        const newweekIndex = weekIndex + 1;
        setweekIndex(newweekIndex);
        setselectedWeek(daysOfWeek(newweekIndex).days);
        onSettingCurrentMode(selectedOption, daysOfWeek(newweekIndex).days);
        return setweekNumber(daysOfWeek(newweekIndex).weekNumber);
      case 2:
        const newMonthIndex = monthIndex + 1;
        setmonthIndex(newMonthIndex);
        onSettingCurrentMode(selectedOption, nameOfTheMonth(newMonthIndex));
        return setselectedMonth(nameOfTheMonth(newMonthIndex));
      default:
        return moment().format("ddd, D MMM YYYY");
    }
  };

  const onTodayClick = () => {
    firebase.analytics().logEvent("GTD_Workload_Today_Button");
    setselectedWeek(daysOfWeek(0).days);
    // setselectedMonth(moment().format("MMMM YYYY"));
    // setselectedDay(moment().format("ddd, D MMM YYYY"));
    // setweekNumber(daysOfWeek(0).weekNumber);
    // setweekIndex(0);
    // setmonthIndex(0);
    // setdayIndex(0);

    switch (selectedOption.id) {
      case 0:
        setdayIndex(0);
        onSettingCurrentMode(selectedOption, nameOfTheDay(0));
        return setselectedDay(nameOfTheDay(0));
      case 1:
        setweekIndex(0);
        setselectedWeek(daysOfWeek(0).days);
        onSettingCurrentMode(selectedOption, daysOfWeek(0).days);
        return setweekNumber(daysOfWeek(0).weekNumber);
      case 2:
        setmonthIndex(0);
        onSettingCurrentMode(selectedOption, nameOfTheMonth(0));
        return setselectedMonth(nameOfTheMonth(0));
      default:
        return moment().format("ddd, D MMM YYYY");
    }
  };

  const onDatePickerInputChange = (selectedDay) => {
    firebase.analytics().logEvent("GTD_Workload_Calendar_Button");
    // console.log("selected day", selectedDay);
    setDatePickerInput(new Date(selectedDay));

    const daySelected = moment(selectedDay)
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    const todayDate = moment(new Date())
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    const newDayIndex = moment(daySelected).diff(todayDate, "days");
    const newWeekIndex = moment(daySelected)
      .startOf("week")
      .diff(
        moment(todayDate)
          .startOf("week")
          .format(),
        "weeks"
      );

    switch (selectedOption.id) {
      case 0:
        setdayIndex(newDayIndex);
        onSettingCurrentMode(selectedOption, nameOfTheDay(newDayIndex));
        return setselectedDay(moment(selectedDay).format("ddd, D MMM YYYY"));
      case 1:
        setweekIndex(newWeekIndex);
        setselectedWeek(daysOfWeek(newWeekIndex).days);
        onSettingCurrentMode(selectedOption, daysOfWeek(newWeekIndex).days);
        return setweekNumber(daysOfWeek(0).weekNumber);
      case 2:
        setmonthIndex(0);
        onSettingCurrentMode(selectedOption, nameOfTheMonth(0));
        return setselectedMonth(nameOfTheMonth(0));
      default:
        return moment().format("ddd, D MMM YYYY");
    }
  };

  return (
    <div className="ppl-setting-caleander-header-container">
      <div className="fc-toolbar-outer-div">
        <div className="fc-toolbar">
          <div className="view-kanban-btn-holder">
            {selectedProject.id !== 0 && (
              <button className="view-workload-btn" onClick={goToKanban}>
                {" "}
                View in Kanban{" "}
              </button>
            )}
          </div>
          <div className="fc-control-holder">
            <span className="wl-selected-date">{selectedDate()}</span>
            <div className="combo-week-and-day-changer">
              <img
                src={workloadArrowRightSVG}
                alt=""
                onClick={previousClicked}
              />
              <div className="format-selector">
                <OutsideClickHandler
                  onOutsideClick={() => setshowOptionsDropdown(false)}
                >
                  <div
                    className="title-wl-holder"
                    role="button"
                    tabIndex="0"
                    onClick={() => setshowOptionsDropdown(!showOptionsDropdown)}
                  >
                    {selectedOption.name}
                  </div>

                  {showOptionsDropdown && (
                    <div className="project-list-container-wl cal-view-ddown-container-wl">
                      <div>
                        <div className="repeat-wl">
                          <div
                            key={0}
                            onClick={() => {
                              onSettingCurrentMode(
                                {
                                  id: 0,
                                  name: "Day",
                                },
                                selectedDay
                              );
                              setselectedOption({
                                id: 0,
                                name: "Day",
                              });
                              setshowOptionsDropdown(false);
                            }}
                            className={
                              selectedOption.id === 0
                                ? "selected-project-wl project-wl-list"
                                : "project-wl-list"
                            }
                          >
                            Day
                          </div>
                          <div
                            key={1}
                            onClick={() => {
                              onSettingCurrentMode(
                                {
                                  id: 1,
                                  name: "Week",
                                },
                                selectedWeek
                              );
                              setselectedOption({
                                id: 1,
                                name: "Week",
                              });
                              setshowOptionsDropdown(false);
                            }}
                            className={
                              selectedOption.id === 1
                                ? "selected-project-wl project-wl-list"
                                : "project-wl-list"
                            }
                          >
                            Week
                          </div>
                          {/* <div
                          key={2}
                          onClick={() => {
                            onSettingCurrentMode(
                              {
                                id: 2,
                                name: "Month"
                              },
                              selectedMonth
                            );
                            setselectedOption({
                              id: 2,
                              name: "Month"
                            });
                            setshowOptionsDropdown(false);
                          }}
                          className={
                            selectedOption.id === 2
                              ? "selected-project-wl project-wl-list"
                              : "project-wl-list"
                          }
                        >
                          Month
                        </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </OutsideClickHandler>
              </div>

              <div></div>
              <img src={workloadArrowLeftSVG} onClick={nextClicked} alt="" />
            </div>
            <div className="today-button">
              <button onClick={onTodayClick}>Today</button>
            </div>
            <div className="wl-day-selector">
              <RenderDatePicker
                field={{
                  label: "Date",
                  model: ".wl_date_input",
                  value: "",
                }}
                placeholder=""
                onWlDayChange={onDatePickerInputChange}
              ></RenderDatePicker>
            </div>
          </div>
        </div>
      </div>

      <div className="caleander-date-days">
        {selectedOption.id === 1 && renderWeekDays}
        {selectedOption.id === 0 && renderTodaysDay()}
        {selectedOption.id === 2 && renderMonthdays}
      </div>
    </div>
  );
}

export default memo(CaleanderHeader);
