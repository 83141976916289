import React, { PureComponent } from "react";
import "../../styles/scss/peopleSettings.scss";
import "../../";
import searchIcon from "../../styles/img/svg/settings-search-icon1.svg";
import addIcon from "../../styles/img/svg/plus-add.svg";
import tickIcon from "../../styles/img/svg/checkmark.svg";
import addClosePpl from "../../styles/img/svg/add-close-ppl.svg";
import settingsSearchIcon from "../../styles/img/svg/settings-search-icon.svg";
import dotIcon from "../../styles/img/svg/activity_dots.svg";
import {
  getAllCustomerForPeopleAction,
  getInvitedEmailsAction,
  clearInvitedEmailsAction,
  getRequestedUsersAction,
  getUserDetailsAction,
  updateWorkspaceUsersAfterRemoveUser,
} from "../../actions/people/peopleaction";
import { updateSubscriptionAction } from "../../actions/billing/billing";

import { Loader } from "../common/loader";
import { Scrollbar } from "react-scrollbars-custom";
import SearchPeopleSuggestions from "./searchpeopleSuggestions";
import {
  sendInviteToEmailsToJoinWorkspace,
  resendCancelInvite,
} from "../../services/workspaceapi";
import { findUserLike } from "../../services/userapi";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { isEmailValid } from "../../helpers/generichelper";
import {
  updateCustomerDetailsAction,
  getAllCustomerForUserAction,
} from "../../actions/workspace/workspaceaction";
import PeopleDetailsForm from "./peopleDetailsForm";
import SettingsSearch from "../common/settingsSearch";
import { ToastContainer, toast } from "react-toastify";
import Avatar from "react-avatar";
import OutsideClickHandler from "react-outside-click-handler";
import NotSubscribed from "../settings/notSubscribed";
import firebase from "../../firebase";

const mapStateToProps = state => {
  return {
    customerLoaderState: state.peopleReducer.customerLoaderState,
    updateCustomerLoaderState:
      state.wrorkSpaceReducer.updateCustomerLoaderState,
    updateEachUserProfileLoaderState:
      state.peopleReducer.updateEachUserProfileLoaderState,
    // listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list,
    selectedCustomerDetails: state.peopleReducer.selectedCustomerDetails,
    workspaceUsersCount: state.peopleReducer.workspaceusers.length,
    userDetails: [
      ...state.peopleReducer.workspaceusers,
      ...state.peopleReducer.invitedEmails,
      ...state.peopleReducer.requestedUsers,
    ],
    invitedEmails: state.peopleReducer.invitedEmails,
    requestedUsers: state.peopleReducer.requestedUsers,
    currentUser: state.loginReducer.loginAuth.user_details,
    billingInfo: state.billingReducer.billingInfo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllCustomerForPeopleAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForPeopleAction({ auth_token, loaderKey }, callback)
      );
    },
    getAllCustomerForUserAction({ auth_token, loaderKey }, callback) {
      dispatch(
        getAllCustomerForUserAction({ auth_token, loaderKey }, callback)
      );
    },
    getInvitedEmailsAction({ auth_token }, callback) {
      dispatch(getInvitedEmailsAction({ auth_token }, callback));
    },
    clearInvitedEmailsAction() {
      dispatch(clearInvitedEmailsAction());
    },
    getRequestedUsersAction({ auth_token }, callback) {
      dispatch(getRequestedUsersAction({ auth_token }, callback));
    },
    getUserDetailsAction({ queryparams, auth_token }, callback) {
      dispatch(getUserDetailsAction({ queryparams, auth_token }, callback));
    },
    updateCustomerDetailsAction(
      auth_token,
      users,
      id,
      users_join_request,
      deleted_users
    ) {
      dispatch(
        updateCustomerDetailsAction(
          auth_token,
          users,
          id,
          users_join_request,
          deleted_users
        )
      );
    },
    updateWorkspaceUsersAfterRemoveUser({ id }) {
      dispatch(updateWorkspaceUsersAfterRemoveUser({ id }));
    },
    updateSubscriptionAction: (
      { existingSubscriptionObj, planType, user_id, action },
      callback
    ) => {
      dispatch(
        updateSubscriptionAction(
          { existingSubscriptionObj, planType, user_id, action },
          callback
        )
      );
    },
  };
};
class PeopleSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shownAddBox: false,
      shownAddppl: false,
      superAdminId: null,
      peopleDropdown: false,
      selectedPeople: { id: 1, text: "All people" },
      searchText: "",
      emailErr: "",
      filteredUsersOwners: [],
      emailsToInvite: [],
      clickedUserDetailsId: null,
      displayedUserDetailsId: "",
      displaySearchBox: false,
      searchedResult: null,
      showOptionIcon: false,
    };
  }
  searchTimer;

  componentDidMount() {
    firebase.analytics().logEvent("GTD_People_Visited");
    const {
      router,
      // getAllCustomerForPeopleAction,
      getAllCustomerForUserAction,
      getInvitedEmailsAction,
      getRequestedUsersAction,
      // currentWorkSpace,
      userDetails,
    } = this.props;

    getRequestedUsersAction(
      { auth_token: localStorage.getItem("customer_auth_token") },
      response => {
        if (!response.stdErr && response?.length >= 0) {
          if (router?.location?.action === "PUSH")
            getAllCustomerForUserAction(
              { auth_token: localStorage.getItem("auth_token") },
              _response => { /* TODO document why this arrow function is empty */ }
            );

          getInvitedEmailsAction(
            { auth_token: localStorage.getItem("customer_auth_token") },
            _response => { /* TODO document why this arrow function is empty */ }
          );
        }
      }
    );

    if (router.location.state && router.location.state.user) {
      this.setState({ clickedUserDetailsId: router.location.state.user });
    } else if (userDetails && userDetails.length > 0)
        this.setState({
          clickedUserDetailsId: userDetails[0].id || userDetails[0].email_invited,
        });

    

    
  }

  componentWillUnmount() {
    this.props.clearInvitedEmailsAction();
  }

  currentWorkSpaceId = () => {
    const { currentWorkspace } = this.props;
    
    if (!_.isUndefined(currentWorkspace)) {
      

      return currentWorkspace;
    }
    return null;
  };

  showAddBox = () => {
    this.setState({
      shownAddBox: true,
    });
  };

  onClickAddPeople = () => {
    // //console.log("show add ppl clicked");
    firebase.analytics().logEvent("GTD_People_Add_User");
    this.setState({
      shownAddppl: true,
    });
  };

  closePpl = () => {
    this.setState({
      shownAddppl: false,
      searchText: "",
      filteredUsersOwners: [],
      emailsToInvite: [],
      emailErr: "",
    });
  };

  cancelShownBox = () => {
    this.setState({
      showAddBox: false,
    });
  };

  togglePeopleDropdown = e => {
    e.stopPropagation();
    this.setState({
      peopleDropdown: !this.state.peopleDropdown,
    });
    
  };

  removePeopleDropdown = e => {
    e.stopPropagation();
    this.setState({
      peopleDropdown: false,
    });
  };

  handlePeopleddwClick = sortedValue => {
    // //console.log("value", e.target.innerText);
    const { userDetails, currentWorkSpace } = this.props;
    let clickedUserDetailsId = null;
    switch (sortedValue.id) {
      case 1:
        clickedUserDetailsId =
          userDetails[0].id || userDetails[0].email_invited;
        break;
      case 2:
        clickedUserDetailsId = userDetails.filter(el => {
          return el.id && currentWorkSpace.users.hasOwnProperty(el.id);
        })[0].id;
        break;
      case 3:
        clickedUserDetailsId = userDetails.filter(el => {
          return currentWorkSpace.users[el.id] === "SUPERADMIN";
        })[0].id;
        break;
      case 4:
        const members = userDetails.filter(el => {
          return currentWorkSpace.users[el.id] === "USER";
        });
        if (members.length > 0) clickedUserDetailsId = members[0].id;
        break;
      case 5:
        let dateOneMonthAgo = new Date();
        dateOneMonthAgo.setDate(dateOneMonthAgo.getDate() - 30);
        const recentlyAddedMembers = userDetails.filter(el => {
          return (
            el.data &&
            el.data.settings &&
            el.data.settings[currentWorkSpace.id] &&
            el.data.settings[currentWorkSpace.id].added_on &&
            moment(el.data.settings[currentWorkSpace.id].added_on).isAfter(
              dateOneMonthAgo
            )
          );
        });
        if (recentlyAddedMembers.length > 0)
          clickedUserDetailsId = recentlyAddedMembers[0].id;
        break;
      case 6:
        const invitedEmails = userDetails.filter(el => {
          return el.email_invited;
        });
        if (invitedEmails.length > 0)
          clickedUserDetailsId = invitedEmails[0].email_invited;
        break;
      default:
        clickedUserDetailsId = userDetails[0].id || null;
    }
    this.setState({
      selectedPeople: sortedValue,
      clickedUserDetailsId,
    });
  };

  showDetails = e => {
    this.setState({
      clickedUserDetailsId: e.user_email || e.id || e.email_invited,
    });
  };

  // Add user to workspace and display the user in existing people list

  onInputChangeOwner = e => {
    const key = e.target.value;
    this.setState({ searchText: key, emailErr: "" });
    

    clearTimeout(this.searchTimer);
    if (key && key.length > 2) {
      this.searchTimer = setTimeout(() => {
        findUserLike({ key }, response => {
          if (response.length > 0) {
            
            const existingUsersOnlyInList = this.state.emailsToInvite.filter(
              user => user.id
            );
            const alreadyInvitedExistingUsers = this.props.invitedEmails
              .filter(user => user.user_details.id)
              .map(user => {
                return { ...user.user_details };
              });
            const filteredArray = _.differenceBy(
              response,
              [
                ...this.props.userDetails,
                ...existingUsersOnlyInList,
                ...alreadyInvitedExistingUsers,
              ],
              "id"
            );

            this.setState({
              filteredUsersOwners: filteredArray,
            });
          } else {
            
            this.setState({
              filteredUsersOwners: [],
            });
            // }
          }
        });
      }, 800);
    } else {
      this.setState({
        filteredUsersOwners: [],
      });
    }
  };

  onAddEmailInList = email => {
    if (email.length > 0 && isEmailValid(email)) {
      const emailAlreadyInvited = this.props.userDetails
        .concat(this.state.emailsToInvite)
        .filter(
          user =>
            (user.email || user.user_email || user.email_invited) === email
        );
      const emailAlreadyInWorkspace = this.props.userDetails.filter(
        user => user.email === email
      );
      const emailAlreadyInInviteList = this.state.emailsToInvite.filter(
        user => user.email === email
      );
      if (emailAlreadyInvited.length === 0) {
        this.setState({
          emailsToInvite: [...this.state.emailsToInvite, { email }],
          searchText: "",
        });
      } else {
        if (emailAlreadyInvited[0].user_email)
          this.setState({ emailErr: "Email already requested to join" });
        else if (emailAlreadyInvited[0].email_invited)
          this.setState({ emailErr: "Email already invited" });
        else if (emailAlreadyInvited[0].email) {
          if (emailAlreadyInWorkspace.length > 0)
            this.setState({ emailErr: "Email already in workspace" });
          if (emailAlreadyInInviteList.length > 0)
            this.setState({ emailErr: "Email already in invite list" });
        }
      }
    } else this.setState({ emailErr: "Invalid email address" });
  };

  onAddUserInList = user => {
    this.setState({
      emailsToInvite: [...this.state.emailsToInvite, user],
      searchText: "",
      filteredUsersOwners: [],
    });
  };

  onInputKeyPress = event => {
    
    const { searchText, filteredUsersOwners } = this.state;
    if (
      searchText.length > 0 &&
      filteredUsersOwners.length === 0 &&
      event.key === "Enter"
    )
      this.onAddEmailInList(searchText);
  };

  onRemoveUser = user => {
    this.setState({
      emailsToInvite: this.state.emailsToInvite.filter(
        emailUser => emailUser.email !== user.email
      ),
    });
  };

  onInviteUser = user => {
    const { currentWorkSpace, getInvitedEmailsAction } = this.props;
    const customer_id = currentWorkSpace.id;
    
    const email = this.state.emailsToInvite
      .filter(user => !user.id)
      .map(user => user.email);
    const existing_users = this.state.emailsToInvite
      .filter(user => user.id)
      .map(user => user.id);
    const auth_token = localStorage.getItem("customer_auth_token");
    
    sendInviteToEmailsToJoinWorkspace(
      { auth_token, customer_id, email, existing_users },
      response => {
        if (response) {
          
          toast("Invitation sent successfully!");
          getInvitedEmailsAction(
            { auth_token: localStorage.getItem("customer_auth_token") },
            _response => { /* TODO document why this arrow function is empty */ }
          );
          this.closePpl();
        } else {
          //console.log("err")
        }
      }
    );
  };

  onOptionIconClick = () => {
    this.setState({ showOptionIcon: !this.state.showOptionIcon });
  };

  handleClickOutside = _event => {
    this.setState({
      showOptionIcon: false,
    });
  };

  resendInvite = userdetail => {
    const customer_id = this.props.currentWorkSpace.id;
    sendInviteToEmailsToJoinWorkspace(
      {
        auth_token: localStorage.getItem("customer_auth_token"),
        customer_id,
        action: "RESEND",
        email: userdetail.user_details.id ? [] : [userdetail.email_invited],
        existing_users: userdetail.user_details.id
          ? [userdetail.user_details.id]
          : [],
        user_id: parseInt(localStorage.getItem("id")),
      },
      response => {
        if (response && response.success) {
          toast("Invitation sent successfully!");
          this.setState({ showOptionIcon: false });
        }
      }
    );
  };

  cancelInvite = userdetail => {
    resendCancelInvite(
      {
        auth_token: localStorage.getItem("customer_auth_token"),
        email_invited: userdetail.email_invited,
        action: "CANCEL",
        invited_by: userdetail.invited_by,
        user_id: parseInt(localStorage.getItem("id")),
      },
      response => {
        if (response) {
          this.setState({
            clickedUserDetailsId:
              this.onSortingUsers()[0].id ||
              this.onSortingUsers()[0].email_invited,
            showOptionIcon: false,
          });
          toast("Invitation canceled successfully");
          this.props.getInvitedEmailsAction(
            { auth_token: localStorage.getItem("customer_auth_token") },
            _response => { /* TODO document why this arrow function is empty */ }
          );
        }
      }
    );
  };

  acceptRequest = user => {
    this.onAddingUserOwner(user);
  };

  DeclineRequest = user => {
    const {
      listOfCustomer,
      updateCustomerDetailsAction,
      getRequestedUsersAction,
    } = this.props;

    if (user) {
      const auth_token = localStorage.getItem("customer_auth_token");
      const currentUserId = parseInt(localStorage.getItem("id"));
      const myWorkspace = listOfCustomer.list.filter(
        workspace => workspace.auth_token === auth_token
      )[0];

      const users_join_request = {
        users: [
          ...myWorkspace.users_join_request.users.map(reqUser => {
            if (reqUser.hasOwnProperty(user.id)) {
              return {
                [user.id]: {
                  ...reqUser[user.id],
                  is_added: false,
                  rejected_by: currentUserId,
                  is_rejected: true,
                },
              };
            }
            return reqUser;
          }),
        ],
      };
      updateCustomerDetailsAction(
        {
          auth_token,
          users: myWorkspace.users,
          id: myWorkspace.id,
          users_join_request,
        },
        response => {
          if (response) {
            getRequestedUsersAction(
              { auth_token: localStorage.getItem("customer_auth_token") },
              _response => { /* TODO document why this arrow function is empty */ }
            );
          }
        }
      );
    }
  };

  // remove people from the current workspace and display the existing people list

  removeFromSelectedOwnerArray = removedId => {
    const {
      listOfCustomer,
      billingInfo,
      updateCustomerDetailsAction,
      workspaceUsersCount,
      // getUserDetailsAction,
      // getAllCustomerForPeopleAction,
      updateWorkspaceUsersAfterRemoveUser,
      updateSubscriptionAction,
    } = this.props;
    const auth_token = localStorage.getItem("customer_auth_token");
    const currentUserId = localStorage.getItem("id");

    if (removedId === currentUserId) {
      // TODO document why this block is empty
    
    } else {
      const myWorkspace = listOfCustomer.list.filter(
        workspace => workspace.auth_token === auth_token
      )[0];

      const deleted_users = { [removedId]: myWorkspace.users[removedId] };

      const newUsersAfterRemove = _.omit(myWorkspace.users, [removedId]);
      myWorkspace.users = newUsersAfterRemove;
      updateCustomerDetailsAction(
        {
          auth_token,
          users: newUsersAfterRemove,
          id: myWorkspace.id,
          deleted_users,
        },
        response => {
          if (response) {
            updateWorkspaceUsersAfterRemoveUser({ id: removedId });
            toast("Member removed!");
            if (!billingInfo.error) {
              let updatedBillingInfo = { ...billingInfo };
              updatedBillingInfo.data[0].quantity = workspaceUsersCount - 1;
              updateSubscriptionAction(
                {
                  existingSubscriptionObj: updatedBillingInfo,
                  planType: billingInfo.data[0].plan.nickname.toLowerCase(),
                  user_id: parseInt(removedId),
                  action: "REMOVE",
                },
                response => {
                  if (response && !response.error) {
                    
                    firebase.analytics().logEvent("GTD_People_Remove_Member");
                  }
                }
              );
            }
            
          } else {
            // TODO document why this block is empty
          
            
          }
        }
      );
    }
  };

  onClickSearch = () => {
    
    firebase.analytics().logEvent("GTD_People_Search_User");
    this.setState({
      displaySearchBox: !this.state.displaySearchBox,
    });
  };

  onShowSearch = searchText => {
    const userDetails = this.onSortingUsers();
    const result = userDetails.filter(
      el =>
        (el.first_name &&
          el.first_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
        (el.email_invited &&
          el.email_invited.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    );
    
    this.setState({
      searchedResult: result,
    });
  };

  onCloseSearch = () => {
    this.setState({
      displaySearchBox: false,
      searchedResult: null,
    });
  };

  fliterOptions = [
    { id: 1, text: "All people" },
    { id: 2, text: "Joined" },
    { id: 3, text: "Owners" },
    { id: 4, text: "Members" },
    { id: 5, text: "Recently added" },
    { id: 6, text: "Invited" },
    { id: 7, text: "Pending Requests" },
  ];

  onSortingUsers = () => {
    const { currentWorkSpace } = this.props;

    let userDetails = this.props.userDetails.sort(function(a, b) {
      let nameA = a.email_invited
        ? a.email_invited.toLowerCase()
        : a.first_name.toLowerCase();
      let nameB = b.email_invited
        ? b.email_invited.toLowerCase()
        : b.first_name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
    let sortedUserDetails = null;
    const sortedValue = this.state.selectedPeople;
    switch (sortedValue.id) {
      case 1:
        sortedUserDetails = userDetails;
        break;
      case 2:
        sortedUserDetails = userDetails.filter(el => {
          return el.id && currentWorkSpace.users.hasOwnProperty(el.id);
        });
        break;
      case 3:
        sortedUserDetails = userDetails.filter(el => {
          return currentWorkSpace.users[el.id] === "SUPERADMIN";
        });
        break;
      case 4:
        sortedUserDetails = userDetails.filter(el => {
          return currentWorkSpace.users[el.id] === "USER";
        });
        break;
      case 5:
        let dateOneMonthAgo = new Date();
        dateOneMonthAgo.setDate(dateOneMonthAgo.getDate() - 30);
        sortedUserDetails = userDetails.filter(el => {
          return (
            el.data &&
            el.data.settings &&
            el.data.settings[currentWorkSpace.id] &&
            el.data.settings[currentWorkSpace.id].added_on &&
            moment(el.data.settings[currentWorkSpace.id].added_on).isAfter(
              dateOneMonthAgo
            )
          );
        });
        break;
      case 6:
        sortedUserDetails = userDetails.filter(el => {
          return el.email_invited;
        });
        break;
      case 7:
        sortedUserDetails = userDetails.filter(el => {
          return el.user_email;
        });
        break;
      default:
        sortedUserDetails = userDetails;
    }
    // //console.log("sortedUserDetails", sortedUserDetails)
    return sortedUserDetails;
  };

  onAddingUserOwner = user => {
    const {
      listOfCustomer,
      // currentUser,
      billingInfo,
      workspaceUsersCount,
      updateCustomerDetailsAction,
      updateSubscriptionAction,
      getAllCustomerForPeopleAction,
      getRequestedUsersAction,
    } = this.props;

    if (user) {
      const auth_token = localStorage.getItem("customer_auth_token");
      const currentUserId = localStorage.getItem("id");
      const myWorkspace = listOfCustomer.list.filter(
        workspace => workspace.auth_token === auth_token
      )[0];
      // user role
      const newUsers = Object.assign(myWorkspace.users, { [user.id]: "USER" });
      myWorkspace.users = newUsers;

      const users_join_request = {
        users: [
          ...myWorkspace.users_join_request.users.map(reqUser => {
            if (reqUser.hasOwnProperty(user.id)) {
              return {
                [user.id]: {
                  ...reqUser[user.id],
                  is_added: true,
                  added_by: currentUserId,
                },
              };
            }
            return reqUser;
          }),
        ],
      };
      updateCustomerDetailsAction(
        { auth_token, users: newUsers, id: myWorkspace.id, users_join_request },
        response => {
          if (response) {
            // add toast
            toast("Member added successfully!");
            this.setState({
              filteredUsersOwners: this.state.filteredUsersOwners.filter(
                displayedUser => user.id !== displayedUser.id
              ),
            });
            getAllCustomerForPeopleAction({}, res => {
              if (res?.length > 0)
                this.setState({ clickedUserDetailsId: user.id });
            });
            getRequestedUsersAction(
              { auth_token: localStorage.getItem("customer_auth_token") },
              _response => { /* TODO document why this arrow function is empty */ }
            );
            if (!billingInfo.error) {
              let updatedBillingInfo = { ...billingInfo };
              updatedBillingInfo.data[0].quantity = workspaceUsersCount + 1;
              updateSubscriptionAction(
                {
                  existingSubscriptionObj: updatedBillingInfo,
                  planType: billingInfo.data[0].plan.nickname.toLowerCase(),
                  user_id: parseInt(user.id),
                  action: "ADD",
                },
                response => {
                  if (response && !response.error) {
                    // TODO document why this block is empty
                  
                    
                  }
                }
              );
            }
          }
        }
      );
    }
  };

  render() {
    const { searchedResult } = this.state;
    const {
      showNoSubscriptionWarning,
      customerLoaderState,
      updateCustomerLoaderState,
      updateEachUserProfileLoaderState,
      currentWorkSpace,
      onTabClick,
    } = this.props;

    let userDetails = null;

    if (!_.isNull(this.state.clickedUserDetailsId)) {
      userDetails = this.props.userDetails.filter(
        user => user.id === this.state.clickedUserDetailsId
      )[0];
    } else if (!_.isUndefined(this.props.userDetails)) {
      const tempUsers = this.props.userDetails.sort(function(a, b) {
        let nameA = a.email_invited
          ? a.email_invited.toLowerCase()
          : a.first_name.toLowerCase();
        let nameB = b.email_invited
          ? b.email_invited.toLowerCase()
          : b.first_name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
      
      userDetails =
        tempUsers.length > 0 && tempUsers[0].id ? tempUsers[0] : null;
    } else {
      userDetails = undefined;
    }
    const renderSuggestionOwnerList = this.state.emailsToInvite.map(
      filteredUsersMember => {
        return (
          <SearchPeopleSuggestions
            key={filteredUsersMember.id || filteredUsersMember.email}
            user={filteredUsersMember}
            onAddingUser={this.onAddingUserOwner}
            onInviteUser={this.onInviteUser}
            onRemoveUser={this.onRemoveUser}
            showDetails={this.showDetails}
            invitedEmails={this.props.invitedEmails}
          ></SearchPeopleSuggestions>
        );
      }
    );

    const renderUserDetails = () => {
      if (this.props.currentWorkSpace) {
        // //console.log(this.state.searchedResult);
        const userDetails =
          this.state.searchedResult && this.state.searchedResult !== ""
            ? this.state.searchedResult
            : this.onSortingUsers();
        
        let renderedAllPeopleList = [];
        if (userDetails.length > 0) {
          renderedAllPeopleList = userDetails.map((userdetail, index, arr) => {
            let name = userdetail.first_name || userdetail.email_invited;
            let firstLetter = name.charAt(0).toUpperCase();
            let length = name.length;
            let newName =
              firstLetter + " " + name.slice(1, length).toLowerCase();

            return (
              <div
                className={
                  (!this.state.clickedUserDetailsId &&
                    userDetails &&
                    userdetail.id &&
                    userDetails[0].id === userdetail.id) ||
                  (this.state.clickedUserDetailsId &&
                    this.state.clickedUserDetailsId === userdetail.id) ||
                  (this.state.clickedUserDetailsId &&
                    this.state.clickedUserDetailsId ===
                      (userdetail.email_invited || userdetail.user_email))
                    ? "ppl-details-holder active"
                    : "ppl-details-holder"
                }
                key={userdetail.id || userdetail.email_invited}
                onClick={() => this.showDetails(userdetail)}
                // style={{ marginRight: "8px", paddingRight: "3px" }}
              >
                <div
                  className={
                    userdetail.id &&
                    userdetail.id === this.props.currentWorkSpace.super_admin_id
                      ? "ppl-details-img-holder creater-class"
                      : "ppl-details-img-holder"
                  }
                >
                  {!(userdetail.img || userdetail?.user_details?.img) && (
                    <Avatar
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      name={newName}
                      maxInitials={1}
                    ></Avatar>
                  )}

                  {(userdetail.img || userdetail?.user_details?.img) && (
                    <img
                      src={userdetail.img || userdetail.user_details.img}
                      width="35px"
                      height="35px"
                      alt="user-img"
                    />
                  )}
                </div>
                <div className="ppl-job-details-holder">
                  <div className="ppl-detail-name">
                    {userdetail.first_name ||
                      userdetail?.user_details?.first_name ||
                      userdetail.email_invited}
                  </div>

                  {/* <div className="ppl-detail-job-title">job_title,team</div>  */}
                  {userdetail.data &&
                    userdetail.data.settings &&
                    userdetail.data.settings.hasOwnProperty(
                      this.props.currentWorkSpace.id
                    ) && (
                      <div className="ppl-detail-job-title">
                        {
                          userdetail.data.settings[
                            this.props.currentWorkSpace.id
                          ].job_title
                        }
                        {userdetail.data.settings[
                          this.props.currentWorkSpace.id
                        ].job_title !== "" &&
                        userdetail.data.settings[this.props.currentWorkSpace.id]
                          .team !== ""
                          ? ", "
                          : ""}
                        {
                          userdetail.data.settings[
                            this.props.currentWorkSpace.id
                          ].team
                        }
                      </div>
                    )}
                  {userdetail.invited_by && (
                    <div className="ppl-detail-job-title">
                      Last invited by {userdetail.invited_by}
                    </div>
                  )}
                  {userdetail.user_email && (
                    <div className="ppl-detail-job-title">Request pending</div>
                  )}
                </div>
                {((userdetail.email_invited &&
                  userdetail.invited_by_id ===
                    parseInt(localStorage.getItem("id"))) ||
                  userdetail.user_email) && (
                  <div
                    className="apc-dot-holder"
                    onClick={this.onOptionIconClick}
                  >
                    <img src={dotIcon} alt="option" />
                    {this.state.showOptionIcon &&
                      (userdetail.email_invited || userdetail.user_email) ===
                        this.state.clickedUserDetailsId && (
                        <OutsideClickHandler
                          onOutsideClick={this.handleClickOutside}
                        >
                          <div
                            className={`apc-options-block ${
                              arr.length === 1
                                ? "one-item"
                                : arr.length > 1 && index === arr.length - 1
                                ? "last-item"
                                : ""
                            }`}
                          >
                            <div
                              className="apc-options-list"
                              onClick={() => {
                                if (userdetail.id && userdetail.user_email)
                                  this.acceptRequest(userdetail);
                                else this.resendInvite(userdetail);
                              }}
                            >
                              {userdetail.user_email
                                ? "Accept"
                                : "Resend invite"}
                            </div>
                            <div
                              className="apc-options-list"
                              onClick={() => {
                                if (userdetail.id && userdetail.user_email)
                                  this.DeclineRequest(userdetail);
                                else this.cancelInvite(userdetail);
                              }}
                            >
                              {userdetail.user_email
                                ? "Decline"
                                : "Cancel invite"}
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>
                )}
              </div>
            );
          });
        } else {
          if (
            this.props.customerLoaderState.fetched &&
            userDetails.length === 0
          )
            renderedAllPeopleList = (
              <div className="nothing-to-show">
                No results found in '{this.state.selectedPeople.text}'
              </div>
            );
        }

        return renderedAllPeopleList;
      } else return <div></div>;
    };

    const filterOptionsRendered = this.fliterOptions.map(filterOption => {
      return (
        <div
          key={filterOption.id}
          className={
            this.state.selectedPeople.text === filterOption.text
              ? "ppl-settings-ddown-list settings-list-options tick-settingsppl"
              : "ppl-settings-ddown-list settings-list-options"
          }
          onClick={_e => this.handlePeopleddwClick(filterOption)}
        >
          {" "}
          {filterOption.text}{" "}
        </div>
      );
    });

    return (
      <div className="ppl-settings-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        {/* loader */}
        <Loader
          size={100}
          loading={
            customerLoaderState.fetching ||
            updateEachUserProfileLoaderState.fetching ||
            updateCustomerLoaderState.fetching
          }
        />
        {/* contact-container */}
        <div className="ppl-settings-contact-container">
          <div className="ppl-settings-search-area">
            {!this.state.displaySearchBox && (
              <div className="ppl-search-control-holder wrap-search-filter">
                <OutsideClickHandler onOutsideClick={this.removePeopleDropdown}>
                  <div
                    className={`all-people-filter ${
                      this.state.peopleDropdown ? "select-filter-open" : ""
                    }`}
                    onClick={this.togglePeopleDropdown}
                  >
                    {this.state.selectedPeople.text} -{" "}
                    {this.onSortingUsers().length}
                    {this.state.peopleDropdown && (
                      <div className="ppl-settings-ddown">
                        {filterOptionsRendered}
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                <img
                  src={searchIcon}
                  onClick={this.onClickSearch}
                  alt="search-icon"
                  style={{ cursor: "pointer" }}
                />
                {/* dropdown of people */}
              </div>
            )}

            {/* Search */}
            {this.state.displaySearchBox && (
              <SettingsSearch
                onShowSearch={this.onShowSearch}
                placeholder={this.state.selectedPeople.text}
                onCloseSearch={this.onCloseSearch}
              />
            )}
          </div>
          <div className="add-people-settings" onClick={this.onClickAddPeople}>
            <img className="imagePlus" src={addIcon} alt="add" />
            <span>Add or Invite people</span>
          </div>
          <div className="ppl-details-container">
            {customerLoaderState.fetched &&
              renderUserDetails().length === 0 && (
                <div className="settings-noresults">
                  Nothing to show in {this.state.selectedPeople.text}
                </div>
              )}
            {renderUserDetails().length > 0 && (
              <Scrollbar className="CustomStyle" id="special">
                {renderUserDetails()}
              </Scrollbar>
            )}
          </div>
        </div>
        <div className="ppl-settings-control-container">
          {userDetails && (!searchedResult || searchedResult.length > 0) && (
            <PeopleDetailsForm
              removeFromSelectedOwnerArray={this.removeFromSelectedOwnerArray}
              currencyLogo={this.props.currencyLogo}
              clickedUserDetailsId={userDetails}
              currentWorkSpaceId={this.props.currentWorkSpace}
              currentUser={this.props.currentUser}
              totalUsers={this.props.userDetails}
              showDetails={this.showDetails}
              listOfCustomer={this.props.listOfCustomer}
              getProjectsAction={this.props.getProjectsAction}
            />
          )}
          {userDetails === undefined &&
            (!searchedResult || searchedResult.length > 0) && (
              <div className=" settings-noresults hide-mgs-ps no-results-ps-msg">
                Details of added people would show up here
              </div>
            )}
          {searchedResult && searchedResult.length === 0 && (
            <div className=" settings-noresults hide-mgs-ps no-results-ps-msg">
              Details of selected people would show up here
            </div>
          )}
        </div>

        {/* add & invite people container start*/}
        {this.state.shownAddppl && (
          <div className="ppl-add-invite-ppl-container">
            <div
              className="ppl-add-invite-ppl"
              style={
                this.state.emailsToInvite.length > 0
                  ? { height: "auto" }
                  : { height: "166px" }
              }
            >
              <div className="ppl-add-invite-ppl-header">
                <span className="add-or-invite">
                  Invite People to {currentWorkSpace.display_name} Workspace
                </span>
                <img
                  src={addClosePpl}
                  onClick={this.closePpl}
                  alt="close-icon"
                />
              </div>
              <div className="search-ppl-invite">
                <img src={settingsSearchIcon} alt="search-icon" />
                <input
                  className="ppl-add-invite-ppl-search"
                  autoFocus
                  placeholder="Search by name or email"
                  value={this.state.searchText}
                  onChange={this.onInputChangeOwner}
                  onKeyPress={this.onInputKeyPress}
                />
                {this.state.emailErr && (
                  <p className="common-err-msg invite-email-err-msg">
                    {this.state.emailErr}
                  </p>
                )}
                {this.state.searchText &&
                  this.state.filteredUsersOwners.length === 0 && (
                    <div
                      className="ppl-email-tick-holder"
                      onClick={() =>
                        this.onAddEmailInList(this.state.searchText)
                      }
                    >
                      <img src={tickIcon} alt="tick" />
                    </div>
                  )}
                {this.state.filteredUsersOwners.length > 0 && (
                  <div className="ppl-suggestions-ddown-container">
                    <div className="ppl-suggestions-ddown">
                      {this.state.filteredUsersOwners.map(
                        filteredUsersMember => {
                          return (
                            <div
                              className="ppl-add-invite-ppl-list-left-side suggestion-left-side"
                              key={filteredUsersMember.id}
                              onClick={() =>
                                this.onAddUserInList(filteredUsersMember)
                              }
                            >
                              <div
                                className="ppl-add-invite-ppl-list-user-img"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  paddingLeft: "0",
                                }}
                              >
                                {/*  sb-avatar sb-avatar--text */}
                                {(_.isNull(filteredUsersMember.img) ||
                                  _.isEmpty(filteredUsersMember.img)) && (
                                  <Avatar
                                    name={filteredUsersMember.first_name}
                                    maxInitials={1}
                                  ></Avatar>
                                )}
                                {filteredUsersMember.img && (
                                  <img
                                    src={filteredUsersMember.img}
                                    alt="user-img"
                                  />
                                )}
                              </div>
                              <div className="ppl-add-invite-ppl-list-user-details">
                                <p className="user-name">
                                  {filteredUsersMember.first_name}
                                </p>
                                <p className="user-domain">
                                  {filteredUsersMember.email}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/************ display the email list**********/}
              {this.state.emailsToInvite.length > 0 && (
                <div className="ppl-add-invite-ppl-list">
                  {/* <Scrollbar> */}
                  {renderSuggestionOwnerList}
                  {/* </Scrollbar> */}
                </div>
              )}
              <div className="ppl-add-invite-ppl-list-right-side">
                <button
                  // className="user-add-action"
                  onClick={this.onInviteUser}
                  disabled={this.state.emailsToInvite.length === 0}
                  style={
                    this.state.emailsToInvite.length === 0
                      ? { background: "#dadada" }
                      : {}
                  }
                >
                  Invite
                </button>
              </div>
            </div>
          </div>
        )}
        {/* add & invite people container end*/}
        {currentWorkSpace && Object.keys(currentWorkSpace.users).length > 5 && (
          <NotSubscribed
            showNoSubscriptionWarning={showNoSubscriptionWarning}
            onTabClick={onTabClick}
          ></NotSubscribed>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleSettings);
