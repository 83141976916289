import moment from "moment";
import _ from "lodash";

export const notificationType = Notifications => {
  let notifications = {
    todaysNotification: [],
    yesterdaysNotification: [],
    olderNotification: []
  };
  let toDay = moment(new Date()).format("YYYY-MM-DD");
  let yesterDay = moment(toDay)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  _.orderBy(
    Notifications,
    obj => {
      return obj.created_at;
    },
    [["desc"]]
  ).forEach(notification => {
    let notificationDate = moment(notification.created_at).format("YYYY-MM-DD");
    if (moment(notificationDate).isSame(toDay)) {
      notifications.todaysNotification = [
        ...notifications.todaysNotification,
        notification
      ];
    } else if (moment(notificationDate).isSame(yesterDay)) {
      notifications.yesterdaysNotification = [
        ...notifications.yesterdaysNotification,
        notification
      ];
    } else if (moment(notificationDate).isBefore(yesterDay)) {
      notifications.olderNotification = [
        ...notifications.olderNotification,
        notification
      ];
    }
  });
  return notifications;
};

export const isUserAdded = msg => {
  var msgSmall = msg.toLowerCase();
  var indexOfAdded = msgSmall.indexOf("added");
  var indexOfRemoved = msgSmall.indexOf("removed");
  if (indexOfAdded > -1 && indexOfRemoved > -1) {
    if (indexOfAdded < indexOfRemoved) {
      return true;
    } else {
      return false;
    }
  }
  if (indexOfAdded > -1 && indexOfRemoved === -1) {
    return true;
  }
  if (indexOfAdded === -1 && indexOfRemoved > -1) {
    return false;
  }
};
export const removeTitleDuplication = (msg, title) => {
  if (msg && title) {
    var msgSmall = msg.toLowerCase();
    msgSmall = msgSmall.replace(/^\s+|\s+$/g, "");

    var titleSmall = title.toLowerCase();
    titleSmall = titleSmall.replace(/^\s+|\s+$/g, "");

    var indexOfTitle = msgSmall.indexOf(titleSmall);
    var lastIndexOfTitle = msgSmall.lastIndexOf(titleSmall);
    var modifiedMsg;
    var lastIndexOfOriginalTitle = msg.lastIndexOf(title);

    if (indexOfTitle > -1 && lastIndexOfTitle > indexOfTitle) {
      modifiedMsg = msg.substring(0, lastIndexOfOriginalTitle);
      return modifiedMsg;
    }
    if (indexOfTitle > 0 && lastIndexOfTitle === indexOfTitle) {
      modifiedMsg = msg.substring(0, lastIndexOfOriginalTitle);
      return modifiedMsg;
    }
    if (indexOfTitle === -1 || indexOfTitle === 0) {
      return msg;
    }
  } else {
    return msg;
  }
};

export const setFcmToken = (token) => {
  localStorage.setItem("fcm_token", token);
};

export const getFcmToken = () => {
  return localStorage.getItem("fcm_token");
}
