import React, { memo } from "react";
import Button from "@material-ui/core/Button";
import "../../styles/scss/commonstyles.scss";

const MaterialButton = props => {
  const { buttonText, variant, color, onClick, className } = props;

  return (
    <Button
      variant={variant}
      className={`${className} text-capitalize`}
      color={color}
      onClick={onClick}
      //disabled={this.state.notValidForm || loginActionState.fetching}
    >
      {buttonText}
    </Button>
  );
};

export default memo(MaterialButton);
