import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Mark from "mark.js";
// import { Form, Control, actions } from "react-redux-form";
import * as action from "../../actions/index";
// import Chip from "@material-ui/core/Chip";
// import { reports } from "../../constants/reportsconstants";
import Avatar from "react-avatar";
// import TextareaAutosize from "react-textarea-autosize";
import { secondsToHms } from "../../helpers/generichelper";
import { fetchTimesheetAction } from "../../actions/activities/activitiesaction";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import RenderDatePicker from "../common/renderDatePicker";
// import TimeField from "../activity/timeField";
// import delete_icon_chip from "../../styles/img/svg/deleteIcon.svg";
import closeIcon from "../../styles/img/svg/Close_White.svg";
// import arrowDown from "../../styles/img/svg/md-select-arrow.svg";
// import arrowUp from "../../styles/img/svg/arrow_up.svg";
import { getQueryParamsForTimeSheet } from "../../helpers/reportlanding";
import TextField from '@material-ui/core/TextField';
import DateRangePicker from "../common/dateRangePicker";
import AttachmentDropdown from "../attachments/attachmentDropdown";
import { withStyles } from "@material-ui/core/styles";
import calIcon from "../../styles/img/svg/calendar-flow.svg";
import DialogWrapper from "../common/dialog";
import AttachmentErrPop from "../activity/attachmentErrPop";

const styles = theme => ({
  input: {
    height: 45,
    "&.MuiOutlinedInput-focused": {
      "fieldset": {
        borderColor: "#C52328",
        borderWidth: "1px"
      }
    }
  },
  label: {
    transform: "translate(14px, 15px) scale(1)"
  },
  overviewInput: {
    minHeight: 81
  },
  dateInput: {
    height: 45,
    width: 200,
    background: `url(${calIcon}) no-repeat right 18px center`,
    backgroundSize: 16
  }
});

class EditReport extends Component {
  state = {
    title: "",
    project: "",
    projectSearchValue: "",
    showProjectDropDown: false,
    hours: 0,
    minutes: 0,
    seconds: 0,
    selectedTags: [],
    projectList: [],
    selectedProject: "",
    activityTagFieldValue: "",
    showTypeDropDownUI: false,
    date: "",
    dateView: "",
    description: "",
    openAttachmentDropdown: false,
    attachmentFrom: "",
    displayErrMsg:false,
    attachmentOneDrive: false,
    attachmentsToEdit: [],
    attachmentsFile: [],
    showAttachmentErrPop: false,
    attachmentsToRemove: "",
    clickedImg: null,
    fetching: false
  };
  highlightTimer;

  componentDidMount() {
    // if (document.getElementsByClassName("report-edit")[0]) {
    //   document
    //     .getElementsByClassName("report-edit")[0]
    //     .parentElement.classList.add("create_activity-parent");
    // }
    const { timeSheet, projectList } = this.props;
    // console.log("title", timeSheet);
    const { hours, mins, seconds } = secondsToHms(timeSheet.time_taken);
    this.setState({
      title: timeSheet.title,
      project: timeSheet.project_name,
      selectedTags: [timeSheet.activity_type],
      activityTagFieldValue: timeSheet.activity_type || "",
      selectedProject: timeSheet.project_obj,
      projectList,
      date: moment(timeSheet.date)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(),
      dateView: timeSheet.date_view,
      hours,
      minutes: mins,
      seconds,
      description: timeSheet.description,
      attachmentsToEdit: timeSheet.attachments
    });
  }

  componentDidUpdate() {
    if (document.getElementsByClassName("daterangepicker").length > 0) {
      let i;
      for(i = 0; i < document.getElementsByClassName("daterangepicker").length; i++) {
        document
        .getElementsByClassName("daterangepicker")[i]
        .classList.add("edit-report-picker-single");
      }
    }
    const innerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].childNodes[0].clientHeight;
    const outerHeight = document.querySelectorAll('[role="dialog"]')[0].childNodes[1].clientHeight
    // console.log(outerHeight, innerHeight)
    if(innerHeight > outerHeight) {
      document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "auto";
    }
    // else if(innerHeight < outerHeight) {
    //   document.querySelectorAll('[role="dialog"]')[0].childNodes[1].style.height = "100%";
    // }
    if (document.getElementsByClassName("report-edit")[0]) {
      document
        .getElementsByClassName("report-edit")[0]
        .parentElement.classList.add("activity-popup-container-parent");
    }
  }

  onTitleChange = event => {
    this.setState({ title: event.target.value });
  };

  onShowProjectDropDown = () => {
    this.setState({ showProjectDropDown: true });
  };

  onProjectOutsideClick = () => {
    this.setState({ showProjectDropDown: false, projectSearchValue: "" });
  };

  showActivityTypeDropdown = () => {
    this.setState({
      showTypeDropDownUI: true
    });
  };

  onChangeTypeField = e => {
    this.setState({
      activityTagFieldValue: e.target.value
    });
    //console.log(e);
    if (e.target.value.length > 0) {
      this.setState({
        showTypeDropDownUI: true
      });
    } else {
      // this.setState({
      //   showTypeDropDownUI: false
      // });
    }
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState({
        selectedTags: [e.target.value],
        showTypeDropDownUI: false
      });
    }
  };

  onTypeOptionSelected = tag => {
    //console.log("tag selected", tag);
    this.setState({
      selectedTags: [tag],
      showTypeDropDownUI: false,
      activityTagFieldValue: tag
    });
  };

  onActivityTypeOutsideClick = () => {
    const typedValue = this.state.activityTagFieldValue;
    if (typedValue.length > 0)
      this.setState({
        selectedTags: [typedValue],
        showTypeDropDownUI: false,
        activityTagFieldValue: typedValue
      });
    else this.setState({ showTypeDropDownUI: false });
  };

  onRemovingSelectedTags = tag => {
    this.setState({
      selectedTags: [],
      activityTagFieldValue: ""
    });
  };

  onProjectInputChange = (event) => {
    this.setState({ projectSearchValue: event.target.value })
  }

  onProjectOptionClicked = project => {
    this.setState({
      project: project.name,
      selectedProject: project,
      showProjectDropDown: false,
      projectSearchValue: ""
    });
  };

  onEditFieldChangeEnd = selectedDay => {
    //console.log(selectedDay);
    this.setState({
      date: moment(selectedDay)
        .set({ hour: 0, minute: 0, second: 0 })
        .format()
    });
  };

  onChangeCompletedDate = (e, picker) => {
    this.setState({ date: moment(picker.startDate._d).set({ hour: 0, minute: 0, second: 0 }).format() })
  }

  onHoursChange = e => {
    const re = /^[0-9]*$/;
    const { date, minutes } = this.state;
    let maxMinutes;

    if (e.target.value.length > 0 && re.test(e.target.value)) {
      // console.log("hours min", minutes);
      let startValue;
      let maxHoursDay;
      startValue = moment().startOf("day");
      maxHoursDay = moment(date).isSame(new Date(), "day")
        ? parseInt(moment().diff(startValue, "hours", true))
        : 23;
      if (minutes) {
        // console.log("hours min", minutes);
        maxMinutes =
          moment(date).isSame(new Date(), "day") &&
          maxHoursDay === parseInt(e.target.value)
            ? parseInt(moment().diff(startValue, "minutes", true)) % 60
            : null;
      }
      if (parseInt(e.target.value) > maxHoursDay) {
        return this.setState({
          hours: maxHoursDay,
          minutes: maxMinutes || minutes
        });
      }
      this.setState({
        hours: parseInt(e.target.value),
        minutes: maxMinutes || minutes
      });
    }
    this.setState({
      hours: parseInt(e.target.value),
      minutes: maxMinutes || minutes
    });
    // else {
    //   this.setState({
    //     hours: 0
    //   });
    // }
  };

  onMinutesChange = e => {
    const re = /^[0-9]*$/;
    const { date, hours } = this.state;

    if (e.target.value.length > 0 && re.test(e.target.value)) {
      let maxHoursDay;
      let maxMinutes = 59;
      const startValue = moment().startOf("day");
      maxHoursDay = moment(date).isSame(new Date(), "day")
        ? parseInt(moment().diff(startValue, "hours", true))
        : 23;
      maxMinutes =
        moment(date).isSame(new Date(), "day") && maxHoursDay === hours
          ? parseInt(moment().diff(startValue, "minutes", true)) % 60
          : 59;
      if (parseInt(e.target.value) > maxMinutes) {
        return this.setState({
          minutes: maxMinutes
        });
      }
      this.setState({
        minutes: parseInt(e.target.value)
      });
    }
    this.setState({
      minutes: parseInt(e.target.value)
    });
    // else {
    //   this.setState({
    //     minutes: 0
    //   });
    // }
  };

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        // rect.top >= 0 &&
        // rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  onChangeDescription = event => {
    this.setState({ description: event.target.value });
    const inputBox = document.querySelector(".report-edit");
    if(inputBox && this.isInViewport(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
  };

  onFocusDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "#0090e9";
    document.getElementsByClassName("completed-description")[0].classList.add("textfield-focused");
  }

  onBlurDescription = () => {
    if(document.querySelector(".add-comp-act"))
      document.querySelector(".add-comp-act").style.borderColor = "rgba(0, 0, 0, 0.23)";
    document.getElementsByClassName("completed-description")[0].classList.remove("textfield-focused");
  }

  isInViewportWhole(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  openAttachmentDropdown = () => {
    this.setState({
      openAttachmentDropdown: !this.state.openAttachmentDropdown
    }, () => {
      const inputBox = document.querySelector(".attach-files-dropdown-container");
    if (inputBox && this.isInViewportWhole(inputBox) === false) {
      inputBox.scrollIntoView({ behavior: "auto", block: "end" });
    }
    });
  };

  chkMaxSize = (size) => {
    if (size > 30000000) {
      return true;
    } else {
      return false;
    }
  };

  handleMaxSize = (size, type) => {
    let sizeOfFile = this.chkMaxSize(size);
    if (sizeOfFile) {
      this.setState({
        displayErrMsg: true
      }, () => {
        setTimeout(() => {
          this.setState({ displayErrMsg: false })
        }, 5000)
      });
    } else {
      this.setState({
        displayErrMsg: false
      });
    }
  };

  handleAttachFrom = (from) => {
    this.setState({
      attachmentFrom: from
    });
  };

  closeAttachmentDropdown = (response, attachments) => {
    // console.log(response);
    if (response && response.length > 0 && (response[0].src || response[0].url || response[0].type)) {
  
    this.setState(
        {
          attachmentFrom: "",
          openAttachmentDropdown: false,
          attachmentsToEdit: [ ...this.state.attachmentsToEdit, ...response ],
          attachmentsFile: [ ...this.state.attachmentsFile, ...attachments ]
        });
    } else if (response && response.url) {
        this.setState(
          {
            attachmentFrom: "",
            openAttachmentDropdown: false,
            attachmentsToEdit: [ ...this.state.attachmentsToEdit, response ],
            attachmentsFile: [ ...this.state.attachmentsFile, response ]
          }
        );
    } else {
      if(!this.state.attachmentOneDrive){
        this.setState({
          attachmentFrom: "",
          openAttachmentDropdown: false
        });
      }
    }
  };

  isImage = (url) => {
    let format = url;
    let ext = format.substr(format.lastIndexOf(".") + 1).toLowerCase();
    if(url.indexOf(";base64") > -1) {
      if (url.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    } else if (
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "svg" ||
      ext === "gif"
    ) {
      return true;
    } else {
      return false;
    }
  };

  getSize = (size) => {
    if (size < 1000) return size + "Bytes";
    else if (size > 1000 * 1000) return Math.round(size / (1000 * 1000)) + "MB";
    else return Math.round(size / 1000) + "KB";
  };

  onRemoveAttachment = (attachment) => {
    this.setState({ attachmentsToEdit: this.state.attachmentsToEdit.filter(att => {
      if(att.src && attachment.src)
        return att.src !== attachment.src
      else if(att.url && attachment.url)
        return att.url !== attachment.url
      else if(att.size && att.name) 
        return (att.size !== attachment.size && att.name !== attachment.name)
      else return true
    }
      ),
      attachmentsFile: !attachment.id ?
        this.state.attachmentsFile.filter(file => {
          if(file.size && file.name) 
          return (file.size !== attachment.size && file.name !== attachment.name)
        else if(attachment.url && file.url)
          return file.url !== attachment.url
        else return true;
      })
      : this.state.attachmentsFile,
      attachmentsToRemove: attachment.id ?
      this.state.attachmentsToRemove.length === 0 ? this.state.attachmentsToRemove.concat(attachment.id) : this.state.attachmentsToRemove.concat(`,${attachment.id}`)
      : this.state.attachmentsToRemove
    });
  }

  updateAttchmetnOneDrive=(flag)=>{
    this.setState({attachmentOneDrive:flag})
  }

  onFormValidate = () => {
    const { title, activityTagFieldValue, hours, minutes, description } = this.state;
    const validTitle = title ? true : false;
    const validActivityTag = activityTagFieldValue.length > 0 ? true : false;
    const validDesc = description && description.trim() ? true : false;
    const validHours = hours && hours > 0 ? true : false;
    const validMinutes = minutes && minutes > 0 ? true : false;
    const validTime = validHours || validMinutes ? true : false;
    if (validTitle && validActivityTag && validDesc && validTime) return false;
    return true;
  };

  onSave = event => {
    event.preventDefault();
    // console.log("on save", this.props.timeSheet)
    const {
      selectedProject,
      // selectedTags,
      activityTagFieldValue,
      title,
      hours,
      minutes,
      seconds,
      date,
      description,
      attachmentsToEdit
    } = this.state;
    const {
      timeSheet,
      editTimeSheetAction,
      updateActivityReportAction,
      // updateTimeSheetList,
      // getSelectedWorkpsaceActivities,
      // getProjectsAction,
      // selectedCustomerDetails,
      // loggedInUser,
      // listOfTimeSheet,
      fetchTimesheetAction,
      filters,
      getTimeSheetsAction,
      searchContent,
      // from,
      // to,
      onPopupCloseEditCallBack
    } = this.props;
    const duration = (hours || 0) * 3600 + (minutes || 0) * 60 + seconds;
    let dateStartToSend = moment(date).isSame(timeSheet.date, "day")
      ? moment(timeSheet.entryStart).format()
      : moment(date).format();
    let local_date_time = moment(date).isSame(moment(timeSheet.date), "day")
      ? moment(timeSheet.date).format()
      : moment(date)
          .add(23, "h")
          .add(59, "m")
          .add(59, "s")
          .format();
    let dateEnd = moment(date).format();
    let dateEndToSend = moment(date).isSame(timeSheet.date, "day")
      ? moment(timeSheet.entryStart)
          .add(hours, "h")
          .add(minutes, "m")
          .add(seconds, "s")
          .format()
      : moment(dateEnd)
          .add(hours, "h")
          .add(minutes, "m")
          .add(seconds, "s")
          .format();
    let activityBody = {
      id: timeSheet.activity_obj.id,
      project_id: selectedProject.id,
      title,
      version_id: timeSheet.activity_obj.version_id
    };
    let newTimeSheetBody = {
      activity_id: timeSheet.activity_obj.id,
      data: {
        ...timeSheet.data,
        TIME: [
          {
            DESCRIPTION: description.trim(),
            INTERVAL: duration,
            START: dateStartToSend,
            STOP: dateEndToSend
          }
        ],
        // timer_stopped_by_app: false
      },
      description: description.trim(),
      attachments: attachmentsToEdit.filter(attachment => attachment.id),
      id: timeSheet.id,
      local_date_time,
      member: [timeSheet.user_id],
      tags: [activityTagFieldValue],
      timeTaken: duration,
      title,
      user_id: parseInt(this.props.loggedInUser.id)
    };
    // console.log("newTimeSheetBody", newTimeSheetBody);

    // Multipart/form-data attachments start
    const { attachmentsFile, attachmentsToRemove } = this.state;
    let formData;
    if(attachmentsFile.length > 0 || attachmentsToRemove.length > 0) {
      formData = new FormData();
      attachmentsFile.forEach((file, index) => {
        if (file instanceof File)
          formData.append(`attachment${index + 1}`, file);
        else
          formData.append(`attachment${index + 1}`, JSON.stringify(file));
      })
      formData.append("new_attachment_count", attachmentsFile.length);
      formData.append("entity_name", "timesheet");
      formData.append("entity_id", timeSheet.id);
      attachmentsToRemove.length > 0 && formData.append("removed_attachment_id", attachmentsToRemove);
    } else {
      formData = null;
    }
    // Multipart/form-data attachments end

    if(newTimeSheetBody.data.timer_stopped_by_app) {
      newTimeSheetBody.data.timer_stopped_by_app = false;
    }

    this.setState({ fetching: true });

    editTimeSheetAction({ body: newTimeSheetBody }, formData, (newTimeSheetResponse, attRes) => {
      if (
        newTimeSheetResponse &&
        !newTimeSheetResponse.error &&
        !newTimeSheetResponse.stdErr
      ) {
        // console.log("new-timesheet response", newTimeSheetResponse);
        updateActivityReportAction({ body: activityBody }, activityResponse => {
          // console.log("updateActivityReport", activityResponse);
          // if (
          //   moment(newTimeSheetResponse.date).isSame(moment(new Date()), "day")
          // ) {
          fetchTimesheetAction(
            {
              from_date: moment()
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment().format("YYYY-MM-DD"),
              user_id: parseInt(this.props.loggedInUser.id)
            },
            response => {
              //console.log(response);
            }
          );
          const { projectId, memberId, from, to,activityType } = filters;
          let queryParam = getQueryParamsForTimeSheet(
            projectId,
            memberId,
            from,
            to,
            activityType
          );

          if(searchContent) {
            queryParam = `${queryParam}&like=${searchContent}`
          }

          getTimeSheetsAction(
            {
              auth_token: localStorage.getItem("customer_auth_token"),
              queryParam
            },
            response => {
              // console.log("filter response", response);
              if(searchContent) {
                clearTimeout(this.highlightTimer);
                var context = [
                  ...document.querySelectorAll(".td_title"),
                  ...document.querySelectorAll(".td_des")
                ];
                var instance = new Mark(context);
                instance.unmark();
                this.highlightTimer = setTimeout(() => {
                  var updatedContext = [
                    ...document.querySelectorAll(".td_title"),
                    ...document.querySelectorAll(".td_des")
                  ];
                  var updatedInstance = new Mark(updatedContext);
                  updatedInstance.mark(searchContent, { element: "span", className: "marked-search", accuracy: "partially" });
                }, 100);
              }
            }
          );
          if(attRes?.stdErr) this.setState({ showAttachmentErrPop: true });
          else onPopupCloseEditCallBack();
        });
      }
    });
  };

  onClickImg = (url) => {
    this.setState({ clickedImg: url });
    document
      .querySelector('[role="dialog"]')
      .setAttribute("id", "noscrolldialog");
  };

  onClosePreview = () => {
    this.setState({ clickedImg: null });
    document
      .querySelector('[role="dialog"]')
      .removeAttribute("id", "noscrolldialog");
  };

  onCloseAttachmentErrPop = () => {
    this.props.onPopupCloseEditCallBack();
    this.setState({ showAttachmentErrPop: false });
  }

  render() {
    const {
      title,
      project,
      projectSearchValue,
      selectedProject,
      // selectedTags,
      activityTagFieldValue,
      showTypeDropDownUI,
      showProjectDropDown,
      date,
      // dateView,
      hours,
      minutes,
      description,
      attachmentsToEdit,
      showAttachmentErrPop,
      clickedImg
    } = this.state;
    const { timeSheet, projectList, classes, selectedCustomerDetails } = this.props;
    let projectListToShow = projectList.filter(
      project => project.id !== "all" && project.archived !== true
    );
    if(projectSearchValue) {
      projectListToShow = projectListToShow.filter(
        element =>
          element.name.toLowerCase().indexOf(projectSearchValue.toLowerCase()) > -1
      );
    }
    const tags =
      selectedProject &&
      selectedProject.data &&
      selectedProject.data.settings &&
      selectedProject.data.settings.activity_type;
    const renderActivityTypes =
      tags &&
      tags
        .filter(
          tag =>
            tag.toLowerCase().indexOf(activityTagFieldValue.toLowerCase()) > -1
        )
        .map(tag => {
          return (
            <li
              key={`${tag}`}
              className="type-li"
              onClick={() => this.onTypeOptionSelected(tag)}
            >
              <span>{tag}</span>
            </li>
          );
        });
    // const renderChip = selectedTags.map(tag => {
    //   //console.log("inside render Chips", tag);
    //   return (
    //     <Chip
    //       key={tag}
    //       label={tag}
    //       deleteIcon={
    //         <img
    //           src={delete_icon_chip}
    //           className="tag-chip-Input-close-Icon"
    //           alt={"delete Chip"}
    //         />
    //       }
    //       className="selectedTagsChip edit-report"
    //       onDelete={() => this.onRemovingSelectedTags(tag)}
    //     />
    //   );
    // });

    let dialogPropsAttErr = {
      className: "dialog_wrapper",
      showPopup: showAttachmentErrPop,
      dialogTitle: () => `Attachment Error`,
        // onCloseCallBack: this.onCloseGooglePopup,
        shouldButtonEnable: true
    };

    return (
      <form className="edit-report-form">
        <div className="re-controls-container edit-board-look">
          <div className="re-control-holder">
          <TextField
              id="outlined-name"
              autoComplete="off"
              label="Activity Title"
              className="description-box activity-title"
              placeholder="Enter a title for this Activity"
              variant="outlined"
              InputProps={{
                className: classes.input
              }}
              InputLabelProps={{
                className: classes.label
              }}
              value={title}
              onChange={this.onTitleChange}
              size="small"
              fullWidth={true}
              onFocus={() => {
                document.getElementsByClassName("activity-title")[0].classList.add("textfield-focused");
              }}
              onBlur={() => {
                document.getElementsByClassName("activity-title")[0].classList.remove("textfield-focused");
              }}
            />
          </div>

          <div className="re-control-holder">
            <div
              // className="cus-autocomp-control proj-ddown-control"
              onClick={this.onShowProjectDropDown}
            >
              <TextField
                    type="text"
                    label="Project"
                    placeholder="Select a Project for this Activity"
                    className="description-box activity-project"
                    variant="outlined"
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    value={project}
                    // onClick={this.onProjectFocus}
                    // className="cus-autocomp-control"
                    autoComplete="off"
                    fullWidth={true}
                    model=".project"
                    onChange={this.onProjectInputChange}
                    innerRef={node => (this.projectNameNode = node)}
                    onFocus={() => {
                      document.getElementsByClassName("activity-project")[0].classList.add("textfield-focused");
                    }}
                    onBlur={() => {
                      document.getElementsByClassName("activity-project")[0].classList.remove("textfield-focused");
                    }}
                  />
              {/* <img
                src={arrowDown}
                className={showProjectDropDown ? "proj-arrow" : ""}
              /> */}
            </div>
            {showProjectDropDown && (
              <OutsideClickHandler onOutsideClick={this.onProjectOutsideClick}>
                <div className="cus-autocomp-ddown-holder proj-ddown-holder">
                <input
                    type="text"
                    className="add-act-project-search"
                    id="projectlist"
                    placeholder="Search"
                    autoFocus
                    onKeyUp={this.onProjectInputChange}
                  />
                  {projectListToShow.length > 0 && (
                    <div className="cus-autocomp-ddown-inner-holder">
                      {projectListToShow &&
                        projectListToShow.map(element => {
                          return (
                            <div
                              className="cus-autocomp-ddown-list add-act-proj-ddown"
                              key={element.id}
                              onClick={() =>
                                this.onProjectOptionClicked(element)
                              }
                            >
                              {(_.isNull(element.img) || _.isEmpty(element.img)) === true ? (
                  <div className="img-container">
                    <Avatar
                      style={{
                      width: "100%",
                      height: "100%"
                      }}
                      name={element.name}
                      maxInitials={1}
                      ></Avatar>
                  </div>
                  ) : (
                    <div className="img-container">
                      <img src={element.img} alt="user-img" />
                    </div>
                )}
                              {element.name}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            )}
          </div>
          {/*Old activity type UI*/}
          {/* <div className="re-control-holder">
            <div className="re-tarea-title-container">
              <div className="re-tarea-title">Activity Type</div>
            </div>
            <OutsideClickHandler
                onOutsideClick={this.onActivityTypeOutsideClick}
              >
            <input
              type="text"
              value={activityTagFieldValue}
              className="cus-autocomp-control"
               onFocus={this.showActivityTypeDropdown}
              onChange={this.onChangeTypeField}
              // placeholder={
              //   selectedTags.length === 0 ? "Enter the type of activity" : ""
              // }
              disabled={selectedTags.length > 0 ? true : false}
            />



            {selectedTags.length > 0 && renderChip}
            {showTypeDropDownUI && (
             
                <div className="edit-board-ddown activityTypeBoardDown">
                  <div className="activityTagsInput">
                    <ul className="activity-tags-ui">{renderActivityTypes}</ul>
                  </div>
                </div>
              
            )}
              </OutsideClickHandler>
          </div> */}

          

          <div className="re-control-holder">
            <div className="re-member-area edit-activity">
              <div className="add-member-activity">Members</div>
              <div className="add-member-row inside-activity">
                <div
                  className="user-img-holder edit-report-img"
                  // ng-repeat="user in reportEditScope.timesheet.member"
                  // ng-disabled="true"
                >
                  <Tooltip title={timeSheet.user_name}>
                  {(_.isNull(timeSheet.user_img) || _.isEmpty(timeSheet.user_img)) === true ||
                      timeSheet.user_img === "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
                      (timeSheet.user_img &&
                        timeSheet.user_img.trim() === "https://s3.amazonaws.com/gtd/users/default.png") ? (
                        <Avatar
                          style={{ width: "100%", height: "100%" }}
                          name={timeSheet.user_name}
                          maxInitials={1}
                        />
                      ) : (
                        <img src={timeSheet.user_img} alt="user-img" />
                    )}
                  </Tooltip>
                  {/* <md-tooltip md-direction="bottom">{{ reportEditScope.userMap[user].first_name }}</md-tooltip> */}
                </div>
              </div>
            </div>
          </div>

          <div className="re-control-holder report-row">
            <div className="rd-date-area edit-date-time">
            <div className="add-act-date-range-holder">
                      <DateRangePicker
                        startDate={moment(date).format("M/DD/YYYY")}
                        endDate={moment(date).format("M/DD/YYYY")}
                        maxDate={moment(new Date()).format("M/DD/YYYY")}
                        onChangeDate={this.onChangeCompletedDate}
                        source={"EDIT_ACTIVITY"}
                        allowedDays={
                          selectedCustomerDetails.data.past_timesheet_restricted ?
                          selectedCustomerDetails.data.allowed_days : null
                        }
                      >
                        <TextField
                          label="Date"
                          placeholder="Select a due date"
                          variant="outlined"
                          className="description-box edit-ts-date-input"
                          value={moment(date).format("ddd, DD MMM YYYY")}
                          InputProps={{
                            className: classes.dateInput
                          }}
                          InputLabelProps={{
                            className: classes.label
                          }}
                          onFocus={() => {
                            document.getElementsByClassName("edit-ts-date-input")[0].classList.add("textfield-focused");
                          }}
                          onBlur={() => {
                            document.getElementsByClassName("edit-ts-date-input")[0].classList.remove("textfield-focused");
                          }}
                        />
                      </DateRangePicker>
                    </div>
            </div>

            <div className="rd-tt-time-area duration">
              <div className=" report-duration">Duration</div>
              <div className="act-time-inner-holder">
              <div className="rd-hours-area">
                <input
                  type="number"
                  value={parseInt(hours) > 0 ? hours : ""}
                  onChange={this.onHoursChange}
                  placeholder="hh"
                  onKeyPress={e => {
                    if (!(e.which > 47 && e.which < 58) || e.shiftKey) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
              </div>
              :
              <div className="rd-hours-area">
                <input
                  type="number"
                  value={parseInt(minutes) > 0 ? minutes : ""}
                  onChange={this.onMinutesChange}
                  placeholder="mm"
                  onKeyPress={e => {
                    if (e.which === 46 || e.which === 45) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            </div>
          </div>

          {this.state.selectedProject && <div className="re-control-holder">
              <div className="cus-autocomp-holder">
                <div className="cus-autocomp-inner-holder">
                  <TextField
                    type="text"
                    label="Activity Type"
                    placeholder="Search an Activity Type or add a custom one for this Activity"
                    variant="outlined"
                    className="description-box activity-act-type"
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    value={activityTagFieldValue}
                    onClick={this.showActivityTypeDropdown}
                    // className="cus-autocomp-control"
                    autoComplete="off"
                    fullWidth={true}
                    model=".project"
                    onChange={this.onChangeTypeField}
                    innerRef={node => (this.projectNameNode = node)}
                    onFocus={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.add("textfield-focused");
                    }}
                    onBlur={() => {
                      document.getElementsByClassName("activity-act-type")[0].classList.remove("textfield-focused");
                    }}
                  />
                  {showTypeDropDownUI && (
                    <OutsideClickHandler
                    onOutsideClick={this.onActivityTypeOutsideClick}
                  >
                    <div className="edit-board-ddown activity-type-down edit-report-act-types">
                      <div className="activityTagsInput">
                        <ul className="activity-tags-ui">
                          {renderActivityTypes}
                        </ul>
                      </div>
                    </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            </div>}

            <div className="re-control-holder first">
            <TextField
              id="outlined-name"
              label="Description"
              placeholder="Enter description"
              variant="outlined"
              model=".title"
              InputProps={{
                className: classes.overviewInput
              }}
              className={`description-box completed-description ${attachmentsToEdit.length > 0 ? "with-attachment" : ""}`}
              value={description}
              onChange={this.onChangeDescription}
              onFocus={this.onFocusDescription}
              onBlur={this.onBlurDescription}
              fullWidth={true}
              color="#0090e9"
              multiline
              rows="2"
              size='medium'
              rowsMax="200"
            />
                  {attachmentsToEdit.length > 0 && 
            <div className="comm-att-holder add-comp-act">
                    <React.Fragment>
                      {attachmentsToEdit.map((attachment, index) => {
                        const attachmentURL = (attachment.src || attachment.url || attachment.type);
                        const isImage = attachment.src ? true : false;
                        return <div
                        className="nw-att-row-container"
                        key={index}
                      >
                        <div className="nw-att-row-left">
                          <div
                            className={
                              this.isImage(attachmentURL) || isImage
                                ? "nw-att-img-holder comm-att"
                                : "nw-att-img-holder notimage comm-att"
                            }
                          >
                            {(this.isImage(attachmentURL) || isImage) && (
                              <img
                                src={decodeURI(attachmentURL)}
                                alt="att-img"
                                onClick={() => this.onClickImg(decodeURI(attachmentURL))}
                              />
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf(".google.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Google Drive</span>
                                </a>
                              )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf("www.dropbox.com") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">Dropbox</span>
                                </a>
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf("https://1drv.ms/") > -1 && (
                                <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
                                  <span className="spanDisplay">One Drive</span>
                                </a>
                            )}
                            {!this.isImage(attachmentURL) && !isImage &&
                              attachmentURL.indexOf(".google.com") < 0 &&
                              attachmentURL.indexOf("www.dropbox.com") < 0 &&
                              attachmentURL.indexOf("https://1drv.ms/") < 0 && (
                                <a>
                                  <span className="spanDisplay">
                                    {attachment.title && attachment.title.substr(
                                      attachment.title.lastIndexOf(".") + 1
                                    )}
                                    {attachment.name && attachment.name.substr(
                                      attachment.name.lastIndexOf(".") + 1
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        </div>
                        {/*  */}
                        <div className="nw-att-row-right comm-att">
                          <div className="nw-att-details-holder">
                            <div className="nw-att-details-name comm-att truncate-text">
                              <a
                                style={this.isImage(attachmentURL) || isImage ? { cursor: "pointer" } : {}}
                                onClick={() => (this.isImage(attachmentURL) || isImage) && this.onClickImg(decodeURI(attachmentURL))}
                              >
                                {attachment.title || attachment.name}
                              </a>
                            </div>
                            <div className="nw-att-details-time comm-att">
                              <span>{this.getSize(attachment.size)}</span>
                            </div>
                            <a
                              className="nw-att-link download comm-att"
                              onClick={() => this.onRemoveAttachment(attachment)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                      })}
                    </React.Fragment>
                </div>
                  }
            </div>
            <div className="act-att-holder">
            <div className="comment-attachment-area">
                  <OutsideClickHandler
                    onOutsideClick={this.closeAttachmentDropdown}
                  >
                      <div
                        className="comp-act-att"
                        onClick={this.openAttachmentDropdown}
                      >Add Attachment</div>
                    {this.state.openAttachmentDropdown && (
                      <AttachmentDropdown
                        closeAttachmentDropdown={this.closeAttachmentDropdown}
                        handleMaxSize={this.handleMaxSize}
                        handleAttachFrom={this.handleAttachFrom}
                        attachedFrom={"completedActivity"}
                        updateAttchmetnOneDrive={this.updateAttchmetnOneDrive}
                      />
                    )}
                  </OutsideClickHandler>
                </div>
            </div>
            {
            this.state.displayErrMsg && 
            (
                      <div class="new-comm-attacherror add-act">
                        The file size exceeds 30 MB{" "}
                      </div>
                    )}
          {this.state.fetching && <CircularProgress
                        classes={{ root: "circular-progress-edit-report" }}
                        size={20}
                        style={{ marginLeft: "10px" }}
                      ></CircularProgress>}
          <button
            className={`done-link-button-bottom ${
              this.onFormValidate() || this.state.fetching ? "" : "button_active_report"
            }`}
            // ng-click="reportEditScope.update()"
            onClick={this.onSave}
            disabled={this.onFormValidate() || this.state.fetching}
          >
            Save
          </button>
        </div>
        {clickedImg && (
            <div className="attachmentpopviews">
              <img src={clickedImg} className="attachmentimage" alt="att-img" />
              <img
                className="closeattachmentpopview"
                src={closeIcon}
                alt="close"
                onClick={this.onClosePreview}
              />
            </div>
          )}
        {showAttachmentErrPop && (
            <DialogWrapper dialogProps={dialogPropsAttErr}>
              <AttachmentErrPop
                fromEditReport={true}
                onClosePopup={this.onCloseAttachmentErrPop} />
            </DialogWrapper>
          )}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    loggedInUser: state.loginReducer.loginAuth.user_details,
    filters: state.timeSheetReducer.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editTimeSheetAction: ({ body }, formData, callback) =>
      dispatch(action.editTimeSheetAction({ body }, formData, callback)),
    updateActivityReportAction: ({ body }, callback) =>
      dispatch(action.updateActivityReportAction({ body }, callback)),
    fetchTimesheetAction: ({ from_date, to_date, user_id }, callback) =>
      dispatch(fetchTimesheetAction({ from_date, to_date, user_id }, callback)),
    getProjectsAction({ auth_token, queryParam }, callback) {
      dispatch(action.getProjectsAction({ auth_token, queryParam }, callback));
    },
    getTimeSheetsAction: ({ auth_token, queryParam }, callback) => {
      dispatch(
        action.getTimeSheetsAction({ auth_token, queryParam }, callback)
      );
    }
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditReport));
