import React from "react";

import "../../styles/scss/joinWorkspace.scss";

import { Loader } from "../common/loader";

export default function ImageUploader(props) {
  const { clientLogo, uploadFileRef, imageLoading, clickOnUploadFile, uploadInitiated } = props;
  return (
    <div className="workspace-upload-image finish-profile-upload">
      <div className="upload-image">
        <Loader
          size={100}
          loading={imageLoading}
          left={-190}
          top={-25}
        />
        {!clientLogo ? <div 
          className="img-profile"
          onClick={clickOnUploadFile}
          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
        >
          <span>Add Photo</span>
        </div> : <img
          src={clientLogo}
          alt="logo"
          onClick={clickOnUploadFile}
          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
        />}

        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
          ref={ref => uploadFileRef(ref)}
          style={{ display: "none" }}
          onChange={uploadInitiated}
        />
      </div>
    </div>
  );
}
