import React, { useState, memo } from "react";
// import OutsideClickHandler from "react-outside-click-handler";
// import { Scrollbar } from "react-scrollbars-custom";
import userSearch from "../../styles/img/svg/user-search.svg";
import Avatar from "react-avatar";
import _ from "lodash";

const MembersBox = props => {
  const [searchText, setSearchText] = useState("");
  const [updatedWorkSpaceUsers, setUpdatedWorkSpaceUsers] = useState(null);

  const onChangeSearch = (event) => {
    let searchText = event.target.value;
    const { workSpaceUsers } = props;
    let updatedWorkSpaceUsers = workSpaceUsers.filter(
      (workSpaceUser) =>
        workSpaceUser.first_name
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1 ||
        workSpaceUser.email.toLowerCase().indexOf(searchText.toLowerCase()) !==
          -1
    );
    setSearchText(searchText);
    setUpdatedWorkSpaceUsers(updatedWorkSpaceUsers);
  };

  const onSelectMember = (event, workspaceUserId, workspaceUserName) => {
    props.onSelectMember(event, workspaceUserId, workspaceUserName);
  };

  const { workSpaceUsers, selectedMemberId } = props;
  const workSpaceUsersToView = updatedWorkSpaceUsers || workSpaceUsers;
  return (
    <div className="member-dropdown">
      <div className="demo-select-header">
        <div className="user-search">
          <img src={userSearch} alt="search-icon" />
        </div>
        <input
          type="search"
          className="demo-header-searchbox md-text"
          placeholder="Search for a member"
          onChange={onChangeSearch}
        />
      </div>
      <div label="users" className="member-outside">
        {workSpaceUsersToView.map((workspaceUser, index) => (
          <div
            key={index}
            className={`user-row ${
              workspaceUser.id === selectedMemberId && "active-member"
            }`}
            onClick={event =>
              onSelectMember(event, workspaceUser.id, workspaceUser.first_name)
            }
          >
            <div className="user-img-icon">
              {_.isNull(workspaceUser.img) || _.isEmpty(workspaceUser.img) ? (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  maxInitials={1}
                  name={workspaceUser.first_name}
                ></Avatar>
              ) : (
                <img src={workspaceUser.img} alt="wp-img" />
              )}
            </div>
            <div className="user-name">{workspaceUser.first_name}</div>
          </div>
        ))}

        {workSpaceUsersToView.length === 0 && workSpaceUsers && (
          <div className="no-results-report-filter">No results found</div>
        )}
      </div>
    </div>
  );
};

export default memo(MembersBox);
