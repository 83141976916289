import React from "react";
import Avatar from "react-avatar";
import _ from "lodash";
export default function SearchSuggestions(props) {
  const { user, onAddingUser, onInviteUser, OnInputClicked } = props;
  if (user.id && user.first_name) {
    return (
      <div className="search-data-list add-user-list" onClick={OnInputClicked}>
        {_.isNull(user.img) ||
        user.img === "" ||
        user.img === "//d30rgbmectrk9r.cloudfront.net/users/default.png" ||
        (user.img &&
          user.img.trim() ===
            "https://s3.amazonaws.com/gtd/users/default.png") ? (
          <div className="user-img-holder add-user-avatar">
            <Avatar
              // color={Avatar.getRandomColor("sitebase", ["#ff9292", "#7c9af1"])}
              style={{ width: "100%", height: "100%" }}
              name={user.first_name}
              maxInitials={1}
            ></Avatar>
          </div>
        ) : (
          <div className="user-img-holder add-user-img-holder">
            <img src={user.img} alt="user-img" />
          </div>
        )}

        <div className="user-details-holder user-workspace">
          <div className="user-name add-user-ws">{user.first_name}</div>
          <div className="user-email add-user-ws">{user.email}</div>
        </div>
        <div className="user-add-action" onClick={() => onAddingUser(user)}>
          Add
        </div>
      </div>
    );
  } else {
    return (
      <div className="search-data-list" onClick={OnInputClicked}>
        {user.email && (
          <div className="user-img-holder">
            <Avatar
              // color={Avatar.getRandomColor("sitebase", ["#ff9292", "#7c9af1"])}
              style={{ width: "100%", height: "100%" }}
              name={user.email}
              maxInitials={1}
            ></Avatar>
          </div>
        )}
        {/* <div className="user-img-icon">
          <img src={user.img || "//s3.amazonaws.com/gtd/users/default.png"} />
        </div> */}
        <div className="user-details-holder invite-email user-workspace">
          <div className="user-email add-user-ws">{user.email}</div>
        </div>
        <div className="user-add-action" onClick={() => onInviteUser(user)}>
          Invite
        </div>
      </div>
    );
  }
}
