import React from "react";
import { isAppleSignIn } from "../../helpers/loginconstants";
import "../../styles/scss/user_profile.scss";

const ChangeGooglePopup = ({ loginAgain, isGoogleId, showRemovedFromWpPopup, passwordChangedOtherPlatform, onClosePopup }) => {
  return (
    <div className="pop-up-password google" id={loginAgain ? "login-again-popup" : "change-google-popup"}>
      <div className="message">
        {isGoogleId && <span>
          This account was signed in using {localStorage.getItem(isAppleSignIn) ? "Apple" : "Google"}. Update your password from
          your {localStorage.getItem(isAppleSignIn) ? "Apple" : "Google"} account instead.
        </span>}
        {loginAgain && <span>
          {passwordChangedOtherPlatform ? "Session expired" : "Your password has been changed successfully"}. Please log in to continue.
        </span>}
        {showRemovedFromWpPopup && <span>
          You are no longer part of the current workspace. Please log in to continue.</span>}
      </div>
      <div className="act-submit-btn-holder change-password-btn google">
        <button className="act-submit-btn google" onClick={onClosePopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default ChangeGooglePopup;
