import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import Mark from "mark.js";
import {
  unarchiveActivitiesAction,
  updateArchiveActivitiesWorkFlowAction
} from "../../actions/activities/activitiesaction";
import { getMoreSelectedWorkpsaceArchivedActivities } from "../../actions/workspace/workspaceaction";
import DialogWrapper from "../common/dialog";
import ActivityPopupTop from "../activity/activityPopupTop";
import ActivityForm from "../activity/addactivity";
import ActivityPopupBottom from "../activity/activityPopupBottom";
import ActivityTile from "./activityTile";

import "../../styles/scss/archiveCenter.scss";

import InfiniteScroll from "react-infinite-scroll-component";


class MoreActivity extends Component {
  state = {
    isPopupEnable: false,
    popupActivityDetails: null,
    page: 2,
    showProjectDropDown: false,
    selectedProject: { id: 0, name: "All" },
    appliedProject: { id: 0, name: "All" },
    popupActivityDetailsEdit: null,
    isPopupEnableEdit: false,
    fromActivityInnerAdd: false
  };

  static getDerivedStateFromProps(props, state) {
    if(props.searchContent.text && state.page !== 2) {
      return {
        ...state,
        page: 2
      }
    }
    return null;
  }

  setIsPopupEnable = (activity) => {
    this.setState({
      isPopupEnable: true,
      popupActivityDetails: activity,
      popupActivityDetailsEdit: activity
    });
  };

  setIsPopupEnableEdit = (fromActivityInnerAdd) => {
    this.setState({
      isPopupEnableEdit: true,
      fromActivityInnerAdd: fromActivityInnerAdd ? fromActivityInnerAdd : false
    });
  };

  onPopupCloseCallBack = () => {
    this.setState(() => ({
      isPopupEnable: false,
      popupActivityDetails: {}
    }));
  };

  onUnarchiveActivity = (activity) => {
    const { loggedInUser, unarchiveActivities } = this.props;
    const customer_auth_token = localStorage.getItem("customer_auth_token");

    const archiveData = {
      id: activity.id,

      data: {
        ...activity.data,
        lastModifiedBy: parseInt(loggedInUser.id),
        archivedUsers: activity.data.archivedUsers.filter(
          (user) => user !== parseInt(loggedInUser.id)
        ),
        priority: activity.data.priority
          ? activity.data.priority.filter(
              (user) => user.user_id !== parseInt(loggedInUser.id)
            )
          : []
      },
      version_id: activity.version_id,
      is_active: true
    };
    
    unarchiveActivities(
      { auth_token: customer_auth_token, archiveData },
      (_response) => {
        this.onPopupCloseCallBack();
      }
    );
  };

  onShowMore = () => {
    const {
      getMoreSelectedWorkpsaceArchivedActivitiesAction,
      loggedInUser
    } = this.props;
    if(!this.props.searchContent.text)
      this.setState(
        {
          page: this.state.page + 1
        },
        () => {
          getMoreSelectedWorkpsaceArchivedActivitiesAction(
            {
              activity_type: "ACTIVITY",
              is_active: true,
              user_id: loggedInUser.id,
              is_archived: true,
              page: this.state.page,
              item_per_page: 10
            });
        }
      );
  };

  onShowProjectDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showProjectDropDown: !this.state.showProjectDropDown
    });
  };

  onOutsideClick = (e) => {
    if (this.menuOptionsNode && !this.menuOptionsNode.contains(e.target)) {
      if (this.state.showProjectDropDown)
        this.setState({
          showProjectDropDown: false
        });
    }
  };

  onProjectClicked = (project) => {
    this.setState({
      selectedProject: project
    });
  };

  applyingSelection = () => {
    this.setState({
      appliedProject: this.state.selectedProject
    }, () => {
      if(this.props.searchContent.text) {
        var context = document.querySelectorAll(".comm-act-title");
        var instance = new Mark(context);
        instance.unmark();
        setTimeout(() => {
          var updatedContext = document.querySelectorAll(".comm-act-title");
          var updatedInstance = new Mark(updatedContext);
          updatedInstance.mark(this.props.searchContent.text, { element: "span", className: "marked-search", accuracy: "partially" });
        }, 100)
      }
    });
  };

  render() {
    const {
      activityLoaderState,
      archievedActivities,
      // searchContent,
      // archivedSearchedActivities,
      updateArchiveActivitiesWorkFlow,
      unarchiveActivities,
      loggedInUser,
      listOfProjects,
      listOfArchivedProjects
    } = this.props;
    const {
      isPopupEnable,
      popupActivityDetails,
      popupActivityDetailsEdit,
      // noOfActivity,
      showProjectDropDown,
      selectedProject,
      appliedProject
    } = this.state;
    const uniqArchivedActivities = _.uniqBy(archievedActivities, "id");
    const filteredActivities =
      appliedProject.id === 0
        ? uniqArchivedActivities
        : uniqArchivedActivities.filter(
            (activity) => activity.project.id === appliedProject.id
          );
    const activityRender = _.orderBy(
      filteredActivities,
      [
        (archivedActivity) => {
          let completedOn = new Date(archivedActivity.completed_on);
          return completedOn.getTime();
        }
      ],
      ["desc"]
    )
      // .reverse()
      .map((activity) => {
        
        if (!activity.workflow) {
          activity = { ...activity, workflow: { id: 1, name: "Backlog" } };
        }
        return (
          <ActivityTile
            key={activity.id}
            activity={activity}
            loggedInUser={loggedInUser}
            setIsPopupEnable={this.setIsPopupEnable}
            updateArchiveActivitiesWorkFlowAction={
              updateArchiveActivitiesWorkFlow
            }
            unarchiveActivitiesAction={unarchiveActivities}
          ></ActivityTile>
        );
        
      });
    const dialogProps = {
      className: "activity-popup-container",
      showPopup: this.state.isPopupEnable,
      dialogTitle: () => { /* TODO document why this method 'dialogTitle' is empty */ },
      onCloseCallBack: this.onPopupCloseCallBack,
      shouldButtonEnable: true,
      shouldScrollUpdate:true
    };
    let dialogPropsEdit = {
      className: "create_activity",
      showPopup: this.state.isPopupEnableEdit,
      dialogTitle: () => "Edit Actvity",
      onCloseCallBack: () =>
        this.setState({
          isPopupEnableEdit: false,
          fromActivityInnerAdd: false,
          popupActivityDetailsEdit: null
        }),
      onConfirmCallBack: () => { /* TODO document why this method 'onConfirmCallBack' is empty */ },
      shouldButtonEnable: true
    };
    const renderProjectOptions = listOfProjects
      .concat(listOfArchivedProjects)
      .map((project) => {
        return (
          <li
            key={project.id}
            className="project-options"
            onClick={() => this.onProjectClicked(project)}
          >
            {project.name}
          </li>
        );
      });
    return (
      <div className="arch-center-tt-container with-no-scroll">
        <div className="comm-filter-control-tt-container filter-view archive-project">
          <div
            className="comm-filter-control-container archive-project"
            ref={(node) => (this.menuOptionsNode = node)}
          >
            <div className="comm-filter-control-title">Project :</div>
            <div
              className={
                showProjectDropDown
                  ? "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown clicked"
                  : "comm-filter-control-holder workflow-change-ddown-holder proj-filter-ddown"
              }
              onClick={this.onShowProjectDropdown}
            >
              <div class="workflow-change-ddown-text">
                {" "}
                {selectedProject.name}
              </div>
              {showProjectDropDown && (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                  <div class="workflow-change-ddown">
                    {/* <ScrollBar> */}
                      <ul class="workflow-change-ddown-inner">
                        <li
                          className="project-options"
                          onClick={() =>
                            this.onProjectClicked({ id: 0, name: "All" })
                          }
                        >
                          All
                        </li>
                        {renderProjectOptions}
                      </ul>
                    {/* </ScrollBar> */}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <button
            className="comm-filter-btn apply"
            onClick={this.applyingSelection}
          >
            Apply
          </button>
        </div>
        {isPopupEnable && (
          <DialogWrapper dialogProps={dialogProps}>
            <ActivityPopupTop
              activityDetails={
                archievedActivities
                  .filter((activity) => activity.id === popupActivityDetails.id)
                  .map((activity) => {
                    if (!activity.workflow) {
                      activity = {
                        ...activity,
                        workflow: { id: 1, name: "Backlog" }
                      };
                      return activity;
                    }
                    return activity;
                  })[0]
              }
              onClosePopup={dialogProps.onCloseCallBack}
              setIsPopupEnableEdit={this.setIsPopupEnableEdit}
              onArchiveItem={() =>
                this.onUnarchiveActivity(this.state.popupActivityDetails)
              }
              source="FROM_ARCHIVE"
              changeWorkFlowAction={updateArchiveActivitiesWorkFlow}
            />
            <ActivityPopupBottom
              activityDetailsAsProps={this.state.popupActivityDetails}
              is_archived={true}
            />
          </DialogWrapper>
        )}
        {/* <ScrollBar> */}

        <div
          className="arch-categ-outer-container"
          id="arch-categ-outer-container-scroll"
        >
          <InfiniteScroll
            dataLength={uniqArchivedActivities.length}
            // onScroll={() => console.log("scrolled")}
            next={() => {
              
              this.onShowMore();
            }}
            hasMore={true}
            scrollableTarget="arch-categ-outer-container-scroll"
          >
            <div className="arch-categ-container">
              <div className="arch-categ-holder all-activities">
                {activityRender}
              </div>
            </div>
          </InfiniteScroll>
        </div>
        {activityLoaderState.fetched &&
          filteredActivities &&
          filteredActivities.length === 0 && (
            <div className="no-checklist-found">
              No archived activities found
            </div>
          )}
        {this.state.isPopupEnableEdit && (
          <DialogWrapper dialogProps={dialogPropsEdit}>
            <ActivityForm
              logedinUserDetails={loggedInUser}
              fromActivityInnerAdd={this.state.fromActivityInnerAdd}
              selectedActivity={
                archievedActivities.filter(
                  (activity) => activity.id === popupActivityDetailsEdit.id
                )[0]
              }
              source="FROM_ARCHIVE"
              {...dialogPropsEdit}
            />
          </DialogWrapper>
        )}

        {/* </ScrollBar> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loginReducer.loginAuth.user_details,
    activityLoaderState: state.activityReducer.activityLoaderState,
    searchContent: state.commonReducer.searchContent,
    archievedActivities: state.activityReducer.listOfArchivedActivity.list,
    archivedSearchedActivities: state.activityReducer.listOfSearchedArchivedActivity.list,
    listOfProjects: state.projectReducer.listOfProjects.list,
    listOfArchivedProjects: state.projectReducer.listOfArchivedProjects.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMoreSelectedWorkpsaceArchivedActivitiesAction(
      {
        auth_token,
        client_id,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page
      },
      callback
    ) {
      dispatch(
        getMoreSelectedWorkpsaceArchivedActivities(
          {
            auth_token,
            client_id,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page
          },
          callback
        )
      );
    },
    unarchiveActivities: (
      { auth_token, archiveData, user_id },
      callback
    ) => {
      dispatch(
        unarchiveActivitiesAction(
          { auth_token, archiveData, user_id },
          callback
        )
      );
    },
    updateArchiveActivitiesWorkFlow: (
      { id, workflow, customer_auth_token },
      callback
    ) => {
      dispatch(
        updateArchiveActivitiesWorkFlowAction(
          { id, workflow, customer_auth_token },
          callback
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreActivity);
