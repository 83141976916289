import React, { Component } from "react";
import { parseComment, commonDateFormat } from "../../helpers/generichelper";
import ReactHtmlParser from "react-html-parser";
import { Tooltip } from "@material-ui/core";
import {
  changeIsReadStatusOfYouTabAction,
  changeFlagStatusOfYouTabAction,
  changeIsReadStatusOfAllTabAction,
  changeFlagStatusOfAllTabAction
} from "../../actions/notifications/notificationaction";
import { connect } from "react-redux";
import checklist from "../../styles/img/svg/notify-checklist.svg";
import firebase from "../../firebase";
import Avatar from "react-avatar";

function mapDispatchToProps(dispatch) {
  return {
    changeIsReadStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfYouTab: (
      { notificationArray, ddownOption, ddownOption2, searchText},
      callback
    ) => {
      dispatch(
        changeFlagStatusOfYouTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText},
          callback
        )
      );
    },
    changeIsReadStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeIsReadStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    },
    changeFlagStatusOfAllTab: (
      { notificationArray, ddownOption, ddownOption2, searchText },
      callback
    ) => {
      dispatch(
        changeFlagStatusOfAllTabAction(
          { notificationArray, ddownOption, ddownOption2, searchText },
          callback
        )
      );
    }
  };
}
class NotifyChecklist extends Component {
  
  youNotificationReadStatus = (event, notification) => {
    firebase.analytics().logEvent("GTD_Notifications_Read_Unread_Toggle");
    event.stopPropagation();
    if (notification.is_read === false) {
      notification.is_read = true;
    } else if (notification.is_read === true) {
      notification.is_read = false;
    }
    // send put request to update status

    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_read"] = notification.is_read;
    notificationArray.push(obj);
    const {
      changeIsReadStatusOfYouTab,
      changeIsReadStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeIsReadStatusOfYouTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    } else if (optionValue === "all") {
      changeIsReadStatusOfAllTab({
        notificationArray,
        ddownOption,
        ddownOption2,
        searchText
      });
    }
  };
  notificationFlagStatus = (event, notification) => {
    firebase.analytics().logEvent("GTD_Notifications_Flag_Unflag_Toggle");
    event.stopPropagation();
    if (notification.is_flagged === false) {
      notification.is_flagged = true;
    } else if (notification.is_flagged === true) {
      notification.is_flagged = false;
    }
    // send put request to update
    let notificationArray = [];
    var obj = {};
    obj["id"] = notification.id;
    obj["is_flagged"] = notification.is_flagged;
    notificationArray.push(obj);
    const {
      changeFlagStatusOfYouTab,
      changeFlagStatusOfAllTab,
      optionValue,
      ddownOption,
      ddownOption2,
      searchText
    } = this.props;
    if (optionValue === "you") {
      changeFlagStatusOfYouTab({ notificationArray, ddownOption, ddownOption2, searchText});
    } else if (optionValue === "all") {
      changeFlagStatusOfAllTab({ notificationArray, ddownOption, ddownOption2, searchText });
    }
  };
  render() {
    const { notification } = this.props;
    return (
      <div
        class="notify-card"
        onClick={() => this.props.goToChecklist(notification)}
        style={{ cursor: "pointer" }}
      >
        <div className="right-comment-notification-title comments-notifi">
        <Tooltip
          title={notification.is_read ? "Mark as unread" : "Mark as read"}
        >
          <div
            className={
              notification.is_read === true
                ? "notify-read-unread read"
                : "notify-read-unread"
            }
            onClick={event =>
              this.youNotificationReadStatus(event, notification)
            }
          ></div>
        </Tooltip>

        <Tooltip
          title={
            notification.is_flagged
              ? "Unflag this notification"
              : "Flag this notification"
          }
        >
          <div
            className={
              notification.is_flagged === true
                ? "notifi-flag flagged"
                : "notifi-flag"
            }
            onClick={event => this.notificationFlagStatus(event, notification)}
          ></div>
        </Tooltip>
        <div class="right-notfi-title-img">
          {/* <div class="notify-user-img"> */}
          {notification.sender_info.img ? <img src={notification.sender_info.img} alt={notification.sender_info.first_name} /> : 
              <Avatar
                style={{ width: "100%", height: "100%" }}
                name={notification.sender_info.first_name}
                maxInitials={1}
                />
            }
          {/* </div> */}
          {/* <div class="notify-type-icon user-member">
            <img src={checklist} />
          </div> */}
        </div>
        <div class="Card_Title">
          <div
            class="notify-card-title"
            style={{ cursor: "pointer" }}
            // onClick={() => this.props.goToChecklist(notification)}
          >
            {notification.recipient_info.id === notification.sender_info.id ?
            "You" : notification.sender_info.first_name} mentioned 
            {notification.recipient_info.id === notification.sender_info.id ? " yourself " : " you "}
             in the checklist
            <span class="activity-title">
              {" " + notification.data.checklist.title + " "}
            </span>{" "}
            in the activity
            <span class="activity-title">
              {" " + notification.data.activity.title + " "}
            </span>{" "}
            under the project
            <span class="activity-title">
              {" " + notification.data.project.name}
            </span>
          </div>
          <p class="comment_post_time">{commonDateFormat(notification.created_at)}</p>
        </div>
        </div>

        <div className="right-comment-desc-you">
          <div className="right-comment-notfi-icon">
            <img
              src={checklist}
              alt="checklist-icon"
            />
          </div>
          <div className="right-comment-notifi-desc">
            <div className="notifi-comments-desc-holder">
              <div className="description">
                {/* <ReactMarkdown
                  source={parseComment(commentTitle, notification.id)}
                  escapeHtml={false}
                /> */}
                {ReactHtmlParser(parseComment(notification.data.checklist_item.title))}
                {/* {ReactHtmlParser(parseComment(commentTitle))} */}
              </div>
            </div>
          </div>
          <div className="clear"></div>
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(NotifyChecklist);
