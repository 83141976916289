import update from "immutability-helper";
import moment from "moment";
import _ from "lodash";
// import { getIsSuperAdmin } from "./reportlanding";
// import { projectConstants } from "../constants/projectconstants";

export const getProjectObjToUpdate = (obj, projectDetails) => {
  let {
    name,
    group,
    clientLogo,
    img_name,
    selectedStatus,
    start,
    due,
    estimated_hours,
    budget,
    engagement,
    description,
    activity_type,
    // kanban_list,
    // workflow,
    // workflowOrder,
    workflow_changes
  } = obj;
  for (var prop in workflow_changes) {
    if (workflow_changes[prop].length === 0) {
        delete workflow_changes[prop];
    }
  }
  let newBody = update(projectDetails, {
    name: { $set: name },
    group: { $set: group },
    img: { $set: clientLogo },
    img_name: { $set: img_name },
    status: { $set: selectedStatus },
    // workflow: { $set: workflow },
    client: { $set: null },
    client_id: { $set: null },
    data: {
      // workflowOrder: { $set: workflowOrder },
      start: {
        $set: moment(new Date(start)).utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
      },
      due: { $set: moment(new Date(due)).utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ") },
      settings: {
        planned_hours: {
          $set: estimated_hours ? parseInt(estimated_hours) : 0
        },
        budget: { $set: budget },
        engagement_type: { $set: engagement },
        description: { $set: description },
        activity_type: { $set: activity_type },
        // kanban_list: { $set: kanban_list }
      }
    },
    workflow_changes: { $set: workflow_changes }
  });
  newBody = _.pickBy(newBody, _.identity);
  delete newBody.created_at;
  delete newBody.updated_at;
  return newBody;
};

export const getStatusView = status => {
  if (status === "ACTIVE") return "Active";
  if (status === "ON_HOLD") return "On hold";
  if (status === "COMPLETED") return "Completed";
  if (status === "CANCELLED") return "Cancelled";
};

export const getProjectMembers = (
  projectDetails,
  workSpaceUsers,
  currentWorkSpace
) => {
  let projectMembers = [];
  if (projectDetails.data.settings.hasOwnProperty("member_access")) {
    //-----------Filter workspace Creator as well as Owners-------------------
    // const workSpaceOwnerDetails = workSpaceUsers.filter(user => {
    //   if (currentWorkSpace.users.hasOwnProperty(user.id)) {
    //     if (currentWorkSpace.users[user.id] === "SUPERADMIN") return user;
    //   }
    //   //user.id === currentWorkSpace.super_admin_id
    // });
    // workSpaceOwnerDetails.forEach(workSpaceOwner => {
    //   projectMembers.push({
    //     team: null,
    //     userId: workSpaceOwner.id,
    //     name: workSpaceOwner.first_name,
    //     access: "Owner",
    //     widget: ["Reports", "Add/Remove members"]
    //   });
    // });
    //-------------------End-----------------------------------
    //--------Filter only creator of workspace--------------------------
    const workSpaceCreatorDetails = workSpaceUsers.filter(user => 
        parseInt(currentWorkSpace.super_admin_id) === parseInt(user.id)
      )[0];
    if(workSpaceCreatorDetails)
      projectMembers.push({
        team: null,
        userId: workSpaceCreatorDetails.id,
        name: workSpaceCreatorDetails.first_name,
        access: "Creator",
        widget: ["Reports", "Add/Remove members"]
      });
    //------------------------End--------------------------------------
    workSpaceUsers.forEach(user => {
      if (
        projectDetails.data.settings.member_access.hasOwnProperty(user.id) &&
        projectDetails.data.settings.member_access[user.id]
      ) {
        if (_.findIndex(projectMembers, { userId: user.id }) < 0)
          projectMembers.push({
            team: projectDetails.data.settings.member_access[
              user.id
            ].hasOwnProperty("team")
              ? projectDetails.data.settings.member_access[user.id].team
              : null,
            userId: user.id,
            name: user.first_name,
            access: projectDetails.data.settings.member_access[user.id].access,
            widget: projectDetails.data.settings.member_access[
              user.id
            ].hasOwnProperty("widget")
              ? projectDetails.data.settings.member_access[user.id].widget
              : []
          });
      }
    });
  }
  return projectMembers;
};

export const getUpdatedProjectObj = (projectDetails, projectMember, access) => {
  // console.log("updated acl", projectMember);
  if (
    projectDetails.data.settings.member_access.hasOwnProperty(
      projectMember.userId
    )
  ) {
    let projectDetailsToUpdate = {};
    if (access === "Remove") {
      let { acl } = projectDetails.data;
      projectDetailsToUpdate = update(projectDetails, {
        client: { $set: null },
        client_id: { $set: null },
        data: {
          acl: { $splice: [[acl.indexOf(projectMember.userId), 1]] },
          settings: {
            member_access: {
              $apply: function(obj) {
                var copy = Object.assign({}, obj);
                delete copy[projectMember.userId];
                return copy;
              }
            }
          }
        }
      });
    } else {
      let widget = [];
      if (
        projectDetails.data.settings.member_access[
          projectMember.userId
        ].hasOwnProperty("widget")
      ) {
        widget = [
          ...projectDetails.data.settings.member_access[projectMember.userId]
            .widget
        ];
      }
      if (access === "Manager") {
        widget.push("Reports");
        widget.push("Add/Remove members");
      } else {
        widget.length = 0;
      }
      projectDetailsToUpdate = update(projectDetails, {
        client: { $set: null },
        client_id: { $set: null },
        data: {
          settings: {
            member_access: {
              [projectMember.userId]: {
                $set: { team: projectMember.team, widget: widget, access }
              }
            }
          }
        }
      });
    }
    projectDetailsToUpdate = _.pickBy(projectDetailsToUpdate, _.identity);
    delete projectDetailsToUpdate.created_at;
    delete projectDetailsToUpdate.updated_at;
    return projectDetailsToUpdate;
  }
};

export const getUpdatedProjectToAddPeople = (
  projectDetails,
  workSpaceUsers,
  selectedWorkSpaceUsers,
  currentWorkSpaceId,
  currentWorkSpace
) => {
  let acl;
  // let filteredWorkSpaceUsers = selectedWorkSpaceUsers.filter()
  let usersForMemberAccess = selectedWorkSpaceUsers.filter(
    selectedWorkSpaceUser =>
      currentWorkSpace.super_admin_id !== parseInt(selectedWorkSpaceUser)
      // && currentWorkSpace.users[parseInt(selectedWorkSpaceUser)] !==
      //   projectConstants.superAdmin
  );
  //------------ Manually adding Owners to "acl" of project (start) ------------------------
  // let allOwnersExceptCreator = workSpaceUsers.filter(wpUser => 
  //   currentWorkSpace.super_admin_id !== parseInt(wpUser.id) &&
  //     currentWorkSpace.users[parseInt(wpUser.id)] ===
  //       projectConstants.superAdmin).map(wpOwner => wpOwner.id)
  //------------- Manually adding Owners to "acl" of project (end) -------------------------
  if (projectDetails.data.hasOwnProperty("acl")) {
    acl = [...new Set([...projectDetails.data.acl, ...usersForMemberAccess])];
  } else {
    acl = [...new Set([...usersForMemberAccess])];
  }
  let projectDetailsToUpdate = update(projectDetails, {
    client: { $set: null },
    archived: { $set: false },
    data: {
      acl: { $set: acl },
      settings: {
        member_access: {
          $apply: function(obj) {
            let copy = Object.assign({}, obj);
            let toAdd = {};
            //------- Manually adding Owners to "member_access" of project (start) --------
            // allOwnersExceptCreator.forEach(owner => {
            //   if (!copy.hasOwnProperty(owner)) {
            //     let wpUserDetails = workSpaceUsers
            //       .filter(wpOwner => wpOwner.id === owner)[0]
            //     let team = wpUserDetails && wpUserDetails.data && wpUserDetails.data.settings &&
            //       wpUserDetails.data.settings[currentWorkSpaceId] &&    
            //       wpUserDetails.data.settings[currentWorkSpaceId].team || null;
            //     toAdd[owner] = {
            //       team,
            //       widget: ["Reports", "Add/Remove members"],
            //       access: "Owner"
            //     };
            //   }
            // });
            //------- Manually adding Owners to "member_access" of project (end) --------
            usersForMemberAccess.forEach(selectedWorkSpaceUser => {
              if (!copy.hasOwnProperty(selectedWorkSpaceUser)) {
                let wpUserDetails = workSpaceUsers
                  .filter(wpUser => wpUser.id === selectedWorkSpaceUser)[0]
                let team = (wpUserDetails && wpUserDetails.data && wpUserDetails.data.settings &&
                  wpUserDetails.data.settings[currentWorkSpaceId] &&    
                  wpUserDetails.data.settings[currentWorkSpaceId].team) || null;
                toAdd[selectedWorkSpaceUser] = {
                  team,
                  widget: currentWorkSpace.users[parseInt(selectedWorkSpaceUser)] === "SUPERADMIN" ? ["Reports", "Add/Remove members"] : [],
                  access: currentWorkSpace.users[parseInt(selectedWorkSpaceUser)] === "SUPERADMIN" ? "Owner" : "Member"
                };
              }
            });
            // console.log("to add", { ...toAdd });
            return { ...copy, ...toAdd };
          }
        }
      }
    }
  });
  projectDetailsToUpdate = _.pickBy(projectDetailsToUpdate, _.identity);
  delete projectDetailsToUpdate.created_at;
  delete projectDetailsToUpdate.updated_at;
  // console.log("Updated Project to add people", projectDetailsToUpdate);
  return projectDetailsToUpdate;
};

export const getWorkSpaceUsersView = (
  workSpaceUsers,
  currentWorkSpaceId,
  currentWorkSpace,
  projectDetails
) => {
  workSpaceUsers = workSpaceUsers.filter(
    workSpaceUser =>
      currentWorkSpace.super_admin_id !== workSpaceUser.id &&
      currentWorkSpace.users.hasOwnProperty(workSpaceUser.id) &&
      (currentWorkSpace.users[workSpaceUser.id] === "USER" || currentWorkSpace.users[workSpaceUser.id] === "SUPERADMIN")
  );
  let filteredWorkSpaceUsers = workSpaceUsers.filter(workSpaceUser => {
    if (projectDetails.data.hasOwnProperty("acl")) {
      if (projectDetails.data.acl.indexOf(workSpaceUser.id) < 0) {
        return workSpaceUser;
      }
    } else return workSpaceUser;
  });
  // console.log("filteredWorkSpaceUsers", filteredWorkSpaceUsers);
  let workSpaceUsersView = filteredWorkSpaceUsers.reduce((acc, curr) => {
    // console.log("abcd", curr);
    if (curr.hasOwnProperty("data")) {
      let { data } = curr;
      if (data && typeof data === "object") {
        if (
          data.hasOwnProperty("settings") &&
          data.settings.hasOwnProperty(currentWorkSpaceId)
        ) {
          acc.push({
            id: curr.id,
            name: `${curr.first_name}`,
            job_title: curr.data.settings[currentWorkSpaceId].job_title,
            img: curr.img
          });
        } else {
          acc.push({
            id: curr.id,
            name: `${curr.first_name}`,
            job_title: "",
            img: curr.img
          });
        }
      } else {
        acc.push({
          id: curr.id,
          name: `${curr.first_name}`,
          job_title: "",
          img: curr.img
        });
      }
    }
    return acc;
  }, []);
  // console.log(workSpaceUsersView);
  return workSpaceUsersView;
};

// function clean(object) {
//   Object.entries(object).forEach(([k, v]) => {
//     if (v && typeof v === "object") {
//       clean(v);
//     }
//     if (
//       (v && typeof v === "object" && !Object.keys(v).length) ||
//       v === null ||
//       v === undefined
//     ) {
//       if (Array.isArray(object)) {
//         object.splice(k, 1);
//       } else {
//         delete object[k];
//       }
//     }
//   });
//   return object;
// }

// export const getMembersLength = (data, workSpaceUsers, currentWorkSpace) => {
//   let membersLength = data.hasOwnProperty("acl") ? [...data.acl] : [];
//   const workSpaceUserIds = workSpaceUsers.map(workSpaceUser => {
//     return workSpaceUser.id;
//   });
//   console.log("workSpaceUserIds", workSpaceUserIds)
//   if (data.hasOwnProperty("acl")) {
//     membersLength = membersLength.filter(id => {
//       if (
//         workSpaceUserIds.includes(id) &&
//         (id !== currentWorkSpace.super_admin_id ||
//           (currentWorkSpace.users.hasOwnProperty(id) &&
//             currentWorkSpace.users[id] !== projectConstants.superAdmin))
//       )
//         return id;
//     });
//   }
//   if (data.hasOwnProperty("acl") && data.settings.member_access) {
//     membersLength = membersLength.filter(id => {
//       if (
//         data.settings.member_access.hasOwnProperty(id) &&
//         data.settings.member_access[id]
//       ) {
//         return id;
//       }
//     });
//   }
//   console.log("membersLength", membersLength);
//   return membersLength;
//   if(data.hasOwnProperty("acl") && data.acl.includes(currentWorkSpace.super_admin_id)) {
//     membersLength.splice(membersLength.indexOf(currentWorkSpace.super_admin_id), 1)
//   }
//   return membersLength.map(id => {
//     if(currentWorkSpace.users.hasOwnProperty(parseInt(id))) return id;
//   }).length
// };
