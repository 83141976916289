import React, { useRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";

import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";
import calendarIcon from "../../styles/img/svg/workload-day-picker.svg";
import "react-day-picker/lib/style.css";
import { Control } from "react-redux-form";
import { pure } from "recompose";

const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

const formatDate = (date, format, _locale) => {
  return dateFnsFormat(date, format);
};



function RenderDatePicker({
  field,
  placeholder,
  disableBefore,
  disableAfter,
  activity_start,
  onChange,
  onWlDayChange,
  isEdit,
  selectedDate,
  // children
}) {
  const { model } = field;
  const isDayDisabled = day => {
    return activity_start ? !DateUtils.isSameDay(day, activity_start) : false;
  };
  
  const inputEl = useRef(null);

  const onCalendarIconClick = () => {
    inputEl && inputEl.current.focus();
  }

  if (isEdit) {
    return (
      <Control
        model={model}
        component={props => (
          <DayPickerInput
            {...props}
            selectedDays={[new Date(selectedDate)]}
            formatDate={formatDate}
            value={field.value}
            format={"MMMM dd, yyyy"}
            parseDate={parseDate}
            dayPickerProps={{
              fixedWeeks: true,
              disabledDays: [
                isDayDisabled,
                { after: disableAfter },
                {
                  before: disableBefore
                }
              ]
            }}
            placeholder={placeholder}
            onDayChange={onChange}
            inputProps={{ style: { hieght: 200 } }}
          >
            {" "}
          </DayPickerInput>
        )}
        mapProps={{
          onDayChange: props => props.onChange
        }}
      />
    );
  }

  if(onWlDayChange) {
    return (
      <React.Fragment>
        <img src={calendarIcon} onClick={onCalendarIconClick} alt="calender" />
      <Control
        model={model}
        component={props => (
          <DayPickerInput
            {...props}
            formatDate={formatDate}
            component={props => <input { ...props } ref={inputEl} />}
            value={field.value}
            format={"MMMM dd, yyyy"}
            parseDate={parseDate}
            dayPickerProps={{
              fixedWeeks: true,
              disabledDays: [
                isDayDisabled,
                { after: disableAfter },
                {
                  before: disableBefore
                }
              ]
            }}
            placeholder={placeholder}
            onDayChange={onWlDayChange}
          >
            {" "}
          </DayPickerInput>
        )}
        mapProps={{
          onDayChange: props => props.onChange
        }}
      /></React.Fragment>
    );
  }

  return (
    <Control
      model={model}
      component={props => (
        <DayPickerInput
          {...props}
          formatDate={formatDate}
          value={field.value}
          format={"MMMM dd, yyyy"}
          parseDate={parseDate}
          dayPickerProps={{
            fixedWeeks: true,
            disabledDays: [
              isDayDisabled,
              { after: disableAfter },
              {
                before: disableBefore
              }
            ]
          }}
          placeholder={placeholder}
          // onDayChange={onWlDayChange ? onWlDayChange : () => {}}
        >
          {" "}
        </DayPickerInput>
      )}
      mapProps={{
        onDayChange: props => props.onChange
      }}
    />
  );
}
export default pure(RenderDatePicker);
