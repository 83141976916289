import React, { useState, useEffect } from "react";
import planCloseSVG from "../../styles/img/svg/view-plans-close.svg";

export default function NotSubscribed({
  showNoSubscriptionWarning,
  onTabClick
}) {
  const [showThis, setshowThis] = useState(
    showNoSubscriptionWarning === true ? true : false
  );
  //   if (showNoSubscriptionWarning === true) {
  //     setshowThis(true);
  //   }

  useEffect(() => {
    setshowThis(showNoSubscriptionWarning);
  }, [showNoSubscriptionWarning]);
  if (showThis === true) {
    return (
      <div className="no-subscription-warning">
        More than 5 users have been added to this workspace.<br/>Upgrade to a paid
        plan to access all features.
        <button
          className="view-plans-btn"
          onClick={() => {
            onTabClick("billing");
          }}
        >
          View plans
        </button>
        <div className="view-plans-close" onClick={() => setshowThis(false)}>
          <img src={planCloseSVG} alt="close" />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
