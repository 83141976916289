import React, { Component } from "react";

class RoleDropdwn extends Component {
 
  onSelectAccess = (projectMember, access) => {
    this.props.onSelectAccess(projectMember, access)
   
}


  render() {
    const { projectMember } = this.props;
    // console.log("props",this.props)
    return ( 
                <div className={`rectangle ${projectMember === "Owner" ? "less-top" : ""}`} >
                  
                  {projectMember === "Owner" && (
                   
                   <div>
                       {/* <OutsideClickHandler onOutsideClick={ () => onClickDdwnClose(index) }> */}
                     <div
                     className="prj-access-role-ddown-list"
                     onClick={_event => this.onSelectAccess("Remove")}
                     >
                     Remove from project
                     </div>
                     {/*  */}
                   {/* </OutsideClickHandler> */}
                   </div>
                 )}
                    {projectMember === "Manager" && (
                   
                      <div>
                          {/* <OutsideClickHandler onOutsideClick={ () => onClickDdwnClose(index) }> */}
                        <div
                        className="prj-access-role-ddown-list"
                        onClick={_event => this.onSelectAccess( "Member")}
                        >
                        Give member access
                        </div>
                        <div
                        className="prj-access-role-ddown-list"
                        onClick={_event => this.onSelectAccess("Remove")}
                        >
                        Remove from project
                        </div>
                        {/*  */}
                      {/* </OutsideClickHandler> */}
                      </div>
                    )}
                  
                    {projectMember === "Member" && (
                    <div>
                       {/* <OutsideClickHandler onOutsideClick={ () => onClickDdwnClose(index) }> */}
                        <div
                        className="prj-access-role-ddown-list"
                        onClick={_event => this.onSelectAccess("Manager")}
                        >
                        Give manager access
                        </div>
                        <div
                        className="prj-access-role-ddown-list"
                        onClick={_event => this.onSelectAccess("Remove")}
                        >
                        Remove from project
                        </div>
                    {/* </OutsideClickHandler> */}
                    </div>
                    )}
                </div>
                      
    )
  }
}
export default RoleDropdwn;
