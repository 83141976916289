import React from "react";
import Avatar from "react-avatar";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { workloadConstant } from "../../constants/workloadConstant";

import { calculateTotalPlannedHours } from "../../helpers/workloadHelper";
import { getIsSuperAdmin } from "../../helpers/reportlanding";

export default function UserList({
  workspace,
  workspaceUsers,
  isCollapsedBacklog,
  onUserSelected,
  listOfActivities,
  selectedUser,
  currentMode,
  currentdateValue,
  selectedProject,
  logedinUserDetails,
  searchText,
  searchType
}) {
  if (workspace && currentMode) {
    const totalWorkingDays =
      workspace.data.hasOwnProperty("settings") &&
      workspace.data.settings.weekly_working_days
        ? Object.keys(workspace.data.settings.weekly_working_days).filter(
            (key) => workspace.data.settings.weekly_working_days[key] === true
          ).length
        : 5;

    let totalWorkingHours = 0;

    if (currentMode.name === "Week") {
      totalWorkingHours = workspace.data.settings
        ? totalWorkingDays * workspace.data.settings.daily_working_hours
        : 40;
    }
    if (currentMode.name === "Day") {
      totalWorkingHours = workspace.data.settings
        ? workspace.data.settings.daily_working_hours
        : 8;
    }

    const sortedWorkspaceUsers = _.sortBy(
      workspaceUsers,
      [(workspaceUser) => workspaceUser.first_name.toLowerCase()],
      ["asc"]
    );

    const renderUserList =
      selectedUser &&
      sortedWorkspaceUsers
        .filter((user) => {
          return searchText &&
            searchText.length > 0 &&
            searchType === workloadConstant.searchUsers
            ? user.first_name.toLowerCase().indexOf(searchText.toLowerCase()) >
                -1
            : true;
        })
        .filter((user) => {
          return selectedProject.id !== 0
            ? selectedProject.data &&
              selectedProject.data.settings &&
              selectedProject.data.settings.member_access.hasOwnProperty(
                logedinUserDetails.id
              ) &&
              selectedProject.data.settings.member_access[logedinUserDetails.id]
                .access === "Manager"
              ? true
              : selectedProject.data &&
                selectedProject.data.acl &&
                selectedProject.data.acl.includes(user.id)
              ? true
              : getIsSuperAdmin(user, workspace)
              ? true
              : false
            : true;
        })
        .map((user) => {
          let totalPlannedHours = calculateTotalPlannedHours(
            currentMode,
            currentdateValue,
            user.id,
            listOfActivities
          );

          return (
            <div
              className={
                selectedUser.id === user.id
                  ? "user-workload selected"
                  : "user-workload"
              }
              key={user.id}
              onClick={() => onUserSelected(user)}
            >
              <div className="user-workload-image">
                <Tooltip title={user.first_name}>
                  {user.img ? (
                    <img src={user.img} alt="" />
                  ) : (
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      name={user.first_name}
                      maxInitials={1}
                    ></Avatar>
                  )}
                </Tooltip>
              </div>
              <div className="user-workload-name">
                {isCollapsedBacklog && (
                  <React.Fragment>
                    <span className="first-name">{user.first_name}</span>
                    <br />
                  </React.Fragment>
                )}

                <span>
                  {isCollapsedBacklog
                    ? `${Math.floor(
                        totalPlannedHours / 3600
                      )}/${totalWorkingHours} hours`
                    : `${Math.floor(
                        totalPlannedHours / 3600
                      )}/${totalWorkingHours} h`}
                </span>
              </div>
            </div>
          );
        });

    return (
      <div
        className={
          isCollapsedBacklog
            ? "user-workload-outer-div "
            : "user-workload-outer-div is-collapsed "
        }
      >
        {renderUserList}
      </div>
    );
  } else {
    return <div></div>;
  }
}
